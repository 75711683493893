<button mat-button (click)="closeModal()" color="warn" class="close-confirm-modal">
  <mat-icon>close</mat-icon>
</button>
<div class="success-authcode-message-container">
  <h3 class="title-confirmation" *ngIf="!data?.preference">
    {{ "model.patient.newPasswordFor" | translate }}
    <b>
      {{ data.currentUser.firstname }}
      {{ data.currentUser.name }}
    </b>
  </h3>
  <h3 class="title-confirmation" *ngIf="data?.preference">
    {{ "model.patient.newOwnPassword" | translate }}
  </h3>

  <div class="authcode-container">
    <p color="primary" id="authcode-display">{{ data.authcode }}</p>
    <button mat-button (click)="copyToClipboard()">
      <mat-icon>content_copy</mat-icon>
      <span *ngIf="!authcodeCopied">{{ "model.patient.copyAuthcode" | translate }}</span>
      <span *ngIf="authcodeCopied">{{ "model.patient.authcodeCopied" | translate }}</span>
    </button>
  </div>

  <mat-checkbox class="checkbox-mail-authcode" [(ngModel)]="sendAuthcode">
    {{ "model.patient.sendAuthcode" | translate }}
  </mat-checkbox>

  <div class="choice-container">
    <button mat-raised-button (click)="closeModal()" color="primary" class="choice-button">
      {{ "model.patient.finish" | translate }}
    </button>
  </div>
</div>
