import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RewardScoreExtended } from "../models/rewardScore-extended.model";
import { RewardScore } from "../models/rewardScore.model";
import { RewardScoreApiService } from "./api/rewardScore-api.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class RewardScoreService {
  constructor(private rewardScoreApiService: RewardScoreApiService, private userService: UserService) {}

  public canUpdateSync(): boolean {
    return this.userService.isAuthorizedSync(null, this.rewardScoreApiService.updateRoutes[0], "PUT");
  }

  public listRewardScoresByPatient(
    patientId: string,
    startDate: string,
    endDate: string,
    onlyInProgress: boolean
  ): Observable<RewardScore[]> {
    return this.rewardScoreApiService.listRewardScoresByPatient(patientId, startDate, endDate, onlyInProgress).pipe(
      map((values) => {
        return values.map((v) => new RewardScore(v));
      })
    );
  }

  public listRewardScoresByService(
    serviceId: string,
    startDate: string,
    endDate: string,
    onlyInProgress: boolean,
    services?: string[],
    monitoringServicesIds?: string[]
  ): Observable<RewardScoreExtended[]> {
    return this.rewardScoreApiService
      .listRewardScoresByService(serviceId, startDate, endDate, onlyInProgress, services, monitoringServicesIds)
      .pipe(
        map((values) => {
          return values.map((v) => new RewardScoreExtended(v));
        })
      );
  }

  public updateRewardScore(rewardScore: RewardScore): Observable<RewardScore> {
    return this.rewardScoreApiService.updateRewardScore(rewardScore);
  }
}
