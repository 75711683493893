<mat-dialog-content>
  <p>
    {{ "page.preference.conflictedLang" | translate : { lang: "languages." + data.lang1 | translate } }}
  </p>
  <p>
    {{ "page.preference.conflictedLangContinue" | translate : { lang: "languages." + data.lang2 | translate } }}
  </p>
  <br />
  <p>
    <mat-checkbox [(ngModel)]="rememberChoice">{{ "page.preference.rememberChoice" | translate }}</mat-checkbox>
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="chooseLang(data.lang1)">
    {{ "languages." + data.lang1 | translate }}
  </button>
  <button mat-button (click)="chooseLang(data.lang2)">
    {{ "languages." + data.lang2 | translate }}
  </button>
</mat-dialog-actions>
