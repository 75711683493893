import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Group } from "src/app/models/group.model";

@Component({
  selector: "app-group-search-only",
  templateUrl: "./group-search-only.component.html",
  styleUrls: ["./group-search-only.component.scss"],
})
export class GroupSearchOnlyComponent {
  public selection: Group;
  public hasSelect = false;
  constructor(private readonly dialogRef: MatDialogRef<GroupSearchOnlyComponent>) {}

  onSave() {
    this.dialogRef.close(this.selection);
  }

  groupSelected($event) {
    this.hasSelect = true;
    this.selection = $event;
  }

  public get isValid(): boolean {
    return this.selection?.isValid;
  }
}
