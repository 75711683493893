import { Reference } from "./reference.interface";
import { IEntity } from "./sharedInterfaces";

export interface IRewardScore extends IEntity {
  _id: string;
  patientReference: Reference;
  rewardDefinitionReference: Reference;
  currentScore: number;
  actionsHistory: IRewardScoreHistory[]; // history of actions in this adress
  start: string; // YYYY-MM-DD HH:mm (calculé àpd du rewardDefinition.goalDuration)
  end: string; // YYYY-MM-DD HH:mm
  hasBeenRewarded: boolean;
  rewardReceived?: string;
  rewardReceivedDate?: string;
  notes?: ICodeableConcept[]; // text of the note
  rewardStatus?: REWARD_STATUS; // status of the alert (UNPROCESSED / PROCESSED)
  actionsNotesHistory?: IAction[];
}

export interface IRewardScoreHistory {
  ponderationId: string;
  score: number;
  date: string; // YYYY-MM-DD HH:mm
}

export interface IAction {
  actionDate?: string;
  action?: ACTION_REWARD[];
  commentId?: number;
}

export interface IRewardScoreExtended extends IRewardScore {
  patientCustomInfo: CustomPatientInfos;
}

export interface CustomPatientInfos {
  bithdate: string;
  gender: string;
  name: string;
  firstname: string;
}

export interface ICodeableConcept {
  text: string;
}

export enum REWARD_STATUS {
  UNPROCESSED = 1,
  PROCESSED = 2,
}

export const enum ACTION_REWARD {
  ADD_NOTE = 2,
  CHANGE_STATUS = 3,
}
