import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({ name: "typeOf" })
export class TypeOfPipe implements PipeTransform {
  transform(value: unknown): unknown {
    if (moment(value).isValid()) {
      return "moment";
    } else {
      return typeof value;
    }
  }
}
