<ng-container *ngIf="hasChartValues">
  <div class="legend-container mt-1" [style.paddingLeft]="margin + 'px'" [style.paddingRight]="margin + 'px'">
    <div *ngFor="let statistic of statsObsGroups.statistics">
      <mat-icon fontSet="fas" fontIcon="fa-circle" class="legend-icon" [ngStyle]="{ color: statistic.baseColor }"></mat-icon>
      <span>{{ statistic.keyName | translate }}</span>
    </div>
  </div>
  <div #chartContainer>
    <!-- Id must start with a letter, we arbittrary chose "A" -->
    <figure [id]="'A' + chartId" class="m-0" style="text-align: center"></figure>
  </div>
</ng-container>

<p *ngIf="!hasChartValues">{{ "common.noData" | translate }}</p>
