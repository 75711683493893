import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { tap } from "rxjs/operators";
import { UserService } from "src/app/providers/user.service";

@Injectable({
  providedIn: "root",
})
export class CareplanEditorGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    let reconstructedUrl = "";
    let redirectUrl = "";
    let k = 0;
    for (const pfr of next.pathFromRoot) {
      let i = 0;
      for (const u of pfr.url) {
        if (i > 0) {
          reconstructedUrl += "/:id";
        } else {
          reconstructedUrl += "/" + u.path;
        }
        if (k < next.pathFromRoot.length - 1 || i < pfr.url.length - 1) {
          redirectUrl += "/" + u.path;
        }
        ++i;
      }
      ++k;
    }
    return this.userService.isAuthorized(reconstructedUrl, "GET").pipe(
      tap((isAuth) => {
        if (!isAuth) {
          this.router.navigate([redirectUrl]);
        }
      })
    );
  }
}
