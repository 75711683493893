import { Injectable } from "@angular/core";
import { Group } from "../models/group.model";
import { GroupApiService } from "./api/group-api.service";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class GroupService {
  constructor(private groupApiService: GroupApiService, private sessionService: SessionService) {}

  public list(healthcareService: string, services?: string[]) {
    if (healthcareService === this.sessionService.allsOption) {
      healthcareService = null;
    }
    return this.groupApiService.list(healthcareService, services);
  }

  public listAll() {
    return this.groupApiService.listAll();
  }

  public create(group: Group) {
    return this.groupApiService.create(group);
  }

  public update(group: Group) {
    return this.groupApiService.update(group);
  }
}
