import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subscription, merge, timer } from "rxjs";
import { tap } from "rxjs/operators";
import { FileLogger } from "../helpers/fileLogger";
import { Tools } from "../helpers/tools";
import { IDocument, IDocumentHistoric } from "../models/patient-document.interface";
import { DocumentApiService } from "./api/document-api.service";

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  constructor(private api: DocumentApiService, private translateService: TranslateService, private snackBar: MatSnackBar) {}

  public uploadDocument(formData: FormData): Observable<IDocument> {
    return this.api.create(formData);
  }

  public editDocument(param: Record<string, string>): Observable<IDocument> {
    return this.api.edit(param);
  }

  public downloadDocument(name: string, modified: string, patient: string): Subscription {
    const alert = this.snackBar.open(this.translateService.instant("common.downloadStarted"), "ok");
    const downloadTimer$ = timer(3000); // 3-second timer
    const getDocumentBlob$ = this.api.getDocument(name, modified, patient).pipe(
      tap((blob: Blob) => {
        Tools.downloadBlob(blob, name);
      })
    );
    return merge(downloadTimer$, getDocumentBlob$).subscribe(
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      (error) => {
        FileLogger.error("RiskAssessmentService", "openPdf", error);
        this.snackBar.open(this.translateService.instant("api.errors.download-error"), "ok", { duration: 5000 });
      },
      () => {
        alert.dismiss();
      }
    );
  }

  public getDocHistoric(patientId: string, name: string): Observable<IDocumentHistoric> {
    return this.api.getHistoric(patientId, name);
  }

  public archiveDocument(name: string, modified: string, patient: string): Observable<boolean> {
    return this.api.deleteDocument(name, modified, patient);
  }

  /**
   * Download data using a cAnalytics url
   * @param url The cAnalytics url
   * @returns Downloaded file
   */
  public downloadFromCAnalytics(url: string, name: string, fileType: string): Subscription {
    const alert = this.snackBar.open(this.translateService.instant("common.downloadStarted"), "ok");
    const downloadTimer$ = timer(3000); // 3-second timer
    const getDocumentBlob$ = this.api.downloadFromCAnalytics(url).pipe(
      tap((blob: Blob) => {
        const b = new Blob([blob], {
          type: fileType,
        });
        Tools.downloadBlob(b, name);
      })
    );
    return merge(downloadTimer$, getDocumentBlob$).subscribe(
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      (error) => {
        FileLogger.error("DocumentService", "downloadFromCAnalytics", error);
        this.snackBar.open(this.translateService.instant("api.errors.download-error"), "ok", { duration: 5000 });
      },
      () => {
        alert.dismiss();
      }
    );
  }
}
