import { Identifier } from "./identifier.interface";
import { IEntity } from "./sharedInterfaces";

export const Methods = ["POST", "GET", "PUT", "DELETE"];

export enum AccessValue {
  UNDEFINED,
  AUTHORIZED,
  RESTRICTED,
}
export interface IAccessGroup extends IEntity {
  identifier: Identifier;
  description?: string;
  permissions?: IAccessGroupPermission[];
  permsByTheme?: IPermissionsByTheme[]; // dashboard only; used for display
  displayPerms?: boolean; // dashboard only; used for display
}
export interface IPermissionsByTheme {
  theme: string;
  permissions: IAccessGroupPermission[];
  displayPerms?: boolean;
}
export interface IAccessGroupPermission extends IEntity {
  accessGroupId: string;
  routeName: string;
  method: "GET" | "PUT" | "POST" | "DELETE";
  access: AccessValue;
  theme?: string; // dashboard only; used for pretty display
}

export interface IPractitionerAccess {
  routeName: string;
  method: "GET" | "PUT" | "POST" | "DELETE";
  access: AccessValue; // final access summed by each group access
  theme: string;
  accessesByGroups: IAccessByGroup[];
}

export interface IAccessByGroup {
  accessGroupName: string;
  access: AccessValue;
}
