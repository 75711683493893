import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IKnowMedia } from "src/app/models/knowledge.interface";
import { ITranslation } from "src/app/models/translation.interface";
import { KnowledgeService } from "src/app/providers/knowledge.service";
import { LanguagesService } from "src/app/providers/languages.service";
import { SessionService } from "src/app/providers/session.service";

@Component({
  selector: "app-knowledge-comparison-dialog",
  templateUrl: "./knowledge-comparison-dialog.component.html",
  styleUrls: ["./knowledge-comparison-dialog.component.scss"],
})
export class KnowledgeComparisonDialogComponent implements OnInit, OnDestroy {
  public publishedMediaForm: UntypedFormGroup = this.fb.group({
    category: ["", [Validators.required]],
    content: this.fb.array([]),
    description: ["", []],
    importanceLevel: ["", []],
    label: ["", [Validators.required]],
    language: [{ value: "", disabled: true }, [Validators.required]],
    type: [{ value: "", disabled: true }, [Validators.required]],
    specificQuestionnaire: [[], []],
    visibleForPatient: [""],
    visibleForMonitoring: [""],
  });

  public draftMediaForm: UntypedFormGroup = this.fb.group({
    category: ["", [Validators.required]],
    content: this.fb.array([]),
    description: ["", []],
    importanceLevel: ["", []],
    label: ["", [Validators.required]],
    language: ["", [Validators.required]],
    type: ["", [Validators.required]],
    specificQuestionnaire: [[], []],
    visibleForPatient: ["", []],
    visibleForMonitoring: ["", []],
  });

  public joditConfig = {
    useSearch: false,
    spellcheck: true,
    language: this.sessionService.userLang,
    toolbarButtonSize: "small",
    height: 450,
    disablePlugins: "file, media",
    style: { font: "16px Arial" },
    container: {
      style: { border: "solid 2px red" },
    },
    readonly: false,
  };

  public joditConfigReadOnly = {
    useSearch: false,
    spellcheck: true,
    language: this.sessionService.userLang,
    toolbarButtonSize: "small",
    height: 450,
    disablePlugins: "file, media",
    style: { font: "16px Arial" },
    container: {
      style: { border: "solid 2px red" },
    },
    readonly: true,
  };

  public availableLangs: ITranslation[] = [];
  public availableTypes = [1, 2, 3];

  draftSlidesList: unknown[];
  draftCurrentSlide = 0;
  get draftContent(): UntypedFormArray {
    return this.draftMediaForm.get("content") as UntypedFormArray;
  }

  publishedSlidesList: unknown[];
  publishedCurrentSlide = 0;
  get publishedContent(): UntypedFormArray {
    return this.publishedMediaForm.get("content") as UntypedFormArray;
  }

  get importanceLevel(): number {
    return this.draftMediaForm.get("importanceLevel").value;
  }

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<KnowledgeComparisonDialogComponent>,
    private sessionService: SessionService,
    private fb: UntypedFormBuilder,
    private knowledgeService: KnowledgeService,
    @Inject(MAT_DIALOG_DATA)
    public data: { publishedMedia: IKnowMedia; draftMedia: IKnowMedia },
    protected languagesService: LanguagesService
  ) {}

  ngOnInit(): void {
    // init draft form
    this.draftSlidesList = this.initForm(this.draftMediaForm, this.data.draftMedia);
    // init published form
    this.publishedSlidesList = this.initForm(this.publishedMediaForm, this.data.publishedMedia);
    this.languagesService
      .list()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((languages) => (this.availableLangs = languages));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initForm(form: UntypedFormGroup, media: IKnowMedia) {
    form.patchValue(this.knowledgeService.patchedMediaValuesButContent(media));
    form = this.knowledgeService.slideItUp(form, media);
    return Object.keys((form.get("content") as UntypedFormArray).getRawValue());
  }

  public save(): void {
    this.dialogRef.close(this.draftMediaForm);
  }

  public setImportanceLevel(level: number): void {
    if (this.importanceLevel === level) {
      this.draftMediaForm.get("importanceLevel").setValue(0);
    } else {
      this.draftMediaForm.get("importanceLevel").setValue(level);
    }
  }

  public onPublishedSlideChanged(newSlideId: number): void {
    this.publishedCurrentSlide = newSlideId;
  }

  public onDraftSlideChanged(newSlideId: number): void {
    this.draftCurrentSlide = newSlideId;
  }

  /**
   * Create new empty media slide
   */
  public createNewSlide(): void {
    (this.draftMediaForm.get("content") as UntypedFormArray).push(
      this.fb.group({
        title: ["", Validators.required],
        text: ["", Validators.required],
        showInSummary: [true],
      })
    );
    this.draftSlidesList = Object.keys((this.draftMediaForm.get("content") as UntypedFormArray).getRawValue());
    this.draftCurrentSlide = this.draftSlidesList.length - 1;
  }

  /**
   * delete media slide and mark form as dirty in order to enable the save button
   */
  public removeSlide(index: number): void {
    this.draftMediaForm.markAsDirty();
    (this.draftMediaForm.get("content") as UntypedFormArray).removeAt(index);
    this.draftSlidesList = Object.keys((this.draftMediaForm.get("content") as UntypedFormArray).getRawValue());
    this.draftCurrentSlide = index - 1;
  }
}
