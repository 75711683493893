import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LoginPageComponent } from "../pages/login-page/login-page.component";
import { SessionService } from "../providers/session.service";
import { UserService } from "../providers/user.service";

@Injectable({
  providedIn: "root",
})
export class LoginGuard implements CanActivate {
  constructor(
    private sessionService: SessionService,
    private userService: UserService,
    private router: Router,
    private cookieService: CookieService
  ) {}

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): true | UrlTree | Observable<boolean | UrlTree> {
    // if already connected, we redirect to the default page
    if (this.sessionService.isLoggedIn) {
      if (this.cookieService.check(LoginPageComponent.COOKIE_PAGE_ID)) {
        const pageid = this.cookieService.get(LoginPageComponent.COOKIE_PAGE_ID);
        this.cookieService.delete(LoginPageComponent.COOKIE_PAGE_ID);
        return this.router.createUrlTree([pageid]);
      } else if (this.cookieService.check(LoginPageComponent.COOKIE_REDIRECT_MODALID)) {
        return this.router.createUrlTree(["/dashboard"]);
      }
      return this.userService.defaultPage().pipe(
        map((pageName) => {
          return this.router.createUrlTree([pageName]);
        }),
        catchError(() => {
          return of(true);
        })
      );
    }
    return true;
  }
}
