import { Component, OnInit } from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { ICareplan } from "src/app/models/careplans.interface";
import { IObservationsLoincsInfo } from "src/app/models/observations.interface";
import { IQuestionnaireIdentifierInfo } from "src/app/models/questionnaire.interface";
import { Reference } from "src/app/models/reference.interface";
import { AnonymisedDataService } from "src/app/providers/anonymised-data.service";
import { CareplansService } from "src/app/providers/careplans.service";
import { SessionService } from "src/app/providers/session.service";

@Component({
  selector: "app-data-extraction",
  templateUrl: "./data-extraction.component.html",
  styleUrls: ["./data-extraction.component.scss"],
})
export class DataExtractionComponent implements OnInit {
  public isLoading = true;
  public exportFormat: "JSON" | "CSV" = "JSON";
  public availableObservations: [{ careplanId: string; observations: IObservationsLoincsInfo[] }]; // careplanId = careplan id + service ref
  public availableQuestionnaires: [{ careplanId: string; questionnaires: IQuestionnaireIdentifierInfo[] }]; // careplanId = careplan id + service ref
  public patientData = false;
  public drugData = false;
  public alertData = false;
  public anonymizeDates = false;
  public emailsToExclude = "";
  public selectedCareplans: [{ careplanId: string; observations: string[]; questionnaires: string[] }] = [
    { careplanId: "", observations: [], questionnaires: [] },
  ]; // careplanId = careplan id + service ref
  public careplanOptions: [{ careplan: ICareplan; service: Reference }];
  public allCps: ICareplan[];
  private onDestroy$ = new Subject<void>();

  constructor(
    private careplansService: CareplansService,
    private anonymisedDataService: AnonymisedDataService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.getCareplans();
  }

  private getCareplans() {
    this.careplansService
      .getCareplanTemplates(null, this.sessionService.userLang)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((careplans) => {
        if (careplans) {
          this.allCps = careplans;
          this.allCps.forEach((c) => {
            c.author.forEach((a) => {
              if (this.careplanOptions?.length) {
                this.careplanOptions.push({
                  careplan: c,
                  service: a,
                });
              } else {
                this.careplanOptions = [
                  {
                    careplan: c,
                    service: a,
                  },
                ];
              }
            });
          });
          this.careplanOptions.sort((a: { careplan: ICareplan; service: Reference }, b: { careplan: ICareplan; service: Reference }) => {
            const comp1 = a.service.display.localeCompare(b.service.display);
            if (comp1 === 0) {
              return a.service.reference.localeCompare(b.service.reference);
            }
            return a.service.display.localeCompare(b.service.display);
          });
          this.isLoading = false;
        }
      });
  }

  public removeSelectedCp(i: number): void {
    this.selectedCareplans.splice(i, 1);
    this.availableObservations.splice(i, 1);
    this.availableQuestionnaires.splice(i, 1);
  }

  public addCp(): void {
    if (this.selectedCareplans?.length) {
      this.selectedCareplans.push({ careplanId: "", observations: [], questionnaires: [] });
    } else {
      this.selectedCareplans = [{ careplanId: "", observations: [], questionnaires: [] }];
    }

    if (this.availableObservations?.length) {
      this.availableObservations.push({ careplanId: "", observations: [] });
    } else {
      this.availableObservations = [{ careplanId: "", observations: [] }];
    }

    if (this.availableQuestionnaires?.length) {
      this.availableQuestionnaires.push({ careplanId: "", questionnaires: [] });
    } else {
      this.availableQuestionnaires = [{ careplanId: "", questionnaires: [] }];
    }
  }

  public async exportData(): Promise<void> {
    const supportCareplans = [];
    const exportObs = [];
    const exportQuests = [];
    let servicesToExclude = [];
    this.selectedCareplans.forEach((cp) => {
      const cpId = cp.careplanId.split("+")[0];
      const serviceRef = cp.careplanId.split("+")[1];
      supportCareplans.push(cpId);
      exportObs.push(cp.observations);
      exportQuests.push(cp.questionnaires);
      const foundCp = this.allCps.find((c) => c.support[0].reference === cpId);
      const indexFound = foundCp.author.findIndex((a) => a.reference === serviceRef);
      foundCp.author.splice(indexFound, 1);
      foundCp.author.forEach((a) => {
        servicesToExclude.push(a.reference);
      });
      if (servicesToExclude?.length && servicesToExclude.includes(serviceRef)) {
        servicesToExclude.forEach((s, i) => {
          if (s === serviceRef) {
            servicesToExclude.splice(i, 1);
          }
        });
      }
    });
    servicesToExclude = servicesToExclude.filter(ArrayHelper.onlyUnique);
    await this.anonymisedDataService.export(
      this.exportFormat,
      supportCareplans.toString(),
      exportObs.toString(),
      exportQuests.toString(),
      this.emailsToExclude.replace(/\s/g, ""),
      servicesToExclude.toString(),
      this.patientData,
      this.drugData,
      this.alertData,
      this.anonymizeDates
    );
  }

  public async loadCpObsAndQuests(event: MatSelectChange, i: number): Promise<void> {
    const careplanId = event.value;
    const careplanSupport = careplanId.split("+")[0];
    const publicationDate = this.allCps.find((cp) => cp.support[0].reference === careplanSupport).publicationDate;
    const observations: IObservationsLoincsInfo[] = await this.careplansService.getObservationsLinkedToCareplan(
      careplanSupport,
      false,
      publicationDate
    );
    const questionnaires: IQuestionnaireIdentifierInfo[] = await this.careplansService.getQuestionnairesLinkedToCareplan(
      careplanSupport,
      false,
      publicationDate
    );

    if (this.availableObservations?.length) {
      this.availableObservations[i] = { careplanId: careplanId, observations: observations };
    } else {
      this.availableObservations = [{ careplanId: careplanId, observations: observations }];
    }

    if (this.availableQuestionnaires?.length) {
      this.availableQuestionnaires[i] = { careplanId: careplanId, questionnaires: questionnaires };
    } else {
      this.availableQuestionnaires = [{ careplanId: careplanId, questionnaires: questionnaires }];
    }
  }
}
