import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { map, startWith, takeUntil } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { Tools } from "src/app/helpers/tools";
import { AccessValue, IAccessByGroup, IAccessGroup, IPractitionerAccess } from "src/app/models/accessGroup.interface";
import { Account } from "src/app/models/account.interface";
import { DataType } from "src/app/models/filter.interface";
import { AccessService } from "src/app/providers/access.service";
import { AccessGroupApiService } from "src/app/providers/api/accessGroup-api.service";
import { UserService } from "src/app/providers/user.service";
import { AccessGroupDialogComponent } from "../access-group-dialog/access-group-dialog.component";
import { RoutesService } from "../routesServices.service";
import { PractitionerAccessListDataSource } from "./practitioner-access-list-datasource";

@Pipe({ name: "findGroupAccess" })
export class FindGroupAccessPipe implements PipeTransform {
  transform(accessesByGroups: IAccessByGroup[], groupLabel: string): AccessValue {
    const access = accessesByGroups.find((a) => a.accessGroupName === groupLabel);
    if (access !== undefined) {
      return access.access;
    }
    return AccessValue.UNDEFINED;
  }
}
@Component({
  selector: "app-practitioner-access-groups",
  templateUrl: "./practitioner-access-groups.component.html",
  styleUrls: ["./practitioner-access-groups.component.scss"],
})
export class PractitionerAccessGroupsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatTable) table: MatTable<IPractitionerAccess>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private _practitionerAccessGroupsIds: string[];
  @Input() set practitionerAccessGroupsIds(value: string[]) {
    this._practitionerAccessGroupsIds = value ? Tools.clone(value) : undefined;
    // Useful for when we select another practitioner after this component has been initialized:
    if (value && this.allAccessGroups) {
      this.setupPractitionerAccesses();
      if (!this.dataSource && this.table && this.sort && this.paginator) {
        this.setupDatasource();
      } else if (this.dataSource) {
        this.dataSource.loadData(this.practitionerAccesses);
      }
    }
  }
  @Output() practitionerAccessGroupsIdsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input() ownAccount: Account;
  @Input() allAccessGroups: IAccessGroup[];
  @Input() needDelete = false;
  @Input() needAdd = false;
  @Input() needCreate = false;
  @Input() mode = FORMS_MODE;

  public pageLoaded = false;
  public dataSource: PractitionerAccessListDataSource;
  public displayedColumns: string[] = ["theme", "method", "routeName"];
  public ACCESS = AccessValue;
  public accessList = Tools.getValuesOfEnum(AccessValue);
  public dataType = DataType;

  public nbPermissions: number;
  public pageSize = 20;
  public showPaginator = true;
  public accessGroupsControl = new UntypedFormControl(null);
  public filteredAccessGroups: Observable<IAccessGroup[]>;
  private practitionerAccessGroups: IAccessGroup[];
  private practitionerAccesses: IPractitionerAccess[];
  public practitionerGroupsNames: string[];

  // All permissions of this page
  public canReadGroups = false;
  public canReadPermissions = false;

  public alreadyInGroupError = false;

  /** Subject that emits when the component has been destroyed. */
  // tslint:disable-next-line: variable-name
  private onDestroy$ = new Subject<void>();

  constructor(
    private accessService: AccessService,
    private accessGroupApiService: AccessGroupApiService,
    private dialog: MatDialog,
    private routesServices: RoutesService, // Important ! Needed for service injection, do not remove !
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.checkUsersPermissions(this.ownAccount);
    if (!this.allAccessGroups) {
      this.setupAllAccessGroups()
        .then((success) => {
          // The first time this component is initialized, it's possible that we did not choose a practitioner yet
          if (success && this._practitionerAccessGroupsIds && !this.practitionerAccesses) {
            this.setupPractitionerAccesses();
            // Since we are in an asynchronous function, we do not know if the view has already been initialized:
            if (!this.dataSource && this.table && this.sort && this.paginator) {
              this.setupDatasource();
            }
          }
        })
        .catch((err) => {
          FileLogger.error("PractitionerAccessGroupsComponent", "Error while setting up access groups", err);
          this.pageLoaded = true;
        });
    } else if (this._practitionerAccessGroupsIds) {
      this.setupPractitionerAccesses();
    }
    this.filteredAccessGroups = this.accessGroupsControl.valueChanges.pipe(
      takeUntil(this.onDestroy$),
      startWith(""),
      map((value: string | IAccessGroup) => {
        const name = typeof value === "string" ? value : value?.identifier.label;
        return name ? this._filterAccessGroups(name as string) : this.allAccessGroups?.slice();
      })
    );
  }

  ngAfterViewInit(): void {
    // Here we are certain that the view has been initialized
    // But we don't know if the user already choose a practioner or not
    if (this.practitionerAccesses && !this.dataSource) {
      this.setupDatasource();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Check the permissions of the user
   * @param account the user account
   */
  private checkUsersPermissions(account: Account): void {
    this.canReadGroups = this.userService.isAuthorizedSync(account, this.accessGroupApiService.readRoutes[0], "GET");
    this.canReadPermissions = this.userService.isAuthorizedSync(account, this.accessGroupApiService.readRoutes[1], "GET");
  }

  /**
   * Download the full list of access groups available and their permissions
   * @returns
   */
  private async setupAllAccessGroups(): Promise<boolean> {
    const loadGroupsSuccess = await this.loadAccessGroups();
    if (!loadGroupsSuccess) {
      return false;
    }
    const loadPermissionsSuccess = await this.loadGroupsPermissions();
    if (!loadPermissionsSuccess) {
      return false;
    }
    return true;
  }

  /**
   * We need all the user's permissions in a special format that gathers all the access for a same permission
   * given by his different access groups into one object that we will use for our table
   */
  private setupPractitionerAccesses(): void {
    // Get the practitioner's own access groups:
    this.practitionerAccessGroups = this.allAccessGroups.filter((g) => this._practitionerAccessGroupsIds.includes(g.identifier.value));
    this.practitionerGroupsNames = this.practitionerAccessGroups.map((g) => g.identifier.label);
    // Map the permissions according to their theme, method and route name:
    const permissions = new Map<string, IAccessByGroup[]>();
    const practionerAccess: IPractitionerAccess[] = [];
    for (const group of this.practitionerAccessGroups) {
      for (const perm of group.permissions) {
        const key = perm.theme + ";" + perm.method + ";" + perm.routeName;
        if (!permissions.has(key)) {
          permissions.set(key, []);
        }
        permissions.get(key).push({ accessGroupName: group.identifier.label, access: perm.access });
      }
    }
    // Create for each permission, an object with the final access and a list of all
    // the access given by the different access groups for this permission:
    permissions.forEach((value: IAccessByGroup[], key: string) => {
      const perm = key.split(";");
      const isForbidden = value.findIndex((a) => a.access === AccessValue.RESTRICTED) > -1;
      const isAuthorized = !isForbidden && value.findIndex((a) => a.access === AccessValue.AUTHORIZED) > -1;
      const finalAccess = isForbidden ? AccessValue.RESTRICTED : isAuthorized ? AccessValue.AUTHORIZED : AccessValue.UNDEFINED;
      practionerAccess.push({
        theme: perm[0],
        method: perm[1] as "GET" | "PUT" | "POST" | "DELETE",
        routeName: perm[2],
        accessesByGroups: value,
        access: finalAccess,
      });
    });
    this.practitionerAccesses = practionerAccess;
    this.nbPermissions = this.practitionerAccesses.length;
    // We will have one extra column for each access group the user belongs to:
    this.displayedColumns = ["theme", "method", "routeName"];
    this.displayedColumns.push(...this.practitionerGroupsNames);
  }

  /**
   * Loads all the access groups
   * @returns true if it was loaded without error, false otherwise
   */
  private async loadAccessGroups(): Promise<boolean> {
    if (!this.canReadGroups) {
      return false;
    }
    try {
      this.allAccessGroups = await this.accessService.readAccessGroups();
      return true;
    } catch (err) {
      FileLogger.error("PractitionerAccessGroupsComponent", "Error while loading access groups: ", err);
      this.snackBar
        .open(`❗${this.translateService.instant("page.accessGroups.loadingGroupsError")}`, null, { duration: 4000 })
        .afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe();
      return false;
    }
  }

  /**
   * Loads the permissions of the non-filtered access group's list
   * @returns true if it was loaded without error, false otherwise
   */
  private async loadGroupsPermissions(): Promise<boolean> {
    if (!this.canReadPermissions) {
      return false;
    }
    try {
      await this.accessService.loadGroupsPermissions(this.allAccessGroups);
      return true;
    } catch (err) {
      FileLogger.error("PractitionerAccessGroupsComponent", "Error while loading access groups' permissions: ", err);
      this.snackBar
        .open(`❗${this.translateService.instant("page.accessGroups.loadingPermissionsError")}`, null, { duration: 4000 })
        .afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe();
      return false;
    }
  }

  /**
   * Create the datasource, adds the paginator and the sorts and set the datasource to the table
   * Should be only used once during the initialization of the component
   */
  public setupDatasource(): void {
    this.dataSource = new PractitionerAccessListDataSource(this.translateService);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.loadData(this.practitionerAccesses);
    this.table.dataSource = this.dataSource;
    this.pageLoaded = true;
  }

  /**
   * Toggle the paginator for the array
   * @param show (boolean) whether we want the paginator or not
   */
  public toggleShowPaginator(show: boolean): void {
    this.showPaginator = show;
    this.dataSource.paginator = show ? this.paginator : undefined;
  }

  // --------------------------------------------------------------------------

  /**
   * Emit an array with the ids of the chosen access groups
   * @returns
   */
  public async save(): Promise<boolean> {
    this.practitionerAccessGroupsIdsChange.emit(this._practitionerAccessGroupsIds);
    return true;
  }

  /**
   * Removes an access group from the user and recomputes his permissions
   * @param idx (number) the index of the group we want to remove
   */
  public removeAccessGroup(idx: number): void {
    const groupToRemove = this.practitionerAccessGroups.splice(idx, 1);
    this.practitionerGroupsNames.splice(idx, 1);
    this._practitionerAccessGroupsIds = this._practitionerAccessGroupsIds.filter((i) => i !== groupToRemove[0].identifier.value);
    this.setupPractitionerAccesses();
    this.dataSource.loadData(this.practitionerAccesses);
  }

  /**
   * Adds an access group to the user and recomputes his permissions
   * If the user already has that access group, display an error
   * @returns
   */
  public addAccessGroup(): void {
    const newGroup = this.accessGroupsControl.value;
    if (newGroup && newGroup.identifier) {
      if (!this._practitionerAccessGroupsIds) {
        this._practitionerAccessGroupsIds = [];
      }
      if (this._practitionerAccessGroupsIds.includes(newGroup.identifier.value)) {
        this.alreadyInGroupError = true;
        return;
      }
      this.alreadyInGroupError = false;
      this._practitionerAccessGroupsIds.push(newGroup.identifier.value);
      this.setupPractitionerAccesses();
      this.accessGroupsControl.reset();
      this.dataSource.loadData(this.practitionerAccesses);
    }
  }

  /**
   * Opens the access group modal. Add the created access group to the user groups
   * @returns
   */
  public createAccessGroup(): void {
    if (!this.needCreate) {
      return;
    }
    this.dialog
      .open(AccessGroupDialogComponent, {
        data: {
          mode: FORMS_MODE.CREATE,
          allAccessGroups: this.allAccessGroups,
        },
        disableClose: false,
        maxWidth: "90vw",
        width: "70%",
      })
      .afterClosed()
      .subscribe((result: { success: boolean; data: IAccessGroup }) => {
        if (result && result.success) {
          this.allAccessGroups.push(result.data);
          this.practitionerAccessGroups.push(result.data);
          this._practitionerAccessGroupsIds.push(result.data.identifier.value);
          this.setupPractitionerAccesses();
          this.dataSource.loadData(this.practitionerAccesses);
        }
      });
  }

  /**
   * Display function for the autocomplete access group input
   * @param group the selected group
   * @returns
   */
  public displayGroupFn(group: IAccessGroup): string {
    return group ? group.identifier.label : "";
  }

  /**
   * Filter the access group when we search for a group to add to the user
   * @param value the string entered
   * @returns an array with the access groups filtered by label
   */
  private _filterAccessGroups(value: string): IAccessGroup[] {
    const filterValue = value.toLowerCase();
    return this.allAccessGroups?.filter((group) => group.identifier.label.toLowerCase().includes(filterValue));
  }
}
