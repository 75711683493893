import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Tools } from "src/app/helpers/tools";
import { Organization } from "src/app/models/organization.model";
import { EnableWhen } from "src/app/models/sharedModels.model";
import { IVitalProfileDefinition } from "src/app/models/vitalProfileDefinition.interface";
import { OrganizationsService } from "src/app/providers/organizations.service";
import { SessionService } from "src/app/providers/session.service";

export interface InsuranceOpt extends Organization {
  selected: boolean;
}

@Component({
  selector: "app-enable-when-input",
  templateUrl: "./enable-when-input.component.html",
  styleUrls: ["./enable-when-input.component.scss"],
})
export class EnableWhenInputComponent implements OnInit, OnDestroy {
  public currentLang: string;

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  @ViewChild("insInput") input: ElementRef;

  @Input() enableWhen: EnableWhen; // index of the current enableWhen
  @Output() enableWhenChange = new EventEmitter<EnableWhen>();

  @Input() isSubCriteria: boolean;

  @Input() vitalSignsDefinitions: IVitalProfileDefinition[];

  @Input() index: number; // index of the current enableWhen
  @Output() delete = new EventEmitter<any>();

  @Input() enableWhenBehaviour: "all" | "any";
  @Output() enableWhenBehaviourChange = new EventEmitter<string>();

  operatorOptions = ["=", ">", "<", ">=", "<=", "€"];

  public $insuOrganizations = this.organisationService.listSome("ins", this.sessionService.currentCountry);

  public availableInsurances: Organization[] = [];
  public filteredInsurances: Organization[] = [];
  public selectedInsurances: Organization[] = [];

  constructor(private sessionService: SessionService, private organisationService: OrganizationsService) {
    this.currentLang = this.sessionService.userLang;
    this.sessionService.refreshServerTraductions.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentLang = this.sessionService.userLang;
    });
  }

  async ngOnInit(): Promise<void> {
    this.availableInsurances = await this.$insuOrganizations.toPromise();

    // show selected Insurance in the mat-autocomplete field based on the insurance value within the enableWhen
    if (this.enableWhen.code.coding[0].code === "ins" && this.enableWhen.answer) {
      JSON.parse(this.enableWhen.answer as string).forEach((code) => {
        const insurance = (this.availableInsurances as InsuranceOpt[]).find((ins) => ins.identifier[0].value === code);
        insurance.selected = true;
        this.selectedInsurances.push(insurance);
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public setEnableWhen(): void {
    this.enableWhenChange.emit(this.enableWhen);
  }

  public setEnableWhenBehaviour(): void {
    this.enableWhenBehaviourChange.emit(this.enableWhenBehaviour);
  }

  public deleteCondition(): void {
    this.delete.emit();
  }

  private insuranceDisplay(insurance: Organization) {
    return insurance ? insurance.organizationName : "";
  }

  public filterInsurances(event): void {
    if (!event.organizationName) {
      this.filteredInsurances = this.availableInsurances.filter((ins) => {
        return Tools.suppressDiacritics(ins.organizationName)?.toLowerCase().indexOf(Tools.suppressDiacritics(event).toLowerCase()) > -1;
      });
    }
  }

  private setInsurance() {
    this.enableWhen.answer = JSON.stringify(this.selectedInsurances.map((ins) => ins.identifier[0].value));
    this.enableWhenChange.emit(this.enableWhen);
  }

  public toggleInsuranceSelection(insurance: InsuranceOpt): void {
    insurance.selected = !insurance.selected;

    if (insurance.selected) {
      this.selectedInsurances.push(insurance);
    } else {
      const i = this.selectedInsurances.findIndex((ins) => ins === insurance);
      this.selectedInsurances.splice(i, 1);
      // empty input and filters from the searched string within mat-autocomplete
      this.input.nativeElement.value = null;
      this.filteredInsurances = undefined;
    }
    this.setInsurance();
  }
}
