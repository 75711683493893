<div
  class="alert"
  [ngClass]="[
    visibility,
    options?.type ? 'alert-' + options?.type : 'alert-info',
    options?.closable ? 'alert-closable' : '',
    options?.showIcon ? 'flex' : ''
  ]"
  role="alert"
>
  <mat-icon *ngIf="options?.showIcon">{{ options?.type }}</mat-icon>
  <div [ngClass]="{ 'mr-05': options?.closable }"><ng-content></ng-content></div>
  <mat-icon *ngIf="options?.closable" class="close" (click)="onDismiss()">close</mat-icon>
</div>
