<form [formGroup]="filterForm">
  <div class="close-cross">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <h2 mat-dialog-title>{{ data?.fieldName }}</h2>

  <mat-dialog-content>
    <mat-form-field>
      <input
        cdkFocusInitial
        matInput
        (dateChange)="onChangeFromDate()"
        [matDatepicker]="fromDate"
        [max]="maxFromDate"
        formControlName="fromDate"
        placeholder="{{ 'itemFilter.fromDate' | translate }}"
        aria-label="From date input"
      />
      <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
      <mat-datepicker #fromDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        (dateChange)="onChangeToDate()"
        [matDatepicker]="toDate"
        formControlName="toDate"
        [min]="minToDate"
        placeholder="{{ 'itemFilter.toDate' | translate }}"
        aria-label="To date input"
      />
      <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
      <mat-datepicker #toDate></mat-datepicker>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="clear()" aria-label="Clear filter">
      {{ "itemFilter.clear" | translate }}
    </button>
    <button mat-raised-button color="primary" [disabled]="!filterForm.valid" (click)="apply()" aria-label="Apply filter" type="submit">
      {{ "itemFilter.apply" | translate }}
    </button>
  </mat-dialog-actions>
</form>
