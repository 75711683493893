import { Component, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-display-environement-dialog",
  templateUrl: "./display-environement-dialog.component.html",
  styleUrls: ["./display-environement-dialog.component.scss"],
})
export class DisplayEnvironementDialogComponent implements OnInit {
  environmentMode = environment;

  txtDisplay: string;

  ngOnInit(): void {
    switch (this.environmentMode.envName) {
      case "dev":
        this.txtDisplay = "environments.devEnvironementDisplay";
        break;

      case "test":
        this.txtDisplay = "environments.testEnvironementDisplay";
        break;

      case "local":
        this.txtDisplay = "environments.localEnvironementDisplay";
        break;
    }
  }
}
