<div class="pagesMenu">
  <!-- Page management goes here (note: maybe put it in a component...) -->
  <button
    mat-button
    *ngFor="let in of counter; let i = index"
    [ngClass]="{
      pageTab: true,
      selectedPage: i === currentPage,
      firstPage: i === 0
    }"
    [disabled]="i === currentPage"
    (click)="displayPage(i)"
  >
    {{ "common." + translationKey | translate }} {{ i + 1 }}
    <button mat-icon-button aria-label="Delete icon" *ngIf="i !== 0 && i === currentPage && !visualization" (click)="removePage(i)">
      <mat-icon>close</mat-icon>
    </button>
  </button>
  <button mat-button class="pageTab addPageTab" (click)="addPage()" *ngIf="!visualization">
    <i> + {{ "common." + translationKey | translate }}</i>
  </button>
</div>
