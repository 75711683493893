import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { Tools } from "src/app/helpers/tools";
import { SlimUserInfo } from "src/app/models/account.interface";
import { AppError } from "src/app/models/app-error.interface";
import { Identifier } from "src/app/models/identifier.interface";
import { IPractitioner, IPractitionerInfo } from "src/app/models/practitioner.interface";
import { Practitioner } from "src/app/models/practitioner.model";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

export interface PostPractitionerBody {
  userInfo: unknown;
  practitioner: IPractitioner;
  practitionerFields?: unknown;
}

@Injectable({
  providedIn: "root",
})
export class PractitionerApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Practitioners", // Theme !
      ["practitioner", "dashboard/resetPractitionerPassword", "dashboard/sendPractitionerPassword"], // Post route paths
      ["practitioners", "dashboard/userInfo", "dashboard/practitionerFromDirectory", "resetOwnPassword"], // Get route paths
      ["practitioner", "ownPractitionerInfos"], // Update route paths
      [] // Delete route paths
    );
  }

  public list(): Observable<Practitioner[]> {
    return this.api.get(this.readRoutes[0]) as Observable<Practitioner[]>;
  }

  public update(body: PostPractitionerBody): Observable<Practitioner> {
    return this.api.put(this.updateRoutes[0], body) as Observable<Practitioner>;
  }
  public updateOwnInfos(body: PostPractitionerBody): Observable<Practitioner> {
    return this.api.put(this.updateRoutes[1], body) as Observable<Practitioner>;
  }

  public create(body: PostPractitionerBody): Observable<Practitioner> {
    return this.api.post(this.createRoutes[0], body) as Observable<Practitioner>;
  }

  public listForService(service?: string, services?: string[]): Observable<Practitioner[]> {
    const params: Record<string, string | number | boolean | string[] | Identifier> = {};
    if (Tools.isDefined(services) && !ArrayHelper.isEmpty(services)) {
      params.services = services.join();
    }
    if (Tools.isDefined(service)) {
      params.service = service;
    }
    return this.api.get(this.readRoutes[0], params) as Observable<Practitioner[]>;
  }

  public getPractitionerInfo(caremateId: string): Observable<SlimUserInfo> {
    return this.api.get(this.readRoutes[1], { caremateId: caremateId }) as Observable<SlimUserInfo>;
  }

  public resetPractitionerPassword(caremateId: string, password: string): Observable<Record<string, never>> {
    return this.api.post(this.createRoutes[1], {
      caremateIdentifier: caremateId,
      newPassword: password,
    }) as Observable<Record<string, never>>;
  }

  public sendPractitionerPassword(mail: string, password: string): Observable<Record<string, never>> {
    return this.api.post(this.createRoutes[2], {
      mail,
      password,
    }) as Observable<Record<string, never>>;
  }

  public getPractitionersFromDirectory(family: string, given: string, country: string, msSante?: string): Observable<IPractitionerInfo[]> {
    return msSante
      ? (this.api.get(this.readRoutes[2], { country, msSante }) as Observable<IPractitionerInfo[]>)
      : (this.api.get(this.readRoutes[2], { country, family, given }) as Observable<IPractitionerInfo[]>);
  }

  public resetOwnPassword(login: string, oldPassword: string, newPassword: string): Observable<AppError> {
    return this.api.get(this.readRoutes[3], { login: login, oldPassword: oldPassword, newPassword: newPassword }) as Observable<AppError>;
  }
}
