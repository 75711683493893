import { Pipe, PipeTransform } from "@angular/core";
import { Patient } from "../models/patient.interface";
import { PatientService } from "../providers/patient.service";

@Pipe({ name: "getPatientFullName" })
export class GetPatientFullNamePipe implements PipeTransform {
  constructor(private patientService: PatientService) {}

  transform(patient: Patient): string {
    return this.patientService.getFullname(patient);
  }
}
