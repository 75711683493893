import { Observable } from "rxjs";
import { MatTableDataSourceBackendExtended } from "src/app/helpers/datasource-backend";
import { ICareplanInfo } from "src/app/models/careplans.interface";
import { DataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { CareplansApiService } from "src/app/providers/api/careplans-api.service";

export class CareplansDataSource extends MatTableDataSourceBackendExtended<ICareplanInfo> {
  constructor(private careplansApi: CareplansApiService) {
    super();
  }

  public data(params: DataSourceBackendParam): Observable<ICareplanInfo[]> {
    return this.careplansApi.findCareplans(params) as Observable<ICareplanInfo[]>;
  }
}
