export const template = `
<h5>%DATE_TITlE% : %DATE_VALUE%</h5>
%SCORES%
<div>
    %P_DATAS%
</div>
<style>
    .qTable {
        border-collapse: collapse;
    }
    .qTHead {
        margin-top: 20px !important;
    }
    .qTh,
    .qTd {
        border: 1px solid black;
    }
    h5 {
        text-align: right;
    }
    .fullW {
        width: 100%;
    }
    .scores-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: stretch;
        max-height: 300px;
        margin-bottom: 15px;
    }
    .scores {
        flex-grow: 1;
        min-width: 240px;
        margin: 0px 8px 0px 8px;
    }
    .no-margin {
        margin: 0px !important;
    }
    .pl-10 {
        padding-left: 10px;
    }
</style>
`;

export const p_datas = `<p>%Topic_VAL% %Question_VAL% : %Answer_VAL%</p>`;

export const scores = `
<h4 class="no-margin">Scores :</h4>
<div class="scores-container">
    %SCORES_TABLE%
</div>
<hr>
`;

export const scoresTable = `
<table class="qTable scores">
    <h4 class="pl-10">%CAT_NAME%</h4>
        <thead class="qTHead">
            <tr>
                <th class="qTh">%SCORE_NAME_TITLE%</th>
                <th class="qTh">%SCORE_VALUE_TITLE%</th>
                <th class="qTh">%SCORE_INTERPRETATION_TITLE%</th>
            </tr>
        </thead>
        <tbody>
            %TR_SCORES%
        </tbody>
</table>
`;

export const trScores = `
<tr>
    <td class="qTd">%SCORE_NAME_VAL%</td>
    <td class="qTd">%SCORE_VAL%</td>
    <td class="qTd">%SCORE_INTERPRETATION%</td>
</tr>
`;
