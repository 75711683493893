import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { Tools } from "src/app/helpers/tools";
import { IRuleAlert } from "src/app/models/rule-alert.interface";
import { RuleAlert } from "src/app/models/rule-alert.model";
import { IRule } from "src/app/models/rule.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class RuleAlertApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "RuleAlerts", // Theme !
      ["dashboard/rule"], // Post route paths
      ["rulealerts", "dashboard/lastNoDataTransmissionRuleAlert"], // Get route paths
      ["rulesAlert"], // Put route paths
      [] // Delete route paths
    );
  }

  public list(
    alertLevel: number,
    services?: string[],
    monitoringServicesIds?: string[],
    onlyNoDataTransmissionAlert = false
  ): Observable<IRuleAlert[]> {
    const params: {
      alert: number;
      onlyNoDataTransmissionAlert: boolean;
      services?: string;
      monitoringServicesIds?: string;
    } = { alert: alertLevel, onlyNoDataTransmissionAlert };
    if (Tools.isDefined(services) && !ArrayHelper.isEmpty(services)) {
      params.services = JSON.stringify(services);
    }
    if (Tools.isDefined(monitoringServicesIds) && !ArrayHelper.isEmpty(monitoringServicesIds)) {
      params.monitoringServicesIds = monitoringServicesIds.join();
    }
    return this.api.get(this.readRoutes[0], params) as Observable<IRuleAlert[]>;
  }

  public listForPatient(patientId: string, alertsLevels?: string[]): Observable<IRuleAlert[]> {
    return this.api.get(this.readRoutes[0], {
      identifier: patientId,
      alert: alertsLevels ? alertsLevels.join(",") : undefined,
    }) as Observable<IRuleAlert[]>;
  }

  public save(ruleAlert: RuleAlert): Observable<IRuleAlert[]> {
    return this.api.put(this.updateRoutes[0], ruleAlert) as Observable<IRuleAlert[]>;
  }

  public listNoDataTransmission(caremateId: string): Observable<RuleAlert> {
    return this.api.get(this.readRoutes[1], {
      caremateId,
    }) as Observable<RuleAlert>;
  }

  /**
   * Creates a new rule
   * @param rule The new rule to save as an IRule
   * @returns The saved rule
   */
  public createRule(rule: IRule): Observable<IRule> {
    return this.api.post(this.createRoutes[0], rule) as Observable<IRule>;
  }
}
