import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTabGroup } from "@angular/material/tabs";
import { IKnowMedia } from "src/app/models/knowledge.interface";

@Component({
  selector: "app-knowledge-overview",
  templateUrl: "./knowledge-overview.component.html",
  styleUrls: ["./knowledge-overview.component.scss"],
})
export class KnowledgeOverviewComponent implements OnInit {
  @ViewChild("tabGroup") tabGroup: MatTabGroup;
  public media: IKnowMedia;
  public category: number;
  public slides: string[];
  public selectedIndex = 0;
  public summary: string[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { media: IKnowMedia; modaleMode?: boolean }) {}

  ngOnInit(): void {
    this.media = this.data.media;
    if (this.media) {
      this.createSlides(this.media?.content);
      this.createSummary(this.slides);
    }
  }

  private createSlides(content: string) {
    const reg = /<hr id=(?:"|')(?:null|(?:[0-9]))(?:"|').*?>/;
    this.slides = content?.split(reg);
  }

  private createSummary(_slide: string[]) {
    this.summary = this.slides.map((value) => {
      const reg = /<h1[^>]*>(.*?)<\/h1>/;
      return value.match(reg) ? value.match(reg)[1] : null;
    });

    // delete null values from the array to avoid showing the "summary" when no h1 markup are founds but <hr> are presents
    // (ex : bilan cancer vads uses h2 instead of h1 and summary is not present on the mobile-app)
    this.summary = this.summary.filter((value) => value !== null);
  }
}
