import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { Subject, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DataType, Filter } from "src/app/models/filter.interface";
import { PatientUser } from "src/app/models/patient.interface";
import { PreferenceContext, WidgetPatientParameter } from "src/app/models/preference.interface";
import { IPredictionStatus, IRiskAssessment, PredictionState } from "src/app/models/riskAssessment.interface";
import { RiskAssessment } from "src/app/models/riskAssessment.model";
import { DocumentService } from "src/app/providers/document.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { RiskAssessmentService } from "src/app/providers/risk-assessments-api.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { Item } from "../item-selector/item-selector.component";
import { RiskAssessmentStore } from "./application/risk-assessment.store";
import { PatientRiskAssessmentListDataSource } from "./patient-risk-assessment-list-datasource";
import { PredictionModelImportComponent } from "./prediction-model-import/prediction-model-import.component";
import { RiskAssessmentDetailsComponent } from "./risk-assessment-details/risk-assessment-details.component";

@Component({
  selector: "app-patient-risk-assessment",
  templateUrl: "./patient-risk-assessment.component.html",
  styleUrls: ["./patient-risk-assessment.component.scss"],
})
export class PatientRiskAssessmentComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() set patientUser(pu: PatientUser) {
    if (pu?.user?.caremateIdentifier) {
      this.raStore.clearRiskAssessments();
      this.pu = pu;
      this.loadDataSource();
      this.loadPreferences();
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<RiskAssessment | IPredictionStatus>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("focus") target: ElementRef;
  public displayedColumns: string[] = ["risk", "date", "gauge", "action"];
  public dataSource: PatientRiskAssessmentListDataSource;
  public data: (RiskAssessment | IPredictionStatus)[];
  private currentPageSize: number;
  public sliderData: Item[] = [];
  public showDraft = true;
  public pu: PatientUser;
  public PredictionState = PredictionState;
  /**
   * Filter
   */
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public globalSearchValue = "";
  public loading = true;
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();

  constructor(
    private preferenceService: PreferenceService,
    public translateService: TranslateService,
    private raService: RiskAssessmentService,
    private userService: UserService,
    private dialog: MatDialog,
    private sessionService: SessionService,
    private raStore: RiskAssessmentStore,
    private documentService: DocumentService
  ) {}

  ngOnInit(): void {
    this.createDataSource();
    this.sessionService.refreshServerTraductions.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      // this is needed to make the pipes realize there's something that changed
      if (this.data) {
        this.loadDataSource();
      }
    });

    timer(60000, 60000)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.loadDataSource();
      });
  }

  ngAfterViewInit(): void {
    this.initPaginatorAndSort();
  }

  ngOnDestroy(): void {
    this.raStore.clearRiskAssessments();
    this.dataSource.clear();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private createDataSource() {
    if (!this.dataSource) {
      this.dataSource = new PatientRiskAssessmentListDataSource(this.translateService);
    }
  }

  public initPaginatorAndSort(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;

    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((page) => {
      if (this.currentPageSize !== page.pageSize) {
        this.updatePreference();
      }
      this.currentPageSize = this.paginator.pageSize;
    });
  }

  public loadDataSource(): void {
    this.createDataSource();
    this.userService
      .isAuthorized("riskAssessments", "GET")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isAuth) => {
        if (!isAuth) {
          return;
        }
        this.raStore.loadRiskAssessments(this.pu.user.caremateIdentifier);
        this.raStore.loading$.pipe(takeUntil(this.onDestroy$)).subscribe((loading) => {
          this.loading = loading;
        });
        this.raStore.raDatas$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
          this.dataSource.data = data;
          this.data = data;
        });
      });
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
  }

  public updatePreference(): void {
    this.preferenceService
      .update({
        context: PreferenceContext.WIDGET_PATIENT_RISK_ASSESSMENT,
        parameters: {
          filters: this.dataSource.getAllFilters(),
        } as WidgetPatientParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  private loadPreferences() {
    this.preferenceService
      .list(PreferenceContext.WIDGET_PATIENT_RISK_ASSESSMENT)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: WidgetPatientParameter) => {
        if (parameters) {
          // Apply saved filters
          parameters.filters.forEach((filter: Filter) => {
            this.applyFilter(filter);
          });
        }
      });
  }

  public viewDetails(risk: RiskAssessment): void {
    this.dialog.open(RiskAssessmentDetailsComponent, {
      data: { risk },
      maxWidth: "95vw",
      width: "100%",
      disableClose: true,
    });
  }

  public openPdf(risk: IRiskAssessment): void {
    this.raService.openPdf(risk, this.translateService.currentLang, this.translateService.instant("api.errors.pdf-error"));
  }

  public addPrediction(): void {
    this.dialog
      .open(PredictionModelImportComponent, { data: { patientId: this.pu.user.caremateIdentifier }, disableClose: true })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((saved: boolean) => {
        if (saved) {
          this.loadDataSource();
        }
      });
  }

  public downloadModel(risk: IRiskAssessment): void {
    const url = risk.basis.find((b) => b.type === "genetic").reference;
    const name = risk.identifier[0].value;
    this.documentService.downloadFromCAnalytics(url, name, "text/csv");
  }

  public downloadResultAsZip(risk: IRiskAssessment): void {
    const url = risk.basis.find((b) => b.type === "result").reference;
    const name = risk.identifier[0].value;
    this.documentService.downloadFromCAnalytics(url, name, "application/zip");
  }
}
