export interface IMaintenance {
  _id?: string;
  creation?: string;
  plannedStart: string;
  estimatedEnd: string;
  message?: string;
  maintenanceType: MaintenanceType;
  maintenanceStatus: MaintenanceStatus;
}
export enum MaintenanceType {
  CLASSIC = "classic",
  HOTFIX = "hotfix",
}
export enum MaintenanceStatus {
  PLANNED = "planned",
  CANCELED = "cancelled",
  ONGOING = "ongoing",
  FINISHED = "finished",
}
