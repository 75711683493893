<div class="wrapper">
  <!-- {{showDirectly}} -->
  <div class="container">
    <mat-slide-toggle
      *ngIf="needShowDirectly"
      [(ngModel)]="showDirectly"
      name="noEndDate"
      (change)="setShowDirectly()"
      [disabled]="isDisabled"
    >
      <div [ngSwitch]="true" [ngStyle]="{ opacity: !showDirectly ? '50%' : '100%' }">
        <span *ngSwitchCase="context === action_target.COMMUNICATION">{{
          "page.careplanEditor.tabs.communications.showDirectly" | translate
        }}</span>
        <span *ngSwitchCase="context === action_target.QUESTIONNAIRE || context === action_target.OBSERVATION">
          {{ "page.careplanEditor.tabs.showDirectly" | translate }}
        </span>
        <span *ngSwitchDefault>{{ "knowledgebase.knowledgeCriteria.showDirectly" | translate }}</span>
      </div>
    </mat-slide-toggle>
  </div>
  <div class="container" *ngIf="!showDirectly">
    <ng-container [ngSwitch]="true">
      <p *ngSwitchCase="context === action_target.COMMUNICATION">{{ "btn.send" | translate }}</p>
      <p *ngSwitchCase="context === 'careplanEndDate'">{{ "schedule.dateTo" | translate }} :</p>
      <p *ngSwitchCase="context === action_target.QUESTIONNAIRE || context === action_target.OBSERVATION" class="w-full mt-1">
        {{ "page.careplanEditor.tabs.show" | translate }} :
      </p>
      <p *ngSwitchDefault>{{ "knowledgebase.knowledgeCriteria.show" | translate }}</p>
    </ng-container>

    <!--{{period}}  -->
    <mat-form-field>
      <input matInput type="number" min="0" [(ngModel)]="periodDisplayed" (change)="setPeriod()" [disabled]="isDisabled || showDirectly" />
    </mat-form-field>

    <!-- {{periodUnit}} -->
    <mat-form-field>
      <mat-select
        [(value)]="periodUnit"
        (selectionChange)="setPeriodUnit()"
        [disabled]="isDisabled || showDirectly"
        [compareWith]="compareFn"
      >
        <mat-option *ngFor="let unit of availablePeriodUnits" [value]="unit.value">
          {{ unit.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- {{beforeAfter}} -->
    <mat-form-field>
      <mat-select [(value)]="beforeAfter" (selectionChange)="setPeriod()" [disabled]="isDisabled || showDirectly || onlyAfter">
        <mat-option *ngFor="let el of ['after', 'before']" [value]="el">
          {{ "careplan." + el | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <p *ngIf="!hasSelectedActivity" [ngStyle]="showDirectly ? { opacity: '50%' } : null">
      {{ "knowledgebase.knowledgeCriteria.beginningCareplan" | translate }}.
    </p>
    <p *ngIf="hasSelectedActivity" [ngStyle]="showDirectly ? { opacity: '50%' } : null">
      {{ "knowledgebase.knowledgeCriteria.beginningActivity" | translate }}.
    </p>
  </div>

  <div class="container" *ngIf="context === action_target.COMMUNICATION">
    <p>{{ "page.careplanEditor.tabs.communications.showTiming" | translate }}</p>
    <!-- {{TimingCode}} -->
    <mat-form-field>
      <mat-select [(value)]="timingCode" (selectionChange)="setTimingCode()" [disabled]="isDisabled">
        <mat-option *ngFor="let el of availableTimingCodes" [value]="el.value">
          {{ "careplan.timingCodes." + el.display | translate | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- {{SkipFirst}} -->
    <div *ngIf="needSkipFirst">
      <mat-checkbox [checked]="skipFirst" (change)="setSkipFirst($event.checked)">
        {{ "page.careplanEditor.tabs.communications.skipFirst" | translate }}
      </mat-checkbox>
    </div>
  </div>
</div>
