import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Authentification, MFA_TYPE } from "./../../models/authentification.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class AuthApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Authentication", // Theme !
      ["mfa/enable-dashboard", "mfa/disable", "mfa/confirm", "authenticate_dashboard", "authenticate2fa"], // Post route paths
      ["passwordreset"], // Get route paths
      [], // Update route paths
      [] // Delete route paths
    );
  }

  public authenticate(email: string, password: string): Observable<Authentification> {
    return this.api.post(this.createRoutes[3], {
      login: email,
      password,
    }) as Observable<Authentification>;
  }

  public authenticate2FA(token: string, code: string) {
    const body = { token2fa: token, code };
    return this.api.post(this.createRoutes[4], body);
  }

  public getEHealthRedirectUrl(): Observable<string> {
    return this.api.get("free/dashboard/startEHealthAuth") as Observable<string>;
  }

  public authenticateANS(code: string): Observable<Authentification> {
    return this.api.get("free/dashboard/authANS", { code: code }) as Observable<Authentification>;
  }
  public logoutANS(): Observable<string> {
    return this.api.get("free/dashboard/ANSLogoutRedirect") as Observable<string>;
  }

  public redirectToANS(): Observable<string> {
    return this.api.get("free/dashboard/ANSAuthRedirect") as Observable<string>;
  }

  public resetPassword(identifier: string) {
    return this.api.get(this.readRoutes[0], {
      identifier,
    });
  }

  public enable2FA(chosenMfaType: MFA_TYPE): Observable<{ secret: string; qrCodeUrl: string }> {
    const body = { mfaType: chosenMfaType };
    return this.api.post(this.createRoutes[0], body) as Observable<{
      secret: string;
      qrCodeUrl: string;
    }>;
  }

  public disable2FA() {
    return this.api.post(this.createRoutes[1], {});
  }

  public confirm2FA(code: string): Observable<boolean> {
    return this.api.post(this.createRoutes[2], { code }) as Observable<boolean>;
  }
}
