import { Injectable } from "@angular/core";
import { IMaintenance } from "src/app/models/maintenance.interface";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class MaintenanceApiService {
  constructor(private api: ApiService) {}

  public getFutureMaintenances(): Promise<IMaintenance[]> {
    return this.api.get("futureMaintenances", null, true).toPromise() as Promise<IMaintenance[]>;
  }
}
