import { Filter } from "../models/filter.interface";

export class FilterHelper {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private _filters: Filter[] = [];

  get filters(): Filter[] {
    return this._filters;
  }

  public setFilter(filter: Filter): void {
    const existingFilterIndex = this._filters.findIndex((f) => f.propertyName === filter.propertyName);
    if (filter?.data) {
      if (existingFilterIndex >= 0) {
        this._filters[existingFilterIndex].data = filter.data;
      } else {
        this._filters.push(filter);
      }
    } else if (existingFilterIndex >= 0) {
      this._filters.splice(existingFilterIndex, 1);
    }
  }

  public getFilter(propertyName: string): Filter {
    return this._filters.find((f) => f.propertyName === propertyName);
  }

  public clearFilter(): void {
    this._filters = [];
  }
}
