import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { QuestionQuestionnaire } from "src/app/models/questionnaire.interface";

@Component({
  selector: "app-date-input",
  templateUrl: "./date-input.component.html",
  styleUrls: ["./date-input.component.scss"],
})
export class DateInputComponent implements OnDestroy {
  @Input() disabled = false;
  @Input() set question(q: QuestionQuestionnaire) {
    this.dateQuestion = q;
    this.setupDateVariables();
  }
  @Output() questionChange = new EventEmitter<QuestionQuestionnaire>();

  public dateForm: UntypedFormGroup;
  public dateQuestion: QuestionQuestionnaire;
  private onDestroy$ = new Subject<void>();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Setup all the variables needed for the Date input
   */
  private setupDateVariables() {
    const initialDate = this.dateQuestion.answerDisplay.default ? moment(this.dateQuestion.answerDisplay.default, "DD/MM/YYYY") : null;
    const minDate = this.dateQuestion.answerDisplay.min ? moment(this.dateQuestion.answerDisplay.min, "DD/MM/YYYY") : null;
    const maxDate = this.dateQuestion.answerDisplay.max ? moment(this.dateQuestion.answerDisplay.max, "DD/MM/YYYY") : null;
    let placeholder = "";
    if (this.dateQuestion.answerDisplay.placeholder) {
      placeholder = this.dateQuestion.answerDisplay.placeholder;
    }
    this.dateForm = this.fb.group({
      initialDate: [initialDate, []],
      minDate: [minDate, []],
      maxDate: [maxDate, []],
      placeholder: [placeholder, []],
    });
    this.dateForm
      .get("initialDate")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        const initial = val ? moment(val, "DD/MM/YYYY") : null;
        const min = this.dateForm.get("minDate").value ? moment(this.dateForm.get("minDate").value, "DD/MM/YYYY") : null;
        const max = this.dateForm.get("maxDate").value ? moment(this.dateForm.get("maxDate").value, "DD/MM/YYYY") : null;
        this.dateChanged(min, max, initial);
      });
    this.dateForm
      .get("minDate")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        const initial = this.dateForm.get("initialDate").value ? moment(this.dateForm.get("initialDate").value, "DD/MM/YYYY") : null;
        const min = val ? moment(val, "DD/MM/YYYY") : null;
        const max = this.dateForm.get("maxDate").value ? moment(this.dateForm.get("maxDate").value, "DD/MM/YYYY") : null;
        this.dateChanged(min, max, initial);
      });
    this.dateForm
      .get("maxDate")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        const initial = this.dateForm.get("initialDate").value ? moment(this.dateForm.get("initialDate").value, "DD/MM/YYYY") : null;
        const min = this.dateForm.get("minDate").value ? moment(this.dateForm.get("minDate").value, "DD/MM/YYYY") : null;
        const max = val ? moment(val, "DD/MM/YYYY") : null;
        this.dateChanged(min, max, initial);
      });
    if (this.disabled) {
      this.dateForm.disable();
    }
  }

  private dateChanged(minDate: moment.Moment, maxDate: moment.Moment, initialDate: moment.Moment) {
    if (minDate && maxDate && minDate > maxDate) {
      minDate = maxDate;
      this.dateForm.patchValue({ minDate });
    }
    if (initialDate && minDate && initialDate < minDate) {
      initialDate = minDate;
      this.dateForm.patchValue({ initialDate });
    }
    if (initialDate && maxDate && initialDate > maxDate) {
      initialDate = maxDate;
      this.dateForm.patchValue({ initialDate });
    }
    this.emitQuestionChanges();
  }

  public emitQuestionChanges(): void {
    this.dateQuestion.answerDisplay.placeholder = this.dateForm.get("placeholder").value;
    const initialDate = this.dateForm.get("initialDate").value ? moment(this.dateForm.get("initialDate").value).format("DD/MM/YYYY") : null;
    const minDate = this.dateForm.get("minDate").value ? moment(this.dateForm.get("minDate").value).format("DD/MM/YYYY") : null;
    const maxDate = this.dateForm.get("maxDate").value ? moment(this.dateForm.get("maxDate").value).format("DD/MM/YYYY") : null;

    if (initialDate) {
      this.dateQuestion.answerDisplay.default = initialDate;
    }
    if (minDate) {
      this.dateQuestion.answerDisplay.min = minDate;
    }
    if (maxDate) {
      this.dateQuestion.answerDisplay.max = maxDate;
    }
    this.questionChange.emit(this.dateQuestion);
  }

  public isValid(): boolean {
    const initial = this.dateForm.get("initialDate").value;
    const min = this.dateForm.get("minDate").value;
    const max = this.dateForm.get("maxDate").value;
    if (initial && !moment(initial).isValid()) {
      return false;
    }
    if (min && !moment(min).isValid()) {
      return false;
    }
    if (max && !moment(max).isValid()) {
      return false;
    }
    return this.dateForm.valid;
  }
}
