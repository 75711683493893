import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IAppointment } from "src/app/models/appointment.interface";
import { Appointment } from "src/app/models/appointment.model";
import { IPatientAppointement } from "src/app/models/patient-appointement.interface";
import { HealthcareserviceService } from "../healthcareservice.service";
import { SessionService } from "../session.service";
import { UserService } from "../user.service";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class AppointmentApiService extends BaseApiService {
  constructor(
    private api: ApiService,
    private sessionService: SessionService,
    private healthcareService: HealthcareserviceService,
    private userService: UserService
  ) {
    super(
      "Appointments", // Theme !
      ["appointment"], // Post route paths
      ["appointmentsPractitioner", "patientsAppointments"], // Get route paths
      ["appointment"], // Update route paths
      [] // Delete route paths
    );
  }

  public list(): Observable<IAppointment[]> {
    const params = {
      language: this.sessionService.userLang,
    };
    return this.api.get(this.readRoutes[0], params) as Observable<IAppointment[]>;
  }

  public listVisioAppointements(patientId: string, allAppointments?: boolean): Observable<IAppointment[]> {
    const params = { patientId: patientId, allAppointments: allAppointments, language: this.sessionService.userLang };
    return this.api.get(this.readRoutes[0], params) as Observable<IAppointment[]>;
  }

  public getPatientAppointement(name: string): Observable<IPatientAppointement[]> {
    let currentServiceRef = this.sessionService.currentService;
    if (currentServiceRef.reference === this.sessionService.allsOption) {
      FileLogger.warn("AppointmentApiService", 'You are trying to get patients for appointment with service "all", result gonna be wonky');
      currentServiceRef = this.healthcareService.availableServices()[0].asReference;
    }
    const params = {
      name,
      serviceRef: currentServiceRef.reference,
      monitoringServicesIds: null,
    };
    if (this.userService.isMonitoringUser) {
      const monitServiceRef = this.sessionService.currentMonitoringService;
      const monitRefs: string[] = [];
      if (monitServiceRef.reference === this.sessionService.allsOption) {
        monitRefs.push(...this.healthcareService.availableMonitoringServices().map((s) => s.serviceRef));
      } else {
        monitRefs.push(monitServiceRef.reference);
      }
      params.monitoringServicesIds = monitRefs.join();
    }
    return this.api.get(this.readRoutes[1], params) as Observable<IPatientAppointement[]>;
  }

  public update(app: IAppointment): Observable<Appointment> {
    return this.api.put(this.updateRoutes[0], app) as Observable<Appointment>;
  }

  public createVisioAppointement(app: IAppointment): Observable<Appointment> {
    return this.api.post(this.createRoutes[0], app) as Observable<Appointment>;
  }
}
