import { Injectable } from "@angular/core";
import { IStatEvent, IStatSummary } from "../models/userStatistics.interface";
import { UserStatisticsApiService } from "./api/userStatistics-api.service";
import { HealthcareserviceService } from "./healthcareservice.service";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class UserStatisticsService {
  constructor(
    private statApi: UserStatisticsApiService,
    private sessionService: SessionService,
    private healthcareService: HealthcareserviceService
  ) {}

  public async listSummaries(from: string, to: string): Promise<IStatSummary[]> {
    if (!this.sessionService.isLoggedIn) {
      return [];
    }
    return this.statApi.listSummaries(from, to).toPromise();
  }
  public async updateStatSummary(statToIncrement: string): Promise<IStatSummary> {
    if (!this.sessionService.isLoggedIn) {
      return null;
    }
    return this.statApi.updateStatSummary(statToIncrement).toPromise();
  }

  public async createStatEvent(eventDescription: string, servicesIds?: string[], careplansIds?: string[]): Promise<IStatEvent> {
    if (!this.sessionService.isLoggedIn || this.sessionService.isAdmin()) {
      return null;
    }
    let services: string[] = servicesIds;
    if (!services) {
      services =
        this.sessionService?.currentService?.reference && this.sessionService.currentService.reference !== this.sessionService.allsOption
          ? [this.sessionService.currentService.reference]
          : this.healthcareService.availableServices().map((s) => s.asReference.reference);
    }
    return this.statApi.createStatEvent(eventDescription, services, careplansIds).toPromise();
  }
}
