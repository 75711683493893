import { ErrorHandler, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { SPECIFIC_USE } from "src/app/models/questionnaire.interface";
import { QuestionnairesService } from "../questionnaires.service";

@Injectable({
  providedIn: "root",
})
export class QuizListResolver implements Resolve<{ count: number }> {
  constructor(private questionnaireApiService: QuestionnairesService, private globalErrorHandler: ErrorHandler) {}

  resolve(route: ActivatedRouteSnapshot): Observable<{ count: number }> {
    const specificUse: SPECIFIC_USE = route.data.specificUse;
    return this.questionnaireApiService.getSpecificQuestionnaireInfosCount(specificUse, undefined, undefined).pipe(
      catchError((err) => {
        return throwError(this.globalErrorHandler.handleError(err));
      })
    );
  }
}
