import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { PermissionGuard } from "./guards/permission.guard";
import { RouteData } from "./models/route-data.interface";
import { CommunicationsPageComponent } from "./pages/communications-page/communications-page.component";
import { DashboardPageComponent } from "./pages/dashboard-page/dashboard-page.component";
import { DrugSchemaPageComponent } from "./pages/drug-schema-page/drug-schema-page.component";
import { GroupsListPageComponent } from "./pages/groups-list-page/groups-list-page.component";
import { KnowledgeDetailsPageComponent } from "./pages/knowledge-details-page/knowledge-details-page.component";
import { KnowledgeListPageComponent } from "./pages/knowledge-list-page/knowledge-list-page.component";
import { KnowledgeMediaEditorComponent } from "./pages/knowledge-media-editor/knowledge-media-editor.component";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { MyServicePageComponent } from "./pages/my-service-page/my-service-page.component";
import { OrganizationListPageComponent } from "./pages/organization-list-page/organization-list-page.component";
import { PatientListPageComponent } from "./pages/patient-list-page/patient-list-page.component";
import { PatientPageComponent } from "./pages/patient-page/patient-page.component";
import { QuestionnaireListPageComponent } from "./pages/questionnaire-list-page/questionnaire-list-page.component";
import { RewardsPageComponent } from "./pages/rewards-page/rewards-page.component";
import { TeleconsultationsPageComponent } from "./pages/teleconsultation-page/teleconsultation-page.component";
import { KnowledgeResolver } from "./providers/resolvers/knowledge.resolver";
import { KnowledgeDetailsResolver } from "./providers/resolvers/knowledgeDetails.resolver";
// eslint-disable-next-line max-len
import { CareplansListPageComponent } from "./careplan-editor/views/careplans-list-page/views/careplans-list-page/careplans-list-page.component";
import { LoginGuard } from "./guards/login.guard";
import { PreferenceContext } from "./models/preference.interface";
import { SPECIFIC_USE } from "./models/questionnaire.interface";
import { AccessGroupsPageComponent } from "./pages/access-groups-page/access-groups-page.component";
import { CorrespondantPageComponent } from "./pages/correspondant-page/correspondant-page.component";
import { DataExtractionComponent } from "./pages/data-extraction/data-extraction.component";
import { DeleteRequestListPageComponent } from "./pages/delete-request-list-page/delete-request-list-page.component";
import { ErrorPageComponent } from "./pages/error-page/error-page.component";
import { FhirQuestionnaireListPageComponent } from "./pages/fhir-questionnaire-list-page/fhir-questionnaire-list-page.component";
import { IdentityVerifMethodsPageComponent } from "./pages/identity-verif-methods-page/identity-verif-methods-page.component";
import { JournalInsiPageComponent } from "./pages/journal-insi-page/journal-insi-page.component";
import { NotFoundPageComponent } from "./pages/not-found-page/not-found-page.component";
import { SimplePatientListPageComponent } from "./pages/patient-list-page/simple-patient-list-page/simple-patient-list-page.component";
import { QuestionnaireEditorPageComponent } from "./pages/questionnaire-editor-page/questionnaire-editor-page.component";
import { QuizListPageComponent } from "./pages/quiz-list-page/quiz-list-page.component";
import { StatEventsPageComponent } from "./pages/statistics-pages/stat-events-page/stat-events-page.component";
import { StatSummaryPageComponent } from "./pages/statistics-pages/stat-summary-page/stat-summary-page.component";
import { TemplateQuestionnaireListPageComponent } from "./pages/template-questionnaire-list-page/template-questionnaire-list-page.component";
import { UserPageComponent } from "./pages/user-page/user-page.component";
import { DeleteRequestResolver } from "./providers/resolvers/deleteRequest.resolver";
import { PatientsCountResolver } from "./providers/resolvers/patient.resolver";
import { PreferenceResolver } from "./providers/resolvers/preferences.resolver";
import { QuizListResolver } from "./providers/resolvers/quizList.resolver";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  { path: "login", component: LoginPageComponent, canActivate: [LoginGuard] },
  {
    path: "dashboard",
    component: DashboardPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: true,
    } as RouteData,
  },
  {
    path: "patients",
    component: PatientListPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    resolve: {
      patientsCount: PatientsCountResolver,
      preferences: PreferenceResolver,
    },
    data: {
      canBeLanding: true,
      ctx: PreferenceContext.PATIENT_LIST,
    } as RouteData,
  },
  {
    path: "simplePatientsList",
    component: SimplePatientListPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    resolve: {
      patientsCount: PatientsCountResolver,
      preferences: PreferenceResolver,
    },
    data: {
      canBeLanding: true,
      ctx: PreferenceContext.PATIENT_LIST,
    } as RouteData,
  },
  // { path: "preference", component: PreferencePageComponent, canActivate: [AuthGuard, PermissionGuard], data: {
  //         canBeLanding: true,
  //         featureGuardKey: PermissionFeatures.DASHBOARD_MENU_PREFERENCE
  //     } as RouteData
  // },
  {
    path: "accessGroups",
    component: AccessGroupsPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: true,
    } as RouteData,
  },
  {
    path: "patient",
    component: PatientPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    resolve: {
      patientsCount: PatientsCountResolver,
    },
    data: {
      canBeLanding: false,
    } as RouteData,
  },
  {
    path: "teleconsultations",
    component: TeleconsultationsPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: true,
    } as RouteData,
  },
  {
    path: "organizations",
    component: OrganizationListPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: true,
    } as RouteData,
  },
  {
    path: "correspondant",
    component: CorrespondantPageComponent,
    canActivate: [AuthGuard],
    data: {
      canBeLanding: true,
    } as RouteData,
  },
  {
    path: "myService",
    component: MyServicePageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: true,
    } as RouteData,
  },
  {
    path: "groups",
    component: GroupsListPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: false,
    } as RouteData,
  },
  {
    path: "questionnaireList",
    component: QuestionnaireListPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: true,
    } as RouteData,
  },
  {
    path: "communications",
    component: CommunicationsPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: true,
    } as RouteData,
  },
  {
    path: "rewardsPage",
    component: RewardsPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: false,
    } as RouteData,
  },
  {
    path: "templatesQuestionnaire",
    component: TemplateQuestionnaireListPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: false,
    } as RouteData,
  },
  {
    path: "fhirQuestionnaireList",
    component: FhirQuestionnaireListPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    resolve: {
      preferences: PreferenceResolver,
    },
    data: {
      canBeLanding: false,
      ctx: PreferenceContext.FHIR_QUESTIONNAIRE_PAGE,
    } as RouteData,
  },
  {
    path: "questionnaireEditor",
    component: QuestionnaireEditorPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: false,
    } as RouteData,
  },
  {
    path: "drugSchema",
    component: DrugSchemaPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: false,
    } as RouteData,
  },
  {
    path: "knowledgeList",
    component: KnowledgeListPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    resolve: {
      knowledgeCount: KnowledgeResolver,
      preferences: PreferenceResolver,
    },
    data: {
      canBeLanding: true,
      ctx: PreferenceContext.KNOWLEDGE_LIST,
    } as RouteData,
  },
  {
    path: "knowledgeDetails",
    component: KnowledgeDetailsPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    resolve: {
      knowledgeData: KnowledgeDetailsResolver,
    },
    data: {
      canBeLanding: false,
    } as RouteData,
  },
  {
    path: "knowledgeMediaEditor",
    component: KnowledgeMediaEditorComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: false,
    } as RouteData,
  },
  {
    path: "quizList",
    component: QuizListPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    resolve: {
      quizCount: QuizListResolver,
      preferences: PreferenceResolver,
    },
    data: {
      canBeLanding: false,
      specificUse: SPECIFIC_USE.QUIZ,
      ctx: PreferenceContext.QUIZ_PAGE,
    } as RouteData,
  },
  {
    path: "consentList",
    component: QuizListPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    resolve: {
      quizCount: QuizListResolver,
      preferences: PreferenceResolver,
    },
    data: {
      canBeLanding: false,
      specificUse: SPECIFIC_USE.CONSENT,
      ctx: PreferenceContext.CONSENTS_PAGE,
    } as RouteData,
  },
  {
    path: "deleteRequestList",
    component: DeleteRequestListPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    resolve: {
      deleteRequestCount: DeleteRequestResolver,
      preferences: PreferenceResolver,
    },
    data: {
      canBeLanding: true,
      ctx: PreferenceContext.DELETE_REQUEST_PAGE,
    } as RouteData,
  },
  {
    path: "user",
    component: UserPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: false,
    } as RouteData,
  },
  {
    path: "statSummary",
    component: StatSummaryPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: false,
    } as RouteData,
  },
  {
    path: "statEvents",
    component: StatEventsPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    resolve: {
      preferences: PreferenceResolver,
    },
    data: {
      canBeLanding: false,
      ctx: PreferenceContext.STAT_EVENTS_PAGE,
    } as RouteData,
  },
  {
    path: "journalInsi",
    component: JournalInsiPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: false,
    } as RouteData,
  },
  {
    path: "identityVerifMethods",
    component: IdentityVerifMethodsPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: false,
    } as RouteData,
  },
  {
    path: "dataExtraction",
    component: DataExtractionComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      canBeLanding: false,
    } as RouteData,
  },
  {
    path: "careplansList",
    component: CareplansListPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    resolve: {
      preferences: PreferenceResolver,
    },
    data: {
      canBeLanding: true,
      ctx: PreferenceContext.CAREPLAN_LIST,
    } as RouteData,
  },

  { path: "error", component: ErrorPageComponent },
  { path: "**", component: NotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
