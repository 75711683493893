<div class="rewardDetails">
  <div class="close-cross">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-card *ngIf="createAction">
    <h3>{{ "model.rewardsPage.rewardDetails" | translate }}</h3>
    <table class="datatable">
      <thead>
        <th>{{ "model.rewardsPage.objectifStart" | translate }}</th>
        <th>{{ "model.rewardsPage.objectifEnd" | translate }}</th>
        <th>{{ "model.rewardsPage.objectifName" | translate }}</th>
        <th>{{ "model.rewardsPage.objectifProgress" | translate }}</th>
        <th>{{ "table.status" | translate }}</th>
      </thead>
      <tbody>
        <tr>
          <td class="w-200">
            {{ reward.start | date : "dd/MM/yyyy" }}
          </td>
          <td class="w-200">
            {{ reward.end | date : "dd/MM/yyyy" }}
          </td>
          <td style="width: 250px !important">
            {{ rewardDescripion }}
          </td>
          <td class="w-200">
            {{ rewardScore }}
          </td>
          <td class="w-80">
            {{ reward.statusTranslateKey | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card>
  <app-reward-details-edit
    (saved)="close()"
    [patientServiceId]="patientServiceId"
    [reward]="reward"
    [createAction]="createAction"
  ></app-reward-details-edit>
  <app-reward-details-historic *ngIf="createAction" [reward]="reward"></app-reward-details-historic>
</div>
