import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { RewardScore } from "src/app/models/rewardScore.model";

@Component({
  selector: "app-reward-details",
  templateUrl: "./reward-details.component.html",
  styleUrls: ["./reward-details.component.scss"],
})
export class RewardDetailsComponent implements OnInit {
  public reward: RewardScore;
  public createAction: boolean;
  public patientServiceId: string;
  public rewardDescripion: string;
  public rewardScore: string;

  public constructor(
    private readonly dialogRef: MatDialogRef<RewardDetailsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reward: RewardScore;
      createAction: boolean;
      dialogRef;
      description: string;
      score: string;
      patientServiceId: string;
    }
  ) {}

  ngOnInit(): void {
    this.reward = this.data.reward;
    this.rewardDescripion = this.data.description;
    this.rewardScore = this.data.score;
    this.createAction = this.data.createAction;
    this.patientServiceId = this.data.patientServiceId;
  }

  public close(): void {
    this.dialogRef.close();
  }
}
