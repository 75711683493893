import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import moment from "moment";
import { Subject, fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, first, takeUntil, tap } from "rxjs/operators";
import { Choice } from "src/app/components/item-filter/filters/choice-filter/choice-filter.component";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { DataType, Filter } from "src/app/models/filter.interface";
import { PreferenceUser, TableParameter } from "src/app/models/preference.interface";
import { IQuestionnaire, IQuizListInfo, QuestionnaireStatus, SPECIFIC_USE } from "src/app/models/questionnaire.interface";
import { AccessLevel } from "src/app/models/sharedInterfaces";
import { PreferenceService } from "src/app/providers/preference.service";
import { QuestionnairesService } from "src/app/providers/questionnaires.service";
import { UserService } from "src/app/providers/user.service";
import { QuizListDataSource } from "./quiz-list.datasource";

@Component({
  selector: "app-quiz-list-page",
  templateUrl: "./quiz-list-page.component.html",
  styleUrls: ["./quiz-list-page.component.scss"],
})
export class QuizListPageComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("searchInput") searchInput: ElementRef;
  @ViewChild("searchClearBtn", { read: ElementRef }) searchClearBtn: ElementRef;

  private preferences: TableParameter;
  public dataSource: QuizListDataSource;
  public quizCount: number;
  public loading: boolean;
  public specificUse: SPECIFIC_USE;
  public translationKey: "quizList" | "consentList";

  // Columns
  public displayedColumns = ["title", "language", "knowledgeRef.display", "mediaLabel", "date", "draft", "actions"];

  /**
   * Filter
   */
  public isFiltered = false;
  public filters: Filter[] = [];
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public currentPageSize: number;
  public AccessLevel = AccessLevel;

  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private questionnaireApiService: QuestionnairesService,
    private userService: UserService,
    private preferenceService: PreferenceService
  ) {
    // get data from resolvers
    this.preferences = this.route.snapshot.data.preferences;
    this.filters = this.preferences?.filters ? this.preferences.filters : [];
    this.currentPageSize = this.preferences?.itemsPerPage ? this.preferences.itemsPerPage : 25;
    this.specificUse = this.route.snapshot.data.specificUse;
    this.quizCount = this.route.snapshot.data.quizCount.count;

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        this.loading = true;
      }
      if (ev instanceof NavigationEnd || ev instanceof NavigationCancel || ev instanceof NavigationError) {
        this.loading = false;
      }
    });
  }

  ngOnInit(): void {
    switch (this.specificUse) {
      case SPECIFIC_USE.QUIZ:
        this.translationKey = "quizList";
        break;

      case SPECIFIC_USE.CONSENT:
        this.translationKey = "consentList";
        break;
    }

    // Create dataSource
    this.dataSource = new QuizListDataSource(this.questionnaireApiService);
    // apply filter
    this.filters?.forEach((filter) => this.dataSource.setFilter(filter));
    // Load data for the first time
    this.dataSource.loadData({
      sortId: "title",
      sortOrder: "asc",
      pageNumber: 0,
      pageSize: this.currentPageSize,
      filters: this.getFiltersFormated(),
      search: "",
      specificUse: this.specificUse,
    });
  }

  ngAfterViewInit(): void {
    const searchEvent$ = fromEvent(this.searchInput.nativeElement, "keyup");
    const searchResetEvent$ = fromEvent(this.searchClearBtn.nativeElement, "click");

    // server-side search
    merge(searchEvent$, searchResetEvent$)
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          const searchedValue = this.searchInput.nativeElement.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
          this.paginator.pageIndex = 0;
          // update quiz count with search result
          this.questionnaireApiService
            .getSpecificQuestionnaireInfosCount(this.specificUse, searchedValue, this.getFiltersFormated())
            .pipe(first(), takeUntil(this.onDestroy$))
            .subscribe((result) => {
              this.quizCount = result?.count;
            });
          this.loadQuizListPage();
        })
      )
      .subscribe();

    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadQuizListPage()),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentPageSize = this.paginator.pageSize;
      this.updatePreference();
    });
  }

  private loadQuizListPage() {
    this.dataSource.loadData({
      pageNumber: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      search: this.searchInput.nativeElement.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
      sortId: this.sort.active,
      sortOrder: this.sort.direction,
      filters: this.getFiltersFormated(),
      specificUse: this.specificUse,
    });
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter, isInit = false): void {
    this.dataSource.setFilter(filter);
    if (!isInit) {
      this.paginator.pageIndex = 0;
    }
    this.loadQuizListPage();
    const searchedValue = this.searchInput.nativeElement.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    // update quiz count with filters result
    this.questionnaireApiService
      .getSpecificQuestionnaireInfosCount(this.specificUse, searchedValue, this.getFiltersFormated())
      .pipe(first())
      .subscribe((result) => {
        this.quizCount = result?.count;
      });
    this.isFiltered = this.filters && this.filters.length > 0;
    this.updatePreference();
  }

  public clearFilter(): void {
    this.searchInput.nativeElement.value = "";
    this.dataSource.clearFilter();
    this.paginator.pageIndex = 0;
    this.filters = [];
    this.isFiltered = false;
    this.loadQuizListPage();
    // update quiz count with filters result
    this.questionnaireApiService
      .getSpecificQuestionnaireInfosCount(this.specificUse, this.searchInput.nativeElement.value, this.getFiltersFormated())
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.quizCount = result?.count;
      });
    this.updatePreference();
  }

  private getFiltersFormated(): Filter[] {
    const formatedFilters: Filter[] = [];
    const filters = Tools.clone(this.dataSource.getAllFilters());
    if (filters && filters.length > 0) {
      filters.forEach((filter) => {
        switch (filter.dataType) {
          case this.dataTypeChoice: {
            const values: Array<string> = [];
            const choices: Choice[] = filter.data ? filter.data : [];
            choices.forEach((choice) => {
              if (choice.checked) {
                values.push(choice.value);
              }
            });
            filter.data = {
              value: values,
            };
            break;
          }
        }
        formatedFilters.push(filter);
      });
    }
    this.filters = formatedFilters;
    return formatedFilters;
  }

  /**
   * Navigate to /questionnaireEditor and pass the property "specificUse" to explicit the type of questionnaire (e.g. quiz, consent )
   */
  public createSpecificQuestionnaire(): void {
    this.router.navigate(["/questionnaireEditor"], {
      state: { specificUse: this.specificUse },
    });
  }

  /**
   * Navigate to /questionnaireEditor and pass the properties :
   *  "specificUse" to explicit the type of questionnaire (e.g. quiz, consent)
   *  "questionnaire" to edit the correct questionnaire
   */
  public async updateSpecificQuestionnaire(q: IQuizListInfo): Promise<void> {
    if (
      !this.userService.isAuthorizedSync(null, "dashboard/questionnaire", "PUT") ||
      !this.userService.isAuthorizedSync(null, "/questionnaireEditor", "GET")
    ) {
      return;
    }
    let quest: IQuestionnaire;
    if (q.hasDraft) {
      quest = await this.questionnaireApiService.getQuestionnaire(q.quizId, q.language, true);
    } else {
      const questionnaires = await this.questionnaireApiService.getSpecificQuestionnaireList(this.specificUse);
      quest = Tools.clone(questionnaires.find((quest) => quest.identifier[0].value === q.quizId));
      quest.status = QuestionnaireStatus.DRAFT;
      quest.publisher = "";
      quest.date = null;
      quest._id = undefined;
    }

    this.router.navigate(["/questionnaireEditor"], {
      state: {
        specificUse: this.specificUse,
        questionnaire: quest,
        visualization: q.useContext[0].accessLevel < AccessLevel.WRITE,
        userHighestAccessLevel: q.useContext[0].accessLevel,
      },
    });
  }

  private async getQuizVersion(q: IQuizListInfo) {
    try {
      const questionnaires = await this.questionnaireApiService.getSpecificQuestionnaireList(this.specificUse);
      const questVersion = questionnaires.find(
        (quest) =>
          quest.identifier[0].value === q.quizId && q.language === quest.version && moment(q.date).format() === moment(quest.date).format()
      );
      return questVersion;
    } catch (err) {
      FileLogger.error("FhirQuestionnaireListPageComponent", "Error while get questionnaireVersion: ", err);
      return null;
    }
  }

  private async getQuizDraft(q: IQuizListInfo) {
    try {
      const questDraft = await this.questionnaireApiService.getQuestionnaire(q.quizId, q.language, true);
      return questDraft;
    } catch (err) {
      FileLogger.error("FhirQuestionnaireListPageComponent", "Error while get questionnaireDraft: ", err);
      return null;
    }
  }

  public async showQuizVersion(q: IQuizListInfo, isDraft = false): Promise<void> {
    const questionnaire = isDraft ? await this.getQuizDraft(q) : await this.getQuizVersion(q);
    this.router.navigate(["questionnaireEditor"], {
      state: {
        specificUse: this.specificUse,
        questionnaire,
        visualization: !isDraft || q.useContext[0].accessLevel < AccessLevel.WRITE,
      },
    });
  }

  public updatePreference(): void {
    this.preferenceService
      .update({
        context: this.route.snapshot.data.ctx,
        parameters: {
          filters: this.dataSource.getAllFilters(),
          itemsPerPage: this.paginator.pageSize,
        } as TableParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: PreferenceUser) => {
        const param = parameters.preferences.find((p) => p.context === this.route.snapshot.data.ctx);
        this.preferences = param.parameters;
      });
  }
}
