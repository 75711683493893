<div class="addToGroup">
  <div class="buttons-bar">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close matTooltip="{{ 'btn.close' | translate }}">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="!data.isDelete">
    <mat-dialog-content>
      <h2>{{ "group.findAGroup" | translate }}</h2>
      <form>
        <app-group-server-side-search (groupSelected)="groupIsSelected($event)"></app-group-server-side-search>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="start">
      <button mat-raised-button color="primary" type="submit" (click)="onSave()">
        {{ "group.addToGroup" | translate }}
      </button>
      <mat-checkbox [(ngModel)]="isChecked" class="checkbox" *ngIf="canUpdate()">{{ "group.privateGroup" | translate }}</mat-checkbox>
    </mat-dialog-actions>
  </div>
  <div *ngIf="data.isDelete">
    <mat-dialog-content>
      <h2>{{ "group.deleteFromGroups" | translate }}</h2>
      <mat-form-field *ngIf="!noCommonGroups">
        <mat-label>{{ "group.group" | translate }}</mat-label>
        <mat-select multiple [(ngModel)]="groupsToUpdate">
          <mat-option *ngFor="let item of allGroups" [value]="item">{{ item.groupName }}</mat-option>
        </mat-select>
      </mat-form-field>
      <p *ngIf="noCommonGroups">
        {{ data.list.length > 1 ? ("group.noCommonP" | translate) : ("group.noCommonS" | translate) }}
      </p>
    </mat-dialog-content>
    <mat-dialog-actions align="start" *ngIf="!noCommonGroups">
      <button mat-raised-button color="primary" type="submit" (click)="onDelete()">
        {{ "group.removeToGroups" | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</div>
