import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStepper } from "@angular/material/stepper";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { EnableWhenHelper } from "src/app/helpers/enableWhen-helper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { Coding } from "src/app/models/coding.interface";
import { ActionStatusEntity } from "src/app/models/entity.interface";
import { IObservation, IObservationDefinition } from "src/app/models/observations.interface";
import { INPUT_TYPE, IQuestionnaire, PAGING_MODE, QuestionQuestionnaire } from "src/app/models/questionnaire.interface";
import { Question } from "src/app/models/questionnaireResponse.interface";
import { Answer, QuestionnaireResponse } from "src/app/models/questionnaireResponse.model";
import { Reference } from "src/app/models/reference.interface";
import { EnableWhenBehavior, IEnableWhen } from "src/app/models/sharedInterfaces";
import { SlideContentGroupQuestion, SlideContentQuestion, SlideView } from "src/app/models/slideView.interface";
import { ObservationsService } from "src/app/providers/observations.service";
import { QRService } from "src/app/providers/qr-api.service";
import { SessionService } from "src/app/providers/session.service";
import { EncodeObservationsComponent } from "./encode-observations/encode-observations.component";

@Pipe({ name: "questionTextValue" })
export class QuestionTextValuePipe implements PipeTransform {
  transform(linkId: string, questionnaire: IQuestionnaire, questionnaireResponse: QuestionnaireResponse): string {
    const res = this.getAnswerModel(linkId, questionnaire, questionnaireResponse);

    if (res && res.length) {
      return res[0].valueCoding.display;
    }
    return "";
  }
  public getAnswerModel(linkId: string, questionnaire: IQuestionnaire, questionnaireResponse: QuestionnaireResponse): Answer[] {
    if (questionnaire?.group?.question?.length) {
      for (const question of questionnaireResponse.group.question) {
        if (question.linkId === linkId) {
          return question.answer;
        }
      }
    } else if (questionnaire?.group?.group?.length) {
      for (const subGroup of questionnaireResponse.group.group) {
        for (const question of subGroup.question) {
          if (question.linkId === linkId) {
            return question.answer;
          }
        }
      }
    }
    return null;
  }
}

@Pipe({ name: "questionNumberValue" })
export class QuestionNumberValuePipe implements PipeTransform {
  transform(linkId: string, questionnaire: IQuestionnaire, questionnaireResponse: QuestionnaireResponse): string {
    const res = this.getAnswerModel(linkId, questionnaire, questionnaireResponse);
    if (res && res.length && res[0]) {
      return res[0].valueCoding.code;
    }
    return "";
  }
  public getAnswerModel(linkId: string, questionnaire: IQuestionnaire, questionnaireResponse: QuestionnaireResponse): Answer[] {
    if (questionnaire?.group?.question?.length) {
      for (const question of questionnaireResponse.group.question) {
        if (question.linkId === linkId) {
          return question.answer;
        }
      }
    } else if (questionnaire?.group?.group?.length) {
      for (const subGroup of questionnaireResponse.group.group) {
        for (const question of subGroup.question) {
          if (question.linkId === linkId) {
            return question.answer;
          }
        }
      }
    }
    return null;
  }
}
@Pipe({ name: "isQuestionEnabled", pure: false })
// Do not use "pure: false" everywhere as a solution
// this makes the pipe kinda useless since it acts as a normal function
export class IsQuestionEnabledPipe implements PipeTransform {
  transform(question: Question, questionnaireResponse: QuestionnaireResponse): boolean {
    const enableWhens: IEnableWhen[] = question.enableWhen;
    if (!enableWhens) {
      return true;
    } else {
      const behavior = question.enableWhenBehavior;
      for (const enableWhen of enableWhens) {
        const values = this.getAnswerValue(enableWhen.question, questionnaireResponse);
        if (!values || values.length < 1 || !values[0]) {
          return false;
        }
        const firstValue = enableWhen.type === "number" ? Number(values[0].valueCoding.code) : values[0].valueCoding.code;
        const isEnabled = EnableWhenHelper.computeIsEnabled(enableWhen, firstValue);
        const behaviorCheck = EnableWhenHelper.checkBehavior(isEnabled, behavior);
        if (Tools.isDefined(behaviorCheck)) {
          return behaviorCheck;
        }
      }
      if (behavior === EnableWhenBehavior.OR) {
        return false;
      }
      if (!Tools.isDefined(behavior) || behavior === EnableWhenBehavior.AND) {
        return true;
      }
    }
  }

  public getAnswerValue(linkId: string, questionnaireResponse: QuestionnaireResponse): Answer[] {
    if (questionnaireResponse?.group?.question?.length) {
      for (const question of questionnaireResponse.group.question) {
        if (question.linkId === linkId && question?.answer?.length) {
          return question.answer;
        }
      }
    } else if (questionnaireResponse?.group?.group?.length) {
      for (const subGroup of questionnaireResponse.group.group) {
        for (const question of subGroup.question) {
          if (question.linkId === linkId && question?.answer?.length) {
            return question.answer;
          }
        }
      }
    }
    return null;
  }
}

@Component({
  selector: "app-answer-new-qr",
  templateUrl: "./answer-new-qr.component.html",
  styleUrls: ["./answer-new-qr.component.scss"],
})
export class AnswerNewQrComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("stepper") slides: MatStepper;
  @ViewChild(EncodeObservationsComponent) observationsEncoder: EncodeObservationsComponent;
  public questionnaire: IQuestionnaire;
  public questionnaireResponse: QuestionnaireResponse;
  public linkedObservationsDef: IObservationDefinition[];
  public linkedObservations: IObservation[];
  public slideViews: SlideView[] = [];
  public isFirst = true;
  public isLast = false;
  public isUpdate = false;
  private onDestroy$ = new Subject<void>();
  private hasValidate = false;
  private newObservations: IObservation[];
  public lang: string;
  public joditConfig = {
    useSearch: false,
    spellcheck: true,
    language: this.sessionsService.userLang,
    toolbarButtonSize: "small",
    disablePlugins: "file, media",
  };
  public isFullScreen = false;
  public INPUT_TYPE = INPUT_TYPE;

  constructor(
    private qrService: QRService,
    private sessionsService: SessionService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private observationService: ObservationsService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<AnswerNewQrComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      questionnaire: IQuestionnaire;
      patientRef: Reference;
      questionnaireResponse?: QuestionnaireResponse;
    }
  ) {}

  ngOnInit(): void {
    this.questionnaire = this.data.questionnaire;
    this.downloadLinkedObservationsDefs();
    // create new response or edit existing one ?
    // build Questionnaire Response based on Questionnaire template
    if (!this.data.questionnaireResponse) {
      this.questionnaireResponse = new QuestionnaireResponse();
      this.questionnaireResponse.fromTemplate(this.questionnaire, this.data.patientRef, {
        reference: this.sessionsService.account.caremateIdentifier,
        display: this.sessionsService.account.displayName,
      });
    } else {
      this.questionnaireResponse = this.data.questionnaireResponse;
      this.lang = this.data.questionnaireResponse.version;
      this.isUpdate = true;
      this.downloadLinkedObservations();
    }
    if (this.questionnaire) {
      this.buildSlideViews();
    }
  }

  ngAfterViewInit(): void {
    if (this.slideViews.length > 1) {
      this.slides.selectionChange.pipe(takeUntil(this.onDestroy$)).subscribe((event: StepperSelectionEvent) => {
        this.isFirst = event.selectedIndex === 0;
        this.isLast = event.selectedIndex === this.slideViews.length - 1;
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private downloadLinkedObservationsDefs(): void {
    const loincs = this.questionnaire?.observationLoincs?.map((o) => o.loinc);
    if (loincs?.length) {
      this.observationService
        .listDef(null, loincs)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (o: IObservationDefinition[]) => {
            this.linkedObservationsDef = o.filter(ArrayHelper.onlyUniqueLoincObsDef);
            this.linkedObservationsDef.forEach((o) => {
              const matchingLoinc = this.questionnaire?.observationLoincs?.find((loincObj) => loincObj.loinc === o.loinc);
              if (matchingLoinc) {
                o.patientDefaultAccess = matchingLoinc.patientDefaultAccess;
              }
            });
          },
          (err) => {
            FileLogger.error("AnswerNewQRComponent", "Error while downloading observations definitions", err, "none");
            this.linkedObservationsDef = [];
          }
        );
    }
  }

  private async downloadLinkedObservations(): Promise<void> {
    const questionnaireResponseRef = this.questionnaireResponse?.identifier.value;
    if (questionnaireResponseRef) {
      this.linkedObservations = await this.observationService.getObservationsByQR(questionnaireResponseRef).catch((err) => {
        FileLogger.error("AnswerNewQRComponent", "Error while downloading observations", err, "none");
        return [];
      });
    }
  }

  public linkedObservationsChanged(newObservations: IObservation[]): void {
    this.newObservations = newObservations;
  }

  /**
   * Create all slides based on Questionnaire
   */
  private buildSlideViews(): void {
    switch (this.questionnaire.paging) {
      default:
      case PAGING_MODE.NONE: {
        // no slides (in fact only 1 slide)
        this.buildSimpleSlideMode();
        break;
      }
      case PAGING_MODE.SINGLE: {
        // 1 slide by question
        this.buildSingleSlideMode();
        break;
      }
      case PAGING_MODE.GROUP: {
        // 1 slide by theme
        this.buildGroupSlideMode();
        break;
      }
    }
  }

  /**
   * build 1 slide by question
   */
  private buildSingleSlideMode(): void {
    // multiple groups of questions
    if (QuestionnaireResponse.isMultiGroup(this.questionnaireResponse)) {
      // loop on sub groups
      for (let i = 0; i < this.questionnaire.group.group.length; i++) {
        const subGroup = this.questionnaire.group.group[i];
        // loop on questions for each group
        for (let j = 0; j < subGroup.question.length; j++) {
          const question = subGroup.question[j];
          // one slide for 1 content group
          const slideView: SlideView = {
            identifier: this.questionnaireResponse.identifier.value,
            content: [],
          };
          // one content group for 1 question
          const slideContentGroup: SlideContentGroupQuestion = {
            title: subGroup.title,
            description: subGroup.text,
            questionResponses: [],
          };
          const slideContent: SlideContentQuestion = {
            question,
            response: this.questionnaireResponse.group.group[i].question[j],
          };
          // set question/response to its Content Group
          slideContentGroup.questionResponses = [slideContent];
          // add content group to its slide
          slideView.content = [slideContentGroup];
          // add slide to the list of slides
          this.slideViews.push(slideView);
        }
      }
    } else {
      // flat questionnaires (only main group with questions)
      // loop on main group questions
      for (let i = 0; i < this.questionnaire.group.question.length; i++) {
        const question = this.questionnaire.group.question[i];
        // one slide for 1 content group
        const slideView: SlideView = {
          identifier: this.questionnaireResponse.identifier.value,
          content: [],
        };
        // one content group for 1 question
        const slideContentGroup: SlideContentGroupQuestion = {
          title: this.questionnaire.group.title,
          description: this.questionnaire.group.text,
          questionResponses: [],
        };
        const slideContent: SlideContentQuestion = {
          question,
          response: this.questionnaireResponse.group.question[i],
        };
        // set question/response to its Content Group
        slideContentGroup.questionResponses = [slideContent];
        // add content group to its slide
        slideView.content = [slideContentGroup];
        // add slide to the list of slides
        this.slideViews.push(slideView);
      }
    }
    // console.text("buildSingleSlideMode", this.slideViews);
  }

  /**
   * build 1 slide by theme (by group)
   */
  private buildGroupSlideMode(): void {
    // multiple groups of questions
    if (QuestionnaireResponse.isMultiGroup(this.questionnaireResponse)) {
      // loop on sub groups
      for (let i = 0; i < this.questionnaire.group.group.length; i++) {
        const subGroup = this.questionnaire.group.group[i];
        // one slide for 1 group
        const slideView: SlideView = {
          identifier: this.questionnaireResponse.identifier.value,
          content: [],
        };
        // one content group for all questions of this group
        const slideContentGroup: SlideContentGroupQuestion = {
          title: subGroup.title,
          description: subGroup.text,
          questionResponses: [],
        };
        // add 1 content group to its slide
        slideView.content = [slideContentGroup];
        // loop on questions for each group
        for (let j = 0; j < subGroup.question.length; j++) {
          const question = subGroup.question[j];
          const slideContent: SlideContentQuestion = {
            question,
            response: this.questionnaireResponse.group.group[i].question[j],
          };
          // add question/response to its Content Group
          slideContentGroup.questionResponses.push(slideContent);
        }
        // add slide to the list of slides
        this.slideViews.push(slideView);
      }
    } else {
      // flat questionnaires (only main group with questions)
      // ??? not possible to build by group mode if there are no groups (only main) ==> same as simple mode
      this.buildSimpleSlideMode();
    }
  }

  /**
   * No slide ==> build 1 single slide with all questions
   */
  private buildSimpleSlideMode(): void {
    const slideContentGroups: SlideContentGroupQuestion[] = [];
    // multiple groups of questions
    if (QuestionnaireResponse.isMultiGroup(this.questionnaireResponse)) {
      // loop on sub groups
      for (let i = 0; i < this.questionnaire.group.group.length; i++) {
        const subGroup = this.questionnaire.group.group[i];
        const slideContentGroup: SlideContentGroupQuestion = {
          title: subGroup.title,
          description: subGroup.text,
          questionResponses: [],
        };
        // loop on questions for each sub groups
        for (let j = 0; j < subGroup.question.length; j++) {
          const question = subGroup.question[j];
          const slideContent: SlideContentQuestion = {
            question,
            response: this.questionnaireResponse.group.group[i].question[j],
          };
          slideContentGroup.questionResponses.push(slideContent);
        }
        slideContentGroups.push(slideContentGroup);
      }
    } else {
      // flat questionnaires (only main group with questions)
      const slideContentGroup: SlideContentGroupQuestion = {
        title: this.questionnaire.group.title,
        description: this.questionnaire.group.text,
        questionResponses: [],
      };
      // loop on main group questions
      for (let i = 0; i < this.questionnaire.group.question.length; i++) {
        const question = this.questionnaire.group.question[i];
        const slideContent: SlideContentQuestion = {
          question,
          response: this.questionnaireResponse.group.question[i],
        };
        // add question/response to the single Content Group
        slideContentGroup.questionResponses.push(slideContent);
      }
      slideContentGroups.push(slideContentGroup);
    }
    // create 1 slide
    const slideView: SlideView = {
      identifier: this.questionnaireResponse.identifier.value,
      content: slideContentGroups,
    };
    // add only 1 slide to the list of slides
    this.slideViews = [slideView];
  }

  /**
   * return multiple choice values define in "questionnaire"
   */
  public getValueSetChoices(reference: string): Coding[] {
    if (this.questionnaire.contained) {
      for (const element of this.questionnaire.contained) {
        if (element.resourceType === "ValueSet" && element.idSet === reference) {
          return element.compose.include[0].concept;
        }
      }
    }
    return [];
  }

  /**
   * Find coding display text for this answer
   */
  public getValueSetChoice(reference: string, code: string): Coding {
    // find Coding value
    const codings = this.getValueSetChoices(reference);
    for (const coding of codings) {
      if (coding.code === code) {
        return coding;
      }
    }
  }

  /**
   * Save these responses: create a QuestionaireResponse
   */
  public async onValidate(): Promise<void> {
    if (this.isComplete()) {
      this.observationsEncoder?.save();
      this.questionnaireResponse.modified = moment().format();
      this.questionnaireResponse.questionnairePublicationDate = this.questionnaire.date;
      this.questionnaireResponse.actionStatus = this.isUpdate ? ActionStatusEntity.MODIFIED : ActionStatusEntity.CREATED;
      if (this.isUpdate) {
        // Remove empty new observations (keep empty pre-existing observations, the server will have to delete them):
        const filteredObs = this.newObservations?.filter(
          (o) => o._id || !!o.component.find((c) => Tools.isDefined(c.valueQuantity?.value))
        );
        await this.qrService.updateQR(this.questionnaireResponse, filteredObs);
        this.sessionsService.needRefresQRDataList();
        this.sessionsService.needRefreshObservationsList();
        this.close();
      } else {
        // Remove empty observations:
        const filteredObs = this.newObservations?.filter((o) => !!o.component.find((c) => Tools.isDefined(c.valueQuantity?.value)));
        this.qrService
          .createQR(this.questionnaireResponse, filteredObs)
          .pipe(
            takeUntil(this.onDestroy$),
            finalize(() => {
              this.sessionsService.needRefresQRDataList(), this.sessionsService.needRefreshObservationsList(), this.close();
            })
          )
          .subscribe();
      }
    } else {
      this.hasValidate = true;
      return;
    }
  }

  private close(): void {
    this.snackBar.open(this.translateService.instant("common.saveSuccess"), undefined, {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
    this.dialog.closeAll();
  }
  /**
   * Response choice has changed: update QuestionnaireResponse
   */
  public onRadioChange($event: MatRadioChange, question: QuestionQuestionnaire): void {
    let answers = this.getAnswerModel(question.linkId);
    if (Tools.isNotDefined(answers)) {
      answers = new Array<Answer>();
    }
    answers.splice(0); // remove previous value
    const answer = new Answer();
    // find display code
    const coding = question.options?.reference ? this.getValueSetChoice(question.options.reference, $event.value) : null;
    answer.valueCoding = {
      code: $event.value,
      display: coding ? coding.display : "",
      system: null,
    };
    answers.push(answer);
    this.getQuestionResponseModel(question.linkId).inputType = INPUT_TYPE.RADIO;
  }

  public onCheckboxChange($event: MatCheckboxChange, question: QuestionQuestionnaire): void {
    let answers = this.getAnswerModel(question.linkId);
    if (Tools.isNotDefined(answers)) {
      answers = new Array<Answer>();
    }
    const checked = $event.checked;
    const value = $event.source.value;
    if (checked) {
      const answer = new Answer();

      // find display code
      const coding = question.options?.reference ? this.getValueSetChoice(question.options.reference, value) : null;
      answer.valueCoding = {
        code: value,
        display: coding ? coding.display : "",
        system: null,
      };
      answers.push(answer);
    } else {
      const index = answers.findIndex((a) => a.valueCoding.code === value);
      if (index !== -1) {
        answers.splice(index, 1);
      }
    }
    this.getQuestionResponseModel(question.linkId).inputType = INPUT_TYPE.CHECKBOXES;
  }

  public setInput(value: string, question: QuestionQuestionnaire, withUnits = false, answerId = 0, multiInputs = false): void {
    let res = this.getAnswerModel(question.linkId);
    const valueSet = question.options?.reference ? this.getValueSetChoices(question.options.reference) : null;
    const defaultUnit = withUnits && valueSet ? valueSet[0] : null;
    if (!value) {
      return;
    }
    this.getQuestionResponseModel(question.linkId).inputType = question.answerDisplay ? question.answerDisplay.type : question.type;
    if (!res) {
      res = [] as Answer[];
    }
    // New answer:
    if (!res.length) {
      // The answer is actually the "unit" part of the number with unit input:
      if (answerId > 0 && withUnits && !multiInputs) {
        // set default value for the number
        const defaultValue = question.answerDisplay?.default ? question.answerDisplay.default : "0";
        res.push(this.getAnswer(defaultValue, defaultValue));
        // set unit
        res.push(
          this.getAnswer(
            this.getValueSetChoice(question.options?.reference, value).code,
            this.getValueSetChoice(question.options?.reference, value).display
          )
        );
      }
      // All other cases:
      else {
        if (multiInputs) {
          const iMax = valueSet?.length ? valueSet.length : answerId + 1;
          for (let i = 0; i < iMax; ++i) {
            if (i === answerId) {
              res.push(this.getAnswer(value, value));
            } else {
              const defaultValue = question.answerDisplay?.default ? question.answerDisplay.default : "";
              res.push(this.getAnswer(defaultValue, defaultValue));
            }
            if (withUnits) {
              // set unit
              res.push(this.getAnswer(valueSet[i].code, valueSet[i].display));
            }
          }
        } else {
          res.push(this.getAnswer(value, value));
          // set the default unit when we are setting the "number" part of the number with unit input:
          if (answerId === 0 && withUnits && !multiInputs && defaultUnit) {
            res.push(this.getAnswer(defaultUnit.code, defaultUnit.display));
          }
        }
      }
    }
    // Update answer:
    else {
      // The answer is actually the "unit" part of the number with unit input:
      if (answerId > 0 && withUnits && !multiInputs) {
        res[answerId] = this.getAnswer(
          this.getValueSetChoice(question.options?.reference, value).code,
          this.getValueSetChoice(question.options?.reference, value).display
        );
      }
      // All other cases:
      else {
        if (multiInputs && withUnits) {
          res[answerId * 2] = this.getAnswer(value, value);
        } else {
          res[answerId] = this.getAnswer(value, value);
        }
        // if the default "unit" wasn't already set (for some mysterious reason):
        if (answerId === 0 && withUnits && !multiInputs && defaultUnit && !res[1]) {
          // set the default unit
          res.push(this.getAnswer(defaultUnit.code, defaultUnit.display));
        }
      }
    }
  }

  private getAnswer(code: string, display: string): Answer {
    return {
      valueCoding: {
        code,
        display,
        system: "http://comunicare.be",
      },
    };
  }

  /**
   * return the Answer object related to this "LinkId"
   */
  private getAnswerModel(linkId: string): Answer[] {
    if (this.questionnaire?.group?.question?.length) {
      for (const question of this.questionnaireResponse.group.question) {
        if (question.linkId === linkId) {
          return question.answer;
        }
      }
    } else if (this.questionnaire?.group?.group?.length) {
      for (const subGroup of this.questionnaireResponse.group.group) {
        for (const question of subGroup.question) {
          if (question.linkId === linkId) {
            return question.answer;
          }
        }
      }
    }
    return null;
  }

  /**
   * return the Question(response) object related to this "LinkId"
   */
  public getQuestionResponseModel(linkId: string): Question {
    if (this.questionnaire?.group?.question?.length) {
      for (const question of this.questionnaireResponse.group.question) {
        if (question.linkId === linkId) {
          return question;
        }
      }
    } else if (this.questionnaire?.group?.group?.length) {
      for (const subGroup of this.questionnaireResponse.group.group) {
        for (const question of subGroup.question) {
          if (question.linkId === linkId) {
            return question;
          }
        }
      }
    }
    return null;
  }

  /**
   * Automatic go to next slide if only 1 questions and not at the end
   */
  public onRadioSelected(slide: SlideView): void {
    if (this.isSingleQuestionAndHasNextSlide(slide)) {
      this.nextSlide();
    }
  }

  /**
   * Can we automatic go to next slide ?
   */
  public isSingleQuestionAndHasNextSlide(slide: SlideView): boolean {
    return (
      slide.content &&
      slide.content.length === 1 &&
      (slide.content[0] as SlideContentGroupQuestion).questionResponses.length === 1 &&
      this.slides.selectedIndex === this.slideViews.length
    );
  }

  public getValueTxt(linkId: string): string {
    const res = this.getAnswerModel(linkId);

    if (res && res.length) {
      return res[0].valueCoding.display;
    }
    return "";
  }

  public getCheckedRadio(linkId: string, code: string): boolean {
    const res = this.getAnswerModel(linkId);
    if (res && res.length) {
      return res[0].valueCoding.code === code;
    }
    return false;
  }

  public getChecked(linkId: string, code: string): boolean {
    const res = this.getAnswerModel(linkId);
    if (res && res.length) {
      return res.findIndex((r) => r.valueCoding.code === code) !== -1;
    }
    return false;
  }

  /**
   * Click on next button
   */
  public nextSlide(): void {
    if (this.isComplete() || this.isUpdate) {
      this.hasValidate = false;
      this.slides.next();
    } else {
      this.hasValidate = true;
      return;
    }
  }

  /**
   * Click on previous button
   */
  prevSlide(): void {
    this.slides.previous();
  }

  /**
   * check if current vue is complete
   */
  public isComplete(): boolean {
    // get the view
    const index = this.slides.selectedIndex;
    const currentView = this.slideViews[index];
    if (currentView) {
      //  if exist take the content
      const currentQuestionnaire = currentView.content[0] as SlideContentGroupQuestion;
      if (currentQuestionnaire.questionResponses) {
        // if exist loop on all Questions and response
        for (const qr of currentQuestionnaire.questionResponses) {
          // if question is required, enabled and response empty, return false
          const isQuestionEnabledtype = new IsQuestionEnabledPipe();
          const isQuestionEnabled = isQuestionEnabledtype.transform(qr.response, this.questionnaireResponse);
          if (qr.question.required && qr.response.answer.length === 0 && isQuestionEnabled) {
            return false;
          }
          if (qr.response.answer && !qr.response.answer[0]) {
            delete qr.response;
          }
        }
        return true;
      }
    } else {
      return false;
    }
  }

  public isNotCompleted(q: QuestionQuestionnaire): boolean {
    const getAnswerModelPipe = new QuestionTextValuePipe();
    return (
      this.hasValidate &&
      q.required &&
      Tools.isDefined(getAnswerModelPipe.getAnswerModel(q.linkId, this.questionnaire, this.questionnaireResponse))
    );
  }

  public onResizeModal(): void {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      this.dialogRef.updateSize("100vw", "100vh");
    } else {
      this.dialogRef.updateSize("70vw", "80vh");
    }
  }
}
