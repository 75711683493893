import { Observable } from "rxjs";
import { MatTableDataSourceBackendExtended } from "src/app/helpers/datasource-backend";
import { DocumentDataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { IPatientDocInfo } from "src/app/models/patient-document.interface";
import { DocumentApiService } from "src/app/providers/api/document-api.service";

export class DocumentsDataSource extends MatTableDataSourceBackendExtended<IPatientDocInfo> {
  constructor(private documentApi: DocumentApiService) {
    super();
  }

  public data(params: DocumentDataSourceBackendParam): Observable<IPatientDocInfo[]> {
    return this.documentApi.findDocument(params);
  }
}
