<div class="qr-details">
  <div>
    <div class="close-cross">
      <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div *ngIf="linkedObservations?.length" style="text-align: center">
      <mat-button-toggle-group [(ngModel)]="viewType" aria-label="Observations or questionnaire">
        <mat-button-toggle [value]="'questionnaire'"
          ><mat-icon>notes</mat-icon>&nbsp;{{ "page.questionnaireEditor.questionnaire" | translate }}</mat-button-toggle
        >
        <mat-button-toggle [value]="'observations'"
          ><mat-icon>insert_chart_outlined</mat-icon>&nbsp;{{ "observation.title" | translate }}</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="buttons-bar" *ngIf="viewType === 'questionnaire'">
    <mat-slide-toggle *ngIf="!loading && nbSection > 1 && page + 1 === 1" [(ngModel)]="showAllQuestions" (change)="onShowAll()">{{
      "questionnaire.showAllQuestions" | translate
    }}</mat-slide-toggle>
    <button
      mat-icon-button
      color="primary"
      class="negative-margin"
      matTooltip="{{ 'btn.exportQuestionnaire' | translate }}"
      (click)="onExportPDF()"
      aria-label="icon button with a pdf icon"
    >
      <mat-icon>picture_as_pdf</mat-icon>
    </button>
  </div>

  <mat-card *ngIf="viewType === 'questionnaire' && page + 1 === 1 && mergedCategory.length" class="mt-10 main-card">
    <mat-card-title class="fs-16">Scores</mat-card-title>
    <mat-card-content class="score-container">
      <app-score-details *ngFor="let items of mergedCategory" [scorings]="items" class="scores"> </app-score-details>
    </mat-card-content>
  </mat-card>
  <mat-spinner class="centerSpinner" *ngIf="loading"></mat-spinner>

  <mat-card class="mt-10 main-card" *ngIf="viewType === 'questionnaire'">
    <mat-card-title *ngIf="page + 1 === 1" class="fs-16">{{ "table.questions" | translate }}</mat-card-title>
    <mat-card-subtitle>{{ getGroupTitle() }}</mat-card-subtitle>
    <mat-card-content>
      <mat-card>
        <table mat-table matSort matSortDirection="asc" [dataSource]="dataSource" [ngClass]="{ hide: isNoneData }">
          <!-- name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "table.question" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.text }}
            </td>
          </ng-container>

          <!-- Answer Column -->
          <ng-container matColumnDef="answer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "table.answer" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <span
                [mdePopoverTriggerFor]="appPopover"
                mdePopoverTriggerOn="hover"
                #popoverTrigger="mdePopoverTrigger"
                [innerHTML]="element.linkId | questionAnswerStr : showAllQuestions : page : groupsResponse"
                *ngIf="element.type === INPUT_TYPE.TEXTFIELD_HTML"
              ></span>
              <span
                [mdePopoverTriggerFor]="appPopover"
                mdePopoverTriggerOn="hover"
                #popoverTrigger="mdePopoverTrigger"
                *ngIf="element.type !== INPUT_TYPE.TEXTFIELD_HTML"
              >
                <ng-container *ngIf="element.answerDisplay?.type === INPUT_TYPE.EXTERNAL_LINK; else answerString">
                  <a target="_blank" [href]="element.answerDisplay.default | externalUrl">{{
                    element.answerDisplay.placeholder ? element.answerDisplay.placeholder : element.answerDisplay.default
                  }}</a>
                </ng-container>
                <ng-template #answerString>{{ element.linkId | questionAnswerStr : showAllQuestions : page : groupsResponse }}</ng-template>
              </span>
              <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
                <app-qr-values-possibles
                  [valuesPossibles]="element?.options?.reference | valueSetChoices : questionnaire.contained"
                  [answer]="element.linkId | questionAnswerCode : showAllQuestions : page : groupsResponse"
                  [answerType]="element.answerDisplay?.type"
                >
                </app-qr-values-possibles>
              </mde-popover>
              <span *ngIf="isQuiz" class="quizIcons">
                <mat-icon
                  *ngIf="
                    element.linkId
                      | questionAnswerCode : showAllQuestions : page : groupsResponse
                      | isGoodAnswerVer2 : questionnaire.group.group[0].question : element.linkId
                  "
                  style="color: green"
                >
                  check_circle
                </mat-icon>
                <mat-icon
                  *ngIf="
                    !(
                      element.linkId
                      | questionAnswerCode : showAllQuestions : page : groupsResponse
                      | isGoodAnswerVer2 : questionnaire.group.group[0].question : element.linkId
                    )
                  "
                  style="color: red"
                >
                  cancel
                </mat-icon>
              </span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
        </table>
      </mat-card>
    </mat-card-content>
  </mat-card>
  <div id="pagination" class="action-container" *ngIf="!showAllQuestions && viewType === 'questionnaire'">
    <button mat-button [disabled]="page + 1 === 1" (click)="changePage('-')">
      {{ "btn.previousData" | translate }}
    </button>
    <p>{{ page + 1 + "/" + nbSection }}</p>
    <button mat-button [disabled]="page + 1 === nbSection" (click)="changePage('+')">
      {{ "btn.nextData" | translate }}
    </button>
  </div>
  <mat-card class="mt-10 main-card" *ngIf="viewType === 'observations'">
    <mat-card-title class="fs-16">{{ "observation.title" | translate }}</mat-card-title>
    <mat-card-content>
      <app-small-observation-table
        [obsDefinition]="linkedObservationsDef"
        [observations]="linkedObservations"
        [loading]="loading"
      ></app-small-observation-table>
    </mat-card-content>
  </mat-card>
</div>
