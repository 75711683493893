import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface IShowAlertLevel {
  levelOne: boolean;
  levelTwo: boolean;
  levelThree: boolean;
}
@Component({
  selector: "app-alert-preference",
  templateUrl: "./alert-preference.component.html",
  styleUrls: ["./alert-preference.component.scss"],
})
export class AlertPreferenceComponent implements OnInit {
  public showAlerts: IShowAlertLevel = {
    levelOne: false,
    levelTwo: true,
    levelThree: true,
  };
  constructor(
    private dialogRef: MatDialogRef<AlertPreferenceComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { showAlertLevels: IShowAlertLevel }
  ) {}

  ngOnInit(): void {
    this.showAlerts = this.data.showAlertLevels;
  }

  public onSave() {
    this.dialogRef.close(this.showAlerts);
  }
}
