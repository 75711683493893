<h3>{{ "page.careplanEditor.tabs.communications.titleLinked2Activities" | translate }}</h3>
<mat-accordion multi *ngIf="careplanEditorService.careplanTemplateReady$ | async">
  <mat-expansion-panel
    *ngFor="let activityFormGroup of careplanEditorService.careplanTemplateForm?.get('activity').controls"
    [disabled]="activityFormGroup.value.detail.category === ACTIVITY_CATEGORY.DRUG"
    [matTooltip]="'page.careplanEditor.tabs.communications.notAllowed' | translate"
    [matTooltipDisabled]="activityFormGroup.value.detail.category !== ACTIVITY_CATEGORY.DRUG"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> {{ activityFormGroup | getActivityName : currentLanguage }} </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-accordion class="mat-elevation-z0" displayMode="flat">
      <mat-expansion-panel
        class="communication-container mat-elevation-z0"
        *ngFor="let actionResultingFormGroup; let i = index; of: getCommunicationFormControl(activityFormGroup)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="pr-1">email</mat-icon>
            <span
              *ngIf="
                actionResultingFormGroup.value.detail.contentCommunication.topicTranslation &&
                  actionResultingFormGroup.value.detail.contentCommunication.topicTranslation[currentLanguage];
                else noTitle
              "
            >
              {{ actionResultingFormGroup.value.detail.contentCommunication.topicTranslation[currentLanguage] }}
            </span>
            <ng-template #noTitle
              ><span class="italic">{{ "common.noTitle" | translate }}</span></ng-template
            >
          </mat-panel-title>
          <mat-panel-description>
            <div class="spacer"></div>
            <button mat-icon-button (click)="$event.stopPropagation(); deleteCommunication(activityFormGroup, i)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <app-communication-form
          [actionResultingForm]="actionResultingFormGroup"
          [needSkipFirst]="
            activityFormGroup.value.detail?.scheduledTiming?.repeat?.frequency !== null &&
            activityFormGroup.value.detail?.scheduledTiming?.repeat?.frequency !== undefined
              ? true
              : false
          "
        ></app-communication-form>
      </mat-expansion-panel>
    </mat-accordion>
    <button class="mt-1" mat-raised-button color="primary" (click)="addCommunication(activityFormGroup)">
      {{ "page.careplanEditor.tabs.communications.add" | translate }}
    </button>
  </mat-expansion-panel>
</mat-accordion>
