<div class="patientListPage">
  <div class="toolbar">
    <div class="left">
      <mat-form-field>
        <mat-label><span class="fa fa-search mr-5"></span>{{ "itemFilter.search" | translate }}</mat-label>
        <input
          type="search"
          matInput
          [placeholder]="'itemFilter.searchPlaceHolder' | translate"
          [value]="globalSearchValue"
          (keyup)="updateSearch($event.target.value)"
        />
        <button *ngIf="globalSearchValue" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="updateSearch('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button mat-icon-button (click)="openUserListHelp()">
        <mat-icon style="font-size: 30px" matTooltip="{{ 'btn.help' | translate }}">help_outline</mat-icon>
      </button>
    </div>
    <div class="spacer"></div>
    <button
      mat-stroked-button
      class="success"
      *ngIf="('/user' | isAuthorized | async) === true && ('practitioner' | isAuthorized : 'POST' | async) === true"
      (click)="addUser()"
      matTooltip="{{ 'page.organizations.btn.newPract' | translate }}"
    >
      <mat-icon>person_add</mat-icon>
    </button>
    <button mat-stroked-button (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}" [disabled]="!isFiltered">
      <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
    </button>
    <div class="right">
      <app-item-selector
        [items]="availableColumnItems"
        (changed)="availableColumnChanged($event)"
        [translate]="true"
        matTooltip="{{ 'page.patientlist.columnsSelect' | translate }}"
      >
      </app-item-selector>
    </div>
  </div>

  <app-filters-display [filters]="filters" (removeFilter)="applyFilter($event)"></app-filters-display>

  <mat-card>
    <div [style.display]="this.displayedColumns.length > 0 ? 'block' : 'none'">
      <table
        mat-table
        matSort
        matSortDirection="asc"
        matSortActive="name"
        aria-label="Elements"
        cdkDropList
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="columnDropped($event)"
        class="full-width-table"
      >
        <!-- Visibility Column -->
        <ng-container matColumnDef="visible">
          <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
            {{ "model.patient.visible" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-icon class="fail" *ngIf="row.active === false">visibility_off</mat-icon>
            <mat-icon class="success" *ngIf="row.active === true">visibility</mat-icon>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
            {{ "model.patient.name" | translate }}
            <app-item-filter
              fieldName="{{ 'model.patient.name' | translate }}"
              translateFieldKey="model.patient.name"
              propertyName="familyName"
              [dataType]="dataTypeText"
              [defaultValue]="getFilter('familyName')"
              (applyFilter)="applyFilter($event)"
            >
            </app-item-filter>
          </th>
          <td mat-cell class="clickable" *matCellDef="let row">
            {{ row.familyName }}
          </td>
        </ng-container>

        <!-- Firstname Column -->
        <ng-container matColumnDef="firstname">
          <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
            {{ "model.patient.firstname" | translate }}
            <app-item-filter
              fieldName="{{ 'model.patient.firstname' | translate }}"
              translateFieldKey="model.patient.firstname"
              propertyName="firstname"
              [dataType]="dataTypeText"
              [defaultValue]="getFilter('firstname')"
              (applyFilter)="applyFilter($event)"
            >
            </app-item-filter>
          </th>
          <td mat-cell class="clickable" *matCellDef="let row">
            {{ row.firstname }}
          </td>
        </ng-container>

        <!-- Birthday Column -->
        <ng-container matColumnDef="birthday">
          <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
            {{ "model.patient.birthdate" | translate }}
            <app-item-filter
              fieldName="{{ 'model.patient.birthdate' | translate }}"
              translateFieldKey="model.patient.birthdate"
              propertyName="birthDate"
              [dataType]="dataTypeDate"
              [defaultValue]="getFilter('birthDate')"
              (applyFilter)="applyFilter($event)"
            >
            </app-item-filter>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.birthDate | validDate | date : "dd/MM/yyyy" }}
          </td>
        </ng-container>

        <!-- Phone Column -->
        <ng-container matColumnDef="phone">
          <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
            {{ "model.patient.phone" | translate }}
            <app-item-filter
              fieldName="{{ 'model.patient.phone' | translate }}"
              translateFieldKey="model.patient.phone"
              propertyName="mainPhoneNbr.value"
              [dataType]="dataTypeText"
              [defaultValue]="getFilter('mainPhoneNbr.value')"
              (applyFilter)="applyFilter($event)"
            >
            </app-item-filter>
          </th>
          <td mat-cell *matCellDef="let row">{{ row.mainPhoneNbr?.value }}</td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
            {{ "model.patient.email" | translate }}
            <app-item-filter
              fieldName="{{ 'model.patient.email' | translate }}"
              translateFieldKey="model.patient.email"
              propertyName="mainMail.value"
              [dataType]="dataTypeText"
              [defaultValue]="getFilter('mainMail.value')"
              (applyFilter)="applyFilter($event)"
            >
            </app-item-filter>
          </th>
          <td mat-cell *matCellDef="let row">{{ row.mainMail?.value }}</td>
        </ng-container>

        <!-- Healthcare service Column -->
        <ng-container matColumnDef="healthcare">
          <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
            {{ "model.patient.healthcare" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <span *ngFor="let healthcare of row.allServices">{{ healthcare.display }}<br /></span>
          </td>
        </ng-container>

        <!-- Managing organization Column -->
        <ng-container matColumnDef="managingOrganization">
          <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
            {{ "model.patient.organization" | translate }}
            <app-item-filter
              fieldName="{{ 'model.patient.organization' | translate }}"
              translateFieldKey="model.patient.organization"
              propertyName="organization.display"
              [dataType]="dataTypeText"
              [defaultValue]="getFilter('organization.display')"
              (applyFilter)="applyFilter($event)"
            >
            </app-item-filter>
          </th>
          <td mat-cell *matCellDef="let row">{{ row.organization?.display }}</td>
        </ng-container>

        <!-- Gender Column -->
        <ng-container matColumnDef="gender">
          <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
            {{ "model.patient.gender" | translate }}
            <app-item-filter
              fieldName="{{ 'model.patient.gender' | translate }}"
              translateFieldKey="model.patient.gender"
              propertyName="gender"
              [dataType]="dataTypeChoice"
              [data]="dataSource.data"
              [defaultValue]="getFilter('gender')"
              translatePrefix="choiceLabel.gender"
              (applyFilter)="applyFilter($event)"
            >
            </app-item-filter>
          </th>
          <td mat-cell *matCellDef="let row">
            <span class="fas fa-2x fa-mars" *ngIf="row.gender === 'male'"></span>
            <span class="fas fa-2x fa-venus" *ngIf="row.gender === 'female'"></span>
            <span class="fas fa-2x fa-genderless" *ngIf="row.gender !== 'male' && row.gender !== 'female'"></span>
          </td>
        </ng-container>

        <!-- reset password column -->
        <ng-container *ngIf="isUserAuthorizedToReset" matColumnDef="password">
          <th class="title-password-column" mat-header-cell *matHeaderCellDef>
            {{ "model.patient.password" | translate }}
          </th>
          <td class="reset-container" mat-cell *matCellDef="let row">
            <button mat-raised-button color="primary" class="reset-pass-icon" (click)="displayConfirmResetModal(row, $event)">
              <mat-icon>lock</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="updateUser(row)"></tr>
      </table>

      <div class="spinner-container" *ngIf="dataSource.loading$ | async">
        <mat-spinner></mat-spinner>
      </div>
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="currentPageSize" [pageSizeOptions]="isDialog ? [5, 10, 15] : [15, 20, 25]">
      </mat-paginator>
    </div>
    <!-- Message to show when no column is selected -->
    <app-no-column-info *ngIf="this.displayedColumns.length === 0"></app-no-column-info>
  </mat-card>
</div>
