import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute } from "@angular/router";
import { Subject, merge } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { DataType, Filter } from "src/app/models/filter.interface";
import { PreferenceUser, TableParameter } from "src/app/models/preference.interface";
import { UserStatisticsApiService } from "src/app/providers/api/userStatistics-api.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { StatEventsDataSource } from "./statEvents.datasource";

@Component({
  selector: "app-stat-events-page",
  templateUrl: "./stat-events-page.component.html",
  styleUrls: ["./stat-events-page.component.scss"],
})
export class StatEventsPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public eventsDataSource: StatEventsDataSource;
  public displayedColumns: string[] = ["eventDatetime", "eventSource", "description", "servicesIds", "careplans", "userName"];
  public filters: Filter[] = [];
  public isFiltered = false;
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public eventsCount = 0;
  private preferences: TableParameter;
  public currentPageSize: number;
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();

  constructor(
    private sessionService: SessionService,
    private userService: UserService,
    private apiStatService: UserStatisticsApiService,
    private preferenceService: PreferenceService,
    private route: ActivatedRoute
  ) {
    // get data from resolvers
    this.preferences = this.route.snapshot.data.preferences;
    this.filters = this.preferences?.filters ? this.preferences.filters : [];
    this.isFiltered = this.filters && this.filters.length > 0;
    this.currentPageSize = this.preferences?.itemsPerPage ? this.preferences.itemsPerPage : 25;
  }

  ngOnInit(): void {
    this.eventsDataSource = new StatEventsDataSource(this.apiStatService);
    // apply filter
    this.filters?.forEach((filter) => this.eventsDataSource.setFilter(filter));
    this.loadEvents();
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    // on sort or paginate events, load a new page
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.loadEvents();
        })
      )
      .subscribe();

    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentPageSize = this.paginator.pageSize;
      this.updatePreference();
    });
    this.loadEvents();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadEvents() {
    if (!this.userService.isAuthorizedSync(null, "dashboard/statEvents", "GET")) {
      return;
    }
    this.apiStatService
      .countStatEvents(this.getFilters(), this.sessionService.userLang)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((count: number) => {
        this.eventsCount = count;
        this.eventsDataSource.loadData({
          sortId: this.sort.active,
          sortOrder: this.sort.direction,
          pageNumber: this.paginator.pageIndex,
          pageSize: this.paginator.pageSize,
          filters: this.getFilters(),
          lang: this.sessionService.userLang,
        });
      });
  }

  public getFilter(propertyName: string): Filter {
    return this.eventsDataSource.getFilter(propertyName);
  }

  private getFilters(): Filter[] {
    this.filters = this.eventsDataSource.getAllFilters();
    return this.filters;
  }

  public applyFilters(filter: Filter): void {
    this.eventsDataSource.setFilter(filter);
    this.loadEvents();
    this.isFiltered = this.filters && this.filters.length > 0;
    this.updatePreference();
  }

  public clearFilters(): void {
    this.eventsDataSource.clearFilter();
    this.paginator.pageIndex = 0;
    this.filters = [];
    this.isFiltered = false;
    this.loadEvents();
    this.updatePreference();
  }

  private updatePreference() {
    this.preferenceService
      .update({
        context: this.route.snapshot.data.ctx,
        parameters: {
          filters: this.getFilters(),
          itemsPerPage: this.paginator.pageSize,
        } as TableParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: PreferenceUser) => {
        const param = parameters.preferences.find((p) => p.context === this.route.snapshot.data.ctx);
        this.preferences = param.parameters;
      });
  }
}
