import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { FORMS_MODE, FormsData } from "src/app/helpers/formsData";
import { Tools } from "src/app/helpers/tools";
import { IAccessGroup } from "src/app/models/accessGroup.interface";
import { ICountry } from "src/app/models/country.interface";
import { Healthcareservice } from "src/app/models/healthcareservice.model";
import { IOrganization } from "src/app/models/organization.interface";
import { MonitoringCodes, OrganisationType, Organization } from "src/app/models/organization.model";
import { Reference } from "src/app/models/sharedModels.model";
import { AccessService } from "src/app/providers/access.service";
import { AccessGroupApiService } from "src/app/providers/api/accessGroup-api.service";
import { CountriesService } from "src/app/providers/countries.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { OrganizationsService } from "src/app/providers/organizations.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";

@Component({
  selector: "app-add-organization",
  templateUrl: "./add-organization.component.html",
  styleUrls: ["./add-organization.component.scss"],
})
export class AddOrganizationComponent implements OnInit, OnDestroy {
  public availableCountries: ICountry[];

  public organizationForm = this.fb.group({
    name: ["", [Validators.required]],
    reference: ["", [Validators.required]],
    phone: [undefined, [this.phoneValidator]],
    phoneLabel: [{ value: undefined, disabled: true }],
    mail: [undefined, [Validators.email]],
    mailLabel: [{ value: undefined, disabled: true }],
    addressLine1: [""],
    addressLine2: [undefined],
    addressCP: [""],
    addressCity: [""],
    addressCountry: ["BE", [Validators.required]],
    organizationType: [OrganisationType.PROV, [Validators.required]],
    monitoringOrgsLink: [[]],
    defaultAccessGroups: [[]],
  });

  public isCreation = false;
  public organization: Organization;
  public OrganizationTypes = OrganisationType;
  public allAccessGroups: IAccessGroup[] = [];
  public canReadAccessGroups = false;
  public monitoringOrgs: Organization[] = [];
  public monitoringServices: Healthcareservice[] = [];

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();
  private monitLinkSub$: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private readonly dialogRef: MatDialogRef<AddOrganizationComponent>,
    public sessionService: SessionService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    public formsData: FormsData,
    private organizationService: OrganizationsService,
    private healthcareService: HealthcareserviceService,
    private accessGroupsService: AccessService,
    private accessGroupApiService: AccessGroupApiService,
    private userService: UserService,
    private countriesService: CountriesService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      organization: Organization;
      mode: FORMS_MODE;
    }
  ) {
    this.isCreation = this.data.mode === FORMS_MODE.CREATE;
    this.organization = this.data.organization;
  }

  ngOnInit(): void {
    this.checkUsersPermissions();
    this.countriesService
      .list()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((countries) => {
        this.availableCountries = countries;
      });
    this.monitoringOrgs = this.userService.allMonitoringOrganizations;

    if (!this.isCreation) {
      this.updateForm();
    }
    if (this.canReadAccessGroups) {
      this.loadAccessGroups();
    }
    this.organizationForm
      .get("phone")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((r) => {
        if (r) {
          this.organizationForm.get("phoneLabel").enable();
        } else {
          this.organizationForm.get("phoneLabel").disable();
        }
        this.organizationForm.updateValueAndValidity();
      });
    this.organizationForm
      .get("mail")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((r) => {
        if (r) {
          this.organizationForm.get("mailLabel").enable();
        } else {
          this.organizationForm.get("mailLabel").disable();
        }
        this.organizationForm.updateValueAndValidity();
      });
    this.organizationForm
      .get("organizationType")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((type) => {
        if (MonitoringCodes.includes(type)) {
          this.organizationForm.removeControl("monitoringOrgsLink");
          this.organizationForm.removeControl("monitoringServicesLink");
          this.monitLinkSub$?.unsubscribe();
        } else {
          this.organizationForm.addControl("monitoringOrgsLink", new UntypedFormControl([]));
          this.setupMonitOrgWatch();
        }
      });
    this.setupMonitOrgWatch();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setupMonitOrgWatch() {
    if (this.organizationForm.get("monitoringOrgsLink")) {
      this.monitLinkSub$?.unsubscribe();
      this.monitLinkSub$ = this.organizationForm
        .get("monitoringOrgsLink")
        .valueChanges.pipe(takeUntil(this.onDestroy$))
        .subscribe((orgs: Reference[]) => {
          this.monitoringServices = this.filterServicesFromSelectedOrgs(orgs);
          if (orgs && orgs.length === 1) {
            this.organizationForm.addControl("monitoringServicesLink", new UntypedFormControl([], [Validators.required]));
          } else if (!orgs || orgs.length < 1) {
            this.organizationForm.removeControl("monitoringServicesLink");
          } else {
            this.organizationForm.get("monitoringServicesLink").patchValue([]);
          }
        });
    }
  }

  private filterServicesFromSelectedOrgs(orgs: Reference[]): Healthcareservice[] {
    if (!orgs) {
      return [];
    }
    const services = [];
    const allServices = this.userService.allMonitoringServices;
    for (const org of orgs) {
      const s = this.healthcareService.filterServicesByOrgAndMonitoring(org.reference, allServices);
      services.push(...s);
    }
    return services;
  }

  public updateForm(): void {
    const reference = this.data.organization?.identifier?.[0]?.value;
    const phone = this.data.organization?.telecom.find((t) => t.system === "phone");
    const mail = this.data.organization?.telecom.find((t) => t.system === "mail");
    const address = this.data.organization?.address?.[0];
    const defaultAccessGroups = this.data.organization.defaultAccessGroups ? this.data.organization.defaultAccessGroups : [];
    const orgType = this.data.organization?.organizationType;
    const monitLinksRefs: string[] = this.data.organization?.linkedMonitoringOrganizations?.map((link) => link.organizationRef.reference);
    const selectedOrgs: Reference[] = this.monitoringOrgs
      ?.filter((o) => monitLinksRefs?.includes(o.asReference.reference))
      .map((o) => o.asReference);

    this.organizationForm = this.fb.group({
      name: [this.data.organization.name, [Validators.required]],
      reference: [reference, [Validators.required]],
      phone: [phone?.value, [this.phoneValidator]],
      phoneLabel: [
        {
          value: phone?.label ? phone?.label : undefined,
          disabled: phone?.value ? false : true,
        },
      ],
      mail: [mail?.value, [Validators.email]],
      mailLabel: [
        {
          value: mail?.label ? mail?.label : undefined,
          disabled: mail?.value ? false : true,
        },
      ],
      addressLine1: [address?.line[0] ?? undefined],
      addressLine2: [address?.line[1] ?? undefined],
      addressCP: [address?.postalCode ?? undefined],
      addressCity: [address?.city],
      addressCountry: [address?.country ?? "BE", [Validators.required]],
      organizationType: [orgType ?? OrganisationType.PROV, [Validators.required]],
      defaultAccessGroups: [defaultAccessGroups],
    });
    this.organizationForm.get("reference").disable();
    if (this.organizationForm.get("organizationType").value === OrganisationType.PROV) {
      this.organizationForm.addControl("monitoringOrgsLink", new UntypedFormControl(selectedOrgs));
      if (selectedOrgs && selectedOrgs.length > 0) {
        this.monitoringServices = this.filterServicesFromSelectedOrgs(selectedOrgs);
        const monitoringServicesClumps: Reference[][] = this.data.organization?.linkedMonitoringOrganizations?.map(
          (link) => link.servicesRef
        );
        const monitServicesLinkRefs: string[] = monitoringServicesClumps
          ?.reduce((acc, value) => acc.concat(value), [])
          .map((s) => s.reference);
        const selectedMonitServices: Reference[] = this.monitoringServices
          ?.filter((s) => monitServicesLinkRefs?.includes(s.asReference.reference))
          .map((o) => o.asReference);
        this.organizationForm.addControl("monitoringServicesLink", new UntypedFormControl(selectedMonitServices, [Validators.required]));
      }
      this.setupMonitOrgWatch();
    }
  }

  public onSave(): void {
    try {
      if (this.organizationForm.valid) {
        if (this.isCreation) {
          const newOrg = this.createOrganizationModel();
          this.organizationService
            .create(newOrg)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((org) => {
              this.close(org);
              this.snackBar.open(this.translateService.instant("common.saveSuccess"), undefined, {
                duration: 3000,
                horizontalPosition: "center",
                verticalPosition: "top",
              });
            });
        } else {
          const updatedOrg = this.updateOrganizationModel();
          this.organizationService
            .update(updatedOrg)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((org) => {
              this.close(org);
              this.snackBar.open(this.translateService.instant("common.saveSuccess"), undefined, {
                duration: 3000,
                horizontalPosition: "center",
                verticalPosition: "top",
              });
            });
        }
      }
    } catch (err) {
      FileLogger.error("AddOrganizationComponent", "onSave() failed", err);
    }
  }

  private close(newOrg: Organization) {
    this.data.organization = newOrg;
    this.sessionService.needRefreshOrganizationsList();
    this.dialogRef.close(this.data);
    const msg = this.translateService.instant("common.success");
    this.snackBar.open(msg, "ok", { duration: 3000 });
  }

  private createOrganizationModel(): Organization {
    const name = this.organizationForm.get("name").value;
    const reference = "Organization/" + this.organizationForm.get("reference").value;
    const phone = this.organizationForm.get("phone").value;
    const phoneLabel = this.organizationForm.get("phoneLabel").value;
    const mail = this.organizationForm.get("mail").value;
    const mailLabel = this.organizationForm.get("mailLabel").value;
    const addressLine1 = this.organizationForm.get("addressLine1").value;
    const addressLine2 = this.organizationForm.get("addressLine2").value;
    const addressCP = this.organizationForm.get("addressCP").value.toString(10);
    const addressCity = this.organizationForm.get("addressCity").value;
    const addressCountry = this.organizationForm.get("addressCountry").value;
    const orgType = this.organizationForm.get("organizationType").value;

    const newOrganization: IOrganization = {
      _id: null,
      active: true,
      resourceType: "Organization",
      identifier: [
        {
          use: "official",
          system: "http://comunicare.io",
          value: reference,
          label: name,
        },
      ],
      type: {
        coding: [
          {
            system: "http://hl7.org/fhir/organization-type",
            code: orgType,
            display: this.translateService.instant("page.organizations." + orgType),
          },
        ],
      },
      name,
      telecom: [],
      address: [],
      contact: [],
      defaultAccessGroups: this.organizationForm.get("defaultAccessGroups").value,
    };

    if (orgType === OrganisationType.PROV) {
      const monitOrgs: Reference[] = this.organizationForm.get("monitoringOrgsLink").value;
      if (monitOrgs.length > 0) {
        newOrganization.linkedMonitoringOrganizations = [];
        const monitServicesRefs: Reference[] = this.organizationForm.get("monitoringServicesLink").value;
        const servRefs: string[] = monitServicesRefs.map((s) => s.reference);
        for (const org of monitOrgs) {
          const services: Reference[] = this.monitoringServices
            .filter((s) => s.providedBy.reference === org.reference && servRefs.includes(s.asReference.reference))
            .map((s) => s.asReference);
          newOrganization.linkedMonitoringOrganizations.push({
            organizationRef: org,
            servicesRef: services,
          });
        }
      }
    }

    if (phone) {
      newOrganization.telecom.push({
        system: "phone",
        value: phone,
        use: "work",
        label: phoneLabel ?? null,
      });

      newOrganization.contact.push({
        purpose: {
          coding: [
            {
              system: "http://hl7.org/fhir/contactentity-type",
              code: "PRESS",
            },
          ],
        },
        telecom: [
          {
            system: "phone",
            value: phone,
          },
        ],
      });
    }

    if (mail) {
      newOrganization.telecom.push({
        system: "mail",
        value: mail,
        use: "work",
        label: mailLabel ?? null,
      });

      newOrganization.contact.push({
        purpose: {
          coding: [
            {
              system: "http://hl7.org/fhir/contactentity-type",
              code: "PRESS",
            },
          ],
        },
        telecom: [
          {
            system: "mail",
            value: mail,
          },
        ],
      });
    }

    if (addressLine1 && addressCP && addressCity && addressCountry) {
      const adr = {
        use: "work",
        line: [addressLine1],
        city: addressCity,
        postalCode: addressCP,
        country: addressCountry,
      };

      if (addressLine2) {
        adr.line.push(addressLine2);
      }
      newOrganization.address.push(adr);
    }

    return new Organization(newOrganization);
  }

  private updateOrganizationModel(): Organization {
    const name = this.organizationForm.get("name").value;
    const phone = this.organizationForm.get("phone").value;
    const phoneLabel = this.organizationForm.get("phoneLabel").value;
    const mail = this.organizationForm.get("mail").value;
    const mailLabel = this.organizationForm.get("mailLabel").value;
    const addressLine1 = this.organizationForm.get("addressLine1").value;
    const addressLine2 = this.organizationForm.get("addressLine2").value;
    const addressCP = this.organizationForm.get("addressCP").value?.toString(10);
    const addressCity = this.organizationForm.get("addressCity").value;
    const addressCountry = this.organizationForm.get("addressCountry").value;
    const orgType = this.organizationForm.get("organizationType").value;

    this.organization.defaultAccessGroups = this.organizationForm.get("defaultAccessGroups").value;
    this.organization.name = name;
    this.organization.identifier[0].label = name;
    this.organization.type.coding = [
      {
        system: "http://hl7.org/fhir/organization-type",
        code: orgType,
        display: this.translateService.instant("page.organizations." + orgType),
      },
    ];
    this.organization.linkedMonitoringOrganizations = [];

    if (orgType === OrganisationType.PROV) {
      const monitOrgs: Reference[] = this.organizationForm.get("monitoringOrgsLink").value;
      if (monitOrgs.length > 0) {
        const monitServicesRefs: Reference[] = this.organizationForm.get("monitoringServicesLink").value;
        const servRefs: string[] = monitServicesRefs.map((s) => s.reference);
        for (const org of monitOrgs) {
          const services: Reference[] = this.monitoringServices
            .filter((s) => s.providedBy.reference === org.reference && servRefs.includes(s.asReference.reference))
            .map((s) => s.asReference);
          this.organization.linkedMonitoringOrganizations.push({
            organizationRef: org,
            servicesRef: services,
          });
        }
      }
    }

    const oldPhone = this.organization.telecom?.find((t) => t.system === "phone");
    if (oldPhone) {
      oldPhone.value = phone;
      oldPhone.label = phoneLabel ?? undefined;
    } else {
      if (phone) {
        this.organization.telecom.push({
          system: "phone",
          value: phone,
          use: "work",
          label: phoneLabel ?? undefined,
        });
      }
    }

    const oldContactPhone = this.organization.contact?.find((t) => t.telecom[0].system === "phone");
    if (oldContactPhone) {
      oldContactPhone.telecom[0].value = phone;
    } else {
      if (phone) {
        this.organization.contact.push({
          purpose: {
            coding: [
              {
                system: "http://hl7.org/fhir/contactentity-type",
                code: "PRESS",
              },
            ],
          },
          telecom: [
            {
              system: "phone",
              value: phone,
            },
          ],
        });
      }
    }

    const oldMail = this.organization.telecom?.find((t) => t.system === "mail");
    if (oldMail) {
      oldMail.value = mail;
      oldMail.label = mailLabel ?? undefined;
    } else {
      if (mail) {
        this.organization.telecom.push({
          system: "mail",
          value: mail,
          use: "work",
          label: mailLabel ?? undefined,
        });
      }
    }

    const oldContactMail = this.organization.contact?.find((t) => t.telecom[0].system === "mail");
    if (oldContactMail) {
      oldContactMail.telecom[0].value = mail;
    } else {
      if (mail) {
        this.organization.contact.push({
          purpose: {
            coding: [
              {
                system: "http://hl7.org/fhir/contactentity-type",
                code: "PRESS",
              },
            ],
          },
          telecom: [
            {
              system: "mail",
              value: mail,
            },
          ],
        });
      }
    }

    const oldAddress = this.organization.address?.[0];
    if (oldAddress) {
      oldAddress.line[0] = addressLine1;
      oldAddress.line[1] = addressLine2;
      if (!oldAddress.line[1]) oldAddress.line.splice(1, 1);
      oldAddress.postalCode = addressCP;
      oldAddress.city = addressCity;
      oldAddress.country = addressCountry;
    } else {
      const adr = {
        use: "work",
        line: [addressLine1],
        city: addressCity,
        postalCode: addressCP,
        country: addressCountry,
      };
      if (addressLine2) {
        adr.line.push(addressLine2);
      }
      this.organization.address.push(adr);
    }

    return this.organization;
  }

  public phoneValidator(control: AbstractControl): { [key: string]: true } | null {
    return Tools.phoneValidator(control);
  }

  private checkUsersPermissions(): void {
    this.canReadAccessGroups = this.userService.isAuthorizedSync(null, this.accessGroupApiService.readRoutes[0], "GET");
  }

  /**
   * Loads all the access groups
   * @returns true if it was loaded without error, false otherwise
   */
  private async loadAccessGroups(): Promise<boolean> {
    if (!this.canReadAccessGroups) {
      return false;
    }
    try {
      this.allAccessGroups = await this.accessGroupsService.readAccessGroups();
      return true;
    } catch (err) {
      FileLogger.error("AddOrganizationComponent", "Error while loading access groups: ", err);
      return false;
    }
  }
}
