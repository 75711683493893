import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Reference } from "src/app/models/reference.interface";

@Pipe({
  name: "truncateList",
})
export class TruncateListPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  /**
   * Transforms a list of Reference objects by removing duplicates, truncating
   * the list to the first three unique items, and formatting them as a string.
   * Additionally, it generates a tooltip text showing all items or indicating
   * the number of additional items if the list exceeds three unique items.
   *
   * @param {Reference[]} list - The list of Reference objects to be transformed.
   * @param {string} _currentLang - The current language, used only to trigger the
   *                                pipe refresh when the language changes.
   * @returns {{ truncatedText: string, tooltipText: string }}
   */
  transform(list: Reference[], _currentLang: string): { truncatedText: string; tooltipText: string } {
    // Use a Set to eliminate duplicates
    const uniqueList = Array.from(new Set(list.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));

    const truncatedList = uniqueList.slice(0, 3); // Get the first three unique items
    const formattedList = truncatedList.map((item) => `${item.display} (${item.reference})`);
    let tooltipText = formattedList.join(", "); // Join the first three items with a comma and space
    if (uniqueList.length > 3) {
      const moreCount = uniqueList.length - 3;
      const moreTranslation = this.translateService.instant("tooltip.more", { count: moreCount });
      tooltipText += ` ${moreTranslation}.`; // Add "and x more" if there are more than three unique items
    }
    return { truncatedText: formattedList.join(", ") + (uniqueList.length > 3 ? ", ..." : ""), tooltipText };
  }
}
