import { Clipboard } from "@angular/cdk/clipboard";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { PractitionerService } from "src/app/providers/practitioner.service";

@Component({
  selector: "app-display-authcode-confirmation",
  templateUrl: "./display-authcode-confirmation.component.html",
  styleUrls: ["./display-authcode-confirmation.component.scss"],
})
export class DisplayAuthcodeConfirmationComponent implements OnInit, OnDestroy {
  public sendAuthcode = false;

  authcodeCopied: boolean;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { currentUser: { mail: string; name: string; firstname: string }; authcode: string; preference?: boolean },
    private dialogRef: MatDialogRef<DisplayAuthcodeConfirmationComponent>,
    private practitionerService: PractitionerService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.authcodeCopied = false;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public closeModal(): void {
    if (this.sendAuthcode) {
      this.sendMailForAuthcode()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          () => {
            this.translateService
              .get("common.sendMailSuccess")
              .pipe(takeUntil(this.onDestroy$))
              .subscribe((trans) => {
                this.snackBar.open(trans, "ok", { duration: 3000 });
                this.dialogRef.close();
              });
          },
          (err) => {
            FileLogger.error("DisplayAuthcodeConfirmationComponent", "closeModal", err);
            this.translateService
              .get("common.sendMailFailed")
              .pipe(takeUntil(this.onDestroy$))
              .subscribe((trans) => {
                this.snackBar.open(trans, "ok", { duration: 10000 });
                this.dialogRef.close();
              });
          }
        );
    } else {
      this.dialogRef.close();
    }
  }

  private sendMailForAuthcode(): Observable<Record<string, never>> {
    const mail = this.data.currentUser.mail;
    const authcode = this.data.authcode;

    return this.practitionerService.sendPractitionerPassword(mail, authcode);
  }

  public copyToClipboard(): void {
    const authcodeValue = document.getElementById("authcode-display").innerText;
    this.clipboard.copy(authcodeValue);
    this.authcodeCopied = true;
  }
}
