import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DataType, Filter } from "src/app/models/filter.interface";
import { ACTION_REWARD, IAction } from "src/app/models/rewardScore.interface";
import { RewardScore } from "src/app/models/rewardScore.model";
import { SessionService } from "src/app/providers/session.service";
import { RewardHistoricDataSource } from "./reward-historic-datasource";

@Component({
  selector: "app-reward-details-historic",
  templateUrl: "./reward-details-historic.component.html",
  styleUrls: ["./reward-details-historic.component.scss"],
})
export class RewardDetailsHistoricComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() reward: RewardScore;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<IAction>;
  public dataSource: RewardHistoricDataSource;
  public currentPageSize: number;
  public displayedColumns: string[] = ["date", "action", "comment"];
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(private translationService: TranslateService, private sessionService: SessionService) {
    this.sessionService.refreshRewardDetailsDataList.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.dataSource.loadData(this.reward);
    });
  }

  ngOnInit(): void {
    this.dataSource = new RewardHistoricDataSource(this.reward);
    this.dataSource.loadData(this.reward);
  }

  ngAfterViewInit(): void {
    this.initPaginatorAndSort();
    setTimeout(() => {
      this.sort?.sort({
        id: "date",
        start: "desc",
        disableClear: false,
      });
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public getComment(actionHistory: IAction): string {
    const comment = this.reward?.notes?.[actionHistory.commentId]?.text;
    if (comment?.length > 100) {
      return comment.substring(0, 100) + "...";
    } else {
      return comment;
    }
  }

  public getTranslations(actions: ACTION_REWARD[]): string {
    return this.reward.getActionTranslateKey(actions, this.translationService);
  }

  private initPaginatorAndSort() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    // this.table.dataSource = this.dataSource;
    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentPageSize = this.paginator.pageSize;
    });
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
  }

  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }
}
