import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { Tools } from "src/app/helpers/tools";
import { DataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { Filter } from "src/app/models/filter.interface";
import { IStatEvent, IStatSummary } from "src/app/models/userStatistics.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class UserStatisticsApiService extends BaseApiService {
  constructor(private api: ApiService, private translateService: TranslateService) {
    super(
      "UserStatistics", // Theme !
      [], // Post route paths
      ["dashboard/statisticSummary", "dashboard/statEvents"], // Get route paths
      [], // Put route paths
      [] // Delete route paths
    );
  }

  public listSummaries(from: string, to: string): Observable<IStatSummary[]> {
    return this.api.get(this.readRoutes[0], { from, to }) as Observable<IStatSummary[]>;
  }

  public listStatEvents(p: DataSourceBackendParam): Observable<IStatEvent[]> {
    const copyParams = Tools.clone(p);
    const params: any = {
      pageNumber: copyParams.pageNumber,
      pageSize: copyParams.pageSize,
      sortOrder: copyParams.sortOrder,
      lang: copyParams.lang ? copyParams.lang : this.translateService.currentLang,
    };
    if (Tools.isDefined(copyParams.filters) && !ArrayHelper.isEmpty(copyParams.filters)) {
      const sourceFilter = copyParams.filters.find((f) => f.propertyName === "eventSource");
      if (sourceFilter) {
        sourceFilter.data = { value: sourceFilter.data.filter((d) => d.checked).map((d) => d.value) };
      }
      params.filters = JSON.stringify(copyParams.filters);
    }
    if (Tools.isDefined(copyParams.sortId)) {
      params.sortId = copyParams.sortId;
    }
    return this.api.get(this.readRoutes[1], params) as Observable<IStatEvent[]>;
  }

  public countStatEvents(filters?: Filter[], lang?: string): Observable<number> {
    const copyFilters = Tools.clone(filters);
    const params: any = {
      lang: lang ? lang : this.translateService.currentLang,
    };
    if (Tools.isDefined(copyFilters) && !ArrayHelper.isEmpty(copyFilters)) {
      const sourceFilter = copyFilters.find((f) => f.propertyName === "eventSource");
      if (sourceFilter) {
        sourceFilter.data = { value: sourceFilter.data.filter((d) => d.checked).map((d) => d.value) };
      }
      params.filters = JSON.stringify(copyFilters);
    }
    return this.api.get("dashboard/countStatEvents", params) as Observable<number>;
  }

  public updateStatSummary(statToIncrement: string): Observable<IStatSummary> {
    return this.api.put("dashboard/statisticSummary", { statName: statToIncrement }) as Observable<IStatSummary>;
  }

  public createStatEvent(eventDescription: string, servicesIds?: string[], careplansIds?: string[]): Observable<IStatEvent> {
    const body: any = {
      eventDescription,
    };
    if (Tools.isDefined(servicesIds) && !ArrayHelper.isEmpty(servicesIds)) {
      body.servicesIds = servicesIds.join(",");
    }
    if (Tools.isDefined(careplansIds) && !ArrayHelper.isEmpty(careplansIds)) {
      body.careplansIds = careplansIds.join(",");
    }
    return this.api.post("dashboard/statEvent", body) as Observable<IStatEvent>;
  }
}
