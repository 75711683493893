import { Component, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { IAccessGroup } from "src/app/models/accessGroup.interface";
import { AccessGroupComponent } from "../access-group/access-group.component";

@Component({
  selector: "app-access-group-dialog",
  templateUrl: "./access-group-dialog.component.html",
  styleUrls: ["./access-group-dialog.component.scss"],
})
export class AccessGroupDialogComponent implements OnDestroy {
  @ViewChild(AccessGroupComponent)
  private accessGroupComponent: AccessGroupComponent;
  public MODES = FORMS_MODE;
  public saveInProgress = false;

  /** Subject that emits when the component has been destroyed. */
  // tslint:disable-next-line: variable-name
  private onDestroy$ = new Subject<void>();

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<AccessGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { mode: FORMS_MODE; allAccessGroups: IAccessGroup[]; accessGroup?: IAccessGroup }
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public async save(): Promise<void> {
    if (this.saveInProgress) {
      return;
    }
    this.saveInProgress = true;
    try {
      const group = await this.accessGroupComponent.save();
      this.saveInProgress = false;
      if (group) {
        this.dialogRef.close({ success: true, data: group });
      }
    } catch (err) {
      FileLogger.error("AccessGroupDialogComponent", "Error while saving access group", err);
      this.saveInProgress = false;
      this.snackBar
        .open(`❗${this.translateService.instant("accessGroups.savingGroupError")}`, null, { duration: 5000 })
        .afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe();
    }
  }

  public cancel(): void {
    this.dialogRef.close({ success: false, data: null });
  }
}
