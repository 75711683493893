<form [formGroup]="filterForm">
  <div class="close-cross">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <h2 mat-dialog-title>{{ data?.fieldName }}</h2>

  <mat-dialog-content>
    <mat-form-field>
      <input
        cdkFocusInitial
        type="text"
        matInput
        placeholder="{{ 'itemFilter.containing' | translate }}"
        formControlName="value"
        aria-label="Text filter input"
      />
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="clear()" aria-label="Clear filter">
      {{ "itemFilter.clear" | translate }}
    </button>
    <button mat-raised-button color="primary" [disabled]="!filterForm.valid" (click)="apply()" aria-label="Apply filter" type="submit">
      {{ "itemFilter.apply" | translate }}
    </button>
  </mat-dialog-actions>
</form>
