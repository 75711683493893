/** Accepts values labeled with units. If number, treat as pixels. */
export type ILayoutDimension = string | number;

export type IPDFFormat = "Letter" | "Legal" | "Tabloid" | "Ledger" | "A0" | "A1" | "A2" | "A3" | "A4" | "A5" | "A6";

export interface IPDFOptions {
  /**
   * The file path to save the PDF to.
   * If `path` is a relative path, then it is resolved relative to current working directory.
   * If no path is provided, the PDF won't be saved to the disk.
   */
  path?: string | undefined;
  /**
   * Scale of the webpage rendering.
   * @default 1
   */
  scale?: number | undefined;
  /**
   * Display header and footer.
   * @default false
   */
  displayHeaderFooter?: boolean | undefined;
  /**
   * HTML template for the print header. Should be valid HTML markup with following classes used to inject printing values into them:
   * - `date` formatted print date
   * - `title` document title
   * - `url` document location
   * - `pageNumber` current page number
   * - `totalPages` total pages in the document
   */
  headerTemplate?: string | undefined;
  /**
   * HTML template for the print footer. Should be valid HTML markup with following classes used to inject printing values into them:
   * - `date` formatted print date
   * - `title` document title
   * - `url` document location
   * - `pageNumber` current page number
   * - `totalPages` total pages in the document
   */
  footerTemplate?: string | undefined;
  /**
   * Print background graphics.
   * @default false
   */
  printBackground?: boolean | undefined;
  /**
   * Paper orientation.
   * @default false
   */
  landscape?: boolean | undefined;
  /**
   * Paper ranges to print, e.g., '1-5, 8, 11-13'.
   * @default '' which means print all pages.
   */
  pageRanges?: string | undefined;
  /**
   * Paper format. If set, takes priority over width or height options.
   * @default 'Letter'
   */
  format?: IPDFFormat | undefined;
  /** Paper width. */
  width?: ILayoutDimension | undefined;
  /** Paper height. */
  height?: ILayoutDimension | undefined;
  /** Paper margins, defaults to none. */
  margin?:
    | {
        /** Top margin. */
        top?: ILayoutDimension | undefined;
        /** Right margin. */
        right?: ILayoutDimension | undefined;
        /** Bottom margin. */
        bottom?: ILayoutDimension | undefined;
        /** Left margin. */
        left?: ILayoutDimension | undefined;
      }
    | undefined;
  /**
   * Give any CSS @page size declared in the page priority over what is declared in width and
   * height or format options.
   * @default false which will scale the content to fit the paper size.
   */
  preferCSSPageSize?: boolean | undefined;
}

export enum CANAL {
  RESPONSE = 0,
  EMAIL = 1,
}

export interface IDocumentInfo {
  organizationName: string;
  healthserviceName: string;
  patientFullName?: string;
  patientGender?: string;
  patientBirthdate?: string;
  patientInternalNumber?: string;
}

export interface ISendingCanal {
  canal: CANAL;
  email?: string;
}

export interface IExportPdf {
  documentInfo: IDocumentInfo;
  sendingCanal: ISendingCanal;
  lang: string;
  title: string;
  content: string;
  preserveTempFile?: boolean;
  pdfOptions?: IPDFOptions;
}
