import { Component, Input } from "@angular/core";
import { Healthcareservice } from "src/app/models/healthcareservice.model";
import { Organization } from "src/app/models/organization.model";

@Component({
  selector: "app-service-details-component",
  templateUrl: "./service-details-component.component.html",
  styleUrls: ["./service-details-component.component.scss"],
})
export class ServiceDetailsComponentComponent {
  public dummyPhotoPath = "../../../assets/images/dummy-photos/dummyService.jpeg";

  @Input() service: Healthcareservice;
  @Input() organization: Organization;

  private showMoreTelecom() {
    // TODO CMATE-3567 show all telecom and commentary of service
  }

  public photoSrc(): string {
    return this.service?.photo ? this.service.photo : this.dummyPhotoPath;
  }
}
