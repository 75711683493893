<ng-container *ngIf="!noPercentile; else noData">
  <ng-container *ngIf="!loading">
    <div class="legend-container mt-1" [style.paddingLeft]="margin + 'px'" [style.paddingRight]="margin + 'px'">
      <div *ngFor="let legend of legends" class="legend-element">
        <div class="legend-icon" [ngStyle]="{ backgroundColor: legend.color }"></div>
        <span>{{ legend.display }}</span>
      </div>
    </div>
  </ng-container>

  <div #chartContainer class="chart-container">
    <figure [id]="chartId" class="m-0" style="text-align: center" [ngStyle]="{ display: !loading ? 'block' : 'none' }"></figure>
    <div class="spinner-container" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</ng-container>

<ng-template #noData>
  <p class="text-center mt-10 pb-10" *ngIf="!loading">
    {{ "widget.noData" | translate }}
  </p>
</ng-template>
