<div class="close-cross">
  <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div *ngIf="isLoading" class="loading">
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div *ngIf="!isLoading">
  <h2>{{ "ansPractitionnerResult.title" | translate }}</h2>
  <h3>{{ "ansPractitionnerResult.subtitle" | translate }} : {{ practitionners.length }}</h3>
  <div style="display: flex; flex-direction: row; overflow: auto">
    <mat-card
      *ngFor="let p of practitionners; let i = index"
      (click)="indexSelected = i"
      [style.border]="i === indexSelected ? '3px solid var(--comunicareBlue)' : ''"
      style="min-width: 300px; margin: 0px 5px 10px 0px; height: 500px; overflow-y: auto"
    >
      <mat-list>
        <mat-list-item *ngIf="p.title">
          <p>
            <b>{{ "forms.title.title" | translate }} :</b>
            {{ p.title }}
          </p>
        </mat-list-item>
        <mat-list-item *ngIf="p.name">
          <p>
            <b>{{ "forms.name" | translate }} :</b>
            {{ p.name }}
          </p>
        </mat-list-item>
        <mat-list-item *ngIf="p.firstname">
          <p>
            <b>{{ "forms.firstname" | translate }} :</b>
            {{ p.firstname }}
          </p>
        </mat-list-item>
        <mat-list-item *ngIf="p.birthdate">
          <p>
            <b>{{ "forms.birthdate" | translate }} :</b>
            {{ p.birthdate }}
          </p>
        </mat-list-item>
        <mat-list-item *ngIf="p.gender">
          <p>
            <b>{{ "forms.gender" | translate }} :</b>
            {{ p.gender | getGenderDisplay }}
          </p>
        </mat-list-item>
        <mat-list-item *ngIf="p.nationalNumber">
          <p>
            <b>{{ "forms.nationalNumber" | translate }} :</b>
            {{ p.nationalNumber }}
          </p>
        </mat-list-item>
        <mat-list-item *ngIf="p.language">
          <p>
            <b>{{ "forms.userLang" | translate }} :</b>
            {{ p.language }}
          </p>
        </mat-list-item>
        <mat-list-item *ngIf="p.practitionerReferenceNumber">
          <p>
            <b>{{ "forms.inami" | translate }} :</b>
            {{ p.practitionerReferenceNumber }}
          </p>
        </mat-list-item>
        <mat-list-item *ngIf="p.telecom?.value" style="margin-bottom: 5px">
          <p>
            <b>{{ "forms.msSante" | translate }} :</b>
            {{ p.telecom.value }}
          </p>
        </mat-list-item>
        <fieldset *ngFor="let role of p.ansRole" style="margin-top: 2px">
          <mat-list-item *ngIf="role.profession">
            <p>
              <b>{{ "forms.profession" | translate }} :</b>
              <span *ngIf="role.profession !== NOT_FOUND_MSG">
                {{ role.profession }}
              </span>
              <a *ngIf="role.profession === NOT_FOUND_MSG" href="mailto:contact@comunicare.be">
                {{ "forms.profession_not_found" | translate }}</a
              >
            </p>
          </mat-list-item>
          <mat-list-item *ngIf="role.specialty">
            <p>
              <b>{{ "forms.specialty" | translate }} :</b>
              <span *ngIf="role.specialty !== NOT_FOUND_MSG">
                {{ role.specialty }}
              </span>
              <a *ngIf="role.specialty === NOT_FOUND_MSG" href="mailto:contact@comunicare.be">
                {{ "forms.specialty_not_found" | translate }}</a
              >
            </p>
          </mat-list-item>
          <mat-list-item *ngIf="role.categorie">
            <p>
              <b>{{ "forms.categorie" | translate }} :</b>
              <span *ngIf="role.categorie !== NOT_FOUND_MSG">
                {{ role.categorie }}
              </span>
              <a *ngIf="role.categorie === NOT_FOUND_MSG" href="mailto:contact@comunicare.be">
                {{ "forms.categorie_not_found" | translate }}</a
              >
            </p>
          </mat-list-item>
        </fieldset>
      </mat-list>
    </mat-card>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="validate()">
      {{ "btn.validate" | translate }}
    </button>
    &nbsp;
    <button mat-raised-button [mat-dialog-close]="true">
      {{ "btn.cancel" | translate }}
    </button>
  </mat-dialog-actions>
</div>
