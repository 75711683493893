import { ErrorHandler, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { KnowledgeApiService } from "../api/knowledge-api.service";

@Injectable({
  providedIn: "root",
})
export class KnowledgeDetailsResolver implements Resolve<unknown> {
  constructor(private knowledgeApiService: KnowledgeApiService, private globalErrorHandler: ErrorHandler) {}

  resolve(route: ActivatedRouteSnapshot): Observable<unknown> {
    return this.knowledgeApiService.getKnowledgeDetails(route.params.id).pipe(
      catchError((err) => {
        return throwError(this.globalErrorHandler.handleError(err));
      })
    );
  }
}
