import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SMS_TYPE } from "../models/UnknownPatientData.interface";
import { IAppointment } from "../models/appointment.interface";
import { Appointment } from "../models/appointment.model";
import { StatusEntity } from "../models/entity.interface";
import { IPatientAppointement } from "../models/patient-appointement.interface";
import { AppointmentApiService } from "./api/appointment-api.service";

export const SYSTEM_JITSI = "meetjitsi";
export const SYSTEM_JITSI_PRACTITIONER = "meetjitsi_practitioner";

@Injectable({
  providedIn: "root",
})
export class AppointmentService {
  constructor(private appointmentApiService: AppointmentApiService) {}

  public list(): Observable<Appointment[]> {
    return this.appointmentApiService.list().pipe(
      map((values) => {
        return values.map((v) => new Appointment(v));
      })
    );
  }

  public getPatientAppointement(name: string): Observable<IPatientAppointement[]> {
    return this.appointmentApiService.getPatientAppointement(name);
  }

  public listVisioAppointements(patientId: string, allAppointments?: boolean): Observable<Appointment[]> {
    return this.appointmentApiService.listVisioAppointements(patientId, allAppointments).pipe(
      map((values) => {
        return values.filter((app) => app.entityStatus.indexOf(StatusEntity.ACTIVE) >= 0).map((v) => new Appointment(v));
      })
    );
  }

  /**
   * delete an appointement and the refresh view
   */
  public deleteApp(app: Appointment): Observable<unknown> {
    const i = app.entityStatus.indexOf(StatusEntity.ACTIVE);
    app.entityStatus[i] = StatusEntity.CANCELLED;
    if (app.unknowPatientData) {
      app.unknowPatientData.smsMailSent = false;
      app.unknowPatientData.smsType = SMS_TYPE.DELETE;
    }
    return this.appointmentApiService.update(app.interface);
  }

  public onlyFuturActiveApp(appointements: Appointment[]): Appointment[] {
    return appointements
      .filter((app) => {
        const isActive = app.entityStatus.indexOf(StatusEntity.ACTIVE) >= 0;
        return moment(app.start).isSameOrAfter(moment(), "day") && isActive;
      })
      .sort((a, b) => this.compare(a, b));
  }

  private compare(a: Appointment, b: Appointment) {
    const dateA = moment(a.start);
    const dateB = moment(b.start);
    return dateA.isBefore(dateB) ? -1 : 1;
  }

  public createVisioAppointement(app: IAppointment): Observable<Appointment> {
    return this.appointmentApiService.createVisioAppointement(app);
  }

  public updateVisioAppointement(app: IAppointment): Observable<Appointment> {
    return this.appointmentApiService.update(app);
  }
}
