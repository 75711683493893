<div class="buttons-bar">
  <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<h2 mat-dialog-title>{{ "dialog.alertsHistory.title" | translate }}</h2>

<ui-alert [options]="{ type: 'info' }">{{ "dialog.alertsHistory.info" | translate }}</ui-alert>

<div mat-dialog-content>
  <table>
    <thead>
      <tr>
        <th>{{ "table.date" | translate }}</th>
        <th>Moment</th>
        <th *ngFor="let compo of alerts[0].originalComponents">
          {{
            ((data.obsDefinitions | findObsDefByLoinc : compo.code)
              ? (data.obsDefinitions | findObsDefByLoinc : compo.code).nameTranslation
              : (currentObsDef.components | findObsDefComponentsByLoinc : compo.code.coding[0].code).nameTranslation
            ) | getTranslation : compo.code.coding[0].code
          }}
        </th>
        <th>{{ "table.level" | translate }}</th>
        <th>{{ "table.resultsPractitionner" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let alert of alerts">
        <td>{{ alert.originalIssued | date : "short" }}</td>
        <td>{{ alert.originalEffectiveTiming?.repeat?.when?.code | getEffectiveTiming : currentObsDef | getTranslation : "-" }}</td>
        <td *ngFor="let comp of alert.originalComponents">
          {{
            comp?.code?.coding[0].code
              | loincAndValueMeaning
                : comp.parentObservation?.code?.coding[0].code
                : comp.valueQuantity.value
                : [currentObsDef]
                : true
                : true
                : false
          }}
        </td>
        <td><mat-icon class="custom-icon-small" [ngClass]="alert.rule | alertLevelClass">notifications</mat-icon></td>
        <td>{{ alert.rule.resultsPractitionner[0]?.value | getTranslation : "-" }}</td>
      </tr>
    </tbody>
  </table>
</div>
