<div class="alertPreferenceDialog">
  <div class="buttons-bar">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close matTooltip="{{ 'btn.close' | translate }}">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <h2>{{ "alerts.preferenceTitle" | translate }}</h2>
    <mat-checkbox [(ngModel)]="showAlerts.levelOne" [checked]="showAlerts.levelOne">
      {{ "alerts.showLevelOneAlert" | translate }} </mat-checkbox
    ><br />
    <mat-checkbox [(ngModel)]="showAlerts.levelTwo" [checked]="showAlerts.levelTwo">
      {{ "alerts.showLevelTwoAlert" | translate }} </mat-checkbox
    ><br />
    <mat-checkbox [(ngModel)]="showAlerts.levelThree" [checked]="showAlerts.levelThree">
      {{ "alerts.showLevelThreeAlert" | translate }} </mat-checkbox
    ><br />
    <div class="actionButton">
      <button mat-raised-button color="primary" type="button" (click)="onSave()">
        {{ "common.save" | translate }}
      </button>
    </div>
  </mat-dialog-content>
</div>
