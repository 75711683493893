import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { IKnowledgeBase, KNOW_CATEGORY, KNOW_DOC_CATEGORY } from "src/app/models/knowledge.interface";
import { STATUS_ENTITY } from "src/app/models/sharedInterfaces";
import { KnowledgeService } from "src/app/providers/knowledge.service";
import { SessionService } from "src/app/providers/session.service";
import { CustomErrorStateMatcher } from "../../../helpers/formValidators";

@Component({
  selector: "app-add-knowledge",
  templateUrl: "add-knowledge.component.html",
  styleUrls: ["add-knowledge.component.scss"],
})
export class KnowledgeCreationDialogComponent implements OnInit {
  public matcher = new CustomErrorStateMatcher();
  public KNOW_CATEGORY = KNOW_CATEGORY;
  public KNOW_DOC_CATEGORY = KNOW_DOC_CATEGORY;
  public SelectedDocCategory: KNOW_DOC_CATEGORY;
  public knowledgeCategories: number[];
  private previousLocation: string;

  public knowledgeForm: UntypedFormGroup = this.fb.group({
    term: [[], [Validators.required]],
    reference: ["", [Validators.required]],
    public: ["", []],
    category: [{ value: "", disabled: true }, [Validators.required]],
    documentCategory: ["", [Validators.required]],
  });

  constructor(
    public dialogRef: MatDialogRef<KnowledgeCreationDialogComponent>,
    private fb: UntypedFormBuilder,
    private sessionService: SessionService,
    private knowledgeService: KnowledgeService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { previousLocation: string }
  ) {}

  ngOnInit(): void {
    this.previousLocation = this.data?.previousLocation;
    this.knowledgeForm.get("documentCategory").valueChanges.subscribe((value) => {
      this.SelectedDocCategory = value;
      if (this.SelectedDocCategory) {
        // reset category value
        this.knowledgeForm.get("category").setValue("");
        // enable field
        this.knowledgeForm.get("category").enable();
      }
      this.computeCategories(this.SelectedDocCategory);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public apply(): void {
    if (this.knowledgeForm.valid) {
      const knowledge: IKnowledgeBase = {
        author: {
          reference: this.sessionService.account.caremateIdentifier,
          display: this.sessionService.account.displayName,
        },
        organization: this.sessionService.organization,
        healthcareservice: [this.sessionService.currentService],
        snomedReference: {
          reference: this.knowledgeForm.get("reference").value,
          display: this.knowledgeForm.get("term").value,
        },
        category: this.knowledgeForm.get("category").value,
        documentCategory: this.knowledgeForm.get("documentCategory").value,
        medias: [],
        entityStatus: [STATUS_ENTITY.ACTIVE],
      };

      if (this.knowledgeForm.get("public").value) {
        knowledge.entityStatus.push(STATUS_ENTITY.SHARED);
      }

      this.knowledgeService.createDraft(knowledge).subscribe((k: IKnowledgeBase) => {
        this.router.navigate(["/knowledgeDetails", { id: k.identifier.value }], { state: { previousLocation: this.previousLocation } });
        this.dialogRef.close();
      });
    }
  }

  private computeCategories(docCat: number) {
    switch (docCat) {
      case KNOW_DOC_CATEGORY.CONSENT:
        this.knowledgeCategories = [KNOW_CATEGORY.CONSENT_PATIENT, KNOW_CATEGORY.CONSENT_RELATED, KNOW_CATEGORY.CONSENT_TPDA_GENERIC];
        break;

      case KNOW_DOC_CATEGORY.SIDEEFFECT:
        this.knowledgeCategories = [KNOW_CATEGORY.DRUG];
        break;

      default:
        this.knowledgeCategories = [
          KNOW_CATEGORY.DEVICE,
          KNOW_CATEGORY.DRUG,
          KNOW_CATEGORY.MEDICAL,
          KNOW_CATEGORY.OBSERVATION,
          KNOW_CATEGORY.PATHOLOGY,
          KNOW_CATEGORY.THERAPY,
        ];
        break;
    }
  }
}
