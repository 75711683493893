import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export enum insType {
  NIR = "1.2.250.1.213.1.4.8",
  NIR_TEST = "1.2.250.1.213.1.4.10",
  NIR_DEMO = "1.2.250.1.213.1.4.11",
  NIA = "1.2.250.1.213.1.4.9",
}

export class MatriculeInsHelper {
  public static computeDisplayOID(oid: string): string {
    switch (oid) {
      case insType.NIR:
        return "INS - NIR";
      case insType.NIR_TEST:
        return "INS - NIR de test";
      case insType.NIR_DEMO:
        return "INS - NIR de démonstration";
      case insType.NIA:
        return "INS - NIA";
      default:
        return "";
    }
  }

  public static insValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    let ins: string = control.value;
    if (ins && ins?.length !== 15) {
      return { invalidIns: true };
    } else if (ins && ins?.length === 15) {
      ins = ins.replace("2A", "19");
      ins = ins.replace("2B", "18");
      const key = +ins.slice(-2);
      const result = 97 - (+ins.slice(0, ins.length - 2) % 97);
      return result !== key ? { invalidIns: true } : null;
    }
  };

  /**
   * Split string by commas, spaces and hyphens
   */
  public static namesStringToArray(names: string): string[] {
    if (!names) return [];

    return names
      .replace(/\s/g, "-") // replace " " by "-"
      .replace(/,/g, "-")
      .replace(/--/g, "-") // avoid multiple "-"
      .split("-")
      .map((el) => {
        el = el.trim();
        el = el.toUpperCase();
        return el;
      })
      .filter(Boolean);
  }
}
