import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IRewardDefinition } from "src/app/models/rewardDefinition.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class RewardDefinitionApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "RewardDefinition", // Theme !
      [], // Post route paths
      ["dashboard/rewardDefinitions"], // Get route paths
      [], // Put route paths
      [] // Delete route paths
    );
  }

  public listRewardDefinitions(identifiers?: string[]): Observable<IRewardDefinition[]> {
    return this.api.get(this.readRoutes[0], { identifiers }) as Observable<IRewardDefinition[]>;
  }
}
