<div class="buttons-bar">
  <button
    mat-icon-button
    class="negative-margin align-middle color-button"
    matTooltipClass="helpTooltip"
    matTooltipPosition="left"
    #tooltip="matTooltip"
    matTooltip="{{ 'globalHelp.addVideoCallHelpP1' | translate }}"
    aria-label="icon button with a ? icon"
    (click)="tooltip.toggle()"
  >
    <mat-icon>help_outline</mat-icon>
  </button>
  <button
    *ngIf="!this.data.vsHistory"
    mat-icon-button
    color="primary"
    class="negative-margin align-middle"
    (click)="closeWithSave()"
    [disabled]="!mustUpdate"
    matTooltip="{{ 'common.save' | translate }}"
  >
    <mat-icon>save</mat-icon>
  </button>
  <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close matTooltip="{{ 'btn.close' | translate }}">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <h2>{{ def.nameMobile | getTranslation : "" }}</h2>
  <app-vital-sign-form [def]="def" [vitalSign]="vitalSign" (formUpdateEvent)="updateVitalSigns($event)"></app-vital-sign-form>
</mat-dialog-content>
