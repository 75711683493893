<div class="communicationsPage">
  <div class="toolbar">
    <form [formGroup]="filterFormTable">
      <div class="datePickerContainer ml-5">
        <mat-form-field class="datePickerField ml-5">
          <input
            cdkFocusInitial
            matInput
            [max]="maxFromDate"
            [matDatepicker]="fromDateTable"
            formControlName="fromDate"
            placeholder="{{ 'itemFilter.fromDate' | translate }}"
          />
          <mat-datepicker-toggle matSuffix [for]="fromDateTable"></mat-datepicker-toggle>
          <mat-datepicker #fromDateTable></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="datePickerField ml-5">
          <input
            matInput
            [min]="minToDate"
            [max]="today"
            [matDatepicker]="toDate"
            formControlName="toDate"
            placeholder="{{ 'itemFilter.toDate' | translate }}"
          />
          <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
          <mat-datepicker #toDate></mat-datepicker>
        </mat-form-field>
      </div>
    </form>
    <button mat-icon-button (click)="openCommunicationPageHelp()">
      <mat-icon style="font-size: 30px" matTooltip="{{ 'btn.help' | translate }}">help_outline</mat-icon>
    </button>
    <div class="spacer"></div>
    <div class="right">
      <button
        mat-stroked-button
        matTooltip="{{ 'communication.newComm' | translate }}"
        *ngIf="('dashboard/communication' | isAuthorized : 'POST' | async) === true"
        (click)="createCommunication()"
        [matTooltipDisabled]="isAllServices"
        [disabled]="isAllServices"
      >
        <mat-icon matTooltip="{{ 'btn.disabledWithAll' | translate }}" [matTooltipDisabled]="!isAllServices"> add </mat-icon>
      </button>
      <button mat-stroked-button (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
        <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
      </button>
    </div>
  </div>
  <mat-card>
    <table
      #MatSortMain="matSort"
      #MatTableMain="matTable"
      mat-table
      matSort
      matSortActive="sendDate"
      multiTemplateDataRows
      matSortDirection="desc"
      class="full-width-table"
    >
      <!-- expand Column-->
      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-icon (click)="expand(row)" *ngIf="row.isGrouped && expandedElement?.selfId !== row.selfId">arrow_right</mat-icon>
          <mat-icon (click)="expand(row)" *ngIf="row.isGrouped && expandedElement?.selfId === row.selfId">arrow_drop_down</mat-icon>
        </td>
      </ng-container>

      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.patient.status" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.status' | translate }}"
            propertyName="openedStatus"
            [dataType]="dataTypeChoice"
            [data]="dataSource?.data"
            [defaultValue]="getFilter('openedStatus')"
            translatePrefix="choiceLabel.openedStatus"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          <span
            matTooltip="{{ 'communication.draft' | translate }}"
            class="fas fa-lg fa-envelope-open-text primary"
            *ngIf="row.openedStatus === 4"
          ></span>
          <span
            matTooltip="{{ 'communication.notOpen' | translate }}"
            class="fas fa-lg fa-envelope warn"
            *ngIf="row.openedStatus === 5"
          ></span>
          <span
            matTooltip="{{ 'communication.partlyReceived' | translate }}"
            class="fas fa-lg fa-envelope warn"
            *ngIf="row.openedStatus === 6"
          ></span>
          <span
            matTooltip="{{ 'communication.notDelivred' | translate }}"
            class="fas fa-lg fa-envelope warn"
            style="opacity: 0.3"
            *ngIf="row.openedStatus === 3"
          ></span>
          <span
            matTooltip="{{ 'communication.partlyOpen' | translate }}"
            class="fas fa-lg fa-envelope-open warn"
            *ngIf="row.openedStatus === 2"
          ></span>
          <span
            matTooltip="{{ 'communication.open' | translate }}"
            class="fas fa-lg fa-envelope-open warn"
            style="opacity: 0.3"
            *ngIf="row.openedStatus === 1"
          ></span>
        </td>
      </ng-container>

      <!-- sendDate Column -->
      <ng-container matColumnDef="sendDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.sendDate" | translate }}
          <app-item-filter
            fieldName="{{ 'table.sendDate' | translate }}"
            propertyName="sent"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('sent')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.sent | date : "dd/MM/yyyy HH:mm" }}
        </td>
      </ng-container>

      <!-- receivedDate Column -->
      <ng-container matColumnDef="receivedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.receivedDate" | translate }}
          <app-item-filter
            fieldName="{{ 'table.receivedDate' | translate }}"
            propertyName="received"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('received')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          {{
            (row.receivedDateStringGlobal | typeOf) === "moment"
              ? (row.receivedDateStringGlobal | date : "dd/MM/yyyy HH:mm")
              : (row.receivedDateStringGlobal | translate)
          }}
        </td>
      </ng-container>

      <!-- sendTo Column -->
      <ng-container matColumnDef="sendTo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.sendTo" | translate }}
          <app-item-filter
            fieldName="{{ 'table.sendTo' | translate }}"
            propertyName="sendTo"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('sendTo')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">{{ row.sendTo }}<span *ngIf="row.isGrouped">,...</span></td>
      </ng-container>

      <!-- Subject Column -->
      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.subject" | translate }}
          <app-item-filter
            fieldName="{{ 'table.subject' | translate }}"
            propertyName="topic"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('topic')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">{{ row.topic }}</td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.action" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-icon-button
            matTooltip="{{ 'btn.overview' | translate }}"
            *ngIf="('dashboard/communication' | isAuthorized | async) === true"
            (click)="viewCommunication(row)"
            aria-label="icon button with a loupe icon"
          >
            <mat-icon class="custom-icon-small sub-align">search</mat-icon>
          </button>
          <button
            mat-icon-button
            class="warn"
            matTooltip="{{ row.isGrouped ? ('btn.updateComP' | translate) : ('btn.updateCom' | translate) }}"
            [matTooltipDisabled]="isAllServices"
            *ngIf="
              row.isEditable &&
              !(row | isBasedOnCareplan) &&
              ('dashboard/communication' | isAuthorized | async) === true &&
              ('dashboard/communications' | isAuthorized : 'PUT' | async) === true
            "
            (click)="editCommunication(row, true)"
            aria-label="icon button with a pencil icon"
            [disabled]="isAllServices"
            [ngClass]="{
              warn: !isAllServices,
              medium: isAllServices
            }"
          >
            <mat-icon
              class="custom-icon-small sub-align"
              matTooltip="{{ 'btn.disabledWithAll' | translate }}"
              [matTooltipDisabled]="!isAllServices"
            >
              edit
            </mat-icon>
          </button>
          <button
            *ngIf="row.isEditable && !(row | isBasedOnCareplan) && ('dashboard/communications' | isAuthorized : 'PUT' | async) === true"
            mat-icon-button
            matTooltip="{{ row.isGrouped ? ('btn.deleteComP' | translate) : ('btn.deleteCom' | translate) }}"
            (click)="deleteCommunication(row, true)"
            aria-label="icon button with a trash icon"
          >
            <mat-icon class="custom-icon-small sub-align fail">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
            <mat-card style="width: 100%; margin: 20px">
              <table
                mat-table
                [dataSource]="
                  subDatasSources[element.selfId]
                    ? subDatasSources[element.selfId]
                    : (element | getDataSource : sort : dataSource : subDatasSources)
                "
                #sort="matSort"
                matSort
                matSortDirection="desc"
                [matSortActive]="'receivedDateSub' + element.selfId"
                class="full-width-table"
              >
                <!-- status Column -->
                <ng-container [matColumnDef]="'statusSub' + element.selfId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ "model.patient.status" | translate }}
                    <app-item-filter
                      fieldName="{{ 'model.patient.status' | translate }}"
                      propertyName="openStatusLocal"
                      [dataType]="dataTypeChoice"
                      [data]="dataSource?.data"
                      [defaultValue]="getFilter('openStatusLocal', element.selfId)"
                      translatePrefix="choiceLabel.openedStatus"
                      (applyFilter)="applyFilter($event, element.selfId)"
                    >
                    </app-item-filter>
                  </th>
                  <td mat-cell *matCellDef="let row" style="text-align: left">
                    <span
                      matTooltip="{{ 'communication.draft' | translate }}"
                      class="fas fa-lg fa-envelope-open-text primary ml-15"
                      *ngIf="row.openStatusLocal === 4"
                    ></span>
                    <span
                      matTooltip="{{ 'communication.notOpen' | translate }}"
                      class="fas fa-lg fa-envelope warn ml-15"
                      *ngIf="!row.isOpened && row.openStatusLocal === 5"
                    ></span>
                    <span
                      matTooltip="{{ 'communication.notDelivred' | translate }}"
                      class="fas fa-lg fa-envelope warn ml-15"
                      style="opacity: 0.3"
                      *ngIf="row.openStatusLocal === 3"
                    ></span>
                    <span
                      matTooltip="{{ 'communication.open' | translate }}"
                      class="fas fa-lg fa-envelope-open warn ml-15"
                      style="opacity: 0.3"
                      *ngIf="row.isOpened && row.openStatusLocal !== 4"
                    ></span>
                  </td>
                </ng-container>

                <!-- receivedDate Column -->
                <ng-container [matColumnDef]="'receivedDateSub' + element.selfId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ "table.receivedDate" | translate }}
                    <app-item-filter
                      fieldName="{{ 'table.receivedDate' | translate }}"
                      propertyName="received"
                      [dataType]="dataTypeDate"
                      [defaultValue]="getFilter('received', element.selfId)"
                      (applyFilter)="applyFilter($event, element.selfId)"
                    >
                    </app-item-filter>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.receivedDateStringLocal | translate }}
                  </td>
                </ng-container>

                <!-- sendTo Column -->
                <ng-container [matColumnDef]="'sendToSub' + element.selfId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ "table.sendTo" | translate }}
                    <app-item-filter
                      fieldName="{{ 'table.sendTo' | translate }}"
                      propertyName="subjectDisplay"
                      [dataType]="dataTypeText"
                      [defaultValue]="getFilter('subjectDisplay', element.selfId)"
                      (applyFilter)="applyFilter($event, element.selfId)"
                    >
                    </app-item-filter>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.subjectDisplay }}
                  </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container [matColumnDef]="'actionSub' + element.selfId">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "table.action" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <button
                      mat-icon-button
                      matTooltip="{{ 'btn.overview' | translate }}"
                      *ngIf="('dashboard/communication' | isAuthorized | async) === true"
                      (click)="viewCommunication(row)"
                      aria-label="icon button with a loupe icon"
                    >
                      <mat-icon class="custom-icon-small sub-align">search</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="getDisplayedColumnsSub(element); sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: getDisplayedColumnsSub(element)"></tr>
              </table>
              <mat-paginator
                [length]="subDatasSources[element.selfId]?.data?.length"
                [pageIndex]="0"
                [pageSize]="5"
                [pageSizeOptions]="[5, 10, 15]"
              >
              </mat-paginator>
            </mat-card>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        [ngClass]="{ 'expanded-row': expandedElement?.selfId === row.selfId }"
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
      <tr mat-row *matRowDef="let element; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>

    <div class="spinner-container" *ngIf="dataSource?.loading$ | async">
      <mat-spinner></mat-spinner>
    </div>
    <mat-paginator #MatPaginatorMain [pageIndex]="0" [pageSize]="currentPageSize ? currentPageSize : 25" [pageSizeOptions]="[25, 50, 100]">
    </mat-paginator>
  </mat-card>
</div>
