import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IVitalProfileDefinition } from "src/app/models/vitalProfileDefinition.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class VitalProfileDefinitionApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "VitalProfileDefinition", // Theme !
      [], // Post route paths
      ["dashboard/vitalProfileDefinitions"], // Get route paths
      [], // Put route paths
      [] // Delete route paths
    );
  }

  public list(patientId: string): Observable<IVitalProfileDefinition[]> {
    return this.api.get(this.readRoutes[0], { patient: patientId }) as Observable<IVitalProfileDefinition[]>;
  }
}
