import * as moment from "moment";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { IPatientHistoric } from "src/app/models/patientHistoric.interface";

export class PatientHistoricDialogDataSource extends MatTableDataSourceExtended<IPatientHistoric> {
  sortingDataAccessor = (data: IPatientHistoric, sortHeaderId: string) => {
    switch (sortHeaderId) {
      case "date":
        return moment(data.date).unix();
      case "author":
        return data.author?.reference.toLowerCase();
      case "oldReason":
        return data.oldReason;
      case "newReason":
        return data.newReason;
      default:
        return "";
    }
  };

  constructor() {
    super();
  }

  public loadData(data: IPatientHistoric[]) {
    this.data = data;
  }
}
