import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FilterByActivityPipe } from "./filter-by-activity.pipe";
import { FormReferenceToReferencePipe } from "./form-reference-to-reference.pipe";
import { GetActivityNamePipe } from "./get-activity-name.pipe";
import { GetAddressNamePipe } from "./get-address-name.pipe";
import { GetCareplanDescriptionPipe } from "./get-careplan-description.pipe";
import { IsProcedureActivityPipe } from "./isProcedureActivity.pipe";
import { TranslationReadyPipe } from "./translation-ready.pipe";

@NgModule({
  declarations: [
    FilterByActivityPipe,
    GetActivityNamePipe,
    GetAddressNamePipe,
    GetCareplanDescriptionPipe,
    IsProcedureActivityPipe,
    FormReferenceToReferencePipe,
    TranslationReadyPipe,
  ],
  imports: [CommonModule],
  exports: [
    FilterByActivityPipe,
    GetActivityNamePipe,
    GetAddressNamePipe,
    GetCareplanDescriptionPipe,
    IsProcedureActivityPipe,
    FormReferenceToReferencePipe,
    TranslationReadyPipe,
  ],
})
export class CareplanEditorPipesModule {}
