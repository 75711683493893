import * as moment from "moment";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { Appointment } from "src/app/models/appointment.model";

export class TeleconsultationDataSource extends MatTableDataSourceExtended<Appointment> {
  sortingDataAccessor = (data: Appointment, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "start":
        return moment(data.start).unix();
      case "end":
        return data.duration;
      case "participant":
        return data.participantPractitioner.toLowerCase();
      case "patient":
        return data.participantPatient.toLowerCase();
      case "object":
        return data.description.toLowerCase();
      case "dateSeenByPatient":
        return moment(data.dateSeenByPatient).unix();
      default:
        return "";
    }
  };

  constructor() {
    super();
  }

  public loadData(data: Appointment[]): void {
    this.data = data;
  }
}
