import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { first } from "rxjs/operators";
import { PreferenceContext } from "src/app/models/preference.interface";
import { PreferenceService } from "src/app/providers/preference.service";

export enum ConfirmationDialogType {
  CONFIRM,
  SUCCESS,
  ERROR,
  INFORMATION,
}

export interface ConfirmationDialogOption {
  title?: string;
  message: string;
  type: ConfirmationDialogType;
}

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent {
  TYPES = ConfirmationDialogType;
  public savePref: boolean;
  public go2PatientAfterInscription: boolean;

  constructor(
    private preferenceService: PreferenceService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogOption: ConfirmationDialogOption
  ) {
    this.preferenceService
      .getGlobal()
      .pipe(first())
      .subscribe((pref) => {
        this.go2PatientAfterInscription = pref ? pref.go2PatientAfterInscription : undefined;
      });
  }

  public onNoClick(): void {
    this.dialogRef.close(false);
  }

  public onYesClick(): void {
    this.dialogRef.close(true);
  }

  public onClose() {
    if (this.savePref) {
      this.savePreference(false);
    }
    this.dialogRef.close(false);
  }

  public onCloseAndRedirect() {
    if (this.savePref) {
      this.savePreference(true);
    }
    this.dialogRef.close(true);
  }

  public savePreference(go2PatientAfterInscription) {
    this.preferenceService
      .update({
        context: PreferenceContext.GLOBAL,
        parameters: {
          go2PatientAfterInscription,
        },
      })
      .pipe(first())
      .subscribe();
  }
}
