<div class="close-cross">
  <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="questionnaireHistory">
  <mat-card>
    <h3>
      {{ "alerts.historic" | translate }}
      <span *ngIf="data.history?.length">{{ ": " + data.history?.[0].subjectType }}</span>
    </h3>
    <mat-card style="padding: 0px !important">
      <table mat-table matSort matSortDirection="desc" matSortActive="date" [dataSource]="datasource">
        <!-- date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "table.date" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.date | date : "short" }}
          </td>
        </ng-container>

        <!-- Author Column -->
        <ng-container matColumnDef="author">
          <th mat-header-cell *matHeaderCellDef mat-sort-header matSortDirection="asc">
            {{ "table.author" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.author }}
          </td>
        </ng-container>

        <!-- nbPages Column -->
        <ng-container matColumnDef="nbPages">
          <th mat-header-cell *matHeaderCellDef>
            {{ "page.fhirQuestionnaireList.pages" | translate | titlecase }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.nbPages }}
          </td>
        </ng-container>

        <!-- nbQuestions Column -->
        <ng-container matColumnDef="nbQuestions">
          <th mat-header-cell *matHeaderCellDef>
            {{ "page.fhirQuestionnaireList.questions" | translate | titlecase }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.nbQuestions }}
          </td>
        </ng-container>

        <!-- questionnaireStatus Column -->
        <ng-container matColumnDef="questionnaireStatus">
          <th mat-header-cell *matHeaderCellDef>
            {{ "table.status" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ "status." + element.questionnaireStatus | translate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ "table.action" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button aria-label="icon button with a loupe icon" (click)="showVersion(element)">
              <mat-icon class="custom-icon-small">search</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator class="borderBox" #paginator [pageIndex]="0" [pageSize]="5" [pageSizeOptions]="[5, 10, 15]" [hidePageSize]="false">
      </mat-paginator>
    </mat-card>
    <p class="text-center" *ngIf="!data.history || data?.history?.length === 0">
      {{ "widget.noData" | translate }}
    </p>
  </mat-card>
</div>
