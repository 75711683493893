import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StepwiseDrug } from "../models/stepwiseDrug.model";
import { StepwiseDrugApiService } from "./api/stepwiseDrug-api.service";

@Injectable({
  providedIn: "root",
})
export class StepwiseDrugService {
  constructor(private stepwiseDrugApiService: StepwiseDrugApiService) {}

  public list(): Observable<StepwiseDrug[]> {
    return this.stepwiseDrugApiService.list();
  }

  public create(stepwiseDrug: StepwiseDrug): Observable<StepwiseDrug> {
    return this.stepwiseDrugApiService.create(stepwiseDrug);
  }

  public update(stepwiseDrug: StepwiseDrug): Observable<StepwiseDrug> {
    return this.stepwiseDrugApiService.update(stepwiseDrug);
  }

  public delete(id: string): Observable<StepwiseDrug> {
    return this.stepwiseDrugApiService.delete(id);
  }
}
