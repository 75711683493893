import { Pipe, PipeTransform } from "@angular/core";
import { IObservation, IObservationDefinition } from "../models/observations.interface";

@Pipe({ name: "filterObsDefinitions" })
export class FilterObsDefinitionsPipe implements PipeTransform {
  transform(obsDefinitions: IObservationDefinition[], observations: IObservation[]): IObservationDefinition[] {
    const filteredDefinitions: IObservationDefinition[] = [];

    for (const definition of obsDefinitions) {
      // Check if a corresponding observation exists for this definition
      const matchingObservation = observations.find((observation) => observation.code.coding[0].code === definition.loinc);

      if (matchingObservation) {
        filteredDefinitions.push(definition);
      }
    }

    return filteredDefinitions;
  }
}
