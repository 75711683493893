import { Pipe, PipeTransform } from "@angular/core";
import { IObservation } from "../models/observations.interface";

@Pipe({
  name: "filterObservationByLoinc",
})
export class FilterObservationsByLoincPipe implements PipeTransform {
  transform(observations: IObservation[], loinc: string): IObservation[] {
    return observations.filter((observation) => observation.code.coding[0].code === loinc);
  }
}
