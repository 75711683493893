import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { skipWhile, takeUntil } from "rxjs/operators";
import { AVAILABLE_ACTIONS } from "src/app/careplan-editor/data/careplan-editor.enum";
import { IlinkableQuestionnaireItem } from "src/app/careplan-editor/domain/IlinkableItem.interface";
import { CareplanEditorService } from "src/app/careplan-editor/domain/careplan-editor.service";
import { CareplanTemplateFormHelper } from "src/app/careplan-editor/domain/careplan-template-form-helper";
import { LinkableQuestionnaireItem } from "src/app/careplan-editor/domain/linkable-item.model";
import { Tools } from "src/app/helpers/tools";
import { ACTION_TARGET, ICareplan, VersioningStatus } from "src/app/models/careplans.interface";
import { IQuestionnaireIdentifierInfo } from "src/app/models/questionnaire.interface";
import { ACTIVITY_CATEGORY } from "src/app/models/sharedInterfaces";
import { CareplansService } from "src/app/providers/careplans.service";
import { SessionService } from "src/app/providers/session.service";

@Component({
  selector: "app-careplan-editor-questionnaires-tab",
  templateUrl: "./careplan-editor-questionnaires-tab.component.html",
  styleUrls: ["./careplan-editor-questionnaires-tab.component.scss"],
})
export class CareplanEditorQuestionnairesTabComponent implements OnInit, OnDestroy {
  public columns = ["subjectType"];
  public actions = [AVAILABLE_ACTIONS.configure, AVAILABLE_ACTIONS.unlink, AVAILABLE_ACTIONS.edit];
  public careplanTemplate: ICareplan;
  public questionnaires: IlinkableQuestionnaireItem;
  public link2CareplanQuestionnaires: IlinkableQuestionnaireItem[];
  public activitiesQuestionnaires: IlinkableQuestionnaireItem[];
  public currentLanguage: string = this.sessionService.userLang;
  private onDestroy$ = new Subject<void>();

  public ACTIVITY_CATEGORY = ACTIVITY_CATEGORY;

  constructor(
    public careplanEditorService: CareplanEditorService,
    private sessionService: SessionService,
    private careplanService: CareplansService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private careplanTemplateFormHelper: CareplanTemplateFormHelper,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {}
  public ngOnInit(): void {
    // Refresh translation when needed
    this.sessionService.refreshServerTraductions.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentLanguage = this.sessionService.userLang;
    });

    // Observable to check if the careplanTemplate is ready
    this.careplanEditorService.careplanTemplateReady$
      .pipe(
        skipWhile((ready) => ready === false),
        takeUntil(this.onDestroy$)
      )
      .subscribe((ready) => {
        if (ready === true) {
          this.careplanTemplate = this.careplanEditorService.currentCareplanTemplate;
          this.setupQuestionnaires();
        }
      });
  }

  public ngOnDestroy(): void {
    // After publish careplanTemplateReady$ value is false to avoid trying to save a careplan without draft
    if (this.careplanEditorService.careplanTemplateReady$.value === true) {
      this.careplanEditorService.save(false, false, false);
    }
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Retrieves questionnaires linked to the careplan and to its activities and categorizes them.
   */
  public async setupQuestionnaires(): Promise<void> {
    const questionnairesPromise = this.careplanService.getQuestionnairesLinkedToCareplan(
      this.careplanTemplate.support[0].reference,
      this.careplanTemplate.versioningStatus === VersioningStatus.DRAFT,
      this.careplanTemplate.publicationDate
    );

    const questionnaires = (await Promise.all([questionnairesPromise, Tools.timeout(500)]))[0]; // resolve promise in minimum 500ms to show loader without glitch
    this.link2CareplanQuestionnaires = this.mapAvailableQuestionnaires(questionnaires, "link2careplan");
    this.activitiesQuestionnaires = this.mapAvailableQuestionnaires(questionnaires, "activity");
  }

  /**
   * Filters ICareplanQuestionnaireInfos[] by origin and maps them to IlinkableQuestionnaireItem[] array
   * @param allQuestionnaires
   * @param origin
   */
  public mapAvailableQuestionnaires(
    allQuestionnaires: IQuestionnaireIdentifierInfo[],
    origin: "link2careplan" | "activity"
  ): IlinkableQuestionnaireItem[] {
    return allQuestionnaires
      .filter((k) => k.origin === origin)
      .map((k) => {
        return new LinkableQuestionnaireItem({
          uniqueId: k.identifier,
          originReference: k.originReference,
          origin: k.origin,
          itemType: ACTION_TARGET.QUESTIONNAIRE,
          identifier: k.identifier,
          subjectType: k.subjectType,
          version: k.version,
          isDraft: k.noPublishedVersionExists,
        });
      });
  }

  public async update($event: {
    item: LinkableQuestionnaireItem;
    items: LinkableQuestionnaireItem[];
    action: AVAILABLE_ACTIONS;
  }): Promise<void> {
    await this.careplanEditorService.update($event, ACTION_TARGET.QUESTIONNAIRE);
    await this.careplanEditorService.silentSave();
    await this.setupQuestionnaires();
  }

  /**
   * Show toast and reset questionnaires in case of error
   */
  public handleError(): void {
    this.snackBar.open(this.translateService.instant("api.errors.server-error"), "ok", { duration: 5000 });
    // reload the questionnaires list in case of error
    this.setupQuestionnaires();
  }

  public addQuestionnaire(): void {
    this.router.navigate(["/questionnaireEditor"], { state: { previousLocation: this.router.url } });
  }
}
