import { Component, EventEmitter, Input, Output } from "@angular/core";
import moment from "moment";
import { Subject } from "rxjs";
import { FileLogger } from "src/app/helpers/fileLogger";

@Component({
  selector: "ui-date-range-max",
  templateUrl: "./ui-date-range-max.component.html",
  styleUrls: ["./ui-date-range-max.component.scss"],
})
export class UiDateRangeMaxComponent {
  @Input() maxValue: moment.DurationInputArg1;
  @Input() maxUnit: moment.DurationInputArg2;
  @Input() fromDate: moment.Moment;
  @Output() fromDateChange = new EventEmitter<moment.Moment>();
  @Input() toDate: moment.Moment;
  @Output() toDateChange = new EventEmitter<moment.Moment>();
  @Output() dateChange = new EventEmitter<void>();

  public today = moment().endOf("day");

  public max: moment.Moment;
  protected onDestroy$ = new Subject<void>();

  onDateChange(): void {
    if (!this.maxValue || !this.maxUnit) {
      FileLogger.warn("UiDateRangeMaxComponent", "no max value");
      return;
    }

    const newMaxValue = moment(this.fromDate).add(this.maxValue, this.maxUnit);
    this.max = newMaxValue.isBefore(this.today) ? newMaxValue : this.today;
  }

  close(): void {
    // remove this.max when closing the calendar so we can choose any date when we reopen it.
    this.max = undefined;
    this.fromDateChange.emit(this.fromDate);
    this.toDateChange.emit(this.toDate);
    // emit the change of date to update form in parent component
    this.dateChange.emit();
  }
}
