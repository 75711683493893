<div *ngIf="isLoading" class="loading">
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</div>
<mat-form-field appearance="fill" *ngIf="!isLoading" style="width: 100%">
  <ui-alert [options]="{ type: 'warning', showIcon: true }">
    {{ "page.careplanEditor.jsonEditorWarning" | translate }}
  </ui-alert>
  <textarea class="json-field" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" [(ngModel)]="jsonCT">
  </textarea>
</mat-form-field>
