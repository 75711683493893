<div class="spinner-container" *ngIf="isLoading">
  <mat-spinner class="centerSpinner"></mat-spinner>
</div>
<div class="questionnaire-editor-page" *ngIf="!isLoading">
  <!-- ========================== TOP MENU ============================== -->
  <div class="topMenu">
    <div class="saveMenu">
      <mat-checkbox
        matTooltip="{{
          !questionnaire._id
            ? ('page.questionnaireEditor.properties.needSaveTooltip' | translate)
            : ('page.questionnaireEditor.properties.autoSaveTooltip' | translate)
        }}"
        [disabled]="!questionnaire._id"
        (change)="autoSave($event)"
        [(ngModel)]="autoSaveActivated"
        *ngIf="
          ((!questionnaire._id && ('dashboard/questionnaire' | isAuthorized : 'POST' | async) === true) ||
            (questionnaire._id && ('dashboard/questionnaire' | isAuthorized : 'PUT' | async) === true)) &&
          !visualization &&
          !knowledgeId
        "
      >
        {{ "page.questionnaireEditor.properties.autoSave" | translate }}
      </mat-checkbox>
      <button
        mat-stroked-button
        class="saveButton"
        *ngIf="
          ((!questionnaire._id && ('dashboard/questionnaire' | isAuthorized : 'POST' | async) === true) ||
            (questionnaire._id && ('dashboard/questionnaire' | isAuthorized : 'PUT' | async) === true)) &&
          !visualization &&
          !knowledgeId
        "
        (click)="saveQuestionnaire(false)"
      >
        <mat-icon aria-hidden="false" attr.aria-label="{{ 'page.questionnaireEditor.saveIcon' | translate }}">done</mat-icon>
        {{ "page.questionnaireEditor.save" | translate }}
      </button>
      <button
        mat-stroked-button
        class="saveButton"
        *ngIf="
          ((!questionnaire._id && ('dashboard/questionnaire' | isAuthorized : 'POST' | async) === true) ||
            (questionnaire._id && ('dashboard/questionnaire' | isAuthorized : 'PUT' | async) === true)) &&
          !visualization &&
          !knowledgeId
        "
        (click)="saveQuestionnaire(true)"
      >
        <mat-icon aria-hidden="false" attr.aria-label="{{ 'page.questionnaireEditor.saveIcon' | translate }}">save</mat-icon>
        {{ "page.questionnaireEditor.saveAndClose" | translate }}
      </button>

      <div
        [matTooltip]="
          userHighestAccessLevel < AccessLevel.WRITE
            ? ('common.notAuthorized' | translate)
            : ('page.questionnaireEditor.publish' | translate)
        "
      >
        <button
          mat-stroked-button
          [ngClass]="{
            saveButtonDisabled: userHighestAccessLevel < AccessLevel.WRITE,
            saveButton: userHighestAccessLevel >= AccessLevel.WRITE
          }"
          *ngIf="
            ((!questionnaire._id && ('dashboard/questionnaire' | isAuthorized : 'POST' | async) === true) ||
              (questionnaire._id && ('dashboard/questionnaire' | isAuthorized : 'PUT' | async) === true)) &&
            (questionnaire.status === QuestionnaireStatus.DRAFT || knowledgeId)
          "
          (click)="publish()"
          [disabled]="userHighestAccessLevel < AccessLevel.WRITE"
        >
          <mat-icon aria-hidden="false" attr.aria-label="{{ 'page.questionnaireEditor.saveIcon' | translate }}">publish</mat-icon>
          {{ "page.questionnaireEditor.publish" | translate }}
        </button>
      </div>
      <button mat-stroked-button class="saveButton" *ngIf="visualization" (click)="close()">
        {{ "btn.close" | translate }}
      </button>
    </div>
    <div *ngIf="viewType === 'questionnaire'">
      <button mat-icon-button (click)="showQuestProperties()">
        <mat-icon aria-hidden="false" attr.aria-label="{{ 'page.questionnaireEditor.editIcon' | translate }}">{{
          visualization ? "remove_red_eye" : "settings"
        }}</mat-icon>
      </button>
      <!-- Page management (création, suppression, choix) -->
      <app-pages-editor
        *ngIf="questionnaire.paging === 2"
        [pages]="questionnaire?.group?.group?.length"
        [currentPage]="currentPage"
        (currentPageChange)="onPageChanged($event)"
        (onCreateNewPage)="createNewPage($event)"
        (onRemovePage)="removePage($event)"
        [translationKey]="'page'"
        [visualization]="visualization"
      >
      </app-pages-editor>
    </div>
  </div>

  <mat-drawer-container class="mat-elevation-z1">
    <mat-drawer #drawerInputs *ngIf="!visualization" class="inputMenu" mode="side" opened position="start">
      <!-- =========================== LEFT MENUS ============================= -->
      <div class="drawer-title-container">
        <h2 class="drawer-title">
          {{ (viewType === "questionnaire" ? "page.questionnaireEditor.inputFields" : "observation.title") | translate }}
        </h2>
      </div>
      <mat-form-field class="obsSearch" *ngIf="viewType === 'observations'">
        <input
          matInput
          [placeholder]="'itemFilter.search' | translate"
          #nameSearchInput
          [value]="obsFilter"
          (keyup.enter)="obsFilter = nameSearchInput.value; filterObs()"
          (blur)="obsFilter = nameSearchInput.value; filterObs()"
        />
        <button
          type="button"
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="nameSearchInput.value = ''; obsFilter = ''; filterObs()"
          [style.visibility]="nameSearchInput.value ? 'visible' : 'hidden'"
          #nameSearchClearBtn
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <div
        *ngIf="viewType === 'observations'"
        id="obsDefListArea"
        class="input-list"
        cdkDropList
        cdkDropListSortingDisabled
        [cdkDropListConnectedTo]="['observationsDisplayArea']"
        (cdkDropListEntered)="removeItemsInTransfer()"
        (cdkDropListExited)="exitedInputList($event)"
      >
        <!-- Draggable Observations list -->
        <div
          class="input-item"
          *ngFor="let item of obsDefsItems"
          [cdkDragData]="item"
          [matTooltipDisabled]="!item?.haveTooltip"
          matTooltip="{{ (item?.haveTooltip ? item?.tooltipContent : '') | translate }}"
          cdkDrag
        >
          <mat-icon
            aria-hidden="false"
            attr.aria-label="{{ 'page.questionnaireEditor.itemType' | translate }}"
            style="vertical-align: middle"
          >
            perm_data_setting
          </mat-icon>
          &nbsp;
          {{ item?.type }}
        </div>
      </div>

      <div
        *ngIf="viewType === 'questionnaire'"
        id="inputsListArea"
        class="input-list"
        cdkDropList
        cdkDropListSortingDisabled
        [cdkDropListConnectedTo]="['questionsDisplayArea']"
        (cdkDropListEntered)="removeItemsInTransfer()"
        (cdkDropListExited)="exitedInputList($event)"
      >
        <!-- Draggable Inputs list -->
        <ng-container *ngFor="let item of inputItems">
          <div
            class="input-item"
            [cdkDragData]="item"
            [matTooltipDisabled]="!item.haveTooltip"
            matTooltip="{{ (item.haveTooltip ? item.tooltipContent : '') | translate }}"
            cdkDrag
          >
            <mat-icon
              aria-hidden="false"
              attr.aria-label="{{ 'page.questionnaireEditor.itemType' | translate }}"
              style="vertical-align: middle"
            >
              {{ item.logo }}
            </mat-icon>
            &nbsp;
            {{ "page.questionnaireEditor.inputsTypes." + item.type | translate }}
          </div>
        </ng-container>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <mat-drawer-container class="mat-elevation-z1">
        <mat-drawer #drawerProperties class="propertiesMenu" mode="side" [opened]="properties" position="end">
          <!-- =========================== RIGHT MENUS ========================== -->
          <div class="drawer-title-container">
            <h2 class="drawer-title">{{ "page.questionnaireEditor.fieldsProperties" | translate }}</h2>

            <div>
              <button mat-stroked-button (click)="cancelProperties()" *ngIf="!visualization">
                <mat-icon aria-hidden="false" attr.aria-label="{{ 'page.questionnaireEditor.cancelIcon' | translate }}">close</mat-icon>
              </button>
              <button mat-stroked-button class="saveButton" type="submit" [attr.form]="formId" *ngIf="!fieldProperties && !visualization">
                <mat-icon aria-hidden="false" attr.aria-label="{{ 'page.questionnaireEditor.saveIcon' | translate }}">done</mat-icon>
              </button>

              <!-- TODO : Remove button and use the previous one for inputsProperty as well (à faire dans CMATE-3096) -->
              <button mat-stroked-button class="saveButton" (click)="applyProperties()" *ngIf="fieldProperties && !visualization">
                <mat-icon aria-hidden="false" attr.aria-label="{{ 'page.questionnaireEditor.saveIcon' | translate }}">done</mat-icon>
              </button>
            </div>
          </div>
          <div class="properties">
            <div *ngIf="questProperties">
              <!-- Questionnaire properties go here -->
              <app-questionnaire-properties
                [questionnaire]="questionnaire"
                (questionnaireChange)="applyProperties()"
                [isNewQuestionnaire]="isNewQuestionnaire"
                [(parameters)]="questionnaireParams"
                (closeEvent)="cancelProperties(); hasBeenEdited = true"
                [visualization]="visualization"
                [(scorings)]="scorings"
                [(withObservations)]="withObservations"
              >
              </app-questionnaire-properties>
            </div>
            <div *ngIf="pageProperties && questionnaire.paging === 2">
              <!-- Page properties go here -->
              <app-page-properties
                [(page)]="questionnaire.group.group[currentPage]"
                [visualization]="visualization"
                (closeEvent)="cancelProperties()"
              ></app-page-properties>
            </div>
            <div *ngIf="fieldProperties">
              <!-- Input fields' properties go here -->
              <app-input-properties
                [pageNumber]="currentPage"
                [(questions)]="questions"
                [currentQuestion]="currentQuestion"
                [(contained)]="questionnaire.contained"
                (pageNumberChange)="onQuestionsPageChange($event)"
                [lang]="questionnaire.version"
                [totalPages]="questionnaire.group.group?.length || 1"
                [specificUse]="specificUse"
                [visualization]="visualization"
              >
              </app-input-properties>
            </div>
          </div>
        </mat-drawer>
        <mat-drawer-content>
          <div *ngIf="withObservations" style="text-align: center">
            <mat-button-toggle-group [(ngModel)]="viewType" aria-label="Observations or questionnaire">
              <mat-button-toggle [value]="'questionnaire'" (change)="showQuestProperties()"
                ><mat-icon>notes</mat-icon>&nbsp;{{ "page.questionnaireEditor.questionnaire" | translate }}</mat-button-toggle
              >
              <mat-button-toggle [value]="'observations'" (change)="cancelProperties()"
                ><mat-icon>insert_chart_outlined</mat-icon>&nbsp;{{ "observation.title" | translate }}</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
          <!-- ===================== OBSERVATIONS OVERVIEW ====================== -->
          <div *ngIf="viewType === 'observations'" id="observationsContainer" class="questionnaire" cdk-scrollable>
            <ul
              id="observationsDisplayArea"
              class="obsDropZone"
              cdkDropList
              [cdkDropListConnectedTo]="['obsDefListArea']"
              (cdkDropListDropped)="dropInputItem($event)"
              [cdkDropListDisabled]="visualization"
            >
              <h2 class="empty-inputs" *ngIf="linkedObservations.length === 0">
                {{ "page.questionnaireEditor.placeInputElements" | translate }}
              </h2>
              <div *ngFor="let obsDef of linkedObservations; let i = index" cdkDrag class="question">
                <button
                  mat-stroked-button
                  *ngIf="!visualization"
                  class="rightMostBtn"
                  (click)="deleteObservation(i)"
                  [disabled]="visualization"
                >
                  <mat-icon aria-hidden="false" attr.aria-label="{{ 'page.questionnaireEditor.deleteIcon' | translate }}">
                    delete
                  </mat-icon>
                </button>
                <mat-form-field appearance="fill" class="accessLevelDropDown">
                  <mat-label>{{ "useContext.patientAccess" | translate }}</mat-label>
                  <mat-select [(ngModel)]="obsDef.patientDefaultAccess">
                    <mat-option *ngFor="let access of AccessLevel | EnumToArray" [value]="access">
                      {{ "useContext." + access | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <app-encode-observations
                  style="width: fit-content"
                  [existingObservations]="[]"
                  [observationsDefinitions]="[obsDef]"
                  [patientReference]="null"
                  [questionnaireIdentifier]="questionnaire.identifier[0].value"
                  [disabled]="false"
                ></app-encode-observations>
                <mat-icon class="material-icons-outlined drag-icon" cdkDragHandle>drag_indicator</mat-icon>
              </div>
            </ul>
          </div>

          <!-- ==================== QUESTIONNAIRE OVERVIEW ====================== -->

          <div
            *ngIf="viewType === 'questionnaire' && questionnaire && questions"
            id="questionnaireContainer"
            class="questionnaire"
            cdk-scrollable
          >
            <!-- Questionnaire title (& stuff) preview -->
            <div
              *ngIf="currentPage === 0"
              [ngClass]="{
                questionnaireTitle: true,
                selectedElement: questProperties
              }"
            >
              <button mat-stroked-button class="editButton" (click)="showQuestProperties()">
                <mat-icon aria-hidden="false" attr.aria-label="{{ 'page.questionnaireEditor.editIcon' | translate }}">{{
                  visualization ? "remove_red_eye" : "settings"
                }}</mat-icon>
              </button>
              <h1>{{ questionnaire.subjectType }}</h1>
              <h3>{{ questionnaire.group.title }}</h3>
              <p>{{ questionnaire.group.text }}</p>
            </div>
            <!-- Current page title (& stuff) preview-->
            <div
              *ngIf="questionnaire.paging === 2"
              [ngClass]="{
                questionnaireTitle: true,
                selectedElement: pageProperties
              }"
            >
              <button mat-stroked-button class="editButton" (click)="showPageProperties()">
                <mat-icon aria-hidden="false" attr.aria-label="{{ 'page.questionnaireEditor.editIcon' | translate }}">{{
                  visualization ? "remove_red_eye" : "edit"
                }}</mat-icon>
              </button>
              <h3>{{ questionnaire.group.group[currentPage].title }}</h3>
              <p>{{ questionnaire.group.group[currentPage].text }}</p>
            </div>
            <!-- List of questions -->
            <ul
              id="questionsDisplayArea"
              class="inputDropZone"
              cdkDropList
              [cdkDropListConnectedTo]="['inputsListArea']"
              (cdkDropListDropped)="dropInputItem($event)"
              [cdkDropListDisabled]="visualization"
            >
              <h2 class="empty-inputs" *ngIf="questions.length === 0">
                {{ "page.questionnaireEditor.placeInputElements" | translate }}
              </h2>
              <li
                [ngClass]="{
                  question: true,
                  selectedElement: fieldProperties && currentQuestion === i
                }"
                *ngFor="let question of questions; let i = index"
              >
                <app-item-drag-drop
                  [question]="question"
                  [contained]="questionnaire.contained"
                  [default]="question.answerDisplay?.default"
                  (edit)="showFieldProperties($event)"
                  (delete)="deleteQuestion($event)"
                  (duplicate)="duplicateQuestion($event)"
                  [min]="question.answerDisplay?.min"
                  [max]="question.answerDisplay?.max"
                  [specificUse]="specificUse"
                  [visualization]="visualization"
                  [scorings]="scorings"
                >
                </app-item-drag-drop>
              </li>
            </ul>
          </div>
        </mat-drawer-content>
      </mat-drawer-container>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
