<div class="appointmentDelayDialog">
  <div class="buttons-bar">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close matTooltip="{{ 'btn.close' | translate }}">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <app-appointment-delay
    [appointments]="[appointment]"
    [(minutesDelay)]="minutesDelay"
    (minutesDelayChange)="emitMinutesChange()"
  ></app-appointment-delay>
</div>
