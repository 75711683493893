<div class="close-cross">
  <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <form [formGroup]="resetOwnPasswordForm">
    <h2>{{ expiredPassword ? ("page.login.resetPasswordTitle" | translate) : ("forms.changePwd" | translate) }}</h2>
    <div *ngIf="expiredPassword" class="expired-password-image">
      <img src="../../../../assets/images/custom-icon/expired-password.png" alt="Expired password icon" />
    </div>
    <p *ngIf="expiredPassword">
      {{ "page.login.expiredPasswordText" | translate }}
    </p>
    <!-- This is a workaround to disabled autocomplete issue on chrome -->
    <div style="display: none">
      <input type="password" />
    </div>
    <div class="form-container">
      <!-- Old password input -->
      <mat-form-field>
        <mat-label>{{ "page.login.oldPassword" | translate }}</mat-label>
        <app-show-hide-password>
          <input matInput autocomplete="off" type="password" formControlName="oldPassword" />
        </app-show-hide-password>
      </mat-form-field>
      <!-- New password input -->
      <mat-form-field [ngStyle]="isFieldValid('newPasswordA') ? { height: '5rem' } : ''">
        <mat-label>{{ "page.login.newPassword" | translate }}</mat-label>
        <app-show-hide-password>
          <input matInput autocomplete="off" type="password" formControlName="newPasswordA" />
        </app-show-hide-password>
        <!-- New password input validation error -->
        <mat-error *ngIf="isFieldValid('newPasswordA')">
          {{ "page.login.password-notValid" | translate }}
        </mat-error>
      </mat-form-field>
      <!-- Confirm password input -->
      <mat-form-field>
        <mat-label>{{ "page.login.confirmNewPassword" | translate }}</mat-label>
        <app-show-hide-password>
          <input matInput autocomplete="off" type="password" formControlName="newPasswordB" />
        </app-show-hide-password>
        <!-- Confirm password input validation error -->
        <mat-error *ngIf="resetOwnPasswordForm.get('newPasswordB')?.touched && resetOwnPasswordForm.get('newPasswordB').errors?.matching">
          {{ "page.login.password-not-same" | translate }}
        </mat-error>
      </mat-form-field>
      <!-- additionalData error -->
      <mat-error *ngIf="passwordError">
        {{ passwordError }}
      </mat-error>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="btn-container">
  <button mat-raised-button (click)="cancel()" *ngIf="!expiredPassword">
    {{ "btn.cancel" | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="saveNewPassword()" [disabled]="!resetOwnPasswordForm.valid">
    {{ expiredPassword ? ("btn.reset" | translate) : ("btn.send" | translate) }}
  </button>
</mat-dialog-actions>
