import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, Observable, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { AddDrugIntakeDialogComponent } from "src/app/components/patient-drugs/add-drug-intake-dialog/add-drug-intake-dialog.component";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { HelpData } from "src/app/helpers/helpData";
import { Tools } from "src/app/helpers/tools";
import { IDrugInfo } from "src/app/models/drugInfo.interface";
import { HISTORIC_MODE, IDrugsHistoric } from "src/app/models/drugsHistoric.interface";
import { ActionStatusEntity, CycleSchema, EntityDrug, Entitylink, GAUGE_COLOR, IEntitylink } from "src/app/models/entity.interface";
import { DataType, Filter } from "src/app/models/filter.interface";
import { IKnowledges, IKnowMedia, KNOW_DOC_CATEGORY } from "src/app/models/knowledge.interface";
import { Notification } from "src/app/models/notifications.interface";
import { PatientUser } from "src/app/models/patient.interface";
import { PatientPageParameter, PatientWidgetName, PreferenceContext, WidgetPatientParameter } from "src/app/models/preference.interface";
import { DrugSchemaService } from "src/app/providers/drugSchema.service";
import { DrugsService } from "src/app/providers/drugs.service";
import { KnowledgeService } from "src/app/providers/knowledge.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { SessionService } from "src/app/providers/session.service";
import { TileManager } from "src/app/providers/tile-manager.service";
import { UserService } from "src/app/providers/user.service";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "../confirmation-dialog/confirmation-dialog.component";
import { PauseDrugSchemaComponent } from "../forms/add-drug-schema/pause-drug-schema/pause-drug-schema.component";
import { Item } from "../item-selector/item-selector.component";
import { KnowledgeMediaListModalComponent } from "../knowledge-media-list-modal/knowledge-media-list-modal.component";
import { DrugAddComponent } from "./drug-add/drug-add.component";
import { DrugDetailsComponent } from "./drug-details/drug-details.component";
import { DrugsCalendarComponent } from "./drugs-calendar/drugs-calendar.component";
import { DrugsHistoricComponent } from "./drugs-historic/drugs-historic.component";
import { ObservationsListDataSource } from "./drugs-list-datasource";

export interface CsvRow {
  date: string;
  [key: string]: unknown; // allow any string properties
}
@Pipe({ name: "intakesGauge" })
export class IntakesGaugePipe implements PipeTransform {
  transform(entity: IEntitylink, historic: IDrugsHistoric[], intakeDrugs: Notification[]): { background: string } {
    const gaugeObject = DrugsService.drugTaken(entity, historic, intakeDrugs);
    return {
      background: `linear-gradient(90deg, ${GAUGE_COLOR.GREEN} 0% ${gaugeObject.take}%,
                ${GAUGE_COLOR.ORANGE} ${gaugeObject.take}% ${gaugeObject.take + gaugeObject.unknow}%,
                ${GAUGE_COLOR.RED} ${gaugeObject.unknow + gaugeObject.take}% 100%)`,
    };
  }
}

@Pipe({ name: "intakesToolTip" })
export class IntakesToolTipPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(entity: IEntitylink, historic: IDrugsHistoric[], intakeDrugs: Notification[]): string {
    const translations = [
      this.translateService.instant("tooltip.taken"),
      this.translateService.instant("tooltip.unknow"),
      this.translateService.instant("tooltip.notTake"),
    ];
    const drugTaken = DrugsService.drugTaken(entity, historic, intakeDrugs);
    const takeLabel = drugTaken.take > 0 ? `${translations[0]}: ${drugTaken.take.toFixed(2)}% ` : "";
    const unknowLabel = drugTaken.unknow > 0 ? `${translations[1]}: ${drugTaken.unknow.toFixed(2)}% ` : "";
    const untakeLabel = drugTaken.untake > 0 ? `${translations[2]}: ${drugTaken.untake.toFixed(2)}%` : "";
    return takeLabel + unknowLabel + untakeLabel;
  }
}
@Pipe({ name: "hasIntakesData" })
export class HasIntakesDataPipe implements PipeTransform {
  transform(entity: IEntitylink, intakeDrugs: Notification[]): boolean {
    const entityId = entity._id;
    const cibledNotif = DrugsService.getCibledNotification(entityId, intakeDrugs).reverse();
    return cibledNotif?.length > 0;
  }
}
@Pipe({ name: "frequencyLabel" })
export class FrequencyLabelPipe implements PipeTransform {
  constructor(private translateService: TranslateService, private drugsService: DrugsService) {}
  transform(element: EntityDrug): string {
    if (element.cycle && element.cycle.cycle?.length > 0) {
      return this.translateService.instant("drugSchema.withCycle");
    }
    return this.drugsService.computeFrequency(element.frequency);
  }
}

@Pipe({ name: "hasDrugKnowledge" })
export class HasDrugKnowledgePipe implements PipeTransform {
  transform(element: EntityDrug, drugsKnowledges: IKnowledges[], docCategory: KNOW_DOC_CATEGORY): boolean {
    const knowledges = drugsKnowledges?.find((dk) => dk.reference === element.name);
    if (knowledges?.knowledges?.length > 0) {
      return knowledges.knowledges.findIndex((k) => k.documentCategory === docCategory) !== -1;
    }
    return false;
  }
}

@Pipe({ name: "hasDrugInfoLink" })
export class HasDrugInfoLinkPipe implements PipeTransform {
  constructor(private sessionService: SessionService) {}
  transform(element: EntityDrug, drugsInfo: IDrugInfo[]): boolean {
    if (element.reference) {
      const infos: IDrugInfo = drugsInfo.find((d) => d?.cnk === element.reference);
      if (infos) {
        const userLang = this.sessionService.userLang;
        return Tools.isDefined(infos[userLang].url) || Tools.isDefined(infos.fr.url);
      }
    }
    return false;
  }
}

@Component({
  selector: "app-patient-drugs",
  templateUrl: "./patient-drugs.component.html",
  styleUrls: ["./patient-drugs.component.scss"],
})
export class PatientDrugsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() set patientUser(pu: PatientUser) {
    if (pu?.user?.caremateIdentifier) {
      this.pu = pu;
      this.initDataAndAutoRefresh(pu.user.caremateIdentifier, this.preferenceService.getAutoRefreshWidget(), true);
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<unknown>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("focus") target: ElementRef;
  public pu: PatientUser;
  public scrollAfterDataInit = false;
  public displayedColumns: string[] = ["name", "label", "startDate", "endDate", "ratioIntake"];
  public drugs: IEntitylink[] = [];
  public entities: Entitylink[] = [];
  public dataSource: ObservationsListDataSource;
  public intakeDrugs: Notification[] = [];
  private currentPageSize: number;
  public isBig = false;
  public sliderData: Item[] = [];
  public activeOnlyPref = true;
  public historic: IDrugsHistoric[] = [];
  public historicIsLoading = true;
  public knowledgesLoaded = false;
  public drugsKnowledges: IKnowledges[];
  public KNOW_DOC_CATEGORY = KNOW_DOC_CATEGORY;
  public drugsInfo: IDrugInfo[] = [];

  /**
   * Filter
   */
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public globalSearchValue = "";
  public loading = true;
  public intakeIsLoading = true;
  private refreshInterval;
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();
  private $subDrugs: Subscription;
  private $subIntakes: Subscription;
  private $subHistoric: Subscription;
  private $subPrefUpdate: Subscription;
  private $subPrefUpdate2: Subscription;
  private $subPrefList: Subscription;

  constructor(
    private drugsService: DrugsService,
    private preferenceService: PreferenceService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private sessionService: SessionService,
    public helpData: HelpData,
    private tileManager: TileManager,
    private snackBar: MatSnackBar,
    private drugSchemaService: DrugSchemaService,
    private knowledgeService: KnowledgeService,
    private userService: UserService
  ) {
    this.sessionService.refreshDrugsData.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.initDataAndAutoRefresh(this.pu.user.caremateIdentifier, this.preferenceService.getAutoRefreshWidget());
    });
    this.isBig = TileManager.isBig(PatientWidgetName.DRUGS);
  }

  ngOnInit(): void {
    this.dataSource = new ObservationsListDataSource(this.drugsService, this.translateService);
  }

  ngAfterViewInit(): void {
    this.initPaginatorAndSort();
  }

  ngOnDestroy(): void {
    this.clearAutoRefresh();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public initDataAndAutoRefresh(userId: string, _autoRefreshIntervalMs = 60000, withSort = false): void {
    if (this.refreshInterval) {
      this.clearAutoRefresh();
    }
    // this.refreshInterval = setInterval(() => { this.initData(userId); }, autoRefreshIntervalMs);
    this.initData(userId, withSort);
  }

  public clearAutoRefresh(): void {
    clearInterval(this.refreshInterval);
  }

  public initData(userId: string, withSort = false): void {
    this.loadPreferences();
    const drugsObs = this.drugsService.list(userId);
    const drugIntake = this.drugsService.listIntake(userId);
    const drugHistoric = this.drugsService.getHistoric(userId);
    if (this.$subDrugs) {
      this.$subDrugs.unsubscribe();
    }
    this.$subDrugs = drugsObs.pipe(takeUntil(this.onDestroy$)).subscribe((allDrugs) => {
      this.entities = allDrugs;
      this.drugs = [];
      allDrugs.forEach((entity) => this.drugs.push(entity as IEntitylink));
      this.loadDataSource();
      if (withSort) {
        this.sort.sort({
          id: "name",
          start: "asc",
          disableClear: false,
        });
      }
      this.loading = false;
      if (this.userService.isAuthorizedSync(null, "knowledge", "GET")) {
        this.loadKnowledges();
      }
    });
    if (this.$subIntakes) {
      this.$subIntakes.unsubscribe();
    }
    this.$subIntakes = drugIntake.pipe(takeUntil(this.onDestroy$)).subscribe((allIntake) => {
      this.intakeDrugs = allIntake;
      this.intakeIsLoading = false;
    });
    if (this.$subHistoric) {
      this.$subHistoric.unsubscribe();
    }
    this.$subHistoric = drugHistoric.pipe(takeUntil(this.onDestroy$)).subscribe((historic) => {
      this.historic = historic;
      this.historicIsLoading = false;
    });
  }

  public initPaginatorAndSort(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;

    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((page) => {
      if (this.currentPageSize !== page.pageSize) {
        this.updatePreference(false);
      }
      this.currentPageSize = this.paginator.pageSize;
    });
  }

  public loadDataSource(): void {
    this.dataSource.loadData(this.drugs, this.activeOnlyPref);
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
  }

  public clearFilter(): void {
    this.globalSearchValue = "";
    this.dataSource.clearFilter();
  }

  /**
   * Preferences
   */
  public updatePreference(updateFocus: boolean): void {
    if (this.$subPrefUpdate) {
      this.$subPrefUpdate.unsubscribe();
    }
    this.$subPrefUpdate = this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_DRUGS_LIST,
        parameters: {
          filters: this.dataSource.getAllFilters(),
          isBig: this.isBig,
          activeOnlyDrugs: this.activeOnlyPref,
        } as WidgetPatientParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.isBig && updateFocus) {
          this.updateLastFocus();
        }
      });
  }

  private loadPreferences() {
    if (this.$subPrefList) {
      this.$subPrefList.unsubscribe();
    }
    this.$subPrefList = this.preferenceService
      .list(PreferenceContext.PATIENT_DRUGS_LIST)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: WidgetPatientParameter) => {
        if (parameters) {
          this.isBig = this.sessionService.globalPref?.keepLayoutFromPatientPage
            ? parameters.isBig
            : TileManager.isBig(PatientWidgetName.DRUGS);
          this.tileManager.updateList(PatientWidgetName.DRUGS, this.isBig);

          // Apply saved filters
          parameters.filters.forEach((filter: Filter) => {
            this.applyFilter(filter);
          });
          this.activeOnlyPref = parameters.activeOnlyDrugs;
        }
        this.computeColumn();

        if (this.scrollAfterDataInit && this.isBig && this.sessionService.globalPref?.keepLayoutFromPatientPage) {
          this.scroll();
        }
      });
  }

  public onPlus(): void {
    this.isBig = !this.isBig;
    this.tileManager.updateList(PatientWidgetName.DRUGS, this.isBig);
    this.computeColumn();
    this.updatePreference(true);
    this.loadDataSource();
    this.scroll();
  }

  public updateLastFocus(): void {
    if (this.$subPrefUpdate2) {
      this.$subPrefUpdate2.unsubscribe();
    }
    this.$subPrefUpdate2 = this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_PAGE,
        parameters: {
          lastFocusWidgetName: PatientWidgetName.DRUGS,
        } as PatientPageParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  /**
   * TODO : when we move mouse after click, scrollIntoView seems to not work
   */
  public scroll(): void {
    if (this.target) {
      setTimeout(() => {
        this.target.nativeElement.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }

  public isInfinite(date: string): boolean {
    if (date && date.length && date[0] === "9") {
      return true;
    }
  }

  public computeColumn(): void {
    if (this.isBig) {
      this.displayedColumns = ["name", "label", "moment", "startDate", "endDate", "prescriptor", "ratioIntake", "action"];
    } else {
      this.displayedColumns = ["name", "startDate", "endDate", "ratioIntake", "action"];
    }
  }

  public showMore(element: IEntitylink): void {
    const entityId = element._id;
    const drugHistoric = this.historic.filter((v) => v.entityId === entityId).reverse();
    const supposedIntakes = DrugsService.getSupposedIntakes(element.entityData, drugHistoric);
    const cibledNotif = DrugsService.getCibledNotification(entityId, this.intakeDrugs).reverse();
    const combinedNotifs = DrugsService.combineSupposedAndRealIntakes(supposedIntakes, cibledNotif);
    this.dialog.open(DrugDetailsComponent, {
      data: { drug: element, notifications: combinedNotifs, patientId: this.pu.user.caremateIdentifier },
      height: "900px",
    });
  }

  public isValidGauge(element: IEntitylink): boolean {
    const entityId = element._id;
    const cibledNotif = DrugsService.getCibledNotification(entityId, this.intakeDrugs).reverse();
    return cibledNotif?.length > 0;
  }

  public getPerformerName(ref: string): string {
    return this.drugsService.getPerformerName(ref);
  }

  public isHospital(element: EntityDrug): boolean {
    return element.performer.reference === "H";
  }

  public getTiming(element: EntityDrug): string {
    return this.drugsService.getTiming(element);
  }

  public onActiveOnlyChange(): void {
    this.loadDataSource();
    this.updatePreference(false);
  }

  public getInactiveNbr(): number {
    return this.drugs.length - this.dataSource.data.length;
  }

  public openDrugHelp(): void {
    this.dialog.open(GlobalHelpDialogComponent, {
      data: { slides: this.helpData.patientDrugsHelp },
      disableClose: true,
    });
  }

  public addDrug(): void {
    this.dialog.open(DrugAddComponent, {
      data: {
        patientUser: this.pu,
        mode: FORMS_MODE.CREATE,
      },
      disableClose: true,
    });
  }

  public updateDrug(drug: IEntitylink): void {
    this.dialog.open(DrugAddComponent, {
      data: {
        patientUser: this.pu,
        mode: FORMS_MODE.UPDATE,
        drug,
      },
      disableClose: true,
    });
  }

  public canUpdate(drug: IEntitylink): boolean {
    return drug.parentId === this.sessionService.account.caremateIdentifier;
  }

  public showHistoric(): void {
    this.dialog.open(DrugsHistoricComponent, {
      data: {
        patientId: this.pu.user.caremateIdentifier,
        allDrugs: this.drugs,
        historic: this.historic.reverse(),
        mode: HISTORIC_MODE.GLOBAL,
      },
      disableClose: true,
    });
  }

  public deleteDrug(drug: IEntitylink): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          message: this.translateService.instant("common.removeForEverAsk"),
          type: ConfirmationDialogType.CONFIRM,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((yes) => {
        if (yes) {
          this.drugsService
            .delete(drug)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
              const msg = this.translateService.instant("common.success");
              this.snackBar.open(msg, "ok", { duration: 3000 });
              this.sessionService.needRefreshDrugsData();
            });
        }
      });
  }

  public showHistoricLocal(drug: IEntitylink): void {
    this.dialog.open(DrugsHistoricComponent, {
      data: {
        patientId: this.pu.user.caremateIdentifier,
        allDrugs: this.drugs.filter((v) => v._id === drug._id),
        historic: this.historic.filter((v) => v.entityId === drug._id).reverse(),
        mode: HISTORIC_MODE.LOCAL,
      },
      disableClose: true,
    });
  }

  public OnPauseCycle(element: IEntitylink): void {
    const dialogRef = this.dialog.open(PauseDrugSchemaComponent, {
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          const drug = Tools.clone(element);
          drug.actionStatus = ActionStatusEntity.CREATED;
          if (drug.entityData.cycle.pauseDate && drug.entityData.cycle.pauseDate.length > 0) {
            drug.entityData.cycle.pauseDate.push(new Date(result));
          } else {
            drug.entityData.cycle.pauseDate = [new Date(result)];
          }
          this.drugsService
            .save(drug)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
              this.snackBar.open(this.translateService.instant("common.success"), "ok", { duration: 3000 });
              this.sessionService.needRefreshDrugsData();
            });
        }
      });
  }

  /*
     * We keep this feature as a comment in case we are asked to reactivate it
        public onResumeCycle(element: IEntitylink) {
            this.dialog.open(ConfirmationDialogComponent, {
                data: {
                    message: this.translateService.instant('drugSchema.playConfirm'),
                    type: ConfirmationDialogType.CONFIRM
                },
                disableClose: true
            }).afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe((yes) => {
                if (yes) {
                    const drug = Tools.clone(element);
                    drug.actionStatus = ActionStatusEntity.CREATED;
                    this.drugsService.save(drug).pipe(takeUntil(this.onDestroy$)).subscribe(() => {
                        this.snackBar.open(
                            this.translateService.instant('common.success'),
                            'ok',
                            { duration: 3000 }
                        );
                        this.sessionService.needRefreshDrugsData();
                    });
                }
            });
        }
    */

  public isCycleInPause(cycle: CycleSchema): boolean {
    return this.drugSchemaService.isCycleInPause(cycle);
  }

  public getFormatDate(d: Date): string {
    const date = new Date(d);
    return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
  }

  public onCalendar(): void {
    this.dialog.open(DrugsCalendarComponent, {
      data: {
        allDrugs: this.drugs,
      },
      disableClose: true,
    });
  }

  public onExportDrugs(): void {
    this.drugsService.exportDrugs(this.pu.user.caremateIdentifier, this.sessionService.userLang);
  }

  public onAddIntake(drug: IEntitylink): void {
    const dialogRef = this.dialog.open(AddDrugIntakeDialogComponent, {
      data: {
        patientId: this.pu.user.caremateIdentifier,
        drug: drug,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          this.sessionService.needRefreshDrugsData();
        }
      });
  }

  private loadKnowledges(): void {
    const drugs: EntityDrug[] = this.drugs.map((e) => e.entityData);
    const observables: Observable<any>[] = [this.knowledgeService.getDrugsKnowledges(drugs)];
    drugs.forEach((d) => {
      if (Tools.isDefined(d?.reference)) {
        observables.push(this.drugsService.getDrugInfo(d));
      }
    });
    forkJoin(observables)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result?.length > 0) {
          this.drugsKnowledges = result[0];
          for (let i = 1; i < result.length; i++) {
            this.drugsInfo.push(result[i]);
          }
          this.knowledgesLoaded = true;
        }
      });
  }

  public showKnowledges(drug: EntityDrug, docCategory: KNOW_DOC_CATEGORY): void {
    const medias: IKnowMedia[] = [];
    const knowledges = this.drugsKnowledges.find((dk) => dk.reference === drug.name);
    knowledges.knowledges.forEach((k) => {
      if (k.documentCategory === docCategory) {
        medias.push(...k.medias);
      }
    });
    this.dialog.open(KnowledgeMediaListModalComponent, {
      data: {
        medias: medias,
        translateKeyTitle: "knowledgebase.doccategory." + docCategory,
      },
      minWidth: "50vw",
    });
  }

  public onURL(drug: EntityDrug): void {
    const userLang = this.sessionService.userLang;
    const infos: IDrugInfo = this.drugsInfo.find((d) => d?.cnk === drug.reference);
    if (infos) {
      const url = infos[userLang] ? infos[userLang].url : infos.fr.url;
      window.open(url, "_blank");
    }
  }
}
