<div [formGroup]="form">
  <div *ngIf="definition.type === 'array'" [formGroupName]="definition.code">
    <span class="fail pb-1" *ngIf="!isValid">{{ errorMessage | translate }}</span>

    <!-- <span position="stacked">{{definition.nameMobile | getTranslation:''}} ({{definition.unit | getTranslation:''}})</span> -->

    <div class="card-container">
      <mat-card *ngFor="let col of definition.columns" class="card">
        <div>
          {{ col.name | getTranslation : "" }}
        </div>

        <div class="column-card">
          <div *ngFor="let row of definition.rows; let index = index">
            <div class="cell">
              <span class="cell-title" *ngIf="row.name | getTranslation : ''">{{ row.name | getTranslation : "" }}</span>
              <span class="cell-title" *ngIf="row.start && row.start !== 0 && row.end && row.end !== 0"
                >{{ row.start + " - " + row.end + " " + (row.unit | getTranslation : "") }}
              </span>
              <span class="cell-title" *ngIf="(!row.start || row.start === 0) && row.end && row.end !== 0">{{
                "\< " + row.end + " " + (row.unit | getTranslation : "")
              }}</span>
              <span class="cell-title" *ngIf="row.start && row.start !== 0 && (!row.end || row.end === 0)">{{
                "\> " + row.start + " " + (row.unit | getTranslation : "")
              }}</span>
              <div class="value-container">
                <input
                  matInput
                  type="number"
                  autocomplete="off"
                  [formControlName]="col.coding.code + '-' + index"
                  [min]="definition.min ? definition.min : 0"
                  [max]="definition.max"
                /><span>{{ definition.unit | getTranslation : "" }}</span>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
