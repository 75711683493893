import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ITranslation } from "src/app/models/translation.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class LanguagesApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Languages", // Theme !
      ["dashboard/translations"], // Post route paths
      ["dashboard/translations"], // Get route paths
      ["dashboard/translations"], // Put route paths
      ["dashboard/translations"] // Delete route paths
    );
  }

  public list(): Observable<ITranslation[]> {
    return this.api.get("languages") as Observable<ITranslation[]>;
  }

  public getTranslations(terms: string): Observable<ITranslation[]> {
    return this.api.get(this.readRoutes[0], { terms: terms }) as Observable<ITranslation[]>;
  }

  public deleteTranslations(terms: string): Observable<boolean> {
    return this.api.get(this.deleteRoutes[0], { terms: terms }) as Observable<boolean>;
  }

  public createTranslations(translations: ITranslation[]): Observable<ITranslation[]> {
    return this.api.post(this.createRoutes[0], translations) as Observable<ITranslation[]>;
  }

  public updateTranslations(translations: ITranslation[]): Observable<ITranslation[]> {
    return this.api.put(this.updateRoutes[0], translations) as Observable<ITranslation[]>;
  }
}
