import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { Tools } from "../helpers/tools";
import { Account, UserRole } from "../models/account.interface";
import { Identifier } from "../models/identifier.interface";
import { GlobalParameter } from "../models/preference.interface";
import { Reference } from "../models/reference.interface";

export const enum PATH_URL {
  PRODUCTION = ".appserver.comunicare.io",
  QA = ".testsrv.comunicare.io",
  DEV = ".devsrv.comunicare.io",
}

@Injectable({
  providedIn: "root",
})
export class SessionService {
  private readonly tokenIndex = "access_token";
  private readonly idTokenIndex = "id_token";
  private readonly langIndex = "lang";
  private readonly serviceIndex = "service";
  private readonly organizationIndex = "organization";
  private readonly monitoringOrgIndex = "monitoringOrg";
  private readonly monitoringServiceIndex = "monitoringService";
  private readonly countryIndex = "country";

  private readonly patientPageFromIndex = "patientPageFrom";
  private readonly accountSubject = new BehaviorSubject<Account>(undefined);
  private readonly globalPrefSubject = new BehaviorSubject<GlobalParameter>(undefined);
  private readonly tokenSubject = new BehaviorSubject<string>(undefined);
  private readonly COOKIE_ACCESS_TOKEN = "x-access-token";
  private patientListIndex = 0;

  public readonly refreshPatientUser = new Subject<void>();
  public readonly refreshPatientDataList = new Subject<void>();
  public readonly refreshQRDataList = new Subject<void>();
  public readonly refreshAlertDetailsDataList = new Subject<void>();
  public readonly refreshTeleconsultationsDataList = new Subject<void>();
  public readonly openCpModale = new Subject<void>();
  public readonly refreshGroupDataList = new Subject<void>();
  public readonly refreshPatientPage = new Subject<void>();
  public readonly refreshPatientWidgets = new Subject<void>();
  public readonly refreshDashboardPage = new Subject<void>();
  public readonly refreshDrugsData = new Subject<void>();
  public readonly refreshRewardDetailsDataList = new Subject<void>();
  public readonly refreshRedAlertWidget = new Subject<void>();
  public readonly refreshOrangeAlertWidget = new Subject<void>();
  public readonly refreshLastActivityWidget = new Subject<void>();
  public readonly refreshCp = new Subject<void>();
  public readonly refreshCommunicationsList = new Subject<void>();
  public readonly refreshRewardsList = new Subject<void>();
  public readonly refreshDrugSchemaList = new Subject<void>();
  public readonly refreshOrganizationsList = new Subject<void>();
  public readonly refreshVitalSignsTile = new Subject<void>();
  public readonly refreshServicesList = new Subject<void>();
  public readonly refreshCurrentService = new Subject<void>();
  public readonly refreshCurrentMonitService = new Subject<void>();
  public readonly refreshCurrentMonitOrg = new Subject<void>();
  public readonly refreshServerTraductions = new Subject<void>();
  public readonly refreshDeleteRequestList = new Subject<void>();
  public readonly refreshObservationsList = new Subject<void>();
  public readonly servicesSetupWatch = new Subject<void>();
  public readonly onGoingActionWatch = new BehaviorSubject<boolean>(false);

  public readonly DEFAULT_LANG = "fr";
  public readonly FRANCE = "FR";
  public readonly BELGIUM = "BE";
  public readonly allsOption = "all";
  public readonly allsReference: Reference = {
    reference: this.allsOption,
    display: this.allsOption,
  };
  public readonly drugSchemaEachRowsNumberOfDays = 31;
  public redirectUrl: string;
  private private_ANS_otherIDs: Identifier[] = [];
  public get ANS_otherIDs(): Identifier[] {
    return this.private_ANS_otherIDs;
  }
  public set ANS_otherIDs(value: Identifier[]) {
    this.private_ANS_otherIDs = value;
  }

  constructor(private cookieService: CookieService) {
    this.tokenSubject.next(this.token);
  }

  public get pageIndex(): number {
    return this.patientListIndex;
  }

  public set pageIndex(v: number) {
    this.patientListIndex = v;
  }

  public needRefreshPatientUser(): void {
    this.refreshPatientUser.next();
  }

  public needRefreshPatientPage(): void {
    this.refreshPatientPage.next();
  }

  public needRefreshPatientWidgets(): void {
    this.refreshPatientWidgets.next();
  }

  public needRefreshDashboardPage(): void {
    this.refreshDashboardPage.next();
  }

  public needRefreshDrugsData(): void {
    this.refreshDrugsData.next();
  }

  public needRefreshPatientDataList(): void {
    this.refreshPatientDataList.next();
  }

  public needRefreshGroupDataList(): void {
    this.refreshGroupDataList.next();
  }

  public needRefreshCommunicationsList(): void {
    this.refreshCommunicationsList.next();
  }

  public needRefreshRewardsList(): void {
    this.refreshRewardsList.next();
  }

  public needRefreshDrugSchemaList(): void {
    this.refreshDrugSchemaList.next();
  }

  public needRefreshCp(): void {
    this.refreshCp.next();
  }

  public needRefresQRDataList(): void {
    this.refreshQRDataList.next();
  }

  public needOpenCpModale(): void {
    this.openCpModale.next();
  }

  public needRefreshAlertDetailsDataList(): void {
    this.refreshAlertDetailsDataList.next();
  }

  public needRefreshRewardDetailsDataList(): void {
    this.refreshRewardDetailsDataList.next();
  }

  public needRefreshTeleconsultationsDataList(): void {
    this.refreshTeleconsultationsDataList.next();
  }

  public needRefreshRedAlertWidget(): void {
    this.refreshRedAlertWidget.next();
  }

  public needRefreshOrangeAlertWidget(): void {
    this.refreshOrangeAlertWidget.next();
  }

  public needRefreshLastActivityWidget(): void {
    this.refreshLastActivityWidget.next();
  }

  private needRefreshServerTraductions(): void {
    this.refreshServerTraductions.next();
  }

  public needRefreshDeleteRequestList(): void {
    this.refreshDeleteRequestList.next();
  }
  public needRefreshObservationsList(): void {
    this.refreshObservationsList.next();
  }

  public needRefreshServicesList(): void {
    this.refreshServicesList.next();
  }

  public needRefreshVitalSignsTile(): void {
    this.refreshVitalSignsTile.next();
  }

  public needRefreshOrganizationsList(): void {
    this.refreshOrganizationsList.next();
  }

  public servicesHaveBeenSetup(): void {
    this.servicesSetupWatch.next();
  }

  public clear(): void {
    localStorage.removeItem(this.tokenIndex);
    localStorage.removeItem(this.idTokenIndex);
    localStorage.removeItem(this.serviceIndex);
    localStorage.removeItem(this.organizationIndex);
    localStorage.removeItem(this.monitoringOrgIndex);
    localStorage.removeItem(this.monitoringServiceIndex);
    this.ANS_otherIDs = [];
    if (this.cookieService.check(this.COOKIE_ACCESS_TOKEN)) {
      this.cookieService.delete(this.COOKIE_ACCESS_TOKEN, "/", this.pathByEnv);
    }
  }

  public set token(token: string) {
    localStorage.setItem(this.tokenIndex, token);
    this.tokenSubject.next(token);
  }

  public get token(): string {
    if (this.cookieService.check(this.COOKIE_ACCESS_TOKEN)) {
      const cookieToken = this.cookieService.get(this.COOKIE_ACCESS_TOKEN);
      this.cookieService.delete(this.COOKIE_ACCESS_TOKEN, "/", this.pathByEnv);
      this.token = cookieToken;
    }

    return localStorage.getItem(this.tokenIndex);
  }

  public set idToken(token: string) {
    localStorage.setItem(this.idTokenIndex, token);
  }

  public get idToken(): string {
    return localStorage.getItem(this.idTokenIndex);
  }
  public get pathByEnv(): string {
    return environment.production ? PATH_URL.PRODUCTION : environment.envName === "dev" ? PATH_URL.DEV : PATH_URL.QA;
  }

  public getObservableToken(): Observable<string> {
    return this.tokenSubject.asObservable();
  }

  public get isLoggedIn(): boolean {
    return this.token !== null ? true : false;
  }

  public getRedirect(): string {
    const url = this.redirectUrl ? this.redirectUrl : "/dashboard";
    this.redirectUrl = undefined;
    return url;
  }

  public set userLang(lang: string) {
    localStorage.setItem(this.langIndex, lang);
    this.needRefreshServerTraductions();
    this.needRefreshCp();
  }

  public get userLang(): string {
    const lang = localStorage.getItem(this.langIndex);
    return lang ? lang : this.DEFAULT_LANG;
  }

  public set currentService(service: Reference) {
    const current = this.currentService;
    if (service?.reference === current?.reference) {
      return;
    }
    localStorage.setItem(this.serviceIndex, JSON.stringify(service));
    this.refreshCurrentService.next();
  }

  public get currentService(): Reference | null {
    const dep = localStorage.getItem(this.serviceIndex);
    return Tools.isDefined(dep) ? (JSON.parse(dep) as Reference) : null;
  }

  public set currentCountry(country: string) {
    const current = this.currentCountry;
    if (country === current) {
      return;
    }
    localStorage.setItem(this.countryIndex, country);
  }

  public get currentCountry(): string | null {
    const country = localStorage.getItem(this.countryIndex);
    return country ? country : null;
  }

  public set organization(orga: Reference) {
    const current = this.organization;
    if (orga?.reference === current?.reference) {
      return;
    }
    localStorage.setItem(this.organizationIndex, JSON.stringify(orga));
  }

  public get organization(): Reference | null {
    const orga = localStorage.getItem(this.organizationIndex);
    return orga && orga !== "undefined" ? (JSON.parse(orga) as Reference) : null;
  }

  public set currentMonitoringOrg(orga: Reference) {
    const current = this.currentMonitoringOrg;
    if (orga?.reference === current?.reference) {
      return;
    }
    localStorage.setItem(this.monitoringOrgIndex, JSON.stringify(orga));
    this.refreshCurrentMonitOrg.next();
  }

  public get currentMonitoringOrg(): Reference | null {
    const orga = localStorage.getItem(this.monitoringOrgIndex);
    return orga && orga !== "undefined" ? (JSON.parse(orga) as Reference) : null;
  }
  public set currentMonitoringService(service: Reference) {
    const current = this.currentMonitoringService;
    if (service?.reference === current?.reference) {
      return;
    }
    localStorage.setItem(this.monitoringServiceIndex, JSON.stringify(service));
    this.refreshCurrentMonitService.next();
  }

  public get currentMonitoringService(): Reference | null {
    const service = localStorage.getItem(this.monitoringServiceIndex);
    return Tools.isDefined(service) ? (JSON.parse(service) as Reference) : null;
  }

  public set account(account: Account) {
    this.accountSubject.next(account);
  }

  public get account(): Account {
    return this.accountSubject.getValue();
  }

  public getObservableAccount(): Observable<Account> {
    return this.accountSubject.asObservable();
  }

  public set globalPref(preference: GlobalParameter) {
    this.globalPrefSubject.next(preference);
  }

  public get globalPref(): GlobalParameter {
    return this.globalPrefSubject.getValue();
  }

  public getObservablePref(): Observable<GlobalParameter> {
    return this.globalPrefSubject.asObservable();
  }

  public set patientPageFrom(url: string) {
    localStorage.setItem(this.patientPageFromIndex, url);
  }

  public get patientPageFrom(): string {
    return localStorage.getItem(this.patientPageFromIndex);
  }

  public isAdmin(): boolean {
    return this.account?.role.indexOf(UserRole.ADMIN) >= 0;
  }

  public isOrgaAdmin(): boolean {
    return this.account?.role.indexOf(UserRole.ORAGNIZATION_ADMIN) >= 0;
  }

  public isAllServices(isMonitoringUser: boolean): boolean {
    const current = isMonitoringUser ? this.currentMonitoringService : this.currentService;
    if (current && current.reference === this.allsOption) {
      return true;
    }
    return false;
  }

  /**
   * Toggle onGoingActionWatch value. Use to check if an ongoing action would be interrupted when changing service, organisation, monitoring service or monitoring organization.
   * E.g. When editing a knowledge Criteria.
   */
  public onGoingActionToggle(bool: boolean): void {
    if (bool) {
      this.onGoingActionWatch.next(true);
    } else {
      this.onGoingActionWatch.next(false);
    }
  }

  public get onGoingAction(): boolean {
    return this.onGoingActionWatch.getValue();
  }
}
