<h3 *ngIf="item.itemType === action_target.QUESTIONNAIRE">{{ "page.careplanEditor.tabs.questionnaires.criteriaTitle" | translate }}</h3>
<h3 *ngIf="item.itemType === action_target.OBSERVATION">{{ "page.careplanEditor.tabs.observations.criteriaTitle" | translate }}</h3>

<app-when-input
  [(showDirectly)]="showDirectly"
  [hasSelectedActivity]="item.originReference"
  [needShowDirectly]="item.originReference ? false : true"
  [(period)]="period"
  [(periodUnit)]="periodUnit"
  [isDisabled]="item.itemType === action_target.OBSERVATION && item.origin === 'link2careplan' ? true : false"
  [context]="item.itemType"
  class="when-input"
></app-when-input>

<div class="mt-1">
  <button class="mr-05" mat-stroked-button [matTooltip]="'knowledgebase.knowledgeCriteria.reset' | translate" (click)="cancel()">
    {{ "btn.cancel" | translate }}
  </button>

  <button mat-flat-button color="primary" [matTooltip]="'btn.save' | translate" (click)="save()">
    {{ "btn.save" | translate }}
  </button>
</div>
