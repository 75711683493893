import { CdkDrag } from "@angular/cdk/drag-drop";
import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { HelpData } from "src/app/helpers/helpData";
import { IWidgetComponent, WidgetName } from "src/app/models/widget.interface";

@Component({
  selector: "app-patient-alert-red-widget",
  templateUrl: "./patient-alert-red-widget.component.html",
  styleUrls: ["./patient-alert-red-widget.component.scss"],
})
export class PatientAlertRedWidgetComponent implements IWidgetComponent {
  @ViewChild(CdkDrag, { static: true }) cdkDrag: CdkDrag;
  public isDraggable = true;
  public widgetName = WidgetName.PATIENT_ALERT_RED;

  constructor(private dialog: MatDialog, public helpData: HelpData) {}

  public openRedAlertHelp(): void {
    this.dialog.open(GlobalHelpDialogComponent, {
      data: { slides: this.helpData.patientsRedAlert },
      disableClose: true,
    });
  }
}
