import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "translationReady",
})
export class TranslationReadyPipe implements PipeTransform {
  transform(value: { lang: string; translateKey: string }): unknown {
    if (value.translateKey.startsWith("$TR$")) {
      return false;
    } else {
      return true;
    }
  }
}
