import moment from "moment";
import { ITableRow } from "../components/patient-observations/moment-observation-table/moment-observation-table.component";
import { IDayObs, IObservation } from "../models/observations.interface";

export class ObservationHelper {
  public static groupObservationsByDay(observations: IObservation[]): IDayObs[] {
    observations.sort((a, b) => (moment(a.issued).isBefore(b.issued) ? 1 : -1));
    const results: ITableRow[] = [];
    for (const obs of observations) {
      const index = results.findIndex((row) => moment(row.date).isSame(moment(obs.issued), "day"));
      if (index >= 0) {
        results[index].observations.push(obs);
      } else {
        results.push({ date: moment(obs.issued), observations: [obs] });
      }
    }
    return results;
  }

  /**
   * Remove suffix from given loinc code; if no suffix return given loinc code
   * @param loinc the loinc code with a suffix (ex: 3141-9-HT)
   * @returns the loinc code without the suffix (ex: 3141-9)
   */
  public static ignoreSuffix(loinc: string): string {
    if (loinc.search("-") >= 0) {
      const arr = loinc.split("-");
      return `${arr[0]}-${arr[1]}`;
    } else {
      return loinc;
    }
  }
}
