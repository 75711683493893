<form action="" *ngIf="isInit">
  <mat-toolbar class="app-toolbar">
    <h2 *ngIf="careplan && !noCp" color="secondary" class="fontLarger align-middle">
      {{
        (patient.patient | getPatientFullName) +
          (patient.patient.pseudoname
            ? "
            (" +
              patient.patient.pseudoname +
              ")"
            : "")
      }}
    </h2>
    <span class="spacerBetween"></span>
    <h2 class="align-middle" *ngIf="!createNewCareplan && !noCp">
      <!-- {{'page.careplan.title' | translate}} -->
      {{ templateSelectedIndex ? initializedCareplanTemplates[templateSelectedIndex].description : careplan?.description }}
    </h2>
    <span class="spacerBetween"></span>
    <button
      mat-icon-button
      class="negative-margin align-middle color-button"
      matTooltipClass="helpTooltip"
      matTooltipPosition="left"
      #tooltip="matTooltip"
      matTooltip="{{ 'globalHelp.editCareplanHelp' | translate }}"
      aria-label="icon button with a ? icon"
      (click)="tooltip.toggle()"
    >
      <mat-icon>help_outline</mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      *ngIf="!noCp"
      class="negative-margin align-middle"
      (click)="save()"
      matTooltip="{{ 'common.save' | translate }}"
      [disabled]="!authorizedToEdit || isLoading"
      id="careplanSaveBtn"
    >
      <mat-icon>save</mat-icon>
    </button>
    <button
      *ngIf="!includeBirthdateAndGenderForm"
      mat-icon-button
      color="warn"
      class="negative-margin align-middle"
      mat-dialog-close
      matTooltip="{{ 'btn.close' | translate }}"
      id="careplanDialogCloseBtn"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

  <div class="content-panel">
    <!-- loading spinner -->
    <mat-spinner class="centerSpinner" *ngIf="!pageLoaded"></mat-spinner>
    <!-- Edit anonymous form patient (only birthdate and gender if not init) -->
    <app-edit-anonymous-patient *ngIf="includeBirthdateAndGenderForm" [patientUser]="patient"> </app-edit-anonymous-patient>
    <ng-container *ngIf="pageLoaded">
      <ng-container *ngIf="createNewCareplan">
        <div>
          <!-- careplan template selection -->
          <h2 color="secondary" *ngIf="initializedCareplanTemplates.length > 1 || departments.length > 1" class="fontLarger">
            {{
              isAllServices
                ? ("page.careplan.choosetemplate" | translate) + ("page.careplan.in" | translate)
                : ("page.careplan.choosetemplate" | translate)
            }}
            &nbsp;
          </h2>
          <!-- Department Selector (Services) -->
          <mat-form-field *ngIf="!registration && isAllServices" floatLabel="never" class="department-select">
            <mat-select
              [compareWith]="compareReference"
              [placeholder]="'forms.services' | translate"
              [(value)]="selectedDepartment"
              (selectionChange)="updateDepartment($event)"
            >
              <mat-option *ngFor="let department of departments" [value]="department">
                {{ department.display }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="!noCp" id="careplansListContainer">
          <ng-container *ngFor="let template of initializedCareplanTemplates; let i = index">
            <button
              class="btn-template"
              mat-stroked-button
              color="primary"
              *ngIf="templateSelectedIndex !== i"
              (click)="onTemplateSelected(i)"
            >
              {{ template.description }}
            </button>
            <button
              class="btn-template"
              mat-raised-button
              color="primary"
              *ngIf="templateSelectedIndex === i"
              (click)="onTemplateSelected(i)"
            >
              {{ template.description }}
            </button>
          </ng-container>
        </div>
      </ng-container>

      <!-- Careplan view -->
      <table class="w-100" *ngIf="careplan">
        <tr>
          <td>
            <!-- careplan status -->
            <mat-form-field class="w-100 font-12" style="padding-bottom: 4px">
              <mat-label>{{ "status.label" | translate }}</mat-label>
              <mat-select [(ngModel)]="careplan.status" name="status" [disabled]="!authorizedToEdit">
                <mat-option value="draft">{{ "status.draft" | translate }}</mat-option>
                <mat-option value="inactive">{{ "status.inactive" | translate }}</mat-option>
                <mat-option value="active">{{ "status.active" | translate }}</mat-option>
                <mat-option value="archive">{{ "status.archive" | translate }}</mat-option>
                <mat-option value="error">{{ "status.error" | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td>
            <!-- careplan start date -->
            <mat-form-field class="w-100 font-12">
              <mat-label>{{ "schedule.dateFrom" | translate }}</mat-label>
              <input
                matInput
                [(ngModel)]="careplan.period.start"
                [matDatepicker]="dateFromPicker"
                name="periodStart"
                required
                [disabled]="!authorizedToEdit"
                [max]="noEndDate ? null : careplan.period.end"
                (change)="onPeriodStartDateChanged(careplan.period.start, careplan.period.end)"
                (click)="dateFromPicker.open()"
              />
              <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
              <mat-datepicker #dateFromPicker></mat-datepicker>
            </mat-form-field>
          </td>
          <td *ngIf="!noEndDate">
            <!-- careplan end date -->
            <mat-form-field class="w-100 font-12">
              <mat-label>{{ "schedule.dateTo" | translate }}</mat-label>
              <input
                matInput
                [(ngModel)]="careplan.period.end"
                [matDatepicker]="dateToPicker"
                name="periodEnd"
                required
                [disabled]="!authorizedToEdit"
                [min]="careplan.period.start"
                (change)="onPeriodEndDateChanged(careplan.period.start, careplan.period.end)"
                (click)="dateToPicker.open()"
              />
              <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
              <mat-datepicker #dateToPicker></mat-datepicker>
            </mat-form-field>
          </td>
          <td>
            <mat-slide-toggle [(ngModel)]="noEndDate" name="noEndDate" class="ml-15" [disabled]="!authorizedToEdit">
              {{ "mydrugs.noEndDate" | translate }}
            </mat-slide-toggle>
          </td>
          <td>
            <!-- careplan reference -->
            <mat-form-field class="w-100 font-12">
              <mat-icon matSuffix class="fa fa-key"></mat-icon>
              <mat-label>{{ "knowledgebase.snomedRef" | translate }}</mat-label>
              <input
                [readonly]="true"
                matInput
                placeholder="{{ 'page.patient.reference' | translate }}"
                [(ngModel)]="careplan.category[0].coding[0].code"
                name="careplanRef"
              />
            </mat-form-field>
          </td>
        </tr>
        <!-- Display each addresses if exist -->
        <tr *ngIf="careplan?.addresses && careplan?.addresses?.length">
          <td>
            <span style="margin-left: 20%">{{ "careplan.type" | translate }}</span>
            <ul [ngClass]="{ 'has-error': anyAddressActive === false }">
              <li *ngFor="let addresse of careplan?.addresses">
                <mat-checkbox
                  [checked]="addresse.status === 'active'"
                  (change)="onCheckedAddresses(addresse)"
                  [disabled]="!authorizedToEdit"
                >
                  {{ addresse.display }}
                </mat-checkbox>
              </li>
            </ul>
          </td>
        </tr>
      </table>

      <!-- display each activity -->
      <ng-container *ngFor="let activity of careplan?.activity; let i = index">
        <mat-card class="mat-elevation-z3 activity-card">
          <table class="w-100">
            <tr>
              <!-- activity status & title -->
              <td>
                <mat-checkbox
                  [checked]="activity.detail.status === 'active'"
                  (change)="onCheckedActivity(activity)"
                  [disabled]="!authorizedToEdit"
                >
                  <ng-container *ngIf="activity.reference">
                    <span class="font-14">{{ activity.reference.display }}</span>
                    <span
                      *ngIf="
                        activity.detail.description &&
                        activity.detail.description !== activity.reference.display &&
                        activity.detail.description !== protocols[activity.reference.reference]
                      "
                      text
                      color="darklight"
                    >
                      &nbsp;({{ activity.detail.description }})
                    </span>
                  </ng-container>
                  <ng-container *ngIf="!activity.reference && activity.detail.code">
                    <span class="font-14">{{ activity.detail.code.text }}</span>
                    <span *ngIf="activity.detail.description && activity.detail.description !== activity.detail.code.text"
                      >{{ activity.detail.description }}
                    </span>
                  </ng-container>
                </mat-checkbox>
              </td>
              <td *ngIf="activity.detail.status === 'active'">
                <!-- product reference / protocol -->
                <div
                  *ngIf="
                    activity.reference &&
                    protocols[activity.reference.reference] &&
                    protocols[activity.reference.reference].length &&
                    activity.detail?.productReference
                  "
                >
                  <mat-form-field class="w-100 font-12">
                    <mat-label>{{ "page.careplan.protocol" | translate }}</mat-label>
                    <mat-select
                      class="w-100"
                      [(ngModel)]="activity.detail.productReference"
                      (selectionChange)="onProtocolChanged(activity)"
                      name="{{ 'productRef' + activity.reference.reference + '' + i }}"
                      [disabled]="!authorizedToEdit"
                      [compareWith]="compareDrugDisplay"
                    >
                      <mat-option *ngFor="let protocol of protocols[activity.reference.reference]" [value]="protocol">
                        {{ protocol.display }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
            </tr>
          </table>
          <div *ngIf="activity.detail.status === 'active' && (activity | isActivityADrug)">
            <app-timing-editor
              [mode]="isNewActivityDrug(activity)"
              [authorizedToEdit]="authorizedToEdit"
              [isFrequencyDisplayed]="activity.detail?.productReference?.reference ? true : false"
              [(frequency)]="activity.detail.scheduledTiming.repeat"
              [isMomentRequired]="false"
              [largeDisplay]="true"
              [hasCycle]="drugCycles[activity.reference.reference] ? true : false"
              [hasStepwise]="drugStepwises[activity.reference.reference] ? true : false"
              [careplanStartDate]="createNewCareplan ? careplan.period.start : undefined"
              [careplanEndDate]="createNewCareplan ? careplan.period.end : undefined"
            >
            </app-timing-editor>
            <app-drug-cycle
              *ngIf="
                activity.detail?.productReference?.reference &&
                (createNewCareplan || activity.detail?.cycle?.cycle?.length > 0 || activity.detail?.stepwise?.stepwises?.length > 0)
              "
              [mode]="isNewActivityDrug(activity)"
              [(drugCycle)]="drugCycles[activity.reference.reference]"
              [(stepwiseDrug)]="drugStepwises[activity.reference.reference]"
              (drugCycleChange)="cycleChanged($event)"
              (stepwiseDrugChange)="stepwiseDrugChanged($event)"
            >
            </app-drug-cycle>
            <app-drug-comment
              *ngIf="activity.detail?.productReference?.reference"
              [mode]="isNewActivityDrug(activity)"
              [(drugComment)]="activity.detail.comment"
            >
            </app-drug-comment>
          </div>
          <div *ngIf="activity.detail.status === 'active'">
            <table class="w-100" *ngIf="!(activity | isActivityADrug)">
              <tr>
                <td>
                  <!-- activity start date -->
                  <mat-label class="font-12" item-left>
                    {{
                      activity.detail.scheduledTiming && activity.detail.scheduledTiming !== undefined
                        ? ("schedule.startDate" | translate)
                        : ("table.date" | translate)
                    }}
                    :
                  </mat-label>
                </td>
                <td>
                  <mat-form-field class="w-100 font-12" *ngIf="activity.detail.scheduledTiming !== undefined">
                    <input
                      matInput
                      (change)="onStartDateChanged()"
                      required
                      [(ngModel)]="activity.detail.scheduledTiming.repeat.boundsPeriod.start"
                      [matDatepicker]="boundsPeriodStartPicker"
                      name="{{ activity.reference.reference }}_boundsPeriodStart"
                      [disabled]="!authorizedToEdit"
                      [min]="careplan.period.start"
                      [max]="
                        activity.detail.scheduledTiming.repeat.endless
                          ? noEndDate || !careplan.period.end
                            ? null
                            : careplan.period.end
                          : activity.detail.scheduledTiming.repeat.boundsPeriod.end
                      "
                      (click)="boundsPeriodStartPicker.open()"
                    />
                    <mat-datepicker-toggle matSuffix [for]="boundsPeriodStartPicker"> </mat-datepicker-toggle>
                    <mat-datepicker #boundsPeriodStartPicker></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field class="w-100 font-12" *ngIf="activity.detail.scheduledPeriod !== undefined">
                    <input
                      matInput
                      (change)="onStartDateChanged()"
                      required
                      [(ngModel)]="activity.detail.scheduledPeriod.start"
                      [matDatepicker]="scheduledPeriodStartPicker"
                      name="{{ activity.reference.reference }}_scheduledPeriodStart"
                      [disabled]="!authorizedToEdit"
                      [min]="careplan.period.start"
                      [max]="!noEndDate && careplan.period.end ? careplan.period.end : activity.detail.scheduledPeriod.end"
                      (click)="scheduledPeriodStartPicker.open()"
                    />
                    <mat-datepicker-toggle matSuffix [for]="scheduledPeriodStartPicker"> </mat-datepicker-toggle>
                    <mat-datepicker #scheduledPeriodStartPicker></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field class="w-100 font-12" *ngIf="activity?.detail?.scheduledString !== undefined && activity?.detail?.isDate">
                    <input
                      matInput
                      (change)="onStartDateChanged()"
                      required
                      [(ngModel)]="activity.detail.scheduledString"
                      [matDatepicker]="scheduledStringPicker"
                      name="{{ activity.reference.reference }}_scheduledString"
                      [disabled]="!authorizedToEdit"
                      [min]="careplan.period.start"
                      [max]="noEndDate ? null : careplan.period.end"
                      (click)="scheduledStringPicker.open()"
                    />
                    <mat-datepicker-toggle matSuffix [for]="scheduledStringPicker"> </mat-datepicker-toggle>
                    <mat-datepicker #scheduledStringPicker></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field
                    class="w-100 font-12"
                    *ngIf="activity?.detail?.scheduledString !== undefined && !activity?.detail?.isDate"
                  >
                    <input
                      matInput
                      [ngxMatDatetimePicker]="scheduledStringPicker"
                      required
                      (change)="onStartDateChanged()"
                      [disabled]="!authorizedToEdit"
                      [(ngModel)]="activity.detail.scheduledString"
                      name="{{ activity.reference.reference }}_scheduledString"
                      [min]="careplan.period.start"
                      [max]="noEndDate ? null : careplan.period.end"
                      (click)="scheduledStringPicker.open()"
                    />
                    <mat-datepicker-toggle matSuffix [for]="scheduledStringPicker"> </mat-datepicker-toggle>
                    <ngx-mat-datetime-picker
                      #scheduledStringPicker
                      [showSpinners]="true"
                      [showSeconds]="false"
                      [stepHour]="1"
                      [stepMinute]="1"
                      color="primary"
                    >
                    </ngx-mat-datetime-picker>
                  </mat-form-field>
                </td>
                <td>
                  <!-- activity end date -->
                  <mat-slide-toggle
                    class="font-12"
                    *ngIf="activity.detail.scheduledTiming !== undefined"
                    [(ngModel)]="activity.detail.scheduledTiming.repeat.endless"
                    (change)="onEndlessActivity(activity)"
                    name="{{ activity.reference.reference }}_repeatEndless"
                    required
                    [disabled]="!authorizedToEdit"
                  >
                    {{ (activity.detail.scheduledTiming.repeat.endless ? "schedule.noEndDate" : "schedule.dateTo") | translate }}
                  </mat-slide-toggle>
                  <mat-label class="font-12" item-left *ngIf="activity.detail.scheduledPeriod !== undefined">
                    {{ "schedule.dateTo" | translate }} :
                  </mat-label>
                </td>
                <td>
                  <mat-form-field
                    class="w-100 font-12"
                    *ngIf="activity.detail.scheduledTiming !== undefined && !activity.detail.scheduledTiming.repeat.endless"
                  >
                    <input
                      matInput
                      (change)="onEndDateChanged()"
                      required
                      [(ngModel)]="activity.detail.scheduledTiming.repeat.boundsPeriod.end"
                      [matDatepicker]="repeatBoundsPeriodEndPicker"
                      name="{{ activity.reference.reference }}_repeatBoundsEnd"
                      [disabled]="!authorizedToEdit"
                      [min]="careplan.period.start ? careplan.period.start : activity.detail.scheduledTiming.repeat.boundsPeriod.start"
                      [max]="noEndDate ? null : careplan.period.end"
                      (click)="repeatBoundsPeriodEndPicker.open()"
                    />
                    <mat-datepicker-toggle matSuffix [for]="repeatBoundsPeriodEndPicker"> </mat-datepicker-toggle>
                    <mat-datepicker #repeatBoundsPeriodEndPicker></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field class="w-100 font-12" *ngIf="activity.detail.scheduledPeriod !== undefined">
                    <input
                      matInput
                      (change)="onEndDateChanged()"
                      required
                      [(ngModel)]="activity.detail.scheduledPeriod.end"
                      [matDatepicker]="scheduledPeriodEndPicker"
                      name="{{ activity.reference.reference }}_scheduledEnd"
                      [disabled]="!authorizedToEdit"
                      [min]="careplan.period.start ? careplan.period.start : activity.detail.scheduledPeriod.start"
                      [max]="noEndDate ? null : careplan.period.end"
                      (click)="scheduledPeriodEndPicker.open()"
                    />
                    <mat-datepicker-toggle matSuffix [for]="scheduledPeriodEndPicker"> </mat-datepicker-toggle>
                    <mat-datepicker #scheduledPeriodEndPicker></mat-datepicker>
                  </mat-form-field>
                </td>
              </tr>
            </table>
            <table class="w-100" *ngIf="!(activity | isActivityADrug)">
              <tr *ngIf="activity.detail.scheduledString !== undefined">
                <td *ngIf="activity.detail?.location?.address">
                  <!-- activity location -->
                  <mat-form-field class="w-100 font-12">
                    <mat-label>{{ "common.location" | translate }}</mat-label>
                    <input
                      matInput
                      class="dateIonInput"
                      type="text"
                      name="{{ activity.reference.reference }}_address"
                      [(ngModel)]="activity.detail.location.address.text"
                      [disabled]="!authorizedToEdit"
                    />
                  </mat-form-field>
                </td>
                <td *ngIf="activity.detail?.performer">
                  <!-- activity performer -->
                  <mat-form-field class="w-100 font-12" *ngIf="activity.detail.performer.length">
                    <mat-label>{{ "common.contact" | translate }}</mat-label>
                    <input
                      matInput
                      class="dateIonInput"
                      type="text"
                      name="{{ activity.reference.reference }}_performer"
                      [(ngModel)]="activity.detail.performer[0].display"
                      [disabled]="!authorizedToEdit"
                    />
                  </mat-form-field>
                </td>
              </tr>
              <tr *ngIf="activity.detail.scheduledString !== undefined && activity.detail?.location?.telecom">
                <td>
                  <!-- activity phone -->
                  <mat-form-field class="w-100 font-12">
                    <mat-label>{{ "model.patient.phone" | translate }}</mat-label>
                    <input
                      matInput
                      class="dateIonInput"
                      type="text"
                      name="{{ activity.reference.reference }}_telecom"
                      [(ngModel)]="activity.detail.location.telecom.value"
                      [disabled]="!authorizedToEdit"
                    />
                  </mat-form-field>
                </td>
                <td></td>
              </tr>
            </table>
            <table class="w-100" *ngIf="!(activity | isActivityADrug)">
              <!-- timing -->
              <tr *ngIf="activity.detail.scheduledTiming !== undefined">
                <!-- activity frequency -->
                <td>
                  <mat-form-field class="w-100 font-12">
                    <mat-label> {{ "drugagenda.drugfrequency" | translate }}</mat-label>
                    <!--  frequency -->
                    <mat-select
                      interface="actsheet"
                      name="{{ activity.reference.reference }}_repeatFreq"
                      [(ngModel)]="activity.detail.scheduledTiming.repeat.frequency"
                      [disabled]="!authorizedToEdit"
                    >
                      <mat-option *ngFor="let time of [1, 2, 3, 4, 5]" [value]="time">{{ time }}X </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="w-100 font-12">
                    <mat-label>
                      {{
                        (activity.detail.scheduledTiming.repeat.periodUnits === "wk" ||
                        activity.detail.scheduledTiming.repeat.periodUnits === "h"
                          ? "schedule.every2"
                          : "schedule.every"
                        ) | translate
                      }}
                    </mat-label>
                    <!-- activity period -->
                    <mat-select
                      [(ngModel)]="activity.detail.scheduledTiming.repeat.period"
                      name="{{ activity.reference.reference }}_repeatPeriod"
                      (selectionChange)="detectChanges()"
                      [disabled]="!authorizedToEdit"
                    >
                      <mat-option *ngFor="let time of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]" [value]="time"> {{ time }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td>
                  <!-- activity period unit -->
                  <mat-form-field class="w-100 font-12">
                    <mat-select
                      class="w-100"
                      name="{{ activity.reference.reference }}_repeatPeriodUnits"
                      [(ngModel)]="activity.detail.scheduledTiming.repeat.periodUnits"
                      [disabled]="!authorizedToEdit"
                      [compareWith]="comparePeriodUnitsFn"
                    >
                      <mat-option value="h">
                        {{
                          (activity.detail.scheduledTiming.repeat.period > 1 ? "periodUnit.hour.plural" : "periodUnit.hour.long")
                            | translate
                        }}
                      </mat-option>
                      <mat-option value="d">
                        {{
                          (activity.detail.scheduledTiming.repeat.period > 1 ? "periodUnit.day.plural" : "periodUnit.day.long") | translate
                        }}
                      </mat-option>
                      <mat-option value="w">
                        {{
                          (activity.detail.scheduledTiming.repeat.period > 1 ? "periodUnit.week.plural" : "periodUnit.week.long")
                            | translate
                        }}
                      </mat-option>
                      <mat-option value="M">
                        {{
                          (activity.detail.scheduledTiming.repeat.period > 1 ? "periodUnit.month.plural" : "periodUnit.month.long")
                            | translate
                        }}
                      </mat-option>
                      <mat-option value="y">
                        {{
                          (activity.detail.scheduledTiming.repeat.period > 1 ? "periodUnit.year.plural" : "periodUnit.year.long")
                            | translate
                        }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>
            </table>
            <table
              class="w-100 pb-15"
              *ngIf="activity.detail.scheduledTiming && activity.detail.scheduledTiming.repeat.quantities && !(activity | isActivityADrug)"
            >
              <tr>
                <td *ngFor="let timing of timingCodes">
                  <mat-checkbox
                    [checked]="activity.detail.scheduledTiming.repeat.quantities[timing] !== null"
                    (change)="onCheckedTiming($event, activity, timing)"
                    [disabled]="!authorizedToEdit"
                  >
                    {{ "careplan.timingCodes." + timing | translate }}
                  </mat-checkbox>
                  <br />
                  <mat-form-field class="font-12 w-50" *ngIf="isTimingChecked(activity.detail.scheduledTiming.repeat.timingCode, timing)">
                    <mat-label>{{ "careplan.quantities" | translate }}</mat-label>
                    <mat-select
                      (selectionChange)="detectChanges()"
                      [disabled]="!authorizedToEdit"
                      [(value)]="activity.detail.scheduledTiming.repeat.quantities[timing]"
                    >
                      <mat-option *ngFor="let quantity of quantities" [value]="quantity">
                        {{ quantity }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>
            </table>
            <ng-container
              *ngIf="activity.actionResulting && activity.actionResulting.length > 0 && displayActionResulting(activity.actionResulting)"
            >
              <hr />
              <mat-label padding class="fontLarger" color="secondary">Activités pré/post acte </mat-label>
              <table class="w-100 actionResultingTable">
                <!-- sub-activities -->
                <tr *ngFor="let action of activity.actionResulting; let iAction = index">
                  <td *ngIf="!action.notDisplay" style="vertical-align: middle; width: 10%; text-align: center">
                    <p>{{ "knowledgebase.knowledgeCriteria.show" | translate }}</p>
                  </td>
                  <!-- timing period -->
                  <td *ngIf="!action.notDisplay">
                    <mat-form-field class="w-100 font-12">
                      <mat-icon
                        matPrefix
                        (click)="action.status = action.status === 'active' ? 'inactive' : 'active'"
                        class="fa"
                        [ngClass]="{
                          ' fa-calendar-check': action.status === 'active',
                          ' fa-calendar': action.status === 'inactive'
                        }"
                      >
                      </mat-icon>
                      <input
                        matInput
                        type="number"
                        pattern="[0-9-]*"
                        min="-366"
                        max="366"
                        [(ngModel)]="action.when.period"
                        (change)="periodChange(i, iAction)"
                        name="{{ activity.reference.reference }}_whenPeriod_{{ i }}_{{ iAction }}"
                        [disabled]="!authorizedToEdit"
                      />
                    </mat-form-field>
                  </td>
                  <td *ngIf="!action.notDisplay">
                    <mat-form-field class="w-100 font-12">
                      <mat-select [(value)]="action.when.periodUnits" [disabled]="!authorizedToEdit" [compareWith]="comparePeriodUnitsFn">
                        <mat-option *ngFor="let unit of availablePeriodUnits" [value]="unit.value">
                          {{ unit.key }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td *ngIf="!action.notDisplay" style="vertical-align: middle; width: 10%">
                    <p>{{ "careplan.after" | translate }}</p>
                  </td>
                  <td *ngIf="!action.notDisplay">
                    <!-- detail target -->
                    <mat-form-field class="w-100 font-12">
                      <mat-select [value]="action.detail.target" disabled>
                        <mat-option [value]="1">{{ "observation.observation" | translate }} </mat-option>
                        <mat-option [value]="2">{{ "page.careplan.questionnaire" | translate }} </mat-option>
                        <mat-option [value]="3">{{ "knowledgebase.doccategory.2" | translate }} </mat-option>
                        <mat-option [value]="5">{{ "tile.communications" | translate }} </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td *ngIf="!action.notDisplay && action.detail.target !== 5">
                    <mat-form-field class="w-100 font-12">
                      <mat-select [value]="action.detail.reference.reference" disabled>
                        <mat-option [value]="action.detail.reference.reference">{{ action.detail.reference.display }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td
                    *ngIf="!action.notDisplay && action.detail.target === 5"
                    class="showCommunicationLink"
                    (click)="showCommunication(action)"
                  >
                    {{ "careplan.showCommunication" | translate }}
                  </td>
                </tr>
              </table>
            </ng-container>
            <mat-form-field class="w-100" *ngIf="activity?.progress?.length">
              <mat-label>{{ "mydrugs.comment" | translate }}</mat-label>
              <textarea
                matInput
                name="comment_{{ activity.progress[0].text }}"
                [(ngModel)]="activity.progress[0].text"
                [disabled]="!authorizedToEdit"
              ></textarea>
            </mat-form-field>
          </div>
        </mat-card>
      </ng-container>
    </ng-container>
    <ui-alert *ngIf="noCp" [options]="{ type: 'error', showIcon: true }">
      {{ "careplan.noCp" | translate }}
    </ui-alert>
  </div>
</form>
