import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/guards/auth.guard";
import { RouteData } from "src/app/models/route-data.interface";
import { CareplanEditorGuard } from "../../application/careplan-editor-page/careplan-editor.guard";
import { CareplanEditorPageComponent } from "../../views/careplan-editor-page/careplan-editor-page.component";
import { CareplanEditorCommunicationsTabComponent } from "../../views/careplan-editor-page/tabs/careplan-editor-communications-tab/careplan-editor-communications-tab.component";
import { CareplanEditorGeneralTabComponent } from "../../views/careplan-editor-page/tabs/careplan-editor-general-tab/careplan-editor-general-tab.component";
import { CareplanJSONViewComponent } from "../../views/careplan-editor-page/tabs/careplan-editor-general-tab/careplan-jsonview/careplan-jsonview.component";
import { CareplanPrettyJSONViewComponent } from "../../views/careplan-editor-page/tabs/careplan-editor-general-tab/careplan-pretty-jsonview/careplan-pretty-jsonview.component";
import { CareplanVisualViewComponent } from "../../views/careplan-editor-page/tabs/careplan-editor-general-tab/careplan-visual-view/careplan-visual-view.component";
import { CareplanEditorKnowledgeTabComponent } from "../../views/careplan-editor-page/tabs/careplan-editor-knowledge-tab/careplan-editor-knowledge-tab.component";
import { CareplanEditorObservationsTabComponent } from "../../views/careplan-editor-page/tabs/careplan-editor-observations-tab/careplan-editor-observations-tab.component";
import { CareplanEditorQuestionnairesTabComponent } from "../../views/careplan-editor-page/tabs/careplan-editor-questionnaires-tab/careplan-editor-questionnaires-tab.component";
import { CareplanEditorVitalSignsTabComponent } from "../../views/careplan-editor-page/tabs/careplan-editor-vital-signs-tab/careplan-editor-vital-signs-tab.component";

const careplanEditorPageRoutes: Routes = [
  {
    path: "careplanEditor/:id",
    component: CareplanEditorPageComponent,
    canActivate: [AuthGuard, CareplanEditorGuard],
    children: [
      {
        path: "general",
        component: CareplanEditorGeneralTabComponent,
        children: [
          { path: "visualView", component: CareplanVisualViewComponent, canActivate: [AuthGuard, CareplanEditorGuard] },
          { path: "beautifulJsonView", component: CareplanPrettyJSONViewComponent, canActivate: [AuthGuard, CareplanEditorGuard] },
          { path: "jsonView", component: CareplanJSONViewComponent, canActivate: [AuthGuard, CareplanEditorGuard] },
        ],
      },
      { path: "questionnaires", component: CareplanEditorQuestionnairesTabComponent, canActivate: [AuthGuard, CareplanEditorGuard] },
      { path: "observations", component: CareplanEditorObservationsTabComponent, canActivate: [AuthGuard, CareplanEditorGuard] },
      { path: "vitalSigns", component: CareplanEditorVitalSignsTabComponent, canActivate: [AuthGuard, CareplanEditorGuard] },
      { path: "knowledge", component: CareplanEditorKnowledgeTabComponent, canActivate: [AuthGuard, CareplanEditorGuard] },
      { path: "communications", component: CareplanEditorCommunicationsTabComponent, canActivate: [AuthGuard, CareplanEditorGuard] },
      { path: "", redirectTo: "general", pathMatch: "full" }, // Default route to General
    ],
    data: {
      canBeLanding: false,
    } as RouteData,
  },
];

@NgModule({
  imports: [RouterModule.forChild(careplanEditorPageRoutes)],
  exports: [RouterModule],
})
export class CareplanEditorPageRoutingModule {}
