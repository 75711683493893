import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { StatusEntity } from "src/app/models/entity.interface";
import { Group } from "src/app/models/group.model";
import { Reference } from "src/app/models/sharedModels.model";
import { GroupService } from "src/app/providers/group-api.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";

@Component({
  selector: "app-add-group",
  templateUrl: "./add-group.component.html",
  styleUrls: ["./add-group.component.scss"],
})
export class AddGroupComponent implements OnInit, OnDestroy {
  public isCreation = false;
  public groupForm = this.fb.group({
    name: ["", Validators.required],
    isChecked: [false],
  });
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();
  private currentServiceRef: Reference;

  constructor(
    private fb: UntypedFormBuilder,
    private readonly dialogRef: MatDialogRef<AddGroupComponent>,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private sessionService: SessionService,
    private groupService: GroupService,
    private userService: UserService,
    private healthcareService: HealthcareserviceService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      group: Group;
      mode: FORMS_MODE;
    }
  ) {
    this.isCreation = this.data.mode === FORMS_MODE.CREATE;
    this.groupForm.get("name").setValue(!this.isCreation ? this.data.group.groupName : "");
    this.groupForm.get("isChecked").setValue(!this.isCreation ? !this.data.group.public : false);
  }

  ngOnInit(): void {
    if (this.isCreation) {
      this.setupServices();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setupServices() {
    if (this.userService.isMonitoringUser) {
      const currentServiceRef = this.sessionService.currentMonitoringService;
      const availableServices = this.healthcareService.availableMonitoringServices();
      if (this.isCreation && (!availableServices || availableServices.length < 1)) {
        return this.waitThenCloseWithNothing();
      }
      if (this.isCreation && currentServiceRef.reference === this.sessionService.allsOption) {
        return this.waitThenCloseWithNothing();
      } else {
        this.currentServiceRef = currentServiceRef;
      }
    } else {
      const currentServiceRef = this.sessionService.currentService;
      const availableServices = this.healthcareService.availableServices();
      if (this.isCreation && (!availableServices || availableServices.length < 1)) {
        return this.waitThenCloseWithNothing();
      }
      if (this.isCreation && currentServiceRef.reference === this.sessionService.allsOption) {
        return this.waitThenCloseWithNothing();
      } else {
        this.currentServiceRef = currentServiceRef;
      }
    }
  }

  private waitThenCloseWithNothing() {
    setTimeout(() => {
      this.dialogRef.close();
    }, 100);
  }

  private close() {
    this.dialogRef.close(this.data);
  }

  public onSave(): void {
    try {
      if (this.isCreation) {
        this.createGroup();
      } else {
        this.updateGroup();
      }
    } catch (err) {
      FileLogger.error("AddGroupComponent", "onSave() failed", err);
    }
  }

  private createGroup() {
    if (!this.groupForm.valid) {
      return;
    }
    const success = this.translateService.instant("common.success");
    const name = this.groupForm.get("name").value;
    const newGroup: Group = new Group({
      _id: null,
      modified: null,
      entityStatus: [StatusEntity.ACTIVE],
      groupName: name,
      caremateIdentifier: this.sessionService.account.caremateIdentifier,
      patients: [],
      public: !this.groupForm.get("isChecked").value,
      healthcareservice: this.currentServiceRef,
    });
    if (name) {
      this.groupService
        .create(newGroup)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.close();
          this.snackBar.open(success, "ok", { duration: 3000 });
        });
    }
  }

  private updateGroup() {
    if (!this.groupForm.valid) {
      return;
    }
    const success = this.translateService.instant("common.success");
    const name = this.groupForm.get("name").value;
    if (name) {
      this.data.group.groupName = name;
      this.data.group.public = !this.groupForm.get("isChecked").value;
      this.groupService
        .update(this.data.group)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.close();
          this.snackBar.open(success, "ok", { duration: 3000 });
        });
    }
  }
}
