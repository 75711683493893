<div class="card-container">
  <mat-card *ngFor="let obsAndDef of newObservations; let jindex = index" class="encodeObs minimalPadding mt-10">
    <mat-card-title>
      {{ obsAndDef.definition?.nameTranslation | getTranslation : obsAndDef.definition?.loinc }}
      <button *ngIf="patientReference" mat-icon-button (click)="clearObservation(obsAndDef.observation)" class="clearObsBtn">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-card-title>
    <mat-card-subtitle>{{ obsAndDef.definition?.description | getTranslation : "" }}</mat-card-subtitle>
    <!-- observation value(s) -->
    <ng-container *ngFor="let componentDef of obsAndDef.definition?.components; let index = index">
      <ng-container
        *ngIf="!patientReference || (obsAndDef.observation | isObsCompEnabled : componentDef.enableWhen : (patientCareplans | async))"
      >
        <!-- Number component -->
        <mat-form-field *ngIf="componentDef.type === 'number'" class="font-12 fullSpace">
          <mat-label>
            {{ componentDef.nameTranslation | getTranslation : "" }}
          </mat-label>
          <input matInput [disabled]="disabled" type="number" [(ngModel)]="obsAndDef.observation.component[index].valueQuantity.value" />
        </mat-form-field>
        <!-- Text component -->
        <mat-form-field *ngIf="componentDef.type === 'text'" class="font-12 fullSpace">
          <mat-label>
            {{ componentDef.nameTranslation | getTranslation : "" }}
          </mat-label>
          <input
            matInput
            [disabled]="disabled"
            type="text"
            style="padding-top: 10px !important"
            [(ngModel)]="obsAndDef.observation.component[index].valueQuantity.value"
          />
        </mat-form-field>
        <!-- Range component -->
        <div *ngIf="componentDef.type === 'range' && !componentDef.step" class="radioGroup">
          <mat-label>{{ componentDef.nameTranslation | getTranslation : "" }} : </mat-label>
          <mat-radio-group [(ngModel)]="obsAndDef.observation.component[index].valueQuantity.value">
            <mat-radio-button
              *ngFor="let i of componentDef.min | getRange : componentDef.max; let iAnswer = index"
              [disabled]="disabled"
              [value]="i"
              class="spacedRadio"
              >{{ i | getLabelRangeFromValue : componentDef }}</mat-radio-button
            >
          </mat-radio-group>
        </div>
        <!-- Boolean component -->
        <div *ngIf="componentDef.type === 'bool'" class="font-12 fullSpace">
          <mat-label> {{ componentDef.nameTranslation | getTranslation : "" }}: </mat-label>
          <mat-radio-group [(ngModel)]="obsAndDef.observation.component[index].valueQuantity.value">
            <mat-radio-button [disabled]="disabled" value="1" class="spacedRadio">{{ "application.yes" | translate }}</mat-radio-button>
            <mat-radio-button [disabled]="disabled" value="0" class="spacedRadio">{{ "application.no" | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <!-- Range Slider component -->
        <div *ngIf="componentDef.type === 'range' && componentDef.step">
          <mat-label>
            {{ componentDef.nameTranslation | getTranslation : "" }}
          </mat-label>
          <img
            *ngIf="componentDef.showMethod !== 0"
            class="centerSmiley"
            [src]="
              'assets/images/smileys/' +
              (obsAndDef.observation.component[index].valueQuantity.value
                | rangeSmiley : componentDef.min : componentDef.max : componentDef.showMethod)
            "
          />
          <div class="centerScale">
            <mat-label class="ion-text-center" *ngIf="componentDef.meaning">
              <p>
                {{ obsAndDef.observation.component[index].valueQuantity.value | getLabelRangeFromValue : componentDef }}
              </p>
            </mat-label>
            <mat-slider
              [min]="componentDef.min"
              [max]="componentDef.max"
              [step]="componentDef.step"
              [disabled]="disabled"
              [(ngModel)]="obsAndDef.observation.component[index].valueQuantity.value"
              tickInterval="{{ componentDef.step }}"
              style="width: 100%"
            >
              <mat-label slot="start">{{ componentDef.min }}</mat-label>
              <mat-label slot="end">{{ componentDef.max }}</mat-label>
            </mat-slider>
          </div>
        </div>
        <!-- Observation picture -->
        <div *ngIf="componentDef.askPicture">
          <div class="observationPictureContainer">
            <div
              *ngFor="
                let picture of (componentDef.loinc | getObsComponent : obsAndDef.observation.component).valuePictures;
                let iPicture = index
              "
              class="pictureContainer"
            >
              <img class="picture" [src]="picture" ngxViewerjs [viewerOptions]="viewerOptions" />
              <button mat-icon-button matTooltip="{{ 'btn.delete' | translate }}" (click)="deletePhoto(jindex, index, iPicture)">
                <mat-icon color="warn">delete</mat-icon>
              </button>
            </div>
            <button
              mat-icon-button
              matTooltip="{{ 'btn.import' | translate }}"
              (click)="selectPhoto()"
              *ngIf="
                !(componentDef.loinc | getObsComponent : obsAndDef.observation.component).valuePictures ||
                (componentDef.loinc | getObsComponent : obsAndDef.observation.component).valuePictures.length < 4
              "
            >
              <mat-icon color="primary">add_a_photo</mat-icon>
              <input
                type="file"
                style="display: none"
                accept="image/jpg,image/jpeg,image/png"
                size="5000000"
                (change)="addPhoto($event, jindex, index)"
                #input
              />
            </button>
          </div>
          <div
            *ngIf="
              (componentDef.loinc | getObsComponent : obsAndDef.observation.component).valuePictures &&
              (componentDef.loinc | getObsComponent : obsAndDef.observation.component).valuePictures.length === 4
            "
            style="color: grey; font-style: italic; font-size: 12px"
          >
            {{ "observation.maxPhoto" | translate }}
          </div>
        </div>
      </ng-container>
    </ng-container>
  </mat-card>
</div>
