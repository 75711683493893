import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class UserIdsValidators {
  /*
  https://www.inami.fgov.be/fr/professionnels/autres-professionnels/fournisseurs-de-logiciels#pour-vos-developpements-lies-aux-numeros-inami
  
  Qu’est-ce qu’un check-digit ?

    Le numéro INAMI est une suite de 11 chiffres attribué par l’INAMI qui permet
    entre autres d’identifier les dispensateurs de soins dont les prestations peuvent 
    être remboursées par l’assurance soins de santé.
    Les 6 premiers chiffres constituent une suite unique qui distingue chaque dispensateur. 
    Les 7e et 8e chiffres sont des chiffres de contrôle, le check-digit (ou « CD » en abrégé).
  
  Comment vérifier la validité du check-digit ? 

    La formule pour vérifier le check-digit est :

      C = M – (N mod M)
16510487001
      Dans cette formule :
      M = le nombre premier utilisé pour le calcul du modulo : 97, 89, 83 ou 79
      N = les 6 premiers chiffres du numéro INAMI : 000000 … 999999
      N mod M = le reste de la division entière de N par M
      C = le check-digit de ce numéro INAMI : 01..M (le check-digit est au minimum 01 et au maximum égal au modulo
  */
  public static inamiValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const inami: string = control.value;
    if (!inami) {
      return null;
    }
    if (inami.length === 11) {
      if (isNaN(Number(inami))) {
        return { invalidBelgianSSIN: true };
      }
      const N = +inami.slice(0, 6);
      const C = +inami.slice(6, 8);
      const modulo = [97, 89, 83, 79];
      for (const M of modulo) {
        if (C === M - (N % M)) {
          return null;
        }
      }
    }
    return { invalidInami: true };
  };

  // See: https://fr.wikipedia.org/wiki/Num%C3%A9ro_de_registre_national
  public static belgianSSINValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const ssin: string = control.value;
    if (!ssin) {
      return null;
    }
    if (ssin.length === 11) {
      if (isNaN(Number(ssin))) {
        return { invalidBelgianSSIN: true };
      }
      const N = +ssin.slice(0, 9);
      const N2 = +("2" + ssin.slice(0, 9)); // For people born after 01/01/2000
      const C = +ssin.slice(9, 11);
      const M = 97;
      // For people born before 01/01/2000
      if (C === M - (N % M)) {
        return null;
      }
      // For people born after 01/01/2000
      if (C === M - (N2 % M)) {
        return null;
      }
    }
    return { invalidBelgianSSIN: true };
  };
}
