import { Tools } from "../helpers/tools";

export interface IDrugInfo {
  code?: string; // identifies a samv2 document (identical between different packaging)
  vmpCode?: string; // identifies a samv2 document (identical between different packaging)
  ctiExtended?: string; // depends on packaging
  cnk: string; // depends on packaging
  officialName: string; // identical for all packaging
  fr?: IDrugInfoByLanguage; // depends on packaging
  nl?: IDrugInfoByLanguage; // depends on packaging
  en?: IDrugInfoByLanguage; // depends on packaging
  de?: IDrugInfoByLanguage; // depends on packaging
  atc?: IAtcDrugInfo; // depends on packaging
  country: string; // 'BE', 'FR', 'NL', 'DE', 'LU'
  dbName: string; // 'samv2', 'bdpm'
  pack?: IPack; // depends on packaging
  packNumber?: number; // depends on packaging
  allPack?: IPackExtended[];
  pharmaceuticalForm?: IDetailsPharmaceuticalForm[]; // identical for all packaging
  knownPackaging: boolean;
}
export interface IAtcDrugInfo {
  code?: string;
  description?: string;
}

export interface IPack {
  original?: string;
  usable?: number;
}
export interface IPackExtended extends IPack {
  atc?: IAtcDrugInfo;
  ctiExtended?: string;
  fullName?: {
    fr?: string;
    nl?: string;
    en?: string;
    de?: string;
  };
}
export interface IDrugInfoByLanguage {
  fullName?: string; // depends on packaging
  url?: string; // depends on packaging
  prescriptionNameFamhp?: string; // official name prescription (identical for all packaging)
}

export interface IDetailsPharmaceuticalForm {
  code: string;
  name: {
    fr?: string;
    nl?: string;
    de?: string;
    en?: string;
  };
}

export interface IDrugsInfo {
  total: number;
  results: IDrugInfo[];
}

export interface IDbDrugsInfo {
  country?: string;
  dbName: string;
  modelName: string;
}

export class DrugInfo {
  public static createDrugInfo(
    name: string,
    dbName?: string,
    country?: string,
    atcCode?: string,
    lastPackNumber?: number,
    vmpCode?: string,
    allPack?: IPackExtended[],
    knownPackaging?: boolean,
    prescriptionName?: string
  ): IDrugInfo {
    return {
      code: undefined,
      ctiExtended: undefined,
      cnk: undefined,
      officialName: name,
      fr: {
        fullName: name,
        prescriptionNameFamhp: prescriptionName,
      },
      en: {
        fullName: name,
        prescriptionNameFamhp: prescriptionName,
      },
      nl: {
        fullName: name,
        prescriptionNameFamhp: prescriptionName,
      },
      de: {
        fullName: name,
        prescriptionNameFamhp: prescriptionName,
      },
      vmpCode: vmpCode,
      atc: {
        code: atcCode,
        description: undefined,
      },
      country,
      dbName,
      packNumber: lastPackNumber ?? -1,
      knownPackaging: Tools.isDefined(knownPackaging) ? knownPackaging : false,
      allPack: allPack ?? [],
    };
  }
}
