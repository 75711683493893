import { Injectable } from "@angular/core";
import { FileLogger } from "../helpers/fileLogger";
import { Tools } from "../helpers/tools";
import { AppError } from "../models/app-error.interface";
import { AnonymisedDataApiService } from "./api/anonymised-data-api.service";

@Injectable({
  providedIn: "root",
})
export class AnonymisedDataService {
  constructor(private anonymisedDataApiService: AnonymisedDataApiService) {}

  public async export(
    formatExport: "CSV" | "JSON",
    supportCareplans: string,
    exportObs?: string,
    exportQuests?: string,
    emailsToExclude?: string,
    servicesToExclude?: string,
    exportPatients?: boolean,
    exportDrugs?: boolean,
    exportAlert?: boolean,
    anonymisedDate?: boolean,
    careplansWithoutApp?: string
  ): Promise<void> {
    this.anonymisedDataApiService
      .export(
        formatExport,
        supportCareplans,
        exportObs,
        exportQuests,
        emailsToExclude,
        servicesToExclude,
        exportPatients,
        exportDrugs,
        exportAlert,
        anonymisedDate,
        careplansWithoutApp
      )
      .subscribe(
        (blob) => {
          const url = URL.createObjectURL(blob);
          Tools.downloadBlob(blob, url.split("/")[url.split("/").length - 1]);
        },
        (error: AppError) => {
          FileLogger.error("AnonymisedDataService", "export", error);
          throw new Error(error.message);
        }
      );
  }
}
