import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, Inject, OnDestroy, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStepper } from "@angular/material/stepper";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { DEFAULT_DURATION, DEFAULT_HOUR, FORMS_MODE, FormsData } from "src/app/helpers/formsData";
import { Tools } from "src/app/helpers/tools";
import { IAppointment } from "src/app/models/appointment.interface";
import { Appointment } from "src/app/models/appointment.model";
import { Coding } from "src/app/models/coding.interface";
import { Healthcareservice } from "src/app/models/healthcareservice.model";
import { Identifier } from "src/app/models/identifier.interface";
import { Participant } from "src/app/models/participant.interface";
import { IPatientAppointement, PATIENT_TYPE } from "src/app/models/patient-appointement.interface";
import { PatientUser } from "src/app/models/patient.interface";
import { Reference } from "src/app/models/reference.interface";
import { SMS_TYPE, UnknowPatientData } from "src/app/models/UnknownPatientData.interface";
import { AppointmentService } from "src/app/providers/appointment.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PatientService } from "src/app/providers/patient.service";
import { PractitionerService } from "src/app/providers/practitioner.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import uuid from "uuid-random";

@Component({
  selector: "app-patient-teleconsultations-dialog",
  templateUrl: "./patient-teleconsultations-dialog.component.html",
  styleUrls: ["./patient-teleconsultations-dialog.component.scss"],
})
export class PatientTeleconsultationDialogComponent implements OnDestroy {
  @ViewChild("stepper") private myStepper: MatStepper;

  public isCreation = true;
  public isLoading = true;
  public appointment: IAppointment;
  public patientUser: PatientUser;
  public availableLangs: Coding[];
  public practitionerRefs: Reference[];
  public isUnknowPatient = false;
  public fromPatientWidget = false;
  public minDate = moment();
  public formLoading = false;
  public patientForm = this.fb.group({
    name: [undefined, [Validators.required]],
    firstname: [undefined, [Validators.required]],
    phone: [undefined, [Validators.required, this.phoneValidator]],
    mail: [undefined, [Validators.required, Validators.email]],
    internalId: [undefined, [Validators.minLength(5)]],
    userLang: [undefined, [Validators.required]],
  });
  public isPage1 = true;
  public isPage2 = false;
  public isSmallScreen = false;
  public isSameService = true;

  public appointmentForm = this.fb.group({
    start: [moment(DEFAULT_HOUR()), [Validators.required]],
    duration: [DEFAULT_DURATION, [Validators.required]],
    participant: [undefined, [Validators.required]],
    billingCode: [undefined, []],
    object: [undefined, [Validators.required]],
  });
  private currentServiceRef: Reference;
  private currentService: Healthcareservice;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();
  public appointmentHealthCareService: Participant;

  constructor(
    private fb: UntypedFormBuilder,
    public appointmentService: AppointmentService,
    public patientService: PatientService,
    public practitionerService: PractitionerService,
    public sanitizer: DomSanitizer,
    public formsData: FormsData,
    private sessionService: SessionService,
    private healthcareService: HealthcareserviceService,
    private dialogRef: MatDialogRef<PatientTeleconsultationDialogComponent>,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private bo: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      appointment: Appointment;
      patientUser: PatientUser;
      mode: FORMS_MODE;
      fromPatientWidget: boolean;
    }
  ) {
    this.appointment = this.data.appointment;
    this.appointmentHealthCareService = this.data.appointment?.participant?.find((p) => p.actor.type === Appointment.HEALTHCARESERVICE);
    this.patientUser = this.data.patientUser;
    this.fromPatientWidget = this.data.fromPatientWidget === undefined ? false : this.data.fromPatientWidget;
    this.isCreation = this.data.mode === FORMS_MODE.CREATE;
    this.isUnknowPatient = this.patientUser ? false : true;
    this.currentServiceRef = this.userService.isMonitoringUser
      ? this.sessionService.currentMonitoringService
      : this.sessionService.currentService;
    this.currentService = this.userService.isMonitoringUser
      ? this.healthcareService.availableMonitoringServices().find((s) => s.serviceRef === this.currentServiceRef.reference)
      : this.healthcareService.availableServices().find((s) => s.serviceRef === this.currentServiceRef.reference);
    if (!this.isUnknowPatient) {
      this.patientForm.get("userLang").disabled;
    }
    if (!this.isCreation) {
      this.isSameService = this.currentServiceRef.reference === this.appointmentHealthCareService?.actor?.reference;
    }
    this.initData();
    this.bo
      .observe("(max-height: 700px)")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => (this.isSmallScreen = value.matches));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public patientIsSelected(patient: IPatientAppointement): void {
    this.isLoading = true;
    if (patient.type === PATIENT_TYPE.PATIENT) {
      this.patientService
        .getPatientUser(patient.caremateIdentifier)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((p) => {
          this.patientUser = p;
          this.data.patientUser = p;
          this.isUnknowPatient = false;
          this.initData();
          // set timeout is here to smooth the transition
          setTimeout(() => {
            this.myStepper.next();
            this.isPage1 = false;
            this.isPage2 = true;
          }, 750);
        });
    } else {
      this.patientForm.get("name").setValue(patient.name);
      this.patientForm.get("firstname").setValue(patient.firstname);
      this.patientForm.get("phone").setValue(patient.phone);
      this.patientForm.get("mail").setValue(patient.email);
      this.patientForm.get("internalId").setValue(patient.internalId);
      this.patientForm.get("userLang").setValue(this.availableLangs.find((l) => l.code === patient.lang).code);
      this.isLoading = false;
      // set timeout is here to smooth the transition
      setTimeout(() => {
        this.myStepper.next();
        this.isPage1 = false;
        this.isPage2 = true;
      }, 750);
    }
  }

  private initData() {
    this.practitionerService
      .listForService(this.currentServiceRef.reference)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (practitioners) => {
        this.practitionerRefs = [];
        if (!this.isCreation && !this.isSameService) {
          this.practitionerRefs.push(this.data.appointment.participantPractitionerRef);
          this.appointmentForm.get("participant").setValue(this.data.appointment.participantPractitionerRef);
          this.appointmentForm.get("participant").disable();
        } else {
          practitioners?.forEach((practitioner) => {
            const ref = this.practitionerService.getPractitionerReference(practitioner);
            if (ref.reference && ref.display) {
              this.practitionerRefs.push(ref);
            }
          });
        }
        this.availableLangs = await this.formsData.getLanguages();
        this.updateForm();
        this.isLoading = false;
      });
  }

  public phoneValidator(control: AbstractControl): { [key: string]: true } | null {
    return Tools.phoneValidator(control);
  }

  private updateForm() {
    if (!this.isUnknowPatient) {
      const langCoding = this.availableLangs.find((l) => l.code === this.formsData.getPreferredLang(this.data?.patientUser.patient));
      this.patientForm = this.fb.group({
        name: new UntypedFormControl({ value: this.data?.patientUser.user.name, disabled: true }, Validators.required),
        firstname: new UntypedFormControl({ value: this.data?.patientUser.user.firstname, disabled: true }, Validators.required),
        phone: new UntypedFormControl({ value: this.data?.patientUser.user.phone, disabled: true }, Validators.required),
        mail: new UntypedFormControl({ value: this.data?.patientUser.user.mail, disabled: true }, Validators.required),
        internalId: new UntypedFormControl(
          {
            value: this.patientService.getInternalId(this.data?.patientUser.patient),
            disabled: true,
          },
          Validators.minLength(5)
        ),
        userLang: new UntypedFormControl({ value: langCoding }, Validators.required),
      });
    } else if (!this.isCreation) {
      const langCoding = this.availableLangs.find((l) => l.code === this.data?.appointment.unknowPatientData?.patientLang);
      this.patientForm = this.fb.group({
        name: new UntypedFormControl(
          {
            value: this.data?.appointment.unknowPatientData?.name,
            disabled: false,
          },
          Validators.required
        ),
        firstname: new UntypedFormControl(
          {
            value: this.data?.appointment.unknowPatientData?.firstname,
            disabled: false,
          },
          Validators.required
        ),
        phone: new UntypedFormControl(
          {
            value: this.data?.appointment.unknowPatientData?.phoneNbr,
            disabled: false,
          },
          Validators.required
        ),
        mail: new UntypedFormControl(
          {
            value: this.data?.appointment.unknowPatientData?.email,
            disabled: false,
          },
          Validators.required
        ),
        internalId: new UntypedFormControl(
          {
            value: this.data?.appointment.unknowPatientData?.internalId || "",
            disabled: false,
          },
          Validators.minLength(5)
        ),
        userLang: new UntypedFormControl({ value: langCoding, disabled: false }, Validators.required),
      });
    }

    if (this.isCreation) {
      this.appointmentForm.get("participant").setValue({
        reference: this.sessionService.account.caremateIdentifier,
        display: this.sessionService.account.displayName,
      });
    } else {
      const participant = this.data?.appointment.participantPractitionerRef;
      let appointmentDate = moment(this.data?.appointment.start).seconds(0).milliseconds(0);
      let duration = this.diffMinutes(new Date(this.data?.appointment.start), new Date(this.data?.appointment.end));

      if (
        this.data.appointment.appointmentType?.text === Appointment.TYPE_CAREPLANLINK &&
        Tools.isDefined(this.data.appointment.appointmentType.coding[0]?.code)
      ) {
        duration = 15;
      }

      if (appointmentDate.isBefore(moment())) {
        appointmentDate = moment(DEFAULT_HOUR());
      }
      this.appointmentForm = this.fb.group({
        start: [appointmentDate, [Validators.required]],
        duration: [duration, [Validators.required]],
        participant: [participant, [Validators.required]],
        billingCode: [this.data?.appointment.billingCode, []],
        object: [this.data?.appointment.description, [Validators.required]],
      });
    }
  }

  public compareReference(o1: Reference, o2: Reference): boolean {
    return o1?.reference === o2?.reference;
  }

  public compareLang(langCode: string, langInput: string): boolean {
    return langCode === langInput;
  }

  /**
   * find the practionner with ID in parameters
   */
  public getDefaultPractitioner(caremateIdentifier: string): Reference {
    return this.practitionerRefs.find((p) => p.reference === caremateIdentifier);
  }

  /**
   * used to compute duration in minutes between 2 dates
   */
  public diffMinutes(dt2: Date, dt1: Date): number {
    const diff = (dt2.getTime() - dt1.getTime()) / 1000 / 60;
    return Math.abs(Math.round(diff));
  }

  public get isDataValid(): boolean {
    return this.isUnknowPatient ? this.patientForm.valid && this.appointmentForm.valid : this.appointmentForm.valid;
  }

  public onSave(): void {
    this.isLoading = true;
    if (this.patientForm.valid && this.appointmentForm.valid) {
      if (this.isCreation) {
        this.createVisioAppointement(this.currentService)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => {
            this.close();
          });
      } else {
        this.updateVisioAppointement()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => {
            this.close();
          });
      }
    }
  }

  private close() {
    this.sessionService.needRefreshTeleconsultationsDataList();
    this.dialogRef.close(this.data);
    const msg = this.translateService.instant("common.success");
    this.snackBar.open(msg, "ok", { duration: 3000 });
    if (
      this.data?.appointment?.appointmentType?.text === Appointment.TYPE_CAREPLANLINK &&
      Tools.isDefined(this.data.appointment.appointmentType.coding[0]?.code)
    ) {
      this.sessionService.needRefreshCp();
    }
  }

  /**
   * Main method for the creation of a visio Appointement
   */
  public createVisioAppointement(service: Healthcareservice): Observable<Appointment> {
    const id: Identifier[] = [
      {
        system: "http://comunicare.io",
        value: uuid(),
      },
    ];

    const serviceIdentifier = service.identifier?.find((id) => id.system === FHIRHelper.SYSTEM_COMUNICARE);

    const part: Participant[] = [
      {
        required: "required",
        status: "accepted",
        participantType: {
          text: Appointment.PERSON,
          coding: this.coding,
        },
        actor: {
          reference: !this.isUnknowPatient ? this.patientUser.user.caremateIdentifier : this.patientForm.get("mail").value,
          display: this.patientName,
        },
      },
      {
        required: "required",
        status: "accepted",
        participantType: {
          text: Appointment.PRACTITIONER,
          coding: this.codingPractitioner,
        },
        actor: this.appointmentForm.get("participant").value,
      },
      {
        required: "required",
        status: "accepted",
        participantType: {
          text: Appointment.HEALTHCARESERVICE,
          coding: this.codingPractitioner,
        },
        actor: {
          reference: serviceIdentifier?.value,
          display: serviceIdentifier?.label,
          type: Appointment.HEALTHCARESERVICE,
        },
      },
    ];

    let unknowPatientData: UnknowPatientData;
    if (this.isUnknowPatient) {
      unknowPatientData = {
        name: this.patientForm.get("name").value,
        firstname: this.patientForm.get("firstname").value,
        email: this.patientForm.get("mail").value,
        phoneNbr: this.patientForm.get("phone").value,
        internalId: this.patientForm.get("internalId").value,
        patientLang: this.patientForm.get("userLang").value,
        smsMailSent: false,
        smsType: SMS_TYPE.NEW,
        serviceContactNumber: service.telecom.find((t) => t.system === FHIRHelper.SYSTEM_TELECOM_PHONE)?.value,
      };
    }

    const app: IAppointment = {
      createdBy: this.sessionService.account.caremateIdentifier,
      _id: undefined,
      resourceType: "appointement",
      identifier: id,
      status: "booked",
      description: this.appointmentForm.get("object").value,
      start: this.appointmentForm.get("start").value.format(),
      end: this.endDateComputed.format(),
      participant: part,
      modified: new Date().toISOString(),
      entityStatus: [1],
      unknowPatientData: this.isUnknowPatient ? unknowPatientData : undefined,
      billingCode: this.appointmentForm.get("billingCode").value,
    };

    return this.appointmentService.createVisioAppointement(app);
  }

  /**
   * Get coding for new appointement
   * code = URL --> https://meet.jit.si/{{timestampRDV}}/{{caremateIDwithout @}}/comunicare
   * system = meetjitsi
   */
  public get coding(): Coding[] {
    return [
      {
        code: null, // the backend manages the url
        system: Appointment.SYSTEM_JITSI,
      },
    ];
  }

  /**
   * remove @ from caremateIdentifier to be used in URL
   */
  public get patientIdWithoutA(): string {
    if (this.isUnknowPatient) {
      return this.patientForm.get("mail").value.replace(/@/g, "");
    } else {
      return this.patientUser.user.caremateIdentifier.replace(/@/g, "");
    }
  }

  /**
   * return patient Name (display with space)
   */
  public get patientName(): string {
    if (this.isUnknowPatient) {
      return this.patientForm.get("name").value + " " + this.patientForm.get("firstname").value;
    } else {
      return this.patientUser.user.name + " " + this.patientUser.user.firstname;
    }
  }

  /**
   * Get coding for new appointement
   * system = meetjitsi
   */
  public get codingPractitioner(): Coding[] {
    return [
      {
        code: null, // the backend manages the url
        system: Appointment.SYSTEM_JITSI_PRACTITIONER,
      },
    ];
  }

  /**
   * Compute the end date for creation of a new appointement
   * End date is computed with start date + duration in minutes
   */
  public get endDateComputed(): moment.Moment {
    return moment(this.appointmentForm.get("start").value).add(this.appointmentForm.get("duration").value, "minutes");
  }

  /**
   * Edit the appointement, then save it and refresh view
   */
  public updateVisioAppointement(): Observable<Appointment> {
    let type: SMS_TYPE;
    let resendSmsMail: boolean;
    if (this.isUnknowPatient) {
      // compute all boolean and store it in constant to have a more understandable if statement
      const IS_SAME_PRACTITIONNER =
        this.computeCurrentPractitioner(this.appointment).reference === this.appointmentForm.get("participant").value.reference;
      const IS_SAME_DATE = new Date(this.appointment.start).getTime() === new Date(this.appointmentForm.get("start").value).getTime();
      const IS_SAME_COMMENT = this.appointment.description === this.appointmentForm.get("object").value;
      const IS_SAME_PHONE = this.appointment.unknowPatientData?.phoneNbr === this.patientForm.get("phone").value;
      const IS_SAME_MAIL = this.appointment.unknowPatientData?.email === this.patientForm.get("mail").value;
      const IS_SAME_NAME = this.appointment.unknowPatientData?.name === this.patientForm.get("name").value;
      const IS_SAME_FIRSTNAME = this.appointment.unknowPatientData?.firstname === this.patientForm.get("firstname").value;
      const IS_SAME_LANG = this.appointment.unknowPatientData.patientLang === this.patientForm.get("userLang").value;
      // if one of is different we send a new type sms
      if (!IS_SAME_LANG || !IS_SAME_FIRSTNAME || !IS_SAME_NAME || !IS_SAME_PHONE || !IS_SAME_MAIL) {
        type = SMS_TYPE.NEW;
        resendSmsMail = true;
        // if one of is different we send a update type sms
      } else if (!IS_SAME_COMMENT || !IS_SAME_DATE || !IS_SAME_PRACTITIONNER) {
        type = SMS_TYPE.UPDATE;
        resendSmsMail = true;
        // if none is different we keep actual type and smsMailSent
      } else {
        type = this.appointment.unknowPatientData.smsType;
        resendSmsMail = !this.appointment.unknowPatientData.smsMailSent;
      }

      // update parameter
      this.appointment.unknowPatientData.name = this.patientForm.get("name").value;
      this.appointment.unknowPatientData.firstname = this.patientForm.get("firstname").value;
      this.appointment.unknowPatientData.email = this.patientForm.get("mail").value;
      this.appointment.unknowPatientData.internalId = this.patientForm.get("internalId").value;
      this.appointment.unknowPatientData.patientLang = this.patientForm.get("userLang").value;
      this.appointment.unknowPatientData.phoneNbr = this.patientForm.get("phone").value;
      this.appointment.unknowPatientData.smsMailSent = !resendSmsMail;
      this.appointment.unknowPatientData.smsType = type;
    }

    this.appointment.modified = new Date().toISOString();
    this.appointment.start = this.appointmentForm.get("start").value.format();
    this.appointment.end = this.endDateComputed.format();
    this.appointment.description = this.appointmentForm.get("object").value;
    this.appointment.billingCode = this.appointmentForm.get("billingCode").value;
    this.updateParticipant(this.appointment);
    return this.appointmentService.updateVisioAppointement(this.appointment);
  }

  /**
   * Find the practioner in the appointement
   */
  public computeCurrentPractitioner(app: IAppointment): Reference {
    return app.participant.find((p) => p.participantType.text === Appointment.PRACTITIONER).actor;
  }

  /**
   * update Participant when editing
   */
  private updateParticipant(app: IAppointment) {
    app.participant.forEach((p) => {
      if (p.participantType.text === Appointment.PRACTITIONER) {
        p.actor = this.appointmentForm.get("participant").value;
      }
    });
  }
}
