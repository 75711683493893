<ng-container>
  <div class="buttons-bar">
    <button mat-icon-button color="warn" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div style="text-align: center">
    <h2>
      {{ "questionnaireScoringFormula.title" | translate }}
    </h2>
  </div>
  <div style="display: flex; flex-direction: row; justify-content: space-between">
    <div
      class="questionsContainer"
      id="questionsArea"
      cdkDropList
      [cdkDropListConnectedTo]="['formulaArea']"
      [cdkDropListDisabled]="data.visualization"
    >
      <h3 style="text-align: center">{{ "questionnaireScoringFormula.questionList" | translate }}</h3>
      <div *ngFor="let question of data.questions; let i = index">
        <div
          class="questionnaireDroppedInput"
          [matTooltipDisabled]="question | getCanBeInFormula"
          [matTooltip]="'questionnaireScoringFormula.questionNotDraggable' | translate"
          cdkDrag
          [style]="(question | getCanBeInFormula) ? 'cursor: pointer' : ''"
          [id]="question.linkId"
          [cdkDragDisabled]="!(question | getCanBeInFormula)"
        >
          <button mat-stroked-button type="button" *cdkDragPreview>
            <span>{{ ("page.questionnaireEditor.question" | translate) + " " + (+i + 1) }}</span>
          </button>
          <h4>{{ ("page.questionnaireEditor.question" | translate) + " " + (+i + 1) }} : {{ question.text }}</h4>
          <div [ngSwitch]="question.answerDisplay?.type || question.type || 'radio'" *ngIf="!question.answerDisplay?.multiInputs">
            <div *ngSwitchCase="'range'" class="scale">
              <div *ngIf="question.answerDisplay?.displayIsShown">
                <h5 *ngIf="question.answerDisplay?.displayIsShown && (question | getQuestionOptions : data.contained).length">
                  {{ (question | getQuestionOptions : data.contained)[0].display }}
                </h5>
              </div>

              <div>
                <mat-icon aria-hidden="false" *ngIf="question.answerDisplay?.showMethod < 0"> sentiment_very_satisfied </mat-icon>
                <mat-icon aria-hidden="false" *ngIf="question.answerDisplay?.showMethod > 0"> mood_bad </mat-icon>
                <span *ngIf="question.answerDisplay?.showMethod === 0 && !question?.answerDisplay.scaleReverseValue">
                  {{ question?.answerDisplay.min }}
                </span>
                <span *ngIf="question.answerDisplay?.showMethod === 0 && question?.answerDisplay.scaleReverseValue">
                  {{ question?.answerDisplay.max }}
                </span>
                <mat-slider
                  min="{{ question?.answerDisplay?.min }}"
                  max="{{ question?.answerDisplay?.max }}"
                  step="{{ question?.answerDisplay?.step }}"
                  value="{{ question?.answerDisplay?.default }}"
                  invert="{{ question?.answerDisplay.scaleReverseValue }}"
                  thumbLabel
                  tickInterval="{{ question?.answerDisplay?.step }}"
                >
                </mat-slider>
                <mat-icon aria-hidden="false" *ngIf="question.answerDisplay?.showMethod < 0"> mood_bad </mat-icon>
                <mat-icon aria-hidden="false" *ngIf="question.answerDisplay?.showMethod > 0"> sentiment_very_satisfied </mat-icon>
                <span *ngIf="question.answerDisplay?.showMethod === 0 && question?.answerDisplay.scaleReverseValue">
                  {{ question?.answerDisplay.min }}
                </span>
                <span *ngIf="question.answerDisplay?.showMethod === 0 && !question?.answerDisplay.scaleReverseValue">
                  {{ question?.answerDisplay.max }}
                </span>
                <div *ngIf="question.answerDisplay?.displayIsShown" class="other-options-container">
                  <h5 class="other-options-title">{{ "page.questionnaireEditor.choiceOptionsTitle" | translate }} :</h5>
                  <h5 class="other-option" *ngFor="let choice of question | getQuestionOptions : data.contained">'{{ choice.display }}'</h5>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="'dropdown'">
              <mat-form-field *ngIf="(question | getQuestionOptions : data.contained).length > 0">
                <select matNativeControl>
                  <option *ngFor="let qOption of question | getQuestionOptions : data.contained">
                    {{ qOption.display }}
                  </option>
                </select>
              </mat-form-field>
            </div>
            <div *ngSwitchCase="'checkboxes'">
              <div *ngIf="(question | getQuestionOptions : data.contained).length > 0">
                <div *ngFor="let qOption of question | getQuestionOptions : data.contained">
                  <mat-checkbox>
                    {{ qOption.display }}
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="'radio'">
              <mat-radio-group *ngIf="(question | getQuestionOptions : data.contained).length > 0" class="radio">
                <mat-radio-button *ngFor="let qOption of question | getQuestionOptions : data.contained" value="{{ qOption.code }}">
                  <div style="display: flex; justify-content: center; align-items: center; text-wrap: auto">
                    {{ qOption.display }}
                    <mat-form-field style="width: 100px" class="ml-05">
                      <input
                        matInput
                        type="number"
                        placeholder="valeur"
                        [disabled]="data.visualization"
                        [ngModel]="question | getValueSetValue : qOption.code : data.scoringValueSet"
                        (ngModelChange)="valueSetChange(question, qOption.code, $event)"
                        [required]="true"
                      />
                    </mat-form-field>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div *ngSwitchCase="'choice'">
              <mat-radio-group *ngIf="(question | getQuestionOptions : data.contained).length > 0" class="radio">
                <mat-radio-button *ngFor="let qOption of question | getQuestionOptions : data.contained" value="{{ qOption.code }}">
                  <div style="display: flex; justify-content: center; align-items: center; text-wrap: auto">
                    {{ qOption.display }}
                    <mat-form-field style="width: 100px" class="ml-05">
                      <input
                        matInput
                        type="number"
                        [placeholder]="'table.value' | translate"
                        [disabled]="data.visualization"
                        [ngModel]="question | getValueSetValue : qOption.code : data.scoringValueSet"
                        (ngModelChange)="valueSetChange(question, qOption.code, $event)"
                        [required]="true"
                      />
                    </mat-form-field>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div *ngSwitchCase="'number'">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  [placeholder]="question.answerDisplay?.placeholder"
                  [attr.min]="question.answerDisplay?.min"
                  [attr.max]="question.answerDisplay?.max"
                  step="1"
                />
              </mat-form-field>
              <!-- Units: -->
              <mat-form-field *ngIf="question.options?.reference">
                <select matNativeControl>
                  <option *ngFor="let qOption of question | getQuestionOptions : data.contained">
                    {{ qOption.display }}
                  </option>
                </select>
              </mat-form-field>
            </div>
            <div *ngSwitchCase="'decimal'">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  [placeholder]="question.answerDisplay?.placeholder"
                  [attr.min]="question.answerDisplay?.min"
                  [attr.max]="question.answerDisplay?.max"
                  step="0.01"
                />
              </mat-form-field>
              <!-- Units: -->
              <mat-form-field *ngIf="question.options?.reference">
                <select matNativeControl>
                  <option *ngFor="let qOption of question | getQuestionOptions : data.contained">
                    {{ qOption.display }}
                  </option>
                </select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; flex-direction: column; width: 38%">
      <div class="operatorsContainer">
        <div id="operatorsArea" cdkDropList [cdkDropListConnectedTo]="['formulaArea']" [cdkDropListDisabled]="data.visualization">
          <button mat-stroked-button type="button" *ngFor="let operator of operatorList" cdkDrag>
            {{ operator }}
          </button>
          <button mat-stroked-button type="button" style="width: 35%" cdkDrag>
            <input
              [(ngModel)]="currentNumberValue"
              matInput
              type="number"
              placeholder="{{ 'page.questionnaireEditor.inputsTypes.number' | translate }}"
              (keypress)="preventCharacter($event, [PREVENTCHARACTER.letter])"
              [disabled]="data.visualization"
            />
          </button>
        </div>
      </div>
      <div class="formulaContainer">
        <div
          id="formulaArea"
          cdkDropList
          (cdkDropListDropped)="drop($event)"
          [cdkDropListDisabled]="data.visualization"
          style="height: 100%; width: 100%"
        >
          <h2 class="empty-inputs" *ngIf="decodedFormula.length === 0">
            {{ "page.questionnaireEditor.placeInputElements" | translate }}
          </h2>
          <button
            mat-stroked-button
            type="button"
            *ngFor="let elem of decodedFormula; let i = index"
            cdkDrag
            [style]="elem.type === 'number' ? 'width: 35%' : ''"
          >
            <span *ngIf="elem.type === 'operator'">{{ elem.value }}</span>
            <input
              *ngIf="elem.type === 'number'"
              [(ngModel)]="decodedFormula[i].value"
              matInput
              type="number"
              placeholder="{{ 'page.questionnaireEditor.inputsTypes.number' | translate }}"
              (keypress)="preventCharacter($event, [PREVENTCHARACTER.letter], i)"
              [disabled]="data.visualization"
            />
            <span *ngIf="elem.type === 'question'">{{
              ("page.questionnaireEditor.question" | translate) + " " + (elem.value | getQuestionNumber : data.questions)
            }}</span>
            <mat-icon
              *ngIf="!data.visualization"
              button
              class="deleteButton"
              aria-hidden="false"
              aria-label="Delete icon"
              (click)="deleteElem(i)"
              >cancel</mat-icon
            >
          </button>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="apply()">
      {{ "drugSchema.confirm" | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
