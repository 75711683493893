import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "firstImage" })
export class FirstImagePipe implements PipeTransform {
  transform(content: string): any {
    const reg = /<img[^>]+src="([^">]+)"/;
    const result = content.match(reg);
    return result ? result[1] : undefined;
  }
}
