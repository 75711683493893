<h2 mat-dialog-title>{{ "releaseNote.title" | translate }}</h2>
<mat-dialog-content class="releaseNote">
  <!-- Only show stepper if more than one slide -->
  <mat-horizontal-stepper *ngIf="!isOnlyOnePage()" #stepper>
    <mat-step *ngFor="let slide of slides">
      <h3>{{ "releaseNote." + slide.title | translate }}</h3>
      <div *ngFor="let subElement of slide.subElements">
        <h4 *ngIf="subElement.title">
          <b>{{ "releaseNote." + subElement.title | translate }} :</b>
        </h4>
        <!-- Loop on descriptions and keep index to get image -->
        <ng-container *ngFor="let description of subElement.descriptions; let i = index">
          <p [innerHTML]="'- ' + ('releaseNote.' + description | translate)"></p>
          <br *ngIf="subElement.images[i]" />
          <img
            class="helpImg"
            [src]="subElement.images[i]?.path"
            [ngStyle]="{
              height: subElement.images[i]?.height,
              width: subElement.images[i]?.width
            }"
          />
          <br *ngIf="subElement.images[i]" />
        </ng-container>
        <!-- Only show more images if necessary -->
        <div *ngIf="subElement.descriptions?.length < subElement.images?.length">
          <!-- Loop on images not show yet in previous div -->
          <div *ngFor="let image of getMissingImages(subElement)">
            <img class="helpImg" [src]="image.path" [ngStyle]="{ height: image.height, width: image.width }" />
          </div>
        </div>
      </div>
      <div class="action-container">
        <button mat-raised-button matStepperPrevious *ngIf="!isFirstSlide" color="primary">
          {{ "globalHelp.backSlide" | translate }}
        </button>
        <button mat-raised-button matStepperNext class="next-btn" *ngIf="!isLastSlide" color="primary">
          {{ "globalHelp.nextSlide" | translate }}
        </button>
        <div *ngIf="isLastSlide">
          <mat-checkbox [(ngModel)]="isChecked" class="checkbox">{{ "releaseNote.checkbox" | translate }}</mat-checkbox>
          <button mat-raised-button color="primary" (click)="onClose()">
            {{ "globalHelp.endSlide" | translate }}
          </button>
        </div>
      </div>
    </mat-step>
    <!-- Icon overrides. -->
    <ng-template matStepperIcon="edit" let-index="index">
      {{ index + 1 }}
    </ng-template>
  </mat-horizontal-stepper>
  <!-- Only show this div if one slide -->
  <div *ngIf="isOnlyOnePage()">
    <h3>{{ "releaseNote." + slides[0].title | translate }}</h3>
    <div *ngFor="let subElement of slides[0].subElements">
      <h4 *ngIf="subElement.title">
        <b>{{ "releaseNote." + subElement.title | translate }} :</b>
      </h4>
      <!-- Loop on descriptions and keep index to get image -->
      <ng-container *ngFor="let description of subElement.descriptions; let i = index">
        <p [innerHTML]="'- ' + ('releaseNote.' + description | translate)"></p>
        <br *ngIf="subElement?.images[i]" />
        <img
          class="helpImg"
          [src]="subElement?.images[i]?.path"
          [ngStyle]="{
            height: subElement?.images[i]?.height,
            width: subElement?.images[i]?.width
          }"
        />
        <br *ngIf="subElement?.images[i]" />
      </ng-container>
      <!-- Only show more images if necessary -->
      <div *ngIf="subElement.descriptions?.length < subElement.images?.length">
        <!-- Loop on images not show yet in previous div -->
        <div *ngFor="let image of getMissingImages(subElement)">
          <img class="helpImg" [src]="image.path" [ngStyle]="{ height: image.height, width: image.width }" />
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="isOnlyOnePage()">
  <mat-checkbox [(ngModel)]="isChecked" class="checkbox">{{ "releaseNote.checkbox" | translate }}</mat-checkbox>
  <button mat-raised-button color="primary" (click)="onClose()">
    {{ "globalHelp.endSlide" | translate }}
  </button>
</mat-dialog-actions>
