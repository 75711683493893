import { Component, EventEmitter, Input, OnDestroy, Output, Pipe, PipeTransform } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Tools } from "src/app/helpers/tools";
import { Coding } from "src/app/models/coding.interface";
import { QuestionnaireScoring } from "src/app/models/questionnaireScoring.model";
import { SessionService } from "src/app/providers/session.service";
import { Contained, INPUT_TYPE, QuestionQuestionnaire, SPECIFIC_USE } from "../../../../models/questionnaire.interface";

@Pipe({ name: "getCanDeleteQuestion" })
export class GetCanDeleteQuestion implements PipeTransform {
  transform(question: QuestionQuestionnaire, scorings: QuestionnaireScoring[]): boolean {
    let canDelete = true;
    if (scorings?.length) {
      for (const s of scorings) {
        if (s.formula?.includes(question.linkId)) {
          canDelete = false;
          break;
        }
      }
    }
    return canDelete;
  }
}

@Component({
  selector: "app-item-drag-drop",
  templateUrl: "./item-drag-drop.component.html",
  styleUrls: ["./item-drag-drop.component.scss"],
})
export class ItemDragDropComponent implements OnDestroy {
  public starCount = [];
  public initialDate: moment.Moment = null;
  public minDate: moment.Moment = null;
  public maxDate: moment.Moment = null;
  public minTime: string;
  public maxTime: string;
  public value: unknown[] = [];
  public sliderValue = 0;
  public questionOptions: Coding[] = [];
  public SPECIFIC_USE = SPECIFIC_USE; // for accessing Enum in template
  @Input() specificUse: SPECIFIC_USE;
  @Input() question: QuestionQuestionnaire;
  @Input() visualization = false;
  @Input() scorings: QuestionnaireScoring[];
  @Input() set contained(value: Contained[]) {
    this.setupQuestionOptions(value);
  }
  @Output() edit = new EventEmitter<string>();
  @Output() duplicate = new EventEmitter<string>();
  @Output() delete = new EventEmitter<string>();
  @Input() set default(value: string) {
    if (this.question && this.question.answerDisplay?.type === INPUT_TYPE.RANGE) {
      this.sliderValue = value ? parseInt(value, 10) : 0;
    } else if (this.question.answerDisplay?.type === INPUT_TYPE.RATING) {
      this.starCount = value ? new Array(Number(value)) : null;
    } else if (this.question.answerDisplay?.type === INPUT_TYPE.DATE) {
      this.initialDate = value ? moment(value, "DD/MM/YYYY") : null;
    } else {
      if (this.value.length > 1) {
        this.value = this.value.map((_v) => value);
      } else {
        this.value = [value];
      }
    }
  }
  @Input() set min(value: string) {
    if (this.question.answerDisplay?.type === INPUT_TYPE.DATE) {
      this.minDate = value ? moment(value, "DD/MM/YYYY") : null;
    } else if (this.question.answerDisplay?.type === INPUT_TYPE.TIME) {
      this.minTime = value ? value : undefined;
    }
  }
  @Input() set max(value: string) {
    if (this.question.answerDisplay?.type === INPUT_TYPE.DATE) {
      this.maxDate = value ? moment(value, "DD/MM/YYYY") : null;
    } else if (this.question.answerDisplay?.type === INPUT_TYPE.TIME) {
      this.maxTime = value ? value : undefined;
    }
  }
  public joditConfig = {
    useSearch: false,
    spellcheck: true,
    language: this.sessionService.userLang,
    toolbarButtonSize: "small",
    disablePlugins: "file, media",
  };
  private onDestroy$ = new Subject<void>();

  constructor(private translateService: TranslateService, private adapter: DateAdapter<unknown>, private sessionService: SessionService) {
    moment.locale(this.translateService.currentLang);
    // fix for dynamically update lang on datepicker
    this.adapter.setLocale(this.translateService.currentLang);
    this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe((langChangeEvent: LangChangeEvent) => {
      this.adapter.setLocale(langChangeEvent.lang);
      moment.locale(langChangeEvent.lang);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public editQuestion(): void {
    this.edit.emit(this.question.linkId);
  }

  public duplicateQuestion(): void {
    this.duplicate.emit(this.question.linkId);
  }

  public deleteQuestion(): void {
    this.delete.emit(this.question.linkId);
  }

  public setupQuestionOptions(contained: Contained[]): void {
    this.questionOptions = [];
    if (contained) {
      const c = contained.find((model) => model.idSet === this.question.options?.reference);
      if (c) {
        this.questionOptions = c.compose.include[0].concept;
        this.value = Array.from({ length: c.compose.include[0].concept.length }, (_, _i) => {
          if (Tools.isDefined(this.value)) {
            return this.value;
          } else {
            return "0";
          }
        });
      }
    }
  }
}
