import { Injectable } from "@angular/core";
import { Route, Router } from "@angular/router";
import { Tools } from "../helpers/tools";
import { IServiceRoutes } from "./api/baseApi.service";

@Injectable({
  providedIn: "root",
})
export class DashboardRoutesService {
  private static privateAllApiRoutes: IServiceRoutes[] = [];

  public static get allApiRoutes(): IServiceRoutes[] {
    return Tools.clone(DashboardRoutesService.privateAllApiRoutes);
  }

  public static addServiceRoutes(serviceRoutes: IServiceRoutes) {
    if (!DashboardRoutesService.privateAllApiRoutes.find((r) => r.theme === serviceRoutes.theme)) {
      DashboardRoutesService.privateAllApiRoutes.push(serviceRoutes);
    }
  }

  constructor(private router: Router) {}

  public getDashboardRoutes(): IServiceRoutes {
    const paths = this.getDashboardRoutesPaths("", this.router.config);
    const routes: IServiceRoutes = {
      theme: "Pages",
      create: [],
      read: paths,
      update: [],
      delete: [],
    };
    return routes;
  }

  private getDashboardRoutesPaths(parent: string, config: Route[]): string[] {
    let routes: string[] = [];
    for (const conf of config) {
      const route = conf;
      if (route.path && route.path !== "**") {
        routes.push(parent + "/" + route.path);
      }
      if (route.children) {
        const currentPath = route.path ? parent + "/" + route.path : parent;
        const childrenPaths = this.getDashboardRoutesPaths(currentPath, route.children);
        routes = routes.concat(childrenPaths);
      }
    }
    return routes;
  }
}
