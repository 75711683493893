import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CompareMediaPipe } from "./compare-media.pipe";
import { KnowledgeCategoryPipe } from "./knowledge-category.pipe";
import { FormatToLinkableListPipe } from "./knowledge-formatToLinkableList-pipe";
import { KnowledgeMediasPipe } from "./knowledge-medias.pipe";

@NgModule({
  declarations: [KnowledgeCategoryPipe, KnowledgeMediasPipe, CompareMediaPipe, FormatToLinkableListPipe],
  imports: [CommonModule],
  exports: [KnowledgeCategoryPipe, KnowledgeMediasPipe, CompareMediaPipe, FormatToLinkableListPipe],
})
export class KnowledgeListPipesModule {}
