<section class="properties-group" *ngIf="showChoices && currentIndexContained > -1">
  <div class="sideBySideFields">
    <h3>{{ "page.questionnaireEditor.properties.choices" | translate }} :</h3>
    <div>
      <mat-form-field>
        <mat-label>{{ "page.questionnaireEditor.Templates" | translate }}: </mat-label>
        <mat-select name="vsTemplates" [disabled]="disabled" (selectionChange)="templateSelected($event)">
          <mat-option *ngFor="let t of valueSetTemplates" [value]="t">
            {{ t.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        mat-icon-button
        color="accent"
        aria-label="Manage templates"
        *ngIf="(canSaveServiceTemplate || canSaveGlobalTemplate) && !disabled"
        (click)="manageTemplates()"
      >
        <mat-icon>assignment</mat-icon>
      </button>
    </div>
  </div>
  <div
    class="choiceContainer"
    *ngFor="
      let answer of questionsOptions[currentIndexContained].compose.include[0].concept;
      let index = index;
      let isFirst = first;
      let isLast = last
    "
  >
    <div class="arrowsContainer" *ngIf="!disabled">
      <mat-icon *ngIf="!isFirst" button class="arrow" aria-hidden="false" aria-label="Arrow icon" (click)="changeOrderAnswers(true, index)"
        >arrow_drop_up
      </mat-icon>
      <mat-icon *ngIf="!isLast" button class="arrow" aria-hidden="false" aria-label="Arrow icon" (click)="changeOrderAnswers(false, index)"
        >arrow_drop_down
      </mat-icon>
    </div>
    <mat-form-field appearance="outline" class="valueInput" *ngIf="!isScale">
      <mat-label>{{ "page.questionnaireEditor.properties.value" | translate }}</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="questionsOptions[currentIndexContained].compose.include[0].concept[index].code"
        [disabled]="true"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="choiceInput">
      <mat-label>{{ "page.questionnaireEditor.properties.answerOption" | translate }}</mat-label>
      <input
        matInput
        type="text"
        (change)="handleValuesMeaningChange()"
        [(ngModel)]="questionsOptions[currentIndexContained].compose.include[0].concept[index].display"
        [disabled]="disabled"
      />
    </mat-form-field>
    <button mat-icon-button color="warn" aria-label="Delete answer" *ngIf="!disabled" (click)="deleteAnswer(index)">
      <mat-icon>cancel</mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      aria-label="Add info tip"
      (click)="addInfoTip(index)"
      *ngIf="questionsOptions[currentIndexContained].compose.include[0].concept[index].infotip === undefined && !disabled"
    >
      <mat-icon>info</mat-icon>
    </button>
    <mat-form-field
      appearance="outline"
      class="choiceExplanation"
      *ngIf="questionsOptions[currentIndexContained].compose.include[0].concept[index].infotip !== undefined"
    >
      <mat-label>{{ "page.questionnaireEditor.properties.infoTip" | translate }}</mat-label>
      <textarea
        matInput
        (change)="handleValuesMeaningChange()"
        [(ngModel)]="questionsOptions[currentIndexContained].compose.include[0].concept[index].infotip"
        [disabled]="disabled"
      >
      </textarea>
    </mat-form-field>
    <button
      mat-icon-button
      color="primary"
      aria-label="Delete info tip"
      (click)="deleteInfoTip(index)"
      *ngIf="questionsOptions[currentIndexContained].compose.include[0].concept[index].infotip !== undefined && !disabled"
    >
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
  <button mat-button (click)="reverseOrderAnswers()" style="margin-bottom: 10px" *ngIf="!disabled">
    <mat-icon aria-hidden="false" aria-label="Reverse">swap_horiz</mat-icon>
    {{ "page.questionnaireEditor.properties.reverseAnswers" | translate }}
  </button>
  <div class="sideBySideFields" *ngIf="!disabled">
    <button mat-stroked-button (click)="addAnswer()">
      <mat-icon aria-hidden="false" aria-label="Add icon">add</mat-icon>
      {{ "page.questionnaireEditor.properties.add" | translate }}
    </button>
    <button mat-stroked-button *ngIf="canSaveServiceTemplate || canSaveGlobalTemplate" (click)="saveAsTemplate()" color="accent">
      <i>{{ "page.questionnaireEditor.properties.saveAsTemplate" | translate }}</i>
    </button>
  </div>
</section>
