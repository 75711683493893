import { Pipe, PipeTransform } from "@angular/core";
import { Reference } from "src/app/models/reference.interface";

@Pipe({
  name: "formReferenceToReference",
})
export class FormReferenceToReferencePipe implements PipeTransform {
  transform(value: any): unknown {
    const reference: Reference = { display: value?.display?.traductions?.at(0)?.translateKey, reference: value?.reference };
    return reference;
  }
}
