import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatExpansionPanel } from "@angular/material/expansion";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { skipWhile, takeUntil, tap } from "rxjs/operators";
import { CareplanEditorService } from "src/app/careplan-editor/domain/careplan-editor.service";
import { CareplanTemplateFormHelper } from "src/app/careplan-editor/domain/careplan-template-form-helper";
import { TimingEditorComponent } from "src/app/components/timing-editor/timing-editor.component";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { Careplan } from "src/app/models/careplan.model";
import { ICareplan } from "src/app/models/careplans.interface";
import { Healthcareservice } from "src/app/models/healthcareservice.model";
import { ACTIVITY_CATEGORY, IReference, ITiming } from "src/app/models/sharedInterfaces";
import { ITranslation } from "src/app/models/translation.interface";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { LanguagesService } from "src/app/providers/languages.service";
import { SessionService } from "src/app/providers/session.service";
import { VIEWS } from "../careplan-editor-general-tab.component";

@Component({
  selector: "app-careplan-visual-view",
  templateUrl: "./careplan-visual-view.component.html",
  styleUrls: ["./careplan-visual-view.component.scss"],
})
export class CareplanVisualViewComponent implements OnInit, OnDestroy {
  @ViewChild("input") importInput: ElementRef;
  @ViewChild("productListDrugInput") productListDrugInput: ElementRef<HTMLInputElement>;
  @ViewChild(TimingEditorComponent) timing: TimingEditorComponent;
  public panelCount;
  public previousPanels: MatExpansionPanel[];
  public availablePeriodUnits: { value: string; translationKey: string }[] = [
    { value: "h", translationKey: "periodUnit.hour" },
    { value: "d", translationKey: "periodUnit.day" },
    { value: "w", translationKey: "periodUnit.week" },
  ];

  @ViewChildren(MatExpansionPanel)
  set _expansionPanels(panels: QueryList<MatExpansionPanel>) {
    setTimeout(() => {
      const lastPanel = panels.find((obj1) => !this.previousPanels?.some((obj2) => obj1.id === obj2.id));
      lastPanel?.open();

      this.previousPanels = panels.toArray();
      this.panelCount = panels.length;
    });
  }

  public isLoading = true;
  public auto: ElementRef; // the ref of the app-drug-server-side-search's matAutoComplete input
  public careplanTemplate: ICareplan;
  public activeView = 1;
  public views = VIEWS;
  public currentView: VIEWS = this.views.VISUAL;
  public availableServices: {
    checked: boolean;
    service: Healthcareservice;
  }[];
  private onDestroy$ = new Subject<void>();
  public availableLangs: ITranslation[] = [];
  public availableCategories: ACTIVITY_CATEGORY[];
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public drugServerSideFilteringCtrl: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required, this.noEmptyValue]);
  public lastKeystroke: string; // this variable is used to detect keystroke on child component and trigger reload of the pipe getActivityName
  public currentLanguage: string = this.sessionService.userLang;
  private isValidJson = true;
  public ACTIVITY_CATEGORY = ACTIVITY_CATEGORY;
  private valueChangeIsLinear$: Subscription;
  public typeSelectorDisabled = false;

  constructor(
    public careplanEditorService: CareplanEditorService,
    private careplanTemplateFormHelper: CareplanTemplateFormHelper,
    private healthcareService: HealthcareserviceService,
    private languagesService: LanguagesService,
    private cdr: ChangeDetectorRef,
    private sessionService: SessionService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Refresh translation when needed
    this.sessionService.refreshServerTraductions.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentLanguage = this.sessionService.userLang;
    });

    if (this.careplanEditorService.currentCareplanTemplate) {
      this.init();
    } else {
      this.careplanEditorService.careplanTemplateReady$
        .pipe(
          skipWhile((value) => value === false),
          takeUntil(this.onDestroy$),
          tap((_ready) => {
            this.init();
          })
        )
        .subscribe();
    }
    this.languagesService
      .list()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((languages) => {
        this.availableLangs = languages;
      });
  }

  ngOnDestroy(): void {
    if (this.router.url.startsWith("/careplanEditor/" + this.careplanEditorService.currentCareplanTemplate.support[0].reference)) {
      // save the careplanTemplate
      this.saveCareplanTemplate();
    }
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public init(): void {
    this.careplanTemplate = this.careplanEditorService.currentCareplanTemplate;
    if (this.careplanTemplate.activity.length > 0) {
      this.typeSelectorDisabled = true;
    } else {
      this.typeSelectorDisabled = false;
    }

    this.initHealthcareService();
    this.setupAvailablesCategories();
    this.setupValueChange();
    this.isLoading = false;
  }

  /**
   * Get all available services of the user and setup the list for the form
   */
  private initHealthcareService(): void {
    this.healthcareService
      .watchAvailableServices()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((services) => {
        this.availableServices = services.map((s) => {
          const isChecked = this.careplanTemplate.author.findIndex((a) => a.reference === s.identifier[0].value) !== -1;
          if (isChecked && this.serviceForm.length < 0) {
            this.serviceForm.push(
              new UntypedFormGroup({
                reference: new UntypedFormControl(s.identifier[0].value),
                display: new UntypedFormControl(s.identifier[0].label),
              })
            );
          }
          return { checked: isChecked, service: s };
        });
      });
  }
  /**
   * setup valueChange on form field where it is needed
   */
  private setupValueChange(): void {
    if (this.valueChangeIsLinear$) return;
    this.valueChangeIsLinear$ = this.careplanEditorService.careplanTemplateForm
      .get("isLinear")
      .valueChanges.pipe(
        takeUntil(this.onDestroy$),
        tap(() => {
          this.setupAvailablesCategories();
        })
      )
      .subscribe();
  }

  /**
   * Return the description field form
   */
  public get descriptionForm(): UntypedFormGroup {
    return this.careplanEditorService.careplanTemplateForm.get("description") as UntypedFormGroup;
  }

  /**
   * Return the description.traductions field form
   */
  public get descriptionTradForm(): UntypedFormArray {
    return this.descriptionForm.get("traductions") as UntypedFormArray;
  }

  /**
   * Return the services field form
   */
  public get serviceForm(): UntypedFormArray {
    return this.careplanEditorService.careplanTemplateForm.get("services") as UntypedFormArray;
  }
  /**
   * Return the activity field form
   */
  public get activityForm(): UntypedFormArray {
    return this.careplanEditorService.careplanTemplateForm.get("activity") as UntypedFormArray;
  }

  /**
   * Return the addresses field form
   */
  public get addressesForm(): UntypedFormArray {
    return this.careplanEditorService.careplanTemplateForm.get("addresses") as UntypedFormArray;
  }

  /**
   * Return the detail  field form of an activity
   * @param i - index of the activity
   */
  private getActivityDetailForm(i: number): UntypedFormGroup {
    return this.activityForm.at(i).get("detail") as UntypedFormGroup;
  }

  /**
   * Return the description.traductions field form of an activity
   * @param i - index of the activity
   */
  private getActivityDescriptionTradForm(i: number): UntypedFormArray {
    return this.getActivityDetailForm(i).get("description").get("traductions") as UntypedFormArray;
  }

  /**
   * Return the reference.traductions  field form of an activity
   * @param i - index of the activity
   */
  private getActivityReferenceTradForm(i: number): UntypedFormArray {
    return this.activityForm.at(i).get("reference").get("display").get("traductions") as UntypedFormArray;
  }

  /**
   * Return the display field form of an address
   * @param i - index of the address
   */
  private getAddressDisplayTradForm(i: number): UntypedFormArray {
    return this.addressesForm.at(i).get("display").get("traductions") as UntypedFormArray;
  }

  /**
   * Return the drug field form of an activity
   * @param i - index of the activity
   */
  private getActivityDrugForm(i: number): UntypedFormGroup {
    return this.getActivityDetailForm(i).get("drug") as UntypedFormGroup;
  }

  /**
   * Return the scheduledTiming field form of an activity
   * @param i - index of the activity
   */
  private getActivityScheduledTimingForm(i: number): UntypedFormGroup {
    return this.getActivityDetailForm(i).get("scheduledTiming") as UntypedFormGroup;
  }

  /**
   * Return the drug.productList field form of an activity
   * @param i - index of the activity
   */
  private getActivityDrugProductList(i: number): UntypedFormArray {
    return this.getActivityDrugForm(i).get("productList") as UntypedFormArray;
  }
  /**
   * update the form tu set/unset the service to the careplan form
   * @param service - the service that the user clicked on
   */
  public healthcareServiceChange(service: { checked: boolean; service: Healthcareservice }): void {
    service.checked = !service.checked;
    const s = service.service;
    if (service.checked) {
      // add service to form
      this.serviceForm.push(
        new UntypedFormGroup({
          reference: new UntypedFormControl(s.identifier[0].value),
          display: new UntypedFormControl(s.identifier[0].label),
        })
      );
    } else {
      // delete service to form
      const index = this.serviceForm.value.findIndex((sf) => sf.reference == s.identifier[0].value);
      if (index !== -1) {
        this.serviceForm.removeAt(index);
      } else {
        FileLogger.warn("healthcareServiceChange", "Service not found " + s.identifier[0].value);
      }
    }
  }

  /**
   * Add an (empty) activity to the careplan form
   */
  public onAddActivity(): void {
    this.activityForm.push(
      new UntypedFormGroup({
        actionResulting: new UntypedFormArray([]),
        progress: new UntypedFormArray([]),
        reference: new UntypedFormGroup({
          reference: new UntypedFormControl(""),
          display: new UntypedFormGroup({
            isTranslated: new UntypedFormControl(false),
            defaultLang: new UntypedFormControl("fr"),
            traductions: new UntypedFormArray([]),
          }),
        }),
        detail: new UntypedFormGroup({
          status: new UntypedFormControl(Careplan.ACTIVITY_STATUS_INACTIVE),
          description: new UntypedFormGroup({
            isTranslated: new UntypedFormControl(false),
            defaultLang: new UntypedFormControl("fr"),
            traductions: new UntypedFormArray([]),
          }),
          category: new UntypedFormControl(ACTIVITY_CATEGORY.DRUG, Validators.required),
          code: new UntypedFormGroup({
            code: new UntypedFormControl("", Validators.required),
            system: new UntypedFormControl("http://snomed.info.sct"),
          }),
          location: new UntypedFormControl({}),
          scheduledTiming: new UntypedFormGroup({
            repeat: new UntypedFormGroup({
              frequency: new UntypedFormControl(""),
              period: new UntypedFormControl(""),
              periodUnits: new UntypedFormControl(""),
            }),
          }),
          drug: new UntypedFormGroup({
            isDrugsLinked: new UntypedFormControl(false),
            isMandatory: new UntypedFormControl(false),
            productList: new UntypedFormArray([]),
          }),
          procedure: new UntypedFormGroup({
            isTeleconsultation: new UntypedFormControl(false),
            withPerformer: new UntypedFormControl(false),
            withAddressAndPhone: new UntypedFormControl(false),
            isCycle: new UntypedFormControl(false),
          }),
        }),
      })
    );
    this.typeSelectorDisabled = true;
    this.addActivityReferenceTrad(this.activityForm.length - 1);
    this.addActivityDescriptionTrad(this.activityForm.length - 1);
  }

  /**
   * Add an address (=type) to the careplan form
   */
  public onAddAddress(): void {
    this.addressesForm.push(
      new UntypedFormGroup({
        reference: new UntypedFormControl(""),
        display: new UntypedFormGroup({
          isTranslated: new UntypedFormControl(false),
          defaultLang: new UntypedFormControl("fr"),
          traductions: new UntypedFormArray([]),
        }),
        status: new UntypedFormControl("inactive"), // inactive, active
      })
    );
    this.addAddressDisplayTrad(this.addressesForm.length - 1);
  }

  /**
   * Delete an activity
   * @param index - the index of the activity to delete
   */
  public onDeleteActivity(index: number): void {
    this.activityForm.removeAt(index);
    if (this.activityForm.value.length > 0) {
      this.typeSelectorDisabled = true;
    } else {
      this.typeSelectorDisabled = false;
    }
  }

  /**
   * Delete an address
   * @param index - the index of the address to delete
   */
  public onDeleteAddress(index: number): void {
    this.addressesForm.removeAt(index);
  }

  /**
   * Add a traduction (in a language not already set) for the activity description field
   * @param index - the index of the activity
   */
  private addActivityDescriptionTrad(index: number): void {
    const notAlreadyUsedLang = this.availableLangs.filter((l) => {
      const langs: string[] = this.getActivityDescriptionTradForm(index).value.map((d) => d.lang);
      return !langs.includes(l.term);
    });
    this.getActivityDescriptionTradForm(index).push(
      new UntypedFormGroup({
        translateKey: new UntypedFormControl(""),
        lang: new UntypedFormControl(notAlreadyUsedLang[0]?.term),
      })
    );
  }

  /**
   * Add a traduction (in a language not already set) for the activity reference field
   * @param index - the index of the activity
   */
  private addActivityReferenceTrad(index: number): void {
    const notAlreadyUsedLang = this.availableLangs.filter((l) => {
      const langs: string[] = this.getActivityReferenceTradForm(index).value.map((d) => d.lang);
      return !langs.includes(l.term);
    });
    this.getActivityReferenceTradForm(index).push(
      new UntypedFormGroup({
        translateKey: new UntypedFormControl("", Validators.required),
        lang: new UntypedFormControl(notAlreadyUsedLang[0]?.term),
      })
    );
  }

  /**
   * Add a traduction (in a language not already set) for the address display field
   * @param index - the index of the activity
   */
  private addAddressDisplayTrad(index: number): void {
    const notAlreadyUsedLang = this.availableLangs.filter((l) => {
      const langs: string[] = this.getAddressDisplayTradForm(index).value.map((d) => d.lang);
      return !langs.includes(l.term);
    });
    this.getAddressDisplayTradForm(index).push(
      new UntypedFormGroup({
        translateKey: new UntypedFormControl("", Validators.required),
        lang: new UntypedFormControl(notAlreadyUsedLang[0]?.term),
      })
    );
  }

  /**
   * Remove a drug from the productList of the activity
   * @param item - the refrence of the drug
   * @param i - the index of the activity
   */
  public removeDrug(item: IReference, i: number): void {
    const index = this.getActivityDrugProductList(i).value.findIndex((v) => v.reference === item.reference);
    if (index !== -1) {
      this.getActivityDrugProductList(i).removeAt(index);
    }
  }

  /**
   * Add a drug to the productList of the activity
   * @param value - the name of the drug
   * @param i - the index of the activity
   */
  public addDrugToChip(value: string, i: number): void {
    this.getActivityDrugProductList(i).push(
      new UntypedFormGroup({
        reference: new UntypedFormControl(value),
        display: new UntypedFormControl(value),
      })
    );
    this.productListDrugInput.nativeElement.value = "";
  }
  /**
   * Validator for the drugServerSideFilteringCtrl
   * @param control
   */
  private noEmptyValue(control: UntypedFormControl) {
    let isValid: boolean;
    // control.value can be a string or IDrugInfo if an item has been selected from the list
    if (Tools.isString(control.value)) {
      isValid = control.value?.trim();
    } else {
      isValid = control.value?.officialName?.trim();
    }
    return isValid ? null : { whitespace: true };
  }

  /**
   * Setup the ref of the matAutoComplete input from the drugServerSideSearchComponent to have access to it in the matchiplist input
   * @param event - the ref of the matAutoComplete input from the drugServerSideSearchComponent
   */
  public ref(event: ElementRef): void {
    if (event) {
      this.auto = event;
      this.cdr.detectChanges();
    }
  }

  private setupAvailablesCategories(): void {
    this.availableCategories = this.careplanEditorService.careplanTemplateForm.get("isLinear").value
      ? [ACTIVITY_CATEGORY.DRUG, ACTIVITY_CATEGORY.APPOINTMENT, ACTIVITY_CATEGORY.TELECONSULTATION, ACTIVITY_CATEGORY.OTHER]
      : [ACTIVITY_CATEGORY.DRUG, ACTIVITY_CATEGORY.PROCEDURE, ACTIVITY_CATEGORY.SUPPLY, ACTIVITY_CATEGORY.OTHER];
  }

  public frequencyChange(index: number, frequency: ITiming): void {
    this.getActivityScheduledTimingForm(index).get("repeat.frequency").setValue(frequency.frequency);
    this.getActivityScheduledTimingForm(index).get("repeat.period").setValue(frequency.period);
    this.getActivityScheduledTimingForm(index).get("repeat.periodUnits").setValue(frequency.periodUnits);
  }
  /**
   * Save the careplan template (If the json is valid)
   */
  private saveCareplanTemplate(): void {
    if (this.isValidJson) {
      if (this.currentView === VIEWS.VISUAL) {
        this.careplanEditorService.save();
      } else {
        this.careplanEditorService.currentCareplanTemplate = this.careplanTemplate;
        this.careplanEditorService.save(false, false, true);
        this.initHealthcareService();
      }
    }
  }

  /** Set the status value based on the checkbox state  */
  public statusChange(control: UntypedFormControl, checked: boolean): void {
    control.setValue(checked ? Careplan.ACTIVITY_STATUS_ACTIVE : Careplan.ACTIVITY_STATUS_INACTIVE);
  }

  public updateActivityCode(value: string, activityFG: UntypedFormGroup): void {
    const codeControl = activityFG.get("detail.code.code");
    if (value === ACTIVITY_CATEGORY.TELECONSULTATION) {
      codeControl.setValue(FHIRHelper.SNOMED_TELECONSULTATION);
      codeControl.disable();
    } else {
      if (codeControl.value === FHIRHelper.SNOMED_TELECONSULTATION) {
        codeControl.setValue("");
      }
      codeControl.enable();
    }
  }

  public onIsCycleChange($event: MatCheckboxChange, activity: UntypedFormGroup): void {
    const controlpaths = [
      "detail.scheduledTiming.repeat.frequency",
      "detail.scheduledTiming.repeat.period",
      "detail.scheduledTiming.repeat.periodUnits",
    ];
    this.careplanTemplateFormHelper.toggleRequiredValidators($event.checked, activity, controlpaths);
  }

  public comparePeriodUnitsFn(o1: never, o2: never): boolean {
    return Tools.standardizePeriodUnit(o1) === Tools.standardizePeriodUnit(o2);
  }

  public togglePeriod($event: MatSlideToggleChange) {
    const careplanForm = this.careplanEditorService.careplanTemplateForm;

    if ($event.checked) {
      careplanForm.setControl("period", this.careplanTemplateFormHelper.createPeriodFormGroup(true));
    } else {
      careplanForm.removeControl("period");
    }
  }
}
