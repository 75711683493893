import { Injectable } from "@angular/core";
import { PreferenceApiService } from "./api/preference-api.service";
import { Preference, PreferenceContext, GlobalParameter } from "../models/preference.interface";
import { map, tap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class PreferenceService {
  private listCache: Record<string, any> = [];

  constructor(private preferenceApi: PreferenceApiService, private sessionService: SessionService) {}

  public list(context: string): Observable<null | any> {
    if (this.listCache[context] !== undefined) {
      return of(this.listCache[context]);
    }

    return this.preferenceApi.list(context).pipe(
      map((parameters) => {
        if (!parameters) {
          return null;
        }
        return parameters;
      }),
      tap((parameters) => {
        this.listCache[context] = parameters;
      })
    );
  }

  public update(preference: Preference) {
    this.listCache[preference.context] = undefined;

    if (preference.context === PreferenceContext.GLOBAL) {
      this.sessionService.globalPref = preference.parameters;
    }
    return this.preferenceApi.update(preference);
  }

  public getGlobal(): Observable<null | GlobalParameter> {
    const globalParameter = this.sessionService.globalPref;
    if (!globalParameter) {
      return this.preferenceApi.list(PreferenceContext.GLOBAL).pipe(
        tap((parameters: GlobalParameter) => {
          this.sessionService.globalPref = parameters;
        })
      );
    } else {
      return this.sessionService.getObservablePref();
    }
  }

  public getAutoRefreshWidget() {
    return 60000;
  }

  public getAutoSaveInterval() {
    return 10000;
  }
}
