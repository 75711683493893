import { Pipe, PipeTransform } from "@angular/core";
import { ObservationHelper } from "src/app/helpers/observationHelper";
import { Tools } from "../../../helpers/tools";
import { IObservationDefinition, ObservationResume } from "../../../models/observations.interface";
import { SessionService } from "../../../providers/session.service";

@Pipe({ name: "translateObservationName" })
export class TranslateObservationNamePipe implements PipeTransform {
  constructor(private sessionService: SessionService) {}
  /**
   * Checks the observations' definitions for the name of an observation and returns it.
   * If there isn't a corresponding definition for this observation, it returns the display
   * of the observation (usually it is the question in the language it was presented to the user).
   * @param element (ObservationResume) the observation
   * @returns the translated name of the observation
   */
  transform(element: ObservationResume, allDefinitions: IObservationDefinition[]): string {
    const ref = ObservationHelper.ignoreSuffix(element.reference);
    const def = allDefinitions.find((d) => d.loinc === element.parentReference);
    if (!def) {
      return element.display;
    }
    const componentDef = def.components.find((d) => d.loinc === element.reference);
    const componentDefWithoutSuffix = def.components.find((d) => ObservationHelper.ignoreSuffix(d.loinc) === ref);
    if (componentDef) {
      // handle the 2 cases
      if (componentDef.shortnameTranslation) {
        return Tools.getTranslation(componentDef.shortnameTranslation, this.sessionService.userLang, componentDef.loinc);
      }

      return Tools.getTranslation(componentDef.nameTranslation, this.sessionService.userLang, componentDef.loinc);
    }

    if (componentDefWithoutSuffix) {
      if (componentDefWithoutSuffix.shortnameTranslation) {
        return Tools.getTranslation(
          componentDefWithoutSuffix.shortnameTranslation,
          this.sessionService.userLang,
          componentDefWithoutSuffix.loinc
        );
      }
      return Tools.getTranslation(componentDefWithoutSuffix.nameTranslation, this.sessionService.userLang, componentDefWithoutSuffix.loinc);
    }
    // if no definition was found, we simply return the display
    return element.display;
  }
}
