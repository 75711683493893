<div class="preferenceDialog">
  <div class="buttons-bar">
    <button
      mat-icon-button
      class="negative-margin align-middle color-button"
      aria-label="icon button with a ? icon"
      (click)="showHelp ? dismissHelp() : onShowHelp()"
    >
      <mat-icon>help_outline</mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      class="negative-margin align-middle"
      (click)="save()"
      matTooltip="{{ 'common.save' | translate }}"
      [disabled]="!pageLoaded"
    >
      <mat-icon>save</mat-icon>
    </button>
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close matTooltip="{{ 'btn.close' | translate }}">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="loading" *ngIf="!pageLoaded || saveInProgress">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
    {{ (saveInProgress ? "common.saveInProgress" : "common.loading") | translate }}
  </div>
  <mat-dialog-content *ngIf="pageLoaded && !saveInProgress">
    <ui-alert #helpAlert [options]="{ type: 'info', closable: true, fadeOut: true }" (dismiss)="showHelp = false" *ngIf="showHelp">
      {{ "globalHelp.preferenceHelpP1" | translate }} <br />
      {{ "globalHelp.preferenceHelpP2" | translate }} <br />
      {{ "globalHelp.preferenceHelpP3" | translate }}
    </ui-alert>
    <form [formGroup]="preferenceForm">
      <p *ngIf="errorMessage" class="error">
        {{ "api.errors." + errorMessage | translate }}
      </p>
      <div class="mat-tab-content">
        <div class="mat-tab-item">
          <mat-label class="fs-16">{{ "page.preference.tab-general" | translate }} : </mat-label>
          <p>
            <mat-form-field>
              <mat-label>{{ "page.preference.defaultLandingPage" | translate }}</mat-label>
              <mat-select formControlName="defaultLandingPage">
                <mat-option *ngFor="let page of landingPages" [value]="page">
                  {{ "menu." + page | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="isFieldValid('defaultLandingPage')">
                {{ "page.preference.defaultLandingPageRequired" | translate }}</mat-error
              >
            </mat-form-field>
          </p>
          <p>
            <mat-form-field>
              <mat-label>{{ "page.preference.preferredLanguage" | translate }}</mat-label>
              <mat-select formControlName="preferredLanguage">
                <mat-option *ngFor="let language of availableLangs" [value]="language.term">
                  {{ language | getTranslation : language.term }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="isFieldValid('preferredLanguage')">
                {{ "page.preference.preferredLanguageRequired" | translate }}</mat-error
              >
            </mat-form-field>
          </p>
          <p>
            <mat-form-field>
              <mat-label>{{ "page.preference.preferredOrg" | translate }}</mat-label>
              <mat-select formControlName="preferredOrg" [compareWith]="compareReference">
                <mat-option *ngIf="availableOrgs.length >= 2" [value]="sessionService.allsReference">
                  {{ "page.preference.all" | translate }}
                </mat-option>
                <mat-option *ngFor="let org of availableOrgs" [value]="org">
                  {{ org.display }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="isFieldValid('preferredOrg')"> {{ "page.preference.preferredOrgRequired" | translate }}</mat-error>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field>
              <mat-label>{{ "page.preference.preferredService" | translate }}</mat-label>
              <mat-select formControlName="preferredService" [compareWith]="compareReference">
                <mat-option *ngIf="availableServices.length >= 2" [value]="sessionService.allsReference">
                  {{ "page.preference.all" | translate }}
                </mat-option>
                <mat-option *ngFor="let service of availableServices" [value]="service">
                  {{ service.display }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="isFieldValid('preferredService')"> {{ "page.preference.preferredServiceRequired" | translate }}</mat-error>
            </mat-form-field>
          </p>
          <p>
            <mat-checkbox
              formControlName="usePreferredLanguageWhenConflict"
              [indeterminate]="isFieldIndeterminate('usePreferredLanguageWhenConflict')"
            >
              {{ "page.preference.alwaysUseMyPreferredLanguage" | translate }}
            </mat-checkbox>
          </p>
          <p>
            <mat-checkbox formControlName="go2PatientAfterInscription" [indeterminate]="isFieldIndeterminate('go2PatientAfterInscription')">
              {{ "page.preference.redirectToPatientPage" | translate }}
            </mat-checkbox>
          </p>
        </div>
        <div class="mat-tab-item">
          <mat-label class="fs-16">{{ "page.preference.connectionOptions" | translate }} : </mat-label>
          <br />
          <p class="spacedItem" style="margin-top: 10px">
            <mat-label>{{ "page.patientlist.resetPassword" | translate }} : </mat-label>
            <button mat-stroked-button color="primary" type="button" (click)="displayConfirmResetModal()">
              <mat-icon>lock</mat-icon>
              {{ "page.preference.resetPassword" | translate }}
            </button>
          </p>
          <p *ngIf="country === FRANCE" class="spacedItem">
            <mat-label>{{ "page.preference.togglePSC" | translate }} : </mat-label>
            <mat-slide-toggle formControlName="togglePSC"> </mat-slide-toggle>
          </p>
          <p *ngIf="country !== FRANCE" class="spacedItem">
            <mat-label>{{ "page.preference.toggleEHealth" | translate }} : </mat-label>
            <mat-slide-toggle formControlName="toggleEHealth"> </mat-slide-toggle>
          </p>
          <br />
          <app-mfa-setup></app-mfa-setup>
        </div>
        <div class="mat-tab-item">
          <mat-label class="fs-16">{{ "page.preference.tab-dashboard" | translate }} : </mat-label>
          <br />
          <mat-label>{{ "page.widgetSetting.nbColumn" | translate }} : </mat-label>
          <mat-form-field class="width-50" appearance="none">
            <mat-select formControlName="nbColumn">
              <mat-option *ngFor="let i of [1, 2, 3]" [value]="i">
                {{ i }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="isFieldValid('nbColumn')">
              {{ "page.widgetSetting.nbColumnRequired" | translate }}
            </mat-error>
          </mat-form-field>
          <br />
          <mat-label>{{ "page.widgetSetting.widgetList" | translate }} : </mat-label>
          <div *ngFor="let widgetName of availableWidgets">
            <mat-checkbox class="ml-125" [checked]="isEnabled(widgetName)" (change)="changeWidget($event.checked, widgetName)">
              {{ "widget." + widgetName | translate }}
            </mat-checkbox>
          </div>
          <br />
          <mat-label
            >{{ "page.preference.preferredPatientInactivity.title" | translate }}
            :
          </mat-label>
          <mat-radio-group class="radio-button-list" formControlName="inactivityDuration">
            <mat-radio-button class="ml-125" value="1 day">
              {{ "page.preference.preferredPatientInactivity.oneDay" | translate }}
            </mat-radio-button>
            <mat-radio-button class="ml-125" value="1 week">
              {{ "page.preference.preferredPatientInactivity.oneWeek" | translate }}
            </mat-radio-button>
            <mat-radio-button class="ml-125" value="1 month">
              {{ "page.preference.preferredPatientInactivity.oneMonth" | translate }}
            </mat-radio-button>
            <mat-radio-button class="ml-125" value="3 months">
              {{ "page.preference.preferredPatientInactivity.threeMonth" | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="mat-tab-item">
          <mat-label class="fs-16">{{ "page.preference.patientPage" | translate }} : </mat-label>
          <p>
            <mat-checkbox formControlName="keepLayoutFromPatientPage" [indeterminate]="isFieldIndeterminate('keepLayoutFromPatientPage')">
              {{ "page.preference.keepLayoutFromPatientPage" | translate }}
            </mat-checkbox>
          </p>
          <p>
            <mat-checkbox formControlName="showAllWidgetWhenDetails" [indeterminate]="isFieldIndeterminate('showAllWidgetWhenDetails')">
              {{ "page.preference.showAllWidgetWhenDetails" | translate }}
            </mat-checkbox>
          </p>
          <br />
          <mat-label>{{ "page.preference.tileList" | translate }} : </mat-label>
          <div cdkDropList (cdkDropListDropped)="tileDropped($event)">
            <div *ngFor="let tileName of availableTile; let index = index; let isFirst = first; let isLast = last" class="tileCheckboxes">
              <div class="arrowsContainer">
                <mat-icon
                  *ngIf="!isFirst"
                  button
                  class="arrow arrowUp"
                  aria-hidden="false"
                  aria-label="Arrow icon"
                  (click)="handleTileArrowClicked(true, index)"
                  >arrow_drop_up
                </mat-icon>
                <mat-icon
                  *ngIf="!isLast"
                  button
                  class="arrow arrowDown"
                  aria-hidden="false"
                  aria-label="Arrow icon"
                  (click)="handleTileArrowClicked(false, index)"
                >
                  arrow_drop_down
                </mat-icon>
              </div>

              <mat-checkbox cdkDrag [checked]="isTileEnabled(tileName)" (change)="changeTile($event.checked, tileName)">
                {{ "tile." + tileName | translate }}
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>
