import { Pipe, PipeTransform } from "@angular/core";
import { ILinkedRuleAlert, IObservation, IObservationWithoutComponents } from "src/app/models/observations.interface";

@Pipe({
  name: "getAlerts",
})
export class GetAlertsPipe implements PipeTransform {
  /**
   * @returns Return ILinkedRuleAlert[] generated by the last observation value encoded by patient for a specific observation (filters alerts generated by modified data)
   */
  transform(observation: IObservation | IObservationWithoutComponents): ILinkedRuleAlert[] {
    if (!observation || !observation.linkedRuleAlerts || !observation.linkedRuleAlerts.length) {
      return [];
    }
    return observation.linkedRuleAlerts.filter((lra) => !lra.originalComponents || !lra.originalComponents.length);
  }
}
