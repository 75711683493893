import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { skipWhile, takeUntil, tap } from "rxjs/operators";
import { CareplanEditorService } from "src/app/careplan-editor/domain/careplan-editor.service";
import { FileLogger } from "src/app/helpers/fileLogger";
import { ICareplan } from "src/app/models/careplans.interface";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { LanguagesService } from "src/app/providers/languages.service";

@Component({
  selector: "app-careplan-jsonview",
  templateUrl: "./careplan-jsonview.component.html",
  styleUrls: ["./careplan-jsonview.component.scss"],
})
export class CareplanJSONViewComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public careplanTemplate: ICareplan;
  private isValidJson = true;
  private onDestroy$ = new Subject<void>();

  get jsonCT(): string {
    return JSON.stringify(this.careplanTemplate, null, 2);
  }

  set jsonCT(v: string) {
    try {
      this.isValidJson = true;
      this.careplanTemplate = JSON.parse(v);
    } catch (e) {
      // @TODO : CMATE-5847 : Create validator for JSON field
      FileLogger.error("CareplanJSONViewComponent", "error occored while you were typing the JSON");
      this.isValidJson = false;
    }
  }
  constructor(
    public careplanEditorService: CareplanEditorService,
    private languagesService: LanguagesService,
    private healthcareService: HealthcareserviceService
  ) {}

  ngOnInit(): void {
    if (this.careplanEditorService.currentCareplanTemplate) {
      this.careplanTemplate = this.careplanEditorService.currentCareplanTemplate;
      this.isLoading = false;
    } else {
      this.careplanEditorService.careplanTemplateReady$
        .pipe(
          skipWhile((value) => value === false),
          takeUntil(this.onDestroy$),
          tap((_ready) => {
            this.careplanTemplate = this.careplanEditorService.currentCareplanTemplate;
            this.isLoading = false;
          })
        )
        .subscribe();
    }
  }
  ngOnDestroy(): void {
    // save the careplanTemplate
    this.saveCareplanTemplate();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  /**
   * Save the careplan template (If the json is valid)
   */
  private saveCareplanTemplate() {
    if (this.isValidJson) {
      this.careplanEditorService.currentCareplanTemplate = this.careplanTemplate;
      this.careplanEditorService.save(false, false, true);
      this.initHealthcareService();
    }
  }
  /**
   * Get all available services of the user and setup the list for the form
   */
  private initHealthcareService(): void {
    this.healthcareService
      .watchAvailableServices()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((services) => {
        services.map((s) => {
          const isChecked = this.careplanTemplate.author.findIndex((a) => a.reference === s.identifier[0].value) !== -1;
          const serviceForm = this.careplanEditorService.careplanTemplateForm.get("services") as UntypedFormArray;
          if (isChecked && serviceForm.length < 0) {
            serviceForm.push(
              new UntypedFormGroup({
                reference: new UntypedFormControl(s.identifier[0].value),
                display: new UntypedFormControl(s.identifier[0].label),
              })
            );
          }
        });
      });
  }
}
