import { IQuantity } from "./careplans.interface";
import { IVitalProfileDefinition } from "./vitalProfileDefinition.interface";
import { ITranslation } from "./translation.interface";
import { Tools } from "../helpers/tools";
import { SessionService } from "../providers/session.service";
export class Quantity {
  public code: string;
  public value: number;
  public valueArray: number[][];
  public system: string;
  public unit: string;
  public isHidden?: boolean;
  private sessionService: SessionService;

  /**
   * Use of Itranslation for deal with Itranslation pipes
   * @param data the quantity
   * @param name the translation of the definition to work with
   * @param unit the translation of the definition work with
   */

  constructor(data: IQuantity, sessionService: SessionService, public def?: IVitalProfileDefinition) {
    this.code = data.code;
    this.value = data.value;
    this.valueArray = data.valueArray;
    this.system = data.system;
    this.unit = data.unit;
    this.isHidden = def?.hiddenForMobile;
    this.sessionService = sessionService;
  }

  public translatedName(): string {
    const name: ITranslation = this.def?.nameDashboard ? this.def?.nameDashboard : this.def?.nameMobile ? this.def.nameMobile : null;
    if (name) {
      return Tools.getTranslation(name, this.sessionService.userLang, "");
    }
    return "";
  }

  public get valueIsValid(): boolean {
    if (isNaN(this.value)) {
      return false;
    }

    return true;
  }

  // At this step, the min / max values is already handled by form
  public castValue2NbrAndChangeIt(v: number): void {
    this.value = v;
    this.value = this.valueIsValid ? Number(this.value) : 0;
  }

  public get interface(): IQuantity {
    return {
      code: this.code,
      value: this.value,
      valueArray: this.valueArray,
      system: this.system,
      unit: this.unit,
    };
  }
}
