import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-choose-insurance-dialog",
  templateUrl: "./choose-insurance-dialog.component.html",
  styleUrls: ["./choose-insurance-dialog.component.scss"],
})
export class ChooseInsuranceDialogComponent implements OnInit {
  public needChange = false;
  public oldIns: string;
  public newIns: string;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChooseInsuranceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.oldIns = this.data.oldIns;
    this.newIns = this.data.newIns;
  }

  public validate() {
    this.dialogRef.close({
      needChange: this.needChange,
    });
  }

  public closeAll() {
    this.dialog.closeAll();
  }
}
