import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { Tools } from "src/app/helpers/tools";
import { Healthcareservice } from "src/app/models/healthcareservice.model";
import { Contained } from "src/app/models/questionnaire.interface";
import { IUseContext } from "src/app/models/sharedInterfaces";
import { Reference } from "src/app/models/sharedModels.model";

@Component({
  selector: "app-value-set-dialog",
  templateUrl: "./value-set-dialog.component.html",
  styleUrls: ["./value-set-dialog.component.scss"],
})
export class ValueSetDialogComponent implements OnInit, OnDestroy {
  public valueSetForm = new UntypedFormGroup({
    name: new UntypedFormControl("", Validators.required),
  });
  get name(): UntypedFormControl {
    return this.valueSetForm.get("name") as UntypedFormControl;
  }
  public newUseContexts: IUseContext[] = [];
  private onDestroy$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<ValueSetDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      valueSet: Contained;
      services: Healthcareservice[];
      orgsRefs: Reference[];
      globalPermission: boolean;
      isCreate: boolean;
    }
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.valueSetForm.patchValue({ name: this.data.valueSet.name });
    this.newUseContexts = this.data.valueSet.useContext ? Tools.clone(this.data.valueSet.useContext) : [];
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public onCancelClick(): void {
    this.valueSetForm.reset();
    this.dialogRef.close(null);
  }

  public onSaveClick(): void {
    if (this.valueSetForm.invalid) {
      this.valueSetForm.markAllAsTouched();
      return;
    }
    const newValueSet: Contained = JSON.parse(JSON.stringify(this.data.valueSet));
    newValueSet.useContext = this.newUseContexts;
    newValueSet.name = this.name.value;
    if (this.data.isCreate) {
      delete newValueSet.idSet;
      delete newValueSet.id;
    }
    this.dialogRef.close(newValueSet);
  }
}
