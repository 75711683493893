import { Pipe, PipeTransform } from "@angular/core";
import { ICareplan } from "../models/careplans.interface";

@Pipe({ name: "activityLinkedToQuestionnaire" })
export class ActivityLinkedToQuestionnaire implements PipeTransform {
  transform(cp: ICareplan, id?: string): string {
    if (cp) {
      let activityList = "";
      cp.activity.forEach((a) => {
        a.actionResulting.forEach((ar) => {
          if (ar.detail?.reference?.reference === id || ar.detail?.reference?.reference === "") {
            activityList += a.reference.display + ", ";
          }
        });
      });
      return activityList?.slice(0, activityList?.length - 2);
    } else {
      return "";
    }
  }
}
