import { Observable } from "rxjs";
import { MatTableDataSourceBackendExtended } from "src/app/helpers/datasource-backend";
import { DataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { IDeleteRequest } from "src/app/models/delete-request.interface";
import { DeleteRequestService } from "src/app/providers/deleteRequest.service";

export class DeleteRequestDataSource extends MatTableDataSourceBackendExtended<IDeleteRequest> {
  constructor(private deleteRequestService: DeleteRequestService) {
    super();
  }

  public data(params: DataSourceBackendParam): Observable<IDeleteRequest[]> {
    return this.deleteRequestService.list(params);
  }
}
