<div class="loading" *ngIf="loading">
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
  {{ "common.loading" | translate }}
</div>

<div class="quizListPage" *ngIf="!loading">
  <div class="toolbar">
    <div class="left">
      <!-- GLOBAL SEARCH -->
      <mat-form-field>
        <mat-label><span class="fa fa-search mr-5"></span>{{ "itemFilter.search" | translate }}</mat-label>
        <input type="search" matInput #searchInput autocomplete="off" />
        <button
          type="button"
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="searchInput.value = ''"
          [style.visibility]="searchInput.value ? 'visible' : 'hidden'"
          #searchClearBtn
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="spacer"></div>
    <button mat-flat-button color="warn" *ngIf="isFiltered" (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
      <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
    </button>
    <button mat-stroked-button *ngIf="!isFiltered" (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
      <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
    </button>
    <div class="right">
      <button
        mat-stroked-button
        class="saveButton"
        (click)="createSpecificQuestionnaire()"
        *ngIf="
          ('dashboard/questionnaire' | isAuthorized : 'POST' | async) === true && ('/questionnaireEditor' | isAuthorized | async) === true
        "
      >
        <mat-icon aria-hidden="false" aria-label="Add icon">add</mat-icon>
        {{ "page." + translationKey + ".add" | translate }}
      </button>
    </div>
  </div>

  <app-filters-display [filters]="filters" (removeFilter)="applyFilter($event)"> </app-filters-display>

  <mat-card class="mat-elevation-z0 pt-0">
    <mat-table class="mat-elevation-z1" [dataSource]="dataSource" matSort matSortActive="title" matSortDirection="asc">
      <!-- Quiz/Consent title column -->
      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "page." + translationKey + ".columns.title" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.title }}
        </mat-cell>
      </ng-container>

      <!-- Quiz/Consent language column -->
      <ng-container matColumnDef="language">
        <mat-header-cell *matHeaderCellDef>
          {{ "page." + translationKey + ".columns.language" | translate }}
          <app-item-filter
            fieldName="{{ 'page.' + translationKey + '.columns.language' | translate }}"
            propertyName="language"
            [dataType]="dataTypeChoice"
            [data]="dataSource.data"
            [defaultValue]="getFilter('language')"
            [translateFieldKey]="'page.' + translationKey + '.columns.language'"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">
          {{ row.language }}
        </mat-cell>
      </ng-container>

      <!-- Quiz/Consent linked knowledge title -->
      <ng-container matColumnDef="knowledgeRef.display">
        <mat-header-cell *matHeaderCellDef>
          {{ "page." + translationKey + ".columns.knowledgeTitle" | translate }}
        </mat-header-cell>
        <mat-cell class="duration-cell" *matCellDef="let row">
          {{ row.knowledgeRef ? row.knowledgeRef.display : "" }}
        </mat-cell>
      </ng-container>

      <!-- Quiz/Consent linked media title -->
      <ng-container matColumnDef="mediaLabel">
        <mat-header-cell *matHeaderCellDef>
          {{ "page." + translationKey + ".columns.mediaTitle" | translate }}
        </mat-header-cell>
        <mat-cell class="duration-cell" *matCellDef="let row">
          {{ row.mediaLabel ? row.mediaLabel : "" }}
        </mat-cell>
      </ng-container>

      <!-- Quiz/Consent date -->
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef>
          {{ "table.date" | translate }}
        </mat-header-cell>
        <mat-cell class="duration-cell" *matCellDef="let row">
          {{ row.date | date : "dd/MM/yyyy HH:mm" }}
          <button mat-icon-button aria-label="icon button with a loupe icon" (click)="showQuizVersion(row)">
            <mat-icon class="custom-icon-small">search</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <!-- Quiz/Consent draft -->
      <ng-container matColumnDef="draft">
        <mat-header-cell *matHeaderCellDef>
          {{ "status.draft" | translate }}
        </mat-header-cell>
        <mat-cell class="duration-cell" *matCellDef="let row">
          {{ "page.fhirQuestionnaireList." + row.hasDraft | translate }}
          <button *ngIf="row.hasDraft" mat-icon-button aria-label="icon button with a loupe icon" (click)="showQuizVersion(row, true)">
            <mat-icon class="custom-icon-small">search</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>{{ "table.action" | translate }}</mat-header-cell>
        <mat-cell class="duration-cell" mat-cell *matCellDef="let row">
          <div
            class="editButton"
            [matTooltip]="
              row.useContext[0].accessLevel < AccessLevel.WRITE
                ? ('common.notAuthorized' | translate)
                : ('page.questionnaireEditor.edit' | translate)
            "
          >
            <button
              mat-stroked-button
              (click)="updateSpecificQuestionnaire(row)"
              [disabled]="row.useContext[0].accessLevel < AccessLevel.WRITE"
            >
              <mat-icon aria-hidden="false" aria-label="Edit icon"> edit </mat-icon>
            </button>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
    </mat-table>

    <!-- PAGANITOR MANAGEMENT - length = total number of element via a Resolver!!-->
    <mat-paginator
      class="mat-elevation-z1"
      pageIndex="0"
      [pageSizeOptions]="[10, 25, 50, 100]"
      [length]="quizCount"
      [pageSize]="currentPageSize"
    ></mat-paginator>
  </mat-card>
</div>
