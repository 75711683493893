import { Injectable, OnDestroy } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { BehaviorSubject, Observable, Subject, forkJoin, of } from "rxjs";
import { concatMap, first, takeUntil, tap } from "rxjs/operators";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { CareplanHelper } from "src/app/helpers/careplan-helper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { Careplan } from "src/app/models/careplan.model";
import {
  ACTION_TARGET,
  ICareplan,
  ICareplanKnowledgeInfos,
  ILink2Careplan,
  MainCareplanReference,
  VersioningStatus,
} from "src/app/models/careplans.interface";
import { KNOW_DOC_CATEGORY } from "src/app/models/knowledge.interface";
import { Link2Careplan } from "src/app/models/link2careplan.model";
import { Period, Reference } from "src/app/models/sharedModels.model";
import { ITranslation } from "src/app/models/translation.interface";
import { LanguagesApiService } from "src/app/providers/api/languages-api.service";
import { CareplansService } from "src/app/providers/careplans.service";
import { KnowledgeCriteriaService } from "src/app/providers/knowledge-criteria-service";
import { LanguagesService } from "src/app/providers/languages.service";
import { UserService } from "src/app/providers/user.service";
import uuid from "uuid-random";
import { CareplanEditorApiService } from "../application/careplan-editor-api.service";
import { AVAILABLE_ACTIONS } from "../data/careplan-editor.enum";
import { IlinkableObsItem, IlinkableQuestionnaireItem } from "./IlinkableItem.interface";
import { CareplanTemplateFormHelper } from "./careplan-template-form-helper";

@Injectable({
  providedIn: "root",
})
export class CareplanEditorService implements OnDestroy {
  /** Holds the currently selected care plan template.*/
  public currentCareplanTemplate: ICareplan;
  /** Observable that signals when the careplan template is ready. */
  public careplanTemplateReady$ = new BehaviorSubject<boolean>(false);
  /** Observable that signals when the link to careplan is ready. */
  public link2CareplanReady$ = new Subject<boolean>();
  /** Form group for managing link2Careplan. */
  public link2CareplanForm: UntypedFormGroup;

  public careplanTemplateForm: UntypedFormGroup;
  public lastSaveDate: number;
  public availableLangs: ITranslation[];
  private onDestroy$ = new Subject<void>();

  constructor(
    private careplanEditorApi: CareplanEditorApiService,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private careplanTemplateFormHelper: CareplanTemplateFormHelper,
    private languageApiService: LanguagesApiService,
    private languagesService: LanguagesService,
    private router: Router,
    private careplanService: CareplansService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private knowledgeCriteriaService: KnowledgeCriteriaService
  ) {
    this.languagesService
      .list()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((languages) => {
        this.availableLangs = languages;
      });
    this.initLink2CareplanForm();
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Retrieves a care plan template.
   * @param careplanRef - Reference to the care plan.
   * @param lang - Language code for localization. Defaults to "fr".
   * @returns Observable emitting the careplan template.
   */
  public getCareplanTemplate(
    careplanRef: string,
    withDraft: boolean,
    publicationDate?: string
  ): Observable<{
    published: ICareplan;
    draft: ICareplan;
  }> {
    const routeName = this.careplanEditorApi.readRoutes[0];

    return this.userService.isAuthorized(routeName, "GET").pipe(
      first(),
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("CareplanEditorService", "User does not have access to: GET " + routeName);
          return of() as Observable<{
            published: ICareplan;
            draft: ICareplan;
          }>;
        }
        return this.careplanEditorApi.getCareplanTemplate(careplanRef, withDraft, publicationDate) as Observable<{
          published: ICareplan;
          draft: ICareplan;
        }>;
      })
    );
  }

  /**
   * Signals if the careplan template is ready.
   * @param value - Value indicating if the care plan template is ready.
   */
  public setCareplanTemplateReady(value: boolean): void {
    this.careplanTemplateReady$.next(value);
  }

  /**
   * Signals if the link2Careplan form is ready.
   */
  public setLink2CareplanReady(value: boolean): void {
    this.link2CareplanReady$.next(value);
  }

  /**
   * Creates an empty careplan template object.
   * @param services - List of services associated with the care plan.
   * @returns Created care plan template object.
   */
  public createCareplanTemplateObj(services: Reference[]): ICareplan {
    const careplanRef = uuid();
    return new Careplan({
      identifier: [{ use: "usual", system: "http://comunicare.io", value: "" }],
      support: [new Reference({ display: MainCareplanReference, reference: careplanRef })],
      status: "active",
      period: new Period(),
      description: "no-name",
      resourceType: "Careplan",
      participant: [new Reference()],
      activity: [],
      category: [
        {
          coding: [{ system: FHIRHelper.SYSTEM_SNOMED, display: careplanRef, code: careplanRef }],
          text: careplanRef,
        },
      ],
      subject: new Reference(),
      author: services,
      versioningStatus: VersioningStatus.DRAFT,
    });
  }

  public initCareplanTemplateForm(careplanTemplate: ICareplan): void {
    this.careplanTemplateForm = this.careplanTemplateFormHelper.createCareplanTemplateFormGroup(careplanTemplate);
  }

  /**
   * Creates an empty link2Careplan associated with a careplan Template
   * @param careplanRef
   * @returns Created empty link2Careplan object.
   */
  public createLink2CareplanObj(careplanRef: string): ILink2Careplan {
    return new Link2Careplan({
      careplan: careplanRef,
      questionnaires: [],
      observations: [],
      knowledges: [],
      vitalSignsDefinitions: [],
      versioningStatus: VersioningStatus.DRAFT,
    });
  }

  /**
   * Creates a careplan template.
   * @param careplanTemplate - Care plan template to create.
   * @returns Observable emitting the created care plan template.
   */
  public createCareplanTemplate(careplanTemplate: ICareplan): Observable<ICareplan> {
    return this.careplanEditorApi.createCareplanTemplate(careplanTemplate) as Observable<ICareplan>;
  }

  /**
   * Creates a link2Careplan.
   * @param careplanId - (support.reference)
   * @returns Observable emitting the created link2Careplan.
   */
  public createLink2Careplan(link2Careplan: ILink2Careplan): Observable<ILink2Careplan> {
    return this.careplanEditorApi.createLink2Careplan(link2Careplan) as Observable<ILink2Careplan>;
  }

  /**
   * Retrieves a link to a care plan.
   * @param careplanId - ID of the care plan.
   * @returns Observable emitting the link to the care plan.
   */
  public getLink2Careplan(
    careplanId: string,
    withDraft: boolean
  ): Observable<{
    published: ILink2Careplan;
    draft: ILink2Careplan;
  }> {
    const routeName = this.careplanEditorApi.readRoutes[1];

    return this.userService.isAuthorized(routeName, "GET").pipe(
      first(),
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("CareplanEditorService", "User does not have access to: GET " + routeName);
          return of() as Observable<{
            published: ILink2Careplan;
            draft: ILink2Careplan;
          }>;
        }
        return this.careplanEditorApi.getlink2Careplan(careplanId, withDraft) as Observable<{
          published: ILink2Careplan;
          draft: ILink2Careplan;
        }>;
      })
    );
  }

  /**
   * Initializes the form for managing the link to care plan.
   */
  public initLink2CareplanForm(): void {
    this.link2CareplanForm = this.fb.group({
      careplan: ["", Validators.required],
      questionnaires: [[]],
      observations: [[]],
      knowledges: [[]],
      vitalSignsDefinitions: [[]],
    });
  }

  /*
   * Retrieves all knowledges of a careplan (activity knowledges and knowledges linked to a careplan)
   */
  public getCareplanKnowledgeInfos(
    careplanId: string,
    draft: boolean,
    docCategories: KNOW_DOC_CATEGORY[],
    publicationDate?: string
  ): Observable<ICareplanKnowledgeInfos[]> {
    const routeName = this.careplanEditorApi.readRoutes[0];

    return this.userService.isAuthorized(routeName, "GET").pipe(
      first(),
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("CareplanEditorService", "User does not have access to: GET " + routeName);
          return of() as Observable<ICareplanKnowledgeInfos[]>;
        }
        return this.careplanEditorApi.careplanKnowledgeInfos(careplanId, draft, docCategories, publicationDate) as Observable<
          ICareplanKnowledgeInfos[]
        >;
      })
    );
  }

  /**
   * Initialize the traduction for each careplan form fields that can be a translated field
   * case 1 : the value is not a translate key (did not start with $TR$) --> we put the value on the form (and set isTranslated to false)
   * case 2 : the value is a translate key and the traduction is found in the database --> we put the translated value on the form for each languages
   * case 3 : the value is a translate key but the traduction is not found in the database --> we put the value (with the $TR$) on the form (and set isTranslated to false)
   */
  public initTraductions(availableLangs: string[]): Observable<ITranslation[]> {
    // terms will contains all value that can be translated
    // form will contains all form field corresponding to the term (traductionsForm will be the traductions formArray)
    const termsForms = this.getTranslationTermsAndForms();
    const terms = termsForms.terms;
    const form = termsForms.formGroups;
    const traductionsForm = termsForms.traductionsForms;

    // We now need to filter terms to keep only those that start with $TR$, for the others we can already set the form fields corresponding
    // we also need to format the terms : in the request we need to send a string with all terms separate by "," (and not an array)
    let termsFormatedFiltered = "";
    const indexAlreadySet = [];
    terms.forEach((t, i) => {
      if (Tools.isTranslateKey(t)) {
        termsFormatedFiltered += "," + t.substring(4);
      } else {
        form[i].get("isTranslated").setValue(false);

        (traductionsForm[i] as UntypedFormArray)
          .at(0)
          .get("translateKey")
          .setValue(t !== "no-name" ? t : "");
        (traductionsForm[i] as UntypedFormArray).at(0).get("lang").setValue("fr");

        indexAlreadySet.push(i);
      }
    });
    if (!termsFormatedFiltered) return of([]); // no traduction needed

    // request to get the traductions
    return this.languageApiService.getTranslations(termsFormatedFiltered.substring(1)).pipe(
      first(),
      tap((result) => {
        traductionsForm.forEach((t, i) => {
          // indexAlreadySet is all terms that was not a translateKey (started not with $TR$)
          if (!indexAlreadySet.includes(i)) {
            t.clear();
            // we try to find the translation result for the term of the traductionsForm
            const resultIndex = result?.findIndex((r) => r.term === terms[i].substring(4));
            if (resultIndex !== -1) {
              const trad = result[resultIndex];
              Object.keys(trad).forEach((key) => {
                if (availableLangs.includes(key)) {
                  t.push(
                    new UntypedFormGroup({
                      translateKey: new UntypedFormControl(trad[key], Validators.required),
                      lang: new UntypedFormControl(key),
                    })
                  );
                }
              });
            } else {
              // translation not found, we put the value without traduction to the form field corresponding
              form[i].get("isTranslated").setValue(false);
              t.push(
                new UntypedFormGroup({
                  translateKey: new UntypedFormControl(terms[i], Validators.required),
                  lang: new UntypedFormControl("fr"),
                })
              );
            }
          }
        });
        return result;
      })
    );
  }
  /**
   * @returns all terms, formgroups and traductionsForms for each careplan fields that can be a translated field
   */
  private getTranslationTermsAndForms(): { terms: string[]; formGroups: UntypedFormGroup[]; traductionsForms: UntypedFormArray[] } {
    // setup terms, form and traductionsForm for the careplan name (this.currentCareplanTemplate.description)
    const terms = [this.currentCareplanTemplate.description];
    const formGroups = [this.careplanTemplateForm.get("description") as UntypedFormGroup];
    const traductionsForms = [this.careplanTemplateForm.get("description").get("traductions") as UntypedFormArray];

    // setup form and traductionsForm for all activities of the careplan, we have two fields to translate : activity name and description
    const activities = this.careplanTemplateForm.get("activity") as UntypedFormArray;
    activities.controls.forEach((a) => {
      formGroups.push(...[a.get("reference").get("display") as UntypedFormGroup, a.get("detail").get("description") as UntypedFormGroup]);
      traductionsForms.push(
        ...[
          a.get("reference").get("display").get("traductions") as UntypedFormArray,
          a.get("detail").get("description").get("traductions") as UntypedFormArray,
        ]
      );
    });

    // setup terms for all activities of the careplan, we have two fields to translate : activity name and description
    this.currentCareplanTemplate.activity.forEach((a) => {
      if (Tools.isDefined(a.reference?.display)) {
        terms.push(a.reference.display);
      }
      if (Tools.isDefined(a.detail?.description)) {
        terms.push(a.detail.description);
      }
    });

    // setup form and traductionsForm for all addresses (=types) of the careplan, field to translate : display

    const addresses = this.careplanTemplateForm.get("addresses") as UntypedFormArray;
    addresses.controls.forEach((a) => {
      formGroups.push(...[a.get("display") as UntypedFormGroup]);
      traductionsForms.push(...[a.get("display").get("traductions") as UntypedFormArray]);
    });

    // setup terms for all addresses of the careplan,field to translate : display
    this.currentCareplanTemplate.addresses.forEach((a) => {
      if (Tools.isDefined(a.display)) {
        terms.push(a.display);
      }
    });

    return {
      terms,
      formGroups,
      traductionsForms,
    };
  }

  /**
   * For fields that can be a translated one: create, update, delete translations if necessary
   * @param availableLangs - all languages that the translations can be
   */
  public saveTraductions(availableLangs: string[]): Observable<unknown> {
    const termsForms = this.getTranslationTermsAndForms();
    const terms = termsForms.terms;
    const forms = termsForms.formGroups;
    const traductionsForms = termsForms.traductionsForms;

    const translationsToAdd: ITranslation[] = [];
    const translationsToEdit: ITranslation[] = [];
    let translationsToDelete = "";

    forms.forEach((form, i) => {
      if (form.get("isTranslated").value) {
        if (Tools.isTranslateKey(terms?.[i])) {
          // there was already a translation
          // create the formated translation and it add to translationsToEdit
          const translations = traductionsForms[i].value;
          const defaultLang = form.get("defaultLang").value;
          const newTranslation = this.formatTraductionFormToItranslation(translations, defaultLang, availableLangs, terms[i].substring(4));
          if (newTranslation) {
            translationsToEdit.push(newTranslation);

            // set the term to the form before saving the careplan
            traductionsForms[i]
              .at(0)
              .get("translateKey")
              .setValue("$TR$" + newTranslation.term);
          }
        } else {
          // there was no translation yet
          // create the formated translation and it add to translationsToAdd
          const translations = traductionsForms[i].value;
          const defaultLang = form.get("defaultLang").value;
          const newTranslation = this.formatTraductionFormToItranslation(translations, defaultLang, availableLangs);
          if (newTranslation) {
            translationsToAdd.push(newTranslation);

            // set the term to the form before saving the careplan
            traductionsForms[i]
              .at(0)
              .get("translateKey")
              .setValue("$TR$" + newTranslation.term);
          }
        }
      } else {
        if (Tools.isTranslateKey(terms[i]) && terms[i].startsWith("$TR$careplanEditor/")) {
          // delete translate
          translationsToDelete += terms[i];
        }
      }
    });

    const obs$: Observable<unknown>[] = [];
    if (translationsToDelete) {
      // delete the last ","
      translationsToDelete.substring(0, translationsToDelete.length - 1);
      obs$.push(this.languageApiService.deleteTranslations(translationsToDelete));
    }
    if (translationsToAdd?.length) {
      obs$.push(this.languageApiService.createTranslations(translationsToAdd));
    }
    if (translationsToEdit?.length) {
      obs$.push(this.languageApiService.updateTranslations(translationsToEdit));
    }
    if (obs$.length) {
      return forkJoin(obs$);
    }
    return of([]);
  }
  /**
   * @param translations - the array containing all translations
   * @param defaultLang - the lang for the languages not translated by the user in the form
   * @returns the ITranslation based on the form
   */
  private formatTraductionFormToItranslation(
    translations: [{ translateKey: string; lang: string }],
    defaultLang: string,
    availableLangs: string[],
    term?: string
  ): ITranslation {
    if (translations.findIndex((t) => Tools.isTranslateKey(t.translateKey)) !== -1) {
      // there is a $TR$ on the translation, don't save it
      return null;
    }
    const translationFormated: ITranslation = {
      term: term ? term : "careplanEditor/" + uuid(),
      fr: "",
      it: "",
      de: "",
      nl: "",
      en: "",
      pt: "",
    };
    availableLangs.forEach((lang) => {
      const translationFound = translations.find((t) => t.lang === lang);
      if (translationFound) {
        translationFormated[lang] = translationFound.translateKey;
      } else {
        // lang not set by user, we put the default lang
        translationFormated[lang] = translations.find((t) => t.lang === defaultLang).translateKey;
      }
    });
    return translationFormated;
  }

  /**
   * update a careplan template.
   * @param careplanTemplate - Care plan template to edit.
   * @returns Observable emitting the updated care plan template.
   */
  public updateCareplanTemplate(careplanTemplate: ICareplan): Observable<ICareplan> {
    return this.careplanEditorApi.updateCareplanTemplate(careplanTemplate) as Observable<ICareplan>;
  }

  /**
   * publish a careplan template.
   * @param careplanTemplateRef - Care plan template ref (support.reference) to publish.
   * @returns Observable emitting the published care plan template.
   */
  public publishCareplanTemplate(careplanTemplateRef: string): Observable<ICareplan> {
    return this.careplanEditorApi.publishCareplanTemplate(careplanTemplateRef) as Observable<ICareplan>;
  }

  /**
   * publish a link2Careplan.
   * @param careplanTemplateRef - Care plan template ref (support.reference).
   * @returns Observable emitting the published link2Careplan template.
   */
  public publishLink2CareplanTemplate(careplanTemplateRef: string): Observable<ILink2Careplan> {
    return this.careplanEditorApi.publishLink2CareplanTemplate(careplanTemplateRef) as Observable<ILink2Careplan>;
  }

  /**
   * publish the knowledgeCriteria.
   * @param careplanTemplateRef - Care plan template ref (support.reference).
   */
  public publishKnowledgeCriteria(careplanTemplateRef: string): Observable<unknown> {
    return this.careplanEditorApi.publishKnowledgeCriteria(careplanTemplateRef) as Observable<unknown>;
  }

  /**
   * Save the careplan template and all the translations, publish itif needed
   * @param availableLangs - all the langs availables, we need it for the translations
   * @param publish - indicate if we need to publish the careplan template or not
   * @param close - indicate if we close the editor after the saved (if publish is true, we close automatically)
   * @param isJson - indicate if we save via the json views, in this case there is no need to get value from the form, it will save the currentCareplanTemplate
   */
  public save(publish = false, close?: boolean, isJson = false, updateCriteria = false): void {
    const form = this.careplanTemplateForm;
    if (form.valid && this.currentCareplanTemplate.versioningStatus === VersioningStatus.DRAFT) {
      this.setCareplanTemplateReady(false);
      this.saveTraductions(this.availableLangs.map((l) => l.term))
        .pipe(
          first(),
          tap(() => {
            if (!isJson) {
              // set the currentCareplanTemplate with form values
              this.careplanTemplateFormHelper.updateCareplanTemplateWithForm(form, this.currentCareplanTemplate);
            }
            this.updateCareplanTemplate(this.currentCareplanTemplate)
              .pipe(
                first(),
                tap(() => {
                  if (publish) {
                    this.publish(updateCriteria);
                  } else {
                    if (close) {
                      this.router.navigate(["/careplansList"]);
                    } else {
                      // we stay in the editor so we need to reinitialize it with the currentCareplanTemplate saved
                      this.initCareplanTemplateForm(this.currentCareplanTemplate);
                      this.initTraductions(this.availableLangs.map((l) => l.term))
                        .pipe(
                          tap(() => {
                            this.setCareplanTemplateReady(true);
                            this.lastSaveDate = moment.now();
                          })
                        )
                        .subscribe();
                    }
                  }
                })
              )
              .subscribe();
          })
        )
        .subscribe();
    } else {
      this.careplanTemplateForm.markAllAsTouched();
      this.snackBar.open(this.translateService.instant("page.careplanEditor.error.invalidForm"), "ok", { duration: 5000 });
    }
  }

  private async publish(updateCriteria: boolean) {
    // Criterias of the published criteria before the current publish process.
    let previousCriteria;
    if (updateCriteria) {
      try {
        previousCriteria = (
          await this.knowledgeCriteriaService.list(null, null, this.currentCareplanTemplate.support[0].reference).toPromise()
        ).published;
      } catch (error) {
        FileLogger.error("publish()", "Can't get previous knowledge criteria");
      }
    }
    const ref = this.currentCareplanTemplate.support[0].reference;
    const publish$ = [this.publishCareplanTemplate(ref), this.publishLink2CareplanTemplate(ref), this.publishKnowledgeCriteria(ref)];
    forkJoin(publish$)
      .pipe(
        first(),
        tap(() => {
          this.router.navigate(["/careplansList"]);
        })
      )
      .subscribe(() => {
        if (updateCriteria) this.updateCareplanWithKnowledgeCriteria(previousCriteria);
      });
  }

  /**
   * Save careplan template without reloading the page and without saving translations.
   * Should be used to save the careplanTemplate from the form without jump screens.
   * Make sure to call setupQuestionnaires() or setupObservations() afterwards to have coherent data
   */
  silentSave(): Promise<ICareplan> {
    const form = this.careplanTemplateForm;
    if (form.valid && this.currentCareplanTemplate.versioningStatus === VersioningStatus.DRAFT) {
      this.careplanTemplateFormHelper.updateCareplanTemplateWithForm(form, this.currentCareplanTemplate);

      return this.saveTraductions(this.availableLangs.map((l) => l.term))
        .pipe(
          first(),
          concatMap(() => this.updateCareplanTemplate(this.currentCareplanTemplate).pipe(first()))
        )
        .toPromise();
    } else {
      this.careplanTemplateForm.markAllAsTouched();
      this.snackBar.open(this.translateService.instant("page.careplanEditor.error.invalidForm"), "ok", { duration: 5000 });
    }
  }

  public async update(
    $event: {
      item: IlinkableQuestionnaireItem | IlinkableObsItem;
      items: IlinkableQuestionnaireItem[] | IlinkableObsItem[];
      action: AVAILABLE_ACTIONS;
    },
    actionTarget: ACTION_TARGET
  ): Promise<void> {
    const { item, items, action } = $event;

    if (item.origin === "link2careplan") {
      if (action === AVAILABLE_ACTIONS.unlink) {
        await this.handleUnlinkFromCareplan(item, items, actionTarget);
      } else {
        await this.updateLink2Careplan(items, actionTarget);
      }
    } else if (item.origin === "activity") {
      const currentActivityFG = this.careplanTemplateFormHelper.getActivityFormGroupByReference(
        this.careplanTemplateForm,
        item.originReference.reference
      );

      const actionResulting: UntypedFormArray = currentActivityFG.get("actionResulting") as UntypedFormArray;

      if (action === AVAILABLE_ACTIONS.unlink) {
        await this.handleUnlinkFromActivity(actionResulting, item);
      } else {
        await this.handleLinkToActivity(actionResulting, item, actionTarget);
      }
    }
  }

  /**
   * Handles the linking of an item to an activity.
   */
  private async handleLinkToActivity(
    actionResulting: UntypedFormArray,
    item: IlinkableQuestionnaireItem | IlinkableObsItem,
    actionTarget: ACTION_TARGET
  ) {
    actionResulting.push(
      this.fb.control(
        CareplanHelper.newActionResulting(
          this.currentCareplanTemplate.support[0].reference,
          actionTarget === ACTION_TARGET.QUESTIONNAIRE
            ? (item as IlinkableQuestionnaireItem).subjectType
            : (item as IlinkableObsItem).reference,
          actionTarget === ACTION_TARGET.QUESTIONNAIRE
            ? (item as IlinkableQuestionnaireItem).identifier
            : (item as IlinkableObsItem).reference,
          actionTarget
        )
      )
    );
  }

  /**
   * Handles the unlinking of an item from an activity.
   */
  private async handleUnlinkFromActivity(actionResultings: UntypedFormArray, item: IlinkableQuestionnaireItem | IlinkableObsItem) {
    const foundIndex = actionResultings.value.findIndex((ar) => ar.detail?.reference?.reference === item.uniqueId);

    if (foundIndex !== -1) {
      actionResultings.removeAt(foundIndex);
    }
  }

  /**
   * Handles the unlinking of an item from the careplan.
   */
  public async handleUnlinkFromCareplan(
    item: IlinkableQuestionnaireItem | IlinkableObsItem,
    items: IlinkableQuestionnaireItem[] | IlinkableObsItem[],
    actionTarget: ACTION_TARGET
  ): Promise<void> {
    const actionResultings: UntypedFormArray = this.careplanTemplateForm.get("actionResulting") as UntypedFormArray;
    const foundIndex = actionResultings.value.findIndex((ar) => ar.detail.reference.reference === item.uniqueId);
    // if there is an action Resulting for that item we delete it
    if (foundIndex !== -1) {
      actionResultings.removeAt(foundIndex);
    }
    // we update the link2careplan in any case
    await this.updateLink2Careplan(items, actionTarget);
  }

  /**
   * Handles the linking AND the unlinking of an element to the careplan.
   * This add or remove the item to the link2careplan when an item is dropped.
   * The properties are saved separetly in a second time.
   */
  private async updateLink2Careplan(items: IlinkableQuestionnaireItem[] | IlinkableObsItem[], actionTarget: ACTION_TARGET) {
    if (actionTarget === ACTION_TARGET.QUESTIONNAIRE) {
      this.link2CareplanForm.patchValue({
        questionnaires: (items as IlinkableQuestionnaireItem[]).map((q: IlinkableQuestionnaireItem) => q.identifier),
      });
    } else if (actionTarget === ACTION_TARGET.OBSERVATION) {
      this.link2CareplanForm.patchValue({ observations: (items as IlinkableObsItem[]).map((q: IlinkableObsItem) => q.reference) });
    }

    try {
      await this.careplanService.updateLink2Careplan(this.link2CareplanForm.getRawValue()).toPromise();
    } catch (error) {
      FileLogger.error("careplan-editor service", "Cannot update link2careplan");
    }
  }

  /**
   * Reset careplan template and link2Careplan
   */
  public reset(): void {
    this.currentCareplanTemplate = undefined;
    this.careplanTemplateReady$.next(false);
    this.link2CareplanForm.reset();
  }

  public async updateCareplanWithKnowledgeCriteria(previousCriteria) {
    try {
      // Fetch the knowledge criteria for the careplan after current publication.
      const criterias = (
        await this.knowledgeCriteriaService.list(null, null, this.currentCareplanTemplate.support[0].reference).toPromise()
      ).published;

      // Get the list of criteria that have been removed since the last publication
      const deletedCriteria = previousCriteria.filter(
        (obj1) => !criterias.some((obj2) => obj1.knowledgeIdentifier.value === obj2.knowledgeIdentifier.value)
      );

      // variable to detect if one of the updates or more have failed
      let someUpdateFailed = false;
      for (const criteria of [...criterias, ...deletedCriteria]) {
        // Await each updateCareplansWithKnCriteria call before proceeding to the next one
        try {
          await this.knowledgeCriteriaService
            .updateCareplansWithKnCriteria(this.currentCareplanTemplate.support[0].reference, criteria.knowledgeIdentifier.value)
            .toPromise();
        } catch (error) {
          FileLogger.error("updateCareplanWithKnowledgeCriteria", "Cannot save criteria for knowledge " + criteria.knowledgeIdentifier);
          someUpdateFailed = true;
        }
      }
      if (someUpdateFailed) {
        // Failure
        this.snackBar.open(this.translateService.instant("api.errors.unknowerror"), undefined, {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      } else {
        // Success
        this.snackBar.open(this.translateService.instant("common.saveSuccess"), undefined, {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      }
    } catch (error) {
      // Handle any unexpected errors
      FileLogger.error("updateCareplanWithKnowledgeCriteria", "An unexpected error occurred: " + error.message);
      this.snackBar.open(this.translateService.instant("api.errors.unknowerror"), undefined, {
        duration: 3000,
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }
}
