import { Pipe, PipeTransform } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ACTIVITY_CATEGORY } from "src/app/models/sharedInterfaces";

@Pipe({ name: "isProcedureActivity", pure: false }) // Need to set pure to false, otherwise the pipe is not triggered every time it is needed, not recommended to do this
export class IsProcedureActivityPipe implements PipeTransform {
  /**
   * Return weither the category of the activity works like the 'procedure' category or not
   */
  transform(activityForm: UntypedFormGroup): boolean {
    return [
      ACTIVITY_CATEGORY.APPOINTMENT,
      ACTIVITY_CATEGORY.TELECONSULTATION,
      ACTIVITY_CATEGORY.ENCOUNTER,
      ACTIVITY_CATEGORY.PROCEDURE, // has been replaced by "ENCOUNTER" but might still be present in DB
    ].includes(activityForm.get("detail").get("category").value);
  }
}
