import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface Choice {
  value: string;
  checked: boolean;
}

@Component({
  selector: "app-item-filter-choice",
  templateUrl: "./choice-filter.component.html",
  styleUrls: ["./choice-filter.component.scss"],
})
export class ChoiceFilterComponent implements OnInit {
  public choices: Choice[] = [];

  public constructor(private readonly dialogRef: MatDialogRef<ChoiceFilterComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    if (this.data?.filter && this.data?.filter.data) {
      // data.choices is always refreshed at good time (but not data.filter.data)
      this.data.choices.forEach((element: string, i: number) => {
        // We check if new propertie(s) is present in data.choices when we switch to another group
        // And re-make the this.data.filter.data array
        // if we have new propertie(s) - we push it to this.data.filter.data for update it (and keep filters updated)
        if (this.data?.filter.data[i]?.value !== element) {
          this.choices.push({
            value: element,
            checked: false,
          });
        } else {
          // else, we push the actual propertie(s)
          this.choices.push(this.data?.filter.data[i]);
        }
      });

      // Finally, last check if we have doubles
      this.choices = [...new Set(this.choices)];
    } else {
      this.choices = this.data.choices.map((value: string) => ({
        value,
        checked: true,
      }));
    }
  }

  public apply(): void {
    this.dialogRef.close(this.choices);
  }

  public clear(): void {
    this.dialogRef.close();
  }
}
