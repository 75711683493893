import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import packageInfo from "../../../package.json";
import { HelpData, ReleaseNote } from "../helpers/helpData";
import { IVersion, PreferenceContext, ReleaseNoteParameter } from "../models/preference.interface";
import { PreferenceService } from "./preference.service";

@Injectable({
  providedIn: "root",
})
export class ReleaseNoteService {
  constructor(public helpData: HelpData, public preferenceService: PreferenceService) {}

  public getSlidesMissingReleaseNoteList(): Observable<{
    releaseNoteParameter: ReleaseNoteParameter;
    slides: ReleaseNote[];
  }> {
    return this.preferenceService.list(PreferenceContext.RELEASE_NOTE).pipe(
      concatMap((releaseNoteParameter: ReleaseNoteParameter) => {
        if (releaseNoteParameter) {
          const slides = this.missingReleaseNoteList(releaseNoteParameter?.lastReleaseNote);
          if (slides?.length) {
            return of({ releaseNoteParameter, slides });
          } else {
            return of({ releaseNoteParameter, slides: [] });
          }
        } else {
          return this.preferenceService
            .update({
              context: PreferenceContext.RELEASE_NOTE,
              parameters: {
                lastReleaseNote: packageInfo.version,
                alwaysShow: false,
              },
            })
            .pipe(map(() => ({ releaseNoteParameter: null, slides: [] })));
        }
      })
    );
  }

  private missingReleaseNoteList(previousVersionSeenByUser: string): ReleaseNote[] {
    const previousVersion = this.transformToNumber(previousVersionSeenByUser);
    return this.helpData.releaseNoteList.filter((slide) => this.isLastVersionLower(previousVersion, slide.releaseId));
  }

  private transformToNumber(givenVersion: string): IVersion {
    if (!givenVersion) {
      return {
        major: 0,
        minor: 0,
        patch: 0,
      };
    }
    const array = givenVersion.split(".");
    return {
      major: +array[0],
      minor: +array[1],
      patch: +array[2],
    };
  }

  private isLastVersionLower(previous: IVersion, current: IVersion) {
    if (previous.major > current.major) {
      return false;
    } else if (previous.major < current.major) {
      return true;
    } else {
      if (previous.minor > current.minor) {
        return false;
      } else if (previous.minor < current.minor) {
        return true;
      } else {
        if (previous.patch >= current.patch) {
          return false;
        } else {
          return true;
        }
      }
    }
  }
}
