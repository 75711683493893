<ng-container>
  <div class="buttons-bar">
    <button mat-icon-button color="warn" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div>
    <h2>
      {{ "dialog.patientAlreadyExists.chooseInsurance" | translate }}
    </h2>
  </div>

  <div>
    <mat-radio-group [(ngModel)]="needChange" style="display: flex; flex-direction: column">
      <mat-radio-button [value]="false">
        {{ oldIns }}
      </mat-radio-button>
      <mat-radio-button [value]="true" style="margin-top: 5px">
        {{ newIns }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <br />
  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="validate()">
      {{ "drugSchema.confirm" | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
