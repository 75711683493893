import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IJournalINSIEntry, INSIReturnCode, InsResponse } from "../models/ins.interface";
import { INSApiService } from "./api/ins-api.service";

@Injectable({
  providedIn: "root",
})
export class INSService {
  constructor(private api: INSApiService) {}

  public getINSIPatient(name: string, firstname: string, gender: string, birthDate: string, birthplace?: string): Promise<InsResponse> {
    return this.api
      .getINSIPatient(name, firstname, gender, birthDate, birthplace)
      .toPromise()
      .catch((err) => {
        let error: INSIReturnCode;

        switch (err.message) {
          case INSIReturnCode.ACCESS_DENIED:
            error = INSIReturnCode.ACCESS_DENIED;
            break;

          case INSIReturnCode.SERVER_ERROR:
            error = INSIReturnCode.SERVER_ERROR;
            break;

          default:
            error = INSIReturnCode.ERROR;
            break;
        }

        return {
          CodeCR: error,
        };
      });
  }

  public getINSILogs(caremateId: string, start: string, end: string): Observable<IJournalINSIEntry[]> {
    return this.api.getINSILogs(caremateId, start, end);
  }
}
