import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RewardScore } from "src/app/models/rewardScore.model";
import { RewardScoreService } from "src/app/providers/rewardScore.service";

@Component({
  selector: "app-rewards-dialog",
  templateUrl: "./rewards-dialog.component.html",
  styleUrls: ["./rewards-dialog.component.scss"],
})
export class RewardsDialogComponent implements OnInit, OnDestroy {
  public recompense: string;
  public today = moment();
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    protected rewardScoreService: RewardScoreService,
    public dialogRef: MatDialogRef<RewardsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      rewardDescription: string;
      rewardScore: RewardScore;
    },
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.recompense = this.data.rewardDescription;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onConfirm(): void {
    this.data.rewardScore.hasBeenRewarded = true;
    this.data.rewardScore.rewardReceived = this.data.rewardDescription;
    this.data.rewardScore.rewardReceivedDate = moment().format().toString();
    this.rewardScoreService
      .updateRewardScore(this.data.rewardScore)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.dialogRef.close();
        this.snackBar.open(this.translateService.instant("common.saveSuccess"), undefined, {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
      });
  }
}
