import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { ICommunication, IInfoCommunication, ISimplifiedCommunication } from "src/app/models/communications.interface";
import { InfoCommunication } from "src/app/models/communications.model";
import { Reference } from "src/app/models/reference.interface";
import { IEntity } from "src/app/models/sharedInterfaces";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class CommunicationsApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Communications", // Theme !
      ["dashboard/communication"], // Post route paths
      ["dashboard/communications", "dashboard/communication", "dashboard/exportCommsForPatient"], // Get route paths
      ["dashboard/communications"], // Update route paths
      [] // Delete route paths
    );
  }

  public listActive(serviceId: string, services: string[], startDate?: string, endDate?: string): Observable<InfoCommunication[]> {
    const params = {
      startDate,
      endDate,
      onlyActive: true,
      serviceId: null,
      services: null,
    };
    if (serviceId) {
      params.serviceId = serviceId;
    }
    if (services) {
      params.services = JSON.stringify(services);
    }
    return this.api.get(this.readRoutes[0], params).pipe(
      map((values: IInfoCommunication[]) => {
        return values.map((v) => new InfoCommunication(v, values));
      })
    );
  }

  public listActiveForPatient(patientId: string, startDate?: string, endDate?: string): Observable<InfoCommunication[]> {
    return this.api
      .get(this.readRoutes[0], {
        patientId,
        startDate,
        endDate,
        onlyActive: true,
      })
      .pipe(
        map((values: IInfoCommunication[]) => {
          return values.map((v) => new InfoCommunication(v, values));
        })
      );
  }

  public listAllForGroup(groupId: string): Observable<InfoCommunication[]> {
    return this.api.get(this.readRoutes[0], { groupId }).pipe(
      map((values: IInfoCommunication[]) => {
        return values.map((v) => new InfoCommunication(v, values));
      })
    );
  }

  public listOne(communicationId: string, patientId: string): Observable<ICommunication> {
    return this.api.get(this.readRoutes[1], {
      communicationId,
      patientId,
    }) as Observable<ICommunication>;
  }

  public delete(communicationId: string, patientId: string, patientsIds: Reference[]): Observable<boolean> {
    // first ask the communication
    return this.listOne(communicationId, patientId).pipe(
      mergeMap((com: ICommunication) => {
        // set it to deleted
        IEntity.setDeleted(com);
        // update it
        return this.update(com, patientsIds).pipe(
          // only return the deleted
          map((value) => (value ? true : false))
        );
      })
    );
  }

  public create(communication: ISimplifiedCommunication): Observable<ICommunication> {
    return this.api.post(this.createRoutes[0], communication) as Observable<ICommunication>;
  }

  public update(communication: ICommunication, currentSubjectList: Reference[]): Observable<number> {
    return this.api.put(this.updateRoutes[0], {
      paramCommunication: {
        currentSubjectList,
      },
      communication,
    }) as Observable<number>;
  }

  public exportCommunications(patientId: string, lang: string): Observable<Blob> {
    return this.api.getBlob(this.readRoutes[2], { patientId: patientId, lang: lang }) as Observable<Blob>;
  }
}
