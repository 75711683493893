import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { DISABLED_PATIENT_REASON, PatientUser } from "src/app/models/patient.interface";
import { PatientService } from "src/app/providers/patient.service";

@Component({
  selector: "app-patient-disable-dialog",
  templateUrl: "./patient-disable-dialog.component.html",
  styleUrls: ["./patient-disable-dialog.component.scss"],
})
export class PatientDisableDialogComponent implements OnDestroy {
  private onDestroy$ = new Subject<void>();
  public selectedReason: string;
  public otherReason = "";
  public reasons = DISABLED_PATIENT_REASON;

  constructor(
    protected patientService: PatientService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<PatientDisableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { patients: PatientUser[] }
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public onRadioChange($event: MatRadioChange): void {
    if ($event) {
      this.selectedReason = $event.value;
    }
  }

  public onYes(): void {
    this.data.patients.map((patientUser) => {
      if (this.selectedReason) {
        if (this.selectedReason === DISABLED_PATIENT_REASON.OTHER) {
          patientUser.patient.disabledReason = this.otherReason ? this.otherReason : this.selectedReason;
        } else {
          patientUser.patient.disabledReason = this.selectedReason;
        }
      } else {
        patientUser.patient.disabledReason = null;
      }
      this.patientService
        .disable(patientUser.patient)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          () => {
            const msg = this.translateService.instant("common.success");
            this.snackBar.open(msg, "ok", { duration: 3000 });
          },
          (err) => {
            FileLogger.error("PatientDisableDialogComponent", "onYes", err);
            const msg = this.translateService.instant("common.updateFail");
            this.snackBar.open(msg, "ok", { duration: 3000 });
          }
        );
    });
    this.dialogRef.close();
  }
}
