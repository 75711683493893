<div class="confirmMail">
  <mat-dialog-content>
    <div class="buttons-bar">
      <button mat-icon-button color="warn" mat-dialog-close matTooltip="{{ 'btn.close' | translate }}">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <h2>{{ "page.deleteRequestList.btn.resendArchive" | translate }}</h2>
    <form [formGroup]="patientMailForm">
      <mat-form-field>
        <input type="mail" matInput placeholder="{{ 'forms.mail' | translate }}" formControlName="mail" />
      </mat-form-field>
      <mat-error *ngIf="patientMailForm.get('mail').errors?.email || patientMailForm.get('mail').errors?.required">
        {{ "page.login.email-required" | translate }}
      </mat-error>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" type="submit" (click)="onSaveAndClose()">
      {{ "btn.send" | translate }}
    </button>
  </mat-dialog-actions>
</div>
