<mat-card class="comunicare-widget" cdkDrag [cdkDragDisabled]="!isDraggable">
  <mat-card-header class="widget-header">
    <mat-card-title class="custom-widget-title">
      {{ "widget.teleConsultation" | translate }}
      ({{ today | date : "dd/MM/yyyy" }})
      <a mat-icon-button aria-label="icon button with a ? icon" (click)="openDailyVideocall()">
        <mat-icon>help_outline</mat-icon>
      </a>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content class="widget-content">
    <table
      mat-table
      matSort
      matSortActive="start"
      matSortDirection="asc"
      matSortDisableClear
      [dataSource]="dataSource"
      [ngClass]="{ hide: dataSource.data.length === 0 }"
    >
      <!-- Start Column -->
      <ng-container matColumnDef="start">
        <th class="header-start" mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.start" | translate }}
        </th>
        <td class="noPadding custom-widget-font" *matCellDef="let element">
          {{ element.start | date : "HH:mm" }}
        </td>
      </ng-container>

      <!-- Patient Column -->
      <ng-container matColumnDef="participants">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.participant" | translate }}
        </th>
        <td class="noPadding" *matCellDef="let element">
          <ul class="no-bullet">
            <li
              *ngFor="let participant of getParticipants(element)"
              class="custom-widget-font"
              matTooltip="{{ participant.actor.display }}"
              matTooltipClass="truncatedNamesTooltip"
            >
              {{ participant.actor.display | truncate : 30 }}
            </li>
          </ul>
        </td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.description" | translate }}
        </th>
        <td class="custom-widget-font noPadding" *matCellDef="let element">
          {{ element.description }}
        </td>
      </ng-container>

      <!-- Date Seen By Patient Column -->
      <ng-container matColumnDef="seenByPatient">
        <th mat-header-cell *matHeaderCellDef>{{ "table.seenByPatient" | translate }}</th>
        <td *matCellDef="let row">
          <mat-icon
            class="custom-icon-small success cursor-normal"
            *ngIf="row.dateSeenByPatient"
            [matTooltip]="('table.seenByPatient' | translate) + ' ' + (row.dateSeenByPatient | date : 'short')"
            >check</mat-icon
          >
          <span *ngIf="!row.dateSeenByPatient">-</span>
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="actions">
        <th class="header-actions" mat-header-cell *matHeaderCellDef>
          {{ "table.action" | translate }}
        </th>
        <td class="custom-widget-font noPadding" *matCellDef="let element">
          <span matTooltip="{{ (has2FA || skip2FA ? 'btn.startVideoCall' : 'mfa.need2FAForTeleconsultation') | translate }}">
            <button
              class="custom-icon-w"
              [ngClass]="{
                success: has2FA || skip2FA,
                medium: !has2FA && !skip2FA
              }"
              mat-icon-button
              aria-label="icon button with a cam icon"
              (click)="onStartVideoCall(element)"
              [disabled]="!has2FA && !skip2FA"
            >
              <mat-icon class="appointments-image">videocam</mat-icon>
            </button>
          </span>
          <button
            mat-icon-button
            matTooltip="{{ 'btn.editAppointment' | translate }}"
            mat-icon-button
            class="success custom-icon-w"
            (click)="update(element)"
          >
            <mat-icon class="warn appointments-image custom-icon-w">edit</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'btn.deleteAppointment' | translate }}"
            mat-icon-button
            class="success custom-icon-w"
            (click)="delete(element)"
          >
            <mat-icon class="fail appointments-image custom-icon-w">delete</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'btn.goToPatient' | translate }}"
            class="custom-icon-w"
            *ngIf="element.isNotUnknowPatient()"
            mat-icon-button
            color="primary"
            (click)="goToPatient(element)"
          >
            <mat-icon class="appointments-image custom-icon-w">face</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'btn.resendSmsMail' | translate }}"
            class="custom-icon-w"
            *ngIf="!element.isNotUnknowPatient()"
            mat-icon-button
            color="primary"
            (click)="resendSmsMail(element)"
          >
            <mat-icon class="appointments-image custom-icon-w">mail</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      #paginator
      [pageIndex]="0"
      [pageSize]="5"
      [hidePageSize]="true"
      [ngClass]="{ hide: dataSource.data.length === 0 }"
    ></mat-paginator>
    <p class="text-center custom-widget-font" *ngIf="dataSource.data.length === 0">
      {{ "widget.noData" | translate }}
    </p>
    <p *ngIf="!has2FA && !skip2FA" class="noAccess">
      {{ "mfa.need2FAForTeleconsultation" | translate }}
      <button mat-button (click)="skip2FA = true" color="danger">
        <mat-icon>close</mat-icon>
        {{ "btn.ignore" | translate }}
      </button>
    </p>
  </mat-card-content>
</mat-card>
