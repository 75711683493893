// Helper service to create formGroup, formArray, and formControls for careplan editor editable fields only.
// This maintains form simplicity and avoids confusion. Additionally, due to potential form-interface differences,
// conversion methods will be necessary to create the objects to be send to backend.

import { Injectable } from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { Careplan } from "src/app/models/careplan.model";
import { ACTION_TARGET, Activity, Detail, IAddresses, ICareplan, IOrder, IOrderAction } from "src/app/models/careplans.interface";
import { ISimplifiedCommunication } from "src/app/models/communications.interface";
import { ACTIVITY_CATEGORY, ILocation, IReference, ITiming } from "src/app/models/sharedInterfaces";
import { ITranslation } from "src/app/models/translation.interface";
import uuid from "uuid-random";

export function defaultLangValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { isTranslated, defaultLang, traductions } = control.value;

    if (!isTranslated) return null;

    if (defaultLang) {
      const defaultLangValue = traductions.find((tr) => tr.lang === defaultLang)?.translateKey;
      return !defaultLangValue ? { defaultLang: true } : null;
    }
  };
}
@Injectable({
  providedIn: "root",
})
export class CareplanTemplateFormHelper {
  constructor(private fb: UntypedFormBuilder) {}

  /**
   * Creates a form group to inizialize the Careplan Template.
   * @param careplanTemplate - ICareplan to create a form group for.
   * @returns A FormGroup instance representing the careplan template.
   */
  public createCareplanTemplateFormGroup(careplanTemplate: ICareplan): UntypedFormGroup {
    const isCareplanLinear = careplanTemplate?.category?.[0]?.text?.startsWith("LINEAR/");
    return this.fb.group({
      description: this.fb.group({
        isTranslated: true,
        defaultLang: "fr",
        traductions: this.fb.array([
          this.fb.group({
            translateKey: this.fb.control("", Validators.required),
            lang: this.fb.control(""),
          }),
        ]),
      }),
      period: this.createPeriodFormGroup(false, careplanTemplate),
      isLinear: [isCareplanLinear ? true : false, Validators.required],
      services: this.createServicesFormArray(careplanTemplate?.author),
      activity: this.createActivitiesFormArray(careplanTemplate.activity, isCareplanLinear),
      addresses: this.createAdressesFormArray(careplanTemplate.addresses),
      actionResulting: this.fb.array(careplanTemplate.actionResulting),
    });
  }

  public createPeriodFormGroup(forced: boolean, careplanTemplate?: ICareplan): UntypedFormGroup {
    if (careplanTemplate?.period?.endTiming?.repeat?.period || forced) {
      return this.fb.group({
        start: null,
        end: null,
        endTiming: this.fb.group({
          repeat: this.fb.group({
            period: this.fb.control(careplanTemplate?.period?.endTiming?.repeat?.period ?? 0),
            periodUnits: this.fb.control(careplanTemplate?.period?.endTiming?.repeat?.periodUnits ?? "d"),
          }),
        }),
      });
    }
  }

  /**
   * Creates a form array for activities.
   * @param activity - An array of activities to create a form array for.
   * @returns A FormArray instance representing the activities.
   */
  private createActivitiesFormArray(activity: Activity[], isCareplanLinear: boolean): UntypedFormArray {
    const activitiesFormArray = this.fb.array([]);
    if (activity) {
      activity.forEach((activity) => {
        const activityFormGroup = this.fb.group({
          actionResulting: this.fb.array(activity.actionResulting),
          progress: this.fb.array([]),
          reference: this.fb.group({
            reference: activity.reference?.reference ? activity.reference.reference : uuid(),
            display: this.fb.group({
              isTranslated: true,
              defaultLang: "fr",
              traductions: this.fb.array([
                this.fb.group({
                  translateKey: this.fb.control("", Validators.required),
                  lang: this.fb.control(""),
                }),
              ]),
            }),
          }),
          detail: this.createDetailFormGroup(activity.detail, isCareplanLinear),
        });

        // Set teleconsultation snomed if activity category is teleconsultation
        if (activityFormGroup.get("detail.category").value === ACTIVITY_CATEGORY.TELECONSULTATION) {
          activityFormGroup.get("detail.code.code").disable();
        }

        // set requiredValidator on controlPaths when necessary
        const controlpaths = [
          "detail.scheduledTiming.repeat.frequency",
          "detail.scheduledTiming.repeat.period",
          "detail.scheduledTiming.repeat.periodUnits",
        ];
        this.toggleRequiredValidators(activityFormGroup.get("detail.procedure.isCycle").value, activityFormGroup, controlpaths);
        activitiesFormArray.push(activityFormGroup);
      });
    }
    return activitiesFormArray;
  }

  private createAdressesFormArray(addresses: IAddresses[]): UntypedFormArray {
    const addressesFormArray = this.fb.array([]);
    if (addresses) {
      addresses.forEach((address) => {
        const addressFormGroup = this.fb.group({
          reference: this.fb.control(address.reference),
          display: this.fb.group({
            isTranslated: true,
            defaultLang: "fr",
            traductions: this.fb.array([
              this.fb.group({
                translateKey: this.fb.control("", Validators.required),
                lang: this.fb.control(""),
              }),
            ]),
          }),
          status: this.fb.control(address.status), // inactive, active
        });
        addressesFormArray.push(addressFormGroup);
      });
    }
    return addressesFormArray;
  }

  /**
   * Creates a form array for services.
   * @param services - An array of services to create a form array for.
   * @returns A FormArray instance representing the services.
   */
  private createServicesFormArray(services: IReference[]): UntypedFormArray {
    const servicesFormArray = this.fb.array([]);
    if (services) {
      services.forEach((s) => {
        const serviceFormGroup = this.fb.group({
          reference: new UntypedFormControl(s.reference),
          display: new UntypedFormControl(s.display),
        });
        servicesFormArray.push(serviceFormGroup);
      });
    }
    return servicesFormArray;
  }

  /**
   * Creates a form array for action resulting.
   * @param actionResulting - An array of actionResulting to create a form array for.
   * @returns A FormArray instance representing the action resulting.
   */
  private createActionResultingFormArray(actionResulting: IOrder[]): UntypedFormArray {
    const actionResultingFormArray = this.fb.array([]);

    if (actionResulting) {
      actionResulting.forEach((actionResulting: IOrder) => {
        const actionResultingFormGroup = this.createActionResultingFormGroup(actionResulting);
        actionResultingFormArray.push(actionResultingFormGroup);
      });
    }
    return actionResultingFormArray;
  }

  /**
   * Creates a form group for action resulting.
   * Can also be used to add a new actionResulting by calling it in a component with a corresponding IOrder object
   * E.g. this.careplanTemplateFormHelper.createActionResultingFormGroup(CommunicationTabHelper.newCommunication)
   * @param actionResulting - An actionResulting to create a form group for.
   * @returns A FormGroup instance representing the action resulting.
   */
  public createActionResultingFormGroup(actionResulting: IOrder): UntypedFormGroup {
    const target: ACTION_TARGET = actionResulting.detail.target; // Type of action resulting, e.g. : COMMUNICATION
    return this.fb.group({
      when: this.createWhenFormGroup(actionResulting.when, target),
      detail: this.createOrderActionFormGroup(actionResulting.detail),
    });
  }

  /**
   * Creates a form group for "when"
   * @param when - ITiming to create a form group for.
   * @returns A FormGroup instance representing the "when".
   */
  private createWhenFormGroup(when: ITiming, actionTarget: ACTION_TARGET): UntypedFormGroup {
    switch (actionTarget) {
      case ACTION_TARGET.COMMUNICATION:
        return this.fb.group({
          period: when?.period,
          periodUnit: when?.periodUnits,
          timingCode: when?.timingCode,
          skipFirst: when?.skipFirst,
        });
      default:
        FileLogger.warn("createWhenFormGroup", 'Action Target "' + actionTarget + '" not handled');
    }
  }

  /**
   * Creates a form group for the "detail" of an action resulting
   * @param orderAction - detail of an action resulting to create a form group for.
   * @returns A FormGroup instance representing the detail of an actionResulting.
   */
  public createOrderActionFormGroup(orderAction: IOrderAction): UntypedFormGroup {
    switch (orderAction?.target) {
      case ACTION_TARGET.COMMUNICATION:
        return this.fb.group({
          target: orderAction.target,
          contentCommunication: this.createSimplifiedCommunicationFormGroup(orderAction.contentCommunication),
        });
      default:
        FileLogger.warn("createDetailFormGroup", 'Action Target "' + orderAction.target + '" not handled');
    }
  }

  /**
   * Creates a form group for simplified communication.
   * @param communication - Simplified communication to create a form group for.
   * @returns A FormGroup instance representing the simplified communication.
   */
  private createSimplifiedCommunicationFormGroup(communication: ISimplifiedCommunication): UntypedFormGroup {
    return this.fb.group({
      topicTranslation: this.createTranslationFormGroup(communication.topicTranslation),
      contentTranslation: this.createTranslationFormGroup(communication.contentTranslation),
      attachments: communication.attachments ? communication.attachments : [],
    });
  }

  /**
   * Creates a form group for translation.
   * @param translation - ITranslation to create a form group for.
   * @returns A FormGroup instance representing the translation.
   */
  private createTranslationFormGroup(translation: ITranslation): UntypedFormGroup {
    return this.fb.group({
      fr: translation.fr ? translation.fr : "",
      nl: translation.nl ? translation.nl : "",
      en: translation.en ? translation.en : "",
      de: translation.de ? translation.de : "",
      it: translation.it ? translation.it : "",
    });
  }

  /**
   * Creates a form group for detail.
   * @param detail - Detail to create a form group for.
   * @returns A FormGroup instance representing the detail.
   */
  private createDetailFormGroup(detail: Detail, isCareplanLinear: boolean): UntypedFormGroup {
    if (!detail) return undefined;
    return this.fb.group({
      status: detail.status ? detail.status : Careplan.ACTIVITY_STATUS_INACTIVE,
      category: this.getActivityCategory(detail, isCareplanLinear),
      code: this.fb.group({
        code: this.fb.control(detail.code.coding[0].code, Validators.required),
        system: this.fb.control("http://snomed.info.sct"),
      }), // snomed, that's also where the snomed for teleconsultation is stocked
      location: detail.location,
      description: this.fb.group({
        isTranslated: true,
        defaultLang: "fr",
        traductions: this.fb.array([
          this.fb.group({
            translateKey: this.fb.control(""),
            lang: this.fb.control(""),
          }),
        ]),
      }),
      scheduledTiming: this.fb.group({
        repeat: this.fb.group({
          frequency: detail.scheduledTiming?.repeat?.frequency ? detail.scheduledTiming?.repeat?.frequency : 1,
          period: detail.scheduledTiming?.repeat?.period ? detail.scheduledTiming?.repeat?.period : 1,
          periodUnits: detail.scheduledTiming?.repeat?.periodUnits ? detail.scheduledTiming?.repeat?.periodUnits : "d",
        }),
      }),
      drug: this.fb.group({
        isDrugsLinked: detail.productReference?.reference || detail.productTemplateList?.length,
        isMandatory: detail.productReference?.reference && detail.productTemplateList?.length > 0,
        productList: this.createProductListFormArray(detail?.productTemplateList),
      }),
      procedure: this.fb.group({
        withPerformer: Tools.isDefined(detail.performer?.[0]?.reference),
        withAddressAndPhone:
          Tools.isDefined((detail.location as ILocation)?.address) || Tools.isDefined((detail.location as ILocation)?.telecom),
        isCycle: Tools.isDefined(detail.scheduledTiming?.repeat?.frequency),
      }),
    });
  }

  /**
   * Creates a form array for productTemplateList.
   * @param productList - An array of IReference to create a form array for.
   * @returns A FormArray instance representing the productTemplateList.
   */
  private createProductListFormArray(productList: IReference[]): UntypedFormArray {
    const productListFormArray = this.fb.array([]);
    if (productList) {
      productList.forEach((p: IReference) => {
        productListFormArray.push(
          this.fb.group({
            reference: p.reference,
            display: p.display,
          })
        );
      });
    }
    return productListFormArray;
  }

  /**
   * Update the initial careplanTemplate with the form values
   * @param form - the careplanTemplateForm
   * @param careplanTemplate - the careplanTemplate initial
   */
  public updateCareplanTemplateWithForm(form: UntypedFormGroup, careplanTemplate: ICareplan): void {
    const formValues = form.getRawValue();

    careplanTemplate.description = formValues.description.traductions[0].translateKey;
    if (formValues.isLinear && !careplanTemplate.category?.[0]?.text?.startsWith("LINEAR/")) {
      careplanTemplate.category[0].text = "LINEAR/" + careplanTemplate.category[0].text;
    } else if (!formValues.isLinear && careplanTemplate.category?.[0]?.text?.startsWith("LINEAR/")) {
      careplanTemplate.category[0].text = careplanTemplate.category[0].text.replace("LINEAR/", "");
    }
    careplanTemplate.period = formValues.period ?? { start: null, end: null };
    careplanTemplate.author = formValues.services;
    careplanTemplate.activity = this.getActivitiesFromForm(formValues.activity);
    careplanTemplate.addresses = this.getAddressesFromForm(formValues.addresses);
    careplanTemplate.actionResulting = formValues.actionResulting;
  }

  private getAddressesFromForm(addresses: any): IAddresses[] {
    return addresses.map((a) => {
      return {
        reference: a.reference,
        display: a.display.traductions[0].translateKey, // $TR$
        status: a.status,
      };
    });
  }

  /**
   * Convert the activity formArray to Activity[]
   * @param activity - the activity formArray
   * @returns the activity formated
   */
  private getActivitiesFromForm(activity: any): Activity[] {
    return activity.map((a) => {
      const categoryCode =
        a.detail.category === ACTIVITY_CATEGORY.TELECONSULTATION || a.detail.category === ACTIVITY_CATEGORY.APPOINTMENT
          ? ACTIVITY_CATEGORY.ENCOUNTER
          : a.detail.category;

      //  scheduledString must be created only for encounter/proedure like category.
      let scheduledString: string;
      if (this.categoryIsAProcedure(a.detail.category)) {
        scheduledString = categoryCode === ACTIVITY_CATEGORY.ENCOUNTER ? "dateTime" : "date";
      }

      return {
        actionResulting: a.actionResulting,
        reference: {
          reference: a.reference.reference ? a.reference.reference : uuid(),
          display: a.reference.display.traductions[0].translateKey,
        },
        detail: {
          status: a.detail.status,
          category: {
            coding: [
              {
                code: categoryCode,
                display: a.detail.category,
                system: FHIRHelper.SYSTEM_CAREPLAN_ACTIVITY_CATEGORY,
              },
            ],
          },
          code: {
            coding: [
              {
                code: a.detail.category === ACTIVITY_CATEGORY.TELECONSULTATION ? FHIRHelper.SNOMED_TELECONSULTATION : a.detail.code.code,
                display: a.detail.code.display ? a.detail.code.display : a.reference.display.traductions[0].translateKey, // Doesn't make much sense but we but the reference display for this according to what we can find in DB.
                system: a.detail.code.system ? a.detail.code.system : FHIRHelper.SYSTEM_COMUNICARE,
              },
            ],
          },
          location: this.setLocation(a),
          description: a.detail.description.traductions[0].translateKey,
          scheduledString: scheduledString,
          scheduledTiming:
            a.detail.procedure.isCycle || a.detail.category === ACTIVITY_CATEGORY.DRUG
              ? {
                  repeat: {
                    periodUnits: a.detail.scheduledTiming.repeat.periodUnits ? a.detail.scheduledTiming.repeat.periodUnits : "d",
                    frequency: a.detail.scheduledTiming.repeat.frequency ? a.detail.scheduledTiming.repeat.frequency : 1,
                    period: a.detail.scheduledTiming.repeat.period ? a.detail.scheduledTiming.repeat.period : 1,
                    endless: true,
                    boundsPeriod: {
                      start: null,
                      end: null,
                    },
                  },
                }
              : undefined,
          // productRef must be null if there are multiple drugs in the productList and isMandatory is false
          productReference:
            a.detail.drug?.productList?.length && (a.detail.drug.productList?.length === 1 || a.detail.drug.isMandatory)
              ? a.detail.drug.productList[0]
              : undefined,
          productTemplateList: a.detail.drug?.productList?.length ? a.detail.drug.productList : undefined,
          performer: a.detail.procedure?.withPerformer ? [{ reference: "", display: "" }] : undefined,
        },
      };
    });
  }

  private getActivityCategory(detail, isCareplanLinear: boolean): ACTIVITY_CATEGORY {
    const code = detail?.category?.coding?.[0]?.code;
    if (code === ACTIVITY_CATEGORY.ENCOUNTER || (code === ACTIVITY_CATEGORY.PROCEDURE && isCareplanLinear)) {
      return detail?.code?.coding?.[0]?.code === FHIRHelper.SNOMED_TELECONSULTATION
        ? ACTIVITY_CATEGORY.TELECONSULTATION
        : ACTIVITY_CATEGORY.APPOINTMENT;
    } else {
      return code;
    }
  }

  public getActivityFormGroupByReference(form: UntypedFormGroup, activityReference: string): UntypedFormGroup {
    const currentActivityIndex = form.get("activity").value.findIndex((act) => act.reference.reference === activityReference);

    if (currentActivityIndex !== -1) {
      return (form.get("activity") as UntypedFormArray).at(currentActivityIndex) as UntypedFormGroup;
    }
  }

  public toggleRequiredValidators(checked: boolean, formGroup: UntypedFormGroup, controlPaths: string[]): void {
    controlPaths.forEach((path) => {
      const control = formGroup.get(path);
      if (checked) {
        control.setValidators(Validators.required);
      } else {
        control.clearValidators();
      }
      control.updateValueAndValidity();
    });
  }

  public setLocation(a: any): ILocation | IReference {
    if (a.detail.procedure?.withAddressAndPhone) {
      const location: ILocation = {
        address: {
          line: [],
          postalCode: "",
          city: "",
          country: "",
          text: "", // Only this field seems to be used but that's the only one not required ¯\_(ツ)_/¯
        },
        telecom: {
          system: "phone",
          value: "",
        },
      };
      return location;
    } else {
      let reference: IReference;
      if (a.detail.code.code === FHIRHelper.SNOMED_TELECONSULTATION) {
        reference = {
          display: "Home",
          reference: "Location/Home",
        };
      } else {
        reference = {
          display: "Hospital",
          reference: "Location/Hospital",
        };
      }
      return reference;
    }
  }

  public categoryIsAProcedure(categoryStr: ACTIVITY_CATEGORY) {
    return [
      ACTIVITY_CATEGORY.APPOINTMENT,
      ACTIVITY_CATEGORY.TELECONSULTATION,
      ACTIVITY_CATEGORY.ENCOUNTER,
      ACTIVITY_CATEGORY.PROCEDURE, // has been replaced by "ENCOUNTER" but might still be present in DB
    ].includes(categoryStr);
  }
}
