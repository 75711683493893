<div class="rewardsPage">
  <div class="toolbar">
    <div class="left">
      <mat-form-field>
        <mat-label><span class="fa fa-search mr-5"></span>{{ "itemFilter.search" | translate }}</mat-label>
        <input
          type="search"
          matInput
          [placeholder]="'itemFilter.searchPlaceHolder' | translate"
          [value]="globalSearchValue"
          (keyup)="updateSearch($event.target.value)"
        />
        <button *ngIf="globalSearchValue" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="updateSearch('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <form [formGroup]="filterFormTable">
      <div class="datePickerContainer ml-5">
        <mat-form-field class="datePickerField ml-5">
          <input
            matInput
            [max]="maxFromDate"
            [matDatepicker]="fromDateTable"
            formControlName="fromDate"
            placeholder="{{ 'itemFilter.fromDateRewards' | translate }}"
          />
          <mat-datepicker-toggle matSuffix [for]="fromDateTable"></mat-datepicker-toggle>
          <mat-datepicker #fromDateTable></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="datePickerField ml-5">
          <input
            matInput
            [min]="minToDate"
            [max]="today"
            [matDatepicker]="toDate"
            formControlName="toDate"
            placeholder="{{ 'itemFilter.toDateRewards' | translate }}"
          />
          <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
          <mat-datepicker #toDate></mat-datepicker>
        </mat-form-field>
      </div>
    </form>
    <button mat-icon-button>
      <mat-icon style="font-size: 30px" matTooltip="{{ 'btn.help' | translate }}" (click)="openRewardHelp()"> help_outline</mat-icon>
    </button>
    <div class="spacer"></div>
    <div class="right">
      <button
        mat-stroked-button
        *ngIf="('dashboard/communication' | isAuthorized : 'POST' | async) === true"
        (click)="sendCommunication()"
        [disabled]="selection.selected.length <= 0 || isAllServices"
      >
        <span matTooltip="{{ 'btn.disabledWithAll' | translate }}" [matTooltipDisabled]="!isAllServices">
          {{ "communication.sendNew" | translate }}
        </span>
      </button>
      <button mat-stroked-button (click)="activateCheckBox()" matTooltip="{{ 'page.patientlist.multiSelect' | translate }}">
        <mat-icon>check_box</mat-icon>
      </button>
      <button mat-stroked-button (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
        <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
      </button>
    </div>
  </div>
  <app-filters-display [filters]="filters" (removeFilter)="applyFilter($event)"></app-filters-display>
  <mat-card>
    <table mat-table matSort matSortDirection="desc" matSortActive="objectifEnd" aria-label="Elements" class="full-width-table">
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            class="ml-15"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            class="ml-15"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <!-- Gender Column -->
      <ng-container matColumnDef="gender">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.patient.gender" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.gender' | translate }}"
            [translateFieldKey]="'model.patient.gender'"
            propertyName="patientCustomInfo.gender"
            [dataType]="dataTypeChoice"
            [data]="dataSource?.data"
            [defaultValue]="getFilter('patientCustomInfo.gender')"
            translatePrefix="choiceLabel.gender"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          <span class="fas fa-2x fa-mars" *ngIf="row.patientCustomInfo.gender === 'male'"></span>
          <span class="fas fa-2x fa-venus" *ngIf="row.patientCustomInfo.gender === 'female'"></span>
          <span
            class="fas fa-2x fa-genderless"
            *ngIf="row.patientCustomInfo.gender !== 'male' && row.patientCustomInfo.gender !== 'female'"
          ></span>
        </td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.patient.name" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.name' | translate }}"
            [translateFieldKey]="'model.patient.name'"
            propertyName="patientCustomInfo.name"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('patientCustomInfo.name')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">{{ row.patientCustomInfo.name }}</td>
      </ng-container>
      <!-- Firstname Column -->
      <ng-container matColumnDef="firstname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.patient.firstname" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.firstname' | translate }}"
            [translateFieldKey]="'model.patient.firstname'"
            propertyName="patientCustomInfo.firstname"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('patientCustomInfo.firstname')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.patientCustomInfo.firstname }}
        </td>
      </ng-container>
      <!-- Birthday Column -->
      <ng-container matColumnDef="birthday">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.patient.birthdate" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.birthdate' | translate }}"
            [translateFieldKey]="'model.patient.birthdate'"
            propertyName="patientCustomInfo.bithdate"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('patientCustomInfo.bithdate')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.patientCustomInfo.bithdate | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>
      <!-- objectif name Column -->
      <ng-container matColumnDef="objectifName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.rewardsPage.objectifName" | translate }}
          <app-item-filter
            fieldName="{{ 'model.rewardsPage.objectifName' | translate }}"
            [translateFieldKey]="'model.rewardsPage.objectifName'"
            propertyName="rewardDefinitionReference.reference"
            [dataType]="dataTypeRewardsName"
            [defaultValue]="getFilter('rewardDefinitionReference.reference')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ getTargetName(row.rewardDefinitionReference.reference) }}
        </td>
      </ng-container>
      <!-- objectif start Column -->
      <ng-container matColumnDef="objectifStart">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.rewardsPage.objectifStart" | translate }}
          <app-item-filter
            fieldName="{{ 'model.rewardsPage.objectifStart' | translate }}"
            [translateFieldKey]="'model.rewardsPage.objectifStart'"
            propertyName="start"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('start')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.start | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>
      <!-- objectif end Column -->
      <ng-container matColumnDef="objectifEnd">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.rewardsPage.objectifEnd" | translate }}
          <app-item-filter
            fieldName="{{ 'model.rewardsPage.objectifEnd' | translate }}"
            [translateFieldKey]="'model.rewardsPage.objectifEnd'"
            propertyName="end"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('end')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.end | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>
      <!-- objectif progression Column -->
      <ng-container matColumnDef="objectifProgress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.rewardsPage.objectifProgress" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">{{ getTargetValue(row) }}</td>
      </ng-container>
      <!-- Statut traité/pas traité -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.status" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.status' | translate }}"
            [translateFieldKey]="'model.patient.status'"
            propertyName="statusTranslateKey"
            [dataType]="dataTypeChoice"
            [data]="dataSource?.data"
            [defaultValue]="getFilter('statusTranslateKey')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.statusTranslateKey | translate }}
        </td>
      </ng-container>
      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">
          {{ "table.action" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <button
            *ngIf="!isAlreadySeen(row) && ('rewardScore' | isAuthorized : 'PUT' | async) === true"
            mat-icon-button
            matTooltip="{{ 'btn.setSeen' | translate }}"
            (click)="onDetails(row, false, getTargetName(row.rewardDefinitionReference.reference), getTargetValue(row))"
            aria-label="icon button with a pencil icon"
          >
            <mat-icon class="custom-icon-small warn">add_task</mat-icon>
          </button>
          <button
            mat-icon-button
            *ngIf="('rewardScore' | isAuthorized : 'PUT' | async) === true"
            matTooltip="{{ 'btn.details' | translate }}"
            (click)="onDetails(row, true, getTargetName(row.rewardDefinitionReference.reference), getTargetValue(row))"
            aria-label="icon button with a loupe icon"
          >
            <mat-icon class="custom-icon-small">search</mat-icon>
          </button>
        </td>
      </ng-container>
      <!-- reward status Column -->
      <!-- <ng-container matColumnDef="rewardStatut">
                <th mat-header-cell  *matHeaderCellDef>
                    {{ 'model.rewardsPage.rewardStatut' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button matTooltip="{{ 'model.rewardsPage.orangeTrophy' | translate }}"
                        aria-label="icon button trophy icon" *ngIf="row.hasBeenRewarded === false && row.currentScore > getTargetScore(row.rewardDefinitionReference.reference)"
                        (click)="openRewardsDialog(getTargetDescription(row.rewardDefinitionReference.reference), row)">
                        <mat-icon class="sub-align orange">emoji_events</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="{{ 'model.rewardsPage.greenTrophy' | translate }}"
                        aria-label="icon button trophy icon" *ngIf="row.hasBeenRewarded === true && row.currentScore > getTargetScore(row.rewardDefinitionReference.reference)">
                        <mat-icon class="sub-align green">emoji_events</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="{{ 'model.rewardsPage.grayTrophy' | translate }}"
                        aria-label="icon button trophy icon" *ngIf="row.hasBeenRewarded === false && row.currentScore < getTargetScore(row.rewardDefinitionReference.reference)">
                        <mat-icon class="sub-align gray">emoji_events</mat-icon>
                    </button>
                </td>
            </ng-container> -->
      <!-- reward history Column -->
      <!-- <ng-container matColumnDef="rewardHistory">
                <th mat-header-cell  *matHeaderCellDef mat-sort-header>
                    {{ 'model.rewardsPage.rewardHistory' | translate }}
                    <app-item-filter fieldName="{{ 'model.rewardsPage.rewardHistory' | translate }}" propertyName="rewardHistory"
                        [dataType]="dataTypeText" [defaultValue]="getFilter('rewardHistory')"
                        (applyFilter)="applyFilter($event)">
                    </app-item-filter>
                </th>
                <td mat-cell *matCellDef="let row">
                    <span>{{ row.rewardReceived }}</span>
                    -<br />
                    <span>{{ row.rewardReceivedDate | date : 'dd/MM/yyyy' }}</span>
                </td>
            </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div class="spinner-container" *ngIf="dataSource?.loading$ | async">
      <mat-spinner></mat-spinner>
    </div>
    <mat-paginator #paginator [pageIndex]="0" [pageSize]="currentPageSize ? currentPageSize : 25" [pageSizeOptions]="[25, 50, 100]">
    </mat-paginator>
  </mat-card>
</div>
