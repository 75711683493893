import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "app-item-filter-text",
  templateUrl: "./text-filter.component.html",
  styleUrls: ["./text-filter.component.scss"],
})
export class TextFilterComponent implements OnInit {
  public filterForm = this.fb.group({
    value: ["", [Validators.required]],
  });

  public constructor(
    private fb: UntypedFormBuilder,
    private readonly dialogRef: MatDialogRef<TextFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data && this.data.filter && this.data.filter.data) {
      this.filterForm.setValue(this.data.filter.data);
    }
  }

  public apply() {
    if (this.filterForm.valid) {
      this.dialogRef.close(this.filterForm.value);
    }
  }

  public clear() {
    this.dialogRef.close();
  }
}
