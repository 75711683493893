import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IDrugSchema } from "src/app/models/drugSchema.interface";
import { DrugSchema } from "src/app/models/drugSchema.model";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class DrugSchemaApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "DrugSchema", // Theme !
      ["dashboard/drugSchema"], // Post route paths
      ["dashboard/drugSchema"], // Get route paths
      ["dashboard/drugSchema"], // Update route paths
      ["dashboard/drugSchema"] // Delete route paths
    );
  }

  public list(drug?: string, isMenu?: boolean): Observable<DrugSchema[]> {
    if (isMenu) {
      return this.api.get(this.readRoutes[0], { drug: undefined, isMenu }).pipe(
        map((values: IDrugSchema[]) => {
          return values.map((v) => new DrugSchema(v));
        })
      );
    } else {
      if (drug) {
        return this.api.get(this.readRoutes[0], { drug }).pipe(
          map((values: IDrugSchema[]) => {
            return values.map((v) => new DrugSchema(v));
          })
        );
      } else {
        return this.api.get(this.readRoutes[0]).pipe(
          map((values: IDrugSchema[]) => {
            return values.map((v) => new DrugSchema(v));
          })
        );
      }
    }
  }

  public create(drugSchema: DrugSchema): Observable<DrugSchema> {
    return this.api.post(this.createRoutes[0], drugSchema).pipe(map((v: IDrugSchema) => new DrugSchema(v)));
  }

  public update(drugSchema: DrugSchema): Observable<DrugSchema> {
    return this.api.put(this.updateRoutes[0], drugSchema).pipe(map((v: IDrugSchema) => new DrugSchema(v)));
  }

  public delete(id: string): Observable<DrugSchema> {
    return this.api.delete(`${this.deleteRoutes[0]}/${id}`, {}) as Observable<DrugSchema>;
  }
}
