import { CdkDrag } from "@angular/cdk/drag-drop";
import { Component, ViewChild } from "@angular/core";
import { IWidgetComponent, WidgetName } from "src/app/models/widget.interface";

@Component({
  selector: "app-patient-alert-no-transmission-widget",
  templateUrl: "./patient-alert-no-transmission-widget.component.html",
  styleUrls: ["./patient-alert-no-transmission-widget.component.scss"],
})
export class PatientAlertNoTransmissionWidgetComponent implements IWidgetComponent {
  @ViewChild(CdkDrag, { static: true }) cdkDrag: CdkDrag;
  public isDraggable = true;

  public get widgetName(): string {
    return WidgetName.PATIENT_ALERT_NO_TRANSMISSION;
  }
}
