import { SelectionModel } from "@angular/cdk/collections";
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { Tools } from "src/app/helpers/tools";
import { AccessValue, IAccessGroupPermission, Methods } from "src/app/models/accessGroup.interface";
import { DataType } from "src/app/models/filter.interface";
import { STATUS_ENTITY } from "src/app/models/sharedInterfaces";
import { IServiceRoutes } from "src/app/providers/api/baseApi.service";
import { DashboardRoutesService } from "src/app/providers/dashboardRoutes.service";
import { PermissionsListDataSource } from "./permissions-list-datasource";

@Component({
  selector: "app-group-permissions",
  templateUrl: "./group-permissions.component.html",
  styleUrls: ["./group-permissions.component.scss"],
})
export class GroupPermissionsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatTable) table: MatTable<IAccessGroupPermission>;
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() accessGroupId: string;
  private _permissions: IAccessGroupPermission[];
  @Input() set permissions(value: IAccessGroupPermission[]) {
    if (value) {
      this._permissions = Tools.clone(value);
    } else {
      this._permissions = [];
    }
    this.updateUnusedRoutes(null, true);
    this.permissionForm?.get("theme")?.patchValue("");
  }
  @Output() permissionsChange = new EventEmitter<IAccessGroupPermission[]>();
  @Input() needTheme = false;
  @Input() needDelete = false;
  @Input() needCheck = false;
  @Input() needCreate = false;
  @Input() needFilters = false;

  public dataSource: PermissionsListDataSource;
  public displayedColumns: string[] = ["method", "routeName", "access"];
  public ACCESS = AccessValue;
  public accessList = Tools.getValuesOfEnum(AccessValue);
  public dataType = DataType;
  public nbPermissions: number;
  public pageSize = 20;
  public showPaginator = true;
  private methods = Methods;

  // ------ Create and edit mode variables -----
  public unusedRoutes: IServiceRoutes[] = [];
  public currentThemeMethods: string[] = [];
  public currentThemeMethodsRoutes: string[] = [];
  public selection = new SelectionModel<IAccessGroupPermission>(true, []);
  public permissionForm: UntypedFormGroup;
  private selectedServiceRoutes: IServiceRoutes;
  private allRoutes: IServiceRoutes[] = [];

  /** Subject that emits when the component has been destroyed. */
  // tslint:disable-next-line: variable-name
  private onDestroy$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private dashboardRoutesService: DashboardRoutesService,
    private translateService: TranslateService
  ) {
    this.permissionForm = this.fb.group({
      theme: [undefined, [Validators.required, this.NotEmptyValidator]],
      method: [{ value: undefined, disabled: true }, [Validators.required, this.NotEmptyValidator]],
      routesNames: [{ value: [], disabled: true }, [Validators.required, this.NotEmptyValidator]],
      access: [this.accessList[1], [Validators.required]],
    });
    this.dataSource = new PermissionsListDataSource(this.translateService);
  }

  ngOnInit(): void {
    if (this.needTheme) {
      this.displayedColumns.splice(0, 0, "theme");
    }
    if (this.needCheck) {
      this.displayedColumns.splice(0, 0, "select");
    }
    if (this.needDelete) {
      this.displayedColumns.push("action");
    }
    this._permissions = this._permissions || [];
    this.nbPermissions = this._permissions.length;
    if (this.needCreate) {
      const pages: IServiceRoutes = this.dashboardRoutesService.getDashboardRoutes();
      const apiRoutes: IServiceRoutes[] = DashboardRoutesService.allApiRoutes.sort((p1, p2) => p1.theme.localeCompare(p2.theme));
      this.allRoutes.push(pages);
      this.allRoutes.push(...apiRoutes);
      this.unusedRoutes = Tools.clone(this.allRoutes);
      // We are updating the permissions of an access group:
      if (this.accessGroupId) {
        this.updateUnusedRoutes();
      }
      this.setupFormWatchValueChange();
    }
  }

  ngAfterViewInit(): void {
    if (this._permissions) {
      this.loadData();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public loadData(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.loadData(this._permissions);
    this.table.dataSource = this.dataSource;
    this.nbPermissions = this._permissions.length;
  }

  public toggleShowPaginator(show: boolean) {
    this.showPaginator = show;
    this.dataSource.paginator = show ? this.paginator : undefined;
  }

  // --------------------------------------------------------------------------
  // ----------------- Create & delete methods --------------------------------
  // --------------------------------------------------------------------------

  /**
   * Removes permissions from the access group ;
   * Put those permissions back in the list of possible new permissions ;
   * update the table of permissions
   * @returns
   */
  private deletePermissions(idxToDelete: number[]): void {
    for (const i of idxToDelete) {
      this._permissions.splice(i, 1);
    }
    this.permissionsChange.emit(Tools.clone(this._permissions));
    this.updateUnusedRoutes(null, true);
    this.dataSource.loadData(this._permissions);
    this.nbPermissions = this._permissions.length;
  }

  public deleteOnePermission(perm: IAccessGroupPermission): void {
    const idx = this._permissions.findIndex((p) => p && p.theme + p.method + p.routeName === perm.theme + perm.method + perm.routeName);
    if (idx > -1) {
      this.deletePermissions([idx]);
    }
  }

  /**
   * Removes all the checked permissions
   */
  public deleteSelectedPermissions(): void {
    const idxToDelete: number[] = this.selection.selected.map((perm) =>
      this._permissions.findIndex((p) => p && p.theme + p.method + p.routeName === perm.theme + perm.method + perm.routeName)
    );
    // Sort needed because we need to remove them one by one, starting by the end
    // (otherwise, if we remove the first one first, it will modify the index of the others)
    idxToDelete.sort((a, b) => b - a);

    this.selection.clear();
    this.deletePermissions(idxToDelete);
  }

  /**
   * Creates new permissions from the values in the form inputs ;
   * removes those permissions from the list of possible new permissions ;
   * updates the table of permissions ;
   * @returns
   */
  public createPermissions(): void {
    if (!this.permissionForm.valid) {
      this.permissionForm.markAsTouched();
      this.permissionForm.markAsDirty();
      this.permissionForm.markAllAsTouched();
      return;
    }
    const routes = this.permissionForm.get("routesNames").value;
    const newPermissions = [];
    for (const routeName of routes) {
      if (routeName === "all") continue;
      const newPermission: IAccessGroupPermission = {
        accessGroupId: this.accessGroupId,
        method: this.permissionForm.get("method").value,
        routeName,
        access: Number(this.permissionForm.get("access").value) as AccessValue,
        entityStatus: [STATUS_ENTITY.ACTIVE],
        theme: this.permissionForm.get("theme").value,
      };
      newPermissions.push(newPermission);
      this._permissions.push(newPermission);
    }
    this.permissionsChange.emit(Tools.clone(this._permissions));
    this.permissionForm.get("theme").patchValue("");
    this.updateUnusedRoutes(newPermissions);
    this.dataSource.loadData(this._permissions);
    this.nbPermissions = this._permissions.length;
  }

  /**
   * Check all items in the multi-select dropdown list used to choose the routes for the new permissions
   */
  public toggleAllRoutesSelected(): void {
    const selected = this.permissionForm.get("routesNames").value;
    const all = selected[0] === "all" ? true : false;
    if (all) {
      const all = ["all", ...this.currentThemeMethodsRoutes];
      this.permissionForm.get("routesNames").patchValue(all);
    } else {
      this.permissionForm.get("routesNames").patchValue([]);
    }
  }

  /**
   * Deselect (or select) an route in the multi-select dropdown list used to choose the routes
   * for the new permissions. If the "all" checkbox was selected, it deselects it.
   */
  public toggleOneRoutesSelected(): void {
    const selected = this.permissionForm.get("routesNames").value;
    const all = selected[0] === "all" ? true : false;
    if (all) {
      selected.splice(0, 1);
      this.permissionForm.get("routesNames").patchValue(selected);
    }
  }

  /**
   * Watch the selections change of the "theme", and "method" in put in order to update
   * the available list of choices for the next input (like the list of routeNames)
   */
  private setupFormWatchValueChange(): void {
    this.permissionForm
      .get("theme")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((newValue: string) => {
        this.currentThemeMethodsRoutes = [];
        this.permissionForm.get("method").patchValue("");
        if (newValue) {
          const methods = [];
          this.selectedServiceRoutes = this.unusedRoutes.find((r) => r.theme === newValue);
          if (this.selectedServiceRoutes) {
            if (this.selectedServiceRoutes.create.length) methods.push(this.methods[0]);
            if (this.selectedServiceRoutes.read.length) methods.push(this.methods[1]);
            if (this.selectedServiceRoutes.update.length) methods.push(this.methods[2]);
            if (this.selectedServiceRoutes.delete.length) methods.push(this.methods[3]);
          }
          this.currentThemeMethods = methods;
          this.permissionForm.get("method").enable();
        } else {
          this.permissionForm.get("theme").markAsUntouched();
          this.permissionForm.get("method").disable();
        }
      });
    this.permissionForm
      .get("method")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((newValue: string) => {
        this.permissionForm.get("routesNames").patchValue([]);
        if (newValue) {
          let routesNames = [];
          switch (newValue) {
            case this.methods[0]:
              routesNames = this.selectedServiceRoutes.create;
              break;
            case this.methods[1]:
              routesNames = this.selectedServiceRoutes.read;
              break;
            case this.methods[2]:
              routesNames = this.selectedServiceRoutes.update;
              break;
            case this.methods[3]:
              routesNames = this.selectedServiceRoutes.delete;
              break;
          }
          this.currentThemeMethodsRoutes = routesNames;
          this.permissionForm.get("routesNames").enable();
        } else {
          this.permissionForm.get("routesNames").disable();
        }
      });
  }

  /**
   * Update the list of unused routes by removing the ones corresponding to the already assigned permissions
   * @param newPerms (optional) the newly assigned permissions, if not set, it will use the full list of assigned permissions
   */
  private updateUnusedRoutes(newPerms?: IAccessGroupPermission[], useAllRoutes = false) {
    const perms: IAccessGroupPermission[] = newPerms || this._permissions;
    const routes = useAllRoutes ? Tools.clone(this.allRoutes) : this.unusedRoutes;
    for (const perm of perms) {
      if (perm === null) continue;
      const routeIdx = routes.findIndex((r) => r.theme === perm.theme);
      const serviceRoute = routeIdx > -1 ? routes[routeIdx] : null;
      if (serviceRoute) {
        let routesByMethod: string[] = [];
        switch (perm.method) {
          case this.methods[0]:
            routesByMethod = serviceRoute.create;
            break;
          case this.methods[1]:
            routesByMethod = serviceRoute.read;
            break;
          case this.methods[2]:
            routesByMethod = serviceRoute.update;
            break;
          case this.methods[3]:
            routesByMethod = serviceRoute.delete;
            break;
        }
        if (routesByMethod && routesByMethod.length) {
          const idx = routesByMethod.findIndex((r) => r === perm.routeName);
          if (idx > -1) {
            routesByMethod.splice(idx, 1);
          }
        }
        if (!serviceRoute.create?.length && !serviceRoute.read?.length && !serviceRoute.update?.length && !serviceRoute.delete?.length) {
          routes.splice(routeIdx, 1);
        }
      }
    }
    this.unusedRoutes = routes;
  }
  /**
   * Form validator that checks that at the array value of a multi-select is not empty
   * @param control
   * @returns
   */
  public NotEmptyValidator: ValidatorFn = (control: AbstractControl): { [key: string]: true } | null => {
    const newValue = control.value;
    if (!newValue || newValue.length < 1) {
      return { invalidArray: true };
    }
    return null;
  };

  /**
   * Check or uncheck all displayed permissions (in order to delete them all)
   */
  public toggleSelectAllPerms(isChecked: boolean) {
    if (!this.needCheck) {
      return;
    }
    this.dataSource
      .connect()
      .pipe(takeUntil(this.onDestroy$), first())
      .subscribe((rows) => {
        if (!isChecked) {
          this.selection.clear();
        } else {
          this.selection.select(...rows);
        }
      });
  }
}
