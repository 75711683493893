<div class="container mat-elevation-z2">
  <mat-table
    [dataSource]="dataSource"
    cdkDropList
    #table
    [cdkDropListData]="dataSource.data"
    (cdkDropListDropped)="drop($event)"
    *ngIf="displayedColumns?.length"
    [cdkDropListEnterPredicate]="alreadyInPredicate"
    [id]="id"
    [cdkDropListConnectedTo]="connectedTo"
    [cdkDropListSortingDisabled]="true"
    multiTemplateDataRows
  >
    <ng-container matColumnDef="grip">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon class="fa-icon" fontSet="fas" fontIcon="fa-grip-vertical"></mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container *ngFor="let column of columns" [matColumnDef]="column">
      <mat-header-cell *matHeaderCellDef>{{ "table." + column | translate | titlecase }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element[column] }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>
        <!-- This is a spacer -->
        <div style="flex-grow: 2"></div>
        {{ "table.action" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <!-- This is a spacer -->
        <div style="flex-grow: 2"></div>
        <ng-container *ngFor="let action of actions">
          <button mat-icon-button (click)="onAction(action, element)" *ngIf="action === AVAILABLE_ACTIONS.configure">
            <mat-icon class="fa-icon" fontSet="fas" fontIcon="fa-cog"></mat-icon>
          </button>

          <button mat-icon-button (click)="onAction(action, element)" *ngIf="action === AVAILABLE_ACTIONS.unlink">
            <mat-icon class="fa-icon" fontSet="fas" fontIcon="fa-unlink"></mat-icon>
          </button>

          <button
            mat-icon-button
            (click)="onAction(action, element)"
            *ngIf="action === AVAILABLE_ACTIONS.edit && element.itemType !== action_target.QUESTIONNAIRE"
          >
            <mat-icon class="fa-icon" fontSet="fas" fontIcon="fa-pen"></mat-icon>
          </button>

          <!-- Questionnaire only : visualisation button instead of edit (handled by the edit method as well) -->
          <button
            mat-icon-button
            (click)="onAction(action, element)"
            *ngIf="action === AVAILABLE_ACTIONS.edit && element.itemType === action_target.QUESTIONNAIRE"
          >
            <mat-icon class="fa-icon" fontSet="fas" fontIcon="fa-search"></mat-icon>
          </button>
        </ng-container>
      </mat-cell>
    </ng-container>
    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
          <app-knowledge-criteria
            *ngIf="element.itemType === action_target.KNOWLEDGE && element === expandedElement"
            [careplan]="careplanTemplate"
            [knowledgeId]="element.uniqueId"
            (criteriaSaved)="expandedElement = null"
            [activityReference]="element.originReference"
          ></app-knowledge-criteria>

          <app-careplan-editor-properties
            *ngIf="element.itemType !== action_target.KNOWLEDGE && element === expandedElement"
            [careplan]="careplanTemplate"
            [item]="element"
            (saved)="expandedElement = null"
          ></app-careplan-editor-properties>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      cdkDrag
      [cdkDragData]="row"
      *matRowDef="let row; columns: displayedColumns"
      [class.expanded-row]="expandedElement === row"
    ></mat-row>
    <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></mat-row>

    <ng-container *ngIf="isTarget">
      <div *matNoDataRow class="no-data-row">{{ "page.careplanEditor.emptyList" | translate }}</div>
    </ng-container>
  </mat-table>
  <mat-paginator *ngIf="!isTarget" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>
