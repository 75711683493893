import { Directive, ElementRef, OnInit } from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[no-header-tabs]",
})
export class NoHeaderTabsDirective implements OnInit {
  constructor(private eleRef: ElementRef) {}

  ngOnInit(): void {
    this.eleRef.nativeElement.children[0].style.display = "none";
  }
}
