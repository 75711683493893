import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { OComponent } from "./observations.interface";
import { Reference } from "./reference.interface";
import { ACTION_REWARD, IAction, ICodeableConcept, IRewardScore, IRewardScoreHistory, REWARD_STATUS } from "./rewardScore.interface";
import { ACTION_STATUS_ENTITY, STATUS_ENTITY } from "./sharedInterfaces";

export class RewardScore implements IRewardScore {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  public _id: string;
  public patientReference: Reference;
  public rewardDefinitionReference: Reference;
  public currentScore: number;
  public actionsHistory: IRewardScoreHistory[]; // history of actions in this adress
  public start: string; // YYYY-MM-DD HH:mm (calculé àpd du rewardDefinition.goalDuration)
  public end: string; // YYYY-MM-DD HH:mm
  public hasBeenRewarded: boolean;
  public rewardReceived?: string;
  public rewardReceivedDate?: string;
  public author?: Reference;
  public notes?: ICodeableConcept[]; // text and reference to the author of the note
  public rewardStatus?: REWARD_STATUS; // status of the alert (UNPROCESSED / PROCESSED)
  public actionsNotesHistory?: IAction[];
  public creation?: string;
  public modified: string;
  public actionStatus?: ACTION_STATUS_ENTITY;
  public entityStatus: STATUS_ENTITY[];

  constructor(data: IRewardScore) {
    this._id = data._id;
    this.patientReference = data.patientReference;
    this.rewardDefinitionReference = data.rewardDefinitionReference;
    this.currentScore = data.currentScore;
    this.actionsHistory = data.actionsHistory;
    this.start = data.start;
    this.end = data.end;
    this.hasBeenRewarded = data.hasBeenRewarded;
    this.rewardReceived = data.rewardReceived;
    this.rewardReceivedDate = data.rewardReceivedDate;
    this.rewardStatus = data.rewardStatus ? data.rewardStatus : REWARD_STATUS.UNPROCESSED;
    this.notes = data.notes ? data.notes : [];
    this.actionsNotesHistory = data.actionsNotesHistory ? data.actionsNotesHistory : [];
    this.creation = data.creation;
    this.modified = data.modified;
    this.actionStatus = data.actionStatus;
    this.entityStatus = data.entityStatus;
  }

  public get isNoHistoric(): boolean {
    return this.actionsNotesHistory?.length === 0;
  }

  public get lastComment(): string | null {
    const lastIndex = this.notes?.length - 1;
    if (lastIndex >= 0) {
      return this.notes?.[lastIndex]?.text;
    } else {
      return null;
    }
  }

  public get statusTranslateKey(): string {
    switch (this.rewardStatus) {
      case REWARD_STATUS.UNPROCESSED:
        return "alerts.status.unprocessed";
      case REWARD_STATUS.PROCESSED:
        return "alerts.status.processed";
      default:
        return "alerts.status.unknow";
    }
  }

  private getDisplay(valueComponent: OComponent) {
    if (valueComponent?.code?.coding && valueComponent.code.coding.length) {
      return valueComponent.code.coding[0].display;
    }
  }

  public getActionTranslateKey(actions: ACTION_REWARD[], translateService: TranslateService): string {
    let resultToTranslate = "";
    const lastId = actions?.length - 1;
    actions?.forEach((action, id) => {
      switch (action) {
        case ACTION_REWARD.CHANGE_STATUS:
          resultToTranslate += translateService.instant("alerts.action.changeStatus") + (lastId !== id ? ", " : "");
          break;
        case ACTION_REWARD.ADD_NOTE:
          resultToTranslate += translateService.instant("alerts.action.addNote") + (lastId !== id ? ", " : "");
          break;
        default:
          resultToTranslate += translateService.instant("alerts.action.unknow") + (lastId !== id ? ", " : "");
          break;
      }
    });
    return resultToTranslate;
  }

  private changeStatus(status: REWARD_STATUS) {
    this.rewardStatus = status;
  }

  /**
   * return index of comment
   */
  public addComment(note: string): number {
    return (
      this.notes.push({
        text: note,
      }) - 1
    );
  }

  private setNewHistoric(action: ACTION_REWARD[], commentId?: number) {
    this.actionsNotesHistory.push({
      actionDate: moment().format(),
      action,
      commentId,
    });
  }

  public handleActionArray(actionArray: ACTION_REWARD[], status: REWARD_STATUS, comment: string): void {
    let commentId: number;
    actionArray.forEach((a) => {
      switch (a) {
        case ACTION_REWARD.CHANGE_STATUS:
          this.changeStatus(status);
          break;
        case ACTION_REWARD.ADD_NOTE:
          commentId = this.addComment(comment);
          break;
        default:
          break;
      }
    });
    this.setNewHistoric(actionArray, commentId);
  }
}
