<nav class="nav">
  <div>
    <h1 *ngIf="careplanEditorService.careplanTemplateReady$ | async" class="careplan-title">
      {{ careplanEditorService.careplanTemplateForm | getCareplanDescription : translateService.currentLang }}
    </h1>
  </div>
  <div class="btn-container save-btn-container">
    <p style="align-self: center" *ngIf="careplanEditorService.lastSaveDate">
      {{ "page.knowledgeMediaEditor.lastSave" | translate }}
      {{ careplanEditorService.lastSaveDate | date : "mediumTime" }}
    </p>
    <button class="btn save-btn" mat-stroked-button (click)="save()">
      <mat-icon aria-hidden="false">done</mat-icon> {{ "page.careplanEditor.btn.save" | translate }}
    </button>
    <button class="btn save-btn" mat-stroked-button (click)="save(true)" id="cpEditor-saveAndCloseBtn">
      <mat-icon aria-hidden="false">save</mat-icon>
      {{ "page.careplanEditor.btn.saveAndClose" | translate }}
    </button>
    <button class="btn save-btn" mat-stroked-button (click)="publish()" id="cpEditor-publishBtn">
      <mat-icon aria-hidden="false">publish</mat-icon>{{ "page.careplanEditor.btn.publish" | translate }}
    </button>
  </div>
</nav>
<div class="careplan-editor-page-content">
  <mat-drawer-container #drawer class="mat-elevation-z1">
    <mat-drawer mode="side" opened position="start">
      <div class="tab-links-container">
        <a class="tab-link" *ngFor="let tab of tabs; let i = index" (click)="selectTab(i)" [class.active-tab]="activeTabIndex === i">
          {{ "page.careplanEditor.tabs." + tab + ".name" | translate }}
        </a>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
