import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { Coding } from "src/app/models/coding.interface";
import { DataType, Filter } from "src/app/models/filter.interface";
import { IObservationDefinition, ObservationResume } from "src/app/models/observations.interface";
import { PatientUser } from "src/app/models/patient.interface";
import { PreferenceContext, WidgetPatientParameter } from "src/app/models/preference.interface";
import { Contained, Group, INPUT_TYPE, IQuestionnaire, QuestionQuestionnaire, SPECIFIC_USE } from "src/app/models/questionnaire.interface";
import { Answer, GroupResponse, Question } from "src/app/models/questionnaireResponse.interface";
import { QuestionnaireResponse } from "src/app/models/questionnaireResponse.model";
import { QuestionnaireScoring } from "src/app/models/questionnaireScoring.model";
import { ObservationsService } from "src/app/providers/observations.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { QuestionnairesService } from "src/app/providers/questionnaires.service";
import { PdfTypeChoiceComponent } from "../pdf-type-choice/pdf-type-choice.component";
import { QRDetailsDataSource } from "./QR-details-datasource";

@Pipe({ name: "questionAnswerStr" })
export class QuestionAnswerStrPipe implements PipeTransform {
  transform(reference: string, showAllQuestions: boolean, page: number, groupsResponse: GroupResponse[], pageNbr?: number): string {
    let question: Question;
    if (!showAllQuestions || pageNbr) {
      const p = pageNbr || pageNbr === 0 ? pageNbr : page;
      question = groupsResponse?.[p]?.question?.find((q) => q.linkId === reference);
    } else {
      question = groupsResponse?.[0]?.question?.find((q) => q.linkId === reference);
    }
    const answers: Answer[] = question?.answer;
    let display = "";
    answers?.forEach((a, i) => {
      display += a.valueCoding?.display ?? a.valueCoding?.code;
      if (i < answers.length - 1) {
        if ((question.inputType !== INPUT_TYPE.NUMBER && question.inputType !== INPUT_TYPE.DECIMAL) || (i > 0 && i % 2 !== 0))
          display += ", ";
        else display += " ";
      }
    });
    return display;
  }
}
@Pipe({ name: "questionAnswerCode" })
export class QuestionAnswerCodePipe implements PipeTransform {
  transform(reference: string, showAllQuestions: boolean, page: number, groupsResponse: GroupResponse[], pageNbr?: number): string {
    let question: Question;
    if (!showAllQuestions || pageNbr) {
      const p = pageNbr || pageNbr === 0 ? pageNbr : page;
      question = groupsResponse?.[p]?.question?.find((q) => q.linkId === reference);
    } else {
      question = groupsResponse?.[0]?.question?.find((q) => q.linkId === reference);
    }
    const answers: Answer[] = question?.answer;
    if (answers?.length) {
      return answers[0].valueCoding.code;
    } else {
      return reference;
    }
  }
}

@Pipe({ name: "valueSetChoices" })
export class ValueSetChoicesPipe implements PipeTransform {
  transform(reference: string, valueSets: Contained[]): Coding[] {
    if (valueSets) {
      for (const el of valueSets) {
        if (el.resourceType === "ValueSet" && el.idSet === reference) {
          return el.compose.include[0].concept;
        }
      }
    }
    return [];
  }
}

@Component({
  selector: "app-qr-details",
  templateUrl: "./qr-details.component.html",
  styleUrls: ["./qr-details.component.scss"],
})
export class QRDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  public viewType = "questionnaire";
  public withObservations = false;
  public linkedObservationsDef: IObservationDefinition[];
  public linkedObservations: ObservationResume[];
  public INPUT_TYPE = INPUT_TYPE;
  public nbSection = 1;
  public sections: { [pageNbr: number]: unknown[] } = {};
  public selectedSection: unknown[] = [];
  public page = 0;
  @ViewChild(MatTable) table: MatTable<QuestionQuestionnaire>;
  @ViewChild(MatSort) sort: MatSort;
  public displayedColumns: string[] = ["name", "answer"];
  public dataSource: QRDetailsDataSource;
  public patientId: string;
  public editElementCode: string;
  public questionnaire: IQuestionnaire;
  public questionnaireResponse: QuestionnaireResponse;
  public groups: Group[];
  public groupsResponse: GroupResponse[];
  public showAllQuestions = false;
  public isQuiz = false;

  /**
   * Filter
   */
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public globalSearchValue = "";
  public loading = true;

  public mergedCategory: QuestionnaireScoring[][];
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  public constructor(
    private preferenceService: PreferenceService,
    private questionnaireService: QuestionnairesService,
    private observationService: ObservationsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      QR: QuestionnaireResponse;
      scoring: QuestionnaireScoring[];
      pu?: PatientUser;
    }
  ) {
    this.patientId = this.data.QR.subject.reference;
  }

  ngOnInit(): void {
    this.questionnaireResponse = this.data.QR;
    this.loadPreferences();
    this.groupsResponse = this.questionnaireResponse?.group?.group?.length
      ? Tools.clone(this.questionnaireResponse?.group?.group)
      : Tools.clone([this.questionnaireResponse?.group]);
    this.dataSource = new QRDetailsDataSource(this.questionnaireResponse, this.groupsResponse);
    this.mergedCategory = this.getMergedScoringByCategory(this.data.scoring);
  }

  ngAfterViewInit(): void {
    this.initPaginatorAndSort();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initPaginatorAndSort(): void {
    this.dataSource.sort = this.sort;
    this.table.dataSource = this.dataSource;
  }

  private loadDataSource(questions: QuestionQuestionnaire[]): void {
    this.dataSource.loadData(questions, this.page);
    this.loading = false;
  }

  private async init(): Promise<void> {
    try {
      this.questionnaire = await this.questionnaireService.getQuestionnaire(
        this.data.QR.ref,
        this.data.QR.version,
        false,
        true,
        this.data.QR.questionnairePublicationDate
      );
      this.downloadLinkedObservationsDefs();
      this.downloadLinkedObservations();
      this.groups = this.questionnaire?.group?.group?.length
        ? Tools.clone(this.questionnaire?.group?.group)
        : Tools.clone([this.questionnaire?.group]);
      this.nbSection = this.groups.length;
      this.onShowAll(true);
      if (this.questionnaire.specificUse === SPECIFIC_USE.QUIZ) {
        this.isQuiz = true;
      }
      this.loading = false;
    } catch (err) {
      FileLogger.error("QRDetailsComponent", "Error while loading questionnaire: ", err);
    }
  }

  private downloadLinkedObservationsDefs(): void {
    const loincs = this.questionnaire?.observationLoincs?.map((o) => o.loinc);
    if (loincs?.length) {
      this.observationService
        .listDef(null, loincs)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (o: IObservationDefinition[]) => {
            this.linkedObservationsDef = o.filter(ArrayHelper.onlyUniqueLoincObsDef);
          },
          (err) => {
            FileLogger.error("QRDetailsComponent", "Error while downloading observations definitions", err, "none");
            this.linkedObservationsDef = [];
          }
        );
    }
  }

  private async downloadLinkedObservations(): Promise<void> {
    const questionnaireResponseRef = this.questionnaireResponse?.identifier.value;
    if (questionnaireResponseRef) {
      const fullObs = await this.observationService.getObservationsByQR(questionnaireResponseRef).catch((err) => {
        FileLogger.error("QRDetailsComponent", "Error while downloading observations", err, "none");
        return [];
      });
      this.linkedObservations = [];

      fullObs.forEach((obs) =>
        obs.component.forEach((obsComponent) => {
          if (obsComponent?.code?.coding && obsComponent.code.coding.length) {
            // prepare all availableObservations
            this.linkedObservations.push({
              // problem
              reference: obsComponent.code.coding[0].code,
              // we will need the parent's code to find the right definition:
              parentReference: obs.code.coding[0].code,
              display: obsComponent.code.coding[0].display,
              date: obs.issued,
              value: obsComponent.valueQuantity.value,
              unit: obsComponent.valueQuantity.unit,
              pictures: obsComponent.valuePictures,
              device: obs.device,
            });
          }
        })
      );
    }
  }

  public onShowAll(skipSave?: boolean): void {
    if (!skipSave) {
      this.updatePreference();
    }
    if (this.showAllQuestions) {
      this.groups[0].question = this.groups.reduce((acc, el) => {
        acc.push(...el.question);
        return acc;
      }, []);
      this.groupsResponse[0].question = this.groupsResponse.reduce((acc, el) => {
        acc.push(...el.question);
        return acc;
      }, []);
      this.loadDataSource(this.groups?.[0]?.question);
    } else {
      this.groupsResponse = this.questionnaireResponse?.group?.group?.length
        ? Tools.clone(this.questionnaireResponse?.group?.group)
        : Tools.clone([this.questionnaireResponse?.group]);
      this.groups = this.questionnaire?.group?.group?.length
        ? Tools.clone(this.questionnaire?.group?.group)
        : Tools.clone([this.questionnaire?.group]);
      this.loadDataSource(this.groups?.[this.page]?.question);
    }
  }

  public getGroupTitle(): string {
    return this.groups?.[this.page]?.title || null;
  }

  public changePage(sign: "-" | "+"): void {
    switch (sign) {
      case "-":
        if (!this.isFirstSection()) {
          this.page--;
          this.selectedSection = this.sections[this.page];
          this.loadDataSource(this.groups?.[this.page]?.question);
        }
        break;
      case "+":
        if (!this.isLastSection()) {
          this.page++;
          this.selectedSection = this.sections[this.page];
          this.loadDataSource(this.groups?.[this.page]?.question);
        }
        break;
      default:
        break;
    }
  }

  public isLastSection(): boolean {
    return this.page + 1 === this.nbSection;
  }

  public isFirstSection(): boolean {
    return this.page + 1 === 1;
  }

  public get isNoneData(): boolean {
    return this.dataSource?.data?.length === 0;
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
  }

  public clearFilter(): void {
    this.globalSearchValue = "";
    this.dataSource.clearFilter();
  }

  /**
   * Preferences
   */
  private updatePreference(): void {
    this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_QR_DETAILS,
        parameters: {
          filters: this.dataSource.getAllFilters(),
          allQresponse: this.showAllQuestions,
        } as WidgetPatientParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  private loadPreferences() {
    this.preferenceService
      .list(PreferenceContext.PATIENT_QR_DETAILS)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: WidgetPatientParameter) => {
        if (parameters) {
          // Apply saved filters
          parameters.filters.forEach((filter: Filter) => {
            this.applyFilter(filter);
          });
          this.showAllQuestions = parameters?.allQresponse;
        }
        this.init();
      });
  }

  private getMergedScoringByCategory(scorings: QuestionnaireScoring[]): QuestionnaireScoring[][] {
    const allCategories = this.uniq(scorings.map((s) => s.identifier?.value));
    const o = {};
    allCategories.forEach((cat) => (o[cat] = []));
    scorings.forEach((s) => {
      o[s.identifier?.value].push(s);
    });
    return Object.keys(o).reduce((acc, el) => {
      acc.push(o[el]);
      return acc;
    }, [] as QuestionnaireScoring[][]);
  }

  private uniq(a: string[]) {
    return Array.from(new Set(a));
  }

  public onExportPDF(): void {
    this.dialog.open(PdfTypeChoiceComponent, {
      data: {
        pu: this.data.pu,
        questionnaire: this.questionnaire,
        groupsResponse: this.groupsResponse,
        mergedCategory: this.mergedCategory,
        questionnaireResponse: this.questionnaireResponse,
      },
      disableClose: true,
    });
  }
}
