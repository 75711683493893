import { Address } from "./address.interface";
import { Codes } from "./codes.interface";
import { Coding } from "./coding.interface";
import { Entity } from "./entity.interface";
import { Identifier } from "./identifier.interface";
import { Name } from "./name.interface";
import { Practitioner } from "./practitioner.model";
import { Reference } from "./reference.interface";

export interface PractitionerRole {
  managingOrganization: Reference;
  role: Codes;
  specialty: Codes[];
  healthcareService: Reference[];
}

export interface Qualification {
  identifier: Identifier[];
  code: Codes;
}

/**
 * Interface for Practitioner
 */
export interface IPractitioner extends Entity {
  resourceType: string;
  identifier: Identifier[];
  active: boolean;
  valid?: boolean;
  name: Name;
  telecom: Identifier[];
  address: Address[];
  gender: string;
  birthDate: string;
  practitionerRole: PractitionerRole[];
  qualification: Qualification[];
  communication: Communication[];
  photo: string; // base64
  ansRole?: IAnsRole[];
}

export interface Communication {
  preferred: boolean;
  language: Codes;
}

export interface AddPractitionerData {
  activationCode: string;
  birthDate: string;
  firstname: string;
  name: string;
  inami: string;
  internalId: string;
  mail0: string;
  mailComment0: string;
  phone0: string;
  phoneComment0: string;
  organisation: Reference;
  services: Reference[];
  title: string;
  qualif: Coding;
  role: string;
  nationalNumber: string;
  isVisibleInApp: boolean;
  gender: string;
  needMail: boolean;
  userLang: "fr" | "en" | "nl";
  mail1?: string;
  mailComment1?: string;
  phone1?: string;
  phoneComment1?: string;
  mail2?: string;
  mailComment2?: string;
  phone2?: string;
  phoneComment2?: string;
  mail3: string;
  mailComment3?: string;
  phone3?: string;
  phoneComment3?: string;
  user?: Practitioner;
  active?: boolean;
  login?: string;
  accessGroups?: string[];
  ansId?: Identifier;
  ansPractitionerRole?: PractitionerRole[];
  msSante?: string;
  ansRole?: IAnsRole[];
}

export enum TELECOM_SYSTEM {
  PHONE = "phone",
  MAIL = "email",
  MSSANTE = "msSante",
}

export enum QUALIFICATIONS {
  NURSE_CODE = "106292003",
  DOCTOR_CODE = "309343006",
  SECRETARY_CODE = "394572006",
  PHARMACIST_CODE = "46255001",
  OTHER_CODE = "394738000",
}
export interface IPractitionerInfo {
  identifier: Identifier;
  title?: string;
  name?: string;
  firstname?: string;
  birthdate?: string;
  gender?: string;
  language?: string;
  nationalNumber?: string;
  practitionerReferenceNumber?: string; // INAMI for Belgium, RPPS for France, ...
  telecom?: Identifier;
  practitionerRole?: PractitionerRole[];
  ansRole: IAnsRole[];
}

export interface IAnsRole {
  specialty: string;
  profession: string;
  categorie: string;
}
