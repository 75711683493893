<div class="close-cross">
  <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="alertDetailsHistoric">
  <mat-card>
    <h3>{{ "alerts.historic" | translate }}</h3>
    <mat-card style="padding: 0px !important" [ngClass]="{ hide: historic?.length === 0 }">
      <table mat-table matSort matSortDirection="desc" matSortActive="date" [dataSource]="dataSource">
        <!-- date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "table.date" | translate }}
            <app-item-filter
              fieldName="{{ 'table.date' | translate }}"
              propertyName="date"
              [dataType]="dataTypeDate"
              [defaultValue]="getFilter('date')"
              (applyFilter)="applyFilter($event)"
            >
            </app-item-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.date | date : "short" }}
          </td>
        </ng-container>

        <!-- Author Column -->
        <ng-container matColumnDef="author">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "table.author" | translate }}
            <app-item-filter
              fieldName="{{ 'model.patient.name' | translate }}"
              propertyName="author.display"
              [dataType]="dataTypeText"
              [defaultValue]="getFilter('author.display')"
              (applyFilter)="applyFilter($event)"
            >
            </app-item-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.author?.display }}
          </td>
        </ng-container>

        <!-- value type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "table.type" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.def?.nameDashboard | getTranslation : element.code }}
          </td>
        </ng-container>

        <!-- Ex-value Column -->
        <ng-container matColumnDef="exValue">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "table.exValue" | translate }}
          </th>
          <td mat-cell *matCellDef="let element" [ngSwitch]="element.def?.type">
            <ng-container *ngSwitchCase="'number'">
              {{ element.exValue }}
              {{ element.def?.unit | getTranslation : "" }}
            </ng-container>
            <ng-container *ngSwitchCase="'array'">
              <button
                mat-icon-button
                matTooltip="{{ 'vitalSigns.viewArrayTooltip' | translate }}"
                (click)="openValueArrayDialog(element, element.exValue)"
              >
                <i class="fas fa-table"></i>
              </button>
            </ng-container>
          </td>
        </ng-container>

        <!-- New-value Column -->
        <ng-container matColumnDef="newValue">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "table.newValue" | translate }}
          </th>
          <td mat-cell *matCellDef="let element" [ngSwitch]="element.def?.type">
            <ng-container *ngSwitchCase="'number'">
              {{ element.newValue }}
              {{ element.def?.unit | getTranslation : "" }}
            </ng-container>
            <ng-container *ngSwitchCase="'array'">
              <button
                mat-icon-button
                matTooltip="{{ 'vitalSigns.viewArrayTooltip' | translate }}"
                (click)="openValueArrayDialog(element, element.newValue)"
              >
                <i class="fas fa-table"></i>
              </button>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator class="borderBox" #paginator [pageIndex]="0" [pageSize]="5" [pageSizeOptions]="[5, 10, 15]" [hidePageSize]="false">
      </mat-paginator>
    </mat-card>
    <p class="text-center" *ngIf="historic?.length === 0">
      {{ "widget.noData" | translate }}
    </p>
  </mat-card>
</div>
