import { Pipe, PipeTransform } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Pipe({
  name: "getAddressName",
})
export class GetAddressNamePipe implements PipeTransform {
  transform(addressesFormGroup: UntypedFormGroup, currentLang: string, _lastKeystroke?: string): string {
    const display: string = addressesFormGroup
      .get("display")
      ?.get("traductions")
      ?.value.find((v) => v.lang === currentLang)?.translateKey;

    return display;
  }
}
