<ng-container *ngIf="!noData">
  <div class="flex" [ngClass]="{ reversed: reversed }" *ngFor="let stat of statsObsGroups.statistics">
    <ng-container [ngSwitch]="stat.keyName">
      <div *ngSwitchCase="'stat.countBetweenRange'" style="width: 100%">
        <ng-container *ngIf="countBetweenRangeData?.length">
          <div>{{ stat.keyName | translate }}</div>
          <div class="flex mt-1" style="gap: 1rem; justify-content: center">
            <div *ngFor="let data of countBetweenRangeData" style="text-align: center">
              <div class="value">{{ data.value | percent }}</div>
              <div class="label">{{ data.label }}</div>
            </div>
          </div>
        </ng-container>

        <p *ngIf="!countBetweenRangeData?.length">-</p>
      </div>

      <ng-container *ngSwitchDefault>
        <div>{{ stat.keyName | translate }}</div>
        <div *ngIf="stat.values?.length" class="value">
          {{ stat.values[0].valueQuantity.value | number : "1.0-2" }}
          {{ stat.values[0].valueQuantity.unit ? stat.values[0].valueQuantity.unit : "" }}
        </div>
        <p *ngIf="!stat.values?.length">-</p>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<p *ngIf="noData">
  {{ "common.noData" | translate }}
</p>
