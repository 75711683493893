import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { DataType, Filter } from "../models/filter.interface";
import { FilterHelper } from "./filterHelper";
import { Tools } from "./tools";

/**
 * Extend MatTableDataSource to add Filter management
 */
export abstract class MatTableDataSourceExtended<T> extends MatTableDataSource<T> {
  public filterHelper = new FilterHelper();

  filterPredicate = (data: T, _filter: string): boolean => {
    return this.filterHelper.filters.every((f) => {
      const propertyValue = f.propertyName
        .split(".")
        .reduce((p, c) => (p && p[c] !== undefined && ((p[c] instanceof Function && p[c]()) || p[c])) || null, data);

      // Zero can be a value for propertyValue (user status as exemple)
      if ((propertyValue !== undefined && propertyValue !== null) || f.combinedProperties) {
        switch (f.dataType) {
          case DataType.TEXT:
            if (f.data && f.data.value) {
              return Tools.suppressDiacritics(propertyValue.toString())
                .toLowerCase()
                .includes(Tools.suppressDiacritics(f.data.value).toLowerCase());
            }
            break;
          case DataType.DATE:
            if (moment(propertyValue).isValid() && f.data && f.data.fromDate && f.data.toDate) {
              return moment(propertyValue).isSameOrAfter(f.data.fromDate, "D") && moment(propertyValue).isSameOrBefore(f.data.toDate, "D");
            }
            break;
          case DataType.CHOICE:
            if (f.data) {
              return f.data
                .filter((choice) => choice.checked)
                .map((choice) => choice.value.toString())
                .includes(propertyValue.toString());
            }
            break;
          case DataType.COMBINED:
            return f.combinedProperties.some((field) => {
              const value = field.split(".").reduce((p, c) => (p && p[c]) || null, data);
              return Tools.suppressDiacritics(value as string)
                ?.toLowerCase()
                .includes(Tools.suppressDiacritics(f.data).toLowerCase());
            });
          default:
            return this.filtredPredictedDefault(f, propertyValue);
        }
        // if no value, we assume value don't fit in filter
      } else if (!propertyValue) {
        return false;
      }
      return true;
    });
  };

  public filtredPredictedDefault(_f: Filter, _propertyValue: unknown): boolean {
    return false;
  }

  public setFilter(filter: Filter): void {
    this.filterHelper.setFilter(filter);
    // MatTableDataSource process
    this.filter = JSON.stringify(this.filterHelper.filters);
  }

  public getFilter(propertyName: string): Filter {
    return this.filterHelper.getFilter(propertyName);
  }

  public getAllFilters(): Filter[] {
    return this.filterHelper.filters;
  }

  public clearFilter(): void {
    this.filterHelper.clearFilter();
    // MatTableDataSource process
    this.filter = "";
  }
}
