import * as moment from "moment";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { IQuestionnaireHistoryEvent } from "src/app/models/questionnaire.interface";

export class QuestionnaireHistoryDataSource extends MatTableDataSourceExtended<IQuestionnaireHistoryEvent> {
  sortingDataAccessor = (data: IQuestionnaireHistoryEvent, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "date":
        return moment(data.date).unix();
      case "author":
        return data.author;
      default:
        return "";
    }
  };

  public loadData(data: IQuestionnaireHistoryEvent[]): void {
    this.data = data;
  }
}
