import { animate, state, style, transition, trigger } from "@angular/animations";
import { CdkDrag, CdkDragDrop, CdkDropList } from "@angular/cdk/drag-drop";
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AVAILABLE_ACTIONS } from "src/app/careplan-editor/data/careplan-editor.enum";
import { IlinkableItem, IlinkableQuestionnaireItem } from "src/app/careplan-editor/domain/IlinkableItem.interface";
import { Tools } from "src/app/helpers/tools";
import { ACTION_TARGET, ICareplan } from "src/app/models/careplans.interface";
import { Reference } from "src/app/models/reference.interface";
import { AccessLevel } from "src/app/models/sharedInterfaces";
import { DragAndDropService } from "src/app/providers/drag-and-drop.service";
import { QuestionnairesService } from "src/app/providers/questionnaires.service";

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: "app-linkable-items-list",
  templateUrl: "./linkable-items-list.component.html",
  styleUrls: ["./linkable-items-list.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ width: "100%", height: "0px", minHeight: "0" })),
      state("expanded", style({ width: "100%", paddingTop: "1rem", paddingBottom: "1rem", height: "*" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
})
export class LinkableItemsListComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() careplanTemplate?: ICareplan;
  @Input() id: string; // Unique ID to identify the drop zone.
  @Input() connectedTo: string | string[]; // Id of the drop zone to connect to
  @Input() items: IlinkableItem[]; // the elements of the table

  @Input() linkedToCareplanItems: IlinkableItem[]; // list of all items linked to the careplan itself
  @Input() linkedToActivitiesItems: IlinkableItem[]; // list of all items linked to the activities

  @Output() itemsChange = new EventEmitter<{ items: IlinkableItem[]; item: IlinkableItem; action?: AVAILABLE_ACTIONS }>(); // event emitter to track changes on the elements list. If no action specified, imply it's a drop
  @Input() columns: string[]; // array of columns name to loop on to create the columns of the table
  @Input() actions: AVAILABLE_ACTIONS[]; // List of actions to loop on to generate the actions buttons in the "actions" column
  @Input() isTarget: boolean; // define if the list is the target or the source of linkable items

  // @TODO: replace unknown by the interface from the careplantemplateForm as defined by CMATE-5829
  @Input() activityReference: unknown; // if the list is the one present in an activity, it has an activityReference in order to set the originReference of the linkableItem
  @ViewChild("table", { static: false }) table: MatTable<IlinkableItem>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  private onDestroy$ = new Subject<void>();
  public AVAILABLE_ACTIONS = AVAILABLE_ACTIONS;
  public dataSource: MatTableDataSource<IlinkableItem>;
  public displayedColumns: string[];
  public expandedElement: IlinkableItem;
  public action_target = ACTION_TARGET;

  constructor(
    public dragAndDropService: DragAndDropService,
    private router: Router,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private questionnairesService: QuestionnairesService
  ) {
    this.alreadyInPredicate = this.alreadyInPredicate.bind(this);
  }

  public ngOnInit(): void {
    this.dragAndDropService.refreshTables$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.dataSource.paginator = this.paginator;
    });

    this.dataSource = new MatTableDataSource(this.items);
    if (this.columns?.length) this.displayedColumns = ["grip", ...this.columns];
    // Add the "actions" column if there is some actions available. All actions must be handled inside the onAction using the AVAILABLE_ACTIONS to handle them
    if (this.actions?.length) this.displayedColumns = [...this.displayedColumns, "actions"];
  }

  public ngOnChanges(): void {
    // Add the updated items to dataSource, needed in case of adding a new observation
    if (this.dataSource?.data) this.dataSource.data = this.items;
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator; // Connect paginator to data source
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Handles the drop event for drag and drop functionality.
   * @param event - The drag and drop event.
   */
  public drop(event: CdkDragDrop<never>): void {
    const item = event.item.data as IlinkableItem;
    // The item dropped from the list of available items doesn't have the origin nor the originReference
    // so we add it before handling the drop if the item is dropped under an activity
    if (Tools.isDefined((this.activityReference as Reference)?.reference)) {
      item.originReference = { reference: (this.activityReference as Reference)?.reference, display: "" };
      item.origin = "activity";
    } else {
      item.origin = "link2careplan";
    }

    const canBeDropped = this.checkIfCanBeDropped(item);

    if (canBeDropped && event.previousContainer !== event.container) {
      // Delegate the drop event handling to the dragAndDropService
      this.dragAndDropService.drop(event);
      // Emit an event with the updated items after the drop operation
      this.itemsChange.emit({ items: this.dataSource.data, item: item });
    }
  }

  /**
   * Predicate function to determine if the dragged item is already present in the drop list.
   * @param drag - The dragged item.
   * @param drop - The drop list.
   * @returns True if the dragged item is not already present in the drop list, otherwise false.
   */
  public alreadyInPredicate(drag: CdkDrag<IlinkableItem>, drop: CdkDropList): boolean {
    return !drop.data.some((d: IlinkableItem) => d.uniqueId === drag.data.uniqueId);
  }

  public onAction(action: AVAILABLE_ACTIONS, element: IlinkableItem): void {
    if (action === AVAILABLE_ACTIONS.unlink) this.unlinkElement(element);
    if (action === AVAILABLE_ACTIONS.configure) this.configureElement(element);
    if (action === AVAILABLE_ACTIONS.edit) this.editElement(element);
  }

  /**
   * Removes the specified element from the data source.
   * @param element - The element to be unlinked.
   */
  public unlinkElement(element: IlinkableItem): void {
    // Find the index of the element in the data source
    const index = this.dataSource.data.findIndex((el: IlinkableItem) => {
      return el.uniqueId === element.uniqueId;
    });

    // Remove the element if found
    if (index !== -1) {
      this.dataSource.data.splice(index, 1);
      // Trigger a table refresh through the dragAndDropService
      this.dragAndDropService.triggerTableRefresh();
      // Emit an event with the updated items after unlinking the element to update the link2Careplan form
      this.itemsChange.emit({ items: this.dataSource.data, item: element, action: AVAILABLE_ACTIONS.unlink });
    }
  }

  public configureElement(element: IlinkableItem): void {
    this.expandedElement = this.expandedElement === element ? null : element;
  }

  public editElement(element: IlinkableItem): void {
    if (element.itemType === ACTION_TARGET.KNOWLEDGE) {
      this.router.navigate(["/knowledgeDetails", { id: element.uniqueId }], { state: { previousLocation: this.router.url } });
    }
    if (element.itemType === ACTION_TARGET.QUESTIONNAIRE) {
      const el = element as IlinkableQuestionnaireItem;
      this.questionnairesService.editQuestionnaire(el.uniqueId, el.version, el.isDraft, AccessLevel.READ, this.router.url);
    }
  }

  /**
   * Checks if an item can be dropped in the list based on its origin and association with other items.
   * @param item - ILinkableItem
   */
  public checkIfCanBeDropped(item: IlinkableItem): boolean {
    let canBeDropped = false;
    const errorMessage =
      item.origin === "activity"
        ? "page.careplanEditor.error.alreadyPresentInCareplan"
        : "page.careplanEditor.error.alreadyPresentInActivities";

    const linkedItems = item.origin === "activity" ? this.linkedToCareplanItems : this.linkedToActivitiesItems;

    if (!linkedItems.find((el) => el.uniqueId === item.uniqueId)) {
      canBeDropped = true;
    } else {
      this.snackBar.open(this.translateService.instant(errorMessage), "ok", { duration: 5000 });
    }
    return canBeDropped;
  }
}
