import { animate, state, style, transition, trigger } from "@angular/animations";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subject, Subscription } from "rxjs";
import { first, skipWhile, takeUntil } from "rxjs/operators";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { Item } from "src/app/components/item-selector/item-selector.component";
import { HelpData } from "src/app/helpers/helpData";
import { IInfoCommunication } from "src/app/models/communications.interface";
import { InfoCommunication } from "src/app/models/communications.model";
import { DataType, Filter } from "src/app/models/filter.interface";
import { PreferenceContext, TableParameter } from "src/app/models/preference.interface";
import { Reference } from "src/app/models/reference.interface";
import { CommunicationsCrudService } from "src/app/providers/communications-crud.service";
import { CommunicationsService } from "src/app/providers/communications.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { CommunicationsListDataSource } from "./communications-list-datasource";
import { SubCommunicationsListDataSource } from "./sub-communications-list-datasource";

@Pipe({ name: "isBasedOnCareplan" })
export class IsBasedOnCareplanPipe implements PipeTransform {
  transform(com: InfoCommunication): boolean {
    return com.basedOn && com.basedOn.findIndex((ref) => ref.type === "careplan") !== -1;
  }
}

@Pipe({ name: "getDataSource" })
export class GetDataSource implements PipeTransform {
  transform(
    com: InfoCommunication,
    sort: MatSort,
    dataSource: CommunicationsListDataSource,
    subDatasSources: { [key: string]: SubCommunicationsListDataSource }
  ): SubCommunicationsListDataSource {
    if (dataSource?.allDatas && com.isGrouped) {
      if (!subDatasSources[com.selfId]) {
        const allComsFromGroup = dataSource?.allDatas.filter((c) => c.selfId === com.selfId);
        const data = new SubCommunicationsListDataSource(allComsFromGroup, com.selfId);
        data.sort = sort;
        subDatasSources[com.selfId] = data;
      }
      return subDatasSources[com.selfId];
    }
  }
}

@Component({
  selector: "app-communications-page",
  templateUrl: "./communications-page.component.html",
  styleUrls: ["./communications-page.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
})
export class CommunicationsPageComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Material table
   */
  @ViewChild("MatPaginatorMain") paginator: MatPaginator;
  @ViewChild("MatSortMain") sort: MatSort;
  @ViewChild("MatTableMain") table: MatTable<IInfoCommunication>;

  public panelOpenState = false;
  public expandedArray: number[] = [];
  public maxFromDate: string;
  public minToDate: string;
  public today = moment();
  public expandedElement: InfoCommunication | null;
  public displayedColumnsSub: string[] = ["statusSub", "receivedDateSub", "sendToSub", "actionSub"];
  public displayedColumns: string[] = [];
  public filterFormTable = this.fb.group({
    fromDate: new UntypedFormControl(moment().subtract(3, "month"), {
      validators: Validators.required,
      updateOn: "blur",
    }),
    toDate: new UntypedFormControl(moment().endOf("day"), {
      validators: Validators.required,
      updateOn: "blur",
    }),
  });
  /**
   * Filter
   */
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;

  public subDatasSources: { [key: string]: SubCommunicationsListDataSource } = {};

  public currentPageSize: number;
  public dataSource: CommunicationsListDataSource;
  public availableColumnItems: Item[] = [];
  public subIsInit = false;
  public refreshSubLoading: Subscription;
  public isAllServices = true;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    private communicationsService: CommunicationsService,
    private translateService: TranslateService,
    private sessionService: SessionService,
    public helpData: HelpData,
    private fb: UntypedFormBuilder,
    private preferenceService: PreferenceService,
    private cd: ChangeDetectorRef,
    private crud: CommunicationsCrudService,
    private dialog: MatDialog,
    private userService: UserService,
    private depService: HealthcareserviceService
  ) {
    this.loadPreferences();
    // Define all available columns and translate display
    this.translateService
      .get(["model.patient.status", "table.sendDate", "table.receivedDate", "table.sendTo", "table.subject", "table.action"])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((trans: string[]) => {
        this.availableColumnItems = [
          {
            value: "status",
            display: trans["model.patient.gender"],
            checked: true,
          },
          {
            value: "sendDate",
            display: trans["table.sendDate"],
            checked: true,
          },
          {
            value: "receivedDate",
            display: trans["table.receivedDate"],
            checked: true,
          },
          { value: "sendTo", display: trans["table.sendTo"], checked: true },
          { value: "subject", display: trans["table.subject"], checked: true },
          { value: "action", display: trans["table.action"], checked: true },
        ];
        this.displayedColumns = this.getDisplayedColumns();
      });
    this.maxFromDate = moment().endOf("day").format("YYYY-MM-DD");
    this.minToDate = moment().subtract(3, "month").format("YYYY-MM-DD");
    if (
      this.sessionService.currentService &&
      this.userService.ownOrganization &&
      (this.depService.availableServices()?.length > 0 || this.depService.availableMonitoringServices().length > 0)
    ) {
      this.loadData();
      this.setupServicesWatch();
    } else {
      this.sessionService.servicesSetupWatch
        .pipe(
          skipWhile(() => !this.userService.ownOrganization),
          first()
        )
        .subscribe(() => {
          this.loadData();
          this.setupFiltersWatch();
          this.setupDatasource();
          this.setupServicesWatch();
        });
    }
  }

  ngOnInit(): void {
    this.displayedColumns = this.getDisplayedColumns();
    this.sessionService.refreshCommunicationsList.subscribe(() => {
      this.loadData();
      this.setupFiltersWatch();
      this.setupDatasource();
      this.setupServicesWatch();
    });
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.setupFiltersWatch();
      this.setupDatasource();
    }
  }

  ngOnDestroy(): void {
    this.dataSource?.clear();
    this.refreshSubLoading?.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setupFiltersWatch() {
    this.filterFormTable
      .get("fromDate")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.updateDate());
    this.filterFormTable
      .get("toDate")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.updateDate());
  }

  private setupDatasource(): void {
    if (!this.dataSource) {
      return;
    }
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;

    // Detect page size change
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((page) => {
      if (this.currentPageSize !== page.pageSize) {
        this.updatePreference();
      }
    });
  }

  private setupServicesWatch(): void {
    if (this.userService.isMonitoringUser) {
      this.setupServices();
      this.sessionService.refreshCurrentMonitService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.setupServices();
        this.dataSource?.loadCommunications(this.minToDate, this.maxFromDate, this.preferenceService.getAutoRefreshWidget());
        this.refreshSubLoading?.unsubscribe();
        this.refreshSubLoading = this.dataSource?.loading$.subscribe((loading) => {
          if (!loading) {
            this.subDatasSources = {};
          }
        });
      });
    } else {
      this.setupServices();
      this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.setupServices();
        this.dataSource?.loadCommunications(this.minToDate, this.maxFromDate, this.preferenceService.getAutoRefreshWidget());
        this.refreshSubLoading?.unsubscribe();
        this.refreshSubLoading = this.dataSource?.loading$.subscribe((loading) => {
          if (!loading) {
            this.subDatasSources = {};
          }
        });
      });
    }
  }

  private setupServices(): void {
    if (
      (!this.userService.isMonitoringUser && !this.sessionService.currentService) ||
      (this.userService.isMonitoringUser && !this.sessionService.currentMonitoringService)
    ) {
      this.isAllServices = true;
      return;
    }
    this.isAllServices = this.sessionService.isAllServices(this.userService.isMonitoringUser);
  }

  public getDisplayedColumnsSub(communication: InfoCommunication): string[] {
    return this.displayedColumnsSub.map((v) => v + communication.selfId);
  }

  private getDisplayedColumns(): string[] {
    return ["expand", ...this.availableColumnItems?.filter((item) => item.checked).map((item) => item.value)];
  }

  private loadData(): void {
    if (!this.userService.isAuthorizedSync(null, "dashboard/communications", "GET")) {
      return;
    }
    // Load data
    this.dataSource = new CommunicationsListDataSource(
      this.communicationsService,
      this.sessionService,
      this.translateService,
      this.userService,
      this.depService
    );
    this.init(this.minToDate, this.maxFromDate);
  }

  private init(from: string, to: string): void {
    this.dataSource?.loadCommunications(from, to, this.preferenceService.getAutoRefreshWidget());
    this.refreshSubLoading?.unsubscribe();
    this.refreshSubLoading = this.dataSource?.loading$.subscribe((loading) => {
      if (!loading) {
        this.subDatasSources = {};
      }
    });
  }

  public expand(com: InfoCommunication): void {
    this.expandedElement = this.expandedElement?.selfId === com?.selfId ? null : com;
    this.cd.detectChanges();
  }

  public isExpand(com: InfoCommunication): boolean {
    return this.expandedElement?.selfId === com.selfId;
  }

  /**
   * Drag n'Drop
   */
  public columnDropped(event: CdkDragDrop<string[]>): void {
    const realIndexFrom = this.availableColumnItems.findIndex((item) => this.displayedColumns[event.previousIndex] === item.value);
    const realIndexTo = this.availableColumnItems.findIndex((item) => this.displayedColumns[event.currentIndex] === item.value);
    moveItemInArray(this.availableColumnItems, realIndexFrom, realIndexTo);
    this.updatePreference();
  }

  /**
   * Preferences
   */
  private updatePreference(): void {
    this.preferenceService
      .update({
        context: PreferenceContext.COMMUNICATIONS_LIST_PAGE,
        parameters: {
          filters: this.dataSource?.getAllFilters(),
          availableColumnItems: this.availableColumnItems,
          itemsPerPage: this.paginator.pageSize,
        } as TableParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  private loadPreferences(): void {
    this.preferenceService
      .list(PreferenceContext.COMMUNICATIONS_LIST_PAGE)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: TableParameter) => {
        if (parameters) {
          if (parameters?.filters?.length) {
            // Apply saved filters
            parameters.filters.forEach((filter: Filter) => {
              this.applyFilter(filter);
            });
          }
          // Set page size from preference
          this.currentPageSize = parameters.itemsPerPage;

          // Restore column order + visibility
          this.availableColumnItems = parameters.availableColumnItems;
          this.displayedColumns = this.getDisplayedColumns();
        }
      });
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string, id?: string): Filter {
    if (!id) {
      return this.dataSource?.getFilter(propertyName);
    } else {
      return this.subDatasSources[id]?.getFilter(propertyName);
    }
  }

  public applyFilter(filter: Filter, id?: string): void {
    if (!id) {
      this.dataSource?.setFilter(filter);
      this.updatePreference();
    } else {
      this.subDatasSources[id]?.setFilter(filter);
    }
  }

  public clearFilter(): void {
    this.dataSource?.clearFilter();
    this.updatePreference();
  }

  private updateDate(): void {
    const from = moment(this.filterFormTable.get("fromDate").value).format("YYYY-MM-DD");
    const to = moment(this.filterFormTable.get("toDate").value).format("YYYY-MM-DD");
    this.maxFromDate = moment(to).toISOString();
    this.minToDate = moment(from).toISOString();
    if (from && to) {
      this.dataSource?.loadCommunications(from, to, this.preferenceService.getAutoRefreshWidget());
      this.refreshSubLoading?.unsubscribe();
      this.refreshSubLoading = this.dataSource?.loading$.subscribe((loading) => {
        if (!loading) {
          this.subDatasSources = {};
        }
      });
    }
  }

  public viewCommunication(communication: InfoCommunication): void {
    this.crud.viewCommunication(communication);
  }

  public deleteCommunication(communication: InfoCommunication, allSimilar: boolean): void {
    let allRefs: Reference[] = [];
    if (allSimilar) {
      allRefs = this.dataSource?.allDatas.filter((v) => v.selfId === communication.selfId)?.map((v) => v.subject);
    } else {
      allRefs = [communication.subject];
    }
    this.crud.deleteCommunication(communication, allRefs);
  }

  public editCommunication(infoCom: InfoCommunication, fromMainCom?: boolean): void {
    this.crud.editCommunication(infoCom, this.dataSource?.allDatas, fromMainCom);
  }

  public createCommunication(): void {
    this.crud.createCommunication();
  }

  public openCommunicationPageHelp(): void {
    this.dialog.open(GlobalHelpDialogComponent, {
      data: { slides: this.helpData.CommunicationPageHelp },
      disableClose: true,
    });
  }
}
