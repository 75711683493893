import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FileLogger } from "../helpers/fileLogger";
import { IChartBase64Lang, IPredictionStatus, IRiskAssessment } from "../models/riskAssessment.interface";
import { RiskAssessment } from "../models/riskAssessment.model";
import { RiskAssessmentApiService } from "./api/risk-assessments-api.service";

@Injectable({
  providedIn: "root",
})
export class RiskAssessmentService {
  constructor(private apiService: RiskAssessmentApiService) {}

  public listForPatient(caremateId: string): Observable<RiskAssessment[]> {
    return this.apiService.listForPatient(caremateId);
  }

  public getChartExplainer(project: string, algo: string, version: string, lang: string): Observable<{ shap: IChartBase64Lang[] }> {
    return this.apiService.getChartExplainer(project, algo, version, lang);
  }

  public getPdfByLang(risk: IRiskAssessment, version: string): Observable<Blob> {
    const index = risk.about.findIndex((about) => about.type.includes(`/pdf_${version}`));
    let token: string;
    if (index) {
      token = risk.about[index].reference;
    } else {
      return;
    }
    return this.apiService.downloadReport(token);
  }

  public openPdf(risk: IRiskAssessment, lang: string, errorMsg: string): void {
    this.getPdfByLang(risk, lang).subscribe(
      (blob) => {
        if (blob.type === "application/json") {
          throw new Error(errorMsg);
        } else {
          const url = URL.createObjectURL(blob);
          window.open(url);
        }
      },
      (error) => {
        FileLogger.error("RiskAssessmentService", "openPdf", error);
        throw new Error(errorMsg);
      }
    );
  }

  public importPredictionFile(formData: FormData): Observable<null> {
    return this.apiService.importPredictionFile(formData);
  }

  public getPredictionState(patientId: string): Observable<IPredictionStatus[]> {
    return this.apiService.getPredictionState(patientId);
  }
}
