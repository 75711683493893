import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { RewardDefinition } from "src/app/models/rewardDefinition.model";
import { RewardScore } from "src/app/models/rewardScore.model";
import { RewardScoreService } from "src/app/providers/rewardScore.service";

export class PatientRewardDataSource extends MatTableDataSourceExtended<RewardScore> {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private refreshInterval;
  public loading$ = this.loadingSubject.asObservable();
  public rewardDefinitions: RewardDefinition[];
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  sortingDataAccessor = (data: RewardScore, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "objectifName":
        return this.getTargetName(data.rewardDefinitionReference.reference);
      case "objectifStart":
        return moment(data.start).unix();
      case "objectifEnd":
        return moment(data.end).unix();
      case "objectifProgress":
        return data.currentScore;
      case "rewardStatut":
        return data.hasBeenRewarded.toString();
      case "rewardHistory":
        return data.rewardReceived + moment(data.rewardReceivedDate).unix();
      default:
        return "";
    }
  };

  constructor(protected rewardScoreService: RewardScoreService, private translateService: TranslateService) {
    super();
  }

  public get loading(): boolean {
    return this.loadingSubject.value;
  }

  public loadRewardsPage(
    fromDate: string,
    toDate: string,
    onlyInProgress: boolean,
    patientId: string,
    autoRefreshIntervalMs = 60000
  ): void {
    this.loadingSubject.next(true);
    if (this.refreshInterval) {
      this.clearAutoRefresh();
    }
    this.refreshInterval = setInterval(() => {
      this.loadData(fromDate, toDate, onlyInProgress, patientId);
    }, autoRefreshIntervalMs);
    this.loadData(fromDate, toDate, onlyInProgress, patientId);
  }

  private clearAutoRefresh() {
    clearInterval(this.refreshInterval);
  }

  public clear(): void {
    this.clearAutoRefresh();
    this.loadingSubject.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadData(fromDate: string, toDate: string, onlyInProgress: boolean, patientId: string) {
    this.rewardScoreService
      .listRewardScoresByPatient(patientId, fromDate, toDate, onlyInProgress)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.data = res;
        this.loadingSubject.next(false);
      });
  }

  public getTargetName(reference: string): string {
    return this.getTargetDef(reference)?.rewardDisplayName?.[this.translateService.currentLang] || "-";
  }

  private getTargetDef(reference: string) {
    return this.rewardDefinitions.find((d) => FHIRHelper.getMainIdentifier(d)?.value === reference);
  }
}
