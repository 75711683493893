import { Codes } from "./codes.interface";
import { Coding } from "./coding.interface";
import { ISimplifiedCommunication } from "./communications.interface";
import { CycleSchema, Entity } from "./entity.interface";
import { Identifier } from "./identifier.interface";
import { IKnowledgeInfo } from "./knowledge.interface";
import { Period } from "./period.interface";
import { Reference } from "./reference.interface";
import {
  EnableWhenBehavior,
  IEnableWhen,
  ILocation,
  IPeriod,
  IReference,
  IScheduledTiming,
  ISubCriteria,
  ITiming,
} from "./sharedInterfaces";
import { IStepwiseDrug } from "./stepwiseDrug.interface";
import { ITranslation } from "./translation.interface";

export interface ICareplanTemplate {
  provider: Reference; // organization owning these careplans
  careplans: ICareplan[];
  performers: Performer[]; // careprovider performing these activity careplan, group by healthcare service
}

export interface Performer {
  healthcareService: Reference;
  practitioners: Reference[];
}

export interface Progress {
  text: string;
  authorReference: Reference;
  time: string;
}

export interface Detail {
  status: string;
  location: ILocation | Reference; // custom Location instead of Reference
  description: string;
  prohibited?: boolean; // generate appointment or not (default: true => generate)
  category: Codes;
  code: Codes;
  goal?: Goal[];
  productReference?: Reference;
  // reference to protocol hormonotherapy: Novaldex, Arimidex, Femara. Chimio: FEC, Taxol, Taxotere, etc. Immuno: Herceptine
  productTemplateList?: Reference[]; // NOT FHIR: list of possible products. Used in template
  performer?: Reference[];
  scheduledTiming?: IScheduledTiming; // ex: 'ME' means drug has to  be taken in morning and evening
  scheduledPeriod?: Period;
  scheduledString?: string;
  isDate?: boolean; // WARNING! This is only needed on the dashboard to check if scheduledString is a date or dateTime format
  quantity?: IQuantity;
  dailyAmount?: IQuantity;
  cycle?: CycleSchema; // WARNING! This is only needed if the activity has a drug
  // it's to help link changes between careplan cycle and entitylink drugs cycle
  // DO NOT USE this to get the drug cycle !!! Download the entityDrug to get the cycle !!!
  stepwise?: IStepwiseDrug; // WARNING! This is only needed if the activity has a drug
  // it's to help link changes between careplan stepwise and entitylink drugs stepwise
  // DO NOT USE this to get the drug stepwise !!! Download the entityDrug to get the stepwise !!!
  comment?: string; // WARNING! This is only needed if the activity has a drug
  // it's to help link changes between careplan comment and entitylink drugs comment
  // DO NOT USE this to get the drug comment !!! Download the entityDrug to get the comment !!!
}

export interface Goal extends Entity {
  identifier?: Identifier;
  status: string;
  statusDate?: string;
  category?: Codes;
  priority?: Codes;
  description: Coding;
  subject?: Reference;
  startDate?: string;
  target?: {
    measure?: Coding;
    detailQuantity?: IQuantity;
    detailRange?: Range;
    detailCodeableConcept?: Coding;
    dueDate?: string;
    dueDuration?: string;
  };
  statusReason?: string;
  expressedBy?: Reference;
  addresses?: Reference[];
  note?: Notation[]; // instructions
  outcomeCode?: Codes;
  outcomeReference?: Reference[]; // reference to Observations
}

export interface Notation {
  authorReference?: Reference;
  time?: string;
  text: string;
}

export interface Quantities {
  rise: string;
  morning: string;
  noon: string;
  evening: string;
  bedtime: string;
}

export interface IQuantity {
  code?: string;
  value: number;
  valueArray?: number[][]; // homemade fields to manage Glucose
  system?: string;
  unit?: string;
}
/**
 * Careplan Activity
 */
export interface Activity {
  _id: string;
  actionResulting?: IOrder[]; // link a sub-Activity to an careplan Activity with specific timing (Day-1, Day+2, etc.)
  progress?: Progress[];
  reference?: Reference;
  detail?: Detail;
}

/**
 * Inteface to link a sub-Activity to an careplan Activity with specific timing (Day-1, Day+2, etc.)
 */
export interface IOrder {
  identifier: Identifier;
  status: string; // same as activity
  date?: string; // 	When the order was made
  // subject:Reference;     // Patient this order is about
  source?: Reference; // Who initiated the order
  target?: Reference; // Who is intended to fulfill the order
  reasonCodeableConcept?: Coding; // why the order was made
  when: ITiming; // 	When order should be fulfilled
  location?: Location; // What action is being ordered
  detail: IOrderAction; // where action should be fulfilled,
  notDisplay?: boolean; // indicates if this action should be displayed on the dashboard. If not present, it is displayed
  enableWhen?: IEnableWhen[]; // general criteria to display a knowledge
  enableWhenBehavior?: EnableWhenBehavior; // ET | OU
  subCriteria?: ISubCriteria[];
}

/**
 * Request/order for activity
 */
export interface IOrderAction {
  target: ACTION_TARGET;
  reference?: Reference;
  contentCommunication?: ISimplifiedCommunication;
  previouslyIssued?: IPreviouslyIssued[];
}

export interface IPreviouslyIssued {
  reference: string;
  issued: string; // format YYYY-MM-DD in this case
}

/**
 * kind of action to do ?
 */
export enum ACTION_TARGET {
  OBSERVATION = 1,
  QUESTIONNAIRE = 2,
  RECOMMEND = 3,
  KNOWLEDGE = 4,
  COMMUNICATION = 5,
  VITAL_PROFILE = 6,
}

/**
 * Careplan templates and translations
 */
export interface ICareplanTranslation {
  careplans: ICareplan[];
  translations: ITranslation[];
}

/**
 * enum for scheduled period
 */
export enum SCHEDULE_PERIOD {
  MINUTE,
  HOUR,
  DAY,
  WEEK,
  MONTH,
  YEAR,
}

export enum CareplanAccess {
  AUTHENTIFICATION = "auth",
  FREEACCESS = "free",
}

export interface IAddresses {
  reference: string;
  display: string; // $TR$
  status: string; // inactive, active
}

/**
 * Careplan
 */
export interface ICareplan extends Entity {
  identifier: Identifier[];
  support: Reference[]; // template reference (use to link to questionnaires and observations)
  status: string; // draft, inactive, active, archive, error
  period: IPeriodExtended;
  description: string;
  resourceType: string;
  participant: Reference[];
  goal?: Goal[];
  activity: Activity[];
  category: Codes[];
  context?: Reference; // used in Connectors to identifier the context of creation (currently CAREPLANCHUSP & CAREPLANCHULG)
  subject: Reference;
  note?: string[];
  author: Reference[];
  addresses?: IAddresses[];
  actionResulting?: IOrder[]; // link order associated to this careplan
  access?: CareplanAccess;
  publicationDate?: string; // field only used for the careplan template
  versioningStatus?: VersioningStatus;
  templatePublicationDate?: string; // field used when assigning a careplan to a patient
}

export interface IPeriodExtended extends IPeriod {
  endTiming?: IScheduledTiming; // used to stock period and periodUnits for the calculation of the careplan end date.
}

export enum VersioningStatus {
  DRAFT = "draft",
  ACTIVE = "active",
  RETIRED = "retired",
}
export interface ILink2Careplan extends Entity {
  careplan: string;
  questionnaires: string[];
  observations: string[];
  knowledges: string[];
  vitalSignsDefinitions: string[];
  askVitalSign?: boolean;
  access?: CareplanAccess;
  publicationDate?: string;
  versioningStatus?: VersioningStatus;
}

export interface ICareplanInfo {
  description: string; // name or traduction key if `$TR${key}`
  author: Reference[]; // liste des services dans lequel il est disponible. (La référence contient également le code de l'org)
  support: string; // reference of the careplan
  category: Codes[];
  date: string; // date of the last modification
  contributor?: string[]; // name of the persons that updated the careplan template
  versioningStatus: VersioningStatus;
  hasDraft: boolean;
}

export interface ICareplanKnowledgeInfos extends IKnowledgeInfo {
  origin: "link2careplan" | "activity";
  originReference?: IReference;
}

export const MainCareplanReference = "main careplan reference";
