<mat-card class="comunicare-widget" cdkDrag [cdkDragDisabled]="!isDraggable">
  <mat-card-header class="widget-header biggerHeader">
    <mat-card-title class="custom-widget-title">
      {{ "widget.pendingPrescriptions" | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="widget-content">
    <table
      mat-table
      matSort
      matSortActive="start"
      matSortDirection="asc"
      matSortDisableClear
      [dataSource]="dataSource"
      [ngClass]="{ hide: dataSource.data.length === 0 && isLoading }"
    >
      <!-- Patient lastname column -->
      <ng-container matColumnDef="lastname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.name" | translate }}
        </th>
        <td mat-cell class="custom-widget-font" *matCellDef="let element">
          {{ element.lastname }}
        </td>
      </ng-container>
      <!-- Patient firstname column -->
      <ng-container matColumnDef="firstname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.firstname" | translate }}
        </th>
        <td mat-cell class="custom-widget-font" *matCellDef="let element">
          {{ element.firstname }}
        </td>
      </ng-container>
      <!-- Prescription status column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.status" | translate }}
        </th>
        <td mat-cell class="custom-widget-font" *matCellDef="let element">
          {{ "prescription.status." + element.status | translate }}
        </td>
      </ng-container>
      <!-- Prescription category/type column -->
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.category" | translate }}
        </th>
        <td mat-cell class="custom-widget-font" *matCellDef="let element">
          {{ element.category }}
        </td>
      </ng-container>
      <!-- Prescription date request column -->
      <ng-container matColumnDef="dateRequest">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.date" | translate }}
        </th>
        <td mat-cell class="noPadding custom-widget-font" *matCellDef="let element">
          {{ element.dateRequest | date : "dd/MM/yyyy HH:mm" }}
        </td>
      </ng-container>
      <!-- Action Column -->
      <ng-container matColumnDef="actions">
        <th class="header-actions" mat-header-cell *matHeaderCellDef>
          {{ "table.action" | translate }}
        </th>
        <td class="custom-widget-font noPadding" *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="{{ 'btn.goToPatient' | translate }}"
            class="custom-icon-w"
            color="primary"
            (click)="goToPatient(element)"
          >
            <mat-icon class="custom-icon-w">face</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      #paginator
      [pageIndex]="0"
      [pageSize]="5"
      [hidePageSize]="true"
      [ngClass]="{ hide: dataSource.data.length === 0 && isLoading }"
    ></mat-paginator>
    <p class="text-center custom-widget-font" *ngIf="dataSource.data.length === 0 && !isLoading">
      {{ "widget.noData" | translate }}
    </p>
    <div class="spinner-container" *ngIf="isLoading">
      <mat-spinner></mat-spinner>
    </div>
  </mat-card-content>
</mat-card>
