<div>
  <div class="buttons-bar">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close matTooltip="{{ 'btn.close' | translate }}">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <ui-alert class="mt-1" [options]="{ type: data.type, showIcon: true }">
      {{ data.text }}
    </ui-alert>
  </mat-dialog-content>
</div>
