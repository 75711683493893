import { IOrder } from "src/app/models/careplans.interface";
import uuid from "uuid-random";
export class CommunicationTabHelper {
  public static newCommunication(careplanRef: string): IOrder {
    return {
      identifier: {
        system: "http://comunicare.io",
        value: careplanRef + "/" + uuid(),
      },
      status: "active",
      when: {
        period: 0,
        periodUnits: "day",
        timingCode: "M",
        skipFirst: false,
      },
      detail: {
        target: 5,
        contentCommunication: {
          sender: {
            reference: null,
            display: null,
          },
          subjects: [],
          group: {
            reference: null,
            display: null,
          },
          topicTranslation: {
            fr: "",
            en: "",
            nl: "",
            de: "",
            it: "",
            pt: "",
          },
          contentTranslation: {
            fr: "",
            en: "",
            nl: "",
            de: "",
            it: "",
            pt: "",
          },
          attachments: [],
          healthservice: {
            reference: null,
            display: null,
          },
          status: null,
        },
      },
    };
  }
}
