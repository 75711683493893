import { SelectionModel } from "@angular/cdk/collections";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { Item } from "src/app/components/item-selector/item-selector.component";
import { QRDetailsComponent } from "src/app/components/patient-QR/qr-details/qr-details.component";
import { Base64Helper } from "src/app/helpers/Base64Helper";
import { QuestionnaireListCSVHelper } from "src/app/helpers/QuestionnaireListCSVHelper";
import { ITrValues, QuestionnairePDFHelper } from "src/app/helpers/QuestionnairePDFHelper";
import { QuestionnairePDFHelperCheckBox } from "src/app/helpers/QuestionnairePDFHelperCheckBox";
import { FileLogger } from "src/app/helpers/fileLogger";
import { HelpData } from "src/app/helpers/helpData";
import { Tools } from "src/app/helpers/tools";
import { DataType, Filter } from "src/app/models/filter.interface";
import { PreferenceContext, TableParameter } from "src/app/models/preference.interface";
import { INPUT_TYPE } from "src/app/models/questionnaire.interface";
import { IQuestionnaireList } from "src/app/models/questionnaireList.interface";
import { GroupResponse } from "src/app/models/questionnaireResponse.interface";
import { QuestionnaireScoring } from "src/app/models/questionnaireScoring.model";
import { Reference } from "src/app/models/reference.interface";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PatientService } from "src/app/providers/patient.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { QRService } from "src/app/providers/qr-api.service";
import { QuestionnairesService } from "src/app/providers/questionnaires.service";
import { SessionService } from "src/app/providers/session.service";
import { ToolsService } from "src/app/providers/tools.service";
import { UserService } from "src/app/providers/user.service";
import { UserStatisticsService } from "src/app/providers/userStatistics.service";
import { QuestionnaireListDataSource } from "./questionnaire-list-datasource";
@Component({
  selector: "app-questionnaire-list-page",
  templateUrl: "./questionnaire-list-page.component.html",
  styleUrls: ["./questionnaire-list-page.component.scss"],
})
export class QuestionnaireListPageComponent implements OnInit, OnDestroy {
  /**
   * Material table
   */
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<IQuestionnaireList>;
  public dataSource: QuestionnaireListDataSource;
  public currentPageSize: number;
  // public defaultSort: MatSortable = {
  //     id: 'questionnaireDate',
  //     start: 'desc',
  //     disableClear: true
  // };
  public actionOnMultiple = false;
  public isAuthorizeShowAnonymousQr = false;
  public showAnonymized = false;

  // Columns
  public availableColumnItems: Item[] = [];
  public maxFromDate: moment.Moment;
  public minToDate: moment.Moment;
  public displayedColumns: string[];

  private fromDate: moment.Moment;
  private toDate: moment.Moment;

  /**
   * Filter
   */
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public globalSearchValue = "";

  /**
   * Services and organizations
   */

  public availableOrganizations: Reference[];
  public availableServices: Reference[];
  /**
   * Selection
   */
  public selection = new SelectionModel<IQuestionnaireList>(true, []);

  public filterFormTable = this.fb.group({
    fromDate: new UntypedFormControl(moment().subtract(3, "month"), {
      validators: Validators.required,
      updateOn: "blur",
    }),
    toDate: new UntypedFormControl(moment().endOf("day"), {
      validators: Validators.required,
      updateOn: "blur",
    }),
  });

  public today = moment();
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    public translateService: TranslateService,
    private preferenceService: PreferenceService,
    public helpData: HelpData,
    private qrService: QRService,
    private questionnaireService: QuestionnairesService,
    private sessionService: SessionService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private toolsService: ToolsService,
    private snackBar: MatSnackBar,
    private qPdfHelper: QuestionnairePDFHelper,
    private qPdfHelperCheckBox: QuestionnairePDFHelperCheckBox,
    private patientService: PatientService,
    private userService: UserService,
    private healthcareService: HealthcareserviceService,
    private questionnaireListCSVHelper: QuestionnaireListCSVHelper,
    private statService: UserStatisticsService
  ) {
    this.maxFromDate = moment().endOf("day");
    this.minToDate = moment().subtract(3, "month");
    this.fromDate = this.minToDate;
    this.toDate = this.maxFromDate;
    this.dataSource = new QuestionnaireListDataSource(this.qrService, this.sessionService, this.userService, this.healthcareService);
    this.getDisplayedColumns();
  }

  ngOnInit(): void {
    this.isAuthorizeShowAnonymized();
    this.loadPreferences().then(() => {
      // Define all available columns and translate display
      this.translateService
        .get([
          "model.patient.name",
          "model.patient.firstname",
          "model.patient.birthdate",
          "model.patient.gender",
          "model.patient.questionnaireName",
          "model.patient.questionnaireDate",
          "model.patient.questionnaireScore",
          "table.action",
        ])
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((trans: string[]) => {
          this.availableColumnItems = [
            {
              value: "gender",
              display: trans["model.patient.gender"],
              checked: true,
            },
            {
              value: "name",
              display: trans["model.patient.name"],
              checked: true,
            },
            {
              value: "firstname",
              display: trans["model.patient.firstname"],
              checked: true,
            },
            {
              value: "birthday",
              display: trans["model.patient.birthdate"],
              checked: true,
            },
            {
              value: "questionnaireName",
              display: trans["model.patient.questionnaireName"],
              checked: true,
            },
            {
              value: "questionnaireDate",
              display: trans["model.patient.questionnaireDate"],
              checked: true,
            },
            {
              value: "questionnaireScore",
              display: trans["model.patient.questionnaireScore"],
              checked: true,
            },
            { value: "action", display: trans["table.action"], checked: true },
          ];
        });

      this.loadPreferences();

      this.setupServicesWatch();

      this.filterFormTable
        .get("fromDate")
        .valueChanges.pipe(takeUntil(this.onDestroy$))
        .subscribe(() => this.updateDate());

      this.filterFormTable
        .get("toDate")
        .valueChanges.pipe(takeUntil(this.onDestroy$))
        .subscribe(() => this.updateDate());
    });
  }

  ngOnDestroy(): void {
    this.dataSource.clear();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public initPaginatorAndSort(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;

    // Detect page size change
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((page) => {
      if (this.currentPageSize !== page.pageSize) {
        this.updatePreference();
      }
    });
  }

  private updateDate() {
    const from = moment(this.filterFormTable.get("fromDate").value);
    const to = moment(this.filterFormTable.get("toDate").value);

    if (from.isValid() && to.isValid()) {
      this.fromDate = from;
      this.toDate = to;
      this.init(this.fromDate, this.toDate);
    }
  }

  private setupServicesWatch() {
    this.init(this.fromDate, this.toDate);
    this.initPaginatorAndSort();
    if (this.userService.isMonitoringUser) {
      this.sessionService.refreshCurrentMonitService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.init(this.fromDate, this.toDate);
        this.initPaginatorAndSort();
      });
    }
    this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.init(this.fromDate, this.toDate);
      this.initPaginatorAndSort();
    });
  }

  private init(from: moment.Moment, to: moment.Moment) {
    this.userService
      .isAuthorized("dashboard/questionnaireList", "GET")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isAuth) => {
        if (isAuth) {
          this.dataSource.loadQuestionnaires(from.toISOString(), to.toISOString(), this.showAnonymized);
        }
      });
  }

  public availableColumnChanged(_items: Item[]): void {
    this.updatePreference();
  }

  /**
   * Drag n'Drop
   */
  public columnDropped(event: CdkDragDrop<string[]>): void {
    const realIndexFrom =
      this.availableColumnItems.findIndex((item) => this.displayedColumns[event.previousIndex] === item.value) +
      (this.actionOnMultiple ? 1 : 0);
    const realIndexTo =
      this.availableColumnItems.findIndex((item) => this.displayedColumns[event.currentIndex] === item.value) +
      (this.actionOnMultiple ? 1 : 0);
    moveItemInArray(this.availableColumnItems, realIndexFrom, realIndexTo);
    this.updatePreference();
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
    this.updatePreference();
  }

  public clearFilter(): void {
    this.globalSearchValue = "";
    this.dataSource.clearFilter();
    this.updatePreference();
  }

  public updateSearch(value?: string): void {
    this.globalSearchValue = value;
    this.dataSource.setFilter({
      data: value,
      propertyName: "globalSearch",
      dataType: DataType.COMBINED,
      combinedProperties: ["name", "firstname"],
    });
  }

  /**
   * Selection
   */
  public isAllSelected(): boolean {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  public masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /**
   * Preferences
   */
  public updatePreference(): void {
    this.preferenceService
      .update({
        context: PreferenceContext.QUESTIONNAIRES_PAGE,
        parameters: {
          filters: this.dataSource.getAllFilters(),
          availableColumnItems: this.availableColumnItems,
          itemsPerPage: this.paginator.pageSize,
        } as TableParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.getDisplayedColumns();
      });
  }

  public getDisplayedColumns(): void {
    this.displayedColumns = this.actionOnMultiple
      ? ["select", ...this.availableColumnItems.filter((item) => item.checked).map((item) => item.value)]
      : [...this.availableColumnItems.filter((item) => item.checked).map((item) => item.value)];
  }

  private async loadPreferences() {
    const parameters = await this.preferenceService.list(PreferenceContext.QUESTIONNAIRES_PAGE).pipe(first()).toPromise();
    if (parameters) {
      if (parameters.filters.length) {
        // Apply saved filters
        parameters.filters.forEach((filter: Filter) => {
          this.applyFilter(filter);
        });
      }

      // Set page size from preference
      this.currentPageSize = parameters.itemsPerPage;

      // Restore column order + visibility
      this.availableColumnItems = parameters.availableColumnItems;
    }
    this.getDisplayedColumns();
  }

  public openQuestionnaireListHelp(): void {
    this.dialog.open(GlobalHelpDialogComponent, {
      data: { slides: this.helpData.QuestionnairesPageHelp },
      disableClose: true,
    });
  }

  public encodeToB64(str: string): string {
    this.sessionService.patientPageFrom = "/questionnaireList";
    return Base64Helper.utf8_to_b64(str);
  }

  public activateCheckBox(): void {
    this.actionOnMultiple = !this.actionOnMultiple;
  }

  public openDetails(element: IQuestionnaireList): void {
    this.qrService
      .getOneScoring(element.questionnaireIdentifier)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((scoring) => {
        this.dialog.open(QRDetailsComponent, {
          data: { QR: scoring.questionnaireResponse, scoring: scoring.scoring },
          maxHeight: "80vh",
        });
      });
  }

  public onExportPDF(element: IQuestionnaireList): void {
    let groups: GroupResponse[];
    const values: ITrValues[] = [];
    let htmlComputed = "";
    this.qrService
      .getOneScoring(element.questionnaireIdentifier)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (scoring) => {
        groups = scoring.questionnaireResponse?.group?.group?.length
          ? Tools.clone(scoring.questionnaireResponse?.group?.group)
          : Tools.clone([scoring.questionnaireResponse?.group]);
        try {
          const template = await this.questionnaireService.getQuestionnaire(
            scoring.questionnaireResponse.ref,
            scoring.questionnaireResponse.version
          );
          if (template.questionByGroup) {
            htmlComputed = this.qPdfHelperCheckBox.getConstructedTemplate(
              groups,
              template,
              moment(scoring.date).format("DD-MM-YYYY HH:mm"),
              this.getMergedScoringByCategory(scoring.scoring)
            );
          } else {
            groups.forEach((group, i) => {
              if (group.group && group.group.length > 0) {
                group.group.forEach((g, iBis) => {
                  g.question.forEach((q) => {
                    values.push({
                      qid: q.linkId,
                      topic: group.title,
                      question: q.text,
                      answer: this.computeAnswerString(q.linkId, iBis, groups),
                      value: this.computeAnswerCode(q.linkId, iBis, groups),
                    });
                  });
                });
              } else {
                group.question.forEach((q) => {
                  values.push({
                    qid: q.linkId,
                    topic: group.title,
                    question: q.text,
                    answer: this.computeAnswerString(q.linkId, i, groups),
                    value: this.computeAnswerCode(q.linkId, i, groups),
                  });
                });
              }
            });
            if (!this.showAnonymized) {
              htmlComputed = this.qPdfHelper.getConstructedTemplate(
                values,
                moment(scoring.questionnaireResponse.authored).format("DD-MM-YYYY HH:mm"),
                this.getMergedScoringByCategory(scoring.scoring),
                template
              );
            } else {
              htmlComputed = this.qPdfHelper.getConstructedTemplate(
                values,
                "/",
                this.getMergedScoringByCategory(scoring.scoring),
                template
              );
            }
          }
          if (!this.showAnonymized) {
            this.patientService
              .getPatientUser(element.caremateIdentifier)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe((pu) => {
                this.toolsService.createPFDAndOpenInBrowser(pu, scoring.name, htmlComputed);
              });
          } else {
            this.toolsService.createPFDAndOpenInBrowser(undefined, scoring.name, htmlComputed);
          }
          this.statService.createStatEvent(
            "Export questionnaire responses to PDF (" + scoring.questionnaireResponse.questionnaire.display + ")"
          );
        } catch (err) {
          FileLogger.error("QuestionnaireListPageComponent", "Error while loading questionnaire: ", err);
        }
      });
    this.snackBar.open(this.translateService.instant("export.message"), "ok", {
      duration: 3000,
    });
  }

  public computeAnswerString(reference: string, pageNbr: number, groupResponse: GroupResponse[]): string {
    let answerString = "";
    const question = groupResponse?.[pageNbr]?.question?.find((q) => q.linkId === reference);
    if (question) {
      switch (question.inputType) {
        case INPUT_TYPE.NUMBER:
          answerString = question.answer?.[0]?.valueCoding?.display;
          if (question.answer?.[1]) {
            answerString += " " + question.answer?.[1]?.valueCoding?.display;
          }
          break;
        default:
          answerString = question.answer?.map((a) => a.valueCoding?.display ?? a.valueCoding?.code).join(", ");
      }
    }
    return answerString;
  }

  public computeAnswerCode(reference: string, pageNbr: number, groupResponse: GroupResponse[]): string {
    let answerCode: string;
    const question = groupResponse?.[pageNbr]?.question?.find((q) => q.linkId === reference);
    if (question?.inputType === INPUT_TYPE.CHECKBOXES) {
      answerCode = question?.answer?.map((a) => a.valueCoding?.code.toString()).join(", ");
    } else {
      const valueCoding = question?.answer?.[0]?.valueCoding;
      answerCode = isNaN(+valueCoding?.code) ? null : valueCoding.code;
    }
    return answerCode;
  }

  public getMergedScoringByCategory(scorings: QuestionnaireScoring[]): QuestionnaireScoring[][] {
    const allCategories = this.uniq(scorings.map((s) => s.identifier?.value));
    const o = {};
    allCategories.forEach((cat) => (o[cat] = []));
    scorings.forEach((s) => {
      o[s.identifier?.value].push(s);
    });
    return Object.keys(o).reduce((acc, el) => {
      acc.push(o[el]);
      return acc;
    }, [] as QuestionnaireScoring[][]);
  }

  private uniq(a: string[]) {
    return Array.from(new Set(a));
  }

  public async exportCSV(): Promise<void> {
    const msg = this.translateService.instant("export.message");
    this.snackBar.open(msg, "ok", { duration: 3000 });
    const sortedAndFilteredData = this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);
    await this.questionnaireListCSVHelper.exportCSV(sortedAndFilteredData, true).catch((err) => {
      FileLogger.error("QuestionnaireListPage", "Error while exporting CSV", err, "none");
    });
    this.statService.createStatEvent("Export questionnaire responses to CSV");
  }

  public isAuthorizeShowAnonymized(): void {
    this.userService.isAuthorized("dashboard/anonymousQuestionnaires", "GET").subscribe((res) => {
      this.isAuthorizeShowAnonymousQr = res;
    });
  }

  public handleToggleShowAnonymizedClicked(): void {
    this.showAnonymized = !this.showAnonymized;
    this.init(this.fromDate, this.toDate);
  }
}
