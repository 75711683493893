<h3 mat-dialog-title>{{ "forms.addKnowledge" | translate }}</h3>

<form [formGroup]="knowledgeForm" (ngSubmit)="apply()">
  <mat-list>
    <div class="row">
      <mat-list-item>
        <i class="fas fa-h-square icon"></i>
        <mat-form-field>
          <input
            cdkFocusInitial
            type="text"
            matInput
            placeholder="{{ 'knowledgebase.term' | translate }}"
            formControlName="term"
            [errorStateMatcher]="matcher"
          />
        </mat-form-field>
      </mat-list-item>

      <mat-list-item>
        <i class="fas fa-key icon"></i>
        <mat-form-field>
          <input
            cdkFocusInitial
            type="text"
            matInput
            placeholder="{{ 'knowledgebase.snomedRef' | translate }}"
            formControlName="reference"
            [errorStateMatcher]="matcher"
          />
        </mat-form-field>
      </mat-list-item>
    </div>

    <mat-list-item>
      <i class="fas fa-lock icon" *ngIf="!knowledgeForm.get('public').value"></i>
      <i class="fas fa-lock-open icon" *ngIf="knowledgeForm.get('public').value"></i>
      <h3 class="mb-0 mr-15">{{ "knowledgebase.public" | translate }}</h3>
      <mat-slide-toggle formControlName="public" color="primary"></mat-slide-toggle>
    </mat-list-item>

    <div class="row">
      <mat-list-item>
        <i class="fas fa-file icon"></i>
        <mat-form-field>
          <mat-label>{{ "knowledgebase.doccategory.label" | translate }}</mat-label>
          <mat-select formControlName="documentCategory" [errorStateMatcher]="matcher">
            <mat-option *ngFor="let i of KNOW_DOC_CATEGORY | EnumToArray" [value]="i">
              {{ "knowledgebase.doccategory." + i | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-list-item>

      <mat-list-item>
        <i class="fas fa-tag icon"></i>
        <mat-form-field>
          <mat-label>{{ "knowledgebase.category.label" | translate }}</mat-label>
          <mat-select formControlName="category" [errorStateMatcher]="matcher">
            <mat-option *ngFor="let i of knowledgeCategories" [value]="i">
              {{ "knowledgebase.category." + i | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-list-item>
    </div>
  </mat-list>

  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true">
      {{ "btn.cancel" | translate }}
    </button>
    <button mat-raised-button type="submit" color="primary">
      {{ "btn.add" | translate }}
    </button>
  </div>
</form>
