<div class="loading" *ngIf="loading">
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
  {{ "common.loading" | translate }}
</div>

<ng-container *ngIf="!loading">
  <div class="toolbar">
    <div class="left">
      <!-- GLOBAL SEARCH -->
      <mat-form-field>
        <mat-label><span class="fa fa-search mr-5"></span>{{ "itemFilter.search" | translate }}</mat-label>
        <input type="search" matInput #searchInput autocomplete="off" />
        <button
          type="button"
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="searchInput.value = ''"
          [style.visibility]="searchInput.value ? 'visible' : 'hidden'"
          #searchClearBtn
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button type="button" mat-icon-button>
        <mat-icon>help_outline</mat-icon>
      </button>
    </div>

    <div class="spacer"></div>

    <button mat-flat-button color="warn" *ngIf="isFiltered" (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
      <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
    </button>
    <button mat-stroked-button *ngIf="!isFiltered" (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
      <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
    </button>
  </div>

  <app-filters-display [filters]="filters" (removeFilter)="applyFilter($event)"> </app-filters-display>

  <mat-card class="mat-elevation-z0 pt-0">
    <!-- LOADER MANAGEMENT -->
    <!-- The loader has been disactivated because loading id too fast  -->
    <!-- <div class="spinner-container" *ngIf="dataSource.loading$ | async">
                <mat-spinner></mat-spinner>
            </div> -->

    <mat-table
      class="mat-elevation-z1"
      [dataSource]="dataSource"
      matSort
      matSortActive="subjectType"
      matSortDirection="asc"
      cdkDropList
      [id]="dropListId"
      [cdkDropListData]="dataSource"
      [cdkDropListConnectedTo]="dropListConnectedTo"
      [cdkDropListDisabled]="!draggable"
      [cdkDropListSortingDisabled]="dropListSortingDisabled"
    >
      <ng-container matColumnDef="grip" *ngIf="draggable">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let knowledge">
          <mat-icon class="fa-icon" fontSet="fas" fontIcon="fa-grip-vertical"></mat-icon>
        </mat-cell>
      </ng-container>
      <!-- SUBJECT TYPE FIELD (= name of the questionnaire for the practicien) -->
      <ng-container matColumnDef="subjectType">
        <mat-header-cell *matHeaderCellDef [mat-sort-header]="filtersPropertyNames.subjectType"
          >{{ "common.name" | translate }}
          <app-item-filter
            fieldName="{{ 'common.name' | translate }}"
            [propertyName]="filtersPropertyNames.subjectType"
            [dataType]="DATA_TYPE.TEXT"
            [defaultValue]="getFilter(filtersPropertyNames.subjectType)"
            [translateFieldKey]="'common.name'"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let questionnaire">
          {{ questionnaire.subjectType }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions" *ngIf="draggable">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let questionnaire">
          <!-- EDIT BUTTON -->
          <button
            mat-icon-button
            (click)="editElement(questionnaire)"
            [disabled]="questionnaire.useContext[0].accessLevel < AccessLevel.WRITE"
            [matTooltip]="
              questionnaire.useContext[0].accessLevel < AccessLevel.WRITE
                ? ('common.notAuthorized' | translate)
                : ('page.questionnaireEditor.edit' | translate)
            "
          >
            <mat-icon class="fa-icon edit" fontSet="fas" fontIcon="fa-pen"></mat-icon>
          </button>
          <!-- VISUALIZATION BUTTON -->
          <button
            mat-icon-button
            (click)="visualizeElement(questionnaire)"
            [disabled]="questionnaire.useContext[0].accessLevel < AccessLevel.READ"
            [matTooltip]="
              questionnaire.useContext[0].accessLevel < AccessLevel.READ
                ? ('common.notAuthorized' | translate)
                : ('page.careplanEditor.tabs.questionnaires.btn.view' | translate)
            "
          >
            <mat-icon class="fa-icon edit" fontSet="fas" fontIcon="fa-search"></mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <ng-container *ngIf="draggable">
        <mat-row
          cdkDrag
          [cdkDragData]="row | formatToLinkableList : action_target.QUESTIONNAIRE"
          *matRowDef="let row; columns: displayedColumns"
        ></mat-row>
      </ng-container>
      <ng-container *ngIf="!draggable">
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </ng-container>
    </mat-table>

    <mat-paginator
      class="mat-elevation-z1"
      [length]="questionnairesCount"
      [pageSize]="10"
      [pageSizeOptions]="[10, 25, 50, 100]"
    ></mat-paginator>
  </mat-card>
</ng-container>
