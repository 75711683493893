import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, forkJoin, of } from "rxjs";
import { first, switchMap, takeUntil, tap } from "rxjs/operators";
import { ConfirmationDialogType } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { Tools } from "src/app/helpers/tools";
import { ICareplan, VersioningStatus } from "src/app/models/careplans.interface";
import { IKnowledgesCriteria } from "src/app/models/knowledgescriteria-interface";
import { ITranslation } from "src/app/models/translation.interface";
import { KnowledgeCriteriaService } from "src/app/providers/knowledge-criteria-service";
import { LanguagesService } from "src/app/providers/languages.service";
import { CareplanEditorService } from "../../domain/careplan-editor.service";
import { CareplanPublicationDialogComponent } from "./components/careplan-publication-dialog/careplan-publication-dialog.component";

@Component({
  selector: "app-careplan-editor-page",
  templateUrl: "./careplan-editor-page.component.html",
  styleUrls: ["./careplan-editor-page.component.scss"],
})
export class CareplanEditorPageComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  public activeTabIndex = 0;

  //  Must match the route defined in the careplan-editor-page routing module
  public tabs: string[] = ["general", "questionnaires", "observations", "vitalSigns", "knowledge", "communications"];
  public careplanTemplate: ICareplan;
  public availableLangs: ITranslation[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public careplanEditorService: CareplanEditorService,
    private languagesService: LanguagesService,
    public translateService: TranslateService,
    private snackBar: MatSnackBar,
    private knowledgeCriteriaService: KnowledgeCriteriaService,
    private dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.careplanEditorService.reset();
    this.selectTabBasedOnURL();
    this.getCareplanTemplate(this.route.snapshot.params.id, true);
    this.getLink2Careplan(this.route.snapshot.params.id, true);
    this.languagesService
      .list()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((languages) => {
        this.availableLangs = languages;
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public selectTab(event: number): void {
    if (this.careplanEditorService.careplanTemplateForm.valid) {
      this.activeTabIndex = event;
      const activeTab = this.tabs[this.activeTabIndex];

      // Extract the careplan ID from the current route
      const careplanId = this.route.snapshot.paramMap.get("id");

      // Construct the route parameters based on the selected tab and careplan ID
      const routeParams = ["careplanEditor", careplanId, activeTab];

      // If 'general' tab is selected, add 'visualView' to the route parameters
      if (activeTab === "general") {
        routeParams.push("visualView");
      }

      // Navigate to the corresponding route based on the selected tab
      this.router.navigate(routeParams);
    } else {
      // Mark entire form as touched to display the errors
      this.careplanEditorService.careplanTemplateForm.markAllAsTouched();
      this.snackBar.open(this.translateService.instant("page.careplanEditor.error.invalidForm"), "ok", { duration: 5000 });
    }
  }

  /**
   * Selects the tab based on the current URL path when navigating manually via the browser's URL input.
   * It retrieves the last segment of the path from the URL and sets `activeTabIndex` accordingly.
   */
  public selectTabBasedOnURL(): void {
    const currentPath = window.location.pathname;
    const urlSegments = currentPath.split("/");
    const pathname = urlSegments.pop();
    this.activeTabIndex = this.tabs.indexOf(pathname);
  }

  /**
   * Retrieves the care plan template based on the provided reference and update the currentCareplanTemplate for this component and inside the careplanEditorService.
   * @param reference - The reference to the care plan.
   */
  public getCareplanTemplate(reference: string, withDraft: boolean): void {
    const availableLangs$ = this.languagesService.list().pipe(takeUntil(this.onDestroy$));
    const careplanTemplate$ = this.careplanEditorService.getCareplanTemplate(reference, withDraft).pipe(first());

    forkJoin([availableLangs$, careplanTemplate$])
      .pipe(
        switchMap(([lang, careplanTemplate]) => {
          this.availableLangs = lang;
          if (!Tools.isDefined(careplanTemplate.draft)) {
            // no draft, need to create one
            return this.careplanEditorService.createCareplanTemplate(careplanTemplate.published).pipe(
              switchMap((careplanTemplateCreated) => {
                return this.createKnowledgeCriteriaDraftFromPublished(reference).pipe(
                  switchMap(() => {
                    return this.initForm(careplanTemplateCreated);
                  })
                );
              })
            );
          } else {
            // Draft already exists, initialize form with draft
            return this.initForm(careplanTemplate.draft);
          }
        })
      )
      .subscribe();
  }

  public createKnowledgeCriteriaDraftFromPublished(reference: string): Observable<IKnowledgesCriteria[]> {
    return this.knowledgeCriteriaService.list(null, null, reference, null).pipe(
      first(),
      switchMap((knowledgeCriteria) => {
        if (knowledgeCriteria.published.length === 0) {
          // Return an observable that emits an empty array because forJoin will not emit if createObservables is empty
          return of([]);
        }

        // Map over the published knowledge criteria and create draft versions
        const createObservables = knowledgeCriteria.published.map((kc) => {
          // Exclude publicationDate and _id from kc with object destructuring
          const { publicationDate: _publicationDate, _id, ...draftKC } = kc;
          draftKC.versioningStatus = VersioningStatus.DRAFT;
          return this.knowledgeCriteriaService.create(draftKC).pipe(first());
        });
        // Return an observable that completes when all drafts are created
        return forkJoin(createObservables);
      })
    );
  }

  private initForm(careplanTemplate: ICareplan) {
    this.careplanEditorService.currentCareplanTemplate = careplanTemplate;
    this.careplanTemplate = careplanTemplate;
    this.careplanEditorService.initCareplanTemplateForm(careplanTemplate);
    return this.careplanEditorService.initTraductions(this.availableLangs.map((l) => l.term)).pipe(
      tap(() => {
        this.careplanEditorService.setCareplanTemplateReady(true);
      })
    );
  }

  /**
   * Retrieves the link2careplan based on the provided careplan ID and populate the link2CareplanForm with the retrieved data
   * @param careplanId - The ID of the careplan.
   */
  public getLink2Careplan(careplanId: string, withDraft: boolean): void {
    this.careplanEditorService
      .getLink2Careplan(careplanId, withDraft)
      .pipe(
        first(),
        tap((link2careplan) => {
          if (!Tools.isDefined(link2careplan.draft)) {
            // no draft, need to create one
            this.careplanEditorService
              .createLink2Careplan(link2careplan.published)
              .pipe(
                first(),
                tap((link2careplanCreated) => {
                  // Populate the form
                  this.careplanEditorService.link2CareplanForm.patchValue(link2careplanCreated);
                  this.careplanEditorService.setLink2CareplanReady(true);
                })
              )
              .subscribe();
          } else {
            // Populate the form
            this.careplanEditorService.link2CareplanForm.patchValue(link2careplan.draft);
            this.careplanEditorService.setLink2CareplanReady(true);
          }
        })
      )
      .subscribe();
  }

  public save(close?: boolean): void {
    this.careplanEditorService.careplanTemplateForm.markAllAsTouched();
    this.careplanEditorService.save(false, close);
  }

  public publish(): void {
    this.dialog.open(CareplanPublicationDialogComponent, {
      width: "600px",
      data: {
        message: this.translateService.instant("page.careplanEditor.warning.publish"),
        type: ConfirmationDialogType.CONFIRM,
      },
    });
  }
}
