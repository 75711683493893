import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RewardDefinition } from "../models/rewardDefinition.model";
import { RewardDefinitionApiService } from "./api/rewardDefinition-api.service";

@Injectable({
  providedIn: "root",
})
export class RewardDefinitionService {
  constructor(private rewardDefinitionApiService: RewardDefinitionApiService) {}

  public listRewardDefinitions(identifiers?: string[]): Observable<RewardDefinition[]> {
    return this.rewardDefinitionApiService.listRewardDefinitions(identifiers).pipe(
      map((values) => {
        return values.map((value) => new RewardDefinition(value));
      })
    );
  }
}
