import { Pipe, PipeTransform } from "@angular/core";
import { ObsComponent } from "src/app/models/observations.model";
import { OComponent } from "../../../models/observations.interface";

@Pipe({ name: "getComponent" })
export class GetComponentPipe implements PipeTransform {
  transform(headerCompo: OComponent, component: OComponent[]): ObsComponent {
    let comp: OComponent;
    for (const compo of component) {
      if (
        compo.parentObservation?.code?.coding[0].code === headerCompo.parentObservation.code.coding[0].code &&
        compo.code.coding[0].code === headerCompo.code.coding[0].code
      ) {
        comp = compo;
      }
    }
    return new ObsComponent(comp);
  }
}
