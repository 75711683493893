<h3 mat-dialog-title *ngIf="data && data.mode === MODES.CREATE">{{ "accessGroups.create" | translate }}</h3>
<h3 mat-dialog-title *ngIf="data && data.mode === MODES.UPDATE">{{ "accessGroups.update" | translate }}</h3>
<h3 mat-dialog-title *ngIf="data && data.mode === MODES.DISPLAY">{{ "accessGroups.display" | translate }}</h3>

<div mat-dialog-content *ngIf="data">
  <app-access-group [mode]="data.mode" [accessGroup]="data.accessGroup" [allAccessGroups]="data.allAccessGroups"></app-access-group>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true" *ngIf="!data || data.mode !== MODES.DISPLAY" (click)="cancel()" [disabled]="saveInProgress">
    {{ "btn.cancel" | translate }}
  </button>
  <button
    mat-raised-button
    type="submit"
    color="primary"
    *ngIf="data && data.mode === MODES.CREATE"
    (click)="save()"
    [disabled]="saveInProgress"
  >
    {{ "btn.add" | translate }}
  </button>
  <button
    mat-raised-button
    type="submit"
    color="primary"
    *ngIf="data && data.mode === MODES.UPDATE"
    (click)="save()"
    [disabled]="saveInProgress"
  >
    {{ "btn.update" | translate }}
  </button>
  <button mat-raised-button type="submit" color="primary" *ngIf="data && data.mode === MODES.DISPLAY" (click)="cancel()">
    {{ "btn.ok" | translate }}
  </button>
</div>
