import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { I18nModule } from "src/app/i18n.module";
import { PipesModule } from "src/app/pipes/pipes.module";
import { PatientInfosComponent } from "./patient-infos.component";

@NgModule({
  declarations: [PatientInfosComponent],
  imports: [
    I18nModule,
    BrowserAnimationsModule,
    FormsModule,
    PipesModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
  ],
  exports: [PatientInfosComponent],
})
export class PatientInfoModule {}
