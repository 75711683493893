<div class="scoring-graph" #scoringGraph>
  <!-- no questionnaires selected -->
  <p class="text-center" *ngIf="scorings?.length === 0">
    {{ "widget.noneselected" | translate }}
  </p>
  <!-- graphical data cart -->
  <ng-container *ngIf="scorings?.length > 0">
    <div *ngIf="chartData?.length > 0" [style.width]="chartW + 'px'">
      <canvas
        baseChart
        #baseChart="base-chart"
        [datasets]="chartData"
        [labels]="chartLabels"
        [options]="chartOptions"
        [colors]="chartColors"
        legend="true"
        [chartType]="'bar'"
      ></canvas>
    </div>
  </ng-container>
</div>
