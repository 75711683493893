import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import * as d3 from "d3";
import moment from "moment";
import { from } from "rxjs";
import { delay, first } from "rxjs/operators";
import { ChartHelper, IDataPoint, xAxisType, yAxisType } from "src/app/helpers/chart-helper";
import { Tools } from "src/app/helpers/tools";
import { IStatistic, IStatsObsGroups } from "src/app/models/statObservation.interface";
import { IChartConfig } from "src/app/models/stream-observation-interface";
import { BaseChartComponent } from "../base-chart/base-chart.component";

@Component({
  selector: "app-stream-obs-stat-chart",
  templateUrl: "./stream-obs-stat-chart.component.html",
  styleUrls: ["./stream-obs-stat-chart.component.scss", "../base-chart/base-chart.component.scss"],
})
export class StreamObsStatChartComponent extends BaseChartComponent implements OnChanges {
  @Input() loinc: string;
  @Input() statsObsGroups: IStatsObsGroups;
  @Input() fromDate; // seems unused but neccessary to trigger change detection
  @Input() toDate; // idem

  protected chartConfig: IChartConfig = {
    margin: 30,
    width: 800,
    height: 400,
    xAxis: { type: xAxisType.XXIVHours },
    yAxis: { type: yAxisType.percentile },
  };

  public legends: { color: string; date?: moment.Moment; display?: string }[] = [];
  public noPercentile: boolean;
  private minData: IDataPoint[];
  private maxData: IDataPoint[];
  private percentileData: IDataPoint[];

  constructor() {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!Tools.isEqual(changes.statsObsGroups?.previousValue, changes.statsObsGroups?.currentValue)) {
      const { minData, maxData, percentileData } = this.transformRawData();
      this.minData = minData;
      this.maxData = maxData;
      this.percentileData = percentileData;

      if (this.percentileData.length == 0) {
        this.noPercentile = true;
      } else {
        this.noPercentile = false;
      }

      this.updateChartConfig(this.maxData);

      const dataReady = from(this.createChart()).pipe(first(), delay(500));
      dataReady.subscribe(() => {
        this.loading = false;
      });
    }
  }

  public drawPaths(): void {
    this.drawPercentiles(this.percentileData);
    this.drawLine(this.minData);
    this.drawLine(this.maxData);
    this.legends = [
      { color: "#DEF0FD", display: "5% - 95%" },
      { color: "#B3DFFE", display: "25% - 75%" },
      { color: "#51ABE4", display: "50%" },
      { color: "#83999c", display: "Min-Max" },
    ];
  }

  private drawPercentiles(percentileData: IDataPoint[]) {
    const upperOuterArea = d3
      .area<IDataPoint>()
      .curve(d3.curveBasis) // UpminutesAfterMidnightd: Use d3.curveBasis for the 'basis' interpolation
      .x((d: IDataPoint) => this.x(d.minutesAfterMidnight) || 1) // Use ES6 arrow functions for cleaner syntax
      .y0((d: IDataPoint) => this.y(d.pct95)) // Define the bottom line of the area
      .y1((d: IDataPoint) => this.y(d.pct75)); // Define the top line of the area

    const upperInnerArea = d3
      .area<IDataPoint>()
      .curve(d3.curveBasis) // UpminutesAfterMidnightd to use d3.curveBasis
      .x((d: IDataPoint) => this.x(d.minutesAfterMidnight) || 1) // Use ES6 arrow functions
      .y0((d: IDataPoint) => this.y(d.pct75)) // Define the bottom line of the area
      .y1((d: IDataPoint) => this.y(d.pct50)); // Define the top line of the area

    const medianLine = d3
      .line<IDataPoint>()
      .curve(d3.curveBasis) // UpminutesAfterMidnightd to use d3.curveBasis
      .x((d: IDataPoint) => this.x(d.minutesAfterMidnight)) // Use ES6 arrow functions
      .y((d: IDataPoint) => this.y(d.pct50)); // Define the line position

    const lowerInnerArea = d3
      .area<IDataPoint>()
      .curve(d3.curveBasis) // UpminutesAfterMidnightd to use d3.curveBasis
      .x((d: IDataPoint) => this.x(d.minutesAfterMidnight) || 1) // Use ES6 arrow functions
      .y0((d: IDataPoint) => this.y(d.pct50)) // Define the bottom line of the area
      .y1((d: IDataPoint) => this.y(d.pct25)); // Define the top line of the area

    const lowerOuterArea = d3
      .area<IDataPoint>()
      .curve(d3.curveBasis) // UpminutesAfterMidnightd to use d3.curveBasis
      .x((d: IDataPoint) => this.x(d.minutesAfterMidnight) || 1) // Use ES6 arrow functions
      .y0((d: IDataPoint) => this.y(d.pct25)) // Define the bottom line of the area
      .y1((d: IDataPoint) => this.y(d.pct05)); // Define the top line of the area

    this.svg.datum(percentileData);

    this.svg
      .append("path")
      .attr("fill", "#DEF0FD")
      .attr("stroke", "#DEF0FD")
      .attr("d", upperOuterArea)
      .attr("clip-path", "url(#rect-clip)");
    this.svg
      .append("path")
      .attr("fill", "#DEF0FD")
      .attr("stroke", "#DEF0FD")
      .attr("d", lowerOuterArea)
      .attr("clip-path", "url(#rect-clip)");
    this.svg
      .append("path")
      .attr("fill", "#B3DFFE")
      .attr("stroke", "#B3DFFE")
      .attr("d", upperInnerArea)
      .attr("clip-path", "url(#rect-clip)");
    this.svg
      .append("path")
      .attr("fill", "#B3DFFE")
      .attr("stroke", "#B3DFFE")
      .attr("d", lowerInnerArea)
      .attr("clip-path", "url(#rect-clip)");
    this.svg
      .append("path")
      .attr("fill", "none")
      .attr("stroke", "#51ABE4")
      .attr("stroke-width", "3")
      .attr("d", medianLine)
      .attr("clip-path", "url(#rect-clip)");
  }

  private drawLine(data: IDataPoint[]) {
    const line = d3
      .line<IDataPoint>()
      .curve(d3.curveBasis)
      .x((d: IDataPoint) => this.x(d.minutesAfterMidnight))
      .y((d: IDataPoint) => this.y(d.value));

    this.svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#83999c")
      .attr("stroke-width", 2)
      .attr("stroke-dasharray", "2 2") // Dotted line pattern
      .attr("d", line);
  }

  private transformRawData() {
    const percentileRawData: IStatistic = this.statsObsGroups.statistics.find((stat) => stat.keyName === "stat.percentile");
    const percentileData = ChartHelper.percentileStatToDataPoint(percentileRawData);
    const minRawData: IStatistic = this.statsObsGroups.statistics.find((stat) => stat.keyName === "stat.min");
    const minData = ChartHelper.statToDataPoint(minRawData);
    const maxRawData: IStatistic = this.statsObsGroups.statistics.find((stat) => stat.keyName === "stat.max");
    const maxData = ChartHelper.statToDataPoint(maxRawData);
    return { percentileData, minData, maxData };
  }

  private updateChartConfig(maxData: IDataPoint[]) {
    this.chartConfig.yAxis.min = 0;
    this.chartConfig.yAxis.max = d3.max(maxData, function (d) {
      return d.value;
    });
  }
}
