<mat-form-field class="mat-field">
  <input
    type="text"
    [placeholder]="'forms.birthplace' | translate"
    matInput
    [formControl]="townServerSideFilteringCtrl"
    [matAutocomplete]="auto"
    (ngModelChange)="onTownNameChanged($event)"
    (blur)="onBlur()"
    [required]="isRequired"
    #input
  />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="townDisplay">
    <mat-option *ngFor="let town of filteredServerSideTown | async" [value]="town"> {{ town.display }} ({{ town.reference }}) </mat-option>
  </mat-autocomplete>
</mat-form-field>
