import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IHealthcareservice } from "src/app/models/healthcareservice";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class HealthcareserviceApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "HealthCareServices", // Theme !
      ["service"], // Post route paths
      ["services"], // Get route paths
      ["service"], // Update route paths
      [] // Delete route paths
    );
  }

  public list(organizationId?: string): Observable<IHealthcareservice[]> {
    return this.api.get(this.readRoutes[0], {
      identifier: organizationId,
    }) as Observable<IHealthcareservice[]>;
  }

  public create(healthcareService: IHealthcareservice): Observable<IHealthcareservice> {
    return this.api.post(this.createRoutes[0], {
      service: healthcareService,
    }) as Observable<IHealthcareservice>;
  }

  public update(healthcareService: IHealthcareservice): Observable<IHealthcareservice> {
    return this.api.put(this.updateRoutes[0], {
      service: healthcareService,
    }) as Observable<IHealthcareservice>;
  }
}
