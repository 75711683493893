<mat-drawer-container #drawer class="mat-elevation-z1" cdkDropListGroup *ngIf="careplanEditorService.careplanTemplateReady$ | async">
  <mat-drawer mode="side" opened position="end">
    <!-- SOURCE LIST (right column)-->
    <div class="drawer-title-container">
      <h2 class="drawer-title">{{ "page.careplanEditor.tabs.knowledge.titleAvailable" | translate }}</h2>
      <button
        mat-stroked-button
        (click)="addKnowledge()"
        [matTooltip]="'page.knowledgeList.btn.add' | translate"
        *ngIf="('dashboard/knowledge' | isAuthorized : 'POST' | async) === true"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <app-knowledge-list-page
      [visualization]="true"
      [displayedColumns]="columns"
      [nativeFilters]="filters"
      [savePreferences]="false"
      [draggable]="true"
      [dropListId]="'drop-list-1'"
      [dropListConnectedTo]="'drop-list-2'"
      [dropListSortingDisabled]="true"
    ></app-knowledge-list-page>
  </mat-drawer>
  <mat-drawer-content>
    <!-- TARGET LIST-->
    <!-- Knowledge linked to careplan -->
    <h3>{{ "page.careplanEditor.tabs.knowledge.titleLinked2Careplan" | translate }}</h3>
    <app-linkable-items-list
      *ngIf="link2CareplanKnowledges && columns"
      [id]="'drop-list-2'"
      [connectedTo]="'drop-list-1'"
      [careplanTemplate]="careplanTemplate"
      [items]="link2CareplanKnowledges"
      [linkedToCareplanItems]="link2CareplanKnowledges"
      [linkedToActivitiesItems]="activitiesKnowledges"
      [columns]="columns"
      [actions]="actions"
      (itemsChange)="update($event)"
      [isTarget]="true"
    >
    </app-linkable-items-list>

    <mat-spinner *ngIf="!link2CareplanKnowledges" style="margin: auto"></mat-spinner>

    <!-- Knowledge linked to activities -->

    <h3 class="mt-1">{{ "page.careplanEditor.tabs.knowledge.titleLinked2Activities" | translate }}</h3>
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel *ngFor="let activityFormGroup of careplanEditorService.careplanTemplateForm?.get('activity').controls">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ activityFormGroup | getActivityName : currentLanguage }}</mat-panel-title>
        </mat-expansion-panel-header>
        <app-linkable-items-list
          *ngIf="activitiesKnowledges && columns"
          [id]="'drop-list-2'"
          [connectedTo]="'drop-list-1'"
          [careplanTemplate]="careplanTemplate"
          [items]="activitiesKnowledges | filterByActivity : activityFormGroup.value.reference.reference"
          [linkedToCareplanItems]="link2CareplanKnowledges"
          [linkedToActivitiesItems]="activitiesKnowledges"
          [columns]="columns"
          [actions]="actions"
          (itemsChange)="update($event)"
          [isTarget]="true"
          [activityReference]="activityFormGroup.value.reference"
        >
        </app-linkable-items-list>
        <mat-spinner *ngIf="!activitiesKnowledges" style="margin: auto"></mat-spinner>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-drawer-content>
</mat-drawer-container>
