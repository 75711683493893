import { Entity } from "./entity.interface";
import { IGroup } from "./group.interface";
import { Reference } from "./reference.interface";

export class Group extends Entity implements IGroup {
  public groupName: string;
  public caremateIdentifier: string;
  public patients: Reference[];
  public public: boolean;
  public healthcareservice: Reference;
  constructor(data: IGroup) {
    super();
    this.groupName = data.groupName;
    this.caremateIdentifier = data.caremateIdentifier;
    this.patients = data.patients;
    this.public = data.public;
    this.healthcareservice = data.healthcareservice;
    this._id = data._id;
  }

  public get isValid(): boolean {
    return !!(this.groupName && this.caremateIdentifier && this.healthcareservice.display && this.healthcareservice.reference);
  }
}
