<div class="close-cross">
  <button mat-icon-button mat-dialog-close class="negative-margin">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="globalHelp">
  <!-- Only show stepper if more than one slide -->
  <mat-horizontal-stepper *ngIf="!isOnlyOnePage()" #stepper>
    <mat-step *ngFor="let slide of slides">
      <h2>{{ slide.title | translate }}</h2>
      <!-- Loop on descriptions and keep index to get image -->
      <div *ngFor="let description of slide.descriptions; let i = index">
        <p [innerHTML]="description | translate : { cgu: ('page.login.cgu' | translate), link: ('page.login.cguLink' | translate) }"></p>
        <br />
        <img
          class="helpImg"
          [src]="slide.images[i]?.path"
          [ngStyle]="{
            height: slide.images[i]?.height,
            width: slide.images[i]?.width
          }"
        />
        <br />
      </div>
      <!-- Only show more images if necessary -->
      <div *ngIf="isMoreImageThanDescription(slide)">
        <!-- Loop on images not show yet in previous div -->
        <div *ngFor="let image of getMissingImages(slide)">
          <img class="helpImg" [src]="image.path" [ngStyle]="{ height: image.height, width: image.width }" />
        </div>
      </div>
      <div class="action-container">
        <button mat-button matStepperPrevious *ngIf="!isFirstSlide">
          {{ "globalHelp.backSlide" | translate }}
        </button>
        <button mat-button matStepperNext class="next-btn" *ngIf="!isLastSlide">
          {{ "globalHelp.nextSlide" | translate }}
        </button>
        <button mat-button mat-dialog-close *ngIf="isLastSlide">
          {{ "globalHelp.endSlide" | translate }}
        </button>
      </div>
    </mat-step>
    <!-- Icon overrides. -->
    <ng-template matStepperIcon="edit" let-index="index">
      {{ index + 1 }}
    </ng-template>
  </mat-horizontal-stepper>
  <!-- Only show this div if one slide -->
  <div *ngIf="isOnlyOnePage()">
    <h2>{{ slides[0].title | translate }}</h2>
    <!-- Loop on descriptions and keep index to get image -->
    <div *ngFor="let description of slides[0].descriptions; let i = index">
      <p>{{ description | translate }}</p>
      <br />
      <img
        class="helpImg"
        [src]="slides[0]?.images[i]?.path"
        [ngStyle]="{
          height: slides[0]?.images[i]?.height,
          width: slides[0]?.images[i]?.width
        }"
      />
      <br />
    </div>
    <!-- Only show more images if necessary -->
    <div *ngIf="isMoreImageThanDescription(slides[0])">
      <!-- Loop on images not show yet in previous div -->
      <div *ngFor="let image of getMissingImages(slides[0])">
        <img class="helpImg" [src]="image.path" [ngStyle]="{ height: image.height, width: image.width }" />
      </div>
    </div>
  </div>
</mat-dialog-content>
