import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subject, from, fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, first, takeUntil, tap } from "rxjs/operators";
import { Choice } from "src/app/components/item-filter/filters/choice-filter/choice-filter.component";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { ACTION_TARGET } from "src/app/models/careplans.interface";
import { DataType, Filter } from "src/app/models/filter.interface";
import { IKnowledgeBase } from "src/app/models/knowledge.interface";
import { PreferenceContext, TableParameter } from "src/app/models/preference.interface";
import { KnowledgeApiService } from "src/app/providers/api/knowledge-api.service";
import { DragAndDropService } from "src/app/providers/drag-and-drop.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { KnowledgeService } from "src/app/providers/knowledge.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { KnowledgeCreationDialogComponent } from "../../components/forms/add-knowledge/add-knowledge.component";
import { KnowledgeDataSource } from "./knowledge.datasource";

@Component({
  selector: "app-knowledge-list-page",
  templateUrl: "./knowledge-list-page.component.html",
  styleUrls: ["./knowledge-list-page.component.scss"],
})
export class KnowledgeListPageComponent implements AfterViewInit, OnInit, OnDestroy {
  @Output() itemsChange = new EventEmitter<unknown>(); // event emitter to track changes on the elements list
  public action_target = ACTION_TARGET;

  constructor(
    private knowledgeApiService: KnowledgeApiService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private knowledgeService: KnowledgeService,
    private sessionService: SessionService,
    private snackBar: MatSnackBar,
    private router: Router,
    private userService: UserService,
    private preferenceService: PreferenceService,
    private healthcareService: HealthcareserviceService,
    public dragAndDropService: DragAndDropService
  ) {
    this.preferences = this.route?.snapshot?.data.preferences;

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        this.loading = true;
      }
      if (ev instanceof NavigationEnd || ev instanceof NavigationCancel || ev instanceof NavigationError) {
        this.loading = false;
      }
    });
  }

  private preferences: TableParameter;
  dataSource: KnowledgeDataSource;
  knowledgeCount: number;
  includeShortDesc = false;

  filtersPropertyNames = {
    creation: "creation",
    publicationDate: "publicationDate",
    type: "type",
    author: "author.display",
    organization: "organization.display",
    category: "category",
    term: "term",
    reference: "reference",
  };

  public filters: Filter[] = [];
  public isFiltered = false;

  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public canCreateKnowledge: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("searchInput") searchInput: ElementRef; // input mot clé dans le html
  @ViewChild("checkbox") checkbox: MatCheckbox; // input mot clé dans le html
  @ViewChild("searchClearBtn", { read: ElementRef }) searchClearBtn: ElementRef;
  private onDestroy$ = new Subject<void>();
  public loading: boolean;
  @Input() visualization?: boolean;
  @Input() nativeFilters?: Filter[];

  @Input() savePreferences = true;
  @Input() draggable = false;
  @Input() dropListId?: string;
  @Input() dropListConnectedTo?: string;
  @Input() dropListSortingDisabled?: boolean;
  @Input() displayedColumns = [
    "public",
    "term",
    "reference",
    "category",
    "type",
    "author.display",
    "medias",
    "creation",
    "publicationDate",
  ]; // ["term", "category", "type"]

  ngOnInit(): void {
    if (this.draggable) {
      this.displayedColumns = ["grip"].concat(Tools.clone(this.displayedColumns)).concat(["edit"]);
    }
    this.userService
      .isAuthorized("dashboard/knowledge", "POST")
      .pipe(first())
      .subscribe((res) => {
        this.canCreateKnowledge = res;
      });
    // creating dataSource
    this.dataSource = new KnowledgeDataSource(this.knowledgeApiService);

    // handle paginator
    if (Tools.isDefined(this.route?.snapshot?.data?.knowledgeCount?.count)) {
      // get knowledge count from the resolver
      this.knowledgeCount = this.route.snapshot.data.knowledgeCount.count;
    }
  }

  ngAfterViewInit(): void {
    if (this.savePreferences) {
      this.applyPreferences();
    }

    const firstLoad$ = from([1]);
    const searchEvent$ = fromEvent(this.searchInput.nativeElement, "keyup");
    const checkedEvent$ = fromEvent(this.checkbox._inputElement.nativeElement, "click");
    const searchResetEvent$ = fromEvent(this.searchClearBtn.nativeElement, "click");

    // server-side search
    merge(firstLoad$, searchEvent$, checkedEvent$, searchResetEvent$)
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadKnowledge();
          this.updatePreference();
        })
      )
      .subscribe();

    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.loadKnowledge();
          this.updatePreference();
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();
    this.setupServicesWatch();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setupServicesWatch() {
    if (this.userService.isMonitoringUser) {
      this.sessionService.refreshCurrentMonitService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.paginator.pageIndex = 0;
        this.loadKnowledge();
      });
    }
    this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.paginator.pageIndex = 0;
      this.loadKnowledge();
    });
  }

  loadKnowledge(): void {
    if (!this.userService.isAuthorizedSync(null, "dashboard/knowledgeInfos", "GET")) {
      FileLogger.error("KnowledgeListPage", "Not Authorized to load knowledge");
      return;
    }
    const currentService = this.userService.isMonitoringUser
      ? this.sessionService.currentMonitoringService
      : this.sessionService.currentService;
    let ref = currentService?.reference;
    let services = [ref];
    if (ref === this.sessionService.allsOption) {
      services = [];
      const allServices = this.userService.isMonitoringUser
        ? this.healthcareService.availableMonitoringServices()?.map((s) => s.serviceRef)
        : this.healthcareService.availableServices()?.map((s) => s.serviceRef);
      services.push(...allServices);
      ref = null;
    }
    this.dataSource.loadData({
      sortId: this.sort.active,
      sortOrder: this.sort.direction,
      pageNumber: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      filters: this.getFiltersFormated().concat(this.nativeFilters ? this.nativeFilters : []),
      search: this.searchInput.nativeElement.value,
      includeShortDesc: this.includeShortDesc,
      services,
    });
    if (!this.userService.isAuthorizedSync(null, "dashboard/nbKnowledges", "GET")) {
      return;
    }
    // update knowledge count with search result
    this.knowledgeApiService
      .getKnowledgeCount(
        this.searchInput.nativeElement.value,
        this.includeShortDesc,
        this.getFiltersFormated().concat(this.nativeFilters ? this.nativeFilters : []),
        services
      )
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.knowledgeCount = result?.count;
      });
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter, isInit = false): void {
    this.dataSource.setFilter(filter);
    if (!isInit) {
      this.paginator.pageIndex = 0;
    }
    this.loadKnowledge();
    this.isFiltered = this.filters && this.filters.length > 0;

    this.updatePreference();
  }
  public clearFilter(): void {
    this.searchInput.nativeElement.value = "";
    this.dataSource.clearFilter();
    this.paginator.pageIndex = 0;
    this.filters = [];
    this.isFiltered = false;
    this.includeShortDesc = false;
    this.loadKnowledge();
    this.updatePreference();
  }

  private getFiltersFormated(): Filter[] {
    const formatedFilters: Filter[] = [];
    const filters = Tools.clone(this.dataSource.getAllFilters());
    if (filters && filters.length > 0) {
      filters.forEach((filter) => {
        switch (filter.dataType) {
          case this.dataTypeDate:
            // format date params to string
            filter.data.fromDate = moment(filter.data.fromDate).format("YYYY-MM-DD");
            filter.data.toDate = moment(filter.data.toDate).format("YYYY-MM-DD");
            break;
          case this.dataTypeChoice: {
            const values: Array<string> = [];
            const choices: Choice[] = filter.data ? filter.data : [];
            choices.forEach((choice) => {
              if (choice.checked) {
                values.push(choice.value);
              }
            });
            filter.data = {
              value: values,
            };
            break;
          }
        }
        formatedFilters.push(filter);
      });
    }
    this.filters = formatedFilters;
    return formatedFilters;
  }

  public addKnowledge(): void {
    this.dialog.open(KnowledgeCreationDialogComponent, {
      disableClose: false,
    });
  }

  /**
   * Called when the user select a json file to import as new knowledge.
   * Remove all identifying fields of the imported knowledge and make a draft
   * from it.
   * @param event the event containing the file selected by the user
   */
  public onKnowledgeFileSelected(event: File[]): void {
    const file: File = event[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        try {
          const knowledgeStr = fileReader.result as string;
          const newKnowledge = JSON.parse(knowledgeStr) as IKnowledgeBase;
          delete newKnowledge._id;
          delete newKnowledge.publicationDate;
          delete newKnowledge.creation;
          delete newKnowledge.modified;
          for (const media of newKnowledge.medias) {
            media.specificQuestionnaire = [];
          }
          newKnowledge.author = {
            reference: this.sessionService.account.caremateIdentifier,
            display: this.sessionService.account.displayName,
          };
          newKnowledge.entityStatus = [1];
          newKnowledge.healthcareservice = [];
          newKnowledge.organization = undefined;
          this.knowledgeService.createDraft(newKnowledge).subscribe(
            (k: IKnowledgeBase) => {
              this.router.navigate(["/knowledgeDetails", { id: k.identifier.value }]);
            },
            (err: unknown) => {
              FileLogger.error("KnowledgeListPageComponent", "Error while creating knowledge: ", err);
              this.translateService.get("page.knowledgeList.importKnowledgeError").subscribe((trans) => {
                this.snackBar.open(trans, "ok", { duration: 10000 });
              });
            }
          );
        } catch (err) {
          FileLogger.error("KnowledgeListPageComponent", "Error while importing knowledge: ", err);
          this.translateService.get("page.knowledgeList.importKnowledgeError").subscribe((trans) => {
            this.snackBar.open(trans, "ok", { duration: 10000 });
          });
        }
      };
      fileReader.readAsText(file);
    }
  }

  /**
   * Preferences
   */
  public updatePreference(): void {
    if (this.savePreferences) {
      this.preferenceService
        .update({
          context: PreferenceContext.KNOWLEDGE_LIST,
          parameters: {
            filters: this.dataSource.getAllFilters(), // we save the filters unformatted.
            itemsPerPage: this.paginator.pageSize,
            search: this.searchInput.nativeElement.value,
            includeShortDesc: this.includeShortDesc,
            sortId: this.sort.active,
            sortOrder: this.sort.direction,
          } as TableParameter,
        })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe();
    }
  }

  /**
   * Apply Preferences using a timeout to defer the change detection until the next cycle
   * to avoid ExpressionChangedAfterItHasBeenCheckedError
   */
  public applyPreferences(): void {
    setTimeout(() => {
      // set the filters
      this.preferences?.filters.forEach((filter) => this.dataSource.setFilter(filter));
      if (this.dataSource.getAllFilters().length) {
        this.isFiltered = true;
      }
      // set the search string and short description option
      this.searchInput.nativeElement.value = this.preferences?.search ? this.preferences.search : "";
      this.includeShortDesc = this.preferences?.includeShortDesc ? this.preferences.includeShortDesc : false;
      // Paginator size
      if (this.preferences?.itemsPerPage) this.paginator.pageSize = this.preferences.itemsPerPage;
      // Sort preferences
      if (this.preferences?.sortId && this.preferences.sortOrder) {
        this.sort.sort({ id: this.preferences.sortId, start: this.preferences.sortOrder, disableClear: false });
      }
    });
  }

  public editElement(knowledge: IKnowledgeBase): void {
    this.router.navigate(["/knowledgeDetails", { id: knowledge.identifier }], { state: { previousLocation: this.router.url } });
  }
}
