import { Pipe, PipeTransform } from "@angular/core";
import { IStepwise, IStepwiseDrug } from "../models/stepwiseDrug.interface";

@Pipe({ name: "isLastOrFirstCellOfStepwise" })
export class IsLastOrFirstCellOfStepwisePipe implements PipeTransform {
  public transform(lastCellClickedIndex: number, stepwiseDrug: IStepwiseDrug): boolean {
    lastCellClickedIndex = +lastCellClickedIndex;
    let isLastOrFirst = false;
    stepwiseDrug.stepwises.forEach((stepwise) => {
      if (stepwise.startDay === lastCellClickedIndex || stepwise.startDay + stepwise.days.length - 1 === lastCellClickedIndex) {
        isLastOrFirst = true;
      }
    });
    return isLastOrFirst;
  }
}

@Pipe({ name: "getClassesForStepwise" })
export class GetClassesForStepwisePipe implements PipeTransform {
  constructor(private isStepwiseSelected: IsStepwiseSelectedPipe, private getStepwise: GetStepwisePipe) {}

  public transform(index: number, cellsCurrentlySelected: number[], lastCellClickedIndex: number, stepwiseDrug: IStepwiseDrug): string {
    index = +index;
    let classes = "";
    if (cellsCurrentlySelected?.includes(index) || index === lastCellClickedIndex) {
      classes += "selecting";
    } else if (this.isStepwiseSelected.transform(index, stepwiseDrug)) {
      classes += "created";
    } else {
      classes += "no-color";
    }

    if (this.isStepwiseSelected.transform(lastCellClickedIndex, stepwiseDrug)) {
      const stepwiseSelected = this.getStepwise.transform(lastCellClickedIndex, stepwiseDrug);
      if (stepwiseSelected?.startDay <= index && index <= stepwiseSelected?.startDay + stepwiseSelected.days.length - 1) {
        classes += " first-plan";
      } else {
        classes += " background";
      }
    }
    const stepwiseAtCell = this.getStepwise.transform(index, stepwiseDrug);
    if (stepwiseAtCell?.startDay === index) {
      classes += " first-cell-stepwise";
    }
    if (stepwiseAtCell?.startDay + stepwiseAtCell?.days.length - 1 === index) {
      classes += " last-cell-stepwise";
    }
    return classes;
  }
}

@Pipe({ name: "isStepwiseSelected" })
export class IsStepwiseSelectedPipe implements PipeTransform {
  public transform(index: number, stepwiseDrug: IStepwiseDrug): boolean {
    index = +index;
    let isSelected = false;
    for (let i = 0; i < stepwiseDrug?.stepwises?.length && !isSelected; i++) {
      isSelected =
        index >= stepwiseDrug?.stepwises[i]?.startDay &&
        index < stepwiseDrug?.stepwises[i]?.startDay + stepwiseDrug.stepwises[i]?.days.length;
    }
    return isSelected;
  }
}

@Pipe({ name: "getStepwise" })
export class GetStepwisePipe implements PipeTransform {
  public transform(lastCellClickedIndex: number, stepwiseDrug: IStepwiseDrug): IStepwise {
    return stepwiseDrug?.stepwises?.find(
      (stepwise) => stepwise.startDay <= lastCellClickedIndex && lastCellClickedIndex <= stepwise.startDay + stepwise.days.length - 1
    );
  }
}
