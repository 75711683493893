import { CustomPatientInfos, IRewardScoreExtended } from "./rewardScore.interface";
import { RewardScore } from "./rewardScore.model";

export class RewardScoreExtended extends RewardScore {
  public patientCustomInfo?: CustomPatientInfos;

  constructor(data: IRewardScoreExtended) {
    super(data);
    this.patientCustomInfo = data.patientCustomInfo;
  }
}
