import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-warning-box-modal",
  templateUrl: "./warning-box-modal.component.html",
  styleUrls: ["./warning-box-modal.component.scss"],
})
export class WarningBoxModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { text: string; type: "warning" | "error" | "info" | "help" }
  ) {}
}
