export const MAX_CHART_LAST_ITEMS = 999;
export const WIDTH_RATIO = 0.8;
export const HEIGHT_RATIO = 0.6;

export interface CustomPoint {
  o: number;
  h: number;
  l: number;
  c: number;
}

export interface IChartColor {
  backgroundColor;
  borderColor;
  pointBackgroundColor;
  pointBorderColor;
  pointHoverBackgroundColor;
  pointHoverBorderColor;
}

export interface IMinmax {
  min: number;
  max: number;
}

export interface IChartYAxis {
  id: string;
  display: boolean;
  ticks: ITicks;
  position: "left" | "right";
}

export interface ITicks {
  fontColor: string;
  suggestedMin: number;
  suggestedMax: number;
  max?: number;
  stepSize: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (arg0?: any, arg1?: any, arg2?: any) => any;
}

export const COLORS = [
  "#005FB3", // blue - 0
  "#FA9700", // orange - 1
  "#ceedd7", // lightgreen - 2
  "#831F81", // purple - 3
  "#f5718f", // lightred - 4
  "#CC2BB1", // pink - 5
  "#727272", // gray - 6
  "#008c02", // green 2 - 7
  "#82c5ff", // lightBlue - 8
  "#d62202", // red - 9
];

export interface ChartSettings {
  axeYchangePosition: boolean;
  // bloodPressureShownMethod: boolean; SEE CMATE-1301
}
