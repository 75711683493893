import { Observable } from "rxjs";
import { MatTableDataSourceBackendExtended } from "src/app/helpers/datasource-backend";
import { DataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { IKnowledgeInfo } from "src/app/models/knowledge.interface";
import { KnowledgeApiService } from "src/app/providers/api/knowledge-api.service";

export class KnowledgeDataSource extends MatTableDataSourceBackendExtended<IKnowledgeInfo> {
  constructor(private knowledgeApi: KnowledgeApiService) {
    super();
  }

  public data(params: DataSourceBackendParam): Observable<IKnowledgeInfo[]> {
    return this.knowledgeApi.findKnowledge(params) as Observable<IKnowledgeInfo[]>;
  }
}
