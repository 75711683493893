import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { Activity } from "src/app/models/careplans.interface";

export class ActivityListDataSource extends MatTableDataSourceExtended<Activity> {
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  sortingDataAccessor = (data: Activity, sortHeaderId: string) => {
    switch (sortHeaderId) {
      case "name":
        return data.reference.display;
      case "startDate":
        return moment(data.detail.scheduledString).unix();
      case "endDate":
        return moment(data.detail.scheduledString).unix();
      case "responsable":
        return this.getActivityPerformerDisplay(data);
      case "status":
        return data.detail.status;
      default:
        return "";
    }
  };

  constructor() {
    super();
  }

  public loadData(activeActivity: Activity[]) {
    this.data = activeActivity;
  }

  public getActivityPerformerDisplay(activity: Activity): string | null {
    if (activity?.detail?.performer && activity.detail.performer.length) {
      return activity.detail.performer[0].display;
    }
    return null;
  }
}
