import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IOrganization } from "src/app/models/organization.interface";
import { OrganisationType, Organization } from "src/app/models/organization.model";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class OrganizationsApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Organizations", // Theme !
      ["organization"], // Post route paths
      ["organizations", "organization"], // Get route paths
      ["organization"], // Update route paths
      [] // Delete route paths
    );
  }

  public listSome(code: string, country?: string): Observable<Organization[]> {
    return this.api.get(this.readRoutes[0], { code, country: country ? country : undefined }).pipe(
      map((values: IOrganization[]) => {
        return values.map((v) => new Organization(v));
      })
    );
  }

  public list(organizationId?: string, type?: OrganisationType): Observable<Organization[]> {
    const params: { identifier?: string; code?: OrganisationType } = {};
    if (organizationId) {
      params.identifier = organizationId;
    }
    if (type) {
      params.code = type;
    }

    return this.api.get(this.readRoutes[0], params).pipe(
      map((values: IOrganization[]) => {
        return values.map((v) => new Organization(v));
      })
    );
  }

  public create(organization: Organization): Observable<Organization> {
    return this.api.post(this.createRoutes[0], organization).pipe(map((v: IOrganization) => new Organization(v)));
  }

  public update(organization: Organization): Observable<Organization> {
    return this.api.put(this.updateRoutes[0], { organization }).pipe(map((v: IOrganization) => new Organization(v)));
  }
}
