<div class="buttons-bar">
  <button mat-icon-button color="warn" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<h2 mat-dialog-title></h2>
<mat-dialog-content>
  <p>
    {{ "page.careplanEditor.tabs.observations.observationDef" | translate }}
  </p>
  <ui-file-drop
    #uiFileDrop
    [acceptedTypes]="['application/json']"
    typeErrorTranlationKey="page.careplanEditor.tabs.observations.fileErrors.type"
    [maxSize]="5000000"
    sizeErrorTranslationKey="page.careplanEditor.tabs.observations.fileErrors.size"
    [hasError]="hasError"
    (chosenFile)="setFile($event, 'obsDef')"
  ></ui-file-drop>
  <p>
    {{ "page.careplanEditor.tabs.observations.observationParam" | translate }}
  </p>
  <ui-file-drop
    #uiFileDrop
    [acceptedTypes]="['application/json']"
    typeErrorTranlationKey="page.careplanEditor.tabs.observations.fileErrors.type"
    [maxSize]="5000000"
    sizeErrorTranslationKey="page.careplanEditor.tabs.observations.fileErrors.size"
    [hasError]="hasError"
    (chosenFile)="setFile($event, 'obsParam')"
  ></ui-file-drop>
  <ng-container *ngIf="('dashboard/rule' | isAuthorized : 'POST' | async) === true">
    <p>
      {{ "page.careplanEditor.tabs.observations.observationRule" | translate }}
    </p>
    <ui-file-drop
      #uiFileDrop
      [acceptedTypes]="['application/json']"
      typeErrorTranlationKey="page.careplanEditor.tabs.observations.fileErrors.type"
      [maxSize]="5000000"
      sizeErrorTranslationKey="page.careplanEditor.tabs.observations.fileErrors.size"
      [hasError]="hasError"
      (chosenFile)="setFile($event, 'obsRule')"
    ></ui-file-drop>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions class="btn-container">
  <button mat-raised-button color="primary" (click)="saveFiles()">
    {{ "btn.save" | translate }}
  </button>
</mat-dialog-actions>
