import { DragDropModule } from "@angular/cdk/drag-drop";
import { CdkTableModule } from "@angular/cdk/table";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { DrugServerSideSearchModule } from "src/app/components/patient-drugs/drug-add/drug-server-side-search-component/drug-server-side-search.module";
import { TimingEditorModule } from "src/app/components/timing-editor/timing-editor.module";
import { UiAlertModule } from "src/app/components/ui-alert/ui-alert.module";
import { UiFileDropModule } from "src/app/components/ui-file-drop/ui-file-drop.module";
import { I18nModule } from "src/app/i18n.module";
import { EnableWhenInputComponent } from "src/app/pages/knowledge-details-page/knowledge-criteria/enable-when-input/enable-when-input.component";
import { KnowledgeCriteriaComponent } from "src/app/pages/knowledge-details-page/knowledge-criteria/knowledge-criteria.component";
import { WhenInputModule } from "src/app/pages/knowledge-details-page/knowledge-criteria/when-input/when-input.module";
import { KnowledgeListPipesModule } from "src/app/pages/knowledge-list-page/knowledge-list-pipes/knowledge-list-pipes.module";
import { KnowledgeListPageModule } from "src/app/pages/knowledge-list-page/knowledge-list.module";
import { GetShortnamePipe } from "src/app/pipes/get-shortname.pipe";
import { PipesModule } from "src/app/pipes/pipes.module";
import { FiltersDisplayModule } from "../../../components/filters-display/filters-display.module";
import { ItemFilterModule } from "../../../components/item-filter/item-filter.module";
import { CareplanEditorPageRoutingModule } from "../../application/careplan-editor-page/careplan-editor-page-routing.module";
import { CareplanEditorPipesModule } from "../../domain/careplan-editor-pipes/careplan-editor-pipes.module";
import { getDescriptionFilteredLangPipe } from "../../domain/careplan-editor-pipes/getDescriptionFilteredLang.pipe";
import { CareplanEditorPageComponent } from "./careplan-editor-page.component";
import { CareplanEditorPropertiesComponent } from "./components/careplan-editor-properties/careplan-editor-properties.component";
import { CareplanPublicationDialogComponent } from "./components/careplan-publication-dialog/careplan-publication-dialog.component";
import { CommunicationFormModule } from "./components/communication-form/communication-form.module";
import { ImportObsAndRuleComponent } from "./components/import-obs-and-rule/import-obs-and-rule.component";
import { LinkableItemsListComponent } from "./components/linkable-items-list/linkable-items-list.component";
import { QuestionnairesBackendTableComponent } from "./components/questionnaires-backend-table/questionnaires-backend-table.component";
import { TraductionFormComponent } from "./components/traduction-form/traduction-form.component";
import { CareplanEditorCommunicationsTabComponent } from "./tabs/careplan-editor-communications-tab/careplan-editor-communications-tab.component";
import { CareplanEditorGeneralTabComponent } from "./tabs/careplan-editor-general-tab/careplan-editor-general-tab.component";
import { CareplanJSONViewComponent } from "./tabs/careplan-editor-general-tab/careplan-jsonview/careplan-jsonview.component";
import { CareplanPrettyJSONViewComponent } from "./tabs/careplan-editor-general-tab/careplan-pretty-jsonview/careplan-pretty-jsonview.component";
import { CareplanVisualViewComponent } from "./tabs/careplan-editor-general-tab/careplan-visual-view/careplan-visual-view.component";
import { CareplanEditorKnowledgeTabComponent } from "./tabs/careplan-editor-knowledge-tab/careplan-editor-knowledge-tab.component";
import { CareplanEditorObservationsTabComponent } from "./tabs/careplan-editor-observations-tab/careplan-editor-observations-tab.component";
import { CareplanEditorQuestionnairesTabComponent } from "./tabs/careplan-editor-questionnaires-tab/careplan-editor-questionnaires-tab.component";
import { CareplanEditorVitalSignsTabComponent } from "./tabs/careplan-editor-vital-signs-tab/careplan-editor-vital-signs-tab.component";

@NgModule({
  declarations: [
    CareplanEditorPageComponent,
    CareplanEditorGeneralTabComponent,
    CareplanEditorQuestionnairesTabComponent,
    CareplanEditorObservationsTabComponent,
    CareplanEditorVitalSignsTabComponent,
    CareplanEditorKnowledgeTabComponent,
    CareplanEditorCommunicationsTabComponent,
    LinkableItemsListComponent,
    KnowledgeCriteriaComponent,
    EnableWhenInputComponent,
    ImportObsAndRuleComponent,
    QuestionnairesBackendTableComponent,
    getDescriptionFilteredLangPipe,
    TraductionFormComponent,
    CareplanEditorPropertiesComponent,
    CareplanVisualViewComponent,
    CareplanPrettyJSONViewComponent,
    CareplanJSONViewComponent,
    CareplanPublicationDialogComponent,
  ],
  providers: [GetShortnamePipe],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    I18nModule,
    CareplanEditorPageRoutingModule,
    MatSidenavModule,
    MatButtonModule,
    MatCardModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    DragDropModule,
    FormsModule,
    MatInputModule,
    NgxJsonViewerModule,
    PipesModule,
    KnowledgeListPipesModule,
    KnowledgeListPageModule,
    MatSelectModule,
    WhenInputModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatChipsModule,
    DrugServerSideSearchModule,
    CommunicationFormModule,
    MatDialogModule,
    MatSlideToggleModule,
    CareplanEditorPipesModule,
    ItemFilterModule,
    FiltersDisplayModule,
    MatProgressSpinnerModule,
    MatSortModule,
    CdkTableModule,
    MatProgressSpinnerModule,
    UiAlertModule,
    TimingEditorModule,
    UiFileDropModule,
  ],
})
export class CareplanEditorPageModule {}
