<mat-card class="widget-inside" class="big">
  <mat-toolbar color="primary" style="gap: 0.5rem">
    <p>{{ "stat.title" | translate }}</p>
    <span class="spacerBetween"></span>
    <div class="toggle-container" *ngIf="streamObs?.length">
      <p>{{ "observation.showStreamObs" | translate }}</p>
      <mat-slide-toggle [(ngModel)]="showStreamObs" (change)="onShowStreamChange()"></mat-slide-toggle>
    </div>
    <form [formGroup]="dateFilterForm">
      <div class="datePickerContainer">
        <mat-form-field>
          <input
            cdkFocusInitial
            matInput
            [max]="maxFromDate"
            [matDatepicker]="fromDateGraph"
            formControlName="fromDate"
            placeholder="{{ 'itemFilter.fromDate' | translate }}"
            (dateChange)="onDateChange()"
          />
          <mat-datepicker-toggle matSuffix [for]="fromDateGraph"></mat-datepicker-toggle>
          <mat-datepicker #fromDateGraph></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            [min]="minToDate"
            [matDatepicker]="toDateGraph"
            formControlName="toDate"
            placeholder="{{ 'itemFilter.toDate' | translate }}"
            (dateChange)="onDateChange()"
          />
          <mat-datepicker-toggle matSuffix [for]="toDateGraph"></mat-datepicker-toggle>
          <mat-datepicker #toDateGraph></mat-datepicker>
        </mat-form-field>
      </div>
    </form>

    <button
      mat-stroked-button
      *ngIf="('convertHTMLToPDF' | isAuthorized : 'POST' | async) === true"
      (click)="exportStats()"
      [disabled]="!currentDefinitions.length || exportPdfInProgress"
    >
      <mat-icon *ngIf="exportPdfInProgress"><mat-spinner diameter="24" class="white-spinner"></mat-spinner></mat-icon>
      <mat-icon *ngIf="!exportPdfInProgress">picture_as_pdf</mat-icon>
    </button>

    <app-item-selector [items]="sliderData" [useRadioButton]="showStreamObs" (changed)="sliderDataChange.emit(sliderData)">
    </app-item-selector>
  </mat-toolbar>
  <div #statContainer>
    <app-observation-stats-container
      *ngFor="let obsDef of currentDefinitions"
      [obsDefinition]="obsDef"
      [patientId]="pu.user.caremateIdentifier"
      [fromDate]="fromDate"
      [toDate]="toDate"
    ></app-observation-stats-container>
  </div>
</mat-card>
