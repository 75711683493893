export enum DataType {
  TEXT,
  DATE,
  CHOICE,
  COMBINED,
  REWARDSNAME,
}

export interface Filter {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  propertyName: string;
  translateKey?: string;
  dataType: DataType;
  combinedProperties?: string[];
}
