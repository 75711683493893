import { Identifier } from "./identifier.interface";

export enum INSIReturnCode {
  ONE_INDENTITY_FOUND = "00",
  NO_IDENTITY_FOUND = "01",
  SEVERAL_INDENTITIES_FOUND = "02",
  ERROR = "error",
  ACCESS_DENIED = "ACCESS DENIED",
  SERVER_ERROR = "SERVER ERROR",
}

export interface InsResponse {
  CodeCR: INSIReturnCode;
  NomNaissance?: string;
  Prenom?: string;
  ListePrenom?: string[];
  Sexe?: string;
  DateNaissance?: string;
  LieuNaissance?: string | undefined;
  MatriculeIns?: string;
  Oid?: string;
  historiqueIns?: historiqueIns[];
}

export interface historiqueIns {
  MatriculeIns?: string;
  Oid?: string;
  dateDeb?: string;
  dateFin?: string;
}
export enum INSIRequestMethod {
  TRAITS = "traits",
  CARTE_VITALE = "carte vitale",
}

export interface IJournalINSIEntry {
  userIds: Identifier[];
  requestDate: string;
  requestMethod: INSIRequestMethod; // "traits" or "carte vitale"
  INSIReturn: INSIReturnCode; // retour 00 - Une identité trouvée ou retour 01 - Aucune identité trouvée ou retour 02 - Plusieurs identités trouvées
  request: string;
  returnValue: string; // string representing the return xml (matricule INS, OID et traits retournés par INSi ainsi que l'historique des matricules INS)
}
