import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IExportPdf } from "src/app/models/export.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class ToolsApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Tools", // Theme !
      ["exportToPDF", "convertHTMLToPDF"], // Post route paths
      [], // Get route paths
      [], // Update route paths
      [] // Delete route paths
    );
  }

  public exportToPDF(data: IExportPdf): Observable<unknown> {
    return this.api.post(this.createRoutes[0], { data });
  }
  public convertHTMLToPDF(data: IExportPdf): Observable<unknown> {
    return this.api.post(this.createRoutes[1], { data });
  }
}
