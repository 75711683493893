import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { first, map, startWith, takeUntil } from "rxjs/operators";
import { Practitioner } from "../../models/practitioner.model";
import { SessionService } from "../../providers/session.service";
import { UserService } from "../../providers/user.service";

@Component({
  selector: "app-practitioner-selector",
  templateUrl: "./practitioner-selector.component.html",
  styleUrls: ["./practitioner-selector.component.scss"],
})
export class PractitionerSelectorComponent implements OnChanges, OnDestroy {
  @Output() selected: EventEmitter<Practitioner> = new EventEmitter<Practitioner>();
  @Input() availablePractitioners: Practitioner[]; //change to input
  public practitionerControl = new UntypedFormControl(null);
  public filteredPractitioners: Observable<Practitioner[]>;
  protected onDestroy$ = new Subject<void>();

  constructor(public userService: UserService, public sessionService: SessionService) {}

  ngOnChanges(): void {
    this.filteredPractitioners = this.practitionerControl.valueChanges.pipe(
      takeUntil(this.onDestroy$),
      startWith(""),
      map((value: string | Practitioner) => {
        const name = typeof value === "string" ? value : value?.mainMail?.value;
        return name ? this._filterPractitioners(name as string) : this.availablePractitioners?.slice();
      })
    );

    // set current Practitioner first first data load
    this.filteredPractitioners.pipe(first()).subscribe((practitioners) => {
      const currentPractitioner = practitioners?.find((el) => el.caremateId?.value === this.sessionService.account.caremateIdentifier);
      if (currentPractitioner) {
        this.practitionerControl.setValue(currentPractitioner);
        this.selected.emit(currentPractitioner);
      } else {
        // if the current practitionner is not in the list, we don't preselect it
        this.selected.emit(undefined);
      }
    });
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  /**
   * Display function for the autocomplete input
   * @param practitioner the selected practitioner
   * @returns
   */
  public displayFn(practitioner: Practitioner): string {
    return practitioner
      ? practitioner.firstname +
          " " +
          practitioner.familyName +
          (practitioner.mainMail?.value ? " (" + practitioner.mainMail?.value + ")" : "")
      : "";
  }
  private _filterPractitioners(value: string): Practitioner[] {
    const filterValue = value.toLowerCase();
    return this.availablePractitioners.filter(
      (practitioner) =>
        practitioner.mainMail?.value.toLowerCase().includes(filterValue) ||
        (practitioner.firstname + " " + practitioner.familyName).toLowerCase().includes(filterValue)
    );
  }
  public emitValue(): void {
    this.selected.emit(this.practitionerControl.value);
  }
}
