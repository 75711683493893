import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { ActivityLog } from "src/app/models/activity-log.interface";
import { PatientService } from "src/app/providers/patient.service";

export class LastPatientListDataSource extends MatTableDataSourceExtended<ActivityLog> {
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  sortingDataAccessor = (data: ActivityLog, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "patient":
        return this.patientService.getFullname(data.patient).toLocaleLowerCase();
      case "date":
        return moment(data.activityDate).unix();
      default:
        return "";
    }
  };

  constructor(public patientService: PatientService) {
    super();
  }

  public clear(): void {
    this.loadingSubject.complete();
  }

  public loadData(activityLog: ActivityLog[]): void {
    this.data = activityLog;
  }
}
