<div class="alertDetails">
  <div class="close-cross">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-card *ngIf="createAction && alert && allDefinitions">
    <h3>{{ "alerts.alertsDetails" | translate }}</h3>
    <table class="datatable">
      <thead>
        <th>{{ "table.date" | translate }}</th>
        <th>{{ "table.level" | translate }}</th>
        <th>{{ "table.name" | translate }}</th>
        <th>{{ "table.value" | translate }}</th>
        <th>{{ "table.responsable" | translate }}</th>
        <th>{{ "table.status" | translate }}</th>
      </thead>
      <tbody>
        <tr>
          <td class="w-200">
            {{ alert.creation | date : "short" }}
          </td>
          <td class="w-50">
            <mat-icon class="custom-icon-small" [ngClass]="alert | alertLevelClass">notifications </mat-icon>
          </td>
          <td class="w-200">
            <ul class="no-bullet">
              <li *ngFor="let valueComponent of alert.valueComponents">
                {{ valueComponent | translateAlertName : alert.observation : allDefinitions }}
              </li>
            </ul>
          </td>
          <td class="w-80">
            <ul class="no-bullet">
              <li *ngFor="let valueComponent of alert.valueComponents">
                {{
                  valueComponent.code.coding[0].code
                    | loincAndValueMeaning
                      : alert.observation?.code.coding[0].code
                      : valueComponent.valueQuantity.value
                      : allDefinitions
                      : true
                }}
              </li>
            </ul>
          </td>
          <td class="w-200">
            {{ alert.author?.display }}
          </td>
          <td class="w-80">
            {{ alert.statusTranslateKey() | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card>

  <!-- Alert details for noDataTransmission -->
  <mat-card *ngIf="createAction && alert && alert.ruleMeta.typeTarget === RULE_TYPE_TARGET.NO_DATA_TRANSMISSION">
    <h3>{{ "alerts.alertsDetails" | translate }}</h3>
    <table class="datatable">
      <thead>
        <th>{{ "table.patient" | translate }}</th>
        <th *ngFor="let item of $any(alert.noDataTransmission) | keyvalue">{{ "alerts.noDataTransmission." + item.key | translate }}</th>
        <th>{{ "table.status" | translate }}</th>
      </thead>
      <tbody>
        <tr>
          <td>{{ alert.subject.display }}</td>
          <td *ngFor="let item of $any(alert.noDataTransmission) | keyvalue">{{ item.value | date : "dd/MM/yyyy HH:mm" }}</td>
          <td>{{ alert.statusTranslateKey() | translate }}</td>
        </tr>
      </tbody>
    </table>
  </mat-card>

  <app-alert-details-edit
    (saved)="close(true)"
    [patientServiceId]="patientServiceId"
    [alert]="alert"
    [createAction]="createAction"
  ></app-alert-details-edit>
  <app-alert-details-historic *ngIf="createAction" [alert]="alert"></app-alert-details-historic>
</div>
