import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormsData } from "src/app/helpers/formsData";
import { Coding } from "src/app/models/coding.interface";
import { PatientUser } from "src/app/models/patient.interface";
import { PatientService } from "src/app/providers/patient.service";

@Component({
  selector: "app-edit-anonymous-patient",
  templateUrl: "./edit-anonymous-patient.component.html",
  styleUrls: ["./edit-anonymous-patient.component.scss"],
})
export class EditAnonymousPatientComponent implements OnInit, OnDestroy {
  @Input() patientUser: PatientUser;
  public isLoading = true;
  public patientForm = this.fb.group({
    birthDate: [undefined, [Validators.required]],
    gender: [undefined, [Validators.required]],
    pseudoname: [undefined],
  });
  public isAnonymous = false;
  public availableGender: Coding[];
  public maxDate: moment.Moment = moment();
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  public constructor(private fb: UntypedFormBuilder, public formsData: FormsData, private patientService: PatientService) {}

  ngOnInit() {
    this.availableGender = this.formsData.GENDER;
    this.patientForm.get("birthDate").setValue(moment(this.patientUser.user.birthdate));
    this.isLoading = false;
  }
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public save() {
    const patient = this.patientUser.patient;
    patient.birthDate = moment(this.patientForm.get("birthDate").value).format("YYYY-MM-DD");
    patient.gender = this.patientForm.get("gender").value;
    patient.pseudoname = this.patientForm.get("pseudoname").value;
    this.patientService.update(patient, true).pipe(takeUntil(this.onDestroy$)).subscribe();
  }
}
