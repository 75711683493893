<div class="buttons-bar">
  <button mat-icon-button color="primary" class="negative-margin" (click)="onResizeModal()">
    <mat-icon>{{ isFullScreen ? "close_fullscreen" : "open_in_full" }}</mat-icon>
  </button>
  <button mat-icon-button color="warn" class="negative-margin" matTooltip="{{ 'btn.close' | translate }}" (click)="dialog.closeAll()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="accordeonTitle"> {{ questionnaireResponse?.group.text ?? questionnaire?.subjectType }} </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="answerNewQr">
        <div *ngIf="isUpdate && !slideViews.length">
          {{ "model.patient.questionnaireNotAvailable" | translate : { lang: lang } }}
        </div>
        <ng-container *ngIf="questionnaireResponse?.group.text && questionnaireResponse?.group.text.length > 0">
          <p class="title">{{ questionnaireResponse?.group.title }}</p>
        </ng-container>
        <mat-horizontal-stepper linear #stepper class="stepper" labelPosition="bottom">
          <mat-step *ngFor="let slide of slideViews">
            <div *ngFor="let contentGroup of slide.content">
              <ng-template matStepLabel>{{ contentGroup.title }}</ng-template>
              <p style="text-align: center">{{ contentGroup.description }}</p>
              <div class="card-container">
                <ng-container *ngFor="let questionResponse of contentGroup.questionResponses; let i = index">
                  <mat-card
                    *ngIf="questionResponse.question | isQuestionEnabled : questionnaireResponse"
                    class="minimalPadding card-el mt-10"
                    [ngStyle]="questionResponse.question.type === INPUT_TYPE.TEXTFIELD_HTML ? { width: '100%' } : { width: '30%' }"
                  >
                    <p [ngClass]="{ red: isNotCompleted(questionResponse.question) }">
                      {{ questionResponse.question.text }}
                    </p>
                    <ng-container [ngSwitch]="questionResponse.question.type">
                      <div *ngSwitchCase="'choice'">
                        <mat-radio-group
                          class="radio-group"
                          (change)="onRadioChange($event, questionResponse.question)"
                          *ngIf="
                            (!questionResponse.question?.answerDisplay || questionResponse.question?.answerDisplay?.type === 'radio') &&
                            questionResponse.question.options?.reference
                          "
                        >
                          <mat-radio-button
                            class="radio-button"
                            [checked]="getCheckedRadio(questionResponse.question.linkId, valueset.code)"
                            [value]="valueset.code"
                            *ngFor="
                              let valueset of questionResponse.question.options?.reference | valueSetChoices : questionnaire.contained
                            "
                          >
                            <span class="wrap-mat-radio-label">
                              {{ valueset.display }}
                            </span>
                          </mat-radio-button>
                        </mat-radio-group>
                        <div
                          class="radio-group"
                          *ngIf="
                            questionResponse.question?.answerDisplay?.type === 'checkboxes' && questionResponse.question.options?.reference
                          "
                        >
                          <mat-checkbox
                            class="radio-button"
                            [checked]="getChecked(questionResponse.question.linkId, valueset.code)"
                            [value]="valueset.code"
                            *ngFor="
                              let valueset of questionResponse.question.options?.reference | valueSetChoices : questionnaire.contained;
                              let iCheckbox = index
                            "
                            (change)="onCheckboxChange($event, questionResponse.question)"
                          >
                            <span class="wrap-mat-radio-label">
                              {{ valueset.display }}
                            </span>
                          </mat-checkbox>
                        </div>
                      </div>
                      <div *ngSwitchCase="'text'" class="mt-15">
                        <div *ngIf="questionResponse.question?.answerDisplay?.type === 'externalLink'; else textAnswer">
                          <a [href]="questionResponse.question.answerDisplay.default | externalUrl">{{
                            questionResponse.question.answerDisplay.placeholder
                              ? questionResponse.question.answerDisplay.placeholder
                              : questionResponse.question.answerDisplay.default
                          }}</a>
                        </div>
                        <ng-template #textAnswer>
                          <form>
                            <mat-form-field class="input">
                              <mat-label>{{ "table.answer" | translate }}</mat-label>
                              <input
                                matInput
                                [value]="questionResponse.question.linkId | questionTextValue : questionnaire : questionnaireResponse"
                                (change)="setInput($event?.target?.value, questionResponse.question)"
                              />
                            </mat-form-field>
                          </form>
                        </ng-template>
                      </div>
                      <div *ngSwitchCase="'textfield'" class="mt-15">
                        <form>
                          <mat-form-field class="input">
                            <mat-label>{{ "table.answer" | translate }}</mat-label>
                            <textarea
                              matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="1"
                              cdkAutosizeMaxRows="10"
                              [value]="questionResponse.question.linkId | questionTextValue : questionnaire : questionnaireResponse"
                              (change)="setInput($event?.target?.value, questionResponse.question)"
                            ></textarea>
                          </mat-form-field>
                        </form>
                      </div>
                      <div *ngSwitchCase="'textfield-html'" class="mt-15" style="width: 100%">
                        <mat-label>{{ "table.answer" | translate }}</mat-label>
                        <jodit-editor
                          [config]="joditConfig"
                          [ngModel]="questionResponse.question.linkId | questionTextValue : questionnaire : questionnaireResponse"
                          (change)="setInput($event?.target?.value, questionResponse.question)"
                        ></jodit-editor>
                      </div>
                      <div *ngSwitchCase="'number'" class="mt-15">
                        <div *ngIf="questionResponse.question.answerDisplay?.multiInputs">
                          <form
                            style="display: flex"
                            *ngFor="
                              let qOption of questionResponse.question.options?.reference | valueSetChoices : questionnaire.contained;
                              let k = index
                            "
                          >
                            <mat-form-field class="input" style="max-width: 66%">
                              <mat-label>{{ "table.answer" | translate }}</mat-label>
                              <input
                                matInput
                                type="number"
                                id="inputNumber_{{ i }}_{{ k }}"
                                [value]="getQuestionResponseModel(questionResponse.question.linkId).answer[k * 2]?.valueCoding.code"
                                (change)="setInput($event?.target?.value, questionResponse.question, true, k, true)"
                              />
                            </mat-form-field>
                            {{ qOption.display }}
                          </form>
                        </div>
                        <form style="display: flex" *ngIf="!questionResponse.question.answerDisplay?.multiInputs">
                          <mat-form-field class="input" style="max-width: 66%">
                            <mat-label>{{ "table.answer" | translate }}</mat-label>
                            <input
                              matInput
                              type="number"
                              id="inputNumber_{{ i }}"
                              [value]="getQuestionResponseModel(questionResponse.question.linkId).answer[0]?.valueCoding.code"
                              (change)="
                                setInput(
                                  $event?.target?.value,
                                  questionResponse.question,
                                  questionResponse.question.options?.reference !== undefined
                                )
                              "
                            />
                          </mat-form-field>
                          <mat-form-field class="input" style="max-width: 33%" *ngIf="questionResponse.question.options?.reference">
                            <mat-select
                              [value]="getQuestionResponseModel(questionResponse.question.linkId).answer[1]?.valueCoding.code"
                              (selectionChange)="setInput($event?.value, questionResponse.question, true, 1)"
                            >
                              <mat-option
                                [value]="valueset.code"
                                *ngFor="
                                  let valueset of questionResponse.question.options?.reference | valueSetChoices : questionnaire.contained
                                "
                              >
                                {{ valueset.display }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </form>
                      </div>
                    </ng-container>
                  </mat-card>
                </ng-container>
              </div>
            </div>
            <div class="action-container">
              <button mat-button matStepperPrevious *ngIf="!isFirst && slideViews.length > 1">
                {{ "btn.back" | translate }}
              </button>
              <span *ngIf="isFirst && slideViews.length > 1"></span>
              <button mat-button matStepperNext *ngIf="!isLast && slideViews.length > 1">
                {{ "btn.next" | translate }}
              </button>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="linkedObservationsDef?.length && (!isUpdate || linkedObservations?.length)">
      <mat-expansion-panel-header>
        <mat-panel-title class="accordeonTitle"> {{ "observation.title" | translate }} </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="answerNewQr">
        <app-encode-observations
          [existingObservations]="linkedObservations"
          [observationsDefinitions]="linkedObservationsDef"
          [patientReference]="data.patientRef"
          [questionnaireIdentifier]="questionnaire.identifier[0].value"
          [disabled]="false"
          (newObservationsChange)="linkedObservationsChanged($event)"
        ></app-encode-observations>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    (click)="onValidate()"
    *ngIf="isLast || slideViews.length === 1 || linkedObservations?.length || linkedObservationsDef?.length"
  >
    {{ (!isUpdate ? "common.save" : "btn.update") | translate }}
  </button>
</mat-dialog-actions>
