<div class="close-cross">
  <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="patientHistoricDialog">
  <mat-card>
    <h3>{{ "alerts.historic" | translate }}</h3>
    <mat-card style="padding: 0px !important">
      <table mat-table matSort matSortDirection="desc" matSortActive="date" [dataSource]="dataSource">
        <!-- date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "table.date" | translate }}
            <app-item-filter
              fieldName="{{ 'table.date' | translate }}"
              propertyName="date"
              [dataType]="dataTypeDate"
              [defaultValue]="getFilter('date')"
              (applyFilter)="applyFilter($event)"
            >
            </app-item-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.date | date : "short" }}
          </td>
        </ng-container>
        <!-- Author Column -->
        <ng-container matColumnDef="author">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "table.author" | translate }}
            <app-item-filter
              fieldName="{{ 'model.patient.name' | translate }}"
              propertyName="author.display"
              [dataType]="dataTypeText"
              [defaultValue]="getFilter('author.display')"
              (applyFilter)="applyFilter($event)"
            >
            </app-item-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.author?.display }}
          </td>
        </ng-container>
        <!-- oldReason Column -->
        <ng-container matColumnDef="oldReason">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "table.exValue" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              element.oldReason === reason.DEATH ||
              element.oldReason === reason.HOSPITALIZATION ||
              element.oldReason === reason.PERSONALPROBLEM ||
              element.oldReason === reason.OTHER
                ? ("model.patient.disablePatientReason." + element.oldReason | translate)
                : element.oldReason
            }}
          </td>
        </ng-container>
        <!-- newReason Column -->
        <ng-container matColumnDef="newReason">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "table.newValue" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              element.newReason === reason.DEATH ||
              element.newReason === reason.HOSPITALIZATION ||
              element.newReason === reason.PERSONALPROBLEM ||
              element.newReason === reason.OTHER
                ? ("model.patient.disablePatientReason." + element.newReason | translate)
                : element.newReason
            }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="5" [pageSizeOptions]="[5, 10, 15]" [hidePageSize]="false"> </mat-paginator>
    </mat-card>
  </mat-card>
</div>
