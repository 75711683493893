import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "truncate" })
export class TruncatePipe implements PipeTransform {
  transform(value: string, characterNbr: number): string {
    let truncatedString = value?.slice(0, characterNbr);
    if (value.length > truncatedString.length) {
      truncatedString += "...";
    }
    return truncatedString;
  }
}
