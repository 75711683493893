export const template = `
<h5>%DATE_TITlE% : %DATE_VALUE%</h5>
%SCORES%
<h4>%QUESTIONS% :</h4>
%TABLES%
<style>
    .qTable {
        border-collapse: collapse;
    }
    .qTHead {
        margin-top: 20px !important;
    }
    .qTh,
    .qTd {
        border: 1px solid black;
    }
    h5 {
        text-align: right;
    }
    .fullW {
        width: 100%;
    }
    .scores-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: stretch;
        max-height: 300px;
    }
    .scores {
        flex-grow: 1;
        min-width: 240px;
        margin: 0px 8px 0px 8px;
    }
    .no-margin {
        margin: 0px !important;
    }
    .pl-10 {
        padding-left: 10px;
    }
    .greyBox {
        background: rgba(0, 0, 0, .5);
        color: rgba(0, 0, 0, .9);
        font-family: 'Helvetica', 'Arial', sans-serif;
        font-size: 1.2em;
        font-weight: normal;
        text-align: center;
        width: 20px;
        height: 20px;
        border-radius: 1px;
        margin: auto;
    }
    .fixedW-50 {
        width: 50%;
    }
    .fixedW-5 {
        width: 5%;
    }
</style>
`;

export const table = `
<p>%SUBJECT_VAL%</p>
<table class="qTable fullW">
    <thead class="qTHead">
        <tr>
            <th class="qTh">%QID%</th>
            <th class="qTh">%Question%</th>
            %TH%
        </tr>
    </thead>
    <tbody>
        %TR%
    </tbody>
</table>
`;

export const th = `
    <th class="qTh">%TH_VAL%</th>
`;

export const tr = `
<tr>
    <td class="qTd fixedW-5">%QID_VAL%</td>
    <td class="qTd fixedW-50">%QUESTION_VAL%</td>
   %TD%
</tr>
`;

export const td = `
<td class="qTd">
    <div class="greyBox">
        <span>%X_VAL%</span>
    </div>
</td>
`;

export const scores = `
<h4 class="no-margin">Scores :</h4>
<div class="scores-container">
    %SCORES_TABLE%
</div>
`;

export const scoresTable = `
<table class="qTable scores">
    <h4 class="pl-10">%CAT_NAME%</h4>
        <thead class="qTHead">
            <tr>
                <th class="qTh">%SCORE_NAME_TITLE%</th>
                <th class="qTh">%SCORE_VALUE_TITLE%</th>
            </tr>
        </thead>
        <tbody>
            %TR_SCORES%
        </tbody>
</table>
`;

export const trScores = `
<tr>
    <td class="qTd">%SCORE_NAME_VAL%</td>
    <td class="qTd">%SCORE_VAL%</td>
</tr>
`;
