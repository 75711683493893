import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { GetAlertsPipe } from "./get-alerts.pipe";
import { GetComponentPipe } from "./get-component.pipe";
import { GetOutdatedAlertsPipe } from "./get-outdated-alerts.pipe";
import { IsBluetoothObsCompPipe } from "./is-bluetooth-obs-comp.pipe";
import { TranslateComponentPipe } from "./translate-component.pipe";
import { TranslateObservationNamePipe } from "./translate-observation-name.pipe";

@NgModule({
  declarations: [
    IsBluetoothObsCompPipe,
    TranslateObservationNamePipe,
    TranslateComponentPipe,
    GetComponentPipe,
    GetAlertsPipe,
    GetOutdatedAlertsPipe,
  ],
  exports: [
    IsBluetoothObsCompPipe,
    TranslateObservationNamePipe,
    TranslateComponentPipe,
    GetComponentPipe,
    GetAlertsPipe,
    GetOutdatedAlertsPipe,
  ],
  imports: [CommonModule],
})
export class PatientObservationsPipesModule {}
