<div id="StatSummaryPage" class="statSummaryPage">
  <form [formGroup]="fromToForm">
    <div class="datePickerContainer ml-5">
      <mat-form-field class="datePickerField ml-5">
        <input
          cdkFocusInitial
          matInput
          [max]="maxFromDate"
          [matDatepicker]="fromDateTable"
          formControlName="fromDate"
          placeholder="{{ 'itemFilter.fromDate' | translate }}"
        />
        <mat-datepicker-toggle matSuffix [for]="fromDateTable"></mat-datepicker-toggle>
        <mat-datepicker
          #fromDateTable
          panelClass="month-picker"
          startView="multi-year"
          (monthSelected)="setMonthAndYear($event, fromDateTable, 'fromDate')"
        ></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="datePickerField ml-5">
        <input
          matInput
          [min]="minToDate"
          [max]="today"
          [matDatepicker]="toDateTable"
          formControlName="toDate"
          placeholder="{{ 'itemFilter.toDate' | translate }}"
        />
        <mat-datepicker-toggle matSuffix [for]="toDateTable"></mat-datepicker-toggle>
        <mat-datepicker
          #toDateTable
          panelClass="month-picker"
          startView="multi-year"
          (monthSelected)="setMonthAndYear($event, toDateTable, 'toDate')"
        ></mat-datepicker>
      </mat-form-field>
    </div>
  </form>

  <div class="statSummary">
    <table mat-table [dataSource]="statSummaryDataSource" class="full-width-table">
      <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

      <!-- Stat name Column -->
      <ng-container matColumnDef="statName" sticky>
        <th mat-header-cell *matHeaderCellDef>{{ "page.statSummary.statName" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ "page.statSummary." + element.statName | translate }}</td>
      </ng-container>

      <!-- Stat numbers per month Column -->
      <ng-container *ngFor="let month of displayedColumns.slice(1); let i = index" matColumnDef="{{ month }}">
        <th mat-header-cell *matHeaderCellDef>{{ month }}</th>
        <td mat-cell *matCellDef="let element">{{ element.statPerMonth[i] > 0 ? element.statPerMonth[i] : "/" }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div *ngIf="!statSummaryDataSource || statSummaryDataSource.length === 0" style="text-align: center">
    {{ "common.noData" | translate }}
  </div>
</div>
