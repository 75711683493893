import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatRadioChange } from "@angular/material/radio";

export interface Item {
  value: string;
  display: string;
  checked: boolean;
}
@Component({
  selector: "app-item-selector",
  templateUrl: "./item-selector.component.html",
  styleUrls: ["./item-selector.component.scss"],
})
export class ItemSelectorComponent implements OnChanges {
  public listDisplayed = false;

  @Input() items: Item[] = [];
  @Input() position: "left" | "right" = "right";
  @Input() logo: "settings" | "perm_data_setting" = "settings";
  @Input() translate = false;
  @Input() useRadioButton;
  public selectRadioBtn;
  @Output() changed: EventEmitter<Item[]> = new EventEmitter();

  ngOnChanges(): void {
    if (this.useRadioButton) {
      this.selectRadioBtn = this.items.find((el) => el.checked === true);
    }
  }

  public toggleList(): void {
    this.listDisplayed = !this.listDisplayed;
  }

  public itemChange(change: MatCheckboxChange | MatRadioChange, item: Item): void {
    if (this.useRadioButton) {
      // If radio button, uncheck all other items
      this.items.forEach((i) => (i.checked = false));
      item.checked = true;
    } else {
      item.checked = change instanceof MatCheckboxChange ? change.checked : false;
    }
    this.changed.emit(this.items);
  }
}
