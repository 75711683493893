import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-confirm-mail-dialog",
  templateUrl: "./confirm-mail-dialog.component.html",
  styleUrls: ["./confirm-mail-dialog.component.scss"],
})
export class ConfirmMailDialogComponent {
  public patientMailForm = this.fb.group({
    mail: ["", [Validators.required, Validators.email]],
  });

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ConfirmMailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { mail: string }
  ) {
    this.patientMailForm.get("mail").setValue(this.data.mail);
  }

  public onSaveAndClose() {
    if (this.patientMailForm.valid) {
      this.data.mail = this.patientMailForm.get("mail").value;
      this.dialogRef.close(this.data);
    }
  }
}
