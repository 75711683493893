import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { concatMap, first, map } from "rxjs/operators";
import { FileLogger } from "../helpers/fileLogger";
import { IRuleAlert } from "../models/rule-alert.interface";
import { RuleAlert } from "../models/rule-alert.model";
import { IRule } from "../models/rule.interface";
import { RuleAlertApiService } from "./api/rule-alert-api.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class RuleAlertService {
  constructor(private ruleAlertApiService: RuleAlertApiService, private userService: UserService) {}

  public canUpdateSync(): boolean {
    return this.userService.isAuthorizedSync(null, this.ruleAlertApiService.updateRoutes[0], "PUT");
  }

  public list(alertLevel = 1, services?: string[], monitoringIds?: string[], onlyNoDataTransmissionAlert = false): Observable<RuleAlert[]> {
    const routeName = this.ruleAlertApiService.readRoutes[0];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("RuleAlertService", "User does not have access to: GET " + routeName);
          return of([]) as Observable<RuleAlert[]>;
        }
        return this.ruleAlertApiService.list(alertLevel, services, monitoringIds, onlyNoDataTransmissionAlert).pipe(
          map((values) => {
            return values.map((v) => new RuleAlert(v));
          })
        );
      })
    );
  }

  public save(ruleAlert: RuleAlert): Observable<IRuleAlert[]> {
    return this.ruleAlertApiService.save(ruleAlert);
  }

  public listForPatient(patientId: string, alertsLevels?: string[]): Observable<RuleAlert[]> {
    const routeName = this.ruleAlertApiService.readRoutes[0];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("RuleAlertService", "User does not have access to: GET " + routeName);
          return of([]) as Observable<RuleAlert[]>;
        }
        return this.ruleAlertApiService.listForPatient(patientId, alertsLevels).pipe(
          map((values) => {
            return values.map((v) => new RuleAlert(v));
          })
        );
      })
    );
  }

  public listNoDataTransmission(caremateId: string): Observable<RuleAlert> {
    const routeName = this.ruleAlertApiService.readRoutes[1];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      first(),
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("RuleAlertService", "User does not have access to: GET " + routeName);
          return of(null) as Observable<RuleAlert>;
        }
        return this.ruleAlertApiService.listNoDataTransmission(caremateId).pipe(
          map((v) => {
            return new RuleAlert(v);
          })
        );
      })
    );
  }

  /**
   * Creates a new rule
   * @param rule The new rule to save as an IRule
   * @returns The saved rule
   */
  public createRule(rule: IRule): Observable<IRule> {
    return this.ruleAlertApiService.createRule(rule);
  }
}
