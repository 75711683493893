<div class="close-cross">
  <button
    mat-icon-button
    class="negative-margin mr-5 warn"
    matTooltip="{{ 'page.organizations.btn.updServ' | translate }}"
    (click)="updateService()"
    mat-button
  >
    <mat-icon>create</mat-icon>
  </button>
  <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-card class="no-padding">
  <app-service-details-component [service]="service" [organization]="organization"></app-service-details-component>
</mat-card>
<mat-card class="mt-15">
  <app-user-list-page [service]="service" [isDialog]="true" [organization]="organization"></app-user-list-page>
</mat-card>
