<div class="teleConsultationContent" [ngClass]="{ fullHeight: isSmallScreen }">
  <div class="buttons-bar">
    <button
      mat-icon-button
      *ngIf="isPage1 && !isPage2"
      class="negative-margin align-middle color-button"
      matTooltipClass="helpTooltip"
      matTooltipPosition="left"
      #tooltip="matTooltip"
      matTooltip="{{ 'globalHelp.addVideoCallHelpP1' | translate }}"
      aria-label="icon button with a ? icon"
      (click)="tooltip.toggle()"
    >
      <mat-icon>help_outline</mat-icon>
    </button>
    <button
      mat-icon-button
      *ngIf="isPage2 && !isPage1"
      class="negative-margin align-middle"
      matTooltipClass="helpTooltip"
      matTooltipPosition="left"
      #tooltip="matTooltip"
      matTooltip="{{ 'globalHelp.addVideoCallHelpP2' | translate }}"
      aria-label="icon button with a ? icon"
      (click)="tooltip.toggle()"
    >
      <mat-icon>help_outline</mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      class="negative-margin align-middle"
      (click)="onSave()"
      matTooltip="{{ 'common.save' | translate }}"
      [disabled]="!isDataValid || isLoading"
    >
      <mat-icon>save</mat-icon>
    </button>
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close matTooltip="{{ 'btn.close' | translate }}">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content [ngStyle]="{ hide: isLoading }">
    <mat-horizontal-stepper [linear]="true" #stepper>
      <mat-step [stepControl]="patientForm" label="{{ 'forms.appointment.patientData' | translate }}" *ngIf="!fromPatientWidget">
        <mat-card class="inside-card" *ngIf="isCreation">
          <app-server-side-search (patientSelected)="patientIsSelected($event)"></app-server-side-search>
        </mat-card>
        <mat-card class="inside-card">
          <h2 *ngIf="isCreation">{{ "forms.addPatient" | translate }}</h2>
          <h2 *ngIf="!isCreation">{{ "btn.update" | translate }}</h2>
          <form [formGroup]="patientForm">
            <div class="flex-container">
              <div class="row">
                <mat-form-field class="font-12">
                  <input type="text" matInput placeholder="{{ 'forms.name' | translate }}" formControlName="name" pattern="[A-zÀ-ú- ']*" />
                  <mat-error>
                    {{ "forms.errors.nameFormat" | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="font-12">
                  <input
                    type="text"
                    matInput
                    placeholder="{{ 'forms.firstname' | translate }}"
                    formControlName="firstname"
                    pattern="[A-zÀ-ú- ']*"
                  />
                  <mat-error>
                    {{ "forms.errors.nameFormat" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="font-12">
                  <input type="phone" matInput placeholder="{{ 'forms.phone' | translate }}" formControlName="phone" />
                  <mat-error *ngIf="patientForm.get('phone').hasError('phoneValidator')">
                    {{ "forms.checkPhoneFormat" | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="font-12">
                  <input type="mail" matInput placeholder="{{ 'forms.mail' | translate }}" formControlName="mail" />
                  <mat-error>{{ "page.login.email-required" | translate }}</mat-error>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="font-12">
                  <mat-label>{{ "forms.internalId" | translate }}</mat-label>
                  <input type="text" matInput placeholder="{{ 'forms.internalId' | translate }}" formControlName="internalId" />
                </mat-form-field>
                <mat-form-field class="font-12">
                  <mat-label>{{ "forms.userLang" | translate }}</mat-label>
                  <mat-select [compareWith]="compareLang" formControlName="userLang">
                    <mat-option *ngFor="let lang of availableLangs" [value]="lang.code">
                      {{ lang.display }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </form>
        </mat-card>
        <div class="action-container">
          <span></span>
          <button mat-button matStepperNext>
            {{ "btn.next" | translate }}
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="appointmentForm" label="{{ 'forms.appointment.appointmentData' | translate }}">
        <form [formGroup]="appointmentForm">
          <mat-card>
            <div class="flex-container">
              <div class="row">
                <!-- Start date field -->
                <mat-form-field class="calendarInGrid font-12">
                  <mat-label>{{ "forms.appointment.start" | translate }}</mat-label>
                  <input matInput [ngxMatDatetimePicker]="scheduledStringPicker" formControlName="start" [min]="minDate" />
                  <mat-datepicker-toggle matSuffix [for]="scheduledStringPicker"> </mat-datepicker-toggle>
                  <ngx-mat-datetime-picker
                    #scheduledStringPicker
                    [showSpinners]="true"
                    [showSeconds]="false"
                    [stepHour]="1"
                    [stepMinute]="5"
                    color="primary"
                  >
                  </ngx-mat-datetime-picker>
                </mat-form-field>
                <!-- Duration field -->
                <mat-form-field class="font-12">
                  <mat-label>{{ "forms.appointment.duration" | translate }} ({{ "forms.appointment.durationUnit" | translate }})</mat-label>
                  <input matInput type="number" placeholder="{{ 'forms.appointment.duration' | translate }}" formControlName="duration" />
                </mat-form-field>
              </div>
              <div class="row">
                <!-- Participant field -->
                <!-- Since actor.display was previously not completed when setting the healthcareService as an "actor", we use the actor.reference as a backup -->
                <!-- We do not display a customized message in cases where both the service differs (!isSameService) and the practitioner has no access to the service in which the teleconsultation was initiated. 
                  This scenario is unlikely, as the appServer exclusively returns teleconsultations within the practitioner's accessible services. Please update this comment if this were to change. -->
                <mat-form-field
                  class="font-12"
                  matTooltip="{{
                    'teleconsultations.notSameServiceError'
                      | translate
                        : {
                            service: appointmentHealthCareService?.actor?.display
                              ? appointmentHealthCareService?.actor?.display
                              : appointmentHealthCareService?.actor?.reference
                          }
                  }}"
                  [matTooltipDisabled]="isSameService"
                >
                  <mat-label>{{ "forms.appointment.participant" | translate }}</mat-label>
                  <mat-select formControlName="participant" [compareWith]="compareReference">
                    <mat-option *ngFor="let ref of practitionerRefs" [value]="ref"> {{ ref.display }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- BillingCode field -->
                <mat-form-field class="font-12">
                  <mat-label>{{ "forms.appointment.billingCode" | translate }}</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="{{ 'forms.appointment.billingCode' | translate }}"
                    formControlName="billingCode"
                  />
                </mat-form-field>
              </div>
              <div class="row">
                <!-- Object field -->
                <mat-form-field class="font-12 full-w">
                  <mat-label>{{ "forms.appointment.object" | translate }}</mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="object"
                    [value]="appointmentForm.get('object').value"
                    placeholder="{{ 'forms.appointment.object' | translate }}"
                  />
                </mat-form-field>
              </div>
            </div>
          </mat-card>

          <div class="action-container">
            <button mat-button matStepperPrevious *ngIf="isUnknowPatient">
              {{ "btn.back" | translate }}
            </button>
            <span *ngIf="!isUnknowPatient"></span>
            <button mat-button (click)="onSave()" [disabled]="!isDataValid || isLoading">
              {{ "common.save" | translate }}
            </button>
          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-dialog-content>
  <div class="spinner-container" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>
</div>
