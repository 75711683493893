import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FiltersDisplayComponent } from "./filters-display.component";
import { I18nModule } from "src/app/i18n.module";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [FiltersDisplayComponent],
  imports: [I18nModule, CommonModule, MatButtonModule, MatIconModule],
  exports: [FiltersDisplayComponent],
})
export class FiltersDisplayModule {}
