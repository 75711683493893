<button mat-button (click)="closeModal()" color="warn" class="close-confirm-modal">
  <mat-icon>close</mat-icon>
</button>

<h3 class="title-error">
  {{ "model.patient.caremateIdNotFoundTitle" | translate }}
</h3>

<div class="error-message">
  <p *ngIf="!data?.preference">
    {{ "model.patient.caremateIdNotFoundMessage" | translate }}
    <span class="username">
      {{ data.currentUser.firstname }}
      {{ data.currentUser.name }} </span
    >.
  </p>
  <p *ngIf="data?.preference">
    {{ "model.patient.OwnCaremateIdNotFoundMessage" | translate }}
  </p>
</div>
