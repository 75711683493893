<section class="properties-group">
  <mat-radio-group [(ngModel)]="needUnits" (change)="onNeedUnitChange()" [disabled]="disabled">
    <mat-radio-button [value]="UnitsOptions.NOTHING">
      {{ "page.questionnaireEditor.properties.nothing" | translate }}
    </mat-radio-button>
    <mat-radio-button [value]="UnitsOptions.NEED_UNITS" style="margin-right: 10px">
      {{ "page.questionnaireEditor.properties.units" | translate }}
    </mat-radio-button>
    <mat-radio-button [value]="UnitsOptions.MULTI_INPUTS">
      {{ "page.questionnaireEditor.properties.multiInputs" | translate }}
    </mat-radio-button>
  </mat-radio-group>
  <div *ngIf="needUnits && currentIndexContained > -1">
    <mat-form-field *ngIf="unitsTemplates && unitsTemplates.length">
      <mat-label>{{ "page.questionnaireEditor.Templates" | translate }}: </mat-label>
      <mat-select name="unitsTemplates" (selectionChange)="setTemplateUnits($event)" [disabled]="disabled">
        <mat-option *ngFor="let t of unitsTemplates" [value]="t">
          {{ t.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div
      class="choiceContainer"
      *ngFor="let answer of questionsOptions[currentIndexContained].compose.include[0].concept; let index = index"
    >
      <mat-form-field appearance="outline" class="choiceInput">
        <input
          matInput
          type="text"
          (change)="onUnitChoiceChanged(index)"
          [(ngModel)]="questionsOptions[currentIndexContained].compose.include[0].concept[index].display"
          [disabled]="disabled"
        />
      </mat-form-field>
      <button mat-icon-button color="warn" aria-label="Delete unit option" (click)="deleteUnitOption(index)" *ngIf="!disabled">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
    <div class="sideBySideFields">
      <button mat-stroked-button (click)="addUnitOption()" *ngIf="!disabled">
        <mat-icon aria-hidden="false" aria-label="Add icon">add</mat-icon>
        {{ "page.questionnaireEditor.properties.add" | translate }}
      </button>
    </div>
  </div>
</section>
