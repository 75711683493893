import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  ILinkedRuleAlert,
  IObservation,
  IObservationDefinition,
  ObservationDefinitionComponent,
} from "src/app/models/observations.interface";
import { GetOutdatedAlertsPipe } from "../patient-observations-pipes/get-outdated-alerts.pipe";

@Component({
  selector: "app-observation-alerts-history-modal",
  templateUrl: "./observation-alerts-history-modal.component.html",
  styleUrls: ["./observation-alerts-history-modal.component.scss"],
})
export class ObservationAlertsHistoryModalComponent implements OnInit {
  public alerts: ILinkedRuleAlert[];
  private getOutdatedAlertsPipe = new GetOutdatedAlertsPipe();
  public componentsDefinitions: ObservationDefinitionComponent[];
  public currentObsDef: IObservationDefinition;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      observation: IObservation;
      obsDefinitions: IObservationDefinition[];
    }
  ) {}

  ngOnInit(): void {
    this.alerts = this.getOutdatedAlertsPipe.transform(this.data.observation);
    this.currentObsDef = this.data.obsDefinitions.find((od) => od.loinc === this.data.observation.code.coding[0].code);
  }
}
