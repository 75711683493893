import { TranslateService } from "@ngx-translate/core";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { IPractitionerAccess } from "src/app/models/accessGroup.interface";

export class PractitionerAccessListDataSource extends MatTableDataSourceExtended<IPractitionerAccess> {
  sortingDataAccessor = (data: IPractitionerAccess, sortHeaderId: string) => {
    switch (sortHeaderId) {
      case "theme":
        return data?.theme;
      case "method":
        return data?.method ? this.translateService.instant("groupPermission." + data.method) : "";
      case "routeName":
        return data?.routeName;
      default:
        return data?.access.toString() ? this.translateService.instant("groupPermission." + data?.access.toString()) : "";
    }
  };

  constructor(private translateService: TranslateService) {
    super();
  }

  public loadData(accesses: IPractitionerAccess[]) {
    this.data = accesses;
  }
}
