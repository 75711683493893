<div class="alertDetailsEdit">
  <mat-card>
    <h3 *ngIf="createAction">{{ "alerts.createAction" | translate }}</h3>
    <h3 *ngIf="!createAction">{{ "alerts.setSeen" | translate }}</h3>
    <mat-card-content>
      <form [formGroup]="actionForm" style="width: 100%">
        <mat-form-field *ngIf="createAction">
          <mat-select placeholder="{{ 'forms.changeResponsable' | translate }}" formControlName="responsable">
            <mat-option *ngFor="let item of availableCollegues" [value]="item">
              {{ item.display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span *ngIf="createAction" class="spacer"></span>
        <mat-form-field *ngIf="createAction">
          <mat-select placeholder="{{ 'forms.changeStatus' | translate }}" formControlName="status">
            <mat-option *ngFor="let item of availableStatus" [value]="+item.code">
              {{ item.display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span *ngIf="createAction" class="spacer"></span>
        <mat-form-field style="width: 300px">
          <textarea cdkTextareaAutosize matInput placeholder="{{ 'forms.addComment' | translate }}" formControlName="comment"></textarea>
        </mat-form-field>
        <span class="spacer"></span>
        <button
          mat-raised-button
          color="primary"
          class="mr-5"
          (click)="onSave(false)"
          *ngIf="('rulesAlert' | isAuthorized : 'PUT' | async) === true"
        >
          {{ "common.save" | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="onSave(true)"
          *ngIf="createAction && ('rulesAlert' | isAuthorized : 'PUT' | async) === true"
        >
          >
          {{ "common.saveAndQuit" | translate }}
        </button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
