import { IAccessGroupPermission } from "./accessGroup.interface";
import { IQuantity } from "./careplans.interface";
import { Reference } from "./reference.interface";

export enum CONNECTION_OPTION {
  PASSWORD_LOGIN = 1,
  EHEALTH = 2,
  PSC = 3,
}

export enum UserRole {
  PATIENT = 1,
  PRACTITIONER = 2,
  ADMIN = 3,
  RELATEDPERSON = 4,
  SUBSTITUTE = 5,
  ORAGNIZATION_ADMIN = 6,
}

export enum UserKind {
  NORMAL = 0, // simple user
  TEST = 1, // this is a user for testing
}

export enum UserStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  NEW = 2,
  PASSIVE = 3, // this status don't exist on backend, computed in front-end only
}

export interface IEthnicGroup {
  snomedCode: number;
  i18nKey: string;
}
export interface IConsent {
  identifier: string; // identifier of the knowledge
  publicationDate: string; // date of publication of the knowledge
  consentDate: string; // date of patient approval of consent
  pdf?: string; // for the TPDA, path to the pdf containing the proof of consent
  electronicSignature?: any; // if needed for the tpda
}

export interface Account {
  name: string;
  firstname: string;
  status: UserStatus;
  nationalnumber?: string;
  caremateIdentifier: string;
  newPassword?: string;
  mail: string;
  phone: string;
  birthdate: string;
  photo: string;
  role: UserRole[];
  managingOrganizations?: Reference[];
  authOptionsDeactivated?: CONNECTION_OPTION[];
  mfaActive?: boolean;
  kind: UserKind;
  insurance: any;
  accessGroups?: string[];
  permissions?: IAccessGroupPermission[];
  healthcareService?: Reference[];
  vitalProfile: IQuantity[]; // vitals and phisiologicals parameters for a patient (weight, heartrate, ...)
  displayName?: string;
  anonymous?: boolean;
  creation?: string;
  consent?: IConsent[];
  needConsent: boolean; // tells the mobile application if (new) consent is required
  referenceCountry?: string;
  birthplace?: Reference;
  ethnicGroup?: number;
}

export interface UserInfo {
  authcodeUncrypt: string;
  nationalnumber: string;
  role: UserRole;
  needMail: boolean;
  customLogin?: string;
  accessGroups?: string[];
}

export interface SlimUserInfo {
  nationalnumber: string;
  role: UserRole[];
  accessGroups?: string[];
}
