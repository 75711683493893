import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { IlinkableObsItem, IlinkableQuestionnaireItem } from "src/app/careplan-editor/domain/IlinkableItem.interface";
import { CareplanEditorService } from "src/app/careplan-editor/domain/careplan-editor.service";
import { CareplanTemplateFormHelper } from "src/app/careplan-editor/domain/careplan-template-form-helper";
import { CareplanHelper } from "src/app/helpers/careplan-helper";
import { ACTION_TARGET, ICareplan } from "src/app/models/careplans.interface";

@Component({
  selector: "app-careplan-editor-properties",
  templateUrl: "./careplan-editor-properties.component.html",
  styleUrls: ["./careplan-editor-properties.component.scss"],
})
export class CareplanEditorPropertiesComponent implements OnInit {
  @Input() item: IlinkableQuestionnaireItem | IlinkableObsItem;
  @Input() careplan: ICareplan;
  /** Output event emitter for when the properties are saved. */
  @Output() saved = new EventEmitter<boolean>();

  public period: number;
  public periodUnit: string;
  public showDirectly;
  public currentActionResultingFormControl: UntypedFormControl;
  public action_target = ACTION_TARGET;

  /** Array of action resulting objects : either from current careplan or current activity depending on the item origin */
  public actionResultingFormArray: UntypedFormArray;
  public currentActivityFG: UntypedFormGroup;
  public currentActionResultingIndex: number;

  constructor(
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private careplanEditorService: CareplanEditorService,
    private careplanTemplateFormHelper: CareplanTemplateFormHelper,
    private fb: UntypedFormBuilder
  ) {}

  /**
   * If the item's origin is 'activity', it retrieves the action resulting array from the corresponding activity of the care plan.
   * If the item's origin is not 'activity', it retrieves the action resulting array directly from the care plan.
   * After determining the action resulting array, it calls the `init` method to set up the initial state of the component.
   */
  ngOnInit(): void {
    if (this.item.origin === "activity") {
      this.currentActivityFG = this.careplanTemplateFormHelper.getActivityFormGroupByReference(
        this.careplanEditorService.careplanTemplateForm,
        this.item.originReference.reference
      );
      this.actionResultingFormArray = this.currentActivityFG.get("actionResulting") as UntypedFormArray;
    } else {
      this.actionResultingFormArray = this.careplanEditorService.careplanTemplateForm.get("actionResulting") as UntypedFormArray;
    }
    this.init();
  }

  /**
   * Sets up the initial state of the component based on the existing action resulting objects if any.
   * Otherwise, it sets default values for the when-input
   */
  public init(): void {
    this.currentActionResultingIndex = this.actionResultingFormArray.value.findIndex(
      (actionResulting) => actionResulting.detail?.reference?.reference === this.item.uniqueId
    );

    if (this.currentActionResultingIndex !== -1) {
      this.currentActionResultingFormControl = this.actionResultingFormArray.at(this.currentActionResultingIndex) as UntypedFormControl;
    }

    // If an action resulting object is found, update component properties with its values
    if (this.currentActionResultingFormControl) {
      this.period = this.currentActionResultingFormControl.value.when.period;
      this.periodUnit = this.currentActionResultingFormControl.value.when.periodUnits;
      this.showDirectly = false;
    } else {
      // Else, we setup default values
      this.period = 0;
      this.periodUnit = "d";
      this.showDirectly = true;
    }
  }

  /**
   * Saves the careplan.
   * This method updates or creates an action resulting object for the care plan based on user input.
   * If the 'showDirectly' flag is true, it removes the existing action resulting object.
   * Otherwise, it updates the existing action resulting object or creates a new one.
   * After saving, it updates the care plan template with questionnaires or observation, displays a success message,
   * and emits a 'saved' event to close the panel
   */
  public save(): void {
    // Find the index of the action resulting object related to the current item
    if (this.showDirectly) {
      // If 'showDirectly' is true, remove the existing action resulting object
      if (this.currentActionResultingIndex !== -1) {
        this.actionResultingFormArray.removeAt(this.currentActionResultingIndex);
      }
    } else {
      // If the action resulting object already exists, update its period and period unit
      if (this.currentActionResultingIndex !== -1) {
        const ARValue = this.actionResultingFormArray.at(this.currentActionResultingIndex).value;
        ARValue.when.period = this.period;
        ARValue.when.periodUnits = this.periodUnit;
        (this.actionResultingFormArray.at(this.currentActionResultingIndex) as UntypedFormControl).setValue(ARValue);
      } else {
        // If the action resulting object does not exist, create a new one and add it to the array
        this.actionResultingFormArray.insert(
          this.actionResultingFormArray.length,
          this.fb.control(
            CareplanHelper.newActionResulting(
              this.careplan.support[0].reference,
              this.item.itemType === ACTION_TARGET.QUESTIONNAIRE ? this.item.subjectType : this.item.reference,
              this.item.itemType === ACTION_TARGET.QUESTIONNAIRE ? this.item.uniqueId : this.item.reference,
              this.item.itemType,
              undefined,
              {
                period: this.period,
                periodUnit: this.periodUnit,
              }
            )
          )
        );
      }
    }
    // Display a success message
    this.snackBar.open(this.translateService.instant("common.saveSuccess"), undefined, {
      duration: 3000,
    });
    // Emit a 'saved' event to close the panel
    this.saved.emit(true);
  }

  /**
   * Close panel without saving
   */
  public cancel(): void {
    this.saved.emit(true);
  }
}
