import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Appointment } from "src/app/models/appointment.model";

@Component({
  selector: "app-appointment-delay-dialog",
  templateUrl: "./appointment-delay-dialog.component.html",
  styleUrls: ["./appointment-delay-dialog.component.scss"],
})
export class AppointmentDelayDialogComponent {
  public appointment: Appointment;
  public minutesDelay = 15;

  constructor(
    public dialogRef: MatDialogRef<AppointmentDelayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { appointment: Appointment }
  ) {
    this.appointment = this.data.appointment;
  }

  public emitMinutesChange(): void {
    this.dialogRef.close(this.minutesDelay);
  }
}
