import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Subject, from, fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, first, switchMap, takeUntil, tap } from "rxjs/operators";
import { CareplanEditorService } from "src/app/careplan-editor/domain/careplan-editor.service";
import { Choice } from "src/app/components/item-filter/filters/choice-filter/choice-filter.component";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { DataType, Filter } from "src/app/models/filter.interface";
import { Link2Careplan } from "src/app/models/link2careplan.model";
import { PreferenceContext, TableParameter } from "src/app/models/preference.interface";
import { Reference } from "src/app/models/sharedModels.model";
import { CareplansApiService } from "src/app/providers/api/careplans-api.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { CareplansDataSource } from "../../../../domain/careplan-editor-page/careplans.datasource";

@Component({
  selector: "app-careplans-list-page",
  templateUrl: "./careplans-list-page.component.html",
  styleUrls: ["./careplans-list-page.component.scss"],
})
export class CareplansListPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("searchInput") searchInput: ElementRef;
  @ViewChild("searchClearBtn", { read: ElementRef }) searchClearBtn: ElementRef;

  private onDestroy$ = new Subject<void>();
  public DATA_TYPE = DataType;
  public dataSource: CareplansDataSource;
  public displayedColumns = ["description", "support", "services", "category"];
  public careplansCount: number;
  public filters: Filter[] = [];
  public loading: boolean;
  public canCreateCareplan: boolean;
  public isFiltered = false;
  public filtersPropertyNames = { description: "description", support: "support", services: "services", category: "category" };
  private preferences: TableParameter;
  public currentLang: string;

  constructor(
    private route: ActivatedRoute,
    private careplansApiService: CareplansApiService,
    private userService: UserService,
    private sessionService: SessionService,
    private healthcareService: HealthcareserviceService,
    private preferenceService: PreferenceService,
    private careplanEditorService: CareplanEditorService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.preferences = this.route.snapshot.data.preferences;
  }

  ngOnInit(): void {
    this.dataSource = new CareplansDataSource(this.careplansApiService);
    this.canCreateCareplan = this.userService.isAuthorizedSync(null, "dashboard/careplanTemplate", "POST");
    this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentLang = this.translateService.currentLang;
    });
  }

  ngAfterViewInit(): void {
    this.applyPreferences().then(() => {
      const searchEvent$ = fromEvent(this.searchInput.nativeElement, "keyup");
      const searchResetEvent$ = fromEvent(this.searchClearBtn.nativeElement, "click");

      // If we have a currentService, we do the first load. If not, the first load will be handle by the service watch (on page reload e.g.)
      if (this.sessionService.currentService) {
        from([1])
          .pipe(
            first(),
            tap(() => {
              this.paginator.pageIndex = 0;
              this.loadCareplans();
              this.updatePreference();
            })
          )
          .subscribe();
      }

      // server-side search
      merge(searchEvent$, searchResetEvent$)
        .pipe(
          takeUntil(this.onDestroy$),
          debounceTime(150),
          distinctUntilChanged(),
          tap(() => {
            this.paginator.pageIndex = 0;
            this.loadCareplans();
            this.updatePreference();
          })
        )
        .subscribe();

      // reset the paginator after sorting
      this.sort.sortChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => (this.paginator.pageIndex = 0));

      //  watch paginator and sort
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          tap(() => {
            this.loadCareplans();
            this.updatePreference();
          }),
          takeUntil(this.onDestroy$)
        )
        .subscribe();

      // watch Service change
      this.sessionService.refreshCurrentService
        .pipe(
          takeUntil(this.onDestroy$),
          tap(() => {
            this.paginator.pageIndex = 0;
            this.loadCareplans();
          })
        )
        .subscribe();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Preferences
   */
  public updatePreference(): void {
    this.preferenceService
      .update({
        context: PreferenceContext.CAREPLAN_LIST,
        parameters: {
          filters: this.dataSource.getAllFilters(), // we save the filters unformatted.
          itemsPerPage: this.paginator.pageSize,
          search: this.searchInput.nativeElement.value,
          sortId: this.sort.active,
          sortOrder: this.sort.direction,
        } as TableParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }
  public loadCareplans(): void {
    if (!this.userService.isAuthorizedSync(null, "dashboard/careplansInfos", "GET")) {
      FileLogger.error("CareplansListPage", "Not Authorized to load careplans");
      return;
    }

    const services = this.getServices().map((s) => s.reference);

    this.dataSource.loadData({
      sortId: this.sort.active,
      sortOrder: this.sort.direction,
      pageNumber: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      filters: this.getFiltersFormated(),
      search: this.searchInput.nativeElement.value,
      services,
      lang: this.translateService.currentLang,
    });

    // update knowledge count with search result
    this.careplansApiService
      .getCareplansCount(this.searchInput.nativeElement.value, this.getFiltersFormated(), services)
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.careplansCount = result?.count;
      });
  }

  /**
   * Retrieves the list of services based on the current service and available services.
   * If the current service is set to "All Services", it includes all available services.
   */
  public getServices(): Reference[] {
    let currentService = this.sessionService.currentService;
    let services = [currentService];

    if (currentService.reference === this.sessionService.allsOption) {
      services = [];
      const allServices = this.healthcareService.availableServices()?.map((s) => s.asReference);
      services.push(...allServices);
      currentService = null;
    }
    return services;
  }

  /**
   * Apply Preferences using a timeout to defer the change detection until the next cycle
   * to avoid ExpressionChangedAfterItHasBeenCheckedError
   */
  public applyPreferences(): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        // set the filters
        this.preferences?.filters.forEach((filter) => this.dataSource.setFilter(filter));
        if (this.dataSource.getAllFilters().length) {
          this.isFiltered = true;
        }
        // set the search string and short description option
        this.searchInput.nativeElement.value = this.preferences?.search ? this.preferences.search : "";
        // Paginator size
        if (this.preferences?.itemsPerPage) this.paginator.pageSize = this.preferences.itemsPerPage;
        // Sort preferences
        if (this.preferences?.sortId && this.preferences.sortOrder) {
          this.sort.sort({ id: this.preferences.sortId, start: this.preferences.sortOrder, disableClear: false });
        }
        resolve();
      });
    });
  }

  public applyFilter(filter: Filter, isInit = false): void {
    this.dataSource.setFilter(filter);
    if (!isInit) {
      this.paginator.pageIndex = 0;
    }
    this.loadCareplans();
    this.isFiltered = this.filters && this.filters.length > 0;

    this.updatePreference();
  }

  public addCareplan(): void {
    if (!this.canCreateCareplan) return;
    const newCareplanTemplate = this.careplanEditorService.createCareplanTemplateObj(this.getServices());

    this.careplanEditorService
      .createCareplanTemplate(newCareplanTemplate)
      .pipe(
        first(),
        switchMap((careplanTemplate) => {
          const newLink2Careplan = this.careplanEditorService.createLink2CareplanObj(careplanTemplate.support[0].reference);
          return this.careplanEditorService.createLink2Careplan(newLink2Careplan);
        }),
        tap((l2c: Link2Careplan) => {
          this.router.navigate(["/careplanEditor", l2c.careplan, "general", "visualView"]);
        })
      )
      .subscribe();
  }

  public clearFilter(): void {
    this.searchInput.nativeElement.value = "";
    this.dataSource.clearFilter();
    this.paginator.pageIndex = 0;
    this.filters = [];
    this.isFiltered = false;
    this.loadCareplans();
    this.updatePreference();
  }
  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  private getFiltersFormated(): Filter[] {
    const formatedFilters: Filter[] = [];
    const filters = Tools.clone(this.dataSource.getAllFilters());
    if (filters && filters.length > 0) {
      filters.forEach((filter) => {
        switch (filter.dataType) {
          case this.DATA_TYPE.DATE:
            // format date params to string
            filter.data.fromDate = moment(filter.data.fromDate).format("YYYY-MM-DD");
            filter.data.toDate = moment(filter.data.toDate).format("YYYY-MM-DD");
            break;
          case this.DATA_TYPE.CHOICE: {
            const values: Array<string> = [];
            const choices: Choice[] = filter.data ? filter.data : [];
            choices.forEach((choice) => {
              if (choice.checked) {
                values.push(choice.value);
              }
            });
            filter.data = {
              value: values,
            };
            break;
          }
        }
        formatedFilters.push(filter);
      });
    }
    this.filters = formatedFilters;
    return formatedFilters;
  }
}
