import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { FileLogger } from "../helpers/fileLogger";
import { AppError } from "../models/app-error.interface";
import { ICommunication, ISimplifiedCommunication } from "../models/communications.interface";
import { InfoCommunication } from "../models/communications.model";
import { Reference } from "../models/reference.interface";
import { CommunicationsApiService } from "./api/communications-api.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class CommunicationsService {
  constructor(private communicationsApiService: CommunicationsApiService, private userService: UserService) {}

  public list(serviceId: string, startDate?: string, endDate?: string, services?: string[]): Observable<InfoCommunication[]> {
    const routeName = this.communicationsApiService.readRoutes[0];
    const isAuth = this.userService.isAuthorizedSync(null, routeName, "GET");
    if (!isAuth) {
      FileLogger.warn("CommunicationsService", "User does not have access to: GET " + routeName);
      return of([]) as Observable<InfoCommunication[]>;
    }
    return this.communicationsApiService.listActive(serviceId, services, startDate, endDate);
  }

  public listForPatient(patientId: string, startDate?: string, endDate?: string): Observable<InfoCommunication[]> {
    const routeName = this.communicationsApiService.readRoutes[0];
    const isAuth = this.userService.isAuthorizedSync(null, routeName, "GET");
    if (!isAuth) {
      FileLogger.warn("CommunicationsService", "User does not have access to: GET " + routeName);
      return of([]) as Observable<InfoCommunication[]>;
    }
    return this.communicationsApiService.listActiveForPatient(patientId, startDate, endDate);
  }

  public listAllForGroup(groupId: string): Observable<InfoCommunication[]> {
    const routeName = this.communicationsApiService.readRoutes[0];
    const isAuth = this.userService.isAuthorizedSync(null, routeName, "GET");
    if (!isAuth) {
      FileLogger.warn("CommunicationsService", "User does not have access to: GET " + routeName);
      return of([]) as Observable<InfoCommunication[]>;
    }
    return this.communicationsApiService.listAllForGroup(groupId);
  }

  public listOne(communicationId: string, patientId: string): Observable<ICommunication> {
    return this.communicationsApiService.listOne(communicationId, patientId);
  }

  public create(communication: ISimplifiedCommunication): Observable<ICommunication> {
    return this.communicationsApiService.create(communication);
  }

  public update(communication: ICommunication, patientsIds: Reference[]): Observable<number> {
    return this.communicationsApiService.update(communication, patientsIds);
  }

  public delete(communicationId: string, patientId: string, patientsIds: Reference[]): Observable<boolean> {
    return this.communicationsApiService.delete(communicationId, patientId, patientsIds);
  }

  public async exportCommunications(patientId: string, lang: string): Promise<void> {
    this.communicationsApiService.exportCommunications(patientId, lang).subscribe(
      (blob) => {
        const url = URL.createObjectURL(blob);
        window.open(url);
      },
      (error: AppError) => {
        FileLogger.error("CommunicationsService", "exportCommunications", error);
        throw new Error(error.message);
      }
    );
  }
}
