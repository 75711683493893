import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { RiskAssessment } from "../models/riskAssessment.model";

@Pipe({ name: "RaHasPdf" })
export class RaHasPdfPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(risk: RiskAssessment, ..._args: unknown[]): boolean {
    const index = risk?.about?.findIndex((about) => about.type.includes(`/pdf_${this.translateService.currentLang}`));
    return index > 0 ? true : false;
  }
}
