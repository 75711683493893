import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IlinkableKnowledgeItem, IlinkableQuestionnaireItem } from "src/app/careplan-editor/domain/IlinkableItem.interface";
import { ACTION_TARGET } from "src/app/models/careplans.interface";
import { IKnowledgeInfo } from "src/app/models/knowledge.interface";
import { IQuestionnaireListInfo } from "src/app/models/questionnaire.interface";

@Pipe({
  name: "formatToLinkableList",
})
export class FormatToLinkableListPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(el: IKnowledgeInfo | IQuestionnaireListInfo, context: ACTION_TARGET): IlinkableKnowledgeItem | IlinkableQuestionnaireItem {
    switch (context) {
      case ACTION_TARGET.KNOWLEDGE: {
        const k: IKnowledgeInfo = el as IKnowledgeInfo;
        const value: IlinkableKnowledgeItem = {
          uniqueId: k.identifier,
          term: k.term,
          category: this.translateService.instant("knowledgebase.category." + k.category),
          type: this.translateService.instant("knowledgebase.doccategory." + k.type),
          itemType: context,
          identifier: k.identifier,
        };
        return value;
      }

      case ACTION_TARGET.QUESTIONNAIRE: {
        const q: IQuestionnaireListInfo = el as IQuestionnaireListInfo;
        const value: IlinkableQuestionnaireItem = {
          uniqueId: q.identifier[0].value,
          identifier: q.identifier[0].value,
          subjectType: q.subjectType,
          version: q.version,
          itemType: context,
          isDraft: q.hasDraft,
        };
        return value;
      }
    }
  }
}
