<mat-drawer-container #drawer class="mat-elevation-z1" *ngIf="careplanEditorService.careplanTemplateReady$ | async">
  <mat-drawer mode="side" opened position="end">
    <!-- SOURCE LIST (right column)-->
    <div class="drawer-title-container">
      <h2 class="drawer-title">{{ "page.careplanEditor.tabs.observations.titleAvailable" | translate }}</h2>
      <button
        mat-stroked-button
        (click)="addObservation()"
        [matTooltip]="'page.careplanEditor.tabs.observations.addObservation' | translate"
        *ngIf="('dashboard/observationDef' | isAuthorized : 'POST' | async) === true"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="p-1">
      <app-linkable-items-list
        *ngIf="availableObservations && columns"
        [careplanTemplate]="careplanTemplate"
        [id]="'drop-list-0'"
        [connectedTo]="dropListIds"
        [items]="availableObservations"
        [linkedToCareplanItems]="observations"
        [linkedToActivitiesItems]="activitiesObservations"
        [columns]="columns"
      ></app-linkable-items-list>
      <mat-spinner *ngIf="!availableObservations" style="margin: auto"></mat-spinner>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <!-- TARGET LIST-->
    <h3>{{ "page.careplanEditor.tabs.observations.titleLinked2Careplan" | translate }}</h3>

    <app-linkable-items-list
      *ngIf="observations && columns"
      [careplanTemplate]="careplanTemplate"
      [id]="'drop-list-1'"
      [connectedTo]="'drop-list-0'"
      [items]="observations"
      [linkedToCareplanItems]="observations"
      [linkedToActivitiesItems]="activitiesObservations"
      [columns]="columns"
      [actions]="actions"
      (itemsChange)="update($event)"
      [isTarget]="true"
    ></app-linkable-items-list>

    <mat-spinner *ngIf="!observations" style="margin: auto"></mat-spinner>
    <!-- Observations linked to activities (for procedures only, cf. tooltip)-->

    <h3 class="mt-1">{{ "page.careplanEditor.tabs.observations.titleLinked2Activities" | translate }}</h3>
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel *ngFor="let activityFG; let i = index; of: careplanEditorService.careplanTemplateForm?.get('activity').controls">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ activityFG | getActivityName : currentLanguage }} </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-spinner *ngIf="!activitiesObservations" style="margin: auto"></mat-spinner>

        <app-linkable-items-list
          *ngIf="activitiesObservations && columns"
          [careplanTemplate]="careplanTemplate"
          [id]="'drop-list-activity-' + i"
          [connectedTo]="'drop-list-1'"
          [items]="activitiesObservations | filterByActivity : activityFG.value.reference.reference"
          [linkedToCareplanItems]="observations"
          [linkedToActivitiesItems]="activitiesObservations"
          [columns]="columns"
          [actions]="actions"
          (itemsChange)="update($event)"
          [isTarget]="true"
          [activityReference]="activityFG.value.reference"
        >
        </app-linkable-items-list>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-drawer-content>
</mat-drawer-container>
