import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Quantity } from "src/app/models/quantity.model";
import { IVitalProfileDefinition } from "src/app/models/vitalProfileDefinition.interface";
import { SessionService } from "src/app/providers/session.service";

@Component({
  selector: "app-vital-sign-input",
  templateUrl: "./vital-sign-form-input.component.html",
  styleUrls: ["./vital-sign-form-input.component.scss"],
})
export class VitalSignFormInputComponent implements OnInit {
  @Input() definition: IVitalProfileDefinition;
  @Input() form: UntypedFormGroup;

  public get isValid(): boolean {
    const currentInput = this.form.controls[this.definition.code];
    const currentValue = this.form.controls[this.definition.code].value;

    if (this.definition.type === "array") {
      const isEmpty = Object.keys(currentValue);
      // check for null and undefined values for accept 0
      const isEmptyArray = Object.values(currentValue).every((v) => v === null || undefined);
      const isInvalidArray = Object.values(currentValue).some((input: number) => typeof input !== "number" || input < 0);

      if (!isEmpty.length) {
        return false;
      }

      if (isEmptyArray) {
        if (currentInput.dirty) {
          return true;
        }
      }

      if (isInvalidArray) {
        if (currentInput.dirty) {
          this.errorMessage = "vitalSigns.emptyArrayInput";
          return false;
        }
      }
    }

    return true;
  }

  public langue: string;
  public currentVitalSign: Quantity;
  public currentVitalSignValue: number;
  public errorMessage: string;

  constructor(private sessionService: SessionService) {}

  ngOnInit(): void {
    this.langue = this.sessionService.userLang;
  }
}
