<div class="drugGraphComponent">
  <ng-container *ngIf="hasData">
    <mat-button-toggle-group [(ngModel)]="drugsGraphRange" (ngModelChange)="setDrugsGraphRange(drugsGraphRange)">
      <mat-button-toggle value="days">
        {{ "periodUnit.day.plural" | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="weeks">
        {{ "periodUnit.week.plural" | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="months">
        {{ "periodUnit.month.plural" | translate }}
      </mat-button-toggle>
    </mat-button-toggle-group>
    <canvas
      baseChart
      #baseChart="base-chart"
      width="10"
      height="8"
      [datasets]="chartData"
      [labels]="chartLabels"
      [options]="chartOptions"
      [colors]="chartColors"
      chartType="bar"
    ></canvas>
  </ng-container>
  <p *ngIf="!hasData">{{ "widget.noData" | translate }}</p>
</div>
