<div class="group-server-side-search">
  <h2>{{ "group.group" | translate }}</h2>
  <p>
    <mat-form-field class="mat-field">
      <mat-select
        [formControl]="groupServerSideFilteringCtrl"
        (selectionChange)="emit($event)"
        placeholder="{{ 'group.name' | translate }}"
      >
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="placeholderLabel"
            [noEntriesFoundLabel]="noEntriesFoundLabel"
            class="searchForm"
            [formControl]="groupServerSideFilteringCtrl"
            [searching]="searching"
          >
          </ngx-mat-select-search>
        </mat-option>

        <mat-option class="inputForm" *ngFor="let group of filteredServerSideGroup | async" [value]="group">
          {{ group.groupName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </p>
</div>
