<button mat-button (click)="closeModal()" color="warn" class="close-confirm-modal">
  <mat-icon>close</mat-icon>
</button>

<div class="container-confirm-reinit">
  <h3 class="title-confirmation">
    {{ "model.patient.titleConfirmation" | translate }}
  </h3>

  <p *ngIf="!data?.preference">
    {{ "model.patient.confirmReset" | translate }}
    <b>
      {{ data.currentUser?.firstname }}
      {{ data.currentUser?.name }}
    </b>
    ?
  </p>
  <p *ngIf="data?.preference">{{ "model.patient.confirmOwnReset" | translate }} ?</p>

  <div class="choice-container">
    <button mat-raised-button (click)="displayTheRightModal()" color="primary" class="choice-button">
      {{ "model.patient.confirm" | translate }}
    </button>
    <button mat-raised-button (click)="closeModal()" color="warn" class="choice-button">
      {{ "model.patient.cancel" | translate }}
    </button>
  </div>
</div>
