import { CdkDrag } from "@angular/cdk/drag-drop";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { Subject, Subscription, forkJoin, timer } from "rxjs";
import { first, switchMap, takeUntil } from "rxjs/operators";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { Base64Helper } from "src/app/helpers/Base64Helper";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { HelpData } from "src/app/helpers/helpData";
import { DataType, Filter } from "src/app/models/filter.interface";
import { Patient } from "src/app/models/patient.interface";
import { PreferenceContext, WidgetFilterParameter } from "src/app/models/preference.interface";
import { IWidgetComponent, WidgetName } from "src/app/models/widget.interface";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PatientService } from "src/app/providers/patient.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { RuleAlertService } from "src/app/providers/rule-alert.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { AlertDetailsComponent } from "../../patient-alerts/alert-details/alert-details.component";
import { LastActivityListDataSource } from "./patient-last-activity-widget-list-datasource";

@Component({
  selector: "app-patient-last-activity-widget",
  templateUrl: "./patient-last-activity-widget.component.html",
  styleUrls: ["./patient-last-activity-widget.component.scss"],
})
export class PatientLastActivityWidgetComponent implements IWidgetComponent, OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<Patient>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(CdkDrag, { static: true }) cdkDrag: CdkDrag;

  public widgetName = WidgetName;
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public isDraggable = true;
  public displayedColumns: string[] = ["patient", "date", "last_data_date"];
  public dataSource: LastActivityListDataSource;
  public patients: Patient[] = [];
  public refreshSub: Subscription;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  private monitoringIds: string[] = [];
  private availableServicesRefs: string[] = [];
  private inactivePatientsSubscription: Subscription;
  public loading = true;

  constructor(
    private patientService: PatientService,
    private dialog: MatDialog,
    private helpData: HelpData,
    private preferenceService: PreferenceService,
    protected sessionService: SessionService,
    private userService: UserService,
    private healthcareService: HealthcareserviceService,
    private ruleAlertService: RuleAlertService
  ) {}

  ngOnInit(): void {
    this.userService
      .isAuthorized("dashboard/lastNoDataTransmissionRuleAlert", "GET")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        if (value === true) {
          // Add "action" if not already present
          if (!this.displayedColumns.includes("action")) {
            this.displayedColumns.push("action");
          }
        } else {
          // Remove "action" if present
          const indexOfAction = this.displayedColumns.indexOf("action");
          if (indexOfAction !== -1) {
            this.displayedColumns.splice(indexOfAction, 1);
          }
        }
      });

    this.dataSource = new LastActivityListDataSource(this.patientService);
    this.initData();
    this.refreshSub = this.sessionService.refreshLastActivityWidget.subscribe(() => {
      this.initData();
    });
  }

  ngOnDestroy(): void {
    this.refreshSub?.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initData() {
    if (
      !this.sessionService.currentService ||
      !this.healthcareService.availableServices() ||
      this.healthcareService.availableServices().length < 1
    ) {
      return;
    }
    this.availableServicesRefs = [this.sessionService.currentService?.reference];
    if (this.sessionService.currentService?.display === this.sessionService.allsOption) {
      this.availableServicesRefs = this.healthcareService.availableServices().map((s) => s.asReference.reference);
    }

    if (this.userService.isMonitoringUser) {
      const current = this.sessionService.currentMonitoringService;
      if (current?.reference === this.sessionService.allsOption) {
        this.monitoringIds = this.healthcareService.availableMonitoringServices().map((s) => s.asReference.reference);
      } else {
        this.monitoringIds = current ? [current.reference] : null;
      }
    }
    this.loadInactivePatients();
  }

  private loadInactivePatients(): void {
    this.loading = true;
    if (this.inactivePatientsSubscription) {
      this.inactivePatientsSubscription.unsubscribe();
    }

    const listInactivePatient$ = this.patientService
      .listInactivePatient(this.availableServicesRefs, this.monitoringIds.length > 0 ? this.monitoringIds : undefined)
      .pipe(takeUntil(this.onDestroy$));

    const timer$ = timer(1000); // Make sure that the spinner is shown for at least one second (best practice)

    this.inactivePatientsSubscription = forkJoin([listInactivePatient$, timer$]).subscribe(([patients]) => {
      this.patients = patients;
      this.loadPreferences();
      this.loadDataSource();
      this.loading = false;
    });
  }

  private loadDataSource() {
    this.dataSource.loadData(this.patients);
    this.initPaginatorAndSort();
  }

  private initPaginatorAndSort() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  /**
   * Preferences
   */
  private updatePreference() {
    this.preferenceService
      .update({
        context: PreferenceContext.WIDGET_LAST_ACTIVITY,
        parameters: {
          filters: this.dataSource.getAllFilters(),
        } as WidgetFilterParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  private loadPreferences() {
    this.preferenceService
      .list(PreferenceContext.WIDGET_LAST_ACTIVITY)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: WidgetFilterParameter) => {
        if (parameters) {
          // Apply saved filters
          parameters.filters.forEach((filter: Filter) => {
            this.applyFilter(filter);
          });
        }
      });
  }

  public getCaremateIdentifier(patient: Patient): string {
    return FHIRHelper.getMainIdentifier(patient).value;
  }

  public encodeToB64(str: string): string {
    return Base64Helper.utf8_to_b64(str);
  }

  public openInactifPatients(): void {
    this.dialog.open(GlobalHelpDialogComponent, {
      data: { slides: this.helpData.inactifPatients },
      disableClose: true,
    });
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
    this.updatePreference();
  }

  public clearFilter(): void {
    this.dataSource.clearFilter();
  }

  public onDetails(patient: Patient, createAction: boolean): void {
    this.ruleAlertService
      .listNoDataTransmission(this.getCaremateIdentifier(patient))
      .pipe(
        first(),
        switchMap((ra) => {
          return this.dialog
            .open(AlertDetailsComponent, {
              data: {
                alert: ra,
                createAction,
                patientServiceId: patient.healthcareservice?.[0]?.reference,
              },
              maxWidth: createAction ? "80vw" : "30vw",
              maxHeight: "93vh",
            })
            .afterClosed();
        })
      )
      .subscribe((needReload) => {
        if (needReload) {
          this.loadInactivePatients();
        }
      });
  }
}
