import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Filter } from "src/app/models/filter.interface";
import { SessionService } from "src/app/providers/session.service";

@Component({
  selector: "app-filters-display",
  templateUrl: "./filters-display.component.html",
  styleUrls: ["./filters-display.component.scss"],
})
export class FiltersDisplayComponent implements OnChanges {
  @Input() parent: string;
  @Input() filters: Filter[];
  @Output() removeFilter: EventEmitter<Filter> = new EventEmitter();

  public onRemoveFilter(filter: Filter): void {
    const emptyFilter: Filter = {
      data: "",
      propertyName: filter.propertyName,
      dataType: filter.dataType,
    };
    this.removeFilter.emit(emptyFilter);
  }

  constructor(private sessionService: SessionService, private translateService: TranslateService) {}

  ngOnChanges(): void {
    if (this.parent === "patientList") {
      this.filters = this.translateFilters(this.filters);
    }
  }

  /**
   * Method overwriting the filter translateKey in order to compile with France specific translations for ANS
   */
  public translateFilters(filters: Filter[]): Filter[] {
    return filters.map((filter) => {
      switch (filter.propertyName) {
        case "name":
          filter.translateKey =
            this.sessionService.currentCountry === this.sessionService.FRANCE
              ? this.translateService.instant("forms.FrName")
              : this.translateService.instant("model.patient.name");

          break;
        case "firstname":
          filter.translateKey =
            this.sessionService.currentCountry === this.sessionService.FRANCE
              ? this.translateService.instant("forms.FrFirstname")
              : this.translateService.instant("model.patient.firstname");
          break;
      }
      return filter;
    });
  }
}
