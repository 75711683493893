import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Coding } from "src/app/models/coding.interface";
import { ACTION_REWARD, REWARD_STATUS } from "src/app/models/rewardScore.interface";
import { RewardScore } from "src/app/models/rewardScore.model";
import { RewardScoreService } from "src/app/providers/rewardScore.service";
import { SessionService } from "src/app/providers/session.service";

@Component({
  selector: "app-reward-details-edit",
  templateUrl: "./reward-details-edit.component.html",
  styleUrls: ["./reward-details-edit.component.scss"],
})
export class RewardDetailsEditComponent implements OnInit, OnDestroy {
  @Input() reward: RewardScore;
  @Input() createAction: boolean;
  @Input() patientServiceId: string;
  @Output() saved = new EventEmitter<void>();

  public actionForm: UntypedFormGroup = this.fb.group({
    status: [undefined, []],
    comment: [undefined, []],
  });
  public availableStatus: Coding[] = [
    {
      code: REWARD_STATUS.PROCESSED.toString(),
      display: this.translateService.instant("alerts.status.processed"),
    },
    {
      code: REWARD_STATUS.UNPROCESSED.toString(),
      display: this.translateService.instant("alerts.status.unprocessed"),
    },
  ];

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  public constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    protected rewardScoreService: RewardScoreService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initForm() {
    this.actionForm = this.fb.group({
      status: [this.reward.rewardStatus, []],
      comment: [undefined, []],
    });
    if (!this.rewardScoreService.canUpdateSync()) {
      this.actionForm.disable();
    }
  }

  public onSave(quitModal: boolean): void {
    let status = Number(this.actionForm.get("status").value);
    const comment = this.actionForm.get("comment").value as string;
    const arrAction: ACTION_REWARD[] = [];

    if (!this.createAction) {
      status = REWARD_STATUS.PROCESSED as number;
    }

    if (status !== this.reward.rewardStatus) {
      arrAction.push(ACTION_REWARD.CHANGE_STATUS);
    }
    if (comment?.length) {
      arrAction.push(ACTION_REWARD.ADD_NOTE);
    }
    if (arrAction.length) {
      this.reward.handleActionArray(arrAction, status, comment);
      this.rewardScoreService
        .updateRewardScore(this.reward)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          if (this.createAction) {
            if (!quitModal) {
              this.initForm();
              this.sessionService.needRefreshRewardDetailsDataList();
            } else {
              this.saved.emit();
              this.sessionService.needRefreshRewardDetailsDataList();
            }
          } else {
            this.saved.emit();
          }
        });
    }
  }
}
