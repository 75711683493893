import { Component, ContentChild } from "@angular/core";
import { MatInput } from "@angular/material/input";

@Component({
  selector: "app-show-hide-password",
  templateUrl: "./show-hide-password.component.html",
  styleUrls: ["./show-hide-password.component.scss"],
})
export class ShowHidePasswordComponent {
  @ContentChild(MatInput) input: MatInput;

  public showPassword = false;

  public toggleShow(): void {
    this.showPassword = !this.showPassword;
    this.input.type = this.showPassword ? "text" : "password";
  }
}
