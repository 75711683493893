import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-error-generate-password",
  templateUrl: "./error-generate-password.component.html",
  styleUrls: ["./error-generate-password.component.scss"],
})
export class ErrorGeneratePasswordComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { message: string },
    private dialogRef: MatDialogRef<ErrorGeneratePasswordComponent>
  ) {}

  public closeModal(): void {
    this.dialogRef.close();
  }
}
