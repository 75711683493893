import { Pipe, PipeTransform } from "@angular/core";
import { UntypedFormArray } from "@angular/forms";
import { ITranslation } from "src/app/models/translation.interface";

@Pipe({ name: "getDescriptionFilteredLang", pure: false }) // Need to set pure to false, otherwise the pipe is not triggered every time it is needed
export class getDescriptionFilteredLangPipe implements PipeTransform {
  /**
   * Return the availables languages options that are not set yet
   */
  transform(availableLangs: ITranslation[], form: UntypedFormArray, index: number): ITranslation[] {
    const langAlreadyPresent: string[] = form.value.map((d) => {
      return d.lang;
    });
    langAlreadyPresent.splice(index, 1);
    return availableLangs.filter((l) => {
      return !langAlreadyPresent.includes(l.term);
    });
  }
}
