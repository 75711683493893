import * as moment from "moment";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { IVitalSignsHistory } from "src/app/models/vital-signs-history.interface";

export class VitalSignsHistoricDataSource extends MatTableDataSourceExtended<IVitalSignsHistory> {
  sortingDataAccessor = (data: IVitalSignsHistory, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "date":
        return moment(data.date).unix();
      case "author":
        return data.author?.reference.toLowerCase();
      case "exValue":
        return data.exValue;
      case "newValue":
        return data.newValue;
      case "type":
        return data.type;
      default:
        return "";
    }
  };

  constructor() {
    super();
  }

  public loadData(data: IVitalSignsHistory[]): void {
    this.data = data;
  }
}
