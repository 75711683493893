<div style="margin-top: 10px; margin-bottom: 10px" *ngIf="questionnaireLinkType !== QUESTIONNAIRE_LINK_TYPE.ACTIVITY">
  <span [ngStyle]="questionnaireLinkType === QUESTIONNAIRE_LINK_TYPE.CAREPLAN_AR ? { opacity: '50%' } : null">{{
    "knowledgebase.knowledgeCriteria.showDirectly" | translate
  }}</span>
  &nbsp;
  <mat-slide-toggle
    [(ngModel)]="showDirectly"
    (change)="careplanLinkTypeChange($event.checked)"
    name="showDirectly"
    [disabled]="disabled || disableShowDirectly"
  >
    <span [ngStyle]="questionnaireLinkType === QUESTIONNAIRE_LINK_TYPE.LINK2CAREPLAN ? { opacity: '50%' } : null">
      {{ "limitCreationByFreq.linkToCareplansStart" | translate }}
    </span>
  </mat-slide-toggle>
</div>
<div *ngIf="questionnaireLinkType !== QUESTIONNAIRE_LINK_TYPE.LINK2CAREPLAN">
  <div *ngFor="let when of actionResultingsWhens" class="arWhen">
    <p style="font-style: italic">- {{ when.infos.display }}</p>
    <app-when-input
      [needShowDirectly]="false"
      [(period)]="when.when.period"
      [(periodUnit)]="when.when.periodUnit"
      [isDisabled]="disabled"
      [hasSelectedActivity]="questionnaireLinkType === QUESTIONNAIRE_LINK_TYPE.ACTIVITY ? true : false"
    >
    </app-when-input>
  </div>
</div>
