import { Pipe, PipeTransform } from "@angular/core";
import { FileLogger } from "../helpers/fileLogger";
import { Identity, Patient } from "../models/patient.interface";

@Pipe({ name: "getLatestIdentityFromPatient" })
export class GetLatestIdentityFromPatientPipe implements PipeTransform {
  transform(patient: Patient): Identity {
    const latestIdentity = patient.identity[patient.identity.length - 1];
    if (!latestIdentity.period?.end) {
      return latestIdentity;
    } else {
      FileLogger.error("GetLatestIdentityFromPatientPipe", "Cannot find latest identity");
    }
  }
}
