<div class="server-side-search">
  <h2>{{ "common.searchPatient" | translate }}</h2>
  <p>
    <mat-form-field class="mat-field">
      <mat-select
        [formControl]="patientServerSideFilteringCtrl"
        (selectionChange)="emit($event)"
        placeholder="{{ 'menu.patients' | translate }}"
      >
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="placeholderLabel"
            [noEntriesFoundLabel]="noEntriesFoundLabel"
            class="searchForm"
            [formControl]="patientServerSideFilteringCtrl"
            [searching]="searching"
          >
          </ngx-mat-select-search>
        </mat-option>
        <mat-option
          class="inputForm"
          *ngFor="let patient of filteredServerSidePatient | async"
          [value]="patient"
          matTooltip="{{ patient.name + ' ' + patient.firstname }}"
          matTooltipClass="truncatedNamesTooltip"
        >
          <span class="fas primary" [ngClass]="getClass(patient)"></span>
          {{ patient.name + " " + patient.firstname | truncate : 20 }}
          {{ patient.birthdate ? " | " + ("common.birthAt" | translate) : "" }}
          {{ patient.birthdate ? (patient.birthdate | date : "dd/MM/yyyy") : "" }}
          | {{ "common.phoneShort" | translate }} {{ patient.phone }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </p>
</div>
