import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Preference, PreferenceUser } from "src/app/models/preference.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class PreferenceApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Preferences", // Theme !
      [], // Post route paths
      [], // Get route paths
      [], // Update route paths
      [] // Delete route paths
    );
  }

  public list(context: string): Observable<any> {
    return this.api.get(`preferences/${context}`);
  }

  public update(preference: Preference): Observable<PreferenceUser> {
    return this.api.put("preference", preference) as Observable<PreferenceUser>;
  }
}
