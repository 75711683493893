<div class="drugSchemaPage">
  <div class="toolbar">
    <div class="left">
      <mat-form-field>
        <mat-label><span class="fa fa-search mr-5"></span>{{ "itemFilter.search" | translate }}</mat-label>
        <input
          type="search"
          matInput
          [placeholder]="'page.drugSchema.searchPlaceholder' | translate"
          [value]="globalSearchValue"
          (keyup)="updateSearch($event.target.value)"
        />
        <button *ngIf="globalSearchValue" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="updateSearch('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button mat-icon-button (click)="openDrugSchemaHelp()">
        <mat-icon style="font-size: 30px" matTooltip="{{ 'btn.help' | translate }}">help_outline</mat-icon>
      </button>
    </div>
    <div class="spacer">
      {{ "page.drugSchema.title" | translate }} :
      <mat-button-toggle-group
        [(ngModel)]="isCycleSelected"
        (ngModelChange)="isCycleSelectedChanged()"
        style="height: 25px; align-items: center; justify-content: center"
      >
        <mat-button-toggle [value]="true" style="display: flex" *ngIf="('dashboard/drugSchema' | isAuthorized | async) === true">{{
          "drugSchema.title" | translate
        }}</mat-button-toggle>
        <mat-button-toggle [value]="false" style="display: flex" *ngIf="('dashboard/stepwiseDrug' | isAuthorized | async) === true">{{
          "drugSchema.stepwise.title" | translate
        }}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="right">
      <button
        mat-stroked-button
        matTooltip="{{ 'page.drugSchema.newSchema' | translate }}"
        *ngIf="
          (isCycleSelected && ('dashboard/drugSchema' | isAuthorized : 'POST' | async) === true) ||
          (!isCycleSelected && ('dashboard/stepwiseDrug' | isAuthorized : 'POST' | async) === true)
        "
        (click)="createNewSchema()"
        [disabled]="sessionService.currentService?.display === sessionService.allsOption"
        class="primary"
      >
        <mat-icon
          matTooltip="{{ 'btn.disabledWithAll' | translate }}"
          [matTooltipDisabled]="sessionService.currentService?.display !== sessionService.allsOption"
        >
          add
        </mat-icon>
      </button>
      <button mat-stroked-button (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
        <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
      </button>
    </div>
  </div>
  <app-filters-display
    [filters]="isCycleSelected ? cycleFilters : stepwiseFilters"
    (removeFilter)="applyFilter($event)"
  ></app-filters-display>
  <mat-card>
    <table
      #MatSortMain="matSort"
      #MatTableMain="matTable"
      mat-table
      matSort
      multiTemplateDataRows
      matSortDirection="desc"
      matSortActive="modified"
      [dataSource]="dataSource"
    >
      <!-- expand Column-->
      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="arrowIcons">
          <mat-icon (click)="expand(element)" *ngIf="!isExpand(element)">arrow_right</mat-icon>
          <mat-icon (click)="expand(element)" *ngIf="isExpand(element)">arrow_drop_down</mat-icon>
        </td>
      </ng-container>

      <!-- Visibility column -->
      <ng-container matColumnDef="visibility">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.patient.visible" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.visible' | translate }}"
            [translateFieldKey]="'model.patient.visible'"
            propertyName="visibility"
            [dataType]="dataTypeChoice"
            translatePrefix="page.drugSchema.status"
            [data]="dataSource.data"
            [defaultValue]="getFilter('visibility')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.public" matTooltip="{{ 'page.drugSchema.global' | translate }}">
            <mat-icon>public</mat-icon>
          </span>
          <span *ngIf="!element.public && element.author" matTooltip="{{ 'page.drugSchema.me' | translate }}">
            <mat-icon>lock</mat-icon>
          </span>
          <span *ngIf="!element.public && element.organization" matTooltip="{{ 'page.drugSchema.myOrg' | translate }}">
            <mat-icon>lock_open</mat-icon>
          </span>
        </td>
      </ng-container>

      <!-- Name column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "page.drugSchema.table.name" | translate }}
          <app-item-filter
            fieldName="{{ 'page.drugSchema.table.name' | translate }}"
            [translateFieldKey]="'page.drugSchema.table.name'"
            propertyName="name"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('name')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>

      <!-- Drugs column -->
      <ng-container matColumnDef="drugs">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "page.drugSchema.table.drugs" | translate }}
          <app-item-filter
            fieldName="{{ 'page.drugSchema.table.drugs' | translate }}"
            [translateFieldKey]="'page.drugSchema.table.drugs'"
            propertyName="drugs"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('drugs')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.drugs && element.drugs.length ? element.drugs.join(", ") : "/" }}
        </td>
      </ng-container>

      <!-- Last change column -->
      <ng-container matColumnDef="modified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "page.drugSchema.table.lastChange" | translate }}
          <app-item-filter
            fieldName="{{ 'page.drugSchema.table.lastChange' | translate }}"
            [translateFieldKey]="'page.drugSchema.table.lastChange'"
            propertyName="modified"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('modified')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.modified | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <!-- Author column -->
      <ng-container matColumnDef="author">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "page.drugSchema.table.author" | translate }}
          <app-item-filter
            fieldName="{{ 'page.drugSchema.table.author' | translate }}"
            [translateFieldKey]="'page.drugSchema.table.author'"
            propertyName="author.display"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('author.display')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.author ? element.author.display : "/" }}
        </td>
      </ng-container>

      <!-- Duration column -->
      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "page.drugSchema.table.duration" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            isCycleSelected
              ? element.cycle?.length
              : element.stepwises?.[element.stepwises?.length - 1]?.startDay + element.stepwises?.[element.stepwises?.length - 1]?.days.length
          }}
        </td>
      </ng-container>

      <!-- Action column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{ "page.drugSchema.table.action" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            *ngIf="
              (isCycleSelected && ('dashboard/drugSchema' | isAuthorized : 'PUT' | async) === true) ||
              (!isCycleSelected && ('dashboard/stepwiseDrug' | isAuthorized : 'PUT' | async) === true)
            "
            (click)="updateSchema(element)"
            matTooltip="{{ 'page.drugSchema.updateSchema' | translate }}"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            *ngIf="
              (isCycleSelected && ('dashboard/drugSchema' | isAuthorized : 'DELETE' | async) === true) ||
              (!isCycleSelected && ('dashboard/stepwiseDrug' | isAuthorized : 'DELETE' | async) === true)
            "
            (click)="deleteSchema(element._id)"
            matTooltip="{{ 'page.drugSchema.deleteSchema' | translate }}"
          >
            <mat-icon class="fail">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="element__detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
            <div class="element__text">{{ "drugSchema.overview" | translate }} :</div>
            <div class="element__table">
              <div *ngFor="let eachRow of rowOfDisplayedColumns">
                <table mat-table class="full-w" [dataSource]="[element.cycle]" *ngIf="isCycleSelected">
                  <ng-container *ngFor="let i of eachRow" [matColumnDef]="i">
                    <th mat-header-cell *matHeaderCellDef>{{ +i + 1 }}</th>
                    <td mat-cell *matCellDef="let element" [ngClass]="element[i] === true ? 'intakeTrue' : 'intakeFalse'"></td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="eachRow"></tr>
                  <tr mat-row *matRowDef="let row; columns: eachRow"></tr>
                </table>
                <table
                  mat-table
                  #sort="matSort"
                  matSort
                  matSortDirection="desc"
                  class="full-w"
                  [dataSource]="[element.stepwises]"
                  *ngIf="!isCycleSelected"
                >
                  <ng-container *ngFor="let i of eachRow" [matColumnDef]="i">
                    <th mat-header-cell *matHeaderCellDef>{{ +i + 1 }}</th>
                    <td
                      mat-cell
                      *matCellDef="let e"
                      [ngClass]="(i | isStepwiseSelected : element) ? 'intakeTrue' : 'intakeFalse'"
                      [mdePopoverTriggerFor]="appPopover"
                      mdePopoverTriggerOn="hover"
                      #popoverTrigger="mdePopoverTrigger"
                    ></td>
                    <mde-popover
                      #appPopover="mdePopover"
                      [mdePopoverOverlapTrigger]="false"
                      mdePopoverPositionY="above"
                      mdePopoverArrowWidth="0"
                    >
                      <div *ngIf="i | isStepwiseSelected : element" class="popoverContainer">
                        <div *ngFor="let moment of (i | getStepwise : element)?.days[0]?.moment" class="moment">
                          {{ "careplan.timingCodes." + moment.timingCode | translate }}
                          <div *ngFor="let drug of moment.drugs" class="drugs">
                            <div class="drugName">
                              {{ drug.name }}
                            </div>
                            <div class="drugQuantity">
                              {{ drug.quantity }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </mde-popover>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="eachRow"></tr>
                  <tr mat-row *matRowDef="let row; columns: eachRow"></tr>
                </table>
              </div>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="element__row"
        [class.example-expanded-row]="expandedElement === element"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail__row"></tr>
    </table>
    <div class="spinner-container" *ngIf="dataSource.loading$ | async">
      <mat-spinner></mat-spinner>
    </div>
    <mat-paginator
      #MatPaginatorMain
      pageIndex="0"
      [pageSize]="currentPageSize ? currentPageSize : 10"
      [pageSizeOptions]="[10, 25, 50, 100, 250]"
    ></mat-paginator>
  </mat-card>
</div>
