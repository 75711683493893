import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Contained } from "src/app/models/questionnaire.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class ValueSetsApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "ValueSets", // Theme !
      ["dashboard/valueSet", "dashboard/valueSet/global"], // Post route paths
      ["dashboard/valueSets", "dashboard/valueSetsUnits"], // Get route paths
      ["dashboard/valueSet", "dashboard/valueSet/global"], // Put route paths
      ["dashboard/valueSet", "dashboard/valueSet/global"] // Delete route paths
    );
  }

  public getValueSets(lang: string, serviceIds: string[], orgsIds: string[]): Observable<Contained[]> {
    const params = {
      lang,
      serviceIds: serviceIds && serviceIds.length ? serviceIds.join() : undefined,
      orgsIds: orgsIds && orgsIds.length ? serviceIds.join() : undefined,
    };

    return this.api.get(this.readRoutes[0], params) as Observable<Contained[]>;
  }

  public getValueSetsUnits(lang: string, serviceIds: string[], orgsIds: string[]): Observable<Contained[]> {
    const params = {
      lang,
      serviceIds: serviceIds && serviceIds.length ? serviceIds.join() : undefined,
      orgsIds: orgsIds && orgsIds.length ? serviceIds.join() : undefined,
    };
    return this.api.get(this.readRoutes[1], params) as Observable<Contained[]>;
  }

  public createValueSet(valueSet: Contained): Observable<Contained> {
    if (this.isTemplateValueSetGlobal(valueSet)) {
      return this.api.post(this.createRoutes[1], valueSet) as Observable<Contained>;
    } else {
      return this.api.post(this.createRoutes[0], valueSet) as Observable<Contained>;
    }
  }

  public updateValueSet(valueSet: Contained): Observable<Contained> {
    if (this.isTemplateValueSetGlobal(valueSet)) {
      return this.api.put(this.updateRoutes[1], valueSet) as Observable<Contained>;
    } else {
      return this.api.put(this.updateRoutes[0], valueSet) as Observable<Contained>;
    }
  }

  public deleteValueSet(valueSet: Contained): Observable<Contained> {
    if (this.isTemplateValueSetGlobal(valueSet)) {
      return this.api.delete(this.deleteRoutes[1], valueSet) as Observable<Contained>;
    } else {
      return this.api.delete(this.deleteRoutes[0], valueSet) as Observable<Contained>;
    }
  }

  /**
   * Returns whether or not the template value set would be available for
   * every one or is restricted to some services.
   * @param valueSet (Contained) the template value set we want to examine
   */
  public isTemplateValueSetGlobal(valueSet: Contained): boolean {
    if (!valueSet.useContext || !valueSet.useContext.length || valueSet.useContext.length !== 1) {
      return false;
    }
    if (valueSet.useContext[0].valueReference === "all") {
      return true;
    }
    return false;
  }
}
