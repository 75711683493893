import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { AddServiceComponent } from "src/app/components/forms/add-service/add-service.component";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { Healthcareservice } from "src/app/models/healthcareservice.model";
import { Organization } from "src/app/models/organization.model";

@Component({
  selector: "app-user-list-dialog-component",
  templateUrl: "./user-list-dialog-component.component.html",
  styleUrls: ["./user-list-dialog-component.component.scss"],
})
export class UserListDialogComponentComponent implements OnInit {
  public service: Healthcareservice;
  public organization: Organization;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: { service: Healthcareservice; organization: Organization }
  ) {}

  ngOnInit(): void {
    this.service = this.data.service;
    this.organization = this.data.organization;
  }

  public updateService(): void {
    // open update service modale
    this.dialog.open(AddServiceComponent, {
      disableClose: true,
      data: {
        service: this.service,
        orgRef: this.organization?.identifier[0],
        mode: FORMS_MODE.UPDATE,
      },
    });
  }
}
