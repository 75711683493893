import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { Healthcareservice } from "src/app/models/healthcareservice.model";
import { AccessLevel, IReference, IUseContext } from "src/app/models/sharedInterfaces";
import { UseContext } from "src/app/models/sharedModels.model";

@Component({
  selector: "app-use-context",
  templateUrl: "./use-context.component.html",
  styleUrls: ["./use-context.component.scss"],
})
export class UseContextComponent implements OnInit, OnDestroy {
  // Important: all changes in the inputs of this component are immediately transmitted to
  // the parent component !
  @Input() services: Healthcareservice[];
  @Input() orgsRefs: IReference[];
  @Input() globalAuthorized: boolean;
  @Input() useContext: IUseContext[];
  @Input() showLevels = true;

  @Output() useContextChange: EventEmitter<IUseContext[]> = new EventEmitter<IUseContext[]>();

  private allRef = { reference: "all", display: this.translateService.instant("groupPermission.all") };

  // The forms:
  public accessLevelsFormArray: UntypedFormArray = new UntypedFormArray([]);
  public orgsAndServForm = new UntypedFormGroup(
    {
      services: new UntypedFormControl([]),
      organizations: new UntypedFormControl([]),
      accessLevelsFormArray: this.accessLevelsFormArray,
    },
    this.atLeastOneValidator()
  );

  public allOrgs: IReference[] = [];
  public allServices: IReference[] = [];

  public accessLevels = [AccessLevel.READ, AccessLevel.WRITE];

  private onDestroy$ = new Subject<void>();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.prepareServicesAndOrgsLists();

    // In case of update:
    if (this.useContext) {
      const r = this.sortBetweenServicesAndOrgsRef(this.useContext);
      this.orgsAndServForm.get("services").patchValue(r.services);
      this.orgsAndServForm.get("organizations").patchValue(r.orgs);
      for (const u of this.useContext) {
        this.accessLevelsFormArray.push(
          new UntypedFormControl({
            valueReference: u.valueReference,
            accessLevel: u.accessLevel,
            display: this.findDisplay(u.valueReference),
          })
        );
      }
    }
    // Watch the selection of organizations and service:
    this.orgsAndServForm
      .get("services")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((services) => {
        const orgs = this.orgsAndServForm.get("organizations").value;
        const orgsAndServ: string[] = this.mergeOrgsAndServices(orgs, services);
        this.updateAccessFormArray(orgsAndServ);
      });
    this.orgsAndServForm
      .get("organizations")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((orgs) => {
        const services = this.orgsAndServForm.get("services").value;
        const orgsAndServ: string[] = this.mergeOrgsAndServices(orgs, services);
        this.updateAccessFormArray(orgsAndServ);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Update the useContext list and emits it when one of the access levels has been
   * changed
   */
  public accessLevelUpdated(): void {
    this.useContext = this.accessLevelsFormArray.value;
    this.useContext.sort(UseContext.useContextSortReference);
    this.useContextChange.emit(this.useContext);
  }

  /**
   * Prepare the lists of available organizations and services, using
   * the data provided by inputs
   */
  private prepareServicesAndOrgsLists(): void {
    const refs = this.globalAuthorized === false ? [] : [this.allRef];
    for (const s of this.services) {
      refs.push({
        reference: s.serviceRef,
        display: s.providedBy.display
          ? s.providedBy.display + " - " + s.asReference.display
          : s.providedBy.reference
          ? s.providedBy.reference + " - " + s.asReference.display
          : s.asReference.display,
      });
    }
    this.allServices = refs;
    this.allOrgs = this.globalAuthorized === false ? [...this.orgsRefs] : [this.allRef, ...this.orgsRefs];
  }

  /**
   * Reference to services and organizations are mixed in the useContext.
   * Here we sort them in two array
   * @param useContext (IUseContext) the useContext we want to sort
   * @returns
   */
  private sortBetweenServicesAndOrgsRef(useContext: IUseContext[]): { orgs: string[]; services: string[] } {
    const result = { orgs: [], services: [] };
    for (const u of useContext) {
      if (this.allOrgs.find((o) => o.reference === u.valueReference)) {
        result.orgs.push(u.valueReference);
      } else {
        result.services.push(u.valueReference);
      }
    }
    return result;
  }

  /**
   * Update the list of useContext with access levels for the new selection of services and
   * organizations
   * @param orgsAndServ (string[]) the reference of the new selection of services and organizations
   */
  private updateAccessFormArray(orgsAndServ: string[]): void {
    for (const [i, c] of this.accessLevelsFormArray.controls.entries()) {
      if (!orgsAndServ.includes(c.value.valueReference)) {
        this.accessLevelsFormArray.removeAt(i);
      }
    }
    const accesses: IUseContext[] = this.accessLevelsFormArray.value;
    const accessesToAdd: IUseContext[] = orgsAndServ
      .filter((o) => !accesses.find((a) => a.valueReference === o))
      .map((o) => {
        return { valueReference: o, accessLevel: AccessLevel.READ, display: this.findDisplay(o) };
      });
    const newControls = accessesToAdd.map((a) => new UntypedFormControl(a));
    for (const c of newControls) {
      this.accessLevelsFormArray.push(c);
    }
    this.useContext = [...accesses, ...accessesToAdd];
    this.useContext.sort(UseContext.useContextSortReference);
    this.useContextChange.emit(this.useContext);
  }

  /**
   * Combines the references of the organizations and services
   * @param orgs (string[]) a list of organizations' references
   * @param services (string[]) a list of services' references
   * @returns
   */
  private mergeOrgsAndServices(orgs: string, services: string): string[] {
    let orgsAndServ: string[] = [];
    if (services) {
      orgsAndServ.push(...services);
    }
    if (orgs) {
      orgsAndServ.push(...orgs);
    }
    orgsAndServ = orgsAndServ.filter(ArrayHelper.onlyUnique);
    return orgsAndServ;
  }

  /**
   * Find a display string of a service or an organization from its reference
   * @param ref (string) the reference
   * @returns
   */
  private findDisplay(ref: string): string {
    let d = this.allServices.find((s) => s.reference === ref);
    if (!d) {
      d = this.allOrgs.find((s) => s.reference === ref);
    }
    return d?.display;
  }

  /**
   * Create a validator that checks that there's at least one service or organization selected
   * @returns a validator
   */
  private atLeastOneValidator(): ValidatorFn {
    const validator: ValidatorFn = (formGroup: UntypedFormGroup) => {
      if (formGroup.get("services").value && formGroup.get("services").value.length) {
        formGroup.get("organizations").setErrors(null);
        formGroup.get("services").setErrors(null);
        return null;
      }
      if (formGroup.get("organizations").value && formGroup.get("organizations").value.length) {
        formGroup.get("organizations").setErrors(null);
        formGroup.get("services").setErrors(null);
        return null;
      }
      formGroup.get("organizations").setErrors({ requiredAtLeastOne: true });
      formGroup.get("services").setErrors({ requiredAtLeastOne: true });
      formGroup.markAllAsTouched();
      return { requiredAtLeastOne: true };
    };
    return validator;
  }
}
