import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IGroup } from "src/app/models/group.interface";
import { Group } from "src/app/models/group.model";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class GroupApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Groups", // Theme !
      ["group"], // Post route paths
      ["groups"], // Get route paths
      ["group"], // Update route paths
      [] // Delete route paths
    );
  }

  public list(healthcareService: string, services?: string[]): Observable<Group[]> {
    const params: any = {};
    if (healthcareService) {
      params.healthcareservice = healthcareService;
    }
    if (services) {
      params.services = JSON.stringify(services);
    }
    return this.api.get(this.readRoutes[0], params).pipe(
      map((values: IGroup[]) => {
        return values.map((v) => new Group(v));
      })
    );
  }

  public listAll() {
    return this.api.get(this.readRoutes[0]).pipe(
      map((values: IGroup[]) => {
        return values.map((v) => new Group(v));
      })
    );
  }

  public create(group: Group): Observable<Group> {
    return this.api.post(this.createRoutes[0], group).pipe(map((v: IGroup) => new Group(v)));
  }

  public update(group: Group): Observable<Group> {
    return this.api.put(this.updateRoutes[0], group).pipe(map((v: IGroup) => new Group(v)));
  }
}
