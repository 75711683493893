import { Component, Input } from "@angular/core";

@Component({
  selector: "app-global-error-display",
  templateUrl: "./global-error-display.component.html",
  styleUrls: ["./global-error-display.component.scss"],
})
export class GlobalErrorDisplayComponent {
  @Input() message: string;
  @Input() code: number;

  public get errorCode() {
    switch (this.code) {
      case 403:
      case 500:
        return "api.errors.server-error";
      default:
        return null;
    }
  }
}
