<h3 mat-dialog-title>{{ "page.knowledgeDetails.draftWarning" | translate }}</h3>
<div mat-dialog-content>
  <p class="red">{{ "page.knowledgeDetails.deleteWarning" | translate }}</p>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="deleteDraft()" color="warn">
    {{ "page.knowledgeDetails.btn.deleteDraft" | translate }}
  </button>
  <button mat-stroked-button (click)="goBack()">
    {{ "page.knowledgeDetails.btn.keepDraft" | translate }}
  </button>
  <button mat-stroked-button (click)="goToPublished()">
    {{ "page.knowledgeDetails.btn.publish" | translate }}
  </button>
</div>
