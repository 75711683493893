import { Component, Inject, OnDestroy } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Observable, Subject } from "rxjs";
import { map, startWith, takeUntil } from "rxjs/operators";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { IAccessGroup } from "src/app/models/accessGroup.interface";
import { Account, UserRole } from "src/app/models/account.interface";
import { UserService } from "src/app/providers/user.service";

@Component({
  selector: "app-practitioner-access-groups-dialog",
  templateUrl: "./practitioner-access-groups-dialog.component.html",
  styleUrls: ["./practitioner-access-groups-dialog.component.scss"],
})
export class PractitionerAccessGroupsDialogComponent implements OnDestroy {
  public practitionerControl = new UntypedFormControl(null);
  public filteredUsers: Observable<Account[]>;

  public practitioner: Account;
  public accessGroupsIds: string[];
  public allUsers: Account[];

  /** Subject that emits when the component has been destroyed. */
  // tslint:disable-next-line: variable-name
  private onDestroy$ = new Subject<void>();

  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<PractitionerAccessGroupsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { mode: FORMS_MODE; allAccessGroups: IAccessGroup[]; ownAccount: Account }
  ) {
    this.init();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public showGroups() {
    if (this.practitionerControl.value) {
      this.practitioner = this.practitionerControl.value;
      this.accessGroupsIds = this.practitioner.accessGroups;
    }
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  /**
   * Display function for the autocomplete input
   * @param user the selected user
   * @returns
   */
  public displayFn(user: Account): string {
    return user ? user.firstname + " " + user.name + " (" + user.mail + ")" : "";
  }

  private init() {
    this.userService
      .list()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((users) => {
        this.allUsers = users.filter(
          (u) => u.role.includes(UserRole.PRACTITIONER) || u.role.includes(UserRole.ADMIN) || u.role.includes(UserRole.ORAGNIZATION_ADMIN)
        );

        this.filteredUsers = this.practitionerControl.valueChanges.pipe(
          takeUntil(this.onDestroy$),
          startWith(""),
          map((value: string | Account) => {
            const name = typeof value === "string" ? value : value?.mail;
            return name ? this._filterUsers(name as string) : this.allUsers.slice();
          })
        );
      });
  }

  private _filterUsers(value: string): Account[] {
    const filterValue = value.toLowerCase();
    return this.allUsers.filter(
      (user) => user.mail.toLowerCase().includes(filterValue) || (user.firstname + " " + user.name).toLowerCase().includes(filterValue)
    );
  }
}
