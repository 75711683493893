import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { Quantity } from "src/app/models/quantity.model";
import { IQuantity } from "src/app/models/sharedInterfaces";
import { IVitalProfileDefinition } from "src/app/models/vitalProfileDefinition.interface";
import { VitalSignControlService } from "../../../../providers/vital-sign-control.service";

@Component({
  selector: "app-vital-sign-form",
  templateUrl: "./vital-sign-form.component.html",
  providers: [VitalSignControlService],
})
export class VitalSignFormComponent implements OnChanges, OnDestroy {
  @Input() def: IVitalProfileDefinition;
  @Input() vitalSign: Quantity;
  @Output() formUpdateEvent = new EventEmitter<unknown>();

  public form: UntypedFormGroup;
  protected formSubscription: Subscription;
  private onDestroy$ = new Subject<void>();

  constructor(private vitalSignControlService: VitalSignControlService) {}

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.def) {
      this.initForm();
    }

    if (this.def.type === "array") {
      this.form.controls[this.def.code].setValidators(this.checkArrayValidator(this.def.code));
    } else {
      this.form.controls[this.def.code].setValidators([Validators.min(this.def.min), Validators.max(this.def.max)]);
    }
  }

  private initForm() {
    this.formSubscription?.unsubscribe();
    this.form = this.vitalSignControlService.toFormGroup(this.def, this.vitalSign as IQuantity);
    const initialValueArray = this.vitalSign.valueArray;

    this.formSubscription = this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      const valueArray: number[][] = [];
      this.def.columns.forEach((col, index) => {
        valueArray.push([]);
        for (const [key, value] of Object.entries(this.form.get(this.def.code).value)) {
          if (key.split("-")[0] === col.coding.code) {
            // the key is stuctured like this : code+"-"+index; ex: M-0, IC-1, etc.
            valueArray[index].push(!value && value !== 0 ? null : +value); // warning : index is the index of column !! Transpose below
          }
        }
      });

      const newValues = ArrayHelper.transpose(valueArray);

      // mustUpdate value : compare array and avoid saving data if there is no change
      this.formUpdateEvent.emit({
        valueArray: newValues,
        mustUpdate: JSON.stringify(newValues) !== JSON.stringify(initialValueArray) && this.form.valid,
      });
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public checkArrayValidator(controlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const v: number = control.value;
      const isEmptyObject = Object.keys(v);
      // check for null and undefined values for accept 0
      const isEmptyArray = Object.values(v).every((e) => e === null || undefined);
      const currentInput = this.form.controls[controlName];
      // We check each values here (if the values are numbers and zero or positives)
      const isInvalidArray = Object.values(v).some((value) => typeof value !== "number" || value < 0);

      if (!isEmptyObject.length) {
        return {
          checkArrayValidator: true,
          typeError: "ERROR ON OBJECT - EMPTY",
        };
      }

      if (isEmptyArray) {
        if (currentInput.dirty) {
          return null;
        }
      }

      if (isInvalidArray) {
        if (currentInput.dirty) {
          return {
            checkArrayValidator: true,
            typeError: "VALUES ARE NOT ALL COMPLETED",
          };
        }
      }
      return null;
    };
  }
}
