import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { QUESTIONNAIRE_LINK_TYPE } from "src/app/models/questionnaire.interface";

export interface IActionResultingWhen {
  infos: { reference: string; display: string };
  when: { period: number; periodUnit: string };
}

@Component({
  selector: "app-questionnaire-start-date",
  templateUrl: "./questionnaire-start-date.component.html",
  styleUrls: ["./questionnaire-start-date.component.scss"],
})
export class QuestionnaireStartDateComponent implements OnInit {
  // Important: all changes in the inputs of this component are immediately transmitted to
  // the parent component !
  @Input() questionnaireLinkType: QUESTIONNAIRE_LINK_TYPE;
  @Input() actionResultingsWhens: IActionResultingWhen[];
  @Input() disabled = false;
  @Input() set blockLinkToStart(isBlocked: boolean) {
    if (isBlocked) {
      this.showDirectly = true;
      this.disableShowDirectly = true;
    } else {
      this.disableShowDirectly = false;
    }
  }

  @Output() questionnaireLinkTypeChange = new EventEmitter<QUESTIONNAIRE_LINK_TYPE>();
  @Output() actionResultingsWhensChange = new EventEmitter<IActionResultingWhen[]>();

  public QUESTIONNAIRE_LINK_TYPE = QUESTIONNAIRE_LINK_TYPE;
  public showDirectly = true;
  public disableShowDirectly = false;

  ngOnInit() {
    this.showDirectly = this.questionnaireLinkType === QUESTIONNAIRE_LINK_TYPE.CAREPLAN_AR;
  }

  public careplanLinkTypeChange(viaCareplanAR: boolean): void {
    if (this.questionnaireLinkType !== QUESTIONNAIRE_LINK_TYPE.ACTIVITY) {
      this.questionnaireLinkType = viaCareplanAR ? QUESTIONNAIRE_LINK_TYPE.CAREPLAN_AR : QUESTIONNAIRE_LINK_TYPE.LINK2CAREPLAN;
      this.questionnaireLinkTypeChange.emit(this.questionnaireLinkType);
    }
  }
}
