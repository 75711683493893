import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IJournalINSIEntry, InsResponse } from "src/app/models/ins.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class INSApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "INS", // Theme !
      [], // Post route paths
      ["dashboard/ins", "dashboard/journalinsis"], // Get route paths
      [], // Update route paths
      [] // Delete route paths
    );
  }

  public getINSIPatient(name: string, firstname: string, gender: string, birthDate: string, birthplace?: string): Observable<InsResponse> {
    if (birthplace) {
      return this.api.get(this.readRoutes[0], {
        NomNaissance: name,
        Prenom: firstname,
        Sexe: gender,
        DateNaissance: birthDate,
        LieuNaissance: birthplace,
      }) as Observable<InsResponse>;
    } else {
      return this.api.get(this.readRoutes[0], {
        NomNaissance: name,
        Prenom: firstname,
        Sexe: gender,
        DateNaissance: birthDate,
      }) as Observable<InsResponse>;
    }
  }

  public getINSILogs(caremateId: string, start: string, end: string): Observable<IJournalINSIEntry[]> {
    return this.api.get(this.readRoutes[1], { caremateId, start, end }) as Observable<IJournalINSIEntry[]>;
  }
}
