import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject, of } from "rxjs";
import { catchError, takeUntil } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { ITranslation } from "src/app/models/translation.interface";
import { LanguagesService } from "src/app/providers/languages.service";
import { SessionService } from "src/app/providers/session.service";
import { UserStatisticsService } from "src/app/providers/userStatistics.service";

export const enum ChangeLangMode {
  BUTTON = 0,
  DROPDOWN = 1,
  DROPDOWNSHORT = 2,
}

@Component({
  selector: "app-change-lang",
  templateUrl: "./change-lang.component.html",
  styleUrls: ["./change-lang.component.scss"],
})
export class ChangeLangComponent implements OnInit, OnDestroy {
  @Input() mode: ChangeLangMode = ChangeLangMode.BUTTON;

  public availableLangs: ITranslation[] = [];

  public get currentLang(): string {
    return this.translate.currentLang;
  }

  public set currentLang(value: string) {
    this.translate.use(value);
    this.sessionService.userLang = value;
    this.statService.createStatEvent("Changed language (" + value + ")");
  }

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private sessionService: SessionService,
    protected languagesService: LanguagesService,
    private statService: UserStatisticsService
  ) {}

  ngOnInit(): void {
    this.languagesService
      .list()
      .pipe(
        catchError((error) => {
          FileLogger.error("ChangeLangComponent", "Server error while downloading languages", error);
          return of([]);
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe((languages) => (this.availableLangs = languages));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public onChange(lang: string): void {
    this.currentLang = lang;
  }

  public get isButtonMode(): boolean {
    return this.mode === ChangeLangMode.BUTTON;
  }

  public get isDropdownMode(): boolean {
    return this.mode === ChangeLangMode.DROPDOWN;
  }

  public get isDropdownShortMode(): boolean {
    return this.mode === ChangeLangMode.DROPDOWNSHORT;
  }
}
