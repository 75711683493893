import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "firstnamesToArray",
})
export class FirstnamesToArrayPipe implements PipeTransform {
  transform(value: string): string[] {
    if (!value) {
      return [];
    }
    return value
      ?.split(",")
      ?.map((s) => s.trim())
      ?.filter(Boolean);
  }
}
