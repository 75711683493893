<div class="item-selector">
  <button mat-stroked-button (click)="toggleList()">
    <mat-icon>{{ logo }}</mat-icon>
  </button>
  <div
    class="item-list mat-elevation-z2"
    [ngClass]="{
      open: listDisplayed,
      left: position === 'left',
      right: position === 'right'
    }"
  >
    <mat-radio-group *ngIf="useRadioButton; else checkboxTemplate" [(ngModel)]="selectRadioBtn">
      <mat-radio-button *ngFor="let item of items" [value]="item" (change)="itemChange($event, item)">
        <span *ngIf="translate" class="item-selector-checkbox-label">{{ item.display | translate }} </span>
        <span *ngIf="!translate" class="item-selector-checkbox-label">{{ item.display }} </span>
      </mat-radio-button>
    </mat-radio-group>

    <ng-template #checkboxTemplate>
      <mat-checkbox *ngFor="let item of items" [checked]="item.checked" (change)="itemChange($event, item)">
        <span *ngIf="translate" class="item-selector-checkbox-label">{{ item.display | translate }}</span>
        <span *ngIf="!translate" class="item-selector-checkbox-label">{{ item.display }}</span>
      </mat-checkbox>
    </ng-template>
  </div>
</div>
