import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { FORMS_MODE } from "src/app/helpers/formsData";

@Component({
  selector: "app-drug-comment",
  templateUrl: "./drug-comment.component.html",
  styleUrls: ["./drug-comment.component.scss"],
})
export class DrugCommentComponent {
  @Input() mode: FORMS_MODE;
  @Input()
  get drugComment(): string {
    return this.comment;
  }
  set drugComment(comment: string) {
    this.cleanForm();
    this.comment = comment;
    this.initComment();
  }
  @Output() drugCommentChange: EventEmitter<string> = new EventEmitter<string>();

  private comment: string;
  public commentForm = this.fb.group({
    comment: [""],
  });

  constructor(private fb: UntypedFormBuilder) {}

  private cleanForm(): void {
    this.commentForm = this.fb.group({
      comment: [""],
    });
  }

  private initComment() {
    this.commentForm = this.fb.group({
      comment: [this.drugComment ? this.drugComment : ""],
    });
  }

  public save(): void {
    if (this.commentForm.get("comment").value !== "") {
      this.drugComment = this.commentForm.get("comment").value;
    } else {
      this.drugComment = "";
    }
    this.comment = this.drugComment;
    this.drugCommentChange.emit(this.comment);
  }
}
