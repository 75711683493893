import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Quantity } from "src/app/models/quantity.model";
import { ITranslation } from "src/app/models/translation.interface";
import { VitalSignsHistory } from "src/app/models/vital-signs-history.model";
import { IVitalProfileDefinition } from "src/app/models/vitalProfileDefinition.interface";
import { SessionService } from "src/app/providers/session.service";
import { VitalSignFormComponent } from "../forms/vitalSignForm/vital-sign-form/vital-sign-form.component";

@Component({
  selector: "app-value-array-dialog",
  templateUrl: "./value-array-dialog.component.html",
  styleUrls: ["./value-array-dialog.component.scss"],
})
export class ValueArrayDialogComponent implements OnInit {
  public def: IVitalProfileDefinition;
  public vitalSign: Quantity;
  @ViewChild(VitalSignFormComponent) vitalSignForm: VitalSignFormComponent;

  private vitalSignRawDatas: {
    code: string;
    value?: number;
    valueArray?: number[][];
    unit: ITranslation;
  }[];
  // This is not a Quantity, unit is an iTranslation
  public isVitalSignFormValid: boolean;

  // true if the form data has changed, useful to avoid saving data in db if there is no change
  public mustUpdate = false;

  constructor(
    public sessionService: SessionService,
    public dialogRef: MatDialogRef<ValueArrayDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      vitalSign?: Quantity;
      vsHistory?: VitalSignsHistory;
      valueArray?: number[][];
    }
  ) {}

  ngOnInit(): void {
    if (this.data.vitalSign) {
      this.def = this.data.vitalSign.def;
      this.vitalSign = this.data.vitalSign;
    } else if (this.data.vsHistory) {
      this.def = this.data.vsHistory.def as IVitalProfileDefinition;
      this.vitalSign = new Quantity(
        {
          code: this.data.vsHistory.def.code,
          value: null,
          valueArray: this.data.valueArray,
          unit: this.data.vsHistory.unit,
          system: undefined,
        },
        this.sessionService
      );
    }
  }

  /**
   * update vitalSigns with valueArray from vitalSignForm (not saving in DB, just update the variable)
   */
  public updateVitalSigns(event: { valueArray: number[][]; mustUpdate: boolean }): void {
    this.vitalSign.valueArray = event.valueArray;
    this.mustUpdate = event.mustUpdate;
  }

  public closeWithSave(): void {
    this.dialogRef.close({
      vitalSign: this.vitalSign,
      mustUpdate: this.mustUpdate,
    });
  }
}
