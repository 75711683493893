import { UntypedFormControl, UntypedFormGroup, NgModel } from "@angular/forms";

export abstract class FormPage {
  abstract getForm(): UntypedFormGroup;

  protected validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public isFieldValid(field: string) {
    return !this.getForm().get(field).valid && this.getForm().get(field).touched;
  }

  public isFieldIndeterminate(field: string) {
    return this.getForm().get(field).value === undefined;
  }

  public isModelValid(field: NgModel) {
    return field.errors && (field.dirty || field.touched);
  }

  public displayFieldCss(field: string) {
    return {
      "has-error": this.isFieldValid(field),
      "has-feedback": this.isFieldValid(field),
    };
  }
}
