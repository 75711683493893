<h1 mat-dialog-title>{{ "page.login.selectAccount.title" | translate }}</h1>
<div mat-dialog-content>
  <p [innerHTML]="'page.login.selectAccount.explanation' | translate"></p>
  <mat-form-field>
    <mat-select [(ngModel)]="selectedAccountIdx">
      <mat-option *ngFor="let account of data.accounts; let i = index" [value]="i">
        {{ account.mail }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="selectedAccountIdx" cdkFocusInitial>Ok</button>
</div>
