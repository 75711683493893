import { Component, EventEmitter, Input, Output } from "@angular/core";
import { QuestionQuestionnaire } from "src/app/models/questionnaire.interface";

@Component({
  selector: "app-rating-input",
  templateUrl: "./rating-input.component.html",
  styleUrls: ["./rating-input.component.scss"],
})
export class RatingInputComponent {
  @Input() disabled = false;
  @Input() set question(q: QuestionQuestionnaire) {
    this.ratingQuestion = q;
    this.setup();
  }
  @Output() questionChange = new EventEmitter<QuestionQuestionnaire>();

  ratingQuestion: QuestionQuestionnaire;
  min = 3;
  max = 10;
  initial = 5;

  private setup() {
    this.initial = 5;
    if (this.ratingQuestion.answerDisplay.default) {
      this.initial = Number(this.ratingQuestion.answerDisplay.default);
    } else {
      this.emitQuestionChanges();
    }
  }

  public emitQuestionChanges(): void {
    if (!this.initial) {
      this.initial = this.min;
    }
    if (this.initial < this.min) {
      this.initial = this.min;
    }
    if (this.initial > this.max) {
      this.initial = this.max;
    }
    this.ratingQuestion.answerDisplay.min = this.min.toString();
    this.ratingQuestion.answerDisplay.max = this.max.toString();
    this.ratingQuestion.answerDisplay.default = this.initial.toString();
    this.questionChange.emit(this.ratingQuestion);
  }
}
