<div class="close-cross">
  <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="modaleSchemaAdd">
  <div class="schemaAdd">
    <h2 *ngIf="isCreation">{{ "drugSchema.creation" | translate }}</h2>
    <h2 *ngIf="!isCreation">{{ "drugSchema.edition" | translate }}</h2>
    <form [formGroup]="schemaForm">
      <mat-form-field class="full-w">
        <input matInput type="text" placeholder="{{ 'drugSchema.nameOfTheCycle' | translate }}" formControlName="name" />
        <mat-error>{{ "forms.errors.drugSchema.nameError" | translate }}</mat-error>
      </mat-form-field>
      <div class="full-w">
        <mat-label>{{ "drugSchema.nbrOfDays" | translate }} :</mat-label>
        <input
          matInput
          class="nbrInput"
          type="number"
          formControlName="nbrOfDays"
          step="1"
          min="0"
          max="365"
          (change)="onNbrOfDaysChange()"
        />
      </div>
      <div class="full-w mt-15">
        <h4 class="full-w daysTitle">
          {{ "drugSchema.daysToTake" | translate }}
        </h4>
        <div *ngFor="let eachRow of rowOfDisplayedColumns">
          <table mat-table [dataSource]="[intakeDays]">
            <ng-container *ngFor="let i of eachRow" [matColumnDef]="i">
              <th mat-header-cell *matHeaderCellDef>{{ +i + 1 }}</th>
              <td
                mat-cell
                *matCellDef="let element"
                (click)="onCellClick(i)"
                [ngClass]="element[i] === true ? 'intakeTrue' : 'intakeFalse'"
              ></td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="eachRow"></tr>
            <tr mat-row *matRowDef="let row; columns: eachRow"></tr>
          </table>
        </div>
      </div>
      <div class="full-w flex mt-15" *ngIf="isAuthorizedAddSchema()">
        <mat-checkbox formControlName="saveAsSchema">{{ "drugSchema.saveAsSchema" | translate }}</mat-checkbox>
        <mat-form-field *ngIf="schemaForm.get('saveAsSchema').value === true">
          <mat-label>{{ "drugSchema.visibility" | translate }}</mat-label>
          <mat-select formControlName="forWho">
            <mat-option value="me">{{ "drugSchema.me" | translate }}</mat-option>
            <mat-option value="myOrg">{{ "drugSchema.myOrg" | translate }}</mat-option>
            <mat-option value="global">{{ "drugSchema.global" | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="full-w" *ngIf="schemaForm.get('saveAsSchema').value === true && isSamv2Drug">
        <mat-checkbox formControlName="linkToDrug">{{ "drugSchema.linkToDrug" | translate }}</mat-checkbox>
      </div>
    </form>
    <div class="action-container">
      <button mat-raised-button color="primary" (click)="onCancel()">
        {{ "common.cancel" | translate }}
      </button>
      <button mat-raised-button color="primary" (click)="onSave()">
        {{ "drugSchema.confirm" | translate }}
      </button>
    </div>
  </div>
</div>
