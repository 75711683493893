import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { skipWhile, takeUntil, tap } from "rxjs/operators";
import { CareplanEditorService } from "src/app/careplan-editor/domain/careplan-editor.service";
import { ICareplan } from "src/app/models/careplans.interface";

@Component({
  selector: "app-careplan-pretty-jsonview",
  templateUrl: "./careplan-pretty-jsonview.component.html",
  styleUrls: ["./careplan-pretty-jsonview.component.scss"],
})
export class CareplanPrettyJSONViewComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public careplanTemplate: ICareplan;
  private onDestroy$ = new Subject<void>();

  constructor(public careplanEditorService: CareplanEditorService) {}

  ngOnInit(): void {
    if (this.careplanEditorService.currentCareplanTemplate) {
      this.careplanTemplate = this.careplanEditorService.currentCareplanTemplate;
      this.isLoading = false;
    } else {
      this.careplanEditorService.careplanTemplateReady$
        .pipe(
          skipWhile((value) => value === false),
          takeUntil(this.onDestroy$),
          tap((_ready) => {
            this.careplanTemplate = this.careplanEditorService.currentCareplanTemplate;
            this.isLoading = false;
          })
        )
        .subscribe();
    }
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
