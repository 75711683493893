<h2 mat-dialog-title>
  {{ "prediction.import.title" | translate }}
</h2>
<mat-dialog-content [formGroup]="predictionModelForm">
  <mat-form-field>
    <mat-label>
      {{ "prediction.model.title" | translate }}
    </mat-label>
    <mat-select formControlName="model">
      <mat-option value="topmd">
        {{ "prediction.model.topmd" | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <ui-file-drop
    #uiFileDrop
    [acceptedTypes]="['text/csv']"
    typeErrorTranlationKey="prediction.import.fileErrors.type"
    [maxSize]="5000000"
    sizeErrorTranslationKey="prediction.import.fileErrors.size"
    [hasError]="hasError"
    (chosenFile)="setFile($event)"
  ></ui-file-drop>
</mat-dialog-content>
<mat-dialog-actions class="btn-container">
  <button mat-raised-button color="primary" (click)="save()" [disabled]="isSaving">
    {{ "btn.save" | translate }}
  </button>
  <button mat-raised-button (click)="cancel()" [disabled]="isSaving">
    {{ "btn.cancel" | translate }}
  </button>
</mat-dialog-actions>
