import { Pipe, PipeTransform } from "@angular/core";
import { QuestionQuestionnaire } from "../models/questionnaire.interface";

@Pipe({ name: "isGoodAnswer" })
export class IsGoodAnswerPipe implements PipeTransform {
  transform(answer: unknown, goodAnswers: string[]): boolean {
    if (goodAnswers.includes(answer as string)) {
      return true;
    } else {
      return false;
    }
  }
}

@Pipe({ name: "isGoodAnswerVer2" })
export class IsGoodAnswerVer2Pipe implements PipeTransform {
  transform(answer: unknown, questions: QuestionQuestionnaire[], currentQuestionId: string): boolean {
    const currentQuestion = questions.find((q) => q.linkId === currentQuestionId);
    if (currentQuestion.answerDisplay.goodAnswers.includes(answer as string)) {
      return true;
    } else {
      return false;
    }
  }
}
