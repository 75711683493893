import { Pipe, PipeTransform } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Pipe({
  name: "getActivityName",
})
export class GetActivityNamePipe implements PipeTransform {
  transform(activityFormGroup: UntypedFormGroup, currentLang: string, _lastKeystroke?: string): string {
    const reference: string = activityFormGroup
      .get("reference")
      ?.get("display")
      ?.get("traductions")
      ?.value.find((v) => v.lang === currentLang)?.translateKey;
    const description: string = activityFormGroup
      .get("detail")
      ?.get("description")
      ?.get("traductions")
      ?.value.find((v) => v.lang === currentLang)?.translateKey;
    if (reference && !reference.startsWith("$TR$") && reference !== description && description && !description.startsWith("$TR$"))
      return reference + ` (${description})`;
    if (reference && !reference.startsWith("$TR$")) return reference;
    return "";
  }
}
