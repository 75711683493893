import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import * as moment from "moment";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Base64Helper } from "src/app/helpers/Base64Helper";
import { Tools } from "src/app/helpers/tools";
import { DataType, Filter } from "src/app/models/filter.interface";
import { IObservationDefinition, OComponent } from "src/app/models/observations.interface";
import { PreferenceContext, WidgetFilterParameter } from "src/app/models/preference.interface";
import { ALERT_STATUS } from "src/app/models/rule-alert.interface";
import { RuleAlert } from "src/app/models/rule-alert.model";
import { WidgetName } from "src/app/models/widget.interface";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { ObservationsService } from "src/app/providers/observations.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { RuleAlertService } from "src/app/providers/rule-alert.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { AlertListDataSource } from "./patient-alert-list-datasource";

@Component({
  selector: "app-patient-alert-table",
  templateUrl: "./patient-alert-table.component.html",
  styleUrls: ["./patient-alert-table.component.scss"],
})
export class PatientAlertTableComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<RuleAlert>;
  @ViewChild(MatSort) sort: MatSort;

  @Input() alertLevel: number;
  @Input() widgetName: string;

  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;

  public displayedColumns: string[] = ["name", "date", "values"];
  public dataSource: AlertListDataSource;

  private alerts: RuleAlert[] = [];
  private refreshSub: Subscription;
  private refreshSub2: Subscription;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();
  private definitions$: Subscription;
  public allDefinitions: IObservationDefinition[] = [];

  constructor(
    private ruleAlertService: RuleAlertService,
    private sessionService: SessionService,
    private preferenceService: PreferenceService,
    private userService: UserService,
    private healthcareService: HealthcareserviceService,
    private observationsService: ObservationsService
  ) {}

  ngOnInit(): void {
    this.dataSource = new AlertListDataSource();
    this.initData();
    this.sessionService.refreshServerTraductions.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      // the idea is to make the pipes realize there's something that changed without
      // restarting everything. For that we just need to 'change' one of the
      // pipe variables.
      if (this.allDefinitions) {
        // Cloning will make it appear as if this is a new object
        this.allDefinitions = Tools.clone(this.allDefinitions);
      }
    });
    this.refreshSub = this.sessionService.refreshOrangeAlertWidget.subscribe(() => {
      this.initData();
    });
    this.refreshSub2 = this.sessionService.refreshRedAlertWidget.subscribe(() => {
      this.initData();
    });
  }

  ngOnDestroy(): void {
    this.refreshSub?.unsubscribe();
    this.refreshSub2?.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initData() {
    if (
      !this.sessionService.currentService ||
      !this.healthcareService.availableServices() ||
      this.healthcareService.availableServices().length < 1
    ) {
      return;
    }
    const departmentId = this.sessionService.currentService?.reference;
    let availableServicesRefs: string[] = [departmentId];
    if (departmentId === this.sessionService.allsOption) {
      availableServicesRefs = this.healthcareService.availableServices().map((s) => s.asReference.reference);
    }
    let monitoringIds = [];
    if (this.userService.isMonitoringUser) {
      const current = this.sessionService.currentMonitoringService;
      if (current?.reference === this.sessionService.allsOption) {
        monitoringIds = this.healthcareService.availableMonitoringServices().map((s) => s.asReference.reference);
      } else {
        monitoringIds = current ? [current.reference] : null;
      }
    }

    this.ruleAlertService
      .list(this.alertLevel, availableServicesRefs, monitoringIds, this.widgetName === WidgetName.PATIENT_ALERT_NO_TRANSMISSION)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((ruleAlerts) => {
        this.alerts = ruleAlerts
          .filter((a) => a.alertStatus === ALERT_STATUS.UNPROCESSED)
          .sort((a, b) => (moment(a.date()).isAfter(moment(b.date())) ? -1 : 1));
        this.definitions$?.unsubscribe();
        // There's no need to add the caremateId as a param of listDef since this is the home pages alert tile
        this.definitions$ = this.observationsService
          .listDef()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((defs) => {
            this.allDefinitions = this.keepOnlyUsefulDefinitions(defs, this.alerts);
            this.loadPreferences();
            this.loadDataSource();
          });
      });
  }

  private loadDataSource() {
    this.dataSource.loadData(this.alerts);
    this.initPaginatorAndSort();
  }

  private initPaginatorAndSort() {
    if (!this.table) {
      return;
    }
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
  private keepOnlyUsefulDefinitions(obsDef: IObservationDefinition[], alerts: RuleAlert[]) {
    const allAlertsLoincs: string[] = [];
    alerts.forEach((alert) => {
      alert.valueComponents.forEach((vc) => {
        allAlertsLoincs.push(vc.code.coding[0].code);
      });
    });
    const definitions: IObservationDefinition[] = obsDef.filter((d) => d.components.find((c) => allAlertsLoincs.includes(c.loinc)));
    return definitions;
  }

  /**
   * Preferences
   */
  private updatePreference() {
    this.preferenceService
      .update({
        context: PreferenceContext.WIDGET_ALERTE_LEVEL + this.alertLevel,
        parameters: {
          filters: this.dataSource.getAllFilters(),
        } as WidgetFilterParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  private loadPreferences() {
    this.preferenceService
      .list(PreferenceContext.WIDGET_ALERTE_LEVEL + this.alertLevel)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: WidgetFilterParameter) => {
        if (parameters) {
          // Apply saved filters
          parameters.filters.forEach((filter: Filter) => {
            this.applyFilter(filter);
          });
        }
      });
  }

  public get isNoneData(): boolean {
    return this.dataSource.data.length === 0;
  }

  public getValue(valueComponent: OComponent): string {
    return (
      valueComponent.code.coding[0].display +
      ": " +
      valueComponent.valueQuantity.value +
      " " +
      (valueComponent.valueQuantity.unit ? valueComponent.valueQuantity.unit : "")
    );
  }

  public encodeToB64(str: string): string {
    return Base64Helper.utf8_to_b64(str);
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
    this.updatePreference();
  }

  public clearFilter(): void {
    this.dataSource.clearFilter();
  }
}
