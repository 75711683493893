import { Pipe, PipeTransform } from "@angular/core";
import { IObservation, OComponent } from "../models/observations.interface";

@Pipe({
  name: "getComponentsByHours",
})
export class GetObsValuesByHoursPipe implements PipeTransform {
  transform(time: number, obs: IObservation[], code: string): OComponent[] {
    const obsForThatTime = obs.filter((obs) => new Date(obs.issued).getHours() === time);
    const componentTokeep: OComponent[] = [];

    obsForThatTime.forEach((obs) => {
      obs.component.forEach((comp) => {
        if (comp.code.coding[0].code === code) {
          componentTokeep.push(comp);
        }
      });
    });

    return componentTokeep;
  }
}
