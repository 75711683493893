<div class="create-stepwise-container">
  <div class="cross-icons">
    <button mat-icon-button color="warn" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
    <button mat-icon-button color="primary" (click)="validate()">
      <mat-icon>check</mat-icon>
    </button>
  </div>
  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
    <div>
      <h2>{{ "stepwise.configure.title" | translate }}</h2>
    </div>
    <mat-button-toggle-group style="height: 25px; align-items: center; justify-content: center" [(ngModel)]="timingSelected">
      <mat-button-toggle *ngFor="let timing of timingCodes; let k = index" [value]="k" style="display: flex">{{
        "careplan.timingCodes." + timing | translate
      }}</mat-button-toggle>
    </mat-button-toggle-group>
    <form [formGroup]="form" style="margin-top: 5px">
      <div formArrayName="timings">
        <div [formGroupName]="timingSelected">
          <div formArrayName="drugs">
            <div *ngFor="let d of drugsForm.controls; let i = index">
              <div [formGroupName]="i" style="display: flex; justify-content: space-around">
                <app-drug-server-side-search [isCreation]="true" [formCtrl]="drugsForm.at(i).get('drugName')"></app-drug-server-side-search>
                <mat-form-field class="font-12" style="width: 25%">
                  <mat-select
                    placeholder="{{ 'mydrugs.dose' | translate }}"
                    formControlName="drugQuantity"
                    matTooltip="{{ 'mydrugs.quantityTooltip' | translate }}"
                    matTooltipPosition="right"
                    matTooltipHideDelay="1000"
                  >
                    <mat-option style="width: 75px">
                      <ngx-mat-select-search
                        placeholderLabel="{{ 'mydrugs.dose' | translate }}"
                        formControlName="drugQuantity"
                        [clearSearchInput]="false"
                        hideClearSearchButton="true"
                      >
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let quantity of availableQuantities.get(timingCodes[timingSelected])[i] | async" [value]="quantity">
                      {{ quantity }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: center">
          <button class="stepwiseActionBtn" mat-raised-button color="primary" (click)="onAddDrug()">
            {{ "btn.newDrug" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
