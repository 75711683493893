<div class="loading" *ngIf="saveInProgress">
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div class="accessGroup" *ngIf="!saveInProgress">
  <div *ngIf="mode !== MODES.DISPLAY" [formGroup]="accessGroupForm">
    <div *ngIf="accessGroupForm.contains('name')">
      <mat-form-field>
        <input
          cdkFocusInitial
          type="text"
          matInput
          placeholder="{{ 'accessGroups.name' | translate }}"
          formControlName="name"
          [errorStateMatcher]="matcher"
        />
      </mat-form-field>
      <p
        style="color: red"
        *ngIf="
          accessGroupForm.contains('name') &&
          (accessGroupForm.get('name').dirty || accessGroupForm.get('name').touched) &&
          accessGroupForm.get('name').invalid
        "
      >
        <i>{{ "accessGroups.invalidName" | translate }}</i>
      </p>
    </div>
  </div>
  <h3 *ngIf="mode === MODES.DISPLAY && accessGroup">{{ accessGroup.identifier.label }}</h3>
  <div *ngIf="mode === MODES.CREATE">
    <h4>{{ "accessGroups.createGroupByCopying" | translate }} :</h4>
    <mat-form-field>
      <mat-select (selectionChange)="copyGroup($event.value)">
        <mat-label>{{ "accessGroups.groupToCopy" | translate }}</mat-label>
        <mat-option [value]="undefined"
          ><i>{{ "accessGroups.none" | translate }}</i></mat-option
        >
        <mat-option *ngFor="let item of allAccessGroups" [value]="item">
          {{ item.identifier.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <h4>{{ "groupPermission.permissions" | translate }} :</h4>
  <div *ngIf="canReadPermissions && accessGroup && permissions">
    <app-group-permissions
      [accessGroupId]="accessGroup.identifier.value"
      [(permissions)]="permissions"
      [needCreate]="canCreatePermission && mode !== MODES.DISPLAY"
      [needCheck]="canDeletePermission && mode !== MODES.DISPLAY"
      [needDelete]="canDeletePermission && mode !== MODES.DISPLAY"
      [needTheme]="true"
      [needFilters]="true"
    ></app-group-permissions>
  </div>
</div>
