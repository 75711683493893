import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-two-famodal",
  templateUrl: "./two-famodal.component.html",
  styleUrls: ["./two-famodal.component.scss"],
})
export class TwoFAModalComponent {
  public twofaForm = this.fb.group({
    code: ["", [Validators.required]],
  });

  constructor(
    public dialogRef: MatDialogRef<TwoFAModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { wrongMfa: boolean },
    private fb: UntypedFormBuilder
  ) {}

  public dismiss(): void {
    this.dialogRef.close(null);
  }

  public onLogIn(): void {
    this.dialogRef.close(this.twofaForm.get("code").value);
  }
}
