import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { I18nModule } from "src/app/i18n.module";
import { PipesModule } from "src/app/pipes/pipes.module";
import { TimingEditorComponent } from "./timing-editor.component";

@NgModule({
  declarations: [TimingEditorComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatOptionModule,
    ReactiveFormsModule,
    TranslateModule,
    I18nModule,
    FormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatIconModule,
    NgxMaterialTimepickerModule,
    MatSelectModule,
    MatTooltipModule,
    NgxMatSelectSearchModule,
    PipesModule,
    MatButtonModule,
    MatCheckboxModule,
  ],
  exports: [TimingEditorComponent],
})
export class TimingEditorModule {}
