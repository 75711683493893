import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { FileLogger } from "src/app/helpers/fileLogger";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { Tools } from "src/app/helpers/tools";
import { IAccessGroup } from "src/app/models/accessGroup.interface";
import { Account } from "src/app/models/account.interface";
import { AccessService } from "src/app/providers/access.service";
import { AccessGroupApiService } from "src/app/providers/api/accessGroup-api.service";
import { UserService } from "src/app/providers/user.service";
import { AccessGroupDialogComponent } from "./access-group-dialog/access-group-dialog.component";
import { PractitionerAccessGroupsDialogComponent } from "./practitioner-access-groups-dialog/practitioner-access-groups-dialog.component";
import { RoutesService } from "./routesServices.service";

@Component({
  selector: "app-access-groups-page",
  templateUrl: "./access-groups-page.component.html",
  styleUrls: ["./access-groups-page.component.scss"],
})
export class AccessGroupsPageComponent implements OnInit, OnDestroy {
  public pageLoaded = false;
  public filteredAccessGroups: IAccessGroup[] = [];

  public methods = ["POST", "GET", "PUT", "DELETE"];
  public filters: { theme: string; method: string; route: string; group: string } = { theme: "", method: "", route: "", group: "" };

  // All permissions of this page
  public canCreateGroup = false;
  public canReadGroups = false;
  public canUpdateGroup = false;
  public canDeleteGroup = false;
  public canReadPermissions = false;

  private nonFilteredAccessGroups: IAccessGroup[] = [];
  private ownAccount: Account;

  /** Subject that emits when the component has been destroyed. */
  // tslint:disable-next-line: variable-name
  private onDestroy$ = new Subject<void>();

  constructor(
    private accessService: AccessService,
    private accessGroupApiService: AccessGroupApiService,
    private routesServices: RoutesService, // Important ! Needed for service injection, do not remove !
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService
      .account()
      .pipe(
        takeUntil(this.onDestroy$),
        filter((account) => account !== undefined)
      )
      .subscribe((account) => {
        this.ownAccount = account;
        this.checkUsersPermissions(account);
        this.init();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private async init(): Promise<void> {
    const loadGroupsSuccess = await this.loadAccessGroups();
    if (!loadGroupsSuccess) {
      return;
    }
    const loadPermissionsSuccess = await this.loadGroupsPermissions();
    if (!loadPermissionsSuccess) {
      return;
    }
  }

  private checkUsersPermissions(account: Account): void {
    this.canCreateGroup = this.userService.isAuthorizedSync(account, this.accessGroupApiService.createRoutes[0], "POST");
    this.canReadGroups = this.userService.isAuthorizedSync(account, this.accessGroupApiService.readRoutes[0], "GET");
    this.canUpdateGroup = this.userService.isAuthorizedSync(account, this.accessGroupApiService.updateRoutes[0], "PUT");
    this.canDeleteGroup = this.userService.isAuthorizedSync(account, this.accessGroupApiService.deleteRoutes[0], "DELETE");
    this.canReadPermissions = this.userService.isAuthorizedSync(account, this.accessGroupApiService.readRoutes[1], "GET");
  }

  /**
   * Loads all the access groups
   * @returns true if it was loaded without error, false otherwise
   */
  private async loadAccessGroups(): Promise<boolean> {
    if (!this.canReadGroups) {
      this.pageLoaded = true;
      return false;
    }
    this.pageLoaded = false;
    try {
      this.nonFilteredAccessGroups = await this.accessService.readAccessGroups();
      return true;
    } catch (err) {
      FileLogger.error("AccessGroupsPageComponent", "Error while loading access groups: ", err);
      this.snackBar
        .open(`❗${this.translateService.instant("page.accessGroups.loadingGroupsError")}`, null, { duration: 4000 })
        .afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe();
      this.pageLoaded = true;
      return false;
    }
  }

  /**
   * Loads the permissions of the non-filtered access group's list
   * @returns true if it was loaded without error, false otherwise
   */
  private async loadGroupsPermissions(): Promise<boolean> {
    if (!this.canReadPermissions) {
      this.pageLoaded = true;
      return false;
    }
    try {
      await this.accessService.loadGroupsPermissions(this.nonFilteredAccessGroups);
      this.filteredAccessGroups = Tools.clone(this.nonFilteredAccessGroups);
      // this.filterGroups();
      this.pageLoaded = true;
      return true;
    } catch (err) {
      FileLogger.error("AccessGroupsPageComponent", "Error while loading access groups' permissions: ", err);
      this.snackBar
        .open(`❗${this.translateService.instant("page.accessGroups.loadingPermissionsError")}`, null, { duration: 4000 })
        .afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe();
      this.pageLoaded = true;
      return false;
    }
  }

  public clearFilters(): void {
    this.filters = { theme: "", method: "", route: "", group: "" };
    this.filterGroups();
  }

  /**
   * Filters the access groups are their permissions displayed according to:
   * - access group's name
   * - permissions' theme
   * - mermussions' method (GET, POST...)
   */
  public filterGroups(): void {
    const nonFiltered = Tools.clone(this.nonFilteredAccessGroups);
    this.filteredAccessGroups = nonFiltered.filter((group) => {
      if (this.filters.group && !group.identifier.label?.toLowerCase().includes(this.filters.group.toLowerCase())) {
        return false;
      }
      if (!(this.filters.theme || this.filters.method)) {
        return true;
      }
      const permsByTheme = group.permsByTheme.filter(
        (p) => !this.filters.theme || p.theme.toLowerCase().includes(this.filters.theme.toLowerCase())
      );
      if (permsByTheme.length === 0) {
        return false;
      }
      group.permsByTheme = permsByTheme;
      let foundMethod = false;
      const permsByMethod = [];
      for (const pTheme of group.permsByTheme) {
        const perms = pTheme.permissions.filter((p) => !this.filters.method || p.method === this.filters.method);
        if (perms.length > 0) {
          permsByMethod.push(pTheme);
          foundMethod = true;
        }
        pTheme.permissions = perms;
      }
      group.permsByTheme = permsByMethod;
      if (!foundMethod) {
        return false;
      }
      return true;
    });
  }

  /**
   * Opens the access group modal. Reloads this page data when an access group is created
   * @returns
   */
  public createAccessGroup(): void {
    if (!this.canCreateGroup) {
      return;
    }
    this.dialog
      .open(AccessGroupDialogComponent, {
        data: {
          mode: FORMS_MODE.CREATE,
          allAccessGroups: this.nonFilteredAccessGroups,
        },
        disableClose: false,
        maxWidth: "90vw",
        width: "70%",
      })
      .afterClosed()
      .subscribe((result: { success: boolean; data: IAccessGroup }) => {
        if (result && result.success) {
          this.init();
        }
      });
  }

  /**
   * Opens the access group modal. Reloads this page data when an access group is updated
   * @param groupId (string) the identifier of the group we want to update
   * @returns
   */
  public editAccessGroup(groupId: string): void {
    if (!this.canUpdateGroup) {
      return;
    }
    const group = this.nonFilteredAccessGroups.find((g) => g.identifier.value === groupId);
    this.dialog
      .open(AccessGroupDialogComponent, {
        data: {
          mode: FORMS_MODE.UPDATE,
          allAccessGroups: this.nonFilteredAccessGroups,
          accessGroup: group,
        },
        disableClose: false,
        maxWidth: "90vw",
        width: "70%",
      })
      .afterClosed()
      .subscribe((result: { success: boolean; data: IAccessGroup }) => {
        if (result && result.success) {
          this.init();
        }
      });
  }

  /**
   * Show a confirmation modal before deleting an access group
   * @param groupId
   * @returns
   */
  public deleteAccessGroup(groupId: string): void {
    if (!this.canDeleteGroup) {
      return;
    }
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          message: this.translateService.instant("page.accessGroups.confirmDeleteGroup"),
          type: ConfirmationDialogType.CONFIRM,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (yes) => {
        if (yes) {
          const group = this.nonFilteredAccessGroups.find((g) => g.identifier.value === groupId);
          try {
            await this.accessService.deleteAccessGroup(group._id);
            this.init();
          } catch (err) {
            FileLogger.error("AccessGroupsPageComponent", "Error while deleting access group: ", err);
            this.snackBar
              .open(`❗${this.translateService.instant("page.accessGroups.deleteGroupError")}`, null, { duration: 5000 })
              .afterDismissed()
              .pipe(takeUntil(this.onDestroy$))
              .subscribe();
          }
        }
      });
  }

  public showPractitionersAccessGroups(): void {
    if (!this.canReadGroups) {
      return;
    }
    this.dialog
      .open(PractitionerAccessGroupsDialogComponent, {
        data: {
          mode: FORMS_MODE.UPDATE,
          allAccessGroups: this.nonFilteredAccessGroups,
          ownAccount: this.ownAccount,
        },
        disableClose: false,
        maxWidth: "90vw",
        width: "70%",
      })
      .afterClosed()
      .subscribe();
  }
}
