<span #focus></span>
<mat-card class="widget">
  <mat-toolbar color="primary">
    <div *ngIf="!isInsurance">
      <button mat-icon-button *ngIf="!isExpand" matTooltip="{{ 'page.organizations.btn.expand' | translate }}" (click)="onExpand()">
        <mat-icon>arrow_right</mat-icon>
      </button>
      <button mat-icon-button *ngIf="isExpand" matTooltip="{{ 'page.organizations.btn.collapse' | translate }}" (click)="onExpand()">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
    </div>
    <div class="spacer-for-insurance" *ngIf="isInsurance"></div>
    <button mat-icon-button class="fs-16" (click)="onExpand()">
      {{
        organization?.name
          ? organization.name + " [" + organization.identifier[0].value + "]"
          : " [" + organization?.identifier[0]?.value + "]"
      }}
    </button>

    <span class="spacerBetween"></span>

    <button mat-icon-button *ngIf="isAdmin" matTooltip="{{ 'page.organizations.btn.updOrg' | translate }}" (click)="onEditOrganization()">
      <mat-icon>create</mat-icon>
    </button>

    <button
      mat-icon-button
      *ngIf="!isInsurance"
      matTooltip="{{ 'page.organizations.btn.newServ' | translate }}"
      (click)="onCreateService()"
    >
      <mat-icon>add</mat-icon>
    </button>
    <div class="spacer-for-insurance" *ngIf="isInsurance"></div>
  </mat-toolbar>

  <mat-card-content *ngIf="isExpand">
    <table mat-table matSort matSortDirection="asc" matSortActive="name" [dataSource]="dataSource">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "page.organizations.table.name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.identifier[0].label }}
        </td>
      </ng-container>

      <!-- Rererence Column -->
      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "page.organizations.table.reference" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.identifier[0].value }}
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.action" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            class="custom-small-btn custom-small-btn-width warn"
            mat-icon-button
            matTooltip="{{ 'page.organizations.btn.updServ' | translate }}"
            (click)="onEditService(element)"
          >
            <mat-icon class="custom-icon-small baseline-align">create</mat-icon>
          </button>

          <button
            class="custom-small-btn custom-small-btn-width warn"
            mat-icon-button
            matTooltip="{{ 'page.organizations.btn.viewServ' | translate }}"
            (click)="onDisplayService(element)"
          >
            <mat-icon class="custom-icon-small baseline-align">visibility</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-card-content>
  <div *ngIf="loading" class="loading">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</mat-card>
