import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MatTableDataSourceBackendExtended } from "src/app/helpers/datasource-backend";
import { DataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { IQuizListInfo } from "src/app/models/questionnaire.interface";
import { UseContext } from "src/app/models/sharedModels.model";
import { QuestionnairesService } from "src/app/providers/questionnaires.service";

export class QuizListDataSource extends MatTableDataSourceBackendExtended<IQuizListInfo> {
  constructor(private questionnaireApi: QuestionnairesService) {
    super();
  }

  public data(params: DataSourceBackendParam): Observable<IQuizListInfo[]> {
    return this.questionnaireApi.findSpecificQuestionnaire(params).pipe(
      map((list: IQuizListInfo[]) => {
        for (const q of list) {
          q.useContext?.sort(UseContext.useContextSortAccess);
        }
        return list;
      })
    );
  }
}
