import { Codes } from "./codes.interface";
import { Entity } from "./entity.interface";
import { Identifier } from "./identifier.interface";
import { Participant } from "./participant.interface";
import { Reference } from "./reference.interface";
import { UnknowPatientData } from "./UnknownPatientData.interface";

export const SYSTEM_JITSI = "meetjitsi";
export const SYSTEM_JITSI_PRACTITIONER = "meetjitsi_practitioner";

export interface IAppointment extends Entity {
  createdBy?: string;
  resourceType: string;
  identifier: Identifier[];
  status: string; // "booked" for user appointement, set something else for hospital appointement
  description: string;
  participant: Participant[];
  comment?: string;
  patientComment?: string; // NOT FHIR format: user specific comment for this appointment
  dateSeenByPatient?: string;
  dateSeenByPatientHistory?: string[];
  start: string; // moment start date
  end: string; // moment end date
  serviceType?: Codes;
  appointmentType?: Codes;
  reason?: Codes;
  supportingInformation?: Reference[];
  serviceCategory?: Codes;
  specialty?: Codes[];
  indication?: Reference[];
  minutesDuration?: string;
  unknowPatientData?: UnknowPatientData;
  billingCode?: string;
  notificationStatus?: NOTIF_STATUS;
  minutesDelay?: number;
}

export const enum NOTIF_STATUS {
  NEW = 1,
  UPDATE = 2,
  CANCELLED = 3,
  SENDED = 4,
}
