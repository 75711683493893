import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { first, map, skipWhile, startWith, takeUntil } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Healthcareservice } from "src/app/models/healthcareservice.model";
import { IKnowledgeBase } from "src/app/models/knowledge.interface";
import { Reference } from "src/app/models/sharedModels.model";
import { KnowledgeService } from "src/app/providers/knowledge.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";

interface ServiceDisplay {
  serviceRef: Reference;
  displayWithOrg: string;
}

@Component({
  selector: "app-knowledge-services",
  templateUrl: "./knowledge-services.component.html",
  styleUrls: ["./knowledge-services.component.scss"],
})
export class KnowledgeServicesComponent implements OnDestroy {
  @ViewChild("serviceInput") serviceInput: ElementRef<HTMLInputElement>;
  @Input() set knowledge(k: IKnowledgeBase) {
    this._knowledge = k;
    this.init();
  }
  @Input() readonly: boolean;
  public servicesDisplay: ServiceDisplay[] = [];
  public servicesFormCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredServices: Observable<ServiceDisplay[]>;
  public selectedServices: ServiceDisplay[] = [];
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public updating = false;
  public isAdmin = false;
  private remainingServicesDisplay: ServiceDisplay[] = [];
  private _knowledge: IKnowledgeBase;

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private sessionService: SessionService,
    private knowledgeService: KnowledgeService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {
    if (this.sessionService.currentService && this.userService.ownOrganization) {
      this.init();
    } else {
      this.sessionService.servicesSetupWatch
        .pipe(
          skipWhile(() => !this.userService.ownOrganization),
          first()
        )
        .subscribe(() => {
          this.init();
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public addService(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    const i = this.remainingServicesDisplay.findIndex((s) => s.displayWithOrg.includes(value));

    this.putServiceInKnowledge(i);

    // Clear the input value
    event.chipInput!.clear();

    this.servicesFormCtrl.setValue(null);
  }

  public removeService(service: ServiceDisplay): void {
    const index = this.selectedServices.indexOf(service);

    if (index >= 0) {
      this.selectedServices.splice(index, 1);
      this.remainingServicesDisplay.push(service);
    }
  }

  public selectedService(event: MatAutocompleteSelectedEvent): void {
    const display = event.option.viewValue;
    if (display) {
      const i = this.remainingServicesDisplay.findIndex((s) => s.displayWithOrg === display);
      this.putServiceInKnowledge(i);
    }
    this.serviceInput.nativeElement.value = "";
    this.servicesFormCtrl.setValue(null);
  }

  private init(): void {
    if (!this._knowledge || !this.sessionService.currentService || !this.userService.ownOrganization) {
      return;
    }
    this.isAdmin = this.sessionService.isAdmin();
    this.initKnowledgeServices();
    this.filteredServices = this.servicesFormCtrl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterServices(value || ""))
    );
  }

  private initKnowledgeServices(): void {
    this.servicesDisplay = this.getServicesDisplays();
    if (this._knowledge.healthcareservice?.length) {
      const knowledgeServices = this._knowledge.healthcareservice?.map((s) => s.reference);
      this.selectedServices = this.servicesDisplay.filter((s) => !knowledgeServices || knowledgeServices.includes(s.serviceRef.reference));
      this.remainingServicesDisplay = this.servicesDisplay.filter(
        (s) => !knowledgeServices || !knowledgeServices.includes(s.serviceRef.reference)
      );
    } else {
      this.remainingServicesDisplay = this.servicesDisplay.slice();
    }
  }

  private _filterServices(value: string | ServiceDisplay): ServiceDisplay[] {
    if (!value) {
      return this.remainingServicesDisplay.slice();
    }
    const filterValue = typeof value === "string" ? value.toLowerCase() : "";
    return this.remainingServicesDisplay.filter((service) => service.displayWithOrg.toLowerCase().includes(filterValue));
  }

  private getServicesDisplays(): ServiceDisplay[] {
    const allServices = this.userService.isMonitoringUser
      ? this.userService.allMonitoringServices?.map(this.createServiceDisplay)
      : this.userService.allServices?.map(this.createServiceDisplay);
    return allServices;
  }

  private createServiceDisplay = (service: Healthcareservice) => {
    const display: ServiceDisplay = {
      serviceRef: service.asReference,
      displayWithOrg: (service.organizationName ? service.organizationName : service.providedBy?.reference) + " - " + service.serviceName,
    };
    return display;
  };

  private putServiceInKnowledge(i: number): void {
    if (i >= 0) {
      this.selectedServices.push(this.remainingServicesDisplay[i]);
      this.remainingServicesDisplay.splice(i, 1);
    }
  }

  public updateKnowledge(): void {
    this.updating = true;
    const isDraft = this._knowledge.publicationDate ? false : true;
    if (isDraft || this.sessionService.isAdmin()) {
      const selected = this.selectedServices.map((s) => s.serviceRef);
      const services =
        selected && selected.length > 0
          ? selected
          : [
              {
                reference: "all",
                display: "Available for all services",
              },
            ];
      this.knowledgeService
        .updateServices(this._knowledge.identifier.value, services)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (_k) => {
            this._knowledge.healthcareservice = services;
            this.snackBar.open(this.translateService.instant("common.saveSuccess"), undefined, {
              duration: 3000,
              horizontalPosition: "center",
              verticalPosition: "bottom",
            });
          },
          (err) => FileLogger.error("KnowledgeServicesComponent", "updateKnowledge", err),
          () => {
            this.updating = false;
          }
        );
    } else {
      this.updating = false;
    }
  }
}
