import { Pipe, PipeTransform } from "@angular/core";
import { ObservationDefinitionComponent } from "../models/observations.interface";

@Pipe({
  name: "findObsDefComponentsByLoinc",
})
export class FindObsDefComponentsByLoincPipe implements PipeTransform {
  transform(components: ObservationDefinitionComponent[], loinc: string): ObservationDefinitionComponent {
    return components.find((component) => component.loinc === loinc);
  }
}
