import { SelectionModel } from "@angular/cdk/collections";
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, forkJoin } from "rxjs";
import { first, skipWhile, takeUntil } from "rxjs/operators";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { PatientTeleconsultationDialogComponent } from "src/app/components/patient-teleconsultations-dialog/patient-teleconsultations-dialog.component";
import { TeleConsultationDialogComponent } from "src/app/components/tele-consultation-dialog/tele-consultation-dialog.component";
import { Base64Helper } from "src/app/helpers/Base64Helper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { HelpData } from "src/app/helpers/helpData";
import { SMS_TYPE } from "src/app/models/UnknownPatientData.interface";
import { Appointment } from "src/app/models/appointment.model";
import { DataType, Filter } from "src/app/models/filter.interface";
import { PreferenceContext, TableParameter } from "src/app/models/preference.interface";
import { AppointmentService } from "src/app/providers/appointment.service";
import { CareplansService } from "src/app/providers/careplans.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PatientService } from "src/app/providers/patient.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { TeleconsultationDataSource } from "./teleconsultation-list-datasource";

@Component({
  selector: "app-teleconsultation-page",
  templateUrl: "./teleconsultation-page.component.html",
  styleUrls: ["./teleconsultation-page.component.scss"],
})
export class TeleconsultationsPageComponent implements OnInit, AfterViewInit, OnDestroy {
  public displayedColumns: string[] = ["select", "start", "end", "participant", "patient", "object", "dateSeenByPatient", "action"];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<Appointment>;

  public dataSource: TeleconsultationDataSource;
  public selection = new SelectionModel<Appointment>(true, []);
  public currentPageSize: number;
  public allAppointements: Appointment[] = [];
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public has2FA = false;
  public skip2FA = false;
  public isAllServices = true;
  public minutesDelay = 15;
  public forceSelected = false;
  public filters: Filter[] = [];
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    private appService: AppointmentService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private patientService: PatientService,
    private userService: UserService,
    private router: Router,
    public sessionService: SessionService,
    private healthcareService: HealthcareserviceService,
    public helpData: HelpData,
    private careplanService: CareplansService,
    private preferenceService: PreferenceService
  ) {
    if (
      this.sessionService.currentService &&
      this.userService.ownOrganization &&
      (this.healthcareService.availableServices()?.length > 0 || this.healthcareService.availableMonitoringServices().length > 0)
    ) {
      this.loadPreferences();
      this.setupServicesWatch();
    } else {
      this.sessionService.servicesSetupWatch
        .pipe(
          skipWhile(() => !this.userService.ownOrganization),
          first()
        )
        .subscribe(() => {
          this.loadPreferences();
          this.setupServicesWatch();
        });
    }
    this.initData();
    this.sessionService.refreshTeleconsultationsDataList.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.initData();
    });
  }

  ngOnInit(): void {
    this.userService
      .account()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((account) => {
        this.has2FA = account.mfaActive;
      });
    this.dataSource = new TeleconsultationDataSource();
  }

  ngAfterViewInit(): void {
    this.initPaginatorAndSort();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setupServicesWatch() {
    // This is a special case because, when its a monitoring user, we cannot
    // allow creation of teleconsultation when EITHER the normal services OR the
    // monitoring services are in "all" mode
    this.setupServices();
    this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.setupServices();
    });
    if (this.userService.isMonitoringUser) {
      this.sessionService.refreshCurrentMonitService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.setupServices();
      });
    }
  }

  private setupServices() {
    if (!this.sessionService.currentService || (this.userService.isMonitoringUser && !this.sessionService.currentMonitoringService)) {
      this.isAllServices = true;
      return;
    }
    if (this.userService.isMonitoringUser) {
      this.isAllServices = this.sessionService.isAllServices(true) || this.sessionService.isAllServices(false);
    } else {
      this.isAllServices = this.sessionService.isAllServices(false);
    }
  }

  private initData() {
    this.userService
      .isAuthorized("appointmentsPractitioner", "GET")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isAuth) => {
        if (isAuth) {
          this.appService
            .list()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((app) => {
              this.allAppointements = this.appService.onlyFuturActiveApp(app);
              this.dataSource.loadData(this.allAppointements);
            });
        }
      });
  }

  private initPaginatorAndSort() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((page) => {
      if (this.currentPageSize !== page.pageSize) {
        this.updatePreference();
      }
    });
    this.loadPreferences();
  }

  public applyFilter(filter: Filter): void {
    this.forceSelected = false;
    this.dataSource.setFilter(filter);
    this.updatePreference();
  }

  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public delete(app: Appointment): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          message: this.translateService.instant("common.removeForEverAsk"),
          type: ConfirmationDialogType.CONFIRM,
        },
      })
      .afterClosed()
      .subscribe((yes) => {
        if (yes) {
          this.appService.deleteApp(app).subscribe(() => {
            this.initData();
            const msg = this.translateService.instant("common.success");
            this.snackbar.open(msg, "ok", { duration: 3000 });
          });
        }
      });
  }

  public onStartVideoCall(appointment: Appointment): void {
    if (!this.has2FA && !this.skip2FA) {
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      appointment,
    };
    this.dialog.open(TeleConsultationDialogComponent, dialogConfig);
  }

  public async update(app: Appointment): Promise<void> {
    let patientUser = null;
    if (app.isNotUnknowPatient()) {
      const patientId = app.participantPatientRef.reference;
      patientUser = await this.patientService.getPatientUser(patientId).toPromise();
    }
    this.dialog.open(PatientTeleconsultationDialogComponent, {
      data: {
        appointment: app,
        patientUser,
        mode: FORMS_MODE.UPDATE,
        fromPatientWidget: app.isNotUnknowPatient(),
      },
    });
  }

  /**
   * Show confirmation modal before, change smsMailSent boolean and smsType
   */
  public resendSmsMail(app: Appointment): void {
    app.unknowPatientData.smsMailSent = false;
    app.unknowPatientData.smsType = SMS_TYPE.NEW;
    this.appService
      .updateVisioAppointement(app)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.snackbar.open(this.translateService.instant("common.success"), undefined, {
          duration: 3000,
        });
      });
  }

  public goToPatient(app: Appointment): void {
    if (app.isNotUnknowPatient()) {
      const patientId = app.participantPatientRef.reference;
      const b64Id = Base64Helper.utf8_to_b64(patientId);
      this.router.navigateByUrl(`/patient;id=${b64Id}`, {
        state: undefined,
      });
    } else {
      return;
    }
  }

  public openVideoCallHelp(): void {
    this.dialog.open(GlobalHelpDialogComponent, {
      data: { slides: this.helpData.teleconsultationsPageHelp },
      disableClose: true,
    });
  }

  public masterToggle(): void {
    this.dataSource
      .connect()
      .pipe(first())
      .subscribe((rows) => {
        if (this.selection.hasValue() || this.forceSelected) {
          this.selection.clear();
          this.forceSelected = false;
        } else {
          this.selection.select(...rows);
        }
      });
  }

  public updateAppointmentsWithDelay(): void {
    if (this.selection.selected.length) {
      const appUpdates: Observable<Appointment>[] = [];
      this.selection.selected.forEach((appointment) => {
        appointment.minutesDelay = this.minutesDelay;
        appUpdates.push(this.appService.updateVisioAppointement(appointment));
      });

      forkJoin(appUpdates).subscribe(
        () => {
          this.snackbar.open(`✅ ${this.translateService.instant("common.success")}`, undefined, {
            duration: 3000,
          });
        },
        (err) => {
          FileLogger.error("TeleconsultationsPageComponent", "Error while updating appointments: ", err);
        }
      );
    }
  }

  private loadPreferences() {
    this.preferenceService
      .list(PreferenceContext.TELECONSULTATIONS_PAGE)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: TableParameter) => {
        if (parameters) {
          if (parameters?.filters?.length) {
            // Apply saved filters
            parameters.filters.forEach((filter: Filter) => {
              this.applyFilter(filter);
            });
          }
          // Set page size from preference
          this.currentPageSize = parameters.itemsPerPage;
        }
      });
  }

  private updatePreference() {
    this.preferenceService
      .update({
        context: PreferenceContext.TELECONSULTATIONS_PAGE,
        parameters: {
          filters: this.dataSource?.getAllFilters(),
          itemsPerPage: this.paginator.pageSize,
        } as TableParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filters = this.dataSource?.getAllFilters();
      });
  }
}
