import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ICountry } from "../models/country.interface";
import { CountriesApiService } from "./api/countries-api.service";

@Injectable({
  providedIn: "root",
})
export class CountriesService {
  constructor(private countriesApiService: CountriesApiService) {}

  public list(code?: string): Observable<ICountry[]> {
    return this.countriesApiService.list(code);
  }
}
