import { Injectable } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { IQuantity } from "../models/sharedInterfaces";
import { IVitalProfileDefinition } from "../models/vitalProfileDefinition.interface";

@Injectable()
export class VitalSignControlService {
  public toFormGroup(definition: IVitalProfileDefinition, currentVitalSign: IQuantity): UntypedFormGroup {
    const group = {};

    if (definition.type === "array") {
      const formGroup = {};

      definition.columns.forEach((col, index) => {
        definition.rows.forEach((row, i) => {
          formGroup[col.coding.code + "-" + i] = new UntypedFormControl(
            currentVitalSign?.valueArray?.length ? currentVitalSign?.valueArray[i][index] : null,
            [Validators.min(definition.min), Validators.max(definition.max)]
          );
        });
      });
      group[definition.code] = new UntypedFormGroup(formGroup);
    }
    return new UntypedFormGroup(group);
  }
}
