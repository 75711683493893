<div class="loading" *ngIf="loading">
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
  {{ "common.loading" | translate }}
</div>

<div class="knowledgeListPage" *ngIf="!loading">
  <div class="toolbar">
    <div class="left">
      <!-- GLOBAL SEARCH -->
      <mat-form-field>
        <mat-label><span class="fa fa-search mr-5"></span>{{ "itemFilter.search" | translate }}</mat-label>
        <input type="search" matInput #searchInput autocomplete="off" />
        <button
          type="button"
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="searchInput.value = ''"
          [style.visibility]="searchInput.value ? 'visible' : 'hidden'"
          #searchClearBtn
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-checkbox class="search-checkbox" #checkbox [(ngModel)]="includeShortDesc">{{
        "page.knowledgeList.search-checkbox" | translate
      }}</mat-checkbox>
    </div>

    <div class="spacer"></div>

    <input
      type="file"
      class="file-input"
      accept=".json,application/json"
      (change)="onKnowledgeFileSelected($event.target.files)"
      #fileUpload
    />

    <div
      *ngIf="!visualization"
      [matTooltip]="canCreateKnowledge ? ('page.knowledgeList.btn.add' | translate) : ('api.errors.ACCESS DENIED' | translate)"
    >
      <button mat-stroked-button (click)="fileUpload.click()" [disabled]="!canCreateKnowledge">
        <i class="fas fa-upload fa-md"></i>
        {{ "page.knowledgeList.importKnowledge" | translate }}
      </button>
    </div>

    <button mat-flat-button color="warn" *ngIf="isFiltered" (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
      <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
    </button>
    <button mat-stroked-button *ngIf="!isFiltered" (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
      <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
    </button>
    <div
      *ngIf="!visualization"
      class="right"
      [matTooltip]="canCreateKnowledge ? ('page.knowledgeList.btn.add' | translate) : ('api.errors.ACCESS DENIED' | translate)"
    >
      <button mat-stroked-button [disabled]="!canCreateKnowledge" (click)="addKnowledge()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <app-filters-display [filters]="filters" (removeFilter)="applyFilter($event)"> </app-filters-display>

  <mat-card class="mat-elevation-z0 pt-0">
    <!-- LOADER MANAGEMENT -->
    <!-- The loader has been disactivated because loading id too fast  -->
    <mat-spinner class="m-auto" *ngIf="(dataSource.loading$ | async) === true"></mat-spinner>

    <mat-table
      [style.visibility]="(dataSource.loading$ | async) ? 'hidden' : 'visible'"
      class="mat-elevation-z1"
      [dataSource]="dataSource"
      matSort
      matSortActive="term"
      matSortDirection="asc"
      cdkDropList
      [id]="dropListId"
      [cdkDropListData]="dataSource"
      [cdkDropListConnectedTo]="dropListConnectedTo"
      [cdkDropListDisabled]="!draggable"
      [cdkDropListSortingDisabled]="dropListSortingDisabled"
    >
      <ng-container matColumnDef="grip" *ngIf="draggable">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let knowledge">
          <mat-icon class="fa-icon" fontSet="fas" fontIcon="fa-grip-vertical"></mat-icon>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="public">
        <!-- @TODO add mat-sort-header to mat-header-cell after back-end sorting implementation -->
        <mat-header-cell *matHeaderCellDef>{{ "page.knowledgeList.public" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let knowledge">
          <i *ngIf="knowledge.public" class="fas fa-lock-open"></i>
          <i *ngIf="!knowledge.public" class="fas fa-lock"></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="term">
        <mat-header-cell *matHeaderCellDef mat-sort-header="snomedReference.display"
          >{{ "page.knowledgeList.term" | translate }}
          <app-item-filter
            fieldName="{{ 'page.knowledgeList.term' | translate }}"
            [propertyName]="filtersPropertyNames.term"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter(filtersPropertyNames.term)"
            [translateFieldKey]="'page.knowledgeList.term'"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let knowledge">{{ knowledge.term }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="reference">
        <mat-header-cell *matHeaderCellDef mat-sort-header="snomedReference.reference"
          >{{ "page.knowledgeList.reference" | translate }}
          <app-item-filter
            fieldName="{{ 'page.knowledgeList.reference' | translate }}"
            [propertyName]="filtersPropertyNames.reference"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter(filtersPropertyNames.reference)"
            [translateFieldKey]="'page.knowledgeList.reference'"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let knowledge">{{ knowledge.reference }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef
          >{{ "page.knowledgeList.category" | translate }}
          <app-item-filter
            fieldName="{{ 'page.knowledgeList.category' | translate }}"
            [propertyName]="filtersPropertyNames.category"
            [dataType]="dataTypeChoice"
            [data]="dataSource.data"
            [defaultValue]="getFilter(filtersPropertyNames.category)"
            translatePrefix="knowledgebase.category"
            [translateFieldKey]="'page.knowledgeList.category'"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell class="duration-cell" *matCellDef="let knowledge">{{ knowledge.category | knowledgeCategory }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef
          >{{ "page.knowledgeList.type" | translate }}
          <app-item-filter
            fieldName="{{ 'page.knowledgeList.type' | translate }}"
            [propertyName]="filtersPropertyNames.type"
            [dataType]="dataTypeChoice"
            [data]="dataSource.data"
            [defaultValue]="getFilter(filtersPropertyNames.type)"
            translatePrefix="knowledgebase.doccategory"
            [translateFieldKey]="'page.knowledgeList.type'"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell class="duration-cell" *matCellDef="let knowledge">
          {{ "knowledgebase.doccategory." + knowledge.type | translate }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="organization.display">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >{{ "page.knowledgeList.organization" | translate }}
          <app-item-filter
            fieldName="{{ 'page.knowledgeList.organization' | translate }}"
            [propertyName]="filtersPropertyNames.organization"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter(filtersPropertyNames.organization)"
            [translateFieldKey]="'page.knowledgeList.organization'"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell class="duration-cell" *matCellDef="let knowledge">{{ knowledge.organization.display }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="author.display">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >{{ "page.knowledgeList.author" | translate }}
          <app-item-filter
            fieldName="{{ 'page.knowledgeList.author' | translate }}"
            [propertyName]="filtersPropertyNames.author"
            [dataType]="dataTypeText"
            [translateFieldKey]="'page.knowledgeList.author'"
            [defaultValue]="getFilter(filtersPropertyNames.author)"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell class="duration-cell" *matCellDef="let knowledge">{{ knowledge.author.display }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="medias">
        <mat-header-cell *matHeaderCellDef>{{ "page.knowledgeList.medias" | translate }}</mat-header-cell>
        <mat-cell class="duration-cell" *matCellDef="let knowledge">
          <span *ngFor="let media of knowledge.medias | knowledgeMedias"> {{ media }} &nbsp; </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="creation">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "page.knowledgeList.creationDate" | translate }}
          <app-item-filter
            fieldName="{{ 'page.knowledgeList.creationDate' | translate }}"
            [propertyName]="filtersPropertyNames.creation"
            [dataType]="dataTypeDate"
            [translateFieldKey]="'page.knowledgeList.creationDate'"
            [defaultValue]="getFilter(filtersPropertyNames.creation)"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell class="duration-cell" *matCellDef="let knowledge">{{ knowledge.creationDate | date : "dd/MM/yyyy" }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="publicationDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >{{ "page.knowledgeList.publicationDate" | translate }}
          <app-item-filter
            fieldName="{{ 'page.knowledgeList.publicationDate' | translate }}"
            [propertyName]="filtersPropertyNames.publicationDate"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter(filtersPropertyNames.publicationDate)"
            [translateFieldKey]="'page.knowledgeList.publicationDate'"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell class="duration-cell" *matCellDef="let knowledge">{{ knowledge.publicationDate | date : "dd/MM/yyyy" }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit" *ngIf="draggable">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let knowledge">
          <button mat-icon-button (click)="editElement(knowledge)">
            <mat-icon class="fa-icon edit" fontSet="fas" fontIcon="fa-pen"></mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <div *ngIf="draggable">
        <mat-row
          cdkDrag
          [cdkDragData]="row | formatToLinkableList : action_target.KNOWLEDGE"
          *matRowDef="let row; columns: displayedColumns"
        ></mat-row>
      </div>
      <div *ngIf="!draggable">
        <mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="['/knowledgeDetails', { id: row.identifier }]"></mat-row>
      </div>
    </mat-table>

    <!-- PAGANITOR MANAGEMENT - length = total number of element via a Resolver!!-->
    <mat-paginator
      [style.visibility]="(dataSource.loading$ | async) === true ? 'hidden' : 'visible'"
      class="mat-elevation-z1"
      [length]="knowledgeCount"
      [pageSize]="10"
      [pageSizeOptions]="[10, 25, 50, 100]"
    ></mat-paginator>
  </mat-card>
</div>
