import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { I18nModule } from "src/app/i18n.module";
import { UiAlertComponent } from "./ui-alert.component";

@NgModule({
  declarations: [UiAlertComponent],
  imports: [I18nModule, CommonModule, MatIconModule],
  exports: [UiAlertComponent],
})
export class UiAlertModule {}
