import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Appointment } from "src/app/models/appointment.model";
import { AppointmentService } from "src/app/providers/appointment.service";

@Component({
  selector: "app-tele-consultation-dialog",
  templateUrl: "./tele-consultation-dialog.component.html",
  styleUrls: ["./tele-consultation-dialog.component.scss"],
})
export class TeleConsultationDialogComponent {
  public embeded = false;
  public trustedURL: SafeResourceUrl;

  constructor(
    public appointmentService: AppointmentService,
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: { appointment: Appointment }
  ) {
    const arrUrl = this.data.appointment.addressJitsiMeet;
    const tempEmbededUrl = arrUrl[0] + arrUrl[1];
    this.trustedURL = this.sanitizer.bypassSecurityTrustResourceUrl(tempEmbededUrl);
    this.embeded = true;
  }

  /**
   * Close embeded Visio and start it in a new tab
   */
  public openVisioNewTab(): void {
    const arrUrl = this.data.appointment.addressJitsiMeet;
    this.embeded = false;
    // time out to be sure embeded is destroy and not open 2 connections
    setTimeout(() => {
      window.open(arrUrl[0] + arrUrl[1], "_blank");
    }, 100);
  }
}
