/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import vegaEmbed, { VisualizationSpec } from "vega-embed";
import { IchartData } from "../risk-assessment-details.component";

@Component({
  selector: "app-risk-assessment-chart",
  templateUrl: "./risk-assessment-chart.component.html",
  styleUrls: ["./risk-assessment-chart.component.scss"],
})
export class RiskAssessmentChartComponent implements OnChanges {
  @Input() chartData: IchartData;
  public tooltipOptions: { formatTooltip: (value: any, sanitize: any) => any };

  constructor(public translateService: TranslateService) {}

  public chartSpec: VisualizationSpec | any = {
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    data: {
      values: [],
    },
    width: 800,
    height: 450,
    transform: [
      { window: [{ op: "sum", field: "value", as: "sum" }] },
      { window: [{ op: "lead", field: "label", as: "lead" }] },
      {
        calculate: "datum.lead === null ? datum.label : datum.lead",
        as: "lead",
      },
      {
        calculate: "datum.id === 'output' ? 0 : datum.sum - datum.value",
        as: "previous_sum",
      },
      {
        calculate: "datum.id === 'output' ? datum.sum : datum.value",
        as: "value",
      },
      {
        calculate: "(datum.id !== 'baseValue' && datum.id !== 'output' && datum.value > 0 ? '+' : '') + datum.value",
        as: "text_value",
      },
      { calculate: "(datum.sum + datum.previous_sum) / 2", as: "center" },
      {
        calculate: "datum.sum < datum.previous_sum ? datum.sum : ''",
        as: "sum_dec",
      },
      {
        calculate: "datum.sum > datum.previous_sum ? datum.sum : ''",
        as: "sum_inc",
      },
    ],
    encoding: {
      y: {
        field: "label",
        type: "ordinal",
        sort: null,
        axis: { labelAngle: 0, title: "Features" },
      },
    },
    layer: [
      {
        mark: { type: "bar", size: 28, tooltip: { content: "data" } },
        encoding: {
          x: {
            field: "previous_sum",
            type: "quantitative",
            title: "",
            sort: "ascending",
            axis: { labels: false },
          },
          x2: { field: "sum" },
          color: {
            condition: [
              {
                test: "datum.id === 'baseValue' || datum.id === 'output'",
                value: "#86afcf",
              },
              { test: "datum.sum < datum.previous_sum", value: "#3EAA5A" },
            ],
            value: "#E54E35",
          },
        },
      },
      {
        mark: {
          type: "text",
          fontWeight: "bold",
          baseline: "middle",
          angle: 0,
          tooltip: { content: "data" },
        },
        encoding: {
          x: { field: "center", type: "quantitative" },
          text: { field: "text_value", type: "nominal", format: ".2%" },
          color: {
            condition: [
              {
                test: "datum.id === 'baseValue' || datum.id === 'output'",
                value: "white",
              },
              {
                test: "abs(datum.value) < 0.030",
                value: "transparent",
              },
            ],
            value: "white",
          },
        },
      },
    ],
    config: { text: { fontWeight: "bold", color: "#404040", angle: 90 } },
  };

  ngOnChanges(_changes: SimpleChanges): void {
    this.tooltipOptions = {
      formatTooltip: (value, sanitize) => {
        switch (value.id) {
          case "baseValue":
            return this.translateService.instant("Risk_Copd_Triaging_Binary.tooltip.baseValue");
          case "output":
            return this.translateService.instant("Risk_Copd_Triaging_Binary.tooltip.output");
          default:
            return this.translateService.instant("Risk_Copd_Triaging_Binary.tooltip.text", {
              parameter: sanitize(value.label),
              assessment: this.computeAssessment(sanitize(value.value)),
              effect: this.computeEffect(sanitize(value.value)),
              value: Math.round(sanitize(value.value) * 100 * 100) / 100,
            });
        }
      },
    };

    this.chartSpec.data.values = this.chartData;
    vegaEmbed("#vis", this.chartSpec, { tooltip: this.tooltipOptions });
  }

  public computeAssessment(value: number): string {
    if (Math.sign(value) === -1) {
      return this.translateService.instant("Risk_Copd_Triaging_Binary.tooltip.positive");
    } else if (Math.sign(value) === 1) {
      return this.translateService.instant("Risk_Copd_Triaging_Binary.tooltip.negative");
    }
  }

  public computeEffect(value: number): string {
    if (Math.sign(value) === -1) {
      return this.translateService.instant("Risk_Copd_Triaging_Binary.tooltip.decrease");
    } else if (Math.sign(value) === 1) {
      return this.translateService.instant("Risk_Copd_Triaging_Binary.tooltip.increase");
    }
  }
}
