import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IStatisticsConfiguration } from "src/app/models/statObservation.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class StatObservationApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Stats Observation", // Theme !
      [], // Post route paths
      ["dashboard/statObservation"], // Get route paths
      [], // Update route paths
      [] // Delete route paths
    );
  }

  public list(patientId: string, loinc: string, fromDate: string, toDate: string): Observable<IStatisticsConfiguration> {
    return this.api.get(this.readRoutes[0], {
      identifier: patientId,
      loinc: loinc,
      start: fromDate,
      end: toDate,
    }) as Observable<IStatisticsConfiguration>;
  }
}
