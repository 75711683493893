import { AfterViewInit, Component, Input, OnChanges, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { DataType, Filter } from "src/app/models/filter.interface";
import { IObservationDefinition, ObservationResume } from "src/app/models/observations.interface";
import { DisplayPicturesComponent } from "../../display-pictures/display-pictures.component";
import { ObservationsListDataSource } from "../observations-list-datasource";
import { TranslateObservationNamePipe } from "../patient-observations-pipes/translate-observation-name.pipe";

@Component({
  selector: "app-small-observation-table",
  templateUrl: "./small-observation-table.component.html",
  styleUrls: ["./small-observation-table.component.scss"],
})
export class SmallObservationTableComponent implements AfterViewInit, OnChanges {
  @Input() obsDefinition: IObservationDefinition[];
  @Input() observations: ObservationResume[];
  @Input() loading: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public DATATYPE = DataType;
  public displayedColumns: string[] = ["name", "date", "value", "meaning"];
  public displayedColumnsFull: string[] = ["date"];
  public dataSource: ObservationsListDataSource;

  constructor(private dialog: MatDialog, private translateObservationNamePipe: TranslateObservationNamePipe) {
    this.dataSource = new ObservationsListDataSource(this.translateObservationNamePipe);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngOnChanges(): void {
    this.dataSource.loadData(this.observations, this.obsDefinition);
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource?.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
  }

  /**
   * For a quick view of the picture in small mode :
   * Open the DisplayPicturesComponent without previous and next
   */
  public pictureQuickView(pictures: string[], title: string): void {
    this.dialog.open(DisplayPicturesComponent, {
      data: {
        pictures,
        title,
        previous: null,
        next: null,
      },
      disableClose: true,
    });
  }
}
