import { Observable } from "rxjs";
import { MatTableDataSourceBackendExtended } from "src/app/helpers/datasource-backend";
import { DataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { IStatEvent } from "src/app/models/userStatistics.interface";
import { UserStatisticsApiService } from "src/app/providers/api/userStatistics-api.service";

export class StatEventsDataSource extends MatTableDataSourceBackendExtended<IStatEvent> {
  constructor(private statEventApi: UserStatisticsApiService) {
    super();
  }

  public data(params: DataSourceBackendParam): Observable<IStatEvent[]> {
    return this.statEventApi.listStatEvents(params) as Observable<IStatEvent[]>;
  }
}
