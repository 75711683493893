import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { first } from "rxjs/operators";
import { IHealthcareservice } from "src/app/models/healthcareservice";
import { Healthcareservice } from "src/app/models/healthcareservice.model";
import { DocCategory, IDocumentHistoric } from "src/app/models/patient-document.interface";
import { PatientUser } from "src/app/models/patient.interface";
import { Reference } from "src/app/models/reference.interface";
import { DocumentService } from "src/app/providers/document.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";

@Component({
  selector: "app-document-modal",
  templateUrl: "./document-modal.component.html",
  styleUrls: ["./document-modal.component.scss"],
})
export class DocumentModalComponent implements OnInit {
  public documentForm: UntypedFormGroup;
  public DocCategory = DocCategory;
  public docCategoryKeys: string[];
  public availableServices: Reference[];
  public availableMonitoringServices: Healthcareservice[];
  public allReference = this.sessionService.allsReference;
  public monitoringService: IHealthcareservice;
  public docHistoric: IDocumentHistoric;
  public isEditing: boolean;
  public allArchived: boolean;
  public isMonitoringUser: boolean;
  public isSaving: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private documentService: DocumentService,
    public dialogRef: MatDialogRef<DocumentModalComponent>,
    private sessionService: SessionService,
    private userService: UserService,
    private departmentService: HealthcareserviceService,
    @Inject(MAT_DIALOG_DATA)
    public data: { file: File; patientUser: PatientUser; name: string }
  ) {
    this.isMonitoringUser = this.userService.isMonitoringUser;
    this.documentForm = this.fb.group({
      name: new UntypedFormControl({ value: undefined, disabled: true }, Validators.required),
      file: new UntypedFormControl(undefined),
      service: new UntypedFormControl(undefined, Validators.required),
      patient: new UntypedFormControl(undefined),
      docCategory: new UntypedFormControl(undefined, Validators.required),
      contentType: new UntypedFormControl(undefined),
    });

    if (this.isMonitoringUser) {
      this.documentForm.addControl("monitoringService", new UntypedFormControl(undefined, Validators.required));
    }
  }

  ngOnInit(): void {
    this.availableMonitoringServices = this.userService.allMonitoringServices;

    this.setAvailableServices();
    this.documentForm.get("patient").setValue(this.data.patientUser.user.caremateIdentifier);

    // Modal open with existing document
    if (this.data.name) {
      this.isEditing = true;
      this.getDocHistoricAndUpdateForm(this.data.patientUser.user.caremateIdentifier, this.data.name);
    }
    if (this.data.file) {
      this.documentForm.get("file").setValidators(Validators.required);
      this.documentForm.get("name").setValue(this.data.file.name);
      this.documentForm.get("file").setValue(this.data.file);
      this.documentForm.get("contentType").setValue(this.data.file.type);

      this.getDocHistoricAndUpdateForm(this.data.patientUser.user.caremateIdentifier, this.data.file.name);
    }
  }

  public setAvailableServices(): void {
    const patientOrgRef = this.data.patientUser.patient?.managingOrganization.reference;
    const patientServices = this.data.patientUser.patient.healthcareservice;
    this.docCategoryKeys = Object.keys(DocCategory);
    this.availableServices = this.userService.allServices
      .filter((s) => s.providedBy.reference === patientOrgRef)
      .map((x) => x.asReference)
      .filter((s) => patientServices.findIndex((x) => s.reference === x.reference) >= 0);
  }

  public save(): void {
    this.isSaving = true;
    this.documentForm.markAllAsTouched();
    if (this.documentForm.invalid) {
      this.isSaving = false;
      return;
    }
    const formData = new FormData();
    const formValues = this.documentForm.getRawValue();

    for (const key of Object.keys(formValues)) {
      if (this.documentForm.get(key).value) {
        if (key === "service" || key === "monitoringService") {
          formData.append(key, JSON.stringify([this.documentForm.get(key).value]));
        } else {
          formData.append(key, this.documentForm.get(key).value);
        }
      }
    }

    if (this.isEditing) {
      const param = {
        // value to retrieve doc to update :
        name: this.docHistoric.name,
        modified: this.docHistoric.modified,
        patient: this.data.patientUser.user.caremateIdentifier,
        // field actually updated :
        docCategory: this.documentForm.get("docCategory").value,
        services: JSON.stringify([this.documentForm.get("service").value]),
        monitoringService: this.documentForm.get("monitoringService")
          ? JSON.stringify([this.documentForm.get("monitoringService").value])
          : undefined,
      };
      this.documentService
        .editDocument(param)
        .pipe(first())
        .subscribe(
          () => {
            this.isSaving = false;
            this.dialogRef.close();
          },
          () => {
            this.isSaving = false;
          }
        );
    } else {
      this.documentService
        .uploadDocument(formData)
        .pipe(first())
        .subscribe(
          () => {
            this.isSaving = false;
            this.dialogRef.close();
          },
          () => {
            this.isSaving = false;
          }
        );
    }
  }

  public download(name: string, modified: string): void {
    this.documentService.downloadDocument(name, modified, this.data.patientUser.user.caremateIdentifier);
  }

  public getDocHistoricAndUpdateForm(patientId: string, name: string): void {
    this.documentService
      .getDocHistoric(patientId, name)
      .pipe(first())
      .subscribe((docHistoric: IDocumentHistoric) => {
        if (!docHistoric) {
          return;
        }
        this.docHistoric = docHistoric;
        this.documentForm.get("name").setValue(docHistoric.name);
        this.documentForm.get("docCategory").setValue(docHistoric.docCategory);
        this.documentForm.get("service").setValue(docHistoric.service?.reference);
        this.documentForm.get("monitoringService")?.setValue(docHistoric.monitoringService?.reference);

        if (
          !this.data.file?.name &&
          !this.documentForm.get("monitoringService")?.value &&
          !this.documentForm.get("service").value &&
          !this.documentForm.get("docCategory").value
        ) {
          this.allArchived = true;
        }
      });
  }
}
