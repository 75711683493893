import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IObservationDefinition } from "src/app/models/observations.interface";
import { RuleAlert } from "src/app/models/rule-alert.model";
import { RULE_TYPE_TARGET } from "src/app/models/rule.interface";

@Component({
  selector: "app-alert-details",
  templateUrl: "./alert-details.component.html",
  styleUrls: ["./alert-details.component.scss"],
})
export class AlertDetailsComponent implements OnInit {
  public alert: RuleAlert;
  public createAction: boolean;
  public patientServiceId: string;
  public allDefinitions: IObservationDefinition[];
  public RULE_TYPE_TARGET = RULE_TYPE_TARGET;

  public constructor(
    private readonly dialogRef: MatDialogRef<AlertDetailsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      alert: RuleAlert;
      createAction: boolean;
      dialogRef;
      patientServiceId: string;
      allDefinitions: IObservationDefinition[];
    }
  ) {}

  ngOnInit() {
    this.alert = this.data.alert;
    this.createAction = this.data.createAction;
    this.patientServiceId = this.data.patientServiceId;
    this.allDefinitions = this.data.allDefinitions;
  }

  public close(needReload: boolean): void {
    this.dialogRef.close(needReload);
  }
}
