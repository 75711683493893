import { Component, Input, OnInit } from "@angular/core";
import { IDrugStock } from "src/app/models/entity.interface";
import { DrugsService } from "src/app/providers/drugs.service";

@Component({
  selector: "app-drug-stock",
  templateUrl: "./drug-stock.component.html",
  styleUrls: ["./drug-stock.component.scss"],
})
export class DrugStockComponent implements OnInit {
  @Input() drugId: string;
  @Input() patientId: string;

  public stock: IDrugStock;

  constructor(private drugsService: DrugsService) {}

  ngOnInit(): void {
    if (this.drugId && this.patientId) {
      this.drugsService
        .getDrugStock(this.patientId, this.drugId)
        .then((s) => (this.stock = s))
        .catch((err) => {
          console.error("Error while downloading stock", err);
        });
    }
  }
}
