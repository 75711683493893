<div class="loading" *ngIf="!pageLoaded">
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div class="accessGroupsSearchBars" *ngIf="pageLoaded">
  <div>
    <mat-form-field class="searchField">
      <input
        matInput
        [placeholder]="'page.accessGroups.name' | translate"
        #nameSearchInput
        [value]="filters.group"
        (keyup.enter)="filters.group = nameSearchInput.value; filterGroups()"
        (blur)="filters.group = nameSearchInput.value; filterGroups()"
      />
      <button
        type="button"
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="nameSearchInput.value = ''"
        [style.visibility]="nameSearchInput.value ? 'visible' : 'hidden'"
        #nameSearchClearBtn
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="searchField">
      <input
        matInput
        [placeholder]="'groupPermission.theme' | translate"
        #themeSearchInput
        [value]="filters.theme"
        (keyup.enter)="filters.theme = themeSearchInput.value; filterGroups()"
        (blur)="filters.theme = themeSearchInput.value; filterGroups()"
      />
      <button
        type="button"
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="themeSearchInput.value = ''"
        [style.visibility]="themeSearchInput.value ? 'visible' : 'hidden'"
        #themeSearchClearBtn
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="searchField">
      <mat-label>{{ "groupPermission.method" | translate }}</mat-label>
      <mat-select (selectionChange)="filters.method = $event.value; filterGroups()" [value]="filters.method">
        <mat-option [value]="undefined"
          ><i>{{ "group.noGroupFilter" | translate }}</i></mat-option
        >
        <mat-option *ngFor="let item of methods" [value]="item">
          {{ "groupPermission." + item | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button
      mat-stroked-button
      *ngIf="filters.theme || filters.group || filters.method"
      (click)="clearFilters()"
      matTooltip="{{ 'itemFilter.clearAll' | translate }}"
    >
      <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
    </button>
  </div>
  <div>
    <button mat-stroked-button class="createButton" (click)="showPractitionersAccessGroups()" *ngIf="canReadGroups">
      {{ "page.accessGroups.showPractitionerGroups" | translate }}
    </button>
    <button mat-stroked-button class="createButton" (click)="createAccessGroup()" *ngIf="canCreateGroup">
      <mat-icon aria-hidden="false" aria-label="Add icon">add</mat-icon>
      {{ "page.accessGroups.createGroup" | translate }}
    </button>
  </div>
</div>

<!-- ========================  Access groups list ========================= -->

<div *ngIf="pageLoaded" style="margin-bottom: 60px">
  <div *ngFor="let accessGroup of filteredAccessGroups; let i = index" class="accessGroups">
    <h3 class="groupsPermissions accessGroup">
      <button class="itemTitle" mat-button (click)="accessGroup.displayPerms = !accessGroup.displayPerms">
        <mat-icon *ngIf="!accessGroup.displayPerms">arrow_right</mat-icon>
        <mat-icon *ngIf="accessGroup.displayPerms">arrow_drop_down</mat-icon>
        {{ accessGroup.identifier.label ? accessGroup.identifier.label : accessGroup.identifier.value }}
      </button>
      <button
        mat-icon-button
        *ngIf="canUpdateGroup"
        (click)="editAccessGroup(accessGroup.identifier.value)"
        matTooltip="{{ 'page.accessGroups.edit' | translate }}"
      >
        <mat-icon class="custom-icon" color="primary">edit</mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="canDeleteGroup"
        (click)="deleteAccessGroup(accessGroup.identifier.value)"
        matTooltip="{{ 'common.delete' | translate }}"
      >
        <mat-icon class="custom-icon" color="warn">delete</mat-icon>
      </button>
    </h3>
    <div *ngIf="accessGroup.displayPerms">
      <div class="permission" *ngFor="let pTheme of accessGroup.permsByTheme">
        <h4 class="groupsPermissions groupTheme">
          <button class="itemTitle" mat-button (click)="pTheme.displayPerms = !pTheme.displayPerms">
            <mat-icon *ngIf="!pTheme.displayPerms">arrow_right</mat-icon>
            <mat-icon *ngIf="pTheme.displayPerms">arrow_drop_down</mat-icon>
            {{ pTheme.theme }}
          </button>
        </h4>
        <div *ngIf="pTheme.displayPerms">
          <app-group-permissions [permissions]="pTheme.permissions"></app-group-permissions>
        </div>
      </div>
    </div>
  </div>
</div>
