import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IKnowledgesCriteria } from "src/app/models/knowledgescriteria-interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class KnowledgeCriteriaApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "KnowledgesCriteria", // Theme !
      ["dashboard/knowledgesCriteria"], // Post route paths
      ["dashboard/knowledgesCriteria"], // Get route paths
      ["dashboard/knowledgesCriteria", "dashboard/updateCareplansWithKnowledgeCriteria"], // Update route paths
      ["dashboard/knowledgesCriteria"] // Delete route paths
    );
  }

  public list(
    withDraft: boolean,
    careplanIds?: string[],
    knowledgeIds?: string[],
    careplanId?: string,
    knowledgeId?: string
  ): Observable<{ published: IKnowledgesCriteria[]; draft: IKnowledgesCriteria[] }> {
    return this.api.get(this.readRoutes[0], {
      withDraft,
      careplanIds,
      knowledgeIds,
      careplanId,
      knowledgeId,
    }) as Observable<{ published: IKnowledgesCriteria[]; draft: IKnowledgesCriteria[] }>;
  }

  public update(knowledgeCriteria: IKnowledgesCriteria): Observable<IKnowledgesCriteria> {
    return this.api.put(this.updateRoutes[0], knowledgeCriteria) as Observable<IKnowledgesCriteria>;
  }

  public create(knowledgeCriteria: IKnowledgesCriteria): Observable<IKnowledgesCriteria> {
    return this.api.post(this.createRoutes[0], knowledgeCriteria) as Observable<IKnowledgesCriteria>;
  }

  public deleteById(id: string): Observable<unknown> {
    return this.api.delete(`${this.deleteRoutes[0]}/${id}`, {});
  }

  public delete(careplanId: string, knowledgeId: string, activityId?: string): Observable<unknown> {
    return this.api.deleteWithParams(this.deleteRoutes[0], { careplanId, knowledgeId, activityId });
  }

  public updateCareplansWithKnCriteria(reference: string, identifier: string): Observable<unknown> {
    return this.api.put(this.updateRoutes[1], { reference, identifier });
  }
}
