<form>
  <mat-form-field>
    <mat-label>{{ "accessGroups.user" | translate }}</mat-label>
    <input
      type="text"
      placeholder="{{ 'accessGroups.chooseUser' | translate }}"
      aria-label="Number"
      matInput
      [formControl]="practitionerControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="emitValue()" panelWidth="unset">
      <mat-option *ngFor="let practitioner of filteredPractitioners | async" [value]="practitioner">
        {{ practitioner.firstname }} {{ practitioner.familyName }}
        {{ practitioner.mainMail?.value ? "(" + practitioner.mainMail?.value + ")" : "" }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
