<section class="properties-group">
  <form id="dateInput" [formGroup]="dateForm">
    <mat-form-field class="placeholderText">
      <mat-label>
        {{ "page.questionnaireEditor.properties.placeholder" | translate }}
      </mat-label>
      <input matInput formControlName="placeholder" (change)="emitQuestionChanges()" />
    </mat-form-field>
    <div class="sideBySideFields">
      <mat-form-field>
        <mat-label>{{ "page.questionnaireEditor.properties.min" | translate }}</mat-label>
        <input matInput formControlName="minDate" [matDatepicker]="dateMin" />
        <mat-datepicker-toggle matSuffix [for]="dateMin"></mat-datepicker-toggle>
        <mat-datepicker #dateMin></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "page.questionnaireEditor.properties.max" | translate }}</mat-label>
        <input matInput formControlName="maxDate" [matDatepicker]="dateMax" />
        <mat-datepicker-toggle matSuffix [for]="dateMax"></mat-datepicker-toggle>
        <mat-datepicker #dateMax></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "page.questionnaireEditor.properties.initial" | translate }}</mat-label>
        <input matInput formControlName="initialDate" [matDatepicker]="dateInitial" />
        <mat-datepicker-toggle matSuffix [for]="dateInitial"></mat-datepicker-toggle>
        <mat-datepicker #dateInitial></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
</section>
