import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject, of } from "rxjs";
import { catchError, delay, first, skipWhile, takeUntil, tap } from "rxjs/operators";
import { AVAILABLE_ACTIONS } from "src/app/careplan-editor/data/careplan-editor.enum";
import { IlinkableKnowledgeItem } from "src/app/careplan-editor/domain/IlinkableItem.interface";
import { CareplanEditorService } from "src/app/careplan-editor/domain/careplan-editor.service";
import { LinkableKnowledgeItem } from "src/app/careplan-editor/domain/linkable-item.model";
import { KnowledgeCreationDialogComponent } from "src/app/components/forms/add-knowledge/add-knowledge.component";
import { Tools } from "src/app/helpers/tools";
import { ACTION_TARGET, ICareplan, ICareplanKnowledgeInfos, VersioningStatus } from "src/app/models/careplans.interface";
import { DataType } from "src/app/models/filter.interface";
import { KNOW_CATEGORY, KNOW_DOC_CATEGORY } from "src/app/models/knowledge.interface";
import { IKnowledgesCriteria } from "src/app/models/knowledgescriteria-interface";
import { EnableWhenBehavior, STATUS_ENTITY } from "src/app/models/sharedInterfaces";
import { CareplansService } from "src/app/providers/careplans.service";
import { KnowledgeCriteriaService } from "src/app/providers/knowledge-criteria-service";
import { SessionService } from "src/app/providers/session.service";

@Component({
  selector: "app-careplan-editor-knowledge-tab",
  templateUrl: "./careplan-editor-knowledge-tab.component.html",
  styleUrls: ["./careplan-editor-knowledge-tab.component.scss"],
})
export class CareplanEditorKnowledgeTabComponent implements OnInit, OnDestroy {
  public careplanTemplate: ICareplan;
  /** knowledges actually linked to the careplan (target table) */
  public link2CareplanKnowledges: IlinkableKnowledgeItem[];
  public activitiesKnowledges: IlinkableKnowledgeItem[];
  public columns = ["term", "category", "type"];
  public actions = [AVAILABLE_ACTIONS.configure, AVAILABLE_ACTIONS.unlink, AVAILABLE_ACTIONS.edit];
  public currentKnowledgeConfigured: IlinkableKnowledgeItem;
  public filters = [
    {
      propertyName: "category",
      dataType: DataType.CHOICE,
      data: {
        value: [KNOW_CATEGORY.MEDICAL, KNOW_CATEGORY.OBSERVATION, KNOW_CATEGORY.PATHOLOGY],
      },
    },
    {
      propertyName: "type",
      dataType: DataType.CHOICE,
      data: {
        value: [KNOW_DOC_CATEGORY.DESCRIPTION, KNOW_DOC_CATEGORY.RECOMMENDATION],
      },
    },
  ];
  public currentLanguage: string = this.sessionService.userLang;
  private onDestroy$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    public careplanEditorService: CareplanEditorService,
    private knowledgeCriteriaService: KnowledgeCriteriaService,
    private sessionService: SessionService,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private careplanService: CareplansService
  ) {}

  public ngOnInit(): void {
    // Refresh translation when needed
    this.sessionService.refreshServerTraductions.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentLanguage = this.sessionService.userLang;
    });
    // Observable to check if the careplanTemplate is ready
    const careplanTemplateReady$ = this.careplanEditorService.currentCareplanTemplate
      ? of(true)
      : this.careplanEditorService.careplanTemplateReady$.pipe(
          skipWhile((value) => value === false),
          first()
        );

    careplanTemplateReady$.subscribe(() => {
      this.careplanTemplate = this.careplanEditorService.currentCareplanTemplate;
      this.setupKnowledge();
    });
  }

  public ngOnDestroy(): void {
    // After publish careplanTemplateReady$ value is false to avoid trying to save a careplan without draft
    if (this.careplanEditorService.careplanTemplateReady$.value === true) {
      this.careplanEditorService.save(false, false, false);
    }
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public async update($event: { item: LinkableKnowledgeItem; items: LinkableKnowledgeItem[]; action: AVAILABLE_ACTIONS }): Promise<void> {
    const { item, items, action } = $event;

    if (item.origin === "link2careplan") {
      if (action === AVAILABLE_ACTIONS.unlink) {
        await this.handleUnlinkFromCareplan(item, items);
      } else {
        await this.updateLink2Careplan(items);
      }
    } else if (item.origin === "activity") {
      if (action === AVAILABLE_ACTIONS.unlink) {
        await this.deleteCriteria(
          this.careplanTemplate.support[0].reference,
          $event.item.identifier,
          $event.item.originReference.reference
        );
      } else {
        await this.handleLinkToActivity(item);
      }
    }
    this.setupKnowledge();
  }

  public async handleUnlinkFromCareplan(item: LinkableKnowledgeItem, items: LinkableKnowledgeItem[]): Promise<void> {
    let criteria = (
      await this.knowledgeCriteriaService.list(null, null, this.careplanTemplate.support[0].reference, item.identifier).toPromise()
    ).draft;

    criteria = criteria.filter((c) => !Tools.isDefined(c.activityReference));

    if (criteria.length > 0) {
      this.deleteCriteria(this.careplanTemplate.support[0].reference, item.identifier);
    } else {
      await this.updateLink2Careplan(items);
    }
  }

  public async updateLink2Careplan(items: LinkableKnowledgeItem[]): Promise<void> {
    this.careplanEditorService.link2CareplanForm.patchValue({ knowledges: items.map((k) => k.uniqueId) });
    try {
      await this.careplanService.updateLink2Careplan(this.careplanEditorService.link2CareplanForm.getRawValue()).toPromise();
    } catch (error) {
      this.handleError();
    }
  }

  /**
   * Show toast and reset knowledge in case of error
   */
  public handleError(): void {
    this.snackBar.open(this.translateService.instant("api.errors.server-error"), "ok", { duration: 5000 });
    // reload the knowledge list in case of error
    this.setupKnowledge();
  }

  /**
   * Handles the linking of a knowledge to an activity.
   */
  private async handleLinkToActivity(item: LinkableKnowledgeItem) {
    // Save knowledge criteria
    const knowledgeCriteria: IKnowledgesCriteria = {
      knowledgeIdentifier: { value: item.identifier, system: "http://comunicare.io" },
      careplanSupport: this.careplanTemplate.support,
      activityReference: item.originReference,
      when: { period: 0, periodUnits: "d" },
      entityStatus: [STATUS_ENTITY.ACTIVE],
      subCriteria: [],
      showDirectly: false,
      enableWhenBehavior: EnableWhenBehavior.AND,
      versioningStatus: VersioningStatus.DRAFT,
    };
    return this.knowledgeCriteriaService
      .create(knowledgeCriteria)
      .pipe(
        catchError(() => {
          this.snackBar.open(this.translateService.instant("api.errors.server-error"), "ok", { duration: 5000 });
          return of(false);
        })
      )
      .toPromise();
  }

  public deleteCriteria(careplanId: string, knowledgeId: string, activityId?: string): Promise<unknown> {
    return this.knowledgeCriteriaService
      .delete(careplanId, knowledgeId, activityId)
      .pipe(
        catchError(() => {
          this.snackBar.open(this.translateService.instant("api.errors.server-error"), "ok", { duration: 5000 });
          return of(false);
        })
      )
      .toPromise();
  }

  /**
   * Filters ICareplanKnowledgeInfos[] by origin and maps them to IlinkableKnowledgeItem[] array
   * @param allKnowledge
   * @param origin
   */
  public mapAvailableKnowledge(allKnowledge: ICareplanKnowledgeInfos[], origin: "link2careplan" | "activity"): IlinkableKnowledgeItem[] {
    return allKnowledge
      .filter((k) => k.origin === origin)
      .map((k) => {
        return new LinkableKnowledgeItem({
          uniqueId: k.identifier,
          term: k.term,
          category: this.translateService.instant("knowledgebase.category." + k.category),
          type: this.translateService.instant("knowledgebase.doccategory." + k.type),
          originReference: k.originReference,
          origin: k.origin,
          itemType: ACTION_TARGET.KNOWLEDGE,
          identifier: k.identifier,
        });
      });
  }

  public addKnowledge(): void {
    this.dialog.open(KnowledgeCreationDialogComponent, {
      data: { previousLocation: this.router.url },
      disableClose: false,
    });
  }

  /**
   * Sets up knowledge related to the careplan.
   * Fetches careplanKnowledgeInfos and maps the available knowledge to link2CareplanKnowledges and activitiesKnowledges
   */
  public setupKnowledge(): void {
    this.careplanEditorService
      .getCareplanKnowledgeInfos(
        this.careplanTemplate.support[0].reference,
        true,
        [KNOW_DOC_CATEGORY.DESCRIPTION, KNOW_DOC_CATEGORY.RECOMMENDATION],
        this.careplanTemplate.publicationDate
      )
      .pipe(
        first(),
        delay(500),
        tap((knowledges) => {
          this.link2CareplanKnowledges = this.mapAvailableKnowledge(knowledges, "link2careplan");
          this.activitiesKnowledges = this.mapAvailableKnowledge(knowledges, "activity");
        })
      )
      .subscribe();
  }
}
