import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CareplanTemplateFormHelper } from "src/app/careplan-editor/domain/careplan-template-form-helper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { ACTION_TARGET, IOrder, IOrderAction } from "src/app/models/careplans.interface";
import { IAttachment, IPayloadType } from "src/app/models/communications.interface";
import { ITranslation } from "src/app/models/translation.interface";
import { LanguagesService } from "src/app/providers/languages.service";
import { SessionService } from "src/app/providers/session.service";

@Component({
  selector: "app-communication-form",
  templateUrl: "./communication-form.component.html",
  styleUrls: ["./communication-form.component.scss"],
})
export class CommunicationFormComponent implements OnInit, OnDestroy {
  @Input() actionResultingForm: UntypedFormGroup;
  @Input() needSkipFirst: boolean;

  public config = {
    useSearch: false,
    spellcheck: true,
    language: this.sessionService.userLang,
    toolbarButtonSize: "small",
    minHeight: 450,
    disablePlugins: "file, media",
    style: { font: "20px Arial" },
  };

  public actionResulting: IOrder;
  public attachements: IAttachment[] = [];
  public selectedIndex = 0;
  public availableLanguages: ITranslation[];
  public onDestroy$ = new Subject<void>();
  public currentLanguage = this.translateService.currentLang;
  public period: number;
  public periodUnit: string;
  public timingCode: string;
  public skipFirst: boolean;
  public communicationForm: UntypedFormGroup;
  public action_target = ACTION_TARGET;

  constructor(
    private sessionService: SessionService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private languagesService: LanguagesService,
    private fb: UntypedFormBuilder,
    private careplanTemplateFormHelper: CareplanTemplateFormHelper
  ) {}

  ngOnInit(): void {
    this.initializeValues();
    this.getAvailableLanguages();
    this.createCommunicationForm();

    this.communicationForm.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value: { detail: IOrderAction }) => {
      this.actionResulting.detail.contentCommunication = value.detail.contentCommunication;
      this.actionResultingForm.setValue(this.actionResulting);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public initializeValues(): void {
    this.actionResulting = this.actionResultingForm.value;
    const { period, periodUnits, timingCode, skipFirst } = this.actionResulting.when;
    this.period = period;
    this.periodUnit = periodUnits;
    this.timingCode = timingCode;
    this.skipFirst = skipFirst;
  }

  public async getAvailableLanguages(): Promise<void> {
    try {
      this.availableLanguages = await this.languagesService.list().pipe(takeUntil(this.onDestroy$)).toPromise();
    } catch (error) {
      FileLogger.error("CommunicationFormComponent", "Cannot get available Languages");
    }
    this.selectedIndex = this.availableLanguages.findIndex((el) => el.term === this.currentLanguage);
  }

  public addPdf(event: { target: { files: FileList } }): void {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (files.length && file) {
        const maxSize = 0.6 * 1024 * 1024;
        if (file?.size > maxSize) {
          this.showTooBig();
          return;
        } else {
          const reader = new FileReader();
          const fileName = file.name;
          reader.onload = this._handleReaderLoaded.bind(this, fileName, IPayloadType.PDF);
          reader.readAsDataURL(file);
        }
      }
    }
  }

  private showTooBig(): void {
    const trans = this.translateService.instant("page.myService.imageError");
    this.snackBar.open(trans, "ok", { duration: 3000 });
  }

  public _handleReaderLoaded(fileName: string, type: IPayloadType, readerEvt: ProgressEvent<FileReader>): void {
    this.attachements.push({
      title: fileName,
      data: readerEvt.target.result as string,
      contentType: type,
      creation: new Date(),
    });

    // @TODO: CMATE-5881, adapter la ligne suivante lors de l'ajout de la gestion des attachements multilingues
    // this.actionResultingForm.setValue(this.actionResulting);
  }

  public addImage(event: { target: { files: FileList } }): void {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (files.length && file) {
        const maxSize = 0.6 * 1024 * 1024;
        if (file?.size > maxSize) {
          this.showTooBig();
          return;
        } else {
          const reader = new FileReader();
          const fileName = file.name;
          reader.onload = this._handleReaderLoaded.bind(this, fileName, IPayloadType.JPG);
          reader.readAsDataURL(file);
        }
      }
    }
  }

  public get attachementsString(): string[] {
    return this.attachements.map((v) => v.title);
  }

  public remove(attachement: string): void {
    const index = this.attachements.findIndex((v) => v.title === attachement);

    if (index >= 0) {
      this.attachements.splice(index, 1);

      // @TODO: CMATE-5881, adapter la ligne suivante lors de l'ajout de la gestion des attachements multilingues
      // this.actionResultingForm.setValue(this.actionResulting);
    }
  }

  public updateWhenInput(): void {
    this.actionResulting.when.period = this.period;
    this.actionResulting.when.periodUnits = this.periodUnit;
    this.actionResulting.when.timingCode = this.timingCode;
    this.actionResulting.when.skipFirst = this.skipFirst;
  }

  public createCommunicationForm(): void {
    this.communicationForm = this.fb.group({
      detail: this.careplanTemplateFormHelper.createOrderActionFormGroup(this.actionResultingForm.value.detail),
    });
  }
}
