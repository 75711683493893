<h3 mat-dialog-title>{{ "page.accessGroups.showPractitionerGroups" | translate }}</h3>

<div mat-dialog-content *ngIf="data">
  <form (ngSubmit)="showGroups()">
    <mat-form-field appearance="fill" style="width: 50%">
      <mat-label>{{ "accessGroups.user" | translate }}</mat-label>
      <input
        type="text"
        placeholder="{{ 'accessGroups.chooseUser' | translate }}"
        aria-label="Number"
        matInput
        [formControl]="practitionerControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
          {{ user.firstname }} {{ user.name }} ({{ user.mail }})
        </mat-option>
      </mat-autocomplete> </mat-form-field
    ><button mat-icon-button type="submit">
      <mat-icon color="primary">check</mat-icon>
    </button>
  </form>

  <app-practitioner-access-groups
    [mode]="data.mode"
    [allAccessGroups]="data.allAccessGroups"
    [(practitionerAccessGroupsIds)]="accessGroupsIds"
    [ownAccount]="data.ownAccount"
  ></app-practitioner-access-groups>
</div>

<div mat-dialog-actions>
  <button mat-raised-button type="submit" color="primary" (click)="cancel()">
    {{ "btn.ok" | translate }}
  </button>
</div>
