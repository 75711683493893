import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject, combineLatest, of } from "rxjs";
import { delay, first, mergeMap, skipWhile, takeUntil } from "rxjs/operators";
import { AVAILABLE_ACTIONS } from "src/app/careplan-editor/data/careplan-editor.enum";
import { IlinkableObsItem } from "src/app/careplan-editor/domain/IlinkableItem.interface";
import { CareplanEditorService } from "src/app/careplan-editor/domain/careplan-editor.service";
import { LinkableObsItem } from "src/app/careplan-editor/domain/linkable-item.model";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { ACTION_TARGET, ICareplan, VersioningStatus } from "src/app/models/careplans.interface";
import { IObservationsLoincsInfo } from "src/app/models/observations.interface";
import { IObservationParamWithObsDefinition } from "src/app/models/patientConfig.interface";
import { GetShortnamePipe } from "src/app/pipes/get-shortname.pipe";
import { CareplansService } from "src/app/providers/careplans.service";
import { ObservationsService } from "src/app/providers/observations.service";
import { SessionService } from "src/app/providers/session.service";
import { ImportObsAndRuleComponent } from "../../components/import-obs-and-rule/import-obs-and-rule.component";

@Component({
  selector: "app-careplan-editor-observations-tab",
  templateUrl: "./careplan-editor-observations-tab.component.html",
  styleUrls: ["./careplan-editor-observations-tab.component.scss"],
})
export class CareplanEditorObservationsTabComponent implements OnInit, OnDestroy {
  public columns = ["name", "loinc", "reference"];
  public actions = [AVAILABLE_ACTIONS.configure, AVAILABLE_ACTIONS.unlink];

  /** All available observations to choose from (source table) */
  public availableObservations: IlinkableObsItem[];
  /** observations actually linked to the careplan (target table) */
  public observations: IlinkableObsItem[];
  public activitiesObservations: IlinkableObsItem[];
  public dropListIds: string[];
  public currentLanguage: string = this.sessionService.userLang;
  private onDestroy$ = new Subject<void>();
  public careplanTemplate: ICareplan;

  constructor(
    public careplanEditorService: CareplanEditorService,
    public careplansService: CareplansService,
    private sessionService: SessionService,
    private observationsService: ObservationsService,
    private getShortnamePipe: GetShortnamePipe,
    private dialog: MatDialog,
    private careplanService: CareplansService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {}

  public ngOnInit(): void {
    // Refresh translation when needed
    this.sessionService.refreshServerTraductions.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentLanguage = this.sessionService.userLang;
    });

    // Observable to check if the careplanTemplate is ready
    const careplanTemplateReady$ = this.careplanEditorService.currentCareplanTemplate
      ? of(true)
      : this.careplanEditorService.careplanTemplateReady$.pipe(
          skipWhile((value) => value === false),
          first()
        );

    // Observable to check if the link2Careplan is ready
    const link2CareplanReady$ = this.careplanEditorService.link2CareplanForm.value
      ? of(true)
      : this.careplanEditorService.link2CareplanReady$.pipe(first());

    // Observable to get all observation params
    const allObsParams$ = this.observationsService.listParams(true).pipe(first(), delay(500));

    // Combine observables to ensure they are ready before proceeding
    combineLatest([allObsParams$, careplanTemplateReady$, link2CareplanReady$])
      .pipe(first())
      .subscribe(([allObsParams]) => {
        this.careplanTemplate = this.careplanEditorService.currentCareplanTemplate;
        this.mapAvailableObsParams(allObsParams);
        this.setupObservations();
        this.dropListIds = this.careplanTemplate.activity.map((activity, index) => "drop-list-activity-" + index);
        this.dropListIds = [...this.dropListIds, "drop-list-1"];
      });
  }

  public ngOnDestroy(): void {
    // After publish careplanTemplateReady$ value is false to avoid trying to save a careplan without draft
    if (this.careplanEditorService.careplanTemplateReady$.value === true) {
      this.careplanEditorService.save(false, false, false);
    }
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Retrieves observations linked to the current careplan and activities and map them to IlinkableObsItem
   */
  public async setupObservations(): Promise<void> {
    try {
      const obsPromise = this.careplansService.getObservationsLinkedToCareplan(
        this.careplanTemplate.support[0].reference,
        this.careplanTemplate.versioningStatus === VersioningStatus.DRAFT,
        this.careplanTemplate.publicationDate
      );

      const obs = (await Promise.all([obsPromise, Tools.timeout(500)]))[0]; // resolve promise in minimum 500ms to show loader without glitch

      this.observations = this.mapObservationsInfos(obs, "link2careplan");
      this.activitiesObservations = this.mapObservationsInfos(obs, "activity");
    } catch (error) {
      FileLogger.error("CareplanEditorObservationsTab", "Error fetching observations:");
    }
  }

  /**
   * Maps IObservationParamWithObsDefinition array to IlinkableObsItem array
   * @param allObsParams - Array of observation parameters with associated observation definitions.
   */
  public mapAvailableObsParams(allObsParams: IObservationParamWithObsDefinition[]): void {
    this.availableObservations = allObsParams.map((obsParam) => {
      return new LinkableObsItem({
        uniqueId: obsParam.reference,
        name: this.getShortnamePipe.transform(obsParam?.observationdefinitions),
        loinc: obsParam.observationdefinitions?.loinc,
        reference: obsParam.reference,
        itemType: ACTION_TARGET.OBSERVATION,
      });
    });
  }

  /**
   * Filters ICareplanQuestionnaireInfos[] by origin and maps them to IlinkableQuestionnaireItem[] array
   * @param allObservations
   * @param origin
   */
  public mapObservationsInfos(allObservations: IObservationsLoincsInfo[], origin: "link2careplan" | "activity"): IlinkableObsItem[] {
    return allObservations
      .filter((k) => k.origin === origin)
      .map((obsInfo) => {
        return new LinkableObsItem({
          uniqueId: obsInfo.reference,
          originReference: obsInfo.originReference,
          origin: obsInfo.origin,
          name: this.getShortnamePipe.transform(obsInfo),
          loinc: obsInfo.loinc,
          reference: obsInfo.reference,
          itemType: ACTION_TARGET.OBSERVATION,
        });
      });
  }

  /**
   * Opens the modal to add a new observation, parameter and rule.
   * When everything is saved it refreshes the observation params list
   */
  public addObservation(): void {
    this.dialog
      .open(ImportObsAndRuleComponent, { disableClose: true })
      .afterClosed()
      .pipe(
        first(),
        mergeMap((saved: boolean) => {
          if (saved) {
            return this.observationsService.listParams(true).pipe(first());
          } else {
            return of(null);
          }
        })
      )
      .subscribe((allObsParams) => {
        if (allObsParams) this.mapAvailableObsParams(allObsParams);
      });
  }

  public async update($event: { item: LinkableObsItem; items: LinkableObsItem[]; action: AVAILABLE_ACTIONS }): Promise<void> {
    await this.careplanEditorService.update($event, ACTION_TARGET.OBSERVATION);
    await this.careplanEditorService.silentSave();
    await this.setupObservations();
  }
}
