import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { QuestionnaireScoring } from "src/app/models/questionnaireScoring.model";
import { ScoreDetailsDataSource } from "./score-details-datasource";

@Component({
  selector: "app-score-details",
  templateUrl: "./score-details.component.html",
  styleUrls: ["./score-details.component.scss"],
})
export class ScoreDetailsComponent implements OnInit, AfterViewInit {
  @Input() scorings: QuestionnaireScoring[];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<QuestionnaireScoring>;

  public displayedColumns: string[] = ["name", "value", "interpretation"];
  public dataSource: ScoreDetailsDataSource;
  public categoryName: string;

  constructor(public translateService: TranslateService) {
    this.dataSource = new ScoreDetailsDataSource();
  }

  ngOnInit(): void {
    this.categoryName = this.scorings[0].identifier?.value;
    this.dataSource.loadData(this.scorings);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.table.dataSource = this.dataSource;
  }
}
