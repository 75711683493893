<div
  [style.width]="chartW + 'px'"
  class="graphCenter"
  *ngIf="observations && superComponents && chartData && observations.length && chartData.length"
>
  <canvas
    id="chart"
    baseChart
    #baseChart="base-chart"
    [datasets]="chartData"
    [labels]="chartLabels"
    [options]="chartOptions"
    [colors]="chartColorsByLoinc | mapChartColorsPipe"
    legend="true"
    [chartType]="'bar'"
  >
  </canvas>
</div>
