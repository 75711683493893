import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IAccessGroup, IAccessGroupPermission } from "src/app/models/accessGroup.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class AccessGroupApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Access groups", // Theme !
      ["dashboard/accessGroup", "dashboard/accessGroupPermission"], // Post route paths
      ["dashboard/accessGroups", "dashboard/accessGroupPermissions"], // Get route paths
      ["dashboard/accessGroup", "dashboard/accessGroupPermission"], // Update route paths
      ["dashboard/accessGroup", "dashboard/accessGroupPermission"] // Delete route paths
    );
  }

  public createAccessGroup(group: IAccessGroup): Observable<unknown> {
    return this.api.post(this.createRoutes[0], group);
  }

  public readAccessGroups(): Observable<IAccessGroup[]> {
    return this.api.get(this.readRoutes[0]) as Observable<IAccessGroup[]>;
  }

  public updateAccessGroup(group: IAccessGroup): Observable<unknown> {
    return this.api.put(this.updateRoutes[0], group);
  }

  public deleteAccessGroup(id: string): Observable<unknown> {
    return this.api.delete(`${this.deleteRoutes[0]}/${id}`, {});
  }

  // --------------------------------------------------------------------------

  public createAccessGroupPermission(permission: IAccessGroupPermission) {
    return this.api.post(this.createRoutes[1], permission);
  }

  public readAccessGroupsPermissions(accessGroupId: string): Observable<IAccessGroupPermission[]> {
    const params: any = { accessGroupId };
    return this.api.get(this.readRoutes[1], params) as Observable<IAccessGroupPermission[]>;
  }

  public updateAccessGroupPermission(permission: IAccessGroupPermission): Observable<unknown> {
    return this.api.put(this.updateRoutes[1], permission);
  }

  public deleteAccessGroupPermission(id: string): Observable<unknown> {
    return this.api.delete(`${this.deleteRoutes[1]}/${id}`, {});
  }
}
