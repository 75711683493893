<div class="aboveTableElems">
  <mat-checkbox
    class="ml-15"
    *ngIf="nbPermissions > pageSize"
    [checked]="showPaginator"
    (change)="$event ? toggleShowPaginator($event.checked) : null"
  >
    {{ "groupPermission.activePaginator" | translate }}
  </mat-checkbox>
  <button mat-button *ngIf="selection.hasValue()" (click)="deleteSelectedPermissions()">
    <mat-icon class="custom-icon" color="warn">delete</mat-icon>
    {{ "groupPermission.deleteAllSelected" | translate }}
  </button>
</div>
<table mat-table matSort matSortDirection="desc" matSortActive="startDate" [formGroup]="permissionForm">
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        class="ml-15"
        *ngIf="needCheck"
        (change)="$event ? toggleSelectAllPerms($event.checked) : null"
        [checked]="selection.hasValue()"
        [indeterminate]="selection.hasValue() && selection.selected.length !== dataSource.data.length"
      >
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox
        class="ml-15"
        *ngIf="needCheck && element"
        (change)="$event ? selection.toggle(element) : null"
        [checked]="selection.isSelected(element)"
      >
      </mat-checkbox>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <!-- theme Column -->
  <ng-container matColumnDef="theme">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "groupPermission.theme" | translate }}
      <app-item-filter
        *ngIf="needFilters"
        fieldName="{{ 'groupPermission.theme' | translate }}"
        propertyName="theme"
        [dataType]="dataType.TEXT"
        [defaultValue]="dataSource.getFilter('theme')"
        (applyFilter)="dataSource.setFilter($event)"
      >
      </app-item-filter>
    </th>
    <td mat-cell *matCellDef="let element">
      <span>{{ element.theme }}</span>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <!-- For create mode (should only concern the last row )-->
      <mat-form-field *ngIf="needCreate">
        <mat-label>{{ "groupPermission.theme" | translate }}</mat-label>
        <mat-select formControlName="theme">
          <mat-option *ngFor="let item of unusedRoutes" [value]="item.theme">
            {{ item.theme }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <!-- method Column -->
  <ng-container matColumnDef="method">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "groupPermission.method" | translate }}
      <app-item-filter
        *ngIf="needFilters"
        fieldName="{{ 'groupPermission.method' | translate }}"
        propertyName="method"
        [data]="true"
        translatePrefix="groupPermission"
        [dataType]="dataType.CHOICE"
        [defaultValue]="dataSource.getFilter('method')"
        (applyFilter)="dataSource.setFilter($event)"
      >
      </app-item-filter>
    </th>
    <td mat-cell *matCellDef="let element">
      <span>{{ "groupPermission." + element.method | translate }}</span>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <!-- For create mode (should only concern the last row )-->
      <mat-form-field *ngIf="needCreate">
        <mat-label>{{ "groupPermission.method" | translate }}</mat-label>
        <mat-select formControlName="method">
          <mat-option *ngFor="let item of currentThemeMethods" [value]="item">
            {{ "groupPermission." + item | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <!-- routeName Column -->
  <ng-container matColumnDef="routeName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "groupPermission.routeName" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <span>{{ element.routeName }}</span>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <!-- For create mode (should only concern the last row )-->
      <mat-form-field *ngIf="needCreate">
        <mat-label>{{ "groupPermission.routeName" | translate }}</mat-label>
        <mat-select formControlName="routesNames" multiple>
          <mat-option #allSelected (click)="toggleAllRoutesSelected()" [value]="'all'">{{ "groupPermission.all" | translate }}</mat-option>
          <mat-option *ngFor="let item of currentThemeMethodsRoutes" [value]="item" (click)="toggleOneRoutesSelected()">
            {{ item }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <!-- access Column -->
  <ng-container matColumnDef="access">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "groupPermission.access" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <span>{{ "groupPermission." + element.access | translate }}</span>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <!-- For create mode (should only concern the last row )-->
      <mat-form-field *ngIf="needCreate">
        <mat-label>{{ "groupPermission.access" | translate }}</mat-label>
        <mat-select formControlName="access">
          <mat-option *ngFor="let item of accessList" [value]="item">
            {{ "groupPermission." + item | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <!-- action Column -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>
      {{ "groupPermission.action" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button (click)="deleteOnePermission(element)" matTooltip="{{ 'common.delete' | translate }}">
        <mat-icon class="custom-icon" color="warn">delete</mat-icon>
      </button>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <!-- For create mode (should only concern the last row )-->
      <button mat-icon-button *ngIf="needCreate" (click)="createPermissions()" matTooltip="{{ 'groupPermission.create' | translate }}">
        <mat-icon class="custom-icon" color="primary">check</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    [ngClass]="{
      restricted: row && row.access === ACCESS.RESTRICTED,
      authorized: row && row.access === ACCESS.AUTHORIZED,
      unallowed: row && row.access === ACCESS.UNDEFINED
    }"
  ></tr>
</table>
<mat-paginator
  [ngClass]="{ hide: !showPaginator || nbPermissions <= pageSize }"
  #paginator
  [pageIndex]="0"
  [pageSize]="pageSize"
  [hidePageSize]="true"
></mat-paginator>
