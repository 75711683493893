<div class="buttons-bar">
  <button
    mat-icon-button
    class="negative-margin align-middle color-button"
    matTooltipClass="helpTooltip"
    matTooltipPosition="left"
    #tooltip="matTooltip"
    matTooltip="{{ 'globalHelp.addVideoCallHelpP1' | translate }}"
    aria-label="icon button with a ? icon"
    (click)="tooltip.toggle()"
  >
    <mat-icon>help_outline</mat-icon>
  </button>

  <button
    mat-icon-button
    color="primary"
    class="negative-margin align-middle"
    matTooltip="{{ 'common.save' | translate }}"
    (click)="save()"
  >
    <mat-icon>save</mat-icon>
  </button>

  <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close matTooltip="{{ 'btn.close' | translate }}">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <h2>{{ "knowledgebase.comparison" | translate }}</h2>
  <div class="flex-row">
    <!-- FORM OF THE PUBLISHED VERSION -->

    <form [formGroup]="publishedMediaForm" class="editor-container mr-5">
      <h3>{{ "knowledgebase.publishedVersion" | translate }}</h3>

      <!-- Title -->
      <div class="one-line">
        <h4>
          {{ "knowledgebase.label" | translate }}
        </h4>
        <mat-form-field class="label-field" appearance="standard">
          <input matInput type="text" formControlName="label" [placeholder]="'knowledgebase.label' | translate" readonly="true" />
        </mat-form-field>
      </div>

      <!-- Short Description -->
      <div class="title">
        <mat-icon>edit</mat-icon>
        <h4>{{ "knowledgebase.shortDescription" | translate }}</h4>
      </div>
      <mat-form-field class="w-full" appearance="outline">
        <textarea
          rows="3"
          formControlName="description"
          matInput
          cdkTextareaAutosize
          placeholder="Short Description"
          readonly="true"
        ></textarea>
      </mat-form-field>

      <mat-card class="left-card" formArrayName="content">
        <!-- Page management (create, delete, choose) -->
        <app-pages-editor
          *ngIf="publishedSlidesList"
          [pages]="publishedSlidesList.length"
          [currentPage]="publishedCurrentSlide"
          (currentPageChange)="onPublishedSlideChanged($event)"
          [translationKey]="'slide'"
        ></app-pages-editor>

        <ng-container *ngFor="let slide of publishedSlidesList; let index = index">
          <div [formGroupName]="index" *ngIf="publishedCurrentSlide === index">
            <mat-form-field class="label-field" appearance="standard" *ngIf="publishedContent.at(index).get('showInSummary').value">
              <input matInput type="text" formControlName="title" [placeholder]="'knowledgebase.label' | translate" readonly="true" />
            </mat-form-field>

            <mat-checkbox formControlName="showInSummary" class="mt-1 mb-1" disabled="true">
              {{ "page.knowledgeMediaEditor.showInSummary" | translate }}
            </mat-checkbox>

            <jodit-editor formControlName="text" [config]="joditConfigReadOnly" class="editor mat-elevation-z1"> </jodit-editor>
          </div>
        </ng-container>
      </mat-card>

      <!-- Language -->
      <div class="one-line mt-1">
        <!-- Here we use FA icon instead of mat-icon to be able to use the flex property "baseline" -->
        <h4>
          <i class="fas fa-globe-europe"></i>
          {{ "languages.lang" | translate }}
        </h4>
        <mat-form-field>
          <mat-select formControlName="language" placeholder="{{ 'languages.lang' | translate }}" disabled="true">
            <mat-option *ngFor="let lang of availableLangs" [value]="lang.term">
              {{ lang | getTranslation : lang.term }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Media Type -->
      <div class="one-line">
        <!-- Here we use FA icon instead of mat-icon to be able to use the flex property "baseline" -->
        <h4>
          <i class="fas fa-file"></i>
          {{ "knowledgebase.mediatype.label" | translate }}
        </h4>
        <mat-form-field>
          <mat-select formControlName="type" placeholder="{{ 'knowledgebase.mediatype.label' | translate }}" disabled="true">
            <mat-option *ngFor="let type of availableTypes" [value]="type">
              {{ "knowledgebase.mediatype." + type | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Visible for -->
      <div class="one-line">
        <h4>
          <i class="fas fa-eye"></i>
          {{ "drugSchema.visibility" | translate }}
        </h4>
        <div style="display: flex; flex-direction: column">
          <mat-checkbox formControlName="visibleForPatient" disabled="true">{{ "menu.patients" | translate }}</mat-checkbox>
          <mat-checkbox style="margin-top: 5px" formControlName="visibleForMonitoring" disabled="true">{{
            "knowledgebase.monitoringPerson" | translate
          }}</mat-checkbox>
        </div>
      </div>

      <!-- Importance -->
      <div class="one-line">
        <!-- Here we use FA icon instead of mat-icon to be able to use the flex property "baseline" -->
        <h4>
          <i class="fas fa-star"></i>
          {{ "knowledgebase.importance" | translate }}
        </h4>

        <div>
          <button disabled="true" mat-icon-button *ngFor="let level of [1, 2, 3]">
            <mat-icon class="star-icon" [class]="data.publishedMedia.importanceLevel >= level ? 'warning' : ''">
              {{ data.publishedMedia.importanceLevel >= level ? "star" : "star_border" }}
            </mat-icon>
          </button>
        </div>
      </div>
    </form>

    <!-- FORM OF THE DRAFT VERSION -->

    <form [formGroup]="draftMediaForm" class="editor-container ml-5">
      <h3>{{ "knowledgebase.draft" | translate }}</h3>

      <!-- Title -->
      <div class="one-line">
        <h4>
          {{ "knowledgebase.label" | translate }}
        </h4>
        <mat-form-field class="label-field" appearance="standard">
          <input matInput type="text" formControlName="label" [placeholder]="'knowledgebase.label' | translate" />
        </mat-form-field>
      </div>

      <!-- Short Description -->
      <div class="title">
        <mat-icon>edit</mat-icon>
        <h4>{{ "knowledgebase.shortDescription" | translate }}</h4>
      </div>
      <mat-form-field class="w-full" appearance="outline">
        <textarea rows="3" formControlName="description" matInput cdkTextareaAutosize placeholder="Short Description"></textarea>
      </mat-form-field>

      <mat-card class="left-card" formArrayName="content">
        <!-- Page management (create, delete, choose) -->
        <app-pages-editor
          *ngIf="draftSlidesList"
          [pages]="draftSlidesList.length"
          [currentPage]="draftCurrentSlide"
          (currentPageChange)="onDraftSlideChanged($event)"
          (onCreateNewPage)="createNewSlide()"
          (onRemovePage)="removeSlide($event)"
          [translationKey]="'slide'"
        ></app-pages-editor>

        <ng-container *ngFor="let slide of draftSlidesList; let index = index">
          <div [formGroupName]="index" *ngIf="draftCurrentSlide === index">
            <mat-form-field class="label-field" appearance="standard" *ngIf="draftContent.at(index).get('showInSummary').value">
              <input matInput type="text" formControlName="title" [placeholder]="'knowledgebase.label' | translate" />
            </mat-form-field>
            <mat-checkbox formControlName="showInSummary" class="mt-1 mb-1">
              {{ "page.knowledgeMediaEditor.showInSummary" | translate }}
            </mat-checkbox>

            <jodit-editor formControlName="text" [config]="joditConfig" class="editor mat-elevation-z1"> </jodit-editor>
          </div>
        </ng-container>
      </mat-card>

      <!-- Language -->
      <div class="one-line mt-1">
        <!-- Here we use FA icon instead of mat-icon to be able to use the flex property "baseline" -->
        <h4>
          <i class="fas fa-globe-europe"></i>
          {{ "languages.lang" | translate }}
        </h4>
        <mat-form-field>
          <mat-select formControlName="language" placeholder="{{ 'languages.lang' | translate }}">
            <mat-option *ngFor="let lang of availableLangs" [value]="lang.term">
              {{ lang | getTranslation : lang.term }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Media Type -->
      <div class="one-line">
        <!-- Here we use FA icon instead of mat-icon to be able to use the flex property "baseline" -->
        <h4>
          <i class="fas fa-file"></i>
          {{ "knowledgebase.mediatype.label" | translate }}
        </h4>
        <mat-form-field>
          <mat-select formControlName="type" placeholder="{{ 'knowledgebase.mediatype.label' | translate }}">
            <mat-option *ngFor="let type of availableTypes" [value]="type">
              {{ "knowledgebase.mediatype." + type | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Visible for -->
      <div class="one-line">
        <h4>
          <i class="fas fa-eye"></i>
          {{ "drugSchema.visibility" | translate }}
        </h4>
        <div style="display: flex; flex-direction: column">
          <mat-checkbox formControlName="visibleForPatient">{{ "menu.patients" | translate }}</mat-checkbox>
          <mat-checkbox style="margin-top: 5px" formControlName="visibleForMonitoring">{{
            "knowledgebase.monitoringPerson" | translate
          }}</mat-checkbox>
        </div>
      </div>

      <!-- Importance -->
      <div class="one-line">
        <!-- Here we use FA icon instead of mat-icon to be able to use the flex property "baseline" -->
        <h4>
          <i class="fas fa-star"></i>
          {{ "knowledgebase.importance" | translate }}
        </h4>
        <div>
          <button mat-icon-button (click)="setImportanceLevel(level)" *ngFor="let level of [1, 2, 3]">
            <mat-icon class="star-icon" [class]="importanceLevel >= level ? 'warning' : ''">
              {{ importanceLevel >= level ? "star" : "star_border" }}
            </mat-icon>
          </button>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
