<div class="loading" *ngIf="loading">
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
  {{ "common.loading" | translate }}
</div>

<div class="deleteRequestListPage" *ngIf="!loading">
  <div class="toolbar">
    <div class="left">
      <!-- Global search input -->
      <mat-form-field>
        <mat-label><span class="fa fa-search mr-5"></span>{{ "itemFilter.search" | translate }}</mat-label>
        <input type="search" matInput #searchInput autocomplete="off" />
        <button
          type="button"
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="searchInput.value = ''"
          [style.visibility]="searchInput.value ? 'visible' : 'hidden'"
          #searchClearBtn
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="spacer"></div>
    <button mat-flat-button color="warn" *ngIf="isFiltered" (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
      <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
    </button>
    <button mat-stroked-button *ngIf="!isFiltered" (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
      <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
    </button>
  </div>
  <app-filters-display [filters]="filters" (removeFilter)="applyFilter($event)"> </app-filters-display>
  <mat-card class="mat-elevation-z0 pt-0">
    <mat-table class="mat-elevation-z1" [dataSource]="dataSource" matSort matSortActive="term" matSortDirection="asc">
      <!-- Request id column -->
      <ng-container matColumnDef="identifier.value">
        <mat-header-cell *matHeaderCellDef>
          {{ "page.deleteRequestList.table.requestId" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let delRequest">
          {{ delRequest.identifier.value }}
        </mat-cell>
      </ng-container>
      <!-- Patient firstname column -->
      <ng-container matColumnDef="patient.firstname">
        <mat-header-cell *matHeaderCellDef>
          {{ "page.deleteRequestList.table.firstName" | translate }}
        </mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let delRequest">
          {{ delRequest.patient.firstname }}
        </mat-cell>
      </ng-container>
      <!-- Patient lastname column -->
      <ng-container matColumnDef="patient.lastname">
        <mat-header-cell *matHeaderCellDef>
          {{ "page.deleteRequestList.table.lastName" | translate }}
        </mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let delRequest">
          {{ delRequest.patient.lastname }}
        </mat-cell>
      </ng-container>
      <!-- Request date column -->
      <ng-container matColumnDef="requestDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "page.deleteRequestList.table.requestDate" | translate }}
          <app-item-filter
            fieldName="{{ 'page.deleteRequestList.table.requestDate' | translate }}"
            propertyName="requestDate"
            [dataType]="dataTypeDate"
            [translateFieldKey]="'page.deleteRequestList.table.requestDate'"
            [defaultValue]="getFilter('requestDate')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell class="duration-cell" *matCellDef="let delRequest">
          {{ delRequest.requestDate | date : "dd/MM/yyyy" }}
        </mat-cell>
      </ng-container>
      <!-- Request status column -->
      <ng-container matColumnDef="requestStatus">
        <mat-header-cell *matHeaderCellDef>
          {{ "page.deleteRequestList.table.requestStatus" | translate }}
          <app-item-filter
            fieldName="{{ 'page.deleteRequestList.table.requestStatus' | translate }}"
            propertyName="requestStatus"
            [dataType]="dataTypeChoice"
            [data]="dataSource.data"
            [defaultValue]="getFilter('requestStatus')"
            [translateFieldKey]="'page.deleteRequestList.table.requestStatus'"
            (applyFilter)="applyFilter($event)"
            translatePrefix="page.deleteRequestList.requestStatus"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell class="duration-cell" *matCellDef="let delRequest">
          {{ "page.deleteRequestList.requestStatus." + delRequest.requestStatus | translate }}
        </mat-cell>
      </ng-container>
      <!-- Treatment date column -->
      <ng-container matColumnDef="treatmentDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "page.deleteRequestList.table.treatedDate" | translate }}
          <app-item-filter
            fieldName="{{ 'page.deleteRequestList.table.treatedDate' | translate }}"
            propertyName="treatmentDate"
            [dataType]="dataTypeDate"
            [translateFieldKey]="'page.deleteRequestList.table.treatedDate'"
            [defaultValue]="getFilter('treatmentDate')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell class="duration-cell" *matCellDef="let delRequest">
          {{ delRequest?.treatmentDate | date : "dd/MM/yyyy" }}
        </mat-cell>
      </ng-container>
      <!-- Request controller column -->
      <ng-container matColumnDef="controller.display">
        <mat-header-cell *matHeaderCellDef>
          {{ "page.deleteRequestList.table.incharge" | translate }}
        </mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let delRequest">
          {{ delRequest?.controller?.display }}
        </mat-cell>
      </ng-container>
      <!-- Archive filename column -->
      <ng-container matColumnDef="archiveFilename">
        <mat-header-cell *matHeaderCellDef>
          {{ "page.deleteRequestList.table.archiveName" | translate }}
        </mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let delRequest">
          {{ delRequest?.archiveFilename }}
        </mat-cell>
      </ng-container>
      <!-- Action column -->
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef>
          {{ "table.action" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <!-- Resend archive -->
          <button
            [disabled]="isSendingArchive"
            mat-icon-button
            matTooltip="{{ 'page.deleteRequestList.btn.resendArchive' | translate }}"
            aria-label="icon button with an outbox icon"
            *ngIf="
              (row.requestStatus === deleteRequestType.WAITING_FOR_ARCHIVE_DOWNLOAD ||
                row.requestStatus === deleteRequestType.ARCHIVE_DOWNLOADED) &&
              ('resendArchiveDownload' | isAuthorized : 'POST' | async) === true
            "
            (click)="resendArchive(row)"
          >
            <mat-icon class="custom-icon-small sub-align">outbox</mat-icon>
          </button>
          <!-- Download archive -->
          <button
            mat-icon-button
            matTooltip="{{ 'page.deleteRequestList.btn.downloadArchive' | translate }}"
            aria-label="icon button with an inbox icon"
            *ngIf="
              (row.requestStatus === deleteRequestType.WAITING_FOR_ARCHIVE_DOWNLOAD ||
                row.requestStatus === deleteRequestType.ARCHIVE_DOWNLOADED) &&
              ('dashboard/archive' | isAuthorized | async) === true
            "
            (click)="downloadArchive(row)"
          >
            <mat-icon fontSet="fas" fontIcon="fa-download"></mat-icon>
          </button>
          <!-- End request -->
          <button
            mat-icon-button
            matTooltip="{{ 'page.deleteRequestList.btn.end' | translate }}"
            aria-label="icon button with a double check icon"
            *ngIf="row.requestStatus === deleteRequestType.ARCHIVE_DOWNLOADED && ('deleteRequest' | isAuthorized : 'PUT' | async) === true"
            (click)="endRequest(row)"
          >
            <mat-icon class="custom-icon-small sub-align success">done_all</mat-icon>
          </button>
          <!-- Accept request -->
          <button
            mat-icon-button
            matTooltip="{{ 'page.deleteRequestList.btn.accept' | translate }}"
            aria-label="icon button with a check icon"
            *ngIf="row.requestStatus === deleteRequestType.ONGOING && ('deleteRequest' | isAuthorized : 'PUT' | async) === true"
            (click)="acceptRequest(row)"
          >
            <mat-icon class="custom-icon-small sub-align success">done</mat-icon>
          </button>
          <!-- Change status to on going -->
          <button
            mat-icon-button
            matTooltip="{{ 'page.deleteRequestList.btn.setOngoing' | translate }}"
            aria-label="icon button with a play icon"
            *ngIf="row.requestStatus === deleteRequestType.NEW && ('deleteRequest' | isAuthorized : 'PUT' | async) === true"
            (click)="setRequestToOngoing(row)"
          >
            <mat-icon class="custom-icon-small sub-align warn">play_arrow</mat-icon>
          </button>
          <!-- Cancel request -->
          <button
            mat-icon-button
            matTooltip="{{ 'page.deleteRequestList.btn.cancel' | translate }}"
            aria-label="icon button with a cross icon"
            *ngIf="row.requestStatus !== deleteRequestType.CANCELED && ('deleteRequest' | isAuthorized : 'PUT' | async) === true"
            (click)="cancelRequest(row)"
          >
            <mat-icon class="custom-icon-small sub-align fail">close</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <!-- PAGANITOR MANAGEMENT - length = total number of element via a Resolver!!-->
    <mat-paginator
      class="mat-elevation-z1"
      pageIndex="0"
      [length]="deleteRequestCount"
      [pageSize]="currentPageSize"
      [pageSizeOptions]="[10, 25, 50, 100]"
    ></mat-paginator>
  </mat-card>
</div>
