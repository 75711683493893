import { NgModule } from "@angular/core";
import { ItemFilterComponent } from "./item-filter.component";
import { DateFilterComponent } from "./filters/date-filter/date-filter.component";
import { TextFilterComponent } from "./filters/text-filter/text-filter.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { I18nModule } from "src/app/i18n.module";
import { ChoiceFilterComponent } from "./filters/choice-filter/choice-filter.component";
import { MatCheckboxModule } from "@angular/material/checkbox";

@NgModule({
  declarations: [ItemFilterComponent, TextFilterComponent, DateFilterComponent, ChoiceFilterComponent],
  imports: [
    I18nModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatIconModule,
  ],
  exports: [ItemFilterComponent],
})
export class ItemFilterModule {}
