<div class="ra-details">
  <div class="close-cross">
    <button mat-icon-button (click)="openPdf(risk)" *ngIf="risk | RaHasPdf">
      <mat-icon color="primary">picture_as_pdf</mat-icon>
    </button>
    <button mat-icon-button color="warn" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <h3>
    {{ (dataSource?.length || pValuesDataSource?.data?.length > 1 ? "risk.titleDetails" : "risk.titleDetail") | translate }}
  </h3>

  <!-- Summary Gauge -->
  <p>{{ "risk.gaugeTitle" | translate }}</p>
  <div class="gauge">
    <div
      *ngFor="let el of risk | riskGauge"
      [style.width]="el.value + '%'"
      [style.backgroundColor]="el.color"
      [style.padding]="el.value ? '2px' : 0"
      matTooltip=" {{ el.value }}% {{ risk.getTranslatedClassName(translateService, el.key) }}"
    >
      <p *ngIf="el.value >= 5">{{ el.value }}% {{ risk.getTranslatedClassName(translateService, el.key) }}</p>
    </div>
  </div>

  <table mat-table *ngIf="!risk?.context?.pValues?.length" [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z1">
    <ng-container matColumnDef="algoName">
      <th mat-header-cell *matHeaderCellDef>
        {{ "risk.algoName" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ "risk.algo." + element.algo.rationale | translate }}
        {{ element.algo.version ? " (" + element.algo.version + ") " : "" }}
      </td>
    </ng-container>

    <ng-container
      matColumnDef="{{ column }}"
      *ngFor="
        let column of columnsToDisplay
          | slice : 1 : (dataSource.length > 1 && risk.riskName === 'Risk_Copd_Triaging_Binary' ? -1 : undefined)
      "
    >
      <th mat-header-cell *matHeaderCellDef class="spaceBetweenColumn">
        {{ risk.getTranslatedClassName(translateService, column) }}
      </th>
      <td mat-cell *matCellDef="let element" class="spaceBetweenColumn">{{ element[column].proba }} %</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="spaceBetweenColumn"></th>
      <td mat-cell *matCellDef="let element" class="spaceBetweenColumn">
        <mat-icon class="custom-icon-small sub-align">search</mat-icon>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="grid" [@detailExpand]="element === expandedElement || element.algo.rationale === algoETS ? 'expanded' : 'collapsed'">
          <ui-scroll-container
            [ngSwitch]="element.algo?.rationale"
            (scrollChanged)="computeShowScrollHint($event)"
            *ngIf="(element === expandedElement && this.chartLoaded) || element.algo.rationale === algoETS"
            style="justify-content: center"
          >
            <ng-container *ngSwitchCase="algoGBTP">
              <div class="chart-container">
                <h3>{{ "risk.predictionTitle" | translate }}</h3>
                <app-risk-assessment-chart [chartData]="chartData"> </app-risk-assessment-chart>
                <div class="caption">
                  <p [innerHTML]="captionRAChart"></p>
                  <p [innerHTML]="captionRAChart2"></p>
                </div>
              </div>
              <div class="chart-container">
                <h3>{{ "risk.modelTitle" | translate }}</h3>
                <img [src]="'data:image/png;base64,' + chartExpImg" height="500" />
                <div class="caption">
                  <p>
                    {{ "Risk_Copd_Triaging_Binary.captionModelExplanation" | translate }}
                  </p>
                </div>
              </div>
            </ng-container>

            <div *ngSwitchCase="algoETS">
              <div class="chart-container" *ngIf="illustration">
                <img [src]="'data:image/png;base64,' + illustration" height="500" />
                <p class="caption" *ngIf="enoughData">
                  {{ "risk.caption." + element.algo.rationale + ".enoughData" | translate }}
                </p>
                <p class="caption" *ngIf="!enoughData">
                  {{ "risk.caption." + element.algo.rationale + ".notEnoughData" | translate }}
                </p>
              </div>
              <div class="chart-container" *ngIf="!illustration && noData">
                <p class="caption">
                  {{ "risk.caption." + element.algo.rationale + ".noData" | translate }}
                </p>
              </div>
            </div>
          </ui-scroll-container>
          <div class="scroll-hint" *ngIf="showScrollHint">
            <mat-icon class="icon" fontSet="fas" fontIcon="fa-chevron-circle-right"></mat-icon>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: columnsToDisplay"
      class="element-row"
      [ngClass]="element.algo?.rationale === algoGBTP || element.algo?.rationale === algoETS ? '' : 'disabled'"
      [class.expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"
      (click)="loadChart(element)"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  </table>

  <!-- Table for algo with context -->
  <table mat-table matSort *ngIf="risk?.context?.pValues?.length" [dataSource]="pValuesDataSource" class="mat-elevation-z1">
    <!-- Features column -->
    <ng-container matColumnDef="features">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "risk.features" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.code.coding[0].display }}
      </td>
    </ng-container>

    <!-- pValues column -->
    <ng-container matColumnDef="pValues">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "risk.pValues" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.pValue }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay" class="element-row"></tr>
  </table>
  <mat-paginator
    *ngIf="risk?.context?.pValues?.length"
    [length]="pValuesDataSource.data.length"
    pageSize="10"
    [pageSizeOptions]="[10, 25, 100]"
  ></mat-paginator>
</div>
