import { IObservationDefinition } from "../models/observations.interface";
import { Practitioner } from "../models/practitioner.model";
import { IQuestionnaire } from "../models/questionnaire.interface";
import { IReference } from "../models/sharedInterfaces";
import { Tools } from "./tools";

export class ArrayHelper {
  /**
   * This method allows to transform an array into an array with unique values
   * (basic test with indexOf => for complex objects, we must personalized the test)
   * @param value
   * @param index
   * @param array
   */
  public static onlyUnique<T>(value: T, index: number, array: T[]): boolean {
    return array.indexOf(value) === index;
  }

  public static isEmpty(array: any[]): boolean {
    if (!Tools.isDefined(array)) {
      return true;
    }
    if (!Array.isArray(array)) {
      return true;
    }
    if (array.length === 0) {
      return true;
    }
    return false;
  }

  /**
   * Filter a practitioner list to remove duplicate practitionners in multiples services
   * @param value A practitioner
   * @param index i
   * @param array The practitioner list
   * @returns result : is duplicate or not
   */
  public static onlyUniquePractitionner(value: Practitioner, index: number, array: Practitioner[]): boolean {
    return (
      array.findIndex((prac) => {
        return value._id === prac._id;
      }) === index
    );
  }

  /**
   * Filter a practitioner list to remove duplicate practitionners in multiples services
   * @param value A practitioner
   * @param index i
   * @param array The practitioner list
   * @returns result : is duplicate or not
   */
  public static onlyUniqueQuestionnaire(value: IQuestionnaire, index: number, array: IQuestionnaire[]): boolean {
    return (
      array.findIndex((quest) => {
        return value._id === quest._id;
      }) === index
    );
  }

  /**
   * Filter a refrence list to remove duplicates
   * @param value A reference
   * @param index i
   * @param array The refernces list
   * @returns result : is duplicate or not
   */
  public static onlyUniqueReference(value: IReference, index: number, array: IReference[]): boolean {
    return (
      array.findIndex((r) => {
        return value.reference === r.reference;
      }) === index
    );
  }

  /**
   * Filter a observation definition list to remove duplicates loinc
   * @param value A reference
   * @param index i
   * @param array The observation definitionn list
   * @returns result : is duplicate or not
   */
  public static onlyUniqueLoincObsDef(value: IObservationDefinition, index: number, array: IObservationDefinition[]): boolean {
    return (
      array.findIndex((r) => {
        return value.loinc === r.loinc;
      }) === index
    );
  }

  /**
   *
   * @param array a 2D-array
   * @returns the transposition of this array
   */
  public static transpose(array: any[][]): any[][] {
    return array.reduce((prev, next) => next.map((item, i) => (prev[i] || []).concat(next[i])), []);
  }
}
