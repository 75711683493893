import { CdkDrag } from "@angular/cdk/drag-drop";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { Subject, merge } from "rxjs";
import { first, tap } from "rxjs/operators";
import { Base64Helper } from "src/app/helpers/Base64Helper";
import { HelpData } from "src/app/helpers/helpData";
import { DataType, Filter } from "src/app/models/filter.interface";
import { PreferenceContext, WidgetPatientListParameter } from "src/app/models/preference.interface";
import { IWidgetComponent, WidgetName } from "src/app/models/widget.interface";
import { PatientDataSource } from "src/app/pages/patient-list-page/patient-list/patient.datasource";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PatientService } from "src/app/providers/patient.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { UserStatisticsService } from "src/app/providers/userStatistics.service";
import { GlobalHelpDialogComponent } from "../../global-help-dialog/global-help-dialog.component";

@Component({
  selector: "app-patient-list-widget",
  templateUrl: "./patient-list-widget.component.html",
  styleUrls: ["./patient-list-widget.component.scss"],
})
export class PatientListWidgetComponent implements IWidgetComponent, OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(CdkDrag, { static: true }) cdkDrag: CdkDrag;

  public dataTypeDate = DataType.DATE;
  public dataTypeChoice: DataType.CHOICE;
  public isDraggable = false;
  public displayedColumns: string[] = ["name", "birthDate"];
  public dataSource: PatientDataSource;
  public isVisible: boolean;
  public filters: Filter[];
  private withInactive = true;
  public groupId: string;
  public patientsCount: number;
  public search: string;
  public widgetName = WidgetName.PATIENT_LIST;

  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();

  constructor(
    private preferenceService: PreferenceService,
    private router: Router,
    private patientService: PatientService,
    private sessionService: SessionService,
    private healthcareService: HealthcareserviceService,
    private statService: UserStatisticsService,
    private dialog: MatDialog,
    public helpData: HelpData,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    if (this.router.url === "/dashboard") {
      this.isVisible = false;
    } else {
      this.isVisible = true;
      // Create DataSource and first patients loading
      this.dataSource = new PatientDataSource(this.patientService);
      this.loadPreferences().then(() => {
        if (this.filters?.length > 0) {
          this.filters.forEach((filter) => this.dataSource.setFilter(filter));
        }
        this.loadPatients();
        // on sort or paginate events, load a new page
        merge(this.sort.sortChange, this.paginator.page)
          .pipe(tap(() => this.loadPatients()))
          .subscribe();
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadPatients() {
    let currentServices = [];
    let monitoringServices = [];
    if (!this.sessionService.currentService) {
      currentServices = [];
    } else if (this.sessionService.currentService.reference === this.sessionService.allsOption) {
      currentServices = this.healthcareService.availableServices().map((s) => s.asReference.reference);
    } else {
      currentServices = [this.sessionService.currentService.reference];
    }
    if (this.userService.isMonitoringUser) {
      if (!this.sessionService.currentMonitoringService) {
        monitoringServices = [];
      } else if (this.sessionService.currentMonitoringService.reference === this.sessionService.allsOption) {
        monitoringServices = this.healthcareService.availableMonitoringServices()?.map((s) => s.asReference.reference);
      } else {
        monitoringServices = [this.sessionService.currentMonitoringService?.reference];
      }
    }
    this.dataSource.loadData({
      sortId: this.sort.active,
      sortOrder: this.sort.direction,
      pageNumber: this.paginator.pageIndex,
      pageSize: 10,
      filters: this.getFilters(),
      withInactive: this.withInactive,
      search: this.search,
      groupId: this.groupId,
      lang: this.sessionService.userLang,
      services: currentServices,
      monitoringServicesIds: monitoringServices,
      // if "all", the backend will get all services from the practitioner since the services is undefined
      period: this.sessionService.globalPref?.inactifPatientDuration,
    });
  }

  /**
   * Preferences
   */
  private loadPreferences() {
    return new Promise<void>((resolve) => {
      this.preferenceService
        .list(PreferenceContext.WIDGET_PATIENT_LIST)
        .pipe(first())
        .subscribe((parameters: WidgetPatientListParameter) => {
          if (parameters) {
            this.filters = parameters.filters;
            this.patientsCount = parameters.patientsCount;
            this.search = parameters.search;
            this.groupId = parameters.groupId;
            // Apply saved filters
            if (parameters?.filters?.length) {
              parameters.filters.forEach((filter: Filter) => {
                this.applyFilter(filter);
              });
            }
          }
          resolve();
        });
    });
  }

  private updatePreference() {
    return this.preferenceService.update({
      context: PreferenceContext.WIDGET_PATIENT_LIST,
      parameters: {
        filters: this.filters,
        patientsCount: this.patientsCount,
        search: this.search,
        groupId: this.groupId,
      } as WidgetPatientListParameter,
    });
  }

  public encodeToB64(str: string): string {
    return Base64Helper.utf8_to_b64(str);
  }

  /**
   * Filter
   */
  private getFilters(): Filter[] {
    return (this.filters = this.dataSource.getAllFilters());
  }

  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
    this.updatePreference();
    this.loadPatients();
    let currentServices = [];
    let monitoringServices = [];
    if (!this.sessionService.currentService) {
      currentServices = [];
    } else if (this.sessionService.currentService.reference === this.sessionService.allsOption) {
      currentServices = this.healthcareService.availableServices().map((s) => s.asReference.reference);
    } else {
      currentServices = [this.sessionService.currentService.reference];
    }
    if (this.userService.isMonitoringUser) {
      if (!this.sessionService.currentMonitoringService) {
        monitoringServices = [];
      } else if (this.sessionService.currentMonitoringService.reference === this.sessionService.allsOption) {
        monitoringServices = this.healthcareService.availableMonitoringServices()?.map((s) => s.asReference.reference);
      } else {
        monitoringServices = [this.sessionService.currentMonitoringService?.reference];
      }
    }

    // update patient count with filters result
    this.patientService
      .getPatientsCount(
        currentServices,
        // if "all", the backend will get all services from the practitioner since the services is undefined,
        this.search,
        this.filters,
        this.withInactive,
        this.groupId,
        this.sessionService.globalPref?.inactifPatientDuration,
        monitoringServices
      )
      .pipe(first())
      .subscribe((result) => {
        this.patientsCount = result?.count;
      });
  }

  public openPatientsListWidgetHelp(): void {
    this.dialog.open(GlobalHelpDialogComponent, {
      data: { slides: this.helpData.patientsListWidget },
      disableClose: true,
    });
  }

  public recordUseOfWidget(): void {
    this.statService.createStatEvent("Used patient list widget");
  }
}
