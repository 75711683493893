<div *ngIf="isLoading" class="loading">
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</div>
<form [formGroup]="careplanEditorService.careplanTemplateForm" *ngIf="!isLoading && careplanTemplate">
  <!--------------------->
  <!--  CAREPLAN NAME  -->
  <!--------------------->
  <app-traduction-form
    id="cpEditor-cpNameContainer"
    [formGroup]="descriptionForm"
    [traductionsFormArray]="descriptionTradForm"
    [availableLangs]="availableLangs"
    [placeholder]="'page.careplanEditor.tabs.general.careplanName' | translate"
  ></app-traduction-form>
  <!--------------------->
  <!--  CAREPLAN TYPE  -->
  <!--------------------->
  <div style="display: flex; margin-top: 30px" class="ml-05">
    <h4 class="mr-05">{{ "page.careplanEditor.tabs.general.careplanType" | translate }} :</h4>
    <mat-radio-group
      class="radio"
      formControlName="isLinear"
      [disabled]="typeSelectorDisabled"
      [matTooltip]="'page.careplanEditor.tabs.general.typeSelectorDisabled' | translate"
      [matTooltipDisabled]="!typeSelectorDisabled"
    >
      <mat-radio-button [value]="true"> {{ "page.careplanEditor.tabs.general.linear" | translate }} </mat-radio-button>
      <mat-radio-button [value]="false" style="margin-left: 10px">
        {{ "page.careplanEditor.tabs.general.noLinear" | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <!---------------->
  <!--  END DATE  -->
  <!---------------->
  <div style="margin-top: 30px; display: flex" class="when-input">
    <mat-slide-toggle
      [checked]="careplanEditorService.careplanTemplateForm.get('period.endTiming.repeat.period')"
      (change)="togglePeriod($event)"
      >{{ "page.careplanEditor.tabs.general.scheduleEnddate" | translate }}</mat-slide-toggle
    >

    <app-when-input
      *ngIf="careplanEditorService.careplanTemplateForm.get('period.endTiming.repeat.period')"
      [needShowDirectly]="false"
      [onlyAfter]="true"
      [period]="careplanEditorService.careplanTemplateForm.get('period.endTiming.repeat.period').value"
      (periodChange)="careplanEditorService.careplanTemplateForm.get('period.endTiming.repeat.period').setValue($event)"
      [periodUnit]="careplanEditorService.careplanTemplateForm.get('period.endTiming.repeat.periodUnits').value"
      (periodUnitChange)="careplanEditorService.careplanTemplateForm.get('period.endTiming.repeat.periodUnits').setValue($event)"
      [context]="'careplanEndDate'"
    ></app-when-input>
  </div>
  <!------------------------>
  <!--  LIST OF SERVICES  -->
  <!------------------------>
  <mat-expansion-panel style="margin-top: 30px">
    <mat-expansion-panel-header>
      <mat-panel-title> {{ "forms.services" | translate }} </mat-panel-title>
      <mat-panel-description> {{ "page.careplanEditor.tabs.general.selectServices" | translate }} </mat-panel-description>
    </mat-expansion-panel-header>
    <div *ngFor="let s of availableServices">
      <mat-checkbox [checked]="s.checked" (change)="healthcareServiceChange(s)">
        <p>{{ s.service.serviceName }} ({{ s.service.organizationName }})</p>
      </mat-checkbox>
    </div>
  </mat-expansion-panel>
  <!--------------------->
  <!--  LIST OF TYPES  -->
  <!--------------------->
  <div style="margin-top: 30px">
    <h4>{{ "page.careplanEditor.tabs.general.types" | translate }} :</h4>
    <div formArrayName="addresses">
      <div
        *ngFor="let a of addressesForm.controls; let iAddress = index"
        [formGroupName]="iAddress"
        style="display: flex; align-items: center; margin-right: 10px"
      >
        <mat-expansion-panel style="width: 100%" class="mb-05">
          <mat-expansion-panel-header>
            <mat-panel-title style="width: 50%; overflow: hidden; max-width: 50%; text-wrap: nowrap">
              {{ a | getAddressName : currentLanguage : lastKeystroke }}
            </mat-panel-title>
            <!-- <mat-panel-description>
            </mat-panel-description> -->
          </mat-expansion-panel-header>
          <app-traduction-form
            [formGroup]="a.get('display')"
            [traductionsFormArray]="a.get('display').get('traductions')"
            [availableLangs]="availableLangs"
            [placeholder]="'page.careplanEditor.tabs.general.addressName' | translate"
            (keystroke)="lastKeystroke = $event"
          ></app-traduction-form>

          <div style="margin-top: 2rem; display: flex; flex-direction: column">
            <!-- Reference (code snomed) -->
            <mat-form-field style="width: 300px">
              <mat-label>{{ "common.snomedRef" | translate }}</mat-label>
              <input formControlName="reference" matInput />
            </mat-form-field>

            <!-- Preselect address ? -->
            <mat-checkbox
              [checked]="a.get('status').value === 'active' ? true : false"
              (change)="statusChange(a.get('status'), $event.checked)"
            >
              {{ "page.careplanEditor.tabs.general.preselectAddress" | translate }}
            </mat-checkbox>
          </div>
          <div style="display: flex; justify-content: right">
            <button mat-raised-button color="primary" (click)="onDeleteAddress(iAddress)">
              {{ "common.delete" | translate }}
            </button>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
    <button mat-raised-button color="primary" type="button" (click)="onAddAddress()">
      {{ "page.careplanEditor.tabs.general.addType" | translate }}
    </button>
  </div>

  <!-------------------------->
  <!--  LIST OF ACTIVITIES  -->
  <!-------------------------->
  <div style="margin-top: 30px">
    <h4>{{ "knowledgebase.knowledgeCriteria.activities" | translate }} :</h4>
    <div>
      <div formArrayName="activity">
        <div
          *ngFor="let a of activityForm.controls; let iActivity = index"
          [formGroupName]="iActivity"
          style="display: flex; align-items: center; margin-right: 10px"
          id="cpEditor-activityContainer"
        >
          <mat-expansion-panel style="width: 100%" class="mb-05">
            <mat-expansion-panel-header>
              <mat-panel-title style="width: 50%; overflow: hidden; max-width: 50%; text-wrap: nowrap">
                {{ a | getActivityName : currentLanguage : lastKeystroke }}
              </mat-panel-title>
              <mat-panel-description>
                {{ "page.careplanEditor.tabs.general.activityType." + a.get("detail.category").value | translate }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <app-traduction-form
              [formGroup]="a.get('reference').get('display')"
              [traductionsFormArray]="a.get('reference.display.traductions')"
              [availableLangs]="availableLangs"
              [placeholder]="'page.careplanEditor.tabs.general.activityName' | translate"
              (keystroke)="lastKeystroke = $event"
            >
              <!-- Project category selector inside the component for proper positioning -->
              <mat-form-field style="width: 300px" class="ml-05">
                <mat-label>{{ "page.careplanEditor.tabs.general.activityType.label" | translate }}</mat-label>
                <mat-select [formControl]="a.get('detail').get('category')" (valueChange)="updateActivityCode($event, a)">
                  <mat-option *ngFor="let cat of availableCategories" [value]="cat">
                    {{ "page.careplanEditor.tabs.general.activityType." + cat | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </app-traduction-form>
            <div formGroupName="detail" style="margin-top: 20px">
              {{ "table.description" | translate }} :
              <app-traduction-form
                [formGroup]="a.get('detail').get('description')"
                [traductionsFormArray]="a.get('detail').get('description').get('traductions')"
                [availableLangs]="availableLangs"
                (keystroke)="lastKeystroke = $event"
              ></app-traduction-form>

              <!--  SNOMED CODE  -->
              <mat-form-field style="width: 300px" class="ml-05" formGroupName="code">
                <mat-label>{{ "common.snomedRef" | translate }}</mat-label>
                <input formControlName="code" matInput id="cpEditor-actSnomedCode" />
              </mat-form-field>

              <div formGroupName="drug" style="margin-top: 20px; display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row">
                  <mat-checkbox formControlName="isDrugsLinked">{{ "page.careplanEditor.tabs.general.addDrugs" | translate }}</mat-checkbox>
                  <mat-checkbox
                    style="margin-left: 10px"
                    *ngIf="a.get('detail').get('drug')?.get('productList')?.value?.length > 0"
                    formControlName="isMandatory"
                  >
                    {{ "page.careplanEditor.tabs.general.mandatoryDrug" | translate }}
                  </mat-checkbox>
                </div>
                <mat-form-field class="chip-list" style="width: 70%" *ngIf="a.get('detail').get('drug').get('isDrugsLinked')?.value">
                  <mat-label>{{ "drugs.title" | translate }}</mat-label>
                  <mat-chip-list #chipList formArrayName="productList">
                    <mat-chip
                      *ngFor="let item of a.get('detail').get('drug').get('productList')?.value; let iProduct = index"
                      [selectable]="false"
                      (removed)="removeDrug(item, iActivity)"
                    >
                      {{ item.display }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input
                      matInput
                      #productListDrugInput
                      [placeholder]="'page.careplanEditor.tabs.general.addDrug' | translate"
                      [matAutocomplete]="auto"
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      (matChipInputTokenEnd)="addDrugToChip($event.value, iActivity)"
                      [formControl]="drugServerSideFilteringCtrl"
                    />
                  </mat-chip-list>
                  <app-drug-server-side-search
                    (ref)="ref($event)"
                    [isCreation]="true"
                    [withoutInput]="true"
                    [drugServerSideFilteringCtrl]="drugServerSideFilteringCtrl"
                    (selectOption)="addDrugToChip($event, iActivity)"
                  ></app-drug-server-side-search>
                </mat-form-field>
              </div>
              <div formGroupName="procedure" style="margin-top: 20px">
                <mat-checkbox
                  class="block"
                  formControlName="isCycle"
                  *ngIf="!careplanEditorService.careplanTemplateForm.get('isLinear').value"
                  (change)="onIsCycleChange($event, a)"
                >
                  {{ "page.careplanEditor.tabs.general.cycleActivity" | translate }}
                </mat-checkbox>
                <mat-checkbox
                  class="block"
                  formControlName="withPerformer"
                  *ngIf="(a | isProcedureActivity) && careplanEditorService.careplanTemplateForm.get('isLinear').value"
                >
                  {{ "page.careplanEditor.tabs.general.addContact" | translate }}
                </mat-checkbox>
                <mat-checkbox
                  class="block"
                  formControlName="withAddressAndPhone"
                  *ngIf="(a | isProcedureActivity) && careplanEditorService.careplanTemplateForm.get('isLinear').value"
                >
                  {{ "page.careplanEditor.tabs.general.addAddressAndPhone" | translate }}
                </mat-checkbox>
              </div>
              <div
                formGroupName="scheduledTiming"
                style="margin-top: 20px"
                *ngIf="a.get('detail.procedure.isCycle').value && !careplanEditorService.careplanTemplateForm.get('isLinear').value"
              >
                <fieldset class="fieldset" formGroupName="repeat">
                  <mat-form-field>
                    <!-- Frequency -->
                    <mat-select formControlName="frequency">
                      <mat-option *ngFor="let time of [1, 2, 3, 4, 5]" [value]="time">{{ time }}x </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-label>{{ "schedule.every" | translate | lowercase }}</mat-label>

                  <mat-form-field>
                    <!-- Period -->
                    <mat-select formControlName="period">
                      <mat-option *ngFor="let time of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]" [value]="time"> {{ time }}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field>
                    <!-- activity period unit -->
                    <mat-select formControlName="periodUnits" [compareWith]="comparePeriodUnitsFn">
                      <mat-option *ngFor="let unit of availablePeriodUnits" [value]="unit.value">
                        {{
                          (a.get("detail.scheduledTiming.repeat.period").value > 1
                            ? unit.translationKey + ".plural"
                            : unit.translationKey + ".long"
                          ) | translate
                        }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </fieldset>
              </div>

              <!-- Preselect Activity ? -->
              <mat-checkbox
                style="margin-top: 20px"
                [checked]="a.get('detail').get('status').value === 'active' ? true : false"
                (change)="statusChange(a.get('detail').get('status'), $event.checked)"
              >
                {{ "page.careplanEditor.tabs.general.preselectActivity" | translate }}
              </mat-checkbox>
            </div>
            <div style="display: flex; justify-content: right">
              <button mat-raised-button color="primary" (click)="onDeleteActivity(iActivity)">
                {{ "page.careplanEditor.tabs.general.deleteActivity" | translate }}
              </button>
            </div>
          </mat-expansion-panel>
        </div>
      </div>
      <button mat-raised-button color="primary" type="button" (click)="onAddActivity()" id="cpEditor-addActivityBtn">
        {{ "page.careplanEditor.tabs.general.addActivity" | translate }}
      </button>
    </div>
  </div>
</form>
