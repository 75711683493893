import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { FiltersDisplayModule } from "src/app/components/filters-display/filters-display.module";
import { ItemFilterModule } from "src/app/components/item-filter/item-filter.module";
import { I18nModule } from "src/app/i18n.module";
import { PipesModule } from "src/app/pipes/pipes.module";
import { KnowledgeListPageComponent } from "./knowledge-list-page.component";
import { KnowledgeListPipesModule } from "./knowledge-list-pipes/knowledge-list-pipes.module";

@NgModule({
  declarations: [KnowledgeListPageComponent],
  imports: [
    CommonModule,
    KnowledgeListPipesModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    PipesModule,
    MatIconModule,
    I18nModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatCardModule,
    FiltersDisplayModule,
    MatTableModule,
    ItemFilterModule,
    RouterModule,
    MatButtonModule,
    MatSortModule,
    DragDropModule,
  ],
  exports: [KnowledgeListPageComponent],
})
export class KnowledgeListPageModule {}
