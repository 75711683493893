import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Subject, forkJoin, fromEvent, merge, of, timer } from "rxjs";
import { debounceTime, distinctUntilChanged, first, skipWhile, switchMap, takeUntil, tap } from "rxjs/operators";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { Choice } from "src/app/components/item-filter/filters/choice-filter/choice-filter.component";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { AppError, ERROR_MSG } from "src/app/models/app-error.interface";
import { DELETE_REQUEST_STATUS, IDeleteRequest } from "src/app/models/delete-request.interface";
import { DataType, Filter } from "src/app/models/filter.interface";
import { PreferenceUser, TableParameter } from "src/app/models/preference.interface";
import { DeleteRequestService } from "src/app/providers/deleteRequest.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { ConfirmMailDialogComponent } from "./confirm-mail-dialog/confirm-mail-dialog.component";
import { DeleteRequestDataSource } from "./delete-request-list.datasource";

@Component({
  selector: "app-delete-request-list-page",
  templateUrl: "./delete-request-list-page.component.html",
  styleUrls: ["./delete-request-list-page.component.scss"],
})
export class DeleteRequestListPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("searchInput") searchInput: ElementRef;
  @ViewChild("searchClearBtn", { read: ElementRef }) searchClearBtn: ElementRef;
  private onDestroy$ = new Subject<void>();
  public loading: boolean;
  public dataSource: DeleteRequestDataSource;
  public displayedColumns = [
    "identifier.value",
    "patient.firstname",
    "patient.lastname",
    "requestDate",
    "requestStatus",
    "treatmentDate",
    "controller.display",
    "archiveFilename",
    "action",
  ];
  public filters: Filter[] = [];
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public deleteRequestCount: number;
  public isFiltered = false;
  private services: string[] = [];
  public deleteRequestType = DELETE_REQUEST_STATUS;
  public isSendingArchive: boolean;
  private preferences: TableParameter;
  public currentPageSize: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private deleteRequestService: DeleteRequestService,
    private sessionService: SessionService,
    private userService: UserService,
    private healthcareService: HealthcareserviceService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private preferenceService: PreferenceService
  ) {
    // get data from resolvers
    this.preferences = this.route.snapshot.data.preferences;
    this.filters = this.preferences?.filters ? this.preferences.filters : [];
    this.isFiltered = this.filters && this.filters.length > 0;
    this.currentPageSize = this.preferences?.itemsPerPage ? this.preferences.itemsPerPage : 25;
    this.deleteRequestCount = this.route.snapshot.data.deleteRequestCount.count;

    this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        this.loading = true;
      }
      if (ev instanceof NavigationEnd || ev instanceof NavigationCancel || ev instanceof NavigationError) {
        this.loading = false;
      }
    });

    if (this.sessionService.currentService && this.userService.ownOrganization) {
      this.setupServicesWatch();
    } else {
      this.sessionService.servicesSetupWatch
        .pipe(
          skipWhile(() => !this.userService.ownOrganization),
          first()
        )
        .subscribe(() => {
          this.setupServicesWatch();
        });
    }
  }

  ngOnInit(): void {
    this.dataSource = new DeleteRequestDataSource(this.deleteRequestService);
    // apply filter
    this.filters?.forEach((filter) => this.dataSource.setFilter(filter));
    this.userService
      .isAuthorized("deleteRequest", "GET")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isAuth) => {
        if (isAuth) {
          this.dataSource.loadData({
            pageNumber: 0,
            pageSize: this.currentPageSize,
            search: "",
            sortId: "1",
            sortOrder: "asc",
            filters: this.getFiltersFormated(),
            services: this.services,
          });
          this.deleteRequestCount = this.route.snapshot.data.deleteRequestCount.count;
          this.sessionService.refreshDeleteRequestList.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.initOrRefresh();
          });
        }
      });
  }

  ngAfterViewInit(): void {
    const searchEvent$ = fromEvent(this.searchInput.nativeElement, "keyup");
    const searchResetEvent$ = fromEvent(this.searchClearBtn.nativeElement, "click");
    // server-side search
    merge(searchEvent$, searchResetEvent$)
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.initOrRefresh();
        })
      )
      .subscribe();
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadDeleteRequestPage()),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentPageSize = this.paginator.pageSize;
      this.updatePreference();
    });

    this.initOrRefresh();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initOrRefresh() {
    this.loadServices();
    const searchedValue = this.searchInput.nativeElement.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    this.paginator.pageIndex = 0;
    if (this.userService.isAuthorizedSync(null, "nbDeleteRequest", "GET")) {
      this.deleteRequestService
        .getDeleteRequestCount(this.services, searchedValue, this.getFiltersFormated())
        .pipe(first(), takeUntil(this.onDestroy$))
        .subscribe((result) => {
          this.deleteRequestCount = result?.count;
          this.loadDeleteRequestPage();
        });
    }
  }

  private setupServicesWatch() {
    this.loadServices();
    this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.initOrRefresh();
    });
  }

  private loadServices() {
    let services: string[] = [];
    const currentService = this.sessionService.currentService;

    if (!currentService) {
      this.services = [];
      return;
    }
    if (currentService.reference === this.sessionService.allsOption) {
      services = this.healthcareService.availableServices().map((s) => s.asReference.reference);
    } else {
      services.push(currentService.reference);
    }
    this.services = services;
  }

  private loadDeleteRequestPage(): void {
    if (this.userService.isAuthorizedSync(null, "deleteRequest", "GET")) {
      this.dataSource.loadData({
        pageNumber: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        search: this.searchInput.nativeElement.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
        sortId: this.sort.active,
        sortOrder: this.sort.direction,
        filters: this.getFiltersFormated(),
        services: this.services,
      });
    }
  }

  public getFilter(propertyName: string): Filter {
    return this.dataSource?.getFilter(propertyName);
  }

  public applyFilter(filter: Filter, isInit = false): void {
    this.dataSource.setFilter(filter);
    if (!isInit) {
      this.paginator.pageIndex = 0;
    }
    this.loadDeleteRequestPage();
    // update data count with filters result
    if (this.userService.isAuthorizedSync(null, "nbDeleteRequest", "GET")) {
      this.deleteRequestService
        .getDeleteRequestCount(this.services, this.searchInput.nativeElement.value, this.getFiltersFormated())
        .pipe(first())
        .subscribe((result) => {
          this.deleteRequestCount = result?.count;
        });
    }
    this.isFiltered = this.filters && this.filters.length > 0;
    this.updatePreference();
  }

  public clearFilter(): void {
    this.searchInput.nativeElement.value = "";
    this.dataSource.clearFilter();
    this.paginator.pageIndex = 0;
    this.filters = [];
    this.isFiltered = false;
    this.loadDeleteRequestPage();
    const searchedValue = this.searchInput.nativeElement.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    // update data count with filters result
    if (this.userService.isAuthorizedSync(null, "nbDeleteRequest", "GET")) {
      this.deleteRequestService
        .getDeleteRequestCount(this.services, searchedValue, this.getFiltersFormated())
        .pipe(first(), takeUntil(this.onDestroy$))
        .subscribe((result) => {
          this.deleteRequestCount = result?.count;
        });
    }
    this.updatePreference();
  }

  private getFiltersFormated(): Filter[] {
    const formatedFilters: Filter[] = [];
    const filters = Tools.clone(this.dataSource.getAllFilters());
    if (filters && filters.length > 0) {
      filters.forEach((filter) => {
        switch (filter.dataType) {
          case this.dataTypeDate:
            // format date params to string
            filter.data.fromDate = moment(filter.data.fromDate).format("YYYY-MM-DD");
            filter.data.toDate = moment(filter.data.toDate).format("YYYY-MM-DD");
            break;
          case this.dataTypeChoice: {
            const values: Array<number> = [];
            const choices: Choice[] = filter.data ? filter.data : [];
            choices.forEach((choice) => {
              if (choice.checked) {
                values.push(Number(choice.value));
              }
            });
            filter.data = {
              value: values,
            };
            break;
          }
        }
        formatedFilters.push(filter);
      });
    }
    this.filters = formatedFilters;
    return formatedFilters;
  }

  public cancelRequest(deleteRequest: IDeleteRequest): void {
    if (!this.userService.isAuthorizedSync(null, "deleteRequest", "PUT")) {
      return;
    }
    this.deleteRequestService
      .update(deleteRequest, DELETE_REQUEST_STATUS.CANCELED)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.snackBar
          .open(`${this.translateService.instant("common.success")}`, null, {
            duration: 4000,
          })
          .afterDismissed()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe();
        this.sessionService.needRefreshDeleteRequestList();
      });
  }

  public setRequestToOngoing(deleteRequest: IDeleteRequest): void {
    if (!this.userService.isAuthorizedSync(null, "deleteRequest", "PUT")) {
      return;
    }
    this.deleteRequestService
      .update(deleteRequest, DELETE_REQUEST_STATUS.ONGOING)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.snackBar
          .open(`${this.translateService.instant("common.success")}`, null, {
            duration: 4000,
          })
          .afterDismissed()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe();
        this.sessionService.needRefreshDeleteRequestList();
      });
  }

  public acceptRequest(deleteRequest: IDeleteRequest): void {
    if (!this.userService.isAuthorizedSync(null, "deleteRequest", "PUT")) {
      return;
    }
    if (deleteRequest.wantArchive) {
      this.deleteRequestService
        .update(deleteRequest, DELETE_REQUEST_STATUS.WAITING_FOR_ARCHIVE_DOWNLOAD)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.snackBar
            .open(`${this.translateService.instant("page.deleteRequestList.archiveEmailIsSent")}`, null, { duration: 5000 })
            .afterDismissed()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe();
          this.sessionService.needRefreshDeleteRequestList();
        });
    } else {
      this.dialog
        .open(ConfirmationDialogComponent, {
          data: {
            message: this.translateService.instant("page.deleteRequestList.confirmWithoutArchive"),
            type: ConfirmationDialogType.CONFIRM,
          },
          disableClose: true,
        })
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((yes) => {
          if (yes) {
            this.deleteRequestService
              .update(deleteRequest, DELETE_REQUEST_STATUS.DONE)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(() => {
                this.snackBar
                  .open(`${this.translateService.instant("common.success")}`, null, { duration: 4000 })
                  .afterDismissed()
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe();
                this.sessionService.needRefreshDeleteRequestList();
              });
          }
        });
    }
  }

  public endRequest(deleteRequest: IDeleteRequest): void {
    if (!this.userService.isAuthorizedSync(null, "deleteRequest", "PUT")) {
      return;
    }
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          message: this.translateService.instant("page.deleteRequestList.confirmWithArchive"),
          type: ConfirmationDialogType.CONFIRM,
        },
        disableClose: true,
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((yes) => {
        if (yes) {
          this.deleteRequestService
            .update(deleteRequest, DELETE_REQUEST_STATUS.DONE)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
              this.snackBar
                .open(`${this.translateService.instant("common.success")}`, null, { duration: 4000 })
                .afterDismissed()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe();
              this.sessionService.needRefreshDeleteRequestList();
            });
        }
      });
  }

  public resendArchive(deleteRequest: IDeleteRequest): void {
    if (!this.userService.isAuthorizedSync(null, "resendArchiveDownload", "POST")) {
      return;
    }
    this.dialog
      .open(ConfirmMailDialogComponent, {
        data: {
          mail: deleteRequest.patient.email,
        },
        disableClose: true,
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        if (data) {
          this.isSendingArchive = true;
          this.deleteRequestService
            .resendArchiveDownload(deleteRequest, data.mail)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
              this.snackBar
                .open(`${this.translateService.instant("page.deleteRequestList.archiveEmailIsSent")}`, null, { duration: 5000 })
                .afterDismissed()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe();
              this.isSendingArchive = false;
              this.sessionService.needRefreshDeleteRequestList();
            });
        }
      });
  }

  /**
   * Initiates the download of an archive based on the delete request.
   * Displays a confirmation dialog and triggers download actions.
   *
   * @param {IDeleteRequest} deleteRequest - The delete request object containing necessary information for the download.
   * @returns {void}
   */
  public downloadArchive(deleteRequest: IDeleteRequest): void {
    let alert: MatSnackBarRef<TextOnlySnackBar>;

    this.dialog
      .open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          title: this.translateService.instant("page.deleteRequestList.warning.title"),
          message: this.translateService.instant("page.deleteRequestList.warning.content"),
          type: ConfirmationDialogType.CONFIRM,
          showButtons: true,
        },
      })
      .afterClosed()
      .pipe(
        switchMap((res) => {
          // Check if the user confirmed the action, if not, we return an empty observable
          if (!res) {
            return of([]);
          }

          // Notify the start of the download with a snack bar
          alert = this.snackBar.open(this.translateService.instant("common.downloadStarted"), "ok");

          // Set up sources: a timer and the download request
          const sources = [
            timer(3000), // A 3-second timer
            this.deleteRequestService.downloadArchive(deleteRequest.archiveFilename, deleteRequest.token, true).pipe(
              first(), // Take the first emitted value and complete
              tap((blob: Blob) => {
                // When the file is ready, initiate the download
                if (blob) {
                  Tools.downloadBlob(blob, `${deleteRequest.patient.lastname} ${deleteRequest.patient.firstname}`);
                }
              })
            ),
          ];
          return forkJoin(sources); // Merge observables and wait for all to complete
        }),
        first() // Ensure only one emission
      )
      .subscribe(
        // Empty arrow function as we don't need to handle any value here
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
        (error: AppError) => {
          // Handle error scenarios
          if (error.message === ERROR_MSG.NOT_FOUND) {
            FileLogger.error("DeleteRequestListPage", "downloadArchive", error);
            this.snackBar.open(this.translateService.instant("page.deleteRequestList.notFound"), "ok", { duration: 5000 });
          } else {
            FileLogger.error("DeleteRequestListPage", "downloadArchive", error);
            throw new Error(error.message);
          }
        },
        () => {
          // Dismiss the alert when the process completes
          alert?.dismiss();
        }
      );
  }

  public updatePreference(): void {
    this.preferenceService
      .update({
        context: this.route.snapshot.data.ctx,
        parameters: {
          filters: this.dataSource.getAllFilters(),
          itemsPerPage: this.paginator.pageSize,
        } as TableParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: PreferenceUser) => {
        const param = parameters.preferences.find((p) => p.context === this.route.snapshot.data.ctx);
        this.preferences = param.parameters;
      });
  }
}
