import { Tools } from "../helpers/tools";
import { CareplanAccess, ILink2Careplan, VersioningStatus } from "./careplans.interface";
import { ActionStatusEntity, EntityOption, StatusEntity } from "./entity.interface";

/**
 * Helper class for Careplan
 */
export class Link2Careplan implements ILink2Careplan {
  public careplan: string;
  public questionnaires: string[];
  public observations: string[];
  public knowledges: string[];
  public vitalSignsDefinitions: string[];
  public askVitalSign?: boolean;
  public access?: CareplanAccess;
  public creation?: string;
  public modified?: string;
  public actionStatus?: ActionStatusEntity;
  public entityStatus?: StatusEntity[];
  public options?: EntityOption;
  public versioningStatus?: VersioningStatus;
  public publicationDate?: string;

  constructor(link2Careplan: ILink2Careplan) {
    this.careplan = link2Careplan.careplan;
    this.questionnaires = link2Careplan.questionnaires;
    this.observations = link2Careplan.observations;
    this.knowledges = link2Careplan.knowledges;
    this.vitalSignsDefinitions = link2Careplan.vitalSignsDefinitions;
    if (Tools.isDefined(link2Careplan.askVitalSign)) this.askVitalSign = link2Careplan.askVitalSign;
    if (Tools.isDefined(link2Careplan.access)) this.access = link2Careplan.access;
    if (Tools.isDefined(link2Careplan.creation)) this.creation = link2Careplan.creation;
    if (Tools.isDefined(link2Careplan.modified)) this.modified = link2Careplan.modified;
    if (Tools.isDefined(link2Careplan.actionStatus)) this.actionStatus = link2Careplan.actionStatus;
    if (Tools.isDefined(link2Careplan.entityStatus)) this.entityStatus = link2Careplan.entityStatus;
    if (Tools.isDefined(link2Careplan.options)) this.options = link2Careplan.options;
    this.versioningStatus = Tools.isDefined(link2Careplan.versioningStatus) ? link2Careplan.versioningStatus : VersioningStatus.DRAFT;
    if (Tools.isDefined(link2Careplan.publicationDate)) this.publicationDate = link2Careplan.publicationDate;
  }
}
