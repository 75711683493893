import * as moment from "moment";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { Practitioner } from "src/app/models/practitioner.model";
import { PractitionerService } from "src/app/providers/practitioner.service";
import { SessionService } from "src/app/providers/session.service";

export class UserListDataSource extends MatTableDataSourceExtended<Practitioner> {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private refreshInterval;

  public loading$ = this.loadingSubject.asObservable();
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  sortingDataAccessor = (data: Practitioner, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "birthday":
        return moment(data.birthDate).unix();
      case "name":
        return data.familyName?.toLowerCase();
      case "firstname":
        return data.firstname?.toLowerCase();
      case "phone":
        return data.mainPhoneNbr?.value.toLowerCase();
      case "email":
        return data.mainMail?.value.toLowerCase();
      case "gender":
        return data.gender?.toLowerCase(); // little bugfix on sort (top / bottom)
      case "visible":
        return data.active.toString();
      case "managingOrganization":
        return data.organization.display.toLowerCase();
      case "healthcare":
        return data.mainService.display.toLowerCase();
      default:
        return "";
    }
  };

  constructor(private practitionerService: PractitionerService, private sessionService: SessionService) {
    super();
  }

  public loadPractitioners(serviceId?: string, services?: string[], _autoRefreshIntervalMs = 60000): void {
    this.loadingSubject.next(true);
    this.loadData(serviceId, services);
  }

  private clearAutoRefresh() {
    clearInterval(this.refreshInterval);
  }

  public clear(): void {
    this.clearAutoRefresh();
    this.loadingSubject.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadData(serviceId?: string, services?: string[]) {
    this.practitionerService
      .listForService(serviceId, services)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((practitioners) => {
        // Fix for duplicate practitionners with arrayHelper
        this.data = practitioners.filter(ArrayHelper.onlyUniquePractitionner);
        this.loadingSubject.next(false);
      });
  }
}
