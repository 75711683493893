<div class="globalComponent">
  <div class="buttons-bar">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close matTooltip="{{ 'btn.close' | translate }}">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <h2>
    {{ "observation.obsDefinition.title" | translate }}
  </h2>
  <div class="loading" *ngIf="!pageLoaded">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <mat-dialog-content *ngIf="pageLoaded">
    <div class="questParamsEditor" *ngIf="canUpdateObsParamFreq && obsParamsToUpdate">
      <div style="float: right">
        <button mat-icon-button color="primary" (click)="saveParamsFreq()" matTooltip="{{ 'common.save' | translate }}">
          <mat-icon>save</mat-icon>
        </button>
        <button mat-icon-button class="negative-margin" matTooltip="{{ 'btn.close' | translate }}" (click)="obsParamsToUpdate = null">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <app-timing-editor
        [mode]="1"
        [isFrequencyDisplayed]="true"
        [(frequency)]="obsParamsToUpdate.frequency"
        [isMomentRequired]="true"
        [largeDisplay]="false"
        [hasCycle]="false"
        [needStartEnd]="false"
        [needQuantities]="false"
        [isQuestionnaire]="true"
      >
      </app-timing-editor>
    </div>
    <h3>{{ "observation.obsDefinition.careplanObs" | translate }} :</h3>
    <div class="container">
      <div *ngFor="let cpObs of careplanObs">
        <mat-checkbox [checked]="cpObs.active" disabled="true">
          {{ cpObs.observationDefinition | translateObservationNameFromObsDef }}
          ({{ "observation.obsDefinition.linkedTo" | translate }} {{ cpObs.originName }})
        </mat-checkbox>
        <button
          *ngIf="canUpdateObsParamFreq"
          mat-icon-button
          matTooltip="{{ 'btn.settings' | translate }}"
          (click)="updateObsParamsFreq(cpObs.observationDefinition.loinc)"
          aria-label="icon button with a settings icon"
        >
          <mat-icon>settings</mat-icon>
        </button>
      </div>
    </div>
    <p *ngIf="!careplanObs?.length" style="text-align: center">
      {{ "observation.obsDefinition.noLinkedObs" | translate }}
    </p>
    <br />
    <h3>{{ "observation.obsDefinition.activityObs" | translate }} :</h3>
    <div class="container">
      <div *ngFor="let actObs of activityObs">
        <mat-checkbox
          [checked]="actObs.active"
          disabled="true"
          matTooltip="{{ 'observation.obsDefinition.disabledFromActivity' | translate }}"
          [matTooltipDisabled]="actObs.active"
        >
          {{ actObs.observationDefinition | translateObservationNameFromObsDef }}
          ({{ "observation.obsDefinition.linkedTo" | translate }} {{ actObs.originName }})
        </mat-checkbox>
        <button
          *ngIf="canUpdateObsParamFreq"
          mat-icon-button
          matTooltip="{{ 'btn.settings' | translate }}"
          (click)="updateObsParamsFreq(actObs.observationDefinition.loinc)"
          aria-label="icon button with a settings icon"
        >
          <mat-icon>settings</mat-icon>
        </button>
      </div>
    </div>
    <p *ngIf="!activityObs?.length" style="text-align: center">
      {{ "observation.obsDefinition.noLinkedObs" | translate }}
    </p>
    <br />
    <h3>{{ "observation.obsDefinition.additionalObs" | translate }} :</h3>
    <div class="container">
      <div *ngFor="let stObs of standaloneObs">
        <div
          [matTooltip]="
            (canAddStandaloneObs ? (!stObs.canBeDisabled ? 'observation.obsDefinition.removeError' : '') : 'common.notAuthorized')
              | translate
          "
        >
          <mat-checkbox
            [checked]="stObs.active"
            [value]="stObs.observationDefinition.loinc"
            [disabled]="!stObs.canBeDisabled || !canAddStandaloneObs"
            (change)="onStandaloneObsChange($event)"
          >
            {{ stObs.observationDefinition | translateObservationNameFromObsDef }}
          </mat-checkbox>
          <button
            *ngIf="canUpdateObsParamFreq"
            mat-icon-button
            matTooltip="{{ 'btn.settings' | translate }}"
            (click)="updateObsParamsFreq(stObs.observationDefinition.loinc)"
            aria-label="icon button with a settings icon"
            [disabled]="!stObs.active || !patientObsLoinc.includes(stObs.observationDefinition.loinc)"
          >
            <mat-icon>settings</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <p *ngIf="!standaloneObs?.length" style="text-align: center">
      {{ "observation.obsDefinition.noLinkedObs" | translate }}
    </p>
  </mat-dialog-content>
  <div class="actionButton">
    <button
      mat-raised-button
      color="primary"
      type="button"
      (click)="updatePatientConfig()"
      [disabled]="!hasChanges || ('dashboard/configurationPatient' | isAuthorized : 'PUT' | async) === false"
    >
      {{ "common.save" | translate }}
    </button>
  </div>
</div>
