import { Address } from "./address.interface";
import { Coding } from "./coding.interface";
import { Identifier } from "./identifier.interface";
import { Name } from "./name.interface";
import { Communication, IAnsRole, IPractitioner, PractitionerRole, Qualification, TELECOM_SYSTEM } from "./practitioner.interface";
import { Reference } from "./reference.interface";

export const SYSTEM_COMUNICARE = "http://comunicare.io";
export const SYSTEM_TOPMD = "https://www.topmd.co.uk/";
export const USE_INAMI = "inami";
export const USE_HOSPITAL = "hospital";

/**
 * Interface for Practitioner
 */
export class Practitioner implements IPractitioner {
  public resourceType: string;
  public identifier: Identifier[];
  public active: boolean;
  public valid?: boolean;
  public name: Name;
  public telecom: Identifier[];
  public address: Address[];
  public gender: string;
  public birthDate: string;
  public practitionerRole: PractitionerRole[];
  public qualification: Qualification[];
  public communication: Communication[];
  public photo: string; // base64
  public creation?: string;
  public _id?: string;
  public ansRole?: IAnsRole[];

  constructor(data: IPractitioner) {
    this.resourceType = data.resourceType;
    this.identifier = data.identifier;
    this.active = data.active;
    this.valid = data.valid;
    this.name = data.name;
    this.telecom = data.telecom;
    this.address = data.address;
    this.gender = data.gender;
    this.birthDate = data.birthDate;
    this.practitionerRole = data.practitionerRole;
    this.qualification = data.qualification;
    this.communication = data.communication;
    this.photo = data.photo;
    this.creation = data.creation;
    this._id = data._id;
    this.ansRole = data.ansRole;
  }

  public get firstname(): string | null {
    return this.name?.given?.[0] || null;
  }

  public set firstname(v: string) {
    if (this.name.given.length) {
      this.name.given[0] = v;
    } else {
      this.name.given = [v];
    }
  }

  public get prefix(): string | null {
    return this.name?.prefix?.[0] || null;
  }

  public set prefix(v: string) {
    if (this.name.prefix.length) {
      this.name.prefix[0] = v;
    } else {
      this.name.prefix = [v];
    }
  }

  public get familyName(): string | null {
    return this.name?.family?.[0] || null;
  }

  public set familyName(v: string) {
    if (this.name.family.length) {
      this.name.family[0] = v;
    } else {
      this.name.family = [v];
    }
  }

  public addTelecom(v: Identifier): void {
    if (this.telecom.length) {
      this.telecom.push(v);
    } else {
      this.telecom = [v];
    }
  }

  public removeTelecom(type: TELECOM_SYSTEM): void {
    const idx = this.telecom.map((v) => v.system).lastIndexOf(type);
    if (idx !== -1) {
      delete this.telecom[idx];
    }
  }

  public get phoneNbr(): Identifier[] {
    return this.telecom.filter((v) => v.system === TELECOM_SYSTEM.PHONE);
  }

  public get mailAdress(): Identifier[] {
    return this.telecom.filter((v) => v.system === TELECOM_SYSTEM.MAIL);
  }

  public get mainQualif(): Coding {
    return this.practitionerRole?.[0]?.role?.coding?.[0];
  }

  public get organization(): Reference {
    return this.practitionerRole?.[0]?.managingOrganization;
  }

  public get allServices(): Reference[] {
    return this.practitionerRole?.[0]?.healthcareService;
  }

  public get allServicesRefs(): string[] {
    return this.allServices.map((v) => v.reference);
  }

  public get mainLang(): string {
    return this.communication?.[0]?.language?.coding[0]?.code;
  }

  public get caremateId(): Identifier {
    return this.identifier.find((id) => id.system === SYSTEM_COMUNICARE);
  }

  public get inamiNbr(): string {
    return this.identifier.find((id) => id.use === USE_INAMI)?.value;
  }

  public get internalId(): string {
    return this.identifier.find((id) => id.use === USE_HOSPITAL)?.value;
  }

  public get mainService(): Reference {
    return this.allServices?.[0];
  }

  public get mainPhoneNbr(): Identifier {
    return this.phoneNbr?.[0];
  }

  public get mainMail(): Identifier {
    return this.mailAdress?.[0];
  }
}
