<span #focus></span>
<mat-card class="widget" [ngClass]="{ big: isBig }">
  <mat-toolbar color="primary">
    <p class="fs-16">{{ "teleconsultations.title" | translate }}</p>
    <a mat-icon-button aria-label="icon button with a ? icon" (click)="openPTeleconsulationHelp()">
      <mat-icon>help_outline</mat-icon>
    </a>
    <span class="spacerBetween"></span>

    <mat-slide-toggle class="mr-5 font-12" *ngIf="isBig" (change)="refreshData()" [(ngModel)]="allTeleconsultations" labelPosition="before">
      {{ "btn.allTeleconsultations" | translate }}
    </mat-slide-toggle>

    <button
      mat-icon-button
      matTooltip="{{ 'btn.newAppointment' | translate }}"
      (click)="onAddVisio()"
      *ngIf="('appointment' | isAuthorized : 'POST' | async) === true"
      aria-label="icon button with a plus icon"
      [disabled]="isAllServices || !isSameOrganization || !isSameService"
      [matTooltipDisabled]="isAllServices || !isSameOrganization || !isSameService"
    >
      <mat-icon [matTooltip]="newAppointmentTooltip"> add </mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="{{ 'btn.details' | translate }}"
      *ngIf="!isBig"
      (click)="onPlus()"
      aria-label="icon button with a plus icon"
    >
      <mat-icon>open_in_full</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="{{ 'btn.reduce' | translate }}"
      *ngIf="isBig"
      (click)="onPlus()"
      aria-label="icon button with a pencil icon"
    >
      <mat-icon>close_fullscreen</mat-icon>
    </button>
  </mat-toolbar>
  <mat-card-content [ngClass]="{ hide: dataSource.data.length === 0 }">
    <table mat-table matSort matSortDirection="desc" matSortActive="startDate" [dataSource]="dataSource">
      <!-- startDate Column -->
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.start" | translate }}
          <app-item-filter
            fieldName="{{ 'table.startDate' | translate }}"
            propertyName="start"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('start')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.start | date : "short" }}
          <span class="appointmentDelay">
            {{
              element.minutesDelay && element.minutesDelay !== 0 ? "+" + element.minutesDelay + ("periodUnit.minute.short" | translate) : ""
            }}
          </span>
        </td>
      </ng-container>

      <!-- endDate Column -->
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.end" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element.end | date : "HH:mm" }}</span>
        </td>
      </ng-container>

      <!-- description Column -->
      <ng-container matColumnDef="object">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.object" | translate }}
          <app-item-filter
            fieldName="{{ 'table.object' | translate }}"
            propertyName="description"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('description')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.description }}
        </td>
      </ng-container>

      <!-- participant Column -->
      <ng-container matColumnDef="participant">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.participant" | translate }}
          <app-item-filter
            fieldName="{{ 'table.participant' | translate }}"
            propertyName="participantPractitioner"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('participantPractitioner')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.participantPractitioner }}
        </td>
      </ng-container>

      <!-- Date Seen By Patient Column -->
      <ng-container matColumnDef="dateSeenByPatient">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.dateSeenByPatient" | translate }}
          <app-item-filter
            fieldName="{{ 'table.dateSeenByPatient' | translate }}"
            propertyName="dateSeenByPatient"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('dateSeenByPatient')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="flex-container">
            <div style="text-wrap: nowrap">
              {{ row.dateSeenByPatient ? (row.dateSeenByPatient | date : "short") : "-" }}
            </div>
            <mat-icon
              *ngIf="row.dateSeenByPatientHistory?.length"
              class="custom-icon-small baseline-align"
              matTooltip="{{ row.dateSeenByPatientHistory | dateArray : 'short' | join : '\n' }}"
              matTooltipClass="multiline-tooltip"
              >history</mat-icon
            >
          </div>
        </td>
      </ng-container>

      <!-- action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.action" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            *ngIf="!element.isPast && ('appointment' | isAuthorized : 'PUT' | async) === true"
            [ngClass]="{
              warn: !isAllServices,
              medium: isAllServices,
              'custom-small-btn': true,
              'custom-small-btn-width': true
            }"
            mat-icon-button
            matTooltip="{{ 'btn.editAppointment' | translate }}"
            [matTooltipDisabled]="isAllServices"
            aria-label="icon button with a pencil icon"
            (click)="onEditVisio(element)"
            [disabled]="isAllServices"
          >
            <mat-icon
              class="custom-icon-small baseline-align"
              matTooltip="{{ 'btn.disabledWithAll' | translate }}"
              [matTooltipDisabled]="!isAllServices"
            >
              create
            </mat-icon>
          </button>
          <button
            *ngIf="!element.isPast && ('appointment' | isAuthorized : 'PUT' | async) === true"
            class="primary custom-small-btn custom-small-btn-width"
            mat-icon-button
            matTooltip="{{ 'teleconsultations.reportDelayTooltip' | translate }}"
            aria-label="icon button with an update (forward) icon"
            (click)="updateAppointmentWithDelay(element)"
          >
            <mat-icon class="custom-icon-small baseline-align">update</mat-icon>
          </button>
          <button
            class="custom-small-btn custom-small-btn-width fail"
            *ngIf="!element.isPast && ('appointment' | isAuthorized : 'PUT' | async) === true"
            mat-icon-button
            matTooltip="{{ 'btn.deleteAppointment' | translate }}"
            aria-label="icon button with a delete icon"
            (click)="onDeleteVisio(element)"
          >
            <mat-icon class="custom-icon-small baseline-align">delete</mat-icon>
          </button>
          <span matTooltip="{{ (has2FA || skip2FA ? 'btn.startVideoCall' : 'mfa.need2FAForTeleconsultation') | translate }}">
            <button
              class="custom-small-btn custom-small-btn-width"
              [ngClass]="{
                success: has2FA || skip2FA,
                medium: !has2FA && !skip2FA
              }"
              *ngIf="!element.isPast"
              mat-icon-button
              aria-label="icon button with a cam icon"
              (click)="onStartVideoCall(element)"
              [disabled]="!has2FA && !skip2FA"
            >
              <mat-icon class="custom-icon-small baseline-align">videocam</mat-icon>
            </button>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [ngClass]="{ hide: dataSource.data.length === 0 }"
      #paginator
      [pageIndex]="0"
      [pageSize]="10"
      [hidePageSize]="true"
    ></mat-paginator>
    <p *ngIf="!has2FA && !skip2FA" class="noAccess">
      {{ "mfa.need2FAForTeleconsultation" | translate }}
      <button mat-button (click)="skip2FA = true" color="danger">
        <mat-icon>close</mat-icon>
        {{ "btn.ignore" | translate }}
      </button>
    </p>
  </mat-card-content>
  <div *ngIf="loading" class="loading">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <p class="text-center" *ngIf="dataSource.data.length === 0 && !loading">
    {{ "widget.noData" | translate }}
  </p>
</mat-card>
