import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IPrescriptionInfosResponse } from "../models/prescription.interface";
import { PrescriptionApiService } from "./api/prescription-api.service";

@Injectable({
  providedIn: "root",
})
export class PrescriptionService {
  constructor(private prescriptionApiService: PrescriptionApiService) {}

  public getPrescriptionInfos(
    organizations?: string,
    services?: string,
    patientCaremateId?: string,
    period?: string,
    date?: string,
    status?: string
  ): Observable<IPrescriptionInfosResponse[]> {
    return this.prescriptionApiService.getPrescriptionInfos(organizations, services, patientCaremateId, period, date, status) as Observable<
      IPrescriptionInfosResponse[]
    >;
  }
}
