<div class="drugsCalendarContainer">
  <div class="close-cross">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-form-field>
    <mat-label>{{ "drugs.title" | translate }}</mat-label>
    <mat-select multiple [(ngModel)]="drugsToShow" (selectionChange)="computeIntakeDays()">
      <mat-option *ngFor="let item of activeDrugs" [value]="item">{{
        item.entityData.prescriptionName ?? item.entityData.name
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-card>
    <mat-calendar #calendar [dateClass]="dateClass" (monthSelected)="handleMonthSelected($event)"></mat-calendar>
  </mat-card>
</div>
