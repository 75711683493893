import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MatOptionSelectionChange } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { Tools } from "src/app/helpers/tools";
import { Healthcareservice } from "src/app/models/healthcareservice.model";
import { Contained } from "src/app/models/questionnaire.interface";
import { Reference } from "src/app/models/sharedModels.model";
import { QuestionnairesService } from "src/app/providers/questionnaires.service";
import { UserService } from "src/app/providers/user.service";
import { ValueSetDialogComponent } from "../../value-set-dialog/value-set-dialog.component";
import { ValueSetListDialogComponent } from "../../value-set-list-dialog/value-set-list-dialog.component";

@Component({
  selector: "app-value-set",
  templateUrl: "./value-set.component.html",
  styleUrls: ["./value-set.component.scss"],
})
export class ValueSetComponent implements OnInit, OnDestroy {
  @Input() showChoices: boolean;
  @Input() isScale: boolean;
  @Input() lang: string;
  @Input() disabled = false;

  @Input() set setValueSetRef(value: string) {
    this.valueSetRef = value;
    if (this.questionsOptions && this.valueSetRef) {
      this.setup();
    }
  }

  @Input() set contained(value: Contained[]) {
    this.questionsOptions = value;
    if (this.questionsOptions && this.valueSetRef) {
      this.setup();
    }
  }
  @Output() containedChange = new EventEmitter<Contained[]>();

  valueSetRef: string;
  questionsOptions: Contained[];
  currentIndexContained = -1;
  // Authorizations:
  canSaveServiceTemplate = false;
  canSaveGlobalTemplate = false;
  orgServices: Healthcareservice[];
  organizationsRefs: Reference[];
  // ValueSets templates:
  valueSetTemplates: Contained[] = [];
  private onDestroy$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private questionnaireService: QuestionnairesService,
    private translateService: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setupAuthorizations();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Setup all the variables according to the inputs
   */
  private setup() {
    this.currentIndexContained = -1;
    this.currentIndexContained = this.getCurrentIndexContained();
    if (this.currentIndexContained > -1) {
      this.setupValueSetTemplate();
    }
  }

  /**
   * Find the index of the answers options for the current question
   */
  private getCurrentIndexContained(): number {
    return this.questionsOptions.findIndex((model) => model.idSet === this.valueSetRef);
  }

  // --------------------------------------------------------------------------
  // ---------------------  VALUESETS TEMPLATES -------------------------------

  /**
   * Setup valueSets (answers options) templates' autorizations
   */
  private setupAuthorizations() {
    this.canSaveGlobalTemplate = this.userService.isAuthorizedSync(null, "dashboard/valueSet/global", "POST");
    this.canSaveServiceTemplate = this.userService.isAuthorizedSync(null, "dashboard/valueSet", "POST");
  }

  /**
   * Get all valueSets templates (answers options) available
   */
  private async setupValueSetTemplate() {
    if (!this.orgServices) {
      if (this.userService.isMonitoringUser) {
        this.orgServices = this.userService.allMonitoringServices;
      } else {
        this.orgServices = this.userService.allServices;
      }
    }
    this.organizationsRefs = this.orgServices.map((s) => s.providedBy).filter(ArrayHelper.onlyUniqueReference);
    const servicesIds = this.orgServices.map((s) => s.asReference).map((s: Reference) => s.reference);
    const valueSetTemplates = await this.questionnaireService.getValueSets(
      this.lang,
      servicesIds,
      this.organizationsRefs.map((s: Reference) => s.reference)
    );
    for (const valueSet of valueSetTemplates) {
      valueSet.idSet = this.questionsOptions[this.currentIndexContained].idSet;
      valueSet.id = this.questionsOptions[this.currentIndexContained].idSet;
    }
    this.valueSetTemplates = valueSetTemplates;
    this.valueSetTemplates.sort((t1, t2) => t1.name.toLowerCase().localeCompare(t2.name.toLowerCase()));
  }

  /**
   * Called when the user selects a template for the answers options.
   * We replace the current answer option by a copy of the template.
   * @param event (MatOptionSelectionChange) the event containing the selected template
   */
  public templateSelected(event: MatOptionSelectionChange): void {
    const templateCopy = JSON.parse(JSON.stringify(event.source.value));
    templateCopy.name = "";
    delete templateCopy._id;
    this.questionsOptions[this.currentIndexContained] = templateCopy;
    this.handleValuesMeaningChange();
  }

  /**
   * Called when the user wants to save the current options choices as template
   */
  public saveAsTemplate(): void {
    const dialogRef = this.dialog.open(ValueSetDialogComponent, {
      data: {
        valueSet: this.questionsOptions[this.currentIndexContained],
        services: this.orgServices,
        orgsRefs: this.organizationsRefs,
        globalPermission: this.canSaveGlobalTemplate,
        isCreate: true,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (newTemplate: Contained | null) => {
        if (newTemplate) {
          newTemplate.version = this.lang;
          await this.questionnaireService.createValueSet(newTemplate);
          this.setupValueSetTemplate();
        }
      });
  }

  /**
   * Called when the user wants to delete or edit his templates
   */
  public manageTemplates(): void {
    const dialogRef = this.dialog.open(ValueSetListDialogComponent, {
      data: {
        valueSets: this.valueSetTemplates,
        globalPermission: this.canSaveGlobalTemplate,
        services: this.orgServices,
        orgsRefs: this.organizationsRefs,
        orgRefs: this.organizationsRefs,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (setsToDelete: Contained[]) => {
        if (setsToDelete && setsToDelete.length) {
          const promises = [];
          for (const t of setsToDelete) {
            const tCopy = Tools.clone(t);
            delete tCopy.id;
            delete tCopy.idSet;
            promises.push(this.questionnaireService.deleteValueSet(tCopy));
          }
          await Promise.all(promises);
          this.setupValueSetTemplate();
        }
        // This happens when we edit some template but do not delete any:
        else if (setsToDelete && !setsToDelete.length) {
          this.setupValueSetTemplate();
        }
      });
  }

  // --------------------------------------------------------------------------
  // -----------------------  ANSWERS OPTIONS ---------------------------------

  /**
   * Change the order of the sentences in the answers options (checkboxes, radio, scales...)
   * @param isUp sens of the move
   * @param index the index of the answer option we want to move
   */
  public changeOrderAnswers(isUp: boolean, index: number): void {
    const temp = this.questionsOptions[this.currentIndexContained].compose.include[0].concept[index];
    if (isUp) {
      this.questionsOptions[this.currentIndexContained].compose.include[0].concept[index] =
        this.questionsOptions[this.currentIndexContained].compose.include[0].concept[index - 1];
      this.questionsOptions[this.currentIndexContained].compose.include[0].concept[index - 1] = temp;
    } else {
      this.questionsOptions[this.currentIndexContained].compose.include[0].concept[index] =
        this.questionsOptions[this.currentIndexContained].compose.include[0].concept[index + 1];
      this.questionsOptions[this.currentIndexContained].compose.include[0].concept[index + 1] = temp;
    }
    this.handleValuesMeaningChange();
  }

  /**
   * Reverse the order of the sentences in the answers options
   */
  public reverseOrderAnswers(): void {
    this.questionsOptions[this.currentIndexContained].compose.include[0].concept.reverse();
    this.handleValuesMeaningChange();
  }

  /**
   * Delete an answer option (checkboxes, radio, dropdown, scales...)
   * @param index the index of the answer option we want to delete
   */
  public deleteAnswer(index: number): void {
    const nbAnswers = this.questionsOptions[this.currentIndexContained].compose.include[0].concept.length;
    if (nbAnswers < 2) {
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translateService.instant("page.questionnaireEditor.properties.atLeastOneChoice"),
          type: ConfirmationDialogType.INFORMATION,
        },
      });
    } else {
      this.questionsOptions[this.currentIndexContained].compose.include[0].concept.splice(index, 1);
      this.handleValuesMeaningChange();
    }
  }

  /**
   * Add an empty answer option to the list (for checkboxes, radio, dropdown...)
   */
  public addAnswer(): void {
    const iMax = this.questionsOptions[this.currentIndexContained].compose.include[0].concept.length - 1;
    const max = Number(this.questionsOptions[this.currentIndexContained].compose.include[0].concept[iMax].code) + 1;
    this.questionsOptions[this.currentIndexContained].compose.include[0].concept.push({
      code: max.toString(),
      display: "",
    });
    this.handleValuesMeaningChange();
  }

  /**
   * Called when we chose to put meaningful sentences on the scales
   * (instead of just numbers) or order of sentences.
   * We make sure that the code of the answer is consistent.
   */
  public handleValuesMeaningChange(): void {
    if (this.isScale) {
      this.questionsOptions[this.currentIndexContained].compose.include[0].concept.map((element, index) => {
        element.code = index.toString();
        return element;
      });
    }
    this.containedChange.emit(this.questionsOptions);
  }

  public addInfoTip(i: number): void {
    this.questionsOptions[this.currentIndexContained].compose.include[0].concept[i].infotip = "";
    this.containedChange.emit(this.questionsOptions);
  }

  public deleteInfoTip(i: number): void {
    delete this.questionsOptions[this.currentIndexContained].compose.include[0].concept[i].infotip;
    this.containedChange.emit(this.questionsOptions);
  }
}
