import { Reference } from "./reference.interface";
import { IVitalSignsHistory } from "./vital-signs-history.interface";
import { VitalProfileDefinition } from "./vitalProfileDefinition.model";

export class VitalSignsHistory {
  public author: Reference;
  public patientId: string;
  public date: string;
  public newValue: number;
  public exValue: number | number[][];
  public type: string;
  public unit: string;

  constructor(data: IVitalSignsHistory, public def?: VitalProfileDefinition) {
    this.author = data.author;
    this.patientId = data.patientId;
    this.date = data.date;
    this.newValue = data.newValue;
    this.exValue = data.exValue;
    this.type = data.type;
    this.unit = data.unit;
  }

  public get name(): string {
    return this.type;
  }
}
