import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { concatMap, first } from "rxjs/operators";
import { FileLogger } from "../helpers/fileLogger";
import { IStatisticsConfiguration } from "../models/statObservation.interface";
import { StatObservationApiService } from "./api/stat-observation-api.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class StatObservationService {
  constructor(private statObsService: StatObservationApiService, private userService: UserService) {}

  public list(patientId: string, loinc: string, fromDate: string, toDate: string): Observable<IStatisticsConfiguration> {
    const routeName = this.statObsService.readRoutes[0];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      first(),
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("StatObservation", "User does not have access to: GET " + routeName);
          return of(null);
        }
        return this.statObsService.list(patientId, loinc, fromDate, toDate).pipe(first());
      })
    );
  }
}
