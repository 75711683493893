<span #focus></span>
<mat-card class="widget">
  <mat-toolbar color="primary">
    <p class="fs-16">{{ "menu.rewardsPage" | translate }}</p>
    <button mat-icon-button aria-label="icon button with a ? icon" (click)="openRewardHelp()">
      <mat-icon>help_outline</mat-icon>
    </button>
    <span class="spacerBetween"></span>
    <form [formGroup]="filterFormTable">
      <div class="datePickerContainer ml-5">
        <mat-form-field class="datePickerField ml-5">
          <input
            cdkFocusInitial
            matInput
            [max]="maxFromDate"
            [matDatepicker]="fromDateTable"
            formControlName="fromDate"
            placeholder="{{ 'itemFilter.fromDate' | translate }}"
          />
          <mat-datepicker-toggle matSuffix [for]="fromDateTable"></mat-datepicker-toggle>
          <mat-datepicker #fromDateTable></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="datePickerField ml-5">
          <input
            matInput
            [min]="minToDate"
            [max]="today"
            [matDatepicker]="toDate"
            formControlName="toDate"
            placeholder="{{ 'itemFilter.toDate' | translate }}"
          />
          <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
          <mat-datepicker #toDate></mat-datepicker>
        </mat-form-field>
      </div>
    </form>
  </mat-toolbar>
  <mat-card-content [ngClass]="{ hide: dataSource?.data?.length === 0 }">
    <table
      #MatSortMain="matSort"
      #MatTableMain="matTable"
      mat-table
      matSort
      matSortActive="sendDate"
      matSortDirection="desc"
      class="full-width-table"
    >
      <!-- objectif name Column -->
      <ng-container matColumnDef="objectifName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.rewardsPage.objectifName" | translate }}
          <app-item-filter
            fieldName="{{ 'model.rewardsPage.objectifName' | translate }}"
            propertyName="rewardDefinitionReference.reference"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter(getTargetName('rewardDefinitionReference.reference'))"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ getTargetName(row.rewardDefinitionReference.reference) }}
        </td>
      </ng-container>
      <!-- objectif start Column -->
      <ng-container matColumnDef="objectifStart">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.rewardsPage.objectifStart" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.start | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>
      <!-- objectif end Column -->
      <ng-container matColumnDef="objectifEnd">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.rewardsPage.objectifEnd" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.end | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>
      <!-- objectif progression Column -->
      <ng-container matColumnDef="objectifProgress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.rewardsPage.objectifProgress" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">{{ getTargetValue(row) }}</td>
      </ng-container>
      <!-- Statut traité/pas traité -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.status" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.status' | translate }}"
            propertyName="statusTranslateKey"
            [dataType]="dataTypeChoice"
            [data]="dataSource.data"
            [defaultValue]="getFilter('statusTranslateKey')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.statusTranslateKey | translate }}
        </td>
      </ng-container>
      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">
          {{ "table.action" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <button
            *ngIf="row.rewardStatus !== REWARD_STATUS.PROCESSED"
            mat-icon-button
            matTooltip="{{ 'btn.setSeen' | translate }}"
            (click)="onDetails(row, false, getTargetName(row.rewardDefinitionReference.reference), getTargetValue(row))"
            aria-label="icon button with a pencil icon"
          >
            <mat-icon class="custom-icon-small warn">add_task</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'btn.details' | translate }}"
            (click)="onDetails(row, true, getTargetName(row.rewardDefinitionReference.reference), getTargetValue(row))"
            aria-label="icon button with a loupe icon"
          >
            <mat-icon class="custom-icon-small">search</mat-icon>
          </button>
        </td>
      </ng-container>
      <!-- reward status Column -->
      <!-- <ng-container matColumnDef="rewardStatut">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'model.rewardsPage.rewardStatut' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button matTooltip="{{ 'model.rewardsPage.orangeTrophy' | translate }}"
                        aria-label="icon button trophy icon" *ngIf="row.hasBeenRewarded === false && row.currentScore >= getTargetScore(row.rewardDefinitionReference.reference)"
                        (click)="openRewardsDialog(getTargetDescription(row.rewardDefinitionReference.reference), row)">
                        <mat-icon class="sub-align orange">emoji_events</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="{{ 'model.rewardsPage.greenTrophy' | translate }}"
                        aria-label="icon button trophy icon" *ngIf="row.hasBeenRewarded === true && row.currentScore >= getTargetScore(row.rewardDefinitionReference.reference)">
                        <mat-icon class="sub-align green">emoji_events</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="{{ 'model.rewardsPage.grayTrophy' | translate }}"
                        aria-label="icon button trophy icon" *ngIf="row.hasBeenRewarded === false && row.currentScore < getTargetScore(row.rewardDefinitionReference.reference)">
                        <mat-icon class="sub-align gray">emoji_events</mat-icon>
                    </button>
                </td>
            </ng-container> -->
      <!-- reward history Column -->
      <!-- <ng-container matColumnDef="rewardHistory">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'model.rewardsPage.rewardHistory' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    <span>{{ row.rewardReceived }}</span>
                    -<br />
                    <span>{{ row.rewardReceivedDate | date : 'dd/MM/yyyy' }}</span>
                </td>
            </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [ngClass]="{ hide: dataSource?.data?.length === 0 }"
      #paginator
      [pageIndex]="0"
      [pageSize]="5"
      [hidePageSize]="true"
    ></mat-paginator>
  </mat-card-content>
  <div *ngIf="!dataSource || dataSource.loading" class="loading">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <p class="text-center mt-5" *ngIf="dataSource?.data?.length === 0 && !dataSource.loading">
    {{ "widget.noData" | translate }}
  </p>
</mat-card>
