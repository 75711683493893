import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataSourceBackendParam } from "../models/data-sources-backend-param.interface";
import { IDeleteRequest } from "../models/delete-request.interface";
import { Filter } from "../models/filter.interface";
import { DeleteRequestApiService } from "./api/deleteRequest-api.service";

@Injectable({
  providedIn: "root",
})
export class DeleteRequestService {
  constructor(private api: DeleteRequestApiService) {}

  public list(params: DataSourceBackendParam): Observable<IDeleteRequest[]> {
    return this.api.list(params);
  }

  public update(deleteRequest: IDeleteRequest, newStatus: number): Observable<never> {
    return this.api.update(deleteRequest, newStatus);
  }

  public getDeleteRequestCount(services: string[], search?: string, filters?: Filter[]): Observable<{ count: number }> {
    return this.api.getDeleteRequestCount(services, search, filters);
  }

  public resendArchiveDownload(deleteRequest: IDeleteRequest, mailAddress: string): Observable<never> {
    return this.api.resendArchiveDownload(deleteRequest, mailAddress);
  }
  public downloadArchive(filename: string, drToken: string, downloadArchive: boolean): Observable<unknown> {
    return this.api.downloadArchive(filename, drToken, downloadArchive);
  }
}
