import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { FORMS_MODE, FormsData } from "src/app/helpers/formsData";
import { Tools } from "src/app/helpers/tools";
import { IAccessGroup } from "src/app/models/accessGroup.interface";
import { ICountry } from "src/app/models/country.interface";
import { IHealthcareservice } from "src/app/models/healthcareservice";
import { Identifier } from "src/app/models/identifier.interface";
import { Organization } from "src/app/models/organization.model";
import { IMonitoringOrganizationLink } from "src/app/models/sharedInterfaces";
import { Reference } from "src/app/models/sharedModels.model";
import { AccessService } from "src/app/providers/access.service";
import { AccessGroupApiService } from "src/app/providers/api/accessGroup-api.service";
import { CountriesService } from "src/app/providers/countries.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";

@Component({
  selector: "app-add-service",
  templateUrl: "./add-service.component.html",
  styleUrls: ["./add-service.component.scss"],
})
export class AddServiceComponent implements OnInit, OnDestroy {
  public availableCountries: ICountry[];
  public serviceForm = this.fb.group({
    name: ["", [Validators.required]],
    reference: ["", [Validators.required]],
    phone: [undefined, [this.phoneValidator]],
    phoneLabel: [{ value: undefined, disabled: true }],
    mail: [undefined, [Validators.email]],
    mailLabel: [{ value: undefined, disabled: true }],
    addressLine1: [""],
    addressLine2: [undefined],
    addressCP: [""],
    addressCity: [""],
    addressCountry: ["BE", [Validators.required]],
    photo: [""],
    defaultAccessGroups: [[]],
  });
  public isCreation = false;
  public orgId: string;
  public service: IHealthcareservice;
  public organizationRef: string;
  public imageUrl: string;
  public showImgError = false;
  public allAccessGroups: IAccessGroup[] = [];
  public canReadAccessGroups = false;

  public isMonitoringService = false;
  public monitoringOrgsRefs: Reference[] = [];
  public monitoringServicesRefs: Reference[] = [];
  public monitLinks: IMonitoringOrganizationLink[] = [];
  private monitServicesLinks: Map<string, Reference[]> = new Map<string, Reference[]>();

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();
  private monitLinkSub$: Subscription;
  private servicesOrg: Organization;

  constructor(
    private fb: UntypedFormBuilder,
    private healthCareService: HealthcareserviceService,
    private accessGroupsService: AccessService,
    private accessGroupApiService: AccessGroupApiService,
    private readonly dialogRef: MatDialogRef<AddServiceComponent>,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    public formsData: FormsData,
    private sessionService: SessionService,
    private userService: UserService,
    private countriesService: CountriesService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      service: IHealthcareservice;
      orgRef: Identifier;
      mode: FORMS_MODE;
    }
  ) {
    this.isCreation = this.data.mode === FORMS_MODE.CREATE;
    this.service = this.data.service;
    this.orgId = this.data.orgRef.value;
  }

  ngOnInit(): void {
    this.checkUsersPermissions();
    this.countriesService
      .list()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((countries) => {
        this.availableCountries = countries;
      });
    this.servicesOrg = this.userService.allOrganizations.find((o) => o.asReference.reference === this.orgId);
    if (!this.servicesOrg) {
      this.servicesOrg = this.userService.allMonitoringOrganizations.find((o) => o.asReference.reference === this.orgId);
      if (this.servicesOrg) {
        this.isMonitoringService = true;
      }
    }
    if (this.servicesOrg && !this.isMonitoringService) {
      this.monitLinks = this.servicesOrg.linkedMonitoringOrganizations;
      this.monitoringOrgsRefs = this.monitLinks
        ? this.monitLinks.map((link) => {
            this.monitServicesLinks.set(link.organizationRef.reference, link.servicesRef);
            return link.organizationRef;
          })
        : [];
    }
    const tab = this.data.orgRef.value.split("/");
    this.organizationRef = tab[tab.length - 1];
    if (!this.isCreation) {
      this.updateForm();
    }
    if (this.canReadAccessGroups) {
      this.loadAccessGroups();
    }
    this.serviceForm
      .get("phone")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((r) => {
        if (r) {
          this.serviceForm.get("phoneLabel").enable();
        } else {
          this.serviceForm.get("phoneLabel").disable();
        }
        this.serviceForm.updateValueAndValidity();
      });
    this.serviceForm
      .get("mail")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((r) => {
        if (r) {
          this.serviceForm.get("mailLabel").enable();
        } else {
          this.serviceForm.get("mailLabel").disable();
        }
        this.serviceForm.updateValueAndValidity();
      });

    if (!this.isMonitoringService) {
      this.serviceForm.addControl("monitoringOrgsLink", new UntypedFormControl([]));
      this.setupMonitOrgWatch();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setupMonitOrgWatch() {
    if (this.serviceForm.get("monitoringOrgsLink")) {
      this.monitLinkSub$?.unsubscribe();
      this.monitLinkSub$ = this.serviceForm
        .get("monitoringOrgsLink")
        .valueChanges.pipe(takeUntil(this.onDestroy$))
        .subscribe((orgs: Reference[]) => {
          this.monitoringServicesRefs = this.filterServiceFromSelectedOrgs(orgs);
          if (orgs && orgs.length === 1) {
            this.serviceForm.addControl("monitoringServicesLink", new UntypedFormControl([], [Validators.required]));
          } else if (!orgs || orgs.length < 1) {
            this.serviceForm.removeControl("monitoringServicesLink");
          } else {
            this.serviceForm.get("monitoringServicesLink").patchValue([]);
          }
        });
    }
  }

  private filterServiceFromSelectedOrgs(orgs: Reference[]) {
    const refs = [];
    if (!orgs) {
      return [];
    }
    for (const org of orgs) {
      refs.push(...this.monitServicesLinks.get(org.reference));
    }
    return refs;
  }

  public updateForm(): void {
    const reference = this.data.orgRef.value;
    const phone = this.data.service?.telecom.find((t) => t.system === "phone");
    const mail = this.data.service?.telecom.find((t) => t.system === "mail");
    const address = this.data.service?.location;
    const photo = this.data.service?.photo;
    this.imageUrl = photo;
    const monitLinksRefs: string[] = this.data.service?.linkedMonitoringOrganizations?.map((link) => link.organizationRef.reference);
    const selectedOrgs: Reference[] = this.servicesOrg?.linkedMonitoringOrganizations
      ?.filter((link) => monitLinksRefs?.includes(link.organizationRef.reference))
      .map((link) => link.organizationRef);
    const defaultAccessGroups = this.data.service.defaultAccessGroups ? this.data.service.defaultAccessGroups : [];

    this.serviceForm = this.fb.group({
      name: [this.data.service.serviceName, [Validators.required]],
      reference: [reference, [Validators.required]],
      phone: [phone?.value, [this.phoneValidator]],
      phoneLabel: [
        {
          value: phone?.label ? phone?.label : undefined,
          disabled: phone?.value ? false : true,
        },
      ],
      mail: [mail?.value, [Validators.email]],
      mailLabel: [
        {
          value: mail?.label ? mail?.label : undefined,
          disabled: mail?.value ? false : true,
        },
      ],
      addressLine1: [address?.line[0] ?? undefined],
      addressLine2: [address?.line[1] ?? undefined],
      addressCP: [address?.postalCode ?? undefined],
      addressCity: [address?.city],
      addressCountry: [address?.country ?? "BE", [Validators.required]],
      image: [photo],
      defaultAccessGroups: [defaultAccessGroups],
    });
    this.serviceForm.get("reference").disable();
    if (!this.isMonitoringService) {
      this.serviceForm.addControl("monitoringOrgsLink", new UntypedFormControl(selectedOrgs));
      if (selectedOrgs && selectedOrgs.length > 0) {
        this.monitoringServicesRefs = this.filterServiceFromSelectedOrgs(selectedOrgs);
        const monitoringServicesClumps: Reference[][] = this.data.service?.linkedMonitoringOrganizations?.map((link) => link.servicesRef);
        const monitServicesLinkRefs: string[] = monitoringServicesClumps
          ?.reduce((acc, value) => acc.concat(value), [])
          .map((s) => s.reference);
        const selectedMonitServices: Reference[] = this.monitoringServicesRefs?.filter((s) => monitServicesLinkRefs?.includes(s.reference));
        this.serviceForm.addControl("monitoringServicesLink", new UntypedFormControl(selectedMonitServices, [Validators.required]));
      }
      this.setupMonitOrgWatch();
    }
  }

  public onSave(): void {
    try {
      if (this.serviceForm.valid) {
        if (this.isCreation) {
          const newServ = this.createServiceModel();
          this.healthCareService
            .create(newServ)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
              this.close();
              this.snackBar.open(this.translateService.instant("common.saveSuccess"), undefined, {
                duration: 3000,
                horizontalPosition: "center",
                verticalPosition: "top",
              });
            });
        } else {
          const newServ = this.updateServiceModel();
          this.healthCareService
            .update(newServ)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
              this.close();
              this.snackBar.open(this.translateService.instant("common.saveSuccess"), undefined, {
                duration: 3000,
                horizontalPosition: "center",
                verticalPosition: "top",
              });
            });
        }
      }
    } catch (err) {
      FileLogger.error("AddServiceComponent", "onSave() failed", err);
    }
  }

  private close() {
    this.sessionService.needRefreshServicesList();
    this.dialogRef.close(this.data);
    const msg = this.translateService.instant("common.success");
    this.snackBar.open(msg, "ok", { duration: 3000 });
  }

  public createServiceModel(): IHealthcareservice {
    const name = this.serviceForm.get("name").value;
    const orgReference = this.data.orgRef?.value.split("/");
    const reference = orgReference[orgReference.length - 1] + "/" + this.serviceForm.get("reference").value;
    const phone = this.serviceForm.get("phone").value;
    const phoneLabel = this.serviceForm.get("phoneLabel").value;
    const mail = this.serviceForm.get("mail").value;
    const mailLabel = this.serviceForm.get("mailLabel").value;
    const addressLine1 = this.serviceForm.get("addressLine1").value;
    const addressLine2 = this.serviceForm.get("addressLine2").value;
    const addressCP = this.serviceForm.get("addressCP").value.toString(10);
    const addressCity = this.serviceForm.get("addressCity").value;
    const addressCountry = this.serviceForm.get("addressCountry").value;

    const newService: IHealthcareservice = {
      _id: null,
      entityStatus: [1],
      modified: new Date().toISOString(),
      identifier: [
        {
          use: "",
          system: "http://comunicare.io",
          value: reference,
          label: name,
        },
      ],
      providedBy: {
        display: this.data.orgRef.label,
        reference: this.data.orgRef.value,
      },
      serviceCategory: {
        coding: [],
      },
      serviceType: [
        {
          specialty: [],
          fhirType: {
            coding: [
              {
                display: "",
                code: "",
                system: FHIRHelper.SYSTEM_SNOMED,
              },
            ],
          },
        },
      ],
      location: {
        line: [addressLine1, addressLine2],
        city: addressCity,
        country: addressCountry,
        postalCode: addressCP,
      },
      serviceName: name,
      comment: "",
      telecom: [],
      photo: this.imageUrl,
      defaultAccessGroups: this.serviceForm.get("defaultAccessGroups").value,
    };

    if (!this.isMonitoringService) {
      const monitOrgs: Reference[] = this.serviceForm.get("monitoringOrgsLink").value;
      if (monitOrgs.length > 0) {
        newService.linkedMonitoringOrganizations = [];
        const monitServicesRefs: Reference[] = this.serviceForm.get("monitoringServicesLink").value;
        const servRefs: string[] = monitServicesRefs.map((s) => s.reference);
        for (const org of monitOrgs) {
          const services: Reference[] = this.monitServicesLinks.get(org.reference).filter((s) => servRefs.includes(s.reference));
          newService.linkedMonitoringOrganizations.push({
            organizationRef: org,
            servicesRef: services,
          });
        }
      }
    }

    if (phone) {
      newService.telecom.push({
        system: "phone",
        value: phone,
        use: "work",
        label: phoneLabel ?? null,
      });
    }

    if (mail) {
      newService.telecom.push({
        system: "mail",
        value: mail,
        use: "work",
        label: mailLabel ?? null,
      });
    }

    if (addressLine1 && addressCP && addressCity && addressCountry) {
      const adr = {
        use: "work",
        line: [addressLine1],
        city: addressCity,
        postalCode: addressCP,
        country: addressCountry,
      };

      if (addressLine2) {
        adr.line.push(addressLine2);
      }
      newService.location = adr;
    }

    return newService;
  }

  public updateServiceModel(): IHealthcareservice {
    const name = this.serviceForm.get("name").value;
    const phone = this.serviceForm.get("phone").value;
    const phoneLabel = this.serviceForm.get("phoneLabel").value;
    const mail = this.serviceForm.get("mail").value;
    const mailLabel = this.serviceForm.get("mailLabel").value;
    const addressLine1 = this.serviceForm.get("addressLine1").value;
    const addressLine2 = this.serviceForm.get("addressLine2").value;
    const addressCP = this.serviceForm.get("addressCP").value?.toString(10);
    const addressCity = this.serviceForm.get("addressCity").value;
    const addressCountry = this.serviceForm.get("addressCountry").value;

    this.service.defaultAccessGroups = this.serviceForm.get("defaultAccessGroups").value;
    this.service.serviceName = name;
    this.service.identifier[0].label = name;
    this.service.linkedMonitoringOrganizations = [];

    if (!this.isMonitoringService) {
      const monitOrgs: Reference[] = this.serviceForm.get("monitoringOrgsLink").value;
      if (monitOrgs.length > 0) {
        const monitServicesRefs: Reference[] = this.serviceForm.get("monitoringServicesLink").value;
        const servRefs: string[] = monitServicesRefs.map((s) => s.reference);
        for (const org of monitOrgs) {
          const services: Reference[] = this.monitServicesLinks.get(org.reference).filter((s) => servRefs.includes(s.reference));
          this.service.linkedMonitoringOrganizations.push({
            organizationRef: org,
            servicesRef: services,
          });
        }
      }
    }

    const oldPhone = this.service.telecom?.find((t) => t.system === "phone");
    if (oldPhone) {
      oldPhone.value = phone;
      oldPhone.label = phoneLabel ?? undefined;
    } else {
      if (phone) {
        this.service.telecom.push({
          system: "phone",
          value: phone,
          use: "work",
          label: phoneLabel ?? undefined,
        });
      }
    }

    const oldMail = this.service.telecom?.find((t) => t.system === "mail");
    if (oldMail) {
      oldMail.value = mail;
      oldMail.label = mailLabel ?? undefined;
    } else {
      if (mail) {
        this.service.telecom.push({
          system: "mail",
          value: mail,
          use: "work",
          label: mailLabel ?? undefined,
        });
      }
    }

    const oldAddress = this.service.location;
    if (oldAddress) {
      oldAddress.line[0] = addressLine1;
      if (addressLine2) {
        oldAddress.line[1] = addressLine2;
      }
      oldAddress.postalCode = addressCP;
      oldAddress.city = addressCity;
      oldAddress.country = addressCountry;
    } else {
      const adr = {
        use: "work",
        line: [addressLine1],
        city: addressCity,
        postalCode: addressCP,
        country: addressCountry,
      };
      if (addressLine2) {
        adr.line.push(addressLine2);
      }
      this.service.location = adr;
    }

    this.service.photo = this.imageUrl;

    return this.service;
  }

  public phoneValidator(control: AbstractControl): { [key: string]: true } | null {
    return Tools.phoneValidator(control);
  }

  public uploadImage(event: Event): void {
    const files = (event.target as HTMLInputElement).files as FileList;
    const file = files[0];
    const maxSize = 0.6 * 1024 * 1024;
    if (file?.size > maxSize) {
      this.showImgError = true;
      return;
    } else {
      this.showImgError = false;
      if (files && file) {
        const reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
      }
    }
  }

  _handleReaderLoaded(readerEvt: ProgressEvent<FileReader>): void {
    this.imageUrl = readerEvt.target.result as string;
  }

  private checkUsersPermissions(): void {
    this.canReadAccessGroups = this.userService.isAuthorizedSync(null, this.accessGroupApiService.readRoutes[0], "GET");
  }

  /**
   * Loads all the access groups
   * @returns true if it was loaded without error, false otherwise
   */
  private async loadAccessGroups(): Promise<boolean> {
    if (!this.canReadAccessGroups) {
      return false;
    }
    try {
      this.allAccessGroups = await this.accessGroupsService.readAccessGroups();
      return true;
    } catch (err) {
      FileLogger.error("AddServiceComponent", "Error while loading access groups: ", err);
      return false;
    }
  }
}
