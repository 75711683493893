<div>
  <div class="toolbar">
    <div class="left">
      <button
        (click)="back()"
        class="color-button"
        mat-stroked-button
        [matTooltip]="
          previousLocation ? ('page.knowledgeDetails.btn.back2CpEditor' | translate) : ('page.knowledgeDetails.btn.prev' | translate)
        "
      >
        <i class="fas fa-arrow-circle-left fa-lg"></i>
      </button>
    </div>

    <div class="spacer"></div>

    <div class="right">
      <mat-chip-list *ngIf="draftKnowledge">
        <mat-chip color="accent" selected disabled>
          {{ "page.knowledgeDetails.draftInProgress" | translate }}
        </mat-chip>
      </mat-chip-list>

      <div [matTooltip]="canDownload ? (canDownloadMsg | translate) : (cannotDownloadMsg | translate)">
        <button mat-stroked-button (click)="download()" [disabled]="!canDownload">
          <i class="fas fa-download fa-md"></i>
          {{ "page.knowledgeDetails.btn.download" | translate }}
        </button>
      </div>

      <div matTooltip="{{ cantPublishReason | translate }}">
        <button mat-flat-button color="primary" [disabled]="disable" (click)="publish()">
          {{ "page.knowledgeDetails.btn.publish" | translate }}

          <ng-container *ngIf="isPublishing">
            <mat-icon>
              <mat-spinner diameter="20"></mat-spinner>
            </mat-icon>
          </ng-container>
        </button>
      </div>

      <button
        mat-stroked-button
        matTooltip="{{ 'page.knowledgeDetails.btn.add' | translate }}"
        (click)="addMedia()"
        *ngIf="isMngtAuthorized"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <mat-card class="flex-container mat-elevation-z0" [ngStyle]="dataSource.length === 0 ? { 'align-items': 'stretch' } : {}">
    <mat-card>
      <form [formGroup]="knowledgeForm">
        <mat-list>
          <mat-list-item>
            <mat-icon matListAvatar><i class="fas fa-h-square"></i></mat-icon>
            <h3 matLine>{{ "knowledgebase.term" | translate }}</h3>
            <p matLine>
              {{ draftKnowledge ? draftKnowledge?.snomedReference?.display : publishedKnowledge?.snomedReference?.display }}
            </p>
          </mat-list-item>

          <mat-list-item>
            <mat-icon matListAvatar><i class="fas fa-key"></i></mat-icon>
            <h3 matLine>{{ "knowledgebase.snomedRef" | translate }}</h3>
            <p matLine>
              {{ draftKnowledge ? draftKnowledge?.snomedReference?.reference : publishedKnowledge?.snomedReference?.reference }}
            </p>
          </mat-list-item>

          <mat-list-item>
            <mat-icon matListAvatar><i class="fas fa-file"></i></mat-icon>
            <h3 matLine>{{ "knowledgebase.doccategory.label" | translate }}</h3>
            <p matLine>
              {{
                draftKnowledge
                  ? ("knowledgebase.doccategory." + draftKnowledge?.documentCategory | translate)
                  : ("knowledgebase.doccategory." + publishedKnowledge?.documentCategory | translate)
              }}
            </p>
          </mat-list-item>

          <mat-list-item>
            <mat-icon matListAvatar><i class="fas fa-tag"></i></mat-icon>
            <h3 matLine>{{ "knowledgebase.category.label" | translate }}</h3>
            <p matLine>
              {{
                draftKnowledge
                  ? ("knowledgebase.category." + draftKnowledge?.category | translate)
                  : ("knowledgebase.category." + publishedKnowledge?.category | translate)
              }}
            </p>
          </mat-list-item>

          <mat-list-item>
            <mat-icon matListAvatar>
              <i class="fas fa-lock" *ngIf="!knowledgeForm.get('public').value"></i>
              <i class="fas fa-lock-open" *ngIf="knowledgeForm.get('public').value"></i>
            </mat-icon>
            <h3 matLine>{{ "knowledgebase.visibility.title" | translate }}</h3>
            <p matLine>
              {{
                !knowledgeForm.get("public").value
                  ? ("knowledgebase.visibility.private" | translate)
                  : ("knowledgebase.visibility.public" | translate)
              }}
            </p>
            <mat-slide-toggle formControlName="public" color="primary"></mat-slide-toggle>
          </mat-list-item>
        </mat-list>
      </form>
      <mat-divider [inset]="true"></mat-divider>

      <h3>{{ "page.knowledgeDetails.statusAndVisibility" | translate }}</h3>

      <mat-list>
        <mat-list-item *ngIf="draftKnowledge">
          <h3 matLine>
            {{ "page.knowledgeDetails.lastDraftDate" | translate }}
          </h3>
          <p matLine class="primary">{{ draftKnowledge.modified | date : "dd/MM/yyyy HH:mm" }} ({{ draftKnowledge.author.display }})</p>
        </mat-list-item>
        <mat-list-item *ngIf="publishedKnowledge">
          <h3 matLine>
            {{ "page.knowledgeDetails.lastPublishDate" | translate }}
          </h3>
          <p matLine class="primary">
            {{ publishedKnowledge.publicationDate | date : "dd/MM/yyyy HH:mm" }}
            ({{ publishedKnowledge.author.display }})
          </p>
        </mat-list-item>
      </mat-list>
    </mat-card>

    <div class="container" *ngIf="dataSource.length !== 0">
      <ui-alert
        #lastAuthorAlert
        [options]="{ type: 'warning', closable: true, fadeOut: true }"
        *ngIf="lastAuthorRef && lastAuthorRef.reference !== currentUserRef.reference && showWarning"
        (dismiss)="showWarning = false"
      >
        {{ "page.knowledgeDetails.authorWarning" | translate }}
        <b>{{ lastAuthorRef.display }}</b>
      </ui-alert>

      <table class="medias-list mat-elevation-z1" mat-table [dataSource]="dataSource" cdkDropList (cdkDropListDropped)="onDrop($event)">
        <ng-container matColumnDef="grip">
          <td mat-cell *matCellDef="let media">
            <i class="fas fa-grip-vertical fa-lg" [ngClass]="dataSource.length < 2 ? 'disabled' : ''"></i>
          </td>
        </ng-container>

        <ng-container matColumnDef="changes">
          <td mat-cell *matCellDef="let media; let i = index">
            <!-- icon moved -->
            <mat-icon
              *ngIf="(media | compareMedia : publishedKnowledge?.medias : i).compareMedia"
              class="moved-icon"
              [matTooltip]="'page.knowledgeDetails.btn.moved' | translate"
              >compare_arrows
            </mat-icon>
            <!-- icon draft -->
            <i
              *ngIf="
                !(media | compareMedia : publishedKnowledge?.medias : i).isEqual &&
                !(!media.label || !media.language || !media.type) &&
                !(media.entityStatus === StatusEntity.DELETED)
              "
              class="fas fa-circle warn"
              [matTooltip]="'page.knowledgeDetails.btn.modified' | translate"
            ></i>
            <!-- icon error -->
            <i
              *ngIf="(!media.label || !media.language || !media.type) && !(media.entityStatus === StatusEntity.DELETED)"
              class="fas fa-exclamation-circle fail"
              [matTooltip]="'page.knowledgeDetails.btn.errored' | translate"
            ></i>
            <!-- icon deleted -->
            <i
              *ngIf="media.entityStatus === StatusEntity.DELETED"
              class="fas fa-trash-alt"
              [matTooltip]="'page.knowledgeDetails.btn.deleted' | translate"
            ></i>
          </td>
        </ng-container>

        <ng-container matColumnDef="label">
          <th mat-header-cell *matHeaderCellDef>Label</th>
          <td mat-cell *matCellDef="let media">
            <span [ngClass]="media.label ? '' : 'default-text'">{{ media.label ? media.label : ("common.noTitle" | translate) }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="language">
          <th mat-header-cell *matHeaderCellDef>language</th>
          <td mat-cell *matCellDef="let media">{{ media.language }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>description</th>
          <td mat-cell *matCellDef="let media">
            <span [ngClass]="media.description ? '' : 'default-text'">{{
              media.description ? media.description : ("page.knowledgeDetails.noDescription" | translate)
            }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <td mat-cell *matCellDef="let media; let index = index">
            <button
              *ngIf="!(media.entityStatus === StatusEntity.DELETED) && isMngtAuthorized"
              mat-icon-button
              (click)="stopPropagation($event); openMedia(media)"
              matTooltip="{{ 'page.knowledgeDetails.edit' | translate }}"
            >
              <mat-icon class="custom-icon">edit</mat-icon>
            </button>
            <button
              *ngIf="!(media.entityStatus === StatusEntity.DELETED) && isMngtAuthorized"
              mat-icon-button
              (click)="stopPropagation($event); deleteMedia(media, index)"
              matTooltip="{{ 'common.delete' | translate }}"
            >
              <mat-icon class="custom-icon fail">delete</mat-icon>
            </button>
            <button
              *ngIf="media.entityStatus === StatusEntity.DELETED && isMngtAuthorized"
              mat-icon-button
              (click)="stopPropagation($event); cancelDelete(media, index)"
              matTooltip="{{ 'page.knowledgeDetails.restore' | translate }}"
            >
              <mat-icon class="custom-icon success">restore_from_trash</mat-icon>
            </button>
            <!-- <button mat-icon-button [matMenuTriggerFor]="ctxMenu" [matMenuTriggerData]="{media : media, index :index}"
              (click)="stopPropagation($event)">
            <mat-icon>more_horiz</mat-icon>
          </button> -->
          </td>

          <!-- <mat-menu #ctxMenu="matMenu">
          <ng-template matMenuContent let-media="media" let-index="index">
            <button *ngIf="!(media.entityStatus === StatusEntity.DELETED)" mat-menu-item (click)="openMedia(media)">
              {{'page.knowledgeDetails.edit' | translate }}</button>
            <button *ngIf="!(media.entityStatus === StatusEntity.DELETED)" mat-menu-item (click)="deleteMedia(media,index)">
              {{'common.delete' | translate}}</button>
            <button *ngIf="media.entityStatus === StatusEntity.DELETED" mat-menu-item (click)="cancelDelete(media,index)">
              {{'page.knowledgeDetails.restore' | translate}}</button>
          </ng-template>
        </mat-menu> -->
        </ng-container>

        <tr
          [ngClass]="row.entityStatus === StatusEntity.DELETED ? 'deleted' : ''"
          mat-row
          *matRowDef="let row; columns: columnsToDisplay"
          cdkDrag
          [cdkDragDisabled]="row.entityStatus === StatusEntity.DELETED || dataSource.length < 2 || !isMngtAuthorized"
          (click)="row.entityStatus === StatusEntity.ACTIVE ? handleClick(row) : ''"
        ></tr>
      </table>
      <app-knowledge-services
        *ngIf="draftKnowledge || publishedKnowledge"
        [knowledge]="draftKnowledge ? draftKnowledge : publishedKnowledge"
        [readonly]="!!publishedKnowledge"
      >
      </app-knowledge-services>
      <ui-alert
        *ngIf="
          (publishedKnowledge?.documentCategory || draftKnowledge?.documentCategory) === DocumentCategory.FAQ &&
          (draftKnowledge
            ? draftKnowledge?.entityStatus.includes(STATUS_ENTITY.SHARED)
            : publishedKnowledge?.entityStatus.includes(STATUS_ENTITY.SHARED))
        "
        class="mt-1"
        [options]="{ type: 'warning', showIcon: true }"
      >
        {{ "knowledgebase.publicFaqWarning" | translate }}
      </ui-alert>
      <!-- Show linked careplans -->
      <mat-card
        class="mt-1"
        *ngIf="
          (publishedKnowledge?.documentCategory || draftKnowledge?.documentCategory) === DocumentCategory.DESCRIPTION ||
          (publishedKnowledge?.documentCategory || draftKnowledge?.documentCategory) === DocumentCategory.RECOMMENDATION ||
          (publishedKnowledge?.category || draftKnowledge?.category) === KNOW_CATEGORY.CONSENT_TPDA_GENERIC ||
          ((publishedKnowledge?.documentCategory || draftKnowledge?.documentCategory) === DocumentCategory.FAQ && draftKnowledge
            ? !draftKnowledge?.entityStatus.includes(STATUS_ENTITY.SHARED)
            : !publishedKnowledge?.entityStatus.includes(STATUS_ENTITY.SHARED))
        "
      >
        <mat-card-title>{{ "knowledgebase.careplan.linked" | translate }}</mat-card-title>
        <mat-card-content class="mt-1">
          <mat-list *ngIf="linkedCareplans?.length">
            <mat-list-item *ngFor="let careplan of linkedCareplans">
              {{ careplan.name }}
            </mat-list-item>
          </mat-list>
          <p *ngIf="!linkedCareplans?.length">
            {{ "knowledgebase.careplan.empty" | translate }}
          </p>
          <div
            *ngIf="
              (publishedKnowledge?.documentCategory || draftKnowledge?.documentCategory) === DocumentCategory.FAQ ||
              (publishedKnowledge?.category || draftKnowledge?.category) === KNOW_CATEGORY.CONSENT_TPDA_GENERIC
            "
          >
            <h3>{{ "knowledgebase.careplan.toLink" | translate }}</h3>
            <div class="row">
              <mat-form-field>
                <mat-label>{{ "knowledgebase.knowledgeCriteria.careplans" | translate }}</mat-label>
                <mat-select
                  multiple
                  [(ngModel)]="changedLinkedCareplans"
                  [compareWith]="compareFn"
                  (selectionChange)="changeCareplan($event)"
                >
                  <mat-option *ngFor="let careplan of availableCareplans" [value]="careplan">
                    {{ careplan.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div
                matTooltip="{{ 'knowledgebase.knowledgeCriteria.careplanSaveDisabled' | translate }}"
                [matTooltipDisabled]="!draftKnowledge"
              >
                <button mat-flat-button color="primary" (click)="saveLinkedCareplan()" [disabled]="draftKnowledge">
                  {{ "btn.save" | translate }}
                </button>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div *ngIf="dataSource.length === 0" class="no-media">
      <p>{{ "page.knowledgeDetails.noMedia" | translate }}</p>
    </div>
  </mat-card>
</div>
