<h1 mat-dialog-title>
  {{ "page.questionnaireEditor.valueSet.valueSetTemplate" | translate }}
</h1>
<form [formGroup]="valueSetForm" (ngSubmit)="onSaveClick()">
  <div mat-dialog-content class="valueSetContent">
    <mat-form-field appearance="fill" class="flexItem">
      <mat-label for="name">
        {{ "page.questionnaireEditor.valueSet.templatesName" | translate }}
      </mat-label>
      <input matInput id="name" type="text" formControlName="name" />
      <mat-error *ngIf="name.hasError('required')">
        <strong>
          {{ "page.questionnaireEditor.properties.required" | translate }}
        </strong>
      </mat-error>
    </mat-form-field>
    <div class="flexItem">
      <app-use-context
        [services]="data.services"
        [orgsRefs]="data.orgsRefs"
        [showLevels]="false"
        [globalAuthorized]="data.globalPermission"
        [(useContext)]="newUseContexts"
      ></app-use-context>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onCancelClick()">
      {{ "btn.cancel" | translate }}
    </button>
    <button mat-button type="submit" [disabled]="!valueSetForm.valid">
      {{ "btn.save" | translate }}
    </button>
  </div>
</form>
