<div [formGroup]="formGroup">
  <div class="flex-container space-between">
    <div class="flex-container">
      <ng-container formArrayName="traductions" *ngIf="traductionsFormArray.value.length">
        <div [formGroupName]="0" style="margin-right: 10px">
          <mat-form-field style="width: 300px" class="ml-05">
            <mat-spinner
              *ngIf="(traductionsFormArray.value[0] | translationReady) === false"
              matSuffix
              diameter="16"
              style="display: inline-flex"
            ></mat-spinner>

            <input
              matInput
              type="text"
              formControlName="translateKey"
              [value]="(traductionsFormArray.value[0] | translationReady) ? traductionsFormArray.value[0].translateKey : ' '"
              [placeholder]="placeholder"
              id="cpEditor-translationInput"
            />
            <mat-error>
              {{ "forms.errors.ins.required" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </ng-container>
      <mat-slide-toggle formControlName="isTranslated" (change)="openPanel()">
        {{ "page.careplanEditor.tabs.general.translate" | translate }}
      </mat-slide-toggle>
    </div>

    <!-- Location of the activity category selector by content projection for proper positioning -->
    <ng-content></ng-content>
  </div>
  <mat-expansion-panel *ngIf="formGroup.value['isTranslated']">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ "page.careplanEditor.tabs.general.translations" | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div formArrayName="traductions" style="display: flex; flex-wrap: wrap">
      <div
        *ngFor="let trad of traductionsFormArray.controls; let i = index; let isFirst = first; let isLast = last"
        [formGroupName]="i"
        style="display: flex; align-items: center; margin-right: 10px"
      >
        <mat-form-field class="langOption">
          <mat-select formControlName="lang">
            <mat-option *ngFor="let lang of availableLangs | getDescriptionFilteredLang : traductionsFormArray : i" [value]="lang.term">
              {{ lang | getTranslation : lang.term }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        :
        <mat-form-field style="width: 300px" class="ml-05">
          <input matInput type="text" formControlName="translateKey" [value]="traductionsFormArray.value[i].translateKey" />
          <mat-error>
            {{ "forms.errors.ins.required" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-icon class="custom-icon-w sub-align fail iconButton" (click)="deleteTraduction(i)" *ngIf="!isFirst">delete</mat-icon>
        <mat-icon class="iconButton" (click)="addTraduction()" *ngIf="isLast && i < availableLangs.length - 1">add_circle</mat-icon>
      </div>
    </div>
    <div
      style="display: flex; align-items: center"
      *ngIf="formGroup.value['isTranslated'] && traductionsFormArray.value.length < availableLangs.length"
    >
      <p>{{ "page.careplanEditor.tabs.general.defaultLang" | translate }} :</p>
      <mat-form-field class="langOption" style="margin-left: 10px">
        <mat-select formControlName="defaultLang">
          <mat-option *ngFor="let lang of availableLangs" [value]="lang.term">
            {{ lang | getTranslation : lang.term }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-error *ngIf="formGroup.touched && formGroup.hasError('defaultLang')">{{
      "page.careplanEditor.error.defaultLang" | translate
    }}</mat-error>
  </mat-expansion-panel>
</div>
