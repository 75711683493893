import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { concatMap, first, map } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IChartBase64Lang, IPredictionStatus, IRiskAssessment } from "src/app/models/riskAssessment.interface";
import { RiskAssessment } from "src/app/models/riskAssessment.model";
import { UserService } from "../user.service";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class RiskAssessmentApiService extends BaseApiService {
  constructor(private api: ApiService, private userService: UserService) {
    super(
      "RiskAssessment", // Theme !
      ["dashboard/predictionasync/csv/launch"], // Post route paths
      ["riskAssessments", "dashboard/chartsExplainerModel", "downloadReport", "dashboard/predictionasync/state"], // Get route paths
      [], // Put route paths
      [] // Delete route paths
    );
  }

  public listForPatient(caremateIdentifier: string): Observable<RiskAssessment[]> {
    return this.api.get(this.readRoutes[0], { caremateIdentifier }).pipe(
      map((values: IRiskAssessment[]) => {
        return values.map((v) => new RiskAssessment(v));
      })
    );
  }

  public getChartExplainer(project: string, algo: string, version: string, lang: string): Observable<{ shap: IChartBase64Lang[] }> {
    return this.api.get(this.readRoutes[1], {
      project,
      algo,
      version,
      lang,
    }) as Observable<{ shap: IChartBase64Lang[] }>;
  }

  public downloadReport(token: string): Observable<Blob> {
    return this.api.getBlob(this.readRoutes[2], { id: token }) as Observable<Blob>;
  }

  public importPredictionFile(formData: FormData): Observable<null> {
    return this.api.post(this.createRoutes[0], formData, null, true) as Observable<null>;
  }

  public getPredictionState(patientId: string): Observable<IPredictionStatus[]> {
    return this.userService.isAuthorized(this.readRoutes[3], "GET").pipe(
      first(),
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("RiskAssessmentApiService", "User does not have access to: GET " + this.readRoutes[3]);
          return of([]) as Observable<IPredictionStatus[]>;
        }
        return this.api.get(this.readRoutes[3], { patientId }) as Observable<IPredictionStatus[]>;
      })
    );
  }
}
