import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { OrganisationType, Organization } from "../models/organization.model";
import { OrganizationsApiService } from "./api/organisations-api.service";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class OrganizationsService {
  private availableOrganizations$: BehaviorSubject<Organization[]> = new BehaviorSubject<Organization[]>([]);
  private availableMonitoringOrgs$: BehaviorSubject<Organization[]> = new BehaviorSubject<Organization[]>([]);

  constructor(private organizationServie: OrganizationsApiService, private sessionService: SessionService) {}

  public listSome(code: string, country?: string): Observable<Organization[]> {
    return country ? this.organizationServie.listSome(code, country) : this.organizationServie.listSome(code);
  }

  public list(organizationId?: string, type?: OrganisationType): Observable<Organization[]> {
    if (organizationId === this.sessionService.allsOption) {
      organizationId = null;
    }
    return this.organizationServie.list(organizationId, type);
  }

  public create(organization: Organization): Observable<Organization> {
    return this.organizationServie.create(organization);
  }

  public update(organization: Organization): Observable<Organization> {
    return this.organizationServie.update(organization);
  }

  public clear(): void {
    this.availableMonitoringOrgs$.next([]);
    this.availableOrganizations$.next([]);
  }

  public setAvailableOrganizations(organisations: Organization[]): void {
    this.availableOrganizations$.next(organisations);
  }

  public availableOrganizations(): Organization[] {
    return this.availableOrganizations$.value;
  }

  public watchAvailableOrganizations(): Observable<Organization[]> {
    return this.availableOrganizations$;
  }

  public setAvailableMonitoringOrgs(organisations: Organization[]): void {
    this.availableMonitoringOrgs$.next(organisations);
  }

  public availableMonitoringOrgs(): Organization[] {
    return this.availableMonitoringOrgs$.value;
  }

  public watchAvailableMonitoringOrgs(): Observable<Organization[]> {
    return this.availableMonitoringOrgs$;
  }

  public filterOrgsByMonitoring(orgsList: Organization[], monitoringOrgRef?: string, monitoringServiceRef?: string): Organization[] {
    if (!orgsList) {
      return [];
    }
    if (!monitoringOrgRef && !monitoringServiceRef) {
      return orgsList;
    }
    let monitOrgFiltered = orgsList;
    if (monitoringOrgRef && monitoringOrgRef !== this.sessionService.allsOption) {
      monitOrgFiltered = orgsList.filter(
        (o) =>
          o.linkedMonitoringOrganizations &&
          o.linkedMonitoringOrganizations.length > 0 &&
          o.linkedMonitoringOrganizations.findIndex((link) => link.organizationRef.reference === monitoringOrgRef) >= 0
      );
    }
    let monitServiceFiltered = monitOrgFiltered;
    if (monitoringServiceRef && monitoringServiceRef !== this.sessionService.allsOption) {
      monitServiceFiltered = monitOrgFiltered.filter(
        (s) =>
          s.linkedMonitoringOrganizations &&
          s.linkedMonitoringOrganizations.length > 0 &&
          s.linkedMonitoringOrganizations.findIndex(
            (link) => link.servicesRef.findIndex((ref) => ref.reference === monitoringServiceRef) >= 0
          ) >= 0
      );
    }
    return monitServiceFiltered;
  }
}
