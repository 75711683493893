import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import { Observable, of } from "rxjs";
import { concatMap, first, map } from "rxjs/operators";
import { DisplayPicturesComponent } from "../components/display-pictures/display-pictures.component";
import { ObservationAlertsHistoryModalComponent } from "../components/patient-observations/observation-alerts-history-modal/observation-alerts-history-modal.component";
import { FileLogger } from "../helpers/fileLogger";
import { Tools } from "../helpers/tools";
import { IObservation, IObservationDefinition, IObservationWithoutComponents, OComponent } from "../models/observations.interface";
import { Observation } from "../models/observations.model";
import { IObservationParam, IObservationParamWithObsDefinition } from "../models/patientConfig.interface";
import { IStreamGraphLine } from "../models/stream-observation-interface";
import { ObservationApiService } from "./api/observations-api.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class ObservationsService {
  constructor(private observationService: ObservationApiService, private userService: UserService, private dialog: MatDialog) {}

  public list(patientId: string, fromDate?: string, toDate?: string, computeNorms?: boolean): Observable<Observation[]> {
    const routeName = this.observationService.readRoutes[0];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      first(),
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("ObservationsService", "User does not have access to: GET " + routeName);
          return of([]);
        }
        return this.observationService.list(patientId, fromDate, toDate, computeNorms).pipe(
          first(),
          map((values) => {
            return values.map((v) => {
              const parentObservation = Tools.clone(v);
              delete parentObservation.component;
              v.component.forEach((comp) => {
                comp.parentObservation = parentObservation;
              });
              return new Observation(v);
            });
          })
        );
      })
    );
  }

  /**
   * Returns all observation definitions (IObservationDefinition) for a patient or according to a list of loinc codes
   * @param caremateId
   * @param loincs
   * @returns
   */
  public listDef(caremateId?: string, loincs?: string[]): Observable<IObservationDefinition[]> {
    const routeName = this.observationService.readRoutes[1];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      first(),
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("ObservationsService", "User does not have access to: GET " + routeName);
          return of([]);
        }
        return this.observationService.listDef(caremateId, loincs).pipe(
          first(),
          map((values) => {
            return values;
          })
        );
      })
    );
  }

  public listStream(patientId: string, loincs?: string[]): Observable<string[]> {
    const routeName = this.observationService.readRoutes[1];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      first(),
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("ObservationsService", "User does not have access to: GET " + routeName);
          return of([]);
        }
        return this.observationService.listStream(patientId, loincs).pipe(
          first(),
          map((values) => {
            return values;
          })
        );
      })
    );
  }

  public listParams(includeObsDef?: boolean): Observable<IObservationParamWithObsDefinition[]> {
    const routeName = this.observationService.readRoutes[2];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      first(),
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("ObservationsService", "User does not have access to: GET " + routeName);
          return of([]);
        }
        return this.observationService.listParams(includeObsDef).pipe(
          first(),
          map((values) => {
            return values;
          })
        );
      })
    );
  }

  public listOnlineDeviceData(
    patientId: string,
    startDate: string,
    endDate: string,
    externalRessourceRef: string,
    loincs?: string,
    componentLoincs?: string[]
  ): Observable<IStreamGraphLine[]> {
    const routeName = this.observationService.readRoutes[2];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      first(),
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("ObservationsService", "User does not have access to: GET " + routeName);
          return of([]);
        }
        return this.observationService
          .listOnlineDeviceData(patientId, startDate, endDate, externalRessourceRef, loincs, componentLoincs)
          .pipe(
            first(),
            map((values) => {
              return values;
            })
          );
      })
    );
  }

  public showPictures(component: OComponent, name: string, allObservations: IObservation[]): void {
    const code = component.code.coding[0].code;

    const relevantObservations = allObservations.filter((obs) => {
      const index = obs.component.findIndex((c) => c.code.coding[0].code === code && component.valuePictures?.length > 0);
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    });

    const obsIndexOfCurrentComponent = relevantObservations.findIndex((obs) => obs.component.includes(component));

    const previous = this.getSurroundingPictures(relevantObservations, component, obsIndexOfCurrentComponent, code, name, -1);
    const next = this.getSurroundingPictures(relevantObservations, component, obsIndexOfCurrentComponent, code, name, 1);

    this.dialog.open(DisplayPicturesComponent, {
      data: {
        pictures: component.valuePictures,
        title:
          name +
          " - " +
          moment(component.parentObservation.issued).format("DD/MM/YYYY") +
          " - " +
          component.valueQuantity.value +
          " " +
          (component.valueQuantity.unit ? component.valueQuantity.unit : ""),
        previous,
        next,
        disableClose: true,
      },
    });
  }

  /**
   * get the previous pictures (prevOrNext = -1) or the next ones (prevOrNext = 1)
   */
  private getSurroundingPictures(
    observations: IObservation[],
    component: OComponent,
    currentIndex: number,
    code: string,
    name: string,
    prevOrNext: number
  ) {
    return observations[currentIndex + prevOrNext]
      ? {
          pictures: this.getComponent(observations[currentIndex + prevOrNext], code).valuePictures,
          title:
            name +
            " - " +
            moment(observations[currentIndex + prevOrNext].issued).format("DD/MM/YYYY") +
            " - " +
            this.getComponent(observations[currentIndex + prevOrNext], code).valueQuantity.value +
            " " +
            (component.valueQuantity.unit ? component.valueQuantity.unit : ""),
          issued: moment(observations[currentIndex + prevOrNext].issued).format("DD/MM/YYYY"),
        }
      : null;
  }

  public getComponent(observation: IObservation, code: string): OComponent {
    const i = observation.component.findIndex((c) => c.code.coding[0].code === code);
    return observation.component[i];
  }

  public openAlertHistory(observation: IObservation | IObservationWithoutComponents, obsDefinition: IObservationDefinition[]): void {
    this.dialog.open(ObservationAlertsHistoryModalComponent, {
      data: { observation, obsDefinitions: obsDefinition },
      maxWidth: 850,
    });
  }

  /**
   * Creates a new observation definition and parameter
   * @param obsDef The new observation definition to save as an ObservationDefinition
   * @param obsParam The new observation parameter to save as an IObservationParam
   * @returns The saved observation definition
   */
  public createObservationDef(obsDef: IObservationDefinition, obsParam: IObservationParam): Observable<IObservationDefinition> {
    return this.observationService.createObservationDef(obsDef, obsParam);
  }

  /**
   * Returns an observation list (IObservation) from a linked questionnaireResponse identifier
   * @param questionnaireResponseRef
   * @returns
   */
  public getObservationsByQR(questionnaireResponseRef: string): Promise<IObservation[]> {
    return this.observationService.getObservationsByQR(questionnaireResponseRef).toPromise();
  }

  public exportObservations(
    patientId: string,
    startDate: string,
    endDate: string,
    obsCodes: string[],
    lang: string,
    docType: string
  ): Observable<Blob> {
    return this.observationService.exportObservations(patientId, startDate, endDate, obsCodes, lang, docType).pipe(first());
  }
}
