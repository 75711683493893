import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-pause-drug-schema",
  templateUrl: "./pause-drug-schema.component.html",
  styleUrls: ["./pause-drug-schema.component.scss"],
})
export class PauseDrugSchemaComponent implements OnInit, OnDestroy {
  public pausePlayForm = this.fb.group({
    when: [undefined, Validators.required],
    date: [],
  });
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();

  constructor(private fb: UntypedFormBuilder, private dialogRef: MatDialogRef<PauseDrugSchemaComponent>) {}

  ngOnInit(): void {
    const date = this.pausePlayForm.get("date");
    this.pausePlayForm
      .get("when")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((r) => {
        if (r === "chooseDate") {
          date.setValidators([Validators.required]);
        } else {
          date.setValidators(null);
        }
        date.updateValueAndValidity();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onSave(): void {
    let date: Date;
    if (this.pausePlayForm.valid) {
      if (this.pausePlayForm.get("when").value === "chooseDate") {
        date = moment(this.pausePlayForm.get("date").value).toDate();
      } else if (this.pausePlayForm.get("when").value === "today") {
        date = moment().toDate();
      } else if (this.pausePlayForm.get("when").value === "tomorrow") {
        date = moment().add(1, "day").toDate();
      }
      this.dialogRef.close(date);
    } else {
      return;
    }
  }
}
