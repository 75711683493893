import { xAxisType, yAxisType } from "../helpers/chart-helper";
import { ILines } from "./statObservation.interface";

export interface IStreamGraphLine {
  dateOfDay: string;
  loinc: string;
  componentCode: string;
  deviceReference: string;
  values: IStreamGraphValue[];
  referenceLines: {
    xLines?: ILines; // any lines to be drawn vertically
    yLines?: ILines; // any lines to be drawn horizontally
  };
  yAxes: { min: number; max: number };
  xAxes: { min: string; max: string };
}
export interface IStreamGraphValue {
  valueQuantity: number;
  valueArray: number[][];
  effectiveTimingCode?: string;
  time: string; // hh:mm:ss
  minutesAfterMidnight?: number;
  unit: string;
}

export interface IAxisConfig {
  type: xAxisType | yAxisType;
  min?: number;
  max?: number;
}

export interface IChartConfig {
  margin: number;
  width: number;
  height: number;
  xAxis: IAxisConfig;
  yAxis: IAxisConfig;
}

export enum EXTERNAL_RESSOURCE_REF {
  dexcomGen7 = "dexcomGen7",
}
