import { Injectable } from "@angular/core";
import { FileLogger } from "../helpers/fileLogger";
import { IAccessGroup, IAccessGroupPermission, IPermissionsByTheme } from "../models/accessGroup.interface";
import { AccessGroupApiService } from "./api/accessGroup-api.service";
import { DashboardRoutesService } from "./dashboardRoutes.service";

@Injectable({
  providedIn: "root",
})
export class AccessService {
  constructor(private api: AccessGroupApiService, private dashboardRoutesService: DashboardRoutesService) {}

  public createAccessGroup(group: IAccessGroup): Promise<IAccessGroup> {
    return this.api.createAccessGroup(group).toPromise() as Promise<IAccessGroup>;
  }

  public readAccessGroups(): Promise<IAccessGroup[]> {
    return this.api.readAccessGroups().toPromise();
  }

  public updateAccessGroup(group: IAccessGroup): Promise<IAccessGroup> {
    return this.api.updateAccessGroup(group).toPromise() as Promise<IAccessGroup>;
  }

  public deleteAccessGroup(id: string): Promise<void> {
    return this.api.deleteAccessGroup(id).toPromise() as Promise<void>;
  }

  // --------------------------------------------------------------------------

  public createAccessGroupPermission(permission: IAccessGroupPermission): Promise<IAccessGroupPermission> {
    return this.api.createAccessGroupPermission(permission).toPromise() as Promise<IAccessGroupPermission>;
  }

  public readAccessGroupsPermissions(accessGroupId: string): Promise<IAccessGroupPermission[]> {
    return this.api.readAccessGroupsPermissions(accessGroupId).toPromise();
  }

  public updateAccessGroupPermission(permission: IAccessGroupPermission): Promise<IAccessGroupPermission> {
    return this.api.updateAccessGroupPermission(permission).toPromise() as Promise<IAccessGroupPermission>;
  }

  public deleteAccessGroupPermission(id: string): Promise<void> {
    return this.api.deleteAccessGroupPermission(id).toPromise() as Promise<void>;
  }

  public async loadGroupsPermissions(accessGroups: IAccessGroup[]): Promise<boolean> {
    const permissionsPromises = [];
    for (const group of accessGroups) {
      group.permissions = [];
      const promise = this.readAccessGroupsPermissions(group.identifier.value).then((perms: IAccessGroupPermission[]) => {
        const permsByTheme: IPermissionsByTheme[] = this.getPermsByTheme(perms);
        group.permissions = perms.sort((p1, p2) => {
          if (p1.theme === "Pages") {
            return -1;
          }
          return p1.theme.localeCompare(p2.theme);
        });
        group.permsByTheme = permsByTheme;
      });
      permissionsPromises.push(promise);
    }
    await Promise.all(permissionsPromises);
    return true;
  }

  public getPermsByTheme(perms: IAccessGroupPermission[]): IPermissionsByTheme[] {
    const permsByTheme: IPermissionsByTheme[] = [];
    for (const perm of perms) {
      if (!perm.theme) {
        this.setPermissionTheme(perm);
      }
      const byTheme = permsByTheme.find((pTheme) => pTheme.theme === perm.theme);
      if (byTheme) {
        byTheme.permissions.push(perm);
      } else {
        permsByTheme.push({ theme: perm.theme, permissions: [perm] });
      }
    }
    return permsByTheme.sort((p1, p2) => {
      if (p1.theme === "Pages") {
        return -1;
      }
      return p1.theme.localeCompare(p2.theme);
    });
  }

  public getPermissionsFromPermsByTheme(permsByTheme: IPermissionsByTheme[]): IAccessGroupPermission[] {
    const perms: IAccessGroupPermission[] = [];
    for (const p of permsByTheme) {
      perms.push(...p.permissions);
    }
    return perms;
  }

  private setPermissionTheme(permission: IAccessGroupPermission): void {
    if (permission.routeName.startsWith("/")) {
      const pagesPermissions = this.dashboardRoutesService.getDashboardRoutes();
      if (pagesPermissions.read.find((r) => r === permission.routeName)) {
        permission.theme = pagesPermissions.theme;
      } else {
        FileLogger.warn("AccessService", "No theme for permission: ", permission);
        permission.theme = "Other";
      }
      return;
    }
    for (const serviceRoute of DashboardRoutesService.allApiRoutes) {
      let routes: string[] = [];
      switch (permission.method) {
        case "POST":
          routes = serviceRoute.create;
          break;
        case "GET":
          routes = serviceRoute.read;
          break;
        case "PUT":
          routes = serviceRoute.update;
          break;
        case "DELETE":
          routes = serviceRoute.delete;
          break;
        default:
          FileLogger.warn("AccessService", "No method for permission: ", permission);
          break;
      }
      if (routes.find((r) => r === permission.routeName)) {
        permission.theme = serviceRoute.theme;
        return;
      }
    }
    FileLogger.warn("AccessService", "No theme for permission: ", permission);
    permission.theme = "Other";
  }
}
