<div class="patientDisableDialog">
  <mat-dialog-content>
    <h3 *ngIf="data.patients.length === 1">
      {{ "page.patientlist.disablePatientConfirm" | translate }}
    </h3>
    <h3 *ngIf="data.patients.length > 1">
      {{ "page.patientlist.disablePatientPluralConfirm" | translate : { length: data.patients.length } }}
    </h3>
    <div>
      <mat-label>{{ "model.patient.disablePatientReason.title" | translate }}</mat-label>
      <mat-radio-group class="radio-button-list" (change)="onRadioChange($event)">
        <mat-radio-button [value]="reasons.DEATH">
          {{ "model.patient.disablePatientReason." + reasons.DEATH | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="reasons.HOSPITALIZATION">
          {{ "model.patient.disablePatientReason." + reasons.HOSPITALIZATION | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="reasons.PERSONALPROBLEM">
          {{ "model.patient.disablePatientReason." + reasons.PERSONALPROBLEM | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="reasons.OTHER">
          {{ ("model.patient.disablePatientReason." + reasons.OTHER | translate) + " :" }}
        </mat-radio-button>
      </mat-radio-group>
      <mat-form-field class="otherReasonInput">
        <input matInput type="text" [disabled]="selectedReason !== reasons.OTHER" [(ngModel)]="otherReason" maxlength="30" />
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button cdkFocusInitial mat-dialog-close>
      {{ "common.no" | translate }}
    </button>
    <button mat-button (click)="onYes()">{{ "common.yes" | translate }}</button>
  </mat-dialog-actions>
</div>
