import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TimingEditorComponent } from "src/app/components/timing-editor/timing-editor.component";
import { IQuestionnaireParam } from "src/app/models/questionnaire.interface";

@Component({
  selector: "app-questionnaire-params",
  templateUrl: "./questionnaire-params.component.html",
  styleUrls: ["./questionnaire-params.component.scss"],
})
export class QuestionnaireParamsComponent implements OnInit {
  // Important: no changes in the inputs of this component are transmitted to
  // the parent component until the "applyChanges()" method is called !
  @Input() params: IQuestionnaireParam;
  @Input() disabled = false;
  @Output() paramsChange = new EventEmitter<IQuestionnaireParam>();

  @ViewChild(TimingEditorComponent) freqEditor: TimingEditorComponent;

  public paramsForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    if (this.params) {
      this.paramsForm = this.fb.group({
        notify: [this.params.notify, [Validators.required]],
      });
      if (this.disabled) {
        this.paramsForm.disable();
      }
    }
  }

  /**
   * Transmit the changes in the input to the parent component
   * @returns
   */
  public applyChanges(): boolean {
    if (this.paramsForm.valid) {
      this.params.notify = this.paramsForm.value.notify;
      const freqValid = this.freqEditor ? this.freqEditor.save() : true;
      if (freqValid) {
        this.paramsChange.emit(this.params);
        return true;
      }
    }
    return false;
  }
}
