import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { I18nModule } from "src/app/i18n.module";
import { PipesModule } from "src/app/pipes/pipes.module";
import { WidgetManagerService } from "src/app/providers/widget-manager.service";
import { ItemFilterModule } from "../item-filter/item-filter.module";
import { LastPatientWidgetComponent } from "./last-patient-widget/last-patient-widget.component";
import { PatientAlertNoTransmissionWidgetComponent } from "./patient-alert-no-transmission-widget/patient-alert-no-transmission-widget.component";
import { PatientAlertOrangeWidgetComponent } from "./patient-alert-orange-widget/patient-alert-orange-widget.component";
import { PatientAlertRedWidgetComponent } from "./patient-alert-red-widget/patient-alert-red-widget.component";
import { PatientAlertTableComponent } from "./patient-alert-table/patient-alert-table.component";
import { PatientLastActivityWidgetComponent } from "./patient-last-activity-widget/patient-last-activity-widget.component";
import { PatientListWidgetComponent } from "./patient-list-widget/patient-list-widget.component";
import { PendingPrescriptionsWidgetComponent } from "./pending-prescriptions-widget/pending-prescriptions-widget.component";
import { TeleConsultationWidgetComponent } from "./tele-consultation-widget/tele-consultation-widget.component";
import { WidgetManagerComponent } from "./widget-manager/widget-manager.component";
import { WidgetDirective } from "./widget.directive";

@NgModule({
  declarations: [
    WidgetDirective,
    WidgetManagerComponent,
    TeleConsultationWidgetComponent,
    LastPatientWidgetComponent,
    PatientAlertOrangeWidgetComponent,
    PatientAlertRedWidgetComponent,
    PatientAlertTableComponent,
    PatientLastActivityWidgetComponent,
    PatientListWidgetComponent,
    PatientAlertNoTransmissionWidgetComponent,
    PendingPrescriptionsWidgetComponent,
  ],
  imports: [
    RouterModule,
    I18nModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    DragDropModule,
    MatDialogModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ItemFilterModule,
    PipesModule,
    MatProgressSpinnerModule,
  ],
  providers: [WidgetManagerService],
  exports: [
    WidgetDirective,
    WidgetManagerComponent,
    TeleConsultationWidgetComponent,
    LastPatientWidgetComponent,
    PatientAlertOrangeWidgetComponent,
    PatientAlertRedWidgetComponent,
    PatientAlertTableComponent,
    PatientLastActivityWidgetComponent,
    PatientListWidgetComponent,
    PatientAlertNoTransmissionWidgetComponent,
    PendingPrescriptionsWidgetComponent,
  ],
})
export class WidgetModule {}
