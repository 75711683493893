import { Component, Input, OnChanges } from "@angular/core";
import { IStatsObsGroups } from "src/app/models/statObservation.interface";

@Component({
  selector: "app-stat-card",
  templateUrl: "./stat-card.component.html",
  styleUrls: ["./stat-card.component.scss"],
})
export class StatCardComponent implements OnChanges {
  public noData: boolean;
  @Input() statsObsGroups: IStatsObsGroups;
  @Input() reversed: boolean;

  public countBetweenRangeData: { label: string; value: number }[];
  ngOnChanges(): void {
    this.noData = this.statsObsGroups.statistics.every((statistic) => {
      return statistic.values.length === 0;
    });
    this.computesCountBetweenRange();
  }

  private computesCountBetweenRange() {
    const stat = this.statsObsGroups.statistics.find((stat) => stat.keyName === "stat.countBetweenRange");
    if (!stat) return [];

    const orderedValues = stat.values.sort((a, b) => a.range - b.range);
    const sumOfAllValues = orderedValues.reduce((acc, item) => acc + item.valueQuantity.value, 0);

    this.countBetweenRangeData = orderedValues.map((item, index) => {
      let label = `< ${item.range} mg/dl`;

      if (index > 0) {
        const previousRange = orderedValues[index - 1].range;
        label = `${previousRange} mg/dl - ${item.range} mg/dl`;
      }

      const percentage = item.valueQuantity.value / sumOfAllValues; // as a decimal since the pipe percentage do the job in template

      return {
        label,
        value: percentage,
      };
    });
  }
}
