<div class="choiceInputProperties" *ngIf="choiceQuestion && choiceQuestion.options">
  <section class="properties-group" *ngIf="isScale">
    <mat-checkbox [(ngModel)]="showChoices" class="checkbox" (change)="handleValuesMeaningChange()" [disabled]="disabled">
      {{ "page.questionnaireEditor.properties.valuesMeaning" | translate }}
    </mat-checkbox>
  </section>

  <app-value-set
    [lang]="lang"
    [isScale]="isScale"
    [showChoices]="showChoices"
    [setValueSetRef]="choiceQuestion.options?.reference"
    [contained]="questionsOptions"
    (containedChange)="onValueSetChange($event)"
    [disabled]="disabled"
  ></app-value-set>

  <section class="properties-group" *ngIf="isScale">
    <br />
    <div class="sideBySideFields">
      <mat-form-field>
        <mat-label>
          {{ "page.questionnaireEditor.properties.min" | translate }}
        </mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="min"
          [max]="max"
          (change)="onScalesValuesChange()"
          [disabled]="showChoices || disabled"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>
          {{ "page.questionnaireEditor.properties.max" | translate }}
        </mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="max"
          [min]="min"
          (change)="onScalesValuesChange()"
          [disabled]="showChoices || disabled"
        />
      </mat-form-field>
    </div>
    <div class="sideBySideFields">
      <mat-form-field>
        <mat-label>
          {{ "page.questionnaireEditor.properties.initial" | translate }}
        </mat-label>
        <input
          matInput
          type="number"
          [min]="min"
          [max]="max"
          [(ngModel)]="initial"
          [disabled]="disabled"
          (change)="onScalesValuesChange()"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>
          {{ "page.questionnaireEditor.properties.step" | translate }}
        </mat-label>
        <input matInput type="number" min="1" [(ngModel)]="step" (change)="onScalesValuesChange()" [disabled]="showChoices || disabled" />
      </mat-form-field>
    </div>
  </section>
  <!-- ========================= SCALES =================================== -->
  <section class="properties-group" *ngIf="isScale">
    <h4>
      {{ "page.questionnaireEditor.properties.scalesDirection" | translate }}
    </h4>
    <mat-slide-toggle [(ngModel)]="reverseValues" (change)="onReverseValues()" [disabled]="disabled">
      {{ "page.questionnaireEditor.properties.values" | translate }}
      {{ valuesOrder }}
    </mat-slide-toggle>
    <br />
    <mat-slide-toggle *ngIf="isSmileyScale" [(ngModel)]="reverseSmiley" (change)="onReverseSmiley()" [disabled]="disabled">
      {{ "page.questionnaireEditor.properties.smiley" | translate }}
      {{ smileyOrder }}
    </mat-slide-toggle>
  </section>
  <!-- ==================== Correct & wrong answer ======================== -->
  <section class="properties-group" *ngIf="specificUse === SPECIFIC_USE.QUIZ || specificUse === SPECIFIC_USE.CONSENT">
    <div class="sideBySideFields">
      <h4 style="margin-top: 1rem">{{ "page.questionnaireEditor.properties.correctAnswers" | translate }} :</h4>
      <mat-form-field>
        <mat-select
          multiple
          name="correctAnswers"
          [(ngModel)]="goodAnswers"
          [required]="specificUse"
          (selectionChange)="goodAnswersChange()"
          [disabled]="disabled"
        >
          <mat-option *ngFor="let questionOption of questionsOptions[0].compose.include[0].concept" [value]="questionOption.code">
            {{ questionOption.code }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <ng-container *ngIf="specificUse === SPECIFIC_USE.QUIZ">
      <mat-form-field class="w-full">
        <mat-label>{{ "page.questionnaireEditor.properties.correctAnswerText" | translate }} :</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="choiceQuestion.answerDisplay.textPass"
          [disabled]="disabled"
          (change)="onQuizValuesChange()"
        />
      </mat-form-field>
      <mat-form-field class="w-full">
        <mat-label>{{ "page.questionnaireEditor.properties.wrongAnswerText" | translate }} :</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="choiceQuestion.answerDisplay.textFailed"
          [disabled]="disabled"
          (change)="onQuizValuesChange()"
        />
      </mat-form-field>
    </ng-container>
  </section>
</div>
