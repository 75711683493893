<div
  class="widget"
  [ngStyle]="{ 'justify-content': !file || hasError ? 'center' : 'unset' }"
  appDndFiles
  (fileDropped)="dragAndDropFile($event)"
>
  <ng-container *ngIf="!file || hasError">
    <mat-icon class="fa-icon doubleScale" fontSet="fas" fontIcon="fa-download"></mat-icon>
    <p class="mt-1">
      {{ "uiFileDrop.textPt1" | translate }}
      <a (click)="onSelectFile()">
        {{ "uiFileDrop.textPt2" | translate }}
        <input
          #importInput
          type="file"
          style="display: none"
          [accept]="acceptedTypes.toString()"
          [size]="maxSize"
          (change)="import($event)"
        />
      </a>
    </p>
  </ng-container>
  <ng-container *ngIf="file && !hasError">
    <button mat-icon-button aria-label="Icon button with a close icon" class="closeIcon" (click)="resetFile()">
      <mat-icon>close</mat-icon>
    </button>
    <p class="fileDetail">
      <mat-icon class="customScale">description</mat-icon>
      <span>{{ file.name }}</span>
    </p>
  </ng-container>
</div>
