import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IKnowledgesCriteria } from "../models/knowledgescriteria-interface";
import { KnowledgeCriteriaApiService } from "./api/knowledge-criteria-api.service";

@Injectable({
  providedIn: "root",
})
export class KnowledgeCriteriaService {
  constructor(private api: KnowledgeCriteriaApiService) {}

  public list(
    careplanIds?: string[],
    knowledgeIds?: string[],
    careplanId?: string,
    knowledgeId?: string
  ): Observable<{ published: IKnowledgesCriteria[]; draft: IKnowledgesCriteria[] }> {
    return this.api.list(true, careplanIds, knowledgeIds, careplanId, knowledgeId);
  }

  public create(knowledgeCriteria: IKnowledgesCriteria): Observable<IKnowledgesCriteria> {
    delete knowledgeCriteria.showDirectly;
    return this.api.create(knowledgeCriteria);
  }

  public update(knowledgeCriteria: IKnowledgesCriteria): Observable<IKnowledgesCriteria> {
    delete knowledgeCriteria.showDirectly;
    return this.api.update(knowledgeCriteria);
  }

  public deleteById(id: string): Observable<unknown> {
    return this.api.deleteById(id);
  }

  public delete(careplanId: string, knowledgeId: string, activityId?: string): Observable<unknown> {
    return this.api.delete(careplanId, knowledgeId, activityId);
  }

  public updateCareplansWithKnCriteria(reference: string, identifier: string): Observable<unknown> {
    return this.api.updateCareplansWithKnCriteria(reference, identifier);
  }
}
