import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IPrescriptionInfosResponse } from "src/app/models/prescription.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class PrescriptionApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Prescription", // Theme !
      [], // Post route paths
      ["dashboard/prescriptionInfos"], // Get route paths
      [], // Update route paths
      [] // Delete route paths
    );
  }

  public getPrescriptionInfos(
    organizations?: string,
    services?: string,
    patientCaremateId?: string,
    period?: string,
    date?: string,
    status?: string
  ): Observable<IPrescriptionInfosResponse[]> {
    const params = { organizations, services, patientCaremateId, period, date, status };
    return this.api.get(this.readRoutes[0], params) as Observable<IPrescriptionInfosResponse[]>;
  }
}
