import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { UserService } from "../providers/user.service";

@Pipe({ name: "isAuthorized" })
export class IsAuthorizedPipe implements PipeTransform {
  constructor(private userService: UserService) {}
  transform(routeName: string, method = "GET"): Observable<boolean> {
    return this.userService.isAuthorized(routeName, method);
  }
}
