import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { TranslateService } from "@ngx-translate/core";
import { ReplaySubject, Subject, Subscription, of } from "rxjs";
import { debounceTime, map, takeUntil, tap } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IPatientAppointement, PATIENT_TYPE } from "src/app/models/patient-appointement.interface";
import { AppointmentService } from "src/app/providers/appointment.service";

@Component({
  selector: "app-server-side-search",
  templateUrl: "./server-side-search.component.html",
  styleUrls: ["./server-side-search.component.scss"],
})
export class ServerSideSearchComponent implements OnInit, OnDestroy {
  constructor(private translateService: TranslateService, private appService: AppointmentService) {}

  @Output() patientSelected: EventEmitter<IPatientAppointement> = new EventEmitter<IPatientAppointement>();

  /** control for filter for server side. */
  public patientServerSideFilteringCtrl: UntypedFormControl = new UntypedFormControl();

  /** indicate search operation is in progress */
  public searching = false;

  public filteredServerSidePatient: ReplaySubject<IPatientAppointement[]> = new ReplaySubject<IPatientAppointement[]>(1);

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  protected onDestroy$ = new Subject<void>();

  public noEntriesFoundLabel = this.translateService.instant("common.noEntriesFoundLabel") as string;
  public placeholderLabel = this.translateService.instant("common.placeholderLabel") as string;

  ngOnInit(): void {
    let $subPA: Subscription;
    // listen for search field value changes
    this.patientServerSideFilteringCtrl.valueChanges
      .pipe(
        tap(() => (this.searching = true)),
        takeUntil(this.onDestroy$),
        debounceTime(500),
        map((search) => {
          if (search) {
            return this.appService.getPatientAppointement(search);
          } else {
            return of(undefined);
          }
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (pa) => {
          if ($subPA) {
            $subPA.unsubscribe();
          }
          $subPA = pa.pipe(takeUntil(this.onDestroy$)).subscribe(
            (result) => {
              this.filteredServerSidePatient.next(result);
              this.searching = false;
            },
            (err) => {
              FileLogger.error("ServerSideSearchComponent", "ngOnInit - pipe", err);
              this.searching = false;
              this.filteredServerSidePatient.next(undefined);
            }
          );
        },
        (error) => {
          FileLogger.error("ServerSideSearchComponent", "ngOnInit", error);
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        }
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public emit(event: MatSelectChange): void {
    this.patientSelected.emit(event.value);
  }

  public getClass(patient: IPatientAppointement): string {
    return "fa-" + (patient.type === PATIENT_TYPE.APPOINTEMENT ? "video" : "mobile-alt");
  }
}
