<div class="answerNewQr">
  <div class="buttons-bar">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close matTooltip="{{ 'btn.close' | translate }}">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="spinner-container" *ngIf="isLoading">
      <mat-spinner class="centerSpinner"></mat-spinner>
    </div>

    <ui-alert *ngIf="questionnaires.length === 0 && !isLoading" [options]="{ type: 'error', showIcon: true }" class="mt-1">
      {{ "pdf.questionnaire.noQ" | translate }}
    </ui-alert>
    <div *ngIf="questionnaires.length > 0 && !isLoading">
      <h2>{{ "questionnaire.chooseQR" | translate }}</h2>
      <mat-selection-list [multiple]="false">
        <mat-list-option *ngFor="let questionnaire of questionnaires" (click)="openAnswerNewQr(questionnaire)">
          {{ questionnaire.subjectType }}</mat-list-option
        >
      </mat-selection-list>
    </div>
  </mat-dialog-content>
</div>
