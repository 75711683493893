import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Tools } from "src/app/helpers/tools";
import { ICareplan } from "src/app/models/careplans.interface";
import { PatientUser } from "src/app/models/patient.interface";
import { Reference } from "src/app/models/reference.interface";
import { IGroup } from "../patient-careplans/patient-careplans.component";

export interface ICareplanDialogOption {
  patient: PatientUser;
  careplan: ICareplan;
  careplans?: IGroup[];
  includeBirthdateAndGenderForm?: boolean;
  registration?: boolean;
  healthcareService?: Reference;
}

@Component({
  selector: "app-careplan-dialog",
  templateUrl: "./careplan-dialog.component.html",
  styleUrls: ["./careplan-dialog.component.scss"],
})
export class CareplanDialogComponent implements OnInit {
  public careplan: ICareplan;

  constructor(
    public dialogRef: MatDialogRef<CareplanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogOption: ICareplanDialogOption
  ) {}

  ngOnInit(): void {
    if (this.dialogOption.careplan) {
      this.careplan = Tools.clone(this.dialogOption.careplan);
      // passing this.dialogOption.careplan directly to careplan.component causes bugs
    }
  }

  public saved(): void {
    this.dialogRef.close();
  }
}
