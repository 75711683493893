import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { TranslateService } from "@ngx-translate/core";
import { ReplaySubject, Subject, Subscription, of } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { Group } from "src/app/models/group.model";
import { GroupService } from "src/app/providers/group-api.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";

@Component({
  selector: "app-group-server-side-search",
  templateUrl: "./group-server-side-search.component.html",
  styleUrls: ["./group-server-side-search.component.scss"],
})
export class GroupServerSideSearchComponent implements OnInit, OnDestroy {
  constructor(
    private translateService: TranslateService,
    private groupService: GroupService,
    private sessionService: SessionService,
    private userService: UserService,
    private healthcareService: HealthcareserviceService
  ) {}

  public allGroups: Group[] = [];
  @Output() groupSelected: EventEmitter<Group> = new EventEmitter<Group>();

  /** control for filter for server side. */
  public groupServerSideFilteringCtrl: UntypedFormControl = new UntypedFormControl();

  /** indicate search operation is in progress */
  public searching = false;

  public filteredServerSideGroup: ReplaySubject<Group[]> = new ReplaySubject<Group[]>(1);

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  protected onDestroy$ = new Subject<void>();

  public noEntriesFoundLabel = this.translateService.instant("common.noEntriesFoundLabel") as string;
  public placeholderLabel = this.translateService.instant("group.findAGroup") as string;
  public dummyGroup: Group = new Group({
    groupName: this.translateService.instant("group.new"),
    caremateIdentifier: null,
    patients: null,
    public: null,
    healthcareservice: null,
  });

  public newName: string;

  ngOnInit(): void {
    const currentService = this.userService.isMonitoringUser
      ? this.sessionService.currentMonitoringService
      : this.sessionService.currentService;
    const ref = currentService?.reference;
    const services = [];
    if (ref === this.sessionService.allsOption) {
      const allServices = this.userService.isMonitoringUser
        ? this.healthcareService.availableMonitoringServices()?.map((s) => s.serviceRef)
        : this.healthcareService.availableServices()?.map((s) => s.serviceRef);
      services.push(...allServices);
    }
    this.groupService
      .list(ref, services)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((groups) => {
        this.allGroups = groups;
      });
    let pa$: Subscription;
    // listen for search field value changes
    this.groupServerSideFilteringCtrl.valueChanges
      .pipe(
        tap(() => (this.searching = true)),
        takeUntil(this.onDestroy$),
        map((search) => {
          if (search) {
            return of(this.filter(search));
          } else {
            return of(undefined);
          }
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (pa) => {
          if (pa$) {
            pa$.unsubscribe();
          }
          pa$ = pa.pipe(takeUntil(this.onDestroy$)).subscribe(
            (result) => {
              this.filteredServerSideGroup.next(result);
              this.searching = false;
            },
            (err) => {
              FileLogger.error("GroupServerSideSearchComponent", "ngOnInit - pipe", err);
              this.filteredServerSideGroup.next(undefined);
              this.searching = false;
            }
          );
        },
        (error) => {
          FileLogger.error("GroupServerSideSearchComponent", "ngOnInit", error);
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        }
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public emit(event: MatSelectChange): void {
    const group = event.value as Group;
    if (!group.isValid) {
      group.groupName = this.newName;
    }
    this.groupSelected.emit(event.value);
  }

  public filter(value: any): Group[] {
    if (!value.groupName) {
      this.newName = value as string;
      const filterValue = Tools.suppressDiacritics(value).toLowerCase();
      const groupFiltered = this.allGroups.filter((g) => Tools.suppressDiacritics(g.groupName)?.toLowerCase().indexOf(filterValue) === 0);
      this.dummyGroup.groupName = value as string;
      groupFiltered.push(this.dummyGroup);
      return groupFiltered;
    } else {
      const filterValue = Tools.suppressDiacritics(value).toLowerCase();
      const groupFiltered = this.allGroups.filter((g) => Tools.suppressDiacritics(g.groupName)?.toLowerCase().indexOf(filterValue) === 0);
      return groupFiltered;
    }
  }
}
