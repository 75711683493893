import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IObservationDefinition } from "src/app/models/observations.interface";
import { IStatsAlertsGroups, IStatsObsGroups, TypeDisplayStats } from "src/app/models/statObservation.interface";

/**
 * Component displaying the title and the content of a statistic card (group of statistics). This component will display the stat-card component or the stat table component depending on the statsGroups.type
 */
@Component({
  selector: "app-observation-stats",
  templateUrl: "./observation-stats.component.html",
  styleUrls: ["./observation-stats.component.scss"],
})
export class ObservationStatsComponent {
  public TypeDisplayStats = TypeDisplayStats;
  @Input() statsGroups: IStatsObsGroups | IStatsAlertsGroups;
  @Input() obsDefinition: IObservationDefinition;
  @Input() type: "alert" | "observation";
  @Input() fromDate: moment.Moment;
  @Input() toDate: moment.Moment;

  constructor(public translateService: TranslateService) {}
}
