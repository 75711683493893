import { CdkDrag } from "@angular/cdk/drag-drop";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Base64Helper } from "src/app/helpers/Base64Helper";
import { Filter } from "src/app/models/filter.interface";
import { PreferenceContext, WidgetFilterParameter } from "src/app/models/preference.interface";
import { IPrescriptionInfosResponse } from "src/app/models/prescription.interface";
import { IWidgetComponent } from "src/app/models/widget.interface";
import { PreferenceService } from "src/app/providers/preference.service";
import { PrescriptionService } from "src/app/providers/prescription.service";
import { PendingPrescriptionsDataSource } from "./pending-prescriptions-widget-datasource";

@Component({
  selector: "app-pending-prescriptions-widget",
  templateUrl: "./pending-prescriptions-widget.component.html",
  styleUrls: ["./pending-prescriptions-widget.component.scss"],
})
export class PendingPrescriptionsWidgetComponent implements IWidgetComponent, OnInit, OnDestroy {
  @ViewChild(CdkDrag, { static: true }) cdkDrag: CdkDrag;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<IPrescriptionInfosResponse>;
  @ViewChild(MatSort) sort: MatSort;

  public isLoading = true;
  public isDraggable = true;
  public displayedColumns: string[] = ["lastname", "firstname", "status", "category", "dateRequest", "actions"];
  public dataSource: PendingPrescriptionsDataSource;
  public pendingPrescriptions: IPrescriptionInfosResponse[] = [];
  private onDestroy$ = new Subject<void>();

  constructor(private prescriptionService: PrescriptionService, private preferenceService: PreferenceService, private router: Router) {}

  ngOnInit(): void {
    this.dataSource = new PendingPrescriptionsDataSource();
    this.initData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initData() {
    this.prescriptionService
      .getPrescriptionInfos()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((pendingPrescriptions) => {
        this.pendingPrescriptions = pendingPrescriptions;
        this.loadPreferences();
        this.loadDataSource();
      });
  }

  private loadDataSource() {
    this.dataSource.loadData(this.pendingPrescriptions);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
    this.isLoading = false;
  }

  private loadPreferences() {
    this.preferenceService
      .list(PreferenceContext.WIDGET_PENDING_PRESCRIPTIONS)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: WidgetFilterParameter) => {
        if (parameters) {
          // Apply saved filters
          parameters.filters.forEach((filter: Filter) => {
            this.applyFilter(filter);
          });
        }
      });
  }

  private updatePreference() {
    this.preferenceService
      .update({
        context: PreferenceContext.WIDGET_PENDING_PRESCRIPTIONS,
        parameters: {
          filters: this.dataSource.getAllFilters(),
        } as WidgetFilterParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  private applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
    this.updatePreference();
  }

  public goToPatient(app: IPrescriptionInfosResponse): void {
    const patientId = app.patientCaremateId;
    const b64Id = Base64Helper.utf8_to_b64(patientId);
    this.router.navigateByUrl(`/patient;id=${b64Id}`, {
      state: undefined,
    });
  }
}
