import { ErrorHandler, Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, concatMap, filter, first } from "rxjs/operators";
import { DeleteRequestService } from "../deleteRequest.service";
import { HealthcareserviceService } from "../healthcareservice.service";
import { SessionService } from "../session.service";
import { UserService } from "../user.service";

@Injectable({
  providedIn: "root",
})
export class DeleteRequestResolver implements Resolve<{ count: number }> {
  constructor(
    private deleteRequestService: DeleteRequestService,
    private sessionService: SessionService,
    private healthcareService: HealthcareserviceService,
    private userService: UserService,
    private globalErrorHandler: ErrorHandler
  ) {}

  resolve(): Observable<{ count: number }> {
    return this.userService.account().pipe(
      filter((a) => a !== null && a !== undefined),
      first(),
      concatMap(() => {
        let services = [];
        if (this.sessionService.currentService?.display === this.sessionService.allsOption) {
          services = this.healthcareService.availableServices()?.map((s) => s.serviceRef);
        } else {
          services.push(this.sessionService.currentService?.reference);
        }
        return this.deleteRequestService.getDeleteRequestCount(services);
      }),
      catchError((err) => {
        return throwError(this.globalErrorHandler.handleError(err));
      })
    );
  }
}
