import { ErrorHandler, Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { KnowledgeApiService } from "../api/knowledge-api.service";

@Injectable({
  providedIn: "root",
})
export class KnowledgeResolver implements Resolve<{ count: number }> {
  constructor(private knowledgeApiService: KnowledgeApiService, private globalErrorHandler: ErrorHandler) {}

  resolve(): Observable<{ count: number }> {
    // appelle API qui contient l'information du nombre total d'éléments à afficher
    return this.knowledgeApiService.getKnowledgeCount().pipe(
      catchError((err) => {
        return throwError(this.globalErrorHandler.handleError(err));
      })
    );
  }
}
