import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
import { Activity } from "../models/careplans.interface";

@Pipe({ name: "activityDate" })
export class ActivityDate implements PipeTransform {
  transform(activity: Activity, m: "start" | "end"): string {
    if (activity.detail.scheduledString?.includes("T")) {
      return moment(activity.detail.scheduledString).format("DD/MM/YYYY HH:mm");
    } else if (activity?.detail?.scheduledString && !activity.detail.scheduledString?.includes("T")) {
      return moment(activity.detail.scheduledString).format("DD/MM/YYYY");
    }

    if (activity.detail.scheduledTiming?.repeat?.boundsPeriod && activity.detail.scheduledTiming.repeat.boundsPeriod[m]) {
      return moment(activity.detail.scheduledTiming.repeat.boundsPeriod[m]).format("DD/MM/YYYY");
    }

    if (activity.detail.scheduledPeriod && activity.detail.scheduledPeriod[m]) {
      return moment(activity.detail.scheduledPeriod[m]).format("DD/MM/YYYY");
    }

    return null;
  }
}
