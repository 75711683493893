import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IQuestionnaire } from "../models/questionnaire.interface";
import { SCORING_TYPE } from "../models/questionnaireScoring.interface";
import { QuestionnaireScoring } from "../models/questionnaireScoring.model";
import { IsGoodAnswerVer2Pipe } from "../pipes/isGoodAnswer.pipe";
import { scores, scoresTable, template, tr, trScores } from "./static-values/qTemplateA";

export interface ITrValues {
  qid: string | number;
  topic?: string;
  question: string;
  answer: string;
  value?: string | number;
}

@Injectable({
  providedIn: "root",
})
export class QuestionnairePDFHelper {
  public tr: string = tr;
  public template: string = template;
  public trScoring: string = trScores;
  public scores: string = scores;

  constructor(private translateService: TranslateService) {}

  private get translatedTemplate() {
    return this.template
      .replace("%QID%", this.translateService.instant("pdf.questionnaire.QID"))
      .replace("%Topic%", this.translateService.instant("pdf.questionnaire.topic"))
      .replace("%Question%", this.translateService.instant("pdf.questionnaire.question"))
      .replace("%Answer%", this.translateService.instant("pdf.questionnaire.answer"))
      .replace("%DATE_TITlE%", this.translateService.instant("table.date"))
      .replace("%QUESTIONS%", this.translateService.instant("table.questions"));
  }

  private getCompletedTr(values: ITrValues, questionnaire?: IQuestionnaire) {
    const isGoodAnswerVer2Pipe = new IsGoodAnswerVer2Pipe();
    return this.tr
      .replace("%QID_VAL%", `${values.qid}`)
      .replace("%Topic_VAL%", `${values.topic || ""}`)
      .replace("%Question_VAL%", `${values.question}`)
      .replace("%Answer_VAL%", `${values.answer || "N.A"}`)
      .replace(
        "%VAL_VAL%",
        !questionnaire || !questionnaire?.specificUse
          ? `${values.value || "N.A"}`
          : values.value +
              " " +
              (isGoodAnswerVer2Pipe.transform(values.value, questionnaire.group.group[0].question, values.qid.toString())
                ? "<span>&#9745;</span>"
                : "<span>&#9746;</span>")
      );
  }

  private getCompletedTrScoring(score: QuestionnaireScoring) {
    return this.trScoring
      .replace(
        "%SCORE_NAME_VAL%",
        score.identifier?.label ? `${score.identifier.label}` : this.translateService.instant("model.patient.questionnaireTotalScore")
      )
      .replace("%SCORE_VAL%", `${score.scoringValue || score.scoringValue === 0 ? score.scoringValue : "-"}`)
      .replace(
        "%SCORE_INTERPRETATION%",
        `${
          score.interpretationValue?.result && score.interpretationValue.result[this.translateService.currentLang]
            ? score.interpretationValue.result[this.translateService.currentLang]
            : "-"
        }`
      );
  }

  private getCompletedTrArray(valuesArr: ITrValues[], questionnaire?: IQuestionnaire) {
    return valuesArr.map((v) => this.getCompletedTr(v, questionnaire));
  }

  private getCompletedTrScoringArray(scoresDetails: QuestionnaireScoring[]) {
    return scoresDetails.map((s) => this.getCompletedTrScoring(s));
  }

  private addScoresToTemplate(allScores: QuestionnaireScoring[][]) {
    const tempTemplate: string[] = [];
    allScores.forEach((s) => {
      const allTrScores = this.getCompletedTrScoringArray(s).reduce((acc, el) => acc + el, "");
      tempTemplate.push(
        scoresTable
          .replace("%CAT_NAME%", s[0]?.identifier?.value ? s[0].identifier.value : "")
          .replace("%SCORE_NAME_TITLE%", this.translateService.instant("table.name"))
          .replace("%SCORE_VALUE_TITLE%", this.translateService.instant("table.score"))
          .replace("%SCORE_INTERPRETATION_TITLE%", this.translateService.instant("pdf.questionnaire.scoreInterpretation"))
          .replace("%TR_SCORES%", allTrScores)
      );
    });
    return tempTemplate;
  }

  public getConstructedTemplate(
    valuesArr: ITrValues[],
    date: string,
    scoresToAdd?: QuestionnaireScoring[][],
    questionnaire?: IQuestionnaire
  ): string {
    const flatTr = this.getCompletedTrArray(valuesArr, questionnaire).reduce((acc, el) => acc + el, "");
    let templateWithValueAndDate = this.translatedTemplate.replace("%TR%", flatTr).replace("%DATE_VALUE%", date);
    if (scoresToAdd?.[0]?.[0].scoringType === SCORING_TYPE.QUIZ_CORRECT_ANSWERS) {
      templateWithValueAndDate = templateWithValueAndDate.replace("%VAL%", "");
    } else {
      templateWithValueAndDate = templateWithValueAndDate.replace("%VAL%", this.translateService.instant("pdf.questionnaire.value"));
    }
    if (!scoresToAdd.length) {
      return templateWithValueAndDate.replace("%SCORES%", "");
    } else {
      templateWithValueAndDate = templateWithValueAndDate.replace("%SCORES%", scores);
      const flatTrScores = this.addScoresToTemplate(scoresToAdd).reduce((acc, el) => acc + el, "");
      return templateWithValueAndDate.replace("%SCORES_TABLE%", flatTrScores);
    }
  }
}
