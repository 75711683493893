import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class AnonymisedDataApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "AnonymisedData", // Theme !
      [], // Post route paths
      [], // Get route paths
      [], // Put route paths
      [] // Delete route paths
    );
  }

  public export(
    formatExport: "CSV" | "JSON",
    supportCareplans: string,
    exportObs?: string,
    exportQuests?: string,
    emailsToExclude?: string,
    servicesToExclude?: string,
    exportPatients?: boolean,
    exportDrugs?: boolean,
    exportAlert?: boolean,
    anonymisedDate?: boolean,
    careplansWithoutApp?: string
  ): Observable<Blob> {
    return this.api.getBlob("script/exportAnonymisedData", {
      formatExport,
      supportCareplans,
      exportObs,
      exportQuests,
      emailsToExclude,
      servicesToExclude,
      exportPatients: exportPatients.toString(),
      exportDrugs: exportDrugs.toString(),
      exportAlert: exportAlert.toString(),
      anonymisedDate: anonymisedDate.toString(),
      careplansWithoutApp,
    }) as Observable<Blob>;
  }
}
