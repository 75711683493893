export interface UnknowPatientData {
  name: string;
  firstname: string;
  email: string;
  internalId: string;
  phoneNbr: string;
  smsMailSent: boolean;
  smsType: SMS_TYPE;
  patientLang: string;
  serviceContactNumber: string;
  shortUrl?: string;
}

export enum SMS_TYPE {
  NEW = 1,
  UPDATE = 2,
  DELETE = 3,
}
