import { CdkDrag } from "@angular/cdk/drag-drop";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { Base64Helper } from "src/app/helpers/Base64Helper";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { HelpData } from "src/app/helpers/helpData";
import { ActivityLog } from "src/app/models/activity-log.interface";
import { DataType, Filter } from "src/app/models/filter.interface";
import { Patient } from "src/app/models/patient.interface";
import { PreferenceContext, WidgetFilterParameter } from "src/app/models/preference.interface";
import { IWidgetComponent, WidgetName } from "src/app/models/widget.interface";
import { ActivityService } from "src/app/providers/activity.service";
import { PatientService } from "src/app/providers/patient.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { LastPatientListDataSource } from "./last-patient-widget-list-datasource";

@Component({
  selector: "app-last-patient-widget",
  templateUrl: "./last-patient-widget.component.html",
  styleUrls: ["./last-patient-widget.component.scss"],
})
export class LastPatientWidgetComponent implements IWidgetComponent, OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<ActivityLog>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(CdkDrag, { static: true }) cdkDrag: CdkDrag;

  public widgetName = WidgetName;
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public isDraggable = true;
  public displayedColumns: string[] = ["patient", "date"];
  public dataSource: LastPatientListDataSource;
  public activities: ActivityLog[] = [];
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    private activityService: ActivityService,
    private patientService: PatientService,
    private dialog: MatDialog,
    private helpData: HelpData,
    private preferenceService: PreferenceService
  ) {}

  ngOnInit(): void {
    this.dataSource = new LastPatientListDataSource(this.patientService);
    this.initData();
  }

  ngOnDestroy(): void {
    this.dataSource.clear();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initData() {
    this.activityService
      .list()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((activities) => {
        this.activities = activities;
        this.loadPreferences();
        this.loadDataSource();
      });
  }

  private loadDataSource() {
    this.dataSource.loadData(this.activities);
    this.initPaginatorAndSort();
  }

  private initPaginatorAndSort() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  /**
   * Preferences
   */
  private updatePreference() {
    this.preferenceService
      .update({
        context: PreferenceContext.WIDGET_LAST_PATIENTS,
        parameters: {
          filters: this.dataSource.getAllFilters(),
        } as WidgetFilterParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  private loadPreferences() {
    this.preferenceService
      .list(PreferenceContext.WIDGET_LAST_PATIENTS)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: WidgetFilterParameter) => {
        if (parameters) {
          // Apply saved filters
          parameters.filters.forEach((filter: Filter) => {
            this.applyFilter(filter);
          });
        }
      });
  }

  public getCaremateIdentifier(patient: Patient): string {
    return FHIRHelper.getMainIdentifier(patient).value;
  }

  public encodeToB64(str: string): string {
    return Base64Helper.utf8_to_b64(str);
  }

  public openLastPatientsHelp(): void {
    this.dialog.open(GlobalHelpDialogComponent, {
      data: { slides: this.helpData.lastPatients },
      disableClose: true,
    });
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
    this.updatePreference();
  }

  public clearFilter(): void {
    this.dataSource.clearFilter();
  }
}
