import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Appointment } from "src/app/models/appointment.model";

@Component({
  selector: "app-appointment-delay",
  templateUrl: "./appointment-delay.component.html",
  styleUrls: ["./appointment-delay.component.scss"],
})
export class AppointmentDelayComponent {
  @Input() appointments: Appointment[];
  @Input() minutesDelay: number;

  @Output() minutesDelayChange: EventEmitter<number> = new EventEmitter<number>();

  public increaseMinutes(): void {
    this.minutesDelay++;
  }

  public decreaseMinutes(): void {
    if (this.minutesDelay !== 0) {
      this.minutesDelay--;
    }
  }

  public emitMinutesChange(): void {
    this.minutesDelayChange.emit(this.minutesDelay);
  }
}
