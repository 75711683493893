import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { IPatientInfo } from "src/app/models/patient.interface";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PatientService } from "src/app/providers/patient.service";
import { SessionService } from "src/app/providers/session.service";

export class PatientsInfosDataSource extends MatTableDataSourceExtended<IPatientInfo> {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();
  private $subListInfos: Subscription;

  constructor(
    private patientService: PatientService,
    private sessionService: SessionService,
    private healthcareService: HealthcareserviceService
  ) {
    super();
  }

  public clear(): void {
    this.loadingSubject.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public searchPatients(criteria: string): void {
    this.loadingSubject.next(true);
    let services: string[] = null;
    if (this.sessionService.currentService?.display === this.sessionService.allsOption) {
      services = this.healthcareService.availableServices().map((s) => s.asReference.reference);
    } else {
      services = [this.sessionService.currentService?.reference];
    }

    if (this.$subListInfos) {
      this.$subListInfos.unsubscribe();
    }
    this.$subListInfos = this.patientService
      .listPatientInfo(services, criteria, 100)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((patientsInfos) => {
        this.data = patientsInfos;
      });
  }
}
