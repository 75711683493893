<div class="change-lang-container">
  <ng-container *ngIf="isButtonMode">
    <div *ngFor="let language of availableLangs">
      <button
        mat-button
        (click)="onChange(language.term)"
        [disabled]="language.term === currentLang"
        id="{{ 'loginbtn_' + language.term }}"
      >
        <p class="flagText">
          <img [src]="'assets/i18n/flags/' + language.term + '.svg'" />
          {{ language.term | uppercase }}
        </p>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="isDropdownMode">
    <mat-form-field>
      <mat-select [(value)]="currentLang" [ngClass]="'flag-' + currentLang" [title]="'languages.lang' | translate">
        <mat-option *ngFor="let language of availableLangs" [value]="language.term">
          <p class="flagText">
            <img width="10" height="10" [src]="'assets/i18n/flags/' + language.term + '.svg'" />
            {{ language.term | uppercase }}
          </p>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
  <div *ngIf="isDropdownShortMode" class="selectShortLang">
    <mat-form-field appearance="none">
      <mat-select [(value)]="currentLang" [title]="'languages.lang' | translate" id="languageDropDown">
        <mat-option *ngFor="let language of availableLangs" [value]="language.term" id="{{ 'dropDown_' + language }}">
          <p class="flagText">
            {{ language.term | uppercase }}
          </p>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
