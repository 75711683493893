<form *ngIf="freqForm" [formGroup]="freqForm">
  <div class="full-w flex" [ngStyle]="{ 'flex-wrap': !largeDisplay ? 'wrap' : 'nowrap' }" *ngIf="needStartEnd">
    <div [ngClass]="{ 'full-w': !largeDisplay, flex: true }">
      <mat-label>
        {{ "mydrugs.from" | translate }}
      </mat-label>
      <mat-form-field>
        <input
          matInput
          [matDatepicker]="from"
          formControlName="fromDate"
          required
          [min]="careplanStartDate"
          [max]="careplanEndDate"
          (click)="from.open()"
        />
        <mat-datepicker-toggle matSuffix [for]="from" [disabled]="isDisabled || !authorizedToEdit"></mat-datepicker-toggle>
        <mat-datepicker #from [disabled]="isDisabled || !authorizedToEdit"></mat-datepicker>
      </mat-form-field>
    </div>
    <div [ngClass]="{ 'full-w': !largeDisplay, flex: true }">
      <mat-label>
        {{ "mydrugs.endDate" | translate }}
      </mat-label>
      <mat-slide-toggle [checked]="noEndDate" [disabled]="isDisabled || !authorizedToEdit" (toggleChange)="toggleEndDate()">
        {{ (noEndDate ? "mydrugs.noEndDate" : "") | translate }}
      </mat-slide-toggle>
      <mat-form-field *ngIf="!noEndDate">
        <input
          matInput
          [matDatepicker]="to"
          formControlName="toDate"
          required
          [min]="careplanStartDate"
          [max]="careplanEndDate"
          (click)="to.open()"
          [disabled]="isDisabled || !authorizedToEdit"
        />
        <mat-datepicker-toggle matSuffix [for]="to" [disabled]="isDisabled || !authorizedToEdit"></mat-datepicker-toggle>
        <mat-datepicker #to [disabled]="isDisabled || !authorizedToEdit"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <ng-container *ngIf="isFrequencyDisplayed">
    <ng-container *ngIf="!hasCycle && !hasStepwise">
      <div class="full-w flex" [ngStyle]="{ 'flex-wrap': !largeDisplay ? 'wrap' : 'nowrap' }">
        <fieldset class="fieldset">
          <div [ngClass]="{ 'full-w': !largeDisplay, flex: true }" *ngIf="context !== 'activityFrequency'">
            <mat-radio-group class="radio" formControlName="freqOptions">
              <mat-radio-button class="optionsRadio" *ngFor="let option of freqOptions" [value]="option.value">
                {{ option.key | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div *ngIf="freqForm.get('everyValue')" [ngClass]="{ 'full-w': !largeDisplay, flex: true }">
            <mat-label>{{ "schedule.every" | translate }}</mat-label>
            <mat-form-field [ngStyle]="{ 'margin-right': !largeDisplay ? '10px' : '40px' }">
              <mat-select formControlName="everyValue">
                <mat-option *ngFor="let time of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]" [value]="time">
                  {{ time }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-select formControlName="everyType">
                <mat-option *ngFor="let p of availableEveryType" [value]="p">
                  {{ p.key }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="freqForm.get('fixedDates') && context !== 'activityFrequency'">
            <div
              *ngFor="let date of fixedDatesFormArray.controls; let i = index"
              formArrayName="fixedDates"
              [ngStyle]="{
                color: hasValidate && freqForm.get('fixedDates').invalid ? 'red' : 'black'
              }"
            >
              <div class="date">
                <input
                  style="width: fit-content"
                  matInput
                  [formControlName]="i"
                  [matDatepicker]="fixedDatesPicker"
                  name="{{ i }}_fixedDates"
                />
                <mat-datepicker-toggle matSuffix [for]="fixedDatesPicker"> </mat-datepicker-toggle>
                <mat-datepicker #fixedDatesPicker></mat-datepicker>
              </div>
            </div>
            <button mat-button (click)="addFixedDate()" color="primary" [disabled]="!authorizedToEdit" *ngIf="!isDisabled">
              <mat-icon aria-hidden="false" aria-label="Add icon">add</mat-icon>
              {{ "forms.addDate" | translate }}
            </button>
            <button
              *ngIf="fixedDatesFormArray.length > 1 && !isDisabled"
              mat-button
              (click)="removeFixedDate()"
              color="warn"
              [disabled]="!authorizedToEdit"
            >
              <mat-icon aria-hidden="false" aria-label="Remove icon">remove</mat-icon>
              {{ "forms.removeDate" | translate }}
            </button>
          </div>
          <div *ngIf="freqForm.get('weekDays') && context !== 'activityFrequency'" [ngClass]="{ 'full-w': !largeDisplay, flex: true }">
            <mat-label
              [ngStyle]="{
                color: hasValidate && freqForm.get('weekDays').invalid ? 'red' : 'black'
              }"
            >
              {{ "periodUnit.day.singPlural" | translate }}</mat-label
            >
            <mat-form-field class="ml-15 auto-w">
              <mat-select formControlName="weekDays" multiple>
                <mat-option *ngFor="let d of availableWeekDays" [value]="d">
                  {{ d.key }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="freqForm.get('monthDays') && context !== 'activityFrequency'" [ngClass]="{ 'full-w': !largeDisplay, flex: true }">
            <mat-label
              [ngStyle]="{
                color: hasValidate && freqForm.get('monthDays').invalid ? 'red' : 'black'
              }"
            >
              {{ "mydrugs.dayofmonth" | translate }}</mat-label
            >
            <mat-form-field class="ml-15 auto-w">
              <mat-select formControlName="monthDays" multiple>
                <mat-option *ngFor="let d of availableMonthDays" [value]="d">
                  {{ d }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="freqForm.get('months') && context !== 'activityFrequency'" [ngClass]="{ 'full-w': !largeDisplay, flex: true }">
            <mat-label
              [ngStyle]="{
                color: hasValidate && freqForm.get('months').invalid ? 'red' : 'black'
              }"
            >
              {{ "periodUnit.month.plural" | translate }}
            </mat-label>
            <mat-form-field class="ml-15 auto-w">
              <mat-select formControlName="months" multiple>
                <mat-option *ngFor="let m of availableMonths" [value]="m.value">
                  {{ m.key }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </fieldset>
      </div>
      <div *ngIf="freqForm.get('hourFrom') && context !== 'activityFrequency'" class="full-w flex">
        <div>
          <mat-label
            [ngStyle]="{
              color: hasValidate && freqForm.get('hourFrom').invalid ? 'red' : 'black'
            }"
          >
            {{ "schedule.hourFrom" | translate }}
          </mat-label>
          <ngx-timepicker-field
            [format]="24"
            formControlName="hourFrom"
            [controlOnly]="true"
            [defaultTime]="freqForm.get('hourFrom').value"
          >
          </ngx-timepicker-field>
        </div>
        <mat-slide-toggle [checked]="noEndHour" [disabled]="isDisabled || !authorizedToEdit" (toggleChange)="toggleEndHour()">
        </mat-slide-toggle>
        <div *ngIf="!noEndHour">
          <mat-label
            [ngStyle]="{
              color: hasValidate && freqForm.get('hourTo').invalid ? 'red' : 'black'
            }"
          >
            {{ "schedule.hourTo" | translate }}</mat-label
          >
          <ngx-timepicker-field
            [disabled]="isDisabled || !authorizedToEdit"
            [format]="24"
            formControlName="hourTo"
            [defaultTime]="freqForm.get('hourTo').value"
            [controlOnly]="true"
          >
          </ngx-timepicker-field>
        </div>
      </div>
    </ng-container>
    <fieldset *ngIf="!hasStepwise && context !== 'activityFrequency' && (freqForm.get('timingOptions') || freqForm.get('timing'))">
      <div *ngIf="freqForm.get('timingOptions')" [ngClass]="{ 'full-w': !largeDisplay, flex: true }">
        <mat-radio-group class="radio" formControlName="timingOptions">
          <mat-radio-button class="optionsRadio" *ngFor="let option of timingOptions" [value]="option.value">
            {{ option.key | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div *ngIf="freqForm.get('timing')" style="position: relative">
        <div class="full-w flex">
          <div
            *ngFor="let timing of availableTimings; let i = index"
            formArrayName="timing"
            [ngStyle]="{
              color: hasValidate && freqForm.get('timing').invalid ? 'red' : 'black'
            }"
          >
            <mat-checkbox [formControlName]="i">
              {{ timing.key | translate }}
            </mat-checkbox>
          </div>
        </div>
        <!-- Quantities functionality -->
        <div class="full-w flex" *ngIf="needQuantities && freqForm.get('timing') && freqForm.get('quantities')">
          <div *ngFor="let timing of availableTimings; let i = index" style="width: 75px" formArrayName="quantities">
            <mat-form-field *ngIf="freqForm.get('timing').value[i]" class="mat-field" class="quantityContainer">
              <mat-select
                placeholder="{{ 'mydrugs.dose' | translate }}"
                [formControlName]="i"
                [id]="'quantitySelect' + i"
                matTooltip="{{ 'mydrugs.quantityTooltip' | translate }}"
                matTooltipPosition="right"
                matTooltipHideDelay="1000"
              >
                <mat-option style="width: 75px">
                  <ngx-mat-select-search
                    placeholderLabel="{{ 'mydrugs.dose' | translate }}"
                    [formControlName]="i"
                    [clearSearchInput]="false"
                    hideClearSearchButton="true"
                    pattern="[A-Za-zÀ-ú0-9+-/ ']{0,100}"
                  >
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let quantity of availableQuantities[i] | async" [value]="quantity">
                  {{ quantity }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="freqForm.get('timeOfDay')">
        <div
          *ngFor="let hour of timeOfDayFormArray.controls; let i = index"
          formArrayName="timeOfDay"
          [ngStyle]="{
            color: hasValidate && freqForm.get('timeOfDay').invalid ? 'red' : 'black'
          }"
        >
          <ngx-timepicker-field [format]="24" [formControlName]="i" [controlOnly]="true" [defaultTime]="hour.value"> </ngx-timepicker-field>
        </div>
        <button mat-button (click)="addFixedHour()" color="primary" *ngIf="!isDisabled">
          <mat-icon aria-hidden="false" aria-label="Add icon">add</mat-icon>
          {{ "forms.addHour" | translate }}
        </button>
        <button *ngIf="timeOfDayFormArray.length > 1 && !disabled" mat-button (click)="removeFixedHour()" color="warn">
          <mat-icon aria-hidden="false" aria-label="Remove icon">remove</mat-icon>
          {{ "forms.removeHour" | translate }}
        </button>
      </div>
    </fieldset>
  </ng-container>
</form>
