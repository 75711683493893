import { Injectable } from "@angular/core";
import { first } from "rxjs/operators";
import { PatientWidgetName } from "../models/preference.interface";
import { PreferenceService } from "./preference.service";
import { SessionService } from "./session.service";

export interface ITileAndStatus {
  tileName: PatientWidgetName;
  isBig: boolean;
}

@Injectable()
export class TileManager {
  private static listTileAndStatus: ITileAndStatus[] = [];
  public static showAll = false;

  public static isBig(key: PatientWidgetName): boolean {
    return TileManager.listTileAndStatus.find((v) => v.tileName === key)?.isBig;
  }

  public static isOneBigAmongSelected(selected: PatientWidgetName[]): boolean {
    const i = TileManager.listTileAndStatus.findIndex((tile) => tile.isBig === true && selected.includes(tile.tileName));
    return i > -1;
  }

  constructor(private preferenceService: PreferenceService, private sessionService: SessionService) {
    this.init();
  }

  public init(): void {
    TileManager.listTileAndStatus = Object.keys(PatientWidgetName).map((key) => {
      return {
        tileName: PatientWidgetName[key],
        isBig: false,
      };
    });

    this.preferenceService
      .getGlobal()
      .pipe(first())
      .subscribe((parameters) => {
        if (parameters?.showAllWidgetWhenDetails !== TileManager.showAll) {
          TileManager.showAll = parameters?.showAllWidgetWhenDetails;
          this.sessionService.needRefreshPatientWidgets();
        }
      });
  }

  public updateList(key: PatientWidgetName, isBig: boolean): void {
    const tile = TileManager.listTileAndStatus.find((v) => v.tileName === key);
    if (tile) {
      tile.isBig = isBig;
    }
    this.sessionService.needRefreshPatientWidgets();
  }
}
