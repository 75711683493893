import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { UserService } from "../providers/user.service";

@Injectable({
  providedIn: "root",
})
export class PermissionGuard implements CanActivate {
  constructor(private userService: UserService) {}

  canActivate(next: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    return this.userService.isAuthorized("/" + next.routeConfig.path, "GET");
  }
}
