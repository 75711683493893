import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActivityLog } from "../models/activity-log.interface";
import { Patient } from "../models/patient.interface";
import { ActivityApiService } from "./api/activity-api.service";

@Injectable({
  providedIn: "root",
})
export class ActivityService {
  constructor(private activityApiService: ActivityApiService) {}

  public log(patient: Patient) {
    return this.activityApiService.log(patient);
  }

  public list(): Observable<ActivityLog[]> {
    return this.activityApiService.list() as Observable<ActivityLog[]>;
  }
}
