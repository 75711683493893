import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Inject, OnInit, Pipe, PipeTransform } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { FileLogger } from "src/app/helpers/fileLogger";
import { PREVENTCHARACTER, PreventCharacter } from "src/app/helpers/formValidators";
import { Tools } from "src/app/helpers/tools";
import { Coding } from "src/app/models/coding.interface";
import { Contained, QuestionQuestionnaire } from "src/app/models/questionnaire.interface";
import { IValueSet } from "src/app/models/questionnaireScoring.interface";

@Pipe({ name: "getQuestionNumber" })
export class GetQuestionNumber implements PipeTransform {
  transform(id: string, questions: QuestionQuestionnaire[]): number {
    return questions.findIndex((q) => q.linkId === id) + 1;
  }
}
@Pipe({ name: "getCanBeInFormula" })
export class GetCanBeInFormula implements PipeTransform {
  transform(question: QuestionQuestionnaire): boolean {
    if (question?.answerDisplay?.multiInputs) {
      return false;
    }
    const excludedType = ["string", "text", "textfield", "date", "time", "checkboxes"];
    return !(excludedType.includes(question?.answerDisplay?.type) || excludedType.includes(question?.type));
  }
}
@Pipe({ name: "getQuestionOptions" })
export class GetQuestionOptions implements PipeTransform {
  transform(question: QuestionQuestionnaire, contained: Contained[]): Coding[] {
    if (contained) {
      const c = contained.find((model) => model.idSet === question.options?.reference);
      if (c) {
        return c.compose.include[0].concept;
      }
    }
  }
}

@Pipe({ name: "getValueSetValue" })
export class GetValueSetValue implements PipeTransform {
  transform(question: QuestionQuestionnaire, optionId: string, scoringValueSet: IValueSet[]): string {
    const options = scoringValueSet?.find((s) => s.id === question.linkId);
    if (options) {
      return options.values.find((v) => v.key === optionId)?.value;
    }
  }
}

@Component({
  selector: "app-questionnaire-scoring-formula",
  templateUrl: "./questionnaire-scoring-formula.component.html",
  styleUrls: ["./questionnaire-scoring-formula.component.scss"],
})
export class QuestionnaireScoringFormulaComponent implements OnInit {
  public response$: Observable<unknown>;
  public decodedFormula: {
    type: string; // operator, question, number
    value: string | number;
  }[] = [];
  public operatorList = ["(", ")", "+", "-", "*", "/"];
  public currentNumberValue: number;
  public PREVENTCHARACTER = PREVENTCHARACTER;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      questions: QuestionQuestionnaire[];
      formula: string;
      visualization: boolean;
      contained: Contained[];
      scoringValueSet: IValueSet[];
    },
    private dialogRef: MatDialogRef<QuestionnaireScoringFormulaComponent>
  ) {}

  ngOnInit(): void {
    this.decodeFormula();
    this.setupValueSet();
  }

  /**
   * Decode the scoring formula string to be in this format :
   * {
   *   type: string; // operator, question, number
   *   value: string | number
   * }[]
   */
  private decodeFormula(): void {
    try {
      const formulaChars = Array.from(this.data.formula ? this.data.formula : ""); // convert the formula string into a an array of chars
      let i = 0;
      while (i < formulaChars.length) {
        const char = formulaChars[i];
        if (char === " ") {
          // White space : nothing to do
          i++;
        } else if (this.operatorList.includes(char)) {
          // Operator char : add it to the decodedFormula array
          this.decodedFormula.push({
            type: "operator",
            value: char,
          });
          i++;
        } else if (!isNaN(Number(char))) {
          // Number segment : take all nexts number char and add the result (extendedNumber) to the decodedFormula array
          let extendedNumber = "";
          do {
            extendedNumber += formulaChars[i];
            i++;
          } while (!isNaN(Number(formulaChars[i])));
          this.decodedFormula.push({
            type: "number",
            value: extendedNumber,
          });
        } else if (char === "A") {
          // QuestionId segment : check the encoding format(right format : ANSWER['questionId'])
          // and add the questionId to the decodedFormula array
          const answerEncoding =
            formulaChars[i] + // A
            formulaChars[i + 1] + // N
            formulaChars[i + 2] + // S
            formulaChars[i + 3] + // W
            formulaChars[i + 4] + // E
            formulaChars[i + 5] + // R
            formulaChars[i + 6] + // [
            formulaChars[i + 7]; // '
          if (answerEncoding === "ANSWER['") {
            i += 8;
            let questionId = "";
            // Take all the questionId (it ends with ')
            while (i < formulaChars.length && formulaChars[i] !== "'") {
              questionId += formulaChars[i];
              i++;
            }
            // Verify the end of the questionId encoding
            if (formulaChars[i] === "'" && formulaChars[i + 1] === "]") {
              this.decodedFormula.push({
                type: "question",
                value: questionId,
              });
              i += 2;
            } else {
              throw new Error("Invalid end of questionId encoding in formula");
            }
          } else {
            throw new Error("Invalid questionId encoding in formula");
          }
        } else {
          throw new Error("Invalid char in formula");
        }
      }
    } catch (err) {
      FileLogger.error("QuestionnaireScoringFormulaComponent", "decodeFormula() failed", err, "none");
    }
  }

  private encodeFormula(): string {
    let encodedFormula = "";
    this.decodedFormula.forEach((elem) => {
      switch (elem.type) {
        case "operator":
        case "number":
          encodedFormula += elem.value;
          break;
        case "question":
          encodedFormula += "ANSWER['" + elem.value + "']";
          break;
      }
    });
    return encodedFormula;
  }

  public drop(event: CdkDragDrop<string[]>): void {
    const fromArea: string = event.previousContainer.id;
    switch (fromArea) {
      case "formulaArea":
        // change order
        moveItemInArray(this.decodedFormula, event.previousIndex, event.currentIndex);
        break;
      case "operatorsArea": {
        // add operator
        const operator = event.item.element.nativeElement.innerText;
        if (this.operatorList.includes(operator)) {
          this.decodedFormula.push({
            type: "operator",
            value: operator,
          });
        } else {
          // number input
          this.decodedFormula.push({
            type: "number",
            value: this.currentNumberValue,
          });
        }
        break;
      }
      case "questionsArea": {
        // add question
        this.decodedFormula.push({
          type: "question",
          value: event.item.element.nativeElement.id,
        });
        break;
      }
    }
  }

  public preventCharacter(event, characters: PREVENTCHARACTER[], index?: number): void {
    // we need this timeout because the replacement must be after the change of the input value
    setTimeout(() => {
      const value = PreventCharacter.preventMultiple(event, characters);
      if (index !== undefined) {
        this.decodedFormula[+index].value = value;
      } else {
        this.currentNumberValue = +value;
      }
    }, 0);
  }

  public deleteElem(index: number): void {
    this.decodedFormula.splice(index, 1);
  }

  /**
   * Create the scoringValueSet if it's missing for this scoring
   */
  private setupValueSet(): void {
    if (!this.data.scoringValueSet?.length) {
      this.data.scoringValueSet = [];
      this.data.questions.forEach((q) => {
        const values = [];
        const options = GetQuestionOptions.prototype.transform(q, this.data.contained);
        options?.forEach((o) => {
          values.push({
            key: o.code,
            value: o.code,
          });
        });
        if (values.length) {
          this.data.scoringValueSet.push({
            id: q.linkId,
            values: values,
          });
        }
      });
    }
  }

  public valueSetChange(question: QuestionQuestionnaire, optionId: string, value: string): void {
    const questionValueSet = this.data.scoringValueSet.find((s) => s.id === question.linkId);
    if (Tools.isDefined(questionValueSet)) {
      const optionValueSet = questionValueSet.values.find((v) => v.key === optionId);
      if (Tools.isDefined(optionValueSet)) {
        optionValueSet.value = value?.toString();
      }
    }
  }

  /**
   * Verify if all values are set to the valueSet
   * @returns a boolean that indicate if all values are set.
   */
  private verifyValueSet(): boolean {
    let isCorrect = true;
    this.data.scoringValueSet.forEach((s) => {
      s.values.forEach((v) => {
        if (!Tools.isDefined(v.value)) {
          isCorrect = false;
        }
      });
    });
    return isCorrect;
  }

  public apply(): void {
    if (this.verifyValueSet()) {
      this.dialogRef.close({
        formula: this.encodeFormula(),
        scoringValueSet: this.data.scoringValueSet,
      });
    }
  }
}
