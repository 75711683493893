import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DragAndDropService {
  constructor(private translateService: TranslateService) {}
  public refreshTables$ = new Subject();

  public triggerTableRefresh(): void {
    this.refreshTables$.next();
  }

  /**
   * Handles the drop event for drag and drop functionality.
   * @param event - The drag and drop event.
   */
  public drop(event: CdkDragDrop<any>): void {
    if (event.previousContainer === event.container) {
      // @TODO: CMATE-5828 : this case is disabled until the analyse of the order handling
      // Move item within the same container
      // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // Copy item from one container to another
      if (event.currentIndex >= 0 && event.currentIndex <= event.container.data.length) {
        event.container.data.splice(event.currentIndex, 0, event.item.data);
      }
    }
    // Trigger a table refresh after dropping the item
    this.triggerTableRefresh();
  }
}
