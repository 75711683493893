<form [formGroup]="editorForm">
  <mat-progress-spinner class="saveLoader" color="primary" mode="indeterminate" *ngIf="isAlreadySaving"> </mat-progress-spinner>
  <div class="toolbar">
    <div class="left">
      <mat-form-field class="label-field" appearance="standard" [ngClass]="{ highlightMissing: !label && !isNewMedia }">
        <input matInput type="text" formControlName="label" [placeholder]="'knowledgebase.label' | translate" />
      </mat-form-field>
    </div>

    <button type="button" mat-icon-button (click)="openMediaEditorHelp()">
      <mat-icon>help_outline</mat-icon>
    </button>

    <div class="spacer"></div>
    <p class="last-save" *ngIf="lastSaveDate">
      {{ "page.knowledgeMediaEditor.lastSave" | translate }}
      {{ lastSaveDate | date : "mediumTime" }}
    </p>
    <button
      *ngIf="!this.editorForm.pristine && isMngtAuthorized && !readOnly"
      mat-stroked-button
      (click)="saveDraft()"
      [disabled]="isAlreadySaving"
    >
      {{ "page.knowledgeMediaEditor.btn.saveDraft" | translate }}
    </button>
    <button mat-stroked-button matTooltip="{{ 'page.knowledgeDetails.overview' | translate }}" (click)="showOverview()">
      <mat-icon>phone_android</mat-icon>
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="this.editorForm.pristine || !isMngtAuthorized || readOnly ? close() : saveDraft(true)"
      [disabled]="isAlreadySaving"
    >
      {{
        this.editorForm.pristine || !isMngtAuthorized || readOnly
          ? ("page.knowledgeMediaEditor.close" | translate)
          : ("page.knowledgeMediaEditor.btn.save" | translate)
      }}
    </button>
  </div>

  <div class="flex-container">
    <mat-card class="left-card" formArrayName="content" *ngIf="!isLoading">
      <!-- Page management (create, delete, choose) -->
      <app-pages-editor
        *ngIf="slidesList"
        [pages]="this.slidesList.length"
        [currentPage]="currentSlide"
        (currentPageChange)="onSlideChanged($event)"
        (onCreateNewPage)="createNewSlide()"
        (onRemovePage)="removeSlide($event)"
        [translationKey]="'slide'"
      ></app-pages-editor>

      <ng-container *ngFor="let slide of slidesList; let index = index">
        <div [formGroupName]="index" *ngIf="currentSlide === index">
          <mat-form-field class="label-field" appearance="standard" *ngIf="content.at(index).get('showInSummary').value">
            <input matInput type="text" formControlName="title" [placeholder]="'knowledgebase.label' | translate" />
          </mat-form-field>

          <mat-checkbox formControlName="showInSummary" class="mt-1 mb-1">
            {{ "page.knowledgeMediaEditor.showInSummary" | translate }}
          </mat-checkbox>

          <jodit-editor
            formControlName="text"
            [config]="joditConfig"
            class="editor mat-elevation-z1"
            [ngClass]="readOnly ? 'read-only' : ''"
          >
          </jodit-editor>
        </div>
      </ng-container>
    </mat-card>

    <mat-card class="right-card">
      <!-- Short Description -->
      <div class="title">
        <mat-icon>edit</mat-icon>
        <h4>{{ "knowledgebase.shortDescription" | translate }}</h4>
      </div>
      <mat-form-field class="w-full" appearance="outline">
        <textarea
          rows="5"
          formControlName="description"
          matInput
          cdkTextareaAutosize
          placeholder="{{ 'knowledgebase.shortDescription' | translate }}"
        ></textarea>
      </mat-form-field>

      <!-- Language -->
      <div class="one-line">
        <!-- Here we use FA icon instead of mat-icon to be able to use the flex property "baseline" -->
        <h4>
          <i class="fas fa-globe-europe"></i>
          {{ "languages.lang" | translate }}
        </h4>
        <mat-form-field [ngClass]="{ highlightMissing: !language && !isNewMedia }">
          <mat-select formControlName="language" placeholder="{{ 'languages.lang' | translate }}">
            <mat-option *ngFor="let lang of availableLangs" [value]="lang.term">
              {{ lang | getTranslation : lang.term }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Media Type -->
      <div class="one-line">
        <!-- Here we use FA icon instead of mat-icon to be able to use the flex property "baseline" -->
        <h4>
          <i class="fas fa-file"></i>
          {{ "knowledgebase.mediatype.label" | translate }}
        </h4>
        <mat-form-field [ngClass]="{ highlightMissing: !type && !isNewMedia }">
          <mat-select formControlName="type" placeholder="{{ 'knowledgebase.mediatype.label' | translate }}">
            <mat-option *ngFor="let type of availableTypes" [value]="type">
              {{ "knowledgebase.mediatype." + type | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- Visible for -->
      <div class="one-line">
        <h4>
          <i class="fas fa-eye"></i>
          {{ "drugSchema.visibility" | translate }}
        </h4>
        <div style="display: flex; flex-direction: column">
          <mat-checkbox formControlName="visibleForPatient">{{ "menu.patients" | translate }}</mat-checkbox>
          <mat-checkbox style="margin-top: 5px" formControlName="visibleForMonitoring">{{
            "knowledgebase.monitoringPerson" | translate
          }}</mat-checkbox>
        </div>
      </div>
      <!-- Importance -->
      <div class="one-line">
        <!-- Here we use FA icon instead of mat-icon to be able to use the flex property "baseline" -->
        <h4>
          <i class="fas fa-star"></i>
          {{ "knowledgebase.importance" | translate }}
        </h4>

        <div>
          <button [disabled]="readOnly" mat-icon-button (click)="setImportanceLevel(level)" *ngFor="let level of [1, 2, 3]">
            <mat-icon class="star-icon" [class]="importanceLevel >= level ? 'warning' : ''">
              {{ importanceLevel >= level ? "star" : "star_border" }}
            </mat-icon>
          </button>
        </div>
      </div>

      <!-- Link Specific Questionnaire -->
      <div class="one-line" *ngIf="('dashboard/linkQuestionnaireToMedia' | isAuthorized : 'POST' | async) === true">
        <h4>
          <i [class]="specificUse === SPECIFIC_USE.CONSENT ? 'fas fa-file-signature' : 'fas fa-award'"></i>
          {{ "knowledgebase.specificUse." + specificUse | translate }}
        </h4>
        <mat-form-field>
          <mat-select
            multiple
            formControlName="specificQuestionnaire"
            [placeholder]="'knowledgebase.specificUse.' + specificUse | translate"
          >
            <mat-option
              *ngFor="let specificQuestionnaire of filteredSpecificQuestionnaires"
              [value]="specificQuestionnaire.identifier[0].value"
            >
              {{ specificQuestionnaire.subjectType }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <button
        mat-stroked-button
        class="w-full"
        (click)="createSpecificQuestionnaire()"
        *ngIf="
          draftKnowledge?.documentCategory !== docCategory.CONSENT &&
          ('dashboard/linkQuestionnaireToMedia' | isAuthorized : 'POST' | async) === true &&
          ('/questionnaireEditor' | isAuthorized | async) === true
        "
      >
        {{ "knowledgebase.quiz.add" | translate }}
      </button>
      <ng-container *ngIf="publishedKnowledge">
        <hr class="mt-1 mb-1" />
        <button mat-stroked-button class="w-full color-button" (click)="compareVersion()">
          {{ "knowledgebase.compare" | translate }}
        </button>
      </ng-container>
    </mat-card>
  </div>
</form>
