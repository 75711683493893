import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IHealthcareservice } from "src/app/models/healthcareservice";
import { Healthcareservice } from "../models/healthcareservice.model";
import { HealthcareserviceApiService } from "./api/healthcareservice-api.service";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class HealthcareserviceService {
  private availableServices$: BehaviorSubject<Healthcareservice[]> = new BehaviorSubject<Healthcareservice[]>([]);
  private availableMonitoringServices$: BehaviorSubject<Healthcareservice[]> = new BehaviorSubject<Healthcareservice[]>([]);

  constructor(private healthcareserviceApi: HealthcareserviceApiService, private sessionService: SessionService) {}

  public list(organizationId?: string): Observable<Healthcareservice[]> {
    if (organizationId === this.sessionService.allsOption) {
      organizationId = null;
    }
    return this.healthcareserviceApi.list(organizationId).pipe(
      map((values) => {
        return values.map((v) => new Healthcareservice(v));
      })
    );
  }

  public create(healthcareService: IHealthcareservice): Observable<IHealthcareservice> {
    return this.healthcareserviceApi.create(healthcareService);
  }

  public update(healthcareService: IHealthcareservice): Observable<IHealthcareservice> {
    return this.healthcareserviceApi.update(healthcareService);
  }

  public clear(): void {
    this.availableServices$.next([]);
    this.availableMonitoringServices$.next([]);
  }

  public setAvailableServices(services: Healthcareservice[]): void {
    this.availableServices$.next(services);
  }

  public availableServices(): Healthcareservice[] {
    return this.availableServices$.value;
  }

  public watchAvailableServices(): Observable<Healthcareservice[]> {
    return this.availableServices$;
  }

  public setAvailableMonitoringServices(services: Healthcareservice[]): void {
    this.availableMonitoringServices$.next(services);
  }

  public availableMonitoringServices(): Healthcareservice[] {
    return this.availableMonitoringServices$.value;
  }

  public watchAvailableMonitoringServices(): Observable<Healthcareservice[]> {
    return this.availableMonitoringServices$;
  }

  public filterServicesByOrgAndMonitoring(
    organizationRef: string,
    servicesList: Healthcareservice[],
    monitoringOrgRef?: string,
    monitoringServiceRef?: string
  ): Healthcareservice[] {
    if (!servicesList) {
      return [];
    }
    const orgFiltered = servicesList.filter(
      (s) => !organizationRef || organizationRef === this.sessionService.allsOption || s.providedBy.reference === organizationRef
    );
    if (!monitoringOrgRef && !monitoringServiceRef) {
      return orgFiltered;
    }
    let monitOrgFiltered = orgFiltered;
    if (monitoringOrgRef && monitoringOrgRef !== this.sessionService.allsOption) {
      monitOrgFiltered = orgFiltered.filter(
        (o) =>
          o.linkedMonitoringOrganizations &&
          o.linkedMonitoringOrganizations.length > 0 &&
          o.linkedMonitoringOrganizations.findIndex((link) => link.organizationRef.reference === monitoringOrgRef) >= 0
      );
    }
    let monitServiceFiltered = monitOrgFiltered;
    if (monitoringServiceRef && monitoringServiceRef !== this.sessionService.allsOption) {
      monitServiceFiltered = monitOrgFiltered.filter(
        (s) =>
          s.linkedMonitoringOrganizations &&
          s.linkedMonitoringOrganizations.length > 0 &&
          s.linkedMonitoringOrganizations.findIndex(
            (link) => link.servicesRef.findIndex((ref) => ref.reference === monitoringServiceRef) >= 0
          ) >= 0
      );
    }
    return monitServiceFiltered;
  }
}
