import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { Appointment } from "src/app/models/appointment.model";

export class TeleconsultationListDataSource extends MatTableDataSourceExtended<Appointment> {
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  sortingDataAccessor = (data: Appointment, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "start":
        return moment(data.start).unix();
      default:
        return "";
    }
  };

  constructor() {
    super();
  }

  public loadData(appointments: Appointment[]): void {
    this.data = appointments;
  }
}
