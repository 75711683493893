<form [formGroup]="twofaForm" autocomplete="off" (keyup.enter)="onLogIn()">
  <mat-dialog-content>
    <h2>{{ "mfa.title" | translate }}</h2>
    <p>{{ "mfa.prompt" | translate }}</p>
    <mat-form-field appearance="fill">
      <mat-label>{{ "mfa.enterCode" | translate }}</mat-label>
      <input matInput formControlName="code" type="text" placeholder="XXXXXX" />
    </mat-form-field>
    <p class="error" *ngIf="data.wrongMfa">{{ "mfa.wrongmfa" | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button expand="block" [disabled]="!twofaForm.get('code').value" size="large" (click)="onLogIn()" color="primary">
      {{ "page.login.login" | translate }}
    </button>
  </mat-dialog-actions>
</form>
