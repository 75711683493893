import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { concatMap, first, map } from "rxjs/operators";
import { FileLogger } from "../helpers/fileLogger";
import { ICareplan, ILink2Careplan, VersioningStatus } from "../models/careplans.interface";
import { IObservationsLoincsInfo } from "../models/observations.interface";
import { IQuestionnaireIdentifierInfo } from "../models/questionnaire.interface";
import { IVitalProfileDefinition } from "../models/vitalProfileDefinition.interface";
import { CareplansApiService } from "./api/careplans-api.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class CareplansService {
  constructor(private careplanApi: CareplansApiService, private userService: UserService, private translateService: TranslateService) {}

  public list(patientId: string, lang?: string): Observable<ICareplan[]> {
    const l = lang ? lang : this.translateService.currentLang;
    const routeName = this.careplanApi.readRoutes[0];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      first(),
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("CareplansService", "User does not have access to: GET " + routeName);
          return of([]) as Observable<ICareplan[]>;
        }
        return this.careplanApi.list(patientId, l);
      })
    );
  }

  public getCareplanTemplates(serviceRef?: string, lang = "fr"): Observable<ICareplan[]> {
    const routeName = this.careplanApi.readRoutes[1];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      first(),
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("CareplansService", "User does not have access to: GET " + routeName);
          return of([]) as Observable<ICareplan[]>;
        }
        return this.careplanApi.getCareplanTemplates(lang, serviceRef) as Observable<ICareplan[]>;
      })
    );
  }

  public getCareplanTemplateByPublicationDate(careplanRef: string, publicationDate: string): Observable<ICareplan | undefined> {
    const routeName = this.careplanApi.readRoutes[8];

    return this.userService.isAuthorized(routeName, "GET").pipe(
      first(),
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("CareplanEditorService", "User does not have access to: GET " + routeName);
          return of() as Observable<ICareplan>;
        }
        return this.careplanApi.getCareplanTemplateByPublicationDate(careplanRef, publicationDate).pipe(map((c) => c?.published));
      })
    );
  }

  public createCareplan(careplan: ICareplan): Observable<unknown> {
    return this.careplanApi.createCareplan(careplan);
  }

  public updateCareplan(careplan: ICareplan): Observable<unknown> {
    return this.careplanApi.updateCareplan(careplan);
  }

  public getLink2Careplan(careplanId: string): Observable<{
    published: ILink2Careplan;
    draft: ILink2Careplan;
  }> {
    const routeName = this.careplanApi.readRoutes[2];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      first(),
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("CareplansService", "User does not have access to: GET " + routeName);
          return of(null) as Observable<{
            published: ILink2Careplan;
            draft: ILink2Careplan;
          }>;
        }
        return this.careplanApi.getLink2Careplan(careplanId) as Observable<{
          published: ILink2Careplan;
          draft: ILink2Careplan;
        }>;
      })
    );
  }

  public updateLink2Careplan(link2careplan: ILink2Careplan): Observable<ILink2Careplan> {
    link2careplan.versioningStatus = VersioningStatus.DRAFT;
    return this.careplanApi.updateLink2Careplan(link2careplan);
  }

  public getAllVitalProfileDefinitions(codes?: string[]): Observable<IVitalProfileDefinition[]> {
    const routeName = this.careplanApi.readRoutes[3];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      first(),
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("CareplansService", "User does not have access to: GET " + routeName);
          return of(null) as Observable<IVitalProfileDefinition[]>;
        }
        return this.careplanApi.getAllVitalProfileDefinitions(codes) as Observable<IVitalProfileDefinition[]>;
      })
    );
  }

  /**
   * returns the list of careplan templates with activities linked to questionnaire
   * @param id the id of the questionnaire
   * @returns
   */
  public getCareplanTemplatesWithActivitiesLinkedToQuestionnaire(id: string): Promise<ICareplan[]> {
    return this.careplanApi.getCareplanTemplatesWithActivitiesLinkedToQuestionnaire(id).toPromise();
  }

  public getObservationsLinkedToCareplan(careplanId: string, draft: boolean, publicationDate?: string): Promise<IObservationsLoincsInfo[]> {
    return this.careplanApi.getObservationsLinkedToCareplan(careplanId, draft, publicationDate).toPromise();
  }

  public getQuestionnairesLinkedToCareplan(
    careplanId: string,
    draft: boolean,
    publicationDate?: string
  ): Promise<IQuestionnaireIdentifierInfo[]> {
    return this.careplanApi.getQuestionnairesLinkedToCareplan(careplanId, draft, publicationDate).toPromise();
  }
}
