import uuid from "uuid-random";
import { ACTION_TARGET, IOrder } from "../models/careplans.interface";

export class CareplanHelper {
  public static newActionResulting(
    careplanRef: string,
    subjectType: string,
    questionnaireId: string,
    target: ACTION_TARGET,
    timingCode?: string,
    when?: { period: number; periodUnit: string }
  ): IOrder {
    const actionResulting: IOrder = {
      identifier: {
        system: "http://comunicare.io",
        value: careplanRef + "/" + uuid(), // Update on questionnaire save with [support.reference]/[uuid()]
        label: subjectType, // Update on questionnaire save with the title of the questionnaire
      },
      status: "active",
      date: null,
      reasonCodeableConcept: {
        display: null,
        system: null,
        code: subjectType, // Update on questionnaire save with the title of the questionnaire
      },
      when: {
        boundsPeriod: {
          start: null,
        },
        period: when?.period ? when.period : 0,
        periodUnits: when?.periodUnit ? when.periodUnit : "d",
      },
      detail: {
        target: target,
        reference: {
          reference: questionnaireId,
          display: subjectType, // Update on questionnaire save with the title of the questionnaire
        },
      },
    };

    if (timingCode) {
      actionResulting.when.timingCode = timingCode;
    }

    return actionResulting;
  }
}
