import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatOptionModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { I18nModule } from "src/app/i18n.module";
import { DrugServerSideSearchComponent } from "./drug-server-side-search.component";

@NgModule({
  declarations: [DrugServerSideSearchComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatOptionModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    I18nModule,
    FormsModule,
    MatInputModule,
  ],
  exports: [DrugServerSideSearchComponent],
})
export class DrugServerSideSearchModule {}
