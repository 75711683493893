import * as moment from "moment";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { IQuestionnaireList } from "src/app/models/questionnaireList.interface";
import { QuestionnaireList } from "src/app/models/questionnaireList.model";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { QRService } from "src/app/providers/qr-api.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";

export class QuestionnaireListDataSource extends MatTableDataSourceExtended<IQuestionnaireList> {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private refreshInterval;

  public loading$ = this.loadingSubject.asObservable();
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  sortingDataAccessor = (data: QuestionnaireList, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "name":
        return data.name?.toLowerCase();
      case "firstname":
        return data.firstname?.toLowerCase();
      case "birthday":
        return moment(data.birthDate).unix();
      case "gender":
        return data.gender;
      case "questionnaireName":
        return data.questionnaireName.toLowerCase();
      case "questionnaireDate":
        return moment(data.questionnaireDate).unix();
      case "questionnaireScore":
        return data.questionnaireScore.join();
      default:
        return "";
    }
  };

  constructor(
    private qrService: QRService,
    private sessionService: SessionService,
    private userService: UserService,
    private healthcareService: HealthcareserviceService
  ) {
    super();
  }

  public loadQuestionnaires(fromDate: string, toDate: string, isAnonymizedQr = false, autoRefreshIntervalMs = 60000): void {
    this.loadingSubject.next(true);

    if (this.refreshInterval) {
      this.clearAutoRefresh();
    }

    this.refreshInterval = setInterval(() => {
      this.loadData(fromDate, toDate, isAnonymizedQr);
    }, autoRefreshIntervalMs);
    this.loadData(fromDate, toDate, isAnonymizedQr);
  }

  /**
   * this.loadingSubject and this.onDestroy$ must not be "complete" here !!!!
   */
  private clearAutoRefresh() {
    clearInterval(this.refreshInterval);
  }

  /**
   * Clear all observables and autorefresh
   */
  public clear(): void {
    this.clearAutoRefresh();
    this.loadingSubject.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadData(fromDate: string, toDate: string, isAnonymizedQr: boolean) {
    if (isAnonymizedQr) {
      this.qrService
        .getAnonymousListExtended()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res) => {
          this.data = res;
          this.loadingSubject.next(false);
        });
    } else {
      const monitoringServicesIds: string[] = [];
      if (!this.sessionService.currentService) {
        this.data = [];
        this.loadingSubject.next(false);
        return;
      }
      if (this.userService.isMonitoringUser) {
        if (!this.sessionService.currentMonitoringService) {
          this.data = [];
          this.loadingSubject.next(false);
          return;
        } else if (this.sessionService.currentMonitoringService.reference === this.sessionService.allsOption) {
          monitoringServicesIds.push(...this.healthcareService.availableMonitoringServices().map((s) => s.serviceRef));
        } else {
          monitoringServicesIds.push(this.sessionService.currentMonitoringService.reference);
        }
      }
      this.qrService
        .getListExtended(fromDate, toDate, null, monitoringServicesIds)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res) => {
          this.data = res;
          this.loadingSubject.next(false);
        });
    }
  }
}
