<div class="loading" *ngIf="loading">
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
  {{ "common.loading" | translate }}
</div>

<div *ngIf="!loading" id="cpListPage">
  <div class="toolbar">
    <div class="left">
      <!-- GLOBAL SEARCH -->
      <mat-form-field>
        <mat-label><span class="fa fa-search mr-5"></span>{{ "itemFilter.search" | translate }}</mat-label>
        <input type="search" matInput #searchInput autocomplete="off" />
        <button
          type="button"
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="searchInput.value = ''"
          [style.visibility]="searchInput.value ? 'visible' : 'hidden'"
          #searchClearBtn
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <!-- @TODO CMATE-5804  -->
      <button type="button" mat-icon-button>
        <mat-icon style="font-size: 30px">help_outline</mat-icon>
      </button>
    </div>

    <div class="spacer"></div>

    <button mat-flat-button color="warn" *ngIf="isFiltered" (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
      <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
    </button>
    <button mat-stroked-button *ngIf="!isFiltered" (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
      <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
    </button>
    <div
      class="right"
      [matTooltip]="canCreateCareplan ? ('page.careplansList.btn.add' | translate) : ('api.errors.ACCESS DENIED' | translate)"
    >
      <button mat-stroked-button [disabled]="!canCreateCareplan" (click)="addCareplan()" id="createNewCpTemplateBtn">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <app-filters-display [filters]="filters" (removeFilter)="applyFilter($event)"> </app-filters-display>

  <mat-card class="mat-elevation-z0 pt-0">
    <!-- LOADER MANAGEMENT -->
    <!-- The loader has been disactivated because loading id too fast  -->
    <!-- <div class="spinner-container" *ngIf="dataSource.loading$ | async">
              <mat-spinner></mat-spinner>
          </div> -->

    <mat-table class="mat-elevation-z1" [dataSource]="dataSource" matSort matSortActive="description" matSortDirection="asc">
      <!-- DESCRIPTION FIELD (= name of the careplan) -->
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef
          >{{ "common.name" | translate }}
          <app-item-filter
            fieldName="{{ 'common.name' | translate }}"
            [propertyName]="filtersPropertyNames.description"
            [dataType]="DATA_TYPE.TEXT"
            [defaultValue]="getFilter(filtersPropertyNames.description)"
            [translateFieldKey]="'common.name'"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let careplan">
          {{ careplan.description }}
        </mat-cell>
      </ng-container>

      <!-- SUPPORT FIELD (= support.reference "Main Careplan Reference" ) -->
      <ng-container matColumnDef="support">
        <mat-header-cell *matHeaderCellDef [mat-sort-header]="filtersPropertyNames.support"
          >{{ "page.careplansList.support" | translate }}
          <app-item-filter
            fieldName="{{ 'page.careplansList.support' | translate }}"
            [propertyName]="filtersPropertyNames.support"
            [dataType]="DATA_TYPE.TEXT"
            [defaultValue]="getFilter(filtersPropertyNames.support)"
            [translateFieldKey]="'page.careplansList.support'"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let careplan">
          {{ careplan.support }}
        </mat-cell>
      </ng-container>

      <!-- SERVICES (author field in DB) -->
      <ng-container matColumnDef="services">
        <mat-header-cell *matHeaderCellDef
          >{{ "common.services" | translate }}
          <app-item-filter
            fieldName="{{ 'common.services' | translate }}"
            [propertyName]="filtersPropertyNames.services"
            [dataType]="DATA_TYPE.TEXT"
            [defaultValue]="getFilter(filtersPropertyNames.services)"
            [translateFieldKey]="'common.services'"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [matTooltip]="(element.author | truncateList : currentLang).tooltipText | translate">
          {{ (element.author | truncateList : currentLang).truncatedText }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef>{{ "common.category" | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let careplan">
          <div *ngIf="careplan.category.length && careplan.category[0].coding.length">
            <span *ngIf="careplan.category[0].coding[0].display"> {{ careplan.category[0].coding[0].display }} </span>
            <span *ngIf="careplan.category[0].coding[0].code"> ({{ careplan.category[0].coding[0].code }}) </span>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        [routerLink]="['/careplanEditor', row.support, 'general', 'visualView']"
      ></mat-row>
    </mat-table>

    <!-- PAGANITOR MANAGEMENT - length = total number of element via a Resolver!!-->
    <mat-paginator class="mat-elevation-z1" [length]="careplansCount" [pageSize]="25" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
  </mat-card>
</div>
