import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from "@angular/cdk/stepper";
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Image, ReleaseNote, Slide } from "src/app/helpers/helpData";
import { PreferenceContext, ReleaseNoteParameter } from "src/app/models/preference.interface";
import { PreferenceService } from "src/app/providers/preference.service";
import packageInfo from "../../../../package.json";

@Component({
  selector: "app-release-note-dialog",
  templateUrl: "./release-note-dialog.component.html",
  styleUrls: ["./release-note-dialog.component.scss"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class ReleaseNoteDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("stepper") private myStepper: MatStepper;
  public slides: ReleaseNote[];
  public isLastSlide = false;
  public isFirstSlide = true;
  public isChecked = false;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private preferenceService: PreferenceService,
    @Inject(MAT_DIALOG_DATA)
    public data: { preference: ReleaseNoteParameter; slides: ReleaseNote[] }
  ) {}

  ngOnInit(): void {
    this.slides = this.data.slides;
    this.isChecked = this.data.preference?.alwaysShow || false;
  }

  ngAfterViewInit(): void {
    // when user change page the stepper emit an event : StepperSelectionEvent
    // We subscribe to it and we recompute boolean at each itteration
    if (this.slides.length > 1) {
      this.myStepper.selectionChange.pipe(takeUntil(this.onDestroy$)).subscribe((event: StepperSelectionEvent) => {
        this.isFirstSlide = event.selectedIndex === 0;
        this.isLastSlide = event.selectedIndex === this.slides.length - 1;
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Check if we need to show mat-stepper or not
   */
  public isOnlyOnePage(): boolean {
    return this.slides.length === 1;
  }

  /**
   * Check if we need to show more image than descriptions
   */
  private isMoreImageThanDescription(slide: Slide): boolean {
    return slide.descriptions.length < slide.images.length;
  }

  /**
   * Return images not show by ngFor descriptions in html
   */
  public getMissingImages(slide: Slide): Image[] {
    if (this.isMoreImageThanDescription(slide)) {
      // get diff between images and description
      const diff = slide.images.length - slide.descriptions.length;
      const images = [];
      // start by the end for the loop is why we decrement index
      for (let index = slide.images.length; index > diff; index--) {
        // push missing images in array
        images.push(slide.images[index - 1]);
      }
      return images;
    } else {
      // normally this never append
      return [];
    }
  }

  public onClose(): void {
    this.savePreference();
    this.dialog.closeAll();
  }

  private savePreference() {
    this.preferenceService
      .update({
        context: PreferenceContext.RELEASE_NOTE,
        parameters: {
          lastReleaseNote: this.isChecked ? this.data.preference?.lastReleaseNote : packageInfo.version,
          alwaysShow: this.isChecked,
        },
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }
}
