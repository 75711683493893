<mat-card class="comunicare-widget" cdkDrag [cdkDragDisabled]="!isDraggable">
  <mat-card-header class="widget-header">
    <mat-card-title class="custom-widget-title">
      {{ "widget.patientAlertRed" | translate }}
      <a mat-icon-button aria-label="icon button with a ? icon" (click)="openRedAlertHelp()">
        <mat-icon>help_outline</mat-icon>
      </a>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="widget-content">
    <app-patient-alert-table [widgetName]="widgetName" [alertLevel]="3"></app-patient-alert-table>
  </mat-card-content>
</mat-card>
