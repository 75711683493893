import { AfterViewInit, Component, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IDrugsHistoric } from "src/app/models/drugsHistoric.interface";
import { IQuestionnaire, IQuestionnaireHistoryEvent } from "src/app/models/questionnaire.interface";
import { QuestionnairesService } from "src/app/providers/questionnaires.service";
import { QuestionnaireHistoryDataSource } from "./questionnaire-history.datasource";

@Component({
  selector: "app-questionnaire-history",
  templateUrl: "./questionnaire-history.component.html",
  styleUrls: ["./questionnaire-history.component.scss"],
})
export class QuestionnaireHistoryComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<IDrugsHistoric>;
  public datasource: QuestionnaireHistoryDataSource = new QuestionnaireHistoryDataSource();
  public displayedColumns: string[] = ["date", "author", "questionnaireStatus", "nbPages", "nbQuestions", "actions"];
  public currentPageSize = 10;
  private onDestroy$ = new Subject<void>();
  constructor(
    private translateService: TranslateService,
    private router: Router,
    private dialogRef: MatDialogRef<QuestionnaireHistoryComponent>,
    private snackBar: MatSnackBar,
    private questionnairesService: QuestionnairesService,
    @Inject(MAT_DIALOG_DATA)
    public data: { history: IQuestionnaireHistoryEvent[]; identifier: string; lang: string }
  ) {
    this.datasource.loadData(this.data.history);
  }

  ngAfterViewInit(): void {
    this.initPaginatorAndSort();
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  private initPaginatorAndSort(): void {
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;
    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentPageSize = this.paginator.pageSize;
    });
  }

  public async showVersion(ev: IQuestionnaireHistoryEvent): Promise<void> {
    const questionnaire = await this.getQuestionnaireVersion(ev);
    if (questionnaire) {
      const success = await this.router.navigate(["questionnaireEditor"], {
        state: { questionnaire, visualization: true },
      });
      if (success) {
        this.dialogRef.close();
      }
    } else {
      this.translateService.get("page.fhirQuestionnaireList.showQuestionnaireVersionError").subscribe((trans) => {
        this.snackBar.open(trans, "ok", { duration: 10000 });
      });
    }
  }

  private async getQuestionnaireVersion(ev: IQuestionnaireHistoryEvent): Promise<IQuestionnaire> {
    try {
      const questionnaire: IQuestionnaire = await this.questionnairesService.getQuestionnaire(
        this.data.identifier,
        this.data.lang,
        false,
        false,
        ev.date
      );
      return questionnaire;
    } catch (err) {
      FileLogger.error("QuestionnaireHistoryComponent", "Error while get questionnaireVersion: ", err);
      return null;
    }
  }
}
