import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { Account } from "src/app/models/account.interface";

@Component({
  selector: "app-select-account-modal",
  templateUrl: "./select-account-modal.component.html",
  styleUrls: ["./select-account-modal.component.scss"],
})
export class SelectAccountModalComponent implements OnDestroy {
  public selectedAccountIdx = 0;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<SelectAccountModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { accounts: Account[] }
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
