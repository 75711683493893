import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ObservationHelper } from "src/app/helpers/observationHelper";
import { IDayObs, IObservation, IObservationDefinition } from "src/app/models/observations.interface";
import { Observation } from "src/app/models/observations.model";
import { RULE_LEVEL } from "src/app/models/rule.interface";
import { ObservationsService } from "src/app/providers/observations.service";

@Component({
  selector: "app-hourly-observation-table",
  templateUrl: "./hourly-observation-table.component.html",
  styleUrls: ["./hourly-observation-table.component.scss"],
})
export class HourlyObservationTableComponent implements OnInit, OnChanges {
  @Input() obsDefinitions: IObservationDefinition[];
  @Input() observations: Observation[];

  public obsByDay: IDayObs[];
  public headerCells = [[]];
  public hours = [];
  public areAllClosed = false;
  public RULE_LEVEL = RULE_LEVEL;

  constructor(public translateService: TranslateService, public observationService: ObservationsService) {}

  ngOnInit(): void {
    this.initializeHeaderCells();
    this.createCells();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.obsByDay = ObservationHelper.groupObservationsByDay(this.observations);
    for (let i = 0; i < this.obsByDay.length; i++) {
      const element = this.obsByDay[i];
      if (i === 0) {
        element.hidden = false;
      } else {
        element.hidden = true;
      }
    }
  }

  public initializeHeaderCells(): void {
    for (let i = 0; i < 25; i++) {
      this.headerCells.push([i]);
    }
  }

  public createCells(): void {
    for (let i = 0; i < 25; i++) {
      this.hours.push(i);
    }
  }

  public toggleVisibility(dayObs: IDayObs): void {
    dayObs.hidden = !dayObs.hidden;
    this.areAllClosed = this.obsByDay.findIndex((el) => el.hidden === false) === -1;
  }

  public openAlertHistory(parentObs: IObservation): void {
    this.observationService.openAlertHistory(parentObs, this.obsDefinitions);
  }
}
