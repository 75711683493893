import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "externalUrl",
})
export class ExternalUrlPipe implements PipeTransform {
  transform(url: string): string {
    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url;
    }
    return "//" + url;
  }
}
