import { Pipe, PipeTransform } from "@angular/core";
import { OBSERVATION_LOINC_MAP_CONVERTOR } from "../../../helpers/FHIRhelper";
import { Tools } from "../../../helpers/tools";
import { IObservationDefinition, OComponent } from "../../../models/observations.interface";
import { SessionService } from "../../../providers/session.service";

@Pipe({ name: "translateComponent" })
export class TranslateComponentPipe implements PipeTransform {
  constructor(private sessionService: SessionService) {}
  transform(component: OComponent, allDefinitions: IObservationDefinition[]): string {
    const def = allDefinitions.find((d) => d.loinc === component.parentObservation?.code.coding[0].code);
    if (!def) {
      return component.code.coding[0].code;
    }
    const cDef = this.getComponent(component.code.coding[0].code, def);
    if (!cDef) {
      return component.code.coding[0].code;
    } // not exists

    if (cDef.shortnameTranslation) {
      return Tools.getTranslation(cDef.shortnameTranslation, this.sessionService.userLang, cDef.loinc);
    }

    return Tools.getTranslation(cDef.nameTranslation, this.sessionService.userLang, cDef.loinc);
  }
  private getComponent(loinc: string | number, obsDef: IObservationDefinition) {
    const loincStr: string = OBSERVATION_LOINC_MAP_CONVERTOR[loinc] || loinc;
    for (const compo of obsDef.components) {
      if (compo.loinc === loincStr) {
        return compo;
      }
    }
    return null;
  }
}
