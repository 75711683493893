/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as d3 from "d3";
import moment, { Moment } from "moment";
import { fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ChartHelper } from "src/app/helpers/chart-helper";
import { IChartData, IChartDataPoint } from "src/app/models/sharedInterfaces";
import { IStatistic, IStatsObsGroups, LineType } from "src/app/models/statObservation.interface";

@Component({
  selector: "app-stat-linechart",
  templateUrl: "./stat-linechart.component.html",
  styleUrls: ["./stat-linechart.component.scss"],
})
export class StatLinechartComponent implements OnInit, OnChanges {
  @ViewChild("chartContainer") chartContainer: ElementRef<HTMLDivElement>;
  @Input() chartId: string; //loinc of obsdef
  @Input() statsObsGroups: IStatsObsGroups;
  @Input() fromDate: moment.Moment;
  @Input() toDate: moment.Moment;

  private svg: any;
  public margin = 30;
  private width = 800 - this.margin * 2;
  private height = 400 - this.margin * 2;
  private data: IChartData[] = [];

  private x: any;
  private y: any;
  private line: d3.Line<[number, number]>; //the line generator

  public hasChartValues = false;

  private tooltip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;

  constructor(private translateService: TranslateService, private cdr: ChangeDetectorRef) {
    // Ensure the correct context ('this') for event handler functions
    // Binding the context here ensures that 'this' inside these functions
    // refers to the current instance of the Angular component,
    // allowing access to component properties/methods like 'this.tooltip'
    this.mouseOverHandler = this.mouseOverHandler.bind(this);
    this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
    this.mouseLeaveHandler = this.mouseLeaveHandler.bind(this);
  }
  ngOnChanges(): void {
    this.processData(this.statsObsGroups.statistics);
    if (this.hasChartValues) {
      this.width = this.chartContainer.nativeElement.clientWidth - this.margin * 2;
    }
    const locale: d3.TimeLocaleDefinition = ChartHelper.parseLocale(this.translateService.instant("d3Locale"));
    d3.timeFormatDefaultLocale(locale);
    setTimeout(() => {
      this.createChart();
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    fromEvent(window, "resize")
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.updateChart(true);
      });
  }

  private async createChart() {
    if (!this.hasChartValues) {
      return;
    }

    // We make sure to remove the existing chart in case it already exists
    d3.select(`figure#A${this.chartId}`).selectAll("*").remove();
    if (this.width > 1000) {
      this.width = 1000;
    }
    this.svg = ChartHelper.createSvg(this.width, this.height, this.margin, `figure#A${this.chartId}`); // we add "A" because a css selector can't start with a number
    this.createTooltip();
    this.createAxis();

    for (const stat of this.statsObsGroups.statistics) {
      this.checkLinesFormat(stat);

      if (stat.yLines?.values?.length > 0) {
        for (const value of stat.yLines.values) {
          this.drawStraightLine(value, stat.yLines.type, stat.baseColor, "y");
        }
      }
      if (stat.xLines?.values?.length > 0) {
        for (const value of stat.xLines.values) {
          this.drawStraightLine(value, stat.xLines.type, stat.baseColor, "x");
        }
      }
    }

    for (const datum of this.data) {
      this.drawLines(datum);
    }
  }

  private updateChart(resetWidth: boolean) {
    if (resetWidth && this.hasChartValues) {
      this.width = this.chartContainer.nativeElement.clientWidth - this.margin * 2;
    }
    // delete chart than recreate it
    this.createChart();
  }

  private async createAxis() {
    this.x = d3.scaleUtc(
      d3.extent([this.fromDate, this.toDate]).map((x) => new Date(x as string)),
      [this.margin, this.width - this.margin]
    );

    this.y = d3.scaleLinear(
      [
        (d3.min([...this.flattenDataByDate(this.data), ...this.YlinesToDataPoint()].map((d) => d.value as number)) - 1) as number, // we add 1 to be sure to have 2 different data in case there are identical values in the resulting array
        (d3.max([...this.flattenDataByDate(this.data), ...this.YlinesToDataPoint()].map((d) => d.value as number)) + 1) as number, // we substract 1 to be sure to have 2 different data in case there are identical values in the resulting array
      ],
      [this.height - this.margin, this.margin]
    );

    this.svg
      .append("g")
      .attr("transform", `translate(0,${this.height - this.margin})`)
      .call(
        d3
          .axisBottom(this.x)
          .ticks(this.width / 80)
          .tickSizeOuter(0)
      );

    // Add the y-axis, remove the domain line, add grid lines and a label.
    this.svg
      .append("g")
      .attr("transform", `translate(${this.margin},0)`)
      .call(d3.axisLeft(this.y).ticks(this.height / 40))
      .call((g: any) => g.select(".domain").remove())
      .call((g: any) =>
        g
          .selectAll(".tick line")
          .clone()
          .attr("x2", this.width - this.margin * 2)
          .attr("stroke-opacity", 0.1)
      );
  }

  private drawLines(data: IChartData) {
    this.line = d3
      .line()
      .x((d: any) => {
        return this.x(new Date(d.date));
      })
      .y((d: any) => this.y(d.value));

    this.svg
      .append("path")
      .datum(
        data.points.map((x) => {
          return { date: new Date(x.date), value: x.value };
        })
      )
      .attr("fill", "none")
      .attr("stroke", data.color)
      .attr("stroke-width", 2)
      .attr("d", this.line);

    const circles = this.svg
      .selectAll("myCircles")
      .data(data.points)
      .enter()
      .append("circle")
      .attr("fill", data.color)
      .attr("stroke", "none")
      .attr("cx", (d) => {
        return this.x(d.date);
      })
      .attr("cy", (d) => {
        return this.y(d.value);
      })
      .attr("r", 4)
      .attr("data-color", data.color) // Set a custom attribute to store color
      .on("mouseover", this.mouseOverHandler)
      .on("mousemove", this.mouseMoveHandler)
      .on("mouseleave", this.mouseLeaveHandler);

    // Bring circles to the front
    circles.raise();
  }

  /**
   * Initialize the tooltip for the chart.
   */
  private createTooltip() {
    this.tooltip = d3
      .select(`figure#A${this.chartId}`)
      .append("div")
      .attr("class", "mat-tooltip mat-tooltip-handset")
      .style("white-space", "nowrap")
      .style("position", "fixed");
  }

  /**
   * Handles tooltip content on hover.
   * @param {MouseEvent} event - The mouse event.
   * @param {*} d - Data associated with the element.
   */
  private mouseOverHandler(event, d) {
    const circle = d3.select(event.target);
    this.tooltip.style("display", "block");

    circle
      .transition()
      .duration(200)
      .attr("r", 6)
      .attr("fill", "white") // Change fill to white
      .attr("stroke", circle.attr("data-color")) // Retrieve color from custom attribute
      .attr("stroke-width", 2); // Add border width; // Increase radius on hover

    this.tooltip.html(
      ` <div style="text-align : start; font-size: 12px;">
          <b>${moment(d.date).format("DD/MM/YYYY")}</b><br>
          ${d.value}  
        </div>`
    );
  }

  /**
   * Manages tooltip position on mouse movement.
   * @param {MouseEvent} event - The mouse event.
   */
  private mouseMoveHandler(event) {
    this.tooltip.style("left", event.x + "px").style("top", event.y + "px");
  }

  /**
   * Hides the tooltip when leaving a cell.
   */
  private mouseLeaveHandler(event) {
    const circle = d3.select(event.target);
    circle.transition().duration(200).attr("r", 4).attr("stroke-width", 0).attr("fill", circle.attr("data-color")); // Add border width; // Increase radius on hover // Increase radius on hover // remove stroke // restore color
    this.tooltip.style("display", "none");
  }

  public drawStraightLine(value: unknown, lineType: LineType, color: string, axe: string): void {
    const typedValue = this.geTypedValue(lineType, value);
    const lineGroup = this.svg.append("g");
    if (axe === "y") {
      lineGroup
        .attr("transform", `translate(${this.margin},${this.y(typedValue)})`)
        .append("line")
        .attr("x2", this.width - this.margin - this.margin);
    } else if (axe === "x") {
      if ((typedValue as Moment).isBetween(this.fromDate, this.toDate, null, "()")) {
        lineGroup
          .attr("transform", `translate(${this.x(typedValue)},${this.margin})`)
          .append("line")
          .attr("y2", this.height - this.margin * 2);
      }
    }

    lineGroup.style("stroke", d3.color(color).brighter()).style("stroke-width", "3px");
  }

  /**
   * Check that type for yLines is always "number" and type for xLines is always "date"
   */
  private checkLinesFormat(stat): void {
    if (stat.yLines && stat.yLines.type !== LineType.NUMBER) {
      throw new Error('yLine type must be "number"');
    }
    if (stat.xLines && stat.xLines.type !== LineType.DATE) {
      throw new Error('xLine type must be "date"');
    }
  }

  /**
   * Return xLines and yLines values to correct format after type checking
   */
  public geTypedValue(type: LineType, value: unknown): number | Moment {
    if (type === LineType.NUMBER) {
      const nValue = Number(value);
      if (Number.isNaN(nValue)) {
        throw new Error('"' + value + '"' + " is not a number. Must be changed in db");
      }
      return nValue;
    }
    if (type === LineType.DATE) {
      const date = moment(value);
      if (!date.isValid()) {
        throw new Error('"' + value + '"' + " is not a valid date. Must be changed in db");
      }
      return date;
    }
    // if other type, throw error
    throw new Error('the type "' + type + '"is not processed');
  }

  /**
   * Processes the statistical data to generate chart data for visualization.
   *
   * @param statistics - An array of statistical data.
   */
  public processData(statistics: IStatistic[]): void {
    for (const statistic of statistics) {
      const chartData: IChartData = {
        keyName: statistic.keyName,
        color: statistic.baseColor,
        points: statistic.values.map((v) => {
          if (v.valueQuantity.value) {
            this.hasChartValues = true;
          }
          return { value: v.valueQuantity.value, date: new Date(v.date) };
        }),
      };

      this.data.push(chartData);
      this.cdr.detectChanges();
    }
  }

  /**
   * Flattens the data points from an array of chart data by date.
   * Warning : data must already sorted
   *
   * @param dataArray - An array of chart data.
   * @returns An array of flattened and sorted data points.
   */
  public flattenDataByDate(dataArray: IChartData[]): IChartDataPoint[] {
    const flattenedArray: IChartDataPoint[] = [];
    for (const chartData of dataArray) {
      flattenedArray.push(...chartData.points);
    }

    return flattenedArray;
  }

  public YlinesToDataPoint(): IChartDataPoint[] {
    const points: IChartDataPoint[] = [];
    for (const stat of this.statsObsGroups.statistics) {
      if (stat.yLines && Array.isArray(stat.yLines.values)) {
        for (const value of stat.yLines.values) {
          points.push({ date: null, value: Number(value as number) });
        }
      }
    }
    return points;
  }
}
