import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { first, skipWhile, takeUntil, tap } from "rxjs/operators";
import { CareplanEditorService } from "src/app/careplan-editor/domain/careplan-editor.service";
import { FileLogger } from "src/app/helpers/fileLogger";
import { ICareplan } from "src/app/models/careplans.interface";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { LanguagesService } from "src/app/providers/languages.service";

export enum VIEWS {
  VISUAL = "visualView",
  BEAUTYFUL_JSON = "beautifulJsonView",
  JSON = "jsonView",
}
@Component({
  selector: "app-careplan-editor-general-tab",
  templateUrl: "./careplan-editor-general-tab.component.html",
  styleUrls: ["./careplan-editor-general-tab.component.scss"],
})
export class CareplanEditorGeneralTabComponent implements OnInit, OnDestroy {
  @ViewChild("input") importInput: ElementRef;

  public views = VIEWS;
  public currentView: VIEWS = this.views.VISUAL;
  public careplanTemplate: ICareplan;
  private onDestroy$ = new Subject<void>();

  constructor(
    public careplanEditorService: CareplanEditorService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private languagesService: LanguagesService,
    private healthcareService: HealthcareserviceService
  ) {}

  ngOnInit(): void {
    if (this.careplanEditorService.currentCareplanTemplate) {
      this.careplanTemplate = this.careplanEditorService.currentCareplanTemplate;
    } else {
      this.careplanEditorService.careplanTemplateReady$
        .pipe(
          skipWhile((value) => value === false),
          takeUntil(this.onDestroy$),
          tap((_ready) => {
            this.careplanTemplate = this.careplanEditorService.currentCareplanTemplate;
          })
        )
        .subscribe();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  public selectFile(): void {
    this.importInput.nativeElement.value = "";
    this.importInput.nativeElement.click();
  }

  public import(event: { target: { files: FileList } }): void {
    const file = event.target.files[0];

    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        try {
          this.careplanTemplate = JSON.parse(fileReader.result as string);
          this.saveCareplanTemplate();
        } catch (err) {
          FileLogger.error("CareplanEditorGeneralTabComponent", "Error while importing careplan template: ", err);
          this.translateService
            .get("page.careplanEditor.importCTError")
            .pipe(first())
            .subscribe((trans) => {
              this.snackBar.open(trans, "ok", { duration: 10000 });
            });
        }
      };
      fileReader.readAsText(file);
    } else {
      throw new Error("no file selected");
    }
  }
  /**
   * Save the careplan template (If the json is valid)
   */
  private saveCareplanTemplate() {
    this.careplanEditorService.currentCareplanTemplate = this.careplanTemplate;
    this.careplanEditorService.save(false, false, true);
    this.initHealthcareService();
  }
  /**
   * Get all available services of the user and setup the list for the form
   */
  private initHealthcareService(): void {
    this.healthcareService
      .watchAvailableServices()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((services) => {
        services.map((s) => {
          const isChecked = this.careplanTemplate.author.findIndex((a) => a.reference === s.identifier[0].value) !== -1;
          const serviceForm = this.careplanEditorService.careplanTemplateForm.get("services") as UntypedFormArray;
          if (isChecked && serviceForm.length < 0) {
            serviceForm.push(
              new UntypedFormGroup({
                reference: new UntypedFormControl(s.identifier[0].value),
                display: new UntypedFormControl(s.identifier[0].label),
              })
            );
          }
        });
      });
  }
}
