import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";

@Component({
  selector: "app-item-filter-date",
  templateUrl: "./date-filter.component.html",
  styleUrls: ["./date-filter.component.scss"],
})
export class DateFilterComponent implements OnInit {
  public filterForm = this.fb.group({
    fromDate: ["", [Validators.required]],
    toDate: ["", [Validators.required]],
  });

  public minToDate: moment.Moment = null;
  public maxFromDate: moment.Moment = null;

  public constructor(
    private fb: UntypedFormBuilder,
    private readonly dialogRef: MatDialogRef<DateFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Record<string, Record<string, unknown>>
  ) {}

  ngOnInit(): void {
    if (this.data && this.data.filter && this.data.filter.data) {
      this.filterForm.setValue(this.data.filter.data);
    }
  }

  public apply(): void {
    if (this.filterForm.valid) {
      this.dialogRef.close({
        fromDate: moment(this.filterForm.get("fromDate").value).format("YYYY-MM-DD"),
        toDate: moment(this.filterForm.get("toDate").value).format("YYYY-MM-DD"),
      });
    }
  }

  public onChangeToDate(): void {
    this.maxFromDate = this.filterForm.get("toDate").value;
  }

  public onChangeFromDate(): void {
    this.minToDate = this.filterForm.get("fromDate").value;
  }

  public clear(): void {
    this.dialogRef.close();
  }
}
