<mat-drawer-container #drawer class="mat-elevation-z1">
  <mat-drawer mode="side" opened position="end">
    <!-- SOURCE LIST (right column)-->
    <div class="drawer-title-container">
      <h2 class="drawer-title">{{ "page.careplanEditor.tabs.vitalSigns.titleAvailable" | translate }}</h2>
    </div>
    <div class="p-1">
      <app-linkable-items-list
        *ngIf="availableVitalProfiles && columns"
        [id]="'drop-list-1'"
        [connectedTo]="'drop-list-2'"
        [items]="availableVitalProfiles"
        [linkedToCareplanItems]="vitalProfiles"
        [linkedToActivitiesItems]="[]"
        [columns]="columns"
      ></app-linkable-items-list>

      <mat-spinner *ngIf="!availableVitalProfiles" style="margin: auto"></mat-spinner>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <!-- TARGET LIST-->
    <h3>{{ "page.careplanEditor.tabs.vitalSigns.titleLinked2Careplan" | translate }}</h3>

    <app-linkable-items-list
      *ngIf="vitalProfiles && columns"
      [id]="'drop-list-2'"
      [connectedTo]="'drop-list-1'"
      [items]="vitalProfiles"
      [linkedToCareplanItems]="vitalProfiles"
      [linkedToActivitiesItems]="[]"
      [columns]="columns"
      [actions]="actions"
      (itemsChange)="updateForm($event)"
      [isTarget]="true"
    ></app-linkable-items-list>

    <mat-spinner *ngIf="!vitalProfiles" style="margin: auto"></mat-spinner>
  </mat-drawer-content>
</mat-drawer-container>
