import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { Tools } from "src/app/helpers/tools";
import { InfoCommunication } from "src/app/models/communications.model";
import { CommunicationsService } from "src/app/providers/communications.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";

export class CommunicationsListDataSource extends MatTableDataSourceExtended<InfoCommunication> {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private refreshInterval;
  public allDatas: InfoCommunication[];
  public loading$ = this.loadingSubject.asObservable();
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  sortingDataAccessor = (data: InfoCommunication, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "status":
        return data.openedStatus;
      case "sendDate":
        return moment(data.sent).unix();
      case "receivedDate":
        return data.receivedDateStringGlobal;
      case "sendTo":
        return data.sendTo.toLowerCase();
      case "subject":
        return data.topic.toLowerCase();
      default:
        return "";
    }
  };

  constructor(
    private comService: CommunicationsService,
    private sessionService: SessionService,
    private translateService: TranslateService,
    private userService: UserService,
    private healthcareService: HealthcareserviceService
  ) {
    super();
  }

  public loadCommunications(fromDate: string, toDate: string, autoRefreshIntervalMs = 600000): void {
    if (!this.userService.isAuthorizedSync(null, "dashboard/communications", "GET")) {
      return;
    }
    this.loadingSubject.next(true);

    if (this.refreshInterval) {
      this.clearAutoRefresh();
    }

    this.refreshInterval = setInterval(() => {
      this.loadData(fromDate, toDate);
    }, autoRefreshIntervalMs);
    this.loadData(fromDate, toDate);
  }

  private clearAutoRefresh() {
    clearInterval(this.refreshInterval);
  }

  public clear(): void {
    this.clearAutoRefresh();
    this.loadingSubject.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadData(fromDate: string, toDate: string) {
    const currentService = this.userService.isMonitoringUser
      ? this.sessionService.currentMonitoringService
      : this.sessionService.currentService;
    let ref = currentService?.reference;
    const services = [];
    if (ref === this.sessionService.allsOption) {
      const allServices = this.userService.isMonitoringUser
        ? this.healthcareService.availableMonitoringServices()?.map((s) => s.serviceRef)
        : this.healthcareService.availableServices()?.map((s) => s.serviceRef);
      services.push(...allServices);
      ref = null;
    }
    if (!ref && services.length < 1) {
      this.allDatas = [];
      this.data = [];
      this.loadingSubject.next(false);
    } else {
      this.comService
        .list(ref, fromDate, toDate, services)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res) => {
          this.allDatas = res;
          this.data = this.mergeByGroup(res);
          this.loadingSubject.next(false);
        });
    }
  }

  private mergeByGroup(communications: InfoCommunication[]): InfoCommunication[] {
    return (
      communications?.reduce((acc: InfoCommunication[], el: InfoCommunication) => {
        const isPersoGroup = communications.filter((c) => c.selfId === el.selfId)?.length > 1 && !el.group?.reference;
        if (isPersoGroup) {
          el.group = {
            display: this.translateService.instant("group.perso"),
            reference: Tools.genValidId(),
          };
        }
        const isPresent = el.group?.reference ? acc.findIndex((c) => c.selfId === el.selfId) > -1 : false;
        if (!isPresent) {
          acc.push(el);
        }
        return acc;
      }, []) || []
    );
  }
}
