<ng-container>
  <app-careplan
    [careplan]="careplan"
    [patient]="dialogOption.patient"
    [registration]="dialogOption.registration"
    [createNewCareplan]="dialogOption.careplan === undefined"
    (saved)="saved()"
    [includeBirthdateAndGenderForm]="dialogOption.includeBirthdateAndGenderForm"
    [healthcareService]="dialogOption.healthcareService"
    [careplans]="dialogOption.careplans"
  >
  </app-careplan>
</ng-container>
