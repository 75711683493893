import { Injectable } from "@angular/core";
import moment from "moment";
import { Observable } from "rxjs";
import { Tools } from "../helpers/tools";
import { DrugSchema } from "../models/drugSchema.model";
import { CycleSchema } from "../models/entity.interface";
import { ITiming } from "../models/sharedInterfaces";
import { DrugSchemaApiService } from "./api/drugSchema-api.service";

@Injectable({
  providedIn: "root",
})
export class DrugSchemaService {
  constructor(private drugSchemaApiService: DrugSchemaApiService) {}

  public static isCycleInPauseOnDate(cycle: CycleSchema, date: Date): boolean {
    if (!DrugSchemaService.isCycleInPause(cycle)) {
      return false;
    }
    const diff = Tools.differenceDate(new Date(cycle.pauseDate[cycle.pauseDate.length - 1]), new Date(date));
    return diff === 0;
  }

  public static getDayOfCycleOnDate(cycle: CycleSchema, date = new Date()): number {
    // the number of days of each cycle, we have it with the length of the cycle schema
    const nbrDaysOfCycle = cycle.cycle.length;

    // determine in which cycle we are
    // we have it by dividing the number of days since the cycle started by the number of days of each cycle
    // we round up the result
    const actualCycleNbr = Math.ceil(Tools.differenceDate(date, new Date(cycle.startDate)) / nbrDaysOfCycle);
    if (actualCycleNbr <= 1) {
      // if we are in the first cycle we just need to return the difference of days between now and the beginning of the cycle
      return Tools.differenceDate(date, new Date(cycle.startDate));
    }
    // we determine since how many days the first cycle started from the beginning of the actual cycle
    const nbrDaysFromLastCycle = (actualCycleNbr - 1) * nbrDaysOfCycle;

    // we determine since how many days the first cycle started from now
    const nbrDaysFromNow = Tools.differenceDate(date, new Date(cycle.startDate));

    // we determine the current index of the actual cycle by subtracting these results
    return nbrDaysFromNow - nbrDaysFromLastCycle;
  }

  public static isCycleInPause(cycle: CycleSchema): boolean {
    if (!cycle.pauseDate || cycle.pauseDate.length === 0) {
      return false;
    }
    // The last pause is the last element of the array of pauses
    const lastPause = new Date(cycle.pauseDate[cycle.pauseDate.length - 1]);
    const now = new Date();

    // the number of days of each cycle, we have it with the length of the cycle schema
    const nbrDaysOfCycle = cycle.cycle.length;

    // determine in which cycle we are
    // we have it by dividing the number of days since the cycle started by the number of days of each cycle
    // we round up the result
    const actualCycleNbr = Math.ceil(Tools.differenceDate(now, new Date(cycle.startDate)) / nbrDaysOfCycle);
    if (actualCycleNbr === 0) {
      // if we are in the first cycle we just need to verify if there is a pause
      return cycle.pauseDate && cycle.pauseDate.length === 1;
    }

    // we determine how many days we need to add to the start date to be in the beginning of the actual cycle
    const daysToAdd = (actualCycleNbr - 1) * nbrDaysOfCycle + 1;

    // we add this number of days to the start date, and we now have the date of the beginning of the actual cycle
    const startCycle = new Date(new Date(cycle.startDate).getTime() + daysToAdd * 1000 * 60 * 60 * 24);

    // we compare the date of the beginning of the actual cycle with the last pause
    // if the last pause is before the start cycle, the cycle was in pause but not in this cycle
    return lastPause >= startCycle;
  }

  public static getCycleInstances(cycle: CycleSchema, timing: ITiming, from?: moment.Moment, to?: moment.Moment): string[] {
    if (!cycle || cycle.cycle.length < 1) {
      return [];
    }
    const instances: string[] = [];

    // do not take 'from', if 'Timing.Period' is more than 1, always start with Drug defined start day
    const startDay = moment(timing.boundsPeriod.start).startOf("day");
    const endDay = moment.min(moment(timing.boundsPeriod.end), to).endOf("day");
    const actualDayOfCycle = this.getDayOfCycleOnDate(cycle);
    // TODO we should put the pauses in the historic to keep a trace of
    let isPaused = this.isCycleInPause(cycle);
    let isPausedAndToday = this.isCycleInPauseOnDate(cycle, new Date());
    let i = actualDayOfCycle;
    // compute from 'startDay' to 'endDay'
    while (startDay.isSameOrBefore(endDay)) {
      // add it only if it is not before 'From' date
      if (startDay.isSameOrAfter(from)) {
        if (!isPaused && cycle.cycle[i]) {
          const drugTime = startDay.clone();
          instances.push(drugTime.format());
        } else {
          // if the pause date is tomorrow
          if (isPaused && !isPausedAndToday && cycle.cycle[i]) {
            const drugTime = startDay.clone();
            instances.push(drugTime.format());
          }
        }
      }
      if (i === cycle.cycle.length - 1) {
        // end of cycle, start a new one
        i = 0;
        isPaused = false;
      } else {
        i++;
      }
      startDay.add(1, "days");
      if (isPaused && !isPausedAndToday) {
        isPausedAndToday = this.isCycleInPauseOnDate(cycle, startDay.toDate());
      }
      // end of all processing
      if (startDay.isAfter(endDay)) {
        break;
      }
    }
    return instances;
  }

  public list(drug?: string, isMenu?: boolean): Observable<DrugSchema[]> {
    return this.drugSchemaApiService.list(drug, isMenu);
  }

  public create(drugSchema: DrugSchema): Observable<DrugSchema> {
    return this.drugSchemaApiService.create(drugSchema);
  }

  public update(drugSchema: DrugSchema): Observable<DrugSchema> {
    return this.drugSchemaApiService.update(drugSchema);
  }

  public delete(id: string): Observable<DrugSchema> {
    return this.drugSchemaApiService.delete(id);
  }

  /*
   * Return a boolean that indicate if the cycle is actually in pause or not
   */
  public isCycleInPause(cycle: CycleSchema): boolean {
    return DrugSchemaService.isCycleInPause(cycle);
  }

  /*
   * Return if the cycle is in pause for today (and not tomorrow)
   */
  public isCycleInPauseOnDate(cycle: CycleSchema, date: Date): boolean {
    return DrugSchemaService.isCycleInPauseOnDate(cycle, date);
  }
}
