import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface LangConflictDialogOption {
  lang1: string;
  lang2: string;
}

export interface LangConflictDialogResult {
  lang: string;
  remember: boolean;
}

@Component({
  selector: "app-lang-conflict-dialog",
  templateUrl: "./lang-conflict-dialog.component.html",
  styleUrls: ["./lang-conflict-dialog.component.scss"],
})
export class LangConflictDialogComponent {
  public rememberChoice = false;

  constructor(
    public dialogRef: MatDialogRef<LangConflictDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LangConflictDialogOption
  ) {}

  public chooseLang(lang: string): void {
    this.dialogRef.close({
      lang,
      remember: this.rememberChoice,
    });
  }
}
