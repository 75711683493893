import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { MatOptionSelectionChange } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { Healthcareservice } from "src/app/models/healthcareservice.model";
import { Contained } from "src/app/models/questionnaire.interface";
import { Reference } from "src/app/models/sharedModels.model";
import { QuestionnairesService } from "src/app/providers/questionnaires.service";
import { UserService } from "src/app/providers/user.service";
import uuid from "uuid-random";

export enum UnitsOptions {
  NEED_UNITS = "needUnits",
  MULTI_INPUTS = "needMultiInputs",
  NOTHING = "nothing",
}
@Component({
  selector: "app-units-value-set",
  templateUrl: "./units-value-set.component.html",
  styleUrls: ["./units-value-set.component.scss"],
})
export class UnitsValueSetComponent implements OnDestroy {
  @Input() lang: string;
  @Input() disabled = false;
  @Input() multiInputs: boolean;
  @Output() multiInputsChange = new EventEmitter<boolean>();
  @Input() valueSetRef: string;
  @Output() valueSetRefChange = new EventEmitter<string>();

  @Input() set contained(value: Contained[]) {
    this.questionsOptions = value;
    this.setup();
  }
  @Output() containedChange = new EventEmitter<Contained[]>();

  questionsOptions: Contained[];
  currentIndexContained = -1;
  // Authorizations:
  canSaveServiceTemplate = false;
  canSaveGlobalTemplate = false;
  orgServices: Reference[];
  // ValueSets templates:
  valueSetTemplates: Contained[] = [];
  unitsTemplates: Contained[] = [];
  needUnits: string = UnitsOptions.NOTHING;
  public UnitsOptions = UnitsOptions;
  private onDestroy$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private questionnaireService: QuestionnairesService,
    private translateService: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Setup all the variables according to the inputs
   */
  private setup(): void {
    this.currentIndexContained = -1;
    this.needUnits = UnitsOptions.NOTHING;
    if (this.questionsOptions && this.valueSetRef && !this.multiInputs) {
      this.needUnits = UnitsOptions.NEED_UNITS;
      this.currentIndexContained = this.getCurrentIndexContained();
    } else if (this.questionsOptions && this.valueSetRef && this.multiInputs) {
      this.needUnits = UnitsOptions.MULTI_INPUTS;
      this.currentIndexContained = this.getCurrentIndexContained();
    }
    this.setupValueSetUnitsTemplates();
  }

  /**
   * Find the index of the answers options for the current question
   */
  private getCurrentIndexContained(): number {
    return this.questionsOptions.findIndex((model) => model.idSet === this.valueSetRef);
  }

  // --------------------------------------------------------------------------
  // --------------------------  UNITS  ---------------------------------------

  private async setupValueSetUnitsTemplates(): Promise<void> {
    let services: Healthcareservice[] = [];
    if (!this.orgServices) {
      if (this.userService.isMonitoringUser) {
        services = this.userService.allMonitoringServices;
        this.orgServices = this.userService.allMonitoringServices.map((s) => s.asReference);
      } else {
        services = this.userService.allServices;
        this.orgServices = this.userService.allServices.map((s) => s.asReference);
      }
    }
    const organizationsRefs = services.map((s) => s.providedBy).filter(ArrayHelper.onlyUniqueReference);
    const servicesIds = this.orgServices.map((s: Reference) => s.reference);
    const valueSetTemplates = await this.questionnaireService.getUnitsTemplate(
      this.lang,
      servicesIds,
      organizationsRefs.map((s: Reference) => s.reference)
    );
    this.unitsTemplates = valueSetTemplates;
  }

  /**
   * Called when the user checks or unchecks the 'units' checkbox for a
   * numeric input.
   */
  public onNeedUnitChange(): void {
    if (this.needUnits === UnitsOptions.NEED_UNITS || this.needUnits === UnitsOptions.MULTI_INPUTS) {
      if (this.needUnits === UnitsOptions.MULTI_INPUTS) {
        this.multiInputs = true;
      } else {
        this.multiInputs = false;
      }
      this.addUnits();
    } else {
      this.multiInputs = false;
      this.removeUnits();
    }
  }

  /**
   * Set a template as units' choices for the current input (either number or decimal)
   * @param event (MatOptionSelectionChange) the event containing the selected template
   */
  public setTemplateUnits(event: MatOptionSelectionChange): void {
    const newUnits: Contained = JSON.parse(JSON.stringify(event.source.value));
    newUnits.idSet = this.valueSetRef;
    this.questionsOptions[this.currentIndexContained] = newUnits;
    this.containedChange.emit(this.questionsOptions);
  }

  /**
   * Setup unit (value set contained) for the input
   */
  private addUnits(): void {
    if (this.currentIndexContained > -1) {
      this.multiInputsChange.emit(this.multiInputs);
      return;
    }
    const cid = uuid();
    // Put default units:
    const newUnits: Contained = {
      resourceType: "ValueSet",
      status: "active",
      name: "",
      idSet: cid,
      id: cid,
      description: "",
      compose: {
        include: [
          {
            system: "",
            concept: [
              { code: "cm", display: "cm" },
              { code: "[in_i]", display: "in" },
            ],
          },
        ],
      },
    };
    this.questionsOptions.push(newUnits);
    this.currentIndexContained = this.questionsOptions.length - 1;
    this.valueSetRef = cid;
    this.containedChange.emit(this.questionsOptions);
    this.valueSetRefChange.emit(this.valueSetRef);
    this.multiInputsChange.emit(this.multiInputs);
  }

  /**
   * Remove the units for the input
   */
  private removeUnits(): void {
    this.questionsOptions.splice(this.currentIndexContained, 1);
    this.currentIndexContained = -1;
    this.valueSetRef = "";
    this.valueSetRefChange.emit(this.valueSetRef);
    this.containedChange.emit(this.questionsOptions);
    this.multiInputsChange.emit(this.multiInputs);
  }

  /**
   * Add a choice of units to the options
   */
  public addUnitOption(): void {
    this.questionsOptions[this.currentIndexContained].compose.include[0].concept.push({
      code: "",
      display: "",
    });
    this.containedChange.emit(this.questionsOptions);
  }

  /**
   * Remove a choice from the units options
   * @param i the index of the choice we want to delete
   */
  public deleteUnitOption(i: number): void {
    const nbAnswers = this.questionsOptions[this.currentIndexContained].compose.include[0].concept.length;
    if (nbAnswers < 2) {
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translateService.instant("page.questionnaireEditor.properties.atLeastOneChoice"),
          type: ConfirmationDialogType.INFORMATION,
        },
      });
    } else {
      this.questionsOptions[this.currentIndexContained].compose.include[0].concept.splice(i, 1);
      this.containedChange.emit(this.questionsOptions);
    }
  }

  /**
   * If the user change the display text of a unit, we need to put a corresponding code
   * @param i the index of the choice that has been changed
   */
  public onUnitChoiceChanged(i: number): void {
    const display = this.questionsOptions[this.currentIndexContained].compose.include[0].concept[i].display;
    this.questionsOptions[this.currentIndexContained].compose.include[0].concept[i].code = display;
    this.containedChange.emit(this.questionsOptions);
  }
}
