import moment from "moment";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { Identity } from "src/app/models/patient.interface";

export class IdentityStatusChangeHistoricDataSource extends MatTableDataSourceExtended<Identity> {
  sortingDataAccessor = (data: Identity, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "identityChangeStatus":
        return data.status;
      case "verification":
        return data.verification;
      case "verificationMethod":
        return data.method;
      case "modificationType":
        return data.modificationType;
      case "periodStart":
        return moment(data.period.start).format();
      case "periodEnd":
        return moment(data.period?.end).format();
      default:
        return "";
    }
  };

  constructor() {
    super();
  }

  public loadData(data: Identity[]): void {
    this.data = data;
  }
}
