<div>
  <mat-grid-list cols="3" rowHeight="35px">
    <mat-grid-tile colspan="1" rowspan="5">
      <img class="customImg" [src]="photoSrc()" />
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
      <h2>{{ service?.serviceName }}</h2>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
      <h1 class="mb-0 mr-10 primary">#</h1>
      <h4 class="mb-0">[{{ service?.serviceRef }}]</h4>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
      <p>{{ service?.organizationName }} - [{{ service?.organizationRef }}]</p>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
      <mat-icon class="mr-10" color="primary">location_city</mat-icon>
      <p>
        {{ service?.adressLine }} {{ service?.cityAndPostCode }}
        {{ service?.country }}
      </p>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
      <mat-icon *ngIf="service?.mainPhone" class="mr-10" color="primary">phone</mat-icon>
      <p *ngIf="service?.mainPhone" class="mr-15">
        {{ service?.mainPhone }}
      </p>
      <mat-icon *ngIf="service?.mainMail" class="mr-10" color="primary">email</mat-icon>
      <p class="mr-15" *ngIf="service?.mainMail">
        {{ service?.mainMail }}
      </p>
      <!-- TODO CMATE-3567
            <button (click)="showMoreTelecom()" *ngIf="service?.isMultipleTelecom()" class="success" mat-button>
                {{'btn.details' | translate}}
            </button> -->
    </mat-grid-tile>
  </mat-grid-list>
</div>
