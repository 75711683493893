<h2 mat-dialog-title>
  {{ "page.questionnaireEditor.valueSet.templateManager" | translate }}
</h2>
<form [formGroup]="valueSetsForm" (ngSubmit)="onSaveClick()">
  <div mat-dialog-content class="valueSetsContent">
    <mat-radio-group
      aria-label="Select an option"
      [(ngModel)]="delete"
      [ngModelOptions]="{ standalone: true }"
      (change)="valueSetsForm.reset()"
    >
      <mat-radio-button [value]="false" [disabled]="!canUpdate">
        {{ "page.questionnaireEditor.edit" | translate }}
      </mat-radio-button>
      <mat-radio-button [value]="true" style="margin-left: 10px" [disabled]="!canDelete">
        {{ "common.delete" | translate }}
      </mat-radio-button>
    </mat-radio-group>
    <mat-selection-list #sets formControlName="valueSets" [multiple]="true" *ngIf="delete && canDelete">
      <mat-list-option [value]="t" *ngFor="let t of templates">
        {{ t.name }}
      </mat-list-option>
    </mat-selection-list>
    <mat-selection-list #sets formControlName="valueSets" [multiple]="false" *ngIf="!delete && canUpdate">
      <mat-list-option [value]="t" *ngFor="let t of templates">
        {{ t.name }}
      </mat-list-option>
    </mat-selection-list>
    <mat-error *ngIf="valueSets.hasError('required')">
      <strong>
        {{ "page.questionnaireEditor.valueSet.selectForDelete" | translate }}
      </strong>
    </mat-error>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onCancelClick()">
      {{ "btn.cancel" | translate }}
    </button>
    <button mat-button color="warn" type="submit" [disabled]="!valueSetsForm.valid" *ngIf="delete && canDelete">
      {{ "common.delete" | translate }}
    </button>
    <button mat-button color="accent" type="submit" [disabled]="!valueSetsForm.valid" *ngIf="!delete && canUpdate">
      {{ "page.questionnaireEditor.edit" | translate }}
    </button>
  </div>
</form>
