import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IVitalProfileDefinition } from "../models/vitalProfileDefinition.interface";
import { VitalProfileDefinitionApiService } from "../providers/api/vital-profile-definition-api.service";

@Injectable({
  providedIn: "root",
})
export class VitalProfileDefinitionsService {
  constructor(private api: VitalProfileDefinitionApiService) {}

  public list(patientId: string): Observable<IVitalProfileDefinition[]> {
    return this.api.list(patientId);
  }
}
