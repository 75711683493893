import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormsData } from "src/app/helpers/formsData";
import { Coding } from "src/app/models/coding.interface";
import { IHealthcareservice } from "src/app/models/healthcareservice";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";

@Component({
  selector: "app-identity-verif-methods-page",
  templateUrl: "./identity-verif-methods-page.component.html",
  styleUrls: ["./identity-verif-methods-page.component.scss"],
})
export class IdentityVerifMethodsPageComponent implements OnInit {
  public serviceWithVerifMethods: IHealthcareservice;
  public availableLangs: Coding[];

  private onDestroy$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private sessionService: SessionService,
    private formsData: FormsData,
    private healthcareService: HealthcareserviceService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.initIdentityVeridMethods();
    });
    this.initIdentityVeridMethods();
  }

  private initIdentityVeridMethods() {
    const currentService = this.sessionService.currentService;
    if (currentService?.reference !== this.sessionService.allsOption) {
      this.formsData.getLanguages().then((langs) => {
        this.availableLangs = langs;
        this.serviceWithVerifMethods = this.userService.allServices.find((s) => s.serviceRef === currentService.reference);
      });
    }
  }

  public addVerifMethod(): void {
    this.serviceWithVerifMethods.identityVerifMethods.push({
      term: (this.serviceWithVerifMethods.identityVerifMethods?.length + 1).toString(),
      fr: "",
      en: "",
      de: "",
      nl: "",
      it: "",
      pt: "",
      activated: false,
      level: "low",
    });
  }

  public updateVerifMethods(): void {
    this.serviceWithVerifMethods.identityVerifMethods.forEach((verifMethod, index) => {
      if (!verifMethod.fr && !verifMethod.en && !verifMethod.de && !verifMethod.nl && !verifMethod.it) {
        this.serviceWithVerifMethods.identityVerifMethods.splice(index, 1);
      }
    });
    this.healthcareService
      .update(this.serviceWithVerifMethods)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.snackBar.open(this.translateService.instant("common.saveSuccess"), undefined, {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      });
  }
}
