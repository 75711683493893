import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { FHIRHelper } from "../helpers/FHIRhelper";
import { Identifier } from "./identifier.interface";
import { NOTIFICATION_STATUS } from "./notifications.interface";
import { IObservationWithoutComponents, OComponent } from "./observations.interface";
import { IQuestionnaireResponse } from "./questionnaireResponse.interface";
import { Reference } from "./reference.interface";
import { ACTION_ALERT, ALERT_STATUS, IActionAlert, ICodeableConcept, INoDataTransmission, IRuleAlert } from "./rule-alert.interface";
import { RuleDefinition, RuleMeta } from "./rule.interface";

export class RuleAlert implements IRuleAlert {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  public _id: string;
  public creation: string;
  public modified: string;
  public identifier: Identifier;
  public subject: Reference; // patient reference for whom the alert was generated
  public ruleMeta: RuleMeta; // Meta data info on Rule
  public rule: RuleDefinition; // rule trigerred
  public valueComponents: OComponent[]; // [OComponent type] component value entered
  public observation?: IObservationWithoutComponents; // observation that generated the alert
  public questionnaireResponse?: IQuestionnaireResponse; // questionnaire response that generated the alert
  public author?: Reference; // Practitioner in charge of alert
  public notes?: ICodeableConcept[]; // text and reference to the author of the note
  public actionsHistory?: IActionAlert[]; // history of actions in this adress
  public alertStatus?: ALERT_STATUS; // status of the alert (UNPROCESSED / PROCESSED)
  public level: number;
  public status?: NOTIFICATION_STATUS; // status of the alert (handled by the patient)
  public comment?: string; // patient comment (only when the alert is rejected)
  public noDataTransmission?: INoDataTransmission;

  constructor(data: IRuleAlert) {
    this._id = data._id;
    this.creation = data.creation;
    this.modified = data.modified;
    this.identifier = data.identifier?.[0];
    this.subject = data.subject;
    this.ruleMeta = data.ruleMeta;
    this.rule = data.rule;
    this.valueComponents = data.valueComponents;
    this.creation = data.creation;
    this.author = data.author;
    this.actionsHistory = data.actionsHistory ? data.actionsHistory : [];
    this.alertStatus = data.alertStatus ? data.alertStatus : ALERT_STATUS.UNPROCESSED;
    this.notes = data.notes ? data.notes : [];
    this.observation = data.observation;
    this.questionnaireResponse = data.questionnaireResponse;
    this.level = data.rule.level;
    this.status = data.status ? data.status : NOTIFICATION_STATUS.NONE;
    this.comment = data.comment ? data.comment : "";
    this.noDataTransmission = data.noDataTransmission;
  }

  public lastComment(): string | null {
    const lastIndex = this.notes?.length - 1;
    if (lastIndex >= 0) {
      return this.notes?.[lastIndex]?.text;
    } else {
      return null;
    }
  }

  public statusTranslateKey(): string {
    switch (this.alertStatus) {
      case ALERT_STATUS.UNPROCESSED:
        return "alerts.status.unprocessed";
      case ALERT_STATUS.PROCESSED:
        return "alerts.status.processed";
      default:
        return "alerts.status.unknow";
    }
  }

  public getActionTranslateKey(actions: ACTION_ALERT[], translateService: TranslateService): string {
    let resultToTranslate = "";
    const lastId = actions.length - 1;
    actions.forEach((action, id) => {
      switch (action) {
        case ACTION_ALERT.CHANGE_IN_CHARGE:
          resultToTranslate += translateService.instant("alerts.action.changeInCharge") + (lastId !== id ? ", " : "");
          break;
        case ACTION_ALERT.CHANGE_STATUS:
          resultToTranslate += translateService.instant("alerts.action.changeStatus") + (lastId !== id ? ", " : "");
          break;
        case ACTION_ALERT.ADD_NOTE:
          resultToTranslate += translateService.instant("alerts.action.addNote") + (lastId !== id ? ", " : "");
          break;
        default:
          resultToTranslate += translateService.instant("alerts.action.unknow") + (lastId !== id ? ", " : "");
          break;
      }
    });
    return resultToTranslate;
  }

  public getValueAndUnit(valueComponent: OComponent): string {
    return valueComponent.valueQuantity.value + " " + (valueComponent.valueQuantity.unit ? valueComponent.valueQuantity.unit : "");
  }

  public getCode(valueComponent: OComponent): string {
    if (valueComponent?.code?.coding && valueComponent.code.coding.length) {
      return valueComponent.code.coding[0].code;
    }
  }

  public getDisplay(valueComponent: OComponent): string {
    if (valueComponent?.code?.coding && valueComponent.code.coding.length) {
      return valueComponent.code.coding[0].display;
    }
  }

  public date(): moment.Moment | null {
    return this.creation ? moment(this.creation) : null;
  }

  public changeInCharge(practitionerResponsable: Reference): void {
    this.author = practitionerResponsable;
  }

  public changeStatus(status: ALERT_STATUS): void {
    this.alertStatus = status;
  }

  /**
   * return index of comment
   */
  public addComment(practitionerAuthor: Reference, note: string): number {
    return (
      this.notes.push({
        text: note,
        code: {
          code: practitionerAuthor.reference,
          display: practitionerAuthor.display,
          system: FHIRHelper.SYSTEM_COMUNICARE,
        },
      }) - 1
    );
  }

  private setNewHistoric(practitionerAuthor: Reference, action: ACTION_ALERT[], commentId?: number) {
    this.actionsHistory.push({
      actionDate: moment().format(),
      action,
      actionAuthor: practitionerAuthor,
      commentId,
    });
  }

  public handleActionArray(
    actionArray: ACTION_ALERT[],
    status: ALERT_STATUS,
    responsable: Reference,
    comment: string,
    author: Reference
  ): void {
    let commentId: number;

    actionArray.forEach((a) => {
      switch (a) {
        case ACTION_ALERT.CHANGE_IN_CHARGE:
          this.changeInCharge(responsable);
          break;
        case ACTION_ALERT.CHANGE_STATUS:
          this.changeStatus(status);
          break;
        case ACTION_ALERT.ADD_NOTE:
          commentId = this.addComment(author, comment);
          break;
        default:
          break;
      }
    });

    this.setNewHistoric(author, actionArray, commentId);
  }

  public patientStatusTranslateKey(): string {
    switch (Array.isArray(this.status) ? this.status[0] : this.status) {
      case NOTIFICATION_STATUS.ACCEPTED:
        return "alerts.notifStatus.accepted";
      case NOTIFICATION_STATUS.REJECTED:
        return "alerts.notifStatus.rejected";
      default:
        return "alerts.notifStatus.none";
    }
  }
}
