export enum ScrollPosition {
  START = "start",
  END = "end",
  BETWEEN = "between",
}

export interface IScrollPosition {
  vertical: ScrollPosition;
  horizontal: ScrollPosition;
}
