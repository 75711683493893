import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterByKeyValue",
})
export class FilterByKeyValuePipe implements PipeTransform {
  transform<T>(items: T[], key: keyof T, value: unknown): T[] {
    if (!items || !Array.isArray(items) || !key) {
      return items;
    }

    return items.filter((item) => item[key] === value);
  }
}
