<app-when-input
  [hasSelectedActivity]="true"
  [(period)]="period"
  (periodChange)="updateWhenInput()"
  [(periodUnit)]="periodUnit"
  (periodUnitChange)="updateWhenInput()"
  [(timingCode)]="timingCode"
  (timingCodeChange)="updateWhenInput()"
  [(skipFirst)]="skipFirst"
  (skipFirstChange)="updateWhenInput()"
  [isDisabled]="false"
  [needShowDirectly]="false"
  [needSkipFirst]="needSkipFirst"
  [context]="action_target.COMMUNICATION"
  class="when-input"
>
</app-when-input>

<mat-tab-group [(selectedIndex)]="selectedIndex" animationDuration="0" mat-align-tabs="center" class="mt-1">
  <mat-tab [label]="'languages.' + lang.term | translate" *ngFor="let lang of availableLanguages">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </mat-tab>
</mat-tab-group>

<input hidden (change)="addPdf($event)" #fileInput type="file" accept="application/pdf" id="file" multiple />
<input hidden (change)="addImage($event)" #imgInput type="file" accept="image/jpg,image/jpeg,image/png,image/tiff" id="img" multiple />
<mat-chip-list #chipList>
  <mat-label *ngIf="attachementsString.length">{{ "communication.attachment" | translate }} : </mat-label>
  <mat-chip *ngFor="let item of attachementsString" [removable]="true" (removed)="remove(item)">
    {{ item }}
    <mat-icon matChipRemove>cancel</mat-icon>
  </mat-chip>
</mat-chip-list>

<!--  @TODO: CMATE-5881 Multilingual attachement not implemented yet  -->
<!-- <div class="btn-container">
  <button mat-raised-button matTooltip="{{ 'page.organizations.btn.addImage' | translate }}" (click)="imgInput.click()">
    <mat-icon>insert_photo</mat-icon>
  </button>
  <button mat-raised-button matTooltip="{{ 'btn.addPdf' | translate }}" (click)="fileInput.click()">
    <mat-icon>attachment</mat-icon>
  </button>
</div> -->

<ng-template #content>
  <ng-container *ngIf="availableLanguages && availableLanguages[selectedIndex]">
    <form id="form" class="mt-1">
      <ng-container>
        <mat-form-field style="width: 100%" class="mt-1">
          <mat-label>{{ "communication.subject" | translate }}</mat-label>
          <input
            type="text"
            matInput
            [formControl]="communicationForm.get('detail.contentCommunication.topicTranslation.' + availableLanguages[selectedIndex].term)"
          />
        </mat-form-field>
        <div class="mb-10">
          <p class="mb-10">{{ "communication.content" | translate }}</p>
          <jodit-editor
            [config]="config"
            [formControl]="
              communicationForm.get('detail.contentCommunication.contentTranslation.' + availableLanguages[selectedIndex].term)
            "
          ></jodit-editor>
        </div>
      </ng-container>
    </form>
  </ng-container>
</ng-template>
