import * as moment from "moment";
import { UnknowPatientData } from "./UnknownPatientData.interface";
import { IAppointment, NOTIF_STATUS, SYSTEM_JITSI, SYSTEM_JITSI_PRACTITIONER } from "./appointment.interface";
import { Codes } from "./codes.interface";
import { StatusEntity } from "./entity.interface";
import { Identifier } from "./identifier.interface";
import { Participant } from "./participant.interface";
import { Reference } from "./reference.interface";

export class Appointment implements IAppointment {
  public static PRACTITIONER = "practitioner";
  public static PERSON = "person";
  public static HEALTHCARESERVICE = "healthcareservice";

  public static SYSTEM_JITSI = "meetjitsi";
  public static SYSTEM_JITSI_PRACTITIONER = "meetjitsi_practitioner";

  public static get TYPE_CAREPLANLINK(): string {
    return "careplanlink";
  } // appointment type linked to/from a careplan

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  public _id: string;
  public creation: string;
  public modified: string;
  public createdBy?: string;
  public resourceType: string;
  public identifier: Identifier[];
  public status: string; // "booked" for user appointement, set something else for hospital appointement
  public description: string;
  public participant: Participant[];
  public comment?: string;
  public patientComment?: string; // NOT FHIR format: user specific comment for this appointment
  public start: string; // moment start date
  public end: string; // moment end date
  public serviceType?: Codes;
  public appointmentType?: Codes;
  public reason?: Codes;
  public supportingInformation?: Reference[];
  public serviceCategory?: Codes;
  public specialty?: Codes[];
  public indication?: Reference[];
  public minutesDuration?: string;
  public unknowPatientData?: UnknowPatientData;
  public billingCode?: string;
  public notificationStatus?: NOTIF_STATUS;
  public entityStatus?: StatusEntity[];
  public minutesDelay?: number;
  public dateSeenByPatient?: string;
  public dateSeenByPatientHistory?: string[];

  constructor(data: IAppointment) {
    this._id = data._id;
    this.creation = data.creation;
    this.modified = data.modified;
    this.createdBy = data.createdBy;
    this.resourceType = data.resourceType;
    this.identifier = data.identifier;
    this.status = data.status;
    this.description = data.description;
    this.participant = data.participant;
    this.comment = data.comment;
    this.patientComment = data.patientComment;
    this.start = data.start;
    this.end = data.end;
    this.serviceType = data.serviceType;
    this.appointmentType = data.appointmentType;
    this.reason = data.reason;
    this.supportingInformation = data.supportingInformation;
    this.serviceCategory = data.serviceCategory;
    this.specialty = data.specialty;
    this.indication = data.indication;
    this.minutesDuration = data.minutesDuration;
    this.unknowPatientData = data.unknowPatientData;
    this.billingCode = data.billingCode;
    this.notificationStatus = data.notificationStatus;
    this.entityStatus = data.entityStatus;
    this.minutesDelay = data.minutesDelay;
    this.dateSeenByPatient = data.dateSeenByPatient;
    this.dateSeenByPatientHistory = data.dateSeenByPatientHistory;
  }

  public get participantPractitioner(): string {
    return this.participant.find((p) => p.participantType.text === Appointment.PRACTITIONER)?.actor?.display;
  }

  public get participantPatient(): string {
    return this.participant.find((p) => p.participantType.text === Appointment.PERSON)?.actor?.display;
  }

  public get participantPatientRef(): Reference {
    return this.participant.find((p) => p.participantType.text === Appointment.PERSON)?.actor;
  }

  public get duration(): number {
    return this.diffMinutes(new Date(this.start), new Date(this.end));
  }

  public get participantPractitionerRef(): Reference {
    return this.participant.find((p) => p.participantType.text === Appointment.PRACTITIONER)?.actor;
  }

  public get isPast(): boolean {
    const today = moment(moment().format("YYYY-MM-DD"));
    const start = moment(moment(this.end || this.start).format("YYYY-MM-DD"));
    return start.isBefore(today);
  }

  /**
   * used to compute duration in minutes between 2 dates
   */
  private diffMinutes(dt2: Date, dt1: Date): number {
    const diff = (dt2.getTime() - dt1.getTime()) / 1000 / 60;
    return Math.abs(Math.round(diff));
  }

  /**
   * check if patient is a comunicare user or not
   */
  public isNotUnknowPatient(): boolean {
    return !this.unknowPatientData;
  }

  public get interface(): IAppointment {
    return {
      _id: this._id,
      creation: this.creation,
      modified: this.modified,
      createdBy: this.createdBy,
      resourceType: this.resourceType,
      identifier: this.identifier,
      status: this.status,
      description: this.description,
      participant: this.participant,
      comment: this.comment,
      patientComment: this.patientComment,
      start: this.start,
      end: this.end,
      serviceType: this.serviceType,
      appointmentType: this.appointmentType,
      reason: this.reason,
      supportingInformation: this.supportingInformation,
      serviceCategory: this.serviceCategory,
      specialty: this.specialty,
      indication: this.indication,
      minutesDuration: this.minutesDuration,
      unknowPatientData: this.unknowPatientData,
      billingCode: this.billingCode,
      notificationStatus: this.notificationStatus,
      entityStatus: this.entityStatus,
      dateSeenByPatient: this.dateSeenByPatient,
      dateSeenByPatientHistory: this.dateSeenByPatientHistory,
    };
  }

  public get isCallVideo(): boolean {
    // TODO : afficher seulement si le rdv est aujourd'hui (dans l'heure ?)
    if (!this.participant) {
      return false;
    }
    return this.participant.some((part) => {
      if (!part.participantType || !part.participantType.coding) {
        return false;
      }
      return part.participantType.coding.some((coding) => coding.system === SYSTEM_JITSI);
    });
  }

  /**
   * return array URL for visio from appointement
   */
  public get addressJitsiMeet(): [string, string] {
    if (this.isCallVideo) {
      for (const part of this.participant) {
        for (const coding of part.participantType.coding) {
          if (coding.system === SYSTEM_JITSI_PRACTITIONER) {
            /*
                        example : "https://meet.jit.si/BellaVitaMedicalCentercovid@com.be.20200320123636178.32497713980"
                              => [https://meet.jit.si/ , BellaVitaMedicalCentercovid@com.be.20200320123636178.32497713980]
                        */
            const address = coding.code;
            const lastIndexSlash = address.lastIndexOf("/");
            return [address.substring(0, lastIndexSlash + 1), address.substring(lastIndexSlash + 1)];
          }
        }
      }
    }

    return null;
  }

  public get isActive(): boolean {
    return this.entityStatus.indexOf(StatusEntity.ACTIVE) >= 0;
  }
}
