<div class="score-details">
  <h5 class="mt-15">{{ categoryName }}</h5>
  <mat-card class="mt-10">
    <table mat-table matSort matSortDirection="asc" [dataSource]="dataSource">
      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.identifier?.label ? element.identifier.label : ("model.patient.questionnaireTotalScore" | translate) }}
        </td>
      </ng-container>

      <!-- Answer Column -->
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.score" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.scoringValue !== null && element.scoringValue !== undefined ? element.scoringValue : "-" }}
        </td>
      </ng-container>
      <!-- Score Interpretation Column -->
      <ng-container matColumnDef="interpretation">
        <th mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "pdf.questionnaire.scoreInterpretation" | translate }}
        </th>
        <td mat-cell mat-cell *matCellDef="let element">
          {{ element.interpretationValue?.result ? element.interpretationValue.result[translateService.currentLang] : "" }}
        </td>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
      </ng-container>
    </table>
  </mat-card>
</div>
