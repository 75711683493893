<div class="teleconsultations-page">
  <div class="toolbar">
    <div class="left">
      <button mat-icon-button (click)="openVideoCallHelp()">
        <mat-icon style="font-size: 30px">help_outline</mat-icon>
      </button>
    </div>
    <div class="spacer"></div>
    <div class="right">
      <app-appointment-delay
        [appointments]="selection.selected"
        [(minutesDelay)]="minutesDelay"
        (minutesDelayChange)="updateAppointmentsWithDelay()"
      ></app-appointment-delay>
    </div>
  </div>
  <p *ngIf="!has2FA && !skip2FA" class="noAccess">
    {{ "mfa.need2FAForTeleconsultation" | translate }}
    <button mat-button (click)="skip2FA = true" color="danger">
      <mat-icon>close</mat-icon>
      {{ "btn.ignore" | translate }}
    </button>
  </p>
  <app-filters-display [filters]="filters" (removeFilter)="applyFilter($event)"></app-filters-display>
  <mat-card [ngClass]="{ hide: allAppointements.length === 0 }">
    <mat-table matSort matSortDirection="asc" matSortActive="start" [dataSource]="dataSource">
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            class="ml-15"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() || forceSelected"
            [indeterminate]="selection.hasValue() && selection.selected.length !== dataSource.data.length"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            class="ml-15"
            (click)="$event.stopPropagation()"
            [disabled]="forceSelected"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row) || forceSelected"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- Start Column -->
      <ng-container matColumnDef="start">
        <mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "table.start" | translate }}
          <app-item-filter
            fieldName="{{ 'table.start' | translate }}"
            [translateFieldKey]="'table.start'"
            propertyName="start"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('start')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.start | date : "short" }}
          <span class="appointmentDelay">
            {{ row.minutesDelay && row.minutesDelay !== 0 ? "+" + row.minutesDelay + ("periodUnit.minute.short" | translate) : "" }}
          </span>
        </mat-cell>
      </ng-container>

      <!-- End Column -->
      <ng-container matColumnDef="end">
        <mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "table.duration" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.duration + ("periodUnit.minute.short" | translate) }}
        </mat-cell>
      </ng-container>

      <!-- Participant Column -->
      <ng-container matColumnDef="participant">
        <mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "table.participantPractitioner" | translate }}
          <app-item-filter
            fieldName="{{ 'table.participantPractitioner' | translate }}"
            [translateFieldKey]="'table.participantPractitioner'"
            propertyName="participantPractitioner"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('participantPractitioner')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.participantPractitioner }}
        </mat-cell>
      </ng-container>

      <!-- Patient Column -->
      <ng-container matColumnDef="patient">
        <mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "table.participantPatient" | translate }}
          <app-item-filter
            fieldName="{{ 'table.participantPatient' | translate }}"
            [translateFieldKey]="'table.participantPatient'"
            propertyName="participantPatient"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('participantPatient')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.participantPatient }}</mat-cell>
      </ng-container>

      <!-- Object Column -->
      <ng-container matColumnDef="object">
        <mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "table.description" | translate }}
          <app-item-filter
            fieldName="{{ 'table.description' | translate }}"
            [translateFieldKey]="'table.description'"
            propertyName="description"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('description')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.description }}
        </mat-cell>
      </ng-container>

      <!-- Date Seen By Patient Column -->
      <ng-container matColumnDef="dateSeenByPatient">
        <mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "table.dateSeenByPatient" | translate }}
          <app-item-filter
            fieldName="{{ 'table.dateSeenByPatient' | translate }}"
            [translateFieldKey]="'table.dateSeenByPatient'"
            propertyName="dateSeenByPatient"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('dateSeenByPatient')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.dateSeenByPatient ? (row.dateSeenByPatient | date : "short") : "-" }}
        </mat-cell>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <mat-header-cell cdkDrag *matHeaderCellDef>
          {{ "table.action" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button
            mat-icon-button
            class="custom-small-btn"
            matTooltip="{{ 'btn.goToPatient' | translate }}"
            *ngIf="row.isNotUnknowPatient() && ('/patient' | isAuthorized | async) === true"
            mat-icon-button
            color="primary"
            (click)="goToPatient(row)"
          >
            <mat-icon class="custom-icon-small baseline-align">face</mat-icon>
          </button>
          <button
            class="custom-small-btn"
            mat-icon-button
            matTooltip="{{ 'btn.resendSmsMail' | translate }}"
            aria-label="icon button with an envelope icon"
            *ngIf="!row.isNotUnknowPatient() && ('appointment' | isAuthorized : 'PUT' | async) === true"
            [disabled]="row.isNotUnknowPatient() || row.isPast"
          >
            <mat-icon
              class="custom-icon-small baseline-align"
              (click)="resendSmsMail(row)"
              [ngClass]="{ 'mid-alpha': row.isPast }"
              color="primary"
            >
              mail
            </mat-icon>
          </button>
          <button
            mat-icon-button
            [ngClass]="{
              warn: !isAllServices,
              medium: isAllServices,
              'custom-small-btn': true
            }"
            matTooltip="{{ 'btn.editAppointment' | translate }}"
            aria-label="icon button with a pencil icon"
            [matTooltipDisabled]="isAllServices"
            *ngIf="('appointment' | isAuthorized : 'PUT' | async) === true"
            (click)="update(row)"
            [disabled]="row.isPast || isAllServices"
          >
            <mat-icon
              class="custom-icon-small baseline-align"
              [ngClass]="{ 'mid-alpha': row.isPast }"
              matTooltip="{{ 'btn.disabledWithAll' | translate }}"
              [matTooltipDisabled]="!isAllServices"
            >
              edit</mat-icon
            >
          </button>
          <button
            class="custom-small-btn"
            mat-icon-button
            matTooltip="{{ 'btn.deleteAppointment' | translate }}"
            aria-label="icon button with a trash icon"
            *ngIf="('appointment' | isAuthorized : 'PUT' | async) === true"
            (click)="delete(row)"
          >
            <mat-icon class="custom-icon-small baseline-align fail">delete</mat-icon>
          </button>
          <span
            class="custom-small-btn"
            matTooltip="{{ (has2FA || skip2FA ? 'btn.startVideoCall' : 'mfa.need2FAForTeleconsultation') | translate }}"
          >
            <button
              class="custom-small-btn"
              mat-icon-button
              aria-label="icon button with a videocam icon"
              (click)="onStartVideoCall(row)"
              [disabled]="row.isPast || (!has2FA && !skip2FA)"
            >
              <mat-icon class="custom-icon-small baseline-align success" [ngClass]="{ 'mid-alpha': row.isPast || (!has2FA && !skip2FA) }">
                videocam
              </mat-icon>
            </button>
          </span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      class="borderBox"
      #paginator
      [pageIndex]="0"
      [pageSize]="currentPageSize ? currentPageSize : 5"
      [pageSizeOptions]="[5, 10, 15]"
      [hidePageSize]="false"
    >
    </mat-paginator>
  </mat-card>
  <p class="text-center" *ngIf="allAppointements.length === 0">
    {{ "widget.noData" | translate }}
  </p>
</div>
