import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { ERROR_MSG } from "src/app/models/app-error.interface";
import { FormPage } from "src/app/pages/formPage";
import { PractitionerService } from "src/app/providers/practitioner.service";
import Validation from "../init-password/password-match.validation";

@Component({
  selector: "app-reset-own-password",
  templateUrl: "./reset-own-password.component.html",
  styleUrls: ["./reset-own-password.component.scss"],
})
export class ResetOwnPasswordComponent extends FormPage {
  public resetOwnPasswordForm = this.fb.group(
    {
      oldPassword: [undefined, [Validators.required]],
      newPasswordA: [undefined, [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,}$/)]],
      newPasswordB: [undefined, [Validators.required]],
    },
    {
      validators: [Validation.match("newPasswordA", "newPasswordB")],
    }
  );
  public passwordError: string;
  public expiredPassword = false;

  constructor(
    private fb: UntypedFormBuilder,
    private practitionerService: PractitionerService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ResetOwnPasswordComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      login: string;
      lang: string;
      expiredPassword?: boolean;
    }
  ) {
    super();
    if (this.data?.expiredPassword) {
      this.expiredPassword = this.data.expiredPassword;
    }
  }

  public getForm(): UntypedFormGroup {
    return this.resetOwnPasswordForm;
  }

  public saveNewPassword(): void {
    if (
      this.resetOwnPasswordForm.get("oldPassword").value &&
      this.resetOwnPasswordForm.get("newPasswordA").value &&
      this.resetOwnPasswordForm.get("newPasswordB").value &&
      this.resetOwnPasswordForm.valid
    ) {
      this.practitionerService
        .resetOwnPassword(
          this.data.login,
          this.resetOwnPasswordForm.get("oldPassword").value,
          this.resetOwnPasswordForm.get("newPasswordA").value
        )
        .subscribe(
          () => {
            this.dialogRef.close();
            this.snackBar.open(this.translateService.instant("page.login.pwdChangeSuccess"), "ok", { duration: 5000 });
          },
          (err) => {
            if (err.message === ERROR_MSG.INVALID_PASSWORD) {
              if (err.additionalData) {
                this.passwordError = `${err.additionalData}.${this.data.lang}`;
                return;
              }
            }
            if (err.message === ERROR_MSG.AUTHENTICATION_FAILED) {
              if (this.expiredPassword) {
                this.passwordError = this.translateService.instant("page.login.oldPwdOrLoginInvalid");
                return;
              } else {
                this.passwordError = this.translateService.instant("page.login.oldPwdInvalid");
                return;
              }
            }
            if (err.message === ERROR_MSG.TOO_MANY_FAIL_CONNECTION) {
              this.passwordError = this.translateService.instant("api.errors.TOO_MANY_FAIL_CONNECTION");
              return;
            }
          }
        );
    } else {
      return;
    }
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
