import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { Patient } from "src/app/models/patient.interface";
import { Reference } from "src/app/models/reference.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

export interface ActivityApiResponse {
  data: any;
}

@Injectable({
  providedIn: "root",
})
export class ActivityApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "PractitionnerActivityHistory", // Theme !
      ["practitioner/activity"], // Post route paths
      ["practitioner/activity"], // Get route paths
      [], // Update route paths
      [] // Delete route paths
    );
  }

  public log(patient: Patient): Observable<ActivityApiResponse> {
    const patientIdentifier = FHIRHelper.getMainIdentifier(patient);
    if (patientIdentifier) {
      const patientReference: Reference = {
        display: patientIdentifier.label,
        reference: patientIdentifier.value,
      };
      return this.api.post(this.createRoutes[0], {
        patientRef: patientReference,
      }) as Observable<ActivityApiResponse>;
    } else {
      throw Error("Cannot get main identifier of patient");
    }
  }

  public list() {
    return this.api.get(this.readRoutes[0]);
  }
}
