import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { DataType } from "src/app/models/filter.interface";
import { IObservationDefinition, ObservationResume } from "src/app/models/observations.interface";
import { TranslateObservationNamePipe } from "./patient-observations-pipes/translate-observation-name.pipe";

export class ObservationsListDataSource extends MatTableDataSourceExtended<ObservationResume> {
  private obsDef: IObservationDefinition[];
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  sortingDataAccessor = (data: ObservationResume, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "name":
        return this.translateObservationNamePipe.transform(data, this.obsDef);
      case "date":
        return moment(data.date).unix();
      case "value":
        return data.value;
      case "unit":
        return data.unit;
      default:
        return "";
    }
  };

  constructor(private translateObservationNamePipe: TranslateObservationNamePipe) {
    super();
    this.filterPredicate = (data: ObservationResume, _filter: string) => {
      return this.filterHelper?.filters.every((f) => {
        const propertyValue = f.propertyName
          .split(".")
          .reduce((p, c) => (p && p[c] !== undefined && ((p[c] instanceof Function && p[c]()) || p[c])) || null, data);

        // Zero can be a value for propertyValue (user status as exemple)
        if (propertyValue !== undefined && propertyValue !== null) {
          switch (f.dataType) {
            case DataType.TEXT:
              if (f.data && f.data.value) {
                return Tools.suppressDiacritics(this.translateObservationNamePipe.transform(data, this.obsDef))
                  .toLowerCase()
                  .includes(Tools.suppressDiacritics(f.data.value).toLowerCase());
              }
              break;
            case DataType.DATE:
              if (moment(propertyValue).isValid() && f.data && f.data.fromDate && f.data.toDate) {
                return (
                  moment(propertyValue).isSameOrAfter(f.data.fromDate, "D") && moment(propertyValue).isSameOrBefore(f.data.toDate, "D")
                );
              }
              break;
            default:
              return this.filtredPredictedDefault(f, propertyValue);
          }
          // if no value, we assume value don't fit in filter
        } else if (!propertyValue) {
          return false;
        }
        return true;
      });
    };
  }

  public loadData(activeObs: ObservationResume[], obsDef: IObservationDefinition[]): void {
    this.obsDef = obsDef;
    this.data = this.computeOneLineByObs(activeObs, obsDef);
  }

  /**
   * when widget is minified we only show one row per observation and we take the more recent value
   */
  public computeOneLineByObs(activeObs: ObservationResume[], obsDef: IObservationDefinition[]): ObservationResume[] {
    if (!obsDef || obsDef.length < 1) {
      return [];
    }
    // prepare array to save obs
    const mergedObs: ObservationResume[] = [];
    // get all codes available
    // component code are NOT UNIQUE
    // so if we do not want repetitive observation of the
    // same component, we need to use both the observation definition loinc code (which IS unique)
    // AND the compoenent loinc code.
    const loincCode: string[] = obsDef.reduce(
      (acc, def) => {
        if (def.components.length > 1) {
          acc = acc.concat(def.components.map((v) => def.loinc + "_" + v.loinc));
        }
        return acc;
      },
      obsDef.map((obs) => obs?.loinc)
    );

    // for each observation we test if exist in loinc code and we save it in array
    activeObs.forEach((o) => {
      // the parent observation loinc code which is needed to identifiy a component
      // because component's loinc code ARE NOT UNIQUE, has been previously stored
      // in parentReference (if it's not there, you need to fix it)
      let i = loincCode.indexOf(o.parentReference + "_" + o.reference);
      // And what do we do if we do not find the loinc code ?
      if (i < 0) {
        // we try using only the parent reference because it could be a definition without
        // components !
        i = loincCode.indexOf(o.parentReference);
        // But what do we do if we still do not find it ?
        if (i < 0) {
          // we get desperate and try with the component loinc only
          // hopefully, it will never get to this
          i = loincCode.indexOf(o.reference);
          if (i < 0) {
            // and if we still do not find it, just give up
            FileLogger.warn("ObservationsListDataSource", "We cannot find any definition for this observation: ", o);
          }
        }
      }
      // if no existing yet or if exist, we check if this observation is more recent
      if (i > -1 && (!mergedObs[i] || moment(mergedObs[i].date).isBefore(moment(o.date)))) {
        mergedObs[i] = o;
      }
    });
    // some index can be empty
    return mergedObs.filter((o) => o);
  }
}
