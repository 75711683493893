import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { QuestionnaireScoring } from "src/app/models/questionnaireScoring.model";

export class ScoreDetailsDataSource extends MatTableDataSourceExtended<QuestionnaireScoring> {
  sortingDataAccessor = (data: QuestionnaireScoring, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "name":
        return data.identifier.label.toLowerCase();
      case "value":
        return data.scoringValue;
      default:
        return "";
    }
  };

  constructor() {
    super();
  }

  public loadData(data: QuestionnaireScoring[]): void {
    this.data = data;
  }
}
