import { Component, Input, OnChanges, Pipe, PipeTransform, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ExportToCsv } from "export-to-csv";
import moment, { Moment } from "moment";
import { ObservationHelper } from "src/app/helpers/observationHelper";
import { CsvRow, IObservation, IObservationDefinition } from "src/app/models/observations.interface";
import { RULE_LEVEL } from "src/app/models/rule.interface";
import { ObservationsService } from "src/app/providers/observations.service";

@Pipe({ name: "getCurrentObservation" })
export class GetCurrentObservationPipe implements PipeTransform {
  transform(observations: IObservation[], loinc: string, date?: string): IObservation[] {
    let currentObservations = observations.filter((obs) => obs.code.coding[0].code === loinc);
    if (date) {
      currentObservations = currentObservations.filter((obs) => obs.effectiveTiming?.repeat.when.code === date);
    }
    return currentObservations;
  }
}

export interface ITableRow {
  date: Moment;
  observations: IObservation[];
}

export interface ITableData {
  headers: IObservationDefinition[];
  row: ITableRow[];
}

@Component({
  selector: "app-moment-observation-table",
  templateUrl: "./moment-observation-table.component.html",
  styleUrls: ["./moment-observation-table.component.scss"],
})
export class MomentObservationTableComponent implements OnChanges {
  @Input() obsDefinition: IObservationDefinition[];
  @Input() observations: IObservation[];

  public RULE_LEVEL = RULE_LEVEL;
  public tableData: ITableData;

  public options = {
    fieldSeparator: ";",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    headers: [],
    showTitle: false,
    title: "",
    useBom: true,
    removeNewLines: true,
    useKeysAsHeaders: false,
    filename: "observationByMoment",
  };

  constructor(public translateService: TranslateService, public observationService: ObservationsService) {}

  ngOnChanges(_changes: SimpleChanges): void {
    this.render();
  }

  public render(): void {
    this.tableData = {
      headers: this.obsDefinition,
      row: ObservationHelper.groupObservationsByDay(this.observations),
    };
  }

  public onExport(): void {
    const csvHeaders: string[] = [];
    const posIndex: string[] = [];
    csvHeaders.push(this.translateService.instant("table.date"));
    for (const header of this.tableData.headers) {
      let title = header.nameTranslation?.[this.translateService.currentLang];
      if (header.components?.length > 1) {
        title += " - " + header.components.map((comp) => comp.nameTranslation?.[this.translateService.currentLang]).join(" / ");
      }
      if (header.lsTimingWhen?.length) {
        for (const lsTimingWhen of header.lsTimingWhen) {
          csvHeaders.push(title + " [" + lsTimingWhen.name[this.translateService.currentLang] + "]");
          posIndex.push(header.loinc + "[" + lsTimingWhen.when.code + "]");
        }
      } else {
        csvHeaders.push(title);
        posIndex.push(header.loinc);
      }
    }
    this.options.headers = csvHeaders;

    const csvData: CsvRow[] = [];
    for (const row of this.tableData.row) {
      const line = {
        date: moment(row.date).format("DD/MM/YYYY"),
      };
      for (let i = 0; i < csvHeaders.length - 1; i++) {
        line["cell" + i] = "";
      }
      for (const observation of row.observations) {
        const search =
          observation.code.coding[0].code + (observation.effectiveTiming ? `[${observation.effectiveTiming?.repeat.when.code}]` : "");
        const index = posIndex.indexOf(search);
        if (line["cell" + index]?.length > 0) {
          line["cell" + index] += " - " + observation.component.map((comp) => comp.valueQuantity.value).join("/");
        } else {
          line["cell" + index] = observation.component.map((comp) => comp.valueQuantity.value).join("/");
        }
      }
      csvData.push(line);
    }
    new ExportToCsv(this.options).generateCsv(csvData);
  }

  public openAlertHistory(observation: IObservation): void {
    this.observationService.openAlertHistory(observation, this.obsDefinition);
  }
}
