import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IStepwiseDrug } from "src/app/models/stepwiseDrug.interface";
import { StepwiseDrug } from "src/app/models/stepwiseDrug.model";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class StepwiseDrugApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "stepwiseDrug", // Theme !
      ["dashboard/stepwiseDrug"], // Post route paths
      ["dashboard/stepwiseDrug"], // Get route paths
      ["dashboard/stepwiseDrug"], // Update route paths
      ["dashboard/stepwiseDrug"] // Delete route paths
    );
  }

  public list(): Observable<StepwiseDrug[]> {
    return this.api.get(this.readRoutes[0]).pipe(
      map((values: IStepwiseDrug[]) => {
        return values.map((v) => new StepwiseDrug(v));
      })
    );
  }

  public create(drugSchema: StepwiseDrug): Observable<StepwiseDrug> {
    return this.api.post(this.createRoutes[0], drugSchema).pipe(map((v: IStepwiseDrug) => new StepwiseDrug(v)));
  }

  public update(drugSchema: StepwiseDrug): Observable<StepwiseDrug> {
    return this.api.put(this.updateRoutes[0], drugSchema).pipe(map((v: IStepwiseDrug) => new StepwiseDrug(v)));
  }

  public delete(id: string): Observable<StepwiseDrug> {
    return this.api.delete(`${this.deleteRoutes[0]}/${id}`, {}) as Observable<StepwiseDrug>;
  }
}
