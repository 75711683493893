import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, of } from "rxjs";
import { delay, first, skipWhile } from "rxjs/operators";
import { AVAILABLE_ACTIONS } from "src/app/careplan-editor/data/careplan-editor.enum";
import { IlinkableVitalProfileItem } from "src/app/careplan-editor/domain/IlinkableItem.interface";
import { CareplanEditorService } from "src/app/careplan-editor/domain/careplan-editor.service";
import { LinkableVitalProfileItem } from "src/app/careplan-editor/domain/linkable-item.model";
import { ACTION_TARGET } from "src/app/models/careplans.interface";
import { CareplansService } from "src/app/providers/careplans.service";

@Component({
  selector: "app-careplan-editor-vital-signs-tab",
  templateUrl: "./careplan-editor-vital-signs-tab.component.html",
  styleUrls: ["./careplan-editor-vital-signs-tab.component.scss"],
})
export class CareplanEditorVitalSignsTabComponent implements OnInit, OnDestroy {
  public columns = ["name", "unit"]; // "code" can be added if needed
  public actions = [AVAILABLE_ACTIONS.unlink];

  /** All available vitalProfilesDefintions to choose from (source table) */
  public availableVitalProfiles: IlinkableVitalProfileItem[];
  /** vitalProfile actually linked to the careplan (target table) */
  public vitalProfiles: IlinkableVitalProfileItem[];

  constructor(
    private careplanService: CareplansService,
    private translateService: TranslateService,
    public careplanEditorService: CareplanEditorService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.setupVitalProfiles();
  }
  ngOnDestroy(): void {
    // After publish careplanTemplateReady$ value is false to avoid trying to save a careplan without draft
    if (this.careplanEditorService.careplanTemplateReady$.value === true) {
      this.careplanEditorService.save(false, false, false);
    }
  }

  public setupVitalProfiles(): void {
    // Observable to get all vital profile definitions
    const getAllVitalProfileDefinitions$ = this.careplanService.getAllVitalProfileDefinitions().pipe(first());

    // Observable to check if the careplanTemplate is ready
    const careplanTemplateReady$ = this.careplanEditorService.currentCareplanTemplate
      ? of(true)
      : this.careplanEditorService.careplanTemplateReady$.pipe(
          skipWhile((value) => value === false),
          first()
        );

    // Observable to check if the link2Careplan is ready
    const link2CareplanReady$ = this.careplanEditorService.link2CareplanForm.value
      ? of(true)
      : this.careplanEditorService.link2CareplanReady$.pipe(first());

    // allow to show loader at least 500ms to avoid glitch
    const timeout$ = of(true).pipe(delay(500));

    // Combine observables to ensure they are ready before proceeding
    combineLatest([getAllVitalProfileDefinitions$, careplanTemplateReady$, link2CareplanReady$, timeout$])
      .pipe(first())
      .subscribe(([vpd]) => {
        // Map vital profile definitions to match the columns definitions
        this.availableVitalProfiles = vpd.map(
          (vp) =>
            new LinkableVitalProfileItem({
              uniqueId: vp.code,
              name: vp.nameDashboard ? vp.nameDashboard[this.translateService.currentLang] : null,
              code: vp.code,
              unit: vp.unit ? vp.unit[this.translateService.currentLang] : null,
              itemType: ACTION_TARGET.VITAL_PROFILE,
            })
        );

        // Filter vital profiles based on link to careplan data
        const link2Careplan = this.careplanEditorService.link2CareplanForm.value;
        this.vitalProfiles = this.availableVitalProfiles.filter((vp) => {
          return link2Careplan.vitalSignsDefinitions?.some((x) => x === vp.code);
        });
      });
  }

  public async updateForm($event: { item: IlinkableVitalProfileItem; items: IlinkableVitalProfileItem[] }): Promise<void> {
    this.careplanEditorService.link2CareplanForm.patchValue({ vitalSignsDefinitions: $event.items.map((vp) => vp.code) });
    try {
      await this.careplanService.updateLink2Careplan(this.careplanEditorService.link2CareplanForm.getRawValue()).toPromise();
    } catch (error) {
      this.snackBar.open(this.translateService.instant("api.errors.server-error"), "ok", { duration: 5000 });
      // reload the vitalProfiles list in case of error
      this.setupVitalProfiles();
    }
  }
}
