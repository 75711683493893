<section class="properties-group">
  <h3>{{ "page.questionnaireEditor.properties.time" | translate }} :</h3>
  <div class="sideBySideFields">
    <div class="sideElem">
      <mat-form-field>
        <mat-label>{{ "page.questionnaireEditor.properties.min" | translate }}</mat-label>
        <input
          matInput
          class="pointer"
          [ngxTimepicker]="minTimePicker"
          [format]="24"
          [(ngModel)]="minTime"
          [disabled]="disabled"
          readonly
        />
        <ngx-material-timepicker #minTimePicker (closed)="onTimeChange()"></ngx-material-timepicker>
      </mat-form-field>
    </div>
    <div class="sideElem">
      <mat-form-field>
        <mat-label>{{ "page.questionnaireEditor.properties.max" | translate }}</mat-label>
        <input
          matInput
          class="pointer"
          [ngxTimepicker]="maxTimePicker"
          [format]="24"
          [(ngModel)]="maxTime"
          [disabled]="disabled"
          readonly
        />
        <ngx-material-timepicker #maxTimePicker (closed)="onTimeChange()"></ngx-material-timepicker>
      </mat-form-field>
    </div>
  </div>
</section>
