import { Identifier } from "./identifier.interface";
import { IRewardDefinition, IRewardPonderation } from "./rewardDefinition.interface";
import { ITranslation } from "./translation.interface";

export class RewardDefinition {
  public careplanIds: string[]; // [CAREPLAN_MM, CAREPLAN_BPCO, ...]
  public identifier: Identifier[];
  public rewardScore: number;
  public rewardDuration: {
    duration: number;
    granularity: string; // (ex: '1 month', '7 days', '7 d', '1 m', ...)
  };
  public rewardPonderation: IRewardPonderation[];
  public rewardDisplayName: ITranslation;
  public rewardDescription: ITranslation;
  public dashboardRewardText: ITranslation;
  public patientRewardText: ITranslation;

  constructor(data: IRewardDefinition) {
    this.careplanIds = data.careplanIds;
    this.identifier = data.identifier;
    this.rewardScore = data.rewardScore;
    this.rewardDuration = data.rewardDuration;
    this.rewardPonderation = data.rewardPonderation;
    this.rewardDisplayName = data.rewardDisplayName;
    this.rewardDescription = data.rewardDescription;
    this.dashboardRewardText = data.dashboardRewardText;
    this.patientRewardText = data.patientRewardText;
  }
}
