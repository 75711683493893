import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { ITranslation } from "../models/translation.interface";
import { LanguagesApiService } from "./api/languages-api.service";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class LanguagesService {
  private listLanguages: ITranslation[] = [];

  constructor(private api: LanguagesApiService, private translateService: TranslateService, private sessionService: SessionService) {}

  public list(): Observable<ITranslation[]> {
    if (this.listLanguages.length) {
      return of(this.listLanguages);
    }

    return this.api.list().pipe(
      tap((languages) => {
        this.listLanguages = languages;
      })
    );
  }

  /**
   * Return the user language from the localStorage if existing or the browser language. If browser language not supported, return the default language
   */
  public get userLangOrBrowser(): string {
    let lang = localStorage.getItem("lang");

    if (!lang) {
      lang = this.translateService.getBrowserLang();
      if (!this.translateService.getLangs().includes(lang)) {
        lang = this.sessionService.DEFAULT_LANG;
      }
    }
    return lang;
  }
}
