import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Account, IEthnicGroup } from "src/app/models/account.interface";
import { IHealthcareservice } from "src/app/models/healthcareservice";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class UserApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "User", // Theme !
      ["account"], // Post route paths
      ["users", "me", "service"], // Get route paths
      [], // Put route paths
      [] // Delete route paths
    );
  }

  public list(): Observable<Account[]> {
    return this.api.get(this.readRoutes[0]) as Observable<Account[]>;
  }

  public me(): Observable<Account> {
    return this.api.get(this.readRoutes[1]) as Observable<Account>;
  }

  public updateAccount(account: Account): Observable<Record<string, never>> {
    return this.api.post(this.createRoutes[0], account) as Observable<Record<string, never>>;
  }

  public getMyService(): Observable<IHealthcareservice> {
    return this.api.get(this.readRoutes[2]) as Observable<IHealthcareservice>;
  }

  public getAllEthnicGroups(): Observable<IEthnicGroup[]> {
    return this.api.get("dashboard/ethnicGroups") as Observable<IEthnicGroup[]>;
  }
}
