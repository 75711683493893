import { Pipe, PipeTransform } from "@angular/core";
import { Coding } from "src/app/models/coding.interface";
import { ObservationResume } from "src/app/models/observations.interface";
import { Observation } from "src/app/models/observations.model";

@Pipe({ name: "isBluetoothObsComp" })
export class IsBluetoothObsCompPipe implements PipeTransform {
  transform(obs: Observation | ObservationResume, code?: Coding[]): boolean {
    if (obs?.device?.reference && obs?.device?.componentAnswer?.length > 0) {
      return (
        obs.device.componentAnswer.findIndex((c) => {
          return c.loinc === (code ? code[0].code : (obs as ObservationResume).reference);
        }) !== -1
      );
    } else {
      return false;
    }
  }
}
