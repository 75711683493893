import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-error-on-getting-caremate-id",
  templateUrl: "./error-on-getting-caremate-id.component.html",
  styleUrls: ["./error-on-getting-caremate-id.component.scss"],
})
export class ErrorOnGettingCaremateIDComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { currentUser: { name: string; firstname: string }; preference?: boolean },
    private dialogRef: MatDialogRef<ErrorOnGettingCaremateIDComponent>
  ) {}

  public closeModal(): void {
    this.dialogRef.close();
  }
}
