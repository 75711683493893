import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Tools } from "src/app/helpers/tools";
import { ACTION_TARGET } from "src/app/models/careplans.interface";
import { TIMING_CODES } from "src/app/models/timingData.model";

@Component({
  selector: "app-when-input",
  templateUrl: "./when-input.component.html",
  styleUrls: ["./when-input.component.scss"],
})
export class WhenInputComponent implements OnChanges {
  public availablePeriodUnits = [
    {
      display: this.translateService.instant("periodUnit.day.singPlural"),
      value: "d",
    },
    {
      display: this.translateService.instant("periodUnit.week.singPlural"),
      value: "w",
    },
    {
      display: this.translateService.instant("periodUnit.month.singPlural"),
      value: "M", // Here it has to be M cause m stands for minute
    },
    {
      display: this.translateService.instant("periodUnit.year.singPlural"),
      value: "y",
    },
  ];

  public periodDisplayed;

  @Input() period;
  @Output() periodChange = new EventEmitter<unknown>();

  @Input() periodUnit: string;
  @Output() periodUnitChange = new EventEmitter<string>();

  @Input() showDirectly?: boolean;
  @Output() showDirectlyChange = new EventEmitter<boolean>();

  @Input() timingCode?: string;
  @Output() timingCodeChange = new EventEmitter<string>();

  @Input() skipFirst: boolean;
  @Output() skipFirstChange = new EventEmitter<boolean>();

  @Input() isDisabled: boolean;
  @Input() hasSelectedActivity: boolean;
  @Input() needShowDirectly = true;
  @Input() needSkipFirst;

  @Input() context?: "careplanEndDate" | ACTION_TARGET | undefined;
  @Input() onlyAfter = false;

  public beforeAfter = "after";
  public whenInputForm: UntypedFormGroup = this.fb.group({});
  public availableTimingCodes = TIMING_CODES;
  public action_target = ACTION_TARGET;

  constructor(private translateService: TranslateService, private fb: UntypedFormBuilder) {}

  ngOnChanges(): void {
    if (this.period < 0 && !this.onlyAfter) {
      this.beforeAfter = "before";
    } else {
      this.beforeAfter = "after";
    }

    this.periodDisplayed = Math.abs(this.period);
    if (this.isDisabled) {
      this.showDirectly = true;
    }
  }

  public setPeriod(): void {
    this.periodChange.emit(this.beforeAfter === "before" ? this.periodDisplayed * -1 : this.periodDisplayed);
  }

  public setPeriodUnit(): void {
    this.periodUnitChange.emit(this.periodUnit);
  }

  public setTimingCode(): void {
    this.timingCodeChange.emit(this.timingCode);
  }

  public setShowDirectly(): void {
    this.showDirectlyChange.emit(this.showDirectly);
  }

  public setSkipFirst(event: boolean): void {
    this.skipFirst = event;
    this.skipFirstChange.emit(this.skipFirst);
  }

  public compareFn(o1: never, o2: never): boolean {
    return Tools.standardizePeriodUnit(o1) === Tools.standardizePeriodUnit(o2);
  }
}
