import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DndFilesDirective } from "./dnd-files.directive";

@NgModule({
  declarations: [DndFilesDirective],
  imports: [CommonModule],
  exports: [DndFilesDirective],
})
export class DndFilesModule {}
