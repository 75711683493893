import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { Tools } from "src/app/helpers/tools";
import { IRewardScore, IRewardScoreExtended } from "src/app/models/rewardScore.interface";
import { RewardScore } from "src/app/models/rewardScore.model";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class RewardScoreApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "RewardScore", // Theme !
      [], // Post route paths
      ["rewardScoresByPatient", "rewardScoresByService"], // Get route paths
      ["rewardScore"], // Put route paths
      [] // Delete route paths
    );
  }

  public listRewardScoresByPatient(
    patientId: string,
    startDate: string,
    endDate: string,
    onlyInProgress: boolean
  ): Observable<IRewardScore[]> {
    return this.api.get(this.readRoutes[0], {
      patientId,
      startDate,
      endDate,
      onlyInProgress,
    }) as Observable<IRewardScore[]>;
  }

  public listRewardScoresByService(
    serviceId: string,
    startDate: string,
    endDate: string,
    onlyInProgress: boolean,
    services?: string[],
    monitoringServicesIds?: string[]
  ): Observable<IRewardScoreExtended[]> {
    const params: any = {
      serviceId,
      startDate,
      endDate,
      onlyInProgress,
    };
    if (Tools.isDefined(services) && !ArrayHelper.isEmpty(services)) {
      params.services = JSON.stringify(services);
    }
    if (Tools.isDefined(monitoringServicesIds) && !ArrayHelper.isEmpty(monitoringServicesIds)) {
      params.monitoringServicesIds = monitoringServicesIds.join();
    }
    return this.api.get(this.readRoutes[1], params) as Observable<IRewardScoreExtended[]>;
  }

  public updateRewardScore(rewardScore: RewardScore): Observable<RewardScore> {
    return this.api.put(this.updateRoutes[0], rewardScore) as Observable<RewardScore>;
  }
}
