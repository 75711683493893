<div class="flex-row">
  <h2>{{ "page.careplanEditor.tabs.general.name" | translate }}</h2>
  <button mat-flat-button matTooltip="{{ 'btn.import' | translate }}" (click)="selectFile()">
    {{ "btn.import" | translate }}
    <input type="file" style="display: none" accept="application/json" size="5000000" (change)="import($event)" #input />
  </button>
</div>
<div class="container" id="cpEditor-generalContainer">
  <div class="selector">
    <button
      *ngFor="let view of views | EnumToArray : 'string'"
      mat-button
      [routerLink]="'/careplanEditor/' + this.careplanTemplate?.support[0].reference + '/general/' + view"
      (click)="currentView = view"
      [color]="currentView === view ? 'primary' : undefined"
      [disabled]="('/careplanEditor/:id/general/' + view | isAuthorized | async) === false"
      [matTooltip]="
        (('/careplanEditor/:id/general/' + view | isAuthorized | async) === false
          ? 'common.notAuthorized'
          : 'page.careplanEditor.' + view + 'Tooltip'
        ) | translate
      "
    >
      {{ "page.careplanEditor." + view | translate }}
    </button>
  </div>
  <mat-card>
    <mat-card-content>
      <router-outlet></router-outlet>
    </mat-card-content>
  </mat-card>
</div>
