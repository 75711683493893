<form [formGroup]="groupForm">
  <div class="close-cross">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <h2 mat-dialog-title *ngIf="isCreation">
    {{ "group.createGroupTitle" | translate }}
  </h2>
  <h2 mat-dialog-title *ngIf="!isCreation">
    {{ "group.editGroupTitle" | translate }}
  </h2>
  <mat-dialog-content>
    <mat-form-field class="font-12">
      <input cdkFocusInitial type="text" matInput placeholder="{{ 'group.name' | translate }}" formControlName="name" autocomplete="off" />
    </mat-form-field>
    <mat-checkbox formControlName="isChecked" class="checkbox">{{ "group.privateGroup" | translate }}</mat-checkbox>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button *ngIf="isCreation" color="primary" (click)="onSave()">
      {{ "group.createGroup" | translate }}
    </button>
    <button mat-raised-button *ngIf="!isCreation" color="primary" (click)="onSave()">
      {{ "group.editGroup" | translate }}
    </button>
  </mat-dialog-actions>
</form>
