import { Account } from "../models/account.interface";
import { TranslateService } from "@ngx-translate/core";
import { SessionService } from "../providers/session.service";
import { NgModule } from "@angular/core";

@NgModule()
export class UserHelper {
  constructor(private translateService: TranslateService, private sessionService: SessionService) {}

  public static getOrganisation(account: Account): string {
    return account?.managingOrganizations[0]?.display;
  }

  public static getHealthcareService(account: Account): string {
    return account?.healthcareService[0]?.display;
  }
}
