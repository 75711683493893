import { Codes } from "./codes.interface";
import { Identifier } from "./identifier.interface";
import { Reference } from "./reference.interface";
import { IEntity } from "./sharedInterfaces";
import { ITranslation } from "./translation.interface";
export interface CommunicationPreference {
  preferred: boolean;
  language: Codes;
}
export interface IAttachment {
  contentType: string;
  data: string;
  title: string;
  creation: Date;
  url?: string;
  size?: number;
  hash?: string;
}

export interface IPayload {
  contentAttachment?: IAttachment;
  contentString?: string;
  contentReference?: Reference;
}

export enum STATUS_CODE {
  PREPARATION = "preparation", // => draft
  IN_PROGRESS = "in-progress", // => not used as far as I can tell
  NOT_DONE = "not-done", // => not used as far as I can tell
  SUSPENDED = "suspended", // => not used as far as I can tell
  ABORTED = "aborted", // => only used in the unit tests... ?
  ENTERED_IN_ERROR = "entered-in-error", // => not used as far as I can tell
  COMPLETED = "completed", // => sent but not received
  DELIVERED = "delivred", // => received
  OPENED = "opened",
}
export interface ISimplifiedCommunication {
  sender: Reference;
  subjects: Reference[];
  group: Reference;
  topic?: string;
  topicTranslation?: ITranslation;
  content?: string;
  contentTranslation?: ITranslation;
  attachments: IPayload[];
  healthservice: Reference;
  status: STATUS_CODE;
}

export enum IPayloadType {
  PDF = "application/pdf",
  JPG = "image/jpeg",
  TIFF = "image/tiff",
  PNG = "image/png",
}

export interface ICommunication extends IEntity {
  resourceType: string;
  identifier: Identifier[];
  basedOn: Reference[];
  partOf: Reference[];
  inResponseTo: Reference[];
  status: STATUS_CODE;
  deletedByReceiver?: boolean;
  statusReason: Codes;
  category: Codes; // this does not respect FHIR (should be []) because of bug (see CMATE-2995)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  priority: any;
  medium: Codes; // this does not respect FHIR (should be []) because of bug (see CMATE-2995)
  subject: Reference;
  topic: Codes;
  about: Reference[];
  encounter: Reference[];
  sent: Date;
  received: Date;
  recipient: Reference[];
  sender: Reference;
  reasonCode: Codes; // this does not respect FHIR (should be []) because of bug (see CMATE-2995)
  reasonReference: Reference[];
  payload: IPayload[];
  payloadCount?: number;
  note: IAnnotation[];
  keepAttachment?: boolean;
  records: string[][];
  healthservice?: Reference;
  group?: Reference;
}

export interface IAnnotation {
  authorReference: Reference;
  authorString: string;
  time: Date;
  text: string;
}

export interface IInfoCommunication {
  identifier: Identifier[];
  sender: Reference;
  subject: Reference;
  group?: Reference;
  topic: string;
  content: string;
  healthservice: Reference;
  sent: Date;
  received: Date;
  payloadCount: number;
  status: STATUS_CODE;
  basedOn?: Reference[];
}

export enum Opened_Status {
  OPENED = 1,
  PARTLY_OPENED = 2,
  SENT = 3,
  DRAFT = 4,
  RECEIVED = 5,
  PARTLY_RECEIVED = 6,
}
