import { EnableWhenBehavior, IEnableWhen } from "../models/sharedInterfaces";
import { Tools } from "./tools";

export class EnableWhenHelper {
  /**
   * Checks if each enableWhen is enabled by his respective values.
   * If there's not the same number of enableWhen has values, it returns false.
   * @param enableWhen
   * @param values
   * @param behavior
   */
  public static areEnabledByEach(
    enableWhen: IEnableWhen[],
    values: number[] | string[] | undefined[],
    behavior: EnableWhenBehavior = EnableWhenBehavior.AND
  ): boolean {
    if (enableWhen.length !== values.length) {
      return false;
    }
    for (let i = 0; i < enableWhen.length; ++i) {
      const ew = enableWhen[i];
      const v = values[i];
      const isEnabled = EnableWhenHelper.computeIsEnabled(ew, v);
      const behaviorCheck = this.checkBehavior(isEnabled, behavior);
      if (Tools.isDefined(behaviorCheck)) {
        return behaviorCheck;
      }
    }
    if (behavior === EnableWhenBehavior.OR) {
      return false;
    }
    if (behavior === EnableWhenBehavior.AND) {
      return true;
    }
  }

  /**
   * Checks if the enableWhens are enabled by the value (same value for all)
   * @param enableWhen
   * @param value
   * @param behavior
   */
  public static areEnabledBySame(
    enableWhen: IEnableWhen[],
    value: number | string | undefined,
    behavior: EnableWhenBehavior = EnableWhenBehavior.AND
  ): boolean {
    for (const ew of enableWhen) {
      const isEnabled = EnableWhenHelper.computeIsEnabled(ew, value);
      const behaviorCheck = this.checkBehavior(isEnabled, behavior);
      if (Tools.isDefined(behaviorCheck)) {
        return behaviorCheck;
      }
    }
    if (behavior === EnableWhenBehavior.OR) {
      return false;
    }
    if (behavior === EnableWhenBehavior.AND) {
      return true;
    }
  }

  public static checkBehavior(isEnabled: boolean, behavior: EnableWhenBehavior = EnableWhenBehavior.AND): boolean | null {
    if (behavior === EnableWhenBehavior.OR && isEnabled) {
      return true;
    }
    if (behavior === EnableWhenBehavior.AND && !isEnabled) {
      return false;
    }
    return null;
  }

  /**
   * @param enableWhen
   * @param value
   * @returns if value is undefined, return false. if value is defined, returns
   *  - true if the enableWhen is verified
   *  - false otherwise
   */
  public static computeIsEnabled(enableWhen: IEnableWhen, value: number | string | undefined): boolean {
    if (!Tools.isDefined(value)) {
      return false;
    }
    const type = enableWhen.type;
    let v = value;
    let expectedResponse = enableWhen.answer;

    switch (enableWhen.operator) {
      case ">":
        [v, expectedResponse] = this.formatValues(v, expectedResponse, type);
        return v > expectedResponse;
      case "<":
        [v, expectedResponse] = this.formatValues(v, expectedResponse, type);
        return v < expectedResponse;
      case "!=":
        // we must use the string form in case it's a list of values:
        [v, expectedResponse] = this.formatValues(v, expectedResponse, "string");
        return !this.answerStringCorrect(v as string, expectedResponse as string);
      case "=":
        // we must use the string form in case it's a list of values:
        [v, expectedResponse] = this.formatValues(v, expectedResponse, "string");
        return this.answerStringCorrect(v as string, expectedResponse as string);
      case ">=":
        [v, expectedResponse] = this.formatValues(v, expectedResponse, type);
        return v >= expectedResponse;
      case "<=":
        [v, expectedResponse] = this.formatValues(v, expectedResponse, type);
        return v <= expectedResponse;
    }
  }

  private static formatValues(a: string | number | undefined, b: string | number | undefined, type: string): (string | number)[] {
    switch (type) {
      case "number":
        a = Number(a);
        b = Number(b);
        break;
      case "string":
        a = String(a);
        b = String(b);
        break;
      default:
        break;
    }
    return [a, b];
  }

  /**
   * Check if a string answer corresponds to the expected answer.
   * Takes into account the fact that a string answer can represent a
   * checkbox selection (several answers separated by a ',') and check
   * if the answer corresponds to one of the checkbox's answers
   * @param answer (string) the answer
   * @param expectedAnswer (string) the expected answer
   * @returns whether or not the answer corresponds to the expected one
   */
  private static answerStringCorrect(answer: string, expectedAnswer: string): boolean {
    if (answer === expectedAnswer) {
      return true;
    }
    // paragraph symbol to reduce the risk of the answer legitimately containing it:
    const splitAnswer = answer.split("§");
    for (const a of splitAnswer) {
      if (a.trim() === expectedAnswer.trim()) {
        return true;
      }
    }
    return false;
  }

  /**
   * check if the arg is of type "IEnableWhen[]"
   * @param enableWhen
   */
  public static isEnableWhen(enableWhen: any): enableWhen is IEnableWhen[] {
    if (Array.isArray(enableWhen)) {
      const elemArray = [];
      for (const elem of enableWhen) {
        if (elem.operator && elem.question && elem.type && Tools.isDefined(elem.answer)) {
          elemArray.push(true);
        } else {
          elemArray.push(false);
        }
      }
      const trueChecker = (currentValue: boolean) => currentValue === true;
      return elemArray.every(trueChecker);
    } else {
      return false;
    }
  }
}
