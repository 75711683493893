import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatExpansionPanel } from "@angular/material/expansion";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil, tap } from "rxjs/operators";
import { defaultLangValidator } from "src/app/careplan-editor/domain/careplan-template-form-helper";
import { ICareplan } from "src/app/models/careplans.interface";
import { ITranslation } from "src/app/models/translation.interface";

@Component({
  selector: "app-traduction-form",
  templateUrl: "./traduction-form.component.html",
  styleUrls: ["./traduction-form.component.scss"],
})
export class TraductionFormComponent implements OnDestroy, OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() traductionsFormArray: UntypedFormArray;
  @Input() availableLangs: ITranslation[];
  @Input() placeholder: string;
  @Input() careplanTemplate: ICareplan;
  @Output() keystroke: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(MatExpansionPanel) panel: MatExpansionPanel;

  private onDestroy$ = new Subject<void>();

  public ngOnInit(): void {
    // Add validator to check that the default language has a translation
    setTimeout(() => {
      this.formGroup.addValidators(defaultLangValidator());
    });

    // Detect keystrokes in order to trigger the refresh of the pipe getActivityName()
    this.formGroup.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(150),
        distinctUntilChanged(),
        tap((value) => {
          this.keystroke.emit(value.traductions[0].translateKey);
        })
      )
      .subscribe();
  }
  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Add a traduction (in a language not already set) for form field
   */
  public addTraduction(): void {
    const notAlreadyUsedLang = this.availableLangs.filter((l) => {
      const langs: string[] = this.traductionsFormArray.value.map((d) => d.lang);
      return !langs.includes(l.term);
    });
    this.traductionsFormArray.push(
      new UntypedFormGroup({
        translateKey: new UntypedFormControl("", Validators.required),
        lang: new UntypedFormControl(notAlreadyUsedLang[0]?.term),
      })
    );
  }

  /**
   * remove a traduction from form field
   */
  public deleteTraduction(index: number): void {
    this.traductionsFormArray.removeAt(index);
  }

  public openPanel(): void {
    setTimeout(() => {
      if (this.panel) {
        this.panel.open();
      }
    });
  }
}
