import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-display-pictures",
  templateUrl: "./display-pictures.component.html",
  styleUrls: ["./display-pictures.component.scss"],
})
export class DisplayPicturesComponent implements OnInit {
  public pictures: string[];
  public currentIndex = 0;
  public previous: {
    title: string;
    pictures: string[];
    issued: string;
  };
  public next: {
    title: string;
    pictures: string[];
    issued: string;
  };
  public compare: {
    title: string;
    pictures: string[];
    issued: string;
  };
  public compareCurrentIndex = 0;
  public title: string;
  public isAllDisplay = false;
  public isCompare = false;
  public viewerOptions = {
    navbar: false,
    title: false,
    toolbar: {
      zoomIn: {
        show: 1,
        size: "large",
      },
      zoomOut: {
        show: 1,
        size: "large",
      },
      oneToOne: {
        show: 1,
        size: "large",
      },
      reset: {
        show: 1,
        size: "large",
      },
      prev: 0,
      play: 0,
      next: 0,
      rotateLeft: {
        show: 1,
        size: "large",
      },
      rotateRight: {
        show: 1,
        size: "large",
      },
      flipHorizontal: {
        show: 1,
        size: "large",
      },
      flipVertical: {
        show: 1,
        size: "large",
      },
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      pictures: string[];
      title: string;
      previous: {
        title: string;
        pictures: string[];
        issued: string;
      };
      next: {
        title: string;
        pictures: string[];
        issued: string;
      };
    }
  ) {}

  ngOnInit(): void {
    this.pictures = this.data.pictures;
    this.title = this.data.title;
    this.previous = this.data.previous;
    this.next = this.data.next;
  }

  public arrowClicked(isForward: boolean, isCompare = false): void {
    if (isCompare) {
      if (isForward) {
        if (this.compareCurrentIndex < this.compare.pictures.length - 1) {
          this.compareCurrentIndex++;
        } else {
          this.compareCurrentIndex = 0;
        }
      } else {
        if (this.compareCurrentIndex === 0) {
          this.compareCurrentIndex = this.compare.pictures.length - 1;
        } else {
          this.compareCurrentIndex--;
        }
      }
    } else {
      if (isForward) {
        if (this.currentIndex < this.pictures.length - 1) {
          this.currentIndex++;
        } else {
          this.currentIndex = 0;
        }
      } else {
        if (this.currentIndex === 0) {
          this.currentIndex = this.pictures.length - 1;
        } else {
          this.currentIndex--;
        }
      }
    }
  }

  public dotClicked(index: number, isPrevious = false): void {
    if (isPrevious) {
      this.compareCurrentIndex = index;
    } else {
      this.currentIndex = index;
    }
  }

  public compareClick(isNext = false): void {
    this.isCompare = !this.isCompare;
    if (this.isCompare) {
      this.compare = isNext ? this.next : this.previous;
    } else {
      this.compare = null;
    }
  }
}
