import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import moment from "moment";
import { Observable, Subject, of } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil } from "rxjs/operators";
import { CustomErrorStateMatcher } from "src/app/helpers/formValidators";
import { Tools } from "src/app/helpers/tools";
import { MODIFICATION_TYPE } from "src/app/models/patient.interface";
import { IReference } from "src/app/models/sharedInterfaces";
import { TownsService } from "src/app/providers/towns.service";

@Component({
  selector: "app-town-server-side-search",
  templateUrl: "./town-server-side-search.component.html",
  styleUrls: ["./town-server-side-search.component.scss"],
})
export class TownServerSideSearchComponent implements OnInit, OnDestroy {
  constructor(private townsService: TownsService) {}

  @Input() formCtrl: UntypedFormControl;
  @Input() date: Date;
  @Input() disabled: boolean;
  @Input() isRequired: boolean;
  @Output() valueChange = new EventEmitter<MODIFICATION_TYPE>();
  @ViewChild("input") input: ElementRef;

  public townServerSideFilteringCtrl: UntypedFormControl = new UntypedFormControl(undefined);
  public filteredServerSideTown: Observable<IReference[]>;
  public matcher: CustomErrorStateMatcher;

  /** Subject that emits when the component has been destroyed. */
  protected onDestroy$ = new Subject<void>();

  ngOnInit(): void {
    if (this.formCtrl.value) {
      this.townServerSideFilteringCtrl = new UntypedFormControl(this.formCtrl.value);
      if (this.disabled) {
        this.townServerSideFilteringCtrl.disable({ emitEvent: false, onlySelf: true });
      }
    }
    this.townServerSideFilteringCtrl?.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.valueChange.emit(MODIFICATION_TYPE.birthplace);
    });
    this.filteredServerSideTown = this.townServerSideFilteringCtrl.valueChanges.pipe(
      startWith(""),
      takeUntil(this.onDestroy$),
      debounceTime(500),
      distinctUntilChanged(),
      map((search: unknown) => {
        if (!search) {
          return "";
        }
        if (typeof search === "string") {
          return search;
        }
      }),
      switchMap((name: string) => (name?.length >= 1 ? this.getFrenchTown(name) : of(undefined)))
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * This method is written differently on purpose
   * It's to bind mat-autocomplete to this component or else we can't use "this" calls
   */
  townDisplay = (town: IReference): string => {
    return town ? town.display + " (" + town.reference + ")" : "";
  };

  onTownNameChanged(event: IReference): void {
    if (event?.reference) {
      this.formCtrl.setValue(event, { emitEvent: false, onlySelf: true });
    } else if (Tools.isDefined(this.formCtrl.value)) {
      this.formCtrl.setValue(undefined, { emitEvent: false, onlySelf: true });
      this.input.nativeElement.value = "";
    }
  }

  public onBlur(): void {
    if (Tools.isString(this.townServerSideFilteringCtrl?.value)) {
      this.townServerSideFilteringCtrl.setValue(undefined, { emitEvent: false, onlySelf: true });
    }
  }

  public getFrenchTown(query: string): Observable<IReference[]> {
    return this.townsService.getTownsByName(moment(this.date).format(), query);
  }
}
