<div id="cpEditor-publicationDialog">
  <h1 mat-dialog-title>{{ "Publier le careplan" }}</h1>

  <mat-dialog-content>
    {{ "page.careplanEditor.warning.publish" | translate }}

    <mat-checkbox [(ngModel)]="updateCriteria" class="mt-1">
      {{ "knowledgebase.knowledgeCriteria.updateCareplans" | translate }}
    </mat-checkbox>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close color="primary" cdkFocusInitial>
      {{ "common.cancel" | translate }}
    </button>
    <button mat-button mat-dialog-close (click)="confirm()" id="cpEditor-publicationDialog-confirmBtn">
      {{ "page.careplanEditor.btn.publish" | translate }}
    </button>
  </mat-dialog-actions>
</div>
