import { Pipe, PipeTransform } from "@angular/core";
import { IKnowMedia } from "src/app/models/knowledge.interface";
import { Tools } from "../../../helpers/tools";

@Pipe({
  name: "compareMedia",
})
export class CompareMediaPipe implements PipeTransform {
  transform(media: IKnowMedia, array: IKnowMedia[], index: number): { isEqual: boolean; compareMedia: boolean } {
    const result = {
      isEqual: undefined,
      compareMedia: undefined,
    };
    if (!array) {
      result.isEqual = false;
      result.compareMedia = false;
      return result;
    }

    // look if the current media of the list is present in the media array
    const found = array.find((el) => el._id === media._id);
    const elementIndex = array.findIndex((el) => el._id === media._id);

    if (found) {
      // is the element the same ?
      if (Tools.isEqual(media, found)) {
        result.isEqual = true;
      } else {
        result.isEqual = false;
      }

      // is the position the same ?
      if (elementIndex === index) {
        result.compareMedia = false;
      } else {
        result.compareMedia = true;
      }
    } else {
      result.isEqual = false;
      result.compareMedia = false;
    }
    return result;
  }
}
