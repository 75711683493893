import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Quantity } from "src/app/models/quantity.model";
import { FileLogger } from "../helpers/fileLogger";
import { AppError } from "../models/app-error.interface";
import { Reference } from "../models/reference.interface";
import { IVitalSignsHistory } from "../models/vital-signs-history.interface";
import { VitalSignApiService } from "./api/vital-sign-api.service";

@Injectable({
  providedIn: "root",
})
export class VitalSignService {
  constructor(private api: VitalSignApiService) {}

  public save(vitalSign: Quantity, patientReference: string, practitionerReference: Reference): Observable<unknown> {
    return this.api.save(vitalSign, patientReference, practitionerReference);
  }

  public list(patientReference: string): Observable<IVitalSignsHistory[]> {
    return this.api.list(patientReference);
  }

  public async exportVitalSigns(patientId: string, lang: string): Promise<void> {
    this.api.exportVitalSigns(patientId, lang).subscribe(
      (blob) => {
        const url = URL.createObjectURL(blob);
        window.open(url);
      },
      (error: AppError) => {
        FileLogger.error("VitalSignService", "exportVitalSigns", error);
        throw new Error(error.message);
      }
    );
  }
}
