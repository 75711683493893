import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormGroupDirective,
  NgForm,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { ErrorStateMatcher } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Observable, Subject, Subscription, of } from "rxjs";
import { concatMap, first, skipWhile, takeUntil } from "rxjs/operators";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { Base64Helper } from "src/app/helpers/Base64Helper";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { CustomErrorStateMatcher, PREVENTCHARACTER, PreventCharacter } from "src/app/helpers/formValidators";
import { CONTROL_KEYS, FORMS_MODE, FormsData } from "src/app/helpers/formsData";
import { MatriculeInsHelper, insType } from "src/app/helpers/matriculeInsHelper";
import { PatientHelper } from "src/app/helpers/patientHelper";
import { Tools } from "src/app/helpers/tools";
import { AppError, ERROR_MSG } from "src/app/models/app-error.interface";
import { Coding } from "src/app/models/coding.interface";
import { IdentityVerificationMethods } from "src/app/models/healthcareservice";
import { Identifier } from "src/app/models/identifier.interface";
import { INSIReturnCode, InsResponse, historiqueIns } from "src/app/models/ins.interface";
import { Organization } from "src/app/models/organization.model";
import {
  AddPatientData,
  IDENTITY_STATUS,
  IDENTITY_VERIFICATION,
  IDENTITY_VERIFICATION_METHOD,
  Identity,
  MODIFICATION_TYPE,
  Patient,
  PatientUser,
} from "src/app/models/patient.interface";
import { QUALIFICATIONS } from "src/app/models/practitioner.interface";
import { Practitioner, SYSTEM_COMUNICARE } from "src/app/models/practitioner.model";
import { AddPatientParameter, PreferenceContext } from "src/app/models/preference.interface";
import { Reference } from "src/app/models/reference.interface";
import { IReference } from "src/app/models/sharedInterfaces";
import { CountriesService } from "src/app/providers/countries.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { INSService } from "src/app/providers/ins.service";
import { OrganizationsService } from "src/app/providers/organizations.service";
import { PatientService } from "src/app/providers/patient.service";
import { PractitionerService } from "src/app/providers/practitioner.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { SessionService } from "src/app/providers/session.service";
import { TownsService } from "src/app/providers/towns.service";
import { UserService } from "src/app/providers/user.service";
import { UserStatisticsService } from "src/app/providers/userStatistics.service";
import { CareplanDialogComponent, ICareplanDialogOption } from "../../careplan-dialog/careplan-dialog.component";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "../../confirmation-dialog/confirmation-dialog.component";
import { PatientAlreadyExistsDialogComponent } from "./patient-already-exists-dialog/patient-already-exists-dialog.component";
import { TownServerSideSearchComponent } from "./town-server-side-search-component/town-server-side-search.component";

export class AtLeastOneErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const practitioners = form.form.controls.practitioners;
    const isSubmitted = form && form.submitted;
    return !!(control && practitioners.invalid && (practitioners.touched || isSubmitted));
  }
}
@Pipe({ name: "getIdentityVerificationMethodDisplay" })
export class getIdentityVerificationMethodDisplay implements PipeTransform {
  transform(
    i: number,
    serviceIdentityVerifMethod: IdentityVerificationMethods[],
    lang: string,
    translateService: TranslateService
  ): string {
    if (serviceIdentityVerifMethod?.length) {
      return serviceIdentityVerifMethod.find((s) => s.term === i.toString())[lang];
    }
    return translateService.instant("forms.identityVerificationMethod." + IDENTITY_VERIFICATION_METHOD[i]);
  }
}

@Component({
  selector: "app-add-patient",
  templateUrl: "./add-patient.component.html",
  styleUrls: ["./add-patient.component.scss"],
})
export class AddPatientComponent implements OnInit, OnDestroy {
  @ViewChild(TownServerSideSearchComponent) townServerSideSearch: TownServerSideSearchComponent;
  public FRANCE: string;
  public IDENTITY_VERIFICATION = IDENTITY_VERIFICATION;
  public IDENTITY_VERIFICATION_METHOD: number[];
  public IDENTITY_STATUS = IDENTITY_STATUS;
  public PREVENTCHARACTER = PREVENTCHARACTER;
  public allEthnicGroups = [];
  @ViewChild("insInput") insInput: ElementRef;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  public $insuOrganizations = this.organisationService.listSome("ins", this.sessionService.currentCountry);
  public $allPractitioners: Observable<Practitioner[]>;
  public $preferences: Observable<AddPatientParameter> = this.preferenceService.list(PreferenceContext.ADD_PATIENT_FORM);
  public isLoading = true;
  public departments: Reference[];
  public availableOrganizations: Organization[];
  public availableDoctors: Practitioner[];
  public availableNurses: Practitioner[];
  public availableSecretaries: Practitioner[];
  public availablePharmacist: Practitioner[];
  public availableInsurances: Organization[];
  public filteredInsurances: Organization[];
  public availableLangs: Coding[];
  public availableGender: Coding[];
  public allPractitioners: Practitioner[];
  public maxDate: moment.Moment = moment();
  public patientForm: UntypedFormGroup;
  public matcher = new CustomErrorStateMatcher();
  public atLeastOneMatcher = new AtLeastOneErrorStateMatcher();
  public noSms = false;
  public userTest = false;
  public isCreation = true;
  public goToCp = true;
  public isAnonymous = false;
  public selectedDepartment: Reference = undefined;
  public selectedOrg: Reference = undefined;
  public submitted = false;
  public startFadeOut = false;
  public emailAlreadyTaken = false; // variable to stock response from put /patient
  public phoneAlreadyTaken = false; // variable to stock response from put /patient
  public monitoringOrgsRefs: Reference[] = [];
  public monitoringServicesRefs: Reference[] = [];
  private monitServicesLinks: Map<string, Reference[]> = new Map<string, Reference[]>();
  public isAllServices = false;
  public isAllOrg = false;
  public alreadyMonitoredByAnotherService = false;
  public monitoringServiceName = "";
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();
  private monitLinkSub$: Subscription;
  public go2patientPage: boolean;
  public saveInProgress = false;
  public showMore = false;
  public country: string;
  public showMoreNames = false;
  public identityStatus = IDENTITY_STATUS.temporary;
  public identityFromINSI = false;
  private newIdentities: Identity[] = [];
  public isSearching4InsiPatient = false;
  public searchIsLoading = false;
  public foundInsPatient: InsResponse;
  public INSIReturnCode = INSIReturnCode;
  public notUsableInsPatient = false;
  public canUpdateRetrievedIdentityPermission = false;
  public canUseINSiService = false;
  public serviceIdentityVerifMethod: IdentityVerificationMethods[];
  public insHistory: historiqueIns[];
  public showInsHistory = false;
  public insType = insType;
  public searchFinished: boolean;
  public nameError: string;
  public firstnamesError: string;
  public firstnameError: string;
  public birthplaceRequired: boolean;
  public showStatusHelp = false;
  public source: string;

  constructor(
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private readonly dialogRef: MatDialogRef<AddPatientComponent>,
    public formsData: FormsData,
    private practitionerService: PractitionerService,
    private organisationService: OrganizationsService,
    private patientService: PatientService,
    private preferenceService: PreferenceService,
    public sessionService: SessionService,
    private healthcareService: HealthcareserviceService,
    public translateService: TranslateService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private insService: INSService,
    private cdr: ChangeDetectorRef,
    private townsService: TownsService,
    private statService: UserStatisticsService,
    private countriesService: CountriesService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mode: FORMS_MODE;
      patient?: PatientUser;
      fallBackPatient?: AddPatientData;
      source?: string;
    }
  ) {
    this.userService.getAllEthnicGroups().then((groups) => {
      this.allEthnicGroups = groups;
    });
    this.isCreation = this.data.mode === FORMS_MODE.CREATE;
    this.isAnonymous = this.data?.patient?.user?.anonymous || false;
    this.country = this.isCreation
      ? this.sessionService.currentCountry
      : this.data?.patient?.user?.referenceCountry // when updating a patient file, the patient's country must be taken into account -> important for insi, mutuelle, etc.
      ? this.data?.patient?.user?.referenceCountry
      : this.sessionService.currentCountry; // backup
    this.source = this.data?.source;
    this.FRANCE = this.sessionService.FRANCE;
    this.checkUsersPermissions();

    this.patientForm = this.fb.group({
      healthcareService: new UntypedFormControl(undefined, this.isCreation ? Validators.required : null),
      organization: new UntypedFormControl(undefined, this.isCreation ? Validators.required : null),
      name: new UntypedFormControl(
        { value: undefined, disabled: this.isAnonymous ? true : false },
        {
          validators:
            this.country === this.FRANCE
              ? [Validators.required, Validators.pattern("[A-Za-z- ']*"), this.NameValidator]
              : [Validators.required, Validators.pattern("[^0-9]*")],
        }
      ),
      firstname: new UntypedFormControl({ value: undefined, disabled: this.isAnonymous ? true : false }, [
        this.country === this.FRANCE ? Validators.pattern("[A-Za-z- ']*") : Validators.required,
        this.firstnameValidator,
        this.FirstNamesValidator,
        Validators.pattern("[^0-9]*"),
      ]),
      pseudoname: new UntypedFormControl(undefined),
      birthDate: new UntypedFormControl(undefined, Validators.required),
      phone: new UntypedFormControl(
        { value: undefined, disabled: this.isAnonymous ? true : false },
        { validators: [Validators.required, this.phoneValidator, Validators.pattern("[0-9+]*")] }
      ),
      mail: new UntypedFormControl(
        { value: undefined, disabled: this.isAnonymous ? true : false },
        { validators: [Validators.required, Validators.email] }
      ),
      userLang: new UntypedFormControl({ value: undefined, disabled: this.isAnonymous ? true : false }, Validators.required),
      gender: new UntypedFormControl(undefined, Validators.required),
      practitioners: this.fb.group(
        {
          secretary: new UntypedFormControl({
            value: undefined,
            disabled: this.isAnonymous ? true : false,
          }),
          pharmacist: new UntypedFormControl({
            value: undefined,
            disabled: this.isAnonymous ? true : false,
          }),
          nurse: new UntypedFormControl({
            value: undefined,
            disabled: this.isAnonymous ? true : false,
          }),
          doc: new UntypedFormControl({
            value: undefined,
            disabled: this.isAnonymous ? true : false,
          }),
        },
        { validators: this.atLeastOneValidator }
      ),
      internalId: new UntypedFormControl(
        { value: undefined, disabled: this.isAnonymous ? true : false },
        { validators: [Validators.minLength(5)] }
      ),
      insurance: new UntypedFormControl({
        value: undefined,
        disabled: this.isAnonymous ? true : false,
      }),
      insuranceId: new UntypedFormControl(
        { value: undefined, disabled: this.country !== this.FRANCE ? true : false },
        this.country === this.FRANCE ? MatriculeInsHelper.insValidator : [Validators.pattern("[a-zA-Z0-9]*")]
      ), // when country is not this.FRANCE, the field is diabled until an insurance is selected
      SSIN: new UntypedFormControl(
        {
          value: undefined,
          disabled: this.isAnonymous ? true : false,
        },
        [Validators.pattern("[a-zA-Z0-9]*")]
      ),
      // FR specific fields :
      firstnames: new UntypedFormControl(
        { value: undefined, disabled: this.isAnonymous ? true : false },
        this.country === this.FRANCE ? [Validators.pattern("[A-Za-z-, ']*"), this.FirstNamesValidator] : undefined
      ),
      useName: new UntypedFormControl(undefined, [Validators.pattern("[^0-9]*")]),
      useFirstname: new UntypedFormControl(undefined, [Validators.pattern("[^0-9]*")]),
      birthplace: new UntypedFormControl(undefined, this.country === this.FRANCE ? undefined : undefined),
      identityVerification: new UntypedFormControl(undefined),
      identityVerificationMethod: new UntypedFormControl(undefined),
      // Numéro de sécurité social gérer dans le champ insuranceId
      ins: new UntypedFormControl(undefined, MatriculeInsHelper.insValidator),
      oid: new UntypedFormControl(undefined, this.oidValidator),
      ethnicGroup: new UntypedFormControl(undefined),
    });
    if (this.country === this.FRANCE) {
      this.watchFrFields();
    }
    if (!this.userService.isMonitoringUser || this.sessionService.isAdmin()) {
      this.patientForm.addControl("monitoringOrgsLink", new UntypedFormControl([]));
      this.setupMonitOrgWatch();
    }
  }

  ngOnInit(): void {
    this.preferenceService
      .getGlobal()
      .pipe(first())
      .subscribe((pref) => {
        this.go2patientPage = pref ? pref.go2PatientAfterInscription : undefined;
      });

    if (
      this.sessionService.currentService &&
      this.userService.ownOrganization &&
      (this.healthcareService.availableServices()?.length > 0 || this.healthcareService.availableMonitoringServices().length > 0)
    ) {
      this.setupServicesWatch();
    } else {
      this.sessionService.servicesSetupWatch
        .pipe(
          skipWhile(() => !this.userService.ownOrganization),
          first()
        )
        .subscribe(() => {
          if (this.healthcareService.availableServices()?.length >= 2) {
            this.sessionService.currentService = this.sessionService.allsReference;
          }
          this.setupServicesWatch();
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setupServicesWatch() {
    this.init();
    if (this.userService.isMonitoringUser) {
      this.sessionService.refreshCurrentMonitService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.init();
      });
    }
    this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.init();
    });
  }

  private init() {
    this.availableOrganizations = this.organisationService.availableOrganizations();
    const currentOrg = this.sessionService.organization;
    if (currentOrg.reference === this.sessionService.allsOption) {
      this.isAllOrg = true;
      this.selectedOrg = this.availableOrganizations[0].asReference;
      this.patientForm.controls.organization.setValue(this.selectedOrg);
    } else {
      this.isAllOrg = false;
      this.selectedOrg = currentOrg;
      this.patientForm.controls.organization.setValue(this.selectedOrg);
    }
    const current = this.sessionService.currentService;
    if (this.isCreation) {
      if (current.reference === this.sessionService.allsOption || this.isAllOrg) {
        this.healthcareService
          .list(this.selectedOrg.reference)
          .pipe(first(), takeUntil(this.onDestroy$))
          .subscribe((services) => {
            this.departments = services.map((s) => s.asReference);
            this.selectedDepartment = this.departments[0];
            this.isAllServices = true;
            this.patientForm.controls.healthcareService.setValue(this.selectedDepartment);
            this.setAvailableInsurancesFromSelectedDepartment();
            this.computeFormData();
          });
      } else {
        this.isAllServices = false;
        this.selectedDepartment = current;
        this.patientForm.controls.healthcareService.setValue(this.selectedDepartment);
        this.computeFormData();
      }
    } else {
      // patient update : use current patient service
      // check if the current selected service is one the patient is in:
      this.selectedOrg = this.data.patient.patient.managingOrganization;
      const hs = this.data.patient.patient.healthcareservice.find((s) => s.reference === current.reference);
      if (hs) {
        // if it is, we select the current one
        this.selectedDepartment = hs;
      } else {
        // if it is not, we try to find which service, available to the user, the patient belong to:
        const availableServices = this.userService.allServices.map((s) => s.serviceRef);
        const serv = this.data.patient.patient.healthcareservice.find((s) => availableServices.includes(s.reference));
        if (serv) {
          this.selectedDepartment = serv;
        } else {
          // if we do not find any service, we actually have a problem
          // Log it, but still assign a correct service
          FileLogger.error(
            "AddPatientComponent",
            "Could not find patient services in available services list: ",
            this.data.patient.patient.healthcareservice
          );
          this.selectedDepartment = this.data.patient.patient.healthcareservice[0];
        }
      }
      this.statService.createStatEvent("Opened edit patient modal", [this.selectedDepartment.reference]);
      this.$insuOrganizations = this.organisationService.listSome("ins", this.data.patient.user.referenceCountry);
      this.patientForm.get("birthplace").setValue(this.data.patient.patient.birthplace, { emitEvent: false });
      this.computeFormData();
    }
    this.initIdentityVerificationMethodList();
    this.patientForm.controls.insurance.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      if (!value?.organizationName) {
        this.filteredInsurances = this.availableInsurances.filter((ins) => {
          return Tools.suppressDiacritics(ins.organizationName)?.toLowerCase().indexOf(Tools.suppressDiacritics(value).toLowerCase()) > -1;
        });
      }
      this.patientForm.controls.insuranceId.enable();
      if (!value && this.country !== this.FRANCE) {
        this.patientForm.controls.insuranceId.setValue(null);
        this.patientForm.controls.insuranceId.disable();
      }
    });
    this.isLoading = false;
  }

  private initIdentityVerificationMethodList() {
    this.IDENTITY_VERIFICATION_METHOD = [];
    const service = this.isCreation ? this.selectedDepartment : this.data.patient.patient.healthcareservice[0];
    this.serviceIdentityVerifMethod = this.userService.allServices.find((s) => s.serviceRef === service?.reference)?.identityVerifMethods;
    if (this.serviceIdentityVerifMethod?.length) {
      this.serviceIdentityVerifMethod.forEach((v, i) => {
        if (v.activated) {
          if (v.term) {
            this.IDENTITY_VERIFICATION_METHOD.push(Number(v.term));
          } else {
            this.IDENTITY_VERIFICATION_METHOD.push(i);
          }
        }
      });
    } else {
      let keys = Object.keys(IDENTITY_VERIFICATION_METHOD);
      keys = keys.slice(0, keys.length / 2);
      this.IDENTITY_VERIFICATION_METHOD = keys.map((key) => Number(key));
    }
  }

  public onSwitchGoToCp(): void {
    this.goToCp = !this.goToCp;
    this.preferenceService
      .update({
        context: PreferenceContext.ADD_PATIENT_FORM,
        parameters: {
          goToCp: this.goToCp,
        },
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  private computeIdentityStatus(
    modifType: MODIFICATION_TYPE,
    verificationValue?: IDENTITY_VERIFICATION,
    verificationMethod?: IDENTITY_VERIFICATION_METHOD
  ): void {
    const prevStatus = this.identityStatus;
    const verification = Tools.isDefined(verificationValue) ? verificationValue : this.patientForm.get("identityVerification").value;
    const method = Tools.isDefined(verificationMethod) ? verificationMethod : this.patientForm.get("identityVerificationMethod").value;
    if (verification === IDENTITY_VERIFICATION.fictive || verification === IDENTITY_VERIFICATION.suspicious) {
      this.identityStatus = IDENTITY_STATUS.temporary;
    } else if (verification === IDENTITY_VERIFICATION.verified && Tools.isDefined(method)) {
      this.identityStatus = this.identityFromINSI ? IDENTITY_STATUS.qualified : IDENTITY_STATUS.validated;
    } else {
      this.identityStatus = this.identityFromINSI ? IDENTITY_STATUS.recovered : IDENTITY_STATUS.temporary;
    }
    if (
      (prevStatus === IDENTITY_STATUS.qualified || prevStatus === IDENTITY_STATUS.recovered) &&
      (this.identityStatus === IDENTITY_STATUS.temporary || this.identityStatus === IDENTITY_STATUS.validated)
    ) {
      // empty ins and oid fields
      this.patientForm.get("oid")?.setValue("", { emitEvent: false });
      this.patientForm.get("ins")?.setValue("", { emitEvent: false });
      this.identityFromINSI = false;
      // unlock fields after retrograding status to temporary
      this.enableFields();
    }
    this.addNewIdentity(modifType);
  }

  private watchFrFields(): void {
    this.patientForm
      .get("identityVerification")
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((value: IDENTITY_VERIFICATION) => {
        this.computeIdentityStatus(MODIFICATION_TYPE.verification, value);
      });
    this.patientForm
      .get("identityVerificationMethod")
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((value: IDENTITY_VERIFICATION_METHOD) => {
        this.computeIdentityStatus(MODIFICATION_TYPE.method, undefined, value);
      });
    this.patientForm
      .get("ins")
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.downgradeIdentityIfNeeded(MODIFICATION_TYPE.ins);
      });
    this.patientForm
      .get("oid")
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.downgradeIdentityIfNeeded(MODIFICATION_TYPE.oid);
      });
    this.patientForm
      .get("name")
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.patientForm.get("name").errors) {
          this.nameError = Object.keys(this.patientForm.get("name").errors)[0];
        }
        this.downgradeIdentityIfNeeded(MODIFICATION_TYPE.name);
      });
    this.patientForm
      .get("firstnames")
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.patientForm.get("firstnames").errors) {
          this.firstnamesError = Object.keys(this.patientForm.get("firstnames").errors)[0];
        }
        this.downgradeIdentityIfNeeded(MODIFICATION_TYPE.firstnames);
      });
    this.patientForm
      .get("firstname")
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.patientForm.get("firstname").errors) {
          this.firstnameError = Object.keys(this.patientForm.get("firstname").errors)[0];
        }
      });
    this.patientForm
      .get("birthDate")
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.downgradeIdentityIfNeeded(MODIFICATION_TYPE.birthDate);
      });
    this.patientForm
      .get("gender")
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.downgradeIdentityIfNeeded(MODIFICATION_TYPE.gender);
      });
  }

  public downgradeIdentityIfNeeded(modificationType: MODIFICATION_TYPE): void {
    if (this.identityStatus === IDENTITY_STATUS.temporary || this.identityStatus === IDENTITY_STATUS.validated) {
      this.addNewIdentity(modificationType);
      return;
    }
    this.identityStatus -= 1;
    this.identityFromINSI = false;
    this.patientForm.get("oid")?.setValue("", { emitEvent: false });
    this.patientForm.get("ins")?.setValue("", { emitEvent: false });
    this.addNewIdentity(modificationType);
  }

  private addNewIdentity(modificationType: MODIFICATION_TYPE): void {
    const n = this.newIdentities.length;
    if (n > 0) {
      this.newIdentities[n - 1].period.end = moment().format();
    }
    const identity: Identity = {
      status: this.identityStatus,
      verification: this.patientForm.get("identityVerification").value,
      method: this.patientForm.get("identityVerificationMethod").value,
      modificationType,
      period: {
        start: moment().format(),
        end: undefined,
      },
      responsible: [
        {
          use: "usual",
          system: SYSTEM_COMUNICARE,
          value: this.sessionService.account.caremateIdentifier,
          label: "",
        },
      ],
    };
    this.newIdentities.push(identity);
  }

  private async initData() {
    // Get the monitoring links available to the selected service:
    const selectedService = this.userService.allServices.find((s) => s.serviceRef === this.selectedDepartment.reference);
    const links = selectedService?.linkedMonitoringOrganizations?.filter(
      (l) => !this.userService.isMonitoringUser || l.organizationRef.reference === this.sessionService.currentMonitoringOrg.reference
    );
    this.monitoringOrgsRefs = links?.map((l) => l.organizationRef);

    for (const link of links) {
      // if the user is monitoring it's possible he does not have access to all the
      // monitoring services the normal selected service has access to, so we need
      // to filter.
      // if the user is not monitoring, by default, he has access to all monitoring
      // services his normal service has access to
      let servRefs = link.servicesRef;
      if (this.userService.isMonitoringUser) {
        const availableMonitServices = this.healthcareService.availableMonitoringServices().map((s) => s.serviceRef);
        servRefs = servRefs.filter((s) => availableMonitServices.includes(s.reference));
      }
      this.monitServicesLinks.set(link.organizationRef.reference, servRefs);
    }

    this.availableLangs = await this.formsData.getLanguages();
    this.availableGender = this.formsData.GENDER;
    this.availableDoctors = this.allPractitioners
      .filter((p) => this.practitionerService.getRole(p) === QUALIFICATIONS.DOCTOR_CODE)
      .sort((a, b) => {
        return a?.name?.given[0]?.toLowerCase() < b?.name?.given[0]?.toLowerCase()
          ? -1
          : a?.name?.given[0]?.toLowerCase() > b?.name?.given[0]?.toLowerCase()
          ? 1
          : 0;
      });
    this.availableNurses = this.allPractitioners
      .filter((p) => this.practitionerService.getRole(p) === QUALIFICATIONS.NURSE_CODE)
      .sort((a, b) => {
        return a?.name?.given[0]?.toLowerCase() < b?.name?.given[0]?.toLowerCase()
          ? -1
          : a?.name?.given[0]?.toLowerCase() > b?.name?.given[0]?.toLowerCase()
          ? 1
          : 0;
      });
    this.availableSecretaries = this.allPractitioners
      .filter((p) => this.practitionerService.getRole(p) === QUALIFICATIONS.SECRETARY_CODE)
      .sort((a, b) => {
        return a?.name?.given[0]?.toLowerCase() < b?.name?.given[0]?.toLowerCase()
          ? -1
          : a?.name?.given[0]?.toLowerCase() > b?.name?.given[0]?.toLowerCase()
          ? 1
          : 0;
      });
    this.availablePharmacist = this.allPractitioners
      .filter((p) => this.practitionerService.getRole(p) === QUALIFICATIONS.PHARMACIST_CODE)
      .sort((a, b) => {
        return a?.name?.given[0]?.toLowerCase() < b?.name?.given[0]?.toLowerCase()
          ? -1
          : a?.name?.given[0]?.toLowerCase() > b?.name?.given[0]?.toLowerCase()
          ? 1
          : 0;
      });

    this.updateForm();
    if (this.data.fallBackPatient || !this.isCreation) {
      this.isLoading = false;
    }
  }

  private setupMonitOrgWatch() {
    if (this.patientForm.get("monitoringOrgsLink")) {
      this.monitLinkSub$?.unsubscribe();
      this.monitLinkSub$ = this.patientForm
        .get("monitoringOrgsLink")
        .valueChanges.pipe(takeUntil(this.onDestroy$))
        .subscribe((org: Reference | string) => {
          this.setupMonitoringServices(org);
        });
    }
  }

  private setupMonitoringServices(org: Reference | string, serv?: Reference[]) {
    if (org && org !== "none" && !this.patientForm.contains("monitoringServicesLink")) {
      this.patientForm.addControl("monitoringServicesLink", new UntypedFormControl(null, [Validators.required]));
    }
    if (org && org !== "none") {
      this.monitoringServicesRefs = this.filterServiceFromSelectedOrgs(org as Reference);
      const service =
        this.monitoringServicesRefs.length > 0
          ? serv && serv.length > 0
            ? this.monitoringServicesRefs?.find((s) => s.reference === serv[0].reference)
            : this.monitoringServicesRefs[0]
          : null;
      if (this.monitoringServicesRefs.length > 0) {
        this.patientForm.get("monitoringServicesLink").patchValue(service);
      } else {
        this.patientForm.get("monitoringServicesLink").patchValue(null);
      }
    } else if (this.patientForm.contains("monitoringServicesLink")) {
      this.patientForm.removeControl("monitoringServicesLink");
    }
  }

  private filterServiceFromSelectedOrgs(org: Reference): Reference[] {
    if (!org || !org.reference) {
      return null;
    }
    return this.monitServicesLinks.get(org.reference);
  }

  public async apply(): Promise<void> {
    if (this.country === this.FRANCE && !this.patientForm.get("birthplace").value) {
      // makeBirthplaceRequired() triggers a value change detection triggering a downgradeIdentityIfNeeded(). To avoid this, we make "birthplace" required only if its value is not set.
      this.makeBirthplaceRequired();
    }

    if (
      this.patientForm.valid &&
      (this.townServerSideSearch?.townServerSideFilteringCtrl ? !this.townServerSideSearch?.townServerSideFilteringCtrl.invalid : true) // we use !invalid instead of valid so disabled field return true as well
    ) {
      if (this.isCreation) {
        this.addPatient(await this.getPatientFromForm());
      } else {
        const formData = this.getPatientFromForm();
        const newPatient = this.transformAddPatientDataToPatient(await formData, this.data.patient.patient);
        this.putPatient(newPatient);
      }
    } else {
      FileLogger.warn("AddPatientComponent", "invalid form");
    }
    this.submitted = true;
  }

  private async getPatientFromForm(): Promise<AddPatientData> {
    const Qdata = {
      ...this.patientForm.getRawValue(),
      noSms: this.noSms,
      userTest: this.userTest,
    };
    const patient: AddPatientData = {
      name: Qdata.name,
      firstname: Qdata.firstname,
      mail: Qdata.mail,
      phone: Qdata.phone,
      internalIdentifier: Qdata.internalId,
      insurance: Qdata.insurance ? Qdata.insurance.organizationReference : null,
      insuranceRef: Qdata.insurance ? Qdata.insurance.asReference : null,
      gender: Qdata.gender,
      careProvider: this.practitionerService.getPractitionerReference(Qdata.practitioners.doc),
      nurse: Qdata.practitioners.nurse ? this.practitionerService.getPractitionerReference(Qdata.practitioners.nurse) : null,
      secretary: Qdata.practitioners.secretary ? this.practitionerService.getPractitionerReference(Qdata.practitioners.secretary) : null,
      pharmacist: Qdata.practitioners.pharmacist ? this.practitionerService.getPractitionerReference(Qdata.practitioners.pharmacist) : null,
      language: Qdata.userLang,
      birthdate: moment(Qdata.birthDate).format("YYYY-MM-DD"),
      testUser: Qdata.userTest,
      noSms: Qdata.noSms,
      insuranceIdentifier: Qdata.insuranceId,
      SSIN: Qdata.SSIN,
      organization: Qdata.organization,
      healthcareService: Qdata.healthcareService,
      pseudoname: Qdata.pseudoname ? Qdata.pseudoname : null,
      // FR fields
      firstnames: Qdata.firstnames
        ?.split(",")
        .map((s) => s.trim())
        .filter(Boolean), // remove empty strings if the input finishes with a coma
      useName: Qdata.useName,
      useFirstname: Qdata.useFirstname,
      birthplace: Qdata.birthplace,
      //@TODO CMATE-4621 : add identity: Identity[]
      ins: Qdata.ins,
      oid: Qdata.oid,
      identity: this.newIdentities,
      historicINS: this.insHistory?.map((el) => ({
        ins: el.MatriculeIns,
        oid: el.Oid,
        begin: el.dateDeb,
        end: el.dateFin,
      })),
      ethnicGroup: Qdata.ethnicGroup,
    };

    if (Qdata.monitoringServicesLink) {
      const monitServiceRef: Reference = Qdata.monitoringServicesLink;
      const currentMonitService = this.userService.allMonitoringServices.find((s) => s.serviceRef === monitServiceRef?.reference);
      if (currentMonitService) {
        patient.monitoringOrganizations = [
          {
            organizationRef: currentMonitService.providedBy,
            servicesRef: [monitServiceRef],
          },
        ];
      } else if (this.data.fallBackPatient || !this.isCreation) {
        patient.monitoringOrganizations = this.data.fallBackPatient
          ? this.data.fallBackPatient.monitoringOrganizations
          : this.data?.patient.patient.monitoringOrganizations;
      } else {
        patient.monitoringOrganizations = [];
      }
    } else if (Qdata.monitoringOrgsLink && !Qdata.monitoringServicesLink) {
      patient.monitoringOrganizations = [];
    } else if (this.data.fallBackPatient || !this.isCreation) {
      patient.monitoringOrganizations = this.data.fallBackPatient
        ? this.data.fallBackPatient.monitoringOrganizations
        : this.data?.patient.patient.monitoringOrganizations;
    } else if (this.isCreation && this.userService.isMonitoringUser) {
      patient.monitoringOrganizations = [
        {
          organizationRef: this.sessionService.currentMonitoringOrg,
          servicesRef: [this.sessionService.currentMonitoringService],
        },
      ];
    } else {
      patient.monitoringOrganizations = [];
    }

    // France has only one (fictitious) mutual insurance company - The French state
    if (this.country === this.FRANCE && this.availableInsurances.length === 1 && patient.insuranceIdentifier) {
      patient.insuranceRef = this.availableInsurances[0].asReference;
      patient.insurance = this.availableInsurances[0].organizationReference;
    }
    return patient;
  }

  private transformAddPatientDataToPatient(Qdata: AddPatientData, unmodifiedPatient: Patient): Patient {
    const newPatient = Tools.clone(unmodifiedPatient);
    // France has only one (fictitious) mutual insurance company - The French state
    if (this.country === this.FRANCE && this.availableInsurances.length === 1 && Qdata.insuranceIdentifier) {
      Qdata.insuranceRef = this.availableInsurances[0].asReference;
      Qdata.insurance = this.availableInsurances[0].organizationReference;
    }

    if (newPatient.name.length) {
      newPatient.name[0].family = [Qdata.name];
      newPatient.name[0].given = [Qdata.firstname, ...(Qdata.firstnames ? Qdata.firstnames : [])];
      newPatient.name[0].prefix = [this.patientService.getPrefixFromGenderAndLang(Qdata)];
      newPatient.name[0].use = "official";
      newPatient.name[0].text = `${Qdata.name} ${Qdata.firstname}`;
    }
    newPatient.birthDate = moment(Qdata.birthdate).format("YYYY-MM-DD");
    newPatient.gender = Qdata.gender;
    newPatient.ethnicGroup = Qdata.ethnicGroup;
    this.patientService.setPreferredLang(newPatient, Qdata.language);
    this.patientService.setMail(newPatient, Qdata.mail);
    this.patientService.setPhone(newPatient, Qdata.phone);
    this.patientService.setInsu(newPatient, Qdata.insuranceIdentifier, Qdata.insuranceRef);
    this.patientService.setInternalId(newPatient, Qdata.internalIdentifier);
    this.patientService.setCareProviders(newPatient, Qdata.careProvider, Qdata.nurse, Qdata.secretary, Qdata.pharmacist);
    this.patientService.setSSIN(newPatient, Qdata.SSIN);
    newPatient.pseudoname = Qdata.pseudoname ? Qdata.pseudoname : null;
    newPatient.monitoringOrganizations = Qdata.monitoringOrganizations ? Qdata.monitoringOrganizations : [];

    // Specific for FR patients :
    if (this.country === this.FRANCE) {
      // add useName and useFirstname
      if (Qdata.useName || Qdata.useFirstname) {
        newPatient.name = newPatient.name.filter((name) => name.use !== "usual");
        newPatient.name.push({
          family: [Qdata.useName],
          given: [Qdata.useFirstname],
          prefix: undefined,
          use: "usual",
          text: `${Qdata.useName} ${Qdata.useFirstname}`,
        });
      } else {
        newPatient.name = newPatient.name.filter((name) => name.use !== "usual");
      }
      newPatient.birthplace = Qdata.birthplace;

      // Save INS in the identifier if it has changed since the first registration
      const ins = PatientHelper.getIns(unmodifiedPatient);
      const oid = PatientHelper.getOid(unmodifiedPatient);
      if ((ins && !Qdata.ins) || (oid && !Qdata.oid) || (Qdata.ins && Qdata.ins !== ins) || (Qdata.oid && Qdata.oid !== oid)) {
        this.patientService.setINS(newPatient, Qdata.ins, Qdata.oid);
      }
      // Save INS history in Identifier (only new elements are added)
      if (Tools.isDefined(this.insHistory) && this.insHistory.length > 0) {
        const historicINSIdentifier: Identifier[] = [];
        for (const h of this.insHistory) {
          const existingINS = newPatient.identifier.find((identifier) => identifier.value === h.MatriculeIns);
          if (!existingINS) {
            const insIdentifier: Identifier = {
              use: FHIRHelper.USE_MATRICULE_INS,
              system: FHIRHelper.SYSTEM_MATRICULE_INS,
              value: h.MatriculeIns,
              period: {
                start: h.dateDeb ? h.dateDeb : moment().format(),
                end: h.dateFin ? h.dateFin : moment().format(),
              },
              type: {
                code: h.Oid,
                display: MatriculeInsHelper.computeDisplayOID(h.Oid),
                system: FHIRHelper.SYSTEM_TYPE_MATRICULE_INS,
              },
            };
            historicINSIdentifier.push(insIdentifier);
          }
        }

        // Add the historicINSIdentifier to newPatient's identifier
        newPatient.identifier.push(...historicINSIdentifier);
      }

      newPatient.identity = Qdata.identity;
    }
    return newPatient;
  }

  private addPatient(data: AddPatientData, forceUpdateUser?: string, needChangeInsurance = false) {
    this.saveInProgress = true;

    this.patientService.create(data, forceUpdateUser ? { forceUpdateUser, needChangeInsurance } : {}).subscribe(
      (patientSaved: Patient) => {
        this.dialog.closeAll(); // close modals before showing the success message
        if (this.goToCp) {
          this.goToCareplanForm(patientSaved);
        } else {
          this.success(patientSaved, forceUpdateUser);
        }
      },
      (err) => {
        if (err?.message === ERROR_MSG.ALREADY_EXISTS && err?.additionalData) {
          this.statService.createStatEvent("Tried to create a patient that already exists", [this.selectedDepartment.reference]);
          // open new modal and inject the form's patient object and the already existing patient passed with the error
          const dialogRef = this.dialog.open(PatientAlreadyExistsDialogComponent, {
            disableClose: true,
            data: { ...err, ...data },
            panelClass: "dialog-container-scroll",
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result?.forceUpdateUser) {
              // don't send sms on update (ignore checkbox by passing to true)
              data.noSms = true;
              this.addPatient(data, result.forceUpdateUser, result.needChangeInsurance);
            }
          });
        } else {
          this.fail(err, data);
        }
        this.saveInProgress = false;
      },
      () => {
        this.sessionService.needRefreshPatientDataList();
        this.sessionService.needRefreshPatientUser(); // make sure PU is refreshed after edition, important when you are on the patient page of the patient being edited to update the form correctly.
        this.saveInProgress = false;
      }
    );
  }

  private putPatient(data: Patient) {
    this.saveInProgress = true;

    this.patientService.update(data, this.data.patient.user.anonymous).subscribe(
      (patientSaved: Patient) => {
        this.statService.createStatEvent("Edit patient", [this.selectedDepartment.reference]);
        this.dialogRef.close(data);
        this.success(patientSaved);
      },
      (err) => {
        // when error, update variables and re-evaluate the related controls to show mat-error
        this.emailAlreadyTaken = err.additionalData?.emailAlreadyTaken;
        this.phoneAlreadyTaken = err.additionalData?.phoneAlreadyTaken;
        this.patientForm.controls.mail.updateValueAndValidity();
        this.patientForm.controls.phone.updateValueAndValidity();
        this.fail(err, this.data.fallBackPatient);
        this.saveInProgress = false;
      },
      () => {
        this.sessionService.needRefreshPatientDataList();
        this.saveInProgress = false;
      }
    );
  }

  private success(patientSaved: Patient, forceUpdateUser?: string): void {
    this.translateService.get("common.saveSuccess").subscribe((trans) => {
      if (this.isCreation && !forceUpdateUser) {
        if (patientSaved?.noSms) {
          trans += " Authocode : " + this.patientService.computeAuthCode(patientSaved);
        }
        const dialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: true,
          data: {
            message: trans,
            type: ConfirmationDialogType.SUCCESS,
            showButtons: this.go2patientPage === undefined,
          },
        });

        dialog.afterClosed().subscribe((res) => {
          // if preference is already set
          if (this.go2patientPage === true) {
            this.navigateToPatientPage(patientSaved);
          } else {
            // redirect if wanted
            if (res) {
              this.navigateToPatientPage(patientSaved);
            }
          }
        });
      } else {
        return this.snackBar.open(trans, "ok", { duration: 3000 });
      }
    });
  }

  private fail(err: AppError, patientFallback: AddPatientData): void {
    this.translateService.get("common.saveFail").subscribe((trans) => {
      if (err?.message === ERROR_MSG.ALREADY_EXISTS) {
        this.statService.createStatEvent("Tried to save a patient that already exists", [this.selectedDepartment.reference]);
        trans += ` : ${this.translateService.instant("api.errors.alreadyExist")}`;
      } else if (err?.message === ERROR_MSG.INVALID_DATA) {
        this.statService.createStatEvent("Save patient failed: invalid data", [this.selectedDepartment.reference]);
        trans += ` : ${this.translateService.instant("api.errors.invalidData")}`;
      } else if (err?.message === ERROR_MSG.INVALID_EMAIL) {
        this.statService.createStatEvent("Save patient failed: invalid email", [this.selectedDepartment.reference]);
        trans += ` : ${this.translateService.instant("api.errors.invalidEmail")}`;
      }
      this.snackBar
        .open(trans, this.translateService.instant("common.retry"), {
          duration: 3000,
        })
        .onAction()
        .subscribe(async () => {
          if (this.isCreation) {
            this.addPatient(patientFallback);
          } else {
            const fallback = patientFallback ? patientFallback : await this.getPatientFromForm();
            const newPatientFallback = this.transformAddPatientDataToPatient(fallback, this.data.patient.patient);
            this.putPatient(newPatientFallback);
          }
        });
    });
  }

  private goToCareplanForm(patientSaved: Patient): void {
    const id = this.patientService.getComunicareId(patientSaved);
    this.patientService.getPatientUser(id).subscribe((patientUser) => {
      const dialog = this.dialog.open(CareplanDialogComponent, {
        disableClose: true,
        panelClass: "no-padding",
        maxHeight: "95vh",
        data: {
          patient: patientUser,
          careplan: undefined,
          registration: true,
          healthcareService: this.selectedDepartment,
        } as ICareplanDialogOption,
      });
      dialog.afterClosed().subscribe(() => {
        this.success(patientSaved);
      });
    });
  }

  public isInternalIdValid(): boolean {
    return this.patientForm.controls[CONTROL_KEYS.internalId].value && this.patientForm.controls[CONTROL_KEYS.internalId].valid;
  }

  private updateForm() {
    let selectedOrgs: Reference[] = [];
    let selectedServices: Reference[] = [];
    if (this.isCreation && this.data?.fallBackPatient) {
      const monitLinksRefs: string[] = this.data?.fallBackPatient.monitoringOrganizations?.map((link) => link.organizationRef.reference);
      selectedOrgs = this.monitoringOrgsRefs?.filter((o) => monitLinksRefs?.includes(o.reference));
      if (selectedOrgs && selectedOrgs.length > 0) {
        selectedServices = this.data.fallBackPatient.monitoringOrganizations.find(
          (o) => o.organizationRef.reference === selectedOrgs[0].reference
        ).servicesRef;
      }

      this.patientForm.patchValue(
        {
          healthcareService: this.data?.fallBackPatient.healthcareService,
          name: this.data?.fallBackPatient.name,
          firstname: this.data?.fallBackPatient.firstname,
          pseudoname: this.data?.fallBackPatient.pseudoname,
          birthDate: this.data?.fallBackPatient.birthdate,
          phone: this.data?.fallBackPatient.phone,
          mail: this.data?.fallBackPatient.mail,
          userLang: this.data?.fallBackPatient.language,
          gender: this.data?.fallBackPatient.gender,
          practitioners: {
            secretary: this.data?.fallBackPatient.secretary,
            pharmacist: this.data?.fallBackPatient.pharmacist,
            nurse: this.data?.fallBackPatient.nurse,
            doc: this.data?.fallBackPatient.careProvider,
          },
          internalId: this.data?.fallBackPatient.internalIdentifier,
          insurance: this.data?.fallBackPatient.insurance,
          insuranceId: this.data?.fallBackPatient.insuranceIdentifier,
          SSIN: this.data?.fallBackPatient.SSIN,
          ethnicGroup: this.data?.fallBackPatient.ethnicGroup,
        },
        { emitEvent: false, onlySelf: true }
      );
    } else if (!this.isCreation) {
      // Find the monit link already selected for the patient:
      const monitLinksRefs: string[] = this.data?.patient.patient.monitoringOrganizations?.map((link) => link.organizationRef.reference);
      selectedOrgs = this.monitoringOrgsRefs?.filter((o) => monitLinksRefs?.includes(o.reference));
      if (selectedOrgs && selectedOrgs.length > 0) {
        const patientSelectedServices = this.data.patient.patient.monitoringOrganizations.find(
          (o) => o.organizationRef.reference === selectedOrgs[0].reference
        ).servicesRef;
        // check if the monitoring services selected are ones the current selected 'normal' service has access to:
        const availableMonitServices = this.monitServicesLinks.get(selectedOrgs[0].reference)?.map((s) => s.reference);
        selectedServices = patientSelectedServices.filter((s) => availableMonitServices?.includes(s.reference));
      }
      if (monitLinksRefs && monitLinksRefs.length > 0 && selectedServices.length === 0) {
        // this means the patient is already monitored by a service we have no access to with the current selected service
        // we cannot allow the user to modify the monitoring service
        this.alreadyMonitoredByAnotherService = true;
        this.monitoringServiceName =
          this.data.patient.patient.monitoringOrganizations[0].organizationRef.display +
          " - " +
          this.data.patient.patient.monitoringOrganizations[0].servicesRef[0].display;
      }

      const insurance = this.patientService.getInsurance(this.data?.patient.patient, this.availableInsurances);

      // update form when modifying existing patient
      this.patientForm.patchValue(
        {
          name: this.patientService.getSurname(this.data?.patient.patient),
          firstname: this.patientService.getFirstname(this.data?.patient.patient),
          pseudoname: this.data?.patient.patient.pseudoname,
          birthDate: this.data?.patient.patient.birthDate,
          phone: this.patientService.getPhone(this.data?.patient.patient),
          mail: this.patientService.getMail(this.data?.patient.patient),
          userLang: this.formsData.getPreferredLang(this.data?.patient.patient),
          gender: this.data?.patient.patient.gender,
          practitioners: {
            secretary: this.patientService.getSecretary(this.data?.patient.patient, this.availableSecretaries),
            pharmacist: this.patientService.getPharmacist(this.data?.patient.patient, this.availablePharmacist),
            nurse: this.patientService.getNurse(this.data?.patient.patient, this.availableNurses),
            doc: this.patientService.getDoctor(this.data?.patient.patient, this.availableDoctors),
          },
          internalId: this.patientService.getInternalId(this.data?.patient.patient),
          insurance: insurance,
          insuranceId: this.patientService.getInsurancelId(this.data?.patient.patient),
          SSIN: this.patientService.getSSIN(this.data?.patient.patient),
          ethnicGroup: this.data?.patient.user.ethnicGroup,
        },
        { emitEvent: false, onlySelf: true }
      );

      if (insurance || this.country === this.FRANCE) {
        this.patientForm.get("insuranceId").enable();
      } else {
        this.patientForm.get("insuranceId").setValue(null);
        this.patientForm.get("insuranceId").disable();
      }

      this.patientForm.get("mail").disable();

      if (this.country === this.FRANCE) {
        const firstnames =
          this.data?.patient.patient.name[0].given.length > 1
            ? this.data?.patient.patient.name[0].given.slice(1).length > 0
              ? this.data?.patient.patient.name[0].given.slice(1).join(", ")
              : this.data?.patient.patient.name[0].given.toString()
            : this.data?.patient.patient.name[0].given.toString();
        this.patientForm.get("firstnames").patchValue(firstnames.toUpperCase(), { emitEvent: false, onlySelf: true });

        const usualNameFirstname = this.data?.patient?.patient?.name?.find((name) => name.use === "usual");
        const usualName =
          Array.isArray(usualNameFirstname?.family) && usualNameFirstname?.family?.length > 0 ? usualNameFirstname?.family[0] : undefined;
        const usualFirstname =
          Array.isArray(usualNameFirstname?.given) && usualNameFirstname?.given?.length > 0 ? usualNameFirstname?.given[0] : undefined;
        if (usualName) {
          this.patientForm.get("useName").patchValue(usualName, { emitEvent: false, onlySelf: true });
        }
        if (usualFirstname) {
          this.patientForm.get("useFirstname").patchValue(usualFirstname, { emitEvent: false, onlySelf: true });
        }

        this.patientForm
          .get("ins")
          .patchValue(this.data?.patient.patient.identifier.find((i) => i.use === FHIRHelper.USE_MATRICULE_INS && !i.period.end)?.value, {
            emitEvent: false,
            onlySelf: true,
          });
        this.patientForm
          .get("oid")
          .patchValue(
            this.data?.patient.patient.identifier.find((i) => i.use === FHIRHelper.USE_MATRICULE_INS && !i.period.end)?.type?.code,
            { emitEvent: false, onlySelf: true }
          );
        const latestIdentityIdx = this.data?.patient.patient.identity?.length;
        if (latestIdentityIdx !== undefined && latestIdentityIdx > 0) {
          const latestIdentity = this.data.patient.patient.identity[latestIdentityIdx - 1];
          this.identityStatus = +latestIdentity.status as IDENTITY_STATUS;
          this.identityFromINSI = this.identityStatus === IDENTITY_STATUS.recovered || this.identityStatus === IDENTITY_STATUS.qualified;
          this.patientForm
            .get("identityVerification")
            .patchValue(Tools.isDefined(latestIdentity.verification) ? +latestIdentity.verification : null, {
              emitEvent: false,
              onlySelf: true,
            });
          this.patientForm
            .get("identityVerificationMethod")
            .patchValue(Tools.isDefined(latestIdentity.method) ? +latestIdentity.method : null, { emitEvent: false, onlySelf: true });
          this.newIdentities = this.data.patient.patient.identity;
        }
        this.disableINSRetrievedField();
      }
    }
    const val = selectedOrgs && selectedOrgs.length > 0 ? selectedOrgs[0] : "none";
    if (!this.alreadyMonitoredByAnotherService && !this.userService.isMonitoringUser) {
      if (!this.patientForm.contains("monitoringOrgsLink")) {
        this.patientForm.addControl("monitoringOrgsLink", new UntypedFormControl("none"));
      } else {
        this.monitLinkSub$?.unsubscribe();
      }
      this.patientForm.get("monitoringOrgsLink").patchValue(val);
      this.setupMonitoringServices(val, selectedServices);
      this.setupMonitOrgWatch();
    } else if (!this.alreadyMonitoredByAnotherService) {
      this.setupMonitoringServices(val, selectedServices);
    }
  }

  public getPractitionerName(practitioner: Practitioner): string {
    return this.practitionerService.getPractitionerName(practitioner);
  }

  public computeFormData(): void {
    this.$allPractitioners = this.practitionerService.listForService(this.selectedDepartment?.reference);
    this.$allPractitioners
      .pipe(
        first(),
        takeUntil(this.onDestroy$),
        concatMap((p) => {
          this.allPractitioners = p;
          return this.$insuOrganizations;
        }),
        concatMap((i) => {
          this.availableInsurances = i;
          this.filteredInsurances = i;
          return this.$preferences;
        }),
        concatMap((prefs) => {
          this.goToCp = this.isAnonymous ? true : prefs ? prefs.goToCp : true;
          return of(true);
        })
      )
      .subscribe((success) => {
        if (success) {
          this.initData();
        }
      });
  }

  public updateDepartment(event: MatSelectChange): void {
    this.selectedDepartment = event.value;
    this.setAvailableInsurancesFromSelectedDepartment();
    this.practitionerService
      .listForService(this.selectedDepartment.reference)
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe((p) => {
        this.allPractitioners = p;
        this.initData();
      });
  }

  public updateOrg(event: MatSelectChange): void {
    this.selectedOrg = event.value;
    this.healthcareService
      .list(this.selectedOrg.reference)
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe((services) => {
        this.departments = services.map((s) => s.asReference);
        this.selectedDepartment = this.departments[0];
        if (this.selectedDepartment) {
          this.patientForm.controls.healthcareService.setValue(this.selectedDepartment);
          this.setAvailableInsurancesFromSelectedDepartment();
          this.practitionerService
            .listForService(this.selectedDepartment.reference)
            .pipe(first(), takeUntil(this.onDestroy$))
            .subscribe((p) => {
              this.allPractitioners = p;
              this.initData();
            });
        }
      });
  }

  public compareReference(o1: Reference, o2: Reference): boolean {
    return o1.reference === o2.reference;
  }

  public atLeastOneValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    const controls = control.controls;
    if (controls) {
      const theOne = Object.keys(controls).findIndex((key) => controls[key].value !== null && controls[key].value !== undefined);
      if (theOne === -1) {
        return { atLeastOneRequired: true };
      }
    }
  };

  public phoneValidator: ValidatorFn = (control: AbstractControl): { [key: string]: true } | null => {
    if (this.phoneAlreadyTaken) {
      return { phoneAlreadyTaken: true };
    }
    return Tools.phoneValidator(control);
  };

  public emailValidator: ValidatorFn = (): ValidationErrors | null => {
    return this.emailAlreadyTaken ? { emailAlreadyTaken: true } : null;
  };

  public resetEmailAlreadyTaken(): void {
    this.emailAlreadyTaken = false;
  }

  public resetPhoneAlreadyTaken(): void {
    this.phoneAlreadyTaken = false;
  }

  public sendMail(): void {
    const mailText = "mailto:contact@comunicare.be?subject=Practitioner whish to use an existing email or phone";
    // TODO : choose correct mail subject
    window.location.href = mailText;
  }

  public insuranceDisplay(insurance: Organization): string {
    return insurance ? insurance.organizationName : "";
  }

  public navigateToPatientPage(patientSaved: Patient): void {
    const patientId = FHIRHelper.getMainIdentifier(patientSaved).value;
    const b64Id = Base64Helper.utf8_to_b64(patientId);
    this.router.navigateByUrl(`/patient;id=${b64Id}`, {
      state: undefined,
    });
  }

  /**
   * Compute the value of availableInsurances based on the country of the selected department (when All is selected)
   */
  public setAvailableInsurancesFromSelectedDepartment(): void {
    const selectedDepartmentCountry = this.healthcareService
      .availableServices()
      .find((service) => service.mainId === this.selectedDepartment.reference).location.country;

    // empty input from search when changing department
    if (this.insInput?.nativeElement?.value) {
      this.insInput.nativeElement.value = "";
    }
    this.filteredInsurances = undefined;
    this.$insuOrganizations = this.organisationService.listSome("ins", selectedDepartmentCountry);
    this.$insuOrganizations.pipe(first()).subscribe((ins) => {
      this.availableInsurances = ins;
      this.filteredInsurances = ins;
    });
  }

  public insuranceAutoCompleteClose(): void {
    // Empty field when no insurance is selected from the list
    if (Tools.isString(this.patientForm.get("insurance").value)) {
      this.patientForm.get("insurance").setValue(undefined);
    }
  }

  private insPatientIsValid(response: InsResponse): boolean {
    return (
      response?.CodeCR === INSIReturnCode.ONE_INDENTITY_FOUND &&
      response?.DateNaissance?.length > 0 &&
      moment(response?.DateNaissance).isValid() &&
      response?.Prenom?.length > 0 &&
      response?.LieuNaissance?.length > 0 &&
      response?.ListePrenom?.length > 0 &&
      response?.NomNaissance?.length > 0 &&
      response?.Sexe?.length > 0
    );
  }

  public async searchForInsiPatient($event: boolean): Promise<void> {
    this.searchFinished = false;
    this.isSearching4InsiPatient = $event;
    this.searchIsLoading = $event;
    if (this.isSearching4InsiPatient) {
      const name = this.patientForm.get("name").value;
      let firstnames: string[] = this.patientForm
        .get("firstnames")
        .value?.split(",")
        ?.map((s) => s.trim())
        ?.filter(Boolean);

      if (!firstnames) {
        firstnames = [];
      }

      if (this.patientForm.get("firstname").value) {
        firstnames.unshift(this.patientForm.get("firstname").value);
      }
      firstnames = firstnames.filter(ArrayHelper.onlyUnique);

      const gender = this.patientForm.get("gender").value === "male" ? "M" : "F";
      const birthDate = moment(this.patientForm.get("birthDate").value).format("YYYY-MM-DD");
      // test each name separately
      for (const firstname of firstnames) {
        this.foundInsPatient = await this.insService.getINSIPatient(name, firstname, gender, birthDate);
        if (this.insPatientIsValid(this.foundInsPatient)) {
          this.searchIsLoading = false;
          this.searchFinished = true;
          return;
        }
      }
      // if nothing is found, we test with all the first names at once
      if (firstnames.length > 1) {
        this.foundInsPatient = await this.insService.getINSIPatient(
          name,
          firstnames.reduce((f1, f2) => f1 + " " + f2),
          gender,
          birthDate
        );
        if (this.insPatientIsValid(this.foundInsPatient)) {
          this.searchIsLoading = false;
          this.searchFinished = true;
          return;
        }
      }

      const birthplace: string = this.patientForm.get("birthplace").value?.reference
        ? this.patientForm.get("birthplace").value.reference
        : undefined;

      if (birthplace) {
        // if nothing is found, try each first name separately, adding the place of birth (if available).
        for (const firstname of firstnames) {
          this.foundInsPatient = await this.insService.getINSIPatient(name, firstname, gender, birthDate, birthplace);
          if (this.insPatientIsValid(this.foundInsPatient)) {
            this.searchIsLoading = false;
            this.searchFinished = true;
            return;
          }
        }
        // if still nothing found, try again with all first names and place of birth (if available).
        if (firstnames.length > 1) {
          this.foundInsPatient = await this.insService.getINSIPatient(
            name,
            firstnames.reduce((f1, f2) => f1 + " " + f2),
            gender,
            birthDate,
            birthplace
          );
          if (this.insPatientIsValid(this.foundInsPatient)) {
            this.searchIsLoading = false;
            this.searchFinished = true;
            return;
          }
        }
      }
      if (this.foundInsPatient?.CodeCR === INSIReturnCode.ONE_INDENTITY_FOUND && !this.insPatientIsValid(this.foundInsPatient)) {
        this.notUsableInsPatient = true;
      }
    }
    this.searchFinished = true;
    this.searchIsLoading = false;
  }

  public transformToUpperCase(value: string, control: string): void {
    if (this.country === this.FRANCE) {
      value = value.trim();
      this.patientForm.get(control).patchValue(value.toUpperCase(), { emitEvent: false, onlySelf: true });
      if (control === "firstnames" && value.includes(",") && !this.patientForm.get("firstname").value) {
        const firstname = value
          .split(",")
          .map((s) => s.trim())
          .filter(Boolean)[0];
        if (firstname) {
          this.patientForm.get("firstname").patchValue(firstname.toUpperCase(), { emitEvent: true, onlySelf: true });
        }
      } else if (control === "firstnames" && !value.includes(",") && !this.patientForm.get("firstname").value) {
        this.patientForm.get("firstname").patchValue(value.toUpperCase(), { emitEvent: true, onlySelf: true });
      }
    }
  }

  public async useFoundInsPatient(): Promise<void> {
    this.searchIsLoading = true;
    this.patientForm.get("name").patchValue(this.foundInsPatient?.NomNaissance?.toUpperCase(), { emitEvent: false, onlySelf: true });
    this.patientForm
      .get("firstnames")
      .patchValue(this.foundInsPatient?.ListePrenom?.join(", ").toUpperCase(), { emitEvent: false, onlySelf: true });
    this.patientForm.get("gender").patchValue(this.foundInsPatient?.Sexe === "M" ? "male" : "female", { emitEvent: false, onlySelf: true });
    this.patientForm.get("birthDate").patchValue(this.foundInsPatient?.DateNaissance, { emitEvent: false, onlySelf: true });
    this.patientForm
      .get("birthplace")
      .patchValue(await this.getTownByCode(this.foundInsPatient?.DateNaissance, this.foundInsPatient?.LieuNaissance), {
        emitEvent: false,
        onlySelf: true,
      });
    this.refreshTownInput();
    this.patientForm.get("ins").patchValue(this.foundInsPatient?.MatriculeIns, { emitEvent: false, onlySelf: true });
    this.patientForm.get("oid").patchValue(this.foundInsPatient?.Oid, { emitEvent: false, onlySelf: true });
    this.insHistory = this.foundInsPatient.historiqueIns;
    this.showMore = true;
    this.identityFromINSI = true;
    this.computeIdentityStatus(MODIFICATION_TYPE.insiReturn);
    this.disableINSRetrievedField();
    this.foundInsPatient = undefined;
    this.searchIsLoading = false;
    this.isSearching4InsiPatient = false;
    const firstnamesFirst = this.patientForm
      .get("firstnames")
      .value?.split(",")
      .map((s) => s.trim())
      .filter(Boolean)[0];
    const writtenFirstname = this.patientForm.get("firstname").value?.split(" ")[0];
    if (firstnamesFirst !== writtenFirstname) {
      this.patientForm.get("firstname").invalid;
      this.patientForm.get("firstname").setErrors({
        isNotACorrectINSFirstnameError: true,
      });
    }
    // Mark fields modified by INS as touched to show error when necessary
    this.patientForm.get("ins").markAsTouched();
    this.patientForm.get("oid").markAsTouched();
    this.patientForm.get("firstname").updateValueAndValidity(); // firstname value must be re-evaluated to check validity
  }

  public cancelInsSearch(): void {
    this.isSearching4InsiPatient = false;
    this.foundInsPatient = undefined;
  }

  public onBirthdateChange(): void {
    // reset birthplace
    this.patientForm.get("birthplace").setValue(undefined, { emitEvent: false, onlySelf: true });
    this.refreshTownInput();
  }

  private async getTownByCode(date: string, code: string): Promise<IReference> {
    let town = await this.townsService.getTownByCode(date, code);
    if (!Tools.isDefined(town)) {
      town = {
        reference: code,
        display: "",
      };
    }
    return town;
  }

  private refreshTownInput() {
    // we need to set birthDate to undefined to trigger the ngIf of the birthplace input
    const value = this.patientForm.get("birthDate").value;
    this.patientForm.get("birthDate").setValue(undefined, { emitEvent: false, onlySelf: true });
    this.cdr.detectChanges();
    this.patientForm.get("birthDate").setValue(value, { emitEvent: false, onlySelf: true });
  }

  private checkUsersPermissions(): void {
    this.canUpdateRetrievedIdentityPermission = this.userService.isAuthorizedSync(null, "patient/retrievedIdentity", "PUT");
    this.canUseINSiService = this.userService.isAuthorizedSync(null, "dashboard/ins", "GET");
  }

  /**
   * Disable the INS fields of the FR patient if the user does not
   * have the right to update them
   */
  private disableINSRetrievedField(): void {
    if (!this.canUpdateRetrievedIdentityPermission && this.identityFromINSI) {
      this.patientForm.get("name").disable({ emitEvent: false, onlySelf: true });
      this.patientForm.get("firstnames").disable({ emitEvent: false, onlySelf: true });
      this.patientForm.get("gender").disable({ emitEvent: false, onlySelf: true });
      this.patientForm.get("birthDate").disable({ emitEvent: false, onlySelf: true });
      this.patientForm.get("ins").disable({ emitEvent: false, onlySelf: true });
      this.patientForm.get("oid").disable({ emitEvent: false, onlySelf: true });
    }
  }

  private enableFields(): void {
    this.patientForm.get("name").enable({ emitEvent: false, onlySelf: true });
    this.patientForm.get("firstnames").enable({ emitEvent: false, onlySelf: true });
    this.patientForm.get("gender").enable({ emitEvent: false, onlySelf: true });
    this.patientForm.get("birthDate").enable({ emitEvent: false, onlySelf: true });
    this.patientForm.get("ins").enable({ emitEvent: false, onlySelf: true });
    this.patientForm.get("oid").enable({ emitEvent: false, onlySelf: true });
  }

  public oidValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control.value?.length) {
      const oid = MatriculeInsHelper.computeDisplayOID(control.value);
      return oid === "" ? { invalidOID: true } : null;
    }
  };

  public firstnameValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control.value?.length && control.value.length > 0 && this.country === this.FRANCE && this.identityFromINSI) {
      const firstnames = MatriculeInsHelper.namesStringToArray(this.patientForm.get("firstnames").value);
      const firstFirstname = MatriculeInsHelper.namesStringToArray(control.value);

      for (let index = 0; index < firstFirstname.length; index++) {
        if (firstFirstname[index] !== firstnames[index]) {
          return {
            isNotACorrectINSFirstnameError: true,
          };
        }
      }
    }
    return null;
  };

  public NameValidator: ValidatorFn = (control: AbstractControl): { [key: string]: true } | null => {
    const name: string = control.value;

    const firstLetterResult = this.checkFirstLetter(name);
    if (firstLetterResult) return firstLetterResult;

    const consecutiveApostrophesResult = this.checkConsecutiveApostrophes(name);
    if (consecutiveApostrophesResult) return consecutiveApostrophesResult;

    const consecutiveSpacesResult = this.checkConsecutiveSpaces(name);
    if (consecutiveSpacesResult) return consecutiveSpacesResult;

    // Check no more than 2 dashes
    if (name?.search("---") >= 0) {
      return { moreThanTwoDashes: true };
    }

    // Check for combinaison of space and apostrophe
    if (name?.search("' ") >= 0 || name?.search(" '") >= 0) {
      return { apostropheAndSpaceCombined: true };
    }
    return null;
  };

  public FirstNamesValidator: ValidatorFn = (control: AbstractControl): { [key: string]: true } | null => {
    if (control.value?.length && this.country === this.FRANCE) {
      const firstnames: string[] = control.value
        ?.split(",")
        .map((s) => s.trim())
        .filter(Boolean);

      for (const firstname of firstnames) {
        const firstLetterResult = this.checkFirstLetter(firstname);
        if (firstLetterResult) return firstLetterResult;

        const consecutiveApostrophesResult = this.checkConsecutiveApostrophes(firstname);
        if (consecutiveApostrophesResult) return consecutiveApostrophesResult;

        const consecutiveSpacesResult = this.checkConsecutiveSpaces(firstname);
        if (consecutiveSpacesResult) return consecutiveSpacesResult;

        // The last character must be different from the hyphen or apostrophe
        const lastChar: string = firstname?.charAt(firstname.length - 1);
        if (lastChar === "'" || lastChar === "-") {
          return { lastCharInvalid: true };
        }

        // Check no more than 1 dashes
        if (firstname?.search("--") >= 0) {
          return { moreThanOneDash: true };
        }
      }
      return null;
    }

    return null;
  };

  public checkConsecutiveApostrophes(s: string): { [key: string]: true } {
    if (s?.search("''") >= 0) {
      return { tooManyApostrophes: true };
    }
  }

  public checkConsecutiveSpaces(s: string): { [key: string]: true } {
    if (s?.search("  ") >= 0) {
      return { tooManySpaces: true };
    }
  }

  public checkFirstLetter(s: string): { [key: string]: true } {
    const firstChar: string = s?.charAt(0);
    if (firstChar === " " || firstChar === "-") {
      return { firstCharInvalid: true };
    }
  }

  public makeBirthplaceRequired(): void {
    // townServerSideSearch is undefined when birthdate is not set. in that case, form submission will be stopped by patientForm validation
    if (!this.townServerSideSearch) {
      return;
    }
    this.townServerSideSearch.townServerSideFilteringCtrl.addValidators(Validators.required);
    this.townServerSideSearch.townServerSideFilteringCtrl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.birthplaceRequired = true; // necessary to add the required attribute on the actual input for accessibility and to show the * on the field
  }

  public preventCharacter(event: KeyboardEvent, characters: PREVENTCHARACTER[], formControlName?: string): void {
    // we need this timeout because the replacement must be after the change of the input value
    setTimeout(() => {
      const value = PreventCharacter.preventMultiple(event, characters);
      this.patientForm.get(formControlName).setValue(value);
    }, 0);
  }
}
