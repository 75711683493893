import { Identifier } from "./identifier.interface";
import { Reference } from "./reference.interface";
import { IEntity } from "./sharedInterfaces";
import { ITranslation } from "./translation.interface";

export enum RULE_CATEGORY {
  ALERT = 1,
  REFERENCE_VALUE = 2,
}

export enum RULE_TYPE_TARGET {
  OBSERVATION = 1,
  QUESTIONNAIRE = 2,
  NO_DATA_TRANSMISSION = 3,
}

export enum TRIGGER_COMPUTATION {
  VALUE = 1, // trigger directly on value entered
  COMPUTED = 2, // trigger on computed values
}

/**
 * Alert Level
 */
export enum RULE_LEVEL {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
  PRIORITY = 9, // above all others
}

/**
 * Alert Type
 */
export enum RESULT_TYPE {
  MESSAGE = 1, // alert is a message
}

/**
 * Field type in formula
 */
export enum FIELD_TYPE {
  ABSOLUTE_VALUE = 0, // field is an absolute value (ex: 2, 37, 193)
  PAST_VALUE = 1, // field is based on past value  (ex: value entered 3 days ago)
  REFERENCE_VALUE = 2, // field is base on a reference value (ex: patient reference weight)
  CURRENT_VALUE = 3, // field is the current entered value
}

/**
 * Formula Operator
 */
export enum RULE_FORMULA_OPERATOR {
  PLUS = 1,
  MINUS = 2,
  MULTIPLY = 3,
  DIVISION = 4,
}

/**
 * Trigger rule definition
 */
export interface RuleTrigger {
  computation: TRIGGER_COMPUTATION; // VALUE / COMPUTED
  bounds?: Range; // When VALUE => low/high limits => [LOW, HIGH[
  formula?: RuleFormula; // When COMPUTED => computation formula
}

/**
 * Trigger Formula when trigger source is "COMPUTED"
 */
export interface RuleFormula {
  fields: RuleField[]; // formula is performed using fields order
}

/**
 * Formula field definition
 */
export interface RuleField {
  fieldType: FIELD_TYPE; // ABSOLUTE_VALUE, PAST_VALUE, REFERENCE_VALUE, CURRENT_VALUE
  operator: RULE_FORMULA_OPERATOR; // PLUS, MINUS, MULTIPLY, DIVISION
  value?: string;
  /* if ABSOLUTE_VALUE type ==> this is a absolute value (like '46')
   *    if PAST_VALUE ==> this is the count of days to go to the past
   *    if REFERENCE_VALUE ==> this is the name of the field
   *    if CURRENT_VALUE ==> not used
   */
}

/**
 * Once Rule has been trigger, result is defined here
 */
export interface RuleResult {
  resultType: RESULT_TYPE; // MESSAGE
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any | ITranslation;
}

/**
 * Rule Definition (triggers and results)
 * formula concept:
 */
export interface RuleDefinition {
  level: RULE_LEVEL; // LOW / MEDIUM / HIGH
  triggers?: RuleTrigger[]; // trigger for rule
  triggerFormula?: string; // javascript pseudo-code to compute trigger event (must return 'true' or 'false')
  repeatObservation: number;
  results: RuleResult[];
  resultsPractitionner?: RuleResult[];
  repeatOnly?: number;
  idRule?: number;
}

/**
 * Meta data info on Rule
 */
export interface RuleMeta {
  category: RULE_CATEGORY;
  typeTarget: RULE_TYPE_TARGET;
  healthcareService: Reference[]; // healthcare services using this rule
  author: Reference; // creator of this rule
  scopes: Reference[]; // careplans references associated to this rule
  targets: Reference[]; // observation references (contains component Loinc codes)
}

/**
 * Once Rule has been trigger, result is defined here
 */
export interface IRuleResult {
  resultType: RESULT_TYPE; // MESSAGE
  value: any | ITranslation; // alert message or ... (depends on 'resultType')
  variableToInterpolate?: string; // optionnal : allows you to define a variable to interpolate in the message (in the field "value")
}
/**
 * Formula field definition
 */
export interface IRuleField {
  fieldType: FIELD_TYPE; // ABSOLUTE_VALUE, PAST_VALUE, REFERENCE_VALUE, CURRENT_VALUE
  operator: RULE_FORMULA_OPERATOR; // PLUS, MINUS, MULTIPLY, DIVISION
  value?: string;
  /* if ABSOLUTE_VALUE type ==> this is a absolute value (like '46')
   *    if PAST_VALUE ==> this is the count of days to go to the past
   *    if REFERENCE_VALUE ==> this is the name of the field
   *    if CURRENT_VALUE ==> not used
   */
}

/**
 * Trigger Formula when trigger source is "COMPUTED"
 */
export interface IRuleFormula {
  fields: IRuleField[]; // formula is performed using fields order
}
/**
 * Trigger rule definition
 */
export interface IRuleTrigger {
  computation: TRIGGER_COMPUTATION; // VALUE / COMPUTED
  bounds?: Range; // When VALUE => low/high limits => [LOW, HIGH[
  formula?: IRuleFormula; // When COMPUTED => computation formula
}

/**
 * Trigger rule definition
 */
export interface IRuleTrigger {
  computation: TRIGGER_COMPUTATION; // VALUE / COMPUTED
  bounds?: Range; // When VALUE => low/high limits => [LOW, HIGH[
  formula?: IRuleFormula; // When COMPUTED => computation formula
}
export interface IRuleDefinition {
  identifier: string;
  level: RULE_LEVEL; // LOW / MEDIUM / HIGH
  triggers?: IRuleTrigger[]; // trigger for rule
  triggerFormula?: string; // javascript pseudo-code to compute trigger event (must return 'true' or 'false')
  repeatObservation: number;
  results: IRuleResult[];
  resultsPractitionner?: IRuleResult[];
  repeatOnly?: number;
  idRule?: number;
}

export interface IRule extends IEntity {
  identifier: Identifier; // rule identifier
  meta: RuleMeta;
  definitions: IRuleDefinition[]; // rule definition for LOW, MEDIUM and/or HIGH level
}
