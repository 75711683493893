import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { FileLogger } from "../helpers/fileLogger";
import { AppError } from "../models/app-error.interface";

export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = "";
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = error.error.message;
        } else {
          // server-side error
          errorMessage = error.message;
        }
        FileLogger.warn("HttpErrorInterceptor", "intercept", errorMessage);
        return throwError({
          global: true,
          code: error.status,
          message: errorMessage,
        } as AppError);
      })
    );
  }
}
