import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Tools } from "src/app/helpers/tools";
import { IKnowMedia, KNOW_DOC_CATEGORY } from "src/app/models/knowledge.interface";
import { KnowledgeOverviewComponent } from "src/app/pages/knowledge-media-editor/knowledge-overview/knowledge-overview.component";
import { KnowledgeService } from "src/app/providers/knowledge.service";
import { SessionService } from "src/app/providers/session.service";

@Component({
  selector: "app-knowledge-media-list-modal",
  templateUrl: "./knowledge-media-list-modal.component.html",
  styleUrls: ["./knowledge-media-list-modal.component.scss"],
})
export class KnowledgeMediaListModalComponent implements OnInit, OnDestroy {
  public isLoading = true;
  private onDestroy$ = new Subject<void>();
  public medias: IKnowMedia[] = [];
  public columnsToDisplay = ["Visibility", "Description", "Actions"];

  constructor(
    private knowledgeService: KnowledgeService,
    private sessionService: SessionService,
    public dialogRef: MatDialogRef<KnowledgeMediaListModalComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      careplanId?: string;
      patientId?: string;
      medias?: IKnowMedia[];
      translateKeyTitle?: string;
    }
  ) {}

  ngOnInit(): void {
    if (Tools.isDefined(this.data.medias)) {
      this.medias = this.data.medias;
      this.isLoading = false;
    } else {
      this.knowledgeService
        .getMediasCareplan(this.data.patientId, KNOW_DOC_CATEGORY.DESCRIPTION, this.data.careplanId, this.sessionService.userLang)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((medias) => {
          this.medias = medias;
          this.isLoading = false;
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Open Modal to show an overview of the knowledge
   */
  public showOverview(media: IKnowMedia): void {
    this.dialog.open(KnowledgeOverviewComponent, {
      data: { media: media, modaleMode: true },
      width: "45%",
      height: "90%",
    });
  }
}
