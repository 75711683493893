<h1 mat-dialog-title *ngIf="dialogOption.title">{{ dialogOption.title }}</h1>

<mat-dialog-content
  [ngClass]="{
    success: dialogOption.type === TYPES.SUCCESS,
    error: dialogOption.type === TYPES.ERROR
  }"
>
  <div class="header" *ngIf="dialogOption.type === TYPES.SUCCESS">
    <i class="fas fa-check-circle fa-4x"></i>
  </div>
  {{ dialogOption.message }}
</mat-dialog-content>
<mat-dialog-actions *ngIf="dialogOption.type === TYPES.CONFIRM">
  <button mat-button (click)="onNoClick()" cdkFocusInitial>
    {{ "common.no" | translate }}
  </button>
  <button mat-button (click)="onYesClick()">
    {{ "common.yes" | translate }}
  </button>
</mat-dialog-actions>
<mat-dialog-actions class="center" *ngIf="dialogOption.type === TYPES.INFORMATION">
  <button mat-button mat-dialog-close color="primary">Ok</button>
</mat-dialog-actions>
<mat-dialog-actions class="center" *ngIf="dialogOption.type === TYPES.ERROR">
  <button mat-button mat-dialog-close color="error">Ok</button>
</mat-dialog-actions>

<div *ngIf="dialogOption.type === TYPES.SUCCESS" class="success-container mt-2">
  <mat-dialog-actions class="center">
    <button mat-flat-button color="primary" (click)="onClose()">
      {{ "btn.close" | translate }}
    </button>
    <button *ngIf="go2PatientAfterInscription === undefined" mat-flat-button color="primary" (click)="onCloseAndRedirect()">
      {{ "btn.goToPatient" | translate }}
    </button>
  </mat-dialog-actions>
  <mat-checkbox *ngIf="go2PatientAfterInscription === undefined" [(ngModel)]="savePref" class="m-auto">
    {{ "confirmationDialog.keepChoice" | translate }}
  </mat-checkbox>
</div>
