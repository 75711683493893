import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable, of } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import { FHIRHelper } from "../helpers/FHIRhelper";
import { FileLogger } from "../helpers/fileLogger";
import { SlimUserInfo } from "../models/account.interface";
import { AppError } from "../models/app-error.interface";
import { Identifier } from "../models/identifier.interface";
import { AddPractitionerData, IPractitioner, IPractitionerInfo, TELECOM_SYSTEM } from "../models/practitioner.interface";
import { Practitioner } from "../models/practitioner.model";
import { Reference } from "../models/reference.interface";
import { PostPractitionerBody, PractitionerApiService } from "./api/practitioner-api.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class PractitionerService {
  constructor(private practitionerApi: PractitionerApiService, private userService: UserService) {}

  public list(): Observable<Practitioner[]> {
    return this.practitionerApi.list().pipe(
      map((values) => {
        return values.map((v) => new Practitioner(v));
      })
    );
  }

  public getInfo(caremateId: string): Observable<SlimUserInfo> {
    return this.practitionerApi.getPractitionerInfo(caremateId);
  }
  public update(Qdata: AddPractitionerData): Observable<Practitioner> {
    return this.practitionerApi.update(this.getUpdateBodyFromQdata(Qdata));
  }
  /**
   * Update practitioner own data.
   * @param practitionerFields the practitionner fields to update
   * @param userInfo (optional) if not set, it will update only practitioner fields that are not in the user account, if set it can update the birthdate, the name, the national number... (not the role)
   * @returns
   */
  public updateOwnInfos(practitionerFields?: unknown, userInfo?: unknown): Observable<Practitioner> {
    if (!practitionerFields) {
      return of(null);
    }
    const data: PostPractitionerBody = {
      userInfo,
      practitioner: null,
      practitionerFields,
    };
    return this.practitionerApi.updateOwnInfos(data);
  }

  public disable(practitioner: Practitioner): Observable<Practitioner> {
    practitioner.active = false;
    return this.practitionerApi.update({ practitioner, userInfo: null });
  }

  public create(Qdata: AddPractitionerData): Observable<Practitioner> {
    const body = this.getCreateBodyFromQdata(Qdata);
    return this.practitionerApi.create(body);
  }

  public getCreateBodyFromQdata(Qdata: AddPractitionerData): PostPractitionerBody {
    const idHospital = {
      label: Qdata.title + " " + Qdata.firstname + " " + Qdata.name,
      system: Qdata.organisation.reference,
      value: Qdata.internalId,
      use: "hospital",
    };

    const idInami = Qdata.inami
      ? {
          label: Qdata.title + " " + Qdata.firstname + " " + Qdata.name,
          system: "inami",
          value: Qdata.inami,
          use: "inami",
        }
      : undefined;
    const idAns = Qdata.ansId;
    const practitionerRole = [
      {
        managingOrganization: Qdata.organisation,
        healthcareService: Qdata.services,
        specialty: [],
        role: { coding: [Qdata.qualif] },
      },
    ];
    if (Qdata.ansPractitionerRole?.length) {
      practitionerRole.push(...Qdata.ansPractitionerRole);
    }
    const fullId: Identifier[] = [idInami, idHospital, idAns].filter((v) => v);

    return {
      userInfo: {
        role: Number(Qdata.role),
        nationalnumber: Qdata.nationalNumber,
        authcodeUncrypt: Qdata.activationCode,
        needMail: Qdata.needMail,
        customLogin: Qdata.login,
        accessGroups: Qdata.accessGroups,
      },
      practitioner: {
        resourceType: "Practitioner",
        active: Qdata.active,
        gender: Qdata.gender,
        birthDate: moment(Qdata.birthDate).format("YYYY-MM-DD"),
        photo: "",
        qualification: [],
        communication: [
          {
            preferred: true,
            language: {
              coding: [
                {
                  code: Qdata.userLang,
                  system: "ISO 639-1",
                },
              ],
            },
          },
        ],
        practitionerRole: practitionerRole,
        address: [],
        telecom: this.getTelecom(Qdata),
        name: {
          use: "",
          prefix: [Qdata.title],
          given: [Qdata.firstname],
          family: [Qdata.name],
        },
        identifier: fullId,
        ansRole: Qdata.ansRole,
      },
    };
  }

  public getUpdateBodyFromQdata(Qdata: AddPractitionerData): PostPractitionerBody {
    const idHospital = Qdata.internalId
      ? ({
          label: Qdata.title + " " + Qdata.firstname + " " + Qdata.name,
          system: Qdata.organisation.reference,
          value: Qdata.internalId,
          use: "hospital",
        } as Identifier)
      : undefined;

    const idInami = Qdata.inami
      ? ({
          label: Qdata.title + " " + Qdata.firstname + " " + Qdata.name,
          system: "inami",
          value: Qdata.inami,
          use: "inami",
        } as Identifier)
      : undefined;
    const idAns = Qdata.ansId;
    const caremateId = Qdata.user.caremateId;

    const practitionerRole = [
      {
        managingOrganization: Qdata.organisation,
        healthcareService: Qdata.services,
        specialty: [],
        role: { coding: [Qdata.qualif] },
      },
    ];
    practitionerRole.push(...Qdata.ansPractitionerRole);
    const fullId: Identifier[] = [caremateId, idInami, idHospital, idAns].filter((v) => v);
    return {
      userInfo: {
        role: Number(Qdata.role),
        nationalnumber: Qdata.nationalNumber,
        authcodeUncrypt: Qdata.activationCode,
        needMail: Qdata.needMail,
        accessGroups: Qdata.accessGroups,
      },
      practitioner: {
        resourceType: "Practitioner",
        _id: Qdata.user?._id,
        active: Qdata.active,
        gender: Qdata.gender,
        birthDate: moment(Qdata.birthDate).format("YYYY-MM-DD"),
        photo: "",
        qualification: [],
        communication: [
          {
            preferred: true,
            language: {
              coding: [
                {
                  code: Qdata.userLang,
                  system: "ISO 639-1",
                },
              ],
            },
          },
        ],
        practitionerRole: practitionerRole,
        address: [],
        telecom: this.getTelecom(Qdata),
        name: {
          use: "",
          prefix: [Qdata.title],
          given: [Qdata.firstname],
          family: [Qdata.name],
        },
        identifier: fullId,
        ansRole: Qdata.ansRole,
      },
    };
  }

  public getTelecom(Qdata: AddPractitionerData): Identifier[] {
    return [
      {
        system: TELECOM_SYSTEM.PHONE,
        value: Qdata.phone0,
        label: Qdata.phoneComment0,
      },
      {
        system: TELECOM_SYSTEM.PHONE,
        value: Qdata.phone1,
        label: Qdata.phoneComment1,
      },
      {
        system: TELECOM_SYSTEM.PHONE,
        value: Qdata.phone2,
        label: Qdata.phoneComment2,
      },
      {
        system: TELECOM_SYSTEM.PHONE,
        value: Qdata.phone3,
        label: Qdata.phoneComment3,
      },
      {
        system: TELECOM_SYSTEM.MAIL,
        value: Qdata.mail0,
        label: Qdata.mailComment0,
      },
      {
        system: TELECOM_SYSTEM.MAIL,
        value: Qdata.mail1,
        label: Qdata.mailComment1,
      },
      {
        system: TELECOM_SYSTEM.MAIL,
        value: Qdata.mail2,
        label: Qdata.mailComment2,
      },
      {
        system: TELECOM_SYSTEM.MAIL,
        value: Qdata.mail3,
        label: Qdata.mailComment3,
      },
      {
        system: TELECOM_SYSTEM.MSSANTE,
        value: Qdata.msSante,
      },
    ].filter((t) => t.value);
  }

  /*
   * Return the role of a practionier, if it exists
   */
  public getRole(p: Practitioner): string {
    if (p.practitionerRole && p.practitionerRole[0]) {
      const practitionerRole = p.practitionerRole[0];
      if (practitionerRole.role && practitionerRole.role.coding[0]) {
        return practitionerRole.role.coding[0].code;
      }
    }
    return null;
  }

  public getPractitionerName(practitioner: Practitioner): string {
    if (practitioner?.identifier.length) {
      return practitioner.identifier[0].label;
    }
    return null;
  }

  public getPractitionerReference(practitioner: Practitioner): Reference | null {
    if (practitioner?.identifier.length) {
      const practitionerId = FHIRHelper.getMainIdentifier(practitioner);
      return {
        display: practitionerId?.label,
        reference: practitionerId?.value,
      };
    }
    return null;
  }

  public listForService(service?: string, services?: string[]): Observable<Practitioner[]> {
    const routeName = this.practitionerApi.readRoutes[0];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("PractitionerService", "User does not have access to: GET " + routeName);
          return of([]);
        }
        return this.practitionerApi.listForService(service, services).pipe(
          map((values) => {
            return values.map((v) => new Practitioner(v));
          })
        );
      })
    );
  }

  public createTemplate(): Practitioner {
    const p: IPractitioner = {
      resourceType: "Practitioner",
      active: true,
      gender: "",
      birthDate: "",
      photo: "",
      qualification: [],
      communication: [
        {
          preferred: true,
          language: {
            coding: [
              {
                code: "",
                system: "ISO 639-1",
              },
            ],
          },
        },
      ],
      practitionerRole: [],
      address: [],
      telecom: [],
      name: {
        use: "",
        prefix: [""],
        given: [""],
        family: [""],
      },
      identifier: null,
    };
    return new Practitioner(p);
  }

  public resetPractitionerPassword(caremateId: string, password: string): Observable<Record<string, never>> {
    return this.practitionerApi.resetPractitionerPassword(caremateId, password);
  }

  public sendPractitionerPassword(mail: string, password: string): Observable<Record<string, never>> {
    return this.practitionerApi.sendPractitionerPassword(mail, password);
  }

  public getPractitionersFromDirectory(family: string, given: string, country: string, msSante?: string): Observable<IPractitionerInfo[]> {
    return this.practitionerApi.getPractitionersFromDirectory(family, given, country, msSante);
  }

  public resetOwnPassword(login: string, oldPassword: string, newPassword: string): Observable<AppError> {
    return this.practitionerApi.resetOwnPassword(login, oldPassword, newPassword) as Observable<AppError>;
  }
}
