<div class="drug-server-side-search">
  <mat-form-field class="mat-field" *ngIf="!withoutInput">
    <input
      type="text"
      placeholder="{{ (isCreation ? 'mydrugs.name' : 'mydrugs.updateName') | translate }}"
      matInput
      [formControl]="drugServerSideFilteringCtrl"
      [matAutocomplete]="auto"
      (ngModelChange)="onDrugNameChanged($event)"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="drugDisplay">
      <mat-option [value]="dummyInfo">
        {{ dummyInfo[userLang]?.fullName }}
      </mat-option>
      <mat-option *ngFor="let drug of filteredServerSideDrug | async" [value]="drug">
        {{ drug[userLang]?.fullName }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-autocomplete #autocomp [displayWith]="drugDisplay" *ngIf="withoutInput">
    <mat-option *ngFor="let drug of filteredServerSideDrug | async" [value]="drug" (click)="selected(drug)">
      {{ drug[userLang]?.fullName }}
    </mat-option>
  </mat-autocomplete>
</div>
