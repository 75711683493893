import { BehaviorSubject } from "rxjs";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { Healthcareservice } from "src/app/models/healthcareservice.model";

export class OrganizationDataSource extends MatTableDataSourceExtended<Healthcareservice> {
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  sortingDataAccessor = (data: Healthcareservice, sortHeaderId: string): string => {
    switch (sortHeaderId) {
      case "name":
        return data?.identifier[0]?.label.toLowerCase();
      case "reference":
        return data?.identifier[0]?.value.toLowerCase();
      default:
        return "";
    }
  };

  constructor() {
    super();
  }

  public loadData(healthcareservices: Healthcareservice[]): void {
    this.data = healthcareservices;
  }
}
