import { SortDirection } from "@angular/material/sort";
import { Item } from "../components/item-selector/item-selector.component";
import { IShowAlertLevel } from "../components/patient-alerts/alert-preference/alert-preference.component";
import { ICareplan } from "./careplans.interface";
import { ChartSettings } from "./chart.interface";
import { Entity } from "./entity.interface";
import { Filter } from "./filter.interface";
import { Group } from "./group.model";
import { OBSERVATION_VIEW } from "./observations.interface";
import { Reference } from "./reference.interface";
import { WidgetStored } from "./widget.interface";

export const enum PreferenceContext {
  GLOBAL = "global",
  PATIENT_LIST = "patientList",
  PATIENT_PAGE = "patientPage",
  PRACTITIONER_LIST = "PRACTITIONER_LIST",
  PATIENT_OBSERVATIONS_LIST = "patientObservationsList",
  PATIENT_CAREPLANS_LIST = "patientCareplansList",
  PATIENT_DRUGS_LIST = "patientDrugsList",
  PATIENT_ALERTS_LIST = "patientAlertsList",
  PATIENT_TELECONSULTATIONS_LIST = "patientTeleconsultationsList",
  PATIENT_VITAL_SIGN_LIST = "patientVitalSignList",
  PATIENT_QR_LIST = "patientQRist",
  PATIENT_QR_DETAILS = "patientQRDetails",
  PATIENT_REWARD_LIST = "patientRewardList",
  ADD_PATIENT_FORM = "addPatientForm",
  WIDGET = "widget",
  TILE = "tile",
  WIDGET_ALERTE_LEVEL = "widgetAlertLevel",
  WIDGET_TELECONSULTATIONS = "widgetTeleconsultation",
  WIDGET_LAST_PATIENTS = "widgetLastPatients",
  WIDGET_LAST_ACTIVITY = "widgetLastActivity",
  RELEASE_NOTE = "releaseNote",
  QUESTIONNAIRES_PAGE = "questionnairesPage",
  COMMUNICATIONS_LIST_PAGE = "communicationsListPage",
  WIDGET_PATIENT_LIST = "widgetPatientList",
  WIDGET_PATIENT_COMMUNICATIONS = "widgetPatientCommunications",
  WIDGET_PATIENT_RISK_ASSESSMENT = "widgetPatientRiskAssessment",
  REWARDS_PAGE = "RewardsPage",
  KNOWLEDGE_LIST = "knowledgeList",
  TELECONSULTATIONS_PAGE = "teleconsultationsPage",
  QUIZ_PAGE = "quizPage",
  CONSENTS_PAGE = "consentsPage",
  CAREPLAN_LIST = "careplansList",
  DELETE_REQUEST_PAGE = "DeleteRequestPage",
  STAT_EVENTS_PAGE = "StatEventsPage",
  FHIR_QUESTIONNAIRE_PAGE = "FhirQuestionnairePage",
  DRUG_SCHEMA_PAGE_CYCLE_TAB = "DrugSchemaPageCycleTab",
  DRUG_SCHEMA_PAGE_STEPWISE_TAB = "DrugSchemaPageStepwiseTab",
  WIDGET_PENDING_PRESCRIPTIONS = "widgetPendingPrescriptions",
}

export enum PatientWidgetName {
  CAREPLANS = "careplans",
  OBSERVATIONS = "observations",
  DRUGS = "drugs",
  QUESTIONNAIRES = "questionnaires",
  VITAL_SIGNS = "vitalSigns",
  ALERTS = "alerts",
  TELECONSULTATIONS = "teleConsultations",
  COMMUNICATIONS = "communications",
  RA = "riskAssessment",
  REWARDS = "rewards",
  DOCUMENTS = "documents",
}

export interface Preference {
  context: string;
  parameters: any;
}

export interface PreferenceUser extends Entity {
  caremateIdentifier: string;
  preferences: Preference[];
}

/**
 * Contextual parameter definitions
 */
export interface GlobalParameter {
  preferredLanguage: string;
  usePreferredLanguageWhenConflict?: boolean;
  defaultLandingPage: string;
  preferredOrg: Reference;
  preferredService: Reference;
  keepLayoutFromPatientPage?: boolean;
  showAllWidgetWhenDetails?: boolean;
  inactifPatientDuration?: string;
  go2PatientAfterInscription?: boolean;
}

export interface PatientPageParameter {
  lastFocusWidgetName: PatientWidgetName;
}

export interface TableParameter {
  filters?: Filter[];
  availableColumnItems?: Item[];
  itemsPerPage?: number;
  group?: Group;
  search?: string;
  includeShortDesc?: boolean;
  sortId?: string;
  sortOrder?: SortDirection;
}

export interface WidgetPatientParameter {
  filters: Filter[];
  isBig: boolean;
  selectedCp?: ICareplan;
  settings?: ChartSettings;
  activeOnlyDrugs: boolean;
  allTeleconsultations?: boolean;
  allQresponse?: boolean;
  aggregateByDay?: boolean;
  showAlertsLevel?: IShowAlertLevel;
  preferredView?: OBSERVATION_VIEW;
  activateNorms?: boolean;
}

export interface AddPatientParameter {
  goToCp: boolean;
}

export interface WidgetParameter {
  nbColumn: number;
  widgets: WidgetStored[];
}

export interface TileParameter {
  list: PatientWidgetName[];
}

export interface ReleaseNoteParameter {
  lastReleaseNote: string;
  alwaysShow: boolean;
}

export interface IVersion {
  major: number;
  minor: number;
  patch: number;
}

export interface WidgetFilterParameter {
  filters: Filter[];
}

export interface WidgetPatientListParameter {
  filters: Filter[];
  patientsCount: number;
  search: string;
  groupId: string;
}
