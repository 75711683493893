import { Entity } from "./entity.interface";
import { IReference } from "./sharedInterfaces";
import { IStepwise, IStepwiseDrug } from "./stepwiseDrug.interface";

export class StepwiseDrug extends Entity implements IStepwiseDrug {
  public name: string;
  public public: boolean;
  public stepwises: IStepwise[]; // the length of the array is the number of days of the cycle
  public author?: IReference; // caremateIdentifier
  public organization?: IReference; // organization of the author
  public visibility?: string; // This is not sent from backend but is needed to filter the datas from the table

  constructor(data: IStepwiseDrug) {
    super();
    this.name = data.name;
    this.public = data.public;
    this.stepwises = data.stepwises;
    this.author = data.author;
    this.organization = data.organization;
    this.modified = data.modified;
    this._id = data._id;
  }
}
