import { Pipe, PipeTransform } from "@angular/core";
import { IObservationDefinition } from "../models/observations.interface";
import { ITranslation } from "../models/translation.interface";

@Pipe({ name: "getEffectiveTiming" })
export class GetEffectiveTimingPipe implements PipeTransform {
  transform(effectiveTimingCode: string, ObservationDefinition: IObservationDefinition): ITranslation {
    return ObservationDefinition?.lsTimingWhen?.find((el) => el.when.code === effectiveTimingCode)?.name;
  }
}
