<div class="aboveTableElems">
  <div class="groupsOptions">
    <form class="addGroup" (ngSubmit)="addAccessGroup()" *ngIf="needAdd">
      <mat-form-field appearance="fill" style="width: 400px">
        <mat-label>{{ "accessGroups.groupToAdd" | translate }}</mat-label>
        <input
          type="text"
          placeholder="{{ 'page.accessGroups.name' | translate }}"
          aria-label="Number"
          matInput
          [formControl]="accessGroupsControl"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayGroupFn">
          <mat-option *ngFor="let group of filteredAccessGroups | async" [value]="group">
            {{ group.identifier.label }}
          </mat-option>
        </mat-autocomplete> </mat-form-field
      ><button mat-icon-button type="submit">
        <mat-icon color="primary">check</mat-icon>
      </button>
      <br />
      <p
        style="color: red; margin-bottom: 10px"
        *ngIf="accessGroupsControl.touched && accessGroupsControl.value?.identifier && alreadyInGroupError"
      >
        {{ "accessGroups.userAlreadyInGroupError" | translate }}
      </p>
    </form>
    <button mat-stroked-button type="button" class="createButton" (click)="createAccessGroup()" *ngIf="needCreate">
      <mat-icon aria-hidden="false" aria-label="Add icon">add</mat-icon>
      {{ "page.accessGroups.createGroup" | translate }}
    </button>
  </div>
  <mat-checkbox
    class="ml-15 checkPaginator"
    *ngIf="nbPermissions > pageSize"
    [checked]="showPaginator"
    (change)="$event ? toggleShowPaginator($event.checked) : null"
  >
    {{ "groupPermission.activePaginator" | translate }}
  </mat-checkbox>
</div>
<table mat-table matSort matSortDirection="desc" matSortActive="startDate">
  <!-- theme Column -->
  <ng-container matColumnDef="theme">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "groupPermission.theme" | translate }}
      <app-item-filter
        fieldName="{{ 'groupPermission.theme' | translate }}"
        propertyName="theme"
        [dataType]="dataType.TEXT"
        [defaultValue]="dataSource?.getFilter('theme')"
        (applyFilter)="dataSource?.setFilter($event)"
      >
      </app-item-filter>
    </th>
    <td mat-cell *matCellDef="let element">
      <span>{{ element.theme }}</span>
    </td>
  </ng-container>

  <!-- method Column -->
  <ng-container matColumnDef="method">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "groupPermission.method" | translate }}
      <app-item-filter
        fieldName="{{ 'groupPermission.method' | translate }}"
        propertyName="method"
        [data]="true"
        translatePrefix="groupPermission"
        [dataType]="dataType.CHOICE"
        [defaultValue]="dataSource?.getFilter('method')"
        (applyFilter)="dataSource?.setFilter($event)"
      >
      </app-item-filter>
    </th>
    <td mat-cell *matCellDef="let element">
      <span>{{ "groupPermission." + element.method | translate }}</span>
    </td>
  </ng-container>

  <!-- routeName Column -->
  <ng-container matColumnDef="routeName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "groupPermission.routeName" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <span>{{ element.routeName }}</span>
    </td>
  </ng-container>

  <!-- groups access Column -->
  <ng-container matColumnDef="{{ groupName }}" *ngFor="let groupName of practitionerGroupsNames; let i = index">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ groupName }}
      <button
        mat-icon-button
        *ngIf="needDelete"
        type="button"
        (click)="removeAccessGroup(i)"
        matTooltip="{{ 'common.delete' | translate }}"
      >
        <mat-icon class="custom-icon" color="warn">delete</mat-icon>
      </button>
    </th>
    <td mat-cell *matCellDef="let element">
      <span>{{ "groupPermission." + (element.accessesByGroups | findGroupAccess : groupName) | translate }}</span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    [ngClass]="{
      restricted: row && row.access === ACCESS.RESTRICTED,
      authorized: row && row.access === ACCESS.AUTHORIZED,
      unallowed: row && row.access === ACCESS.UNDEFINED
    }"
  ></tr>
</table>
<mat-paginator
  [ngClass]="{ hide: !showPaginator || nbPermissions <= pageSize }"
  #paginator
  [pageIndex]="0"
  [pageSize]="pageSize"
  [hidePageSize]="true"
></mat-paginator>
