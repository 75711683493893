<div class="journal-insi-page">
  <div class="toolbar pt-1">
    <app-practitioner-selector [availablePractitioners]="availablePractitioners" (selected)="onUserSelect()"></app-practitioner-selector>
    <app-date-interval-selector (changeDate)="updateDate($event)"></app-date-interval-selector>
    <button mat-icon-button type="button" (click)="loadResults()" [disabled]="disableSearchIcon">
      <mat-icon [color]="disableSearchIcon ? undefined : 'primary'">check</mat-icon>
    </button>
  </div>

  <mat-card class="mat-elevation-z0 pt-0" [style.display]="this.displayedColumns.length > 0 ? 'block' : 'none'">
    <mat-table class="mat-elevation-z1" [dataSource]="dataSource" matSort [ngClass]="{ hide: !showTable }">
      <!-- requestDate column -->
      <ng-container matColumnDef="requestDate">
        <mat-header-cell *matHeaderCellDef>
          {{ "table.date" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.requestDate | date : "dd/MM/YYYY HH:mm" }}</mat-cell>
      </ng-container>
      <!-- insi return code -->
      <ng-container matColumnDef="INSIReturn">
        <mat-header-cell *matHeaderCellDef>
          {{ "page.journalInsi.table.INSIReturn.title" | translate }}
        </mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let element">
          {{ "page.journalInsi.table.INSIReturn." + element.INSIReturn | translate }}
        </mat-cell>
      </ng-container>
      <!-- Action column -->
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef>
          {{ "table.action" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            color="primary"
            mat-raised-button
            matTooltip="{{ 'page.deleteRequestList.btn.resendArchive' | translate }}"
            aria-label="icon button with an outbox icon"
            (click)="downloadResponse(element)"
          >
            {{ "page.journalInsi.btn.showResponse" | translate }}
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <div class="container mat-elevation-z1" *ngIf="!dataSource.data?.length && !loading && showTable">
      {{ "common.noData" | translate }}
    </div>

    <div class="container mat-elevation-z1" *ngIf="loading && showTable">
      <mat-spinner class="mt-1 mb-1 m-auto"></mat-spinner>
    </div>

    <mat-paginator
      [ngClass]="{ hide: !showTable }"
      class="mat-elevation-z1"
      [length]="dataSource.data.length"
      [pageSize]="10"
      [pageSizeOptions]="[10, 25, 50, 100]"
    ></mat-paginator>
  </mat-card>
</div>
