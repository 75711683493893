import { UntypedFormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

/**
 * return Error state when invalid control is dirty, touched, or submitted.
 */
export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return control && control.invalid && (control.dirty || control.touched || isSubmitted);
  }
}

export class PreventCharacter {
  public static preventMultiple(event, characters: PREVENTCHARACTER[]): string {
    let value = event.target.value;
    characters.forEach((c) => {
      switch (c) {
        case PREVENTCHARACTER.number:
          value = this.noNumber(value);
          break;
        case PREVENTCHARACTER.letter:
          value = this.noLetter(value);
          break;
        case PREVENTCHARACTER.specialChar:
          value = this.noSpecialChar(value);
          break;
        case PREVENTCHARACTER.specialCharExceptPlus:
          value = this.noSpecialCharExceptPlus(value);
          break;
      }
    });
    return value;
  }
  public static noNumber(value: string): string {
    return value.replace(/[0-9]/, "");
  }
  public static noLetter(value: string): string {
    return value.replace(/[A-Za-z- ']/, "");
  }
  public static noSpecialChar(value: string): string {
    return value.replace(/[^a-zA-Z0-9]/, "");
  }
  public static noSpecialCharExceptPlus(value: string): string {
    return value.replace(/[^a-zA-Z0-9+]/, "");
  }
}

export enum PREVENTCHARACTER {
  number,
  letter,
  specialChar,
  specialCharExceptPlus,
}
