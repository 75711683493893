import * as moment from "moment";
import { IInfoCommunication, Opened_Status, STATUS_CODE } from "./communications.interface";
import { Identifier } from "./identifier.interface";
import { SYSTEM_COMUNICARE } from "./practitioner.model";
import { Reference } from "./reference.interface";

export class InfoCommunication implements IInfoCommunication {
  public identifier: Identifier[];
  public sender: Reference;
  public subject: Reference;
  public group?: Reference;
  public topic: string;
  public content: string;
  public healthservice: Reference;
  public sent: Date;
  public received: Date;
  public payloadCount: number;
  public status: STATUS_CODE;
  public openedStatus: Opened_Status;
  public basedOn?: Reference[];
  public isOpened: boolean;
  public sendTo: string;
  public subjectDisplay: string;
  public isGrouped: boolean;
  public openStatusLocal: Opened_Status;
  public selfId: string;
  public receivedDateStringGlobal: string;
  public receivedDateStringLocal: string;
  public patientId: string;
  public isNotDelivred: boolean;
  public isEditable: boolean;

  constructor(data: IInfoCommunication, allComs: IInfoCommunication[]) {
    this.identifier = data.identifier;
    this.sender = data.sender;
    this.subject = data.subject;
    this.group = data.group;
    this.topic = data.topic;
    this.content = data.content;
    this.healthservice = data.healthservice;
    this.sent = data.sent;
    this.received = data.received;
    this.payloadCount = data.payloadCount;
    this.status = data.status;
    this.basedOn = data.basedOn;
    this.isOpened = !!this.received || this.status === STATUS_CODE.OPENED;
    this.sendTo = this.group.display ? this.group.display : this.subject.display;
    this.subjectDisplay = this.subject.display;
    this.isGrouped = !!this.group?.reference;
    this.selfId = this.identifier.find((id) => id.system === SYSTEM_COMUNICARE)?.value;
    this.patientId = this.subject.reference;
    this.isNotDelivred = this.status !== STATUS_CODE.DELIVERED;
    this.openStatusLocal = this.getOpenStatusLocal();
    this.openedStatus = this.getOpenedStatus(this, allComs);
    this.receivedDateStringGlobal = this.getReceivedDateStringGlobal();
    this.receivedDateStringLocal = this.getReceivedDateStringLocal();
    this.isEditable = this.editable();
  }

  private editable(): boolean {
    if (this.openedStatus === Opened_Status.DRAFT || this.openedStatus === Opened_Status.SENT) {
      return true;
    }
    return false;
  }

  private getOpenedStatus(communication: InfoCommunication, allComs: IInfoCommunication[]): Opened_Status {
    if (communication.status === STATUS_CODE.PREPARATION) {
      return Opened_Status.DRAFT;
    }
    const sameGroupCom = allComs.filter((c) => this.getId(c) === communication.selfId);
    if (sameGroupCom.length > 1) {
      this.isGrouped = true;
      const opened = sameGroupCom.filter((c) => !!c.received || c.status === STATUS_CODE.OPENED);
      const received = sameGroupCom.filter((c) => c.status === STATUS_CODE.DELIVERED);
      if (opened.length > 0) {
        return opened.length !== sameGroupCom.length ? Opened_Status.PARTLY_OPENED : Opened_Status.OPENED;
      } else if (received.length > 0) {
        return received.length !== sameGroupCom.length ? Opened_Status.PARTLY_RECEIVED : Opened_Status.RECEIVED;
      }
      const firstValue = sameGroupCom[0].status === STATUS_CODE.COMPLETED ? Opened_Status.SENT : Opened_Status.DRAFT;
      return firstValue;
    }
    if (communication.received) {
      return Opened_Status.OPENED;
    } else if (communication.status === STATUS_CODE.DELIVERED) {
      return Opened_Status.RECEIVED;
    } else if (communication.status === STATUS_CODE.COMPLETED) {
      return Opened_Status.SENT;
    } else {
      return Opened_Status.DRAFT;
    }
  }

  private getOpenStatusLocal(): Opened_Status {
    if (this.status === STATUS_CODE.PREPARATION) {
      return Opened_Status.DRAFT;
    } else if (this.received || this.status === STATUS_CODE.OPENED) {
      return Opened_Status.OPENED;
    } else if (this.status === STATUS_CODE.DELIVERED) {
      return Opened_Status.RECEIVED;
    } else {
      return Opened_Status.SENT;
    }
  }

  private getId(com: IInfoCommunication): string {
    return com.identifier.find((id) => id.system === SYSTEM_COMUNICARE)?.value;
  }

  private getReceivedDateStringGlobal(): string {
    switch (this.openedStatus) {
      case Opened_Status.DRAFT:
        return "communication.draft";
      case Opened_Status.SENT:
        return "communication.notDelivred";
      case Opened_Status.PARTLY_RECEIVED:
        return "communication.partlyReceived";
      case Opened_Status.RECEIVED:
        return "communication.notOpen";
      case Opened_Status.PARTLY_OPENED:
        return "communication.partlyOpen";
      case Opened_Status.OPENED:
        return moment(this.received).format();
    }
  }

  private getReceivedDateStringLocal(): string {
    if (this.status === STATUS_CODE.PREPARATION) {
      return "communication.draft";
    } else if (this.received) {
      return moment(this.received).format("DD-MM-YYYY HH:mm");
    } else if (this.status === STATUS_CODE.DELIVERED) {
      return "communication.notOpen";
    } else {
      return "communication.notDelivred";
    }
  }
}
