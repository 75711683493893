<div class="questionnaireProperties">
  <form id="questProperties" [formGroup]="questionnairePropsForm" (ngSubmit)="applyChanges()">
    <mat-tab-group #tabGroup>
      <mat-tab label="{{ 'page.questionnaireEditor.properties.general' | translate }}">
        <mat-accordion style="margin-right: 5px">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>notes</mat-icon>
                &nbsp; Details *
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field class="field" style="margin-top: 5%">
              <mat-label>{{ "model.patient.questionnaireName" | translate }}</mat-label>
              <input matInput type="text" formControlName="title" required [errorStateMatcher]="matcher" />
            </mat-form-field>
            <mat-form-field class="field">
              <mat-label>{{ "page.questionnaireEditor.properties.patientTitle" | translate }}</mat-label>
              <input matInput required formControlName="subtitle" />
            </mat-form-field>
            <mat-form-field class="field">
              <mat-label>{{ "page.questionnaireEditor.properties.description" | translate }}</mat-label>
              <textarea matInput formControlName="description"></textarea>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ "page.questionnaireEditor.properties.language" | translate }}</mat-label>
              <mat-select formControlName="language" required>
                <mat-option *ngFor="let l of languageOptions" [value]="l.code">
                  {{ l.display }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="checkbox">
              <mat-checkbox
                *ngIf="!questionnaire.specificUse"
                matTooltip="{{ 'page.questionnaireEditor.properties.practitionersOnlyTooltip' | translate }}"
                formControlName="practitionersOnly"
                class="checkbox"
              >
                {{ "page.questionnaireEditor.properties.practitionersOnly" | translate }}
              </mat-checkbox>
              <mat-checkbox
                *ngIf="!questionnaire.specificUse && questionnairePropsForm.get('practitionersOnly').value"
                matTooltip="{{ 'page.questionnaireEditor.properties.observationsTooltip' | translate }}"
                formControlName="withObservations"
                class="checkbox"
              >
                {{ "page.questionnaireEditor.properties.withObservations" | translate }}
              </mat-checkbox>
              <mat-checkbox
                matTooltip="{{ 'page.questionnaireEditor.properties.onlyOnlineTooltip' | translate }}"
                formControlName="onlyOnline"
                class="checkbox"
              >
                {{ "page.questionnaireEditor.properties.onlyOnline" | translate }}
              </mat-checkbox>
            </div>
          </mat-expansion-panel>
          <!-- =========================== ACCESS LEVELS ================================================-->
          <mat-expansion-panel *ngIf="useContext">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>assignment</mat-icon>
                &nbsp;
                {{ "groupPermission.access" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-use-context
              [services]="orgServices"
              [orgsRefs]="organizationsRefs"
              [showLevels]="true"
              [globalAuthorized]="true"
              [(useContext)]="useContext"
            ></app-use-context>
          </mat-expansion-panel>

          <!-- ============================= PARAMETERS =================================================-->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>settings</mat-icon>
                &nbsp;
                {{ "common.parameters" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container
              *ngIf="
                questionnaire?.specificUse !== SPECIFIC_USE.QUIZ &&
                questionnaire?.specificUse !== SPECIFIC_USE.CONSENT &&
                !visualization &&
                (questionnaire.status !== QuestionnaireStatus.DRAFT || isNewQuestionnaire)
              "
            >
            </ng-container>

            <!-- ======= SPECIAL CAREPLANS ACTIVITIES INFOS ========= -->
            <div *ngIf="originalCareplansWithQuestActivities?.length" class="cpDetails">
              {{ "page.questionnaireEditor.properties.alreadyLinkedCareplanActivities" | translate }}
              <p *ngFor="let originalLinkedCareplan of originalCareplansWithQuestActivities">
                - {{ originalLinkedCareplan.description }}
                :
                {{ originalLinkedCareplan | activityLinkedToQuestionnaire : questionnaire?.identifier[0]?.value }}
              </p>
            </div>

            <!-- ================================ QUESTIONNAIRE_PARAMS =============================================== -->
            <section *ngIf="!questionnairePropsForm.value.practitionersOnly && params">
              <h3>{{ "page.questionnaireEditor.properties.frequency" | translate }} :</h3>
              <app-questionnaire-params [(params)]="params" [disabled]="visualization || !isNewQuestionnaire"></app-questionnaire-params>
            </section>
            <!--  ================================= QUIZ & CONSENT STUFF ============================================= -->
            <div class="checkbox">
              <mat-form-field
                *ngIf="questionnaire?.specificUse === SPECIFIC_USE.QUIZ || questionnaire?.specificUse === SPECIFIC_USE.CONSENT"
                class="successThreshold"
              >
                <mat-label>{{ "page.questionnaireEditor.properties.successThreshold" | translate }}</mat-label>
                <input matInput formControlName="successThreshold" type="number" min="0" max="100" />
              </mat-form-field>
              <mat-checkbox
                *ngIf="questionnaire.specificUse === SPECIFIC_USE.QUIZ"
                matTooltip="{{ 'page.questionnaireEditor.properties.manualTransition' | translate }}"
                formControlName="manualTransition"
                class="checkbox"
              >
                {{ "page.questionnaireEditor.properties.manualTransition" | translate }}
              </mat-checkbox>
            </div>
            <div class="knowledgeInfos" *ngIf="hasKnowledgeInfos">
              <p>
                {{ ("page.quizList.columns.knowledgeTitle" | translate) + " : " + knowledgeTitle }}
              </p>
              <p>
                {{ ("page.quizList.columns.mediaTitle" | translate) + " : " + mediaTitle }}
              </p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-tab>
      <!-- ========================================= SCORINGS ==================================================== -->

      <mat-tab label="{{ 'page.questionnaireEditor.properties.scoringTitle' | translate }}">
        <div formArrayName="scorings" *ngFor="let scoring of scoringsFormArray.controls; let i = index" style="margin-top: 5%">
          <div [formGroupName]="i">
            <div
              style="text-align: right"
              *ngIf="
                !visualization &&
                (('dashboard/questionnaireScorings' | isAuthorized : 'DELETE' | async) === true || scorings[i].identifier.value === '')
              "
            >
              <mat-icon button class="deleteButton" aria-hidden="false" aria-label="Delete icon" (click)="deleteScoring(i)">
                cancel</mat-icon
              >
            </div>
            <mat-form-field class="field">
              <mat-label>{{ "page.questionnaireEditor.properties.scoring.name" | translate }}</mat-label>
              <input matInput type="text" formControlName="name" />
            </mat-form-field>
            <mat-form-field class="field">
              <mat-label>{{ "page.questionnaireEditor.properties.scoring.label" | translate }}</mat-label>
              <input matInput type="text" formControlName="label" />
            </mat-form-field>
            <div formArrayName="interpretations" *ngFor="let interp of getInterpretationFormArray(i)?.controls; let interpIndex = index">
              <div [formGroupName]="interpIndex">
                <section class="properties-group">
                  <h3>{{ "page.questionnaireEditor.interpretation" | translate }} :</h3>
                  <div
                    style="text-align: right"
                    *ngIf="
                      !visualization &&
                      (('dashboard/questionnaireScorings' | isAuthorized : 'PUT' | async) === true || scorings[i].identifier.value === '')
                    "
                  >
                    <mat-icon
                      button
                      class="deleteButton"
                      aria-hidden="false"
                      aria-label="Delete icon"
                      (click)="deleteInterpretation(i, interpIndex)"
                    >
                      cancel</mat-icon
                    >
                  </div>
                  <mat-form-field class="field">
                    <mat-label>{{ "page.questionnaireEditor.properties.scoring.interpretation" | translate }}</mat-label>
                    <input matInput type="text" formControlName="text" />
                  </mat-form-field>
                  <div
                    class="enableWhenForm"
                    formArrayName="enableWhens"
                    *ngFor="
                      let enableWhen of this.getEnableWhenFormArray(i, interpIndex).controls;
                      let iEnableWhen = index;
                      let isFirst = first
                    "
                  >
                    <div [formGroupName]="iEnableWhen">
                      <mat-form-field style="width: 70%" [floatLabel]="'never'">
                        <mat-select formControlName="operator" placeholder="=">
                          <mat-option *ngFor="let operator of operatorOptions" [value]="operator">
                            {{ operator }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field style="width: 70%" [floatLabel]="'never'">
                        <input matInput type="text" formControlName="answer" placeholder="value x" />
                      </mat-form-field>
                      <mat-icon
                        button
                        class="deleteButton"
                        aria-hidden="false"
                        aria-label="Delete icon"
                        (click)="deleteEnableWhen(i, interpIndex, iEnableWhen)"
                        *ngIf="
                          !visualization &&
                          !isFirst &&
                          (('dashboard/questionnaireScorings' | isAuthorized : 'PUT' | async) === true ||
                            scorings[i].identifier.value === '')
                        "
                      >
                        cancel</mat-icon
                      >
                    </div>
                  </div>
                  <div>
                    <mat-form-field
                      style="width: 50%; text-align: left"
                      *ngIf="this.getEnableWhenFormArray(i, interpIndex).controls.length > 1"
                    >
                      <mat-select formControlName="enableWhenBehavior">
                        <mat-option *ngFor="let behavior of enableWhenBehaviorOptions" [value]="behavior.value">
                          {{ behavior.display }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <button
                    mat-stroked-button
                    type="button"
                    class="saveButton"
                    *ngIf="
                      !visualization &&
                      (('dashboard/questionnaireScorings' | isAuthorized : 'PUT' | async) === true || scorings[i].identifier.value === '')
                    "
                    (click)="addEnableWhen(i, interpIndex)"
                  >
                    <mat-icon aria-hidden="false" aria-label="Add icon">add</mat-icon>
                    {{ "page.questionnaireEditor.properties.addCondition" | translate }}
                  </button>
                </section>
              </div>
            </div>
            <button
              mat-stroked-button
              type="button"
              class="saveButton"
              (click)="addInterpretation(i)"
              style="margin-bottom: 2%"
              *ngIf="
                !visualization &&
                (('dashboard/questionnaireScorings' | isAuthorized : 'PUT' | async) === true || scorings[i].identifier.value === '')
              "
            >
              <mat-icon aria-hidden="false" aria-label="Add icon">add</mat-icon>
              {{ "page.questionnaireEditor.properties.addInterpretation" | translate }}
            </button>

            <section>
              <mat-form-field class="field" style="width: 75%; text-align: left">
                <mat-label>{{ "page.questionnaireEditor.properties.scoring.formula" | translate }}</mat-label>
                <textarea matInput type="text" formControlName="formula"></textarea>
              </mat-form-field>
              <button
                mat-stroked-button
                type="button"
                style="margin-left: 5px"
                (click)="editScoringFormula(i)"
                *ngIf="
                  (!visualization &&
                    (('dashboard/questionnaireScorings' | isAuthorized : 'PUT' | async) === true || scorings[i].identifier.value === '')) ||
                  scoringsFormArray.getRawValue()[i].formula
                "
              >
                <mat-icon aria-hidden="false" aria-label="Add icon">{{
                  scoringsFormArray.getRawValue()[i].formula
                    ? !visualization &&
                      (("dashboard/questionnaireScorings" | isAuthorized : "PUT" | async) === true || scorings[i].identifier.value === "")
                      ? "edit"
                      : "remove_red_eye"
                    : "add"
                }}</mat-icon>
              </button>
            </section>
          </div>

          <hr />
        </div>
        <button
          mat-stroked-button
          type="button"
          *ngIf="!visualization && ('dashboard/questionnaireScorings' | isAuthorized : 'POST' | async) === true"
          (click)="addScoring()"
        >
          <mat-icon aria-hidden="false" aria-label="Add icon">add</mat-icon>
          {{ "page.questionnaireEditor.properties.addScoring" | translate }}
        </button>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>
