import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { FileLogger } from "src/app/helpers/fileLogger";
import { KnowledgeService } from "src/app/providers/knowledge.service";

@Component({
  selector: "app-draft-warning-dialog",
  templateUrl: "draft-warning-dialog.component.html",
  styleUrls: ["draft-warning-dialog.component.scss"],
})
export class DraftWarningDialogComponent {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DraftWarningDialogComponent>,
    private knowledgeService: KnowledgeService,
    @Inject(MAT_DIALOG_DATA) public data: { id?: string }
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  public goBack(): void {
    this.dialogRef.close();
  }
  public goToPublished(): void {
    this.dialogRef.close({ publish: true });
  }
  public deleteDraft(): void {
    this.knowledgeService.deleteDraft(this.data.id).subscribe(
      () => {
        this.goBack();
      },
      (error) => {
        FileLogger.error("DraftWarningDialogComponent", "deleteDraft", error);
      }
    );
  }
}
