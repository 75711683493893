import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { CustomErrorStateMatcher } from "src/app/helpers/formValidators";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { Tools } from "src/app/helpers/tools";
import { IAccessGroup, IAccessGroupPermission } from "src/app/models/accessGroup.interface";
import { STATUS_ENTITY } from "src/app/models/sharedInterfaces";
import { AccessService } from "src/app/providers/access.service";
import { AccessGroupApiService } from "src/app/providers/api/accessGroup-api.service";
import { UserService } from "src/app/providers/user.service";
import { GroupPermissionsComponent } from "../group-permissions/group-permissions.component";

@Component({
  selector: "app-access-group",
  templateUrl: "./access-group.component.html",
  styleUrls: ["./access-group.component.scss"],
})
export class AccessGroupComponent implements OnInit, OnDestroy {
  @ViewChild(GroupPermissionsComponent)
  private groupPermissionsComponent: GroupPermissionsComponent;
  @Input() mode: FORMS_MODE;
  @Input() accessGroup: IAccessGroup;
  @Input() allAccessGroups: IAccessGroup[];

  public pageLoaded = false;
  public saveInProgress = false;
  public accessGroupForm: UntypedFormGroup;
  public matcher = new CustomErrorStateMatcher();
  public permissions: IAccessGroupPermission[] = [];

  public MODES = FORMS_MODE;
  public canCreatePermission = false;
  public canReadPermissions = false;
  public canUpdatePermission = false;
  public canDeletePermission = false;

  /** Subject that emits when the component has been destroyed. */
  // tslint:disable-next-line: variable-name
  private onDestroy$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private accessService: AccessService,
    private accessGroupApiService: AccessGroupApiService,
    private fb: UntypedFormBuilder
  ) {
    this.accessGroupForm = this.fb.group({
      name: ["", [Validators.required, this.AccessGroupNameValidator]],
    });
  }

  ngOnInit(): void {
    this.checkUsersPermissions();
    if (this.mode === this.MODES.CREATE) {
      // Setup empty new access group:
      this.accessGroup = {
        identifier: {
          value: "",
          label: "",
          system: "http://comunicare.io",
        },
        entityStatus: [STATUS_ENTITY.ACTIVE],
      };
      this.permissions = [];
    } else if (this.mode === this.MODES.DISPLAY) {
      // Display the group name in a non-editable way
      // The form input will not be visible, but just in case...
      this.accessGroupForm.get("name").patchValue(this.accessGroup.identifier.label);
      this.accessGroupForm.get("name").disable();
      this.permissions = Tools.clone(this.accessGroup.permissions);
    } else {
      this.permissions = Tools.clone(this.accessGroup.permissions);
      this.accessGroupForm.get("name").patchValue(this.accessGroup.identifier.label);
    }
    this.pageLoaded = true;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Create or update an access group and its permissions
   * @returns true if everything was saved correctly, false if the form is not valid or if we were already saving
   */
  public async save(): Promise<IAccessGroup> {
    if (this.saveInProgress || this.mode === this.MODES.DISPLAY || !this.accessGroupForm.valid) {
      return null;
    }
    this.saveInProgress = true;
    this.accessGroup.identifier.label = this.accessGroupForm.get("name").value;
    if (this.mode === this.MODES.CREATE) {
      this.accessGroup.identifier.value = this.accessGroup.identifier.label.replace(" ", "");
      await this.accessService.createAccessGroup(this.accessGroup);
      if (this.canCreatePermission) {
        const permissionsPromises = [];
        for (const p of this.permissions) {
          p.accessGroupId = this.accessGroup.identifier.value;
          permissionsPromises.push(this.accessService.createAccessGroupPermission(p));
        }
        await Promise.all(permissionsPromises);
        this.accessGroup.permissions = this.permissions;
      }
    } else if (this.mode === this.MODES.UPDATE) {
      await this.accessService.updateAccessGroup(this.accessGroup);
      const newPermissions = this.getNewPermissions();
      const permissionsPromises = [];
      if (this.canUpdatePermission) {
        for (const p of newPermissions.update) {
          permissionsPromises.push(this.accessService.updateAccessGroupPermission(p));
        }
      }
      if (this.canCreatePermission) {
        for (const p of newPermissions.create) {
          permissionsPromises.push(this.accessService.createAccessGroupPermission(p));
        }
      }
      if (this.canDeletePermission) {
        for (const p of newPermissions.delete) {
          permissionsPromises.push(this.accessService.deleteAccessGroupPermission(p._id));
        }
      }
      await Promise.all(permissionsPromises);
      this.accessGroup.permissions = this.permissions;
    }
    this.saveInProgress = false;
    return this.accessGroup;
  }

  /**
   * Deep clone the permission of one group and put them in another group.
   * If the group to copy is undefined, put back the previous permissions if there
   * were some.
   * @param groupToCopy the group we want to copy permissions from (can be undefined)
   * @returns
   */
  public async copyGroup(groupToCopy: IAccessGroup): Promise<void> {
    let clonedPermissions: IAccessGroupPermission[];
    if (!groupToCopy && this.accessGroup.permsByTheme) {
      this.accessGroup.permissions = this.accessService.getPermissionsFromPermsByTheme(this.accessGroup.permsByTheme);
    } else if (!groupToCopy && !this.accessGroup.permsByTheme) {
      this.accessGroup.permissions = [];
    } else if (!groupToCopy.permissions) {
      const success = await this.accessService.loadGroupsPermissions([groupToCopy]);
      if (!success) {
        return;
      }
      clonedPermissions = Tools.clone(groupToCopy.permissions);
    } else {
      clonedPermissions = Tools.clone(groupToCopy.permissions);
    }
    if (clonedPermissions) {
      for (const p of clonedPermissions) {
        delete p._id;
        p.accessGroupId = this.accessGroup.identifier.value;
      }
      this.accessGroup.permissions = clonedPermissions;
    }
    this.permissions = Tools.clone(this.accessGroup.permissions);
    setTimeout(() => {
      this.groupPermissionsComponent.loadData();
    }, 300);
  }

  /**
   * Form validator that checks that the new access group's name is not the same than another group.
   * It compares the names in lower case.
   * @param control
   * @returns
   */
  private AccessGroupNameValidator: ValidatorFn = (control: AbstractControl): { [key: string]: true } | null => {
    const newValue = control.value;
    const newId = newValue.replace(" ", "").toLowerCase();
    const otherGroupWithSameName = this.allAccessGroups?.find(
      (g) =>
        this.accessGroup.identifier.value !== g.identifier.value &&
        (g.identifier.label.toLowerCase() === newValue.toLowerCase() || g.identifier.value.toLowerCase() === newId)
    );
    if (otherGroupWithSameName) {
      return { invalidName: true };
    }
    return null;
  };

  /**
   * Sort the permissions into "permissions to create", "permissions to update" and "permissions to delete"
   * @returns a custom object containing 3 arrays witht the different permissions
   */
  private getNewPermissions(): { create: IAccessGroupPermission[]; update: IAccessGroupPermission[]; delete: IAccessGroupPermission[] } {
    const newPermissions = {
      create: [],
      update: [],
      delete: [],
    };
    for (const perm of this.permissions) {
      const i = this.accessGroup.permissions.findIndex((p) => p.method === perm.method && p.routeName === perm.routeName);
      const original = i > -1 ? this.accessGroup.permissions[i] : null;
      if (original) {
        if (original.access !== perm.access) {
          newPermissions.update.push(perm);
        }
        this.accessGroup.permissions.splice(i, 1);
      } else {
        newPermissions.create.push(perm);
      }
    }
    newPermissions.delete = this.accessGroup.permissions;
    return newPermissions;
  }

  private checkUsersPermissions(): void {
    this.canCreatePermission = this.userService.isAuthorizedSync(null, this.accessGroupApiService.createRoutes[1], "POST");
    this.canReadPermissions = this.userService.isAuthorizedSync(null, this.accessGroupApiService.readRoutes[1], "GET");
    this.canUpdatePermission = this.userService.isAuthorizedSync(null, this.accessGroupApiService.updateRoutes[1], "PUT");
    this.canDeletePermission = this.userService.isAuthorizedSync(null, this.accessGroupApiService.deleteRoutes[1], "DELETE");
  }
}
