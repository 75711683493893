import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "dateArray" })
export class DateArrayPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  /**
   * Take an array of date strings and map it to formated date
   */
  transform(stringArray: string[], format = ""): string[] {
    return stringArray.map((s) => this.datePipe.transform(s, format));
  }
}
