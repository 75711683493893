import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IObservationDefinition, IObservationsLoincsInfo, ObservationDefinitionComponent } from "../models/observations.interface";

@Pipe({
  name: "getShortname",
})
export class GetShortnamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  /**
   * Get shortname of the observationDefinitionComponent if available. If not, returns the name of the component. If still not available, return the loinc
   */
  transform(element: ObservationDefinitionComponent | IObservationDefinition | IObservationsLoincsInfo): string {
    if (element.shortnameTranslation && element.shortnameTranslation[this.translateService.currentLang]) {
      return element.shortnameTranslation[this.translateService.currentLang];
    } else if (element.nameTranslation && element.nameTranslation[this.translateService.currentLang]) {
      return element.nameTranslation[this.translateService.currentLang];
    } else {
      return element.loinc;
    }
  }
}
