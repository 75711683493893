import { Patient } from "../models/patient.interface";
import { FHIRHelper } from "./FHIRhelper";
import { Tools } from "./tools";

export class PatientHelper {
  public static getIns(patient: Patient): string {
    return patient.identifier.find(
      (identifier) => identifier.system === FHIRHelper.SYSTEM_MATRICULE_INS && !Tools.isDefined(identifier.period?.end)
    )?.value;
  }

  public static getOid(patient: Patient): string {
    return patient.identifier.find(
      (identifier) => identifier.system === FHIRHelper.SYSTEM_MATRICULE_INS && !Tools.isDefined(identifier.period?.end)
    )?.type?.code;
  }
}
