import { Pipe, PipeTransform } from "@angular/core";
import { IChartColor } from "src/app/models/chart.interface";

@Pipe({
  name: "mapChartColorsPipe",
})
export class MapChartColorsPipe implements PipeTransform {
  /**
   * Map LOINC codes to chart colors.
   * Extracts colors from an array of objects containing LOINC codes and associated color configurations.
   */
  transform(arr: Array<{ loinc: string; color: IChartColor }>): Array<IChartColor> {
    if (!Array.isArray(arr)) {
      return [];
    }
    return arr.map((item) => item.color);
  }
}
