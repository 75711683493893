import { ErrorHandler, Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AppError } from "../models/app-error.interface";
import { HealthcareserviceService } from "./healthcareservice.service";
import { OrganizationsService } from "./organizations.service";
import { SessionService } from "./session.service";
import { UserService } from "./user.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  public catchedError = new Subject<AppError>();

  constructor(
    private sessionService: SessionService,
    private userService: UserService,
    private healthcareService: HealthcareserviceService,
    private organizationService: OrganizationsService
  ) {}

  public handleError(error: AppError) {
    // Invalid token, disconnect and go to login page. Same in Case of error 404
    if (error.code === 403 || error.code === 0) {
      // this.sessionService.redirectUrl = this.router.url;
      this.organizationService.clear();
      this.healthcareService.clear();
      this.userService.clear();
      this.sessionService.clear();
      // window.location.reload();
      window.location.href = "/";
    }

    this.catchedError.next(error);
  }
}
