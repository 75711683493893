import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { IDeleteRequest } from "src/app/models/delete-request.interface";
import { Filter } from "src/app/models/filter.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class DeleteRequestApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "DeleteRequest", // Theme !
      ["resendArchiveDownload"], // Post route paths
      ["deleteRequest", "nbDeleteRequest", "dashboard/archive"], // Get route paths
      ["deleteRequest"], // Update route paths
      [] // Delete route paths
    );
  }

  public list(params: DataSourceBackendParam): Observable<IDeleteRequest[]> {
    return this.api.get(this.readRoutes[0], {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      search: params.search,
      services: params.services,
      sortId: params.sortId,
      sortOrder: params.sortOrder,
      filters: JSON.stringify(params.filters),
    }) as Observable<IDeleteRequest[]>;
  }

  public update(deleteRequest: IDeleteRequest, newStatus: number): Observable<never> {
    return this.api.put(this.updateRoutes[0], {
      deleteRequest,
      newStatus,
    }) as Observable<never>;
  }

  public getDeleteRequestCount(services: string[], search?: string, filters?: Filter[]): Observable<{ count: number }> {
    return this.api.get(this.readRoutes[1], {
      services,
      search,
      filters: JSON.stringify(filters),
    }) as Observable<{ count: number }>;
  }

  public resendArchiveDownload(deleteRequest: IDeleteRequest, mailAddress: string): Observable<never> {
    return this.api.post(this.createRoutes[0], {
      deleteRequest,
      mailAddress,
    }) as Observable<never>;
  }

  public downloadArchive(filename: string, drToken: string, disclaimerRead: boolean): Observable<unknown> {
    return this.api.getBlob(this.readRoutes[2], {
      filename,
      drToken,
      disclaimerRead,
    }) as Observable<unknown>;
  }
}
