export enum UserEventSource {
  DASHBOARD = "dashboard",
  MOBILE = "mobile",
  PORTAIL = "portail",
  CONNECTOR = "connector",
  SERVER = "server",
  TPDA = "tpda",
}

export interface IStatSummary {
  startOfMonth: string; // unique id, "2023-01"
  knowledgeRead: number; // nb of people that read a knowledge
  quizzesDone: number; // nb of quizzes completed
  dashboardVisits: number; // nb of people that visited the dashboard
  TPDAVisits: number;
}

export interface IStatEvent {
  eventDatetime: string;
  eventSource: UserEventSource;
  description: string; // "read knowledge xxxx ", "page home visit"
  userId?: string; // caremate id or random id or we can delete it if we want fully anonymous stats (no tracking possible)
  servicesIds?: string[]; // services of the user generating the event (if applicable)
  careplansIds?: string[]; // careplans of the user generating the event (if applicable)
  careplans?: { description: string }[]; // only for aggregate during GET
  userName?: string; // only for aggregate during GET
}
