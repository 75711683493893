<div class="close-cross">
  <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="modalePausePlay">
  <div class="pausePlay">
    <h2>{{ "drugSchema.pause" | translate }}</h2>
    <form [formGroup]="pausePlayForm">
      <mat-form-field class="full-w">
        <mat-label>{{ "drugSchema.when" | translate }}</mat-label>
        <mat-select formControlName="when">
          <mat-option value="today">{{ "drugSchema.today" | translate }}</mat-option>
          <mat-option value="tomorrow">{{ "drugSchema.tomorrow" | translate }}</mat-option>
          <mat-option value="chooseDate">{{ "drugSchema.chooseDate" | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="full-w" *ngIf="pausePlayForm.get('when').value === 'chooseDate'">
        <mat-label>{{ "schedule.dateFrom" | translate }}</mat-label>
        <input matInput [matDatepicker]="dateFromPicker" formControlName="date" />
        <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateFromPicker></mat-datepicker>
      </mat-form-field>
    </form>
    <div class="action-container">
      <button mat-raised-button color="primary" (click)="onCancel()">
        {{ "common.cancel" | translate }}
      </button>
      <button mat-raised-button color="primary" (click)="onSave()">
        {{ "drugSchema.confirm" | translate }}
      </button>
    </div>
  </div>
</div>
