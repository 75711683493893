import { Injectable } from "@angular/core";
import moment from "moment";
import { Observable } from "rxjs";
import { IReference } from "../models/sharedInterfaces";
import { TownsApiService } from "./api/towns-api.service";

@Injectable({
  providedIn: "root",
})
export class TownsService {
  constructor(private townsApiService: TownsApiService) {}

  public getTownsByName(date: string, name: string): Observable<IReference[]> {
    return this.townsApiService.list(moment(date).format("YYYY-MM-DD"), name);
  }

  public async getTownByCode(date: string, code: string): Promise<IReference> {
    const towns = await this.townsApiService.list(moment(date).format("YYYY-MM-DD"), undefined, code).toPromise();
    if (towns.length === 1) {
      return towns[0];
    }
    return undefined;
  }
}
