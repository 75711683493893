import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { IEntitylink } from "src/app/models/entity.interface";
import { DrugsService } from "src/app/providers/drugs.service";

export class ObservationsListDataSource extends MatTableDataSourceExtended<IEntitylink> {
  sortingDataAccessor = (data: IEntitylink, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "name":
        return String(data.entityData.name).toLowerCase();
      case "label":
        if (data.entityData.cycle && data.entityData.cycle.cycle?.length > 0) {
          return this.translateService.instant("drugSchema.withCycle");
        }
        return this.drugService.computeFrequency(data.entityData).toLowerCase();
      case "startDate":
        return moment(data.entityData.frequency.boundsPeriod.start).unix();
      case "endDate":
        return moment(data.entityData.frequency.boundsPeriod.end).unix();
      default:
        return "";
    }
  };

  constructor(private drugService: DrugsService, private translateService: TranslateService) {
    super();
  }

  public loadData(drugs: IEntitylink[], onlyActive?: boolean): void {
    if (onlyActive) {
      this.data = drugs.filter((entity) => {
        if (!entity.entityData.frequency.boundsPeriod.end) {
          entity.entityData.frequency.boundsPeriod.end = "9999-12-31";
        }
        const end = new Date(entity.entityData.frequency.boundsPeriod.end).valueOf();
        const today = new Date().setHours(0, 0, 0, 0).valueOf();
        return end >= today;
      });
    } else {
      this.data = drugs;
    }
  }
}
