<section class="properties-group">
  <mat-form-field class="placeholderText">
    <mat-label>
      {{ "page.questionnaireEditor.properties.placeholder" | translate }}
    </mat-label>
    <input matInput [(ngModel)]="placeholder" (change)="onValuesChange()" [disabled]="disabled" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>
      {{ "page.questionnaireEditor.properties.textMax" | translate }}
    </mat-label>
    <input matInput type="number" [(ngModel)]="max" [min]="min" step="1" [disabled]="disabled" (change)="onValuesChange()" />
  </mat-form-field>
</section>
