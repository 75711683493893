<mat-toolbar class="PatientInfosToolbar" fixed color="primary">
  <ng-container *ngIf="patientUser">
    <mat-icon>face</mat-icon>
    <p
      matTooltip="{{
        (patientUser.patient | getPatientFullName) + (patientUser.patient.pseudoname ? ' (' + patientUser.patient.pseudoname + ')' : '')
      }}"
      matTooltipClass="truncatedNamesTooltip"
    >
      {{
        (patientUser.patient | getPatientFullName) +
          (patientUser.patient.pseudoname
            ? "
            (" +
              patientUser.patient.pseudoname +
              ")"
            : "") | truncate : 30
      }}
    </p>
    <mat-icon>email</mat-icon>
    <p>{{ getMail(patientUser.patient) }}</p>
    <mat-icon>phone</mat-icon>
    <p>{{ getPhone(patientUser.patient) }}</p>
    <mat-icon *ngIf="getBirthdate(patientUser.patient)">date_range</mat-icon>
    <p>{{ getBirthdate(patientUser.patient) }}</p>
    <h1 *ngIf="getId(patientUser.patient)">#</h1>
    <p *ngIf="getId(patientUser.patient)">{{ getId(patientUser.patient) }}</p>
    <mat-icon *ngIf="getAuthCode(patientUser.patient) && patientUser.user.status !== 1">vpn_key</mat-icon>
    <p *ngIf="getAuthCode(patientUser.patient) && patientUser.user.status !== 1">
      {{ getAuthCode(patientUser.patient) }}
    </p>
    <ng-container *ngIf="country === FRANCE && patientUser.patient?.identity?.length">
      <mat-icon>badge</mat-icon>
      <p (click)="showStatusChangeHistoric(patientUser)" style="cursor: pointer" matTooltip="{{ 'btn.history' | translate }}">
        {{ "forms.identityStatus." + (patientUser.patient | getLatestIdentityFromPatient).status | translate }}
      </p>
    </ng-container>
  </ng-container>
  <span class="spacerBetween"></span>
  <ng-container *ngIf="patientUser">
    <p
      *ngIf="patientUser.patient.active === false && ('patient/historic' | isAuthorized | async) === true"
      (click)="openHistoric(patientUser)"
      style="cursor: pointer"
      matTooltip="{{ 'btn.history' | translate }}"
    >
      {{
        ("model.patient.disablePatientReason.text" | translate) +
          " " +
          (patientUser.patient?.disabledReason
            ? patientUser.patient.disabledReason === reason.DEATH ||
              patientUser.patient.disabledReason === reason.HOSPITALIZATION ||
              patientUser.patient.disabledReason === reason.PERSONALPROBLEM ||
              patientUser.patient.disabledReason === reason.OTHER
              ? "(" + ("model.patient.disablePatientReason." + patientUser.patient.disabledReason | translate) + ")"
              : "(" + patientUser.patient.disabledReason + ")"
            : "")
      }}
    </p>
    <button
      mat-icon-button
      *ngIf="(!getAuthCode(patientUser.patient) || isActivePatient(patientUser)) && ('passwordreset' | isAuthorized | async) === true"
      matTooltip="{{
        isActivePatient(patientUser) ? ('page.patientlist.resetPassword' | translate) : ('page.patientlist.resetPassword' | translate)
      }}"
      (click)="resetPatientPassword(patientUser)"
      aria-label="icon button with a plus icon"
    >
      <mat-icon>lock_open</mat-icon>
    </button>
    <button mat-icon-button (click)="openPatientHelp()" aria-label="icon button with a ? icon">
      <mat-icon class="customSizeIcon2">help_outline</mat-icon>
    </button>
    <button
      *ngIf="patientUser.patient.active === false && canDeactivate"
      matTooltip=" {{ 'page.patientlist.activatePatientSolo' | translate }}"
      mat-icon-button
      (click)="activatePatient(patientUser.patient)"
      aria-label="icon button with a pencil icon"
    >
      <span class="fa fa-user-check customSizeIcon"></span>
    </button>
    <button
      *ngIf="patientUser.patient.active === true && canDeactivate"
      matTooltip=" {{ 'page.patientlist.disablePatientSolo' | translate }}"
      mat-icon-button
      (click)="disablePatient(patientUser)"
      aria-label="icon button with a slashed user"
    >
      <span class="fa fa-user-slash customSizeIcon"></span>
    </button>
    <button
      *ngIf="isServiceSelected && canUpdate"
      mat-icon-button
      matTooltip="{{ 'btn.update' | translate }}"
      (click)="editPatient()"
      aria-label="icon button with a plus icon"
    >
      <mat-icon>create</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="{{ 'btn.removeForEver' | translate }}"
      class="fail"
      (click)="removeForEver(patientUser.user.caremateIdentifier)"
      *ngIf="isAdmin && ('patient' | isAuthorized : 'DELETE' | async) === true"
      aria-label="icon button with a trash icon"
    >
      <mat-icon>delete_forever</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="{{ 'btn.close' | translate }}"
      (click)="goToPatients()"
      aria-label="icon button with a close icon"
      *ngIf="('/patients' | isAuthorized | async) === true"
    >
      <mat-icon class="fail">close</mat-icon>
    </button>
  </ng-container>
</mat-toolbar>
