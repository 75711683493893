import { IDrugSchema } from "./drugSchema.interface";
import { Entity } from "./entity.interface";
import { IReference } from "./sharedInterfaces";

export class DrugSchema extends Entity implements IDrugSchema {
  public name: string;
  public public: boolean;
  public cycle: boolean[]; // the length of the array is the number of days of the cycle
  public author?: IReference; // caremateIdentifier
  public organization?: IReference; // organization of the author
  public drugs?: string[]; // if the schema is attached to a drug, we keep the ATC code of this drug
  public visibility?: string; // This is not sent from backend but is needed to filter the datas from the table

  constructor(data: IDrugSchema) {
    super();
    this.name = data.name;
    this.public = data.public;
    this.cycle = data.cycle;
    this.author = data.author;
    this.organization = data.organization;
    this.drugs = data.drugs;
    this.modified = data.modified;
    this._id = data._id;
  }
}
