import { Component, Inject, OnDestroy, OnInit, Pipe, PipeTransform } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { Tools } from "src/app/helpers/tools";
import { ERROR_MSG } from "src/app/models/app-error.interface";
import { IPractitionerInfo } from "src/app/models/practitioner.interface";
import { PractitionerService } from "src/app/providers/practitioner.service";
import { SessionService } from "src/app/providers/session.service";

@Pipe({ name: "getGenderDisplay" })
export class GetGenderDisplayPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(gender: string): boolean {
    switch (gender) {
      case FHIRHelper.GENDER_MALE:
        return this.translateService.instant("forms.male");
      case FHIRHelper.GENDER_FEMALE:
        return this.translateService.instant("forms.female");
    }
  }
}
@Component({
  selector: "app-ans-practitionner-result.component",
  templateUrl: "./ans-practitionner-result.component.html",
  styleUrls: ["./ans-practitionner-result.component.scss"],
})
export class AnsPractitionnerResultComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public practitionners: IPractitionerInfo[];
  public indexSelected = 0;
  private onDestroy$ = new Subject<void>();
  public NOT_FOUND_MSG = ERROR_MSG.NOT_FOUND;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { family: string; given: string; msSante: string },
    private dialogRef: MatDialogRef<AnsPractitionnerResultComponent>,
    private practitionnerService: PractitionerService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    if (Tools.isDefined(this.data.msSante)) {
      this.practitionnerService
        .getPractitionersFromDirectory(null, null, this.sessionService.FRANCE, this.data.msSante)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          this.practitionners = result;
          this.isLoading = false;
        });
    } else {
      this.practitionnerService
        .getPractitionersFromDirectory(this.data.family, this.data.given, this.sessionService.FRANCE)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          this.practitionners = result;
          this.isLoading = false;
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public validate(): void {
    this.dialogRef.close(this.practitionners[this.indexSelected]);
  }
}
