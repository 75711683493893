import { EventEmitter, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CookieService } from "ngx-cookie-service";
import { Observable, of, throwError } from "rxjs";
import { catchError, concatMap, filter, first, last, map, mergeMap } from "rxjs/operators";
import { TwoFAModalComponent } from "../components/two-famodal/two-famodal.component";
import { FileLogger } from "../helpers/fileLogger";
import { Account } from "../models/account.interface";
import { AppError } from "../models/app-error.interface";
import { Authentification, IAfterLoginData, IMfaType, MFA_TYPE } from "../models/authentification.interface";
import { AuthApiService } from "./api/auth-api.service";
import { HealthcareserviceService } from "./healthcareservice.service";
import { OrganizationsService } from "./organizations.service";
import { SessionService } from "./session.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  static readonly COOKIE_EHEALTH_REDIRECT = "redirect-fromehealthauth";
  constructor(
    private authApi: AuthApiService,
    private sessionService: SessionService,
    private userService: UserService,
    private healthcareService: HealthcareserviceService,
    private organizationService: OrganizationsService,
    private cookieService: CookieService,
    private dialog: MatDialog
  ) {}

  /**
   * Authenticate user and return url where to redirect
   */
  public authenticate(email: string, password: string, event: EventEmitter<boolean> = null): Observable<IAfterLoginData> {
    return this.authApi.authenticate(email, password).pipe(
      last(),
      mergeMap((auth: Authentification) => {
        event?.emit(true);
        let additionalAction = of(auth);
        if (auth.is2fa) {
          additionalAction = this.prompt2FA(auth);
        }
        return additionalAction;
      }),
      mergeMap((auth) => {
        if (!auth.token) {
          return throwError({
            global: false,
            message: "InvalidData",
          } as AppError);
        }

        this.sessionService.token = auth.token;
        return this.userService.account().pipe(
          filter((a) => a !== null && a !== undefined),
          first(),
          map(() => auth)
        );
      }),
      mergeMap((auth) => {
        const url = this.sessionService.getRedirect();
        if (url !== "/dashboard") {
          return of({
            url,
            newPasswordRequired: auth.newPasswordRequired,
            account: auth.account,
          });
        } else {
          return this.userService.defaultPage().pipe(
            map((pageName) => {
              return {
                url: pageName,
                newPasswordRequired: auth.newPasswordRequired,
                account: auth.account,
              };
            })
          );
        }
      })
    );
  }

  public async redirectToEHealth(): Promise<void> {
    try {
      const eHealthUrl = await this.authApi.getEHealthRedirectUrl().toPromise();
      window.open(eHealthUrl, "_self");
    } catch (err) {
      FileLogger.error("AuthService", "Error while redirecting to eHealth", err);
    }
  }

  public authenticateANS(code: string, event: EventEmitter<boolean> = null): Observable<IAfterLoginData> {
    return this.authApi.authenticateANS(code).pipe(
      mergeMap((auth: Authentification) => {
        event?.emit(true);
        if (auth.otherIds && auth.otherIds.length) {
          // PSC login ok, but no account found. Need mail/password login
          this.sessionService.ANS_otherIDs = auth.otherIds;
          return of(auth);
        }
        if (!auth.token && !auth.tokens) {
          FileLogger.error("AuthService", "Error while authenticating to ANS: no token received");
          return throwError({
            global: false,
            message: "InvalidData",
          } as AppError);
        } else if (auth.token) {
          // Only one account found
          this.sessionService.token = auth.token;
          this.sessionService.idToken = auth.ANS_idToken;
          return this.userService.account().pipe(
            filter((a) => a !== null && a !== undefined),
            first(),
            map(() => auth)
          );
        } else {
          // Several accounts found. Need to select one.
          this.sessionService.idToken = auth.ANS_idToken;
          return of(auth);
        }
      }),
      mergeMap((auth) => {
        if (auth.otherIds && auth.otherIds.length) {
          // PSC login ok, but no account found. Need mail/password login
          return of({
            url: "",
            newPasswordRequired: false,
            account: null,
            needClassicLogin: true,
          } as IAfterLoginData);
        }
        if (auth.tokens && auth.tokens.length) {
          // Several accounts found. Need to select one.
          return of({
            url: "",
            newPasswordRequired: false,
            account: null,
            accounts: auth.accounts,
            tokens: auth.tokens,
            needClassicLogin: false,
          } as IAfterLoginData);
        }
        // Only one account found
        const url = this.sessionService.getRedirect();
        if (url !== "/dashboard") {
          return of({
            url,
            newPasswordRequired: auth.newPasswordRequired,
            account: auth.account,
          });
        } else {
          return this.userService.defaultPage().pipe(
            map((pageName) => {
              return {
                url: pageName,
                newPasswordRequired: auth.newPasswordRequired,
                account: auth.account,
              };
            })
          );
        }
      })
    );
  }

  public selectAccount(auth: IAfterLoginData, selectedIdx: number): Observable<IAfterLoginData> {
    this.sessionService.token = auth.tokens[selectedIdx];
    auth.account = auth.accounts[selectedIdx];
    return this.userService.account().pipe(
      filter((a) => a !== null && a !== undefined),
      first(),
      mergeMap(() => {
        const url = this.sessionService.getRedirect();
        if (url !== "/dashboard") {
          const afterLogin: IAfterLoginData = {
            url,
            newPasswordRequired: auth.newPasswordRequired,
            account: auth.account,
          };
          return of(afterLogin);
        } else {
          return this.userService.defaultPage().pipe(
            map((pageName) => {
              return {
                url: pageName,
                newPasswordRequired: auth.newPasswordRequired,
                account: auth.account,
              };
            })
          );
        }
      })
    );
  }

  public logoutEHealth(): boolean {
    if (!this.cookieService.get(AuthService.COOKIE_EHEALTH_REDIRECT)) {
      return false;
    }
    try {
      const urlLogout = this.cookieService.get(AuthService.COOKIE_EHEALTH_REDIRECT);
      this.cookieService.delete(AuthService.COOKIE_EHEALTH_REDIRECT);
      window.open(urlLogout, "_self");
      return true;
    } catch (err) {
      FileLogger.error("AuthService", "Error while redirecting to eHealth logout", err);
      return false;
    }
  }

  public async logoutANS(): Promise<void> {
    if (!this.sessionService.idToken) {
      FileLogger.log("AuthService", "No id token, no need to logout for ANS");
      return;
    }
    try {
      let urlLogout = await this.authApi.logoutANS().toPromise();
      urlLogout += "?id_token_hint=" + this.sessionService.idToken;
      window.open(urlLogout, "_self");
    } catch (err) {
      FileLogger.error("AuthService", "Error while redirecting to ANS logout", err);
    }
  }

  public async redirectToANS(): Promise<void> {
    try {
      const ansRes = await this.authApi.redirectToANS().toPromise();
      window.open(ansRes, "_self");
    } catch (err) {
      FileLogger.error("AuthService", "Error while redirecting to ANS", err);
    }
  }

  public enable2FA(chosenMfaType: MFA_TYPE): Promise<{ secret: string; qrCodeUrl: string }> {
    return this.authApi.enable2FA(chosenMfaType).toPromise();
  }

  public disable2FA(): Promise<unknown> {
    return this.authApi.disable2FA().toPromise();
  }

  public confirm2FA(code: string): Promise<boolean> {
    return this.authApi.confirm2FA(code).toPromise();
  }

  public logout(): boolean {
    const eHealthlogout = this.logoutEHealth();
    this.logoutANS();
    this.organizationService.clear();
    this.healthcareService.clear();
    this.userService.clear();
    this.sessionService.clear();
    return eHealthlogout;
  }

  public resetPassword(identifier: string): Observable<unknown> {
    return this.authApi.resetPassword(identifier);
  }

  public getMfaTypesList(_account: Account): IMfaType[] {
    const types: IMfaType[] = [];
    for (const value in MFA_TYPE) {
      if (!isNaN(Number(value))) {
        // Here make eventual checks if mfa type is limited to certain types of account \\
        types.push({ mfaType: Number(value), i18nkey: MFA_TYPE[value] });
      }
    }
    return types;
  }

  private presentModal2FA(wrongMfa: boolean) {
    const dialog = this.dialog.open(TwoFAModalComponent, {
      disableClose: true,
      data: { wrongMfa },
    });
    return dialog.afterClosed();
  }

  private prompt2FA(auth: Authentification, wrongMfa = false): Observable<Authentification> {
    return this.presentModal2FA(wrongMfa).pipe(
      concatMap((code) => {
        if (code) {
          return this.authApi.authenticate2FA(auth.token, code).pipe(
            catchError((err) => {
              FileLogger.error("AuthService", "Error while trying to auth with mfa: ", err);
              return this.prompt2FA(auth, true);
            })
          );
        } else {
          auth.token = null;
          return of(auth);
        }
      }),
      catchError((err) => {
        FileLogger.error("AuthService", "Error while presenting 2FA modal", err);
        auth.token = null;
        return of(auth);
      })
    ) as Observable<Authentification>;
  }
}
