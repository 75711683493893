<h2 mat-dialog-title>
  {{ "page.rewardsPage.txtReward" | translate }}
</h2>
<mat-dialog-content class="rewardsDialog">
  <p>{{ recompense }}</p>
</mat-dialog-content>
<mat-dialog-actions class="action-container">
  <button mat-raised-button color="primary" (click)="onClose()">
    {{ "common.cancel" | translate }}
  </button>
  <button mat-raised-button color="primary" class="next-btn" (click)="onConfirm()">
    {{ "page.rewardsPage.confirm" | translate }}
  </button>
</mat-dialog-actions>
