<span #focus></span>
<mat-card class="widget">
  <mat-toolbar color="primary">
    <p class="fs-16">{{ "risk.title" | translate }}</p>
    <span class="spacerBetween"></span>
    <button
      mat-icon-button
      matTooltip="{{ 'risk.addPrediction' | translate }}"
      (click)="addPrediction()"
      *ngIf="('dashboard/predictionasync/csv/launch' | isAuthorized : 'POST' | async) === true"
      aria-label="icon button with a plus icon"
    >
      <mat-icon>add</mat-icon>
    </button>
  </mat-toolbar>
  <mat-card-content [ngClass]="{ hide: dataSource?.data?.length === 0 }">
    <table
      #MatSortMain="matSort"
      #MatTableMain="matTable"
      mat-table
      matSort
      matSortActive="date"
      matSortDirection="desc"
      class="full-width-table"
    >
      <!-- Risk Name Column -->
      <ng-container matColumnDef="risk">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.risk" | translate }}
          <app-item-filter
            fieldName="{{ 'table.risk' | translate }}"
            propertyName="riskName"
            [dataType]="dataTypeChoice"
            [data]="dataSource.data"
            [defaultValue]="getFilter('riskName')"
            translatePrefix="risk"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row?.riskName ? ("risk." + row.riskName | translate) : row.fileName }}
        </td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.date" | translate }}
          <app-item-filter
            fieldName="{{ 'table.date' | translate }}"
            propertyName="issued"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('issued')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ (row?.issued ? row.issued : row.creation) | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <!-- gauge Column -->
      <ng-container matColumnDef="gauge">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.class" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <ng-container *ngIf="!row?.state">
            <div class="gauge" *ngIf="row | riskGauge; else noData">
              <div
                *ngFor="let el of row | riskGauge"
                [style.width]="el.value + '%'"
                [style.backgroundColor]="el.color"
                matTooltip=" {{ el.value }}% {{ row.getTranslatedClassName(translateService, el.key) }}"
              ></div>
            </div>
            <ng-template #noData>
              <p class="alignLeft">{{ "widget.noData" | translate }}</p>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="row?.state === PredictionState.error">
            <p class="alignLeft errorState">
              {{ "risk.state.error" | translate }}
            </p>
          </ng-container>
          <ng-container *ngIf="row?.state === PredictionState.inprogress">
            <p class="alignLeft">
              {{ "risk.state.inprogress" | translate }}
            </p>
          </ng-container>
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.action" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-icon-button
            matTooltip="{{ 'btn.details' | translate }}"
            (click)="viewDetails(row)"
            aria-label="icon button with a loupe icon"
            [disabled]="row?.state"
            [matTooltipDisabled]="row?.state"
          >
            <mat-icon class="custom-icon-small sub-align">search</mat-icon>
          </button>

          <button
            mat-icon-button
            matTooltip="{{ 'risk.download' | translate }}"
            aria-label="icon button pdf"
            (click)="openPdf(row)"
            *ngIf="row | RaHasPdf"
          >
            <mat-icon color="primary">picture_as_pdf</mat-icon>
          </button>

          <!-- Download the submitted model -->
          <button
            mat-icon-button
            matTooltip="{{ 'risk.downloadCsv' | translate }}"
            aria-label="icon button csv"
            (click)="downloadModel(row)"
            *ngIf="row?.basis?.length && ('dashboard/download/canalytics' | isAuthorized : 'GET' | async) === true"
          >
            <mat-icon class="scaleUp" fontSet="fas" fontIcon="fa-file-csv" color="primary"></mat-icon>
          </button>

          <!-- Download the result as a zip file -->
          <button
            mat-icon-button
            matTooltip="{{ 'risk.downloadZip' | translate }}"
            aria-label="icon button zip"
            (click)="downloadResultAsZip(row)"
            *ngIf="row?.basis?.length && ('dashboard/download/canalytics' | isAuthorized : 'GET' | async) === true"
          >
            <mat-icon class="scaleUp" fontSet="fas" fontIcon="fa-file-archive" color="primary"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [ngClass]="{ hide: dataSource?.data?.length === 0 }"
      #paginator
      [pageIndex]="0"
      [pageSize]="10"
      [hidePageSize]="true"
    ></mat-paginator>
  </mat-card-content>
  <div *ngIf="!dataSource?.data?.length && loading" class="loading">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <p class="text-center mt-5" *ngIf="dataSource?.data?.length === 0 && !loading">
    {{ "widget.noData" | translate }}
  </p>
</mat-card>
