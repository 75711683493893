<div class="teleConsultationContent">
  <div class="buttons-bar">
    <button
      mat-icon-button
      color="primary"
      class="negative-margin"
      (click)="openVisioNewTab()"
      mat-dialog-close
      matTooltip="{{ 'btn.openAnotherTab' | translate }}"
    >
      <mat-icon>aspect_ratio</mat-icon>
    </button>
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close matTooltip="{{ 'btn.close' | translate }}">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="videoBoxContainer" *ngIf="embeded" cdkFocusInitial>
    <div>
      <iframe class="visioContent" type="text/html" allow="camera *; microphone *" [src]="trustedURL"></iframe>
    </div>
  </div>
</div>
