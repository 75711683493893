<form [formGroup]="linkForm">
  <section class="properties-group">
    <mat-form-field class="placeholderText">
      <mat-label>{{ "page.questionnaireEditor.properties.link" | translate }}</mat-label>
      <input matInput formControlName="default" [disabled]="disabled" required />
      <mat-error *ngIf="linkForm.controls.default.invalid && linkForm.controls.default.touched">
        {{ "page.questionnaireEditor.error.required" | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="placeholderText">
      <mat-label>{{ "page.questionnaireEditor.properties.linkText" | translate }}</mat-label>
      <input matInput formControlName="placeholder" [disabled]="disabled" />
    </mat-form-field>
  </section>
</form>
