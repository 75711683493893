import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { Tools } from "src/app/helpers/tools";
import { IObservationDefinition } from "src/app/models/observations.interface";
import { RuleAlert } from "src/app/models/rule-alert.model";
import { TranslateAlertNamePipe } from "src/app/pipes/sharedAlerts.pipe";

export class AlertDataSource extends MatTableDataSourceExtended<RuleAlert> {
  sortingDataAccessor = (data: RuleAlert, sortHeaderId: string): string | number => {
    // Get obs name
    let name = data.valueComponents[0]
      ? this.translateNamePipe.transform(data.valueComponents[0], data.observation, this.allDefinitions)
      : "";
    // If no obsName, it's probably a noDataTransmission alert :
    if (data.noDataTransmission) {
      name = this.translateService.instant("alerts.noDataTransmission." + Object.keys(data.noDataTransmission)[0]);
    }

    switch (sortHeaderId) {
      case "type":
        return data.getValueAndUnit(data.valueComponents[0]);
      case "date":
        return moment(data.date()).unix();
      case "level":
        return data.rule.level;
      case "status":
        return data.alertStatus;
      case "incharge":
        return data.author?.display.toLowerCase();
      case "lastcomment":
        return data.notes?.[data.notes.length - 1]?.text.toLowerCase();
      case "name":
        return name;
      case "resultsPractitionner": {
        const r = Tools.getTranslation(
          data.rule.resultsPractitionner ? data.rule.resultsPractitionner[0].value : "",
          this.translateService.currentLang,
          "-"
        );
        return r;
      }
      case "patientStatus":
        return data.status;
      case "patientComment":
        return data.comment;
      default:
        return "";
    }
  };

  constructor(
    private translateService: TranslateService,
    private translateNamePipe: TranslateAlertNamePipe,
    private allDefinitions: IObservationDefinition[]
  ) {
    super();
  }

  public loadData(alerts: RuleAlert[]): void {
    this.data = alerts;
  }
}
