import { Pipe, PipeTransform } from "@angular/core";
import { FileLogger } from "../helpers/fileLogger";
import { Tools } from "../helpers/tools";
import { IObservationDefinition } from "../models/observations.interface";
import { ITranslation } from "../models/translation.interface";
import { SessionService } from "../providers/session.service";

@Pipe({ name: "getTranslation" })
export class GetTranslationPipe implements PipeTransform {
  constructor(private sessionService: SessionService) {}
  transform(def: ITranslation, backup: string, currentLang?: string): string {
    if (!currentLang) {
      currentLang = this.sessionService.userLang;
    }
    return Tools.getTranslation(def, currentLang, backup);
  }
}

@Pipe({ name: "loincAndValueMeaning" })
export class LoincAndValueMeaningPipe implements PipeTransform {
  constructor(private sessionService: SessionService) {}
  transform(
    loinc: string,
    parentLoinc: string,
    value: number | string,
    allDefinitions: IObservationDefinition[],
    withValue = false,
    noDefault = false,
    noUnit = false
  ): string {
    let def: IObservationDefinition;
    if (parentLoinc) {
      // if we have the parent's loinc, this is easy
      def = allDefinitions.find((d) => d.loinc === parentLoinc);
    } else {
      // If not, we first try to check if there's only
      // one component with the right code in all the defs:
      const possibleDefs = allDefinitions.filter((d) => d.components.find((c) => c.loinc === loinc));
      if (possibleDefs.length === 1) {
        def = possibleDefs[0];
      } else {
        // if there's several... well, we select the one with the same
        // parent's loinc as the component loinc (we just assume it's the
        // most likely one. No garantee)
        def = possibleDefs.find((d) => d.loinc === loinc);
        if (!def) {
          // if we have no luck with that we just randomly chose the first one
          def = possibleDefs[0];
        }
      }
    }
    if (def) {
      if (noDefault && (value === undefined || value === null || value === "-")) {
        return "-";
      }
      const targetComponent = def.components.find((c) => c.loinc === loinc);
      if (targetComponent) {
        if (targetComponent?.meaning?.length && value !== undefined && value !== null && value !== "-") {
          const targetMeaning = targetComponent.meaning.find((m) => m.value === value);
          const trans = Tools.getTranslation(targetMeaning?.description, this.sessionService.userLang, targetComponent.loinc);
          return trans;
        } else if (!noUnit && targetComponent.unit) {
          return withValue && value !== undefined && value !== null && value !== "-"
            ? value + " " + targetComponent.unit
            : targetComponent.unit;
        } else {
          return withValue && value !== undefined && value !== null && value !== "-" ? value + "" : "-";
        }
      } else {
        FileLogger.log("LoincAndValueMeaningPipe", "target not found... " + loinc + " " + parentLoinc + " in ", def);
      }
    }
    if (noDefault) {
      return withValue && value !== undefined && value !== null && value !== "-" ? value + " -" : "-";
    }
    return withValue && value !== undefined && value !== null && value !== "-" ? value + " " + loinc : loinc;
  }
}
