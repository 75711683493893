import { Component, ElementRef, HostListener, ViewChild } from "@angular/core";
import * as d3 from "d3";
import { from, Subject } from "rxjs";
import { debounceTime, delay, first } from "rxjs/operators";
import { ChartHelper, xAxisType, yAxisType } from "src/app/helpers/chart-helper";
import { IChartConfig } from "src/app/models/stream-observation-interface";
import uuid from "uuid-random";

@Component({
  selector: "app-base-chart",
  templateUrl: "./base-chart.component.html",
  styleUrls: ["./base-chart.component.scss"],
})
export abstract class BaseChartComponent {
  @HostListener("window:resize", ["$event"])
  onResize(_event: Event): void {
    this.chartResizeSubject.next();
  }

  public loading = true;
  private chartResizeSubject = new Subject<void>();
  protected abstract chartConfig: IChartConfig;
  public abstract drawPaths(): void;

  public chartId = "a" + uuid().slice(1);
  public chartContainer: ElementRef<HTMLDivElement>;

  @ViewChild("chartContainer") set _chartContainer(el: ElementRef<HTMLDivElement>) {
    if (el) {
      this.chartContainer = el;
      this.chartConfig.width = this.chartContainer.nativeElement.clientWidth;
    }
  }

  public svg: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>;

  // SVG dimensions
  public margin: number;
  public width: number;
  public height: number;

  public x: d3.ScaleLinear<number, number, never> | d3.ScaleTime<number, number, never>;
  public y: d3.ScaleLinear<number, number, never>;

  constructor() {
    this.chartResizeSubject.pipe(debounceTime(300)).subscribe(async () => {
      if (!this.chartContainer) return;

      this.chartConfig.width = this.chartContainer.nativeElement.clientWidth;
      const dataReady = from(this.createChart()).pipe(first(), delay(500));
      dataReady.subscribe(() => {
        this.loading = false;
      });
    });
  }

  private applyConfig() {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        this.margin = this.chartConfig.margin;
        this.width = this.chartConfig.width - this.margin * 2;
        this.height = this.chartConfig.height - this.margin * 2;
        resolve();
      });
    });
  }
  protected async createChart(): Promise<void> {
    this.loading = true;
    // We make sure to remove the existing chart in case it already exists
    d3.select(`figure#${this.chartId}`).selectAll("svg").remove();
    await this.applyConfig();
    this.createSvg();
    const { x, y } = await this.createAxis();
    this.x = x;
    this.y = y;
    this.drawPaths();
  }

  private createSvg() {
    this.svg = ChartHelper.createSvg(this.width, this.height, this.margin, `figure#${this.chartId}`, true);
  }

  private async createAxis() {
    return { x: this.createXAxis(), y: this.createYAxis() };
  }

  private createXAxis() {
    // Define x-axis using linear scale for minutesAfterMidnight
    return ChartHelper.createXAxis(
      <xAxisType>this.chartConfig.xAxis.type,
      this.margin,
      this.width,
      this.height,
      this.svg,
      this.chartConfig
    );
  }

  private createYAxis() {
    return ChartHelper.createYAxis(
      <yAxisType>this.chartConfig.yAxis.type,
      this.margin,
      this.width,
      this.height,
      this.svg,
      this.chartConfig
    );
  }
}
