import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-pages-editor",
  templateUrl: "./pages-editor.component.html",
  styleUrls: ["./pages-editor.component.scss"],
})
export class PagesEditorComponent implements OnChanges {
  @Input() translationKey: string;
  @Input() pages: number;
  @Input() currentPage: number;
  @Input() visualization = false;
  @Output() currentPageChange = new EventEmitter<number>();
  /* eslint-disable-next-line */
  @Output() onCreateNewPage = new EventEmitter<number>();
  /* eslint-disable-next-line */
  @Output() onRemovePage = new EventEmitter<number>();

  public counter: unknown[];

  ngOnChanges(_changes: SimpleChanges): void {
    this.counter = new Array(this.pages);
  }

  /**
   * Add a page to the questionnaire
   */
  public addPage(): void {
    this.onCreateNewPage.emit(this.pages);
  }

  public removePage(pageId: number): void {
    this.onRemovePage.emit(pageId);
  }

  /**
   * Display a page from the questionnaire.
   * @param pageId (number) the index of the page
   */
  public displayPage(pageId: number): void {
    this.currentPageChange.emit(pageId);
  }
}
