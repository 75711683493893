import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DataType, Filter } from "src/app/models/filter.interface";
import { IActionAlert } from "src/app/models/rule-alert.interface";
import { IVitalSignsHistory } from "src/app/models/vital-signs-history.interface";
import { VitalSignsHistory } from "src/app/models/vital-signs-history.model";
import { VitalProfileDefinition } from "src/app/models/vitalProfileDefinition.model";
import { VitalSignService } from "src/app/providers/vital-sign.service";
import { ValueArrayDialogComponent } from "../../value-array-dialog/value-array-dialog.component";
import { VitalSignsHistoricDataSource } from "./vital-signs-historic-datasource";

@Component({
  selector: "app-vital-signs-historic",
  templateUrl: "./vital-signs-historic.component.html",
  styleUrls: ["./vital-signs-historic.component.scss"],
})
export class VitalSignsHistoricComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<IActionAlert>;

  public dataSource: VitalSignsHistoricDataSource;
  public currentPageSize: number;
  public displayedColumns: string[] = ["date", "author", "exValue", "newValue", "type"];
  public historic: VitalSignsHistory[] = [];
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  public constructor(
    private dialog: MatDialog,
    private vsService: VitalSignService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      definitions: VitalProfileDefinition[];
    }
  ) {
    this.vsService
      .list(this.data.patientId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.historic = result.map((v) => {
          const defFound = this.data.definitions.find((vp) => vp.code === v.type);

          return new VitalSignsHistory(
            {
              author: v.author,
              patientId: v.patientId,
              date: v.date,
              newValue: v.newValue ? v.newValue : 0,
              exValue: v.exValue ? v.exValue : 0,
              type: v.type,
              unit: v.unit,
            },
            defFound
          );
        });

        this.initData();
      });
  }

  ngOnInit(): void {
    this.dataSource = new VitalSignsHistoricDataSource();
  }

  ngAfterViewInit(): void {
    this.initPaginatorAndSort();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initData() {
    this.dataSource.loadData(this.historic as IVitalSignsHistory[]);
  }

  private initPaginatorAndSort() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    // this.table.dataSource = this.dataSource;

    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentPageSize = this.paginator.pageSize;
    });
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
  }

  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public openValueArrayDialog(vsHistory: VitalSignsHistory, valueArray: number[][]): void {
    this.dialog.open(ValueArrayDialogComponent, {
      data: { vsHistory, valueArray },
      disableClose: false,
      width: "100%",
    });
  }
}
