<form [formGroup]="patientForm">
  <h2 mat-dialog-title>{{ "forms.appointment.patientData" | translate }}</h2>
  <mat-form-field>
    <mat-label>{{ "forms.birthdate" | translate }}</mat-label>
    <input
      matInput
      [matDatepicker]="birthDate"
      [max]="maxDate"
      formControlName="birthDate"
      placeholder="{{ 'forms.birthdateFormat' | translate }}"
      required
    />
    <mat-datepicker-toggle matSuffix [for]="birthDate"></mat-datepicker-toggle>
    <mat-datepicker #birthDate></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ "forms.gender" | translate }}</mat-label>
    <mat-select formControlName="gender" required>
      <mat-option *ngFor="let gender of availableGender" [value]="gender.code">{{ gender.display }} </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ "forms.pseudoname" | translate }}</mat-label>
    <input matInput formControlName="pseudoname" />
  </mat-form-field>
</form>
