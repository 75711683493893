import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IKnowMedia } from "src/app/models/knowledge.interface";

@Pipe({
  name: "knowledgeMedias",
})
export class KnowledgeMediasPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: IKnowMedia[], ..._args: unknown[]): Set<string> {
    return new Set(
      value.map((el) => {
        if (el.type && el.language) {
          return this.translateService.instant(`knowledgebase.mediatype.${el.type}`) + ` ${el.language.toUpperCase()}`;
        } else return "";
      })
    );
  }
}
