import { animate, state, style, transition, trigger } from "@angular/animations";
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { first } from "rxjs/operators";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { RiskAssessmentHelper } from "src/app/helpers/RiskAssessmentHelper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IPValues, IRiskAssessment, IRiskAssessmentPrediction } from "src/app/models/riskAssessment.interface";
import { RiskAssessment } from "src/app/models/riskAssessment.model";
import { IScrollPosition } from "src/app/models/scroll.interface";
import { RiskAssessmentService } from "src/app/providers/risk-assessments-api.service";

export interface IchartData {
  id: string;
  label: string;
  value: number;
}

@Component({
  selector: "app-risk-assessment-details",
  templateUrl: "./risk-assessment-details.component.html",
  styleUrls: ["./risk-assessment-details.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
})
export class RiskAssessmentDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = [];
  pValuesDataSource = new MatTableDataSource<IPValues>();
  columnsToDisplay = [];
  expandedElement: IRiskAssessmentPrediction;
  chartExpImg: string;
  illustration: string; // image in base64 for the risk; from db
  mainPrediction: IRiskAssessmentPrediction;
  captionRAChart = "";
  captionRAChart2 = "";

  chartData: IchartData[];
  enoughData: boolean;
  noData: boolean;

  public algoETS = RiskAssessmentHelper.ALGO_ETS;
  public algoGBTP = RiskAssessmentHelper.ALGO_GBTP;

  public availableAlgo: { rationale: string; version: string }[] = [];
  public availableClassCodes: string[] = [];

  public showScrollHint = false;
  public chartLoaded: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { risk: RiskAssessment },
    public translateService: TranslateService,
    public raService: RiskAssessmentService
  ) {}

  // shortcut
  public get risk(): RiskAssessment {
    return this.data.risk;
  }

  ngOnInit(): void {
    this.illustration = this.risk.illustrations[0]?.chart[this.translateService.currentLang];
    this.availableClassCodes = this.risk.classCode;
    this.availableAlgo = this.risk.availableAlgosKeys;
    this.createDataSource();
    this.setColumnsToDisplay();
    this.enoughData = this.isEnoughData();
    this.noData = this.hasNoData();
  }

  ngAfterViewInit(): void {
    // Connect paginator and sort to DataSource
    if (this.paginator) this.pValuesDataSource.paginator = this.paginator;
    if (this.sort) this.pValuesDataSource.sort = this.sort;
    // If the user changes the sort order, reset back to the first page.
    this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
  }

  public setColumnsToDisplay(): void {
    if (this.risk?.context?.pValues?.length) {
      this.columnsToDisplay = ["features", "pValues"];
    } else {
      this.columnsToDisplay.push("algoName");
      this.availableClassCodes.forEach((classCode) => {
        this.columnsToDisplay.push(classCode);
      });
    }

    if (this.dataSource.length > 1 && this.risk.riskName === "Risk_Copd_Triaging_Binary") {
      this.columnsToDisplay.push("action");
    }
  }

  public createDataSource(): void {
    if (this.risk?.context?.pValues?.length) {
      this.pValuesDataSource.data = this.risk.context.pValues;
      this.pValuesDataSource.sortingDataAccessor = (data: IPValues, sortHeaderId: string): string | number => {
        switch (sortHeaderId) {
          case "features":
            return data.code.coding[0].display;
          case "pValues":
            return data.pValue;
          default:
            return "";
        }
      };
    } else {
      this.availableAlgo.forEach((algo) => {
        const row = { algo };
        this.dataSource.push(row);

        this.availableClassCodes.forEach((classCode) => {
          row[classCode] = {
            proba: this.risk.getPredictionValueByKeyAndCode(algo.rationale, algo.version, classCode),
            baseValue: this.risk.getPredictionBaseValueByKeyAndCode(algo.rationale, algo.version, classCode),
            shapValues: this.risk.getPredictionShapValuesByKeyAndCode(algo.rationale, algo.version, classCode),
          };
        });
      });
    }
  }

  public loadChart(element: { algo: { rationale: string; version: string } }): void {
    // Don't try to load chart for ETS algo since the image is sent by the backend when available (this.illustration).
    if (element.algo.rationale === "ETS") {
      return;
    }

    // Create chartData that is sent as an input to the chart component

    const currentPredictions: IRiskAssessmentPrediction[] = [];

    this.availableClassCodes.forEach((classCode) => {
      currentPredictions.push(this.risk.getPredictionByKeyAndCode(element.algo.rationale, element.algo.version, classCode));
    });

    // we want the main prediction to be "need for medical attention"

    const index = currentPredictions.findIndex((el) => el.outcome.coding[0].code === "needMedicalAttention");

    if (index) {
      this.mainPrediction = currentPredictions[index];
    } else {
      FileLogger.error("RiskAssessmentDetailsComponent", 'No key "needMedicalAttention"');

      this.mainPrediction = currentPredictions.sort((a, b) => {
        if (a.probabilityDecimal < b.probabilityDecimal) {
          return 1;
        }
        if (a.probabilityDecimal > b.probabilityDecimal) {
          return -1;
        }
      })[0];
    }

    this.chartData = this.mainPrediction.explainers.shap.shapValues.map((el, index) => {
      return {
        id: index.toString(),
        label: this.translateService.instant(this.getLabelTranslateKey(el)),
        value: el.valueExplainer,
      };
    });

    this.chartData = this.chartData.sort((a, b) => {
      if (Math.abs(a.value) < Math.abs(b.value)) {
        return 1;
      }
      if (Math.abs(a.value) > Math.abs(b.value)) {
        return -1;
      }
    });
    this.chartData.unshift({
      id: "baseValue",
      label: this.translateService.instant("risk.baseValue"),
      value: this.mainPrediction.explainers.shap.baseValue,
    });
    this.chartData.push({ id: "output", label: this.translateService.instant("risk.output"), value: 0 });

    // Get chart explainer from the server
    this.raService
      .getChartExplainer(this.risk.identifier[0].value, this.mainPrediction.rationale, this.mainPrediction.version, "en")
      .pipe(first())
      .subscribe((x) => {
        this.chartExpImg = x.shap[0].en;
        this.chartLoaded = true;
      });

    // create the captions for both charts
    this.createCaption();
  }

  private getLabelTranslateKey(el): string {
    let coding = el.code.coding.filter((c) => c.system === FHIRHelper.SYSTEM_LOINC)[0];
    if (!coding) {
      coding = el.code.coding[0];
    }
    switch (coding.system) {
      case FHIRHelper.SYSTEM_LOINC:
        return el.instant === "baseline" ? "predictionChart.loincOfReference." + coding.code : "predictionChart.loinc." + coding.code;
      case FHIRHelper.SYSTEM_SNOMED:
        return "predictionChart.snomed." + coding.code;
      default:
        return "communicare." + coding.code;
    }
  }

  private createCaption() {
    const top3Positive = this.mainPrediction.explainers.shap.shapValues
      .filter((x) => x.valueExplainer > 0)
      .sort((a, b) => (a.valueExplainer > b.valueExplainer ? -1 : 1))
      .slice(0, 3);

    const top3Negative = this.mainPrediction.explainers.shap.shapValues
      .filter((x) => x.valueExplainer < 0)
      .sort((a, b) => (Math.abs(a.valueExplainer) > Math.abs(b.valueExplainer) ? -1 : 1))
      .slice(0, 3);

    this.captionRAChart = this.translateService.instant("Risk_Copd_Triaging_Binary.captionRAChart.part1");
    this.captionRAChart2 = `${this.translateService.instant("Risk_Copd_Triaging_Binary.captionRAChart.part2")}
      <i>${this.translateService.instant(this.getLabelTranslateKey(top3Positive[0]))}</i>,
      <i>${this.translateService.instant(this.getLabelTranslateKey(top3Positive[1]))}</i>.
      <br>
      ${this.translateService.instant("Risk_Copd_Triaging_Binary.captionRAChart.part3")}

        <i>${this.translateService.instant(this.getLabelTranslateKey(top3Negative[0]))}<i>,
        <i>${this.translateService.instant(this.getLabelTranslateKey(top3Negative[1]))}.
      `;
  }

  public isEnoughData(): boolean {
    const notEnoughDataValue = this.risk.prediction.find((p) => p.outcome.coding[0].code === "not_enough_data")?.probabilityDecimal;
    if (notEnoughDataValue === 1) {
      return false;
    } else {
      return true;
    }
  }

  public hasNoData(): boolean {
    const noDataValue = this.risk.prediction.find((p) => p.outcome.coding[0].code === "no_data")?.probabilityDecimal;
    if (noDataValue === 1) {
      return true;
    } else {
      return false;
    }
  }

  public openPdf(risk: IRiskAssessment): void {
    this.raService.openPdf(risk, this.translateService.currentLang, this.translateService.instant("api.errors.pdf-error"));
  }

  public computeShowScrollHint(event: IScrollPosition): void {
    if (event.horizontal === "end" || event.horizontal === null) {
      this.showScrollHint = false;
    } else {
      this.showScrollHint = true;
    }
  }
}
