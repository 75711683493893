import { Pipe, PipeTransform } from "@angular/core";
import { Codes } from "../models/codes.interface";
import { IObservationDefinition } from "../models/observations.interface";

@Pipe({
  name: "findObsDefByLoinc",
})
export class FindObsDefByLoincPipe implements PipeTransform {
  transform(observationDefinition: IObservationDefinition[], code: Codes): IObservationDefinition {
    return observationDefinition.find((el) => el.loinc === code.coding[0]?.code);
  }
}
