import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "../../helpers/tools";
import { PractitionerService } from "../../providers/practitioner.service";
import { DisplayAuthcodeConfirmationComponent } from "../display-authcode-confirmation/display-authcode-confirmation.component";
import { ErrorGeneratePasswordComponent } from "../error-generate-password/error-generate-password.component";

@Component({
  selector: "app-confirm-reset-password-dialog",
  templateUrl: "./confirm-reset-password-dialog.component.html",
  styleUrls: ["./confirm-reset-password-dialog.component.scss"],
})
export class ConfirmResetPasswordDialogComponent {
  public response$: Observable<unknown>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      currentUser: { mail: string; name: string; firstname: string; caremateId: string };
      authcode: string;
      preference?: boolean;
    },
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ConfirmResetPasswordDialogComponent>,
    private practitionerService: PractitionerService
  ) {}

  public closeModal(): void {
    this.dialogRef.close();
  }

  /**
   * Define a new password in backend for a practicien
   * @param user the practicien
   * @param password the new password
   * @returns true if the password is really reset, false otherwise
   */
  public computeAuthcode(caremateId: string, password: string): Observable<boolean> {
    return this.practitionerService.resetPractitionerPassword(caremateId, password).pipe(
      map(
        () => {
          return true;
        },
        catchError((error) => {
          FileLogger.error("ConfirmResetPasswordDialogComponent", "computeAuthcode", error);
          return of(false);
        })
      )
    );
  }

  public displayTheRightModal(): void {
    this.closeModal();

    const password: string = Tools.generatePassword(8);

    this.computeAuthcode(this.data.currentUser.caremateId, password).subscribe(
      (success) => {
        if (success) {
          return this.dialog.open(DisplayAuthcodeConfirmationComponent, {
            width: "450px",
            height: "280px",
            data: {
              currentUser: this.data.currentUser,
              authcode: password,
              preference: this.data.preference,
            },
          });
        }
      },
      (error) => {
        FileLogger.error("ConfirmResetPasswordDialogComponent", "computeAuthcode in displayTheRightModal", error);
        this.dialog.open(ErrorGeneratePasswordComponent, {
          width: "450px",
          height: "150px",
        });
      }
    );
  }
}
