import { Component, ComponentFactoryResolver, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { RuleAlertComponent } from "src/app/components/patient-alerts/patient-alerts.component";
import { PatientCareplansComponent } from "src/app/components/patient-careplans/patient-careplans.component";
import { PatientDocumentsComponent } from "src/app/components/patient-documents/patient-documents.component";
import { PatientDrugsComponent } from "src/app/components/patient-drugs/patient-drugs.component";
import { PatientObservationsComponent } from "src/app/components/patient-observations/patient-observations.component";
import { PatientQRComponent } from "src/app/components/patient-QR/patient-QR.component";
import { PatientRewardComponent } from "src/app/components/patient-reward/patient-reward.component";
import { PatientTeleconsultationsComponent } from "src/app/components/patient-teleconsultations/patient-teleconsultations.component";
import { PatientVitalSignsComponent } from "src/app/components/patient-vitalSigns/patient-vital-signs.component";
import { WidgetDirective } from "src/app/components/widgets/widget.directive";
import { Base64Helper } from "src/app/helpers/Base64Helper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { PatientUser } from "src/app/models/patient.interface";
import { PatientPageParameter, PatientWidgetName, PreferenceContext, TileParameter } from "src/app/models/preference.interface";
import { IWidgetComponent, WidgetName } from "src/app/models/widget.interface";
import { ActivityService } from "src/app/providers/activity.service";
import { PatientService } from "src/app/providers/patient.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { SessionService } from "src/app/providers/session.service";
import { TileManager } from "src/app/providers/tile-manager.service";
import { UserStatisticsService } from "src/app/providers/userStatistics.service";
import { WidgetManagerService } from "src/app/providers/widget-manager.service";

@Component({
  selector: "app-patient-page",
  templateUrl: "./patient-page.component.html",
  styleUrls: ["./patient-page.component.scss"],
})
export class PatientPageComponent implements OnInit, OnDestroy {
  @ViewChild(WidgetDirective, { static: true }) widgetContainer: WidgetDirective;
  @ViewChild(MatDrawer) matDrawer: MatDrawer;
  @ViewChild(PatientObservationsComponent) obsComp: PatientObservationsComponent;
  @ViewChild(PatientQRComponent) qrComp: PatientQRComponent;
  @ViewChild(PatientDrugsComponent) drugsComp: PatientDrugsComponent;
  @ViewChild(PatientVitalSignsComponent) vsComp: PatientVitalSignsComponent;
  @ViewChild(PatientCareplansComponent) cpComp: PatientCareplansComponent;
  @ViewChild(RuleAlertComponent) alertsComp: RuleAlertComponent;
  @ViewChild(PatientTeleconsultationsComponent) teleConsultComp: PatientTeleconsultationsComponent;
  @ViewChild(PatientRewardComponent) rewardsComp: PatientRewardComponent;
  @ViewChild(PatientDocumentsComponent) docComp: PatientDocumentsComponent;

  public patientUser: PatientUser;
  public widgetName: string;

  private readonly COOKIE_PATIENT_ID = "redirect-patientid";
  private readonly COOKIE_NOT_VALID_PATIENT = "redirect-notvalidpatient";
  private caremateIdentifier: string;
  public pageLoaded = false;
  private selectedWidgetsPatientList: PatientWidgetName[] = [];
  public selectedWidgetPatientListLeft: PatientWidgetName[] = [];
  public selectedWidgetPatientListRight: PatientWidgetName[] = [];
  public visibleWidgets: Map<PatientWidgetName, boolean> = new Map<PatientWidgetName, boolean>();

  public noneWidgetPref = false;
  public noneWidgetSelected: boolean;
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private activityService: ActivityService,
    private patientService: PatientService,
    private widgetManagerService: WidgetManagerService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cookieService: CookieService,
    private preferenceService: PreferenceService,
    private sessionService: SessionService,
    private statService: UserStatisticsService
  ) {
    // Check cookie param
    if (this.cookieService.check(this.COOKIE_NOT_VALID_PATIENT)) {
      this.router.navigate(["/patients"]);
    } else if (this.cookieService.check(this.COOKIE_PATIENT_ID)) {
      this.loadPatientUser(this.cookieService.get(this.COOKIE_PATIENT_ID));
      this.cookieService.delete(this.COOKIE_PATIENT_ID);
    } else {
      this.route.params.pipe(takeUntil(this.onDestroy$)).subscribe((params) => {
        // Id (Base 64) from URL
        if (params.id) {
          this.caremateIdentifier = Base64Helper.b64_to_utf8(params.id);
        }
        this.loadFromState();
      });
    }
  }

  ngOnInit(): void {
    // Reload user when needRefreshPatientUser() is called
    this.sessionService.refreshPatientUser.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.loadPatientUser(this.patientUser.user.caremateIdentifier);
    });

    // Called when we change the preferences
    // It reloads all the preferences and recomputes the tiles:
    this.sessionService.refreshPatientPage.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.pageLoaded = false;
      this.selectedWidgetPatientListLeft = [];
      this.selectedWidgetPatientListRight = [];
      this.loadTilePreference();
    });
    // Called when we put one tile in big mode
    // (or when the tiles check whether they should be in big mode)
    // Only recomputes which tiles are visible:
    this.sessionService.refreshPatientWidgets.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.refreshWidgetsShown();
    });
    this.loadPreference();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadFromState() {
    if (this.matDrawer) {
      this.matDrawer.close();
    }
    this.route.paramMap
      .pipe(
        takeUntil(this.onDestroy$),
        map(() => window.history.state)
      )
      .subscribe((state) => {
        if (!this.caremateIdentifier) {
          this.caremateIdentifier = state.caremateIdentifier;
        }
        if (this.caremateIdentifier) {
          if (state.widget === WidgetName.PATIENT_LIST) {
            this.loadPatientUser(this.caremateIdentifier, state.widget);
          } else {
            this.loadPatientUser(this.caremateIdentifier, state.widget);
          }
        } else if (!state.patient || !state.user) {
          this.router.navigate(["/patients"]);
          return;
        } else {
          this.patientUser = state;
          this.activityService.log(state.patient).pipe(takeUntil(this.onDestroy$)).subscribe();
        }
      });
  }

  private loadPatientUser(caremateIdentifier: string, widget?: string) {
    this.patientService
      .getPatientUser(caremateIdentifier)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (patientUser) => {
          this.patientUser = patientUser;
          this.activityService.log(patientUser.patient).pipe(takeUntil(this.onDestroy$)).subscribe();
          if (widget) {
            const widgetComponent = this.widgetManagerService.mapWidget(widget) as Type<IWidgetComponent>;
            if (widgetComponent) {
              const componentFactory = this.componentFactoryResolver.resolveComponentFactory(widgetComponent);
              const viewContainerRef = this.widgetContainer.viewContainerRef;
              viewContainerRef.clear();
              const componentRef = viewContainerRef.createComponent(componentFactory);
              (componentRef.instance as IWidgetComponent).isDraggable = false;
            }
            this.widgetName = widget;
          }
        },
        (err) => {
          FileLogger.error("PatientPageComponent", "loadPatientUser", err);
          this.router.navigate(["/patients"]);
        }
      );
  }

  public loadPreference(): void {
    this.preferenceService
      .list(PreferenceContext.PATIENT_PAGE)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((p: PatientPageParameter) => {
        if (p?.lastFocusWidgetName) {
          this.focus(p.lastFocusWidgetName);
        }
      });
    this.loadTilePreference();
  }

  private loadTilePreference(): void {
    this.preferenceService
      .list(PreferenceContext.TILE)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: TileParameter) => {
        if (!parameters) {
          this.noneWidgetPref = true;
          this.noneWidgetSelected = false;
          this.selectedWidgetsPatientList = [];
        } else {
          this.selectedWidgetsPatientList = parameters.list;
          this.noneWidgetSelected = this.selectedWidgetsPatientList.length === 0;
        }
        this.initSelectedWidgetPatientList();
        this.pageLoaded = true;
      });
  }

  public focus(widgetName: PatientWidgetName): void {
    switch (widgetName) {
      case PatientWidgetName.ALERTS:
        if (this.alertsComp) {
          this.alertsComp.scrollAfterDataInit = true;
        }
        break;
      case PatientWidgetName.OBSERVATIONS:
        if (this.obsComp) {
          this.obsComp.scrollAfterDataInit = true;
        }
        break;
      case PatientWidgetName.CAREPLANS:
        if (this.cpComp) {
          this.cpComp.scrollAfterDataInit = true;
        }
        break;
      case PatientWidgetName.DRUGS:
        if (this.drugsComp) {
          this.drugsComp.scrollAfterDataInit = true;
        }
        break;
      case PatientWidgetName.QUESTIONNAIRES:
        if (this.qrComp) {
          this.qrComp.scrollAfterDataInit = true;
        }
        break;
      case PatientWidgetName.VITAL_SIGNS:
        if (this.vsComp) {
          this.vsComp.scrollAfterDataInit = true;
        }
        break;
      case PatientWidgetName.TELECONSULTATIONS:
        if (this.teleConsultComp) {
          this.teleConsultComp.scrollAfterDataInit = true;
        }
        break;
      case PatientWidgetName.DOCUMENTS:
        if (this.docComp) {
          this.docComp.scrollAfterDataInit = true;
        }
        break;
      default:
        break;
    }
  }

  private isShown(key: PatientWidgetName) {
    return (
      TileManager.showAll ||
      TileManager.isBig(key) ||
      (!TileManager.showAll && !TileManager.isOneBigAmongSelected(this.selectedWidgetsPatientList))
    );
  }

  private initSelectedWidgetPatientList() {
    if (this.noneWidgetPref) {
      this.selectedWidgetsPatientList = Object.keys(PatientWidgetName).map((key) => PatientWidgetName[key]);
    }
    const selectedWidgetPatientListLeft = [];
    const selectedWidgetPatientListRight = [];
    let i = 0;
    for (const widget of this.selectedWidgetsPatientList) {
      this.visibleWidgets.set(widget, this.isShown(widget));
      if (window.innerWidth >= 1215) {
        // there are two widgets per line, so the order must be :
        // 1    2
        // 3    4
        // 5    ...
        if (i % 2 === 0) {
          selectedWidgetPatientListLeft.push(widget);
        } else {
          selectedWidgetPatientListRight.push(widget);
        }
      } else {
        // there is only one widget per line, so the order must be : 1  2   3   4 ... (vetically)
        if (i <= Math.floor(this.selectedWidgetsPatientList.length / 2)) {
          selectedWidgetPatientListLeft.push(widget);
        } else {
          selectedWidgetPatientListRight.push(widget);
        }
      }
      i++;
    }
    this.selectedWidgetPatientListLeft = selectedWidgetPatientListLeft;
    this.selectedWidgetPatientListRight = selectedWidgetPatientListRight;
  }

  private refreshWidgetsShown() {
    this.visibleWidgets.forEach((isVisible: boolean, widget: PatientWidgetName) => {
      this.visibleWidgets.set(widget, this.isShown(widget));
    });
  }

  public recordOpenPatientListWidget(): void {
    this.statService.createStatEvent("Toggled patient list widget");
  }
}
