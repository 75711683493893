<form id="pageProperties" class="pageProperties" [formGroup]="pagePropsForm" (ngSubmit)="applyChanges()">
  <mat-form-field class="title-field">
    <mat-label>{{ "page.questionnaireEditor.properties.title" | translate }}</mat-label>
    <input matInput required type="text" formControlName="title" />
  </mat-form-field>
  <mat-form-field class="description-field">
    <mat-label>{{ "page.questionnaireEditor.properties.description" | translate }}</mat-label>
    <textarea matInput formControlName="description"></textarea>
  </mat-form-field>
</form>
