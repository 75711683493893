<form [formGroup]="initPwdForm" (ngSubmit)="apply()">
  <mat-dialog-content>
    <h2>{{ "forms.initPwd" | translate }}</h2>
    <p>{{ "page.login.newPwdLabel" | translate }}</p>
    <!-- This is a workaround to disabled autocomplete issue on chrome -->
    <div style="display: none">
      <input type="password" />
    </div>
    <div class="form-container" [ngClass]="isFieldValid('newPasswordA') ? 'hasError' : ''">
      <!-- New password input -->
      <mat-form-field>
        <mat-label>{{ "page.login.newPassword" | translate }}</mat-label>
        <app-show-hide-password>
          <input autocomplete="off" type="password" formControlName="newPasswordA" matInput />
        </app-show-hide-password>
        <!-- New password input validation error -->
        <mat-error *ngIf="isFieldValid('newPasswordA')">
          {{ "page.login.password-notValid" | translate }}
        </mat-error>
      </mat-form-field>
      <!-- Confirm password input -->
      <mat-form-field>
        <mat-label>{{ "page.login.confirmNewPassword" | translate }}</mat-label>
        <app-show-hide-password>
          <input autocomplete="off" type="password" formControlName="newPasswordB" matInput />
        </app-show-hide-password>
        <!-- Confirm password input validation error -->
        <mat-error *ngIf="initPwdForm.get('newPasswordB')?.touched && initPwdForm.get('newPasswordB').errors?.matching">
          {{ "page.login.password-not-same" | translate }}
        </mat-error>
      </mat-form-field>
      <!-- additionalData error -->
      <mat-error *ngIf="passwordError">
        {{ passwordError }}
      </mat-error>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit">
      {{ "btn.confirmPwd" | translate }}
    </button>
  </mat-dialog-actions>
</form>
