<div class="com-overview">
  <div class="close-cross" *ngIf="data.modaleMode">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div [class]="data.modaleMode ? '' : 'smartphone'">
      <div class="content">
        <h1>{{ media?.label }}</h1>
        <mat-tab-group [selectedIndex]="selectedIndex" no-header-tabs #tabGroup>
          <mat-tab *ngFor="let slide of slides">
            <div [innerHtml]="slide | sanitizeHtml"></div>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="footer" [style]="data.modaleMode ? 'text-align: center' : ''">
        <button
          mat-button
          [ngStyle]="tabGroup.selectedIndex <= 0 ? { visibility: 'hidden' } : {}"
          (click)="tabGroup.selectedIndex = tabGroup.selectedIndex - 1"
        >
          Précédent
        </button>
        <button *ngIf="summary && summary.length" mat-button [matMenuTriggerFor]="sum">Sommaire</button>
        <mat-menu #sum="matMenu" yPosition="above">
          <button mat-menu-item *ngFor="let item of summary; let index = index" (click)="tabGroup.selectedIndex = index">
            {{ item }}
          </button>
        </mat-menu>
        <button
          mat-button
          [ngStyle]="tabGroup.selectedIndex >= slides?.length - 1 ? { visibility: 'hidden' } : {}"
          (click)="tabGroup.selectedIndex = tabGroup.selectedIndex + 1"
        >
          Suivant
        </button>
      </div>
    </div>
  </mat-dialog-content>
</div>
