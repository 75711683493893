import { Component, Input, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { INPUT_TYPE, QuestionQuestionnaire } from "src/app/models/questionnaire.interface";

@Component({
  selector: "app-link-input",
  templateUrl: "./link-input.component.html",
  styleUrls: ["./link-input.component.scss"],
})
export class LinkInputComponent implements OnDestroy {
  @Input() disabled = false;
  @Input() set question(q: QuestionQuestionnaire) {
    this.linkQuestion = q;
    this.setup();
  }

  linkForm: FormGroup;
  linkQuestion: QuestionQuestionnaire;

  protected onDestroy$ = new Subject<void>();

  constructor(private fb: FormBuilder) {
    this.linkForm = this.fb.group({
      default: [{ value: "", disabled: this.disabled }, Validators.required],
      placeholder: [{ value: "", disabled: this.disabled }],
    });

    this.linkForm.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.onValuesChange());
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public setup(): void {
    this.linkForm.patchValue({
      default: this.linkQuestion.answerDisplay.default,
      placeholder: this.linkQuestion.answerDisplay.placeholder,
    });
    this.linkQuestion.type = INPUT_TYPE.TEXT;
  }

  public onValuesChange(): void {
    this.linkQuestion.answerDisplay.default = this.linkForm.value.default;
    this.linkQuestion.answerDisplay.placeholder = this.linkForm.value.placeholder;
  }
}
