import { TranslateLoader } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { catchError, concatMap } from "rxjs/operators";
import { FileLogger } from "../helpers/fileLogger";
import { ApiService } from "./api/api.service";

enum I18nTarget {
  MOBILE = "mobile",
  DASHBOARD = "dashboard",
}

interface II18nTranslation {
  lang: string;
  target: I18nTarget;
  i18n: any;
}

export class CustomI18nTranslateLoader implements TranslateLoader {
  constructor(private apiService: ApiService) {}

  public getTranslation(lang: string): Observable<any> {
    const i18nUrl = "i18nTranslation";
    const i18nParams = {
      target: I18nTarget.DASHBOARD,
      langs: lang,
    };
    const serverObservable = this.apiService.get(i18nUrl, i18nParams).pipe(
      catchError((error) => {
        FileLogger.error("CustomI18nTranslateLoader", "Server error while downloading translations", error);
        return of(null);
      }),
      concatMap((serverTrsl: II18nTranslation[]) => {
        if (!serverTrsl || serverTrsl.length < 1) {
          FileLogger.error("CustomI18nTranslateLoader", "Server sent back empty translations");
          return of({});
        } else {
          return of(serverTrsl[0].i18n);
        }
      })
    );

    return serverObservable;
  }
}
