import { Pipe, PipeTransform } from "@angular/core";
import { LEVEL_COLOR } from "../models/rule-alert.interface";
import { RuleAlert } from "../models/rule-alert.model";

@Pipe({
  name: "alertLevelClass",
})
export class AlertLevelClassPipe implements PipeTransform {
  /**
   * Return the class name corresponding to the color of the alert.
   * To be used directly with [ngClass]
   * E.g. [ngClass]="ruleAlert | alertLevelClass "
   */
  transform(ruleAlert: RuleAlert): unknown {
    switch (ruleAlert.level) {
      case 1:
        return LEVEL_COLOR.ONE;
      case 2:
        return LEVEL_COLOR.TWO;
      case 3:
        return LEVEL_COLOR.THREE;
      default:
        return "";
    }
  }
}
