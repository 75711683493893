import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ui-file-drop",
  templateUrl: "./ui-file-drop.component.html",
  styleUrls: ["./ui-file-drop.component.scss"],
})
export class UiFileDropComponent {
  @ViewChild("importInput") importInput: ElementRef;
  @Input() acceptedTypes: string[]; // The different accepted types
  @Input() typeErrorTranlationKey: string;
  @Input() maxSize: number; // The maximum size of the file in bytes
  @Input() sizeErrorTranslationKey: string;
  @Input() hasError = false;
  @Output() chosenFile: EventEmitter<File> = new EventEmitter();
  public file: File;

  constructor(private snackBar: MatSnackBar, private translateService: TranslateService) {}

  /**
   * Checks if the file type and size are correct.
   * If type and size are correct, emit event
   * @param files The selected/dropped file
   */
  public dragAndDropFile(files: FileList): void {
    if (files && files[0]) {
      if (!this.acceptedTypes.includes(files[0].type)) {
        this.snackBar.open(this.translateService.instant(this.typeErrorTranlationKey), "OK", { duration: 5000 });
        return;
      }
      if (files[0].size > this.maxSize) {
        this.snackBar.open(this.translateService.instant(this.sizeErrorTranslationKey), "OK", { duration: 5000 });
        return;
      }
      this.file = files[0];
      this.chosenFile.emit(this.file);
    }
  }

  /**
   * Opens file manager and handles the error
   * @param event The selected file from the manager
   */
  public import(event: { target: { files: FileList } }): void {
    const files = event.target.files;
    if (files) {
      this.dragAndDropFile(files);
    } else {
      throw new Error("UiFileDropComponent - No file selected");
    }
  }

  /**
   * Triggers the input click
   */
  public onSelectFile(): void {
    this.importInput.nativeElement.value = ""; // Otherwise, the same file can't be selected again.
    this.importInput.nativeElement.click();
  }

  /**
   * Resets the selected file
   */
  public resetFile(): void {
    this.file = undefined;
  }
}
