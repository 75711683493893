import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { concatMap, first } from "rxjs/operators";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { Tools } from "src/app/helpers/tools";
import { PatientDataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { Filter } from "src/app/models/filter.interface";
import { IPatientObservation } from "src/app/models/observations.interface";
import { AddPatientData, IPatientInfo, IPatientsListInfo, Patient, PatientUser } from "src/app/models/patient.interface";
import { IConfiguration } from "src/app/models/patientConfig.interface";
import { IPatientHistoric } from "src/app/models/patientHistoric.interface";
import { Reference } from "src/app/models/reference.interface";
import { ITiming } from "src/app/models/sharedInterfaces";
import { PreferenceService } from "../preference.service";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class PatientApiService extends BaseApiService {
  constructor(private api: ApiService, private preferenceService: PreferenceService) {
    super(
      "Patients", // Theme !
      ["patient", "getPatientUsers"], // Post route paths
      [
        "dashboard/patientsInfos", // 0
        "patientUser", // 1
        "patients/lastActivity", // 2
        "patientsInfos", // 3
        "nbPatientsInfos", // 4
        "patient/historic", // 5
        "dashboard/patientObservationDefinitions", // 6
      ], // Get route paths
      [
        "patient", // 0
        "anonymousPatient", // 1
        "deactivatePatient", // 2
        "activatePatient", // 3
        "dashboard/configurationPatient", // 4
        "patient/retrievedIdentity", // 5
        "dashboard/obsParamsFreqConfig", // 6
      ], // Update route paths
      ["patient"] // Delete route paths
    );
  }

  public listRefsByHealthcareService(
    healthcareService: string,
    services?: string[],
    monitoringServicesIds?: string[]
  ): Observable<Reference[]> {
    const params: {
      healthcareService?: string;
      services?: string;
      monitoringServicesIds?: string;
    } = { healthcareService };
    if (Tools.isDefined(services) && !ArrayHelper.isEmpty(services)) {
      params.services = JSON.stringify(services);
    }
    if (Tools.isDefined(monitoringServicesIds) && !ArrayHelper.isEmpty(monitoringServicesIds)) {
      params.monitoringServicesIds = monitoringServicesIds.join();
    }
    return this.api.get("dashboard/patientsRefs", params) as Observable<Reference[]>;
  }

  public listPatientInfo(
    services: string[],
    criteria: string,
    limit: number,
    monitoringServicesIds?: string[]
  ): Observable<IPatientInfo[]> {
    const params: {
      services?: string;
      monitoringServicesIds?: string;
      criteria?: string;
      limit?: number;
    } = { criteria, limit };
    if (Tools.isDefined(services) && !ArrayHelper.isEmpty(services)) {
      params.services = JSON.stringify(services);
    }
    if (Tools.isDefined(monitoringServicesIds) && !ArrayHelper.isEmpty(monitoringServicesIds)) {
      params.monitoringServicesIds = monitoringServicesIds.join();
    }
    return this.api.get(this.readRoutes[0], params) as Observable<IPatientInfo[]>;
  }

  public update(patient: Patient, isAnonymous = false): Observable<Patient> {
    return isAnonymous
      ? (this.api.put(this.updateRoutes[1], patient) as Observable<Patient>)
      : (this.api.put(this.updateRoutes[0], patient) as Observable<Patient>);
  }

  public deactivatePatient(patientId: string, disableReason?: string): Observable<unknown> {
    const body = {
      patientId,
      disableReason,
    };
    return this.api.put(this.updateRoutes[2], body);
  }

  public activatePatient(patientId: string): Observable<Patient> {
    const body = { patientId };
    return this.api.put(this.updateRoutes[3], body) as Observable<Patient>;
  }

  public removeForEver(patientId: string): Observable<Patient> {
    return this.api.deleteWithParams(this.deleteRoutes[0], {
      patientId,
    }) as Observable<Patient>;
  }

  public create(patient: AddPatientData, reqParams: Record<string, string | boolean>): Observable<Patient> {
    return this.api.post(this.createRoutes[0], patient, reqParams) as Observable<Patient>;
  }

  public getPatientUser(caremateIdentifier: string): Observable<PatientUser> {
    return this.api.get(this.readRoutes[1], {
      caremateIdentifier,
    }) as Observable<PatientUser>;
  }

  public getPatientUsers(patientIds: string[]): Observable<PatientUser[]> {
    return this.api.post(this.createRoutes[1], patientIds) as Observable<PatientUser[]>;
  }

  public listInactivePatient(services: string[], monitoringServicesIds?: string[]): Observable<Patient[]> {
    return this.preferenceService.getGlobal().pipe(
      first(),
      concatMap((parameters) => {
        const period = parameters?.inactifPatientDuration ? parameters.inactifPatientDuration : "1 week";
        const params: {
          period?: string;
          departementId?: string;
          services?: string;
          monitoringServicesIds?: string;
        } = {
          period: period,
          services: JSON.stringify(services),
        };
        if (Tools.isDefined(monitoringServicesIds) && !ArrayHelper.isEmpty(monitoringServicesIds)) {
          params.monitoringServicesIds = monitoringServicesIds.join();
        }
        return this.api.get(this.readRoutes[2], params) as Observable<Patient[]>;
      })
    );
  }

  public findPatients(params: PatientDataSourceBackendParam): Observable<IPatientsListInfo[]> {
    return this.api.get(this.readRoutes[3], {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      search: params.search,
      withInactive: params.withInactive,
      services: JSON.stringify(params.services),
      sortId: params.sortId,
      sortOrder: params.sortOrder,
      lang: params.lang,
      filters: JSON.stringify(params.filters),
      groupId: params.groupId,
      period: params.period,
      monitoringServicesIds: params.monitoringServicesIds?.join(),
      insNumber: params.insNumber,
    }) as Observable<IPatientsListInfo[]>;
  }

  public getPatientsCount(
    services?: string[],
    search?: string,
    filters?: Filter[],
    withInactive?: boolean,
    groupId?: string,
    period?: string,
    monitoringServicesIds?: string[],
    insNumber?: string
  ): Observable<{ count: number }> {
    const params: {
      search?: string;
      withInactive?: boolean;
      services?: string;
      monitoringServicesIds?: string;
      filters?: string;
      groupId?: string;
      period?: string;
      insNumber?: string;
    } = {
      search,
      withInactive,
      groupId,
      period,
      insNumber,
    };
    if (Tools.isDefined(services) && !ArrayHelper.isEmpty(services)) {
      params.services = JSON.stringify(services);
    }
    if (Tools.isDefined(monitoringServicesIds) && !ArrayHelper.isEmpty(monitoringServicesIds)) {
      params.monitoringServicesIds = monitoringServicesIds.join();
    }
    if (Tools.isDefined(filters) && !ArrayHelper.isEmpty(filters)) {
      params.filters = JSON.stringify(filters);
    }
    return this.api.get(this.readRoutes[4], params) as Observable<{
      count: number;
    }>;
  }

  public getPatientHistoric(patientId: string, organizationId: string): Observable<IPatientHistoric[]> {
    return this.api.get(this.readRoutes[5], {
      patientId,
      organizationId,
    }) as Observable<IPatientHistoric[]>;
  }

  public getPatientObservationDefinitions(identifier: string, language?: string): Observable<IPatientObservation> {
    return this.api.get(this.readRoutes[6], {
      identifier,
      language,
    }) as Observable<IPatientObservation>;
  }

  public updatePatientConfiguration(identifier: string, code: string[]): Observable<unknown> {
    const body = {
      caremateId: identifier,
      code,
    };
    return this.api.put(this.updateRoutes[4], body);
  }

  public updatePatientObsParamsTiming(caremateId: string, obsLoinc: string, timing: ITiming): Observable<unknown> {
    const body = {
      caremateId,
      obsLoinc,
      timing,
    };
    return this.api.put(this.updateRoutes[6], body);
  }

  public getPatientConfiguration(caremateId: string): Observable<IConfiguration> {
    return this.api.get("dashboard/configuration", {
      identifier: caremateId,
    }) as Observable<IConfiguration>;
  }
}
