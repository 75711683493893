import { Codes } from "./codes.interface";
import { Coding } from "./coding.interface";
import { Identifier } from "./identifier.interface";
import { Reference } from "./reference.interface";
import { IEntity, IIllustration } from "./sharedInterfaces";

export interface IShapValue {
  valueExplainer: number;
  valueQuantity: {
    value: number;
  };
  code: {
    coding: { code: string; display: string; system: string }[];
  };
}

export interface IExplainers {
  shap: {
    baseValue: number;
    r2: number;
    shapValues: IShapValue[];
  };
}

export interface IRiskAssessmentPrediction {
  explainers: IExplainers;
  outcome: {
    coding: Coding[];
  };
  rationale: string;
  probabilityDecimal: number;
  order: number;
  version: string;
}

export interface IChartBase64Lang {
  typeChart: "Beeswarm" | ""; // list of type of charts (at the moment, only Beeswarm)
  fr: string;
  en: string;
  nl: string;
  de: string;
  it: string;
}

export interface IBasis {
  reference: string;
  type: string;
}

export interface IContext {
  pValues: IPValues[];
}
export interface IPValues {
  pValue: number;
  code: Codes;
}

export interface IRiskAssessment extends IEntity {
  subject: Reference;
  issued: string;
  identifier: Identifier[];
  prediction: IRiskAssessmentPrediction[];
  illustrations: IIllustration[];
  about?: Reference[];
  context?: IContext;
  basis?: IBasis[];
}

export const TopMD = "TopMD";
export const SUMMARY = "summary";
export const monthlyBloodPressureStability = "Monthly_Blood_Pressure_Stability";

export enum PredictionCode {
  NOT_ENOUGH_DATA = "not_enough_data",
  NO_DATA = "no_data",
  NORMAL = "2004005",
}

export enum PredictionState {
  todo = "todo",
  inprogress = "inprogress",
  finish = "finish",
  error = "error",
}

export interface IPredictionStatus {
  creation: string;
  modified: string;
  patientId: string;
  authorId: string;
  predictionType: string;
  state: PredictionState;
  analysisId: string;
  fileName?: string;
}
