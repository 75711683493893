import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Group } from "../../../../models/questionnaire.interface";

@Component({
  selector: "app-page-properties",
  templateUrl: "./page-properties.component.html",
  styleUrls: ["./page-properties.component.scss"],
})
export class PagePropertiesComponent implements OnInit {
  @Input() page: Group;
  @Input() visualization = false;

  @Output() pageChange = new EventEmitter<Group>();
  @Output() closeEvent = new EventEmitter<void>();

  public pagePropsForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    if (this.page) {
      this.pagePropsForm = this.fb.group({
        title: [this.page.title, [Validators.required]],
        description: [this.page.text, []],
      });
      if (this.visualization) {
        this.pagePropsForm.disable();
      }
    }
  }

  /**
   * Apply the change to the page and send them to the parent component.
   */
  public applyChanges(): void {
    if (this.pagePropsForm.valid) {
      this.page.title = this.pagePropsForm.value.title;
      this.page.text = this.pagePropsForm.value.description;
      this.pageChange.emit(this.page);

      // close panel after changes
      this.closeEvent.next();
    }
  }
}
