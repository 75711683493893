<div class="loading" *ngIf="loading">
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
  {{ "common.loading" | translate }}
</div>
<mat-sidenav-container class="sidenav-container" *ngIf="!loading">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    opened="false"
    id="sideMenu"
  >
    <div class="container">
      <div>
        <div class="userInfos">
          <p class="nameLabel" *ngIf="user && user.displayName">
            {{ user.displayName }}
          </p>
          <p *ngIf="!isAuthorizedSelectOrg" class="serviceLabel">
            {{ ownOrgString }}
          </p>
          <!-- if one service only show text as label -->
          <p *ngIf="user?.healthcareService?.length === 1" class="serviceLabel">
            {{ ownServiceString }}
          </p>
        </div>
        <mat-nav-list *ngIf="isLoggedIn">
          <a
            mat-list-item
            (click)="drawer.toggle()"
            routerLink="/dashboard"
            routerLinkActive="active"
            aria-label="dashboard"
            *ngIf="('/dashboard' | isAuthorized | async) === true"
            >{{ "menu.dashboard" | translate }}</a
          >
          <a
            mat-list-item
            (click)="drawer.toggle()"
            routerLink="/teleconsultations"
            routerLinkActive="active"
            aria-label="teleconsultations list"
            *ngIf="('/teleconsultations' | isAuthorized | async) === true"
            >{{ "page.teleconsultations.title" | translate }}</a
          >
          <a
            mat-list-item
            (click)="drawer.toggle()"
            routerLink="/patients"
            routerLinkActive="active"
            aria-label="patients list"
            *ngIf="('/patients' | isAuthorized | async) === true && ('/simplePatientsList' | isAuthorized | async) === false"
            >{{ "menu.patients" | translate }}</a
          >
          <a
            mat-list-item
            (click)="drawer.toggle()"
            routerLink="/simplePatientsList"
            routerLinkActive="active"
            aria-label="simplified patients list"
            *ngIf="('/simplePatientsList' | isAuthorized | async) === true"
            >{{ "menu.patients" | translate }}</a
          >
          <a
            mat-list-item
            (click)="drawer.toggle()"
            routerLink="/questionnaireList"
            routerLinkActive="active"
            aria-label="questionnaires' responses list"
            *ngIf="('/questionnaireList' | isAuthorized | async) === true"
            id="sideMenuQRLink"
            >{{ "menu.questionnaireList" | translate }}</a
          >
          <a
            mat-list-item
            (click)="drawer.toggle()"
            routerLink="/rewardsPage"
            routerLinkActive="active"
            aria-label="rewards list"
            *ngIf="('/rewardsPage' | isAuthorized | async) === true"
            >{{ "menu.rewardsPage" | translate }}</a
          >
          <a
            mat-list-item
            (click)="drawer.toggle()"
            routerLink="/communications"
            routerLinkActive="active"
            aria-label="communications list"
            *ngIf="('/communications' | isAuthorized | async) === true"
            >{{ "menu.communications" | translate }}</a
          >
          <a
            mat-list-item
            (click)="drawer.toggle()"
            routerLink="/knowledgeList"
            routerLinkActive="active"
            aria-label="knowledges list"
            *ngIf="('/knowledgeList' | isAuthorized | async) === true"
            >{{ "menu.knowledge" | translate }}</a
          >
          <a
            mat-list-item
            (click)="drawer.toggle()"
            routerLink="/quizList"
            routerLinkActive="active"
            aria-label="quizzes list"
            *ngIf="('/knowledgeList' | isAuthorized | async) === true"
            >{{ "menu.quizList" | translate }}</a
          >
          <a
            mat-list-item
            (click)="drawer.toggle()"
            routerLink="/consentList"
            routerLinkActive="active"
            aria-label="consent forms list"
            *ngIf="('/knowledgeList' | isAuthorized | async) === true"
            >{{ "menu.consentList" | translate }}</a
          >
          <a
            mat-list-item
            (click)="showSubmenu = !showSubmenu"
            *ngIf="
              ('/permissions' | isAuthorized | async) === true ||
              ('/accessGroups' | isAuthorized | async) === true ||
              ('/organizations' | isAuthorized | async) === true ||
              ('/myService' | isAuthorized | async) === true ||
              ('/drugSchema' | isAuthorized | async) === true ||
              ('/templatesQuestionnaire' | isAuthorized | async) === true ||
              ('/fhirQuestionnaireList' | isAuthorized | async) === true ||
              ('/deleteRequestList' | isAuthorized | async) === true ||
              ('/statSummary' | isAuthorized | async) === true ||
              ('/statEvents' | isAuthorized | async) === true ||
              ('/correspondant' | isAuthorized | async) === true ||
              ('/journalInsi' | isAuthorized | async) === true ||
              ('/careplansList' | isAuthorized | async) === true ||
              ('/identityVerifMethods' | isAuthorized | async) === true ||
              ('/dataExtraction' | isAuthorized | async) === true
            "
            id="sideMenusSubMenu"
          >
            <span class="full-width" *ngIf="isExpanded || isShowing">{{ "menu.managment" | translate }}</span>
            <mat-icon class="menu-button" [ngClass]="{ rotated: showSubmenu }" *ngIf="isExpanded || isShowing"> expand_more</mat-icon>
          </a>
          <div class="submenu" [ngClass]="{ expanded: showSubmenu }" *ngIf="isShowing || isExpanded">
            <a
              mat-list-item
              (click)="drawer.toggle()"
              routerLink="/accessGroups"
              routerLinkActive="active"
              aria-label="access groups management"
              *ngIf="('/accessGroups' | isAuthorized | async) === true"
              >{{ "menu.accessGroups" | translate }}</a
            >
            <a
              mat-list-item
              (click)="drawer.toggle()"
              routerLink="/organizations"
              routerLinkActive="active"
              aria-label="organizations management"
              *ngIf="('/organizations' | isAuthorized | async) === true"
              >{{ "menu.organizations" | translate }}</a
            >
            <a
              mat-list-item
              (click)="drawer.toggle()"
              routerLink="/correspondant"
              routerLinkActive="active"
              aria-label="correspondants management"
              *ngIf="!ENV.production && isAdmin"
              >{{ "menu.correspondant" | translate }}</a
            >
            <!--
            <a
              mat-list-item
              (click)="drawer.toggle()"
              routerLink="/organizations"
              routerLinkActive="active"
              *ngIf="(isAuthorizedPageMyOrganization() | async) === true"
              >{{ "menu.organization" | translate }}</a
            >
            -->
            <a
              mat-list-item
              (click)="drawer.toggle()"
              routerLink="/myService"
              routerLinkActive="active"
              *ngIf="('/myService' | isAuthorized | async) === true"
              >{{ "menu.service" | translate }}</a
            >
            <a
              mat-list-item
              (click)="drawer.toggle()"
              routerLink="/drugSchema"
              routerLinkActive="active"
              aria-label="drugs' schemas management"
              *ngIf="('/drugSchema' | isAuthorized | async) === true"
              >{{ "menu.drugSchema" | translate }}</a
            >
            <a
              mat-list-item
              (click)="drawer.toggle()"
              routerLink="/templatesQuestionnaire"
              routerLinkActive="active"
              aria-label="questionnaires' templates list"
              *ngIf="('/templatesQuestionnaire' | isAuthorized | async) === true"
              >{{ "menu.templates" | translate }}</a
            >
            <a
              mat-list-item
              (click)="drawer.toggle()"
              routerLink="/careplansList"
              routerLinkActive="active"
              aria-label="careplans list"
              *ngIf="('/careplansList' | isAuthorized | async) === true"
              >{{ "menu.careplansEditor" | translate }}</a
            >
            <a
              mat-list-item
              (click)="drawer.toggle()"
              routerLink="/fhirQuestionnaireList"
              routerLinkActive="active"
              aria-label="questionnaires list"
              *ngIf="('/fhirQuestionnaireList' | isAuthorized | async) === true"
              >{{ "menu.fhirquestionnaires" | translate }}</a
            >
            <a
              mat-list-item
              (click)="drawer.toggle()"
              routerLink="/deleteRequestList"
              routerLinkActive="active"
              aria-label="delete requests list"
              *ngIf="('/deleteRequestList' | isAuthorized | async) === true"
              class="deleteRequestList"
            >
              {{ "menu.deleteRequestList" | translate }}
            </a>
            <a
              mat-list-item
              (click)="drawer.toggle()"
              routerLink="/statSummary"
              routerLinkActive="active"
              aria-label="statistics summary"
              *ngIf="('/statSummary' | isAuthorized | async) === true"
              class="statSummary"
            >
              {{ "page.statSummary.title" | translate }}
            </a>
            <a
              mat-list-item
              (click)="drawer.toggle()"
              routerLink="/statEvents"
              routerLinkActive="active"
              aria-label="statistics events list"
              *ngIf="('/statEvents' | isAuthorized | async) === true"
              class="statEvents"
            >
              {{ "page.statEvents.title" | translate }}
            </a>
            <!-- INSI call logs -->
            <a
              mat-list-item
              (click)="drawer.toggle()"
              routerLink="/journalInsi"
              routerLinkActive="active"
              aria-label="Journal Insi"
              *ngIf="('/journalInsi' | isAuthorized | async) === true"
            >
              {{ "page.journalInsi.title" | translate }}
            </a>
            <!-- Identity verification methods -->
            <a
              mat-list-item
              (click)="drawer.toggle()"
              routerLink="/identityVerifMethods"
              routerLinkActive="active"
              aria-label="identityVerifMethods"
              *ngIf="('/identityVerifMethods' | isAuthorized | async) === true"
            >
              {{ "page.identityVerifMethods.title" | translate }}
            </a>
            <!-- Data extraction -->
            <a
              mat-list-item
              (click)="drawer.toggle()"
              routerLink="/dataExtraction"
              routerLinkActive="active"
              aria-label="dataExtraction"
              *ngIf="('/dataExtraction' | isAuthorized | async) === true"
            >
              {{ "page.dataExtraction.title" | translate }}
            </a>
          </div>
        </mat-nav-list>
      </div>
      <mat-nav-list *ngIf="isLoggedIn">
        <a mat-list-item href="" (click)="logout()" id="sideMenuLogout"
          >{{ "menu.logout" | translate }}
          <mat-icon class="fail ml-15">exit_to_app</mat-icon>
        </a>
      </mat-nav-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div *ngIf="maintenance" class="maintenance-banner">
      <p *ngIf="maintenance.maintenanceStatus === MaintenanceStatus.ONGOING">
        {{ "maintenance.infoMessage" | translate }}
        {{ maintenance.estimatedEnd | date : "HH:mm" }}
      </p>
      <p *ngIf="maintenance.maintenanceStatus === MaintenanceStatus.PLANNED">
        {{ "maintenance.plannedMessage1" | translate }}
        {{ maintenance.plannedStart | date : "HH:mm, EEEE, d MMMM" }}{{ "maintenance.plannedMessage2" | translate }}
      </p>
    </div>
    <app-display-environement-dialog></app-display-environement-dialog>
    <mat-toolbar color="grey" class="mainToolBar" *ngIf="!isFullscreen && isLoggedIn" #topMenu>
      <button type="button" id="sidenavOpenBtn" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <app-user-infos></app-user-infos>
      <span class="spacer"></span>
      <p id="topMenuTitle">{{ pageTitle | translate }}</p>
      <span class="spacer"></span>
      <button mat-icon-button class="color-button" *ngIf="smallTopMenu" (click)="expandTopMenu = !expandTopMenu">
        <mat-icon>more_horiz</mat-icon>
      </button>
      <app-top-menu-icons *ngIf="!smallTopMenu"> </app-top-menu-icons>
      <app-change-lang [mode]="langMode"></app-change-lang>
      <button
        mat-icon-button
        matTooltip="{{ 'menu.logout' | translate }}"
        class="fail mt-15"
        (click)="logout()"
        aria-label="icon button with a logout icon"
        id="topMenuLogout"
      >
        <a href="">
          <mat-icon class="fail">exit_to_app</mat-icon>
        </a>
      </button>
    </mat-toolbar>
    <div *ngIf="smallTopMenu && expandTopMenu" class="expandMenu">
      <app-top-menu-icons> </app-top-menu-icons>
    </div>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
