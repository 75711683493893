import { Pipe, PipeTransform } from "@angular/core";
import { ObservationHelper } from "../helpers/observationHelper";
import { Tools } from "../helpers/tools";
import { IObservationDefinition, IObservationWithoutComponents, OComponent } from "../models/observations.interface";
import { SessionService } from "../providers/session.service";

@Pipe({ name: "translateAlertName" })
export class TranslateAlertNamePipe implements PipeTransform {
  constructor(private sessionService: SessionService) {}
  /**
   * Checks the observations' definitions for the name of an observation and returns it.
   * If there isn't a corresponding definition for this observation, it returns the display
   * of the observation (usually it is the question in the language it was presented to the user).
   * @param element (OComponent) the observation's component (= part of the observation corresponding to one question)
   * @param observation (IObservation) the observation
   * @param allDefinitions (ObservationDefinition[]) list of all the definitions we have for the observations
   * @returns the translated name of the observation
   */
  transform(element: OComponent, observation: IObservationWithoutComponents, allDefinitions: IObservationDefinition[]): string {
    let parentLoinc: string;
    let def: IObservationDefinition;
    if (element && allDefinitions) {
      const elemLoinc = element.code.coding[0].code;
      const elemLoincWithoutSuffix = ObservationHelper.ignoreSuffix(elemLoinc);
      // Let's try to find the corresponding observation's definition!
      if (observation) {
        // this is the new easy way, where we save the observation in the alert
        parentLoinc = observation.code.coding[0].code;
        def = allDefinitions.find((d) => d.loinc === parentLoinc);
      } else {
        // this is the bad old way when we did not save the observation in the alert
        // Since that thing is really badly done, we first try to check if there's only
        // one component with the right code in all the def:
        const possibleDefs = allDefinitions.filter((d) => d.components.find((c) => c.loinc === elemLoinc));
        if (possibleDefs.length === 1) {
          def = possibleDefs[0];
        } else {
          // if there's several... well, we select the one with the same
          // parent's loinc as the component loinc (we just assume it's the
          // most likely one. No guarantee)
          def = possibleDefs.find((d) => d.loinc === elemLoinc);
          if (!def) {
            // if we have no luck with that we just randomly chose the first one
            def = possibleDefs[0];
          }
        }
      }
      if (def) {
        const componentDef = def.components.find((d) => d.loinc === elemLoinc);
        const componentDefWithoutSuffix = def.components.find((d) => ObservationHelper.ignoreSuffix(d.loinc) === elemLoincWithoutSuffix);
        if (componentDef) {
          if (componentDef.shortnameTranslation) {
            return Tools.getTranslation(componentDef.shortnameTranslation, this.sessionService.userLang, componentDef.loinc);
          }

          return Tools.getTranslation(componentDef.nameTranslation, this.sessionService.userLang, componentDef.loinc);
        } else if (componentDefWithoutSuffix) {
          if (componentDefWithoutSuffix.shortnameTranslation) {
            return Tools.getTranslation(
              componentDefWithoutSuffix.shortnameTranslation,
              this.sessionService.userLang,
              componentDefWithoutSuffix.loinc
            );
          }

          return Tools.getTranslation(
            componentDefWithoutSuffix.nameTranslation,
            this.sessionService.userLang,
            componentDefWithoutSuffix.loinc
          );
        }
      }
    }
    // if no definition was found, we simply return the display
    return element.code.coding[0].display;
  }
}
