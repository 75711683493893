import { Component } from "@angular/core";
import { CareplanEditorService } from "src/app/careplan-editor/domain/careplan-editor.service";

@Component({
  selector: "app-careplan-publication-dialog",
  templateUrl: "./careplan-publication-dialog.component.html",
  styleUrls: ["./careplan-publication-dialog.component.scss"],
})
export class CareplanPublicationDialogComponent {
  public updateCriteria: any;

  constructor(private careplanEditorService: CareplanEditorService) {}

  public confirm() {
    this.careplanEditorService.careplanTemplateForm.markAllAsTouched();
    this.careplanEditorService.save(true, true, false, this.updateCriteria);
  }
}
