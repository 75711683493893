import { AfterViewInit, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatCalendar } from "@angular/material/datepicker";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";
import { Tools } from "src/app/helpers/tools";
import { EntityDrug, Entitylink, IEntitylink, StatusEntity } from "src/app/models/entity.interface";
import { Timing } from "src/app/models/sharedModels.model";
import { IStepwise } from "src/app/models/stepwiseDrug.interface";
import { TIMING_CODES, TimingData } from "src/app/models/timingData.model";
import { DrugSchemaService } from "src/app/providers/drugSchema.service";

@Component({
  selector: "app-drugs-calendar",
  templateUrl: "./drugs-calendar.component.html",
  styleUrls: ["./drugs-calendar.component.scss"],
})
export class DrugsCalendarComponent implements OnInit, AfterViewInit {
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;

  private intakeDays: { date: Date; drugId: string }[] = [];
  public activeDrugs: Entitylink[] = [];
  public drugsToShow: Entitylink[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      allDrugs: IEntitylink[];
    }
  ) {}

  ngOnInit(): void {
    this.data.allDrugs.forEach((entity) => {
      if (entity.entityStatus.includes(StatusEntity.ACTIVE)) {
        this.activeDrugs.push(entity);
      }
    });
    this.computeIntakeDays();
  }

  ngAfterViewInit(): void {
    const monthPrevBtn = document.querySelectorAll(".mat-calendar-previous-button");
    const monthNextBtn = document.querySelectorAll(".mat-calendar-next-button");
    if (monthPrevBtn) {
      Array.from(monthPrevBtn).forEach((button) => {
        button.addEventListener("click", () => this.onMonthChanged());
      });
    }

    if (monthNextBtn) {
      Array.from(monthNextBtn).forEach((button) => {
        button.addEventListener("click", () => this.onMonthChanged());
      });
    }
  }

  public dateClass = (date: Date): string[] => {
    if (this.isIntakeDays(date)) {
      return ["selected"];
    }
    return [];
  };

  private isIntakeDays(date: Date) {
    const formatDate: string = new Date(date).toDateString();
    return this.intakeDays.findIndex((d) => new Date(d.date).toDateString() === formatDate) !== -1;
  }

  public computeIntakeDays(): void {
    this.intakeDays = [];
    const date = this.calendar
      ? moment(this.calendar.selected as Date)
          .startOf("month")
          .toDate()
      : new Date();
    let drugsToCompute = this.activeDrugs;
    if (this.drugsToShow && this.drugsToShow.length > 0) {
      drugsToCompute = this.drugsToShow;
    }
    drugsToCompute.forEach((entity) => {
      const from = moment(date).startOf("month");
      const to = moment(date).endOf("month");
      const entityDrug: EntityDrug = entity.entityData;
      let drugsDays: string[] = [];
      if (entityDrug.stepwiseSchema) {
        this.computeStepwiseDrugIntakeDays(entity, from, to);
      } else {
        if (entityDrug.cycle) {
          drugsDays = DrugSchemaService.getCycleInstances(entityDrug.cycle, entityDrug.frequency, from, to);
        } else {
          drugsDays = TimingData.getTimingInstances(entityDrug.frequency, from, to);
        }
        for (const day of drugsDays) {
          const intakeDay = {
            drugId: entity._id,
            date: new Date(day),
          };
          this.intakeDays.push(intakeDay);
        }
      }
    });
    this.calendar?.updateTodaysDate();
  }

  private computeStepwiseDrugIntakeDays(entitylink: Entitylink, from: moment.Moment, to: moment.Moment) {
    const drugData = entitylink.entityData as EntityDrug;
    const stepwises: IStepwise[] = drugData.stepwiseSchema.stepwises;
    stepwises.forEach((stepwise) => {
      const startDay = moment(drugData.frequency.boundsPeriod.start).add(stepwise.startDay, "d");
      stepwise.days.forEach((d, i) => {
        const frequency: Timing = {
          boundsPeriod: {
            start: moment(Tools.clone(startDay)).add(i, "d").format(),
          },
          period: 1,
          periodUnits: "d",
        };
        if (
          !drugData.frequency.boundsPeriod.end ||
          moment(frequency.boundsPeriod.start).isSameOrBefore(moment(drugData.frequency.boundsPeriod.end))
        ) {
          frequency.boundsPeriod.end = frequency.boundsPeriod.start;
          d.moment.forEach((m) => {
            frequency.timingCode = TIMING_CODES.find((t) => t.display === m.timingCode).value;
            const timingInstances = TimingData.getTimingInstances(frequency, from, to);
            m.drugs.forEach(() => {
              timingInstances.forEach((instance) => {
                const intakeDay = {
                  drugId: entitylink._id,
                  date: new Date(instance),
                };
                this.intakeDays.push(intakeDay);
              });
            });
          });
        }
      });
    });
  }

  public handleMonthSelected(event: moment.Moment): void {
    this.calendar.selected = new Date(event.toDate());
    this.computeIntakeDays();
  }

  private onMonthChanged() {
    this.calendar.selected = new Date(this.calendar.activeDate);
    this.computeIntakeDays();
  }
}
