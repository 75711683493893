<mat-drawer-container #drawer class="mat-elevation-z1" cdkDropListGroup *ngIf="careplanEditorService.careplanTemplateReady$ | async">
  <mat-drawer mode="side" opened position="end">
    <!-- SOURCE LIST (right column)-->
    <div class="drawer-title-container">
      <h2 class="drawer-title">{{ "page.careplanEditor.tabs.questionnaires.titleAvailable" | translate }}</h2>
      <button
        mat-stroked-button
        (click)="addQuestionnaire()"
        [matTooltip]="'page.careplanEditor.tabs.observations.addObservation' | translate"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <!-- @TODO: CMATE-5812 Available questionnaires backend table will go here -->
    <app-questionnaires-backend-table
      [draggable]="true"
      [dropListId]="'drop-list-1'"
      [dropListConnectedTo]="'drop-list-2'"
      [dropListSortingDisabled]="true"
    ></app-questionnaires-backend-table>
  </mat-drawer>
  <mat-drawer-content>
    <!-- TARGET LIST-->

    <!-- Questionnaires linked to careplan -->
    <h3>{{ "page.careplanEditor.tabs.questionnaires.titleLinked2Careplan" | translate }}</h3>
    <app-linkable-items-list
      *ngIf="link2CareplanQuestionnaires && columns"
      [id]="'drop-list-2'"
      [connectedTo]="'drop-list-1'"
      [careplanTemplate]="careplanTemplate"
      [items]="link2CareplanQuestionnaires"
      [linkedToCareplanItems]="link2CareplanQuestionnaires"
      [linkedToActivitiesItems]="activitiesQuestionnaires"
      [columns]="columns"
      [actions]="actions"
      (itemsChange)="update($event)"
      [isTarget]="true"
    >
    </app-linkable-items-list>
    <mat-spinner *ngIf="!link2CareplanQuestionnaires" style="margin: auto"></mat-spinner>

    <!-- Questionnaires linked to activities (for procedures only, cf. tooltip)-->
    <h3 class="mt-1">{{ "page.careplanEditor.tabs.questionnaires.titleLinked2Activities" | translate }}</h3>
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel
        *ngFor="let activityFormGroup of careplanEditorService.careplanTemplateForm?.get('activity').controls"
        [disabled]="
          !(
            activityFormGroup.value.detail.category === ACTIVITY_CATEGORY.PROCEDURE ||
            activityFormGroup.value.detail.category === ACTIVITY_CATEGORY.APPOINTMENT ||
            activityFormGroup.value.detail.category === ACTIVITY_CATEGORY.TELECONSULTATION
          )
        "
        [matTooltip]="'page.careplanEditor.tabs.questionnaires.notAllowed' | translate"
        [matTooltipDisabled]="
          activityFormGroup.value.detail.category === ACTIVITY_CATEGORY.PROCEDURE ||
          activityFormGroup.value.detail.category === ACTIVITY_CATEGORY.APPOINTMENT ||
          activityFormGroup.value.detail.category === ACTIVITY_CATEGORY.TELECONSULTATION
        "
      >
        <mat-expansion-panel-header>
          <mat-panel-title>{{ activityFormGroup | getActivityName : currentLanguage }} </mat-panel-title>
        </mat-expansion-panel-header>
        <app-linkable-items-list
          *ngIf="activitiesQuestionnaires && columns"
          [id]="'drop-list-2'"
          [connectedTo]="'drop-list-1'"
          [careplanTemplate]="careplanTemplate"
          [items]="activitiesQuestionnaires | filterByActivity : activityFormGroup.value.reference.reference"
          [columns]="columns"
          [actions]="actions"
          (itemsChange)="update($event)"
          [isTarget]="true"
          [activityReference]="activityFormGroup.value.reference"
          [linkedToCareplanItems]="link2CareplanQuestionnaires"
          [linkedToActivitiesItems]="activitiesQuestionnaires"
        >
        </app-linkable-items-list>
        <mat-spinner *ngIf="!activitiesQuestionnaires" style="margin: auto"></mat-spinner>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-drawer-content>
</mat-drawer-container>
