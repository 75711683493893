<mat-card class="mt-1">
  <mat-card-title>{{ "knowledgebase.knowledgeServices.title" | translate }}</mat-card-title>
  <mat-card-content class="mt-1">
    <mat-list *ngIf="(readonly && !isAdmin) || selectedServices.length === 0">
      <mat-list-item *ngFor="let service of selectedServices">
        {{ service.displayWithOrg }}
      </mat-list-item>
      <mat-list-item *ngIf="selectedServices.length === 0">
        {{ "knowledgebase.knowledgeServices.freeAccessKnowledge" | translate }}
      </mat-list-item>
    </mat-list>

    <form *ngIf="!readonly || isAdmin">
      <mat-form-field style="width: 100%">
        <mat-label>{{ "forms.services" | translate }}</mat-label>
        <mat-chip-list #chipList aria-label="Service selection" class="mat-chip-list-stacked">
          <mat-chip
            *ngFor="let service of selectedServices"
            [selectable]="true"
            [removable]="true"
            (removed)="removeService(service)"
            style="width: auto"
          >
            {{ service.displayWithOrg }}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>
        <input
          style="margin-top: 20px"
          [placeholder]="'forms.services' | translate"
          #serviceInput
          [formControl]="servicesFormCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="addService($event)"
        />
        <button mat-icon-button *ngIf="readonly && isAdmin" style="float: right">
          <mat-icon>warning</mat-icon>
        </button>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedService($event)">
          <mat-option *ngFor="let service of filteredServices | async" [value]="service">
            {{ service.displayWithOrg }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-card-actions>
    <button
      mat-button
      mat-flat-button
      color="primary"
      [matTooltip]="'btn.save' | translate"
      (click)="updateKnowledge()"
      [disabled]="updating"
      *ngIf="!readonly || isAdmin"
    >
      {{ "btn.save" | translate }}
    </button>
  </mat-card-actions>
</mat-card>
