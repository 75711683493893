import moment from "moment";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { IPrescriptionInfosResponse } from "src/app/models/prescription.interface";

export class PendingPrescriptionsDataSource extends MatTableDataSourceExtended<IPrescriptionInfosResponse> {
  sortingDataAccessor = (data: IPrescriptionInfosResponse, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "lastname":
        return data.lastname;
      case "firstname":
        return data.firstname;
      case "status":
        return data.status;
      case "category":
        return data.category;
      case "dateRequest":
        return moment(data.dateRequest, "YYYYMMDDHHmmss").toISOString();
      default:
        return "";
    }
  };

  constructor() {
    super();
  }

  public loadData(pendingPrescriptions: IPrescriptionInfosResponse[]): void {
    this.data = pendingPrescriptions.map((prescription) => {
      // Convert the dateRequest to a valid Date
      prescription.dateRequest = moment(prescription.dateRequest, "YYYYMMDDHHmmss").toISOString();
      return prescription;
    });
  }
}
