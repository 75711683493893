<div class="questParamsEditor" *ngIf="params">
  <form [formGroup]="paramsForm">
    <mat-slide-toggle formControlName="notify" style="margin-right: 20px">
      {{ "page.questionnaireEditor.properties.notify" | translate }}
    </mat-slide-toggle>
  </form>
  <app-timing-editor
    *ngIf="paramsForm?.value?.notify"
    [mode]="1"
    [isFrequencyDisplayed]="true"
    [(frequency)]="params.frequency"
    [isMomentRequired]="true"
    [largeDisplay]="false"
    [hasCycle]="false"
    [needStartEnd]="false"
    [needQuantities]="false"
    [isQuestionnaire]="true"
    [disabled]="disabled"
  >
  </app-timing-editor>
</div>
