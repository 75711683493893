<div [formGroup]="orgsAndServForm" class="useContextForm">
  <section class="flexItem">
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label for="services">
        {{ "page.questionnaireEditor.valueSet.servicesAvailability" | translate }}
      </mat-label>
      <mat-select id="services" formControlName="services" multiple>
        <mat-option *ngFor="let service of allServices" [value]="service.reference">
          {{ service.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br />
    <mat-form-field appearance="fill" class="flexItem" style="width: 100%">
      <mat-label for="organizations">
        {{ "page.questionnaireEditor.valueSet.organizationsAvailability" | translate }}
      </mat-label>
      <mat-select id="organizations" formControlName="organizations" multiple>
        <mat-option *ngFor="let org of allOrgs" [value]="org.reference">
          {{ org.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-error *ngIf="orgsAndServForm.hasError('requiredAtLeastOne')">
      <strong>
        {{ "page.questionnaireEditor.properties.required" | translate }}
      </strong>
    </mat-error>
  </section>
  <section formArrayName="accessLevelsFormArray" *ngIf="showLevels" class="aclSection">
    <mat-form-field
      *ngFor="let acl of accessLevelsFormArray.controls; let i = index"
      appearance="fill"
      class="flexItem"
      style="width: 100%"
    >
      <mat-label>{{ acl.value.display ?? acl.value.valueReference }}</mat-label>
      <mat-select [(ngModel)]="acl.value.accessLevel" [ngModelOptions]="{ standalone: true }" (selectionChange)="accessLevelUpdated()">
        <mat-option *ngFor="let access of accessLevels" [value]="access">{{ "useContext." + access | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </section>
</div>
