import { Account } from "./account.interface";
import { Identifier } from "./identifier.interface";

export interface Authentification {
  token: string;
  tokens?: string[]; // For the case where the user has several account with us and is trying to login with PSC
  ANS_idToken?: string; // needed for PSC auth in order to logout
  otherIds?: Identifier[]; // needed to link PSC auth to practitioner account
  is2fa: boolean;
  newPasswordRequired: boolean;
  account: Account;
  accounts?: Account[]; // For the case where the user has several account with us and is trying to login with PSC
}

export interface IAfterLoginData {
  url: string;
  newPasswordRequired: boolean;
  account: Account;
  accounts?: Account[]; // For the case where the user has several account with us and is trying to login with PSC
  tokens?: string[]; // For the case where the user has several account with us and is trying to login with PSC
  needClassicLogin?: boolean; // needed to link PSC auth to practitioner account
}

export enum MFA_TYPE {
  GOOGLE_AUTHENTICATOR = 0,
}

export interface IMfaType {
  i18nkey: string;
  mfaType: MFA_TYPE;
}
