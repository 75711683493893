import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnDestroy, Output } from "@angular/core";
import { Observable, Subject, fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { IScrollPosition, ScrollPosition } from "src/app/models/scroll.interface";

/**
 * Angular component representing a scroll container with scroll position tracking.
 *
 * @event scrollChanged - Emitted when the scroll position changes. The event payload
 * contains an object with information about the vertical and horizontal scroll positions (IScrollPosition).
 */
@Component({
  selector: "ui-scroll-container",
  templateUrl: "./ui-scroll-container.component.html",
  styleUrls: ["./ui-scroll-container.component.scss"],
})
export class UiScrollContainerComponent implements AfterViewInit, OnDestroy {
  public scrollPosition: IScrollPosition;
  public resizeObservable$: Observable<Event>;
  private onDestroy$ = new Subject<void>();

  /**
   * Event listener for the "scroll" event on the host element.
   * @param event - The scroll event.
   */
  @HostListener("scroll", ["$event"])
  onScroll(event: Event): void {
    this.calculateScrollPosition(event);
  }

  /** EventEmitter for notifying when the scroll position changes. */
  @Output()
  scrollChanged = new EventEmitter<IScrollPosition>();

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.calculateScrollPosition(this.elementRef);
    });

    // Create an observable for the window resize event
    this.resizeObservable$ = fromEvent(window, "resize");
    this.resizeObservable$.pipe(takeUntil(this.onDestroy$), debounceTime(150), distinctUntilChanged()).subscribe(() => {
      this.calculateScrollPosition(this.elementRef);
    });
  }

  /**
   * Calculates the scroll position based on the provided event or element reference.
   * @param eventOrElement - The scroll event or element reference.
   */
  calculateScrollPosition(event: Event | ElementRef): void {
    const target = ((event as Event).target as HTMLElement) || this.elementRef.nativeElement;

    // Get the scroll positions (vertical and horizontal)
    const scrollTop = target.scrollTop;
    const scrollLeft = target.scrollLeft;
    // Get the dimensions of the viewport
    const clientHeight = target.clientHeight;
    const clientWidth = target.clientWidth;
    // Get the dimensions of the content
    const scrollHeight = target.scrollHeight;
    const scrollWidth = target.scrollWidth;

    // Calculate the vertical scroll position
    let vertical: ScrollPosition;
    if (scrollTop === 0) {
      vertical = ScrollPosition.START;
    } else if (scrollTop + clientHeight >= scrollHeight) {
      vertical = ScrollPosition.END;
    } else {
      vertical = ScrollPosition.BETWEEN;
    }

    // Calculate the horizontal scroll position
    let horizontal: ScrollPosition;

    if (clientWidth >= scrollWidth) {
      horizontal = null;
    } else if (scrollLeft === 0) {
      horizontal = ScrollPosition.START;
    } else if (scrollLeft + clientWidth >= scrollWidth) {
      horizontal = ScrollPosition.END;
    } else {
      horizontal = ScrollPosition.BETWEEN;
    }

    this.scrollPosition = { vertical, horizontal };
    this.scrollChanged.emit(this.scrollPosition);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
