import { Pipe, PipeTransform } from "@angular/core";
import { RiskAssessment } from "../models/riskAssessment.model";

@Pipe({
  name: "riskGauge",
})
export class RiskGaugePipe implements PipeTransform {
  transform(risk: RiskAssessment): unknown {
    const gauge = risk.summary.map((s, i) => ({
      key: s.outcomeCode,
      value: s.percent,
      color: `rgb(${RiskAssessment.getRed(i, s.outcomeCode, risk.riskName, risk.summary.length)},${RiskAssessment.getGreen(
        i,
        s.outcomeCode,
        risk.riskName,
        risk.summary.length
      )},${RiskAssessment.getBlue(i, s.outcomeCode)})`,
    }));
    return gauge;
  }
}
