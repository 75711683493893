<div class="group-search">
  <div class="buttons-bar">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close matTooltip="{{ 'btn.close' | translate }}">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <app-group-server-side-search (groupSelected)="groupSelected($event)"></app-group-server-side-search>
  </mat-dialog-content>
  <mat-dialog-actions align="start">
    <p class="fail" *ngIf="!isValid && hasSelect">
      {{ "group.notValid" | translate }}
    </p>
    <button mat-raised-button color="primary" [disabled]="!isValid" type="submit" (click)="onSave()">
      {{ "btn.add" | translate }}
    </button>
  </mat-dialog-actions>
</div>
