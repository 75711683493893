<div class="mfaSetup">
  <mat-label class="fs-16">
    {{ "mfa.title" | translate }}
    :
    <i *ngIf="(account && account.mfaActive) || mfaSuccess"> {{ "mfa.active" | translate }} <mat-icon class="success">done</mat-icon> </i>
    <i *ngIf="account && !account.mfaActive && !mfaSuccess">
      {{ "mfa.inactive" | translate }}
    </i>
    <button mat-icon-button aria-label="icon button with a ? icon" (click)="openMfaAuthHelp()">
      <mat-icon>help_outline</mat-icon>
    </button>
  </mat-label>
  <div>
    <mat-form-field class="input" *ngIf="account && !account.mfaActive && !mfaQRCodeUrl">
      <mat-select [(ngModel)]="chosenMfaType" name="mfaType">
        <mat-option *ngFor="let item of mfaTypesList" [value]="item.mfaType">
          {{ "mfa." + item.i18nkey | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <p>
      <button mat-stroked-button *ngIf="account && !account.mfaActive && !mfaQRCodeUrl" color="primary" type="button" (click)="enable2FA()">
        {{ "mfa.enable2FA" | translate }}
      </button>
    </p>
    <p *ngIf="mfaSuccess" class="success">{{ "mfa.success" | translate }}</p>
    <button mat-stroked-button *ngIf="account && account.mfaActive" color="primary" type="button" (click)="disable2FA()">
      {{ "mfa.disable2FA" | translate }}
    </button>
    <p *ngIf="mfaQRCodeUrl && !mfaSuccess">
      {{ "mfa.QRCodeExplanation" | translate }}
    </p>
    <img *ngIf="mfaQRCodeUrl && !mfaSuccess" [src]="mfaQRCodeUrl" alt="QR Code" />
    <p *ngIf="mfaQRCodeUrl && !mfaSuccess">
      {{ "mfa.codeExplanation" | translate }}
    </p>
    <mat-form-field class="input" *ngIf="mfaQRCodeUrl && !mfaSuccess">
      <mat-label>{{ "mfa.enterCode" | translate }}</mat-label>
      <input matInput [(ngModel)]="mfaCode" />
    </mat-form-field>
    <button
      class="confirmBtn"
      mat-stroked-button
      *ngIf="mfaQRCodeUrl && mfaCode && !mfaSuccess"
      color="primary"
      type="button"
      (click)="confirm2FA()"
    >
      <mat-icon>done</mat-icon>
    </button>
    <p *ngIf="mfaError" class="error">{{ "mfa.enable_error" | translate }}</p>
    <p *ngIf="mfaErrorDisable" class="error">
      {{ "mfa.disable_error" | translate }}
    </p>
    <p *ngIf="mfaWrongCode" class="error">{{ "mfa.wrongmfa" | translate }}</p>
  </div>
</div>
