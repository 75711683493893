import { Pipe, PipeTransform } from "@angular/core";
import { IMonitoringOrganizationLink } from "../models/sharedInterfaces";
import { Reference } from "../models/sharedModels.model";
import { UserService } from "../providers/user.service";

@Pipe({ name: "orgNameFromServiceRefAndMonitLinks" })
export class OrgNameFromServiceRefAndMonitLinksPipe implements PipeTransform {
  transform(serviceRef: string, monitLinks: IMonitoringOrganizationLink[], selected?: Reference[]): string {
    if (!serviceRef) {
      return "";
    }
    if (!monitLinks || monitLinks.length === 0) {
      return "";
    }
    if (selected && selected.length === 1) {
      return "";
    }
    for (const l of monitLinks) {
      if (l.servicesRef.findIndex((s) => s.reference === serviceRef) >= 0) {
        return "(" + l.organizationRef.display + ")";
      }
    }
    return "";
  }
}

@Pipe({ name: "getOrgNameFromServiceRef" })
export class GetOrgNameFromServiceRefPipe implements PipeTransform {
  constructor(private userService: UserService) {}

  transform(serviceRef: string): string {
    if (!serviceRef) {
      return "";
    }
    const serviceFromOrg = this.userService.allServices.find((s) => s.serviceRef === serviceRef);
    if (serviceFromOrg?.providedBy) {
      return (serviceFromOrg.providedBy?.display ? serviceFromOrg.providedBy.display : serviceFromOrg.providedBy.reference) + " -";
    } else {
      return "";
    }
  }
}
