<div class="close-cross">
  <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="identityStatusChangeHistoric">
  <h3>{{ "identityStatusChangeHistoric.title" | translate }}</h3>
  <mat-card style="padding: 0px !important">
    <table mat-table matSort matSortDirection="desc" matSortActive="periodStart" [dataSource]="dataSource">
      <ng-container matColumnDef="identityChangeStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.status" | translate }}
          <app-item-filter
            fieldName="{{ 'table.status' | translate }}"
            propertyName="status"
            [dataType]="dataTypeChoice"
            [defaultValue]="getFilter('status')"
            [data]="dataSource.data"
            (applyFilter)="applyFilter($event)"
            [translateFieldKey]="'table.status'"
            translatePrefix="forms.identityStatus"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ "forms.identityStatus." + element.status | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="verification">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "forms.identityVerification.title" | translate }}
          <app-item-filter
            fieldName="{{ 'forms.identityVerification.title' | translate }}"
            propertyName="verification"
            [dataType]="dataTypeChoice"
            [defaultValue]="getFilter('verification')"
            [data]="dataSource.data"
            (applyFilter)="applyFilter($event)"
            [translateFieldKey]="'forms.identityVerification.title'"
            translatePrefix="forms.identityVerification"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            element.verification !== undefined && element.verification !== null
              ? ("forms.identityVerification." + element.verification | translate)
              : "-"
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="verificationMethod">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "forms.identityVerificationMethod.title" | translate }}
          <app-item-filter
            fieldName="{{ 'forms.identityVerificationMethod.title' | translate }}"
            propertyName="verification"
            [dataType]="dataTypeChoice"
            [defaultValue]="getFilter('method')"
            [data]="dataSource.data"
            (applyFilter)="applyFilter($event)"
            [translateFieldKey]="'forms.identityVerificationMethod.title'"
            translatePrefix="forms.identityVerificationMethod"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            element.method !== undefined && element.method !== null
              ? ("forms.identityVerificationMethod." + IDENTITY_VERIFICATION_METHOD[element.method] | translate)
              : "-"
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="modificationType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "identityStatusChangeHistoric.modificationTypeTitle" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ "forms." + (MODIFICATION_TYPE[element.modificationType] | lowercase) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="periodStart">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "schedule.dateFrom" | translate }}
          <app-item-filter
            fieldName="{{ 'schedule.dateFrom' | translate }}"
            propertyName="period.start"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('periodStart')"
            [data]="dataSource.data"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.period.start | date : "short" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="periodEnd">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "schedule.dateTo" | translate }}
          <app-item-filter
            fieldName="{{ 'schedule.dateTo' | translate }}"
            propertyName="period.end"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('periodEnd')"
            [data]="dataSource.data"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.period?.end | date : "short" }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator #paginator [pageIndex]="0" [pageSize]="5" [pageSizeOptions]="[5, 10, 15]" [hidePageSize]="false"> </mat-paginator>
  </mat-card>
</div>
