<div class="groupsListPage">
  <div class="toolbar">
    <div class="left">
      <button mat-icon-button (click)="openGroupsHelp()">
        <mat-icon style="font-size: 30px">help_outline</mat-icon>
      </button>
    </div>
    <div class="spacer"></div>
    <button
      mat-stroked-button
      [ngClass]="{ primary: !isAllServices, medium: isAllServices }"
      matTooltip="{{ 'group.createGroupTitle' | translate }}"
      *ngIf="('group' | isAuthorized : 'POST' | async) === true"
      (click)="createGroup()"
      aria-label="add group icon"
      [disabled]="isAllServices"
      [matTooltipDisabled]="isAllServices"
    >
      <mat-icon matTooltip="{{ 'btn.disabledWithAll' | translate }}" [matTooltipDisabled]="!isAllServices"> group_add </mat-icon>
    </button>
  </div>
  <mat-card class="allGroups">
    <div *ngFor="let group of groups; let i = index">
      <mat-card class="widget">
        <!-- Group name number of patients title -->
        <mat-toolbar>
          <div>
            <button
              mat-icon-button
              *ngIf="!isExpand(i)"
              matTooltip="{{ 'page.organizations.btn.expand' | translate }}"
              (click)="onExpand(i)"
            >
              <mat-icon>arrow_right</mat-icon>
            </button>
            <button
              mat-icon-button
              *ngIf="isExpand(i)"
              matTooltip="{{ 'page.organizations.btn.collapse' | translate }}"
              (click)="onExpand(i)"
            >
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
          </div>
          <div class="spacer-for-insurance"></div>
          <button mat-icon-button class="fs-16" (click)="onExpand(i)">
            <mat-icon *ngIf="!group.public">lock</mat-icon>
            <mat-icon *ngIf="group.public">lock_open</mat-icon>
            {{ group.groupName }}
          </button>
          <span class="spacerBetween"></span>
          <div>
            <button
              mat-icon-button
              matTooltip="{{ 'group.editGroup' | translate }}"
              *ngIf="('group' | isAuthorized : 'PUT' | async) === true && canUpdate(group)"
              (click)="editGroup(group)"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              matTooltip="{{ 'group.deleteGroupTitle' | translate }}"
              *ngIf="('group' | isAuthorized : 'DELETE' | async) === true && canUpdate(group)"
              (click)="deleteGroup(group)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <div class="spacer-for-insurance"></div>
        </mat-toolbar>
        <!-- List of all patients -->
        <mat-card-content *ngIf="isExpand(i)">
          <table mat-table matSort [dataSource]="group.patients">
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "page.organizations.table.name" | translate }}
              </th>
              <td mat-cell *matCellDef="let patient">{{ patient.display }}</td>
            </ng-container>
            <!-- Action Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>
                {{ "table.action" | translate }}
              </th>
              <td mat-cell *matCellDef="let patient">
                <button
                  mat-icon-button
                  color="primary"
                  matTooltip="{{ 'group.deletePFromGroupTitle' | translate }}"
                  *ngIf="('group' | isAuthorized : 'PUT' | async) === true"
                  (click)="deleteFromGroup(patient, group)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-card>
</div>
