<h3 mat-dialog-title>
  {{ "page.knowledgeDetails.modal.title" | translate }}
</h3>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="goToPublished()">
    {{ "page.knowledgeDetails.modal.seePublish" | translate }}
  </button>
  <button *ngIf="isMngtAuthorized" mat-stroked-button (click)="goToDraft()">
    {{ "page.knowledgeDetails.modal.editDraft" | translate }}
  </button>
  <button *ngIf="!isMngtAuthorized" mat-stroked-button (click)="goToDraft()">
    {{ "page.knowledgeDetails.modal.seeDraft" | translate }}
  </button>
</div>
