import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CommunicationTabHelper } from "src/app/careplan-editor/domain/careplan-editor-page/communication-tab-helper";
import { CareplanEditorService } from "src/app/careplan-editor/domain/careplan-editor.service";
import { ACTION_TARGET } from "src/app/models/careplans.interface";
import { ACTIVITY_CATEGORY } from "src/app/models/sharedInterfaces";
import { SessionService } from "src/app/providers/session.service";

@Component({
  selector: "app-careplan-editor-communications-tab",
  templateUrl: "./careplan-editor-communications-tab.component.html",
  styleUrls: ["./careplan-editor-communications-tab.component.scss"],
})
export class CareplanEditorCommunicationsTabComponent implements OnInit, OnDestroy {
  public ACTION_TARGET = ACTION_TARGET;
  public currentLanguage = this.sessionService.userLang;
  public ACTIVITY_CATEGORY = ACTIVITY_CATEGORY;
  private onDestroy$ = new Subject<void>();

  constructor(
    public careplanEditorService: CareplanEditorService,
    private sessionService: SessionService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    // Refresh translation when needed
    this.sessionService.refreshServerTraductions.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentLanguage = this.sessionService.userLang;
    });
  }

  ngOnDestroy(): void {
    // After publish careplanTemplateReady$ value is false to avoid trying to save a careplan without draft
    if (this.careplanEditorService.careplanTemplateReady$.value === true) {
      this.careplanEditorService.save(false, false, false);
    }
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public addCommunication(activityFormGroup: UntypedFormGroup): void {
    (activityFormGroup.get("actionResulting") as UntypedFormArray).insert(
      (activityFormGroup.get("actionResulting") as UntypedFormArray).length,
      this.fb.control(CommunicationTabHelper.newCommunication(this.careplanEditorService.currentCareplanTemplate.support[0].reference))
    );
  }

  public deleteCommunication(activityFormGroup: UntypedFormGroup, index: number): void {
    (activityFormGroup.controls.actionResulting as UntypedFormArray).removeAt(index);
  }

  /**
   * Get action resulting form controls for communication.
   * NB: it is acceptable to have a method called in the template in this particular case because a pipe would
   * need to be impure to work with form anyway. This is Angular common practive to use form getter in template
   */
  public getCommunicationFormControl(activityGoup: UntypedFormGroup): AbstractControl[] {
    return (activityGoup.get("actionResulting") as UntypedFormArray).controls.filter(
      (el) => el.value.detail?.target === ACTION_TARGET.COMMUNICATION
    );
  }
}
