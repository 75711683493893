<div class="fhir-questionnaire-list-page">
  <div class="topMenu">
    <div class="saveMenu">
      <ng-container>
        <input
          type="file"
          style="display: none"
          accept=".json,application/json"
          #fileUpload
          (change)="onImportQuestionnaireFile($event.target.files)"
        />
        <button
          mat-stroked-button
          *ngIf="
            ('dashboard/questionnaire' | isAuthorized : 'POST' | async) === true && ('/questionnaireEditor' | isAuthorized | async) === true
          "
          (click)="fileUpload.click()"
          class="importButton"
          matTooltip="{{ 'page.fhirQuestionnaireList.importQuestionnaire' | translate }}"
        >
          <i class="fas fa-upload fa-md"></i>
        </button>
      </ng-container>
      <button
        mat-stroked-button
        class="saveButton"
        (click)="createQuestionnaire()"
        *ngIf="
          ('dashboard/questionnaire' | isAuthorized : 'POST' | async) === true && ('/questionnaireEditor' | isAuthorized | async) === true
        "
        matTooltip="{{ 'page.fhirQuestionnaireList.add' | translate }}"
      >
        <mat-icon aria-hidden="false" aria-label="Add icon">add</mat-icon>
      </button>
    </div>
  </div>
  <mat-card class="mat-elevation-z0 pt-0">
    <div class="aboveTableElems">
      <button
        mat-flat-button
        color="warn"
        *ngIf="isFiltered"
        (click)="clearFilter()"
        style="max-height: 3em"
        matTooltip="{{ 'itemFilter.clearAll' | translate }}"
      >
        <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
      </button>
      <button mat-stroked-button *ngIf="!isFiltered" (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
        <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
      </button>
      <app-filters-display [filters]="filters" (removeFilter)="correctAndApplyFilter($event)"> </app-filters-display>
    </div>

    <table
      mat-table
      [dataSource]="dataSource"
      class="full-width-table mat-elevation-z1"
      matSort
      matSortActive="subjectType"
      matSortDirection="asc"
    >
      <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

      <ng-container matColumnDef="questionnaireStatus">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.status" | translate }}
          <app-item-filter
            fieldName="{{ 'table.status' | translate }}"
            propertyName="questionnaireStatus"
            translatePrefix="status"
            [data]="true"
            [dataType]="dataType.CHOICE"
            [defaultValue]="dataSource.getFilter('status')"
            (applyFilter)="correctAndApplyFilter($event)"
            [translateFieldKey]="'table.status'"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">{{ "status." + element.status | translate }}</td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="title">
          {{ "model.patient.questionnaireName" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.questionnaireName' | translate }}"
            propertyName="subjectType"
            [dataType]="dataType.TEXT"
            [defaultValue]="dataSource.getFilter('title')"
            (applyFilter)="correctAndApplyFilter($event)"
            [translateFieldKey]="'model.patient.questionnaireName'"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.subjectType }}</td>
      </ng-container>
      <ng-container matColumnDef="subtitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="subtitle">
          {{ "page.questionnaireEditor.properties.patientTitle" | translate }}
          <app-item-filter
            fieldName="{{ 'page.questionnaireEditor.properties.patientTitle' | translate }}"
            propertyName="group.title"
            [dataType]="dataType.TEXT"
            [defaultValue]="dataSource.getFilter('subtitle')"
            (applyFilter)="correctAndApplyFilter($event)"
            [translateFieldKey]="'page.questionnaireEditor.properties.patientTitle'"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.group.title }}</td>
      </ng-container>
      <ng-container matColumnDef="latestAuthor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="publisher">
          {{ "page.fhirQuestionnaireList.latestAuthor" | translate }}
          <app-item-filter
            fieldName="{{ 'page.fhirQuestionnaireList.latestAuthor' | translate }}"
            propertyName="publisher"
            [dataType]="dataType.TEXT"
            [defaultValue]="dataSource.getFilter('latestAuthor')"
            (applyFilter)="correctAndApplyFilter($event)"
            [translateFieldKey]="'page.fhirQuestionnaireList.latestAuthor'"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.publisher }}</td>
      </ng-container>
      <ng-container matColumnDef="latestUpdate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="date">
          {{ "page.fhirQuestionnaireList.latestUpdate" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.date | date : "dd/MM/yyyy HH:mm" }}
          <button mat-icon-button aria-label="icon button with a loupe icon" (click)="showQuestionnaireVersion(element)">
            <mat-icon class="custom-icon-small">search</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="nbPages">
        <th mat-header-cell *matHeaderCellDef>
          {{ "page.fhirQuestionnaireList.pages" | translate | titlecase }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.group.nbPages }}</td>
      </ng-container>
      <ng-container matColumnDef="nbQuestions">
        <th mat-header-cell *matHeaderCellDef>
          {{ "page.fhirQuestionnaireList.questions" | translate | titlecase }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.group.nbQuestions }}</td>
      </ng-container>
      <ng-container matColumnDef="practitionerOnly">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="onlyPractitioner">
          {{ "page.questionnaireEditor.properties.practitionersOnly" | translate }}
          <app-item-filter
            fieldName="{{ 'page.questionnaireEditor.properties.practitionersOnly' | translate }}"
            propertyName="onlyPractitioner"
            translatePrefix="page.fhirQuestionnaireList"
            [data]="true"
            [dataType]="dataType.CHOICE"
            [defaultValue]="dataSource.getFilter('practitionerOnly')"
            (applyFilter)="correctAndApplyFilter($event)"
            [translateFieldKey]="'page.questionnaireEditor.properties.practitionersOnly'"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">{{ "page.fhirQuestionnaireList." + element.onlyPractitioner | translate }}</td>
      </ng-container>
      <ng-container matColumnDef="questionnaireDraft">
        <th mat-header-cell *matHeaderCellDef>
          {{ "status.draft" | translate | titlecase }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ "page.fhirQuestionnaireList." + element.hasDraft | translate }}
          <button
            *ngIf="element.hasDraft"
            mat-icon-button
            aria-label="icon button with a loupe icon"
            (click)="showQuestionnaireVersion(element, true)"
          >
            <mat-icon class="custom-icon-small">search</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ "table.action" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div
            class="editButton"
            [matTooltip]="
              element.useContext[0].accessLevel < AccessLevel.WRITE
                ? ('common.notAuthorized' | translate)
                : ('page.questionnaireEditor.edit' | translate)
            "
          >
            <button
              mat-stroked-button
              [disabled]="element.useContext[0].accessLevel < AccessLevel.WRITE"
              *ngIf="
                ('dashboard/questionnaire' | isAuthorized | async) === true &&
                ('dashboard/questionnaire' | isAuthorized : 'PUT' | async) === true &&
                ('/questionnaireEditor' | isAuthorized | async) === true
              "
              (click)="editQuestionnaire(element)"
            >
              <mat-icon aria-hidden="false" aria-label="Edit icon"> edit </mat-icon>
            </button>
          </div>
          <button
            mat-stroked-button
            class="editButton"
            (click)="copyQuestionnaire(element)"
            *ngIf="
              ('dashboard/questionnaire' | isAuthorized | async) === true &&
              ('dashboard/questionnaire' | isAuthorized : 'POST' | async) === true &&
              ('/questionnaireEditor' | isAuthorized | async) === true
            "
            matTooltip="{{ 'page.questionnaireEditor.copy' | translate }}"
          >
            <mat-icon aria-hidden="false" aria-label="Duplicate icon"> file_copy </mat-icon>
          </button>
          <button mat-stroked-button class="editButton" (click)="download(element)" matTooltip="{{ 'btn.download' | translate }}">
            <mat-icon aria-hidden="false" aria-label="Download icon"> file_download </mat-icon>
          </button>
          <div
            class="editButton"
            [matTooltip]="
              element.useContext[0].accessLevel < AccessLevel.WRITE ? ('common.notAuthorized' | translate) : ('common.delete' | translate)
            "
          >
            <button
              mat-stroked-button
              [disabled]="element.useContext[0].accessLevel < AccessLevel.WRITE"
              *ngIf="
                ('dashboard/questionnaire' | isAuthorized | async) === true &&
                ('dashboard/questionnaire' | isAuthorized : 'DELETE' | async) === true
              "
              (click)="deleteQuestionnaire(element)"
            >
              <mat-icon aria-hidden="false" aria-label="Delete icon"> delete </mat-icon>
            </button>
          </div>
          <button
            mat-icon-button
            class="editButton"
            matTooltip="{{ 'btn.history' | translate }}"
            *ngIf="('dashboard/questionnaireHistory' | isAuthorized | async) === true"
            (click)="showHistory(element.identifier[0].value, element.version)"
            aria-label="icon button with a historic icon"
          >
            <mat-icon>history</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      #paginator
      pageIndex="0"
      [length]="questionnairesCount"
      [pageSize]="currentPageSize"
      [pageSizeOptions]="[10, 25, 50, 100]"
    ></mat-paginator>
    <mat-spinner class="centerSpinner" *ngIf="!pageLoaded"></mat-spinner>
    <div class="noQuestionnaire" *ngIf="pageLoaded && questionnairesCount < 1">
      {{ "page.fhirQuestionnaireList.empty" | translate }}
    </div>
  </mat-card>
</div>
