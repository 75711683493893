<mat-card class="comunicare-widget" cdkDrag [cdkDragDisabled]="!isDraggable">
  <mat-card-header class="widget-header">
    <mat-card-title class="custom-widget-title">
      {{ "widget.lastPatient" | translate }}
      <a mat-icon-button aria-label="icon button with a ? icon" (click)="openLastPatientsHelp()">
        <mat-icon>help_outline</mat-icon>
      </a>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content class="widget-content">
    <table
      mat-table
      matSort
      matSortActive="date"
      matSortDirection="desc"
      matSortDisableClear
      [dataSource]="dataSource"
      [ngClass]="{ hide: dataSource.data.length === 0 }"
    >
      <!-- Patient Column -->
      <ng-container matColumnDef="patient">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.patient" | translate }}
        </th>
        <td class="noPadding custom-widget-font" *matCellDef="let element">
          <button
            mat-button
            class="normalWeight custom-widget-font"
            [routerLink]="['/patient', { id: encodeToB64(getCaremateIdentifier(element.patient)) }]"
            [state]="{
              caremateIdentifier: getCaremateIdentifier(element.patient),
              widget: widgetName.LAST_PATIENT
            }"
            matTooltip="{{ element.patient | getPatientFullName }}"
            matTooltipClass="truncatedNamesTooltip"
          >
            <mat-icon>face</mat-icon>
            {{ element.patient | getPatientFullName | truncate : 30 }}
          </button>
        </td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.date" | translate }}
          <app-item-filter
            fieldName="{{ 'table.date' | translate }}"
            propertyName="activityDate"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('activityDate')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td class="custom-widget-font" *matCellDef="let element">
          {{ element.activityDate | date : "short" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      #paginator
      [pageIndex]="0"
      [pageSize]="5"
      [hidePageSize]="true"
      [ngClass]="{ hide: dataSource.data.length === 0 }"
    ></mat-paginator>
    <p class="text-center custom-widget-font" *ngIf="dataSource.data.length === 0">
      {{ "widget.noData" | translate }}
    </p>
  </mat-card-content>
</mat-card>
