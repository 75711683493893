import * as moment from "moment";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { IAction } from "src/app/models/rewardScore.interface";
import { RewardScore } from "src/app/models/rewardScore.model";

export class RewardHistoricDataSource extends MatTableDataSourceExtended<IAction> {
  public reward: RewardScore;

  sortingDataAccessor = (data: IAction, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "date":
        return moment(data.actionDate).unix();
      case "action":
        return data.action.toString();
      case "comment":
        return this.reward?.notes?.[data.commentId]?.text || "";
      default:
        return "";
    }
  };

  constructor(reward: RewardScore) {
    super();
    this.reward = reward;
  }

  public loadData(reward: RewardScore): void {
    this.data = reward?.actionsNotesHistory;
  }
}
