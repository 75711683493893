import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SCORING_TYPE } from "../models/questionnaireScoring.interface";
import { QuestionnaireScoring } from "../models/questionnaireScoring.model";
import { ITrValues } from "./QuestionnairePDFHelper";
import { p_datas, scores, scoresTable, template, trScores } from "./static-values/qTemplateC";

@Injectable({
  providedIn: "root",
})
export class QuestionnaireLinearPDFHelper {
  public p_datas: string = p_datas;
  public template: string = template;
  public trScoring: string = trScores;
  public scores: string = scores;

  constructor(private translateService: TranslateService) {}

  private get translatedTemplate() {
    return this.template.replace("%DATE_TITlE%", this.translateService.instant("table.date"));
  }

  private getCompletedPDatas(values: ITrValues) {
    return this.p_datas
      .replace("%Topic_VAL%", `${values.topic || ""}`)
      .replace("%Question_VAL%", `${values.question}`)
      .replace("%Answer_VAL%", `${values.answer || "N.A"}`);
  }

  private getCompletedTrScoring(score: QuestionnaireScoring) {
    return this.trScoring
      .replace(
        "%SCORE_NAME_VAL%",
        score.identifier?.label ? `${score.identifier.label}` : this.translateService.instant("model.patient.questionnaireTotalScore")
      )
      .replace("%SCORE_VAL%", `${score.scoringValue || score.scoringValue === 0 ? score.scoringValue : "-"}`)
      .replace(
        "%SCORE_INTERPRETATION%",
        `${
          score.interpretationValue?.result && score.interpretationValue.result[this.translateService.currentLang]
            ? score.interpretationValue.result[this.translateService.currentLang]
            : "-"
        }`
      );
  }

  private getCompletedPDataArray(valuesArr: ITrValues[]) {
    return valuesArr.map((v) => this.getCompletedPDatas(v));
  }

  private getCompletedTrScoringArray(scoresDetails: QuestionnaireScoring[]) {
    return scoresDetails.map((s) => this.getCompletedTrScoring(s));
  }

  private addScoresToTemplate(allScores: QuestionnaireScoring[][]) {
    const tempTemplate: string[] = [];
    allScores.forEach((s) => {
      const allTrScores = this.getCompletedTrScoringArray(s).reduce((acc, el) => acc + el, "");
      tempTemplate.push(
        scoresTable
          .replace("%CAT_NAME%", s[0]?.identifier?.value ? s[0].identifier.value : "")
          .replace("%SCORE_NAME_TITLE%", this.translateService.instant("table.name"))
          .replace("%SCORE_VALUE_TITLE%", this.translateService.instant("table.score"))
          .replace("%SCORE_INTERPRETATION_TITLE%", this.translateService.instant("pdf.questionnaire.scoreInterpretation"))
          .replace("%TR_SCORES%", allTrScores)
      );
    });
    return tempTemplate;
  }

  public getConstructedTemplate(valuesArr: ITrValues[], date: string, scoresToAdd?: QuestionnaireScoring[][]): string {
    const flatPData = this.getCompletedPDataArray(valuesArr).reduce((acc, el) => acc + el, "");
    let templateWithValueAndDate = this.translatedTemplate.replace("%P_DATAS%", flatPData).replace("%DATE_VALUE%", date);

    if (scoresToAdd?.[0]?.[0].scoringType === SCORING_TYPE.QUIZ_CORRECT_ANSWERS) {
      templateWithValueAndDate = templateWithValueAndDate.replace("%VAL%", "");
    } else {
      templateWithValueAndDate = templateWithValueAndDate.replace("%VAL%", this.translateService.instant("pdf.questionnaire.value"));
    }

    if (!scoresToAdd.length) {
      return templateWithValueAndDate.replace("%SCORES%", "");
    } else {
      templateWithValueAndDate = templateWithValueAndDate.replace("%SCORES%", scores);
      const flatTrScores = this.addScoresToTemplate(scoresToAdd).reduce((acc, el) => acc + el, "");
      return templateWithValueAndDate.replace("%SCORES_TABLE%", flatTrScores);
    }
  }
}
