import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { DndFilesModule } from "src/app/directives/dnd-files/dnd-files.module";
import { I18nModule } from "src/app/i18n.module";
import { UiFileDropComponent } from "./ui-file-drop.component";

@NgModule({
  declarations: [UiFileDropComponent],
  imports: [CommonModule, MatIconModule, I18nModule, DndFilesModule, MatButtonModule],
  exports: [UiFileDropComponent],
})
export class UiFileDropModule {}
