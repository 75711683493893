import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { AddDrugSchemaComponent } from "src/app/components/forms/add-drug-schema/add-drug-schema.component";
import { PauseDrugSchemaComponent } from "src/app/components/forms/add-drug-schema/pause-drug-schema/pause-drug-schema.component";
import { AddStepwiseDrugComponent } from "src/app/components/forms/add-stepwise-drug/add-stepwise-drug.component";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { Tools } from "src/app/helpers/tools";
import { IDrugInfo } from "src/app/models/drugInfo.interface";
import { CycleSchema } from "src/app/models/entity.interface";
import { IStepwiseDrug } from "src/app/models/stepwiseDrug.interface";
import { DrugSchemaService } from "src/app/providers/drugSchema.service";
import { SessionService } from "src/app/providers/session.service";
import { StepwiseDrugService } from "src/app/providers/stepwiseDrug.service";

@Component({
  selector: "app-drug-cycle",
  templateUrl: "./drug-cycle.component.html",
  styleUrls: ["./drug-cycle.component.scss"],
})
export class DrugCycleComponent implements OnInit, OnDestroy {
  @Input() mode: FORMS_MODE;
  @Input() quantitiesMustBeNumber: boolean;
  @Input()
  get drugCycle(): CycleSchema {
    return this.cycle;
  }
  set drugCycle(cycle: CycleSchema) {
    this.cycle = cycle;
    if (this.mode === FORMS_MODE.UPDATE) {
      this.isCreation = false;
      this.initCycle();
    }
  }
  @Input()
  get stepwiseDrug(): IStepwiseDrug {
    return this.stepwise;
  }
  set stepwiseDrug(stepwise: IStepwiseDrug) {
    this.stepwise = stepwise;
    if (this.mode === FORMS_MODE.UPDATE) {
      this.isCreation = false;
      this.initStepwise();
    }
  }
  @Output() drugCycleChange: EventEmitter<CycleSchema> = new EventEmitter<CycleSchema>();
  @Output() stepwiseDrugChange: EventEmitter<IStepwiseDrug> = new EventEmitter<IStepwiseDrug>();

  public cycle: CycleSchema;
  public stepwise: IStepwiseDrug;
  public schemaForm = this.fb.group({
    cycle: ["none"],
    stepwise: ["none"],
    isCycleSelected: [null],
  });
  public cycles: CycleSchema[] = [];
  public stepwises: IStepwiseDrug[] = [];
  public initialDBCycles: CycleSchema[] = [];
  public initialDBStepwises: IStepwiseDrug[] = [];
  public displayedColumns: string[] = [];
  public rowOfDisplayedColumns: string[][] = [];
  public eachRowsNumberOfDays = this.sessionService.drugSchemaEachRowsNumberOfDays;
  private atcCode: string;
  public isPaused = false;
  public pauseDate: Date;
  public isCreation: boolean;
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private drugSchemaService: DrugSchemaService,
    private dialog: MatDialog,
    private sessionService: SessionService,
    private stepwiseDrugService: StepwiseDrugService
  ) {}

  ngOnInit(): void {
    if (this.mode === FORMS_MODE.CREATE) {
      this.isCreation = true;
      this.refreshDrugSchemaList();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initCycle() {
    if (this.drugCycle?.cycle?.length === 0) {
      this.drugCycle = undefined;
    }
    if (this.drugCycle) {
      const cycle = this.drugCycle;
      this.cycle = cycle;
      this.schemaForm.get("isCycleSelected").setValue(true);
      this.schemaForm.get("cycle").setValue(cycle.name);
      this.cycles.push(cycle);
      this.generateDisplayedColumns(cycle.cycle.length);
      this.formatDisplayedColumns();
      if (this.isCycleInPause(cycle)) {
        const lastDate = new Date(cycle.pauseDate[cycle.pauseDate.length - 1]);
        this.pauseDate = lastDate;
        this.isPaused = true;
      } else {
        this.isPaused = false;
      }
    }
    this.schemaForm.get("cycle").setValue(this.drugCycle ? this.drugCycle.name : "none");
  }

  private initStepwise() {
    if (this.stepwiseDrug?.stepwises?.length === 0) {
      this.stepwiseDrug = undefined;
    }
    if (this.stepwiseDrug) {
      const stepwise = this.stepwiseDrug;
      this.stepwise = stepwise;
      this.schemaForm.get("isCycleSelected").setValue(false);
      this.schemaForm.get("stepwise").setValue(stepwise.name);
      this.stepwises.push(stepwise);
      this.generateDisplayedColumns(this.getStepwiseLength(stepwise));
      this.formatDisplayedColumns();
    }
    this.schemaForm.get("stepwise").setValue(this.stepwiseDrug ? this.stepwiseDrug.name : "none");
  }

  public save(): boolean {
    if (this.schemaForm.get("cycle").value !== "none") {
      if (this.cycle !== this.drugCycle) {
        this.drugCycle = this.cycle;
        this.drugCycle.startDate = new Date();
      }
      if (this.isPaused) {
        if (this.drugCycle.pauseDate && this.drugCycle.pauseDate.length > 0) {
          this.drugCycle.pauseDate.push(new Date(this.pauseDate));
        } else {
          this.drugCycle.pauseDate = [new Date(this.pauseDate)];
        }
      }
      this.cycle = this.drugCycle;
      this.cycle.isLinked = undefined;
    } else {
      delete this.drugCycle;
    }
    if (this.schemaForm.get("stepwise").value === "none") {
      delete this.stepwiseDrug;
    }
    this.stepwiseDrugChange.emit(this.stepwise);
    this.drugCycleChange.emit(this.cycle);
    return true;
  }

  public drugChanged(newDrug: IDrugInfo): void {
    if (newDrug && newDrug.atc?.code !== undefined) {
      this.atcCode = newDrug.atc.code;
      this.refreshDrugSchemaList(newDrug.atc.code);
    } else {
      this.refreshDrugSchemaList();
    }
    if (this.cycle?.isLinked) {
      this.schemaForm.get("cycle").setValue("none");
      this.cycle = undefined;
    }
  }

  private refreshDrugSchemaList(atcCode?: string) {
    switch (this.schemaForm.get("isCycleSelected")?.value) {
      case true: {
        let $foundCycle: Observable<CycleSchema[]>;
        if (atcCode !== undefined) {
          $foundCycle = this.drugSchemaService.list(atcCode).pipe(
            map((cycles) => {
              return cycles.map((c) => {
                return {
                  name: c.name,
                  cycle: c.cycle,
                  startDate: null,
                  isLinked: c.drugs?.length > 0,
                } as CycleSchema;
              });
            }),
            takeUntil(this.onDestroy$)
          );
        } else {
          $foundCycle = this.drugSchemaService.list().pipe(
            map((cycles) => {
              return cycles.map((c) => {
                return {
                  name: c.name,
                  cycle: c.cycle,
                  startDate: null,
                } as CycleSchema;
              });
            }),
            takeUntil(this.onDestroy$)
          );
        }

        $foundCycle.subscribe((foundCycle) => {
          if (this.cycle && !this.cycle.isLinked) {
            this.cycles.forEach((c) => {
              if (foundCycle.findIndex((fc) => fc.name === c.name) === -1) {
                if (!c.isLinked) {
                  foundCycle.push(c);
                }
              }
            });
          }
          this.initialDBCycles = foundCycle;
          this.cycles = Tools.clone(this.initialDBCycles);
        });
        break;
      }
      case false: {
        this.stepwiseDrugService.list().subscribe((foundStepwises: IStepwiseDrug[]) => {
          if (this.stepwise) {
            this.stepwises.forEach((s) => {
              if (foundStepwises.findIndex((fc) => fc.name === s.name) === -1) {
                foundStepwises.push(s);
              }
            });
          }
          this.initialDBStepwises = foundStepwises;
          this.stepwises = Tools.clone(this.initialDBStepwises);
        });
        break;
      }
      default:
        break;
    }
  }

  public findSchema(event: MatSelectChange): void {
    if (this.schemaForm.get("isCycleSelected").value) {
      const currentCycle = this.cycles.find((c) => c.name === event.value);
      this.cycle = currentCycle;
      if (event.value !== "none") {
        this.generateDisplayedColumns(this.cycle.cycle.length);
        this.formatDisplayedColumns();
      }
      this.drugCycleChange.emit(this.cycle);
    } else if (this.schemaForm.get("isCycleSelected").value === false) {
      const currentStepwise = this.stepwises.find((s) => s.name === event.value);
      this.stepwise = currentStepwise;
      if (event.value !== "none") {
        this.generateDisplayedColumns(this.getStepwiseLength());
        this.formatDisplayedColumns();
      }
      this.stepwiseDrugChange.emit(this.stepwise);
    }
  }

  private generateDisplayedColumns(length: number) {
    this.displayedColumns = [];
    for (let i = 0; i < length; i++) {
      this.displayedColumns.push(i.toString());
    }
  }

  private formatDisplayedColumns() {
    this.rowOfDisplayedColumns = [];
    let value = this.displayedColumns.length / this.eachRowsNumberOfDays;
    if (value % 1 > 0) {
      value++;
    }
    value = Math.floor(value);
    for (let i = 0; i < value; i++) {
      this.rowOfDisplayedColumns.push([]);
      this.displayedColumns.forEach((index) => {
        if (+index >= this.eachRowsNumberOfDays * i && +index < this.eachRowsNumberOfDays * (i + 1)) {
          this.rowOfDisplayedColumns[i].push(index);
        }
      });
    }
  }

  public addSchema(): void {
    if (this.schemaForm.get("isCycleSelected").value) {
      const dialogRef = this.dialog.open(AddDrugSchemaComponent, {
        data: {
          atcCode: this.atcCode,
          mode: FORMS_MODE.CREATE,
          cycles: this.initialDBCycles,
        },
        disableClose: true,
      });
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result) {
            this.initNewSchema(result);
          }
        });
    } else {
      const dialogRef = this.dialog.open(AddStepwiseDrugComponent, {
        disableClose: true,
        data: {
          mode: FORMS_MODE.CREATE,
          isMenu: false,
          stepwises: this.initialDBStepwises,
          quantitiesMustBeNumber: this.quantitiesMustBeNumber,
        },
      });
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result) {
            this.initNewSchema(result);
          }
        });
    }
  }

  public updateSchema(): void {
    if (this.schemaForm.get("isCycleSelected").value) {
      const dialogRef = this.dialog.open(AddDrugSchemaComponent, {
        data: {
          drugSchema: this.cycle,
          atcCode: this.atcCode,
          mode: FORMS_MODE.UPDATE,
          cycles: this.initialDBCycles,
        },
        disableClose: true,
      });
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result) {
            this.initNewSchema(result);
          }
        });
    } else {
      const dialogRef = this.dialog.open(AddStepwiseDrugComponent, {
        data: {
          mode: FORMS_MODE.UPDATE,
          stepwises: this.initialDBStepwises,
          stepwiseDrug: this.stepwise,
          quantitiesMustBeNumber: this.quantitiesMustBeNumber,
        },
      });
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result) {
            this.initNewSchema(result);
          }
        });
    }
  }

  private initNewSchema(element: CycleSchema | IStepwiseDrug) {
    if (this.schemaForm.get("isCycleSelected").value === true) {
      this.cycles = Tools.clone(this.initialDBCycles);
      const cycleSchema = element as CycleSchema;
      if (this.isNameAlreadyInTheList(cycleSchema.name)) {
        cycleSchema.name += " (bis)";
      }
      this.cycle = cycleSchema;
      this.schemaForm.get("cycle").setValue(cycleSchema.name);
      this.cycles.push(cycleSchema);
      this.generateDisplayedColumns(cycleSchema.cycle.length);
      this.formatDisplayedColumns();
      this.drugCycleChange.emit(this.cycle);
    } else if (this.schemaForm.get("isCycleSelected").value === false) {
      this.stepwises = Tools.clone(this.initialDBStepwises);
      const stepwiseDrug = element as IStepwiseDrug;
      if (this.isNameAlreadyInTheList(stepwiseDrug.name)) {
        stepwiseDrug.name += " (bis)";
      }
      this.stepwise = stepwiseDrug;
      this.schemaForm.get("stepwise").setValue(stepwiseDrug.name);
      this.stepwises.push(stepwiseDrug);
      this.generateDisplayedColumns(this.getStepwiseLength(stepwiseDrug));
      this.formatDisplayedColumns();
      this.stepwiseDrugChange.emit(this.stepwise);
    }
  }

  public OnPauseCycle(): void {
    const dialogRef = this.dialog.open(PauseDrugSchemaComponent, {
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          this.isPaused = true;
          this.pauseDate = new Date(result);
        }
      });
  }

  /*
  * We keep this feature as a comment in case we are asked to reactivate it
      public onResumeCycle() {
          this.dialog.open(ConfirmationDialogComponent, {
              data: {
                  message: this.translateService.instant('drugSchema.playConfirm'),
                  type: ConfirmationDialogType.CONFIRM
              },
              disableClose: true
          }).afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe((yes) => {
              if (yes) {
                  this.isPaused = false;
              }
          });
      }
  */

  private isNameAlreadyInTheList(name: string): boolean {
    let result = false;
    if (this.schemaForm.get("isCycleSelected").value) {
      this.cycles.forEach((cycle) => {
        if (cycle.name === name) {
          result = true;
        }
      });
    } else {
      this.stepwises.forEach((stepwise) => {
        if (stepwise.name === name) {
          result = true;
        }
      });
    }
    return result;
  }

  /*
   * Return a boolean that indicate if the cycle is actualy in pause or not
   */
  private isCycleInPause(cycle: CycleSchema): boolean {
    if (!cycle.pauseDate || cycle.pauseDate.length === 0) {
      return false;
    }
    // The last pause is the last element of the array of pauses
    const lastPause = new Date(cycle.pauseDate[cycle.pauseDate.length - 1]);
    const now = new Date();
    // the number of days of each cycle, we have it with the length of the cycle schema
    const nbrDaysOfCycle = cycle.cycle.length;
    // determine in wich cycle we are
    // we have it by dividing the number of days since the cycle started by the number of days of each cycle
    // we round up the result
    const actualCycleNbr = Math.ceil(this.differenceDate(now, new Date(cycle.startDate)) / nbrDaysOfCycle);
    if (actualCycleNbr === 0) {
      // if we are in the first cycle we just need to verify if there is a pause
      return cycle.pauseDate && cycle.pauseDate.length === 1;
    }
    // we determine how many days we need to add to the start date to be in the begining of the actual cycle
    const daysToAdd = (actualCycleNbr - 1) * nbrDaysOfCycle + 1;
    // we add this number of days to the start date, and we now have the date of the beginning of the actual cycle
    const startCycle = new Date(new Date(cycle.startDate).getTime() + daysToAdd * 1000 * 60 * 60 * 24);
    // we compare the date of the beginning of the actual cycle with the last pause
    // if the last pause is before the start cycle, the cycle was in pause but not in this cycle
    return lastPause >= startCycle;
  }

  private differenceDate(date1: Date, date2: Date) {
    const date1utc = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const date2utc = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
    const day = 1000 * 60 * 60 * 24;
    return (date1utc - date2utc) / day;
  }

  public resetForm(): void {
    this.schemaForm.get("cycle").setValue("none");
    this.schemaForm.get("stepwise").setValue("none");
    this.cycle = undefined;
    this.stepwise = undefined;
    this.cycles = [];
    this.stepwises = [];
    this.refreshDrugSchemaList(this.atcCode ? this.atcCode : undefined);
  }

  private getStepwiseLength(stepwise = this.stepwise): number {
    return stepwise?.stepwises[stepwise.stepwises.length - 1].startDay + stepwise.stepwises[stepwise.stepwises.length - 1].days.length;
  }
}
