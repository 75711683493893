import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "join",
})
export class JoinPipe implements PipeTransform {
  transform(text: string[], str: string): string {
    if (!text) {
      return "";
    }
    return text.join(str);
  }
}
