import * as moment from "moment";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { IDrugsHistoric } from "src/app/models/drugsHistoric.interface";
import { IEntitylink } from "src/app/models/entity.interface";

export class DrugsHistoricDataSource extends MatTableDataSourceExtended<IDrugsHistoric> {
  sortingDataAccessor = (data: IDrugsHistoric, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "date":
        return moment(data.date).unix();
      case "author":
        return data.author?.display;
      case "drugsName":
        return this.getDrugName(data.entityId) || data.entityName;
      default:
        return "";
    }
  };

  constructor(public allDrugs: IEntitylink[]) {
    super();
  }

  public loadData(data: IDrugsHistoric[]): void {
    this.data = data;
  }

  public getDrugName(id: string): string {
    return this.allDrugs.find((v) => v._id === id)?.entityData.name;
  }
}
