<div class="statEventsPage">
  <div class="toolbar">
    <button mat-flat-button color="warn" *ngIf="isFiltered" (click)="clearFilters()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
      <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
    </button>
    <button mat-stroked-button *ngIf="!isFiltered" (click)="clearFilters()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
      <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
    </button>
  </div>
  <app-filters-display [filters]="filters" (removeFilter)="applyFilters($event)"> </app-filters-display>
  <div class="statEvents">
    <table
      mat-table
      [dataSource]="eventsDataSource"
      class="full-width-table mat-elevation-z1"
      matSort
      matSortActive="eventDatetime"
      matSortDirection="desc"
    >
      <ng-container matColumnDef="eventDatetime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "page.statEvents.eventDatetime" | translate }}
          <app-item-filter
            fieldName="{{ 'page.statEvents.eventDatetime' | translate }}"
            propertyName="eventDatetime"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('eventDatetime')"
            [translateFieldKey]="'page.statEvents.eventDatetime'"
            (applyFilter)="applyFilters($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.eventDatetime | date : "YYYY-MM-dd HH:mm" }}</td>
      </ng-container>

      <ng-container matColumnDef="eventSource">
        <th mat-header-cell *matHeaderCellDef>
          {{ "page.statEvents.eventSource" | translate }}
          <app-item-filter
            fieldName="{{ 'page.statEvents.eventSource' | translate }}"
            propertyName="eventSource"
            [dataType]="dataTypeChoice"
            [data]="true"
            [defaultValue]="getFilter('eventSource')"
            translatePrefix="page.statEvents"
            [translateFieldKey]="'page.statEvents.eventSource'"
            (applyFilter)="applyFilters($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">{{ "page.statEvents." + element.eventSource | translate }}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "page.statEvents.description" | translate }}
          <app-item-filter
            fieldName="{{ 'page.statEvents.description' | translate }}"
            propertyName="description"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('description')"
            [translateFieldKey]="'page.statEvents.description'"
            (applyFilter)="applyFilters($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>

      <ng-container matColumnDef="servicesIds">
        <th mat-header-cell *matHeaderCellDef>
          {{ "page.statEvents.services" | translate }}
          <app-item-filter
            fieldName="{{ 'page.statEvents.services' | translate }}"
            propertyName="servicesIds"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('servicesIds')"
            [translateFieldKey]="'page.statEvents.services'"
            (applyFilter)="applyFilters($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngFor="let serviceId of element.servicesIds; let i = index">
            <span *ngIf="i < 5">
              {{ serviceId }}<span *ngIf="i < element.servicesIds.length - 1"><br /></span>
            </span>
          </span>
          <span *ngIf="element.servicesIds?.length >= 5"> ... ({{ element.servicesIds?.length - 4 }}) </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="careplans">
        <th mat-header-cell *matHeaderCellDef>
          {{ "page.statEvents.careplans" | translate }}
          <app-item-filter
            fieldName="{{ 'page.statEvents.careplans' | translate }}"
            propertyName="careplans"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('careplans')"
            [translateFieldKey]="'page.statEvents.careplans'"
            (applyFilter)="applyFilters($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngFor="let careplan of element.careplans; let i = index">
            {{ careplan.description }}<span *ngIf="i < element.careplans.length - 1"><br /> </span>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "page.statEvents.userName" | translate }}
          <app-item-filter
            fieldName="{{ 'page.statEvents.userName' | translate }}"
            propertyName="userName"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('userName')"
            [translateFieldKey]="'page.statEvents.userName'"
            (applyFilter)="applyFilters($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.userName }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      #paginator
      pageIndex="0"
      [length]="eventsCount"
      [pageSize]="currentPageSize"
      [pageSizeOptions]="[25, 50, 100, 250]"
    ></mat-paginator>
  </div>
</div>
