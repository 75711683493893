<section class="properties-group">
  <mat-form-field class="placeholderText">
    <mat-label>
      {{ "page.questionnaireEditor.properties.placeholder" | translate }}
    </mat-label>
    <input matInput [(ngModel)]="placeholder" [disabled]="disabled" (change)="onValuesChange()" />
  </mat-form-field>
  <div class="sideBySideFields" *ngIf="isNumber">
    <mat-form-field>
      <mat-label>
        {{ "page.questionnaireEditor.properties.min" | translate }}
      </mat-label>
      <input matInput type="number" [(ngModel)]="min" [max]="max" step="1" [disabled]="disabled" (change)="onValuesChange()" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>
        {{ "page.questionnaireEditor.properties.max" | translate }}
      </mat-label>
      <input matInput type="number" [(ngModel)]="max" [min]="min" step="1" [disabled]="disabled" (change)="onValuesChange()" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>
        {{ "page.questionnaireEditor.properties.initial" | translate }}
      </mat-label>
      <input matInput type="number" [(ngModel)]="initial" [disabled]="disabled" (change)="onValuesChange()" />
    </mat-form-field>
  </div>
  <div class="sideBySideFields" *ngIf="isDecimal">
    <mat-form-field>
      <mat-label>
        {{ "page.questionnaireEditor.properties.min" | translate }}
      </mat-label>
      <input matInput type="number" [(ngModel)]="min" [max]="max" step="0.01" [disabled]="disabled" (change)="onValuesChange()" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>
        {{ "page.questionnaireEditor.properties.max" | translate }}
      </mat-label>
      <input matInput type="number" [(ngModel)]="max" [min]="min" step="0.01" [disabled]="disabled" (change)="onValuesChange()" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>
        {{ "page.questionnaireEditor.properties.initial" | translate }}
      </mat-label>
      <input matInput type="number" [(ngModel)]="initial" [disabled]="disabled" (change)="onValuesChange()" />
    </mat-form-field>
  </div>
</section>
<!-- ========================= UNITS ==================================== -->
<app-units-value-set
  [lang]="lang"
  [valueSetRef]="numberQuestion?.options.reference"
  (valueSetRefChange)="onOptionsChanges($event)"
  [contained]="questionsOptions"
  (containedChange)="onUnitsChanges($event)"
  [disabled]="disabled"
  [(multiInputs)]="numberQuestion.answerDisplay.multiInputs"
></app-units-value-set>
