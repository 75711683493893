import { ErrorHandler, Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { catchError, first, mergeMap } from "rxjs/operators";
import { HealthcareserviceService } from "../healthcareservice.service";
import { PatientService } from "../patient.service";
import { PreferenceService } from "../preference.service";
import { SessionService } from "../session.service";
import { UserService } from "../user.service";

@Injectable({
  providedIn: "root",
})
export class PatientsCountResolver implements Resolve<{ count: number }> {
  constructor(
    private patientService: PatientService,
    private sessionService: SessionService,
    private preferenceService: PreferenceService,
    private userService: UserService,
    private healthcareService: HealthcareserviceService,
    private globalErrorHandler: ErrorHandler
  ) {}

  resolve(): Observable<{ count: number }> {
    return this.preferenceService.list("patientList").pipe(
      first(),
      mergeMap((pref) => {
        let currentServices = [];
        if (!this.sessionService.currentService) {
          currentServices = [];
        } else if (this.sessionService.currentService.reference === this.sessionService.allsOption) {
          currentServices = this.healthcareService.availableServices().map((s) => s.asReference.reference);
        } else {
          currentServices = [this.sessionService.currentService?.reference];
        }
        if (!currentServices || currentServices.length < 1) {
          return of({ count: 0 });
        }
        return this.patientService
          .getPatientsCount(
            currentServices,
            null,
            pref?.filters,
            true,
            pref?.group?._id,
            this.sessionService.globalPref?.inactifPatientDuration
          )
          .pipe(first());
      }),
      catchError((err) => {
        return throwError(this.globalErrorHandler.handleError(err));
      })
    );
  }
}
