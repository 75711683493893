import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ICountry } from "src/app/models/country.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class CountriesApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Countries", // Theme !
      [], // Post route paths
      [], // Get route paths
      [], // Put route paths
      [] // Delete route paths
    );
  }

  public list(code?: string): Observable<ICountry[]> {
    return this.api.get("countries", { code }) as Observable<ICountry[]>;
  }
}
