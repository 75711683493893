import { BehaviorSubject } from "rxjs";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { IDrugSchema } from "src/app/models/drugSchema.interface";
import { IStepwiseDrug } from "src/app/models/stepwiseDrug.interface";
import { DrugSchemaService } from "src/app/providers/drugSchema.service";
import { StepwiseDrugService } from "src/app/providers/stepwiseDrug.service";
import { hasOwnProperty } from "vega";

export class DrugSchemaDataSource extends MatTableDataSourceExtended<IDrugSchema | IStepwiseDrug> {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private refreshInterval;
  public loading$ = this.loadingSubject.asObservable();

  // This only returns string OR number, it cannot return arrays
  // So here for string[] of drugs I join them to convert them into one string
  sortingDataAccessor = (data: IDrugSchema | IStepwiseDrug, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "visibility":
        return data.visibility;
      case "name":
        return data.name?.toLowerCase();
      case "drugs":
        return (data as IDrugSchema).drugs?.join();
      case "modified":
        return data.modified;
      case "author":
        return data.author?.display;
      case "duration":
        return data && hasOwnProperty(data, "cycle")
          ? (data as IDrugSchema).cycle.length
          : (data as IStepwiseDrug).stepwises[(data as IStepwiseDrug).stepwises.length - 1].startDay +
              (data as IStepwiseDrug).stepwises[(data as IStepwiseDrug).stepwises.length - 1].days.length;
      default:
        return "";
    }
  };

  constructor(private drugSchemaService: DrugSchemaService, private stepwiseDrugService: StepwiseDrugService) {
    super();
  }

  public loadSchemas(isCycle = true, autoRefreshIntervalMs = 60000): void {
    this.loadingSubject.next(true);
    if (this.refreshInterval) {
      this.clearAutoRefresh();
    }
    this.refreshInterval = setInterval(() => {
      this.loadData(isCycle);
    }, autoRefreshIntervalMs);
    this.loadData(isCycle);
  }

  private clearAutoRefresh() {
    clearInterval(this.refreshInterval);
  }

  public clear(): void {
    this.clearAutoRefresh();
    this.loadingSubject.complete();
  }

  private loadData(isCycle = true) {
    if (isCycle) {
      this.drugSchemaService.list(undefined, true).subscribe((schemas) => {
        for (const element of schemas) {
          if (element.public) {
            element.visibility = "public";
          }
          if (!element.public && element.author) {
            element.visibility = "private";
          }
          if (!element.public && element.organization) {
            element.visibility = "org";
          }
        }
        this.data = schemas;
        this.loadingSubject.next(false);
      });
    } else {
      this.stepwiseDrugService.list().subscribe((schemas) => {
        for (const element of schemas) {
          if (element.public) {
            element.visibility = "public";
          }
          if (!element.public && element.author) {
            element.visibility = "private";
          }
          if (!element.public && element.organization) {
            element.visibility = "org";
          }
        }
        this.data = schemas;
        this.loadingSubject.next(false);
      });
    }
  }
}
