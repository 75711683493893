import { Coding } from "./coding.interface";
import { Identifier } from "./identifier.interface";
import {
  AccessLevel,
  EnableWhenBehavior,
  IEnableWhen,
  IPeriod,
  IQuantities,
  IReference,
  ISubCriteria,
  ITiming,
  IUseContext,
} from "./sharedInterfaces";

export class SubCriteria implements ISubCriteria {
  public identifier: Identifier; // identifier of the entity
  public activityReference?: Reference; // optional : indicated if this entity is associated to a specific activity of the careplan
  public when?: Timing; // When order should be fulfilled (interface Timing)
  public enableWhen?: IEnableWhen[];
  public enableWhenBehavior?: EnableWhenBehavior;
  public showDirectly?: boolean;
  public hasInsuranceCriteria?: boolean;

  constructor(data?: ISubCriteria) {
    this.identifier = data?.identifier;
    this.activityReference = data?.activityReference ? data.activityReference : null;
    this.when = data?.when ? data.when : new Timing();
    this.enableWhen = data?.enableWhen;
    this.enableWhenBehavior = data?.enableWhenBehavior;
    this.showDirectly = data?.showDirectly ? data?.showDirectly : null; // helper for knowledge subcriteria; never sent to backend.
    this.hasInsuranceCriteria = data?.hasInsuranceCriteria;
  }
}

export class EnableWhen implements IEnableWhen {
  public operator: "=" | "!=" | ">" | "<" | ">=" | "<=" | "€";
  public question: string;
  public answer: string | number;
  public code?: { coding: Coding[] };
  public type?: "string" | "number";
  constructor(data?: IEnableWhen) {
    this.operator = data?.operator ? data.operator : "=";
    this.question = data?.question ? data.question : undefined;
    this.answer = data?.answer ? data.answer : undefined;
    this.code = data?.code ? data.code : { coding: [{ code: undefined }] };
    this.type = data?.type ? data.type : undefined;
  }
}

export class Timing implements ITiming {
  public boundsPeriod?: IPeriod;
  public count?: number;
  public frequency?: number;
  public period?: number;
  public periodUnits?: string;
  public when?: string;
  public timingCode?: string;
  public endless?: boolean;
  public quantities?: IQuantities;

  constructor(data?: ITiming) {
    this.boundsPeriod = data?.boundsPeriod;
    this.count = data?.count;
    this.frequency = data?.frequency;
    this.period = data?.period;
    this.periodUnits = data?.periodUnits;
    this.when = data?.when;
    this.timingCode = data?.timingCode;
    this.endless = data?.endless;
    this.quantities = data?.quantities;
  }
}

export class Reference implements IReference {
  public reference: string;
  public display: string;

  constructor(data?: IReference) {
    this.reference = data?.reference ? data.reference : "";
    this.display = data?.display ? data.display : "";
  }
}

export class Period implements IPeriod {
  public start: string;
  public end?: string;

  constructor(data?: IPeriod) {
    this.start = data?.start ? data.start : "";
    this.end = data?.end ? data.end : "";
  }
}

export class UseContext implements IUseContext {
  public code?: Coding;
  public valueReference: string;
  public accessLevel?: AccessLevel;
  /**
   * Sort function for useContext array. the elements are sorted by their access level.
   * Highest first.
   * @param a (IUseContext)
   * @param b  (IUseContext)
   * @returns 1 if b should come before a, -1 otherwise
   */
  public static useContextSortAccess(a: IUseContext, b: IUseContext) {
    if (b.accessLevel > a.accessLevel) {
      return 1;
    } else {
      return -1;
    }
  }

  /**
   * Sort function for useContext array. The element are sorted by their valueReference.
   * The "all" reference ends up first, the other are sorted alphabeticaly.
   * @param a
   * @param b
   * @returns
   */
  public static useContextSortReference(a: IUseContext, b: IUseContext) {
    if (b.valueReference === "all") {
      return 1;
    }
    return a.valueReference.localeCompare(b.valueReference);
  }
}
