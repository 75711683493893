import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IDrugInfo, IDrugsInfo } from "src/app/models/drugInfo.interface";
import { IDrugsHistoric } from "src/app/models/drugsHistoric.interface";
import { ENTITY_TYPE, Entitylink, IDrugStock, IDrugStockIncrement, IEntitylink } from "src/app/models/entity.interface";
import { IDrugIntakeNotif, NOTIFICATION_TYPE, Notification } from "src/app/models/notifications.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class DrugsApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Drugs", // Theme !
      ["entity", "dashboard/drugIntake"], // Post route paths
      [
        "entitylinks", // 0
        "notifications", // 1
        "drugsInfo", // 2
        "dashboard/entitylink", // 3
        "dashboard/drugsHistoric", // 4
        "dashboard/exportDrugsForPatient", // 5
        "drugInfo", // 6
      ], // Get route paths
      ["dashboard/drugStock"], // Update route paths
      [] // Delete route paths
    );
  }

  public list(patientId: string): Observable<Entitylink[]> {
    return this.api.get(this.readRoutes[0], {
      patientid: patientId,
      ENTITY_TYPE: ENTITY_TYPE.DRUG,
    }) as Observable<Entitylink[]>;
  }

  public listIntake(patientId: string): Observable<Notification[]> {
    return this.api.get(this.readRoutes[1], {
      account: patientId,
      type: NOTIFICATION_TYPE.DRUG,
    }) as Observable<Notification[]>;
  }

  public listInfo(query: string, lang: string): Observable<IDrugInfo[]> {
    return this.api.get(this.readRoutes[2], { name: query, lang: lang }).pipe(map((v: IDrugsInfo) => v.results));
  }

  public getOne(id: string): Observable<Entitylink> {
    return this.api.get(this.readRoutes[3], { id }) as Observable<Entitylink>;
  }

  public getHistoric(patientId: string): Observable<IDrugsHistoric[]> {
    return this.api.get(this.readRoutes[4], { patientId }) as Observable<IDrugsHistoric[]>;
  }

  public save(drug: IEntitylink): Observable<number> {
    return this.api.post(this.createRoutes[0], drug) as Observable<number>;
  }

  public exportDrugs(patientId: string, lang: string): Observable<Blob> {
    return this.api.getBlob(this.readRoutes[5], { patientId: patientId, lang: lang }) as Observable<Blob>;
  }

  public createDrugIntakes(drugIntakeNotif: IDrugIntakeNotif[]): Observable<Notification> {
    return this.api.post(this.createRoutes[1], drugIntakeNotif) as Observable<Notification>;
  }

  public getDrugInfo(reference: string, source: string): Observable<IDrugInfo> {
    return this.api.get(this.readRoutes[6], { cnk: reference, source }) as Observable<IDrugInfo>;
  }
  public getDrugStock(patientId: string, drugId: string): Observable<IDrugStock> {
    return this.api.get("dashboard/drugStock", { patientId, drugId }) as Observable<IDrugStock>;
  }
  public updateDrugStock(stockUpdate: IDrugStockIncrement): Observable<IDrugStockIncrement> {
    return this.api.put(this.updateRoutes[0], stockUpdate) as Observable<IDrugStockIncrement>;
  }
}
