import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DocumentDataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { Filter } from "src/app/models/filter.interface";
import { IDocument, IDocumentHistoric, IPatientDocInfo } from "src/app/models/patient-document.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";
@Injectable({
  providedIn: "root",
})
export class DocumentApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Documents", // Theme !
      ["dashboard/document"], // Post route paths
      [
        "dashboard/document",
        "dashboard/documentsInfos",
        "dashboard/nbDocumentsInfos",
        "dashboard/documentHistoric",
        "dashboard/download/canalytics",
      ], // Get route paths
      ["dashboard/document"], // Update route paths
      ["dashboard/document"] // Delete route paths
    );
  }

  public findDocument(params: DocumentDataSourceBackendParam): Observable<IPatientDocInfo[]> {
    return this.api.get(this.readRoutes[1], {
      patientId: params.patientId,
      sortId: params.sortId,
      sortOrder: params.sortOrder,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      filters: JSON.stringify(params.filters),
      includeArchived: params.includeArchived,
    }) as Observable<IPatientDocInfo[]>;
  }

  public getDocumentsCount(patientId: string, includeArchived?: boolean, filters?: Filter[]): Observable<{ count: number }> {
    return this.api.get(this.readRoutes[2], {
      patientId,
      includeArchived,
      filters: JSON.stringify(filters),
    }) as Observable<{ count: number }>;
  }

  public create(formData: FormData): Observable<IDocument> {
    return this.api.post(this.createRoutes[0], formData, null, true) as Observable<IDocument>;
  }

  public edit(param: Record<string, string>): Observable<IDocument> {
    return this.api.put(this.updateRoutes[0], param) as Observable<IDocument>;
  }

  public deleteDocument(name: string, modified: string, patient: string): Observable<boolean> {
    return this.api.deleteWithParams(this.deleteRoutes[0], { name, modified, patient }) as Observable<boolean>;
  }

  public getDocument(name: string, modified: string, patient: string): Observable<Blob> {
    return this.api.getBlob(this.readRoutes[0], { name, modified, patient }) as Observable<Blob>;
  }

  public getHistoric(patientId: string, name: string): Observable<IDocumentHistoric> {
    return this.api.get(this.readRoutes[3], { patientId, name }) as Observable<IDocumentHistoric>;
  }

  public downloadFromCAnalytics(url: string): Observable<Blob> {
    return this.api.getBlob(this.readRoutes[4], { url }) as Observable<Blob>;
  }
}
