import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DataType, Filter } from "src/app/models/filter.interface";
import { DISABLED_PATIENT_REASON, PatientUser } from "src/app/models/patient.interface";
import { IPatientHistoric } from "src/app/models/patientHistoric.interface";
import { PatientService } from "src/app/providers/patient.service";
import { PatientHistoricDialogDataSource } from "./patient-historic-dialog-datasource";

@Component({
  selector: "app-patient-historic-dialog",
  templateUrl: "./patient-historic-dialog.component.html",
  styleUrls: ["./patient-historic-dialog.component.scss"],
})
export class PatientHistoricDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<IPatientHistoric>;
  public dataSource: PatientHistoricDialogDataSource;
  public historic: IPatientHistoric[] = [];
  public currentPageSize: number;
  public displayedColumns: string[] = ["date", "author", "oldReason", "newReason"];
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public reason = DISABLED_PATIENT_REASON;
  private onDestroy$ = new Subject<void>();

  constructor(
    protected patientService: PatientService,
    private dialogRef: MatDialogRef<PatientHistoricDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { patient: PatientUser }
  ) {
    this.patientService
      .getPatientHistoric(this.data.patient.user.caremateIdentifier, this.data.patient.patient.managingOrganization.reference)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.historic = result;
        this.initData();
      });
  }

  ngOnInit(): void {
    this.dataSource = new PatientHistoricDialogDataSource();
  }

  ngAfterViewInit() {
    this.initPaginatorAndSort();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public initData() {
    this.dataSource.loadData(this.historic);
  }

  public initPaginatorAndSort() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentPageSize = this.paginator.pageSize;
    });
  }

  public applyFilter(filter: Filter) {
    this.dataSource.setFilter(filter);
  }

  public getFilter(propertyName: string) {
    return this.dataSource.getFilter(propertyName);
  }
}
