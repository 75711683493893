import { ErrorHandler, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { PreferenceService } from "../preference.service";

@Injectable({
  providedIn: "root",
})
export class PreferenceResolver implements Resolve<unknown> {
  constructor(private preferenceService: PreferenceService, private globalErrorHandler: ErrorHandler) {}
  resolve(route: ActivatedRouteSnapshot): Observable<unknown> {
    const ctx = route.data.ctx;
    return this.preferenceService.list(ctx).pipe(
      catchError((err) => {
        return throwError(this.globalErrorHandler.handleError(err));
      })
    );
  }
}
