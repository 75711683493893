import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AddDrugIntakeDialogComponent } from "src/app/components/patient-drugs/add-drug-intake-dialog/add-drug-intake-dialog.component";
import { IEntitylink } from "src/app/models/entity.interface";
import { Notification, NOTIFICATION_STATUS } from "src/app/models/notifications.interface";
import { IConfiguration } from "src/app/models/patientConfig.interface";
import { TimingData } from "src/app/models/timingData.model";
import { PatientService } from "src/app/providers/patient.service";
import { SessionService } from "src/app/providers/session.service";

@Component({
  selector: "app-drug-details",
  templateUrl: "./drug-details.component.html",
  styleUrls: ["./drug-details.component.scss"],
})
export class DrugDetailsComponent implements OnInit, OnDestroy {
  public nbSection: number;
  public sections: { [pageNbr: number]: Notification[] } = {};
  public selectedSection: Notification[] = [];
  public page = 0;
  public patientConfig: IConfiguration;
  public NOTIFICATION_STATUS = NOTIFICATION_STATUS;
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { drug: IEntitylink; notifications?: Notification[]; patientId: string },
    private sessionService: SessionService,
    private dialog: MatDialog,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.patientService
      .getPatientConfiguration(this.data.patientId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((config) => {
        this.patientConfig = config;
      });
    // 10 results by page
    this.nbSection = Math.ceil(this.data.notifications.length / 10);
    // we build section with index
    for (let index = 0; index < this.nbSection; index++) {
      const start = index * 10;
      this.sections[index] = this.data.notifications.slice(start, start + 10);
    }
    this.selectedSection = this.sections[this.page];
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public changePage(sign: "-" | "+"): void {
    switch (sign) {
      case "-":
        if (!this.isFirstSection()) {
          this.page--;
          this.selectedSection = this.sections[this.page];
        }
        break;
      case "+":
        if (!this.isLastSection()) {
          this.page++;
          this.selectedSection = this.sections[this.page];
        }
        break;
      default:
        break;
    }
  }

  public isLastSection(): boolean {
    return this.page + 1 === this.nbSection;
  }

  public isFirstSection(): boolean {
    return this.page + 1 === 1;
  }

  public onAddIntake(notif: Notification, index: number): void {
    const time = TimingData.getMomentHour(notif.moment, this.patientConfig.settings.notificationSettings, notif.time);
    const dialogRef = this.dialog.open(AddDrugIntakeDialogComponent, {
      data: {
        patientId: this.data.patientId,
        drug: this.data.drug,
        time: time ? time : notif.time,
        moment: notif.moment,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result?.length === 1) {
          this.selectedSection[+index] = result[0];
          this.sessionService.needRefreshDrugsData();
        }
      });
  }
}
