import { DatePipe } from "@angular/common";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { IPDFOptions } from "src/app/models/export.interface";
import { IObservationDefinition } from "src/app/models/observations.interface";
import { PatientUser } from "src/app/models/patient.interface";
import { ToolsService } from "src/app/providers/tools.service";
import { Item } from "../../item-selector/item-selector.component";

@Component({
  selector: "app-patient-observations-stat-card",
  templateUrl: "./patient-observations-stat-card.component.html",
  styleUrls: ["./patient-observations-stat-card.component.scss"],
})
export class PatientObservationsStatCardComponent implements OnInit {
  @Input() pu: PatientUser;
  @Input() currentDefinitions: IObservationDefinition[];

  @Input() showStreamObs: boolean;
  @Output() showStreamObsChange = new EventEmitter<boolean>();

  @Input() sliderData: Item[];
  @Output() sliderDataChange = new EventEmitter<Item[]>();

  @Input() streamObs: string[];

  @ViewChild("statContainer") statContainer: ElementRef;

  public dateFilterForm: FormGroup;

  public maxFromDate: moment.Moment;
  public minToDate: moment.Moment;

  public fromDate: moment.Moment;
  public toDate: moment.Moment;
  public exportPdfInProgress = false;

  public onDateChange(): void {
    this.toDate = this.dateFilterForm.get("toDate").value as moment.Moment;
    this.fromDate = this.dateFilterForm.get("fromDate").value as moment.Moment;
    this.maxFromDate = this.toDate;
    this.minToDate = this.fromDate;
  }

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private toolsService: ToolsService
  ) {}

  ngOnInit(): void {
    this.initDateForm();
  }

  public onShowStreamChange(): void {
    this.showStreamObsChange.emit(this.showStreamObs);
  }

  public initDateForm(): void {
    this.dateFilterForm = this.fb.group({
      fromDate: [moment().subtract(3, "month"), [Validators.required]],
      toDate: [moment().endOf("day"), [Validators.required]],
    });

    this.onDateChange();
  }

  public exportStats(): void {
    this.exportPdfInProgress = true;
    const pdfOptions: IPDFOptions = { margin: { top: 28, right: 28, bottom: 28, left: 28 } };
    const style = `

     html { -webkit-print-color-adjust: exact; }
    .legend-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: center;
    }
    .legend-icon {
      height: 10px;
      width: 20px;
    }
    .legend-element {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
    }
    mat-card * {
      font-size: 12px !important;
      color : black !important;
    }
    .mat-row, mat-header-row{
      min-height : 20px;
      border-color: lightgray;
    }
    `;

    const title = `${this.translateService.instant("stat.exportTitle")} : ${this.datePipe.transform(
      this.fromDate.toISOString(),
      "shortDate"
    )} - ${this.datePipe.transform(this.toDate.toISOString(), "shortDate")}`;

    const htmlContent = this.statContainer.nativeElement.cloneNode(true) as HTMLElement;

    // Resize svg if they have the class .toResize. This allow to scale the chart to fit inside the pdf.
    // the class is added by ChartHelper.createSvg()
    const svgElements = htmlContent.querySelectorAll("svg.toResize");
    svgElements.forEach((svg: SVGElement) => {
      const width = parseFloat(svg.getAttribute("width") || "0");
      if (width > 1000) {
        const scaleFactor = 1000 / width;
        svg.style.transform = `scale(${scaleFactor})`;
        svg.style.transformOrigin = "center center";
      }
    });

    this.toolsService.convertHtmlToPFDAndOpenInBrowser(this.pu, title, htmlContent.innerHTML, style, pdfOptions).then(() => {
      this.exportPdfInProgress = false;
    });
  }
}
