<div class="container">
  <h2>{{ "forms.identityVerificationMethod.title" | translate }}:</h2>
  <div>
    <div *ngFor="let method of serviceWithVerifMethods?.identityVerifMethods; let i = index">
      <mat-form-field class="mr-5">
        <input matInput type="text" [(ngModel)]="method.term" placeholder="N°" disabled />
      </mat-form-field>
      <mat-form-field class="mr-5">
        <input matInput type="text" [(ngModel)]="method.fr" placeholder="{{ availableLangs[0].display }}" />
      </mat-form-field>
      <mat-form-field class="mr-5">
        <input matInput type="text" [(ngModel)]="method.nl" placeholder="{{ availableLangs[1].display }}" />
      </mat-form-field>
      <mat-form-field class="mr-5">
        <input matInput type="text" [(ngModel)]="method.en" placeholder="{{ availableLangs[2].display }}" />
      </mat-form-field>
      <mat-form-field class="mr-5">
        <input matInput type="text" [(ngModel)]="method.de" placeholder="{{ availableLangs[3].display }}" />
      </mat-form-field>
      <mat-form-field class="mr-5">
        <input matInput type="text" [(ngModel)]="method.it" placeholder="{{ availableLangs[4].display }}" />
      </mat-form-field>
      <mat-form-field class="mr-5">
        <mat-label>
          {{ "forms.identityVerificationMethod.level.title" | translate }}
        </mat-label>
        <mat-select [(ngModel)]="method.level" name="level">
          <mat-option value="high">
            {{ "forms.identityVerificationMethod.level.high" | translate }}
          </mat-option>
          <mat-option value="medium">
            {{ "forms.identityVerificationMethod.level.medium" | translate }}
          </mat-option>
          <mat-option value="low">
            {{ "forms.identityVerificationMethod.level.low" | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox [(ngModel)]="method.activated">
        {{ "status.active" | translate }}
      </mat-checkbox>
    </div>
    <button mat-raised-button class="mr-5" (click)="addVerifMethod()">
      {{ "forms.identityVerificationMethod.addNewMethod" | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="updateVerifMethods()">
      {{ "forms.identityVerificationMethod.updateVerifMethods" | translate }}
    </button>
  </div>
</div>
