import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subject } from "rxjs";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { IPredictionStatus } from "src/app/models/riskAssessment.interface";
import { RiskAssessment } from "src/app/models/riskAssessment.model";

export class PatientRiskAssessmentListDataSource extends MatTableDataSourceExtended<RiskAssessment | IPredictionStatus> {
  private refreshInterval;
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();

  sortingDataAccessor = (data: RiskAssessment | IPredictionStatus, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "risk":
        return (data as RiskAssessment)?.riskName
          ? this.translateService.instant("risk" + (data as RiskAssessment).riskName)
          : (data as IPredictionStatus).fileName;
      case "date":
        return (data as RiskAssessment).issued
          ? moment((data as RiskAssessment).issued, "YYYY-MM-DD").unix()
          : moment((data as IPredictionStatus).creation, "YYYY-MM-DD").unix();
      default:
        return "";
    }
  };

  constructor(private translateService: TranslateService) {
    super();
  }

  private clearAutoRefresh() {
    clearInterval(this.refreshInterval);
  }

  public clear(): void {
    this.clearAutoRefresh();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
