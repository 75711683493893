import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatAutocomplete, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Observable, Subject, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { IAttachment, ICommunication, IPayloadType, ISimplifiedCommunication, STATUS_CODE } from "src/app/models/communications.interface";
import { Group } from "src/app/models/group.model";
import { Reference } from "src/app/models/reference.interface";
import { CommunicationsService } from "src/app/providers/communications.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PatientService } from "src/app/providers/patient.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { GroupSearchOnlyComponent } from "../../add-to-group-dialog/group-search-only/group-search-only.component";

@Component({
  selector: "app-add-communication",
  templateUrl: "./add-communication.component.html",
  styleUrls: ["./add-communication.component.scss"],
})
export class AddCommunicationComponent implements OnInit, OnDestroy {
  public visible = true;
  public selectable = true;
  public removable = true;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public sendToCtrl = new UntypedFormControl();
  public filteredSendTo: Observable<Reference[]>;
  public availableSendTo: Reference[] = [];
  public isSubmitted = false;
  public isCreation: boolean;
  private currentService: Reference;

  @ViewChild("sendToInput") sendToInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  public comTemplate: ISimplifiedCommunication;

  public htmlContent = "";
  public communicationForm: UntypedFormGroup = this.fb.group({
    currentSendTo: [[], [Validators.required]],
    subject: ["", [Validators.required]],
    content: ["", [Validators.required]],
  });

  public autoSaveSub: Subscription;
  public alreadySaved = false;
  public savedCommunication: ICommunication;
  public lastSavedDate: moment.Moment;
  public attachements: IAttachment[] = [];
  public loading = false;
  public config = {
    useSearch: false,
    spellcheck: true,
    language: this.sessionService.userLang,
    toolbarButtonSize: "small",
    minHeight: 450,
    disablePlugins: "file, media",
    style: { font: "20px Arial" },
  };
  public allSimilar = true;
  public autoSaveInterval: unknown;
  public isInit = false;
  public hasValidate = false;
  public savingInProgress = false;

  constructor(
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private comService: CommunicationsService,
    private sessionService: SessionService,
    private readonly dialogRef: MatDialogRef<AddCommunicationComponent>,
    private patientService: PatientService,
    private dialog: MatDialog,
    private preferenceService: PreferenceService,
    private userService: UserService,
    private healthcareService: HealthcareserviceService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mode: FORMS_MODE;
      selection: Reference[];
      group?: Group;
      allPatients?: Reference[];
      communication?: ICommunication;
      fromMainCom?: boolean;
      allCanBeUpdated?: boolean;
      onePatientOnly?: boolean;
    }
  ) {
    this.isCreation = this.data.mode === FORMS_MODE.CREATE;
    this.currentService = this.userService.isMonitoringUser
      ? this.sessionService.currentMonitoringService
      : this.sessionService.currentService;
    this.comTemplate = {
      sender: {
        reference: this.sessionService.account.caremateIdentifier,
        display: this.sessionService.account.displayName,
      },
      healthservice: this.currentService,
      group: null,
      topic: "",
      status: STATUS_CODE.COMPLETED,
      content: "",
      subjects: null,
      attachments: [],
    };
    if (this.data.onePatientOnly) {
      this.sendToCtrl.disable();
      this.communicationForm.get("currentSendTo").disable();
    }
  }

  ngOnInit(): void {
    this.loadPatients();
    this.autoSaveInterval = setInterval(() => {
      this.send(true);
    }, this.preferenceService.getAutoSaveInterval());
  }

  ngOnDestroy(): void {
    clearInterval(+this.autoSaveInterval);
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * shortcuts to get forms value
   */
  private get content(): string {
    return this.communicationForm.get("content")?.value as string;
  }

  private get subject(): string {
    return this.communicationForm.get("subject")?.value as string;
  }

  get currentSendTo(): Reference[] {
    return this.communicationForm.get("currentSendTo")?.value as Reference[];
  }

  set currentSendTo(value: Reference[]) {
    this.communicationForm.get("currentSendTo")?.setValue(value);
  }

  private loadPatients(): void {
    if (!this.data.allPatients && !this.data.onePatientOnly) {
      this.loading = true;
      const selectedService = this.sessionService.currentService;
      const selectedMonitService = this.sessionService.currentMonitoringService;
      const services = [];
      const monitServices = [];
      if (selectedService && selectedService.reference === this.sessionService.allsOption) {
        services.push(...this.healthcareService.availableServices()?.map((s) => s.serviceRef));
      } else if (selectedService) {
        services.push(selectedService.reference);
      }
      if (selectedMonitService && selectedMonitService.reference === this.sessionService.allsOption) {
        monitServices.push(...this.healthcareService.availableMonitoringServices()?.map((s) => s.serviceRef));
      } else if (selectedMonitService) {
        monitServices.push(selectedMonitService.reference);
      }
      let ref = this.isCreation ? selectedService?.reference : this.data.communication.healthservice?.reference;
      if (ref && ref === this.sessionService.allsOption) {
        ref = null;
      }
      this.patientService
        .listRefsByHealthcareService(ref, services, monitServices)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((patients) => {
          this.data.allPatients = patients;
          this.init();
          this.loading = false;
        });
    } else {
      this.init();
    }
  }

  private init(): void {
    this.initSenderChip();
    this.setContentAndSubject();
    this.setAttachement();
    if (!this.isCreation) {
      this.savedCommunication = this.data.communication;
    }
  }

  private setContentAndSubject(): void {
    if (!this.isCreation) {
      const content = this.data.communication.payload.find((v) => v.contentString)?.contentString;
      const subject = this.data.communication.topic.text;
      this.communicationForm.get("content").setValue(content);
      this.communicationForm.get("subject").setValue(subject);
    }
  }

  private setAttachement(): void {
    if (!this.isCreation) {
      this.attachements = this.data.communication.payload.filter((p) => p.contentAttachment).map((v) => v.contentAttachment);
    }
  }

  private initSenderChip(): void {
    this.addUniqRefsToCurrentSendTo(this.data.selection);
    this.availableSendTo = (this.data.allPatients || []).sort((p1, p2) => p1.display.toLowerCase().localeCompare(p2.display.toLowerCase()));
    this.updateAvailableSendTo(this.currentSendTo);
    this.filteredSendTo = this.sendToCtrl.valueChanges.pipe(
      map((v: unknown) => (v && !(v as Reference).display ? this._filter(v as string) : this.availableSendTo.slice()))
    );
  }

  private updateAvailableSendTo(currentSendTo: Reference[]): void {
    for (const sendTo of currentSendTo) {
      const found = this.availableSendTo.find((a) => a.reference === sendTo.reference);
      const index = this.availableSendTo.indexOf(found);
      if (index > -1) {
        this.availableSendTo.splice(index, 1);
      }
    }
  }

  private addUniqRefsToCurrentSendTo(references: Reference[]): void {
    if (!references || !references.length) {
      this.currentSendTo = [];
    } else {
      const uniqRefs = Array.from(new Set(references.map((sel) => sel.reference)));
      for (const uniqRef of uniqRefs) {
        const sentTo = this.currentSendTo.find((cst) => cst.reference === uniqRef);
        if (!sentTo) {
          this.currentSendTo.push(references.find((ref) => ref.reference === uniqRef));
        }
      }
    }
  }

  public addSendToChip(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (value || "") {
      const reference = this.availableSendTo.find((v) => v.display === value)?.reference;
      if (reference) {
        this.currentSendTo.push({ reference, display: value });
      }
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.sendToCtrl.setValue(null);
  }

  public removeSendTo(sendTo: Reference): void {
    const index = this.currentSendTo.findIndex((v) => v.reference === sendTo.reference);

    if (index >= 0) {
      this.availableSendTo.push(this.currentSendTo.find((v) => v.reference === sendTo.reference));
      this.availableSendTo.sort((p1, p2) => p1.display.toLowerCase().localeCompare(p2.display.toLowerCase()));
      this.currentSendTo.splice(index, 1);
    }
    if (this.currentSendTo.length === 0) {
      this.data.group = null;
    }
    this.communicationForm.get("currentSendTo").updateValueAndValidity();
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    this.currentSendTo.push(event.option.value as Reference);
    this.updateAvailableSendTo(this.currentSendTo);
    this.sendToInput.nativeElement.value = "";
    this.sendToCtrl.setValue(null);
    this.communicationForm.get("currentSendTo").updateValueAndValidity();
  }

  private _filter(value: string): Reference[] {
    const filterValue = value.toLowerCase();

    return this.availableSendTo.filter((sendTo) => sendTo.display.toLowerCase().indexOf(filterValue) === 0);
  }

  public get savedDate(): string {
    return this.lastSavedDate?.format("HH:mm") || null;
  }

  public send(saveDraft?: boolean): void {
    if (!this.communicationForm.valid || !this.currentSendTo.length) {
      if (!this.subject && saveDraft && this.content) {
        this.communicationForm.get("subject").setValue(this.translateService.instant("communication.defaultSubject"));
        this.send(saveDraft);
      }
      if (!saveDraft) {
        this.hasValidate = true;
      }
      return;
    }
    if (this.savingInProgress) {
      return;
    }
    this.savingInProgress = true;
    if (this.isCreation) {
      this.comTemplate.content = this.content;
      this.comTemplate.topic = this.subject;
      this.comTemplate.group = this.data.group ? { reference: this.data.group._id, display: this.data.group.groupName } : null;
      this.comTemplate.subjects = this.currentSendTo;
      this.comTemplate.attachments = this.attachements.map((v) => {
        return {
          contentAttachment: v,
        };
      });
      if (saveDraft) {
        this.comTemplate.status = STATUS_CODE.PREPARATION;
      } else {
        this.comTemplate.status = STATUS_CODE.COMPLETED;
      }
      this.comService
        .create(this.comTemplate)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (v) => {
            this.handleApiResponse(saveDraft, v[0]);
          },
          (err) => {
            this.savingInProgress = false;
            FileLogger.error("AddCommunicationComponent", "Error while creating communication", err, "none");
          }
        );
    } else {
      // keep only html content
      this.savedCommunication.payload = this.savedCommunication.payload.filter((v) => v.contentString);
      // get it and replace it
      const htmlPayload = this.savedCommunication.payload.find((v) => v.contentString);
      if (htmlPayload) {
        htmlPayload.contentString = this.content;
      } else {
        // if not exist (normally never)
        this.savedCommunication.payload = [
          {
            contentString: this.content,
          },
        ];
      }
      // reconstruct attach
      this.attachements.forEach((attach) => {
        this.savedCommunication.payload.push({
          contentAttachment: attach,
        });
      });
      // reset topic
      this.savedCommunication.topic.text = this.subject;
      if (saveDraft) {
        this.savedCommunication.status = STATUS_CODE.PREPARATION;
      } else {
        this.savedCommunication.status = STATUS_CODE.COMPLETED;
      }
      this.savedCommunication.received = null;
      this.comService
        .update(this.savedCommunication, this.currentSendTo)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          () => {
            this.handleApiResponse(saveDraft, null);
          },
          (err) => {
            this.savingInProgress = false;
            FileLogger.error("AddCommunicationComponent", "Error while updating communication", err, "none");
          }
        );
    }
  }

  private handleApiResponse(saveDraft: boolean, communication?: ICommunication): void {
    if (!saveDraft) {
      this.closeAndSuccess();
    } else {
      if (communication) {
        this.savedCommunication = communication;
      }
      this.isCreation = false;
      this.lastSavedDate = moment();
    }
    this.savingInProgress = false;
  }

  private closeAndSuccess(): void {
    this.showSuccess();
    this.dialogRef.close();
    this.sessionService.needRefreshCommunicationsList();
  }

  private closeAndSayWhereAreDraft(): void {
    this.showWhereAreDraft();
    this.dialogRef.close();
    this.sessionService.needRefreshCommunicationsList();
  }

  private showWhereAreDraft(): void {
    const success = this.translateService.instant("communication.whereAreDraft");
    this.snackBar.open(success, "ok", { duration: 5000 });
  }

  public addImage(event: { target: { files: FileList } }): void {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (files.length && file) {
        const maxSize = 0.6 * 1024 * 1024;
        if (file?.size > maxSize) {
          this.showTooBig();
          return;
        } else {
          const reader = new FileReader();
          const fileName = file.name;
          reader.onload = this._handleReaderLoaded.bind(this, fileName, IPayloadType.JPG);
          reader.readAsDataURL(file);
        }
      }
    }
  }

  public addPdf(event: { target: { files: FileList } }): void {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (files.length && file) {
        const maxSize = 0.6 * 1024 * 1024;
        if (file?.size > maxSize) {
          this.showTooBig();
          return;
        } else {
          const reader = new FileReader();
          const fileName = file.name;
          reader.onload = this._handleReaderLoaded.bind(this, fileName, IPayloadType.PDF);
          reader.readAsDataURL(file);
        }
      }
    }
  }

  private showTooBig(): void {
    const trans = this.translateService.instant("page.myService.imageError");
    this.snackBar.open(trans, "ok", { duration: 3000 });
  }

  private showSuccess(): void {
    const success = this.translateService.instant("common.success");
    this.snackBar.open(success, "ok", { duration: 3000 });
  }

  public _handleReaderLoaded(fileName: string, type: IPayloadType, readerEvt: ProgressEvent<FileReader>): void {
    this.attachements.push({
      title: fileName,
      data: readerEvt.target.result as string,
      contentType: type,
      creation: new Date(),
    });
  }

  public get attachementsString(): string[] {
    return this.attachements.map((v) => v.title);
  }

  public remove(attachement: string): void {
    const index = this.attachements.findIndex((v) => v.title === attachement);

    if (index >= 0) {
      this.attachements.splice(index, 1);
    }
  }

  public onClose(): void {
    if (this.lastSavedDate) {
      this.closeAndSayWhereAreDraft();
    } else {
      this.dialogRef.close();
    }
  }

  public get isGroupSelected(): boolean {
    return !!this.data.group;
  }

  public openGroupSearch(): void {
    this.dialog
      .open(GroupSearchOnlyComponent, {
        disableClose: true,
      })
      .afterClosed()
      .subscribe((group: Group) => {
        if (group) {
          this.data.group = group;
          if (!this.currentSendTo) {
            this.currentSendTo = [];
          }
          this.addUniqRefsToCurrentSendTo(group.patients);
          this.updateAvailableSendTo(this.currentSendTo);
          this.filteredSendTo = this.sendToCtrl.valueChanges.pipe(
            map((v: unknown) => (v && !(v as Reference).display ? this._filter(v as string) : this.availableSendTo.slice()))
          );
        }
      });
  }

  /**
   * for form Validation purposes only, for sending logic see send()
   */
  public submit(): void {
    this.isSubmitted = true;
  }
}
