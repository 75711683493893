import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Coding } from "src/app/models/coding.interface";
import { Reference } from "src/app/models/reference.interface";
import { ACTION_ALERT, ALERT_STATUS } from "src/app/models/rule-alert.interface";
import { RuleAlert } from "src/app/models/rule-alert.model";
import { PractitionerService } from "src/app/providers/practitioner.service";
import { RuleAlertService } from "src/app/providers/rule-alert.service";
import { SessionService } from "src/app/providers/session.service";

@Component({
  selector: "app-alert-details-edit",
  templateUrl: "./alert-details-edit.component.html",
  styleUrls: ["./alert-details-edit.component.scss"],
})
export class AlertDetailsEditComponent implements OnInit, OnDestroy {
  @Input() alert: RuleAlert;
  @Input() createAction: boolean;
  @Input() patientServiceId: string;
  @Output() saved = new EventEmitter<void>();

  public actionForm: UntypedFormGroup = this.fb.group({
    responsable: [undefined, []],
    status: [undefined, []],
    comment: [undefined, []],
  });

  public availableCollegues: Reference[] = [];

  public availableStatus: Coding[] = [
    {
      code: ALERT_STATUS.PROCESSED.toString(),
      display: this.translateService.instant("alerts.status.processed"),
    },
    {
      code: ALERT_STATUS.UNPROCESSED.toString(),
      display: this.translateService.instant("alerts.status.unprocessed"),
    },
  ];
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  public constructor(
    private fb: UntypedFormBuilder,
    private practitionerService: PractitionerService,
    private sessionService: SessionService,
    private translateService: TranslateService,
    private ruleAlertService: RuleAlertService
  ) {}

  ngOnInit() {
    this.practitionerService
      .listForService(this.patientServiceId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((ps) => {
        this.availableCollegues = ps
          .map((p) => {
            return this.practitionerService.getPractitionerReference(p);
          })
          .filter((v) => v.display);
        this.initForm();
      });
  }
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public initForm() {
    const responsable = this.availableCollegues.find((ps) => ps.reference === this.alert.author?.reference);
    this.actionForm = this.fb.group({
      responsable: [responsable, []],
      status: [this.alert.alertStatus, []],
      comment: [undefined, []],
    });
    if (!this.ruleAlertService.canUpdateSync()) {
      this.actionForm.disable();
    }
  }

  public onSave(quitModal: boolean) {
    let status = Number(this.actionForm.get("status").value);
    let responsable = this.actionForm.get("responsable").value as Reference;
    const comment = this.actionForm.get("comment").value as string;
    const arrAction: ACTION_ALERT[] = [];
    const author: Reference = {
      reference: this.sessionService.account.caremateIdentifier,
      display: this.sessionService.account.displayName,
    };

    if (!this.createAction) {
      status = ALERT_STATUS.PROCESSED as number;
      responsable = author;
    }

    if (status !== this.alert.alertStatus) {
      arrAction.push(ACTION_ALERT.CHANGE_STATUS);
    }
    if (comment?.length) {
      arrAction.push(ACTION_ALERT.ADD_NOTE);
    }
    if (responsable?.reference !== this.alert?.author?.reference) {
      arrAction.push(ACTION_ALERT.CHANGE_IN_CHARGE);
    }
    if (arrAction.length) {
      this.alert.handleActionArray(arrAction, status, responsable, comment, author);
      this.ruleAlertService
        .save(this.alert)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          if (this.createAction) {
            if (!quitModal) {
              this.initForm();
              this.sessionService.needRefreshAlertDetailsDataList();
            } else {
              this.saved.emit();
            }
          } else {
            this.saved.emit();
          }
        });
    }
  }
}
