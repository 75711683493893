import { AfterViewInit, Component, OnDestroy, ViewChild, ViewEncapsulation } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatTable } from "@angular/material/table";
import * as _moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IStatSummary } from "src/app/models/userStatistics.interface";
import { UserStatisticsService } from "src/app/providers/userStatistics.service";

// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from "moment";
import { FileLogger } from "src/app/helpers/fileLogger";

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

interface StatSummaryRow {
  statName: string;
  statPerMonth: number[];
}

@Component({
  selector: "app-stat-summary-page",
  templateUrl: "./stat-summary-page.component.html",
  styleUrls: ["./stat-summary-page.component.scss"],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class StatSummaryPageComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatTable) table: MatTable<unknown>;
  public maxFromDate: string;
  public minToDate: string;
  public today = moment();
  public fromToForm: UntypedFormGroup;
  public displayedColumns: string[] = [];
  public statSummaryDataSource: StatSummaryRow[] = [];
  private statSummaries: IStatSummary[] = [];
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  private get from(): string {
    return this.fromToForm.get("fromDate").value?.startOf("month").format("YYYY-MM");
  }
  private get to(): string {
    return this.fromToForm.get("toDate").value?.startOf("month").format("YYYY-MM");
  }

  constructor(private fb: UntypedFormBuilder, private statService: UserStatisticsService) {
    this.fromToForm = this.fb.group({
      fromDate: new UntypedFormControl(moment().startOf("month").startOf("day").subtract(3, "month"), {
        validators: Validators.required,
        updateOn: "blur",
      }),
      toDate: new UntypedFormControl(moment().startOf("month").endOf("day"), {
        validators: Validators.required,
        updateOn: "blur",
      }),
    });
    this.updateDate();
    this.fromToForm
      .get("fromDate")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((newDate) => {
        if (!moment(newDate).isValid()) {
          return;
        }
        this.updateDate(moment(newDate).startOf("month").format("YYYY-MM"), null);
        this.loadStatSummaries();
      });
    this.fromToForm
      .get("toDate")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((newDate) => {
        if (!moment(newDate).isValid()) {
          return;
        }
        this.updateDate(null, moment(newDate).startOf("month").format("YYYY-MM"));
        this.loadStatSummaries();
      });
  }

  ngAfterViewInit(): void {
    this.loadStatSummaries();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>, controlName: string): void {
    const ctrlValue = this.fromToForm.get(controlName).value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.fromToForm.get(controlName).setValue(ctrlValue);
    datepicker.close();
  }

  private updateDate(from?: string, to?: string): void {
    if ((!this.from && !from) || (!this.to && !to)) {
      return;
    }
    if (!from) {
      from = moment(this.from).format("YYYY-MM");
    }
    if (!to) {
      to = moment(this.to).format("YYYY-MM");
    }
    this.maxFromDate = moment(to).toISOString();
    this.minToDate = moment(from).toISOString();
  }

  private async loadStatSummaries(): Promise<void> {
    try {
      this.statSummaries = await this.statService.listSummaries(this.from, this.to);
      this.setupSummaryTable(this.statSummaries);
    } catch (err) {
      FileLogger.error("StatSummaryPageComponent", "Error while loading statistics summaries", err);
    }
  }

  private setupSummaryTable(statSummaries: IStatSummary[]): void {
    if (!statSummaries || statSummaries.length === 0) {
      this.displayedColumns = [];
      this.statSummaryDataSource = [];
      return;
    }
    const newColumnNames: string[] = ["statName"];
    newColumnNames.push(...statSummaries.map((s: IStatSummary) => s.startOfMonth));
    const newRows: StatSummaryRow[] = [];
    for (let i = 0; i < statSummaries.length; ++i) {
      const statSummary: any = statSummaries[i];
      delete statSummary._id;
      delete statSummary.__v;
      const statNames = Object.keys(statSummary);
      for (const statName of statNames) {
        if (statName === "startOfMonth") {
          continue;
        }
        let row = newRows.find((r) => r.statName === statName);
        if (!row) {
          const statPerMonth: number[] = Array(i).fill(-1);
          row = { statName, statPerMonth };
          newRows.push(row);
        }
        row.statPerMonth.push(statSummary[statName]);
      }
    }
    for (const row of newRows) {
      const diff = statSummaries.length - row.statPerMonth.length;
      if (diff > 0) {
        const missingStats: number[] = Array(diff).fill(-1);
        row.statPerMonth.push(...missingStats);
      }
    }
    this.displayedColumns = newColumnNames;
    this.statSummaryDataSource = newRows;
  }
}
