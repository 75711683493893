import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
@Injectable({
  providedIn: "root",
})
export class PwaService {
  constructor(private swUpdate: SwUpdate) {
    this.swUpdate.versionUpdates.subscribe((event) => {
      if (event.type === "VERSION_READY") {
        window.location.reload();
      }
    });
  }
}
