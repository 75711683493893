import { ACTION_TARGET } from "src/app/models/careplans.interface";
import { IReference } from "src/app/models/sharedInterfaces";
import { IlinkableKnowledgeItem, IlinkableObsItem, IlinkableQuestionnaireItem, IlinkableVitalProfileItem } from "./IlinkableItem.interface";

export class LinkableObsItem implements IlinkableObsItem {
  public uniqueId: string;
  public itemType: ACTION_TARGET.OBSERVATION;
  public name: string;
  public loinc: string;
  public reference: string;
  public originReference?: IReference;
  public origin?: "link2careplan" | "activity";
  constructor(item: IlinkableObsItem) {
    this.uniqueId = item.uniqueId;
    this.itemType = ACTION_TARGET.OBSERVATION;
    this.name = item.name;
    this.loinc = item.loinc;
    this.reference = item.reference;
    this.originReference = item.originReference;
    this.origin = item.origin;
  }
}

export class LinkableVitalProfileItem implements IlinkableVitalProfileItem {
  public uniqueId: string;
  public itemType: ACTION_TARGET.VITAL_PROFILE;
  public name: string;
  public code: string;
  public unit: string;
  constructor(item: IlinkableVitalProfileItem) {
    this.uniqueId = item.uniqueId;
    this.itemType = ACTION_TARGET.VITAL_PROFILE;
    this.name = item.name;
    this.code = item.code;
    this.unit = item.unit;
  }
}

export class LinkableKnowledgeItem implements IlinkableKnowledgeItem {
  public uniqueId: string;
  public itemType: ACTION_TARGET.KNOWLEDGE;
  public term: string;
  public category: string;
  public type: string;
  public originReference?: IReference;
  public origin?: "link2careplan" | "activity";
  public identifier: string;
  constructor(item: IlinkableKnowledgeItem) {
    this.itemType = ACTION_TARGET.KNOWLEDGE;
    this.uniqueId = item.uniqueId;
    this.term = item.term;
    this.category = item.category;
    this.type = item.type;
    this.originReference = item.originReference;
    this.origin = item.origin;
    this.identifier = item.identifier;
  }
}
export class LinkableQuestionnaireItem implements IlinkableQuestionnaireItem {
  public identifier: string;
  public subjectType: string;
  public version: string;
  public itemType: ACTION_TARGET.QUESTIONNAIRE;
  public uniqueId: string;
  public originReference?: IReference;
  public origin?: "link2careplan" | "activity";
  public isDraft: boolean;

  constructor(item: IlinkableQuestionnaireItem) {
    this.identifier = item.identifier;
    this.subjectType = item.subjectType;
    this.version = item.version;
    this.itemType = ACTION_TARGET.QUESTIONNAIRE;
    this.uniqueId = item.identifier;
    this.originReference = item.originReference;
    this.origin = item.origin;
    this.isDraft = item.isDraft;
  }
}
