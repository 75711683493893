import * as moment from "moment";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { InfoCommunication } from "src/app/models/communications.model";

export class SubCommunicationsListDataSource extends MatTableDataSourceExtended<InfoCommunication> {
  public id: string;
  sortingDataAccessor = (data: InfoCommunication, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "statusSub" + this.id:
        return data.openStatusLocal;
      case "sendDateSub" + this.id:
        return moment(data.sent).unix();
      case "receivedDateSub" + this.id:
        return data.receivedDateStringLocal;
      case "sendToSub" + this.id:
        return data.subjectDisplay.toLowerCase();
      case "subjectSub" + this.id:
        return data.topic.toLowerCase();
    }
  };

  constructor(datas: InfoCommunication[], id: string) {
    super();
    this.data = datas;
    this.id = id;
  }
}
