<div class="container">
  <h2>{{ "forms.dataExtraction.title" | translate }}</h2>
  <mat-spinner *ngIf="isLoading"></mat-spinner>
  <div *ngIf="!isLoading">
    <div>
      <mat-label class="mr-1">{{ "forms.dataExtraction.exportFormat" | translate }} : </mat-label>
      <mat-radio-group [(ngModel)]="exportFormat">
        <mat-radio-button value="JSON" class="mr-1">JSON</mat-radio-button>
        <mat-radio-button value="CSV">CSV</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="mt-1" *ngFor="let selectedCareplan of selectedCareplans; let i = index">
      <mat-form-field class="mr-1 cp-form-field">
        <mat-label>{{ "forms.dataExtraction.cpChoice" | translate }}</mat-label>
        <mat-select required="true" [(ngModel)]="selectedCareplan.careplanId" (selectionChange)="loadCpObsAndQuests($event, i)">
          <mat-option
            *ngFor="let careplanOption of careplanOptions"
            [value]="careplanOption.careplan.support[0].reference + '+' + careplanOption.service.reference"
          >
            (
            {{ careplanOption.service.reference | getOrgNameFromServiceRef }}
            {{ careplanOption.service.display ? careplanOption.service.display : careplanOption.service.reference }}
            )
            {{ careplanOption.careplan.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mr-1 obsQuestSelect">
        <mat-label>{{ "forms.dataExtraction.obsChoice" | translate }}</mat-label>
        <mat-select
          [(ngModel)]="selectedCareplan.observations"
          multiple="true"
          [disabled]="selectedCareplan.careplanId && !availableObservations?.length"
        >
          <mat-option *ngFor="let obs of availableObservations?.length ? availableObservations[i]?.observations : []" [value]="obs.loinc">
            {{
              obs.shortnameTranslation
                ? (obs.shortnameTranslation | getTranslation : obs.loinc)
                : (obs.nameTranslation | getTranslation : obs.loinc)
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="obsQuestSelect">
        <mat-label>{{ "forms.dataExtraction.questChoice" | translate }}</mat-label>
        <mat-select
          [(ngModel)]="selectedCareplan.questionnaires"
          multiple="true"
          [disabled]="selectedCareplan.careplanId && !availableQuestionnaires?.length"
        >
          <mat-option
            *ngFor="let quest of availableQuestionnaires?.length ? availableQuestionnaires[i]?.questionnaires : []"
            [value]="quest.identifier + '/' + quest.version"
          >
            {{ quest.subjectType }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        mat-icon-button
        aria-label="icon button with a trash icon"
        matTooltip="{{ 'groupPermission.deleteAllSelected' | translate }}"
        (click)="removeSelectedCp(i)"
      >
        <mat-icon class="fail">delete</mat-icon>
      </button>
    </div>
    <button mat-raised-button (click)="addCp()">
      <mat-icon>add</mat-icon>
      {{ "forms.dataExtraction.addCp" | translate }}
    </button>
    <div class="mt-1">
      <mat-checkbox [(ngModel)]="patientData">{{ "forms.dataExtraction.patientData" | translate }}</mat-checkbox>
    </div>
    <div>
      <mat-checkbox
        [(ngModel)]="drugData"
        [disabled]="exportFormat !== 'JSON'"
        matTooltip="{{ 'forms.dataExtraction.drugDataDisabled' | translate }}"
        [matTooltipDisabled]="exportFormat === 'JSON'"
      >
        {{ "forms.dataExtraction.drugData" | translate }}
      </mat-checkbox>
    </div>
    <div>
      <mat-checkbox [(ngModel)]="alertData">{{ "forms.dataExtraction.alertData" | translate }}</mat-checkbox>
    </div>
    <div>
      <mat-checkbox [(ngModel)]="anonymizeDates">{{ "forms.dataExtraction.anonymizeDates" | translate }}</mat-checkbox>
    </div>
    <mat-form-field appearance="fill" class="mt-1 textarea">
      <mat-label>{{ "forms.dataExtraction.emailsToExclude" | translate }}</mat-label>
      <textarea matInput placeholder="demo.example@comunicare.be, demo.example2@comunicare.be" [(ngModel)]="emailsToExclude"></textarea>
    </mat-form-field>
    <div class="mt-1">
      <button class="export-button" mat-raised-button color="primary" (click)="exportData()" [disabled]="!selectedCareplans[0]?.careplanId">
        <mat-icon>file_download</mat-icon>
        {{ "observation.export" | translate }}
      </button>
    </div>
  </div>
</div>
