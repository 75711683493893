import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from "@angular/router";
import { SessionService } from "../providers/session.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private sessionService: SessionService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkLogin(state.url);
  }

  checkLogin(url: string) {
    if (this.sessionService.isLoggedIn) {
      return true;
    }

    // Store the attempted URL for redirecting
    this.sessionService.redirectUrl = url;

    return this.router.createUrlTree(["/login"]);
  }
}
