import { IKeyValue } from "./keyValues.interface";

/**
 * Simple Object to store Key Value pair
 */
export class KeyValue implements IKeyValue {
  public key: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public value: any;

  constructor(data: IKeyValue) {
    this.setKeyValue(data.key, data.value);
  }

  public setKeyValue(key: string, value: unknown): void {
    this.key = key;
    this.value = value;
  }
}
