import { Observable, of } from "rxjs";
import { MatTableDataSourceBackendExtended } from "src/app/helpers/datasource-backend";
import { FileLogger } from "src/app/helpers/fileLogger";
import { DataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { IQuestionnaireListInfo } from "src/app/models/questionnaire.interface";
import { QuestionnaireApiService } from "src/app/providers/api/questionnaires-api.service";
import { UserService } from "src/app/providers/user.service";

export class QuestionnairesDataSource extends MatTableDataSourceBackendExtended<IQuestionnaireListInfo> {
  constructor(private questionnairesApiService: QuestionnaireApiService, private userService: UserService) {
    super();
  }

  public data(params: DataSourceBackendParam): Observable<IQuestionnaireListInfo[]> {
    if (!this.userService.isAuthorizedSync(null, "dashboard/userQuestionnairesListInfos", "GET")) {
      FileLogger.error("questionnaires.datasource.ts", "Not Authorized to load questionnaires");
      return of([]);
    }
    return this.questionnairesApiService.findQuestionnaires(params) as Observable<IQuestionnaireListInfo[]>;
  }
}
