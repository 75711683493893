import * as moment from "moment";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { Appointment } from "src/app/models/appointment.model";

export class TeleconsultationsListDataSource extends MatTableDataSourceExtended<Appointment> {
  sortingDataAccessor = (data: Appointment, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "startDate":
        return moment(data.start).unix();
      case "endDate":
        return moment(data.end).unix();
      case "object":
        return data.description.toLowerCase();
      default:
        return "";
    }
  };

  constructor() {
    super();
  }

  public loadData(visios: Appointment[]): void {
    this.data = visios;
  }
}
