import { Pipe, PipeTransform } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Pipe({
  name: "getCareplanDescription",
})
export class GetCareplanDescriptionPipe implements PipeTransform {
  transform(careplanTemplateForm: UntypedFormGroup, lang: string): string {
    return careplanTemplateForm.get("description").value.traductions.find((trad) => trad.lang === lang)?.translateKey;
  }
}
