import { Pipe, PipeTransform } from "@angular/core";
import { IlinkableItem } from "../IlinkableItem.interface";

@Pipe({
  name: "filterByActivity",
})
export class FilterByActivityPipe implements PipeTransform {
  transform(items: IlinkableItem[], reference: string): unknown {
    return items.filter((item) => item.originReference?.reference === reference);
  }
}
