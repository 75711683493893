import { Component, EventEmitter, Input, Output } from "@angular/core";
import { INPUT_TYPE, QuestionQuestionnaire } from "src/app/models/questionnaire.interface";

@Component({
  selector: "app-text-input",
  templateUrl: "./text-input.component.html",
  styleUrls: ["./text-input.component.scss"],
})
export class TextInputComponent {
  @Input() disabled = false;
  @Input() set question(q: QuestionQuestionnaire) {
    this.textQuestion = q;
    this.setup();
  }
  @Output() questionChange = new EventEmitter<QuestionQuestionnaire>();

  textQuestion: QuestionQuestionnaire;
  isString = false;
  isText = false;
  placeholder: string;
  min = 1;
  max = 500;

  /**
   * Setup all the variables according to the inputs
   */
  private setup() {
    this.resetTypeOfInput();
    if (!this.textQuestion) {
      return;
    }
    this.setupTypeOfInput();
    this.placeholder = "";
    let needEmit = false;
    if (this.textQuestion.answerDisplay.placeholder) {
      this.placeholder = this.textQuestion.answerDisplay.placeholder;
    }
    if (this.textQuestion.answerDisplay.min) {
      this.min = Number(this.textQuestion.answerDisplay.min);
    } else {
      needEmit = true;
    }
    if (this.textQuestion.answerDisplay.max) {
      this.max = Number(this.textQuestion.answerDisplay.max);
    } else {
      needEmit = true;
    }
    this.onValuesChange(needEmit);
  }

  /**
   * Put all the variables indicating the type of input back to default.
   */
  private resetTypeOfInput() {
    this.isString = false;
    this.isText = false;
  }

  /**
   * Setup the variable indicating the type of input
   */
  private setupTypeOfInput() {
    // Note: we are using boolean var instead of just one string var
    // describing the type because it is prettier on the html side.
    switch (this.textQuestion.answerDisplay.type) {
      case INPUT_TYPE.TEXT:
        this.isText = true;
        break;
      case INPUT_TYPE.TEXTFIELD:
        this.isText = true;
        break;
      case INPUT_TYPE.STRING:
        this.isString = true;
        break;
      default:
        this.isText = true;
        break;
    }
  }

  /**
   * Called when the values of min, max, step or initial changed.
   * Update the variable depending on it and check that the values are correct.
   */
  public onValuesChange(needEmit = true): void {
    if (this.max === null || this.max === undefined) {
      this.max = 100000;
    }
    if (this.max < this.min) {
      this.max = this.min;
    }
    if (needEmit) {
      this.emitQuestionChanges();
    }
  }

  private emitQuestionChanges() {
    this.textQuestion.answerDisplay.placeholder = this.placeholder;
    this.textQuestion.answerDisplay.min = this.min.toString();
    this.textQuestion.answerDisplay.max = this.max.toString();
    this.questionChange.emit(this.textQuestion);
  }
}
