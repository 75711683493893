<h1 mat-dialog-title>
  {{ (isEditing ? "page.document.document" : "forms.document.titleImport") | translate }}
</h1>

<div class="container">
  <form [formGroup]="documentForm">
    <ui-alert [options]="{ type: 'warning', showIcon: true }" *ngIf="allArchived">
      {{ "page.document.allArchived" | translate }}
    </ui-alert>
    <section>
      <!-- NAME FIELD -->
      <mat-form-field>
        <input type="text" matInput placeholder="{{ 'forms.document.name' | translate }}" formControlName="name" />
      </mat-form-field>

      <!-- NAME CHIP (to indicate that a file is loaded) -->
      <mat-chip-list aria-label="Fish selection" class="mb-1" *ngIf="documentForm.get('name').value">
        <mat-chip>{{ documentForm.get("name").value }}</mat-chip>
      </mat-chip-list>

      <mat-form-field appearance="fill" [style]="allArchived ? 'display : none' : ''">
        <mat-label>{{ "page.document.docCategory.title" | translate }}</mat-label>
        <mat-select formControlName="docCategory">
          <mat-option *ngFor="let dc of docCategoryKeys" [value]="DocCategory[dc]">
            {{ "page.document.docCategory." + DocCategory[dc] | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    <!-- MonitoringService selection -->
    <section [style]="allArchived ? 'display : none' : ''">
      <mat-form-field appearance="fill" *ngIf="isMonitoringUser">
        <mat-label>{{ "common.monitoringService" | translate }}</mat-label>
        <mat-select formControlName="monitoringService">
          <mat-option *ngFor="let monitoringService of availableMonitoringServices" [value]="monitoringService.asReference.reference">
            {{ monitoringService.asReference.display }} ({{ monitoringService.providedBy.display }})
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Service selection -->
      <mat-form-field appearance="fill">
        <mat-label>{{ "common.service" | translate }}</mat-label>
        <mat-select formControlName="service">
          <mat-option *ngFor="let service of availableServices" [value]="service.reference">
            {{ service.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </section>
  </form>

  <ng-container *ngIf="docHistoric?.archived?.length">
    <h3>{{ "page.document.fileHistory" | translate }}</h3>
    <div class="table-container">
      <table class="table">
        <tr *ngFor="let d of docHistoric.archived">
          <td>{{ docHistoric.name }}</td>
          <td>{{ d.ownerDisplay }}</td>
          <td>{{ d.modified | date : "dd/MM/yyyy HH:mm" }}</td>
          <td>
            {{ "page.document.docCategory." + d.docCategory | translate }}
          </td>
          <td class="flex-end">
            <button mat-icon-button matTooltip="{{ 'btn.download' | translate }}" (click)="download(docHistoric.name, d.modified)">
              <mat-icon fontSet="fas" fontIcon="fa-download"></mat-icon>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </ng-container>
</div>

<mat-dialog-actions align="end">
  <ng-container *ngIf="!allArchived">
    <button mat-stroked-button mat-dialog-close matTooltip="{{ 'btn.cancel' | translate }}">
      {{ "btn.cancel" | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="isSaving">
      {{ (isEditing ? "btn.save" : "btn.import") | translate }}
      <ng-container *ngIf="isSaving">
        <mat-icon>
          <mat-spinner diameter="20"></mat-spinner>
        </mat-icon>
      </ng-container>
    </button>
  </ng-container>

  <button *ngIf="allArchived" mat-raised-button color="primary" mat-dialog-close matTooltip="{{ 'btn.close' | translate }}">
    {{ "btn.close" | translate }}
  </button>
</mat-dialog-actions>
