import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeHtml, SafeResourceUrl } from "@angular/platform-browser";
import { IAttachment, ICommunication } from "src/app/models/communications.interface";

@Component({
  selector: "app-communication-overview",
  templateUrl: "./communication-overview.component.html",
  styleUrls: ["./communication-overview.component.scss"],
})
export class CommunicationOverviewComponent implements OnInit {
  public safeHtml: SafeHtml;
  public getAttachements: IAttachment[];
  public attach: SafeResourceUrl = "";
  private communication: ICommunication;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { communication: ICommunication }, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.communication = this.data.communication;
    const htmlPayload = this.communication.payload.find((p) => p.contentString)?.contentString;
    this.getAttachements = this.communication.payload.filter((p) => p.contentAttachment).map((v) => v.contentAttachment);
    this.safeHtml = this.transform(htmlPayload);
  }

  public transform(content: string): SafeHtml {
    let newContent = `<style>img {max-width: 340px !important; margin: auto !important;} * {font-family: Roboto, "Helvetica Neue", sans-serif;}</style>`;
    if (this.communication.basedOn && this.communication.basedOn.findIndex((ref) => ref.type === "careplan") !== -1) {
      // show service name instead of practitioner name
      newContent += `<h3 style="text-align:center;">${this.communication.healthservice?.display}</h3>`;
    } else {
      newContent += `<h3 style="text-align:center;">${this.communication.sender?.display}</h3>`;
    }
    newContent += `<h4 style="text-align:center;">${this.communication.topic?.text}</h4>`;
    newContent += content;
    return this.sanitizer.bypassSecurityTrustHtml(newContent);
  }

  public openAttach(item: IAttachment): void {
    this.attach = this.sanitizer.bypassSecurityTrustResourceUrl(item.data);
  }

  public closeAttach(): void {
    this.attach = "";
  }
}
