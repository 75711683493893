import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DataType, Filter } from "src/app/models/filter.interface";
import { IDENTITY_VERIFICATION_METHOD, Identity, MODIFICATION_TYPE } from "src/app/models/patient.interface";
import { IdentityStatusChangeHistoricDataSource } from "./identity-status-change-historic-datasource";

@Component({
  selector: "app-identity-status-change-historic",
  templateUrl: "./identity-status-change-historic.component.html",
  styleUrls: ["./identity-status-change-historic.component.scss"],
})
export class IdentityStatusChangeHistoricComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataSource: IdentityStatusChangeHistoricDataSource;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public displayedColumns: string[] = [
    "identityChangeStatus",
    "verification",
    "verificationMethod",
    "modificationType",
    "periodStart",
    "periodEnd",
  ];
  public currentPageSize: number;
  public MODIFICATION_TYPE = MODIFICATION_TYPE;
  public IDENTITY_VERIFICATION_METHOD = IDENTITY_VERIFICATION_METHOD;
  private onDestroy$ = new Subject<void>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: { identity: Identity[] }) {}

  ngOnInit(): void {
    this.dataSource = new IdentityStatusChangeHistoricDataSource();
    this.dataSource.loadData(this.data.identity);
  }

  ngAfterViewInit(): void {
    this.initPaginatorAndSort();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public initPaginatorAndSort(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentPageSize = this.paginator.pageSize;
    });
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
  }

  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }
}
