import { TranslateService } from "@ngx-translate/core";
import { FHIRHelper } from "../helpers/FHIRhelper";
import { Tools } from "../helpers/tools";
import { Coding } from "./coding.interface";
import { Identifier } from "./identifier.interface";
import { SYSTEM_COMUNICARE, SYSTEM_TOPMD } from "./practitioner.model";
import { Reference } from "./reference.interface";
import {
  IBasis,
  IContext,
  IExplainers,
  IRiskAssessment,
  IRiskAssessmentPrediction,
  IShapValue,
  PredictionCode,
  SUMMARY,
  TopMD,
  monthlyBloodPressureStability,
} from "./riskAssessment.interface";
import { ACTION_STATUS_ENTITY, IIllustration, STATUS_ENTITY } from "./sharedInterfaces";

export class RiskAssessmentPrediction implements IRiskAssessmentPrediction {
  public outcome: { coding: Coding[] };
  public rationale: string;
  public probabilityDecimal: number;
  public order: number;
  public explainers: IExplainers;
  public version: string;

  constructor(data: IRiskAssessmentPrediction) {
    this.outcome = data.outcome;
    this.rationale = data.rationale;
    this.probabilityDecimal = data.probabilityDecimal;
    this.order = data.order;
    this.explainers = data.explainers;
    this.version = data.version;
  }

  public get outcomeCode(): string {
    return this.outcome.coding.find((c) => c.system === FHIRHelper.SYSTEM_COMUNICARE || c.system === FHIRHelper.SYSTEM_SNOMED)?.code;
  }

  public get outcomeDisplay(): string {
    return this.outcome.coding.find((c) => c.system === SYSTEM_COMUNICARE)?.display;
  }

  public get percent(): number {
    return Math.round(this.probabilityDecimal * 100);
  }
}

export class RiskAssessment implements IRiskAssessment {
  public subject: Reference;
  public issued: string;
  public identifier: Identifier[];
  public prediction: RiskAssessmentPrediction[];
  public _id: string;
  public creation?: string;
  public modified: string;
  public actionStatus?: ACTION_STATUS_ENTITY;
  public entityStatus: STATUS_ENTITY[];
  public illustrations: IIllustration[];
  public about?: Reference[];
  public context?: IContext;
  public basis?: IBasis[];

  constructor(data: IRiskAssessment) {
    this.subject = data.subject;
    this.issued = data.issued;
    this.identifier = data.identifier;
    this.prediction = data.prediction.map((p) => new RiskAssessmentPrediction(p)).sort((a, b) => a.order - b.order);
    this._id = data._id;
    this.creation = data.creation;
    this.modified = data.modified;
    this.actionStatus = data.actionStatus;
    this.entityStatus = data.entityStatus;
    this.illustrations = data.illustrations;
    this.about = data.about;
    this.context = data.context;
    this.basis = data.basis;
  }

  public get riskName(): string {
    return this.identifier.find((v) => v.system === SYSTEM_TOPMD)?.value
      ? this.identifier.find((v) => v.system === SYSTEM_TOPMD).value
      : this.identifier.find((v) => v.system === SYSTEM_COMUNICARE)?.value;
  }

  public static getRed(index: number, code: string, riskName: string, catNbr: number): number {
    if (code === PredictionCode.NO_DATA) {
      return 96;
    } else if (code === PredictionCode.NOT_ENOUGH_DATA) {
      return 160;
    } else {
      switch (riskName) {
        case monthlyBloodPressureStability:
          if (code === PredictionCode.NORMAL) {
            return 50;
          } else {
            return 230;
          }
        default:
          if (index === catNbr - 1 || index >= catNbr / 2) {
            return 230;
          } else if (index === 0) {
            return 50;
          } else {
            return (255 / catNbr) * (index + 1) + 20;
          }
      }
    }
  }

  public static getGreen(index: number, code: string, riskName: string, catNbr: number): number {
    if (code === PredictionCode.NO_DATA) {
      return 96;
    } else if (code === PredictionCode.NOT_ENOUGH_DATA) {
      return 160;
    } else {
      switch (riskName) {
        case monthlyBloodPressureStability:
          if (code === PredictionCode.NORMAL) {
            return 170;
          } else {
            return 50;
          }
        default:
          if (index === catNbr - 1) {
            return 50;
          } else if (index < catNbr / 2) {
            return 170;
          } else {
            return (170 / catNbr) * (index + 1);
          }
      }
    }
  }

  public static getBlue(index: number, code: string): number {
    if (code === PredictionCode.NO_DATA) {
      return 96;
    } else if (code === PredictionCode.NOT_ENOUGH_DATA) {
      return 160;
    } else {
      return 20;
    }
  }

  public getTooltipTranslated(translateService: TranslateService): string {
    return this.summary
      .map((s) =>
        s.probabilityDecimal
          ? `${translateService.instant(this.riskName + "." + s.outcomeCode)} : ${Math.round(s.probabilityDecimal * 100)}%`
          : ""
      )
      .join("\n");
  }

  public getTranslatedClassName(translateService: TranslateService, code: string): string {
    return translateService.instant(this.riskName + "." + code);
  }

  public get summary(): RiskAssessmentPrediction[] {
    return this.identifier.find((v) => v.system === SYSTEM_TOPMD)?.value
      ? this.prediction.filter((p) => p.rationale === TopMD)
      : this.prediction.filter((p) => p.rationale === SUMMARY);
  }

  public get allPredictions(): RiskAssessmentPrediction[] {
    return this.prediction.filter((p) => p.rationale !== SUMMARY);
  }

  public get classNameStr(): string[] {
    return this.summary.map((s) => s.outcomeDisplay);
  }

  public get classCode(): string[] {
    return this.summary.map((s) => s.outcomeCode);
  }

  public get availableAlgosKeys(): { rationale: string; version: string }[] {
    return Tools.uniqObject(
      this.allPredictions.map((p) => ({
        rationale: p.rationale,
        version: p.version,
      }))
    );
  }

  public get catNbr(): number {
    return this.summary?.length;
  }

  public getPredictionByKeyAndCode(key: string, version: string, code: string): RiskAssessmentPrediction {
    return this.prediction.filter((p) => p.rationale === key && p.version === version).find((p) => p.outcomeCode === code);
  }

  public getPredictionValueByKeyAndCode(key: string, version: string, code: string): number {
    return this.prediction.filter((p) => p.rationale === key && p.version === version).find((p) => p.outcomeCode === code)?.percent;
  }

  public getPredictionShapValuesByKeyAndCode(key: string, version: string, code: string): IShapValue[] {
    return this.prediction.filter((p) => p.rationale === key).find((p) => p.outcomeCode === code && p.version === version)?.explainers?.shap
      ?.shapValues;
  }

  public getPredictionBaseValueByKeyAndCode(key: string, version: string, code: string): number {
    return this.prediction.filter((p) => p.rationale === key && p.version === version).find((p) => p.outcomeCode === code)?.explainers?.shap
      ?.baseValue;
  }
}
