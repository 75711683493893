import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Tools } from "src/app/helpers/tools";
import { Contained } from "src/app/models/questionnaire.interface";
import { Reference } from "src/app/models/reference.interface";
import { QuestionnairesService } from "src/app/providers/questionnaires.service";
import { UserService } from "src/app/providers/user.service";
import { ValueSetDialogComponent } from "../value-set-dialog/value-set-dialog.component";

@Component({
  selector: "app-value-set-list-dialog",
  templateUrl: "./value-set-list-dialog.component.html",
  styleUrls: ["./value-set-list-dialog.component.scss"],
})
export class ValueSetListDialogComponent implements OnInit, OnDestroy {
  public templates: Contained[] = [];
  public delete = false;
  public valueSetsForm = this.fb.group({
    valueSets: [[], Validators.required],
  });
  get valueSets(): UntypedFormControl {
    return this.valueSetsForm.get("valueSets") as UntypedFormControl;
  }
  public canDelete = false;
  public canUpdate = false;
  private onDestroy$ = new Subject<void>();

  constructor(
    private questionnaireService: QuestionnairesService,
    private userService: UserService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ValueSetListDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      valueSets: Contained[];
      globalPermission: boolean;
      services: Reference[];
      orgsRefs: Reference[];
    }
  ) {}

  ngOnInit(): void {
    let sets = this.data.valueSets;
    if (!this.data.globalPermission) {
      sets = sets.filter((value: Contained) => value.useContext?.length && value.useContext[0].valueReference !== "all");
    }
    this.setupAuthorizations();
    if (!this.canUpdate && this.canDelete) {
      this.delete = true;
    }
    this.templates = sets;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onCancelClick(): void {
    this.valueSetsForm.reset();
    this.dialogRef.close([]);
  }

  onSaveClick(): void {
    if (this.valueSetsForm.invalid) {
      return null;
    }
    if (this.delete) {
      this.dialogRef.close(this.valueSets.value);
    } else {
      this.editTemplate(this.valueSets.value[0]);
    }
  }
  /**
   * Setup valueSets (answers options) templats' autorizations
   */
  private setupAuthorizations() {
    this.canUpdate =
      this.userService.isAuthorizedSync(null, "dashboard/valueSet/global", "PUT") ||
      this.userService.isAuthorizedSync(null, "dashboard/valueSet", "PUT");
    this.canDelete =
      this.userService.isAuthorizedSync(null, "dashboard/valueSet/global", "DELETE") ||
      this.userService.isAuthorizedSync(null, "dashboard/valueSet", "DELETE");
  }

  private editTemplate(template: Contained) {
    const dialogRef = this.dialog.open(ValueSetDialogComponent, {
      data: {
        valueSet: template,
        services: this.data.services,
        orgsRefs: this.data.orgsRefs,
        isCreate: false,
        globalPermission: this.data.globalPermission,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (newTemplate: Contained | null) => {
        if (newTemplate) {
          const templateCopy = Tools.clone(newTemplate);
          delete templateCopy.id;
          delete templateCopy.idSet;
          await this.questionnaireService.updateValueSet(templateCopy);
          this.dialogRef.close([]);
        }
      });
  }
}
