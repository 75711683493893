import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { Tools } from "src/app/helpers/tools";
import { QuestionQuestionnaire } from "src/app/models/questionnaire.interface";
import { GroupResponse } from "src/app/models/questionnaireResponse.interface";
import { QuestionnaireResponse } from "src/app/models/questionnaireResponse.model";

export class QRDetailsDataSource extends MatTableDataSourceExtended<QuestionQuestionnaire> {
  public responses: QuestionnaireResponse;
  public groups: GroupResponse[];
  public page: number;
  sortingDataAccessor = (data: QuestionQuestionnaire, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "name":
        return Tools.suppressDiacritics(data.text).toLowerCase();

      case "answer": {
        const question = this.groups?.[this.page]?.question?.find((q) => q.linkId === data.linkId);
        if (!question) {
          return "";
        }
        const answer = question?.answer?.[0]?.valueCoding?.display;
        if (!answer || answer === undefined) {
          return "";
        } else if (this.isNumber(answer)) {
          return Number(answer);
        } else {
          return Tools.suppressDiacritics(answer).toLowerCase();
        }
      }
      default:
        return "";
    }
  };

  constructor(responses: QuestionnaireResponse, groups: GroupResponse[]) {
    super();
    this.responses = responses;
    this.groups = groups;
  }

  public loadData(QR: QuestionQuestionnaire[], page: number): void {
    this.page = page;
    this.data = QR;
  }

  private isNumber(value: string | number): boolean {
    return value !== null && value !== "" && !isNaN(Number(value.toString()));
  }
}
