import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable, forkJoin } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { IPredictionStatus } from "src/app/models/riskAssessment.interface";
import { RiskAssessment } from "src/app/models/riskAssessment.model";
import { RiskAssessmentService } from "src/app/providers/risk-assessments-api.service";

interface RiskAssessmentState {
  loading: boolean;
  raDatas: (RiskAssessment | IPredictionStatus)[];
}

@Injectable()
export class RiskAssessmentStore extends ComponentStore<RiskAssessmentState> {
  constructor(private riskAssessmentService: RiskAssessmentService) {
    super({ loading: false, raDatas: [] });
  }

  readonly loading$ = this.select((state) => state.loading);
  readonly raDatas$ = this.select((state) => state.raDatas);

  readonly loadRiskAssessments = this.effect((trigger$: Observable<string>) =>
    trigger$.pipe(
      tap(() => this.patchState({ loading: true })),
      tap(() => this.patchState({ raDatas: [] })),
      switchMap((patientId: string) =>
        forkJoin([this.riskAssessmentService.listForPatient(patientId), this.riskAssessmentService.getPredictionState(patientId)]).pipe(
          tap(([riskAssessments, predictionStatus]) => {
            const combinedData = [...riskAssessments, ...predictionStatus];
            this.patchState({ raDatas: combinedData, loading: false });
          })
        )
      )
    )
  );

  readonly clearRiskAssessments = this.updater(() => ({ raDatas: [], loading: false }));
}
