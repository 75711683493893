import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { Tools } from "src/app/helpers/tools";
import { Methods } from "src/app/models/accessGroup.interface";
import { UserStatus } from "src/app/models/account.interface";
import { Opened_Status } from "src/app/models/communications.interface";
import { DataType, Filter } from "src/app/models/filter.interface";
import { KNOW_CATEGORY, KNOW_DOC_CATEGORY } from "src/app/models/knowledge.interface";
import { IDENTITY_STATUS, IDENTITY_VERIFICATION } from "src/app/models/patient.interface";
import { QuestionnaireStatus } from "src/app/models/questionnaire.interface";
import { RuleAlert } from "src/app/models/rule-alert.model";
import { UserEventSource } from "src/app/models/userStatistics.interface";
import { LanguagesService } from "src/app/providers/languages.service";
import { ChoiceFilterComponent } from "./filters/choice-filter/choice-filter.component";
import { DateFilterComponent } from "./filters/date-filter/date-filter.component";
import { TextFilterComponent } from "./filters/text-filter/text-filter.component";

@Component({
  selector: "app-item-filter",
  templateUrl: "./item-filter.component.html",
  styleUrls: ["./item-filter.component.scss"],
})
export class ItemFilterComponent {
  @Input() data: RuleAlert[];
  @Input() dataType: DataType = DataType.TEXT;
  @Input() fieldName: string;
  @Input() propertyName: string;
  @Input() defaultValue: Filter;
  @Input() translatePrefix: string;
  @Input() translateFieldKey: string;
  @Input() currentLang: string;
  @Output() applyFilter: EventEmitter<Filter> = new EventEmitter<Filter>();

  private choices: string[];
  private dialogClosed$: Subscription;

  constructor(private dialog: MatDialog, private languagesService: LanguagesService) {}

  public filter(): void {
    if (this.dataType === DataType.CHOICE) {
      this.choices = this.getChoices(this.propertyName);
    }
    let dialogComponent;
    switch (this.dataType) {
      case DataType.DATE:
        dialogComponent = DateFilterComponent;
        break;
      case DataType.CHOICE:
        dialogComponent = ChoiceFilterComponent;
        break;
      default:
      case DataType.TEXT:
        dialogComponent = TextFilterComponent;
        break;
    }

    if (this.dialogClosed$) {
      this.dialogClosed$.unsubscribe();
    }
    this.dialogClosed$ = this.dialog
      .open(dialogComponent, {
        data: {
          filter: { ...this.defaultValue },
          fieldName: this.fieldName,
          choices: this.choices,
          translatePrefix: this.translatePrefix,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((data) => {
        // If cross (X) is clicked to close the dialog, data is equal to an empty string otherwise it has undefined or a filter
        if (data || data === undefined) {
          this.applyFilter.emit({
            data,
            propertyName: this.propertyName,
            dataType: this.dataType,
            translateKey: this.translateFieldKey,
          });
        }
      });
  }

  private getChoices(propertyName: string): string[] {
    switch (propertyName) {
      case "status":
        return Tools.getValuesOfEnum(UserStatus).map((e) => e.toString());
      case "questionnaireStatus":
        return Tools.getValuesOfEnum(QuestionnaireStatus).map((e) => e);
      case "onlyPractitioner":
        return ["true", "false"];
      case "gender":
        return ["male", "female"];
      case "patientCustomInfo.gender":
        return ["male", "female"];
      case "requestStatus":
        return Tools.getDeleteRequestStatus().map((e) => e.toString());
      case "language":
        return Tools.getQuizLanguage(this.languagesService);
      case "method":
        return Methods;
      case "identityChangeStatus":
        return Tools.getValuesOfEnum(IDENTITY_STATUS).map((e) => e.toString());
      case "verification":
        return Tools.getValuesOfEnum(IDENTITY_VERIFICATION).map((e) => e.toString());
      case "eventSource":
        return Tools.getValuesOfEnum(UserEventSource).map((e) => e.toString());
      case "openedStatus":
        return Tools.getValuesOfEnum(Opened_Status).map((e) => e.toString());
      case "openStatusLocal":
        return Tools.getValuesOfEnum(Opened_Status).map((e) => e.toString());
      case "category":
        return Tools.getValuesOfEnum(KNOW_CATEGORY).map((e) => e.toString());
      case "type":
        return Tools.getValuesOfEnum(KNOW_DOC_CATEGORY).map((e) => e.toString());
      default: {
        const choices = this.data
          ?.map((ruleAlert: RuleAlert) => {
            return propertyName
              .split(".")
              .reduce((p, c) => (p && p[c] && ((p[c] instanceof Function && p[c]()) || p[c])) || null, ruleAlert);
          })
          .filter((value, index, self) => {
            return value && self.indexOf(value) === index;
          })
          .sort((a, b) => a - b);
        return choices;
      }
    }
  }
}
