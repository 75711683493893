<table class="tableChart">
  <thead>
    <tr>
      <th>{{ "table.date" | translate }}</th>
      <th *ngFor="let compo of headers">
        {{ compo | translateComponent : obsDefinition }}
        <br />{{ compo.valueQuantity.unit || "" }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let observation of observations; let iObs = index">
      <td>
        {{ observation.issued | date : (aggregateByDay ? "shortDate" : "short") }}
      </td>
      <td *ngFor="let headerCompo of headers; let i = index">
        <div
          style="display: flex; justify-content: center; align-items: center"
          *ngIf="(headerCompo | valueObservation : observation.component) !== null"
        >
          <mat-icon
            *ngIf="observation | isBluetoothObsComp : headerCompo?.code?.coding"
            style="font-size: 20px"
            [matTooltip]="observation?.device?.reference"
          >
            bluetooth
          </mat-icon>
          <span
            [style]="
              (headerCompo | getComponent : observation.component).parentObservation?.normColor
                ? 'color:' + (headerCompo | getComponent : observation.component).parentObservation.normColor
                : ''
            "
          >
            {{
              headerCompo?.code?.coding[0].code
                | loincAndValueMeaning
                  : headerCompo?.parentObservation?.code?.coding[0].code
                  : (headerCompo | valueObservation : observation.component)
                  : obsDefinition
                  : true
                  : true
                  : true
            }}
          </span>
          <span *ngIf="(headerCompo | getComponent : observation.component).effectiveTiming?.repeat?.when?.code">
            &nbsp;({{
              (headerCompo | getComponent : observation.component).effectiveTiming?.repeat?.when?.code
                | getEffectiveTiming
                  : (obsDefinition | findObsDefByLoinc : (headerCompo | getComponent : observation.component).parentObservation.code)
                | getTranslation : ""
            }})
          </span>
          <span>
            <mat-icon
              *ngIf="(headerCompo | getComponent : observation.component)?.valuePictures?.length > 0"
              class="pictureButton"
              (click)="showPictures(observation, headerCompo)"
            >
              camera_alt
            </mat-icon>
          </span>
          <span *ngFor="let alert of (headerCompo | getComponent : observation.component).parentObservation | getAlerts">
            <mat-icon
              *ngIf="alert.rule?.level === RULE_LEVEL.MEDIUM || alert.rule?.level === RULE_LEVEL.HIGH"
              class="custom-icon-small"
              [ngClass]="[alert.rule | alertLevelClass, alert.rule?.resultsPractitionner[0]?.value ? 'cursor-help' : '']"
              [matTooltip]="alert.rule?.resultsPractitionner[0]?.value | getTranslation : ''"
              >notifications</mat-icon
            ></span
          >
          <span>
            <mat-icon
              class="ml-05 pointer color-button"
              style="width: unset; height: unset"
              fontSet="fas"
              fontIcon="fa-history"
              *ngIf="((headerCompo | getComponent : observation.component).parentObservation | getOutdatedAlerts).length"
              [matTooltip]="'dialog.alertsHistory.title' | translate"
              (click)="openAlertHistory(headerCompo, observation.component)"
            ></mat-icon>
          </span>
        </div>
      </td>
    </tr>
  </tbody>
</table>
