import { DecimalPipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IObservationDefinition } from "src/app/models/observations.interface";
import { IStatistic } from "src/app/models/statObservation.interface";

@Component({
  selector: "app-stat-table",
  templateUrl: "./stat-table.component.html",
  styleUrls: ["./stat-table.component.scss"],
})
export class StatTableComponent implements OnInit {
  @Input() observationDefinition: IObservationDefinition;
  @Input() statistics: IStatistic[];

  public columnsToDisplay: string[] = ["name", "global"];
  public columnsNames = { name: this.translateService.instant("stat.title"), global: "Global" };
  public data: { [key: string]: string }[] = [];

  constructor(private translateService: TranslateService, private decimalPipe: DecimalPipe) {}

  ngOnInit(): void {
    this.setColumns();
    this.setRows();
  }

  setColumns(): void {
    for (const iterator of this.observationDefinition.lsTimingWhen) {
      this.columnsToDisplay.push(iterator.when.code);
      this.columnsNames[iterator.when.code] = iterator.name[this.translateService.currentLang];
    }
  }

  setRows(): void {
    for (const stat of this.statistics) {
      const row = { name: this.translateService.instant(stat.keyName) };
      for (const value of stat.values) {
        row[value.effectiveTimingCode ? value.effectiveTimingCode : "global"] =
          this.decimalPipe.transform(value.valueQuantity.value, "1.0-2") + (value.valueQuantity.unit ? " " + value.valueQuantity.unit : "");
      }
      this.data.push(row);
    }
  }
}
