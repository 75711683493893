import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UserService } from "src/app/providers/user.service";

@Component({
  selector: "app-media-selector-dialog",
  templateUrl: "media-selector-dialog.component.html",
})
export class MediaSelectorDialogComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  public isMngtAuthorized: boolean;

  constructor(public dialogRef: MatDialogRef<MediaSelectorDialogComponent>, public userService: UserService) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    this.userService
      .isAuthorized("dashboard/knowledge", "PUT")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        this.isMngtAuthorized = value;
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public goToDraft(): void {
    this.dialogRef.close("draft");
  }
  public goToPublished(): void {
    this.dialogRef.close("published");
  }
}
