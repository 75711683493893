import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ExportToCsv } from "export-to-csv";
import * as moment from "moment";
import { INPUT_TYPE } from "../models/questionnaire.interface";
import { CsvRow, IQuestionnaireList } from "../models/questionnaireList.interface";
import { IScoring } from "../models/questionnaireScoring.interface";
import { Scoring } from "../models/questionnaireScoring.model";
import { QRService } from "../providers/qr-api.service";

@Injectable({
  providedIn: "root",
})
export class QuestionnaireListCSVHelper {
  public options = {
    fieldSeparator: ";",
    filename: "",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    headers: [],
    showTitle: false,
    title: "",
    useBom: true,
    removeNewLines: true,
    useKeysAsHeaders: false,
  };

  constructor(private translateService: TranslateService, private qrService: QRService) {}

  public async exportCSV(data: IQuestionnaireList[], createFile: boolean): Promise<unknown[]> {
    const arrResponses: unknown[] = [];
    const questionnairesList: IQuestionnaireList[] = data;
    if (questionnairesList?.length === 0) {
      return;
    }
    const uniqueQuestionnairesName = [...new Set(questionnairesList.map((q) => q.questionnaireName))];
    const scoreLabel = this.translateService.instant("table.score");

    for (const questionnaireName of uniqueQuestionnairesName) {
      const questionnairesToExport = questionnairesList.filter((q) => q.questionnaireName === questionnaireName);

      const questionnaireids: string[] = questionnairesToExport.map((q) => q.questionnaireIdentifier);

      const scorings = await this.getScorings(questionnaireids);

      // Add Patient Headers
      const csvHeaders: string[] = [];
      csvHeaders.push(this.translateService.instant("model.patient.name"));
      csvHeaders.push(this.translateService.instant("model.patient.birthdate"));
      csvHeaders.push(this.translateService.instant("model.patient.gender"));

      // Add Questionnaire Headers
      csvHeaders.push(this.translateService.instant("model.patient.questionnaireDate"));
      csvHeaders.push(this.translateService.instant("model.patient.questionnaireName"));

      // Add Score Headers
      let scoresMap: Map<string, { score: number; interpretation: string }> = new Map<string, { score: number; interpretation: string }>();
      const scoresHeader: string[] = [];
      if (questionnairesToExport[0]?.questionnaireScore?.length > 0) {
        scoresMap = this.createScoresMap(scorings[0]);
        for (const label of scoresMap.keys()) {
          scoresHeader.push(label);
          csvHeaders.push(scoreLabel + "-" + label);
          csvHeaders.push(this.translateService.instant("pdf.questionnaire.scoreInterpretation"));
        }
      }

      // Add Questions Headers
      let questionsMap: Map<string, string> = new Map<string, string>();
      const questionsHeader: string[] = [];
      questionsMap = this.createQuestionsMap(scorings[0]);
      for (const label of questionsMap.keys()) {
        questionsHeader.push(label);
        csvHeaders.push(label);
      }

      this.options.title = questionnaireName;
      this.options.filename = questionnaireName;
      this.options.headers = csvHeaders;

      // Build Content

      const csvData: CsvRow[] = [];
      for (const questionnaireToExport of questionnairesToExport) {
        // Add patient data
        const name = questionnaireToExport.name + " " + questionnaireToExport.firstname;
        const birthDate = moment(questionnaireToExport.birthDate).format("DD/MM/YYYY");
        let gender: string;
        if (questionnaireToExport.gender) {
          gender = this.translateService.instant("choiceLabel.gender." + questionnaireToExport.gender);
        } else {
          gender = "-";
        }

        // Add questionnaire data
        const date = moment(questionnaireToExport.questionnaireDate).format("DD/MM/YYYY");
        const questName = questionnaireToExport.questionnaireName;

        const csvRow: CsvRow = {
          name,
          birthDate,
          gender,
          date,
          questName,
        };

        // Add score data
        const scoringPat = scorings.find(
          (s) => s?.questionnaireResponse.identifier.value === questionnaireToExport.questionnaireIdentifier
        );
        if (scoresHeader.length > 0) {
          scoresMap = this.createScoresMap(scoringPat);
          for (const header of scoresHeader) {
            csvRow[header] = scoresMap.get(header).score || scoresMap.get(header).score === 0 ? scoresMap.get(header).score : "-";
            csvRow.interpretation = scoresMap.get(header).interpretation ? scoresMap.get(header).interpretation : "-";
          }
        }

        // Add questions data
        if (questionsHeader.length > 0) {
          questionsMap = this.createQuestionsMap(scoringPat);
          for (const header of questionsHeader) {
            csvRow[header] = questionsMap.get(header) ? questionsMap.get(header) : "-";
          }
        }

        csvData.push(csvRow);
      }

      if (createFile) {
        new ExportToCsv(this.options).generateCsv(csvData);
      }
      arrResponses.push({ options: this.options, csvData });
    }
    return arrResponses;
  }

  private async getScoring(questionnaireId: string): Promise<Scoring> {
    return await this.qrService.getOneScoring(questionnaireId).toPromise();
  }

  private async getScorings(questionnaireIds: string[]): Promise<Scoring[]> {
    return await this.qrService.getScorings(questionnaireIds).toPromise();
  }

  private createScoresMap(scoring: IScoring): Map<string, { score: number; interpretation: string }> {
    const scoresMap: Map<string, { score: number; interpretation: string }> = new Map<string, { score: number; interpretation: string }>();
    for (const score of scoring.scoring) {
      const key = score.identifier?.label;
      const value = {
        score: score.scoringValue,
        interpretation: score.interpretationValue?.result[this.translateService.currentLang],
      };
      scoresMap.set(key, value);
    }
    return scoresMap;
  }

  private createQuestionsMap(scoring: IScoring): Map<string, string> {
    const questionsMap: Map<string, string> = new Map<string, string>();
    if (scoring) {
      for (const group of scoring.questionnaireResponse?.group?.group) {
        for (const question of group.question) {
          const key = question.text;
          let value: string;
          switch (question.inputType) {
            case INPUT_TYPE.NUMBER:
              value = question.answer[0].valueCoding.code;
              if (question.answer.length > 1) {
                value += " " + question.answer[1].valueCoding.code;
              }
              break;
            default:
              value = question.answer?.map((a) => a.valueCoding?.display ?? a.valueCoding?.code).join(", ");
              break;
          }
          questionsMap.set(key, value);
        }
      }
    }
    return questionsMap;
  }
}
