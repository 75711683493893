import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "EnumToArray" })
export class EnumToArrayPipe implements PipeTransform {
  /**
   * Create an array of numbers or strings based on the keys of a number enum.
   * If the enum contains 3 items, the return value is [0,1,2] or ['0','1','2'] depending on the type property
   * @param data
   * @param type
   * @returns
   */
  transform(data: unknown, type = "number"): number[] | string[] {
    let keys = Object.keys(data);
    if (type === "number") {
      keys = keys.slice(0, keys.length / 2);
      return keys.map((key) => Number(key));
    } else if (type === "string") {
      return keys.map((k) => data[k]);
    }
  }
}
