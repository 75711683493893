<form [formGroup]="organizationForm">
  <div class="close-cross">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <h2 mat-dialog-title *ngIf="isCreation">
    {{ "page.organizations.creatingTitle" | translate }}
  </h2>
  <h2 mat-dialog-title *ngIf="!isCreation">
    {{ "page.organizations.modifyingTitle" | translate }}
  </h2>

  <mat-dialog-content>
    <mat-form-field class="font-12">
      <input
        cdkFocusInitial
        type="text"
        matInput
        placeholder="{{ 'page.organizations.table.name' | translate }}"
        formControlName="name"
        autocomplete="off"
        pattern="[A-Za-zÀ-ú0-9\-\s']*"
      />
      <mat-error>
        {{ "forms.errors.nameFormat" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="font-12">
      <span matPrefix *ngIf="isCreation">Organization/</span>
      <input
        cdkFocusInitial
        type="text"
        matInput
        placeholder="{{ 'page.organizations.table.reference' | translate }}"
        formControlName="reference"
        autocomplete="off"
        pattern="[A-Za-z0-9\-]*"
      />
      <mat-error>
        {{ "forms.errors.ins.pattern" | translate }}
      </mat-error>
    </mat-form-field>
    <br />
    <mat-form-field
      class="font-12"
      *ngIf="canReadAccessGroups"
      [ngStyle]="{ 'padding-top': !organizationForm.get('name').valid ? '3%' : '0' }"
    >
      <mat-label>{{ "page.organizations.defaultAccessGroups" | translate }}</mat-label>
      <mat-select formControlName="defaultAccessGroups" multiple>
        <mat-option *ngFor="let group of allAccessGroups" [value]="group.identifier.value">
          {{ group.identifier.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <br />

    <mat-form-field class="font-12">
      <mat-label>{{ "page.organizations.type" | translate }}</mat-label>
      <mat-select formControlName="organizationType">
        <mat-option *ngFor="let type of OrganizationTypes | keyvalue" [value]="type.value">
          {{ "page.organizations." + type.value | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="organizationForm.get('organizationType').value === OrganizationTypes.PROV">
      <mat-form-field class="font-12">
        <mat-label>{{ "page.organizations.monitoringOrgsLink" | translate }}</mat-label>
        <mat-select formControlName="monitoringOrgsLink" multiple>
          <mat-option *ngFor="let monitOrg of monitoringOrgs" [value]="monitOrg.asReference">
            {{ monitOrg.organizationName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="font-12"
        *ngIf="organizationForm.get('monitoringOrgsLink') && organizationForm.get('monitoringOrgsLink').value.length > 0"
      >
        <mat-label>{{ "page.organizations.monitoringServicesLink" | translate }}</mat-label>
        <mat-select formControlName="monitoringServicesLink" multiple>
          <mat-option *ngFor="let monitService of monitoringServices" [value]="monitService.asReference">
            <span *ngIf="organizationForm.get('monitoringOrgsLink').value.length > 1"> ({{ monitService.providedBy.display }}) </span>
            {{ monitService.serviceName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <br />

    <mat-form-field class="font-12">
      <mat-icon matSuffix class="color-button">phone</mat-icon>
      <input type="phone" matInput placeholder="{{ 'forms.phone' | translate }}" formControlName="phone" autocomplete="off" />
      <mat-error *ngIf="organizationForm.get('phone').hasError('phoneValidator')">{{ "forms.checkPhoneFormat" | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field class="font-12">
      <input
        cdkFocusInitial
        type="text"
        matInput
        placeholder="{{ 'page.organizations.table.description' | translate }}"
        formControlName="phoneLabel"
        autocomplete="off"
      />
    </mat-form-field>

    <br />

    <mat-form-field class="font-12">
      <mat-icon matSuffix class="color-button">mail</mat-icon>
      <input type="mail" matInput placeholder="{{ 'forms.mail' | translate }}" formControlName="mail" autocomplete="off" />
      <mat-error>{{ "page.login.email-required" | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field class="font-12">
      <input
        cdkFocusInitial
        type="text"
        matInput
        placeholder="{{ 'page.organizations.table.description' | translate }}"
        formControlName="mailLabel"
        autocomplete="off"
      />
    </mat-form-field>

    <p style="padding-top: 3%">{{ "page.organizations.table.address" | translate }} :</p>
    <mat-grid-list cols="2" rowHeight="55px">
      <mat-grid-tile colspan="2" class="left-align">
        <mat-form-field class="font-12 field-max-width">
          <input
            cdkFocusInitial
            type="text"
            matInput
            placeholder="{{ 'page.organizations.table.line1' | translate }}"
            formControlName="addressLine1"
            autocomplete="off"
          />
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile colspan="2" class="left-align">
        <mat-form-field class="font-12 field-max-width">
          <input
            cdkFocusInitial
            type="text"
            matInput
            placeholder="{{ 'page.organizations.table.line2' | translate }}"
            formControlName="addressLine2"
            autocomplete="off"
          />
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile class="left-align">
        <mat-form-field class="font-12">
          <input
            cdkFocusInitial
            type="text"
            matInput
            placeholder="{{ 'page.organizations.table.zipCode' | translate }}"
            formControlName="addressCP"
            autocomplete="off"
            pattern="[A-Za-z0-9\-,\s]{0,10}"
          />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field class="font-12">
          <input
            cdkFocusInitial
            type="text"
            matInput
            placeholder="{{ 'page.organizations.table.city' | translate }}"
            formControlName="addressCity"
            autocomplete="off"
          />
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile class="left-align">
        <mat-select formControlName="addressCountry" placeholder="{{ 'page.organizations.table.country' | translate }}">
          <mat-option *ngFor="let country of availableCountries" [value]="country.countryCode">
            {{ "forms.country." + (country.countryCode | lowercase) | translate }}
          </mat-option>
        </mat-select>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button *ngIf="isCreation" color="primary" (click)="onSave()">
      {{ "page.organizations.btn.createOrg" | translate }}
    </button>
    <button mat-raised-button *ngIf="!isCreation" color="primary" (click)="onSave()">
      {{ "page.organizations.btn.updateOrg" | translate }}
    </button>
  </mat-dialog-actions>
</form>
