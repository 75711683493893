/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import * as d3 from "d3";
import { fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ChartHelper } from "src/app/helpers/chart-helper";
import { IStatistic, IStatsObsGroups } from "src/app/models/statObservation.interface";

@Component({
  selector: "app-stat-stacked-barplot-chart",
  templateUrl: "./stat-stacked-barplot-chart.component.html",
  styleUrls: ["./stat-stacked-barplot-chart.component.scss"],
})
export class StatStackedBarplotChartComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild("chartContainer") chartContainer: ElementRef<HTMLDivElement>;
  @Input() chartId: string; //loinc of obsdef
  @Input() statsObsGroups: IStatsObsGroups;

  private svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  public margin = 30;
  private width = 800 - this.margin * 2;
  private height = 400 - this.margin * 2;
  private data: d3.DSVRowArray;
  private x: d3.ScaleBand<string | number>;
  private y: d3.ScaleLinear<number, number, never>;

  public hasChartValues = false;

  /**
   * Represents the list of "column" names within the chart.
   * Each column name corresponds to a specific statistic event
   * */
  public columns: string[];
  public colors: string[] = [];
  public tooltip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;

  constructor() {
    // Ensure the correct context ('this') for event handler functions
    // Binding the context here ensures that 'this' inside these functions
    // refers to the current instance of the Angular component,
    // allowing access to component properties/methods like 'this.tooltip'
    this.mouseOverHandler = this.mouseOverHandler.bind(this);
    this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
    this.mouseLeaveHandler = this.mouseLeaveHandler.bind(this);
  }

  ngOnChanges(): void {
    this.processData(this.statsObsGroups.statistics);

    setTimeout(() => {
      if (this.hasChartValues && this.chartContainer) {
        this.createOrUpdateChart(false);
      }
    });
  }

  ngAfterViewInit(): void {
    this.createOrUpdateChart(true);
  }

  ngOnInit(): void {
    fromEvent(window, "resize")
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.createOrUpdateChart(true);
      });
  }

  /**
   * Initializes the chart by creating an SVG container and rendering necessary chart elements.
   * If chart data exists, it removes existing chart elements, creates an SVG container,
   * sets up axes, tooltips, and renders bars based on the provided chart data.
   *
   * If chart data is not available, the method returns without performing any rendering.
   */
  private async initChart() {
    if (!this.hasChartValues) {
      return;
    }

    // We make sure to remove the existing chart in case it already exists
    d3.select(`figure#B${this.chartId}`).selectAll("*").remove();

    if (this.width > 1000) {
      this.width = 1000;
    }
    this.svg = ChartHelper.createSvg(this.width, this.height, this.margin, `figure#B${this.chartId}`); // we add "B" because a css selector can't start with a number and A is taken by line charts

    await this.createAxis();
    await this.createTooltip();
    await this.createBars();
  }

  private createOrUpdateChart(resetWidth: boolean) {
    if (resetWidth && this.hasChartValues) {
      this.width = this.chartContainer.nativeElement.clientWidth - this.margin * 2;
    }
    this.initChart();
  }

  /**
   * Create Axis and append them to the svg
   */
  private async createAxis() {
    // List of groups value (moment key) of the first column called group and show it on x axis
    // (if no moment associated with the stat, we just don't name the stack of rectangles)
    const groups = d3
      .map(this.data, function (d) {
        return d.group;
      })
      .values();

    // Add X axis
    this.x = d3.scaleBand().domain(groups).range([0, this.width]).padding(0.2);
    this.svg
      .append("g")
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3.axisBottom(this.x).tickSizeOuter(0));

    // Add Y axis
    this.y = d3.scaleLinear().domain([0, 100]).range([this.height, 0]);
    this.svg.append("g").call(d3.axisLeft(this.y));
  }

  /**
   * Processes the statistical data to generate chart data for visualization.
   *
   * @param statistics - An array of statistical data.
   */
  public processData(statistics: IStatistic[]): void {
    const rawData = [];

    for (const statistic of statistics) {
      this.colors.push(statistic.baseColor);
      for (const value of statistic.values) {
        const effectiveTimingCode = value.effectiveTimingCode;
        const statEventKey = statistic.keyName;
        const valueQuantity = value.valueQuantity.value;
        /**
         * Represents statistical data for specific moments and their corresponding stat events.
         * 'group' contains the moment (effectiveTimingCode) if any,
         * while other keys represent the names of statEvents (key) and their associated valueQuantity (value).
         */
        let existingStatObject = rawData.find((obj) => obj.group === effectiveTimingCode);
        if (!existingStatObject) {
          existingStatObject = { group: effectiveTimingCode };
          rawData.push(existingStatObject);
        }
        existingStatObject[statEventKey] = valueQuantity.toString(); // Convert valueQuantity to a string
      }
    }

    // Convert rawData into a format compatible with D3
    this.data = new MyDSVRowArray(rawData);

    if (this.data.length) {
      this.hasChartValues = true;
    }
    // Extract column names representing 'statEvents' for chart rendering
    this.columns = this.data.columns.slice(1);
  }

  /**
   * Create bars for the chart.
   */
  private createBars() {
    // color palette = one color per stat
    const color = d3.scaleOrdinal().domain(this.columns).range(this.colors);
    //stack the data? --> stack per stat
    const stackedData = d3.stack().keys(this.columns)(this.data as any);
    // Show the bars
    this.svg
      .append("g")
      .selectAll("g")
      // Enter in the stack data = loop key per key = group per group (moment per moment)
      .data(stackedData)
      .join("g")
      .attr("fill", (d) => color(d.key) as string)
      .selectAll("rect")
      // enter a second time = loop subgroup per subgroup (stat by stat) to add all rectangles
      .data((d) => d)
      .join("rect")
      .attr("x", (d) => this.x(d.data.group as any))
      .attr("y", (d) => this.y(d[1]))
      .attr("height", (d) => this.y(d[0]) - this.y(d[1]))
      .attr("width", this.x.bandwidth())
      .on("mouseover", this.mouseOverHandler)
      .on("mousemove", this.mouseMoveHandler)
      .on("mouseleave", this.mouseLeaveHandler);
  }

  /**
   * Initialize the tooltip for the chart.
   */
  private createTooltip() {
    this.tooltip = d3
      .select(`figure#B${this.chartId}`)
      .append("div")
      .attr("class", "mat-tooltip mat-tooltip-handset")
      .style("white-space", "nowrap")
      .style("position", "fixed");
  }

  /**
   * Handles tooltip content on hover.
   * @param {MouseEvent} event - The mouse event.
   * @param {*} d - Data associated with the element.
   */
  private mouseOverHandler(event, d) {
    const subgroupName = (d3.select(event.currentTarget.parentNode).datum() as any).key;
    const subgroupValue = d.data[subgroupName];
    this.tooltip.html(subgroupName + " : " + subgroupValue).style("opacity", 1);
  }

  /**
   * Manages tooltip position on mouse movement.
   * @param {MouseEvent} event - The mouse event.
   */
  private mouseMoveHandler(event) {
    this.tooltip.style("left", event.x + "px").style("top", event.y + "px");
  }

  /**
   * Hides the tooltip when leaving a cell.
   */
  private mouseLeaveHandler() {
    this.tooltip.style("opacity", 0);
  }
}

export class MyDSVRowArray<Columns extends string = string> extends Array<d3.DSVRowString<Columns>> implements d3.DSVRowArray<Columns> {
  columns: Columns[];
  constructor(data: d3.DSVRowString<Columns>[]) {
    super(...data);
    this.columns = Object.keys(data[0] || {}) as Columns[];
  }
}
