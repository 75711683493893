import { Pipe, PipeTransform } from "@angular/core";
import { IPosition } from "../models/statObservation.interface";

export type GridPosition = {
  gridColumnStart: string | number;
  gridColumnEnd: string | number;
  gridRowStart: string | number;
  gridRowEnd: string | number;
};

@Pipe({ name: "positionStyle" })
export class PositionStylePipe implements PipeTransform {
  /**
   * Create a style Object to set a position on a Grid based on a IPosition
   */
  transform(position: IPosition): GridPosition {
    return {
      gridColumnStart: position?.columnStart || "",
      gridColumnEnd: position?.columnEnd || "",
      gridRowStart: position?.rowStart || "",
      gridRowEnd: position?.rowEnd || "",
    };
  }
}
