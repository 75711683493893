import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { RuleAlert } from "src/app/models/rule-alert.model";
import * as moment from "moment";
import { IActionAlert } from "src/app/models/rule-alert.interface";

export class AlertHistoricDataSource extends MatTableDataSourceExtended<IActionAlert> {
  public alert: RuleAlert;

  sortingDataAccessor = (data: IActionAlert, sortHeaderId: string) => {
    switch (sortHeaderId) {
      case "date":
        return moment(data.actionDate).unix();
      case "action":
        return data.action.toString().toLowerCase();
      case "author":
        return data.actionAuthor?.display.toLowerCase();
      case "comment":
        return (this.alert?.notes?.[data.commentId]?.text || "").toLowerCase();
      default:
        return "";
    }
  };

  constructor(alert: RuleAlert) {
    super();
    this.alert = alert;
  }

  public loadData(alert: RuleAlert) {
    this.data = alert?.actionsHistory;
  }
}
