import { TranslateService } from "@ngx-translate/core";
import uuid from "uuid-random";
import { FHIRHelper } from "../helpers/FHIRhelper";
import { Tools } from "../helpers/tools";
import {
  IAnswerDisplay,
  INPUT_TYPE,
  IQuestionnaire,
  IQuestionnaireParam,
  PAGING_MODE,
  QuestionQuestionnaire,
  QuestionnaireStatus,
  SPECIFIC_USE,
} from "./questionnaire.interface";
import { AccessLevel } from "./sharedInterfaces";

/**
 * Helper class for IQuestionnaire
 */
export class Questionnaire {
  /**
   * Return identifier for a reponse to a Questionnaire (Caremate Identifier + version)
   */
  public static getIdentifier4Response(q: IQuestionnaire): string {
    for (const ident of q.identifier) {
      if (ident.system === FHIRHelper.SYSTEM_COMUNICARE || ident.system === "caremate") {
        return ident.value + "/" + q.version;
      }
    }
    return null;
  }

  public static getIdentifier(q: IQuestionnaire): string {
    for (const ident of q.identifier) {
      if (ident.system === FHIRHelper.SYSTEM_COMUNICARE || ident.system === "caremate") {
        return ident.value;
      }
    }
    return null;
  }

  /**
   * Whether or not a questionnaire is "classic" (meaning: not a quiz nor a consent form)
   * @param q the questionnaire
   * @returns
   */
  public static isClassicQuestionnaire(q: IQuestionnaire): boolean {
    return !Tools.isDefined(q?.specificUse) || q?.specificUse === SPECIFIC_USE.NONE;
  }
  /**
   * Create a default questionnaire
   * @param specificUse (SPECIFIC_USE) the type of questionnaire
   * @param translateService (TranslateService)
   * @returns
   */
  public static createQuestionnaire(specificUse: SPECIFIC_USE, translateService: TranslateService): IQuestionnaire {
    const questionnaire: IQuestionnaire = {
      resourceType: "Questionnaire",
      questionByGroup: true,
      contained: [],
      identifier: [{ system: "http://comunicare.io", value: uuid() }],
      version: "fr",
      status: QuestionnaireStatus.DRAFT,
      date: new Date().toString(),
      publisher: "",
      telecom: [],
      subjectType: translateService.instant("model.patient.questionnaireName"),
      group: {
        linkId: "",
        title: translateService.instant("page.questionnaireEditor.questionnairePatientTitle"),
        concept: [],
        text: translateService.instant("page.questionnaireEditor.questionnaireDescription"),
        required: true,
        repeats: false,
        question: [],
        group: [
          {
            linkId: "",
            title: translateService.instant("page.questionnaireEditor.pageTitle"),
            concept: [],
            text: translateService.instant("page.questionnaireEditor.pageDescription"),
            required: true,
            repeats: false,
            question: [],
          },
        ],
      },
      paging: PAGING_MODE.GROUP,
      useContext: [
        {
          valueReference: "all",
          accessLevel: AccessLevel.WRITE,
        },
      ],
      observationLoincs: [],
    };
    if (specificUse === SPECIFIC_USE.CONSENT || specificUse === SPECIFIC_USE.QUIZ) {
      questionnaire.specificUse = specificUse;
      questionnaire.successThreshold = specificUse === SPECIFIC_USE.CONSENT ? 100 : 50;
      questionnaire.knowledgeId = "";
      questionnaire.mediaId = "";
    }
    return questionnaire;
  }

  public static createQuestion(
    itemType: INPUT_TYPE,
    specificUse: SPECIFIC_USE,
    id: number,
    translateService: TranslateService
  ): QuestionQuestionnaire {
    let show = 0;
    if (itemType === INPUT_TYPE.HAPPY_RANGE) {
      show = -1;
      itemType = INPUT_TYPE.RANGE;
    }
    const t = itemType as INPUT_TYPE;
    let type: INPUT_TYPE;
    switch (itemType) {
      case INPUT_TYPE.STRING:
      case INPUT_TYPE.TEXT:
        type = INPUT_TYPE.TEXT;
        break;
      case INPUT_TYPE.RANGE:
      case INPUT_TYPE.CHECKBOXES:
      case INPUT_TYPE.RADIO:
      case INPUT_TYPE.DROPDOWN:
      case INPUT_TYPE.RATING:
      case INPUT_TYPE.CHOICE:
        type = INPUT_TYPE.CHOICE;
        break;
      case INPUT_TYPE.TEXTFIELD:
        type = INPUT_TYPE.TEXTFIELD;
        break;
      case INPUT_TYPE.TEXTFIELD_HTML:
        type = INPUT_TYPE.TEXTFIELD_HTML;
        break;
      case INPUT_TYPE.NUMBER:
      case INPUT_TYPE.DECIMAL:
        type = INPUT_TYPE.NUMBER;
        break;
    }
    const question = {
      linkId: uuid(),
      concept: [],
      text: translateService.instant("page.questionnaireEditor.question") + " " + (id + 1),
      type,
      required: false,
      repeats: false,
      options: { reference: "", display: "" },
      option: [],
      answerDisplay: {
        type: t,
        showMethod: show,
        scaleReverseValue: false,
        min:
          t === INPUT_TYPE.RATING
            ? "3"
            : t === INPUT_TYPE.DATE
            ? null
            : t === INPUT_TYPE.TIME
            ? undefined
            : t === INPUT_TYPE.TEXT || t === INPUT_TYPE.TEXTFIELD || t === INPUT_TYPE.STRING
            ? "1"
            : "0",

        max: t === INPUT_TYPE.RATING ? "10" : t === INPUT_TYPE.DATE ? null : t === INPUT_TYPE.TIME ? undefined : "200",

        step: t === INPUT_TYPE.DATE ? null : 1,

        default:
          t === INPUT_TYPE.RATING
            ? "5"
            : t === INPUT_TYPE.DATE
            ? null
            : t === INPUT_TYPE.NUMBER || t === INPUT_TYPE.DECIMAL || t === INPUT_TYPE.RANGE
            ? "100"
            : undefined,
        displayIsShown: false,
      } as IAnswerDisplay,
    };
    if (specificUse === SPECIFIC_USE.CONSENT || specificUse === SPECIFIC_USE.QUIZ) {
      question.answerDisplay.goodAnswers = [];
      question.answerDisplay.textPass = "";
      question.answerDisplay.textFailed = "";
    }

    return question;
  }

  public static createParam(questionnaire: IQuestionnaire): IQuestionnaireParam {
    return {
      identifier: {
        system: questionnaire.identifier[0].value,
        value: questionnaire.subjectType,
      },
      version: questionnaire.version,
      frequency: {
        periodUnits: "d",
        frequency: 1,
        period: 1,
        when: null,
        timingCode: "M",
        boundsPeriod: {
          start: null,
          end: null,
        },
      },
      notify: false,
    };
  }
}
