import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Observable } from "rxjs";
import { Choice } from "../components/item-filter/filters/choice-filter/choice-filter.component";
import { FHIRHelper } from "../helpers/FHIRhelper";
import { MatriculeInsHelper } from "../helpers/matriculeInsHelper";
import { Tools } from "../helpers/tools";
import { PatientDataSourceBackendParam } from "../models/data-sources-backend-param.interface";
import { DataType, Filter } from "../models/filter.interface";
import { Identifier } from "../models/identifier.interface";
import { IPatientObservation } from "../models/observations.interface";
import { Organization } from "../models/organization.model";
import { AddPatientData, IPatientInfo, IPatientsListInfo, Patient, PatientUser } from "../models/patient.interface";
import { IConfiguration } from "../models/patientConfig.interface";
import { IPatientHistoric } from "../models/patientHistoric.interface";
import { Practitioner } from "../models/practitioner.model";
import { Reference } from "../models/reference.interface";
import { ITiming } from "../models/sharedInterfaces";
import { ApiService } from "./api/api.service";
import { PatientApiService } from "./api/patient-api.service";

@Injectable({
  providedIn: "root",
})
export class PatientService {
  dataTypeDate: unknown;

  constructor(private patientApi: PatientApiService, private translateService: TranslateService, private api: ApiService) {}

  public listRefsByHealthcareService(serviceId: string, services?: string[], monitoringServicesIds?: string[]): Observable<Reference[]> {
    return this.patientApi.listRefsByHealthcareService(serviceId, services, monitoringServicesIds);
  }

  public listPatientInfo(
    services: string[],
    criteria: string,
    limit?: number,
    monitoringServicesIds?: string[]
  ): Observable<IPatientInfo[]> {
    return this.patientApi.listPatientInfo(services, criteria, limit, monitoringServicesIds);
  }

  public findPatient(params: PatientDataSourceBackendParam): Observable<IPatientsListInfo[]> {
    params.filters = this.formatFilters(params.filters);
    return this.patientApi.findPatients(params);
  }

  public getPatientsCount(
    services: string[],
    search?: string,
    filters?: Filter[],
    withInactive?: boolean,
    groupId?: string,
    period?: string,
    monitoringServicesIds?: string[],
    insNumber?: string
  ): Observable<{
    count: number;
  }> {
    return this.patientApi.getPatientsCount(
      services,
      search,
      this.formatFilters(filters),
      withInactive,
      groupId,
      period,
      monitoringServicesIds,
      insNumber
    );
  }

  public update(patient: Patient, isAnonymous = false): Observable<Patient> {
    return this.patientApi.update(patient, isAnonymous);
  }

  public removeForEver(patientId: string): Observable<Patient> {
    return this.patientApi.removeForEver(patientId);
  }

  public disable(patient: Patient): Observable<unknown> {
    patient.active = false;
    return this.patientApi.deactivatePatient(patient._id, patient.disabledReason);
  }

  public activate(patient: Patient): Observable<Patient> {
    patient.active = true;
    return this.patientApi.activatePatient(patient._id);
  }

  public create(patientModalData: AddPatientData, reqParams: Record<string, string | boolean>): Observable<Patient> {
    return this.patientApi.create(patientModalData, reqParams);
  }

  public getInternalId(user: Patient): string | null {
    const org = user.managingOrganization.reference;
    return org ? user.identifier.find((id) => id.system === org)?.value : null;
  }

  public getInsurancelId(user: Patient): string {
    const ins = user.identifier.find((id) => id.use === FHIRHelper.SYSTEM_CONTACT_INSURANCE);
    return ins?.value;
  }

  public getSSIN(user: Patient): string {
    const SSIN = user.identifier.find((id) => id.system === FHIRHelper.SYSTEM_SOCIAL_SECURITY_IDENTIFIER_NUMBER);
    return SSIN?.value;
  }

  public getInsurance(user: Patient, availableInsurances: Organization[]): Organization {
    return availableInsurances.find((o) => FHIRHelper.getMainIdentifier(o)?.value === FHIRHelper.getInsIdentifier(user)?.system);
  }

  public getDoctor(user: Patient, availableDoctors: Practitioner[]): Practitioner | null {
    if (user.careProvider[0]) {
      return availableDoctors.find((p) => FHIRHelper.getMainIdentifier(p)?.value === user.careProvider[0]?.reference);
    }
    return null;
  }

  public getNurse(user: Patient, availableNurses: Practitioner[]): Practitioner | null {
    if (user.careProvider.length > 1 && user.careProvider[1]) {
      return availableNurses.find((p) => FHIRHelper.getMainIdentifier(p)?.value === user.careProvider[1]?.reference);
    }
    return null;
  }

  public getSecretary(user: Patient, availableSecretary: Practitioner[]): Practitioner | null {
    if (user.careProvider.length > 2 && user.careProvider[2]) {
      return availableSecretary.find((p) => FHIRHelper.getMainIdentifier(p)?.value === user.careProvider[2]?.reference);
    }
    return null;
  }

  public getPharmacist(user: Patient, availablePharmacist: Practitioner[]): Practitioner | null {
    if (user.careProvider.length > 2 && user.careProvider[3]) {
      return availablePharmacist.find((p) => FHIRHelper.getMainIdentifier(p)?.value === user.careProvider[3]?.reference);
    }
    return null;
  }

  public setCareProviders(
    patient: Patient,
    mainCareProvider: Reference,
    nurse: Reference,
    secretary: Reference,
    pharmacist: Reference
  ): void {
    patient.careProvider = [mainCareProvider, nurse, secretary, pharmacist];
  }

  public getPrefixFromGenderAndLang(Qdata: AddPatientData): string {
    const gender = Qdata.gender;
    const lang = Qdata.language;

    if (!gender) {
      return "";
    } else if (gender === FHIRHelper.GENDER_MALE) {
      return this.translateService.instant("forms.title.mister.short", lang);
    } else {
      return this.translateService.instant("forms.title.miss.short", lang);
    }
  }

  public setPreferredLang(patient: Patient, lang: string): void {
    const preferredIndex = patient.communication.findIndex((c) => c.preferred);

    if (preferredIndex >= 0 && patient.communication[preferredIndex].language.coding.length) {
      patient.communication[preferredIndex].language.coding[0].code = lang;
    }
  }

  public setMail(patient: Patient, mail: string): void {
    const mailIndex = patient.telecom.findIndex((t) => t.system === FHIRHelper.SYSTEM_TELECOM_MAIL);

    if (mailIndex >= 0) {
      patient.telecom[mailIndex].value = mail;
    }
  }

  public setPhone(patient: Patient, phone: string): void {
    const phoneIndex = patient.telecom.findIndex((t) => t.system === FHIRHelper.SYSTEM_TELECOM_PHONE);

    if (phoneIndex >= 0) {
      patient.telecom[phoneIndex].value = phone;
    }
  }

  public setInsu(patient: Patient, InsuID: string, insuRef: Reference): void {
    const insuIndex = patient.identifier.findIndex((id) => id.use === FHIRHelper.SYSTEM_CONTACT_INSURANCE);
    if (insuIndex >= 0) {
      if (insuRef) {
        patient.identifier[insuIndex].value = InsuID;
        patient.identifier[insuIndex].system = insuRef.reference;
        patient.identifier[insuIndex].label = insuRef.display;
      } else {
        patient.identifier.splice(insuIndex, 1);
      }
    } else if (patient.identifier && patient.identifier.length && insuRef) {
      patient.identifier.push({
        value: InsuID,
        system: insuRef.reference,
        label: insuRef.display,
        use: FHIRHelper.SYSTEM_CONTACT_INSURANCE,
      });
    }
  }

  public setSSIN(patient: Patient, SSIN: string): void {
    const SSINIndex = patient.identifier.findIndex((id) => id.system === FHIRHelper.SYSTEM_SOCIAL_SECURITY_IDENTIFIER_NUMBER);
    if (SSINIndex >= 0) {
      patient.identifier[SSINIndex].value = SSIN;
    } else if (patient.identifier && patient.identifier.length) {
      patient.identifier.push({
        value: SSIN,
        system: FHIRHelper.SYSTEM_SOCIAL_SECURITY_IDENTIFIER_NUMBER,
      });
    }
  }

  public setInternalId(patient: Patient, internalID: string): void {
    const internalIndex = patient.identifier.findIndex((id) => id.use === FHIRHelper.SYSTEM_ORGANIZATION);

    if (internalIndex >= 0) {
      patient.identifier[internalIndex].value = internalID;
      patient.identifier[internalIndex].system = patient.managingOrganization.reference;
      patient.identifier[internalIndex].label = patient.managingOrganization.display;
    } else if (patient.identifier && patient.identifier.length && patient.managingOrganization) {
      patient.identifier.push({
        value: internalID,
        system: patient.managingOrganization.reference,
        label: patient.managingOrganization.display,
        use: FHIRHelper.SYSTEM_ORGANIZATION,
      });
    }
  }

  /**
   * Invalidates current ins number and, if ins is non-empty, create an new "identifier ins"
   * @param patient
   * @param ins
   * @param oid
   */
  public setINS(patient: Patient, ins: string, oid: string): void {
    const INSIndex = patient.identifier.findIndex((id) => id.use === FHIRHelper.USE_MATRICULE_INS && Tools.isNotDefined(id.period.end));
    if (INSIndex >= 0) {
      patient.identifier[INSIndex].period.end = moment().format();
    }
    if (ins) {
      const identifierMatriculeIns = {
        use: FHIRHelper.USE_MATRICULE_INS,
        system: FHIRHelper.SYSTEM_MATRICULE_INS,
        value: ins,
        period: {
          start: moment().format(),
        },
      } as Identifier;
      if (oid) {
        identifierMatriculeIns.type = {
          code: oid,
          display: MatriculeInsHelper.computeDisplayOID(oid),
          system: FHIRHelper.SYSTEM_TYPE_MATRICULE_INS,
        };
      }
      patient.identifier.push(identifierMatriculeIns);
    }
  }

  public getPhone(patient: Patient): string {
    const phoneObj = patient.telecom.find((t) => t.system === FHIRHelper.SYSTEM_TELECOM_PHONE);
    return phoneObj ? phoneObj.value : null;
  }

  public getMail(patient: Patient): string {
    const mailObj = patient.telecom.find((t) => t.system === FHIRHelper.SYSTEM_TELECOM_MAIL);
    return mailObj ? mailObj.value : null;
  }

  public getFullname(patient: Patient): string {
    if (patient.name && patient.name.length > 0) {
      return `${patient.name[0].family.join(" ")} ${patient.name[0].given[0]}`;
    }
    return null;
  }

  public getSurname(patient: Patient): string {
    if (patient.name && patient.name.length > 0) {
      return patient.name[0].family.join(" ");
    }
    return null;
  }

  public getFirstname(patient: Patient): string {
    if (patient.name && patient.name.length > 0) {
      return patient.name[0].given[0];
    }
    return null;
  }

  public getPatientUser(caremateIdentifier: string): Observable<PatientUser> {
    return this.patientApi.getPatientUser(caremateIdentifier);
  }

  public getPatientUsers(caremateIdentifiers: string[]): Observable<PatientUser[]> {
    return this.patientApi.getPatientUsers(caremateIdentifiers) as Observable<PatientUser[]>;
  }

  public getPatientListInfo(params: PatientDataSourceBackendParam): Observable<IPatientsListInfo[]> {
    params.filters = this.formatFilters(params.filters);
    return this.patientApi.findPatients(params);
  }

  public getBirthdate(patient: Patient): string {
    if (moment(patient.birthDate).isValid()) {
      return moment(patient.birthDate).format("DD/MM/YYYY");
    } else {
      return "";
    }
  }

  public listInactivePatient(services: string[], monitoringServicesIds?: string[]): Observable<Patient[]> {
    return this.patientApi.listInactivePatient(services, monitoringServicesIds);
  }

  public getPatientHistoric(patientId: string, organizationId: string): Observable<IPatientHistoric[]> {
    return this.patientApi.getPatientHistoric(patientId, organizationId);
  }

  public computeAuthCode(patient: Patient): string | null {
    const exId = this.getInternalId(patient);
    return exId && patient.noSms ? this.api.serverPrefix + exId : null;
  }

  public getComunicareId(patient: Patient): string | null {
    return patient.identifier?.find((id) => id.system === FHIRHelper.SYSTEM_COMUNICARE)?.value;
  }

  public toReference(patientsUsers: PatientUser[]): Reference[] {
    return patientsUsers.map((v) => {
      return {
        reference: v.user.caremateIdentifier,
        display: this.getFullname(v.patient),
      };
    });
  }

  public formatFilters(filters: Filter[]): Filter[] {
    if (!filters?.length) {
      return [];
    }

    const formatedFilters: Filter[] = [];
    const unformattedFilters = Tools.clone(filters);
    if (unformattedFilters && unformattedFilters.length > 0) {
      unformattedFilters.forEach((filter) => {
        switch (filter.dataType) {
          case this.dataTypeDate:
            // format date params to string
            filter.data.fromDate = moment(filter.data.fromDate).format("YYYY-MM-DD");
            filter.data.toDate = moment(filter.data.toDate).format("YYYY-MM-DD");
            break;
          case DataType.CHOICE: {
            const values: Array<string> = [];
            const choices: Choice[] = filter.data ? filter.data : [];
            if (choices.length) {
              choices.forEach((choice) => {
                if (choice.checked) {
                  values.push(choice.value);
                }
              });
              filter.data = {
                value: values,
              };
            }
            break;
          }
        }
        formatedFilters.push(filter);
      });
    }
    return formatedFilters;
  }

  public getPatientObservationDefinitions(identifier: string, language?: string): Observable<IPatientObservation> {
    return this.patientApi.getPatientObservationDefinitions(identifier, language);
  }

  public updatePatientConfiguration(identifier: string, code: string[]): Observable<unknown> {
    return this.patientApi.updatePatientConfiguration(identifier, code);
  }
  public updatePatientObsParamsTiming(caremateId: string, obsLoinc: string, timing: ITiming): Observable<unknown> {
    return this.patientApi.updatePatientObsParamsTiming(caremateId, obsLoinc, timing);
  }
  public getPatientConfiguration(caremateId: string): Observable<IConfiguration> {
    return this.patientApi.getPatientConfiguration(caremateId);
  }
}
