import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import moment from "moment";
import { Subject, from, fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, first, takeUntil, tap } from "rxjs/operators";
import { QuestionnairesDataSource } from "src/app/careplan-editor/domain/careplan-editor-page/components/questionnaires-backend-table/questionnaires.datasource";
import { Choice } from "src/app/components/item-filter/filters/choice-filter/choice-filter.component";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { ACTION_TARGET } from "src/app/models/careplans.interface";
import { DataType, Filter } from "src/app/models/filter.interface";
import { IQuestionnaire, IQuestionnaireListInfo } from "src/app/models/questionnaire.interface";
import { IQuestionnaireScoring } from "src/app/models/questionnaireScoring.interface";
import { AccessLevel } from "src/app/models/sharedInterfaces";
import { QuestionnaireApiService } from "src/app/providers/api/questionnaires-api.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { QuestionnairesService } from "src/app/providers/questionnaires.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";

@Component({
  selector: "app-questionnaires-backend-table",
  templateUrl: "./questionnaires-backend-table.component.html",
  styleUrls: ["./questionnaires-backend-table.component.scss"],
})
export class QuestionnairesBackendTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("searchInput") searchInput: ElementRef;
  @ViewChild("searchClearBtn", { read: ElementRef }) searchClearBtn: ElementRef;

  @Input() dropListId?: string;
  @Input() dropListConnectedTo?: string;
  @Input() dropListSortingDisabled?: boolean;
  @Input() draggable = false;

  private onDestroy$ = new Subject<void>();
  public DATA_TYPE = DataType;
  public dataSource: QuestionnairesDataSource;
  public displayedColumns = ["subjectType", "actions"];
  public questionnairesCount: number;
  public filters: Filter[] = [];
  public loading: boolean;
  public canCreateQuestionnaire: boolean;
  public isFiltered = false;
  public filtersPropertyNames = { subjectType: "subjectType" };
  public action_target = ACTION_TARGET;
  public availableServices: string[];
  public AccessLevel = AccessLevel;
  constructor(
    private questionnaireApiService: QuestionnaireApiService,
    private questionnairesService: QuestionnairesService,
    private userService: UserService,
    private sessionService: SessionService,
    private healthcareService: HealthcareserviceService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.healthcareService
      .watchAvailableServices()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((service) => (this.availableServices = service.map((s) => s.asReference.reference)));

    if (this.draggable) {
      this.displayedColumns = ["grip"].concat(Tools.clone(this.displayedColumns));
    }
    this.dataSource = new QuestionnairesDataSource(this.questionnaireApiService, this.userService);
    this.canCreateQuestionnaire = this.userService.isAuthorizedSync(null, "dashboard/careplanTemplate", "POST");
  }

  ngAfterViewInit(): void {
    const searchEvent$ = fromEvent(this.searchInput.nativeElement, "keyup");
    const searchResetEvent$ = fromEvent(this.searchClearBtn.nativeElement, "click");

    // If we have a currentService, we do the first load. If not, the first load will be handle by the service watch (on page reload e.g.)
    if (this.sessionService.currentService) {
      from([1])
        .pipe(
          first(),
          tap(() => {
            this.paginator.pageIndex = 0;
            this.loadQuestionnaires();
          })
        )
        .subscribe();
    }

    // server-side search
    merge(searchEvent$, searchResetEvent$)
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadQuestionnaires();
        })
      )
      .subscribe();

    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => (this.paginator.pageIndex = 0));

    //  watch paginator and sort
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.loadQuestionnaires();
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    // watch Service change
    this.sessionService.refreshCurrentService
      .pipe(
        takeUntil(this.onDestroy$),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadQuestionnaires();
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public loadQuestionnaires(): void {
    const services = this.availableServices;

    this.dataSource.loadData({
      sortId: this.sort.active,
      sortOrder: this.sort.direction,
      pageNumber: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      filters: this.getFiltersFormated(),
      search: this.searchInput.nativeElement.value,
      orgsAndServicesRefs: services,
    });

    // update questionnaires count with search result
    this.questionnairesService
      .getQuestionnairesCount(this.searchInput.nativeElement.value, this.getFiltersFormated(), services)
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.questionnairesCount = result?.count;
      });
  }

  public applyFilter(filter: Filter, isInit = false): void {
    this.dataSource.setFilter(filter);
    if (!isInit) {
      this.paginator.pageIndex = 0;
    }
    this.loadQuestionnaires();
    this.isFiltered = this.filters && this.filters.length > 0;
  }

  public clearFilter(): void {
    this.searchInput.nativeElement.value = "";
    this.dataSource.clearFilter();
    this.paginator.pageIndex = 0;
    this.filters = [];
    this.isFiltered = false;
    this.loadQuestionnaires();
  }
  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  private getFiltersFormated(): Filter[] {
    const formatedFilters: Filter[] = [];
    const filters = Tools.clone(this.dataSource.getAllFilters());
    if (filters && filters.length > 0) {
      filters.forEach((filter) => {
        switch (filter.dataType) {
          case this.DATA_TYPE.DATE:
            // format date params to string
            filter.data.fromDate = moment(filter.data.fromDate).format("YYYY-MM-DD");
            filter.data.toDate = moment(filter.data.toDate).format("YYYY-MM-DD");
            break;
          case this.DATA_TYPE.CHOICE: {
            const values: Array<string> = [];
            const choices: Choice[] = filter.data ? filter.data : [];
            choices.forEach((choice) => {
              if (choice.checked) {
                values.push(choice.value);
              }
            });
            filter.data = {
              value: values,
            };
            break;
          }
        }
        formatedFilters.push(filter);
      });
    }
    this.filters = formatedFilters;
    return formatedFilters;
  }

  public editElement(q: IQuestionnaireListInfo): void {
    this.questionnairesService.editQuestionnaire(
      q.identifier[0].value,
      q.version,
      q.hasDraft,
      AccessLevel.WRITE, // if the user hasn't access to WRITE, the button is disabled in template
      this.router.url
    );
  }

  public async visualizeElement(q: IQuestionnaireListInfo): Promise<void> {
    let questionnaire: IQuestionnaire | undefined;
    try {
      questionnaire = await this.questionnairesService.getQuestDraftFirstOrActive(q.identifier[0].value, q.version);
    } catch (error) {
      FileLogger.error("QuestionnairesBackendTableComponent", "Error getting questionnaire with getQuestDraftFirstOrActive()", error);
      throw error;
    }
    let questionnaireScorings: IQuestionnaireScoring[] = [];
    if (q.hasDraft) {
      try {
        questionnaireScorings = await this.questionnairesService.getQuestionnaireScoringsDraft(q.identifier[0].value);
      } catch (error) {
        FileLogger.error(
          "QuestionnairesBackendTableComponent",
          "Error getting questionnaire scorings with getQuestionnaireScoringsDraft() ",
          error
        );
      }
    } else {
      try {
        questionnaireScorings = await this.questionnairesService.getQuestionnaireScorings(q.identifier[0].value);
      } catch (error) {
        FileLogger.error(
          "QuestionnairesBackendTableComponent",
          "Error getting questionnaire scorings with getQuestionnaireScorings() ",
          error
        );
      }
    }

    this.router.navigate(["/questionnaireEditor"], {
      state: {
        questionnaire,
        scorings: questionnaireScorings,
        visualization: true,
        userHighestAccessLevel: AccessLevel.READ,
        previousLocation: this.router.url,
      },
    });
  }
}
