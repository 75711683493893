import { Pipe, PipeTransform } from "@angular/core";
import { FileLogger } from "../helpers/fileLogger";

@Pipe({ name: "log" })
export class LogPipe implements PipeTransform {
  /**
   * Log value in the console. Intended for development only. Do not remove even if it seems not used.
   */
  transform(param1: unknown, param2?: string): void {
    FileLogger.log("LogPipe", param2 ? param2 : "", param1, "none");
    return;
  }
}
