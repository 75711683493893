import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, forkJoin } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { StatusEntity } from "src/app/models/entity.interface";
import { Group } from "src/app/models/group.model";
import { PatientUser } from "src/app/models/patient.interface";
import { Reference } from "src/app/models/reference.interface";
import { GroupService } from "src/app/providers/group-api.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";

@Component({
  selector: "app-add-to-group-dialog",
  templateUrl: "./add-to-group-dialog.component.html",
  styleUrls: ["./add-to-group-dialog.component.scss"],
})
export class AddToGroupDialogComponent implements OnInit, OnDestroy {
  public isChecked = false;
  public patients: Reference[] = this.data.list.map((p) => Tools.identifiers2MainReference(p.patient.identifier));
  public noCommonGroups = false;
  constructor(
    private groupService: GroupService,
    private dialogRef: MatDialogRef<AddToGroupDialogComponent>,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private sessionService: SessionService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA)
    public data: { list: PatientUser[]; isDelete?: boolean }
  ) {}

  public groupSelected: Group;
  public newGroupName: string;
  public isUpdate = false;
  public allGroups: Group[];
  public groupsToUpdate: Group[];
  private onDestroy$ = new Subject<void>();

  ngOnInit(): void {
    if (this.data.isDelete) {
      const currentService = this.userService.isMonitoringUser
        ? this.sessionService.currentMonitoringService
        : this.sessionService.currentService;
      this.groupService
        .list(currentService.reference)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((groups) => {
          this.allGroups = this.filteredGroups(groups, this.data.list);
          if (!this.allGroups.length) {
            this.noCommonGroups = true;
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public filteredGroups(groups: Group[], patientUsers: PatientUser[]): Group[] {
    const patientsRef = patientUsers.map((pu) => pu.user.caremateIdentifier);
    const arrGroups: Group[] = [];
    for (const group of groups) {
      let foundAll = true;
      for (const patient of patientsRef) {
        if (group.patients.findIndex((p) => p.reference === patient) === -1) {
          foundAll = false;
          break;
        }
      }
      if (foundAll) {
        arrGroups.push(group);
      }
    }
    return arrGroups;
  }

  public onDelete(): void {
    const patientsRef = this.data.list.map((pu) => pu.user.caremateIdentifier);
    this.groupsToUpdate.forEach((group) => {
      patientsRef.forEach((ref) => {
        group.patients = Tools.removePatientFromRef(group.patients, ref);
      });
    });
    const obsSave: Observable<Group>[] = [];

    this.groupsToUpdate.forEach((group) => {
      if (group.isValid) {
        obsSave.push(this.groupService.update(group));
      }
    });
    forkJoin(obsSave)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.close(true));
  }

  public onSave(): void {
    try {
      if (this.isUpdate) {
        this.updateGroup();
      } else {
        this.createGroup();
      }
    } catch (err) {
      FileLogger.error("AddToGroupDialogComponent", "onSave() failed", err);
    }
  }

  private close(success?: boolean) {
    this.dialogRef.close(success);
    const msg = this.translateService.instant("common.success");
    this.snackBar.open(msg, "ok", { duration: 3000 });
  }

  private createGroup() {
    const name = this.newGroupName;
    const currentService = this.userService.isMonitoringUser
      ? this.sessionService.currentMonitoringService
      : this.sessionService.currentService;
    const newGroup: Group = new Group({
      _id: null,
      modified: null,
      entityStatus: [StatusEntity.ACTIVE],
      groupName: name,
      caremateIdentifier: this.sessionService.account.caremateIdentifier,
      patients: this.patients,
      public: !this.isChecked,
      healthcareservice: currentService,
    });
    if (newGroup.isValid) {
      this.groupService
        .create(newGroup)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.close(true);
        });
    } else {
      throw new Error("Group is not valid");
    }
  }

  private updateGroup() {
    this.groupSelected.patients = this.uniq([...this.groupSelected.patients, ...this.patients]);
    this.groupSelected.public = !this.isChecked;
    if (this.groupSelected.isValid) {
      this.groupService
        .update(this.groupSelected)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.close(true);
        });
    } else {
      throw new Error("Group is not valid");
    }
  }

  private uniq(a: Reference[]) {
    return Array.from(new Set(a));
  }

  public groupIsSelected(group: Group): void {
    if (group.isValid) {
      this.groupSelected = group as Group;
      this.isChecked = !this.groupSelected.public;
      this.isUpdate = true;
    } else {
      this.newGroupName = group.groupName;
    }
  }

  public canUpdate(): boolean {
    return this.isUpdate && this.groupSelected?.caremateIdentifier === this.sessionService.account.caremateIdentifier;
  }
}
