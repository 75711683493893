import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { FileLogger } from "src/app/helpers/fileLogger";
import { QuestionnaireLinearPDFHelper } from "src/app/helpers/QuestionnaireLinearPDFHelper";
import { ITrValues, QuestionnairePDFHelper } from "src/app/helpers/QuestionnairePDFHelper";
import { QuestionnairePDFHelperCheckBox } from "src/app/helpers/QuestionnairePDFHelperCheckBox";
import { Tools } from "src/app/helpers/tools";
import { PatientUser } from "src/app/models/patient.interface";
import { IAnswerDisplay, INPUT_TYPE, IQuestionnaire } from "src/app/models/questionnaire.interface";
import { GroupResponse } from "src/app/models/questionnaireResponse.interface";
import { QuestionnaireResponse } from "src/app/models/questionnaireResponse.model";
import { QuestionnaireScoring, Scoring } from "src/app/models/questionnaireScoring.model";
import { QuestionnairesService } from "src/app/providers/questionnaires.service";
import { ToolsService } from "src/app/providers/tools.service";
import { UserStatisticsService } from "src/app/providers/userStatistics.service";

@Component({
  selector: "app-pdf-type-choice",
  templateUrl: "./pdf-type-choice.component.html",
  styleUrls: ["./pdf-type-choice.component.scss"],
})
export class PdfTypeChoiceComponent {
  constructor(
    private dialogRef: MatDialogRef<PdfTypeChoiceComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      scoring?: Scoring;
      pu?: PatientUser;
      questionnaire?: IQuestionnaire;
      groupsResponse?: GroupResponse[];
      mergedCategory?: QuestionnaireScoring[][];
      questionnaireResponse?: QuestionnaireResponse;
    },
    private questionnaireService: QuestionnairesService,
    private toolsService: ToolsService,
    private qPdfHelper: QuestionnairePDFHelper,
    private qLinearPdfHelper: QuestionnaireLinearPDFHelper,
    private qPdfHelperCheckBox: QuestionnairePDFHelperCheckBox,
    private snackBar: MatSnackBar,
    private statService: UserStatisticsService,
    private translateService: TranslateService
  ) {}

  public async onExportPDF(tabular: boolean): Promise<void> {
    const groups: GroupResponse[] = this.data.scoring
      ? this.data.scoring.questionnaireResponse?.group?.group?.length
        ? Tools.clone(this.data.scoring.questionnaireResponse?.group?.group)
        : Tools.clone([this.data.scoring.questionnaireResponse?.group])
      : this.data.groupsResponse;

    const values: ITrValues[] = [];

    try {
      const template = this.data.scoring
        ? await this.questionnaireService.getQuestionnaire(
            this.data.scoring.questionnaireResponse.ref,
            this.data.scoring.questionnaireResponse.version,
            false,
            true,
            this.data.scoring.questionnaireResponse?.questionnairePublicationDate
          )
        : this.data.questionnaire;

      if (template.questionByGroup) {
        const temp = this.qPdfHelperCheckBox.getConstructedTemplate(
          groups,
          template,
          moment(this.data.scoring ? this.data.scoring.date : this.data.questionnaireResponse.authored).format("DD-MM-YYYY HH:mm"),
          this.data.scoring ? this.getMergedScoringByCategory(this.data.scoring.scoring) : this.data.mergedCategory
        );
        this.toolsService.createPFDAndOpenInBrowser(this.data.pu, this.data.scoring?.name ?? this.data.questionnaire.subjectType, temp);
      } else {
        groups.forEach((group, i) => {
          if (group.group && group.group.length > 0) {
            group.group.forEach((g, iBis) => {
              g.question.forEach((q) => {
                values.push({
                  qid: q.linkId,
                  topic: group.title,
                  question: q.text,
                  answer: this.computeAnswerString(q.linkId, iBis, groups),
                  value: this.computeAnswerCode(q.linkId, iBis, groups),
                });
              });
            });
          } else {
            // questions are in group.group
            group.question.forEach((q) => {
              try {
                const answerDisplay = template.group.group[0].question.find((quest) => quest.linkId === q.linkId)?.answerDisplay;
                const inputType: INPUT_TYPE = answerDisplay?.type;
                const isExternalLink = inputType === INPUT_TYPE.EXTERNAL_LINK;

                const answer = isExternalLink
                  ? this.formatExternalLinkDisplay(answerDisplay)
                  : this.computeAnswerString(q.linkId, i, groups);

                const value = isExternalLink ? this.formatExternalLinkDisplay(answerDisplay) : this.computeAnswerCode(q.linkId, i, groups);

                values.push({
                  qid: q.linkId,
                  topic: group.title,
                  question: q.text,
                  answer,
                  value,
                });
              } catch (error) {
                FileLogger.error("PdfTypeChoiceComponent - onExportPDF", error);
              }
            });
          }
        });
        const htmlComputed = tabular
          ? this.qPdfHelper.getConstructedTemplate(
              values,
              moment(this.data.scoring?.questionnaireResponse.authored ?? this.data.questionnaireResponse.authored).format(
                "DD-MM-YYYY HH:mm"
              ),
              this.data.scoring ? this.getMergedScoringByCategory(this.data.scoring.scoring) : this.data.mergedCategory,
              template
            )
          : this.qLinearPdfHelper.getConstructedTemplate(
              values,
              moment(this.data.scoring?.questionnaireResponse.authored ?? this.data.questionnaireResponse.authored).format(
                "DD-MM-YYYY HH:mm"
              ),
              this.data.scoring ? this.getMergedScoringByCategory(this.data.scoring.scoring) : this.data.mergedCategory
            );

        this.toolsService.createPFDAndOpenInBrowser(
          this.data.pu,
          this.data.scoring?.name ?? this.data.questionnaire.subjectType,
          htmlComputed
        );
        if (this.data.scoring) {
          this.statService.createStatEvent(
            "Export questionnaire responses to PDF (" + this.data.scoring.questionnaireResponse.questionnaire.display + ")"
          );
        }
      }
    } catch (err) {
      FileLogger.error("PdfTypeChoiceComponent", "Error while getting questionnaire", err);
    }
    this.snackBar.open(this.translateService.instant("export.message"), "ok", { duration: 3000 });
  }

  private getMergedScoringByCategory(scorings: QuestionnaireScoring[]): QuestionnaireScoring[][] {
    const allCategories = this.uniq(scorings.map((s) => s.identifier?.value));
    const o = {};
    allCategories.forEach((cat) => (o[cat] = []));
    scorings.forEach((s) => {
      o[s.identifier?.value].push(s);
    });
    return Object.keys(o).reduce((acc, el) => {
      acc.push(o[el]);
      return acc;
    }, [] as QuestionnaireScoring[][]);
  }

  private uniq(a: string[]) {
    return Array.from(new Set(a));
  }

  private computeAnswerString(reference: string, pageNbr: number, groupResponse: GroupResponse[]): string {
    let answerString = "";
    const question = groupResponse?.[pageNbr]?.question?.find((q) => q.linkId === reference);
    if (question) {
      const answers = question.answer;
      answers?.forEach((a, i) => {
        answerString += a.valueCoding?.display ?? a.valueCoding?.code;
        if (i < answers.length - 1) {
          if ((question.inputType !== INPUT_TYPE.NUMBER && question.inputType !== INPUT_TYPE.DECIMAL) || (i > 0 && i % 2 !== 0))
            answerString += ", ";
          else answerString += " ";
        }
      });
    }
    return answerString;
  }

  private computeAnswerCode(reference: string, pageNbr: number, groupResponse: GroupResponse[]): string {
    let answerCode = "";
    const question = groupResponse?.[pageNbr]?.question?.find((q) => q.linkId === reference);
    if (
      question?.inputType === INPUT_TYPE.CHECKBOXES ||
      question?.inputType === INPUT_TYPE.NUMBER ||
      question?.inputType === INPUT_TYPE.DECIMAL
    ) {
      question?.answer?.forEach((a, i) => {
        if (i < question?.answer?.length - 1) {
          if (question.inputType !== INPUT_TYPE.NUMBER && question.inputType !== INPUT_TYPE.DECIMAL)
            answerCode += a.valueCoding?.code + ", ";
          else if (i > 0 && i % 2 !== 0) answerCode += ", ";
          else answerCode += a.valueCoding?.code;
        } else if ((question?.inputType !== INPUT_TYPE.NUMBER && question.inputType !== INPUT_TYPE.DECIMAL) || i % 2 === 0) {
          answerCode += a.valueCoding?.code;
        }
      });
    } else {
      const valueCoding = question?.answer?.[0]?.valueCoding;
      answerCode = isNaN(+valueCoding?.code) ? null : valueCoding.code;
    }
    return answerCode;
  }

  public formatExternalLinkDisplay(display: IAnswerDisplay): string {
    return display.default + (display.placeholder ? ` (${display.placeholder})` : "");
  }
}
