import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Subject } from "rxjs";
import { Tools } from "src/app/helpers/tools";
import { Contained, INPUT_TYPE, QuestionQuestionnaire } from "src/app/models/questionnaire.interface";

@Component({
  selector: "app-number-input",
  templateUrl: "./number-input.component.html",
  styleUrls: ["./number-input.component.scss"],
})
export class NumberInputComponent implements OnDestroy {
  @Input() lang: string;
  @Input() disabled = false;

  @Input() set question(q: QuestionQuestionnaire) {
    this.numberQuestion = q;
    this.setup();
  }
  @Output() questionChange = new EventEmitter<QuestionQuestionnaire>();

  @Input() set contained(value: Contained[]) {
    this.questionsOptions = value;
  }
  @Output() containedChange = new EventEmitter<Contained[]>();

  numberQuestion: QuestionQuestionnaire;
  questionsOptions: Contained[];
  currentIndexContained = -1;

  isNumber = false;
  isDecimal = false;
  placeholder: string;
  // Numeric answer values variable:
  min = 0;
  max = 100;
  initial = 0;
  step = 1;
  private onDestroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Setup all the variables according to the inputs
   */
  private setup() {
    this.resetTypeOfInput();
    if (!this.numberQuestion) {
      return;
    }
    this.setupTypeOfInput();
    if (this.numberQuestion.answerDisplay.min) {
      this.min = Number(this.numberQuestion.answerDisplay.min);
    }
    if (this.numberQuestion.answerDisplay.max) {
      this.max = Number(this.numberQuestion.answerDisplay.max);
    }
    if (this.numberQuestion.answerDisplay.default) {
      this.initial = Number(this.numberQuestion.answerDisplay.default);
    }
    if (this.numberQuestion.answerDisplay.step) {
      this.step = this.numberQuestion.answerDisplay.step;
    }
    this.placeholder = "";
    if (this.numberQuestion.answerDisplay.placeholder) {
      this.placeholder = this.numberQuestion.answerDisplay.placeholder;
    }
    if (!Tools.isDefined(this.numberQuestion.answerDisplay.multiInputs)) {
      this.numberQuestion.answerDisplay.multiInputs = false;
    }
    this.onValuesChange(false);
  }

  /**
   * Put all the variables indicating the type of input back to default.
   */
  private resetTypeOfInput() {
    this.isNumber = false;
    this.isDecimal = false;
  }

  /**
   * Setup the variable indicating the type of input
   */
  private setupTypeOfInput() {
    // Note: we are using boolean var instead of just one string var
    // describing the type because it is prettier on the html side.
    switch (this.numberQuestion.answerDisplay.type) {
      case INPUT_TYPE.NUMBER:
        this.isNumber = true;
        break;
      case INPUT_TYPE.DECIMAL:
        this.isDecimal = true;
        break;
      default:
        this.isNumber = true;
        break;
    }
  }

  /**
   * Called when the values of min, max, step or initial changed.
   * Update the variable depending on it and check that the values are correct.
   */
  public onValuesChange(needEmit = true): void {
    if (this.min > this.max) {
      this.min = this.max;
    }
    if (this.step < 1) {
      this.step = 1;
    }
    if (this.initial < this.min) {
      this.initial = this.min;
    }
    if (this.initial > this.max) {
      this.initial = this.max;
    }
    if (needEmit) {
      this.emitQuestionChanges();
    }
  }

  private emitQuestionChanges() {
    this.numberQuestion.answerDisplay.placeholder = this.placeholder;
    this.numberQuestion.answerDisplay.min = this.min?.toString();
    this.numberQuestion.answerDisplay.max = this.max?.toString();
    this.numberQuestion.answerDisplay.default = this.initial?.toString();
    this.numberQuestion.answerDisplay.step = this.step;
    this.questionChange.emit(this.numberQuestion);
  }

  public onUnitsChanges(contained: Contained[]): void {
    this.containedChange.emit(contained);
  }

  public onOptionsChanges(optionsRef: string): void {
    if (!this.numberQuestion) {
      return;
    }
    this.numberQuestion.options.reference = optionsRef;
    this.questionChange.emit(this.numberQuestion);
  }
}
