import { CdkDragDrop, CdkDragExit, copyArrayItem, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { IObservationDefinition } from "src/app/models/observations.interface";
import { IObservationParamWithObsDefinition } from "src/app/models/patientConfig.interface";
import { Questionnaire } from "src/app/models/questionnaire.mdel";
import { IQuestionnaireScoring } from "src/app/models/questionnaireScoring.interface";
import { AccessLevel, IEnableWhen } from "src/app/models/sharedInterfaces";
import { InputPropertiesComponent } from "src/app/pages/questionnaire-editor-page/components/input-properties/input-properties.component";
import { QuestionnairePropertiesComponent } from "src/app/pages/questionnaire-editor-page/components/questionnaire-properties/questionnaire-properties.component";
import { ObservationsService } from "src/app/providers/observations.service";
import { SessionService } from "src/app/providers/session.service";
import { environment } from "src/environments/environment";
import uuid from "uuid-random";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "../../components/confirmation-dialog/confirmation-dialog.component";
import {
  Contained,
  INPUT_TYPE,
  IQuestionnaire,
  IQuestionnaireParam,
  PAGING_MODE,
  QuestionQuestionnaire,
  QuestionnaireStatus,
  SPECIFIC_USE,
} from "../../models/questionnaire.interface";
import { QuestionnairesService } from "../../providers/questionnaires.service";
import { IDragItem, consentItems, dashboardOnlyItems, mobileItems, quizItems } from "./questionnaire-items";

@Component({
  selector: "app-questionnaire-editor-page",
  templateUrl: "./questionnaire-editor-page.component.html",
  styleUrls: ["./questionnaire-editor-page.component.scss"],
})
export class QuestionnaireEditorPageComponent implements OnInit, OnDestroy {
  public currentPage = 0;
  public currentQuestion = -1;

  public previousLocation: string;

  public questionnaire: IQuestionnaire;
  public questions: QuestionQuestionnaire[];
  public scorings: IQuestionnaireScoring[];
  public questionnaireParams: IQuestionnaireParam;
  public specificUse: SPECIFIC_USE;
  public QuestionnaireStatus = QuestionnaireStatus;
  public linkedObservations: IObservationDefinition[] = [];
  public withObservations = false;
  public knowledgeId: string;
  public isNewQuestionnaire = false;

  public properties = true;
  public questProperties = true;
  public pageProperties = false;
  public fieldProperties = false;
  public formId = "questProperties"; // default set to questProperties because it's the panel opening by default
  public viewType: "questionnaire" | "observations" = "questionnaire";
  public isQuestionnaireValid = false;
  public isLoading = true;
  public saveInterval: ReturnType<typeof setInterval>;
  public hasBeenEdited = false;
  public autoSaveActivated = false;
  public visualization = false;
  public AccessLevel = AccessLevel;
  public userHighestAccessLevel: AccessLevel;

  private oldScorings: IQuestionnaireScoring[];
  private action: string;
  private isProd: boolean;

  @ViewChild(InputPropertiesComponent)
  private inputPropertiesComponent: InputPropertiesComponent;
  @ViewChild(QuestionnairePropertiesComponent)
  private questPropertiesComponent: QuestionnairePropertiesComponent;

  public inputItems: IDragItem[] = [];
  public obsDefsItems: IDragItem[] = [];
  public obsFilter = "";
  private displayedObsDefs: IObservationDefinition[] = [];
  private allObsDefs: IObservationDefinition[] = [];
  private allObsDefsItems: IDragItem[] = [];
  private onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private questService: QuestionnairesService,
    private observationsService: ObservationsService,
    private sessionService: SessionService
  ) {
    if (this.router.getCurrentNavigation()?.extras?.state?.questionnaire) {
      this.questionnaire = this.router.getCurrentNavigation().extras.state.questionnaire;
      this.withObservations = this.questionnaire.observationLoincs?.length > 0;
      this.userHighestAccessLevel = this.sessionService.isAdmin()
        ? AccessLevel.WRITE
        : this.router.getCurrentNavigation()?.extras?.state?.userHighestAccessLevel;
      this.action = this.router.getCurrentNavigation()?.extras?.state?.action;
      this.scorings = this.router.getCurrentNavigation().extras.state.scorings;
      this.oldScorings = this.scorings ? Tools.clone(this.scorings) : undefined;
      if (!this.questionnaire.identifier?.length) {
        this.questionnaire.identifier = [{ system: "http://comunicare.io", value: uuid() }];
        this.isNewQuestionnaire = true;
      }
      if (this.withObservations) {
        this.downloadLinkedObservationsDefs();
      }
      switch (this.questionnaire.specificUse) {
        case SPECIFIC_USE.QUIZ:
          this.specificUse = SPECIFIC_USE.QUIZ;
          this.inputItems = quizItems;
          break;
        case SPECIFIC_USE.CONSENT:
          this.specificUse = SPECIFIC_USE.CONSENT;
          this.inputItems = consentItems;
          break;
        case SPECIFIC_USE.NONE:
        default:
          this.specificUse = SPECIFIC_USE.NONE;
          if (this.questionnaire.onlyPractitioner) {
            this.inputItems = dashboardOnlyItems;
          } else {
            this.inputItems = mobileItems;
          }
          break;
      }

      if (!Tools.isDefined(this.questionnaire.paging)) {
        if (
          this.questionnaire?.specificUse === SPECIFIC_USE.QUIZ ||
          this.questionnaire?.specificUse === SPECIFIC_USE.CONSENT ||
          (this.questionnaire.group.question && this.questionnaire.group.question.length > 0)
        ) {
          this.questionnaire.paging = PAGING_MODE.SINGLE;
        } else if (this.questionnaire.group.group && this.questionnaire.group.group.length > 0) {
          this.questionnaire.paging = PAGING_MODE.GROUP;
        }
      }
      this.initQuestions();
      this.makeQuestionnaireCompatible();
      this.getParams().then(() => {
        if (this.action === "copy") {
          this.duplicateQuestionnaire();
        }
        this.isLoading = false;
      });
    } else {
      switch (this.router.getCurrentNavigation()?.extras?.state?.specificUse) {
        case SPECIFIC_USE.QUIZ:
          this.specificUse = SPECIFIC_USE.QUIZ;
          this.inputItems = quizItems;
          if (this.router.getCurrentNavigation()?.extras?.state?.knowledgeId) {
            this.knowledgeId = this.router.getCurrentNavigation()?.extras?.state?.knowledgeId;
          }
          break;
        case SPECIFIC_USE.CONSENT:
          this.specificUse = SPECIFIC_USE.CONSENT;
          this.inputItems = consentItems;
          if (this.router.getCurrentNavigation()?.extras?.state?.knowledgeId) {
            this.knowledgeId = this.router.getCurrentNavigation()?.extras?.state?.knowledgeId;
          }
          break;
        case SPECIFIC_USE.NONE:
        default:
          this.specificUse = SPECIFIC_USE.NONE;
          this.inputItems = mobileItems;
          break;
      }
      this.createNewQuestionnaire();
      this.userHighestAccessLevel = AccessLevel.WRITE;
      this.isLoading = false;
    }
    this.visualization = this.router.getCurrentNavigation()?.extras?.state?.visualization;
    this.previousLocation = this.router.getCurrentNavigation()?.extras?.state?.previousLocation;
  }

  ngOnInit(): void {
    this.isProd = environment.production;
  }

  ngOnDestroy(): void {
    clearInterval(this.saveInterval);
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Load the questionnaire parameters
   */
  private async getParams(): Promise<void> {
    this.questionnaireParams = await this.questService.getParams(this.questionnaire.identifier[0].value);
    if (Questionnaire.isClassicQuestionnaire(this.questionnaire) && !this.questionnaireParams) {
      // If a questionnaire doesn't have a questionnaire param we need to add one
      this.questionnaireParams = Questionnaire.createParam(this.questionnaire);
    }
  }

  /**
   * Download the list of observations definitions linked to this questionnaire.
   */
  private downloadLinkedObservationsDefs(): void {
    const loincs = this.questionnaire?.observationLoincs?.map((o) => o.loinc);
    if (loincs?.length) {
      this.downloadAvailableObservationsDefs();
      this.observationsService
        .listDef(null, loincs)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (o: IObservationDefinition[]) => {
            this.linkedObservations = o.filter(ArrayHelper.onlyUniqueLoincObsDef);
            this.linkedObservations.forEach((obsDef) => {
              const matchingLoinc = this.questionnaire?.observationLoincs?.find((obsLoinc) => obsLoinc.loinc === obsDef.loinc);
              if (matchingLoinc) {
                obsDef.patientDefaultAccess = matchingLoinc.patientDefaultAccess;
              }
            });
          },
          (err) => {
            FileLogger.error("QuestionnaireEditorPAge", "Error while downloading observations definitions", err, "none");
            this.linkedObservations = [];
          }
        );
    } else {
      this.linkedObservations = [];
    }
  }

  /**
   * Download the list of observations definitions available to the user
   * @returns
   */
  private downloadAvailableObservationsDefs(): void {
    // We only download the obs def once:
    if (this.allObsDefs?.length > 0) return;
    this.observationsService
      .listParams(true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((paramsWithDef: IObservationParamWithObsDefinition[]) => {
        this.allObsDefs = paramsWithDef ? paramsWithDef.map((p) => p.observationdefinitions).filter(ArrayHelper.onlyUniqueLoincObsDef) : [];
        this.allObsDefs.sort((a: IObservationDefinition, b: IObservationDefinition) => {
          const aName: string = a.nameTranslation[this.translateService.currentLang] ?? a.nameTranslation.fr;
          const bName: string = b.nameTranslation[this.translateService.currentLang] ?? b.nameTranslation.fr;
          return aName.localeCompare(bName);
        });
        const obsDefsItems = this.allObsDefs.map((o) => {
          return {
            logo: "perm_data_setting",
            type: o.nameTranslation[this.translateService.currentLang] ?? o.nameTranslation.fr,
            haveTooltip: true,
            tooltipPosition: "",
            tooltipContent: o.loinc,
          };
        });
        this.allObsDefs.forEach((o) => (o.patientDefaultAccess = AccessLevel.NONE));
        this.displayedObsDefs = [...this.allObsDefs];
        this.allObsDefsItems = obsDefsItems;
        this.obsDefsItems = [...obsDefsItems];
      });
  }

  /**
   * Find the current page of questions we are working on and link them via
   * the "questions" variable
   */
  private initQuestions(): void {
    this.questions =
      this.questionnaire.paging === PAGING_MODE.GROUP
        ? this.questionnaire.group.group[this.currentPage].question
        : this.questionnaire.group.question;
  }

  public filterObs(): void {
    this.obsDefsItems = this.allObsDefsItems.filter((obs) => {
      return !this.obsFilter || obs.type.toLowerCase().includes(this.obsFilter.toLowerCase());
    });
    this.displayedObsDefs = this.allObsDefs.filter((o) => this.obsDefsItems.find((i) => i.tooltipContent === o.loinc));
  }

  /**
   * Create a default questionnaire
   */
  private createNewQuestionnaire() {
    this.questionnaire = Questionnaire.createQuestionnaire(this.specificUse, this.translateService);
    this.initQuestions();
    this.isNewQuestionnaire = true;
    this.questionnaireParams = Questionnaire.createParam(this.questionnaire);
  }

  /**
   * Update the page number and close the properties menu.
   * @param newPageId (number) the new page's number
   */
  public onPageChanged(newPageId: number): void {
    this.currentPage = newPageId;
    this.initQuestions();
    this.fieldProperties = false;
    this.pageProperties = false;
    this.questProperties = false;
    this.properties = false;
  }

  /**
   * Extract the current question from its page and put it on another page.
   * @param newPageId (number) the new page's on which we want to transfer the question
   */
  public onQuestionsPageChange(newPageId: number): void {
    if (newPageId >= 0 && newPageId < this.questionnaire.group.group.length) {
      // extract the question
      const question = this.questionnaire.group.group[this.currentPage].question.splice(this.currentQuestion, 1)[0];
      // put it on another page
      this.questionnaire.group.group[newPageId].question.push(question);
      this.hasBeenEdited = true;
    }
  }

  /**
   * Display the questionnaire's properties
   */
  public showQuestProperties(): void {
    this.fieldProperties = false;
    this.pageProperties = false;
    this.questProperties = true;
    this.properties = true;
    this.formId = "questProperties";
  }

  /**
   * Display or hide the properties of an input field
   * @param questionId (string) the linkId of the question we want to edit
   */
  public showFieldProperties(questionId: string): void {
    this.currentQuestion = this.questions.findIndex((q) => q.linkId === questionId);
    this.questProperties = false;
    this.pageProperties = false;
    this.fieldProperties = true;
    this.properties = true;
    this.formId = "fieldProperties";
  }

  /**
   * Display or hide the properties of the current page
   */
  public showPageProperties(): void {
    this.questProperties = false;
    this.fieldProperties = false;
    this.pageProperties = true;
    this.properties = true;
    this.formId = "pageProperties";
  }

  /**
   * Cancel the properties' modifications and close the menu.
   */
  public cancelProperties(): void {
    this.properties = false;
    this.questProperties = false;
    this.fieldProperties = false;
    this.pageProperties = false;
    // reset form id to default value
    this.formId = "questProperties";
    // Check if we need to display the observations options now:
    if (this.withObservations) {
      this.downloadAvailableObservationsDefs();
    }
  }

  /**
   * Remove a question from the list and delete its linked choices.
   * Then recompute the linkId of the other questions.
   * @param questionId (string) the linkId of the question we want to delete
   */
  public deleteQuestion(questionId: string): void {
    this.cancelProperties();
    const i = this.questions.findIndex((q) => q.linkId === questionId);
    // extract the question
    const question = this.questions.splice(i, 1)[0];
    if (question.options && question.options.reference !== "" && !this.doesOtherUseSameContained(question.options.reference)) {
      const k = this.questionnaire.contained.findIndex((contained) => contained.idSet === question.options.reference);
      if (k > -1) {
        this.questionnaire.contained.splice(k, 1);
      }
    }
    this.hasBeenEdited = true;
  }

  public deleteObservation(obsId: number): void {
    this.linkedObservations.splice(obsId, 1);
    this.hasBeenEdited = true;
  }

  /**
   * Check if several questions use the same valueSet (contained)
   * @param cId (string) the reference of the valueSet
   */
  private doesOtherUseSameContained(cId: string): boolean {
    const pages = this.questionnaire.paging === PAGING_MODE.GROUP ? this.questionnaire.group.group : [this.questionnaire.group];
    let nbQ = 0;
    for (const page of pages) {
      for (const question of page.question) {
        if (question.options && question.options.reference === cId) {
          nbQ += 1;
        }
      }
    }
    return nbQ > 1;
  }

  /**
   * Duplicate a question on the current page
   * @param questionId (string) the linkId of the question we want to duplicate
   */
  public duplicateQuestion(questionId: string): void {
    this.cancelProperties();
    const question = this.questions.find((q) => q.linkId === questionId);
    const newQuestion = JSON.parse(JSON.stringify(question)) as QuestionQuestionnaire;
    const i = this.questions.length;
    newQuestion.linkId = (i + 1).toString();
    if (question.options && question.options.reference !== "") {
      const contained = this.questionnaire.contained.find((c) => c.idSet === question.options.reference);
      if (contained) {
        const newContained = JSON.parse(JSON.stringify(contained)) as Contained;
        newContained.idSet = uuid();
        newContained.id = newContained.idSet;
        newQuestion.options.reference = newContained.idSet;
        this.questionnaire.contained.push(newContained);
      }
    }
    this.questions.push(newQuestion);
    this.scrollToLastQuestion();
    this.hasBeenEdited = true;
  }

  private scrollToLastQuestion() {
    setTimeout(() => {
      const questionnaireContainer = document.getElementById("questionnaireContainer");
      questionnaireContainer.scrollTo({
        top: questionnaireContainer.scrollHeight,
        behavior: "smooth",
      });
    }, 1);
  }

  /**
   * Check if all required fields in questionnaire properties are filled,
   * then save the questionnaire in the DB.
   */
  public async saveQuestionnaire(close: boolean, publish = false): Promise<void> {
    if (
      !this.questionnaire.useContext?.length ||
      (!this.questionnaire.useContext?.find((u) => u.accessLevel >= AccessLevel.WRITE) &&
        Questionnaire.isClassicQuestionnaire(this.questionnaire))
    ) {
      // At least one group must have access to this questionnaire in "write" level:
      this.showQuestProperties();
      this.dialog
        .open(ConfirmationDialogComponent, {
          data: {
            message: this.translateService.instant("page.questionnaireEditor.properties.needAccess"),
            type: ConfirmationDialogType.INFORMATION,
          },
        })
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.questPropertiesComponent.showErrors();
        });
    } else {
      if (publish) {
        this.dialog
          .open(ConfirmationDialogComponent, {
            data: {
              message: this.translateService.instant("page.questionnaireEditor.confirmSave." + this.specificUse),
              type: ConfirmationDialogType.CONFIRM,
            },
          })
          .afterClosed()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(async (yes) => {
            if (yes) {
              await this.saveMethod(close, publish);
            }
          });
      } else {
        await this.saveMethod(close, publish);
      }
    }
  }

  /**
   * Methode needed for compatibility with previous versions.
   */
  private removeAnswerDisplayForRadio() {
    if (this.questionnaire.paging === PAGING_MODE.GROUP) {
      for (const page of this.questionnaire.group.group) {
        for (const question of page.question) {
          if (
            question.answerDisplay &&
            (question.answerDisplay.type === INPUT_TYPE.CHOICE || question.answerDisplay.type === INPUT_TYPE.RADIO)
          ) {
            question.type = INPUT_TYPE.CHOICE;
            delete question.answerDisplay;
          }
        }
      }
    } else {
      for (const question of this.questions) {
        if (
          question.answerDisplay &&
          (question.answerDisplay.type === INPUT_TYPE.CHOICE || question.answerDisplay.type === INPUT_TYPE.RADIO)
        ) {
          question.type = INPUT_TYPE.CHOICE;
          delete question.answerDisplay;
        }
      }
    }
  }

  /**
   * Create a new question with a response input corresponding to the type
   * passed in parameters.
   * @param itemType (string) the type of the input we want to create
   */
  public createQuestionForQuestionnaire(itemType: INPUT_TYPE): void {
    const id = this.questions.length;
    const question = Questionnaire.createQuestion(itemType, this.specificUse, id, this.translateService);
    this.questions.push(question);
    if (itemType === INPUT_TYPE.CHECKBOXES || itemType === INPUT_TYPE.RADIO || itemType === INPUT_TYPE.DROPDOWN) {
      const cid = uuid();
      const startCode = 1;
      const defaultChoiceText = this.translateService.instant("page.questionnaireEditor.choice");
      this.questionnaire.contained.push(this.questService.generateValueSet(cid, startCode, defaultChoiceText));
      question.options.reference = cid;
    }
    this.scrollToLastQuestion();
    this.hasBeenEdited = true;
  }

  /**
   * Called when we drop a new input on the questionnaire or when we move a question
   * @param event the drop event
   */
  public dropInputItem(event: CdkDragDrop<IDragItem[]>): void {
    this.cancelProperties();
    if (event.previousContainer === event.container) {
      if (this.viewType === "questionnaire") {
        moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
      } else {
        moveItemInArray(this.linkedObservations, event.previousIndex, event.currentIndex);
      }
    } else if (this.viewType === "questionnaire") {
      const itemType = event.item.data.type;
      this.createQuestionForQuestionnaire(itemType);
      this.removeItemsInTransfer();
    } else if (this.viewType === "observations") {
      copyArrayItem(this.displayedObsDefs, this.linkedObservations, event.previousIndex, event.currentIndex);
      this.removeItemsInTransfer();
    }
    this.hasBeenEdited = true;
  }

  /**
   * Called when we re-enter the input list when we drag an input.
   */
  public removeItemsInTransfer(): void {
    const list = this.viewType === "questionnaire" ? this.inputItems : this.obsDefsItems;
    // Remove the temporary item we added to the list in order for it to stay pretty:
    const i = list.findIndex((b) => b.inTransfer === true);
    if (i > -1) list.splice(i, 1);
  }

  /**
   * Called when we exit the input list while dragging an input.
   * Usually it makes the input we are dragging disappear from the list,
   * which is not pretty. So here, we are adding a temporary input in order for
   * the list to stay pretty.
   * @param e the drag event
   */
  public exitedInputList(e: CdkDragExit<IDragItem>): void {
    const list = this.viewType === "questionnaire" ? this.inputItems : this.obsDefsItems;
    const i = list.findIndex((b) => b.type === e.item.data.type);
    // For some reason the next part bug when it's the observation list
    // so until someone figures out why, we will need this condition:
    if (i > -1 && this.viewType === "questionnaire") {
      const clone = Tools.clone(list[i]);
      clone.inTransfer = true;
      list.splice(i + 1, 0, clone);
    }
  }

  private makeQuestionnaireCompatible(): void {
    if (this.questionnaire) {
      if (this.questionnaire.group.group?.length) {
        for (const page of this.questionnaire.group.group) {
          for (const question of page.question) {
            if (question.type === INPUT_TYPE.NUMBER && !question.options) {
              question.options = { reference: "", display: "" };
              question.answerDisplay = {
                type: INPUT_TYPE.NUMBER,
              };
            }
          }
        }
      } else if (this.questionnaire.group.question?.length) {
        for (const question of this.questionnaire.group.question) {
          if (question.type === INPUT_TYPE.NUMBER && !question.options) {
            question.options = { reference: "", display: "" };
            question.answerDisplay = {
              type: INPUT_TYPE.NUMBER,
            };
          }
        }
      }
    }
  }

  /**
   * Remove the page and its content from the questionnaire
   * @param pageId (number) the index of the page we want to remove
   */
  public removePage(pageId: number): void {
    if (pageId < 0 || pageId >= this.questionnaire.group.group.length || this.questionnaire.group.group.length < 1) {
      return;
    }
    this.questionnaire.group.group.splice(pageId, 1);
    this.currentPage -= 1;
    this.onPageChanged(this.currentPage);
    this.hasBeenEdited = true;
  }

  /**
   * Create a new page (= group) in the questionnaire
   */
  public createNewPage(index: number): void {
    this.questionnaire.group.group.push({
      linkId: "",
      title: this.translateService.instant("page.questionnaireEditor.properties.pageTitle"),
      concept: [],
      text: this.translateService.instant("page.questionnaireEditor.properties.pageDescription"),
      required: true,
      repeats: false,
      question: [],
    });

    this.onPageChanged(index);
    this.hasBeenEdited = true;
  }

  // TODO : temporary solution to keep InputProperties working before completion of CMATE-3096

  /**
   * Apply the modifications to the element and close the menu.
   */
  public applyProperties(): void {
    if (this.fieldProperties && this.inputPropertiesComponent.isValid()) {
      this.properties = false;
      this.fieldProperties = false;
      this.inputPropertiesComponent.applyChanges();
      this.hasBeenEdited = true;
    } else if (!this.fieldProperties) {
      this.properties = false;
    }
    if (this.questProperties) {
      if (this.questionnaire.onlyPractitioner && Questionnaire.isClassicQuestionnaire(this.questionnaire)) {
        this.inputItems = dashboardOnlyItems;
      } else {
        switch (this.questionnaire.specificUse) {
          case SPECIFIC_USE.QUIZ:
            this.specificUse = SPECIFIC_USE.QUIZ;
            this.inputItems = quizItems;
            break;
          case SPECIFIC_USE.CONSENT:
            this.specificUse = SPECIFIC_USE.CONSENT;
            this.inputItems = consentItems;
            break;
          case SPECIFIC_USE.NONE:
          default:
            this.specificUse = SPECIFIC_USE.NONE;
            if (this.questionnaire.onlyPractitioner) {
              this.inputItems = dashboardOnlyItems;
            } else {
              this.inputItems = mobileItems;
            }
            break;
        }
      }
    }
  }
  // END OF TODO

  /**
   * Duplicate a questionnaire
   */
  public duplicateQuestionnaire(): void {
    delete this.questionnaire._id;
    this.questionnaire.identifier[0].value = uuid();
    this.questionnaire.group.group.forEach((g) => {
      g.question.forEach((q) => {
        const generatedUuid = uuid();
        const foundEnableWhens: IEnableWhen[][] = [];
        for (const quest of g.question) {
          foundEnableWhens.push(quest?.enableWhen?.filter((ew) => ew.question === q.linkId));
        }
        if (foundEnableWhens?.length) {
          foundEnableWhens.forEach((ews: IEnableWhen[]) => {
            if (ews?.length) {
              ews.forEach((ew) => {
                ew.question = generatedUuid;
              });
            }
          });
        }
        q.linkId = generatedUuid;
      });
    });
    this.questionnaire.contained.forEach((c) => {
      delete c._id;
      const newUuid = uuid();
      if (this.questionnaire.group.question?.length) {
        // Old questionnaires still have that
        this.questionnaire.group.question.forEach((q) => {
          if (q?.options?.reference === c.id) {
            q.options.reference = newUuid;
          }
        });
      } else if (this.questionnaire.group.group?.length) {
        this.questionnaire.group.group.forEach((g) => {
          const questions = g.question.filter((q) => q?.options?.reference === c.id);
          if (questions.length) {
            questions.forEach((q) => {
              q.options.reference = newUuid;
            });
          }
        });
      }
      c.id = newUuid;
      c.idSet = newUuid;
    });
    this.questionnaire.date = moment().format();
    if (this.questionnaire.publisher) {
      this.questionnaire.publisher = "";
    }
    if (!this.questionnaire?.specificUse || this.specificUse === SPECIFIC_USE.NONE) {
      if (this.questionnaireParams) {
        delete this.questionnaireParams._id;
        this.questionnaireParams.identifier.system = this.questionnaire.identifier[0].value;
      } else {
        this.questionnaireParams = Questionnaire.createParam(this.questionnaire);
      }
    }
  }

  /**
   * Auto save questionnaire change every minute
   * @param $event checkbox change
   */
  public autoSave($event: MatCheckboxChange): void {
    this.autoSaveActivated = $event.checked;
    if (this.isProd) {
      return;
    }
    if (this.saveInterval && !$event.checked) {
      clearInterval(this.saveInterval);
    } else if ($event.checked) {
      this.saveInterval = setInterval(async () => {
        if (!this.hasBeenEdited) {
          return;
        }
        await this.saveMethod(false);
      }, 60000);
    }
  }

  /**
   * Method called after we've done a first check on the questionnaire validity
   * and asked the user if he's sure he want to save
   * @param close whether or not we want to quit the page after saving
   * @param publish whether or not we want to publish the questionnaire after saving the modifications
   * @returns
   */
  private async saveMethod(close: boolean, publish = false): Promise<void> {
    let success = false;
    if (Questionnaire.isClassicQuestionnaire(this.questionnaire)) {
      // if classic questionnaire
      this.removeAnswerDisplayForRadio();
    }
    if (this.withObservations) {
      this.questionnaire.observationLoincs = [];
      this.linkedObservations.forEach((linkedObs) => {
        this.questionnaire.observationLoincs.push({
          loinc: linkedObs.loinc,
          patientDefaultAccess: linkedObs.patientDefaultAccess,
        });
      });
    } else {
      this.questionnaire.observationLoincs = [];
    }

    // Updating draft
    if (this.questionnaire._id) {
      this.questionnaire = await this.questService.updateQuestionnaireDraft(this.questionnaire, this.scorings, this.oldScorings);
      this.initQuestions();
      success = this.questionnaire ? true : false;
    }
    // Creating new draft (can be a new draft for an existing published questionnaire or a new draft for a new questionnaire)
    else {
      // -- For quiz or consent
      if (this.specificUse === SPECIFIC_USE.CONSENT || this.specificUse === SPECIFIC_USE.QUIZ) {
        this.questionnaire = await this.questService.createSpecificQuestionnaireDraft(this.questionnaire);
        this.initQuestions();
        success = this.questionnaire ? true : false;
      }
      // -- For classic questionnaire
      else {
        const newQuest = await this.questService.createQuestionnaireDraft(this.questionnaire, this.scorings);
        if (!newQuest) {
          FileLogger.error("QuestionnaireEditorPage", "No new questionnaire received", null, "none");
          return;
        }
        this.questionnaire = newQuest;
        this.initQuestions();
        success = this.questionnaire ? true : false;
        // Totally new questionnaire:
        if (this.isNewQuestionnaire) {
          this.questionnaireParams = await this.questService.createParams(this.questionnaireParams);
          success = this.questionnaireParams ? success : false;
        }
      }
    }
    if (success) {
      this.oldScorings = this.scorings ? Tools.clone(this.scorings) : undefined;
      this.hasBeenEdited = false;
      if (publish) {
        const q = await this.questService.publishQuestionnaire(this.questionnaire);
        success = q ? success : false;
      }
    }
    this.isNewQuestionnaire = false;
    if (success && close) {
      this.close();
    }
  }

  public async publish(): Promise<void> {
    await this.saveQuestionnaire(true, true);
  }

  public close(): void {
    if (this.knowledgeId) {
      this.router.navigate(["/knowledgeDetails", { id: this.knowledgeId }]);
      return;
    }

    if (this.previousLocation) {
      this.router.navigate([this.previousLocation]);
      return;
    }

    switch (this.specificUse) {
      case SPECIFIC_USE.QUIZ:
        this.router.navigateByUrl("quizList");
        break;

      case SPECIFIC_USE.CONSENT:
        this.router.navigateByUrl("consentList");
        break;

      case SPECIFIC_USE.NONE:
        this.router.navigateByUrl("fhirQuestionnaireList");
        break;
      default:
        this.router.navigateByUrl("fhirQuestionnaireList");
        break;
    }
  }
}
