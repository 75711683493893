<div class="close-cross">
  <button mat-icon-button mat-dialog-close class="negative-margin">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div style="text-align: center">
  <h2>{{ (data.translateKeyTitle ? data.translateKeyTitle : "knowledgebase.title") | translate }}</h2>
</div>
<mat-dialog-content class="CareplanKnowledgesModalContainer">
  <div *ngIf="isLoading" class="loading">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <div *ngIf="!isLoading && medias?.length > 0">
    <mat-table [dataSource]="medias">
      <ng-container matColumnDef="Visibility">
        <mat-header-cell *matHeaderCellDef style="justify-content: center">{{ "drugSchema.visibility" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element" style="justify-content: center">
          <mat-icon *ngIf="!element.notVisibleForPatient" [matTooltip]="'menu.patients' | translate" color="primary">face</mat-icon>
          <mat-icon *ngIf="element.visibleForMonitoring" [matTooltip]="'knowledgebase.monitoringPerson' | translate" color="primary"
            >person</mat-icon
          >
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Description">
        <mat-header-cell *matHeaderCellDef>{{ "forms.title.title" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element" [matTooltip]="element.description" matTooltipPosition="above">{{ element.label }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <mat-header-cell *matHeaderCellDef style="justify-content: center"> {{ "table.action" | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" style="justify-content: center">
          <button type="button" mat-icon-button (click)="showOverview(element)">
            <mat-icon color="primary">search</mat-icon>
          </button></mat-cell
        >
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let element; columns: columnsToDisplay"></mat-row>
    </mat-table>
  </div>
  <div style="text-align: center" *ngIf="!isLoading && !(medias?.length > 0)">
    {{ "widget.noData" | translate }}
  </div>
</mat-dialog-content>
