import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class ExportApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Export", // Theme !
      [], // Post route paths
      ["exportAlertsForPatient"], // Get route paths
      [], // Update route paths
      [] // Delete route paths
    );
  }

  public exportAlerts(patientId: string, lang: string): Observable<Blob> {
    return this.api.getBlob(this.readRoutes[0], { patientId: patientId, lang: lang }) as Observable<Blob>;
  }
}
