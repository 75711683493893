import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { Quantity } from "src/app/models/quantity.model";
export class VitalSignDataSource extends MatTableDataSourceExtended<Quantity> {
  sortingDataAccessor = (data: Quantity, sortHeaderId: string): string => {
    switch (sortHeaderId) {
      case "name":
        return data.system;
      case "value":
        return data.value.toString();
      case "unit":
        return data.unit;
      default:
        return "";
    }
  };

  constructor() {
    super();
  }

  public loadData(vitalSigns: Quantity[]): void {
    this.data = vitalSigns;
  }
}
