import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "knowledgeCategory",
})
export class KnowledgeCategoryPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(value: unknown, ..._args: unknown[]): unknown {
    return this.translateService.instant(`knowledgebase.category.${value}`);
  }
}
