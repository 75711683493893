<div class="rewardDetailsHistoric">
  <mat-card>
    <h3>{{ "alerts.historic" | translate }}</h3>
    <mat-card style="padding: 0px !important" [ngClass]="{ hide: reward.isNoHistoric }">
      <table mat-table matSort matSortDirection="asc" [dataSource]="dataSource">
        <!-- date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "table.date" | translate }}
            <app-item-filter
              fieldName="{{ 'table.date' | translate }}"
              propertyName="actionDate"
              [dataType]="dataTypeDate"
              [defaultValue]="getFilter('actionDate')"
              (applyFilter)="applyFilter($event)"
            >
            </app-item-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.actionDate | date : "short" }}
          </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>
            {{ "table.action" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ getTranslations(element.action) }}
          </td>
        </ng-container>

        <!-- Comment Column -->
        <ng-container matColumnDef="comment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "table.comment" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ getComment(element) || "-" }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator class="borderBox" #paginator [pageIndex]="0" [pageSize]="5" [pageSizeOptions]="[5, 10, 15]" [hidePageSize]="false">
      </mat-paginator>
    </mat-card>
    <p class="text-center" *ngIf="reward.isNoHistoric">
      {{ "widget.noData" | translate }}
    </p>
  </mat-card>
</div>
