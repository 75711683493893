import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IReference } from "src/app/models/sharedInterfaces";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class TownsApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Towns", // Theme !
      [], // Post route paths
      [], // Get route paths
      [], // Update route paths
      [] // Delete route paths
    );
  }

  public list(date: string, name?: string, code?: string): Observable<IReference[]> {
    // the date must be in this format : YYYY-MM-DD
    return this.api.get("dashboard/frenchTown", {
      date,
      code,
      name,
    }) as Observable<IReference[]>;
  }
}
