import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Tools } from "src/app/helpers/tools";
import { DataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { Filter } from "src/app/models/filter.interface";
import {
  IQuestionnaire,
  IQuestionnaireHistoryEvent,
  IQuestionnaireListInfo,
  IQuestionnaireParam,
  IQuizListInfo,
  SPECIFIC_USE,
} from "src/app/models/questionnaire.interface";
import { IQuestionnaireScoring } from "src/app/models/questionnaireScoring.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class QuestionnaireApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Questionnaires", // Theme !
      [
        "dashboard/questionnaire", // 0
        "dashboard/questionnaireParams", // 1
        "dashboard/linkQuestionnaireToMedia", // 2
        "dashboard/questionnaireScorings", // 3
      ], // Post route paths
      [
        "dashboard/questionnaires", // 0
        "dashboard/specificQuestionnaireInfos", // 1
        "dashboard/nbSpecificQuestionnaireInfos", // 2
        "dashboard/specificQuestionnaireList", // 3
        "dashboard/questionnaireParams", // 4
        "dashboard/questionnaireTemplateInfos", // 5
        "dashboard/questionnaireTemplate", // 6
        "dashboard/questionnaire", // 7
        "dashboard/questionnaireHistory", // 8
        "dashboard/userQuestionnairesListInfos", // 9 Backend table for questionnaire listing
      ], // Get route paths
      [
        "dashboard/questionnaire", // 0
        "dashboard/questionnaireParams", // 1
        "dashboard/questionnaireTemplate", // 2
        "dashboard/publishQuestionnaire", // 3
        "dashboard/questionnaireScorings", // 4
      ], // Update route paths
      ["dashboard/questionnaire", "dashboard/questionnaireParams", "dashboard/questionnaireTemplate", "dashboard/questionnaireScorings"] // Delete route paths
    );
  }

  public getByCareplan(
    careplanId: string,
    language: string,
    filterOnlyPractitioner?: boolean,
    draft = false,
    date?: string
  ): Observable<IQuestionnaire[]> {
    const params = {
      careplanId,
      lang: language,
      filterOnlyPractitioner: Tools.isDefined(filterOnlyPractitioner) ? filterOnlyPractitioner : undefined,
      draft,
      date,
    };
    return this.api.get(this.readRoutes[0], params) as Observable<IQuestionnaire[]>;
  }

  /**
   * Get one questionnaire using its id, its lang version and whether we want the draft or not
   * @param questId (string) the questionnaire's id
   * @param language (string) the language
   * @param draft (boolean) if we want the latest version and it's okay if it is a draft
   * @param latest (boolean) if we want the latest active version only and nothing else if it does not exists
   * @param date (string) if we want a specific version, we identifiy it by it's date
   * @returns an promise with the questionnaire found
   */
  public getQuestionnaire(questId: string, lang: string, draft = false, latest = true, date?: string): Observable<IQuestionnaire> {
    return this.api.get(this.readRoutes[7], {
      identifier: questId,
      version: lang,
      draft: draft,
      date: date,
      latest: latest,
    }) as Observable<IQuestionnaire>;
  }

  public getQuestionnaireScorings(questId: string, draft = false, date?: string): Observable<IQuestionnaireScoring[]> {
    return this.api.get("dashboard/questionnaireScorings", {
      questionnaireId: questId,
      draft: draft,
      publicationDate: date,
    }) as Observable<IQuestionnaireScoring[]>;
  }

  public createQuestionnaireDraft(questionnaire: IQuestionnaire): Observable<IQuestionnaire> {
    return this.api.post(this.createRoutes[0], questionnaire) as Observable<IQuestionnaire>;
  }

  public createQuestionnaireScoringsDrafts(scorings: IQuestionnaireScoring[]): Observable<IQuestionnaireScoring[]> {
    return this.api.post(this.createRoutes[3], scorings) as Observable<IQuestionnaireScoring[]>;
  }

  public updateQuestionnaireDraft(questionnaire: IQuestionnaire): Observable<IQuestionnaire> {
    return this.api.put(this.updateRoutes[0], questionnaire) as Observable<IQuestionnaire>;
  }

  public updateQuestionnaireScorings(scorings: IQuestionnaireScoring[]): Observable<IQuestionnaireScoring[]> {
    return this.api.put(this.updateRoutes[4], scorings) as Observable<IQuestionnaireScoring[]>;
  }

  public deleteQuestionnaire(questionnaire: IQuestionnaire): Observable<boolean> {
    return this.api.delete(this.deleteRoutes[0], questionnaire) as Observable<boolean>;
  }

  public deleteQuestionnaireScorings(scoringsIds: string[]): Observable<boolean> {
    return this.api.deleteWithParams(this.deleteRoutes[3], { scoringsIds: JSON.stringify(scoringsIds) }) as Observable<boolean>;
  }

  public publishQuestionnaire(idQuestionnaire: string, version: string): Observable<IQuestionnaire> {
    return this.api.put(this.updateRoutes[3], { idQuestionnaire, version }) as Observable<IQuestionnaire>;
  }

  public getSpecificQuestionnaireInfos(params: DataSourceBackendParam): Observable<IQuizListInfo[]> {
    return this.api.get(this.readRoutes[1], {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      search: params.search,
      sortId: params.sortId,
      sortOrder: params.sortOrder,
      filters: JSON.stringify(params.filters),
      specificUse: params.specificUse,
    }) as Observable<IQuizListInfo[]>;
  }

  public getSpecificQuestionnaireInfosCount(specificUse: SPECIFIC_USE, search?: string, filters?: Filter[]): Observable<{ count: number }> {
    return this.api.get(this.readRoutes[2], {
      specificUse,
      search,
      filters: JSON.stringify(filters),
    }) as Observable<{ count: number }>;
  }

  public getSpecificQuestionnaires(specificUse: SPECIFIC_USE, lang?: string): Observable<IQuestionnaire[]> {
    return this.api.get(this.readRoutes[3], { specificUse, lang }) as Observable<IQuestionnaire[]>;
  }

  public createParams(params: IQuestionnaireParam): Observable<IQuestionnaireParam> {
    return this.api.post(this.createRoutes[1], params) as Observable<IQuestionnaireParam>;
  }

  public getParams(questId: string): Observable<IQuestionnaireParam> {
    return this.api.get(this.readRoutes[4], { identifier: questId }) as Observable<IQuestionnaireParam>;
  }

  public updateParams(params: IQuestionnaireParam): Observable<IQuestionnaireParam> {
    return this.api.put(this.updateRoutes[1], params) as Observable<IQuestionnaireParam>;
  }

  public deleteParams(paramsId: string): Observable<IQuestionnaireParam> {
    return this.api.delete(this.deleteRoutes[1], paramsId) as Observable<IQuestionnaireParam>;
  }

  public linkQuestionnaireToMedia(
    questionnaireId: string,
    questionnaireVersion: string,
    knowledgeId: string,
    mediaId: string
  ): Observable<IQuestionnaire> {
    const params = {
      questionnaireId,
      questionnaireVersion,
      knowledgeId,
      mediaId,
    };
    return this.api.post(this.createRoutes[2], params) as Observable<IQuestionnaire>;
  }
  public getQuestionnaireHistory(identifier: string, lang: string): Observable<IQuestionnaireHistoryEvent[]> {
    return this.api.get(this.readRoutes[8], { identifier, lang }) as Observable<IQuestionnaireHistoryEvent[]>;
  }

  /**
   * Backend Table : Get questionnairesListInfo accessible by some services or organizations. If no organization
   * or services references are provided, it will just use the whole list of organizations and services
   * available to the user
   */
  public findQuestionnaires(params: DataSourceBackendParam): Observable<IQuestionnaireListInfo[]> {
    return this.api.get(this.readRoutes[9], {
      sortId: params.sortId,
      sortOrder: params.sortOrder,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      filters: JSON.stringify(params.filters),
      search: params.search,
      orgsAndServicesRefs: params.orgsAndServicesRefs?.join(","),
      lang: params.lang,
    }) as Observable<IQuestionnaireListInfo[]>;
  }

  /**
   * Backend Table : Count all questionnairesListInfo accessible by some services or organizations.
   */
  public getQuestionnairesCount(search?: string, filters?: Filter[], orgsAndServicesRefs?: string[]): Observable<{ count: number }> {
    return this.api.get("dashboard/countUserQuestionnairesListInfos", {
      search,
      filters: JSON.stringify(filters),
      orgsAndServicesRefs: orgsAndServicesRefs?.join(","),
    }) as Observable<{ count: number }>;
  }
}
