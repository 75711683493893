import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AddPatientComponent } from "../components/forms/add-patient/add-patient.component";
import { FORMS_MODE } from "../helpers/formsData";

@Injectable({
  providedIn: "root",
})
export class ModalManagementService {
  private readonly ADD_PATIENT_COMPONENT = "AddPatientComponent";

  constructor(private dialog: MatDialog, private router: Router) {}

  public async redirectToGivenModal(modalId: string, source?: string): Promise<void> {
    switch (modalId) {
      case this.ADD_PATIENT_COMPONENT:
        this.dialog.open(AddPatientComponent, {
          disableClose: true,
          data: {
            mode: FORMS_MODE.CREATE,
            source: source,
          },
          maxWidth: 850,
        });
        break;
      default:
        await this.router.navigate(["not-found"]);
    }
  }
}
