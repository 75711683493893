import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Account } from "src/app/models/account.interface";
import { ERROR_MSG } from "src/app/models/app-error.interface";
import { FormPage } from "src/app/pages/formPage";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import Validation from "./password-match.validation";

@Component({
  selector: "app-init-pwd",
  templateUrl: "./init-password.component.html",
  styleUrls: ["./init-password.component.scss"],
})
export class InitPwdComponent extends FormPage {
  public initPwdForm = this.fb.group(
    {
      newPasswordA: [undefined, [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,}$/)]],
      newPasswordB: [undefined, [Validators.required]],
    },
    {
      validators: [Validation.match("newPasswordA", "newPasswordB")],
    }
  );
  public passwordError: string;

  public constructor(
    private readonly dialogRef: MatDialogRef<string>,
    private fb: UntypedFormBuilder,
    protected sessionService: SessionService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      account: Account;
    }
  ) {
    super();
  }

  public apply() {
    if (this.initPwdForm.valid) {
      this.data.account.newPassword = this.initPwdForm.get("newPasswordA").value;
      this.userService.updateAccount(this.data.account).subscribe(
        () => {
          this.dialogRef.close(this.initPwdForm.get("newPasswordA").value);
        },
        (err) => {
          if (err.message === ERROR_MSG.INVALID_PASSWORD) {
            if (err.additionalData) {
              this.passwordError = `${err.additionalData}.${this.sessionService.userLang}`;
              return;
            }
          }
        }
      );
    } else {
      return;
    }
  }

  public getForm(): UntypedFormGroup {
    return this.initPwdForm;
  }
}
