import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Coding } from "../models/coding.interface";
import { IQuestionnaire } from "../models/questionnaire.interface";
import { GroupResponse, Question } from "../models/questionnaireResponse.interface";
import { QuestionnaireScoring } from "../models/questionnaireScoring.model";
import { scores, scoresTable, table, td, template, th, tr, trScores } from "./static-values/qTemplateB";

@Injectable({
  providedIn: "root",
})
export class QuestionnairePDFHelperCheckBox {
  private tr: string = tr;
  private th: string = th;
  private td: string = td;
  private table: string = table;
  private template: string = template;
  private trScoring: string = trScores;

  constructor(private translateService: TranslateService) {}

  private get translatedTemplate(): string {
    return this.template
      .replace("%DATE_TITlE%", this.translateService.instant("table.date"))
      .replace("%QUESTIONS%", this.translateService.instant("table.questions"));
  }

  private get translatedTable(): string {
    return this.table
      .replace("%QID%", this.translateService.instant("pdf.questionnaire.QID"))
      .replace("%Question%", this.translateService.instant("pdf.questionnaire.question"));
  }

  private addTableToTemplate(groups: GroupResponse[], questionnaire: IQuestionnaire): string {
    const flatTables = groups.reduce((acc, el) => acc + this.computedTable(el, questionnaire), "");
    return this.translatedTemplate.replace("%TABLES%", flatTables);
  }

  private computedTable(group: GroupResponse, questionnaire: IQuestionnaire): string {
    const possibleChoice = this.getValueSetChoices(group.question[0].linkId, questionnaire);
    const possibleChoiceString = possibleChoice.map((v) => v.display);
    const flatTh = possibleChoiceString.reduce((acc, el) => acc + this.th.replace("%TH_VAL%", el), "");
    const computedTr = group.question.reduce((acc, el) => acc + this.computeTr(el, possibleChoice), "");
    return this.translatedTable.replace("%TH%", flatTh).replace("%TR%", computedTr).replace("%SUBJECT_VAL%", group.title);
  }

  private computeTr(question: Question, possibleChoice: Coding[]): string {
    const flatTd = possibleChoice.reduce((acc, el) => {
      const isValidate = question.answer?.[0]?.valueCoding.code === el.code;
      return acc + this.td.replace("%X_VAL%", isValidate ? "&#10003" : "");
    }, "");
    return this.tr.replace("%TD%", flatTd).replace("%QID_VAL%", question.linkId).replace("%QUESTION_VAL%", question.text);
  }

  /**
   * return multiple choice values define in "questionnaire"
   */
  private getValueSetChoices(reference: string, questionnaire: IQuestionnaire): Coding[] {
    let id: string;
    if (questionnaire.group.question?.length) {
      id = questionnaire.group.question.find((q) => q.linkId === reference)?.options.reference;
    } else {
      questionnaire.group.group.forEach((g) => {
        const index = g.question.findIndex((q) => q.linkId === reference);
        if (index > -1) {
          id = g.question[index].options.reference;
        }
      });
    }

    if (questionnaire.contained) {
      for (const el of questionnaire.contained) {
        if (el.resourceType === "ValueSet" && el.idSet === id) {
          return el.compose.include[0].concept;
        }
      }
    }
    return [];
  }

  private getCompletedTrScoring(score: QuestionnaireScoring) {
    return this.trScoring
      .replace("%SCORE_NAME_VAL%", `${score.identifier.label}`)
      .replace("%SCORE_VAL%", `${score.scoringValue || score.scoringValue === 0 ? score.scoringValue : "-"}`);
  }

  private getCompletedTrScoringArray(scoresDetails: QuestionnaireScoring[]) {
    return scoresDetails.map((s) => this.getCompletedTrScoring(s));
  }

  private addScoresToTemplate(allScores: QuestionnaireScoring[][]) {
    const tempTemplate: string[] = [];
    allScores.forEach((s) => {
      const allTrScores = this.getCompletedTrScoringArray(s).reduce((acc, el) => acc + el, "");
      tempTemplate.push(
        scoresTable
          .replace("%CAT_NAME%", s[0]?.identifier.value)
          .replace("%SCORE_NAME_TITLE%", this.translateService.instant("table.name"))
          .replace("%SCORE_VALUE_TITLE%", this.translateService.instant("table.score"))
          .replace("%TR_SCORES%", allTrScores)
      );
    });
    return tempTemplate;
  }

  public getConstructedTemplate(
    groups: GroupResponse[],
    questionnaire: IQuestionnaire,
    date: string,
    scoresToAdd?: QuestionnaireScoring[][]
  ): string {
    let templateWithValueAndDate = this.addTableToTemplate(groups, questionnaire).replace("%DATE_VALUE%", date);
    if (!scoresToAdd.length) {
      return templateWithValueAndDate.replace("%SCORES%", "");
    } else {
      templateWithValueAndDate = templateWithValueAndDate.replace("%SCORES%", scores);
      const flatTrScores = this.addScoresToTemplate(scoresToAdd).reduce((acc, el) => acc + el, "");
      return templateWithValueAndDate.replace("%SCORES_TABLE%", flatTrScores);
    }
  }
}
