import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ICareplan, ICareplanKnowledgeInfos, ILink2Careplan, VersioningStatus } from "src/app/models/careplans.interface";
import { KNOW_DOC_CATEGORY } from "src/app/models/knowledge.interface";
import { ApiService } from "src/app/providers/api/api.service";
import { BaseApiService } from "src/app/providers/api/baseApi.service";

@Injectable({
  providedIn: "root",
})
export class CareplanEditorApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Careplan Editor", // Theme !
      ["dashboard/careplanTemplate", "dashboard/link2careplan"], // Post route paths
      ["dashboard/careplanTemplate", "dashboard/link2careplan"], // Get route paths
      [
        "dashboard/careplanTemplate", // 0
        "dashboard/publishCareplanTemplate", // 1
        "dashboard/publishLink2careplan", // 2
        "dashboard/publishKnowledgeCriteria", // 3
      ], // Update route paths
      [] // Delete route paths
    );
  }

  public getCareplanTemplate(
    careplanRef: string,
    withDraft: boolean,
    publicationDate?: string
  ): Observable<{
    published: ICareplan;
    draft: ICareplan;
  }> {
    return this.api.get(this.readRoutes[0], { careplanRef: careplanRef, withDraft, publicationDate }) as Observable<{
      published: ICareplan;
      draft: ICareplan;
    }>;
  }

  public createCareplanTemplate(careplanTemplate: ICareplan): Observable<ICareplan> {
    careplanTemplate.versioningStatus = VersioningStatus.DRAFT;
    delete careplanTemplate.publicationDate;
    delete careplanTemplate._id;

    return this.api.post(this.createRoutes[0], careplanTemplate) as Observable<ICareplan>;
  }

  public getlink2Careplan(
    careplanId: string,
    withDraft: boolean
  ): Observable<{
    published: ILink2Careplan;
    draft: ILink2Careplan;
  }> {
    return this.api.get(this.readRoutes[1], { careplanId, withDraft }) as Observable<{
      published: ILink2Careplan;
      draft: ILink2Careplan;
    }>;
  }

  public createLink2Careplan(link2careplan: ILink2Careplan): Observable<ILink2Careplan> {
    link2careplan.versioningStatus = VersioningStatus.DRAFT;
    delete link2careplan.publicationDate;
    delete link2careplan._id;

    return this.api.post(this.createRoutes[1], link2careplan) as Observable<ILink2Careplan>;
  }
  public careplanKnowledgeInfos(
    careplanId: string,
    draft: boolean,
    docCategories: KNOW_DOC_CATEGORY[],
    publicationDate?: string
  ): Observable<ICareplanKnowledgeInfos[]> {
    return this.api.get("dashboard/careplanKnowledgeInfos", {
      careplanId,
      draft,
      docCategories: docCategories.join(","),
      publicationDate,
    }) as Observable<ICareplanKnowledgeInfos[]>;
  }

  public updateCareplanTemplate(careplanTemplate: ICareplan): Observable<ICareplan> {
    careplanTemplate.versioningStatus = VersioningStatus.DRAFT;
    delete careplanTemplate.publicationDate;
    return this.api.put(this.updateRoutes[0], careplanTemplate) as Observable<ICareplan>;
  }

  public publishCareplanTemplate(careplanTemplateRef: string): Observable<ICareplan> {
    return this.api.put(this.updateRoutes[1], { careplanTemplateRef }) as Observable<ICareplan>;
  }

  public publishLink2CareplanTemplate(careplanTemplateRef: string): Observable<ILink2Careplan> {
    return this.api.put(this.updateRoutes[2], { careplanTemplateRef }) as Observable<ILink2Careplan>;
  }

  public publishKnowledgeCriteria(careplanTemplateRef: string): Observable<ILink2Careplan> {
    return this.api.put(this.updateRoutes[3], { careplanTemplateRef }) as Observable<ILink2Careplan>;
  }
}
