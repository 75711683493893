import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { FormArray } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject, forkJoin } from "rxjs";
import { delay, first, switchMap, takeUntil, tap } from "rxjs/operators";
import { CareplanEditorService } from "src/app/careplan-editor/domain/careplan-editor.service";
import { Tools } from "src/app/helpers/tools";
import { ICareplan, ILink2Careplan, VersioningStatus } from "src/app/models/careplans.interface";
import { IKnowMedia, IKnowledgeBase } from "src/app/models/knowledge.interface";
import { IKnowledgesCriteria } from "src/app/models/knowledgescriteria-interface";
import { EnableWhenBehavior, IReference, STATUS_ENTITY } from "src/app/models/sharedInterfaces";
import { EnableWhen, Reference, SubCriteria, Timing } from "src/app/models/sharedModels.model";
import { IVitalProfileDefinition } from "src/app/models/vitalProfileDefinition.interface";
import { CareplansService } from "src/app/providers/careplans.service";
import { KnowledgeCriteriaService } from "src/app/providers/knowledge-criteria-service";
import { KnowledgeService } from "src/app/providers/knowledge.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";

export enum EnableWhenType {
  VITALSIGN = 0,
  INSURANCE = 1,
}
@Component({
  selector: "app-knowledge-criteria",
  templateUrl: "./knowledge-criteria.component.html",
  styleUrls: ["./knowledge-criteria.component.scss"],
})
export class KnowledgeCriteriaComponent implements OnDestroy, AfterViewInit {
  public ENABLE_WHEN_TYPE = EnableWhenType;
  public knowledgeCriteria: IKnowledgesCriteria;

  /**
   * Careplans
   */

  @Input() careplan: ICareplan;
  public link2Careplan: ILink2Careplan;
  public careplanCriteria: IKnowledgesCriteria[];

  /**
   * Activities
   */

  public availableActivities: FormArray;
  // @Input() activity: Activity;
  @Input() activityReference: IReference;
  public canSelectActivity: boolean;

  /**
   * Knowledge
   */

  @Input() knowledgeId: string;
  public knowledge: IKnowledgeBase;
  public isUpdate: boolean;

  public vitalSignsDefinitions: IVitalProfileDefinition[];
  public availableVS4FirstLevel: IVitalProfileDefinition[];
  public availableVS4SecondLevel: IVitalProfileDefinition[];

  public availableMedia: IKnowMedia[][] = [];
  public currentLanguage = this.translateService.currentLang;
  private get alreadyTakenMedia() {
    return this.knowledgeCriteria.subCriteria.filter((sub) => Tools.isDefined(sub.identifier.value)).map((s) => s.identifier.value);
  }

  public isValid: boolean;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  @Output() criteriaSaved = new EventEmitter<boolean>();

  constructor(
    private sessionService: SessionService,
    private careplanService: CareplansService,
    private knowledgeCriteriaService: KnowledgeCriteriaService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private knowledgeService: KnowledgeService,
    public dialog: MatDialog,
    private careplanEditorService: CareplanEditorService
  ) {}

  ngAfterViewInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public init(): void {
    const knowledgeDraftAndPublished$ = this.knowledgeService.getKnowledge(this.knowledgeId).pipe(first());

    const allViatlSignProfile$ = this.careplanService
      .getAllVitalProfileDefinitions(this.careplanEditorService.link2CareplanForm.value.vitalSignsDefinitions)
      .pipe(first());

    knowledgeDraftAndPublished$
      .pipe(
        first(),
        tap(({ draft, published }) => {
          this.knowledge = draft ? draft : published;
        }),
        switchMap(() =>
          forkJoin([
            this.knowledgeCriteriaService
              .list(null, null, this.careplan.support[0].reference, this.knowledge.identifier.value)
              .pipe(first(), delay(500)), // delay is there to show loader during at least 500ms to avoid a glitch
            allViatlSignProfile$,
          ])
        )
      )
      .subscribe(([knowledgeCriteria, vitalProfileDef]) => {
        // this.activity = null;
        this.careplanCriteria = knowledgeCriteria.draft ? knowledgeCriteria.draft : knowledgeCriteria.published;
        this.canSelectActivity = !this.hasGlobalCPCriteriaSet(this.careplanCriteria);
        this.availableActivities = this.careplanEditorService.careplanTemplateForm.get("activity") as FormArray;
        //remove type that are not number because we don't handle them at the moment.
        this.vitalSignsDefinitions = vitalProfileDef.filter((v) => v.type === "number");
        this.availableVS4FirstLevel = Tools.clone(this.vitalSignsDefinitions);
        this.availableVS4SecondLevel = Tools.clone(this.vitalSignsDefinitions);
        this.initCriteria();
      });
  }

  public reset(): void {
    this.knowledgeCriteria = null;
    this.careplan = null;
    // this.activity = null;
    this.sessionService.onGoingActionToggle(false);
    this.criteriaSaved.emit(true);
    this.availableMedia = [];
  }

  /**
   * Initialise knowledgeCriteria by loading existing one or creating a new one
   */
  private initCriteria() {
    if (!this.careplanCriteria.length) {
      this.createNewCriteria();
      return;
    }

    // If there is no selected activity, just select the entry in DB without activity if it exists; if not create a new criteria
    if (!this.activityReference) {
      const foundIndex = this.careplanCriteria.findIndex((el) => !Object.prototype.hasOwnProperty.call(el, "activityReference"));
      if (foundIndex !== -1) {
        this.loadExistingCriteria(this.careplanCriteria[foundIndex]);
      } else {
        this.createNewCriteria();
      }
    } else {
      // search for corresponding activityReference; if it exists; if not create a new criteria
      const foundI = this.careplanCriteria.findIndex((el) => el.activityReference?.reference === this.activityReference?.reference);
      if (foundI !== -1) {
        this.loadExistingCriteria(this.careplanCriteria[foundI]);
      } else {
        this.createNewCriteria();
      }
    }
  }

  /**
   *  Create a new empty knowledge criteria to work with.
   */
  private createNewCriteria() {
    this.isUpdate = false;
    this.knowledgeCriteria = {
      knowledgeIdentifier: this.knowledge.identifier,
      careplanSupport: this.careplan?.support,
      activityReference: this.activityReference,
      when: { period: 0, periodUnits: "d" },
      entityStatus: [STATUS_ENTITY.ACTIVE],
      subCriteria: [],
      showDirectly: false,
      enableWhenBehavior: EnableWhenBehavior.AND,
      versioningStatus: VersioningStatus.DRAFT,
    };
    this.checkIfValid();
  }

  /**
   *  Load existing createria
   */
  private loadExistingCriteria(criteria: IKnowledgesCriteria) {
    this.knowledgeCriteria = criteria;
    // If there is no "when", that means that showDirectly should be true.
    // Then we create the when property with default values to allow databinding
    if (!this.knowledgeCriteria.when) {
      this.knowledgeCriteria.showDirectly = true;
      this.knowledgeCriteria.when = { period: 0, periodUnits: "d" };
    }
    // same as previous comment but for subcriteria
    this.knowledgeCriteria.subCriteria.forEach((sub) => {
      if (!sub.when) {
        sub.showDirectly = true;
        sub.when = { period: 0, periodUnits: "d" };
      }
      this.availableMedia.push([this.knowledge.medias.find((m) => m.identifier.value === sub.identifier.value)]);
    });

    this.isUpdate = true;
    this.setAvailableVS();
    this.checkInsurance();
    this.checkIfValid();
  }

  /**
   * Add empty subCriteria with some default values to the knowledgeCriteria
   */
  public addSubCriteria(): void {
    this.availableMedia.push(this.knowledge.medias.filter((m) => !this.alreadyTakenMedia.includes(m.identifier.value)));
    const subCriteria = new SubCriteria({
      identifier: {
        system: null,
        value: null,
      },
      when: { period: 0, periodUnits: "d" },
      showDirectly: !this.knowledgeCriteria.showDirectly,
    });

    this.knowledgeCriteria.subCriteria.push(subCriteria);
    this.addEnableWhen(subCriteria);
    this.checkInsurance();
    this.checkIfValid();
  }

  /**
   * Delete subCriteria from the knowledgeCriteria based on its position in the array
   */
  public removeSubCriteria(index: number): void {
    this.knowledgeCriteria.subCriteria.splice(index, 1);
    this.availableMedia.splice(index, 1);

    // if a media with a set media value is deleted, we need to recalculate the availableMedia to make it available again in the list
    // if the deleted subcriteria had no media value, we don't recalculate the available media since it hasn't change
    //  and because it would remove the already selected media from the options of the select.
    if (
      this.availableMedia.length &&
      !this.knowledgeCriteria.subCriteria[this.knowledgeCriteria.subCriteria.length - 1]?.identifier.value
    ) {
      this.availableMedia[this.availableMedia.length - 1] = this.knowledge.medias.filter(
        (m) => !this.alreadyTakenMedia.includes(m.identifier.value)
      );
    }
    this.checkIfValid();
  }

  /**
   * Save knowledgeCriteria in DB after dealing with the showDirectly option
   */
  public saveCriteria(): void {
    if (this.knowledgeCriteria.showDirectly) {
      this.knowledgeCriteria.when = new Timing(); // delete "when" without deleting keys to avoid error in console
    }
    this.knowledgeCriteria.subCriteria.forEach((sub) => {
      if (sub.showDirectly) {
        sub.when = new Timing();
      }
    });

    if (this.isUpdate) {
      this.knowledgeCriteriaService
        .update(this.knowledgeCriteria)
        .pipe(first())
        .subscribe(() => {
          this.snackBar.open(this.translateService.instant("common.saveSuccess"), undefined, {
            duration: 3000,
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
        });
    } else {
      this.knowledgeCriteriaService
        .create(this.knowledgeCriteria)
        .pipe(first())
        .subscribe(() => {
          this.snackBar.open(this.translateService.instant("common.saveSuccess"), undefined, {
            duration: 3000,
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
        });
    }

    this.reset();
  }

  public compareReference(o1: Reference, o2: Reference): boolean {
    return o1?.reference === o2?.reference;
  }

  public deleteCriteria(id: string): void {
    this.knowledgeCriteriaService
      .deleteById(id)
      .pipe(first())
      .subscribe(() => this.reset());
  }

  /**
   * Add empty enableWhen with some default values to the knowledgeCriteria
   */
  public addEnableWhen(criteria: IKnowledgesCriteria | SubCriteria, type?: EnableWhenType): void {
    switch (type) {
      case EnableWhenType.INSURANCE:
        if (criteria.enableWhen) {
          criteria.enableWhen.push(
            new EnableWhen({
              operator: "€",
              answer: null,
              code: {
                coding: [
                  {
                    code: "ins",
                  },
                ],
              },
            })
          );
        } else {
          criteria.enableWhen = [
            new EnableWhen({
              operator: "€",
              answer: null,
              code: {
                coding: [
                  {
                    code: "ins",
                  },
                ],
              },
            }),
          ];
        }
        this.checkInsurance();
        break;

      case EnableWhenType.VITALSIGN:
      default:
        if (criteria.enableWhen) {
          criteria.enableWhen.push(new EnableWhen());
        } else {
          criteria.enableWhen = [new EnableWhen()];
        }
        criteria.enableWhen[criteria.enableWhen.length - 1].type = "number";
        break;
    }
    if (criteria.enableWhen.length <= 2) {
      // do not change enableWhenBehaviour if already set on previous items
      // set default value
      criteria.enableWhenBehavior = EnableWhenBehavior.AND;
    }
    this.checkIfValid();
  }

  /**
   * Delete enableWhen from the knowledgeCriteria based on its position in the array
   */
  public removeEnableWhen(criteria: IKnowledgesCriteria | SubCriteria, index: number): void {
    criteria.enableWhen.splice(index, 1);
    this.checkInsurance();
    this.setAvailableVS();
  }

  /**
   * Set the selectable vitalSignDefinition in the first level and second level criterias
   * based on what as already been set in first and second level
   */
  public setAvailableVS(_event?: unknown): void {
    // reset available vitalSign
    this.availableVS4SecondLevel = Tools.clone(this.vitalSignsDefinitions);
    this.availableVS4FirstLevel = Tools.clone(this.vitalSignsDefinitions);

    // remove already taken for first level criteria
    if (this.knowledgeCriteria.enableWhen) {
      this.knowledgeCriteria.enableWhen.forEach((element) => {
        const found = this.availableVS4SecondLevel.findIndex((el) => {
          return el.code === element.code.coding[0].code;
        });
        this.availableVS4SecondLevel.splice(found, 1);
      });
    }

    // remove already taken for second level criteria
    if (this.knowledgeCriteria.subCriteria) {
      this.knowledgeCriteria.subCriteria.forEach((knSubCriteria) => {
        knSubCriteria.enableWhen.forEach((element) => {
          const found = this.availableVS4FirstLevel.findIndex((el) => {
            return el.code === element.code.coding[0].code;
          });
          this.availableVS4FirstLevel.splice(found, 1);
        });
      });
    }
  }

  public checkInsurance(): void {
    // check if there is a insurance on first level criteria.
    if (this.knowledgeCriteria.enableWhen?.findIndex((ew) => ew.code.coding[0].code === "ins") >= 0) {
      this.knowledgeCriteria.hasInsuranceCriteria = true;
      // if there is one, set hasInsuranceCriteria to true on every subcriteria to block btn
      for (const sub of this.knowledgeCriteria.subCriteria) {
        sub.hasInsuranceCriteria = true;
      }
      return;
    } else {
      this.knowledgeCriteria.hasInsuranceCriteria = false;
      for (const sub of this.knowledgeCriteria.subCriteria) {
        sub.hasInsuranceCriteria = false;
      }
    }

    // check on subcriteria if one insurance has already been added and report it on first level criteria if it has.
    for (const sub of this.knowledgeCriteria.subCriteria) {
      if (sub.enableWhen?.findIndex((ew) => ew.code.coding[0].code === "ins") >= 0) {
        sub.hasInsuranceCriteria = true;
        this.knowledgeCriteria.hasInsuranceCriteria = true;
      } else {
        sub.hasInsuranceCriteria = false;
      }
    }
  }

  public checkIfValid(): boolean {
    if (!this.checkCriteria(this.knowledgeCriteria)) {
      return (this.isValid = false);
    }
    // check if subcriteria enablewhen has value
    for (const subCriteria of this.knowledgeCriteria.subCriteria) {
      if (!this.checkCriteria(subCriteria, true)) {
        return (this.isValid = false);
      }
    }
    return (this.isValid = true);
  }

  private checkCriteria(criteria, isSubCriteria?: boolean) {
    // if no media selected for the subcriteria, the criteria can't be saved
    if (isSubCriteria && !criteria.identifier.value) {
      return false;
    }
    // if enableWhen has no value selected or no ref value selected, the criteria can't be saved

    if (criteria.enableWhen) {
      for (const enableWhen of criteria.enableWhen) {
        if (
          !Tools.isDefined(enableWhen.answer) ||
          (Array.isArray(JSON.parse(enableWhen.answer as string)) && !JSON.parse(enableWhen.answer as string).length) ||
          !Tools.isDefined(enableWhen.code.coding[0].code)
        ) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Check if a criteria for the whole careplan is already set in order to determine
   * if adding a criteria for a specific activity should be possible
   */
  private hasGlobalCPCriteriaSet(criteria: IKnowledgesCriteria[]) {
    if (criteria?.length && criteria.some((x) => Tools.isNotDefined(x.activityReference))) {
      return true;
    } else {
      return false;
    }
  }

  public getCareplanCriteria(): Promise<{ published: IKnowledgesCriteria[]; draft: IKnowledgesCriteria[] }> {
    return this.knowledgeCriteriaService
      .list(null, null, this.careplan.support[0].reference, this.knowledge.identifier.value)
      .pipe(first(), takeUntil(this.onDestroy$))
      .toPromise();
  }
}
