import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "ui-alert",
  templateUrl: "./ui-alert.component.html",
  styleUrls: ["./ui-alert.component.scss"],
})
export class UiAlertComponent {
  @Input() options: {
    type: "warning" | "error" | "info" | "help";
    closable?: boolean;
    fadeOut?: boolean;
    showIcon?: boolean;
  };
  @Output() dismiss = new EventEmitter();
  public visibility = "";

  public onDismiss(): void {
    if (this.options.fadeOut) {
      this.visibility = "fadeOut";
      setTimeout(() => {
        this.visibility = "hidden";
        this.dismiss.emit("dismissed");
      }, 400);
    } else {
      this.visibility = "hidden";
      this.dismiss.emit("dismissed");
    }
  }
}
