import { DashboardRoutesService } from "../dashboardRoutes.service";

export interface IServiceRoutes {
  theme: string;
  create: string[];
  read: string[];
  update: string[];
  delete: string[];
}

export abstract class BaseApiService {
  private serviceTheme: string;
  public get theme(): string {
    return this.serviceTheme;
  }

  private serviceCreateRoutes: string[] = [];
  public get createRoutes(): string[] {
    return this.serviceCreateRoutes;
  }

  private serviceUpdateRoutes: string[] = [];
  public get updateRoutes(): string[] {
    return this.serviceUpdateRoutes;
  }

  private serviceDeleteRoutes: string[] = [];
  public get deleteRoutes(): string[] {
    return this.serviceDeleteRoutes;
  }

  private serviceReadRoutes: string[] = [];
  public get readRoutes(): string[] {
    return this.serviceReadRoutes;
  }

  constructor(serviceNameKey: string, createRoutes: string[], readRoutes: string[], updateRoutes: string[], deleteRoutes: string[]) {
    this.serviceTheme = serviceNameKey;
    this.serviceCreateRoutes = createRoutes;
    this.serviceUpdateRoutes = updateRoutes;
    this.serviceReadRoutes = readRoutes;
    this.serviceDeleteRoutes = deleteRoutes;
    DashboardRoutesService.addServiceRoutes(this.getAllRoutes());
  }

  public getAllRoutes(): IServiceRoutes {
    return {
      theme: this.theme,
      create: this.createRoutes,
      read: this.readRoutes,
      update: this.updateRoutes,
      delete: this.deleteRoutes,
    };
  }
}
