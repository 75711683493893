import { IQuestionnaireList, IQuestionnaireScore } from "./questionnaireList.interface";

export class QuestionnaireList implements IQuestionnaireList {
  name: string;
  firstname: string;
  gender: string;
  careplan: string[];
  questionnaireName: string;
  questionnaireScore: IQuestionnaireScore[];
  questionnaireDate: string;
  birthDate: string;
  caremateIdentifier: string;
  questionnaireIdentifier: string;

  constructor(data: IQuestionnaireList) {
    this.name = data.name;
    this.firstname = data.firstname;
    this.gender = data.gender;
    this.careplan = data.careplan;
    this.questionnaireName = data.questionnaireName;
    this.questionnaireScore = data.questionnaireScore;
    this.questionnaireDate = data.questionnaireDate;
    this.birthDate = data.birthDate;
    this.caremateIdentifier = data.caremateIdentifier;
    this.questionnaireIdentifier = data.questionnaireIdentifier;
  }

  private get careplans() {
    return this.careplan.join();
  }
}
