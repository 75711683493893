<table
  matSort
  mat-table
  matSortDirection="desc"
  matSortActive="date"
  [ngClass]="{ hide: loading || dataSource?.data?.length <= 0 }"
  [dataSource]="dataSource"
>
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "table.name" | translate }}
      <app-item-filter
        fieldName="{{ 'model.patient.name' | translate }}"
        propertyName="display"
        [dataType]="DATATYPE.TEXT"
        [defaultValue]="getFilter('display')"
        (applyFilter)="applyFilter($event)"
      >
      </app-item-filter>
    </th>
    <td mat-cell *matCellDef="let element">
      <div style="display: flex">
        <mat-icon *ngIf="element | isBluetoothObsComp" style="font-size: 20px" [matTooltip]="element.device?.reference">
          bluetooth
        </mat-icon>
        {{ element | translateObservationName : obsDefinition }}
      </div>
    </td>
  </ng-container>

  <!-- Sate Column -->
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "table.date" | translate }}
      <app-item-filter
        fieldName="{{ 'date' | translate }}"
        propertyName="date"
        [dataType]="DATATYPE.DATE"
        [defaultValue]="getFilter('date')"
        (applyFilter)="applyFilter($event)"
      >
      </app-item-filter>
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.date | date : "short" }}
    </td>
  </ng-container>

  <!-- Value Column -->
  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef>
      {{ "table.value" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.value }}
      <mat-icon
        *ngIf="element.pictures?.length > 0"
        class="pictureButton small"
        (click)="pictureQuickView(element.pictures, element.display)"
      >
        camera_alt
      </mat-icon>
    </td>
  </ng-container>

  <!-- display / Unit Column -->
  <ng-container matColumnDef="meaning">
    <th mat-header-cell *matHeaderCellDef>
      {{ "table.meaning" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.reference | loincAndValueMeaning : element.parentReference : element.value : obsDefinition }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator
  #paginator
  [ngClass]="{ hide: loading || dataSource?.data?.length <= 0 }"
  [pageSizeOptions]="[5]"
  [hidePageSize]="true"
  [pageIndex]="0"
  [pageSize]="5"
  [length]="dataSource?.data?.length"
>
</mat-paginator>
<div *ngIf="loading" class="loading">
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</div>
<p class="text-center" *ngIf="dataSource && dataSource.data?.length <= 0 && !loading">
  {{ "widget.noData" | translate }}
</p>
