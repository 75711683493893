import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { Tools } from "src/app/helpers/tools";
import { IDrugSchema } from "src/app/models/drugSchema.interface";
import { DrugSchema } from "src/app/models/drugSchema.model";
import { CycleSchema } from "src/app/models/entity.interface";
import { DrugSchemaService } from "src/app/providers/drugSchema.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";

@Component({
  selector: "app-add-drug-schema",
  templateUrl: "./add-drug-schema.component.html",
  styleUrls: ["./add-drug-schema.component.scss"],
})
export class AddDrugSchemaComponent implements OnInit, OnDestroy {
  public schemaForm = this.fb.group({
    name: [undefined, Validators.required],
    nbrOfDays: [28, Validators.required],
    linkToDrug: [false],
    saveAsSchema: [false],
    forWho: [],
  });
  private displayedColumns: string[] = [];
  public rowOfDisplayedColumns: string[][] = [];
  public intakeDays: boolean[] = [];
  private finalDrugCycle: CycleSchema = {
    startDate: null,
    name: "",
    cycle: [],
  };
  private finalDrugSchema: DrugSchema;
  public isSamv2Drug = false;
  public isMenu = false;
  public isCreation: boolean;
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();

  constructor(
    private sessionService: SessionService,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private drugSchemaService: DrugSchemaService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AddDrugSchemaComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      drugSchema: CycleSchema;
      atcCode: string;
      mode: FORMS_MODE;
      cycleSchema: IDrugSchema;
      isMenu: boolean;
      cycles: CycleSchema[];
    }
  ) {}

  ngOnInit(): void {
    this.isSamv2Drug = this.data.atcCode ? true : false;
    this.isMenu = this.data.isMenu ? true : false;
    this.isCreation = this.data.mode === FORMS_MODE.CREATE ? true : false;
    if (this.isCreation) {
      for (let i = 0; i < this.schemaForm.get("nbrOfDays").value; i++) {
        this.intakeDays.push(false);
      }
      if (this.isMenu) {
        const checkbox = this.schemaForm.get("saveAsSchema");
        checkbox.setValue(true);
        checkbox.disable();
        this.schemaForm.get("forWho").setValidators([Validators.required]);
      }
    } else {
      if (this.isMenu) {
        this.intakeDays = Tools.clone(this.data.cycleSchema.cycle);
        this.finalDrugSchema = new DrugSchema(this.data.cycleSchema);
        this.schemaForm = this.fb.group({
          name: [this.finalDrugSchema.name, Validators.required],
          nbrOfDays: [this.intakeDays.length, Validators.required],
          saveAsSchema: [{ value: true, disabled: true }],
          linkToDrug: [false],
          forWho: [Validators.required],
        });
        const forWhoChoice = this.schemaForm.get("forWho");
        if (this.finalDrugSchema.public) {
          forWhoChoice.setValue("global");
        } else if (!this.finalDrugSchema.public && this.finalDrugSchema.author) {
          forWhoChoice.setValue("me");
        } else if (!this.finalDrugSchema.public && this.finalDrugSchema.organization) {
          forWhoChoice.setValue("myOrg");
        }
      } else {
        this.intakeDays = Tools.clone(this.data.drugSchema.cycle);
        this.schemaForm = this.fb.group({
          name: [this.data.drugSchema.name, Validators.required],
          nbrOfDays: [this.intakeDays.length, Validators.required],
          linkToDrug: [false],
          saveAsSchema: [false],
          forWho: [],
        });
      }
    }
    const forWho = this.schemaForm.get("forWho");
    this.schemaForm
      .get("saveAsSchema")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((r) => {
        if (r) {
          forWho.setValidators([Validators.required]);
        } else {
          forWho.setValidators(null);
        }
        forWho.updateValueAndValidity();
      });
    this.onNbrOfDaysChange();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public isAuthorizedAddSchema(): boolean {
    return this.userService.isAuthorizedSync(null, "dashboard/drugSchema", "POST") ? true : false;
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onNbrOfDaysChange(): void {
    this.displayedColumns = [];
    let nbrOfDays: number = this.schemaForm.get("nbrOfDays").value;
    // Here we have to set a max numbers of days
    // Cause if you enter for example 999999 the dashboard dies
    if (nbrOfDays > 365) {
      this.schemaForm.get("nbrOfDays").setValue(365);
      nbrOfDays = 365;
    } else if (nbrOfDays < 0) {
      this.schemaForm.get("nbrOfDays").setValue(0);
      nbrOfDays = 0;
    }
    this.intakeDays.length = nbrOfDays;
    for (let i = 0; i < nbrOfDays; i++) {
      this.displayedColumns.push(i.toString());
    }
    if (nbrOfDays < this.intakeDays.length) {
      this.intakeDays.splice(nbrOfDays, this.intakeDays.length - nbrOfDays);
    } else {
      const nbrOfDaysSupp = nbrOfDays - this.intakeDays.length;
      for (let i = 0; i < nbrOfDaysSupp; i++) {
        this.intakeDays.push(false);
      }
    }
    this.formatDisplayedColumns();
  }

  private formatDisplayedColumns() {
    this.rowOfDisplayedColumns = [];
    let value = this.displayedColumns.length / 15;
    if (value % 1 > 0) {
      value++;
    }
    value = Math.floor(value);
    for (let i = 0; i < value; i++) {
      this.rowOfDisplayedColumns.push([]);
      this.displayedColumns.forEach((index) => {
        if (+index >= 15 * i && +index < 15 * (i + 1)) {
          this.rowOfDisplayedColumns[i].push(index);
        }
      });
    }
  }

  public onCellClick(index: number): void {
    this.intakeDays.splice(index, 1, this.intakeDays[index] ? false : true);
  }

  public onSave(): void {
    if (this.schemaForm.valid) {
      this.finalDrugCycle.name = this.schemaForm.get("name").value;
      this.finalDrugCycle.cycle = this.intakeDays;
      if (this.schemaForm.get("saveAsSchema").value === true) {
        if (!this.isMenu && this.isNameAlreadyInTheList(this.finalDrugCycle.name)) {
          const msg = this.translateService.instant("page.drugSchema.nameAlreadyExist");
          this.snackBar.open(msg, "ok", { duration: 5000 });
          return;
        }
        const result = this.saveAsSchema();
        if (!result) {
          return;
        }
      }
      this.dialogRef.close(this.finalDrugCycle);
    } else {
      this.schemaForm.markAllAsTouched();
    }
  }

  private saveAsSchema(): boolean {
    this.finalDrugSchema = new DrugSchema({
      name: this.finalDrugCycle.name,
      cycle: this.intakeDays,
      public: this.schemaForm.get("forWho").value === "global",
    });
    const forWho = this.schemaForm.get("forWho").value;
    if (forWho === "me") {
      this.finalDrugSchema.public = false;
      this.finalDrugSchema.author = {
        reference: this.sessionService.account.caremateIdentifier,
        display: this.sessionService.account.displayName,
      };
      if (!this.isCreation && this.isMenu && this.data.cycleSchema.organization) {
        this.finalDrugSchema.organization = null;
      }
    } else if (forWho === "myOrg") {
      this.finalDrugSchema.public = false;
      const orgRef = this.userService.isMonitoringUser ? this.sessionService.currentMonitoringOrg : this.sessionService.organization;
      if (!orgRef || orgRef.reference === this.sessionService.allsOption) {
        const msg = this.translateService.instant("drugSchema.mustChooseOrg");
        this.snackBar.open(msg, "ok", { duration: 5000 });
        return false;
      }
      this.finalDrugSchema.organization = orgRef;
      if (!this.isCreation && this.isMenu && this.data.cycleSchema.author) {
        this.finalDrugSchema.author = null;
      }
    } else if (forWho === "global") {
      this.finalDrugSchema.public = true;
      if (!this.isCreation && this.isMenu && this.data.cycleSchema.organization) {
        this.finalDrugSchema.organization = null;
      }
      if (!this.isCreation && this.isMenu && this.data.cycleSchema.author) {
        this.finalDrugSchema.author = null;
      }
    }
    if (this.schemaForm.get("linkToDrug").value === true) {
      this.finalDrugSchema.drugs = [this.data.atcCode];
    }
    if (this.isMenu && !this.isCreation) {
      this.finalDrugSchema._id = this.data.cycleSchema._id;
      this.drugSchemaService.update(this.finalDrugSchema).pipe(takeUntil(this.onDestroy$)).subscribe();
    } else {
      this.drugSchemaService.create(this.finalDrugSchema).pipe(takeUntil(this.onDestroy$)).subscribe();
    }
    return true;
  }

  private isNameAlreadyInTheList(name: string): boolean {
    return this.data.cycles.find((cycle) => cycle.name === name) !== undefined;
  }
}
