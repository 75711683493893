import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { DataType, Filter } from "src/app/models/filter.interface";
import { RewardDefinition } from "src/app/models/rewardDefinition.model";
import { RewardScoreExtended } from "src/app/models/rewardScore-extended.model";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { RewardScoreService } from "src/app/providers/rewardScore.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";

export class RewardsPageDataSource extends MatTableDataSourceExtended<RewardScoreExtended> {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private refreshInterval;
  public loading$ = this.loadingSubject.asObservable();
  public rewardDefinitions: RewardDefinition[];
  private sub: Subscription;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  constructor(
    protected rewardScoreService: RewardScoreService,
    protected sessionService: SessionService,
    private translateService: TranslateService,
    private userService: UserService,
    private healthcareService: HealthcareserviceService
  ) {
    super();
  }

  sortingDataAccessor = (data: RewardScoreExtended, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "gender":
        return data.patientCustomInfo.gender;
      case "name":
        return data.patientCustomInfo.name?.toLowerCase();
      case "firstname":
        return data.patientCustomInfo.firstname?.toLowerCase();
      case "birthday":
        return moment(data.patientCustomInfo.bithdate).unix();
      case "objectifName":
        return this.getTargetName(data.rewardDefinitionReference.reference).toLowerCase();
      case "objectifStart":
        return moment(data.start).unix();
      case "objectifEnd":
        return moment(data.end).unix();
      case "objectifProgress":
        return data.currentScore;
      case "rewardStatut":
        return data.hasBeenRewarded.toString().toLowerCase();
      case "rewardHistory":
        return data.rewardReceived + moment(data.rewardReceivedDate).unix();
      default:
        return "";
    }
  };

  // override method
  public filtredPredictedDefault(f: Filter, propertyValue: unknown): boolean {
    switch (f.dataType) {
      case DataType.REWARDSNAME:
        if (f.data && f.data.value) {
          const value = this.getTargetName(propertyValue as string);
          return value.toLowerCase().includes(f.data.value.toLowerCase());
        }
        break;
    }
    return false;
  }

  public loadRewardsPage(fromDate: string, toDate: string, onlyInProgress: boolean, autoRefreshIntervalMs = 60000): void {
    this.loadingSubject.next(true);
    this.startAutoRefresh(fromDate, toDate, onlyInProgress, autoRefreshIntervalMs);
    this.loadData(fromDate, toDate, onlyInProgress);
  }

  public startAutoRefresh(fromDate: string, toDate: string, onlyInProgress: boolean, autoRefreshIntervalMs = 60000): void {
    if (this.refreshInterval) {
      this.clearAutoRefresh();
    }
    this.refreshInterval = setInterval(() => {
      this.loadData(fromDate, toDate, onlyInProgress);
    }, autoRefreshIntervalMs);
  }

  private clearAutoRefresh() {
    clearInterval(this.refreshInterval);
  }

  public clear(): void {
    this.clearAutoRefresh();
    this.sub?.unsubscribe();
    this.loadingSubject.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadData(fromDate: string, toDate: string, onlyInProgress: boolean) {
    let services: string[] = null;
    let monitoringServicesIds: string[] = null;
    if (this.sessionService.currentService?.display === this.sessionService.allsOption) {
      services = this.healthcareService.availableServices().map((s) => s.serviceRef);
    }
    const serviceId = this.sessionService.currentService?.reference;
    if (!serviceId && !services) {
      this.data = [];
      this.loadingSubject.next(false);
      return;
    }
    if (this.userService.isMonitoringUser) {
      const currentMonit = this.sessionService.currentMonitoringService;
      if (currentMonit) {
        if (currentMonit.reference === this.sessionService.allsOption) {
          monitoringServicesIds = this.healthcareService.availableMonitoringServices().map((s) => s.serviceRef);
        } else {
          monitoringServicesIds = [currentMonit.reference];
        }
      }
    }
    this.sub = this.rewardScoreService
      .listRewardScoresByService(serviceId, fromDate, toDate, onlyInProgress, services, monitoringServicesIds)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.data = res;
        this.loadingSubject.next(false);
      });
  }

  public getTargetName(reference: string): string {
    return this.getTargetDef(reference)?.rewardDisplayName?.[this.translateService.currentLang] || "-";
  }

  private getTargetDef(reference: string) {
    return this.rewardDefinitions.find((d) => FHIRHelper.getMainIdentifier(d)?.value === reference);
  }
}
