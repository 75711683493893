import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Subject, combineLatest, timer } from "rxjs";
import { first, switchMap, takeUntil } from "rxjs/operators";
import { PractitionerSelectorComponent } from "src/app/components/practitioner-selector/practitioner-selector.component";
import { Tools } from "src/app/helpers/tools";
import { IJournalINSIEntry } from "src/app/models/ins.interface";
import { Practitioner } from "src/app/models/practitioner.model";
import { IDateInterval } from "src/app/models/sharedInterfaces";
import { INSService } from "src/app/providers/ins.service";
import { PractitionerService } from "src/app/providers/practitioner.service";
import { SessionService } from "src/app/providers/session.service";

@Component({
  selector: "app-journal-insi-page",
  templateUrl: "./journal-insi-page.component.html",
  styleUrls: ["./journal-insi-page.component.scss"],
})
export class JournalInsiPageComponent implements OnInit, AfterViewInit, OnDestroy {
  public start: moment.Moment;
  public end: moment.Moment;
  public displayedColumns: string[] = ["requestDate", "INSIReturn", "action"];
  public dataSource: MatTableDataSource<IJournalINSIEntry>;
  public availablePractitioners: Practitioner[];
  private onDestroy$ = new Subject<void>();
  private isFirstLoad = true;
  public loading = true;
  public showTable = true;
  public disableSearchIcon = true;

  @ViewChild(PractitionerSelectorComponent) practitionerSelector: PractitionerSelectorComponent;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private insService: INSService, private practitionerService: PractitionerService, private sessionService: SessionService) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    // Reload practitionner on service change / page reload
    this.sessionService.refreshCurrentService
      .pipe(
        switchMap(() => this.practitionerService.listForService(this.sessionService.currentService?.reference).pipe(first())),
        takeUntil(this.onDestroy$)
      )
      .subscribe((practitioners: Practitioner[]) => {
        this.availablePractitioners = practitioners.filter((p) => p.caremateId);
        if (!this.isFirstLoad) {
          // When changing service, we reset the data and the practitioner select without loading the data automatically
          this.dataSource.data = [];
          this.practitionerSelector.practitionerControl.setValue(undefined);
        }
      });
    // load practitioners when current service is already known
    this.loadPractitioners();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadPractitioners() {
    if (this.sessionService.currentService) {
      this.practitionerService
        .listForService(this.sessionService.currentService.reference)
        .pipe(first(), takeUntil(this.onDestroy$))
        .subscribe((practitioners: Practitioner[]) => {
          this.availablePractitioners = practitioners.filter((p) => p.caremateId);
        });
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  public updateDate(event: IDateInterval): void {
    this.reset();
    this.start = event.fromDate;
    this.end = event.toDate;
  }

  public downloadResponse(data: IJournalINSIEntry): void {
    let blob: Blob;
    if (data.returnValue.startsWith("<?xml")) {
      blob = new Blob([data.returnValue], {
        type: "application/xml",
      });
    } else {
      blob = new Blob([JSON.stringify(JSON.parse(data.returnValue), null, 2)], {
        type: "application/json",
      });
    }
    Tools.openBlob(blob);
  }

  public loadResults(): void {
    this.showTable = true;
    this.disableSearchIcon = true;
    this.dataSource.data = [];
    this.loading = true;
    const caremateId = this.practitionerSelector.practitionerControl.value?.caremateId?.value;
    if (!caremateId) {
      this.dataSource.data = [];
      this.loading = false;
      return;
    }

    const insiLogs$ = this.insService.getINSILogs(caremateId, this.start.toISOString(), this.end.toISOString()).pipe(first());
    const loadingDelay$ = timer(1000);

    combineLatest([insiLogs$, loadingDelay$])
      .pipe(first())
      .subscribe((value) => {
        this.dataSource.data = value[0];
        this.loading = false;
      });
  }

  public onUserSelect(): void {
    // We load the data automaticaly when the page is loaded but not when we change the service
    if (this.isFirstLoad && this.practitionerSelector) {
      this.loadResults();
      this.isFirstLoad = false;
    } else {
      // we empty the table because the search is manually trigger with the "V" button
      this.reset();
    }
  }

  public reset(): void {
    this.showTable = false;
    this.dataSource.data = [];
    this.disableSearchIcon = false;
  }
}
