import { Component, EventEmitter, Input, Output } from "@angular/core";
import { QuestionQuestionnaire } from "src/app/models/questionnaire.interface";

@Component({
  selector: "app-time-input",
  templateUrl: "./time-input.component.html",
  styleUrls: ["./time-input.component.scss"],
})
export class TimeInputComponent {
  @Input() disabled = false;
  @Input() set question(q: QuestionQuestionnaire) {
    this.timeQuestion = q;
    this.setup();
  }
  @Output() questionChange = new EventEmitter<QuestionQuestionnaire>();

  public timeQuestion: QuestionQuestionnaire;
  // Time's variable
  public minTime: string = undefined;
  public maxTime: string = undefined;

  /**
   * Setup all the variables according to the inputs
   */
  private setup() {
    this.setupTimeVariables();
    this.onTimeChange(false);
  }

  /**
   * Setup all the variables needed for the Time input
   */
  private setupTimeVariables() {
    this.minTime = this.timeQuestion.answerDisplay.min ? this.timeQuestion.answerDisplay.min : undefined;
    this.maxTime = this.timeQuestion.answerDisplay.max ? this.timeQuestion.answerDisplay.max : undefined;
  }

  /**
   * Called when the values of min, max, step or initial changed.
   * Update the variable depending on it and check that the values are correct.
   */
  public onTimeChange(needEmit = true): void {
    if (this.minTime && this.maxTime && this.minTime > this.maxTime) {
      this.minTime = this.maxTime;
    }
    if (needEmit) {
      this.emitQuestionChanges();
    }
  }

  private emitQuestionChanges() {
    this.timeQuestion.answerDisplay.min = this.minTime ? this.minTime : undefined;
    this.timeQuestion.answerDisplay.max = this.maxTime ? this.maxTime : undefined;
    this.questionChange.emit(this.timeQuestion);
  }
}
