import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AddOrganizationComponent } from "src/app/components/forms/add-organization/add-organization.component";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { Tools } from "src/app/helpers/tools";
import { OrganisationType, Organization } from "src/app/models/organization.model";
import { OrganizationsService } from "src/app/providers/organizations.service";
import { SessionService } from "src/app/providers/session.service";
import { DataForUserPage } from "../user-page/user-page.component";

@Component({
  selector: "app-organization-user-list-page",
  templateUrl: "./organization-list-page.component.html",
  styleUrls: ["./organization-list-page.component.scss"],
})
export class OrganizationListPageComponent implements OnInit, OnDestroy {
  // Filter
  public globalSearchValue = "";
  public allOrganizations: Organization[];
  public organizationsFiltered: Organization[];
  public OrganizationTypes = OrganisationType;
  public orgType = OrganisationType.PROV;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();
  public isAdmin = false;

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private organisationService: OrganizationsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // Load data
    this.isAdmin = this.sessionService.isAdmin();
    this.loadOrganizations();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public updateSearch(value?: string): void {
    this.globalSearchValue = value ?? "";
    this.organizationsFiltered = this.allOrganizations.filter((org) => {
      return org.name?.toLowerCase().indexOf(this.globalSearchValue.toLowerCase()) > -1 && org.organizationType === this.orgType;
    });
  }

  public onTypeChange(event: MatSelectChange): void {
    const newType = event.value as OrganisationType;
    this.organizationsFiltered = this.allOrganizations.filter((org) => {
      return (
        Tools.suppressDiacritics(org.name)?.toLowerCase().indexOf(Tools.suppressDiacritics(this.globalSearchValue).toLowerCase()) > -1 &&
        org.organizationType === newType
      );
    });
  }

  private loadOrganizations() {
    this.organisationService
      .list()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((orgs) => {
        this.allOrganizations = orgs.sort((a, b) => {
          if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
            return 1;
          }
          if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
            return -1;
          }
          return 0;
        });
        this.updateSearch();
      });
  }

  public addOrganization(): void {
    // Add dialog for new organization
    const dialogRef = this.dialog.open(AddOrganizationComponent, {
      disableClose: true,
      data: { organization: null, mode: FORMS_MODE.CREATE },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.loadOrganizations();
      });
  }

  public addService(): void {
    // TODO Add dialog for new service
  }

  public addUser(): void {
    const userData: DataForUserPage = {
      availableServices: [],
      availableOrganizations: this.organizationsFiltered.map((o) => o.asReference),
    };
    this.router.navigate(["user"], {
      state: { data: userData, mode: FORMS_MODE.CREATE, previousPage: "organizations" },
    });
  }
}
