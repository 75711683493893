<div class="reportDelayContainer" [matTooltip]="!appointments.length ? ('page.teleconsultations.noSelectionError' | translate) : ''">
  <p [ngClass]="{ disable: !appointments.length }" style="padding-right: 1em">
    {{ "page.teleconsultations.reportDelay" | translate }}
  </p>
  <div class="customMinuteField">
    <button mat-icon-button [disabled]="!appointments.length" (click)="increaseMinutes()">
      <mat-icon style="font-size: 30px">arrow_drop_up</mat-icon>
    </button>
    <p [ngClass]="{ disable: !appointments.length }">
      <span>{{ minutesDelay }} </span><span>{{ "periodUnit.minute.plural" | translate }}</span>
    </p>
    <button mat-icon-button [disabled]="!appointments.length || minutesDelay === 0" (click)="decreaseMinutes()">
      <mat-icon style="font-size: 30px">arrow_drop_down</mat-icon>
    </button>
  </div>
  <button mat-stroked-button type="button" style="margin-left: 1em" [disabled]="!appointments.length" (click)="emitMinutesChange()">
    {{ "btn.ok" | translate }}
  </button>
</div>
