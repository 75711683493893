<table
  mat-table
  matSort
  matSortActive="date"
  matSortDirection="desc"
  matSortDisableClear
  [dataSource]="dataSource"
  [ngClass]="{ hide: isNoneData }"
>
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "table.patient" | translate }}
      <app-item-filter
        fieldName="{{ 'table.name' | translate }}"
        propertyName="subject.display"
        [dataType]="dataTypeText"
        [defaultValue]="getFilter('subject.display')"
        (applyFilter)="applyFilter($event)"
      >
      </app-item-filter>
    </th>
    <td mat-cell class="noPadding custom-widget-font" *matCellDef="let element">
      <button
        mat-button
        class="normalWeight custom-widget-font"
        [routerLink]="['/patient', { id: encodeToB64(element?.subject?.reference) }]"
        [state]="{
          caremateIdentifier: element?.subject?.reference,
          widget: widgetName
        }"
      >
        <mat-icon>face</mat-icon>{{ element?.subject?.display }}
      </button>
    </td>
  </ng-container>

  <!-- Date Column -->
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "table.date" | translate }}
      <app-item-filter
        fieldName="{{ 'table.date' | translate }}"
        propertyName="date"
        [dataType]="dataTypeDate"
        [defaultValue]="getFilter('date')"
        (applyFilter)="applyFilter($event)"
      >
      </app-item-filter>
    </th>
    <td mat-cell class="noPadding custom-widget-font" *matCellDef="let element">
      {{ element.creation | date : "short" }}
    </td>
  </ng-container>

  <!-- Values Column -->
  <ng-container matColumnDef="values">
    <th mat-header-cell *matHeaderCellDef>{{ "table.alert" | translate }}</th>
    <td mat-cell class="noPadding custom-widget-font" *matCellDef="let element">
      <ul class="no-bullet">
        <li class="custom-widget-font" *ngFor="let valueComponent of element?.valueComponents">
          {{ valueComponent | translateAlertName : element.observation : allDefinitions }}
          :
          {{
            valueComponent.code.coding[0].code
              | loincAndValueMeaning
                : element.observation?.code.coding[0].code
                : valueComponent.valueQuantity.value
                : allDefinitions
                : true
                : true
          }}
        </li>
      </ul>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator #paginator [pageIndex]="0" [pageSize]="5" [hidePageSize]="true" [ngClass]="{ hide: isNoneData }"></mat-paginator>
<p class="text-center custom-widget-font" *ngIf="isNoneData">
  {{ "widget.noData" | translate }}
</p>
