<div class="organizationListPage">
  <div class="toolbar">
    <div class="left">
      <mat-form-field>
        <mat-label><span class="fa fa-search mr-5"></span>{{ "itemFilter.search" | translate }}</mat-label>
        <input
          type="search"
          matInput
          [placeholder]="'page.organizations.searchPlaceHolder' | translate"
          [value]="globalSearchValue"
          (keyup)="updateSearch($event.target.value)"
        />
        <button *ngIf="globalSearchValue" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="updateSearch('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field class="orgType">
        <mat-label>{{ "page.organizations.type" | translate }}</mat-label>
        <mat-select [(ngModel)]="orgType" (selectionChange)="onTypeChange($event)">
          <mat-option *ngFor="let type of OrganizationTypes | keyvalue" [value]="type.value">
            {{ "page.organizations." + type.value | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="spacer"></div>
    <button
      mat-stroked-button
      class="success"
      *ngIf="('organization' | isAuthorized : 'POST' | async) === true"
      matTooltip="{{ 'page.organizations.btn.newOrg' | translate }}"
      (click)="addOrganization()"
    >
      <mat-icon>add</mat-icon>
    </button>
    <!-- dialog for new service
        <button mat-stroked-button class="success" matTooltip="{{ 'page.organizations.btn.newServ' | translate }}" (click)="addService()">
            <mat-icon>group_add</mat-icon>
        </button> -->
    <button
      mat-stroked-button
      class="success"
      matTooltip="{{ 'page.organizations.btn.newPract' | translate }}"
      (click)="addUser()"
      *ngIf="('/user' | isAuthorized | async) === true && ('practitioner' | isAuthorized : 'POST' | async) === true"
    >
      <mat-icon>person_add</mat-icon>
    </button>
  </div>

  <mat-card>
    <div *ngFor="let organization of organizationsFiltered">
      <app-organization [setOrganization]="organization" [setExpand]="!isAdmin"></app-organization>
    </div>
  </mat-card>
</div>
