import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DataType, Filter } from "src/app/models/filter.interface";
import { ACTION_ALERT, IActionAlert } from "src/app/models/rule-alert.interface";
import { RuleAlert } from "src/app/models/rule-alert.model";
import { SessionService } from "src/app/providers/session.service";
import { AlertHistoricDataSource } from "./alert-historic-datasource";

@Component({
  selector: "app-alert-details-historic",
  templateUrl: "./alert-details-historic.component.html",
  styleUrls: ["./alert-details-historic.component.scss"],
})
export class AlertDetailsHistoricComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() alert: RuleAlert;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<IActionAlert>;

  public dataSource: AlertHistoricDataSource;
  public currentPageSize: number;
  public displayedColumns: string[] = ["date", "action", "author", "comment"];
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  public constructor(private translationService: TranslateService, private sessionService: SessionService) {
    this.sessionService.refreshAlertDetailsDataList.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.dataSource.loadData(this.alert);
    });
  }

  ngOnInit() {
    this.dataSource = new AlertHistoricDataSource(this.alert);
    this.dataSource.loadData(this.alert);
  }

  ngAfterViewInit(): void {
    this.initPaginatorAndSort();
  }
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public getComment(actionHistory: IActionAlert) {
    const comment = this.alert?.notes?.[actionHistory.commentId]?.text;
    if (comment?.length > 100) {
      return comment.substring(0, 100) + "...";
    } else {
      return comment;
    }
  }

  public getTranslations(actions: ACTION_ALERT[]) {
    return this.alert.getActionTranslateKey(actions, this.translationService);
  }

  private initPaginatorAndSort() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentPageSize = this.paginator.pageSize;
    });
  }

  public applyFilter(filter: Filter) {
    this.dataSource.setFilter(filter);
  }

  public getFilter(propertyName: string) {
    return this.dataSource.getFilter(propertyName);
  }
}
