<div class="close-cross">
  <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="displayPicturesContainer">
  <div class="picturesAndPaginatorContainer">
    <div style="display: flex; flex-direction: column; align-items: center; max-width: 100%">
      <div class="title">
        {{ title }}
      </div>
      <div style="display: flex; align-items: center; justify-content: center; max-width: 100%">
        <div class="arrowContainer" *ngIf="!isAllDisplay">
          <mat-icon style="color: white" (click)="arrowClicked(false)">arrow_back</mat-icon>
        </div>
        <div class="pictureContainer" *ngIf="!isAllDisplay">
          <img [src]="pictures[currentIndex]" ngxViewerjs [viewerOptions]="viewerOptions" />
        </div>
        <div class="allPicturesContainer" *ngIf="isAllDisplay">
          <div *ngFor="let picture of pictures">
            <img [src]="picture" style="margin: 5px" ngxViewerjs [viewerOptions]="viewerOptions" />
          </div>
        </div>
        <div class="arrowContainer" *ngIf="!isAllDisplay">
          <mat-icon style="color: white" (click)="arrowClicked(true)">arrow_forward</mat-icon>
        </div>
      </div>
      <div class="paginatorContainer" *ngIf="!isAllDisplay">
        <div *ngFor="let p of pictures; let i = index" class="thumbnailContainer" (click)="dotClicked(i)">
          <img [class]="currentIndex === i ? 'thumbnail blue' : 'thumbnail'" [src]="pictures[i]" />
        </div>
      </div>
    </div>
    <div *ngIf="isCompare" style="display: flex; flex-direction: column; align-items: center; max-width: 100%">
      <div class="title">
        {{ compare.title }}
      </div>
      <div style="display: flex; align-items: center; justify-content: center; max-width: 100%">
        <div class="arrowContainer">
          <mat-icon style="color: white" (click)="arrowClicked(false, true)">arrow_back</mat-icon>
        </div>
        <div class="pictureContainer">
          <img [src]="compare.pictures[compareCurrentIndex]" ngxViewerjs [viewerOptions]="viewerOptions" />
        </div>
        <div class="arrowContainer">
          <mat-icon style="color: white" (click)="arrowClicked(true, true)">arrow_forward</mat-icon>
        </div>
      </div>
      <div class="paginatorContainer">
        <div *ngFor="let p of compare.pictures; let i = index" class="thumbnailContainer" (click)="dotClicked(i, true)">
          <img [class]="compareCurrentIndex === i ? 'thumbnail blue' : 'thumbnail'" [src]="compare.pictures[i]" />
        </div>
      </div>
    </div>
  </div>
  <div class="changeDisplayButton" *ngIf="!isCompare && pictures.length > 1">
    <mat-icon (click)="isAllDisplay = !isAllDisplay">{{ isAllDisplay ? "photo" : "view_column" }}</mat-icon>
  </div>
  <div class="compareButton previous" *ngIf="previous && !isAllDisplay && !isCompare" (click)="compareClick()">
    <p>{{ ("observation.compareObs" | translate) + " " + previous.issued }}</p>
  </div>
  <div class="compareButton next" *ngIf="(next || isCompare) && !isAllDisplay" (click)="compareClick(true)">
    <p>
      {{
        isCompare
          ? ("dialog.patientAlreadyExists.differentOrganization.button.cancel" | translate)
          : ("observation.compareObs" | translate) + " " + next.issued
      }}
    </p>
  </div>
</div>
